<template>
  <CCardText>
    <h5 class="mb-4 mt-2"> Um Ihre Energiedienstleistungen zu konfigurieren sind folgende Schritte notwendig: </h5>
    <div>
      <p class="font-weight-bold"> Definieren von Energiedienstleistungsstrategien </p>
      <ol>
        <li>
          Definition und Konfiguration von Aktuatorgruppen, welche jeweils eine Energiedienstleistung umsetzen sollen,
          z.B. eine Batterie (Gruppe mit einem Aktuator), welche am Netzübergabezähler Eigenverbrauchsoptimierung macht,
          oder eine PV Anlage, für welche die Einspeiseabregelung am Netzübergabezähler (70-Prozent Regelung) umgesetzt
          werden soll.
        </li>
        <li>
          Konfigurieren der Energiedienstleistung der jeweiligen Aktuatorgruppe, z.B. Eigenverbrauchsoptimierung
          umgesetzt durch die Batterie.
        </li>
        <li>
          Die Gesamtheit aller Paare aus Aktuatorgruppe und Energiedienstleistung ist eine
          Energiedienstleistungsstrategie. Es können mehrere Strategien definiert werden.
        </li>
      </ol>
    </div>
    <div>
      <p class="font-weight-bold"> Definieren von Aktivierungsregeln für jede Strategie </p>
      <ol>
        <li> Definieren der Standardstrategie. </li>
        <li> Konfigurieren der fallabhängigen Strategieaktivierung (Multi-use). </li>
      </ol>
    </div>
    <div>
      <p class="font-weight-bold"> Prüfen und Übertragen der Energiedienstleistungskonfiguration </p>
      <ol>
        <li> Auf einer Übersichtsseite können Sie alle getroffenen Konfigurationen überprüfen. </li>
        <li> Übertragen Ihrer Konfiguration an den Energiemanager. </li>
      </ol>
    </div>
  </CCardText>
</template>

<script>
export default {
  name: 'EnergyServiceConfigGuideDe'
}
</script>
