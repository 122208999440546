<template>
  <div class="c-app" :class="{ 'c-dark-theme': isDark }" :style="{ cursor: cursorLoadingStyle }">
    <ApxSidebar v-if="isLoggedIn" />
    <CWrapper>
      <ApxHeader v-if="isLoggedIn" />
      <ApxToaster />
      <FwUpgradeGuard v-if="isLoggedIn" />
      <div class="c-body">
        <main
          :class="{
            'c-main': true,
            'pt-0': true,
            'px-0': isLoggedIn,
            'px-lg-2': isLoggedIn,
            'px-xl-5': isLoggedIn
          }"
        >
          <CContainer fluid class="p-0 m-0">
            <CRow v-if="isLoggedIn" class="my-1 my-md-2 my-xl-3">
              <CCol col="12" :md="{ size: 10, offset: 1 }" :xl="{ size: 8, offset: 2 }">
                <ApxMainAlert />
              </CCol>
            </CRow>
            <template v-if="isLoggedIn">
              <transition>
                <router-view></router-view>
              </transition>
            </template>
            <ApxAuthContainer v-else>
              <transition>
                <router-view></router-view>
              </transition>
            </ApxAuthContainer>
          </CContainer>
        </main>
        <ApxFooter v-if="isLoggedIn" />
        <CFooter v-else class="position-absolute">
          <ApxInformationsSnippet />
        </CFooter>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import ApxAuthContainer from './auth-container'
import ApxFooter from 'custom/footer'
import ApxHeader from 'custom/header'
import ApxInformationsSnippet from '@/layout/footer/apx-informations-snippet'
import ApxMainAlert from './apx-main-alert'
import ApxSidebar from 'custom/sidebar'
import ApxToaster from './toast'
import FwUpgradeGuard from '@/components/fw-upgrade/fw-upgrade-guard'

import { newToastNotificationMsg } from '@/store/modules/notifications'

import { mapGetters } from 'vuex'

export default {
  name: 'ApxMainContainer',
  components: {
    ApxAuthContainer,
    ApxFooter,
    ApxHeader,
    ApxInformationsSnippet,
    ApxMainAlert,
    ApxSidebar,
    ApxToaster,
    FwUpgradeGuard
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('user', ['isLoggedIn']),
    cursorLoadingStyle() {
      return this.loading ? 'wait' : null
    }
  },
  watch: {
    isLoggedIn(loggedIn) {
      if (loggedIn) {
        this.getLicenseFeatures()
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getLicenseFeatures()
    }
  },
  methods: {
    getLicenseFeatures() {
      this.$store.commit('apiLoadingStatus/STARTED')

      const licenseCalls = []
      // for consistency get also Licens ID, however, license does matter
      licenseCalls.push(this.$store.dispatch('auth/getLicenseId'))
      licenseCalls.push(this.$store.dispatch('auth/getLicense'))
      licenseCalls.push(this.$store.dispatch('auth/getLicenseFeatures'))

      Promise.all(licenseCalls)
        .then(() => {
          this.$log.debug('Succesfully fetched license informations.')
        })
        .catch(this.onServerError)
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err) {
      this.$log.warn(err)

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 10000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content: this.$t('api.errors.fetch')
        })
      )
    }
  }
}
</script>
