/**
 * @module view-helper/filters
 */

export function stripOfLogicalDeviceName(ref) {
  if (typeof ref !== 'string') {
    return ref
  }
  return ref.replace(/.*\//, '')
}

export function filterDataObjectName(ref) {
  if (typeof ref !== 'string') {
    return ref
  }

  return ref.replace(/.*\/\w+\./i, '')
}

export function filterLogicalDeviceName(ref) {
  if (typeof ref !== 'string') {
    return ref
  }
  return ref.replace(/^(\w+)\/.*/, '$1')
}
