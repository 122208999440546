/** @module */

import {
  ActuatorStrategies as ActuatorStrategiesPb,
  StrategyDecision as StrategyDecisionPb
} from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import { controllerRpc } from '@/api/ems/controller'
import {
  actuatorGroupToProtoMsg,
  addActuatorGroupsToProtoMsg,
  addStrategiesToProtoMsg,
  decisionTreeToProtoMsg
} from '@/grpc/protobuf/ems-energy-service-helper'
import { isProto } from '@/grpc/protobuf/misc'

/**
 * API call to get the `ems.scontroller.ActuatorStrategies`
 *
 * @function
 *
 * @return {promise}
 */
export function getStrategies() {
  return controllerRpc('get_strategies')
}

/**
 * API call to get the `ems.scontroller.StrategyDecision`
 *
 * @function
 *
 * @return {promise}
 */
export function getStrategyDecisions() {
  return controllerRpc('get_strategy_decisions')
}

/**
 * API call to set the `ems.scontroller.ActuatorStrategies`
 *
 * @function
 *
 * @param {object} params
 * @param {array} params.actuatorGroups have to be a list of plain `ems.scontroller.ActuatorStrategies.ActuatorGroup`(s)
 * @param {array} params.actuatorGroupIds have to be the IDs (Map keys) of the `actuatorGroups`
 * @param {array} params.strategies have to be a list of lists. Each list entry (level 0) corresponds to a `ems.scontroller.ActuatorStrategies.Strategy`. The entry itself is a list too. Each entry of this list (level 1) is a plain `ems.scontroller.EnergyService` or `null`. If `null`, the corresponding actuator-group will not be added to this strategy. The actuator-group-ID of, e.g. an energy-service at position `k`, can be found by checking the entry of `actuatorGroupdIds` at position `k`.
 * @param {arrau} params.strategyIds have to be the IDs (Map keys) of the `strategies`
 *
 * @return {promise}
 */
export function setStrategies({ actuatorGroupIds, actuatorGroups, strategyIds, strategies }) {
  const actuatorStrategies = new ActuatorStrategiesPb()
  if (actuatorGroupIds && actuatorGroups) {
    addActuatorGroupsToProtoMsg({
      actuatorStrategies,
      actuatorGroupIds,
      actuatorGroups
    })
  }
  if (actuatorGroupIds && strategyIds && strategies) {
    addStrategiesToProtoMsg({
      actuatorStrategies,
      actuatorGroupIds,
      strategyIds,
      strategies
    })
  }

  return controllerRpc('set_strategies', actuatorStrategies)
}

/**
 * API call to set the `ems.scontroller.StrategyDecision`
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.defaultStrategyId is the default strategy ID
 * @param {object} params.decisionTree (optional) is a `ems.scontroller.DecisionTree` plain or protobuf message
 *
 * @return {promise} which resolves to an empty message
 */
export function setStrategyDecisions({ defaultStrategyId, decisionTree }) {
  const strategyDecision = new StrategyDecisionPb()

  if (defaultStrategyId) {
    strategyDecision.setDefaultStrategyId(defaultStrategyId)
  }
  if (decisionTree) {
    if (!isProto(decisionTree)) {
      decisionTree = decisionTreeToProtoMsg(decisionTree)
    }
    strategyDecision.setDecisionTree(decisionTree)
  }

  return controllerRpc('set_strategy_decisions', strategyDecision)
}

/**
 * API call to get the `ems.scontroller.SetpointSources`
 *
 * @function
 *
 * @return {promise}
 */
export function getSetpointSourcePropertiesCollection() {
  return controllerRpc('get_setpoint_source_properties_collection')
}

/**
 * API call to get the `ems.scontroller.EnergyServices`
 *
 * @function
 *
 * @param {object} actuatorGroup, in javascript object representation
 *
 * @return {promise}
 */
export function getSuitableEnergyServices(actuatorGroup) {
  return controllerRpc('get_suitable_energy_services', actuatorGroupToProtoMsg({ actuatorGroup }))
}
