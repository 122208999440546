/**
 *
 * @module store/ems-sensors
 */

import Vue from 'vue'

import * as apiEA from '@/api/ems/eval-aliases'

/**
 * A state for the sensors.
 * Currently only a list of the names for it
 */
const state = () => {
  return { aliases: {} }
}

/**
 * Getters for the EMS sensor store.
 */
const getters = {
  aliasNames: (state) => {
    if (state.aliases) {
      return Object.keys(state.aliases)
    } else {
      return []
    }
  }
}

// Question @Eugen: Can we prevent to use mutations from outside, calling this one directly would
//                  end up invalid state.
const mutations = {
  ADD_ALIAS(state, { name }) {
    if (!name) {
      throw new TypeError('Paramter "name" cannot be blank.')
    }

    if (!state.aliases) {
      Vue.set(state, 'aliases', {})
    }

    Vue.set(state.aliases, name, {})
  },
  REMOVE_ALIAS(state, { name }) {
    if (!name) {
      throw new TypeError('Paramter "name" cannot be blank.')
    }

    if (!state.aliases) {
      Vue.set(state, 'aliases', {})
    }

    Vue.delete(state.aliases, name)
  },
  CLEAR_ALL(state) {
    for (const key in state) {
      Vue.delete(state, key)
    }
  }
}

/**
 * Action (API call) to fetch the list of Aliases.
 * Empties aliases on success before refilling them
 *
 * @function
 */
async function getAliases({ state, commit }) {
  return apiEA.listAliases().then((list) => {
    commit('CLEAR_ALL')
    list.getNamesList().forEach((element) => {
      commit('ADD_ALIAS', {
        name: element
      })
    })
  })
}

async function createAlias({ commit }, { name }) {
  return apiEA.createAlias(name).then((rsp) => {
    commit('ADD_ALIAS', {
      name: name
    })
  })
}

async function removeAlias({ commit }, { name }) {
  return apiEA.removeAlias(name).then(() => {
    commit('REMOVE_ALIAS', {
      name: name
    })
  })
}

const actions = {
  getAliases,
  createAlias,
  removeAlias
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
