// package: de.mypowergrid.sherlock
// file: ext/sherlock/network.proto

var ext_sherlock_network_pb = require("../../ext/sherlock/network_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NetworkService = (function () {
  function NetworkService() {}
  NetworkService.serviceName = "de.mypowergrid.sherlock.NetworkService";
  return NetworkService;
}());

NetworkService.GetNetworkInfo = {
  methodName: "GetNetworkInfo",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_network_pb.GetNetworkInfoRequest,
  responseType: ext_sherlock_network_pb.GetNetworkInfoResponse
};

NetworkService.CheckConnectivity = {
  methodName: "CheckConnectivity",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_network_pb.CheckConnectivityRequest,
  responseType: ext_sherlock_network_pb.CheckConnectivityResponse
};

NetworkService.GetKnownDevices = {
  methodName: "GetKnownDevices",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_network_pb.GetKnownDevicesRequest,
  responseType: ext_sherlock_network_pb.GetKnownDevicesResponse
};

exports.NetworkService = NetworkService;

function NetworkServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NetworkServiceClient.prototype.getNetworkInfo = function getNetworkInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.GetNetworkInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NetworkServiceClient.prototype.checkConnectivity = function checkConnectivity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.CheckConnectivity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NetworkServiceClient.prototype.getKnownDevices = function getKnownDevices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.GetKnownDevices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NetworkServiceClient = NetworkServiceClient;

