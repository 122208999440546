<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm @submit.prevent>
      <CRow>
        <div col="12" class="text-muted col mb-4"> {{ $t(`${tBasePath}.description`) }} </div>
      </CRow>
      <CInput
        id="soc-limit"
        type="range"
        min="0"
        max="100"
        :lazy="false"
        add-input-classes="soc-slider-range"
        add-label-classes="custom-form-label"
        custom
        :horizontal="{ label: 'col-12 col-sm-3', input: 'col-12 col-sm-9' }"
        :label="$t(`${tBasePath}.socLimit.label`)"
        :description="$t(`${tBasePath}.socLimit.description`)"
        :invalid-feedback="$t(`${tBasePath}.socLimit.invalidFeedback`)"
        :value.sync="socLimit"
      >
        <template #append>
          <output class="soc-slider-value" for="soc-limit"> {{ socLimit }} % </output>
        </template>
      </CInput>
      <CInput
        type="number"
        custom="true"
        step="1"
        :min="0"
        :description="$t(`${tBasePath}.probingDurationOnInMin.description`)"
        :invalid-feedback="$t(`${tBasePath}.probingDurationOnInMin.invalidFeedback`)"
        :horizontal="{ label: 'col-12 col-sm-3 custom-form-label', input: 'col-12 col-sm-7' }"
        :label="$t(`${tBasePath}.probingDurationOnInMin.label`)"
        :placeholder="$t(`${tBasePath}.probingDurationOnInMin.placeholder`)"
        :is-valid="$v.probingDurationOnInSec.$invalid ? false : null"
        :value="probingDurationOnInMin"
        @change="onUpdateProbingDurationOnInMin"
      />
      <CInput
        type="number"
        custom="true"
        step="1"
        :min="0"
        :description="$t(`${tBasePath}.probingDurationOffInMin.description`)"
        :invalid-feedback="$t(`${tBasePath}.probingDurationOffInMin.invalidFeedback`)"
        :horizontal="{ label: 'col-12 col-sm-3 custom-form-label', input: 'col-12 col-sm-7' }"
        :label="$t(`${tBasePath}.probingDurationOffInMin.label`)"
        :placeholder="$t(`${tBasePath}.probingDurationOffInMin.placeholder`)"
        :is-valid="$v.probingDurationOffInSec.$invalid ? false : null"
        :value="probingDurationOffInMin"
        @change="onUpdateProbingDurationOffInMin"
      />
    </CForm>
  </EsLayoutForm>
</template>

<script>
// import { offGridTargetSocGenerationCurtailmentValidators } from '@/validations/ems-es-decision-tree-validators'
import { esOffGridBatteryProtectionFormValidators } from '@/validations/ems-es-form-validators'
import EsLayoutForm from './es-layout-form'
import { minToSec, strToFloat, probingDurationOnInMin, probingDurationOffInMin } from './form-methods'
import { formProps as props } from './form-props'

export default {
  name: 'EsOffGridBatteryProtectionForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      socLimit: 20,
      probingDurationOnInSec: null,
      probingDurationOffInSec: null,
      showFailureAlert: false
    }
  },
  computed: {
    probingDurationOnInMin,
    probingDurationOffInMin
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.offGridBatteryProtection'
    this.init()
  },
  validations: esOffGridBatteryProtectionFormValidators,
  methods: {
    init() {
      this.socLimit = this.initValues.socLimit || 20
      this.probingDurationOnInSec = this.initValues.probingDurationOnInSec
      this.probingDurationOffInSec = this.initValues.probingDurationOffInSec
    },
    onUpdateProbingDurationOnInMin(duration) {
      this.probingDurationOnInSec = minToSec(strToFloat(duration))
      this.$v.probingDurationOnInSec.$touch()
    },
    onUpdateProbingDurationOffInMin(duration) {
      this.probingDurationOffInSec = minToSec(strToFloat(duration))
      this.$v.probingDurationOffInSec.$touch()
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const esParams = {
        socLimit: strToFloat(this.socLimit),
        probingDurationOnInSec: this.probingDurationOnInSec,
        probingDurationOffInSec: this.probingDurationOffInSec
      }

      this.$emit('submit', {
        esParams
      })
    }
  }
}
</script>
