<template>
  <div class="py-2">
    <!-- BEGIN: Non-Trivial Eval Tree -->
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary pt-2">
        {{ $t('ems.energyService.config.form.targetPower.powerWatts.label') }}
      </small>
      <CButton color="primary" size="sm" variant="outline" @click="onShowEvalTreeModal">
        <CIcon name="cis-eye" size="sm" />
        <span class="align-middle">
          {{ $t('main.showBtn') }}
        </span>
      </CButton>
    </p>
    <!-- END: Non-Trivial Eval Tree  -->
    <!-- BEGIN: Eval Tree Preview Modal -->
    <CModal
      :show.sync="showEvalTreeModal"
      color="primary"
      size="xl"
      :title="$t('ems.energyService.config.form.targetPower.powerWatts.label')"
    >
      <EvalTree v-if="inited" preview />
      <template #footer>
        <div class="d-flex justify-content-end">
          <CButton class="mx-1" type="button" color="secondary" @click="showEvalTreeModal = !showEvalTreeModal">
            {{ $t('main.closeBtn') }}
          </CButton>
        </div>
      </template>
    </CModal>
    <!-- END: Eval Tree Preview Modal -->

    <!-- END: Power Watts -->
    <!-- BEGIN: Power at Position in Topology -->
    <EsPreviewPositionInTopology :position-in-topology="positionInTopology" />
    <!-- END: Power at Position in Topology -->
  </div>
</template>

<script>
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'
import EsPreviewPositionInTopology from './es-preview-position-in-topology'

import EvalTree from '@/components/ems/evalexp/eval-tree'

export default {
  name: 'EsPreviewTargetPowerEval',
  components: {
    EsPreviewPositionInTopology,
    EvalTree
  },
  props: {
    evalWatts: {
      required: true,
      type: Object
    },
    positionInTopology: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      inited: false,
      showEvalTreeModal: false
    }
  },
  async created() {
    await this.init()
    this.inited = true
  },
  methods: {
    translatePhysicalDevice,
    async init() {
      await this.$store.commit('emsEvalExpression/SET', this.evalWatts)
    },
    onShowEvalTreeModal() {
      this.init()
      this.showEvalTreeModal = true
    }
  }
}
</script>
