export const formProps = {
  initValues: {
    required: false,
    type: Object,
    default: () => {}
  },
  initPreselect: {
    required: false,
    type: String,
    default: null
  }
}
