/**
 * @module validations/license-va
 */

import jwtDecode from 'jwt-decode'
import { helpers, required } from 'vuelidate/lib/validators'

export const licenseValidators = {
  license: {
    required,
    validJwt: helpers.withParams({ type: 'validJwt' }, (license) => {
      try {
        const decodedJwt = jwtDecode(license)
        if (decodedJwt.license) {
          return true
        } else {
          return false
        }
      } catch (_err) {
        return false
      }
    })
  }
}
