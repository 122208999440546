/** @module store/users */

import * as apiAuth from '@/api/auth'
import { isProto } from '@/grpc/protobuf/misc'

/**
 * Struct for a User.
 *
 * Info is taken from the BE proto messages: `auth.Principal`, `auth.Role`
 *
 * @constructor
 *
 * @param {object} params defines the user's properties
 * @param {object} params.principal has to be a `auth.Principal` instance
 * @param {array} params.roles (optional) list of `auth.Role`(s)
 *
 */
export function User({ principal, roles = [] }) {
  /**
   * The username. Has to be globally unique.
   *
   * @member {string}
   */
  this.username = null

  /**
   * A list of roles. Each role has a `name` attribute.
   *
   * @member {object}
   */
  this.roles = []

  if (isProto(principal)) {
    principal = principal.toObject()
  }

  this.username = principal.username

  roles.forEach((role) => {
    if (isProto(role)) {
      role = role.toObject()
    } else {
      role = {
        name: role
      }
    }

    this.roles.push(role)
  })
}

/**
 * Store state for `users`.
 *
 * The `users` are a list with User instances.
 *
 * @function
 *
 * @return {object}
 */
const state = () => ({
  users: []
})

const getters = {
  getUser: (state) => {
    return (username) => {
      return state.users.find((u) => {
        return u.username === username
      })
    }
  }
}

const mutations = {
  ADD_USER(state, user) {
    const i = state.users.findIndex((u) => {
      return u.username === user.username
    })

    if (i < 0) {
      state.users.push(user)
    } else {
      state.users.splice(i, 1, user)
    }
  },
  REMOVE_USER(state, username) {
    const i = state.users.findIndex((u) => {
      return u.username === username
    })

    if (i >= 0) {
      state.users.splice(i, 1)
    }
  },
  CLEAR_USERS(state) {
    state.users = []
  }
}

/**
 * Action to fetch users from BE and add to store
 *
 * @function
 *
 * @return {promise} resolves to list of `auth.Claims` proto objects
 */
async function getUsers({ commit }) {
  const rsp = await apiAuth.listUsers()

  rsp.getUsersList().forEach((claims) => {
    commit('ADD_USER', new User({ principal: claims.getPrincipal(), roles: claims.getRolesList() }))
  })

  return rsp.getUsersList()
}

/**
 * Action (API call) to create a new user by another, authorized user
 *
 * @function
 *
 * @param commit
 * @param username {string}
 * @param password {string}
 * @param roles {array}
 *
 * @returns {promise}
 */
async function createUser({ commit }, { username, password, roles = ['admin'] }) {
  // NOTE: The 'admin' as the only needs to be the default such that this user can be useful for any interaction with the EMS
  // TODO: Above needs to be changed once a proper role model is adopted
  if (!username) {
    throw new TypeError('A username is required.')
  }
  if (!password) {
    throw new TypeError('A password is required.')
  }

  return apiAuth.createUser({ username, password, roles }).then((msg) => {
    const principal = {
      username: username
    }
    commit('ADD_USER', new User({ principal, roles: roles }))
    return msg
  })
}

/**
 * Action (API call) to delete a user
 *
 * @function
 *
 * @param commit
 * @param username {string}
 *
 * @returns {promise}
 */
async function deleteUser({ commit }, { username }) {
  if (!username) {
    throw new TypeError('A username is required to delete a user.')
  }

  return apiAuth.deleteUser({ username }).then((msg) => {
    commit('REMOVE_USER', username)
    return msg
  })
}

/**
 * Action (API call) to create a new admin user with PUK
 *
 * @function
 *
 * @param commit
 *
 * @param username {string}
 * @param password {string}
 * @param puk {string}
 *
 * @returns {promise}
 */
async function createUserWithPuk({ commit }, { username, password, puk }) {
  if (!username) {
    throw new TypeError('A username is required.')
  }
  if (!password) {
    throw new TypeError('A password is required.')
  }
  if (!puk) {
    throw new TypeError('A puk is required.')
  }

  return apiAuth.createUserWithPuk({ username, password, puk }).then((msg) => {
    if (!username) {
      throw new TypeError('A username is required.')
    }
    if (!password) {
      throw new TypeError('A new password is required.')
    }
    if (!puk) {
      throw new TypeError('A puk is required.')
    }

    const principal = {
      username: username
    }
    const roles = ['admin']

    commit('ADD_USER', new User({ principal, roles }))
    return msg
  })
}

/**
 * Action (API call) to update a user with PUK
 *
 * @function
 *
 * @param commit
 * @param username {string}
 * @param newPassword {string}
 * @param puk {string}
 *
 * @returns {promise}
 */
async function updateUserWithPuk({ commit }, { username, newPassword, puk }) {
  if (!username) {
    throw new TypeError('A username is required.')
  }
  if (!newPassword) {
    throw new TypeError('A new password is required.')
  }
  if (!puk) {
    throw new TypeError('A puk is required.')
  }

  return apiAuth.updateUserWithPuk({ username, newPassword, puk }).then((msg) => {
    return msg
  })
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    createUser,
    createUserWithPuk,
    deleteUser,
    getUsers,
    updateUserWithPuk
  }
}
