<template>
  <div :key="tabs.length">
    <CCard v-if="omiOnlyEnabled" class="mt-4 px-0 col-12">
      <slot></slot>
    </CCard>
    <!-- Prop `active` of `CTab` is changing, however, computed prop isActive not. Bug? Well, active sets only the initial state. Tmp fix: setting the key below to force rerender. -->
    <CTabs v-else :key="`tab-nav-${device.plural}`" variant="pills" :vertical="false">
      <CTab
        v-for="tab in tabs"
        :key="tab.device.singular"
        :title="tab.title"
        :active="device.singular === tab.device.singular"
        :to="{ name: tab.route.name, params: { device: tab.device.plural } }"
      >
        <CCard :id="tab.device.singular" class="mt-4 px-0 col-12">
          <slot></slot>
        </CCard>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import { toLower } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ApxDevicesConfigNav',
  data() {
    return {
      device: {
        plural: '',
        singular: ''
      },
      extraDevices: ['container' /* , 'eza' */],
      tabs: []
    }
  },
  computed: {
    ...mapGetters('auth', ['enabledFeatures', 'omiOnlyEnabled'])
  },
  watch: {
    '$route.params.device': function (interfaceType) {
      this.init(interfaceType)
    },
    enabledFeatures: function () {
      // needed for a hard refresh on the page => async with getFeatures call
      for (const device of this.extraDevices) {
        if (this.enabledFeatures.includes(device)) {
          this.pushNewTab(device)
        }
      }
    }
  },
  created() {
    this.tabs = []
    const supportedDevices = ['meter', 'inverter', 'wallbox', 'relay']

    supportedDevices.forEach((device) => {
      this.pushNewTab(device)
    })

    for (const device of this.extraDevices) {
      if (this.enabledFeatures.includes(device)) {
        this.pushNewTab(device)
      }
    }

    this.init(this.$route.params.device)
  },
  methods: {
    init(type) {
      this.device.plural = type
      this.device.singular = toLower(type.replace(/^([a-z]+)s$/i, '$1'))
    },
    pushNewTab(device) {
      this.tabs.push({
        device: {
          singular: device,
          plural: `${device}s`
        },
        route: {
          name: 'config-devices'
        },
        title: this.$t(`config.device.${device}.tabTitle`)
      })
    }
  }
}
</script>
