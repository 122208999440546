/** @module */

import logger from '@/logger'
import { Upgrader as UpgraderService } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb_service'
import { GetStateStreamRequest as GetStateStreamRequestPb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import { createStreamCall } from '@/grpc'

/**
 * Subscribs one subscriber to the fw-upgrader state-stream.
 *
 * Performs exponentail backoff during stream-recovery.
 *
 * @function
 *
 * @param {object} opts
 * @param {function} opts.onMessage is the callback-handler, called each time a new message is received. Is called with a `de.mypowergrid.fwupgrade.GetStateStreamResponse` proto-msg.
 * @param {function} opts.onReEstablishing is a callback, called during a broken stream is re-established. It is not sure, that re-establishing will succeed. E.g. use to get current fw-upgrader state, since we might have missed something.
 * @param {boolean} opts.persistent if `true`, a "broken" stream, will be tried to be re-established
 * @param {integer} opts.maxBackoffNum sets the maximal number of retries to re-establishe a stream, before it fails.
 * @param {integer} opts.minBackoffTime sets the lower timescale (in ms), how long the client will wait, until a broken-stream is retried to be re-established.
 * @param {integer} opts.maxBackoffTime sets the upper timescale (in ms), how long the client will wait, until a broken-stream is retried to be re-established.
 *
 * @return {Promise} with additional property `meta`, which includes the gRPC `StreamCall` instance under `meta.stream`.
 */
export function getStateStream({
  onMessage,
  onReEstablishing,
  persistent = true,
  maxBackoffNum = 100,
  minBackoffTime = 400,
  maxBackoffTime = 10000
} = {}) {
  const req = new GetStateStreamRequestPb()
  let failedCounter = 0
  const setupStream = () => {
    return createStreamCall({
      service: UpgraderService,
      method: 'GetStateStream',
      payload: req,
      onMessage: (msg) => {
        failedCounter = 0
        onMessage(msg)
      }
    })
  }

  const meta = { stream: null }

  const p = new Promise((resolve, reject) => {
    async function startStream() {
      // cleanup
      try {
        meta.stream?.close()
      } catch (err) {
        logger.debug(err)
      }
      meta.stream = setupStream()

      try {
        const runningStream = meta.stream.perform()
        if (failedCounter > 0) {
          // resetting failedCounter here would be desireable
          // however, technicial not achiveable,
          // hence, it is done in the `onMessage` handler above
          logger.info('Re-establishing fw-upgrader state-stream. Might fail again.')
          if (onReEstablishing) {
            onReEstablishing()
          }
        }
        await runningStream
        resolve()
      } catch (err) {
        logger.warn('Failed/broken fw-upgrader state-stream.')

        if (persistent && failedCounter < maxBackoffNum) {
          // use an exponential backoff
          const delay = Math.min(2 ** failedCounter * minBackoffTime, maxBackoffTime)
          logger.warn(`Will try to re-establish fw-upgrader state-stream in ${delay} ms.`)
          setTimeout(startStream, delay)
        } else {
          reject(err)
        }
        failedCounter++
      }
    }

    startStream()
  })
  p.meta = meta

  return p
}
