<template>
  <div class="py-2">
    <p class="d-flex justify-content-between mb-1">
      <small :class="labelClass">
        {{ $t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.intervalMinutes.label') }}
      </small>
      <small
        :class="
          Object.assign({}, valueClass, {
            'text-primary': intervalSeconds,
            'text-danger': !intervalSeconds
          })
        "
      >
        <em>
          {{ intervalSeconds / 60 || '?' }}
        </em>
        <em>min</em>
      </small>
    </p>
    <p class="d-flex justify-content-between mb-1">
      <small :class="labelClass">
        {{ $t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.peakWatts.label') }}
      </small>
      <small
        :class="
          Object.assign({}, valueClass, {
            'text-primary': peakWatts,
            'text-danger': !peakWatts
          })
        "
      >
        <em>
          {{ peakWatts || '?' }}
        </em>
        <em>W</em>
      </small>
    </p>
    <p class="d-flex justify-content-between mb-2">
      <small :class="labelClass">
        {{ $t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.safetyMarginWatts.label') }}
      </small>
      <small :class="valueClass">
        <em>
          {{ safetyMarginWatts }}
        </em>
        <em>W</em>
      </small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'EsPreviewRecordedPowerPeakShaving',
  // a failing peak-observer list data request might lead to missing params
  // handle this by allowing for `required: false`
  props: {
    intervalSeconds: {
      required: false,
      type: Number,
      default: null
    },
    peakWatts: {
      required: false,
      type: Number,
      default: null
    },
    safetyMarginWatts: {
      required: true,
      type: Number
    }
  },
  created() {
    this.labelClass = 'font-weight-bold text-primary'
    this.valueClass = {
      'text-break': true,
      'text-primary': true
    }
  }
}
</script>
