<template>
  <CForm @submit.prevent>
    <!-- BEGIN: Replacement Device -->
    <CRow>
      <CCol>
        <!-- prettier-ignore -->
        <CSelect
          :value="selectedLd"
          :options="replacementOptions"
          :horizontal="{ label: 'col-12 col-md-5 custom-form-label', input: 'col-12 col-md-7' }"
          :label="$t(`${tTouPath}.form.replaceDevice.label`)"
          :description="
            ld.isOnline
              ? $t(`${tTouPath}.form.replaceDevice.descriptionForOnline`)
              : $t(`${tTouPath}.form.replaceDevice.descriptionForOffline`)
          "
          custom
          @update:value="onSelectReplacementDevice"
        />
      </CCol>
    </CRow>
    <!-- END:   Replacement Device  -->
    <!-- BEGIN: Remove Device -->
    <CRow form class="form-group mb-0">
      <!-- BEGIN: Description  -->
      <CCol sm="7" class="offset-sm-5 mt-0">
        <CButton
          class="mb-1"
          type="button"
          size="sm"
          color="danger"
          :disabled="ld.isOnline"
          @click="showRemoveConfirmModal = true"
        >
          {{ $t('main.removeBtn') }}
        </CButton>
        <small v-if="ld.isOnline" class="form-text text-muted mt-0">
          {{ $t(`${tTouPath}.form.removeDevice.descriptionForOnline`) }}
        </small>
        <small v-else class="form-text text-muted mt-0">
          {{ $t(`${tTouPath}.form.removeDevice.descriptionForOffline`) }}
        </small>
      </CCol>
      <!-- END: Description   -->
    </CRow>
    <!-- END: Remove Device -->
    <!-- BEGIN: Removal ConfirmationModal -->
    <ConfirmationModal
      :visible.sync="showRemoveConfirmModal"
      color="danger"
      size="lg"
      :title="$t(`${tTouPath}.form.removeDevice.confirmationModal.title`)"
      @update:confirmation="removeSelectedDevice({ confirmed: $event })"
    >
      {{ $t(`${tTouPath}.form.removeDevice.confirmationModal.content`) }}
    </ConfirmationModal>
    <!-- END: Removal ConfirmationModal -->
    <!-- BEGIN: Replacment ConfirmationModal -->
    <ConfirmationModal
      :visible.sync="showReplaceConfirmModal"
      color="danger"
      size="lg"
      :title="$t(`${tTouPath}.form.replaceDevice.confirmationModal.title`)"
      @update:confirmation="replaceSelectedDevice({ confirmed: $event })"
    >
      {{ $t(`${tTouPath}.form.replaceDevice.confirmationModal.content`) }}
    </ConfirmationModal>
    <!-- END: Replacment ConfirmationModal -->
  </CForm>
</template>

<script>
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import { mapGetters } from 'vuex'

export default {
  name: 'EmsReplaceDeviceSelection',
  components: {
    ConfirmationModal
  },
  props: {
    ld: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showRemoveConfirmModal: false,
      showReplaceConfirmModal: false,
      selectedLd: null
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['logicalDeviceNames']),
    ...mapGetters('deviceConfig', ['getDeviceStatus']),
    replacementOptions() {
      const opts = [{ value: null, label: this.$t(`${this.tTouPath}.form.replaceDevice.placeholder`) }]

      this.logicalDeviceNames.forEach((ldn) => {
        // Use only offline devices as options for an online device
        if (this.ld.isOnline) {
          if (this.getDeviceStatus(ldn).health === 'FATAL' || this.getDeviceStatus(ldn).health === 'ALARM') {
            opts.push({
              value: ldn,
              label: ldn
            })
          }
        } else {
          // Use only online devices as options for an offline device
          if (!(this.getDeviceStatus(ldn).health === 'FATAL' || this.getDeviceStatus(ldn).health === 'ALARM')) {
            opts.push({
              value: ldn,
              label: ldn
            })
          }
        }
      })
      return opts
    }
  },
  created() {
    this.tTouPath = 'ems.deviceReplacement'
  },
  methods: {
    onSelectReplacementDevice(val) {
      if (val !== null) {
        this.showReplaceConfirmModal = true
        this.selectedLd = val
      }
    },
    removeSelectedDevice({ confirmed }) {
      if (!confirmed) {
        return
      }
      this.showRemoveConfirmModal = false
      this.$emit('onRemoveDevice', { ldn: this.ld.name })
    },
    replaceSelectedDevice({ confirmed }) {
      if (!confirmed) {
        return
      }
      this.showRemoveConfirmModal = false

      let oldLdn
      let newLdn

      if (this.getDeviceStatus(this.ld.name).health === 'FATAL') {
        oldLdn = this.ld.name
        newLdn = this.selectedLd
      } else {
        oldLdn = this.selectedLd
        newLdn = this.ld.name
      }
      this.$emit('onReplaceDevice', { oldLdn: oldLdn, newLdn: newLdn })
    }
  }
}
</script>

<style scoped></style>
