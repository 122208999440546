<template>
  <CCard class="col-md-12 col-lg-10 card-columns px-0">
    <ApxCardHeader icon="cil-call-missed-outgoing" :title="$t(`${tGlobalRlmPath}.pageTitle`)" />
    <CAlert v-if="isLoading" class="my-5 mx-5" show color="info">
      <h5 class="alert-heading text-center">
        {{ $t(`${tGlobalRlmPath}.form.formSaveConfigFeedback`) }}
      </h5>
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <hr />
      <p class="mb-0">
        <vue-loading
          class="mt-5"
          type="spiningDubbles"
          :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
        />
      </p>
    </CAlert>
    <CRow v-else class="m-0 p-0">
      <CCardGroup class="mt-4 px-3">
        <!-- ---- BEGIN Current Global Load Peak Settings ---- -->
        <CCard class="shadow-none p-1 flat-card mb-0 col-12">
          <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
            <CCardTitle class="mb-0 font-md">
              <h5>
                {{ $t(`${tGlobalRlmPath}.form.rlmCurrent.title`) }}
              </h5>
            </CCardTitle>
          </CCardHeader>
          <CCardBody v-if="defaultPeakObserver" class="mt-0">
            <CurrentRlmDisplay :current-settings="{ currentIntervalMinutes, currentPeakWatts, currentPeakTimestamp }" />
            <CCol class="custom-form-separator my-1" sm="12"></CCol>
            <CurrentRlmForm
              :current-settings="{ currentIntervalMinutes, currentPeakWatts }"
              @overrideRlmConfig="overrideRlmConfig"
            />
          </CCardBody>
        </CCard>
        <!-- ---- END Current Global Load Peak Settings ---- -->
        <!-- ---- BEGIN Edit Timeseries Settings ---- -->
        <CCard class="shadow-none p-1 flat-card mb-0 col-12">
          <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
            <CCardTitle class="mb-0 font-md">
              <h5>
                {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.titleConfig`) }}
              </h5>
            </CCardTitle>
          </CCardHeader>
          <CCardBody class="mt-0">
            <TimeseriesConfigForm v-if="tsName" :ts-name="tsName" class="p-0" />
          </CCardBody>
        </CCard>
      </CCardGroup>
      <!-- ---- END Edit Timeseries Settings ---- -->
      <!-- ---- BEGIN Overview of Past and Planned Settings ---- -->
      <CCard class="shadow-none p-1 px-3 flat-card mb-0 col-12">
        <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
          <CCardTitle class="mb-0 font-md">
            <h5>
              {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.titleOverview`) }}
            </h5>
          </CCardTitle>
        </CCardHeader>
        <CCardBody class="mt-0 pb-0">
          <TimeseriesTable v-if="tsName" :ts-name="tsName" />
          <div class="pb-3 d-flex justify-content-end">
            <CButton size="sm" color="primary" @click="updateTimestampValues()">
              {{ $t('main.saveBtn') }}
            </CButton>
          </div>
        </CCardBody>
      </CCard>
      <div class="d-flex justify-content-start border-top p-3 w-100">
        <!-- prettier-ignore -->
        <CButton
          class="mr-2"
          size="sm"
          color="danger"
          @click="initStore"
        >
          {{ $t('main.resetBtn') }}
        </CButton>
      </div>
      <!-- ---- END Overview of Past and Planned Settings ---- -->
    </CRow>
    <ConfirmationModal
      :visible.sync="showConfirmSaveCurrentRlm"
      color="primary"
      size=""
      :title="$t(`${tGlobalRlmPath}.form.rlmCurrent.confirmOverrideModalTitle`)"
      @update:confirmation="onConfirmOverrideCurrentRlmConfig({ confirmed: $event })"
    >
      <div class="mb-lg-4 mb-2 font-weight-bolder">
        <span>
          {{ $t(`${tGlobalRlmPath}.form.rlmCurrent.confirmOverrideModalContent`) }}
        </span>
      </div>
      <div class="d-flex justify-content-between small px-2 px-lg-5">
        <span>
          <p class="mb-0 mr-1 text-muted text-break">
            {{ $t(`${tBasePathCurrentRlm}.peakWatts.label`) + $t(`${tBasePathCurrentRlm}.peakWatts.unit`) }}
          </p>
          <p class="text-monospace">{{ peakWattsForFormReset }}</p>
        </span>
        <span>
          <p class="m-0 p0 text-muted text-break">
            {{ $t(`${tBasePathCurrentRlm}.intervalMinutes.label`) + $t(`${tBasePathCurrentRlm}.intervalMinutes.unit`) }}
          </p>
          <p class="text-monospace">{{ intervalMinutesForFormReset }}</p>
        </span>
      </div>
    </ConfirmationModal>
  </CCard>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import CurrentRlmDisplay from '@/components/ems/energy-services/rlm-timeseries/current-rlm-display'
import CurrentRlmForm from '@/components/ems/energy-services/rlm-timeseries/current-rlm-form'
import TimeseriesConfigForm from '@/components/ems/energy-services/rlm-timeseries/timeseries-config-form'
import TimeseriesTable from '@/components/ems/energy-services/rlm-timeseries/timeseries-table'
import { API_LOADING_IDS } from '@/store/modules/api-loading-status'
import { newAlertNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'RlmConfig',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    CurrentRlmDisplay,
    CurrentRlmForm,
    TimeseriesConfigForm,
    TimeseriesTable
  },
  data() {
    return {
      showConfirmSaveCurrentRlm: false,
      peakWattsForFormReset: null,
      intervalMinutesForFormReset: null,
      tsName: ''
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['defaultPeakObserver']),
    ...mapGetters('emsTimeseries', ['getTimestampValues']),
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    currentIntervalMinutes() {
      if (this.defaultPeakObserver) {
        return this.defaultPeakObserver.intervalSeconds / 60
      } else {
        return null
      }
    },
    currentPeakTimestamp() {
      if (this.defaultPeakObserver) {
        return moment.unix(this.defaultPeakObserver.peakTimestamp).format('DD.MM.YYYY HH:mm')
      } else {
        return null
      }
    },
    currentPeakWatts() {
      if (this.defaultPeakObserver) {
        return this.defaultPeakObserver.peakWatts
      } else {
        return null
      }
    }
  },
  created() {
    this.initStore()
    this.tBasePathCurrentRlm = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'
  },
  methods: {
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('emsEnergyServicesConfig/getPeakObserverParams')
        .then(() => {
          this.$log.debug('Successfully received EMS default peak observer parameters')
          this.$store.commit('apiLoadingStatus/SET_LOADED', {
            id: API_LOADING_IDS.ems.defaultPeakObserver,
            status: true
          })
        })
        .then(() => {
          this.tsName = this.defaultPeakObserver.resetPeakTimeSeriesName
          this.$store
            .dispatch('emsTimeseries/timeseriesListData', {
              tsName: this.tsName
            })
            .then((res) => {
              this.$log.debug('Succesfully set timeseries data.')
            })
            .catch((err) => {
              // TODO: well check if the error is realy a "GrpcEmsControllerError: Timeseries not found!" ???

              // Handle "GrpcEmsControllerError: Timeseries not found!"
              // If not found, we manually commit empty tsValues to the store or handle it in
              // the timeseriesListData action in order to be able to add new tsValues
              this.$log.warn(err)
              this.$store.commit('emsTimeseries/SET_TIMESTAMP_VALUES', {
                tsName: this.tsName,
                timestampValueList: []
              })
            })
        })
        .catch(this.onServerError)
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err, opts = {}) {
      this.$log.error(err)
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err, opts))
    },
    onSuccess() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'success',
          content: this.$t(`${this.tGlobalRlmPath}.form.formSuccess`),
          show: 8
        })
      )
    },
    onFailure() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'danger',
          content: this.$t(`${this.tGlobalRlmPath}.form.formFailure`),
          show: true
        })
      )
    },
    overrideRlmConfig(payload) {
      this.showConfirmSaveCurrentRlm = true

      this.peakWattsForFormReset = payload.peakWatts
      this.intervalMinutesForFormReset = payload.intervalMinutes
    },
    async onConfirmOverrideCurrentRlmConfig({ confirmed }) {
      if (!confirmed) {
        return
      }

      const defaultPeakObserver = {
        peakWatts: parseFloat(this.peakWattsForFormReset),
        intervalSeconds: 60 * parseFloat(this.intervalMinutesForFormReset)
      }
      this.$store.commit('emsEnergyServicesConfig/ADD_PEAK_OBSERVER', { observer: defaultPeakObserver })

      const timestampValue = {
        timestamp: moment().startOf('minute').unix(),
        value: parseFloat(this.peakWattsForFormReset)
      }
      this.$store.commit('emsTimeseries/ADD_TIMESTAMP_VALUE', {
        tsName: this.tsName,
        timestampValue
      })

      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.updateTimestampValues(false)
        await this.updateCurrentRlmConfig()
        this.onSuccess()
      } catch (err) {
        this.$log.error(
          'EMS failed to update timeseries data or set peak-observer params or perform EMS Energy Service post-processing.'
        )
        this.onFailure()
        this.onServerError(err, { type: 'danger' })
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    async updateCurrentRlmConfig() {
      await this.$store.dispatch('emsEnergyServicesConfig/setPeakObserverParams')

      this.$log.debug('Successfully set (POST) peak-observer params.')
    },
    async updateTimestampValues(sole = true) {
      try {
        if (sole) {
          this.$store.commit('apiLoadingStatus/STARTED')
        }

        await this.$store.dispatch('emsTimeseries/timeseriesUpdate', {
          tsName: this.tsName,
          timestampValueList: this.getTimestampValues(this.tsName)
        })

        this.$log.debug('Succesfully updated timeseries data.')
        if (sole) {
          this.onSuccess()
        }
      } catch (err) {
        if (sole) {
          this.onFailure()
          this.onServerError(err, { type: 'danger' })
        } else {
          throw err
        }
      } finally {
        if (sole) {
          this.$store.commit('apiLoadingStatus/STOPPED')
        }
      }
    }
  }
}
</script>
