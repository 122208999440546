/** @module store/network-config */

import * as apiApx from '@/api/amperix/network-config'

/**
 * Allowed config fields for the uplink network configuration.
 *
 * The `id` is required by the Config-d.
 * However, the 'view' can safely ignore it.
 */
export const UPLINK_NETWORK_CONFIG_FIELDS = [
  'id',
  'static', // all fields are manually set
  'auto', // all fields are obtained via DHCP
  'autoAddr', // only DNS is set manually, other fields (addr) are set via DHCP
  'ip',
  'netmask',
  'gateway',
  'primaryDns',
  'secondaryDns'
]

/**
 * Data structure for the Uplink Network Config information.
 *
 * Is used to create the `de.mypowergrid.amperix.NetworkConfig` proto message.
 *
 * @function
 *
 * @params {object} params to be used instead of the default (dummy) one
 *
 * @return {object}
 */
export function uplinkNetworkConfigTemplate(params = {}) {
  const config = {
    static: false,
    auto: false,
    autoAddr: false,
    ip: '',
    netmask: '',
    gateway: '',
    primaryDns: '',
    secondaryDns: ''
  }

  for (const f of Object.keys(params)) {
    if (UPLINK_NETWORK_CONFIG_FIELDS.indexOf(f) < 0) {
      throw new TypeError(`The NetworkConfig field ${f} is not supported.`)
    }
  }
  Object.assign(config, params)

  return config
}

const state = () => {
  return {
    id: null
  }
}

const mutations = {
  UPDATE_CONFIG_ID(state, { id }) {
    state.id = id
  },
  CLEAR_CONFIG_ID(state) {
    state.id = null
  }
}

/**
 * Fetches the current uplink network configuration.
 * Stores the config-ID for the config-d.
 *
 * @function
 *
 * @param {object} store
 *
 * @return {Promise} a promise which resolves to the received config. The structure of the received config equals the return of [uplinkNetworkConfigTemplate]{@link module:store/network-config.uplinkNetworkConfigTemplate}
 */
function fetchUplinkNetworkConfig({ commit }) {
  const doCommit = (msg) => {
    commit('UPDATE_CONFIG_ID', {
      id: msg.getUplinkConfig().getId()
    })

    return msg
  }

  const simplify = (msg) => {
    msg = msg.getUplinkConfig()
    const config = uplinkNetworkConfigTemplate()

    config.static = msg.hasStatic()
    config.auto = msg.hasFullAuto()
    config.autoAddr = msg.hasAutoAddr()
    if (msg.hasStatic()) {
      config.ip = msg.safeGetMsgChain(['static', 'ip', 'ip'])
      config.netmask = msg.safeGetMsgChain(['static', 'netmask'])
      config.gateway = msg.safeGetMsgChain(['static', 'gateway', 'ip'])
      config.primaryDns = msg.safeGetMsgChain(['static', 'dnsConfig', 'primaryDns', 'ip', 'ip'])
      config.secondaryDns = msg.safeGetMsgChain(['static', 'dnsConfig', 'secondaryDns', 'ip', 'ip'])
    } else if (msg.hasAutoAddr()) {
      config.primaryDns = msg.safeGetMsgChain(['autoAddr', 'dnsConfig', 'primaryDns', 'ip', 'ip'])
      config.secondaryDns = msg.safeGetMsgChain(['autoAddr', 'dnsConfig', 'secondaryDns', 'ip', 'ip'])
    }

    return config
  }

  return apiApx.fetchUplinkNetworkConfig().then(doCommit).then(simplify)
}

/**
 * Pushes the Uplink Network config to the BE,
 * and after success, requests the updated config from the BE.
 * The latter will update the config-ID.
 *
 * @function
 *
 * @param {object} store
 * @param {object} config to be written. Has to be of form as [uplinkNetworkConfigTemplate]{@link module:store/network-config.uplinkNetworkConfigTemplate} returns.
 *
 * @return {Promise} a promise which 'fresh' config. The structure of the received config equals the return of [uplinkNetworkConfigTemplate]{@link module:store/network-config.uplinkNetworkConfigTemplate}.
 */
function upsertUplinkNetworkConfig({ state, dispatch }, config) {
  config.id = state.id

  // the config.id has to be updated, hence we should sync with the BE
  const fetchAgain = () => {
    return dispatch('fetchUplinkNetworkConfig')
  }

  return apiApx.upsertUplinkNetworkConfig(config).then(fetchAgain)
}

const actions = {
  fetchUplinkNetworkConfig,
  upsertUplinkNetworkConfig
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
