/**
 * Vuex module,
 * to cache other vuex modules,
 * or arbitrary local state.
 *
 * Is synced with local storge via persited-state npm package.
 *
 * @module store/cache
 */

import Vue from 'vue'
import { cloneDeep, upperFirst } from 'lodash'
import moment from 'moment'
import { v1 as uuid } from 'uuid' // create UUID from system clock + random values
import * as shvl from 'shvl'
import logger from '@/logger'

/**
 * Simply un-humanize internally used cache-ids.
i */
export const CACHE_IDS = {
  emsEnergyServicesConfig: 'ybza',
  emsEvalExpression: 'obca'
}

/**
 * The default timeout to consider a cache as invalid/unusable
 * in seconds.
 */
export const DEFAULT_TIMEOUT = 1800

/**
 * Supported types to be cached
 *
 */
export const SUPPORTED_TYPES = ['store', 'local']

const timestampKey = (key) => {
  return `timestamp${upperFirst(key)}`
}

const state = () => {
  return {
    store: {},
    local: {},
    timestampStore: {},
    timestampLocal: {}
  }
}

const getters = {
  getCache: (state) => {
    return (id, type, { path } = {}) => {
      if (!SUPPORTED_TYPES.includes(type)) {
        throw TypeError(`The type argument '${type}' is not supported.`)
      }

      const cache = state[type][id]

      if (!path) {
        return cache
      }

      return shvl.get(cache, path)
    }
  },
  hasCache: (state) => {
    return (id, type) => {
      return !!state[type][id]
    }
  },
  hasValidCache: (state, getters) => {
    return (id, type, { timeout = DEFAULT_TIMEOUT } = {}) => {
      const t = state[timestampKey(type)][id]

      return getters.hasCache(id, type) && t > moment().unix() - timeout
    }
  }
}

const mutations = {
  SET_CACHE(state, { id, type, payload }) {
    if (!SUPPORTED_TYPES.includes(type)) {
      throw new TypeError(`Invalid type argument '${type}'.`)
    }

    Vue.set(state[timestampKey(type)], id, moment().unix())
    Vue.set(state[type], id, payload)
  },
  CLEAR_CACHE(state, { id, type } = {}) {
    for (const e in state) {
      if (type && e !== type && e !== timestampKey(type)) {
        continue
      }
      if (id) {
        Vue.delete(state[e], id)
      } else {
        state[e] = {}
      }
    }
  },
  PURGE_CACHE(state) {
    state.store = {}
    state.local = {}
  }
}

/**
 * ACtion to check if a valid cache exists.
 * Will cleanup (optionally), if invalid cache.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.id is the cache ID
 * @param {string} params.type is one of the supported types (`store`, `local`)
 * @param {timeout} params.timeout is an optional timeout, when the cache is considered as invalid
 * @param {boolean} params.cleanup allows to decide whether to clean up an invalid cache or not.
 *
 * @return {boolean}
 */
function hasValidCache({ getters, commit }, { id, type, timeout = DEFAULT_TIMEOUT, cleanup = true }) {
  if (getters.hasValidCache(id, type, { timeout })) {
    return true
  }

  if (cleanup && getters.hasCache(id, type)) {
    commit('CLEAR_CACHE', { id, type })
  }

  return false
}
/**
 * Action to get a valid cache.
 * Will cleanup (optionally), if invalid cache.
 *
 * @function
 *
 * @param {object} params see [hasValidCache]{@link module:store/cache.hasValidCache}
 *
 * @return {object|null}
 */
async function getValidCache({ getters, dispatch }, { id, type, timeout = DEFAULT_TIMEOUT, cleanup = true }) {
  const hasCache = await dispatch('hasValidCache', { id, type, timeout, cleanup })

  if (!hasCache) {
    return null
  }

  return getters.getCache(id, type)
}

/**
 * Action to cache (partially) the current Vuex store state.
 * Cache will be written to this Vuex store,
 * and persisted to local storage.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.path is a dot separated path to the Vuex store state, to be cached. E.g. `emsEnergyServicesConfig` or `emsEnergyServicesConfig.actuatorGroups`.
 * @param {string} params.id is an optional unique ID for the cached data. Is used to receive cache later.
 *
 * @return {string} the ID of the cached store-state.
 */
function cacheStoreState({ rootState, commit }, { path = '', id }) {
  const s = shvl.get(rootState, path)

  if (s === undefined) {
    throw new Error(`Failed to cache parts of the root-store-state. No data found under ${path}. `)
  }

  id = id || uuid()
  commit('SET_CACHE', { id, type: 'store', payload: cloneDeep(s) })
  logger.info(`[Cache] Successfully cached store for ${path} with ID ${id}.`)

  return id
}

/**
 * Action to replay a (partially) cached Vuex store into the current store.
 * Will replay (overwrite) all parts (modules) being cached.
 * Will not merge those.
 * However, the 'rest' of the current Vuex store is left untouched.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.path is a dot sparated path to the Vues store state. to be replayed, e.g. `emsEnergyServicesConfig`
 * @param {string} params.id is the ID of the cached data
 * @param {Vuex.Store} params.vuexStore has to be an instance of the vuexStore to be (partially) replayed
 * @param {integer} params.timeout (optional) is the timeout in seconds, after which the cache is considered as invalid/deprecated.
 *
 * @return {promise} which resolves to `true/false` whether the cache was replayed or not
 */
async function replayStoreState(
  { rootState, getters, dispatch },
  { path = '', id, vuexStore, timeout = DEFAULT_TIMEOUT }
) {
  const useCache = id && (await dispatch('hasValidCache', { id, type: 'store', timeout }))
  if (!useCache) {
    return false
  }
  const s = getters.getCache(id, 'store')

  // deep-clone is expensive, however, modifying the vuex-state here is NOT allowed
  const replayedRootState = cloneDeep(rootState)
  shvl.set(replayedRootState, path, s)

  vuexStore.replaceState(replayedRootState)
  logger.info(`[Cache] Successfully replayed cached 'store' state for path ${path} from ID ${id}.`)

  return true
}

/**
 * Action to replay a cached Vue-component local state into the current component state.
 * Will not merge current with cached state.
 * Will leave 'non-cached' state untouched.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.id
 * @param {Vue} params.vm is the vue model (Vue instance) for which the state is replayed
 * @param {string} params.path (optional) is the path inside the cached data, to be replayed
 * @param {function} params.condition (optional) is a callback which receives the found cache. If it returns false, the cache replay will be aborted
 * @param {array} params.blacklist (optional) is a list of keys to be NOT replayed
 * @param {integer} params.timeout (optional) is the timeout in seconds, after which the cache is considered as invalid/deprecated.
 *
 * @return {promise} which resolves to `true/false` whether the cache was replayed or not
 */
async function replayLocalState(
  { getters, dispatch },
  { id, vm, path, condition, blacklist, timeout = DEFAULT_TIMEOUT }
) {
  if (!vm) {
    throw new TypeError('The vm (Vue instance) is missing.')
  }

  const useCache = id && (await dispatch('hasValidCache', { id, type: 'local', timeout }))
  if (!useCache) {
    return false
  }

  const cache = getters.getCache(id, 'local', { path })
  if (!cache) {
    logger.info(`[Cache] Failed to find local cache for ID ${id} and path ${path}.`)
    return false
  }
  if (condition && !condition(cache)) {
    return false
  }

  for (const e in cache) {
    if (blacklist && blacklist.includes(e)) {
      continue
    }

    vm[e] = cache[e]
  }

  logger.info(`[Cache] Successfully replayed cached 'local' state for path ${path} from ID ${id}.`, cache)
  return true
}

/**
 * Action to fully clear the cache.
 * Exposed as action to be used by other module's actions.
 *
 * @function
 *
 */
function clear({ commit }) {
  commit('CLEAR_CACHE')
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    cacheStoreState,
    clear,
    getValidCache,
    hasValidCache,
    replayLocalState,
    replayStoreState
  }
}
