/** @module grpc/protobuf/ems-helper */

import { upperFirst } from 'lodash'

import sControllerDescription from '@/../lib/proto_json/ext/ems/scontroller/scontroller'

import DataObjectsPkgPb from '@/../lib/proto_js/ext/ems/data-objects_pb'
import TopologyPkgPb from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import SControllerPkgPb from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import { buildDescriptor, objectToProtoMsg } from '@/grpc/parser'
import { evalExpressionPlainToPb } from '@/grpc/protobuf/ems-eval-expression-helper'
import logger from '@/logger'

Object.defineProperty(SControllerPkgPb.EnergyService, 'name', {
  value: 'EnergyService',
  writable: false
})

export const EMS_DESCRIPTION = sControllerDescription.nested.de.nested.mypowergrid.nested.ems

export const EMS_TOPOLOGY_DESCRIPTION = sControllerDescription.nested.de.nested.mypowergrid.nested.ems.nested.topology

export const EMS_SCONTROLLER_DESCRIPTION =
  sControllerDescription.nested.de.nested.mypowergrid.nested.ems.nested.scontroller

export const EMS_ENERGY_SERVICE_DESCRIPTION = EMS_SCONTROLLER_DESCRIPTION.nested.EnergyService

export const EMS_ACTUATOR_STRATEGIES_DESCRIPTION = EMS_SCONTROLLER_DESCRIPTION.nested.ActuatorStrategies

export const EMS_DECISION_TREE_NODE_DESCRIPTION = EMS_SCONTROLLER_DESCRIPTION.nested.DecisionTree.nested.Node

export const EMS_POSITION_IN_TOPOLOGY_DESCRIPTOR = buildDescriptor(EMS_SCONTROLLER_DESCRIPTION, SControllerPkgPb, {
  rootConstructorName: 'PositionInTopology',
  otherPkgDescriptions: {
    topology: EMS_TOPOLOGY_DESCRIPTION
  },
  otherPkgConstructors: {
    topology: TopologyPkgPb
  }
})

export const EMS_ENERGY_SERVICE_DESCRIPTOR = buildDescriptor(EMS_SCONTROLLER_DESCRIPTION, SControllerPkgPb, {
  rootConstructorName: 'EnergyService',
  otherPkgDescriptions: {
    topology: EMS_TOPOLOGY_DESCRIPTION
  },
  otherPkgConstructors: {
    topology: TopologyPkgPb
  }
})

export const EMS_ACTUATOR_GROUP_DESCRIPTOR = buildDescriptor(
  EMS_ACTUATOR_STRATEGIES_DESCRIPTION.nested.ActuatorGroup,
  SControllerPkgPb.ActuatorStrategies.ActuatorGroup,
  {
    otherPkgDescriptions: {
      topology: EMS_TOPOLOGY_DESCRIPTION
    },
    otherPkgConstructors: {
      topology: TopologyPkgPb
    }
  }
)

const EMS_DECISION_TREE_NODE_DESCRIPTOR = buildDescriptor(
  EMS_DECISION_TREE_NODE_DESCRIPTION,
  SControllerPkgPb.DecisionTree.Node,
  {
    otherPkgDescriptions: {
      'de.mypowergrid.ems': EMS_DESCRIPTION,
      'de.mypowergrid.ems.ENS_ExternalSetpointTarget': EMS_DESCRIPTION.nested.ENS_ExternalSetpointTarget,
      topology: EMS_TOPOLOGY_DESCRIPTION
    },
    otherPkgConstructors: {
      'de.mypowergrid.ems': DataObjectsPkgPb,
      'de.mypowergrid.ems.ENS_ExternalSetpointTarget': DataObjectsPkgPb.ENS_ExternalSetpointTarget,
      topology: TopologyPkgPb
    }
  }
)

/**
 * Parses a plain JS proto-msg object to a "full" proto-msg object.
 *
 * @function
 *
 * @param {object} energyService has to be the plain proto msg
 *
 * @return {object} the "full" proto-msg
 */
export function energyServiceToProtoMsg(energyService) {
  if (energyService.evalExpression) {
    const es = new SControllerPkgPb.EnergyService()
    es.setEvalExpression(evalExpressionPlainToPb(energyService.evalExpression))

    return es
  } else if (energyService.targetPower && energyService.targetPower.evalWatts) {
    const es = new SControllerPkgPb.EnergyService()
    const tp = new SControllerPkgPb.EnergyService.TargetPower()
    tp.setEvalWatts(evalExpressionPlainToPb(energyService.targetPower.evalWatts))

    const pos = objectToProtoMsg({
      descriptor: EMS_POSITION_IN_TOPOLOGY_DESCRIPTOR,
      payload: energyService.targetPower.positionInTopology
    })

    tp.setPositionInTopology(pos)

    es.setTargetPower(tp)

    return es
  } else {
    return objectToProtoMsg({
      descriptor: EMS_ENERGY_SERVICE_DESCRIPTOR,
      payload: energyService
    })
  }
}

export function validateEnergyServicePlainMsg(energyService) {
  try {
    // will fail if:
    // plain energyService has not the expected structure
    // proto-type-assertion fails (during serialization)
    energyServiceToProtoMsg(energyService).serializeBinary()

    return true
  } catch (err) {
    logger.error('Invalid "plain" energy-service object. Parsing or serialization faild!')
    logger.error(err.message)
    logger.error('Input:', energyService)

    return false
  }
}

/**
 * Parses and adds the actuator groups data
 * to a protobuf Map (with `ems.scontroller.ActuatorGroup`)
 * of `ems.scontroller.ActuatorStrategies` message
 *
 * @function
 *
 * @param {object} params see [API call setStrategies]{@link module:src/api/ems/scontroller.setStrategies}
 * @param {array} params.actuatorGroupIds (required)
 * @param {array} params.actuatorGroups (required)
 * @param {object} params.actuatorStrategies (optional) should be a `ems.scontroller.ActuatorStrategies` protobuf message (If missing, a new one will be init.)
 *
 * @return {ems.scontroller.ActuatorStrategies}
 */
export function addActuatorGroupsToProtoMsg({ actuatorStrategies, actuatorGroupIds, actuatorGroups }) {
  if (!actuatorStrategies) {
    actuatorStrategies = new SControllerPkgPb.ActuatorStrategies()
  }

  try {
    const ags = actuatorStrategies.getActuatorGroupFromActuatorIdMap()
    actuatorGroupIds.forEach((gId, i) => {
      if (!actuatorGroups[i]) {
        throw new Error(`Failed to parse the actuator-groups data. No actuator-group found at index ${i}.`)
      }

      // `toObject` maps the `switch` field of ActuatorGroup to `pb_switch`,
      // however, the objectToProtoMsg parser needs a `switch` key
      if (Object.prototype.hasOwnProperty.call(actuatorGroups[i], 'pb_switch')) {
        actuatorGroups[i].switch = actuatorGroups[i].pb_switch
        delete actuatorGroups[i].pb_switch
      }

      const g = objectToProtoMsg({
        descriptor: EMS_ACTUATOR_GROUP_DESCRIPTOR,
        payload: actuatorGroups[i]
      })
      ags.set(gId, g)
    })
  } catch (err) {
    logger.error('Failed to addActuatorGroupsToProtoMsg')
    logger.error(err)
  }

  return actuatorStrategies
}

export function actuatorGroupToProtoMsg({ actuatorGroup }) {
  return objectToProtoMsg({
    descriptor: EMS_ACTUATOR_GROUP_DESCRIPTOR,
    payload: actuatorGroup
  })
}

/**
 * Parses the strategies data to a protobuf Map with `ems.scontroller.Strategy`.
 * Will skip "unused" acutator-groups, i.e. a strategy entry with `null` energy-service.
 *
 * @function
 *
 * @param {object} params see [API call setStrategies]{@link module:src/api/ems/scontroller.setStrategies}
 * @param {array} params.actuatorGroupIds (required)
 * @param {array} params.strategyIds (required)
 * @param {array} params.strategies (required)
 * @param {object} params.actuatorStrategies (optional) should be a `ems.scontroller.ActuatorStrategies` protobuf message (If missing, a new one will be init.)
 *
 * @return {protobuf.Map}
 */
export function addStrategiesToProtoMsg({ actuatorStrategies, actuatorGroupIds, strategyIds, strategies }) {
  if (!actuatorStrategies) {
    actuatorStrategies = new SControllerPkgPb.ActuatorStrategies()
  }
  try {
    const strgs = actuatorStrategies.getStrategyFromStrategyIdMap()
    strategyIds.forEach((sId, i) => {
      strgs.set(sId, new SControllerPkgPb.ActuatorStrategies.Strategy())
      const ess = strgs.get(sId).getEnergyServiceFromActuatorIdMap()
      actuatorGroupIds.forEach((gId, j) => {
        if (!strategies[i][j]) {
          logger.debug(`Will not use actuator-group ${gId} in strategy ${sId}.`)
          return
        }
        const es = energyServiceToProtoMsg(strategies[i][j])
        ess.set(gId, es)
      })
    })
  } catch (err) {
    logger.error('Failed to addStrategiesToProtoMsg')
    logger.error(err)
  }

  return actuatorStrategies
}

/**
 * Takes a plain `ems.scontroller.DecisionTree` object
 * and converts it to a full proto-message.
 *
 * @function
 *
 * @param {object} decisionTree has to be a plain `ems.scontroller.DecisionTree` message object
 *
 * @return {object} a protobuf message
 */
export function decisionTreeToProtoMsg(decisionTree) {
  const nodeOneofs = EMS_DECISION_TREE_NODE_DESCRIPTION.oneofs.type.oneof
  const decisionTreePb = new SControllerPkgPb.DecisionTree()
  // 'r' = "root"
  convert(decisionTree, decisionTreePb, 'r')

  return decisionTreePb

  // private
  function convert(tree, msg, id) {
    if (tree.node) {
      msg.setNode(new SControllerPkgPb.DecisionTree.Node([[], []]))
      msg.getNode().setId(id)
      for (const f of nodeOneofs) {
        const setter = `set${upperFirst(f)}`
        if (!tree.node[f]) {
          continue
        }

        if (!EMS_DECISION_TREE_NODE_DESCRIPTOR[f]) {
          throw new Error(`Missing descriptor for the decision tree node paramse ${f}.`)
        }

        msg.getNode()[setter](
          objectToProtoMsg({
            descriptor: EMS_DECISION_TREE_NODE_DESCRIPTOR[f],
            payload: tree.node[f]
          })
        )
        break
      }

      const opts = tree.node.optionsList || tree.node.options
      if (opts?.length) {
        opts.forEach((opt, i) => {
          const optPb = objectToProtoMsg({
            descriptor: EMS_DECISION_TREE_NODE_DESCRIPTOR.options,
            payload: opt
          })
          if (!optPb.getId()) {
            optPb.setId(`${id}_${i}`)
          }
          msg.getNode().addOptions(optPb)
        })
      }

      convert(tree.node.ifYes, msg.getNode().getIfYes(), id + 'y')
      convert(tree.node.ifNo, msg.getNode().getIfNo(), id + 'n')
    } else {
      msg.setStrategyId(tree.strategyId)
    }
  }
}
