<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <p style="white-space: pre-line">
      {{ $t(`${tBasePath}.description`) }}
    </p>
    <CForm @submit.prevent>
      <CSelect
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.gridState.label`)"
        :description="$t(`${tBasePath}.gridState.description`)"
        :is-valid="$v.gridState.$dirty && $v.gridState.$error ? false : null"
        :invalid-feedback="$t(`${tBasePath}.gridState.invalidFeedback`)"
        :options="gridStateOpts"
        :value="$v.gridState.$model"
        @update:value="onUpdateGridState"
      />
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { formProps as props } from './form-props'
import { offgridNodeFormValidators } from '@/validations/ems-es-decision-tree-validators'
import { EMS_GRID_STATES } from '@/view-helper/ems/ems-energy-service-helper'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'

export default {
  name: 'EsOffgridNodeForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      gridState: null,
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry']),
    gridStateOpts() {
      const opts = [{ value: null, label: this.$t(`${this.tBasePath}.gridState.placeholder`) }]

      EMS_GRID_STATES.forEach((gs) => {
        opts.push({
          value: gs,
          label: this.$t(`${this.tBasePath}.gridState.${gs}`)
        })
      })

      return opts
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.gridStateIs'
    this.entry = this.getDecisionTreeEntry(this.idx)
    this.init()
  },
  validations() {
    return offgridNodeFormValidators
  },
  methods: {
    init() {
      if (this.entry) {
        this.gridState = this.entry.params.decisionNodeParams.gridState
      }
    },
    onUpdateGridState(gridState) {
      this.$v.gridState.$model = gridState
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.showFailureAlert = false
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      this.$emit('confirmed', { idx: this.idx, decisionNodeType: 'gridStateIs', params: { gridState: this.gridState } })
    }
  }
}
</script>
