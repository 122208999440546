<template>
  <CRow>
    <CCol sm="12">
      <CListGroupItem>
        <!-- BEGIN: Clickable Header -->
        <!-- prettier-ignore -->
        <div
          :style="{ cursor: 'pointer' }"
          :class="{
            'card-header-bottom-border': showConfig,
            'pb-3': showConfig
          }"
          class="collapsable-card-header pl-0 px-4 ml-n3 mr-n3"
          @click="showConfig = !showConfig"
        >
          <i class="card-header-icon">
            <CIcon
              class="pb-1 pl-0"
              :class="{
                'status-ok': ld.statusIndicator === 'a_green',
                'status-warning': ld.statusIndicator === 'b_yellow',
                'status-alarm': !ld.isOnline || ld.statusIndicator === 'c_red'
              }"
              :name="!ld.isOnline ? 'cisMinusCircle' : ld.statusIndicator === 'a_green' ? 'cisCheckCircle' : 'cisWarning'"
              :height="26"
            />
          </i>
          <!-- END:   LD Status -->
          <!-- BEGIN: LD Name - Small Viewports  -->
          <span class="text-info d-inline-block d-sm-none text-truncate" style="max-width: 100px;">
            {{ ld.name }}
          </span>
          <!-- END: LD Name - Small Viewports   -->
          <!-- BEGIN: LD Name - Medidum Viewports -->
          <span class="text-info d-none d-sm-inline-block d-md-none text-truncate" style="max-width: 270px;">
            {{ ld.name }}
          </span>
          <!-- END: LD Name - Medidum Viewports   -->
          <!-- BEGIN: LD Name - l/xl Viewports -->
          <span class="text-info d-none d-md-inline-block">
            {{ ld.name }}
          </span>
          <!-- END: LD Name - l/xl Viewports -->
          <!-- BEGIN: LD Actions -->
          <div class="card-header-actions mb-2">
            <!-- BEGIN: Show Edit Text and Collapse/Uncollaps Icon on viewports greater than sm  -->
            <span class="topo-card-header-configure-action btn-minimize font-sm d-none d-md-block text-primary">
              <strong class="font-sm">
                {{ $t('main.configActions.edit') }}
              </strong>
              <i class="topo-config-header-icon">
                <CIcon class="pb-1" :name="`cil-chevron-${showConfig ? 'bottom' : 'top'}`" :height="20" />
              </i>
            </span>
            <!-- END: Show Edit Text and Collapse/Uncollaps Icon on viewports greater than sm   -->
            <!-- BEGIN: Show Collapse/Uncollaps Icon on sm viewports but hide Edit Text -->
            <span class="topo-card-header-configure-action btn-minimize font-sm d-md-none">
              <i class="topo-config-header-icon">
                <CIcon class="pb-1" :name="`cil-chevron-${showConfig ? 'bottom' : 'top'}`" :height="20" />
              </i>
            </span>
            <!-- END: Show Collapse/Uncollaps Icon on sm viewports but hide Edit Text -->
          </div>
          <!-- END: LD Actions -->
        </div>
        <!-- END:   Clickable Header -->
        <!-- BEGIN: Body -->
        <CCollapse :show="showConfig">
          <CCardBody class="pb-1">
            <EmsReplaceDeviceSelection :ld="ld" @onRemoveDevice="onRemoveDevice" @onReplaceDevice="onReplaceDevice" />
          </CCardBody>
        </CCollapse>
        <!-- END: Body   -->
      </CListGroupItem>
    </CCol>
  </CRow>
</template>

<script>
import EmsReplaceDeviceSelection from '@/components/ems/device-replacement/ems-replace-device-selection'

export default {
  name: 'EmsReplaceDevice',
  components: {
    EmsReplaceDeviceSelection
  },
  props: {
    ld: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showConfig: false
    }
  },
  methods: {
    onRemoveDevice({ ldn }) {
      this.$emit('onRemoveDevice', { ldn })
    },
    onReplaceDevice({ oldLdn, newLdn }) {
      this.$emit('onReplaceDevice', { oldLdn, newLdn })
    }
  }
}
</script>

<style>
header.card-header.collapsable-card-header {
  border: 0;
  padding: 0;
}

li.list-group-item {
  /*border-left: 1px solid;*/
  /*border-right: 1px solid;*/
  /*border-top: 1px solid;*/
  padding: 0.75rem 1rem;
}

.card-header-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 21, 0.125);
}
</style>
