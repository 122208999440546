/** @module grpc */

import jspb from 'google-protobuf'
import { grpc } from '@improbable-eng/grpc-web'
import { v1 as uuid } from 'uuid'
import { evalServiceError } from '@/api/error-handling'
import { extendJsProtobufMessagePrototype } from './parser'
import { getGrpcUrl, getGrpcOpts } from './configure'
import { eventBus } from '@/view-helper/event-bus'
import logger from '@/logger'

extendJsProtobufMessagePrototype(jspb) // e.g. extends by `safeGetMsgChain`
const OPTS = getGrpcOpts()

// see: https://github.com/improbable-eng/grpc-web/blob/master/client/grpc-web/docs/transport.md
//
// set `withCredentials: true` (defaults to `false`) will send Browser cookies along with cross-origin (e.g. different port) requests
grpc.setDefaultTransport(grpc.CrossBrowserHttpTransport({ withCredentials: OPTS.withCredentials }))

/**
 * Constructor function for unary gRPC calls.
 * A thin wrapper arround the [gRPC unary call]{@link see https://github.com/improbable-eng/grpc-web/blob/master/client/grpc-web/docs/unary.md}.
 *
 * @constructor
 *
 * @param {object} arg
 * @param {object} arg.service a gRPC Service generated by protoc
 * @param {string} arg.method the gRPC method invoked from the gRPC Service, e.g. `arg.service[arg.method] -> MethodConstructor`
 * @param {object} arg.payload has to be an instance of a proto request message class, expected by the gRPC service-method.
 */
export function UnaryCall({ service, method, payload }) {
  let callId = 0

  /**
   * Endpoint for gRPC-web.
   *
   * For config see `ui/config/api/grpc.json`
   *
   */
  this.url = getGrpcUrl()

  /** All active (not ended) gRPC unary call requests */
  this.calls = {}

  /**
   * Getter for request payload.
   * Modify, if needed, the payload attributes here, before performing the call.
   */
  this.payload = payload

  /**
   * Performs the gRPC unary call.
   * Make sure, that the request-payload is specified before.
   *
   * @function
   *
   *
   * @return {Promise}
   */
  this.perform = () => {
    const id = ++callId % 100000 // avoid overflow
    const p = new Promise((resolve, reject) => {
      this.calls[id] = grpc.unary(service[method], {
        host: this.url,
        // clone avoids raice conditions, if perform is called multiple times with changed payload
        request: this.payload.cloneMessage(),
        onEnd: (res) => {
          const { status, statusMessage, message } = res
          if (status === grpc.Code.OK) {
            // logger.debug('Successfully received gRPC response.') // , message
            resolve(message)
          } else {
            logger.debug(`gRPC request failed with ${status}`)
            const err = new RspErr({ code: status, msg: statusMessage })

            if (status === 7) {
              logger.warn('Unary call failed. Request PERMISSION_DENIED.')
              eventBus.$emit('grpc:unary-permission-denied', err)
            }

            if (status === 16) {
              logger.warn('Unary call failed. Request UNAUTHENTICATED.')
              eventBus.$emit('grpc:unary-unauthenticated', err)
            }

            reject(err)
          }

          this.clean(id)
        }
      })
    })
    p.id = id

    return p
  }

  /**
   * @function
   *
   * @param {Integer} id of the gRPC request
   *
   * @return {gRPC-Request}
   */
  this.getCall = (id) => {
    return this.calls[id]
  }

  this.abort = (id) => {
    if (id && this.calls[id]) {
      this.calls[id].close()
    } else {
      for (const i in Object.keys(this.calls)) {
        this.calls[i].close()
      }
    }
    this.clean(id)
  }

  this.clean = (id) => {
    if (id && this.calls[id]) {
      delete this.calls[id]
    } else {
      for (const i in Object.keys(this.calls)) {
        delete this.calls[i]
      }
    }
  }
}

/**
 * Constructor function for polling unary calls.
 * Should mainly have the same interface as the gRPC Stream construtor.
 * In case the BE supports a stream, use always the stream instead.
 *
 * NOTE: Does not allow for multiple subscriptions.
 *
 * @constructor
 *
 * @param {object} arg
 * @param {object} arg
 * @param {object} arg.service a gRPC Service generated by protoc
 * @param {string} arg.method the gRPC unary method invoked from the gRPC Service, e.g. `arg.service[arg.method] -> MethodConstructor`
 * @param {object} arg.payload has to be an instance of a proto request message class, expected by the gRPC service-method.
 * @param {function} arg.onMessage is the callback to be executed on successful unary call response. Receives the gRPC unary call response proto message.
 *
 */
export function PollingUnaryCall({ service, method, payload, onMessage, onError }, opts = {}) {
  const pollingInterval = opts.pollingInterval || 10000 // ms

  // stubs the interface of the gRPC streaming Request (grpc.invoke)
  function Request(id) {
    this.id = id
    this.running = false
    if (typeof id === 'number' && id > 0) {
      this.running = true
    }

    this.close = () => {
      if (typeof this.id === 'number' && this.id > 0) {
        window.clearInterval(this.id)
        this.id = null
        this.running = false
        logger.debug('PollingUnaryCall closed')
      }
    }
  }

  if (typeof onError !== 'function') {
    onError = (err) => logger.error(`Unary gRPC polling failed with status: ${err.status} and message: ${err.msg}`)
  }
  const unaryCall = createUnaryCall({ service, method, payload })

  this.stream = new Request()

  this.perform = () => {
    const id = window.setInterval(() => {
      const p = unaryCall
        .perform()
        .then(evalServiceError)
        .then(onMessage)
        .catch(onError)
        .finally(() => {
          unaryCall.clean(p.id)
        })
    }, pollingInterval)

    this.stream = new Request(id)
  }

  this.close = () => {
    this.stream.close()
  }
}

/**
 * Constructor function for receiving messeages of server-side streams.
 * Allows for multiple subscriptions to the same stream.
 *
 * @see {@link https://github.com/improbable-eng/grpc-web/blob/master/client/grpc-web/docs/invoke.md}
 *
 * @constructor
 *
 * @param {object} arg
 * @param {object} arg.service a gRPC Service generated by protoc
 * @param {string} arg.method the gRPC stream method invoked from the gRPC Service, e.g. `arg.service[arg.method] -> MethodConstructor`
 * @param {object} arg.payload has to be an instance of a proto request message class, expected by the gRPC service-method.
 * @param {function} arg.onMessage is an optional callback (registered as `'default'` subscription) to be executed on successful message received. Receives the gRPC-stream response proto message. If missing, subscriptions might be added later on.
 *
 */
export function StreamCall({ service, method, payload, onMessage }) {
  /**
   * Endpoint for gRPC-web.
   *
   * For config see `ui/config/api/grpc.json`
   *
   */
  this.url = getGrpcUrl()

  /**
   * Getter for request payload.
   * Modify, if needed, the payload attributes here, before performing the call.
   */
  this.payload = payload

  /**
   * The gRPC stream Request.
   *
   * @see {@link https://github.com/improbable-eng/grpc-web/blob/master/client/grpc-web/docs/invoke.md#request}
   *
   * Only available if stream was opened (`perform` called).
   *
   */
  this.stream = null

  /**
   * Currently subscribed consumer-callbacks.
   * Accessible via ID.
   */
  this.subscriptions = {}

  /**
   * Allows to register a new subscription.
   *
   * @function
   *
   * @param {string} id is an optional ID to identify this subscription
   * @param {function} handler has to be the callback called each time a new message is received. The received message will be the gRPC stream response proto message.
   *
   * @return {integer} id
   */
  this.addSubscription = ({ id, handler }) => {
    id ||= uuid()

    this.subscriptions[id] = handler

    return id
  }

  /**
   * Allows to remove one or all subscriptions.
   *
   * @function
   *
   * @param {string} id is the subscription's ID. If missing, all subscriptions are removed.
   *
   */
  this.removeSubscription = (id) => {
    if (id) {
      delete this.subscriptions[id]
    } else {
      this.subscriptions = {}
    }
  }

  if (onMessage) {
    this.addSubscription({ id: 'default', handler: onMessage })
  }

  /**
   * Triggers the gRPC stream request,
   * and subscribes (if `onMessage` is present).
   *
   * @function
   *
   * @return {Promise} which resolves when the stream is properly closed (gRPC status code OK) by server/client, or rejects with a `RspErr`, if any error.
   */
  this.perform = () => {
    const p = new Promise((resolve, reject) => {
      this.stream = grpc.invoke(service[method], {
        host: this.url,
        request: this.payload,
        onMessage: (rsp) => {
          for (const id in this.subscriptions) {
            try {
              this.subscriptions[id](rsp)
            } catch (err) {
              logger.error(`Subscription ${id} failed to handle newly incoming message.`)
              logger.error(err)
            }
          }
        },
        onEnd: (code, message) => {
          if (code === grpc.Code.OK || code === grpc.Code.CANCELLED) {
            resolve()
          } else {
            logger.debug(`gRPC stream failed/aborted with ${code}`)
            const err = new RspErr({ code, msg: message })

            if (code === 7) {
              logger.warn('Stream failed. Request PERMISSION_DENIED.')
              // currently no listener is implemented
              eventBus.$emit('grpc:stream-permission-denied', err)
            }

            if (code === 16) {
              logger.warn('Stream failed. Request UNAUTHENTICATED.')
              // currently no listener is implemented
              eventBus.$emit('grpc:stream-unauthenticated', err)
            }

            reject(err)
          }
        }
      })
    })

    return p
  }

  /**
   * Closes an open stream
   *
   * @function
   *
   */
  this.close = () => {
    if (!this.stream) {
      return
    }
    this.stream.close()
  }
}

/**
 * Factory function to create an instance for gRPC unary calls
 * @see UnaryCall
 *
 * @function
 *
 * @return {UnaryCall}
 */
export function createUnaryCall(opt) {
  return new UnaryCall(opt)
}

/**
 * Factory function to create an instance for gRPC polling unary calls
 * @see PollingUnaryCall
 *
 * @function
 *
 * @return {PollingUnaryCall}
 */
export function createPollingUnaryCall(opt) {
  return new PollingUnaryCall(opt)
}

/**
 * Factory function to create an instance for gRPC stream
 * @see Stream
 *
 * @function
 *
 * @return {StreamCall}
 */
export function createStreamCall(opt) {
  return new StreamCall(opt)
}

/**
 * A Error constructor for gRPC response errors.
 *
 * @constructor
 *
 * @param {object} arg
 * @param {integer} arg.status
 * @param {string} arg.msg
 *
 */
export function RspErr({ code, msg }) {
  this.name = 'GrpcError'

  /** gRPC error code */
  this.code = code

  /** gRPC error message */
  this.msg = msg

  /**
   * gRPC error message
   *
   * only due to interface conventions (an Error should have a message property)
   */
  this.message = msg
}

RspErr.prototype = Object.create(Error.prototype)
