<template>
  <!-- prettier-ignore -->
  <CDataTable
    class="table-sm p-2"
    :items="tsValues"
    :fields="fields"
    hover
    border
    sorter
  >
    <template #datetime="{item}">
      <td class="align-middle">
        {{ item.datetime }}
      </td>
    </template>
    <template #value="{item}">
      <td class="align-middle">
        {{ item.value }}
      </td>
    </template>
    <template #status="{item}">
      <td class="align-middle d-none d-sm-table-cell">
        <CBadge v-if="item._classes === 'table-dark' " :color="'secondary'">
          {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.timestamp.tableStatus.past`) }}
        </CBadge>
        <CBadge v-else :color="'success'">
          {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.timestamp.tableStatus.planned`) }}
        </CBadge>
      </td>
    </template>
    <!-- prettier-ignore -->
    <template #remove_tsValue="{item}">
      <td class="align-middle text-center">
        <CButton
          color="danger"
          square
          size="sm"
          @click="onRemove(item)"
        >
          <CIcon name="cil-trash"/>
        </CButton>
      </td>
    </template>
    <template #no-items-view>
      <tbody class="d-flex align-content-center">
        <!-- prettier-ignore -->
        <svg
          id="svg-inner"
          x="0"
          y="0"
          width="50%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <CIcon name="cilBan" :height="100"/>
          <text
            x="50%"
            y="80%"
            :style="{'font-size': '12px', fill: isDark ? 'var(--dark-danger)' : 'var(--danger)'}"
            dominant-baseline="hanging"
            text-anchor="middle"
          >
          <tspan >
              {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.noData`) }}
            </tspan>
          </text>
        </svg>
      </tbody>
    </template>
  </CDataTable>
</template>

<script>
import { omit } from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'TimeseriesTable',
  props: {
    tsName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('coreui', { isDark: 'darkMode' }),
    ...mapGetters('emsTimeseries', ['getTimestampValues']),
    fields() {
      return [
        {
          key: 'datetime',
          label: this.$t(`${this.tGlobalRlmPath}.form.rlmTimeseries.timestamp.tableHeader`),
          _style: 'width:10%'
        },
        {
          key: 'value',
          label:
            this.$t(`${this.tBasePathCurrentRlm}.peakWatts.label`) +
            this.$t(`${this.tBasePathCurrentRlm}.peakWatts.unit`),
          _style: 'width:10%;',
          sorter: false
        },
        {
          key: 'status',
          label: 'Status',
          _style: 'width:5%;',
          _classes: 'd-none d-sm-table-cell ',
          sorter: false
        },
        {
          key: 'remove_tsValue',
          label: '',
          _style: 'width:1%',
          sorter: false
        }
      ]
    },
    tsValues() {
      const tvs = this.getTimestampValues(this.tsName)
      if (tvs) {
        const now = moment().unix()
        return tvs.map((item) => {
          // TODO: adapt datetime format to users locale (e.g. from the store, which is WIP for the user auth feature)
          return {
            timestamp: item.timestamp,
            datetime: moment.unix(item.timestamp).format('DD.MM.YYYY HH:mm'),
            ...omit(item, ['timestamp']),
            _classes: now >= item.timestamp ? 'table-dark' : 'table-success'
          }
        })
      }

      return []
    }
  },
  created() {
    this.tBasePathCurrentRlm = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'
  },
  methods: {
    onRemove(item) {
      this.$store.commit('emsTimeseries/REMOVE_TIMESTAMP_VALUE', { tsName: this.tsName, timestamp: item.timestamp })
    }
  }
}
</script>

<style scoped>
#svg-outer {
  display: flex;
  align-items: center;
  background-color: lightgray;
  height: 100%;
}

#svg-inner {
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 0;
  display: block;
}
</style>
