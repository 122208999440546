/**
 * Helper for the [ems-energy-services-config store]{@link module:store/ems-energy-services-config}
 * @module store/ems-energy-services-config-helper
 */

import { clone, isEmpty, toLower, uniq, upperFirst } from 'lodash'
import { EMS_ENERGY_SERVICE_DECISION_TYPES } from '@/grpc/protobuf/ems-scontroller-helper'
import { isProto } from '@/grpc/protobuf/misc'
import logger from '@/logger'

export const SUPPORTED_EMS_ENERGY_SERVICE_PRESELECTIONS = {
  targetPower: ['ADVANCED', 'SELF-CONSUMPTION', 'PEAKSHAVING', 'PV-CURTAILMENT', 'EVAL'],
  recordedPowerMeasurementPeakShaving: [],
  generationPeakShaving: []
}

export const SUPPORTED_EMS_ENERGY_SERVICES = {
  POWER: [
    'targetPower',
    'recordedPowerMeasurementPeakShaving',
    'generationPeakShaving',
    'offGrid',
    'evalExpression',
    'pvCurtailment',
    'offGridTargetSocGenerationCurtailment'
  ],
  SWITCH: ['evalExpression', 'digitalOutputSwitchCondition', 'offGridBatteryProtection']
}

/**
 * Maps the supported actuator-group types to the protobuf ActuatorGroup one-of field names.
 *
 *
 */
export const SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES = {
  POWER: ['battery', 'chai', 'simplePriority', 'advancedPriority', 'carLis', 'singlePower'],
  SWITCH: ['switch']
}

/**
 *
 * @function
 *
 * @param {object} actuatorGroup is a "full" or "plain" PB ActuatorGroup instance
 *
 * @return {string} `POWER` or `SWITCH` or 'NONE'
 */
export function determineActuatorGroupType(actuatorGroup) {
  const check = (t) => {
    if (isProto(actuatorGroup)) {
      return actuatorGroup[`has${upperFirst(t)}`]()
    }

    if (t === 'switch') {
      return !!actuatorGroup[t] || !!actuatorGroup.pb_switch
    }

    return !!actuatorGroup[t]
  }

  for (const t of SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES.POWER) {
    if (check(t)) {
      return 'POWER'
    }
  }
  for (const t of SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES.SWITCH) {
    if (check(t)) {
      return 'SWITCH'
    }
  }

  // a "non-parametrized" group should be treated as POWER
  if (isProto(actuatorGroup)) {
    if (!actuatorGroup.getTypeCase()) {
      return 'POWER'
    }
  } else {
    if (
      [
        ...SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES.POWER,
        ...SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES.SWITCH
      ].every((t) => {
        return !check(t)
      })
    ) {
      return 'POWER'
    }
  }

  return 'NONE'
}

export function buildActuatorGroupName({ type, id }) {
  if (type) {
    return `${id}-${toLower(type)}`
  }

  return id
}

/**
 * Allows to travers in pre-order the ems.scontroller.DecisionTree
 *
 * @generator
 * @function
 *
 * @param {object} decisionTree has to be a `ems.scontroller.DecisionTree` plain JS-proto Object (empty one is allowed)
 * @param {object} parsedDecisionTree (optional) can be a `new DecisionTreeEntry` which will be populated during traversal.
 *
 * @yields {DecisionTreeEntry} returns either the DecisionTree.Node or the Strategy-ID
 *
 * @return {generator}
 */
export function traverseDecisionTreeInPreOrder(decisionTree, parsedDecisionTree) {
  let idx = -1
  const idxPath = []

  function* iterates(tree, parsedTree, { isYes, isNo } = {}) {
    idx++
    idxPath.push(idx)
    const value = { idx, tree, parsedTree, entry: null, idxPath: clone(idxPath) }
    const opts = { idx, idxPath: value.idxPath, isYes, isNo }

    if (tree && tree.node) {
      value.entry = tree.node
      parsedTree.init(value.entry, opts)
      yield value
      yield* iterates(tree.node.ifYes, parsedTree.children[0], { isYes: true, isNo: false })
      yield* iterates(tree.node.ifNo, parsedTree.children[1], { isYes: false, isNo: true })
    } else if (tree && tree.strategyId !== undefined) {
      value.entry = tree.strategyId
      parsedTree.init(value.entry, opts)
      yield value
    }

    idxPath.pop()
  }

  if (!parsedDecisionTree) {
    parsedDecisionTree = new DecisionTreeEntry()
  }

  return iterates(decisionTree, parsedDecisionTree)
}

/**
 * Iterates in pre-order the ems.scontroller.DecisionTree
 *
 * @function
 *
 * @param {object} tree has to be a `ems.scontroller.DecisionTree` plain JS-proto Object (empty one is allowed)
 *
 * @param {function} cb (optional) is the callback function called for each iteration step. It will receive `({DecisionTreeEntry} entry, {integer} idx, {ems.scontroller.DecisionTree} tree)` as arguments, and should return `true` to continue or `false` to break iteration. `entry` is the data either a node or a leaf. `idx` is the iteration index of pre-order traversal. `tree` is a reference to the sub-tree at the current position.
 *
 *
 * return {DecisionTreeEntry} is the parsed decision tree
 */
export function iterateDecisionTree(tree, cb) {
  if (isEmpty(tree)) {
    return {}
  }

  const parsedTree = new DecisionTreeEntry()
  parsedTree.isNode = true // empty tree should start as empty node
  parsedTree.idx = 0
  const itr = traverseDecisionTreeInPreOrder(tree, parsedTree)

  let step = itr.next()
  while (!step.done) {
    if (cb) {
      // prettier-ignore
      const shouldNext = cb(
        step.value.parsedTree,
        step.value.idx,
        step.value.tree
      )
      if (!shouldNext) {
        break
      }
    }
    step = itr.next()
  }

  return parsedTree
}

/**
 * Wraps a ems.scontroller.DecisionTree.Node or DecisionTree.strategy_id data
 *
 * @constructor
 *
 * @param {object|string} entry is either a ems.scontroller.DecisionTree.Node or a ems.s.scontroller.ActuatorStrategies.Strategy ID
 *
 */
export function DecisionTreeEntry(entry) {
  /**
   * The display name (or name identifier if node) for this entry
   *
   * @member {string}
   */
  this.name = ''

  /**
   * Whether it is a node or not.
   *
   * @member {boolean}
   */
  this.isNode = null

  /**
   * The index of this entry for a pre-order traversal
   *
   * @member {integer}
   */
  this.idx = null
  this.idxPath = []
  this.isYes = undefined
  this.isNo = undefined

  /**
   * Children (empty or two) for this entry.
   *
   * @member {array}
   */
  this.children = []

  /**
   * Parameters of this node or leaf.
   *
   * For a node: `decisionNodeType`, `decisionNodeParams`.
   * For a leaf: `strategyId`
   *
   * @member {object}
   */
  this.params = {}

  this.init = function (e, { idx, idxPath, isYes, isNo } = {}) {
    this.isNode = typeof e === 'object'
    this.idx = idx
    this.idxPath = idxPath
    this.isYes = isYes
    this.isNo = isNo

    if (this.isNode) {
      this.node = e
      this.params.decisionNodeType = null
      this.params.decisionNodeParams = {}

      try {
        for (const t of EMS_ENERGY_SERVICE_DECISION_TYPES) {
          const d = e[t]
          if (!d) {
            continue
          }
          this.name = t
          this.params.decisionNodeType = t
          this.params.decisionNodeParams = d
          break
        }
      } catch (err) {
        logger.error(err)
      }

      if (!this.children[0]) {
        this.children.push(new DecisionTreeEntry())
      }
      if (!this.children[1]) {
        this.children.push(new DecisionTreeEntry())
      }
    } else {
      this.name = e
      this.params.strategyId = e
    }
  }.bind(this)

  if (entry) {
    this.init(entry)
  }
}

export function uniqueEnergyServiceNamesFromEnergyServices(energyServices) {
  if (!energyServices) return []

  const energyServiceNames = []

  for (const energyService of energyServices) {
    for (const serviceName of Object.keys(energyService)) {
      if (energyService[serviceName]) {
        energyServiceNames.push(serviceName)
        break
      }
    }
  }

  return uniq(energyServiceNames)
}

/**
 *
 * @function
 *
 * @param {object} actuatorGroup ems.scontroller.ActuatorStrategies.ActuatorGroup
 *
 * @return {string} ParametrizationType (any oneof or 'none')
 */
export function determineActuatorGroupParametrizationType(actuatorGroup) {
  for (const key in actuatorGroup) {
    if (key !== 'setpointSourcesList' && actuatorGroup[key]) {
      if (key === 'pb_switch') {
        return 'switch'
      }
      return key
    }
  }

  return 'none'
}
