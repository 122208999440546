<template>
  <div>
    <EsDecisionTreeEntry
      v-if="hasDecisionTree"
      :key="`entry-${parsedDecisionTree.idx}`"
      :entry="parsedDecisionTree"
      :decision-node-types="decisionNodeTypes"
    />
    <ConfirmationModal
      :visible.sync="showRemoveDecisionTreeConfirm"
      color="danger"
      size=""
      :title="$t('ems.energyService.config.decisionTree.remove.title')"
      @update:confirmation="onRemoveDecisionTree({ confirmed: $event })"
    >
      {{ $t('ems.energyService.config.decisionTree.remove.description') }}
    </ConfirmationModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ConfirmationModal from '@/components/snippets/confirmation-modal'
import EsDecisionTreeEntry from './es-decision-tree-entry'

import { DecisionTree as DecisionTreePb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import { EMS_DECISION_TREE_NODE_DESCRIPTION } from '@/grpc/protobuf/ems-energy-service-helper'
import { EMS_ENERGY_SERVICE_DECISION_TYPES } from '@/grpc/protobuf/ems-scontroller-helper'
import { eventBus } from '@/view-helper/event-bus'

export default {
  name: 'EsDecisionTree',
  components: {
    ConfirmationModal,
    EsDecisionTreeEntry
  },
  data() {
    return {
      showRemoveDecisionTreeConfirm: false
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', [
      'decisionTreeNodeIdxs',
      'decisionTreeLeafIdxs',
      'hasDecisionTree',
      'parsedDecisionTree'
    ])
  },
  created() {
    this.decisionNodeTypes = EMS_ENERGY_SERVICE_DECISION_TYPES

    eventBus.$on('update:decision-tree-node-params', this.onUpdateNodeParams)
    eventBus.$on('add:decision-tree-node-option', this.onAddNodeOption)
    eventBus.$on('remove:decision-tree-node-option', this.onRemoveNodeOption)
    eventBus.$on('update:decision-tree-node-option-fallback', this.onUpdateNodeOptionFallback)
    eventBus.$on('update:decision-tree-leaf-params', this.onUpdateLeafParams)
    eventBus.$on('toggle:decision-tree-entry', this.onToggleEntry)
    eventBus.$on('remove:decision-tree', this.onRemoveDecisionTree)
  },
  destroyed() {
    eventBus.$off('update:decision-tree-node-params')
    eventBus.$off('add:decision-tree-node-option')
    eventBus.$off('remove:decision-tree-node-option')
    eventBus.$on('update:decision-tree-node-option-fallback', this.onUpdateNodeOptionFallback)
    eventBus.$off('update:decision-tree-leaf-params')
    eventBus.$off('toggle:decision-tree-entry')
    eventBus.$off('remove:decision-tree')
  },
  methods: {
    newNodeParams({ decisionNodeType, params }) {
      const fieldIdx = EMS_DECISION_TREE_NODE_DESCRIPTION.fields[decisionNodeType].id
      const initVal = new Array(fieldIdx)
      initVal[fieldIdx - 1] = []
      const nodeParams = new DecisionTreePb.Node(initVal).toObject()
      Object.assign(nodeParams[decisionNodeType], params)

      return nodeParams
    },
    onUpdateNodeParams({ idx, decisionNodeType, params }) {
      if (!this.decisionTreeNodeIdxs.some((i) => i === idx)) {
        this.$store.dispatch('emsEnergyServicesConfig/initNode', idx)
        this.$log.debug('Dispatched init Node at index', idx)
      }

      const nodeParams = this.newNodeParams({ decisionNodeType, params })
      if (nodeParams.greaterThanZero) {
        this.$store.dispatch('emsEvalExpression/copyEvalExpressionToDecisionTree', {
          decisionTreeIdx: idx
        })
      } else if (nodeParams.valueHysteresis) {
        this.$store.dispatch('emsEvalExpression/copyEvalExpressionToDecisionTreeVH', {
          decisionTreeIdx: idx,
          tolerance: params.tolerance
        })
      } else if (nodeParams.socGreaterThan) {
        this.$store.dispatch('emsEvalExpression/copyEvalExpressionToDecisionTreeSoC', {
          decisionTreeIdx: idx,
          params: params
        })
      } else {
        this.$store.commit('emsEnergyServicesConfig/UPDATE_DECISION_NODE_PARAMS', {
          idx,
          nodeParams
        })
      }
      this.$log.debug('Commited UPDATE_DECISION_NODE_PARAMS: ', nodeParams)
    },
    onAddNodeOption({ idx, type, value }) {
      this.$store.commit('emsEnergyServicesConfig/ADD_DECISION_NODE_OPTION', {
        idx,
        type,
        value
      })

      this.$log.debug('Commited ADD_DECISION_NODE_OPTION: ', idx, type, value)
    },
    onRemoveNodeOption({ idx, optionType }) {
      this.$store.commit('emsEnergyServicesConfig/REMOVE_DECISION_NODE_OPTION', {
        idx,
        optionType
      })

      this.$log.debug('Commited REMOVE_DECISION_NODE_OPTION', idx, optionType)
    },
    onUpdateNodeOptionFallback({ idx, fallback }) {
      this.$store.commit('emsEnergyServicesConfig/UPDATE_DECISION_NODE_OPTION_FALLBACK', {
        idx,
        fallback
      })

      this.$log.debug('Commited UPDATE_DECISION_NODE_OPTION_FALLBACK')
    },
    onUpdateLeafParams({ idx, strategyId }) {
      this.$store.commit('emsEnergyServicesConfig/UPDATE_DECISION_LEAF_PARAMS', {
        idx: idx,
        strategyId: strategyId
      })
      this.$log.debug('Commited UPDATE_DECISION_LEAF_PARAMS:', idx, strategyId)
    },
    onToggleEntry({ idx, toggleToNode }) {
      if (toggleToNode) {
        this.$store.dispatch('emsEnergyServicesConfig/initNode', idx)
      } else {
        this.$store.dispatch('emsEnergyServicesConfig/initLeaf', idx)
      }
      this.$log.debug('Dispatched init Node/Leaf at index', idx)
    },
    onRemoveDecisionTree({ confirmed, displayConfirm } = {}) {
      if (displayConfirm) {
        this.showRemoveDecisionTreeConfirm = true
      }
      if (!confirmed) {
        return
      }
      this.$store.commit('emsEnergyServicesConfig/CLEAR_DECISION_TREE')
      this.$log.debug('Commited CLEAR_DECISION_TREE')
    }
  }
}
</script>
