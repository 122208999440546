<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm @submit.prevent>
      <CRow form>
        <CCol tag="label" sm="5" class="custom-form-label d-flex' align-items-start" :for="safeId">
          {{ $t(`${tBasePath}.positionInTopology.label`) }}
        </CCol>
        <CCol xs="7" class="pl-1 d-none d-sm-block">
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol tag="label" sm="5" class="align-items-center text-muted d-none d-sm-block" :for="safeId">
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <CCol xs="7" class="pl-1 d-block d-sm-none">
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol tag="label" xs="5" class="align-items-center text-muted d-block d-sm-none" :for="safeId">
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <CCol v-if="!isGrid" sm="7" class="offset-sm-5">
          <TreeSelect
            :id="safeId"
            :value="positionInTopologySelected"
            disable-branch-nodes
            multiple
            :placeholder="$t(`${tBasePath}.positionInTopology.placeholder`)"
            :options="positionInTopologyOpts"
            @input="onUpdatePositionInTopology"
          />
        </CCol>
        <CCol v-if="!isGrid" tag="label" xs="7" class="offset-xs-0 offset-sm-5 pl-1">
          <small class="text-muted form-text w-100">
            {{ posInTopoDescription }}
          </small>
        </CCol>
      </CRow>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeUid } from '@coreui/utils/src'
import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import {
  generatePhysicalDeviceOptsForTreeSelect,
  getPhysicalDevicePreselection,
  isGridEnabled,
  makePhysicalDevicesList
} from '@/view-helper/ems/ems-topology-helper'
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'EsPvCurtailmentForm',
  components: {
    EsLayoutForm,
    TreeSelect
  },
  props,
  data() {
    return {
      positionInTopologySelected: [],
      isGrid: null,
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['existingPhysicalDevices']),
    positionInTopologyOpts() {
      return generatePhysicalDeviceOptsForTreeSelect(this.existingPhysicalDevices)
    },
    safeId() {
      return makeUid()
    },
    posInTopoDescription() {
      return this.$t(`${this.tBasePath}.positionInTopology.description`)
    }
  },
  created() {
    this.init()
    this.tBasePath = 'ems.energyService.config.form.pvCurtailment'
  },
  methods: {
    init() {
      this.switchLabelIcon = {
        labelOn: '\u2713',
        labelOff: '\u2715'
      }
      const pit = this.initValues.radioRippleControlReceiver
      this.isGrid = isGridEnabled(pit)

      if (pit && pit.physicalDevicesList) {
        this.positionInTopologySelected = getPhysicalDevicePreselection(pit.physicalDevicesList)
      }
    },
    onSubmit() {
      const physicalDevicesList = makePhysicalDevicesList(this.positionInTopologySelected, this.isGrid)
      const esParams = { radioRippleControlReceiver: { physicalDevicesList } }

      this.$emit('submit', {
        esParams
      })
    },
    onUpdatePositionInTopology(selection) {
      this.positionInTopologySelected = selection
    }
  }
}
</script>
