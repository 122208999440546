<template>
  <FwUpgradeViewLayout
    :do-confirm="doConfirm"
    :confirm-title="$t('fwupgrade.action.startUpgrade.confirm')"
    @action:confirmation="onStartUpgrade"
  >
    <CRow>
      <CCol sm="12" md="6">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.currentVersion') }}
        </p>
        <p class="font-italic">
          {{ currentVersion }}
        </p>
      </CCol>
      <!-- Available -->
      <CCol v-if="upgradeAvailable" sm="12" md="6">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.availableVersion') }}
        </p>
        <p class="font-italic">
          {{ availableVersion }}
        </p>
        <p>
          {{
            $t('fwupgrade.status.upgradeAvailable.description', {
              energyManager: brandName,
              newVersion: availableVersion
            })
          }}
        </p>
      </CCol>
      <!-- UpToDate -->
      <CCol v-if="!upgradeAvailable && !upgradeForbidden" sm="12" md="6">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.upToDate.title', { energyManager: brandName }) }}
        </p>
        <p>
          {{ $t('fwupgrade.status.upToDate.description', { energyManager: brandName, currentVersion }) }}
        </p>
      </CCol>
      <!-- Forbidden -->
      <CCol v-if="!upgradeAvailable && upgradeForbidden" sm="12" md="6">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.upgradeForbidden.title', { energyManager: brandName }) }}
        </p>
        <p>
          {{ $t('fwupgrade.status.upgradeForbidden.description', { energyManager: brandName }) }}
        </p>
        <p>
          {{ $t('fwupgrade.status.upgradeForbidden.reasonLabel') }}:
          <!-- prettier-ignore -->
          <br>
          <span class="font-italic">{{ forbiddenReason }}</span>
        </p>
      </CCol>
    </CRow>
    <template #actions>
      <!-- prettier-ignore -->
      <CButton
        class="mx-1 mx-md-3 mx-lg-4"
        size="sm"
        color="primary"
        :disabled="loading"
        @click="onCheckForUpgrade"
      >
        {{ $t('fwupgrade.action.checkForLatestUpgrade.btn') }}
      </CButton>
      <CButton
        class="mx-1 mx-md-3 mx-lg-4"
        size="sm"
        color="danger"
        :disabled="loading || !upgradeAvailable"
        @click="onStartingUpgrade"
      >
        <CIcon size="sm" name="cil-level-up" />
        <span> {{ $t('fwupgrade.action.startUpgrade.btn') }} </span>
      </CButton>
    </template>
    <template #confirmation-body>
      <p>
        {{
          $t('fwupgrade.action.startUpgrade.description', {
            energyManager: brandName,
            newVersion: availableVersion,
            oldVersion: currentVersion
          })
        }}
      </p>
      <div class="text-danger mt-1 mt-md-3 mt-lg-4">
        <p class="font-weight-bold">
          <CIcon size="sm" name="cil-warning" class="mb-1 mx-1" />
          <span class="mt-1"> {{ $t('fwupgrade.warning') }} </span>
        </p>
        <p>
          {{ $t('fwupgrade.action.startUpgrade.warningOutOfOrder', { energyManager: brandName }) }}
        </p>
      </div>
    </template>
  </FwUpgradeViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import FwUpgradeViewLayout from '@/components/fw-upgrade/fw-upgrade-view-layout'

import { buildI18nErrorMsg } from '@/view-helper/fw-upgrade/fw-upgrade-error'
import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'FwUpgradeStatus',
  components: {
    FwUpgradeViewLayout
  },
  data() {
    return {
      doConfirm: false
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('fwupgrader', [
      'fwUpgraderState',
      'currentVersion',
      'availableVersion',
      'upgradeAvailable',
      'upgradeForbidden',
      'forbiddenReason'
    ])
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
  },
  mounted() {
    this.initCurrentState().then(() => this.onCheckForUpgrade())
  },
  methods: {
    async initCurrentState() {
      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch('fwupgrader/reloadCurrentState')
        this.$log.debug('Succesfully reloaded current fw-upgrader state.')
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t('fwupgrade.error.checkForLatestUpgrade.title'),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    async onCheckForUpgrade() {
      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch('fwupgrader/checkForLatestUpgrade')
        this.$log.debug('Succesfully checked for latest fw-upgrade.')
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t('fwupgrade.error.checkForLatestUpgrade.title'),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    onStartingUpgrade() {
      this.doConfirm = true
    },
    async onStartUpgrade(confirmed) {
      this.doConfirm = false
      if (!confirmed) {
        return
      }

      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch('fwupgrader/startUpgrade')
        this.$log.info('Succesfully started fw-upgrade.')
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t('fwupgrade.error.startUpgrade.title'),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    }
  }
}
</script>
