<template>
  <div class="py-2">
    <!-- BEGIN: Trivial Eval Tree -->
    <p v-if="isSwitch" class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.previewLabelTrivialTree`) }}
      </small>
      <span v-if="isSwitchOn" class="ml-n4 text-primary">
        {{ $t('main.on') }}
      </span>
      <span v-else class="ml-n4 text-primary">
        {{ $t('main.off') }}
      </span>
    </p>
    <!-- END: Trivial Eval Tree  -->
    <!-- BEGIN: Non-Trivial Eval Tree -->
    <p v-else class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary pt-2">
        {{ $t(`${tBasePath}.previewLabelNonTrivialTree`) }}
      </small>
      <CButton color="primary" size="sm" variant="outline" @click="onShowEvalTreeModal">
        <CIcon name="cis-eye" size="sm" />
        <span class="align-middle">
          {{ $t('main.showBtn') }}
        </span>
      </CButton>
    </p>
    <!-- END: Non-Trivial Eval Tree  -->
    <!-- BEGIN: Eval Tree Preview Modal -->
    <CModal
      v-if="!isSwitch"
      :show.sync="showEvalTreeModal"
      color="primary"
      size="xl"
      :title="$t(`${tBasePath}.previewLabelNonTrivialTree`)"
    >
      <EvalTree v-if="inited" preview />
      <template #footer>
        <div class="d-flex justify-content-end">
          <CButton class="mx-1" type="button" color="secondary" @click="showEvalTreeModal = !showEvalTreeModal">
            {{ $t('main.closeBtn') }}
          </CButton>
        </div>
      </template>
    </CModal>
    <!-- END: Eval Tree Preview Modal -->
  </div>
</template>

<script>
import EvalTree from '@/components/ems/evalexp/eval-tree'
import { iterateEvalTree } from '@/grpc/protobuf/ems-eval-expression-helper'

export default {
  name: 'EsPreviewEvalExpression',
  components: {
    EvalTree
  },
  props: {
    strategyId: {
      required: true,
      type: String
    },
    actuatorGroupId: {
      required: true,
      type: String
    },
    evalExpression: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      inited: false,
      showEvalTreeModal: false,
      switchLabelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      tBasePath: 'ems.energyService.config.form.evalExpression'
    }
  },
  computed: {
    isSwitch() {
      const parsedEvalTree = iterateEvalTree(this.evalExpression)

      return parsedEvalTree.operator === 'value' && [0, 1].includes(parsedEvalTree.value)
    },
    isSwitchOn() {
      if (!this.isSwitch) {
        return null
      }

      return !!this.evalExpression.value
    }
  },
  watch: {
    strategyId() {
      this.init()
    },
    actuatorGroupId() {
      this.init()
    }
  },
  async mounted() {
    await this.$store
      .dispatch('emsSensors/sensorInit', {})
      .then(() => {
        this.$log.debug('Succesfully fetched sensor names.')
      })
      .catch((err) => {
        this.$log.error(err)
      })

    await this.$store
      .dispatch('emsTimeseries/timeseriesesInit', {
        withTimestampValues: false
      })
      .then(() => {
        this.$log.debug('Succesfully fetched timeseries names.')
      })
      .catch((err) => {
        this.$log.error(err)
      })
    await this.init()
    this.inited = true
  },
  methods: {
    async init() {
      await this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromStrategy', {
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId
      })
    },
    onShowEvalTreeModal() {
      this.init()
      this.showEvalTreeModal = true
    }
  }
}
</script>
