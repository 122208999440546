<template>
  <CButton size="sm" color="primary">
    <!-- NOTE: We need the label in order for the file chooser to work and give it a button style. -->
    <label class="p-0 m-0 pointer">
      <!-- prettier-ignore -->
      <input accept="text/plain" type="file" @change="onUpload" >
      <CIcon size="sm" name="cil-cloud-upload" />
      <span class="align-middle d-none d-sm-inline">
        {{ $t('main.uploadBtn') }}
      </span>
    </label>
  </CButton>
</template>

<script>
export default {
  name: 'LicenseUploadBtn',
  methods: {
    onUpload(e) {
      const licenseFile = e.target.files[0]
      if (!licenseFile) {
        return
      }
      if (licenseFile.type !== 'text/plain') {
        this.$emit('upload:error', 'The choosen file is not a valid license file or does not match the correct format.')
        return
      }
      this.$log.debug('A license file has been succesfully choosen: ', licenseFile.name)

      const reader = new FileReader()
      reader.readAsText(licenseFile)
      reader.onload = (e) => {
        this.$emit('upload:success', e.target.result.trim())
      }
      reader.onerror = (err) => {
        this.$emit('upload:error', err)
      }
    }
  }
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}

.pointer {
  cursor: pointer;
}
</style>
