<template>
  <div>
    <template v-if="ldn">
      <!-- toggler expert mode -->
      <div class="card-header-actions mb-2 mr-n4" :style="{ cursor: 'pointer' }">
        <span class="card-header-action small" @click="showExpertMode = !showExpertMode">
          {{ $t('ems.topology.expertMode') }}
          <i class="topo-config-header-icon">
            <CIcon class="pb-1" :name="`cil-chevron-${showExpertMode ? 'bottom' : 'top'}`" size="sm" />
          </i>
        </span>
      </div>
      <EmsEditTopoConfigDevSelect
        v-if="!showExpertMode"
        :label="ldn"
        :value="selectedValue"
        @update:value="onUpdateSrcToDevTopoForLD"
      />
      <CCollapse class="pt-4" :show="showExpertMode" :duration="400">
        <template v-for="(src, i) of sources">
          <!-- prettier-ignore -->
          <hr v-if="i !== 0 && src.iec.iecNodePrefix" :key="i" class="mt-4" style="width: 60%;">
          <EmsEditTopoConfigGenericSrc v-if="src.iec.iecNodePrefix" :key="`generic-src-${i}`" :iec-id="src.iec" />
        </template>
      </CCollapse>
    </template>
    <template v-else-if="iecId">
      <EmsEditTopoConfigDevSelect
        v-if="showDevSelect"
        :label="iecIdToLiteral(iecId)"
        :value="selectedValue"
        @update:value="onUpdateSrcToDevTopo"
      />
      <EmsEditTopoConfigDevSelectLabel
        v-if="showInternalMtr && !showDevSelect"
        tag="div"
        :label="iecIdToLiteral(iecId)"
        class="mb-2"
      />
      <div v-if="showInternalMtr">
        <CInputCheckbox
          :disabled="missingMtrCounter && !useInternalMtr"
          class="ml-md-1 mt-1 mb-3"
          :checked="useInternalMtr"
          :label="$t('ems.topology.form.internalMeter.label')"
          @update:checked="onToggleInternalMtr"
        />
        <CAlert :color="useInternalMtr ? 'danger' : 'warning'" :show="missingMtrCounter">
          {{ $t('ems.topology.alerts.mtrMissingDmdSup') }}
        </CAlert>
        <EmsEditTopoConfigPureMtr
          v-if="useInternalMtr"
          class="px-1 px-md-3 px-lg-4"
          :iec-id="iecId"
          :init-phys-dev-literals="selectedValue ? [selectedValue] : undefined"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmsEditTopoConfigDevSelect from '@/components/ems/topology/ems-edit-topo-config-dev-select'
import EmsEditTopoConfigDevSelectLabel from '@/components/ems/topology/ems-edit-topo-config-dev-select-label'
import EmsEditTopoConfigPureMtr from '@/components/ems/topology/ems-edit-topo-config-pure-mtr'
import { iecIdToLiteral, physicalDeviceToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { validateIecId } from '@/view-helper/props-validation/iec-id-props-validation'

export default {
  name: 'EmsEditTopoConfigGenericSrc',
  components: {
    EmsEditTopoConfigDevSelect,
    EmsEditTopoConfigDevSelectLabel,
    EmsEditTopoConfigPureMtr
  },
  props: {
    ldn: {
      type: String,
      required: false,
      default: null
    },
    iecId: {
      type: Object,
      required: false,
      validator: validateIecId,
      default: null
    }
  },
  data() {
    return {
      touched: false,
      showDevSelect: true,
      showInternalMtr: false,
      showExpertMode: false,
      useInternalMtr: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', [
      'hasPhysicalDevices',
      'getSources',
      'getPhysicalDeviceOfSourceToDeviceTopology',
      'getPhysicalDevicesOfPhaseTopology',
      'isGridMtr',
      'hasAnyMtrCounter',
      'hasSimpleConfig',
      'hasQuantity'
    ]),
    missingMtrCounter() {
      return !this.hasAnyMtrCounter(this.iecId)
    },
    selectedValue() {
      let iecId
      if (this.ldn) {
        this.getSources(this.ldn).forEach((src) => {
          if (this.hasQuantity({ iecId: src.iec, quantity: '_ANY_SRC_DEVICE_TOPO' })) {
            iecId = src.iec
          }
        })
      }
      if (this.iecId) {
        iecId = this.iecId
      }
      if (!iecId) {
        return null
      }

      const pd = this.getPhysicalDeviceOfSourceToDeviceTopology(iecId)
      if (!pd) {
        return null
      }

      return physicalDeviceToLiteral(pd)
    },
    sources() {
      if (!this.ldn) {
        return []
      }

      return this.getSources(this.ldn)
    }
  },
  watch: {
    hasPhysicalDevices: function (e) {
      if (this.touched || !e) {
        return
      }

      // API topo called returned with non-trivial topo
      this.touched = true
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    iecIdToLiteral,
    init() {
      if (this.ldn) {
        this.showExpertMode = !this.hasSimpleConfig(this.ldn)
      }

      if (this.iecId) {
        this.showDevSelect = this.hasQuantity({ iecId: this.iecId, quantity: '_ANY_SRC_DEVICE_TOPO' })
        this.showInternalMtr = this.hasQuantity({ iecId: this.iecId, quantity: '_ANY_PHASE_TOPO' })
        this.useInternalMtr = !!this.getPhysicalDevicesOfPhaseTopology(this.iecId).length
      }
    },
    onUpdateSrcToDevTopoForLD(physDevLiteral) {
      if (!this.ldn) {
        this.$log.error('Triggered updateSrcToDevTopo for each source of a logical-device without "ldn".')
      }

      // cleanup before: otherwise e.g. `inv` might NOT be reseted
      this.$store.dispatch('emsTopologyConfig/cleanupTopo', this.ldn)

      // set SourceToDeviceTopology
      this.sources.forEach((src) => {
        // apply a filter here, if not all sources of a logical-device should be added
        const iecId = src.iec
        this.$store.dispatch('emsTopologyConfig/updateSrcToDevTopo', Object.assign({}, { iecId, physDevLiteral }))

        // Uncomment the line below,
        // if the 'internal meters' should be added to the phase topo by default:
        // this.$store.dispatch('emsTopologyConfig/updatePhaseTopo', { iecId, physDevLiterals: [physDevLiteral] })
      })
    },
    onUpdateSrcToDevTopo(physDevLiteral) {
      if (!this.iecId) {
        this.$log.error('Triggered updateSrcToDevTopo without "iecId".')
      }

      this.$store.dispatch(
        'emsTopologyConfig/updateSrcToDevTopo',
        Object.assign({}, { iecId: this.iecId, physDevLiteral })
      )
    },
    onToggleInternalMtr(checked) {
      this.useInternalMtr = checked

      if (checked) {
        return
      }

      // remove src from Phase topo if unchecked
      this.$store.dispatch(
        'emsTopologyConfig/updatePhaseTopo',
        Object.assign(
          {},
          {
            iecId: this.iecId,
            physDevLiterals: []
          }
        )
      )
      if (this.isGridMtr(this.iecId)) {
        this.$store.commit('emsTopologyConfig/CLEAR_GRID_MTR_IECID')
      }
    }
  }
}
</script>
