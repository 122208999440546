/**
 *
 * Stores the state of device(s) of one device type, e.g. METER.
 * Implements the getters/mutations to change this state.
 *
 * A device is characterized by:
 *  - a device type ([SUPPORTED_DEVICES]{@link module:store/devices.SUPPORTED_DEVICES})
 *  - a list of available drivers
 *  - a list of supported device-models (included in drivers)
 *  - a specific deviceModel (optional)
 *  - its config
 *  - its status (currently provided by the EnergyManager)
 *
 * @module store/device-config
 */

import Vue from 'vue'
import _ from 'lodash'
import * as DevicePb from '@/../lib/proto_js/amperix/device_noopt_pb'

import logger from '@/logger'
import { SUPPORTED_DEVICES } from './devices'
import { processDeviceConfigMsg } from '@/grpc/protobuf/device-config-helper'
import { DeviceModel, DeviceConfigInfo, DeviceStatus } from '@/store/modules/_device-structs'
import * as actions from '@/store/modules/_device-config-actions'

/**
 * The device-config state, allows to store:
 *
 * - [deviceType]{@link module:store/devices.SUPPORTED_DEVICES} which defines the Type of the device, e.g. `METER`, `INVERTER`, ...
 *
 *
 * - `drivers` which are a list of available device-drivers of the Apx, see `Device.DeviceDriver`` proto message. A driver has to have a `driver-id`, a config-(sub)-type with `config-type-id`, a list of supported device-models, and a (default empty) config (see Device.DeviceConfig proto message).
 *
 * - `configs` which are a list of actually configured devices of the Apx (of one device-type). Each config is a Device.DeviceConfig proto message JS-object. A config has to have a config-ID and a specific config (a oneof).
 *
 * - 'statuses' which are a map of `ldn` to device status for all successfully configured devices, received from the BE. Each status is a [DeviceStatus]{@link module:store/device.DeviceStatus}.
 *
 * @function
 *
 * @return {object}
 */
const state = () => {
  return {
    deviceType: null,
    drivers: [],
    configs: [],
    statuses: {}
  }
}

/**
 * Getters for device-config.
 * Allow to compute properties base on the current state.
 *
 *
 * @property {array} deviceModels is the list of available device-models, which are allowed to be configured. This list is extracted from the `state.drivers`. Each device-model is a [DeviceModel]{@link module:store/device.DeviceModel}.
 *
 * @property {function} getNewDeviceModel allows to select on device-model out of the list of device-models, by its ID. Calling the function returns a [DeviceModel]{@link module:store/device.DeviceModel}.
 *
 * @property {array} devCfgInfos is the list of device config meta-data of the currently configured devices. Each device-config is a [DeviceConfigInfo]{@link module:store/device.DeviceConfigInfo}.
 *
 * @property {array} serviceableDevCfgInfos is a list of DeviceConfigInfo. The corresponding Device config is serviceable. Serviceable means, that the Amperix Satellite BE has started successfully.
 *
 * @property {array} unserviceableDevCfgInfos is a list of DeviceConfigInfo. The corresponding Device config is unserviceable. Unserviceable means, that the Amperix Satellite BE had failed to start.
 *
 * @property {function} getDevCfgInfo allows to get one DeviceConfigInfo by config-ID.
 *
 * @property {function} getDeviceConfig allows to get one DevicePb.DeviceConfig by config-ID.
 *
 * @property {function} getDeviceStatus allows to get one DeviceStatus by config-ID.
 *
 */
const getters = {
  deviceType: (state) => state.deviceType,
  drivers: (state) => state.drivers,
  configs: (state) => state.configs,
  statuses: (state) => state.statuses,
  deviceModels: (state) => {
    const models = []
    let id = 0
    state.drivers.forEach((driver) => {
      driver.configTypesList.forEach((dct) => {
        const dc = dct.config // is a DevicePb.DeviceConfig parsed toObject()
        dct.modelsList.forEach((deviceModel) => {
          models.push(
            new DeviceModel({
              ...processDeviceConfigMsg(dc),
              id,
              name: deviceModel.name,
              driverId: driver.id, // important: has to overwrite driverId of processDeviceConfigMsg
              driverAlias: driver.alias,
              configTypeAlias: dct.alias
            })
          )
          id++
        })
      })
    })

    return models
  },
  getNewDeviceModel: function (state, getters) {
    return (modelId) => {
      const model = getters.deviceModels.find((model) => model.id === modelId)
      if (model) {
        return Object.assign({}, model)
      } else {
        return null
      }
    }
  },
  driverIds: function (state) {
    const ids = []
    state.drivers.forEach((driver) => {
      if (!ids.includes(driver.id)) {
        ids.push(driver.id)
      }
    })

    return ids
  },
  devCfgInfos: function (state, getters) {
    const models = getters.deviceModels
    const configs = []
    state.configs.forEach((configMsg) => {
      const configData = processDeviceConfigMsg(configMsg)

      const modelData = {}
      const modelsWithSameDriverId = models.filter((m) => m.driverId === configData.driverId)
      // extract driver and model meta-data
      if (modelsWithSameDriverId.length === 0) {
        logger.warn(
          `Received config "${configData.configId}" with Driver-ID "${configData.driverId}" for which no DeviceModel was found.`
        )
      } else {
        Object.assign(modelData, modelsWithSameDriverId[0])
        // prettier-ignore
        modelData.modelIds = modelsWithSameDriverId.map((m) => m.id)
        modelData.modelNames = modelsWithSameDriverId.map((m) => m.name)
      }
      Object.keys(configData).forEach((k) => delete modelData[k])

      configs.push(
        new DeviceConfigInfo({
          ...configData,
          ...modelData
        })
      )
    })

    return configs
  },
  serviceableDevCfgInfos: function (state, getters) {
    return getters.devCfgInfos.filter((c) => {
      return getters.getDeviceStatusByConfigId(c.configId).health !== 'FATAL'
    })
  },
  unserviceableDevCfgInfos: function (state, getters) {
    return getters.devCfgInfos.filter((c) => {
      return getters.getDeviceStatusByConfigId(c.configId).health === 'FATAL'
    })
  },
  getDevCfgInfo: function (state, getters) {
    return (configId) => {
      const config = getters.devCfgInfos.find((c) => c.configId === configId)
      if (config) {
        return Object.assign({}, config)
      } else {
        return null
      }
    }
  },
  getDeviceConfig: function (state) {
    return (configId) => {
      return state.configs.find((c) => (c.id ? c.id.id === configId : false)) || null
    }
  },
  getDeviceStatus: function (state) {
    return (ldn, empty = false) => {
      const status = state.statuses[ldn]
      if (status) {
        return Object.assign({}, status)
      } else if (empty) {
        return new DeviceStatus({})
      } else {
        return new DeviceStatus({ configId: '', health: 'FATAL', info: { name: ldn } })
      }
    }
  },
  getDeviceStatusByConfigId: function (state) {
    return (configId, empty = false) => {
      let status = null
      for (const ldn in state.statuses) {
        if (state.statuses[ldn].configId === configId) {
          status = state.statuses[ldn]
        }
      }

      if (status) {
        return Object.assign({}, status)
      } else if (empty) {
        return new DeviceStatus({})
      } else {
        return new DeviceStatus({
          configId,
          health: 'FATAL'
        })
      }
    }
  },
  omiInverters: (state, getters) => {
    const omiDrivers = ['de.mypowergrid.config.inverter.BackendCfg.gridcon']

    return getters.deviceModels.filter((deviceModel) => omiDrivers.includes(deviceModel.driverId))
  }
}

const mutations = {
  SET_DEVICE_TYPE(state, deviceType) {
    if (!deviceType) {
      deviceType = 'UNSPECIFIED'
    }
    if (!SUPPORTED_DEVICES.includes(deviceType)) {
      throw new TypeError(`The device ${deviceType} is not supported.`)
    }

    state.deviceType = deviceType
  },
  ADD_DRIVER(state, driver) {
    // prettier-ignore
    if (state.deviceType &&
        state.deviceType !== 'UNSPECIFIED' &&
        DevicePb.DeviceType[state.deviceType] !== driver.deviceType) {
      throw new Error(
        `Adding a new driver ${driver.alias} for device-type ${driver.deviceType} rejected, since the store is for ${state.deviceType}.`
      )
    }

    if (_.isEmpty(driver.configTypesList)) {
      logger.warn('Adding a device driver without config-types.')
    }

    driver.configTypesList.forEach((ct) => {
      if (ct.config) {
        return
      }
      logger.warn(`Adding a device driver with config-type ${ct} having a missing config.`)
      ct.config = new DevicePb.DeviceConfig()
    })
    state.drivers.push(driver)
  },
  FILTER_DRIVERS(state, driverIds = []) {
    state.drivers = state.drivers.filter((d) => driverIds.includes(d.id))
  },
  CLEAR_DRIVERS(state) {
    state.drivers = []
  },
  ADD_CONFIG(state, config) {
    const configId = config.id ? config.id.id : null
    if (!configId || typeof configId !== 'string') {
      logger.warn('Adding a device config without or invalid ID is NOT allowed. Will ignore.')
      return
    }
    const i = state.configs.findIndex((c) => {
      return c.id.id === configId
    })
    if (i > -1) {
      logger.warn('Tried to add a config which already exists. Will replace.')
      state.configs.splice(i, 1, config)
      return
    }

    state.configs.push(config)
  },
  CLEAR_CONFIGS(state) {
    state.configs = []
  },
  ADD_STATUS(state, status) {
    if (!status.configId) {
      logger.warn(`Adding a device config status '${status}' without configId. This is not supported.`)
    } else if (!status.name) {
      logger.warn(`Adding a device config status '${status}' without a name. This is not supported.`)
      return
    }
    Vue.set(state.statuses, status.name, status)
  },
  CLEAR_STATUSES(state) {
    state.statuses = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
