<template>
  <div>
    <CToaster position="top-right">
      <template v-for="msg in toasterMsgs">
        <CToast
          :key="msg.id"
          :header="msg.title"
          :show="true"
          :autohide="msg.autohide === undefined ? 5000 : msg.autohide"
          :class="castTypeToClass(msg.type)"
          @update:show="onUpdateShow(msg.id, $event)"
        >
          <div v-if="msg.content">
            <span v-if="Array.isArray(msg.content)">
              <p v-for="(c, i) in msg.content" :key="`content-${i}`">
                {{ c }}
              </p>
            </span>
            <span v-else>
              {{ msg.content }}
            </span>
          </div>
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApxToaster',
  computed: {
    ...mapGetters('notifications', ['toasterMsgs'])
  },
  methods: {
    castTypeToClass(type) {
      if (!type) {
        return
      }

      if (/^toast-\w+$/.test(type)) {
        return type
      } else {
        return `toast-${type}`
      }
    },
    onUpdateShow(id, show) {
      if (!show) {
        this.$store.commit('notifications/DELETE_TOAST', id)
      }
    }
  }
}
</script>

<style scoped>
.toaster .toast {
  width: 80%;
}
</style>
