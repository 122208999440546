/**
 * Stores the state of EMS Energy Service configuration provided by the EnergyManagementSystem.
 *
 * @module store/ems-energy-services-config
 */

import * as actions from '@/store/modules/_ems-energy-services-config-actions'
import * as getters from '@/store/modules/_ems-energy-services-config-getters'
import * as mutations from '@/store/modules/_ems-energy-services-config-mutations'

/**
 * The ems-energy_services-config store allows to store:
 *
 * NOTE:
 * VueJS v2 does NOT support reactivity on Map() data structures. This should/has changed for v3.
 * The current implementation assumes, that the Map() keys can be matched by a deep compare (which is always true for Strings).
 *
 * Data structure:
 *
 * `actuatorGroups` is a list of `ems.scontroller.ActuatorGroup`(s). The actuator-group ID is stored in `actuatorGroupIds` (same index).
 *
 * `strategies` is a list of lists. The first level corresponds to the strategy-ID index (IDs in `strategyIds`). The second level corresponds to the actuator-group-ID index. The entry is a `ems.scontroller.energyService` or if the actuator-group is not used by the strategy `null`.
 *
 * `strategyPreselections` is a list of lists. C.f. `strategies`. The entry is a string identifier for the energy-service preselection. Note: this cannot be computed, since the relation strategies to strategyPreselections is not injective.
 *
 * `{object} peakObservers` is an object of named peak-observers. Each key corresponds to a peak-observer name. Each value is a `ems.controller.PeakObserverState` (plain object)
 *
 * `{string} defaultStrategyId` is the EMS default strategy
 *
 * `{object} decisionTree` is the plain JS-proto Object of `ems.scontroller.DecisionTree`
 *
 * @function
 *
 * @return {object}
 */
const state = () => {
  return {
    actuatorGroups: [],
    actuatorGroupIds: [],
    strategies: [],
    strategyIds: [],
    strategyPreselections: [],
    suitableEnergyServices: [],
    setpointSourcesToActuatorGroups: {},
    peakObservers: {},
    defaultStrategyId: null,
    decisionTree: {},
    cache: {
      strategyIdToBeCloned: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
