// source: ext/ems/scontroller/scontroller.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_ems_data$objects_pb = require('../../../ext/ems/data-objects_pb.js');
goog.object.extend(proto, ext_ems_data$objects_pb);
var ext_ems_eval_pb = require('../../../ext/ems/eval_pb.js');
goog.object.extend(proto, ext_ems_eval_pb);
var ext_ems_topology_topology_pb = require('../../../ext/ems/topology/topology_pb.js');
goog.object.extend(proto, ext_ems_topology_topology_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var ext_options_field_pb = require('../../../ext/options/field_pb.js');
goog.object.extend(proto, ext_options_field_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorGroupData', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.CurrentLisCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.GridCurrentCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.PowerGridCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.Decision', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionExplaination', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.OperationCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.ActuatorGroupSetpointOnDisagreeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.ApiVersionCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.CheckCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.DecisionTree.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyService.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.EnergyServices', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.PositionInTopology', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.scontroller.StrategyDecision', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.PositionInTopology.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.PositionInTopology, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.PositionInTopology.displayName = 'proto.de.mypowergrid.ems.scontroller.PositionInTopology';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.repeatedFields_, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.repeatedFields_, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.displayName = 'proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.repeatedFields_, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.StrategyDecision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.StrategyDecision.displayName = 'proto.de.mypowergrid.ems.scontroller.StrategyDecision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.Decision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.Decision.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.Decision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.Decision.displayName = 'proto.de.mypowergrid.ems.scontroller.Decision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.displayName = 'proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.DecisionExplaination.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.DecisionExplaination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.DecisionExplaination.displayName = 'proto.de.mypowergrid.ems.scontroller.DecisionExplaination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.displayName = 'proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorGroupData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorGroupData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.displayName = 'proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.displayName = 'proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.displayName = 'proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.scontroller.EnergyServices.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.scontroller.EnergyServices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.scontroller.EnergyServices.displayName = 'proto.de.mypowergrid.ems.scontroller.EnergyServices';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.PositionInTopology.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.PositionInTopology} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.toObject = function(includeInstance, msg) {
  var f, obj = {
    physicalDevicesList: jspb.Message.toObjectList(msg.getPhysicalDevicesList(),
    ext_ems_topology_topology_pb.PhysicalDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.PositionInTopology}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.PositionInTopology;
  return proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.PositionInTopology} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.PositionInTopology}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.PhysicalDevice;
      reader.readMessage(value,ext_ems_topology_topology_pb.PhysicalDevice.deserializeBinaryFromReader);
      msg.addPhysicalDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.PositionInTopology.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.PositionInTopology} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhysicalDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      ext_ems_topology_topology_pb.PhysicalDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated de.mypowergrid.ems.topology.PhysicalDevice physical_devices = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.getPhysicalDevicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, ext_ems_topology_topology_pb.PhysicalDevice, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.PositionInTopology} returns this
*/
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.setPhysicalDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.addPhysicalDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.topology.PhysicalDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.PositionInTopology} returns this
 */
proto.de.mypowergrid.ems.scontroller.PositionInTopology.prototype.clearPhysicalDevicesList = function() {
  return this.setPhysicalDevicesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TypeCase = {
  TYPE_NOT_SET: 0,
  TARGET_POWER: 1,
  RECORDED_POWER_MEASUREMENT_PEAK_SHAVING: 2,
  GENERATION_PEAK_SHAVING: 3,
  OFF_GRID: 4,
  EVAL_EXPRESSION: 5,
  DIGITAL_OUTPUT_SWITCH_CONDITION: 6,
  PV_CURTAILMENT: 7,
  OFF_GRID_TARGET_SOC_GENERATION_CURTAILMENT: 8,
  OFF_GRID_BATTERY_PROTECTION: 9
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.EnergyService.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.EnergyService.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetPower: (f = msg.getTargetPower()) && proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.toObject(includeInstance, f),
    recordedPowerMeasurementPeakShaving: (f = msg.getRecordedPowerMeasurementPeakShaving()) && proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.toObject(includeInstance, f),
    generationPeakShaving: (f = msg.getGenerationPeakShaving()) && proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.toObject(includeInstance, f),
    offGrid: (f = msg.getOffGrid()) && proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.toObject(includeInstance, f),
    evalExpression: (f = msg.getEvalExpression()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    digitalOutputSwitchCondition: (f = msg.getDigitalOutputSwitchCondition()) && proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.toObject(includeInstance, f),
    pvCurtailment: (f = msg.getPvCurtailment()) && proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.toObject(includeInstance, f),
    offGridTargetSocGenerationCurtailment: (f = msg.getOffGridTargetSocGenerationCurtailment()) && proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.toObject(includeInstance, f),
    offGridBatteryProtection: (f = msg.getOffGridBatteryProtection()) && proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.deserializeBinaryFromReader);
      msg.setTargetPower(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.deserializeBinaryFromReader);
      msg.setRecordedPowerMeasurementPeakShaving(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.deserializeBinaryFromReader);
      msg.setGenerationPeakShaving(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.deserializeBinaryFromReader);
      msg.setOffGrid(value);
      break;
    case 5:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setEvalExpression(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.deserializeBinaryFromReader);
      msg.setDigitalOutputSwitchCondition(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.deserializeBinaryFromReader);
      msg.setPvCurtailment(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.deserializeBinaryFromReader);
      msg.setOffGridTargetSocGenerationCurtailment(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.deserializeBinaryFromReader);
      msg.setOffGridBatteryProtection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetPower();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.serializeBinaryToWriter
    );
  }
  f = message.getRecordedPowerMeasurementPeakShaving();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.serializeBinaryToWriter
    );
  }
  f = message.getGenerationPeakShaving();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.serializeBinaryToWriter
    );
  }
  f = message.getOffGrid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.serializeBinaryToWriter
    );
  }
  f = message.getEvalExpression();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getDigitalOutputSwitchCondition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.serializeBinaryToWriter
    );
  }
  f = message.getPvCurtailment();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.serializeBinaryToWriter
    );
  }
  f = message.getOffGridTargetSocGenerationCurtailment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.serializeBinaryToWriter
    );
  }
  f = message.getOffGridBatteryProtection();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_ = [[1,3]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.TypeCase = {
  TYPE_NOT_SET: 0,
  POWER_WATTS: 1,
  EVAL_WATTS: 3
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.toObject = function(includeInstance, msg) {
  var f, obj = {
    powerWatts: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    evalWatts: (f = msg.getEvalWatts()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    positionInTopology: (f = msg.getPositionInTopology()) && proto.de.mypowergrid.ems.scontroller.PositionInTopology.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPowerWatts(value);
      break;
    case 3:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setEvalWatts(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.PositionInTopology;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinaryFromReader);
      msg.setPositionInTopology(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getEvalWatts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getPositionInTopology();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.PositionInTopology.serializeBinaryToWriter
    );
  }
};


/**
 * optional double power_watts = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.getPowerWatts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.setPowerWatts = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.clearPowerWatts = function() {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.hasPowerWatts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression eval_watts = 3;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.getEvalWatts = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.setEvalWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.clearEvalWatts = function() {
  return this.setEvalWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.hasEvalWatts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PositionInTopology position_in_topology = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.PositionInTopology}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.getPositionInTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.PositionInTopology} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.PositionInTopology, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.PositionInTopology|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.setPositionInTopology = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.clearPositionInTopology = function() {
  return this.setPositionInTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower.prototype.hasPositionInTopology = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.toObject = function(includeInstance, msg) {
  var f, obj = {
    safetyMarginWatts: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSafetyMarginWatts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSafetyMarginWatts();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double safety_margin_watts = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.prototype.getSafetyMarginWatts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving.prototype.setSafetyMarginWatts = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.toObject = function(includeInstance, msg) {
  var f, obj = {
    batteryCapacityWattHours: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBatteryCapacityWattHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatteryCapacityWattHours();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double battery_capacity_watt_hours = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.prototype.getBatteryCapacityWattHours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving.prototype.setBatteryCapacityWattHours = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.TypeCase = {
  TYPE_NOT_SET: 0,
  POWER_AT_POSITION_IN_TOPOLOGY: 5
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    onThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    holdDelta: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    negate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    conditionWindowSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    powerAtPositionInTopology: (f = msg.getPowerAtPositionInTopology()) && proto.de.mypowergrid.ems.scontroller.PositionInTopology.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOnThreshold(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHoldDelta(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNegate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConditionWindowSeconds(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.scontroller.PositionInTopology;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinaryFromReader);
      msg.setPowerAtPositionInTopology(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getHoldDelta();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getNegate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getConditionWindowSeconds();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPowerAtPositionInTopology();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.scontroller.PositionInTopology.serializeBinaryToWriter
    );
  }
};


/**
 * optional double on_threshold = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getOnThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.setOnThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double hold_delta = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getHoldDelta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.setHoldDelta = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool negate = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getNegate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.setNegate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional double condition_window_seconds = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getConditionWindowSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.setConditionWindowSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional PositionInTopology power_at_position_in_topology = 5;
 * @return {?proto.de.mypowergrid.ems.scontroller.PositionInTopology}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.getPowerAtPositionInTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.PositionInTopology} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.PositionInTopology, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.PositionInTopology|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.setPowerAtPositionInTopology = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.clearPowerAtPositionInTopology = function() {
  return this.setPowerAtPositionInTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition.prototype.hasPowerAtPositionInTopology = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.toObject = function(includeInstance, msg) {
  var f, obj = {
    radioRippleControlReceiver: (f = msg.getRadioRippleControlReceiver()) && proto.de.mypowergrid.ems.scontroller.PositionInTopology.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.PositionInTopology;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.PositionInTopology.deserializeBinaryFromReader);
      msg.setRadioRippleControlReceiver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRadioRippleControlReceiver();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.PositionInTopology.serializeBinaryToWriter
    );
  }
};


/**
 * optional PositionInTopology radio_ripple_control_receiver = 1;
 * @return {?proto.de.mypowergrid.ems.scontroller.PositionInTopology}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.getRadioRippleControlReceiver = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.PositionInTopology} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.PositionInTopology, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.PositionInTopology|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.setRadioRippleControlReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.clearRadioRippleControlReceiver = function() {
  return this.setRadioRippleControlReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment.prototype.hasRadioRippleControlReceiver = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.toObject = function(includeInstance, msg) {
  var f, obj = {
    socMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    socDeadzoneBelow: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    battery: (f = msg.getBattery()) && ext_ems_topology_topology_pb.PhysicalDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSocMax(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSocDeadzoneBelow(value);
      break;
    case 3:
      var value = new ext_ems_topology_topology_pb.PhysicalDevice;
      reader.readMessage(value,ext_ems_topology_topology_pb.PhysicalDevice.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSocMax();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSocDeadzoneBelow();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_ems_topology_topology_pb.PhysicalDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional double soc_max = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.getSocMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.setSocMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double soc_deadzone_below = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.getSocDeadzoneBelow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.setSocDeadzoneBelow = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional de.mypowergrid.ems.topology.PhysicalDevice battery = 3;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.getBattery = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.PhysicalDevice, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.toObject = function(includeInstance, msg) {
  var f, obj = {
    socLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    probingDurationOnInSec: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    probingDurationOffInSec: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection;
  return proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSocLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProbingDurationOnInSec(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProbingDurationOffInSec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSocLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getProbingDurationOnInSec();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProbingDurationOffInSec();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double soc_limit = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.getSocLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.setSocLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double probing_duration_on_in_sec = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.getProbingDurationOnInSec = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.setProbingDurationOnInSec = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double probing_duration_off_in_sec = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.getProbingDurationOffInSec = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection.prototype.setProbingDurationOffInSec = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional TargetPower target_power = 1;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getTargetPower = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.TargetPower|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setTargetPower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearTargetPower = function() {
  return this.setTargetPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasTargetPower = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RecordedPowerMeasurementPeakShaving recorded_power_measurement_peak_shaving = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getRecordedPowerMeasurementPeakShaving = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.RecordedPowerMeasurementPeakShaving|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setRecordedPowerMeasurementPeakShaving = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearRecordedPowerMeasurementPeakShaving = function() {
  return this.setRecordedPowerMeasurementPeakShaving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasRecordedPowerMeasurementPeakShaving = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GenerationPeakShaving generation_peak_shaving = 3;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getGenerationPeakShaving = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.GenerationPeakShaving|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setGenerationPeakShaving = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearGenerationPeakShaving = function() {
  return this.setGenerationPeakShaving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasGenerationPeakShaving = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OffGrid off_grid = 4;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getOffGrid = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGrid|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setOffGrid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearOffGrid = function() {
  return this.setOffGrid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasOffGrid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression eval_expression = 5;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getEvalExpression = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setEvalExpression = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearEvalExpression = function() {
  return this.setEvalExpression(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasEvalExpression = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DigitalOutputSwitchCondition digital_output_switch_condition = 6;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getDigitalOutputSwitchCondition = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.DigitalOutputSwitchCondition|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setDigitalOutputSwitchCondition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearDigitalOutputSwitchCondition = function() {
  return this.setDigitalOutputSwitchCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasDigitalOutputSwitchCondition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PvCurtailment pv_curtailment = 7;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getPvCurtailment = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.PvCurtailment|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setPvCurtailment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearPvCurtailment = function() {
  return this.setPvCurtailment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasPvCurtailment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OffGridTargetSocGenerationCurtailment off_grid_target_soc_generation_curtailment = 8;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getOffGridTargetSocGenerationCurtailment = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridTargetSocGenerationCurtailment|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setOffGridTargetSocGenerationCurtailment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearOffGridTargetSocGenerationCurtailment = function() {
  return this.setOffGridTargetSocGenerationCurtailment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasOffGridTargetSocGenerationCurtailment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OffGridBatteryProtection off_grid_battery_protection = 9;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.getOffGridBatteryProtection = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyService.OffGridBatteryProtection|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.setOffGridBatteryProtection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.scontroller.EnergyService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.clearOffGridBatteryProtection = function() {
  return this.setOffGridBatteryProtection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.EnergyService.prototype.hasOffGridBatteryProtection = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.toObject = function(includeInstance, msg) {
  var f, obj = {
    actuatorGroupFromActuatorIdMap: (f = msg.getActuatorGroupFromActuatorIdMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.toObject) : [],
    strategyFromStrategyIdMap: (f = msg.getStrategyFromStrategyIdMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getActuatorGroupFromActuatorIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.deserializeBinaryFromReader, "", new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup());
         });
      break;
    case 2:
      var value = msg.getStrategyFromStrategyIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.deserializeBinaryFromReader, "", new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActuatorGroupFromActuatorIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.serializeBinaryToWriter);
  }
  f = message.getStrategyFromStrategyIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.serializeBinaryToWriter);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyServiceFromActuatorIdMap: (f = msg.getEnergyServiceFromActuatorIdMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.ems.scontroller.EnergyService.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getEnergyServiceFromActuatorIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.ems.scontroller.EnergyService.deserializeBinaryFromReader, "", new proto.de.mypowergrid.ems.scontroller.EnergyService());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyServiceFromActuatorIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.ems.scontroller.EnergyService.serializeBinaryToWriter);
  }
};


/**
 * map<string, EnergyService> energy_service_from_actuator_id = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.EnergyService>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.prototype.getEnergyServiceFromActuatorIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.EnergyService>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.de.mypowergrid.ems.scontroller.EnergyService));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy.prototype.clearEnergyServiceFromActuatorIdMap = function() {
  this.getEnergyServiceFromActuatorIdMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_ = [[9,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.TypeCase = {
  TYPE_NOT_SET: 0,
  SINGLE_POWER: 9,
  BATTERY: 3,
  CHAI: 4,
  SIMPLE_PRIORITY: 5,
  ADVANCED_PRIORITY: 6,
  CAR_LIS: 7,
  SWITCH: 8
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    setpointSourcesList: jspb.Message.toObjectList(msg.getSetpointSourcesList(),
    ext_ems_topology_topology_pb.Source.toObject, includeInstance),
    singlePower: (f = msg.getSinglePower()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    battery: (f = msg.getBattery()) && proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.toObject(includeInstance, f),
    chai: (f = msg.getChai()) && proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.toObject(includeInstance, f),
    simplePriority: (f = msg.getSimplePriority()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    advancedPriority: (f = msg.getAdvancedPriority()) && proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.toObject(includeInstance, f),
    carLis: (f = msg.getCarLis()) && proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.toObject(includeInstance, f),
    pb_switch: (f = msg.getSwitch()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.addSetpointSources(value);
      break;
    case 9:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSinglePower(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.deserializeBinaryFromReader);
      msg.setChai(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSimplePriority(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.deserializeBinaryFromReader);
      msg.setAdvancedPriority(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.deserializeBinaryFromReader);
      msg.setCarLis(value);
      break;
    case 8:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSwitch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetpointSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getSinglePower();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.serializeBinaryToWriter
    );
  }
  f = message.getChai();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.serializeBinaryToWriter
    );
  }
  f = message.getSimplePriority();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getAdvancedPriority();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.serializeBinaryToWriter
    );
  }
  f = message.getCarLis();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.serializeBinaryToWriter
    );
  }
  f = message.getSwitch();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.toObject = function(includeInstance, msg) {
  var f, obj = {
    priorityListList: jspb.Message.toObjectList(msg.getPriorityListList(),
    proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.deserializeBinaryFromReader);
      msg.addPriorityList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriorityListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f),
    priority: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.topology.Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 priority = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SourcePriority priority_list = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.getPriorityListList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.setPriorityListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.addPriorityList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.SourcePriority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority.prototype.clearPriorityListList = function() {
  return this.setPriorityListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_ = [[2,3],[4,5],[6,7]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.GridCurrentCase = {
  GRID_CURRENT_NOT_SET: 0,
  MAX_CURRENT_GRID: 2,
  IGNORE_CURRENT_GRID: 3
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.GridCurrentCase}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getGridCurrentCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.GridCurrentCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.PowerGridCase = {
  POWER_GRID_NOT_SET: 0,
  MAX_POWER_GRID: 4,
  IGNORE_POWER_GRID: 5
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.PowerGridCase}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getPowerGridCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.PowerGridCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.CurrentLisCase = {
  CURRENT_LIS_NOT_SET: 0,
  MAX_CURRENT_LIS: 6,
  IGNORE_CURRENT_LIS: 7
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.CurrentLisCase}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getCurrentLisCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.CurrentLisCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargePointListList: jspb.Message.toObjectList(msg.getChargePointListList(),
    proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.toObject, includeInstance),
    maxCurrentGrid: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ignoreCurrentGrid: (f = msg.getIgnoreCurrentGrid()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    maxPowerGrid: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    ignorePowerGrid: (f = msg.getIgnorePowerGrid()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    maxCurrentLis: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    ignoreCurrentLis: (f = msg.getIgnoreCurrentLis()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.deserializeBinaryFromReader);
      msg.addChargePointList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxCurrentGrid(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setIgnoreCurrentGrid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPowerGrid(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setIgnorePowerGrid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxCurrentLis(value);
      break;
    case 7:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setIgnoreCurrentLis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargePointListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIgnoreCurrentGrid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIgnorePowerGrid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getIgnoreCurrentLis();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f),
    maxAmpere: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxAmpere(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getMaxAmpere();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.topology.Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double max_ampere = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.getMaxAmpere = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig.prototype.setMaxAmpere = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated ChargingPointConfig charge_point_list = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getChargePointListList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setChargePointListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.addChargePointList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.ChargingPointConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearChargePointListList = function() {
  return this.setChargePointListList([]);
};


/**
 * optional double max_current_grid = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getMaxCurrentGrid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setMaxCurrentGrid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearMaxCurrentGrid = function() {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasMaxCurrentGrid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty ignore_current_grid = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getIgnoreCurrentGrid = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setIgnoreCurrentGrid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearIgnoreCurrentGrid = function() {
  return this.setIgnoreCurrentGrid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasIgnoreCurrentGrid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double max_power_grid = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getMaxPowerGrid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setMaxPowerGrid = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearMaxPowerGrid = function() {
  return jspb.Message.setOneofField(this, 4, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasMaxPowerGrid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty ignore_power_grid = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getIgnorePowerGrid = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setIgnorePowerGrid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearIgnorePowerGrid = function() {
  return this.setIgnorePowerGrid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasIgnorePowerGrid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double max_current_lis = 6;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getMaxCurrentLis = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setMaxCurrentLis = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearMaxCurrentLis = function() {
  return jspb.Message.setOneofField(this, 6, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasMaxCurrentLis = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Empty ignore_current_lis = 7;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.getIgnoreCurrentLis = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 7));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.setIgnoreCurrentLis = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.clearIgnoreCurrentLis = function() {
  return this.setIgnoreCurrentLis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS.prototype.hasIgnoreCurrentLis = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.toObject = function(includeInstance, msg) {
  var f, obj = {
    deltaSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaSoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeltaSoc();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double delta_soc = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.prototype.getDeltaSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery.prototype.setDeltaSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prefix: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript;
  return proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string filename = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prefix = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript.prototype.setPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated de.mypowergrid.ems.topology.Source setpoint_sources = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.Source>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getSetpointSourcesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.Source>} */ (
    jspb.Message.getRepeatedWrapperField(this, ext_ems_topology_topology_pb.Source, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.Source>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setSetpointSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.Source=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.addSetpointSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.topology.Source, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearSetpointSourcesList = function() {
  return this.setSetpointSourcesList([]);
};


/**
 * optional google.protobuf.Empty single_power = 9;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getSinglePower = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 9));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setSinglePower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearSinglePower = function() {
  return this.setSinglePower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasSinglePower = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Battery battery = 3;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getBattery = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.Battery|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setBattery = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChaiScript chai = 4;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getChai = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.ChaiScript|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setChai = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearChai = function() {
  return this.setChai(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasChai = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty simple_priority = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getSimplePriority = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setSimplePriority = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearSimplePriority = function() {
  return this.setSimplePriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasSimplePriority = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AdvancedPriority advanced_priority = 6;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getAdvancedPriority = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.AdvancedPriority|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setAdvancedPriority = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearAdvancedPriority = function() {
  return this.setAdvancedPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasAdvancedPriority = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LiS car_lis = 7;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getCarLis = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.LiS|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setCarLis = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearCarLis = function() {
  return this.setCarLis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasCarLis = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Empty switch = 8;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.getSwitch = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 8));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
*/
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.setSwitch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.clearSwitch = function() {
  return this.setSwitch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.prototype.hasSwitch = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * map<string, ActuatorGroup> actuator_group_from_actuator_id = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.getActuatorGroupFromActuatorIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.clearActuatorGroupFromActuatorIdMap = function() {
  this.getActuatorGroupFromActuatorIdMap().clear();
  return this;};


/**
 * map<string, Strategy> strategy_from_strategy_id = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.getStrategyFromStrategyIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.Strategy));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.prototype.clearStrategyFromStrategyIdMap = function() {
  this.getStrategyFromStrategyIdMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actuatorGroup: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult;
  return proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuatorGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActuatorGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status = {
  OK: 0,
  MISSING_ENERGY_SERVICE_FOR_ACTUATOR_GROUP: 1
};

/**
 * optional Status status = 1;
 * @return {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.getStatus = function() {
  return /** @type {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.Status} value
 * @return {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} returns this
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string actuator_group = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.getActuatorGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} returns this
 */
proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult.prototype.setActuatorGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.TypeCase = {
  TYPE_NOT_SET: 0,
  NODE: 1,
  STRATEGY_ID: 2
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.toObject(includeInstance, f),
    strategyId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.repeatedFields_ = [11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_ = [[3,4,5,6,8,9,12]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TypeCase = {
  TYPE_NOT_SET: 0,
  SOC_GREATER_THAN: 3,
  GRID_STATE_IS: 4,
  GREATER_THAN_ZERO: 5,
  VALUE_HYSTERESIS: 6,
  CHAI_SCRIPT: 8,
  INVALID_CHECK: 9,
  VPP_GRID_BAT_TARGET_IS: 12
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TypeCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    ifYes: (f = msg.getIfYes()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.toObject(includeInstance, f),
    ifNo: (f = msg.getIfNo()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.toObject(includeInstance, f),
    socGreaterThan: (f = msg.getSocGreaterThan()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.toObject(includeInstance, f),
    gridStateIs: (f = msg.getGridStateIs()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.toObject(includeInstance, f),
    greaterThanZero: (f = msg.getGreaterThanZero()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    valueHysteresis: (f = msg.getValueHysteresis()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.toObject(includeInstance, f),
    chaiScript: (f = msg.getChaiScript()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.toObject(includeInstance, f),
    invalidCheck: (f = msg.getInvalidCheck()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppGridBatTargetIs: (f = msg.getVppGridBatTargetIs()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 10, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinaryFromReader);
      msg.setIfYes(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinaryFromReader);
      msg.setIfNo(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.deserializeBinaryFromReader);
      msg.setSocGreaterThan(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.deserializeBinaryFromReader);
      msg.setGridStateIs(value);
      break;
    case 5:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setGreaterThanZero(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.deserializeBinaryFromReader);
      msg.setValueHysteresis(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.deserializeBinaryFromReader);
      msg.setChaiScript(value);
      break;
    case 9:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setInvalidCheck(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.deserializeBinaryFromReader);
      msg.setVppGridBatTargetIs(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIfYes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.serializeBinaryToWriter
    );
  }
  f = message.getIfNo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.serializeBinaryToWriter
    );
  }
  f = message.getSocGreaterThan();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.serializeBinaryToWriter
    );
  }
  f = message.getGridStateIs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.serializeBinaryToWriter
    );
  }
  f = message.getGreaterThanZero();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getValueHysteresis();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.serializeBinaryToWriter
    );
  }
  f = message.getChaiScript();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.serializeBinaryToWriter
    );
  }
  f = message.getInvalidCheck();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppGridBatTargetIs();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.toObject = function(includeInstance, msg) {
  var f, obj = {
    cooldownSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCooldownSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCooldownSeconds();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double cooldown_seconds = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.prototype.getCooldownSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.prototype.setCooldownSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultStrategy: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultStrategy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultStrategy();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string default_strategy = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.prototype.getDefaultStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.prototype.setDefaultStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.OperationCase = {
  OPERATION_NOT_SET: 0,
  TIME_HYSTERESIS: 2,
  FILTER_DEFAULT: 3,
  FILTER_LAST: 4
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.OperationCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.getOperationCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.OperationCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeHysteresis: (f = msg.getTimeHysteresis()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.toObject(includeInstance, f),
    filterDefault: (f = msg.getFilterDefault()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.toObject(includeInstance, f),
    filterLast: (f = msg.getFilterLast()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.deserializeBinaryFromReader);
      msg.setTimeHysteresis(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.deserializeBinaryFromReader);
      msg.setFilterDefault(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFilterLast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeHysteresis();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis.serializeBinaryToWriter
    );
  }
  f = message.getFilterDefault();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault.serializeBinaryToWriter
    );
  }
  f = message.getFilterLast();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeHysteresis time_hysteresis = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.getTimeHysteresis = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.TimeHysteresis|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.setTimeHysteresis = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.clearTimeHysteresis = function() {
  return this.setTimeHysteresis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.hasTimeHysteresis = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FilterDefault filter_default = 3;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.getFilterDefault = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.FilterDefault|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.setFilterDefault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.clearFilterDefault = function() {
  return this.setFilterDefault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.hasFilterDefault = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty filter_last = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.getFilterLast = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.setFilterLast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.clearFilterLast = function() {
  return this.setFilterLast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption.prototype.hasFilterLast = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.ActuatorGroupSetpointOnDisagreeCase = {
  ACTUATOR_GROUP_SETPOINT_ON_DISAGREE_NOT_SET: 0,
  FIXATE_SETPOINT: 3,
  KEEP_SETPOINT: 4
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.ActuatorGroupSetpointOnDisagreeCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getActuatorGroupSetpointOnDisagreeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.ActuatorGroupSetpointOnDisagreeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    actuatorGroupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zeroPoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fixateSetpoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    keepSetpoint: (f = msg.getKeepSetpoint()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    deadzoneBehaviour: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuatorGroupName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZeroPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFixateSetpoint(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setKeepSetpoint(value);
      break;
    case 5:
      var value = /** @type {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour} */ (reader.readEnum());
      msg.setDeadzoneBehaviour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActuatorGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZeroPoint();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getKeepSetpoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getDeadzoneBehaviour();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour = {
  HHH: 0,
  NHH: 1,
  NNH: 2,
  HHY: 3,
  NHY: 4,
  NNY: 5,
  HYY: 6,
  NYY: 7
};

/**
 * optional string actuator_group_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getActuatorGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.setActuatorGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double zero_point = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getZeroPoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.setZeroPoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double fixate_setpoint = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getFixateSetpoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.setFixateSetpoint = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.clearFixateSetpoint = function() {
  return jspb.Message.setOneofField(this, 3, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.hasFixateSetpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty keep_setpoint = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getKeepSetpoint = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.setKeepSetpoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.clearKeepSetpoint = function() {
  return this.setKeepSetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.hasKeepSetpoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeadzoneBehaviour deadzone_behaviour = 5;
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.getDeadzoneBehaviour = function() {
  return /** @type {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.DeadzoneBehaviour} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.prototype.setDeadzoneBehaviour = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_ = [[1,5,6],[3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.CheckCase = {
  CHECK_NOT_SET: 0,
  SOC_THRESHOLD_PERCENT: 1,
  SOC_LIMIT_EXPRESSION: 5,
  ABSOLUTE_VALUE_EXPRESSION: 6
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.CheckCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getCheckCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.CheckCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.ApiVersionCase = {
  API_VERSION_NOT_SET: 0,
  LEGACY: 3,
  SOC_DEADZONE_BEHAVIOUR: 4
};

/**
 * @return {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.ApiVersionCase}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getApiVersionCase = function() {
  return /** @type {proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.ApiVersionCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.toObject = function(includeInstance, msg) {
  var f, obj = {
    socThresholdPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    socLimitExpression: (f = msg.getSocLimitExpression()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    absoluteValueExpression: (f = msg.getAbsoluteValueExpression()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    socDeadzoneBelow: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    legacy: (f = msg.getLegacy()) && ext_ems_topology_topology_pb.PhysicalDevice.toObject(includeInstance, f),
    socDeadzoneBehaviour: (f = msg.getSocDeadzoneBehaviour()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSocThresholdPercent(value);
      break;
    case 5:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setSocLimitExpression(value);
      break;
    case 6:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setAbsoluteValueExpression(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSocDeadzoneBelow(value);
      break;
    case 3:
      var value = new ext_ems_topology_topology_pb.PhysicalDevice;
      reader.readMessage(value,ext_ems_topology_topology_pb.PhysicalDevice.deserializeBinaryFromReader);
      msg.setLegacy(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.deserializeBinaryFromReader);
      msg.setSocDeadzoneBehaviour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSocLimitExpression();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getAbsoluteValueExpression();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getSocDeadzoneBelow();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLegacy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_ems_topology_topology_pb.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getSocDeadzoneBehaviour();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional double soc_threshold_percent = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getSocThresholdPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setSocThresholdPercent = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.clearSocThresholdPercent = function() {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.hasSocThresholdPercent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression soc_limit_expression = 5;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getSocLimitExpression = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setSocLimitExpression = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.clearSocLimitExpression = function() {
  return this.setSocLimitExpression(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.hasSocLimitExpression = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression absolute_value_expression = 6;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getAbsoluteValueExpression = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setAbsoluteValueExpression = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.clearAbsoluteValueExpression = function() {
  return this.setAbsoluteValueExpression(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.hasAbsoluteValueExpression = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double soc_deadzone_below = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getSocDeadzoneBelow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setSocDeadzoneBelow = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional de.mypowergrid.ems.topology.PhysicalDevice legacy = 3;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getLegacy = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.PhysicalDevice, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setLegacy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.clearLegacy = function() {
  return this.setLegacy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.hasLegacy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LimitCondition soc_deadzone_behaviour = 4;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.getSocDeadzoneBehaviour = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.LimitCondition|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.setSocDeadzoneBehaviour = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.clearSocDeadzoneBehaviour = function() {
  return this.setSocDeadzoneBehaviour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan.prototype.hasSocDeadzoneBehaviour = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridState: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.GridState} */ (reader.readEnum());
      msg.setGridState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.GridState grid_state = 1;
 * @return {!proto.de.mypowergrid.ems.GridState}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.prototype.getGridState = function() {
  return /** @type {!proto.de.mypowergrid.ems.GridState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.GridState} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs.prototype.setGridState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value target = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.prototype.getTarget = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.toObject = function(includeInstance, msg) {
  var f, obj = {
    greaterThanZero: (f = msg.getGreaterThanZero()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    tolerance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setGreaterThanZero(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTolerance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGreaterThanZero();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getTolerance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.EvalExpression greater_than_zero = 1;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.getGreaterThanZero = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.setGreaterThanZero = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.clearGreaterThanZero = function() {
  return this.setGreaterThanZero(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.hasGreaterThanZero = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double tolerance = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.getTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis.prototype.setTolerance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.toObject = function(includeInstance, msg) {
  var f, obj = {
    filepath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prefix: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript;
  return proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilepath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilepath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string filepath = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.getFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.setFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prefix = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript.prototype.setPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DecisionTree if_yes = 1;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getIfYes = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setIfYes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearIfYes = function() {
  return this.setIfYes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasIfYes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DecisionTree if_no = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getIfNo = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setIfNo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearIfNo = function() {
  return this.setIfNo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasIfNo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocGreaterThan soc_greater_than = 3;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getSocGreaterThan = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.SocGreaterThan|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setSocGreaterThan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearSocGreaterThan = function() {
  return this.setSocGreaterThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasSocGreaterThan = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GridStateIs grid_state_is = 4;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getGridStateIs = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.GridStateIs|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setGridStateIs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearGridStateIs = function() {
  return this.setGridStateIs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasGridStateIs = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression greater_than_zero = 5;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getGreaterThanZero = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setGreaterThanZero = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearGreaterThanZero = function() {
  return this.setGreaterThanZero(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasGreaterThanZero = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ValueHysteresis value_hysteresis = 6;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getValueHysteresis = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ValueHysteresis|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setValueHysteresis = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearValueHysteresis = function() {
  return this.setValueHysteresis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasValueHysteresis = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChaiScript chai_script = 8;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getChaiScript = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.ChaiScript|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setChaiScript = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearChaiScript = function() {
  return this.setChaiScript(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasChaiScript = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Empty invalid_check = 9;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getInvalidCheck = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 9));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setInvalidCheck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearInvalidCheck = function() {
  return this.setInvalidCheck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasInvalidCheck = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional VppGridBatteryTargetIs vpp_grid_bat_target_is = 12;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getVppGridBatTargetIs = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs, 12));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.VppGridBatteryTargetIs|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setVppGridBatTargetIs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearVppGridBatTargetIs = function() {
  return this.setVppGridBatTargetIs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.hasVppGridBatTargetIs = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string id = 10;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated DecisionOption options = 11;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption>}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption, 11));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.DecisionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.Node.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional Node node = 1;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.getNode = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree.Node, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree.Node|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.setNode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string strategy_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.getStrategyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.setStrategyId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionTree} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.clearStrategyId = function() {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.scontroller.DecisionTree.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionTree.prototype.hasStrategyId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.StrategyDecision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultStrategyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    decisionTree: (f = msg.getDecisionTree()) && proto.de.mypowergrid.ems.scontroller.DecisionTree.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.StrategyDecision}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.StrategyDecision;
  return proto.de.mypowergrid.ems.scontroller.StrategyDecision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.StrategyDecision}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultStrategyId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionTree;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionTree.deserializeBinaryFromReader);
      msg.setDecisionTree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.StrategyDecision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultStrategyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDecisionTree();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional string default_strategy_id = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.getDefaultStrategyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} returns this
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.setDefaultStrategyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DecisionTree decision_tree = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionTree}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.getDecisionTree = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionTree} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionTree, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionTree|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} returns this
*/
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.setDecisionTree = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.StrategyDecision} returns this
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.clearDecisionTree = function() {
  return this.setDecisionTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.StrategyDecision.prototype.hasDecisionTree = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.Decision.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.Decision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.Decision.toObject = function(includeInstance, msg) {
  var f, obj = {
    strategy: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forcedSetpointsList: jspb.Message.toObjectList(msg.getForcedSetpointsList(),
    proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision}
 */
proto.de.mypowergrid.ems.scontroller.Decision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.Decision;
  return proto.de.mypowergrid.ems.scontroller.Decision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision}
 */
proto.de.mypowergrid.ems.scontroller.Decision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategy(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.deserializeBinaryFromReader);
      msg.addForcedSetpoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.Decision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.Decision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStrategy();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForcedSetpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    actuatorGroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setpoint: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint;
  return proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuatorGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSetpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActuatorGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetpoint();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string actuator_group = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.getActuatorGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint} returns this
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.setActuatorGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double setpoint = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.getSetpoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint} returns this
 */
proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint.prototype.setSetpoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string strategy = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.getStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision} returns this
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ForcedSetpoint forced_setpoints = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint>}
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.getForcedSetpointsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision} returns this
*/
proto.de.mypowergrid.ems.scontroller.Decision.prototype.setForcedSetpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint}
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.addForcedSetpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.scontroller.Decision.ForcedSetpoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.Decision} returns this
 */
proto.de.mypowergrid.ems.scontroller.Decision.prototype.clearForcedSetpointsList = function() {
  return this.setForcedSetpointsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.DecisionExplaination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    decision: (f = msg.getDecision()) && proto.de.mypowergrid.ems.scontroller.Decision.toObject(includeInstance, f),
    decisionTimestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    explaination: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subExplainationsList: jspb.Message.toObjectList(msg.getSubExplainationsList(),
    proto.de.mypowergrid.ems.scontroller.DecisionExplaination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.DecisionExplaination;
  return proto.de.mypowergrid.ems.scontroller.DecisionExplaination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.Decision;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.Decision.deserializeBinaryFromReader);
      msg.setDecision(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDecisionTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExplaination(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.scontroller.DecisionExplaination;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.DecisionExplaination.deserializeBinaryFromReader);
      msg.addSubExplainations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.DecisionExplaination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDecision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.Decision.serializeBinaryToWriter
    );
  }
  f = message.getDecisionTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getExplaination();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubExplainationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.de.mypowergrid.ems.scontroller.DecisionExplaination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Decision decision = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.Decision}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.getDecision = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.Decision} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.scontroller.Decision, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.Decision|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.setDecision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.clearDecision = function() {
  return this.setDecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.hasDecision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double decision_timestamp = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.getDecisionTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.setDecisionTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string explaination = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.getExplaination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.setExplaination = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DecisionExplaination sub_explainations = 5;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.DecisionExplaination>}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.getSubExplainationsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.DecisionExplaination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.DecisionExplaination, 5));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.DecisionExplaination>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
*/
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.setSubExplainationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination}
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.addSubExplainations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.de.mypowergrid.ems.scontroller.DecisionExplaination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.DecisionExplaination} returns this
 */
proto.de.mypowergrid.ems.scontroller.DecisionExplaination.prototype.clearSubExplainationsList = function() {
  return this.setSubExplainationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabletime: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    disabletime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection;
  return proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnabletime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisabletime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabletime();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDisabletime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double enableTime = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.getEnabletime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection} returns this
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.setEnabletime = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double disableTime = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.getDisabletime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection} returns this
 */
proto.de.mypowergrid.ems.scontroller.OffgridBatteryProtection.prototype.setDisabletime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.google.protobuf.Any.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorGroupData;
  return proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Any.deserializeBinaryFromReader, 0, new proto.google.protobuf.Any());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Any.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, google.protobuf.Any> data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.google.protobuf.Any>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.google.protobuf.Any>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.google.protobuf.Any));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData;
  return proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.deserializeBinaryFromReader, "", new proto.de.mypowergrid.ems.scontroller.ActuatorGroupData());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.ems.scontroller.ActuatorGroupData.serializeBinaryToWriter);
  }
};


/**
 * map<string, ActuatorGroupData> data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData>}
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.ems.scontroller.ActuatorGroupData>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.de.mypowergrid.ems.scontroller.ActuatorGroupData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData} returns this
 */
proto.de.mypowergrid.ems.scontroller.ActuatorGroupsData.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    setpointSourcePropertiesList: jspb.Message.toObjectList(msg.getSetpointSourcePropertiesList(),
    proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection;
  return proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.deserializeBinaryFromReader);
      msg.addSetpointSourceProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetpointSourcePropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f),
    assignableActuatorGroupsList: jspb.Message.toObjectList(msg.getAssignableActuatorGroupsList(),
    proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties;
  return proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.deserializeBinaryFromReader);
      msg.addAssignableActuatorGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getAssignableActuatorGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional de.mypowergrid.ems.topology.Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} returns this
*/
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} returns this
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActuatorStrategies.ActuatorGroup assignable_actuator_groups = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup>}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.getAssignableActuatorGroupsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} returns this
*/
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.setAssignableActuatorGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.addAssignableActuatorGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties} returns this
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties.prototype.clearAssignableActuatorGroupsList = function() {
  return this.setAssignableActuatorGroupsList([]);
};


/**
 * repeated SetpointSourceProperties setpoint_source_properties = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties>}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.getSetpointSourcePropertiesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} returns this
*/
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.setSetpointSourcePropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties}
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.addSetpointSourceProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.SetpointSourceProperties, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} returns this
 */
proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection.prototype.clearSetpointSourcePropertiesList = function() {
  return this.setSetpointSourcePropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.scontroller.EnergyServices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyServices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyServicesList: jspb.Message.toObjectList(msg.getEnergyServicesList(),
    proto.de.mypowergrid.ems.scontroller.EnergyService.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyServices}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.scontroller.EnergyServices;
  return proto.de.mypowergrid.ems.scontroller.EnergyServices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyServices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyServices}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.scontroller.EnergyService;
      reader.readMessage(value,proto.de.mypowergrid.ems.scontroller.EnergyService.deserializeBinaryFromReader);
      msg.addEnergyServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.scontroller.EnergyServices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyServices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.scontroller.EnergyService.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnergyService energy_services = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.scontroller.EnergyService>}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.getEnergyServicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.scontroller.EnergyService>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.scontroller.EnergyService, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.scontroller.EnergyService>} value
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyServices} returns this
*/
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.setEnergyServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.scontroller.EnergyService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyService}
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.addEnergyServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.scontroller.EnergyService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.scontroller.EnergyServices} returns this
 */
proto.de.mypowergrid.ems.scontroller.EnergyServices.prototype.clearEnergyServicesList = function() {
  return this.setEnergyServicesList([]);
};


goog.object.extend(exports, proto.de.mypowergrid.ems.scontroller);
