// source: ext/ems/data-objects.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_options_field_pb = require('../../ext/options/field_pb.js');
goog.object.extend(proto, ext_options_field_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.ASG_SG', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ASG_SP', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.AnalogueValue', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.BCR', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.BehaviourModeKind', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.CMV', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DEL', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DPC', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DPL', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DPS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DataObject', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DataObject.OneofCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.DpStatusKind', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENC_DS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENC_EMSError', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENC_EMSError.Errors', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENC_GridState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENG_HeatpumpOpMode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENG_SP_ModeCtrl', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_AirConditionerState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_AirConditionerState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_BatteryState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_BatteryState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_BehaviourMode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_ConnTyp', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_ConnTyp.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_DS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_DerTyp', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_DerTyp.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_EzaState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_EzaState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_FireAlarmState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_FireAlarmState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_GatewayState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_GatewayState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_GridState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_HeatpumpOpMode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_InverterState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_InverterState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoInvState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoInvState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoPcuErrState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoPcuState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_KacoPcuState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_MatTyp', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsCcuState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsCcuState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsIpuState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsIpuState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsMcuState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_PcsMcuState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_WallboxState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.ENS_WallboxState.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.GatewayDiscovery', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.GridState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.HealthKind', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.HeatpumpOpMode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.INS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.MV', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.MaterialKind', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.Quality', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.Quality.ValidType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.SAV', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.SPC', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.SPS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.TSG_SG', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.Timestamp', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.Unit', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.VSS', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.Vector', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.WYE', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.DataObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.DataObject.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.DataObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.DataObject.displayName = 'proto.de.mypowergrid.ems.DataObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.AnalogueValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.AnalogueValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.AnalogueValue.displayName = 'proto.de.mypowergrid.ems.AnalogueValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.Quality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.Quality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.Quality.displayName = 'proto.de.mypowergrid.ems.Quality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.Timestamp.displayName = 'proto.de.mypowergrid.ems.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.Vector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.Vector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.Vector.displayName = 'proto.de.mypowergrid.ems.Vector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.CMV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.CMV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.CMV.displayName = 'proto.de.mypowergrid.ems.CMV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.DPL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.DPL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.DPL.displayName = 'proto.de.mypowergrid.ems.DPL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.MV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.MV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.MV.displayName = 'proto.de.mypowergrid.ems.MV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.SAV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.SAV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.SAV.displayName = 'proto.de.mypowergrid.ems.SAV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.BCR = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.BCR, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.BCR.displayName = 'proto.de.mypowergrid.ems.BCR';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.WYE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.WYE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.WYE.displayName = 'proto.de.mypowergrid.ems.WYE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ASG_SP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ASG_SP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ASG_SP.displayName = 'proto.de.mypowergrid.ems.ASG_SP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ASG_SG = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ASG_SG, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ASG_SG.displayName = 'proto.de.mypowergrid.ems.ASG_SG';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_BehaviourMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_BehaviourMode.displayName = 'proto.de.mypowergrid.ems.ENS_BehaviourMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_DS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_DS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_DS.displayName = 'proto.de.mypowergrid.ems.ENS_DS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENC_DS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENC_DS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENC_DS.displayName = 'proto.de.mypowergrid.ems.ENC_DS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_GridState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_GridState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_GridState.displayName = 'proto.de.mypowergrid.ems.ENS_GridState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENC_GridState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENC_GridState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENC_GridState.displayName = 'proto.de.mypowergrid.ems.ENC_GridState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENC_EMSError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENC_EMSError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENC_EMSError.displayName = 'proto.de.mypowergrid.ems.ENC_EMSError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENC_EMSError.Errors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENC_EMSError.Errors.displayName = 'proto.de.mypowergrid.ems.ENC_EMSError.Errors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.displayName = 'proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_InverterState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_InverterState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_InverterState.displayName = 'proto.de.mypowergrid.ems.ENS_InverterState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_BatteryState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_BatteryState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_BatteryState.displayName = 'proto.de.mypowergrid.ems.ENS_BatteryState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENG_SP_ModeCtrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.displayName = 'proto.de.mypowergrid.ems.ENG_SP_ModeCtrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.TSG_SG = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.TSG_SG, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.TSG_SG.displayName = 'proto.de.mypowergrid.ems.TSG_SG';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.SPS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.SPS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.SPS.displayName = 'proto.de.mypowergrid.ems.SPS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.SPC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.SPC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.SPC.displayName = 'proto.de.mypowergrid.ems.SPC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.DPC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.DPC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.DPC.displayName = 'proto.de.mypowergrid.ems.DPC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.DEL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.DEL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.DEL.displayName = 'proto.de.mypowergrid.ems.DEL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.INS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.INS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.INS.displayName = 'proto.de.mypowergrid.ems.INS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_ConnTyp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_ConnTyp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_ConnTyp.displayName = 'proto.de.mypowergrid.ems.ENS_ConnTyp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_DerTyp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_DerTyp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_DerTyp.displayName = 'proto.de.mypowergrid.ems.ENS_DerTyp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_WallboxState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_WallboxState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_WallboxState.displayName = 'proto.de.mypowergrid.ems.ENS_WallboxState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_PcsCcuState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_PcsCcuState.displayName = 'proto.de.mypowergrid.ems.ENS_PcsCcuState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_PcsIpuState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_PcsIpuState.displayName = 'proto.de.mypowergrid.ems.ENS_PcsIpuState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_PcsMcuState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_PcsMcuState.displayName = 'proto.de.mypowergrid.ems.ENS_PcsMcuState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_HeatpumpOpMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.displayName = 'proto.de.mypowergrid.ems.ENS_HeatpumpOpMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENG_HeatpumpOpMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.displayName = 'proto.de.mypowergrid.ems.ENG_HeatpumpOpMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.VSS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.VSS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.VSS.displayName = 'proto.de.mypowergrid.ems.VSS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_MatTyp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_MatTyp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_MatTyp.displayName = 'proto.de.mypowergrid.ems.ENS_MatTyp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_GatewayState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_GatewayState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_GatewayState.displayName = 'proto.de.mypowergrid.ems.ENS_GatewayState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.GatewayDiscovery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.GatewayDiscovery.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.GatewayDiscovery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.GatewayDiscovery.displayName = 'proto.de.mypowergrid.ems.GatewayDiscovery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.displayName = 'proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_EzaState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_EzaState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_EzaState.displayName = 'proto.de.mypowergrid.ems.ENS_EzaState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_FireAlarmState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_FireAlarmState.displayName = 'proto.de.mypowergrid.ems.ENS_FireAlarmState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_AirConditionerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_AirConditionerState.displayName = 'proto.de.mypowergrid.ems.ENS_AirConditionerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.DPS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.DPS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.DPS.displayName = 'proto.de.mypowergrid.ems.DPS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_KacoInvState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_KacoInvState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_KacoInvState.displayName = 'proto.de.mypowergrid.ems.ENS_KacoInvState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_KacoPcuState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_KacoPcuState.displayName = 'proto.de.mypowergrid.ems.ENS_KacoPcuState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.ENS_KacoPcuErrState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.ENS_KacoPcuErrState.displayName = 'proto.de.mypowergrid.ems.ENS_KacoPcuErrState';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.DataObject.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.DataObject.OneofCase = {
  ONEOF_NOT_SET: 0,
  DPL: 3,
  MV: 4,
  BCR: 5,
  WYE: 6,
  ASG_SP: 7,
  ASG_SG: 8,
  ENS_DS: 9,
  ENS_GRIDSTATE: 10,
  ENG_SP_MODECTRL: 11,
  TSG_SG: 12,
  SPS: 13,
  SAV: 14,
  DPC: 15,
  SPC: 16,
  DEL: 17,
  INS: 18,
  ENS_INVERTERSTATE: 19,
  ENS_BATTERYSTATE: 20,
  ENS_CONNTYP: 21,
  ENS_DERTYP: 22,
  ENS_WALLBOXSTATE: 23,
  ENS_HEATPUMPOPMODE: 24,
  ENG_HEATPUMPOPMODE: 25,
  VSS: 26,
  ENC_GRIDSTATE: 27,
  ENS_MATTYP: 28,
  ENC_EMS_ERROR: 29,
  ENS_EXTERNAL_SETPOINT_TARGET: 30,
  ENS_PCS_CCU_STATE: 31,
  ENS_PCS_IPU_STATE: 32,
  ENS_PCS_MCU_STATE: 33,
  ENS_GATEWAY_STATE: 34,
  GATEWAY_DISCOVERY: 35,
  ENS_EZA_STATE: 36,
  ENC_DS: 37,
  ENS_FIRE_ALARM_STATE: 38,
  ENS_AIR_CONDITIONER_STATE: 39,
  ENS_BEHAVIOUR_MODE: 40,
  DPS: 41,
  ENS_KACO_INV_STATE: 42,
  ENS_KACO_PCU_STATE: 43,
  ENS_KACO_PCU_ERR_STATE: 44
};

/**
 * @return {proto.de.mypowergrid.ems.DataObject.OneofCase}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getOneofCase = function() {
  return /** @type {proto.de.mypowergrid.ems.DataObject.OneofCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.DataObject.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.DataObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.DataObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DataObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalNodeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dpl: (f = msg.getDpl()) && proto.de.mypowergrid.ems.DPL.toObject(includeInstance, f),
    mv: (f = msg.getMv()) && proto.de.mypowergrid.ems.MV.toObject(includeInstance, f),
    bcr: (f = msg.getBcr()) && proto.de.mypowergrid.ems.BCR.toObject(includeInstance, f),
    wye: (f = msg.getWye()) && proto.de.mypowergrid.ems.WYE.toObject(includeInstance, f),
    asgSp: (f = msg.getAsgSp()) && proto.de.mypowergrid.ems.ASG_SP.toObject(includeInstance, f),
    asgSg: (f = msg.getAsgSg()) && proto.de.mypowergrid.ems.ASG_SG.toObject(includeInstance, f),
    ensDs: (f = msg.getEnsDs()) && proto.de.mypowergrid.ems.ENS_DS.toObject(includeInstance, f),
    ensGridstate: (f = msg.getEnsGridstate()) && proto.de.mypowergrid.ems.ENS_GridState.toObject(includeInstance, f),
    engSpModectrl: (f = msg.getEngSpModectrl()) && proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.toObject(includeInstance, f),
    tsgSg: (f = msg.getTsgSg()) && proto.de.mypowergrid.ems.TSG_SG.toObject(includeInstance, f),
    sps: (f = msg.getSps()) && proto.de.mypowergrid.ems.SPS.toObject(includeInstance, f),
    sav: (f = msg.getSav()) && proto.de.mypowergrid.ems.SAV.toObject(includeInstance, f),
    dpc: (f = msg.getDpc()) && proto.de.mypowergrid.ems.DPC.toObject(includeInstance, f),
    spc: (f = msg.getSpc()) && proto.de.mypowergrid.ems.SPC.toObject(includeInstance, f),
    del: (f = msg.getDel()) && proto.de.mypowergrid.ems.DEL.toObject(includeInstance, f),
    ins: (f = msg.getIns()) && proto.de.mypowergrid.ems.INS.toObject(includeInstance, f),
    ensInverterstate: (f = msg.getEnsInverterstate()) && proto.de.mypowergrid.ems.ENS_InverterState.toObject(includeInstance, f),
    ensBatterystate: (f = msg.getEnsBatterystate()) && proto.de.mypowergrid.ems.ENS_BatteryState.toObject(includeInstance, f),
    ensConntyp: (f = msg.getEnsConntyp()) && proto.de.mypowergrid.ems.ENS_ConnTyp.toObject(includeInstance, f),
    ensDertyp: (f = msg.getEnsDertyp()) && proto.de.mypowergrid.ems.ENS_DerTyp.toObject(includeInstance, f),
    ensWallboxstate: (f = msg.getEnsWallboxstate()) && proto.de.mypowergrid.ems.ENS_WallboxState.toObject(includeInstance, f),
    ensHeatpumpopmode: (f = msg.getEnsHeatpumpopmode()) && proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.toObject(includeInstance, f),
    engHeatpumpopmode: (f = msg.getEngHeatpumpopmode()) && proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.toObject(includeInstance, f),
    vss: (f = msg.getVss()) && proto.de.mypowergrid.ems.VSS.toObject(includeInstance, f),
    encGridstate: (f = msg.getEncGridstate()) && proto.de.mypowergrid.ems.ENC_GridState.toObject(includeInstance, f),
    ensMattyp: (f = msg.getEnsMattyp()) && proto.de.mypowergrid.ems.ENS_MatTyp.toObject(includeInstance, f),
    encEmsError: (f = msg.getEncEmsError()) && proto.de.mypowergrid.ems.ENC_EMSError.toObject(includeInstance, f),
    ensExternalSetpointTarget: (f = msg.getEnsExternalSetpointTarget()) && proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.toObject(includeInstance, f),
    ensPcsCcuState: (f = msg.getEnsPcsCcuState()) && proto.de.mypowergrid.ems.ENS_PcsCcuState.toObject(includeInstance, f),
    ensPcsIpuState: (f = msg.getEnsPcsIpuState()) && proto.de.mypowergrid.ems.ENS_PcsIpuState.toObject(includeInstance, f),
    ensPcsMcuState: (f = msg.getEnsPcsMcuState()) && proto.de.mypowergrid.ems.ENS_PcsMcuState.toObject(includeInstance, f),
    ensGatewayState: (f = msg.getEnsGatewayState()) && proto.de.mypowergrid.ems.ENS_GatewayState.toObject(includeInstance, f),
    gatewayDiscovery: (f = msg.getGatewayDiscovery()) && proto.de.mypowergrid.ems.GatewayDiscovery.toObject(includeInstance, f),
    ensEzaState: (f = msg.getEnsEzaState()) && proto.de.mypowergrid.ems.ENS_EzaState.toObject(includeInstance, f),
    encDs: (f = msg.getEncDs()) && proto.de.mypowergrid.ems.ENC_DS.toObject(includeInstance, f),
    ensFireAlarmState: (f = msg.getEnsFireAlarmState()) && proto.de.mypowergrid.ems.ENS_FireAlarmState.toObject(includeInstance, f),
    ensAirConditionerState: (f = msg.getEnsAirConditionerState()) && proto.de.mypowergrid.ems.ENS_AirConditionerState.toObject(includeInstance, f),
    ensBehaviourMode: (f = msg.getEnsBehaviourMode()) && proto.de.mypowergrid.ems.ENS_BehaviourMode.toObject(includeInstance, f),
    dps: (f = msg.getDps()) && proto.de.mypowergrid.ems.DPS.toObject(includeInstance, f),
    ensKacoInvState: (f = msg.getEnsKacoInvState()) && proto.de.mypowergrid.ems.ENS_KacoInvState.toObject(includeInstance, f),
    ensKacoPcuState: (f = msg.getEnsKacoPcuState()) && proto.de.mypowergrid.ems.ENS_KacoPcuState.toObject(includeInstance, f),
    ensKacoPcuErrState: (f = msg.getEnsKacoPcuErrState()) && proto.de.mypowergrid.ems.ENS_KacoPcuErrState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.DataObject}
 */
proto.de.mypowergrid.ems.DataObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.DataObject;
  return proto.de.mypowergrid.ems.DataObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.DataObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.DataObject}
 */
proto.de.mypowergrid.ems.DataObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalNodeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.DPL;
      reader.readMessage(value,proto.de.mypowergrid.ems.DPL.deserializeBinaryFromReader);
      msg.setDpl(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.MV;
      reader.readMessage(value,proto.de.mypowergrid.ems.MV.deserializeBinaryFromReader);
      msg.setMv(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.BCR;
      reader.readMessage(value,proto.de.mypowergrid.ems.BCR.deserializeBinaryFromReader);
      msg.setBcr(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.WYE;
      reader.readMessage(value,proto.de.mypowergrid.ems.WYE.deserializeBinaryFromReader);
      msg.setWye(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.ASG_SP;
      reader.readMessage(value,proto.de.mypowergrid.ems.ASG_SP.deserializeBinaryFromReader);
      msg.setAsgSp(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.ASG_SG;
      reader.readMessage(value,proto.de.mypowergrid.ems.ASG_SG.deserializeBinaryFromReader);
      msg.setAsgSg(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.ENS_DS;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_DS.deserializeBinaryFromReader);
      msg.setEnsDs(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.ENS_GridState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_GridState.deserializeBinaryFromReader);
      msg.setEnsGridstate(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.ENG_SP_ModeCtrl;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.deserializeBinaryFromReader);
      msg.setEngSpModectrl(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.ems.TSG_SG;
      reader.readMessage(value,proto.de.mypowergrid.ems.TSG_SG.deserializeBinaryFromReader);
      msg.setTsgSg(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.ems.SPS;
      reader.readMessage(value,proto.de.mypowergrid.ems.SPS.deserializeBinaryFromReader);
      msg.setSps(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.ems.SAV;
      reader.readMessage(value,proto.de.mypowergrid.ems.SAV.deserializeBinaryFromReader);
      msg.setSav(value);
      break;
    case 15:
      var value = new proto.de.mypowergrid.ems.DPC;
      reader.readMessage(value,proto.de.mypowergrid.ems.DPC.deserializeBinaryFromReader);
      msg.setDpc(value);
      break;
    case 16:
      var value = new proto.de.mypowergrid.ems.SPC;
      reader.readMessage(value,proto.de.mypowergrid.ems.SPC.deserializeBinaryFromReader);
      msg.setSpc(value);
      break;
    case 17:
      var value = new proto.de.mypowergrid.ems.DEL;
      reader.readMessage(value,proto.de.mypowergrid.ems.DEL.deserializeBinaryFromReader);
      msg.setDel(value);
      break;
    case 18:
      var value = new proto.de.mypowergrid.ems.INS;
      reader.readMessage(value,proto.de.mypowergrid.ems.INS.deserializeBinaryFromReader);
      msg.setIns(value);
      break;
    case 19:
      var value = new proto.de.mypowergrid.ems.ENS_InverterState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_InverterState.deserializeBinaryFromReader);
      msg.setEnsInverterstate(value);
      break;
    case 20:
      var value = new proto.de.mypowergrid.ems.ENS_BatteryState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_BatteryState.deserializeBinaryFromReader);
      msg.setEnsBatterystate(value);
      break;
    case 21:
      var value = new proto.de.mypowergrid.ems.ENS_ConnTyp;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_ConnTyp.deserializeBinaryFromReader);
      msg.setEnsConntyp(value);
      break;
    case 22:
      var value = new proto.de.mypowergrid.ems.ENS_DerTyp;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_DerTyp.deserializeBinaryFromReader);
      msg.setEnsDertyp(value);
      break;
    case 23:
      var value = new proto.de.mypowergrid.ems.ENS_WallboxState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_WallboxState.deserializeBinaryFromReader);
      msg.setEnsWallboxstate(value);
      break;
    case 24:
      var value = new proto.de.mypowergrid.ems.ENS_HeatpumpOpMode;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.deserializeBinaryFromReader);
      msg.setEnsHeatpumpopmode(value);
      break;
    case 25:
      var value = new proto.de.mypowergrid.ems.ENG_HeatpumpOpMode;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.deserializeBinaryFromReader);
      msg.setEngHeatpumpopmode(value);
      break;
    case 26:
      var value = new proto.de.mypowergrid.ems.VSS;
      reader.readMessage(value,proto.de.mypowergrid.ems.VSS.deserializeBinaryFromReader);
      msg.setVss(value);
      break;
    case 27:
      var value = new proto.de.mypowergrid.ems.ENC_GridState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENC_GridState.deserializeBinaryFromReader);
      msg.setEncGridstate(value);
      break;
    case 28:
      var value = new proto.de.mypowergrid.ems.ENS_MatTyp;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_MatTyp.deserializeBinaryFromReader);
      msg.setEnsMattyp(value);
      break;
    case 29:
      var value = new proto.de.mypowergrid.ems.ENC_EMSError;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENC_EMSError.deserializeBinaryFromReader);
      msg.setEncEmsError(value);
      break;
    case 30:
      var value = new proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.deserializeBinaryFromReader);
      msg.setEnsExternalSetpointTarget(value);
      break;
    case 31:
      var value = new proto.de.mypowergrid.ems.ENS_PcsCcuState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_PcsCcuState.deserializeBinaryFromReader);
      msg.setEnsPcsCcuState(value);
      break;
    case 32:
      var value = new proto.de.mypowergrid.ems.ENS_PcsIpuState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_PcsIpuState.deserializeBinaryFromReader);
      msg.setEnsPcsIpuState(value);
      break;
    case 33:
      var value = new proto.de.mypowergrid.ems.ENS_PcsMcuState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_PcsMcuState.deserializeBinaryFromReader);
      msg.setEnsPcsMcuState(value);
      break;
    case 34:
      var value = new proto.de.mypowergrid.ems.ENS_GatewayState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_GatewayState.deserializeBinaryFromReader);
      msg.setEnsGatewayState(value);
      break;
    case 35:
      var value = new proto.de.mypowergrid.ems.GatewayDiscovery;
      reader.readMessage(value,proto.de.mypowergrid.ems.GatewayDiscovery.deserializeBinaryFromReader);
      msg.setGatewayDiscovery(value);
      break;
    case 36:
      var value = new proto.de.mypowergrid.ems.ENS_EzaState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_EzaState.deserializeBinaryFromReader);
      msg.setEnsEzaState(value);
      break;
    case 37:
      var value = new proto.de.mypowergrid.ems.ENC_DS;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENC_DS.deserializeBinaryFromReader);
      msg.setEncDs(value);
      break;
    case 38:
      var value = new proto.de.mypowergrid.ems.ENS_FireAlarmState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_FireAlarmState.deserializeBinaryFromReader);
      msg.setEnsFireAlarmState(value);
      break;
    case 39:
      var value = new proto.de.mypowergrid.ems.ENS_AirConditionerState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_AirConditionerState.deserializeBinaryFromReader);
      msg.setEnsAirConditionerState(value);
      break;
    case 40:
      var value = new proto.de.mypowergrid.ems.ENS_BehaviourMode;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_BehaviourMode.deserializeBinaryFromReader);
      msg.setEnsBehaviourMode(value);
      break;
    case 41:
      var value = new proto.de.mypowergrid.ems.DPS;
      reader.readMessage(value,proto.de.mypowergrid.ems.DPS.deserializeBinaryFromReader);
      msg.setDps(value);
      break;
    case 42:
      var value = new proto.de.mypowergrid.ems.ENS_KacoInvState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_KacoInvState.deserializeBinaryFromReader);
      msg.setEnsKacoInvState(value);
      break;
    case 43:
      var value = new proto.de.mypowergrid.ems.ENS_KacoPcuState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_KacoPcuState.deserializeBinaryFromReader);
      msg.setEnsKacoPcuState(value);
      break;
    case 44:
      var value = new proto.de.mypowergrid.ems.ENS_KacoPcuErrState;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENS_KacoPcuErrState.deserializeBinaryFromReader);
      msg.setEnsKacoPcuErrState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.DataObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.DataObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.DataObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DataObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalNodeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDpl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.DPL.serializeBinaryToWriter
    );
  }
  f = message.getMv();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.MV.serializeBinaryToWriter
    );
  }
  f = message.getBcr();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.BCR.serializeBinaryToWriter
    );
  }
  f = message.getWye();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.WYE.serializeBinaryToWriter
    );
  }
  f = message.getAsgSp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.ASG_SP.serializeBinaryToWriter
    );
  }
  f = message.getAsgSg();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.ASG_SG.serializeBinaryToWriter
    );
  }
  f = message.getEnsDs();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.ENS_DS.serializeBinaryToWriter
    );
  }
  f = message.getEnsGridstate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.ENS_GridState.serializeBinaryToWriter
    );
  }
  f = message.getEngSpModectrl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.serializeBinaryToWriter
    );
  }
  f = message.getTsgSg();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.ems.TSG_SG.serializeBinaryToWriter
    );
  }
  f = message.getSps();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.ems.SPS.serializeBinaryToWriter
    );
  }
  f = message.getSav();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.ems.SAV.serializeBinaryToWriter
    );
  }
  f = message.getDpc();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.de.mypowergrid.ems.DPC.serializeBinaryToWriter
    );
  }
  f = message.getSpc();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.de.mypowergrid.ems.SPC.serializeBinaryToWriter
    );
  }
  f = message.getDel();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.de.mypowergrid.ems.DEL.serializeBinaryToWriter
    );
  }
  f = message.getIns();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.de.mypowergrid.ems.INS.serializeBinaryToWriter
    );
  }
  f = message.getEnsInverterstate();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.de.mypowergrid.ems.ENS_InverterState.serializeBinaryToWriter
    );
  }
  f = message.getEnsBatterystate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.de.mypowergrid.ems.ENS_BatteryState.serializeBinaryToWriter
    );
  }
  f = message.getEnsConntyp();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.de.mypowergrid.ems.ENS_ConnTyp.serializeBinaryToWriter
    );
  }
  f = message.getEnsDertyp();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.de.mypowergrid.ems.ENS_DerTyp.serializeBinaryToWriter
    );
  }
  f = message.getEnsWallboxstate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.de.mypowergrid.ems.ENS_WallboxState.serializeBinaryToWriter
    );
  }
  f = message.getEnsHeatpumpopmode();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.serializeBinaryToWriter
    );
  }
  f = message.getEngHeatpumpopmode();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.serializeBinaryToWriter
    );
  }
  f = message.getVss();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.de.mypowergrid.ems.VSS.serializeBinaryToWriter
    );
  }
  f = message.getEncGridstate();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.de.mypowergrid.ems.ENC_GridState.serializeBinaryToWriter
    );
  }
  f = message.getEnsMattyp();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.de.mypowergrid.ems.ENS_MatTyp.serializeBinaryToWriter
    );
  }
  f = message.getEncEmsError();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.de.mypowergrid.ems.ENC_EMSError.serializeBinaryToWriter
    );
  }
  f = message.getEnsExternalSetpointTarget();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.serializeBinaryToWriter
    );
  }
  f = message.getEnsPcsCcuState();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.de.mypowergrid.ems.ENS_PcsCcuState.serializeBinaryToWriter
    );
  }
  f = message.getEnsPcsIpuState();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.de.mypowergrid.ems.ENS_PcsIpuState.serializeBinaryToWriter
    );
  }
  f = message.getEnsPcsMcuState();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.de.mypowergrid.ems.ENS_PcsMcuState.serializeBinaryToWriter
    );
  }
  f = message.getEnsGatewayState();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.de.mypowergrid.ems.ENS_GatewayState.serializeBinaryToWriter
    );
  }
  f = message.getGatewayDiscovery();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.de.mypowergrid.ems.GatewayDiscovery.serializeBinaryToWriter
    );
  }
  f = message.getEnsEzaState();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.de.mypowergrid.ems.ENS_EzaState.serializeBinaryToWriter
    );
  }
  f = message.getEncDs();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.de.mypowergrid.ems.ENC_DS.serializeBinaryToWriter
    );
  }
  f = message.getEnsFireAlarmState();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.de.mypowergrid.ems.ENS_FireAlarmState.serializeBinaryToWriter
    );
  }
  f = message.getEnsAirConditionerState();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.de.mypowergrid.ems.ENS_AirConditionerState.serializeBinaryToWriter
    );
  }
  f = message.getEnsBehaviourMode();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.de.mypowergrid.ems.ENS_BehaviourMode.serializeBinaryToWriter
    );
  }
  f = message.getDps();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.de.mypowergrid.ems.DPS.serializeBinaryToWriter
    );
  }
  f = message.getEnsKacoInvState();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.de.mypowergrid.ems.ENS_KacoInvState.serializeBinaryToWriter
    );
  }
  f = message.getEnsKacoPcuState();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.de.mypowergrid.ems.ENS_KacoPcuState.serializeBinaryToWriter
    );
  }
  f = message.getEnsKacoPcuErrState();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.de.mypowergrid.ems.ENS_KacoPcuErrState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string logical_node_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getLogicalNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.setLogicalNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DPL dpl = 3;
 * @return {?proto.de.mypowergrid.ems.DPL}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getDpl = function() {
  return /** @type{?proto.de.mypowergrid.ems.DPL} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.DPL, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.DPL|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setDpl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearDpl = function() {
  return this.setDpl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasDpl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MV mv = 4;
 * @return {?proto.de.mypowergrid.ems.MV}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getMv = function() {
  return /** @type{?proto.de.mypowergrid.ems.MV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.MV, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.MV|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setMv = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearMv = function() {
  return this.setMv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasMv = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BCR bcr = 5;
 * @return {?proto.de.mypowergrid.ems.BCR}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getBcr = function() {
  return /** @type{?proto.de.mypowergrid.ems.BCR} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.BCR, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.BCR|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setBcr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearBcr = function() {
  return this.setBcr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasBcr = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WYE wye = 6;
 * @return {?proto.de.mypowergrid.ems.WYE}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getWye = function() {
  return /** @type{?proto.de.mypowergrid.ems.WYE} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.WYE, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.WYE|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setWye = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearWye = function() {
  return this.setWye(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasWye = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ASG_SP asg_sp = 7;
 * @return {?proto.de.mypowergrid.ems.ASG_SP}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getAsgSp = function() {
  return /** @type{?proto.de.mypowergrid.ems.ASG_SP} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ASG_SP, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.ASG_SP|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setAsgSp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearAsgSp = function() {
  return this.setAsgSp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasAsgSp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ASG_SG asg_sg = 8;
 * @return {?proto.de.mypowergrid.ems.ASG_SG}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getAsgSg = function() {
  return /** @type{?proto.de.mypowergrid.ems.ASG_SG} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ASG_SG, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.ASG_SG|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setAsgSg = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearAsgSg = function() {
  return this.setAsgSg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasAsgSg = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ENS_DS ens_ds = 9;
 * @return {?proto.de.mypowergrid.ems.ENS_DS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsDs = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_DS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_DS, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_DS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsDs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsDs = function() {
  return this.setEnsDs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsDs = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ENS_GridState ens_gridstate = 10;
 * @return {?proto.de.mypowergrid.ems.ENS_GridState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsGridstate = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_GridState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_GridState, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_GridState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsGridstate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsGridstate = function() {
  return this.setEnsGridstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsGridstate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ENG_SP_ModeCtrl eng_sp_modectrl = 11;
 * @return {?proto.de.mypowergrid.ems.ENG_SP_ModeCtrl}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEngSpModectrl = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENG_SP_ModeCtrl} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENG_SP_ModeCtrl, 11));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENG_SP_ModeCtrl|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEngSpModectrl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEngSpModectrl = function() {
  return this.setEngSpModectrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEngSpModectrl = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TSG_SG tsg_sg = 12;
 * @return {?proto.de.mypowergrid.ems.TSG_SG}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getTsgSg = function() {
  return /** @type{?proto.de.mypowergrid.ems.TSG_SG} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.TSG_SG, 12));
};


/**
 * @param {?proto.de.mypowergrid.ems.TSG_SG|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setTsgSg = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearTsgSg = function() {
  return this.setTsgSg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasTsgSg = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SPS sps = 13;
 * @return {?proto.de.mypowergrid.ems.SPS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getSps = function() {
  return /** @type{?proto.de.mypowergrid.ems.SPS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.SPS, 13));
};


/**
 * @param {?proto.de.mypowergrid.ems.SPS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setSps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearSps = function() {
  return this.setSps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasSps = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional SAV sav = 14;
 * @return {?proto.de.mypowergrid.ems.SAV}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getSav = function() {
  return /** @type{?proto.de.mypowergrid.ems.SAV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.SAV, 14));
};


/**
 * @param {?proto.de.mypowergrid.ems.SAV|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setSav = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearSav = function() {
  return this.setSav(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasSav = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional DPC dpc = 15;
 * @return {?proto.de.mypowergrid.ems.DPC}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getDpc = function() {
  return /** @type{?proto.de.mypowergrid.ems.DPC} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.DPC, 15));
};


/**
 * @param {?proto.de.mypowergrid.ems.DPC|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setDpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearDpc = function() {
  return this.setDpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasDpc = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional SPC spc = 16;
 * @return {?proto.de.mypowergrid.ems.SPC}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getSpc = function() {
  return /** @type{?proto.de.mypowergrid.ems.SPC} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.SPC, 16));
};


/**
 * @param {?proto.de.mypowergrid.ems.SPC|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setSpc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearSpc = function() {
  return this.setSpc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasSpc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional DEL del = 17;
 * @return {?proto.de.mypowergrid.ems.DEL}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getDel = function() {
  return /** @type{?proto.de.mypowergrid.ems.DEL} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.DEL, 17));
};


/**
 * @param {?proto.de.mypowergrid.ems.DEL|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setDel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearDel = function() {
  return this.setDel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasDel = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional INS ins = 18;
 * @return {?proto.de.mypowergrid.ems.INS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getIns = function() {
  return /** @type{?proto.de.mypowergrid.ems.INS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.INS, 18));
};


/**
 * @param {?proto.de.mypowergrid.ems.INS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setIns = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearIns = function() {
  return this.setIns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasIns = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ENS_InverterState ens_inverterstate = 19;
 * @return {?proto.de.mypowergrid.ems.ENS_InverterState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsInverterstate = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_InverterState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_InverterState, 19));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_InverterState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsInverterstate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsInverterstate = function() {
  return this.setEnsInverterstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsInverterstate = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ENS_BatteryState ens_batterystate = 20;
 * @return {?proto.de.mypowergrid.ems.ENS_BatteryState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsBatterystate = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_BatteryState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_BatteryState, 20));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_BatteryState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsBatterystate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsBatterystate = function() {
  return this.setEnsBatterystate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsBatterystate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional ENS_ConnTyp ens_conntyp = 21;
 * @return {?proto.de.mypowergrid.ems.ENS_ConnTyp}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsConntyp = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_ConnTyp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_ConnTyp, 21));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_ConnTyp|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsConntyp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsConntyp = function() {
  return this.setEnsConntyp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsConntyp = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional ENS_DerTyp ens_dertyp = 22;
 * @return {?proto.de.mypowergrid.ems.ENS_DerTyp}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsDertyp = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_DerTyp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_DerTyp, 22));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_DerTyp|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsDertyp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsDertyp = function() {
  return this.setEnsDertyp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsDertyp = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional ENS_WallboxState ens_wallboxstate = 23;
 * @return {?proto.de.mypowergrid.ems.ENS_WallboxState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsWallboxstate = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_WallboxState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_WallboxState, 23));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_WallboxState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsWallboxstate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsWallboxstate = function() {
  return this.setEnsWallboxstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsWallboxstate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional ENS_HeatpumpOpMode ens_heatpumpopmode = 24;
 * @return {?proto.de.mypowergrid.ems.ENS_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsHeatpumpopmode = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_HeatpumpOpMode, 24));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_HeatpumpOpMode|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsHeatpumpopmode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsHeatpumpopmode = function() {
  return this.setEnsHeatpumpopmode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsHeatpumpopmode = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional ENG_HeatpumpOpMode eng_heatpumpopmode = 25;
 * @return {?proto.de.mypowergrid.ems.ENG_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEngHeatpumpopmode = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENG_HeatpumpOpMode} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENG_HeatpumpOpMode, 25));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENG_HeatpumpOpMode|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEngHeatpumpopmode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEngHeatpumpopmode = function() {
  return this.setEngHeatpumpopmode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEngHeatpumpopmode = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional VSS vss = 26;
 * @return {?proto.de.mypowergrid.ems.VSS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getVss = function() {
  return /** @type{?proto.de.mypowergrid.ems.VSS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.VSS, 26));
};


/**
 * @param {?proto.de.mypowergrid.ems.VSS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setVss = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearVss = function() {
  return this.setVss(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasVss = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional ENC_GridState enc_gridstate = 27;
 * @return {?proto.de.mypowergrid.ems.ENC_GridState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEncGridstate = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENC_GridState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENC_GridState, 27));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENC_GridState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEncGridstate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEncGridstate = function() {
  return this.setEncGridstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEncGridstate = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional ENS_MatTyp ens_mattyp = 28;
 * @return {?proto.de.mypowergrid.ems.ENS_MatTyp}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsMattyp = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_MatTyp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_MatTyp, 28));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_MatTyp|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsMattyp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsMattyp = function() {
  return this.setEnsMattyp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsMattyp = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional ENC_EMSError enc_ems_error = 29;
 * @return {?proto.de.mypowergrid.ems.ENC_EMSError}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEncEmsError = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENC_EMSError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENC_EMSError, 29));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENC_EMSError|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEncEmsError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEncEmsError = function() {
  return this.setEncEmsError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEncEmsError = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional ENS_ExternalSetpointTarget ens_external_setpoint_target = 30;
 * @return {?proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsExternalSetpointTarget = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget, 30));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsExternalSetpointTarget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsExternalSetpointTarget = function() {
  return this.setEnsExternalSetpointTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsExternalSetpointTarget = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional ENS_PcsCcuState ens_pcs_ccu_state = 31;
 * @return {?proto.de.mypowergrid.ems.ENS_PcsCcuState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsPcsCcuState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_PcsCcuState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_PcsCcuState, 31));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_PcsCcuState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsPcsCcuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsPcsCcuState = function() {
  return this.setEnsPcsCcuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsPcsCcuState = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional ENS_PcsIpuState ens_pcs_ipu_state = 32;
 * @return {?proto.de.mypowergrid.ems.ENS_PcsIpuState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsPcsIpuState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_PcsIpuState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_PcsIpuState, 32));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_PcsIpuState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsPcsIpuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsPcsIpuState = function() {
  return this.setEnsPcsIpuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsPcsIpuState = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional ENS_PcsMcuState ens_pcs_mcu_state = 33;
 * @return {?proto.de.mypowergrid.ems.ENS_PcsMcuState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsPcsMcuState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_PcsMcuState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_PcsMcuState, 33));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_PcsMcuState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsPcsMcuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsPcsMcuState = function() {
  return this.setEnsPcsMcuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsPcsMcuState = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional ENS_GatewayState ens_gateway_state = 34;
 * @return {?proto.de.mypowergrid.ems.ENS_GatewayState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsGatewayState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_GatewayState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_GatewayState, 34));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_GatewayState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsGatewayState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsGatewayState = function() {
  return this.setEnsGatewayState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsGatewayState = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional GatewayDiscovery gateway_discovery = 35;
 * @return {?proto.de.mypowergrid.ems.GatewayDiscovery}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getGatewayDiscovery = function() {
  return /** @type{?proto.de.mypowergrid.ems.GatewayDiscovery} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.GatewayDiscovery, 35));
};


/**
 * @param {?proto.de.mypowergrid.ems.GatewayDiscovery|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setGatewayDiscovery = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearGatewayDiscovery = function() {
  return this.setGatewayDiscovery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasGatewayDiscovery = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional ENS_EzaState ens_eza_state = 36;
 * @return {?proto.de.mypowergrid.ems.ENS_EzaState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsEzaState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_EzaState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_EzaState, 36));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_EzaState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsEzaState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsEzaState = function() {
  return this.setEnsEzaState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsEzaState = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional ENC_DS enc_ds = 37;
 * @return {?proto.de.mypowergrid.ems.ENC_DS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEncDs = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENC_DS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENC_DS, 37));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENC_DS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEncDs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEncDs = function() {
  return this.setEncDs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEncDs = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional ENS_FireAlarmState ens_fire_alarm_state = 38;
 * @return {?proto.de.mypowergrid.ems.ENS_FireAlarmState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsFireAlarmState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_FireAlarmState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_FireAlarmState, 38));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_FireAlarmState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsFireAlarmState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsFireAlarmState = function() {
  return this.setEnsFireAlarmState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsFireAlarmState = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional ENS_AirConditionerState ens_air_conditioner_state = 39;
 * @return {?proto.de.mypowergrid.ems.ENS_AirConditionerState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsAirConditionerState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_AirConditionerState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_AirConditionerState, 39));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_AirConditionerState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsAirConditionerState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsAirConditionerState = function() {
  return this.setEnsAirConditionerState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsAirConditionerState = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional ENS_BehaviourMode ens_behaviour_mode = 40;
 * @return {?proto.de.mypowergrid.ems.ENS_BehaviourMode}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsBehaviourMode = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_BehaviourMode} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_BehaviourMode, 40));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_BehaviourMode|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsBehaviourMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsBehaviourMode = function() {
  return this.setEnsBehaviourMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsBehaviourMode = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional DPS dps = 41;
 * @return {?proto.de.mypowergrid.ems.DPS}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getDps = function() {
  return /** @type{?proto.de.mypowergrid.ems.DPS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.DPS, 41));
};


/**
 * @param {?proto.de.mypowergrid.ems.DPS|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setDps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearDps = function() {
  return this.setDps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasDps = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional ENS_KacoInvState ens_kaco_inv_state = 42;
 * @return {?proto.de.mypowergrid.ems.ENS_KacoInvState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsKacoInvState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_KacoInvState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_KacoInvState, 42));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_KacoInvState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsKacoInvState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsKacoInvState = function() {
  return this.setEnsKacoInvState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsKacoInvState = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional ENS_KacoPcuState ens_kaco_pcu_state = 43;
 * @return {?proto.de.mypowergrid.ems.ENS_KacoPcuState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsKacoPcuState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_KacoPcuState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_KacoPcuState, 43));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_KacoPcuState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsKacoPcuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsKacoPcuState = function() {
  return this.setEnsKacoPcuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsKacoPcuState = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional ENS_KacoPcuErrState ens_kaco_pcu_err_state = 44;
 * @return {?proto.de.mypowergrid.ems.ENS_KacoPcuErrState}
 */
proto.de.mypowergrid.ems.DataObject.prototype.getEnsKacoPcuErrState = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENS_KacoPcuErrState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENS_KacoPcuErrState, 44));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENS_KacoPcuErrState|undefined} value
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
*/
proto.de.mypowergrid.ems.DataObject.prototype.setEnsKacoPcuErrState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.de.mypowergrid.ems.DataObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DataObject} returns this
 */
proto.de.mypowergrid.ems.DataObject.prototype.clearEnsKacoPcuErrState = function() {
  return this.setEnsKacoPcuErrState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DataObject.prototype.hasEnsKacoPcuErrState = function() {
  return jspb.Message.getField(this, 44) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.AnalogueValue.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.AnalogueValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.AnalogueValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.AnalogueValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    f: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.AnalogueValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.AnalogueValue;
  return proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.AnalogueValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setF(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.AnalogueValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.AnalogueValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getF();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float f = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.AnalogueValue.prototype.getF = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.AnalogueValue} returns this
 */
proto.de.mypowergrid.ems.AnalogueValue.prototype.setF = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.Quality.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.Quality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.Quality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Quality.toObject = function(includeInstance, msg) {
  var f, obj = {
    validity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.Quality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.Quality;
  return proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.Quality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.Quality.ValidType} */ (reader.readEnum());
      msg.setValidity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.Quality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.Quality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.Quality.ValidType = {
  GOOD: 0,
  INVALID: 1,
  QUESTIONABLE: 2
};

/**
 * optional ValidType validity = 1;
 * @return {!proto.de.mypowergrid.ems.Quality.ValidType}
 */
proto.de.mypowergrid.ems.Quality.prototype.getValidity = function() {
  return /** @type {!proto.de.mypowergrid.ems.Quality.ValidType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Quality.ValidType} value
 * @return {!proto.de.mypowergrid.ems.Quality} returns this
 */
proto.de.mypowergrid.ems.Quality.prototype.setValidity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    fractionofsecond: jspb.Message.getFieldWithDefault(msg, 1, 0),
    secondsinceepoch: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.Timestamp;
  return proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFractionofsecond(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSecondsinceepoch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFractionofsecond();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSecondsinceepoch();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional uint32 FractionOfSecond = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.Timestamp.prototype.getFractionofsecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.Timestamp} returns this
 */
proto.de.mypowergrid.ems.Timestamp.prototype.setFractionofsecond = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 SecondSinceEpoch = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.Timestamp.prototype.getSecondsinceepoch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.Timestamp} returns this
 */
proto.de.mypowergrid.ems.Timestamp.prototype.setSecondsinceepoch = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.Vector.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.Vector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.Vector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Vector.toObject = function(includeInstance, msg) {
  var f, obj = {
    mag: (f = msg.getMag()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.Vector}
 */
proto.de.mypowergrid.ems.Vector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.Vector;
  return proto.de.mypowergrid.ems.Vector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.Vector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.Vector}
 */
proto.de.mypowergrid.ems.Vector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setMag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.Vector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.Vector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.Vector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.Vector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional AnalogueValue mag = 1;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.Vector.prototype.getMag = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.Vector} returns this
*/
proto.de.mypowergrid.ems.Vector.prototype.setMag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.Vector} returns this
 */
proto.de.mypowergrid.ems.Vector.prototype.clearMag = function() {
  return this.setMag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.Vector.prototype.hasMag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.CMV.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.CMV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.CMV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.CMV.toObject = function(includeInstance, msg) {
  var f, obj = {
    cval: (f = msg.getCval()) && proto.de.mypowergrid.ems.Vector.toObject(includeInstance, f),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.CMV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.CMV;
  return proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.CMV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.Vector;
      reader.readMessage(value,proto.de.mypowergrid.ems.Vector.deserializeBinaryFromReader);
      msg.setCval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.CMV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.CMV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCval();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.Vector.serializeBinaryToWriter
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional Vector cVal = 1;
 * @return {?proto.de.mypowergrid.ems.Vector}
 */
proto.de.mypowergrid.ems.CMV.prototype.getCval = function() {
  return /** @type{?proto.de.mypowergrid.ems.Vector} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Vector, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.Vector|undefined} value
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
*/
proto.de.mypowergrid.ems.CMV.prototype.setCval = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
 */
proto.de.mypowergrid.ems.CMV.prototype.clearCval = function() {
  return this.setCval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.CMV.prototype.hasCval = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.CMV.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
*/
proto.de.mypowergrid.ems.CMV.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
 */
proto.de.mypowergrid.ems.CMV.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.CMV.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.CMV.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
*/
proto.de.mypowergrid.ems.CMV.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
 */
proto.de.mypowergrid.ems.CMV.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.CMV.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Unit units = 4;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.CMV.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.CMV} returns this
 */
proto.de.mypowergrid.ems.CMV.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.DPL.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.DPL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.DPL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPL.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    swrev: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sernum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    configid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.DPL}
 */
proto.de.mypowergrid.ems.DPL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.DPL;
  return proto.de.mypowergrid.ems.DPL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.DPL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.DPL}
 */
proto.de.mypowergrid.ems.DPL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwrev(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSernum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.DPL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.DPL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.DPL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSwrev();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSernum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConfigid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string vendor = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string swRev = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getSwrev = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setSwrev = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serNum = 3;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getSernum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setSernum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string configId = 6;
 * @return {string}
 */
proto.de.mypowergrid.ems.DPL.prototype.getConfigid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.DPL} returns this
 */
proto.de.mypowergrid.ems.DPL.prototype.setConfigid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.MV.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.MV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.MV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.MV.toObject = function(includeInstance, msg) {
  var f, obj = {
    mag: (f = msg.getMag()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timeout: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.MV}
 */
proto.de.mypowergrid.ems.MV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.MV;
  return proto.de.mypowergrid.ems.MV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.MV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.MV}
 */
proto.de.mypowergrid.ems.MV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setMag(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.MV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.MV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.MV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.MV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTimeout();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional AnalogueValue mag = 1;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.MV.prototype.getMag = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.MV} returns this
*/
proto.de.mypowergrid.ems.MV.prototype.setMag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.MV} returns this
 */
proto.de.mypowergrid.ems.MV.prototype.clearMag = function() {
  return this.setMag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.MV.prototype.hasMag = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.MV.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.MV} returns this
*/
proto.de.mypowergrid.ems.MV.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.MV} returns this
 */
proto.de.mypowergrid.ems.MV.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.MV.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.MV.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.MV} returns this
*/
proto.de.mypowergrid.ems.MV.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.MV} returns this
 */
proto.de.mypowergrid.ems.MV.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.MV.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Unit units = 4;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.MV.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.MV} returns this
 */
proto.de.mypowergrid.ems.MV.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 Timeout = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.MV.prototype.getTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.MV} returns this
 */
proto.de.mypowergrid.ems.MV.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.SAV.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.SAV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.SAV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SAV.toObject = function(includeInstance, msg) {
  var f, obj = {
    instmag: (f = msg.getInstmag()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.SAV}
 */
proto.de.mypowergrid.ems.SAV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.SAV;
  return proto.de.mypowergrid.ems.SAV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.SAV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.SAV}
 */
proto.de.mypowergrid.ems.SAV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setInstmag(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.SAV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.SAV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.SAV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SAV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstmag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional AnalogueValue instMag = 1;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.SAV.prototype.getInstmag = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
*/
proto.de.mypowergrid.ems.SAV.prototype.setInstmag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
 */
proto.de.mypowergrid.ems.SAV.prototype.clearInstmag = function() {
  return this.setInstmag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SAV.prototype.hasInstmag = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.SAV.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
*/
proto.de.mypowergrid.ems.SAV.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
 */
proto.de.mypowergrid.ems.SAV.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SAV.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.SAV.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
*/
proto.de.mypowergrid.ems.SAV.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
 */
proto.de.mypowergrid.ems.SAV.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SAV.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Unit units = 4;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.SAV.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.SAV} returns this
 */
proto.de.mypowergrid.ems.SAV.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.BCR.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.BCR.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.BCR} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.BCR.toObject = function(includeInstance, msg) {
  var f, obj = {
    actval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pulsqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.BCR}
 */
proto.de.mypowergrid.ems.BCR.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.BCR;
  return proto.de.mypowergrid.ems.BCR.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.BCR} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.BCR}
 */
proto.de.mypowergrid.ems.BCR.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActval(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPulsqty(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 5:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.BCR.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.BCR.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.BCR} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.BCR.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActval();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPulsqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 actVal = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.BCR.prototype.getActval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
 */
proto.de.mypowergrid.ems.BCR.prototype.setActval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float pulsQty = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.BCR.prototype.getPulsqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
 */
proto.de.mypowergrid.ems.BCR.prototype.setPulsqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Quality q = 3;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.BCR.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
*/
proto.de.mypowergrid.ems.BCR.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
 */
proto.de.mypowergrid.ems.BCR.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.BCR.prototype.hasQ = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp t = 4;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.BCR.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
*/
proto.de.mypowergrid.ems.BCR.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
 */
proto.de.mypowergrid.ems.BCR.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.BCR.prototype.hasT = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Unit units = 5;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.BCR.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.BCR} returns this
 */
proto.de.mypowergrid.ems.BCR.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.WYE.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.WYE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.WYE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.WYE.toObject = function(includeInstance, msg) {
  var f, obj = {
    phsa: (f = msg.getPhsa()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f),
    phsb: (f = msg.getPhsb()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f),
    phsc: (f = msg.getPhsc()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.WYE}
 */
proto.de.mypowergrid.ems.WYE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.WYE;
  return proto.de.mypowergrid.ems.WYE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.WYE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.WYE}
 */
proto.de.mypowergrid.ems.WYE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsa(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsb(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.WYE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.WYE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.WYE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.WYE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhsa();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
  f = message.getPhsb();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
  f = message.getPhsc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
};


/**
 * optional CMV phsA = 1;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.WYE.prototype.getPhsa = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
*/
proto.de.mypowergrid.ems.WYE.prototype.setPhsa = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
 */
proto.de.mypowergrid.ems.WYE.prototype.clearPhsa = function() {
  return this.setPhsa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.WYE.prototype.hasPhsa = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CMV phsB = 2;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.WYE.prototype.getPhsb = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
*/
proto.de.mypowergrid.ems.WYE.prototype.setPhsb = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
 */
proto.de.mypowergrid.ems.WYE.prototype.clearPhsb = function() {
  return this.setPhsb(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.WYE.prototype.hasPhsb = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CMV phsC = 3;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.WYE.prototype.getPhsc = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
*/
proto.de.mypowergrid.ems.WYE.prototype.setPhsc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.WYE} returns this
 */
proto.de.mypowergrid.ems.WYE.prototype.clearPhsc = function() {
  return this.setPhsc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.WYE.prototype.hasPhsc = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ASG_SP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ASG_SP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ASG_SP.toObject = function(includeInstance, msg) {
  var f, obj = {
    setmag: (f = msg.getSetmag()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    minval: (f = msg.getMinval()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    maxval: (f = msg.getMaxval()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ASG_SP}
 */
proto.de.mypowergrid.ems.ASG_SP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ASG_SP;
  return proto.de.mypowergrid.ems.ASG_SP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ASG_SP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ASG_SP}
 */
proto.de.mypowergrid.ems.ASG_SP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setSetmag(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setMinval(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setMaxval(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ASG_SP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ASG_SP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ASG_SP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetmag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getMinval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getMaxval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional AnalogueValue setMag = 1;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.getSetmag = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
*/
proto.de.mypowergrid.ems.ASG_SP.prototype.setSetmag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.clearSetmag = function() {
  return this.setSetmag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.hasSetmag = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnalogueValue minVal = 2;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.getMinval = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
*/
proto.de.mypowergrid.ems.ASG_SP.prototype.setMinval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.clearMinval = function() {
  return this.setMinval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.hasMinval = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnalogueValue maxVal = 3;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.getMaxval = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
*/
proto.de.mypowergrid.ems.ASG_SP.prototype.setMaxval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.clearMaxval = function() {
  return this.setMaxval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.hasMaxval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Unit units = 4;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.ASG_SP} returns this
 */
proto.de.mypowergrid.ems.ASG_SP.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ASG_SG.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ASG_SG} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ASG_SG.toObject = function(includeInstance, msg) {
  var f, obj = {
    setmag: (f = msg.getSetmag()) && proto.de.mypowergrid.ems.AnalogueValue.toObject(includeInstance, f),
    units: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ASG_SG}
 */
proto.de.mypowergrid.ems.ASG_SG.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ASG_SG;
  return proto.de.mypowergrid.ems.ASG_SG.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ASG_SG} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ASG_SG}
 */
proto.de.mypowergrid.ems.ASG_SG.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.AnalogueValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.AnalogueValue.deserializeBinaryFromReader);
      msg.setSetmag(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.Unit} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ASG_SG.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ASG_SG} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ASG_SG.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetmag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.AnalogueValue.serializeBinaryToWriter
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional AnalogueValue setMag = 1;
 * @return {?proto.de.mypowergrid.ems.AnalogueValue}
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.getSetmag = function() {
  return /** @type{?proto.de.mypowergrid.ems.AnalogueValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.AnalogueValue, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.AnalogueValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.ASG_SG} returns this
*/
proto.de.mypowergrid.ems.ASG_SG.prototype.setSetmag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ASG_SG} returns this
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.clearSetmag = function() {
  return this.setSetmag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.hasSetmag = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Unit units = 2;
 * @return {!proto.de.mypowergrid.ems.Unit}
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.getUnits = function() {
  return /** @type {!proto.de.mypowergrid.ems.Unit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Unit} value
 * @return {!proto.de.mypowergrid.ems.ASG_SG} returns this
 */
proto.de.mypowergrid.ems.ASG_SG.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_BehaviourMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_BehaviourMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_BehaviourMode;
  return proto.de.mypowergrid.ems.ENS_BehaviourMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_BehaviourMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.BehaviourModeKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_BehaviourMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_BehaviourMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional BehaviourModeKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.BehaviourModeKind}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.BehaviourModeKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.BehaviourModeKind} value
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode} returns this
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode} returns this
*/
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode} returns this
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode} returns this
*/
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_BehaviourMode} returns this
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_BehaviourMode.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_DS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_DS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_DS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    addinfo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_DS}
 */
proto.de.mypowergrid.ems.ENS_DS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_DS;
  return proto.de.mypowergrid.ems.ENS_DS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_DS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_DS}
 */
proto.de.mypowergrid.ems.ENS_DS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_DS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_DS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_DS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAddinfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional HealthKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.HealthKind}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.HealthKind} value
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
*/
proto.de.mypowergrid.ems.ENS_DS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
*/
proto.de.mypowergrid.ems.ENS_DS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string addInfo = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.getAddinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.ENS_DS} returns this
 */
proto.de.mypowergrid.ems.ENS_DS.prototype.setAddinfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENC_DS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENC_DS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_DS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ctlval: jspb.Message.getFieldWithDefault(msg, 2, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    addinfo: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENC_DS}
 */
proto.de.mypowergrid.ems.ENC_DS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENC_DS;
  return proto.de.mypowergrid.ems.ENC_DS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENC_DS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENC_DS}
 */
proto.de.mypowergrid.ems.ENC_DS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (reader.readEnum());
      msg.setCtlval(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENC_DS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENC_DS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_DS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCtlval();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAddinfo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional HealthKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.HealthKind}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.HealthKind} value
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional HealthKind ctlVal = 2;
 * @return {!proto.de.mypowergrid.ems.HealthKind}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.getCtlval = function() {
  return /** @type {!proto.de.mypowergrid.ems.HealthKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.HealthKind} value
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.setCtlval = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Quality q = 3;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
*/
proto.de.mypowergrid.ems.ENC_DS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp t = 4;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
*/
proto.de.mypowergrid.ems.ENC_DS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.hasT = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string addInfo = 5;
 * @return {string}
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.getAddinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.ENC_DS} returns this
 */
proto.de.mypowergrid.ems.ENC_DS.prototype.setAddinfo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_GridState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_GridState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_GridState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_GridState}
 */
proto.de.mypowergrid.ems.ENS_GridState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_GridState;
  return proto.de.mypowergrid.ems.ENS_GridState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_GridState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_GridState}
 */
proto.de.mypowergrid.ems.ENS_GridState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.GridState} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_GridState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_GridState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_GridState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional GridState stVal = 1;
 * @return {!proto.de.mypowergrid.ems.GridState}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.GridState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.GridState} value
 * @return {!proto.de.mypowergrid.ems.ENS_GridState} returns this
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_GridState} returns this
*/
proto.de.mypowergrid.ems.ENS_GridState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_GridState} returns this
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_GridState} returns this
*/
proto.de.mypowergrid.ems.ENS_GridState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_GridState} returns this
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_GridState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENC_GridState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENC_GridState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_GridState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ctlval: jspb.Message.getFieldWithDefault(msg, 2, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENC_GridState}
 */
proto.de.mypowergrid.ems.ENC_GridState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENC_GridState;
  return proto.de.mypowergrid.ems.ENC_GridState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENC_GridState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENC_GridState}
 */
proto.de.mypowergrid.ems.ENC_GridState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.GridState} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.GridState} */ (reader.readEnum());
      msg.setCtlval(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENC_GridState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENC_GridState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_GridState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCtlval();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional GridState stVal = 1;
 * @return {!proto.de.mypowergrid.ems.GridState}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.GridState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.GridState} value
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional GridState ctlVal = 2;
 * @return {!proto.de.mypowergrid.ems.GridState}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.getCtlval = function() {
  return /** @type {!proto.de.mypowergrid.ems.GridState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.GridState} value
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.setCtlval = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Quality q = 3;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
*/
proto.de.mypowergrid.ems.ENC_GridState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp t = 4;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
*/
proto.de.mypowergrid.ems.ENC_GridState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_GridState} returns this
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_GridState.prototype.hasT = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENC_EMSError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_EMSError.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: (f = msg.getStval()) && proto.de.mypowergrid.ems.ENC_EMSError.Errors.toObject(includeInstance, f),
    ctlval: (f = msg.getCtlval()) && proto.de.mypowergrid.ems.ENC_EMSError.Errors.toObject(includeInstance, f),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError}
 */
proto.de.mypowergrid.ems.ENC_EMSError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENC_EMSError;
  return proto.de.mypowergrid.ems.ENC_EMSError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError}
 */
proto.de.mypowergrid.ems.ENC_EMSError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.ENC_EMSError.Errors;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENC_EMSError.Errors.deserializeBinaryFromReader);
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.ENC_EMSError.Errors;
      reader.readMessage(value,proto.de.mypowergrid.ems.ENC_EMSError.Errors.deserializeBinaryFromReader);
      msg.setCtlval(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENC_EMSError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_EMSError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.ENC_EMSError.Errors.serializeBinaryToWriter
    );
  }
  f = message.getCtlval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.ENC_EMSError.Errors.serializeBinaryToWriter
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENC_EMSError.Errors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.toObject = function(includeInstance, msg) {
  var f, obj = {
    pvControl: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    batControl: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    gridMeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    pvMeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    batMeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    consumptionMeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    socMeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENC_EMSError.Errors;
  return proto.de.mypowergrid.ems.ENC_EMSError.Errors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPvControl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBatControl(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGridMeasurement(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPvMeasurement(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBatMeasurement(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsumptionMeasurement(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSocMeasurement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENC_EMSError.Errors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPvControl();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBatControl();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getGridMeasurement();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPvMeasurement();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBatMeasurement();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getConsumptionMeasurement();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSocMeasurement();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool pv_control = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getPvControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setPvControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool bat_control = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getBatControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setBatControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool grid_measurement = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getGridMeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setGridMeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool pv_measurement = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getPvMeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setPvMeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool bat_measurement = 5;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getBatMeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setBatMeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool consumption_measurement = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getConsumptionMeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setConsumptionMeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool soc_measurement = 7;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.getSocMeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError.Errors} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.Errors.prototype.setSocMeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Errors stVal = 1;
 * @return {?proto.de.mypowergrid.ems.ENC_EMSError.Errors}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.getStval = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENC_EMSError.Errors} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENC_EMSError.Errors, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENC_EMSError.Errors|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
*/
proto.de.mypowergrid.ems.ENC_EMSError.prototype.setStval = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.clearStval = function() {
  return this.setStval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.hasStval = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Errors ctlVal = 2;
 * @return {?proto.de.mypowergrid.ems.ENC_EMSError.Errors}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.getCtlval = function() {
  return /** @type{?proto.de.mypowergrid.ems.ENC_EMSError.Errors} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.ENC_EMSError.Errors, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.ENC_EMSError.Errors|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
*/
proto.de.mypowergrid.ems.ENC_EMSError.prototype.setCtlval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.clearCtlval = function() {
  return this.setCtlval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.hasCtlval = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Quality q = 3;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
*/
proto.de.mypowergrid.ems.ENC_EMSError.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.hasQ = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp t = 4;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
*/
proto.de.mypowergrid.ems.ENC_EMSError.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENC_EMSError} returns this
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENC_EMSError.prototype.hasT = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget;
  return proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value = {
  OFF: 0,
  GRID: 1,
  PV: 2,
  BAT: 3
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} returns this
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} returns this
*/
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} returns this
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} returns this
*/
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget} returns this
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_ExternalSetpointTarget.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_InverterState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_InverterState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_InverterState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState}
 */
proto.de.mypowergrid.ems.ENS_InverterState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_InverterState;
  return proto.de.mypowergrid.ems.ENS_InverterState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_InverterState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState}
 */
proto.de.mypowergrid.ems.ENS_InverterState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_InverterState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_InverterState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_InverterState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_InverterState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_InverterState.Value = {
  UNKNOWN: 0,
  OFF: 1,
  SLEEPING: 2,
  STARTING: 3,
  MPPT: 4,
  THROTTLED: 5,
  SHUTTING_DOWN: 6,
  FAULT: 7,
  STANDBY: 8,
  ON: 9
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState.Value}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_InverterState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_InverterState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState} returns this
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState} returns this
*/
proto.de.mypowergrid.ems.ENS_InverterState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState} returns this
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState} returns this
*/
proto.de.mypowergrid.ems.ENS_InverterState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_InverterState} returns this
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_InverterState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_BatteryState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_BatteryState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_BatteryState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_BatteryState;
  return proto.de.mypowergrid.ems.ENS_BatteryState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_BatteryState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_BatteryState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_BatteryState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_BatteryState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_BatteryState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.Value = {
  UNKNOWN: 0,
  OFF: 1,
  STANDBY: 2,
  CHARGING: 3,
  DISCHARGING: 4,
  INIT: 5,
  FAULT: 6,
  IDLE: 7
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState.Value}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_BatteryState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_BatteryState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState} returns this
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState} returns this
*/
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState} returns this
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState} returns this
*/
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_BatteryState} returns this
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_BatteryState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    setval: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENG_SP_ModeCtrl;
  return proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} */ (reader.readEnum());
      msg.setSetval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value = {
  UNKNOWN: 0,
  IDLE: 1,
  NORMAL: 2,
  OPTIMISTIC: 3,
  ACTIVE: 4
};

/**
 * optional Value setVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value}
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.prototype.getSetval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} value
 * @return {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl} returns this
 */
proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.prototype.setSetval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.TSG_SG.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.TSG_SG.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.TSG_SG} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TSG_SG.toObject = function(includeInstance, msg) {
  var f, obj = {
    settm: (f = msg.getSettm()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.TSG_SG}
 */
proto.de.mypowergrid.ems.TSG_SG.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.TSG_SG;
  return proto.de.mypowergrid.ems.TSG_SG.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.TSG_SG} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.TSG_SG}
 */
proto.de.mypowergrid.ems.TSG_SG.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setSettm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.TSG_SG.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.TSG_SG.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.TSG_SG} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TSG_SG.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Timestamp setTm = 1;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.TSG_SG.prototype.getSettm = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.TSG_SG} returns this
*/
proto.de.mypowergrid.ems.TSG_SG.prototype.setSettm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.TSG_SG} returns this
 */
proto.de.mypowergrid.ems.TSG_SG.prototype.clearSettm = function() {
  return this.setSettm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.TSG_SG.prototype.hasSettm = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.SPS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.SPS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.SPS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SPS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.SPS}
 */
proto.de.mypowergrid.ems.SPS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.SPS;
  return proto.de.mypowergrid.ems.SPS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.SPS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.SPS}
 */
proto.de.mypowergrid.ems.SPS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.SPS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.SPS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.SPS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SPS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool stVal = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPS.prototype.getStval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.SPS} returns this
 */
proto.de.mypowergrid.ems.SPS.prototype.setStval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.SPS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.SPS} returns this
*/
proto.de.mypowergrid.ems.SPS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SPS} returns this
 */
proto.de.mypowergrid.ems.SPS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.SPS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.SPS} returns this
*/
proto.de.mypowergrid.ems.SPS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SPS} returns this
 */
proto.de.mypowergrid.ems.SPS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPS.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.SPC.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.SPC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.SPC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SPC.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f),
    ctlval: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.SPC}
 */
proto.de.mypowergrid.ems.SPC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.SPC;
  return proto.de.mypowergrid.ems.SPC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.SPC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.SPC}
 */
proto.de.mypowergrid.ems.SPC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCtlval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.SPC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.SPC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.SPC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.SPC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCtlval();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool stVal = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPC.prototype.getStval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
 */
proto.de.mypowergrid.ems.SPC.prototype.setStval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.SPC.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
*/
proto.de.mypowergrid.ems.SPC.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
 */
proto.de.mypowergrid.ems.SPC.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPC.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.SPC.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
*/
proto.de.mypowergrid.ems.SPC.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
 */
proto.de.mypowergrid.ems.SPC.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPC.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool ctlVal = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.SPC.prototype.getCtlval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.SPC} returns this
 */
proto.de.mypowergrid.ems.SPC.prototype.setCtlval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.DPC.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.DPC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.DPC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPC.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ctlval: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.DPC}
 */
proto.de.mypowergrid.ems.DPC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.DPC;
  return proto.de.mypowergrid.ems.DPC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.DPC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.DPC}
 */
proto.de.mypowergrid.ems.DPC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.DpStatusKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCtlval(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.DPC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.DPC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.DPC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCtlval();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional DpStatusKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.DpStatusKind}
 */
proto.de.mypowergrid.ems.DPC.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.DpStatusKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.DpStatusKind} value
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
 */
proto.de.mypowergrid.ems.DPC.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool ctlVal = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DPC.prototype.getCtlval = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
 */
proto.de.mypowergrid.ems.DPC.prototype.setCtlval = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Quality q = 3;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.DPC.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
*/
proto.de.mypowergrid.ems.DPC.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
 */
proto.de.mypowergrid.ems.DPC.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DPC.prototype.hasQ = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp t = 4;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.DPC.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
*/
proto.de.mypowergrid.ems.DPC.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DPC} returns this
 */
proto.de.mypowergrid.ems.DPC.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DPC.prototype.hasT = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.DEL.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.DEL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.DEL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DEL.toObject = function(includeInstance, msg) {
  var f, obj = {
    phsab: (f = msg.getPhsab()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f),
    phsbc: (f = msg.getPhsbc()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f),
    phsca: (f = msg.getPhsca()) && proto.de.mypowergrid.ems.CMV.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.DEL}
 */
proto.de.mypowergrid.ems.DEL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.DEL;
  return proto.de.mypowergrid.ems.DEL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.DEL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.DEL}
 */
proto.de.mypowergrid.ems.DEL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsab(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsbc(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.CMV;
      reader.readMessage(value,proto.de.mypowergrid.ems.CMV.deserializeBinaryFromReader);
      msg.setPhsca(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.DEL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.DEL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.DEL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DEL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhsab();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
  f = message.getPhsbc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
  f = message.getPhsca();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.CMV.serializeBinaryToWriter
    );
  }
};


/**
 * optional CMV phsAB = 1;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.DEL.prototype.getPhsab = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
*/
proto.de.mypowergrid.ems.DEL.prototype.setPhsab = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
 */
proto.de.mypowergrid.ems.DEL.prototype.clearPhsab = function() {
  return this.setPhsab(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DEL.prototype.hasPhsab = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CMV phsBC = 2;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.DEL.prototype.getPhsbc = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
*/
proto.de.mypowergrid.ems.DEL.prototype.setPhsbc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
 */
proto.de.mypowergrid.ems.DEL.prototype.clearPhsbc = function() {
  return this.setPhsbc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DEL.prototype.hasPhsbc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CMV phsCA = 3;
 * @return {?proto.de.mypowergrid.ems.CMV}
 */
proto.de.mypowergrid.ems.DEL.prototype.getPhsca = function() {
  return /** @type{?proto.de.mypowergrid.ems.CMV} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.CMV, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.CMV|undefined} value
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
*/
proto.de.mypowergrid.ems.DEL.prototype.setPhsca = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DEL} returns this
 */
proto.de.mypowergrid.ems.DEL.prototype.clearPhsca = function() {
  return this.setPhsca(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DEL.prototype.hasPhsca = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.INS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.INS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.INS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.INS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.INS}
 */
proto.de.mypowergrid.ems.INS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.INS;
  return proto.de.mypowergrid.ems.INS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.INS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.INS}
 */
proto.de.mypowergrid.ems.INS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.INS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.INS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.INS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.INS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 stVal = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.INS.prototype.getStval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.INS} returns this
 */
proto.de.mypowergrid.ems.INS.prototype.setStval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.INS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.INS} returns this
*/
proto.de.mypowergrid.ems.INS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.INS} returns this
 */
proto.de.mypowergrid.ems.INS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.INS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.INS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.INS} returns this
*/
proto.de.mypowergrid.ems.INS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.INS} returns this
 */
proto.de.mypowergrid.ems.INS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.INS.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_ConnTyp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_ConnTyp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_ConnTyp;
  return proto.de.mypowergrid.ems.ENS_ConnTyp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_ConnTyp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_ConnTyp.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_ConnTyp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_ConnTyp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.Value = {
  UNKNOWN: 0,
  SINGLE_PHASE: 1,
  SPLIT_PHASE: 2,
  TWO_PHASE: 3,
  THREE_PHASE_DEL: 4,
  THREE_PHASE_WYE: 5,
  THREE_PHASE_WYE_GND: 6,
  THREE_PHASE_3WIRE: 7,
  THREE_PHASE_4WIRE: 8,
  OTHER: 99
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp.Value}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_ConnTyp.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_ConnTyp.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_ConnTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_ConnTyp.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_DerTyp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_DerTyp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_DerTyp.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_DerTyp;
  return proto.de.mypowergrid.ems.ENS_DerTyp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_DerTyp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_DerTyp.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_DerTyp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_DerTyp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_DerTyp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.Value = {
  UNKNOWN: 0,
  DIESEL_GAS_ENGINE: 1,
  TURBIN_ENGINE: 2,
  STIRLING_ENGINE: 3,
  STORAGE: 4,
  PV: 5,
  FUEL_CELL: 6,
  PV_STOR: 7,
  CHP: 8,
  ELECTROLYZER: 9,
  OTHER: 99
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp.Value}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_DerTyp.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_DerTyp.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_DerTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_DerTyp.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_WallboxState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_WallboxState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_WallboxState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_WallboxState;
  return proto.de.mypowergrid.ems.ENS_WallboxState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_WallboxState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_WallboxState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_WallboxState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_WallboxState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_WallboxState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.Value = {
  UNKNOWN: 0,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState.Value}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_WallboxState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_WallboxState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState} returns this
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState} returns this
*/
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState} returns this
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState} returns this
*/
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_WallboxState} returns this
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_WallboxState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_PcsCcuState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_PcsCcuState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_PcsCcuState;
  return proto.de.mypowergrid.ems.ENS_PcsCcuState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsCcuState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_PcsCcuState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_PcsCcuState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsCcuState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.Value = {
  UNKNOWN: 0,
  INIT: 1,
  IDLE: 2,
  PRECHARGE: 3,
  GO_IDLE: 4,
  CHARGED: 5,
  READY: 6,
  RUN: 7,
  ERROR: 8,
  PAUSE: 9,
  SYNC_TO_V: 10,
  BLACKSTART: 11,
  COMPENSATOR: 12,
  ISLANDING: 13,
  SIA: 14,
  FRT: 15,
  SYNCISLANDING: 16
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState.Value}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_PcsCcuState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_PcsCcuState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsCcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsCcuState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_PcsIpuState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_PcsIpuState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_PcsIpuState;
  return proto.de.mypowergrid.ems.ENS_PcsIpuState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsIpuState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_PcsIpuState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_PcsIpuState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsIpuState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.Value = {
  UNKNOWN: 0,
  OFFLINE: 1,
  INIT: 2,
  IDLE: 3,
  PRECHARGE: 4,
  GO_IDLE: 5,
  READY: 6,
  RUN: 7,
  ERROR: 8
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState.Value}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_PcsIpuState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_PcsIpuState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsIpuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsIpuState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_PcsMcuState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_PcsMcuState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_PcsMcuState;
  return proto.de.mypowergrid.ems.ENS_PcsMcuState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsMcuState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_PcsMcuState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_PcsMcuState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_PcsMcuState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.Value = {
  UNKNOWN: 0,
  STARTUP: 1,
  FAN_MONITOR: 2,
  HARD_TRIP: 3,
  WAIT_FOR_ENABLE: 4,
  ENABLED: 5,
  PRECHARGE_WAIT_FOR_VOLTAGE: 6,
  TRIP_PRECHG: 7,
  PRECHG_WAIT: 8,
  PRECHG_CANCELED: 9,
  HS_ON: 10,
  HS_ON_POST: 11,
  READY_EXTERN: 12,
  RUN_EXTERN: 13,
  TRIP: 14,
  TRANSIT_TRIP_OV_UV: 15,
  TRIP_OV: 16,
  TRIP_OVERCURRENT: 17
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState.Value}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_PcsMcuState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_PcsMcuState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_PcsMcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_PcsMcuState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_HeatpumpOpMode;
  return proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.HeatpumpOpMode} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional HeatpumpOpMode stVal = 1;
 * @return {!proto.de.mypowergrid.ems.HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.HeatpumpOpMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.HeatpumpOpMode} value
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} returns this
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} returns this
*/
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} returns this
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} returns this
*/
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_HeatpumpOpMode} returns this
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_HeatpumpOpMode.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    setval: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENG_HeatpumpOpMode;
  return proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.HeatpumpOpMode} */ (reader.readEnum());
      msg.setSetval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional HeatpumpOpMode setVal = 1;
 * @return {!proto.de.mypowergrid.ems.HeatpumpOpMode}
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.prototype.getSetval = function() {
  return /** @type {!proto.de.mypowergrid.ems.HeatpumpOpMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.HeatpumpOpMode} value
 * @return {!proto.de.mypowergrid.ems.ENG_HeatpumpOpMode} returns this
 */
proto.de.mypowergrid.ems.ENG_HeatpumpOpMode.prototype.setSetval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.VSS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.VSS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.VSS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.VSS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, ""),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.VSS}
 */
proto.de.mypowergrid.ems.VSS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.VSS;
  return proto.de.mypowergrid.ems.VSS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.VSS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.VSS}
 */
proto.de.mypowergrid.ems.VSS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.VSS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.VSS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.VSS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.VSS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stVal = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.VSS.prototype.getStval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.VSS} returns this
 */
proto.de.mypowergrid.ems.VSS.prototype.setStval = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.VSS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.VSS} returns this
*/
proto.de.mypowergrid.ems.VSS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.VSS} returns this
 */
proto.de.mypowergrid.ems.VSS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.VSS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.VSS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.VSS} returns this
*/
proto.de.mypowergrid.ems.VSS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.VSS} returns this
 */
proto.de.mypowergrid.ems.VSS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.VSS.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_MatTyp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_MatTyp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_MatTyp.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_MatTyp;
  return proto.de.mypowergrid.ems.ENS_MatTyp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_MatTyp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.MaterialKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_MatTyp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_MatTyp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_MatTyp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.MaterialKind}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.MaterialKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.MaterialKind} value
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp} returns this
*/
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_MatTyp} returns this
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_MatTyp.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_GatewayState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_GatewayState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_GatewayState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_GatewayState;
  return proto.de.mypowergrid.ems.ENS_GatewayState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_GatewayState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_GatewayState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_GatewayState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_GatewayState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_GatewayState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.Value = {
  UNKNOWN: 0,
  DISCOVERING: 1,
  NORMAL: 2
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState.Value}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_GatewayState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_GatewayState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState} returns this
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState} returns this
*/
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState} returns this
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState} returns this
*/
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_GatewayState} returns this
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_GatewayState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.GatewayDiscovery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.GatewayDiscovery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.GatewayDiscovery.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.toObject, includeInstance),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.GatewayDiscovery;
  return proto.de.mypowergrid.ems.GatewayDiscovery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo;
      reader.readMessage(value,proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.GatewayDiscovery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.GatewayDiscovery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState = {
  UNKNOWN: 0,
  EXPECTED_NOT_FOUND: 1,
  EXPECTED_AND_FOUND: 2,
  NOT_EXPECTED_BUT_FOUND: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uniqueid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addinfo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo;
  return proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUniqueid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddinfo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DiscoveryState state = 1;
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.getState = function() {
  return /** @type {!proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery.DiscoveryState} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string uniqueId = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.getUniqueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.setUniqueid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string addInfo = 3;
 * @return {string}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.getAddinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo.prototype.setAddinfo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DeviceInfo devices = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo>}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo>} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
*/
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.GatewayDiscovery.DeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
*/
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
*/
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.GatewayDiscovery} returns this
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.GatewayDiscovery.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_EzaState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_EzaState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_EzaState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState}
 */
proto.de.mypowergrid.ems.ENS_EzaState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_EzaState;
  return proto.de.mypowergrid.ems.ENS_EzaState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_EzaState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState}
 */
proto.de.mypowergrid.ems.ENS_EzaState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_EzaState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_EzaState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_EzaState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_EzaState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_EzaState.Value = {
  UNKNOWN: 0,
  EXTERNAL: 1,
  EXTERNAL_VNB: 2,
  EXTERNAL_DIRECT_MARKETER: 3,
  DEFAULTS: 4,
  EMS: 5
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState.Value}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_EzaState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_EzaState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState} returns this
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState} returns this
*/
proto.de.mypowergrid.ems.ENS_EzaState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState} returns this
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState} returns this
*/
proto.de.mypowergrid.ems.ENS_EzaState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_EzaState} returns this
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_EzaState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_FireAlarmState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_FireAlarmState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_FireAlarmState;
  return proto.de.mypowergrid.ems.ENS_FireAlarmState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_FireAlarmState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_FireAlarmState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_FireAlarmState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_FireAlarmState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.Value = {
  UNKNOWN: 0,
  OK: 1,
  WARNING: 2,
  ALARM: 3
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState.Value}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_FireAlarmState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_FireAlarmState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState} returns this
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState} returns this
*/
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState} returns this
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState} returns this
*/
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_FireAlarmState} returns this
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_FireAlarmState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_AirConditionerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_AirConditionerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_AirConditionerState;
  return proto.de.mypowergrid.ems.ENS_AirConditionerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_AirConditionerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_AirConditionerState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_AirConditionerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_AirConditionerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.Value = {
  UNKNOWN: 0,
  OK: 1,
  WARNING: 2,
  ALARM: 3,
  PREHEATING: 4,
  AUTOMATIC: 5,
  STANDBY: 6
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState.Value}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_AirConditionerState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_AirConditionerState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState} returns this
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState} returns this
*/
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState} returns this
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState} returns this
*/
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_AirConditionerState} returns this
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_AirConditionerState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.DPS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.DPS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.DPS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPS.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.DPS}
 */
proto.de.mypowergrid.ems.DPS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.DPS;
  return proto.de.mypowergrid.ems.DPS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.DPS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.DPS}
 */
proto.de.mypowergrid.ems.DPS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.DpStatusKind} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.DPS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.DPS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.DPS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.DPS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional DpStatusKind stVal = 1;
 * @return {!proto.de.mypowergrid.ems.DpStatusKind}
 */
proto.de.mypowergrid.ems.DPS.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.DpStatusKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.DpStatusKind} value
 * @return {!proto.de.mypowergrid.ems.DPS} returns this
 */
proto.de.mypowergrid.ems.DPS.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.DPS.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.DPS} returns this
*/
proto.de.mypowergrid.ems.DPS.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DPS} returns this
 */
proto.de.mypowergrid.ems.DPS.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DPS.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.DPS.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.DPS} returns this
*/
proto.de.mypowergrid.ems.DPS.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.DPS} returns this
 */
proto.de.mypowergrid.ems.DPS.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.DPS.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_KacoInvState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_KacoInvState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_KacoInvState;
  return proto.de.mypowergrid.ems.ENS_KacoInvState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoInvState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_KacoInvState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_KacoInvState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoInvState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.Value = {
  UNKNOWN: 0,
  OFF: 1,
  SLEEPING: 2,
  STARTING: 3,
  MPPT: 4,
  THROTTLED: 5,
  SHUTTING_DOWN: 6,
  FAULT: 7,
  STANDBY: 8,
  PRECHARGE: 9,
  GRID_PRE_CONNECTED: 10,
  GRID_CONNECTED: 11,
  NO_ERROR_PENDING: 12
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState.Value}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_KacoInvState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_KacoInvState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoInvState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoInvState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_KacoPcuState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_KacoPcuState;
  return proto.de.mypowergrid.ems.ENS_KacoPcuState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_KacoPcuState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_KacoPcuState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.Value = {
  UNKNOWN: 0,
  WAIT_FOR_STARTUP: 1,
  STANDBY: 2,
  SWITCH_REL_MINUS: 3,
  SWITCH_REL_PRECHARGE: 4,
  SWITCH_REL_PLUS: 5,
  RUNNING: 6,
  COOLDOWN: 7,
  ERROR: 8,
  CLEAR_ERROR: 9
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState.Value}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_KacoPcuState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.ENS_KacoPcuErrState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    q: (f = msg.getQ()) && proto.de.mypowergrid.ems.Quality.toObject(includeInstance, f),
    t: (f = msg.getT()) && proto.de.mypowergrid.ems.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.ENS_KacoPcuErrState;
  return proto.de.mypowergrid.ems.ENS_KacoPcuErrState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value} */ (reader.readEnum());
      msg.setStval(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.Quality;
      reader.readMessage(value,proto.de.mypowergrid.ems.Quality.deserializeBinaryFromReader);
      msg.setQ(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.Timestamp;
      reader.readMessage(value,proto.de.mypowergrid.ems.Timestamp.deserializeBinaryFromReader);
      msg.setT(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.ENS_KacoPcuErrState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.Quality.serializeBinaryToWriter
    );
  }
  f = message.getT();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value = {
  NO_EVENT: 0,
  OVER_TEMP: 1,
  OVER_VOLT: 2,
  UNDER_VOLT: 3,
  BATT_POL_INCORRECT: 4,
  COUNTER_TOO_HIGH: 5,
  DURING_PRECHARGE: 6,
  BATT_VOLT_OUT_OF_RANGE: 7,
  I2C_COMM: 8,
  CAN_COMM: 9,
  SWITCH_OFF_AC_DSP: 10
};

/**
 * optional Value stVal = 1;
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.getStval = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState.Value} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.setStval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Quality q = 2;
 * @return {?proto.de.mypowergrid.ems.Quality}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.getQ = function() {
  return /** @type{?proto.de.mypowergrid.ems.Quality} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Quality, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.Quality|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.setQ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.clearQ = function() {
  return this.setQ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.hasQ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp t = 3;
 * @return {?proto.de.mypowergrid.ems.Timestamp}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.getT = function() {
  return /** @type{?proto.de.mypowergrid.ems.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.Timestamp, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} returns this
*/
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.setT = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.ENS_KacoPcuErrState} returns this
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.clearT = function() {
  return this.setT(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.ENS_KacoPcuErrState.prototype.hasT = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.HealthKind = {
  OK: 0,
  WARNING: 1,
  ALARM: 2
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.GridState = {
  UNKNOWN: 0,
  OFF: 1,
  ONLINE: 2,
  ISLAND: 3,
  ERROR: 4,
  OTHER: 99,
  ONLINE_TO_ISLAND: 100,
  ISLAND_TO_ONLINE: 101,
  ISLAND_WITHOUT_LOAD: 200,
  ISLAND_WITH_LOAD: 201,
  ISLAND_TO_ISLAND: 202,
  BYPASSED: 300
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.DpStatusKind = {
  INTERMEDIATE_STATE: 0,
  OPEN: 1,
  CLOSED: 2,
  BAD_STATE: 3
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.Unit = {
  UNIT_UNKNOWN: 0,
  UNKNOWN_UNIT: 0,
  UNIT_PERCENT: 1,
  PCT: 1,
  UNIT_METER: 2,
  UNIT_KILOGRAM: 3,
  UNIT_SECOND: 4,
  S: 4,
  UNIT_AMPERE: 5,
  A: 5,
  UNIT_KELVIN: 6,
  UNIT_MOL: 7,
  UNIT_CANDELA: 8,
  UNIT_DEGREE: 9,
  UNIT_RADIAN: 10,
  UNIT_STERADIAN: 11,
  UNIT_GRAY: 21,
  UNIT_BEQUEREL: 22,
  UNIT_DEGREES_CELSIUS: 23,
  OC: 23,
  UNIT_SIEVERT: 24,
  UNIT_FARAD: 25,
  UNIT_COULOMB: 26,
  UNIT_SIEMENS: 27,
  UNIT_HENRY: 28,
  UNIT_VOLT: 29,
  V: 29,
  UNIT_OHM: 30,
  OHM: 30,
  UNIT_JOULE: 31,
  UNIT_NEWTON: 32,
  UNIT_HERTZ: 33,
  HERTZ: 33,
  UNIT_LUX: 34,
  UNIT_LUMEN: 35,
  UNIT_WEBER: 36,
  UNIT_TESLA: 37,
  UNIT_WATT: 38,
  W: 38,
  UNIT_PASCAL: 39,
  UNIT_SQUARE_METER: 41,
  UNIT_CUBIC_METER: 42,
  M3: 42,
  UNIT_METERS_PER_SECOND: 43,
  UNIT_METERS_PER_SECOND_SQUARED: 44,
  UNIT_CUBIC_METERS_PER_SECOND: 45,
  UNIT_METERS_PER_CUBIC_METER: 46,
  UNIT_KILOGRAM_METER: 47,
  UNIT_KILOGRAMS_PER_CUBIC_METRE: 48,
  UNIT_SQUARE_METERS_PER_SECOND: 49,
  UNIT_WATT_PER_METER_KELVIN: 50,
  UNIT_JOULE_PER_KELVIN: 51,
  UNIT_PARTS_PER_MILLION: 52,
  UNIT_ROTATIONS_PER_SECOND: 53,
  UNIT_RADIANS_PER_SECOND: 54,
  UNIT_WATT_PER_SQUARE_METER: 55,
  UNIT_JOULE_PER_SQUARE_METER: 56,
  UNIT_SIEMENS_PER_METER: 57,
  UNIT_KELVIN_PER_SECOND: 58,
  UNIT_PASCAL_PER_SECOND: 59,
  UNIT_JOULE_PER_KILOGRAM_KELVIN: 60,
  UNIT_VOLT_AMPERES: 61,
  VA: 61,
  UNIT_VOLT_AMPERES_REACTIVE: 63,
  VAR: 63,
  UNIT_COS_PHI: 65,
  UNIT_VOLT_SECONDS: 66,
  UNIT_VOLT_SQUARED: 67,
  UNIT_AMPERE_SECONDS: 68,
  AS: 67,
  UNIT_AMPERE_SQUARED: 69,
  UNIT_AMPERE_SQUARED_SECONDS: 70,
  UNIT_VOLT_AMPERE_HOURS: 71,
  VAH: 71,
  UNIT_WATT_HOURS: 72,
  WH: 72,
  UNIT_VOLT_AMPERE_REACTIVE_HOURS: 73,
  VARH: 73,
  UNIT_VOLT_PER_HERTZ: 74,
  UNIT_HERTZ_PER_SECOND: 75,
  UNIT_KILOGRAM_SQUARE_METERS: 78,
  UNIT_DECIBEL: 79,
  UNIT_JOULE_PER_WATT_HOUR: 80,
  UNIT_WATT_PER_SECOND: 81,
  UNIT_LITERS_PER_HOUR: 82,
  LPERH: 82,
  UNIT_DECIBEL_MILLIWATT: 83,
  UNIT_HOUR: 84,
  UNIT_MINUTE: 85,
  UNIT_OHM_PER_METER: 86,
  UNIT_PERCENT_PER_SECOND: 87
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.BehaviourModeKind = {
  BEHAVIOUR_MODE_INVALID: 0,
  BEHAVIOUR_MODE_ON: 1,
  BEHAVIOUR_MODE_BLOCKED: 2,
  BEHAVIOUR_MODE_TEST: 3,
  BEHAVIOUR_MODE_TEST_BLOCKED: 4,
  BEHAVIOUR_MODE_OFF: 5,
  BEHAVIOUR_MODE_MANUAL: 6
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.HeatpumpOpMode = {
  HPMODE_UNKNOWN: 0,
  HPMODE_AUTOMATIC: 1,
  HPMODE_HEATING: 2,
  HPMODE_COOLING: 3,
  HPMODE_IDLE: 4
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.MaterialKind = {
  MK_INVALID: 0,
  MK_AIR: 1,
  MK_WATER: 2,
  MK_STEAM: 3,
  MK_OIL: 4,
  MK_HYDROGEN: 5,
  MK_NATURAL_GAS: 6,
  MK_BUTANE: 7,
  MK_PROPANE: 8,
  MK_WARM_WATER: 9,
  MK_COLD_WATER: 10,
  MK_UNKNOWN: 98,
  MK_OTHER: 99
};

goog.object.extend(exports, proto.de.mypowergrid.ems);
