/**
 * @module view-helper/fw-upgrade-error
 */

import i18n from '@/i18n'

/**
 * Takes a GrpcFwupgraderError, and builds a partially translated message for e.g. a new toast notification.
 * The error-message received from the BE is not translated.
 *
 * @function
 *
 * @param {object} err should be a GrpcFwupgraderError
 *
 * @return {string}
 */
export function buildI18nErrorMsg(err) {
  const content = []
  let errorKey
  switch (err.name) {
    case 'GrpcFwupgraderImmediateError':
      errorKey = 'immediateErrorKind'
      break
    case 'GrpcFwupgraderError':
      errorKey = 'errorKind'
      break
  }
  const tErrorPath = `fwupgrade.error.${errorKey}.${err.code}`

  if (i18n.te(tErrorPath)) {
    content.push(i18n.t(tErrorPath))
  }
  content.push(err.msg || err.message)

  return content.join(' ')
}
