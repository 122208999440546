<template>
  <CCard>
    <CCard v-if="$route.name === 'ems-energy-services-config'" class="flat-card shadow-none mb-0">
      <ApxCardHeader icon="cil-settings" :title="$t('ems.energyService.config.title')" />
      <div class="px-3">
        <CCardBody>
          <EnergyServiceConfigGuideDe v-if="$i18n.locale === 'de'" />
          <EnergyServiceConfigGuideEn v-else />
        </CCardBody>
      </div>
    </CCard>

    <router-view ref="routerViewComponent"></router-view>

    <!-- BEGIN CFooter/ Action Buttons -->
    <div class="d-flex justify-content-end border-top mt-3 p-3">
      <span class="mr-auto">
        <CButton
          v-if="!routeName.next"
          class="mr-1"
          type="submit"
          size="sm"
          color="primary"
          :disabled="loading"
          @click.prevent="onSubmit"
        >
          <CIcon size="sm" name="cil-save" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('main.saveBtn') }}
          </span>
        </CButton>
        <CButton
          v-if="routeName.prev"
          :class="!routeName.next ? 'ml-5' : null"
          type="reset"
          size="sm"
          color="danger"
          :disabled="loading"
          @click.prevent="showResetConfirm = true"
        >
          <CIcon size="sm" name="cil-x-circle" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('main.resetBtn') }}
          </span>
        </CButton>
      </span>
      <!-- Not using CLink, othwise blocking navigation is not possible -->
      <!-- prettier-ignore-->
      <CButton 
        v-if="routeName.prev" 
        role="link" 
        class="mr-1" 
        color="secondary" 
        size="sm" 
        @click.prevent="onClickPrev"
      >
        {{ $t('main.prev') }}
      </CButton>
      <CButton
        v-if="routeName.next"
        role="link"
        class="ml-1 mr-1"
        color="secondary"
        size="sm"
        @click.prevent="onClickNext"
      >
        {{ $t('main.next') }}
      </CButton>
    </div>
    <!-- END CFooter/ Action Buttons -->
    <ConfirmationModal
      key="energy-service-config-reset"
      :visible.sync="showResetConfirm"
      color="danger"
      size=""
      :title="$t('ems.energyService.config.reset.title')"
      @update:confirmation="onResetConfirmation"
    >
      {{ $t('ems.energyService.config.reset.description') }}
    </ConfirmationModal>
  </CCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { CACHE_IDS } from '@/store/modules/cache'
import { API_LOADING_IDS } from '@/store/modules/api-loading-status'
import { newAlertNotificationMsg, newToastNotificationMsg } from '@/store/modules/notifications'
import {
  buildDecisionTreeValidationNotification,
  validateDecisionTree
} from '@/validations/ems-es-decision-tree-validators'
import { validateActuatorStrategies } from '@/validations/ems-es-strategy-validators'
import { eventBus } from '@/view-helper/event-bus'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import EnergyServiceConfigGuideDe from '@/components/ems/energy-services/energy-service-config-guide-de'
import EnergyServiceConfigGuideEn from '@/components/ems/energy-services/energy-service-config-guide-en'

export default {
  name: 'EnergyServicesConfig',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    EnergyServiceConfigGuideDe,
    EnergyServiceConfigGuideEn
  },
  data() {
    return {
      lastNotificationMsgId: null,
      showResetConfirm: false,
      routeName: {
        next: null,
        prev: null
      }
    }
  },
  computed: {
    ...mapState('emsEnergyServicesConfig', ['actuatorGroupIds', 'strategyIds', 'actuatorGroups']),
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('emsEnergyServicesConfig', [
      'getStrategy',
      'decisionTreeNodeIdxs',
      'decisionTreeLeafIdxs',
      'getDecisionTreeEntry'
    ])
  },
  watch: {
    '$route.name': function (name) {
      this.setRouteNames(name)
    }
  },
  created() {
    eventBus.$on('update:default-strategy', this.cleanupNotificationMsg)
    this.initStore()
    this.setRouteNames(this.$route.name)
  },
  destroyed() {
    eventBus.$off('update:default-strategy')
  },
  methods: {
    async initStore({ reseted = false } = {}) {
      let hasCache = false
      if (!reseted) {
        hasCache = await this.$store.dispatch('cache/replayStoreState', {
          path: 'emsEnergyServicesConfig',
          id: this.$route.query['eec-cache'] || CACHE_IDS.emsEnergyServicesConfig,
          vuexStore: this.$store
        })
      }

      const calls = []
      this.$store.commit('apiLoadingStatus/STARTED')

      if (hasCache) {
        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.esActuatorStrategies,
          status: true
        })
      } else {
        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.esActuatorStrategies,
          status: false
        })
        this.$store.commit('apiLoadingStatus/SET_LOADING', {
          id: API_LOADING_IDS.ems.esActuatorStrategies,
          status: true
        })
        calls.push(
          this.$store
            .dispatch('emsEnergyServicesConfig/getActuatorStrategies')
            .then(() => {
              this.$log.debug('Successfully received actuator-strategies')
              this.$store.commit('apiLoadingStatus/SET_LOADED', {
                id: API_LOADING_IDS.ems.esActuatorStrategies,
                status: true
              })
            })
            .catch(this.onServerError)
            .finally(() =>
              this.$store.commit('apiLoadingStatus/SET_LOADING', {
                id: API_LOADING_IDS.ems.esActuatorStrategies,
                status: false
              })
            )
        )
      }

      this.$store.commit('apiLoadingStatus/SET_LOADED', {
        id: API_LOADING_IDS.ems.topologyQuantities,
        status: false
      })
      this.$store.commit('apiLoadingStatus/SET_LOADING', {
        id: API_LOADING_IDS.ems.topologyQuantities,
        status: true
      })
      calls.push(
        this.$store
          .dispatch('emsTopologyConfig/getSourceQuantities')
          .then(() => {
            this.$log.debug('Successfully received source-quantities')
            this.$store.commit('apiLoadingStatus/SET_LOADED', {
              id: API_LOADING_IDS.ems.topologyQuantities,
              status: true
            })
          })
          .catch(this.onServerError)
          .finally(() => {
            this.$store.commit('apiLoadingStatus/SET_LOADING', {
              id: API_LOADING_IDS.ems.topologyQuantities,
              status: false
            })
          })
      )

      this.$store.commit('apiLoadingStatus/SET_LOADING', {
        id: API_LOADING_IDS.ems.topology,
        status: true
      })
      this.$store.commit('apiLoadingStatus/SET_LOADED', {
        id: API_LOADING_IDS.ems.topology,
        status: false
      })
      calls.push(
        this.$store
          .dispatch('emsTopologyConfig/initTopology')
          .then(() => {
            this.$log.debug('Successfully received EMS topology')
            this.$store.commit('apiLoadingStatus/SET_LOADED', {
              id: API_LOADING_IDS.ems.topology,
              status: true
            })
          })
          .catch(this.onServerError)
          .finally(() => {
            this.$store.commit('apiLoadingStatus/SET_LOADING', {
              id: API_LOADING_IDS.ems.topology,
              status: false
            })
          })
      )

      this.$store.commit('apiLoadingStatus/SET_LOADING', {
        id: API_LOADING_IDS.ems.setpointSourceProperties,
        status: true
      })
      this.$store.commit('apiLoadingStatus/SET_LOADED', {
        id: API_LOADING_IDS.ems.setpointSourceProperties,
        status: false
      })
      calls.push(
        Promise.all([
          this.$store.dispatch('emsEnergyServicesConfig/getSetpointSourcePropertiesCollection'),
          this.$store.dispatch('emsTopologyConfig/getSetpointSources')
        ])
          .then(() => {
            this.$log.debug('Successfully received Setpoint Sources')
            this.$store.commit('apiLoadingStatus/SET_LOADED', {
              id: API_LOADING_IDS.ems.setpointSourceProperties,
              status: true
            })
          })
          .catch(this.onServerError)
          .finally(() => {
            this.$store.commit('apiLoadingStatus/SET_LOADING', {
              id: API_LOADING_IDS.ems.setpointSourceProperties,
              status: false
            })
          })
      )

      if (hasCache) {
        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.defaultPeakObserver,
          status: true
        })
        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.esStrategyActivationRules,
          status: true
        })
      } else {
        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.defaultPeakObserver,
          status: false
        })
        this.$store.commit('apiLoadingStatus/SET_LOADING', {
          id: API_LOADING_IDS.ems.defaultPeakObserver,
          status: true
        })
        calls.push(
          this.$store
            .dispatch('emsEnergyServicesConfig/getPeakObserverParams')
            .then(() => {
              this.$log.debug('Successfully received EMS default peak observer parameters')
              this.$store.commit('apiLoadingStatus/SET_LOADED', {
                id: API_LOADING_IDS.ems.defaultPeakObserver,
                status: true
              })
            })
            .catch(this.onServerError)
            .finally(() => {
              this.$store.commit('apiLoadingStatus/SET_LOADING', {
                id: API_LOADING_IDS.ems.defaultPeakObserver,
                status: false
              })
            })
        )

        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.ems.esStrategyActivationRules,
          status: false
        })
        this.$store.commit('apiLoadingStatus/SET_LOADING', {
          id: API_LOADING_IDS.ems.esStrategyActivationRules,
          status: true
        })
        calls.push(
          this.$store
            .dispatch('emsEnergyServicesConfig/getStrategyActivationRules')
            .then(() => {
              this.$log.debug('Successfully received EMS Energy Service Strategy activation rules.')
              this.$store.commit('apiLoadingStatus/SET_LOADED', {
                id: API_LOADING_IDS.ems.esStrategyActivationRules,
                status: true
              })
              if (reseted) {
                this.showNotification('reset-success')
              }
            })
            .catch(this.onServerError)
            .finally(() => {
              this.$store.commit('apiLoadingStatus/SET_LOADING', {
                id: API_LOADING_IDS.ems.esStrategyActivationRules,
                status: false
              })
            })
        )
      }

      Promise.all(calls).finally(() => {
        // init of default actuator-groups can be made optionally in the future
        // e.g. by allowing the user to actively trigger this
        this.$log.debug('Dispatching initDefaultActuatorGroups')

        this.$store.dispatch('emsEnergyServicesConfig/initDefaultActuatorGroups').then(() => {
          const suitableEnergyServicesCalls = []
          for (const actuatorGroupId of this.actuatorGroupIds) {
            const apiId = `${API_LOADING_IDS.ems.suitableEnergyServices}-${actuatorGroupId}`

            this.$store.commit('apiLoadingStatus/SET_LOADING', {
              id: apiId,
              status: true
            })
            this.$store.commit('apiLoadingStatus/SET_LOADED', {
              id: apiId,
              status: false
            })

            suitableEnergyServicesCalls.push(
              this.$store
                .dispatch('emsEnergyServicesConfig/getSuitableEnergyServices', { actuatorGroupId })
                .then(() => {
                  this.$log.debug(`Successfully received EMS suitable energy services for AG ${actuatorGroupId}`)
                  this.$store.commit('apiLoadingStatus/SET_LOADED', {
                    id: apiId,
                    status: true
                  })
                })
                .catch(this.onServerError)
                .finally(() => {
                  this.$store.commit('apiLoadingStatus/SET_LOADING', {
                    id: apiId,
                    status: false
                  })
                })
            )
          }

          Promise.all(suitableEnergyServicesCalls).finally(() => {
            this.$store.commit('apiLoadingStatus/STOPPED')
          })
        })
      })
    },
    setRouteNames(name) {
      switch (name) {
        case 'ems-energy-services-config':
          this.routeName.next = 'ems-energy-services-config-strategies'
          this.routeName.prev = null
          break
        case 'ems-energy-services-config-strategies':
          this.routeName.next = 'ems-energy-services-config-activation'
          this.routeName.prev = 'ems-energy-services-config'
          break
        case 'ems-energy-services-config-activation':
          this.routeName.next = 'ems-energy-services-config-submission'
          this.routeName.prev = 'ems-energy-services-config-strategies'
          break
        case 'ems-energy-services-config-submission':
          this.routeName.next = null
          this.routeName.prev = 'ems-energy-services-config-activation'
          break
        default:
          this.routeName.next = null
          this.routeName.prev = null
      }
    },
    validateActivation() {
      const validatorDefaultStrategy = this.$refs.routerViewComponent.$v.defaultStrategyId
      validatorDefaultStrategy.$touch()
      const validatorDecisionTree = validateDecisionTree(this) // requires: `decisionTreeNodeIdxs`, `decisionTreeLeafIdxs` and `getDecisionEntry()`

      if (!validatorDefaultStrategy.$anyError && validatorDecisionTree.isValid()) {
        return true
      }

      const notificationMsg = buildDecisionTreeValidationNotification({
        ctxt: this,
        validator: validatorDecisionTree
      })
      if (validatorDefaultStrategy.$anyError) {
        if (!Array.isArray(notificationMsg.content)) {
          notificationMsg.content = [notificationMsg.content]
        }
        notificationMsg.content.unshift(this.$t('ems.energyService.config.defaultStrategy.invalidFeedback'))
      }

      this.$log.debug('Validation of EMS Energy Service activation does not pass.')
      this.$store.commit('notifications/PUSH_TOAST', notificationMsg)
      this.lastNotificationMsgId = this.$store.getters['notifications/lastToasterMsgId']

      return false
    },
    validateStrategies() {
      const validator = validateActuatorStrategies(this)
      if (validator.isValid()) {
        return true
      }

      this.$log.debug('Validation of EMS actuator strategies does not pass.')

      const notificationMsg = validator.buildNotification()
      this.$store.commit('notifications/PUSH_TOAST', notificationMsg)
      this.lastNotificationMsgId = this.$store.getters['notifications/lastToasterMsgId']

      const rName = 'ems-energy-services-config-strategies'
      const sid = validator.strategyErrors[0].targetId
      // go to page with Error, only if not already there
      if (!(this.$route.name === rName && this.$route.query.sid === sid)) {
        this.$router.push({
          name: rName,
          query: { sid }
        })
      }

      return false
    },
    cleanupNotificationMsg() {
      this.$store.commit('notifications/DELETE_TOAST', this.lastNotificationMsgId)
      this.lastNotificationMsgId = null
    },
    clearCache() {
      this.$store.commit('cache/CLEAR_CACHE', { id: CACHE_IDS.emsEnergyServicesConfig })
      this.$store.commit('cache/CLEAR_CACHE', { id: CACHE_IDS.emsEvalExpression })
      this.$log.debug('Cleared caches.')
    },
    onClickNext(e) {
      e.target.blur() // moves focus away from link

      // validation
      this.cleanupNotificationMsg()
      switch (this.$route.name) {
        case 'ems-energy-services-config-strategies':
          if (!this.validateStrategies()) {
            return
          }
          break
        case 'ems-energy-services-config-activation':
          if (!this.validateActivation()) {
            return
          }
          break
      }
      this.$router.push({ name: this.routeName.next })
    },
    onClickPrev(e) {
      e.target.blur() // moves focus away from link

      this.$store.commit('notifications/DELETE_TOAST', this.lastNotificationMsgId)
      this.lastNotificationMsgId = null
      this.$router.push({ name: this.routeName.prev })
    },
    onResetConfirmation(confirmed) {
      if (confirmed) {
        this.clearCache()
        this.initStore({ reseted: true })
      }
    },
    async onSubmit() {
      this.cleanupNotificationMsg()
      if (!this.validateStrategies() || !this.validateActivation) {
        this.$log.debug('Actuator strategy and activation rule validation failed!')
        // Note: in case validateStrategies fails, a redirect to the strategies View is triggered

        return
      }

      const calls = []
      calls.push(
        this.$store.dispatch('emsEnergyServicesConfig/setActuatorStrategies').then(() => {
          this.$log.debug('Successfully set (POST) the actuator-group strategy definitions.')
        })
      )
      calls.push(
        this.$store.dispatch('emsEnergyServicesConfig/setStrategyActivationRules').then(() => {
          this.$log.debug('Successfully set (POST) strategy activation rules (set default strategy and decision-tree).')
        })
      )
      calls.push(
        this.$store.dispatch('emsEnergyServicesConfig/setPeakObserverParams').then(() => {
          this.$log.debug('Successfully set (POST) peak-observer params.')
        })
      )

      this.$store.commit('apiLoadingStatus/STARTED')
      try {
        this.$log.debug('Submitting EMS Energy Service config...')
        try {
          await Promise.all(calls)
        } catch (err) {
          // first Error wins
          this.onServerError(err)
          this.showNotification('submit-failed')
          this.$log.error('Failed to submit EMS Energy Service config.')
          this.$log.error(err)
          return
        }
        this.clearCache()
        this.showNotification('submit-success')
        this.$log.info('Successfully finished submit of EMS Energy Service config.')

        this.showNotification('postprocessing-success')
        this.$log.info('Successfully finished postprocessing.')
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    onServerError(err) {
      this.$log.warn(err)
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
    },
    showNotification(type) {
      switch (type) {
        case 'submit-success':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'info',
              content: this.$t('ems.energyService.config.alert.submitSuccess')
            })
          )
          break
        case 'submit-failed':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'danger',
              content: this.$t('ems.energyService.config.alert.submitFailure')
            })
          )
          break
        case 'postprocessing-processing':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'info',
              content: this.$t('ems.energyService.config.alert.processing')
            })
          )
          break
        case 'postprocessing-success':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'success',
              content: this.$t('ems.energyService.config.alert.processingSuccess')
            })
          )
          break
        case 'postprocessing-failed':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'info',
              content: this.$t('ems.energyService.config.alert.processingFailed')
            })
          )
          break
        case 'reset-success':
          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              content: this.$t('ems.energyService.config.alert.resetSuccess'),
              type: 'success',
              title: this.$t('main.configActions.reset')
            })
          )
          break
        default:
          this.$store.commit('notifications/DELETE_ALERT')
      }
    }
  }
}
</script>
