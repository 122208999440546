<template>
  <div>
    <FwUpgradeStatus v-if="showFwUpgradeStatus" />
    <FwUpgradePartiallyUpgraded v-if="showFwUpgradePartiallyUpgraded" />
    <FwUpgradeError v-if="showFwUpgradeError" />
    <FwUpgradeLog />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { State as FwStatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import FwUpgradeError from '@/components/fw-upgrade/fw-upgrade-error'
import FwUpgradeLog from '@/components/fw-upgrade/fw-upgrade-log'
import FwUpgradePartiallyUpgraded from '@/components/fw-upgrade/fw-upgrade-partially-upgraded'
import FwUpgradeStatus from '@/components/fw-upgrade/fw-upgrade-status'

export default {
  name: 'FwUpgrade',
  components: {
    FwUpgradeError,
    FwUpgradeLog,
    FwUpgradePartiallyUpgraded,
    FwUpgradeStatus
  },
  computed: {
    ...mapGetters('fwupgrader', ['fwUpgraderState', 'alreadyInitialized']),
    showFwUpgradeStatus() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.UP_TO_DATE:
        case FwStatePb.StateKindCase.UPGRADE_AVAILABLE:
          return true
        default:
          return false
      }
    },
    showFwUpgradePartiallyUpgraded() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.PARTIALLY_UPGRADED:
          return true
        default:
          return false
      }
    },
    showFwUpgradeError() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.STATE_KIND_NOT_SET:
        case FwStatePb.StateKindCase.ERROR:
          return true
        default:
          return false
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.alreadyInitialized) {
        return
      }

      this.$log.info('Initialize fw-upgrader store, because it seems not to be done before.')
      this.$store.dispatch('fwupgrader/initialize')
    }
  }
}
</script>
