<template>
  <div class="py-2">
    <!-- BEGIN: Negation Switch -->
    <p class="d-flex justify-content-between mb-1">
      <small class="text-break text-primary">
        <em>
          {{
            negate ? $t(`${tBasePath}.negateSwitch.labelNegateTrue`) : $t(`${tBasePath}.negateSwitch.labelNegateFalse`)
          }}
        </em>
      </small>
    </p>
    <!-- END: Negation Switch -->
    <!-- BEGIN: Upper Threshold -->
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.upperThreshold.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ onThreshold }} </em>
        <em>W</em>
      </small>
    </p>
    <!-- END: Upper Threshold  -->
    <!-- BEGIN: Lower Threshold -->
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.lowerThreshold.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ lowerThreshold }} </em>
        <em>W</em>
      </small>
    </p>
    <!-- END: Lower Threshold  -->
    <!-- BEGIN: Tolerance Window -->
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.conditionWindowSeconds.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ conditionWindowSeconds }} </em>
        <em>s</em>
      </small>
    </p>
    <!-- END: Tolerance Window -->
    <!-- BEGIN: Power at Position in Topology -->
    <EsPreviewPositionInTopology :position-in-topology="powerAtPositionInTopology" />
    <!-- END: Power at Position in Topology -->
  </div>
</template>

<script>
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'
import EsPreviewPositionInTopology from './es-preview-position-in-topology'

export default {
  name: 'EsPreviewDigitalSwitch',
  components: {
    EsPreviewPositionInTopology
  },
  props: {
    conditionWindowSeconds: {
      required: true,
      type: Number
    },
    holdDelta: {
      required: true,
      type: Number
    },
    onThreshold: {
      required: true,
      type: Number
    },
    negate: {
      required: true,
      type: Boolean
    },
    powerAtPositionInTopology: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    lowerThreshold() {
      return this.onThreshold - this.holdDelta
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.digitalOutputSwitchCondition'
  },
  methods: {
    translatePhysicalDevice
  }
}
</script>
