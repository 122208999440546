<template>
  <!-- prettier-ignore -->
  <div>
    <CWidgetIcon
      class="no-border mb-0 mt-4"
      header="Status"
      :text="getStatusText()"
      :color="getStatusColor()"
      :icon-padding="false"
    >
      <CIcon
        :name="(health === 'OK') ? 'cisCheckCircle' : 'cisWarning'"
        :width="36"
      />
    </CWidgetIcon>
    <CCardBody class="mt-4 mb-0 ml-0 pl-0 pb-0 pt-2">
      <ApxDeviceStatusInfo
        :title="$t('config.device.status.statusInfo.serial')"
        :info="serialNumber"
      />
      <hr class="separation mt-0 p-0">
      <ApxDeviceStatusInfo
        :title="$t('config.device.status.statusInfo.name')"
        :info="name"
      />
      <hr class="separation mt-0 p-0">
      <ApxDeviceStatusInfo
        :title="$t('config.device.status.statusInfo.vendor')"
        :info="vendor"
      />
      <hr class="separation mt-0 p-0">
      <ApxDeviceStatusInfo
        :title="$t('config.device.status.statusInfo.model')"
        :info="model"
      />
      <hr class="separation mt-0 p-0">
      <ApxDeviceStatusInfo
        :title="$t('config.device.status.statusInfo.info')"
        :info="statusInfo"
      />
    </CCardBody>
  </div>
</template>

<script>
import ApxDeviceStatusInfo from '@/components/apx-device-status-info'

export default {
  name: 'ApxDeviceStatus',
  components: {
    ApxDeviceStatusInfo
  },
  // might be extended by a prop to show or not show all status info
  props: {
    health: {
      type: String,
      default: ''
    },
    serialNumber: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    vendor: {
      type: String,
      default: null
    },
    model: {
      type: String,
      default: null
    },
    statusInfo: {
      type: String,
      default: null
    }
  },
  methods: {
    getStatusText() {
      switch (this.health) {
        case 'OK':
          return this.$t('config.device.status.msg.ok')
        case 'WARNING':
          return this.$t('config.device.status.msg.warning')
        case 'UNSURE':
          return this.$t('config.device.status.msg.unsure')
        case 'UNAVAILABLE':
          return this.$t('config.device.status.msg.unavailable')
        case 'ALARM':
          return this.$t('config.device.status.msg.alarm')
        case 'UNKNOWN':
          return this.$t('config.device.status.msg.unknown')
        default:
          return ''
      }
    },
    getStatusColor() {
      if (this.health === 'OK') {
        return 'gradient-success'
      } else if (['WARNING', 'UNSURE'].includes(this.health)) {
        return 'gradient-warning'
      } else if (['ALARM', 'UNKNOWN', 'UNAVAILABLE'].includes(this.health)) {
        return 'gradient-danger'
      } else {
        return null
      }
    }
  }
}
</script>
