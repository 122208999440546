/** @module store/device */

import * as DevicePb from '@/../lib/proto_js/amperix/device_noopt_pb'
import { EnergyManagerDeviceStatus } from '@/../lib/proto_js/ext/amperix/ems_pb'
/**
 * List of supported devices.
 * Is a list of Enums defined by the protobuf message `Device.DeviceType`.
 */
export const SUPPORTED_DEVICES = Object.keys(DevicePb.DeviceType)

/**
 * List of known device statuses, after the device-config was accepted.
 *
 * `FATAL` is the status, for which the BE-Satellite failed to start.
 */
export const SUPPORTED_DEVICE_STATUSES = Object.keys(EnergyManagerDeviceStatus.EnergyManagerDeviceHealth).concat([
  'FATAL'
])
