/** @module */

import { v1 as uuid } from 'uuid' // create UUID from system clock + random values
import logger from '@/logger'
import { createUnaryCall } from '@/grpc'
import * as DevicePb from '@/../lib/proto_js/amperix/device_noopt_pb'
import { DeviceService } from '@/../lib/proto_js/amperix/device_noopt_pb_service'
import { buildDescriptor, parseAnyToTypedMsg, objectToProtoMsg, safeGetMsgChain } from '@/grpc/parser'
import { evalServiceError } from '@/api/error-handling'
import { DEVICE_SERVICE_FULL_DESCRIPTION, DEVICE_CONFIG_DESCRIPTOR } from '@/grpc/protobuf/device-config-helper'
import { SUPPORTED_DEVICES } from '@/store/modules/devices'

/**
 * gRPC unary call to fetch all meter drivers
 *
 * @function
 *
 * @param {String} deviceType (optional) is the device type. If present, MUST be one of {@link module:store/devices.SUPPORTED_DEVICES}
 *
 * @return {Promise} which resolves to `GetDeviceDriversResponse` proto message
 */
export function fetchDeviceDrivers(deviceType) {
  if (!deviceType) {
    deviceType = 'UNSPECIFIED'
  }
  if (!SUPPORTED_DEVICES.includes(deviceType)) {
    throw new TypeError(`The deviceType '${deviceType}' is not supported.`)
  }

  /* prettier-ignore */
  const call = createUnaryCall({
    service: DeviceService,
    method: 'GetDeviceDrivers',
    payload: new DevicePb.GetDeviceDriversRequest(
      [DevicePb.DeviceType[deviceType]]
    )
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC unary call to get the default values of a DeviceConfig-Config.
 *
 * @function
 *
 * @param {object} params
 * @param {array} params.fieldNumbers (required) has to be the list of proto-field numbers pointing the the device config of `DevicePb.DeviceConfig`. E.g. `DevicePb.DeviceConfig.Inverter.Config5` would be `[2, 4, 11]`
 * @param {string} params.driverId (required) is the driverId for which this (or the root) config was build.
 * @param {string} params.typeUrl (optional) is the type URL which should be used in the proto response.
 *
 * @return {Promise} which resoves to a `DevicePb.DeviceConfig.<device>.<config>` proto message
 */
export function getDefaultDeviceConfig(params) {
  const descriptor = buildDescriptor(
    DEVICE_SERVICE_FULL_DESCRIPTION.GetDefaultDeviceConfigRequest,
    DevicePb.GetDefaultDeviceConfigRequest
  )

  if (typeof params !== 'object') {
    throw new TypeError('Invalid argument. Params have to be a plain object.')
  }
  Object.keys(params).forEach((k) => {
    if (!Object.prototype.hasOwnProperty.call(descriptor, k)) {
      throw new TypeError(`Invalid argument. The object property ${k} is NOT allowed`)
    }
  })

  const payload = objectToProtoMsg({
    descriptor,
    payload: params
  })
  /* prettier-ignore */
  const call = createUnaryCall({
    service: DeviceService,
    method: 'GetDefaultDeviceConfig',
    payload
  })

  const extractDefaultConfig = (msg) => {
    try {
      const d = msg.getDefault()
      const config = parseAnyToTypedMsg(d, DevicePb, { packageNameSpace: 'de.mypowergrid.amperix' })

      return config
    } catch (err) {
      logger.error(
        'Failed to extract default config from field `google.protobuf.Any GetDefaultDeviceConfigResponse.default`.'
      )
      logger.error(err.message)
      logger.error('Used request params:')
      logger.error(params)

      return null
    }
  }

  return call.perform().then(evalServiceError).then(extractDefaultConfig)
}

/**
 * gRPC unary call to fetch all currently configured devices (device configs)
 *
 * @function
 *
 * @param {string} deviceType (optional) allows to specify for which driver device-configurations should be returned
 *
 * @return {Promise} which resolves to `GetDeviceConfigsResponse` proto message
 */
export function fetchDeviceConfigs(deviceType) {
  /* prettier-ignore */
  const call = createUnaryCall({
    service: DeviceService,
    method: 'GetDeviceConfigs',
    payload: new DevicePb.GetDeviceConfigsRequest([
      DevicePb.DeviceType[deviceType]
    ])
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC unary call to get a device-config.
 *
 * @function
 *
 * @param {string} configId (required) is the configId of the requested device config.
 *
 * @return {Promise}
 */
export function getDeviceConfig(configId) {
  checkConfigId(configId)

  /* prettier-ignore */
  const call = createUnaryCall({
    service: DeviceService,
    method: 'GetDeviceConfig',
    payload: new DevicePb.GetDeviceConfigRequest([configId])
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC unary call to add (create) a new device config
 *
 * @function
 *
 * @param {object} devCfgMsg (required) has to be a plain (toObject) or proto object of `new DevicePb.DeviceConfig`.
 *
 * @return {Promise} which resolves to `AddDeviceConfigResponse` proto message
 */
export function addDeviceConfig(devCfgMsg) {
  const payload = buildDeviceConfigRequestMsg(devCfgMsg, DevicePb.AddDeviceConfigRequest, { action: 'create' })

  const call = createUnaryCall({
    service: DeviceService,
    method: 'AddDeviceConfig',
    payload
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC unary call to update a meter config
 *
 * @function
 *
 * @param {object} devCfgMsg (required) has to be a plain (toObject) or proto object of `new DevicePb.DeviceConfig`.
 *
 * @return {Promise} which resolves to `UpdateDeviceConfigResponse` proto message
 */
export function updateDeviceConfig(devCfgMsg) {
  const payload = buildDeviceConfigRequestMsg(devCfgMsg, DevicePb.UpdateDeviceConfigRequest, { action: 'update' })
  let configId = null
  if (typeof devCfgMsg.toObject === 'function') {
    configId = safeGetMsgChain(devCfgMsg, ['id', 'id'])
  } else {
    configId = devCfgMsg.id ? devCfgMsg.id.id : null
  }
  checkConfigId(configId)

  const call = createUnaryCall({
    service: DeviceService,
    method: 'UpdateDeviceConfig',
    payload
  })

  return call.perform().then(evalServiceError)
}

export function deleteDeviceConfig(configId) {
  checkConfigId(configId)

  /* prettier-ignore */
  const call = createUnaryCall({
    service: DeviceService,
    method: 'DeleteDeviceConfig',
    payload: new DevicePb.DeleteDeviceConfigRequest([configId])
  })

  return call.perform().then(evalServiceError)
}

// private
function checkConfigId(id) {
  // empty string is invalid
  if (!id) {
    throw new TypeError('Missing required configId.')
  }
}

function buildDeviceConfigRequestMsg(devCfgMsg, Request, { action }) {
  const msg = new Request()
  // duck typing :)
  const isProto = typeof devCfgMsg.toObject === 'function'

  // set config-d ID
  if (action === 'create' && isProto) {
    if (!devCfgMsg.hasId() || !devCfgMsg.getId().getId()) {
      if (!devCfgMsg.hasId()) {
        devCfgMsg.setId(new DevicePb.DeviceConfig.ID())
      }
      devCfgMsg.getId().setId(uuid())
    }
  } else if (action === 'create') {
    if (!devCfgMsg.id || !devCfgMsg.id.id) {
      if (!devCfgMsg.id) {
        devCfgMsg.id = {}
      }
      devCfgMsg.id.id = uuid()
    }
  }

  if (!isProto) {
    devCfgMsg = objectToProtoMsg({
      descriptor: DEVICE_CONFIG_DESCRIPTOR,
      payload: devCfgMsg
    })
  }

  msg.setConfig(devCfgMsg)

  return msg
}

// function buildDeviceConfigPayloadFormDeviceModel(deviceModel, desc) {
//   const payload = {
//     id: deviceModel.configId
//   }

//   let config = payload
//   const num = desc.path.length
//   desc.path.forEach((f, i) => {
//     if (i < num - 1) {
//       config[f] = {}
//       config = config[f]
//     } else {
//       config[f] = deviceModel.config
//     }
//   })

//   return payload
// }
