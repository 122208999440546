<template>
  <CModal
    :show="isOperating"
    color="primary"
    size="lg"
    :backdrop="true"
    :close-on-backdrop="false"
    :centered="true"
    :fade="true"
  >
    <template #header>
      <h5 class="pt-2">
        {{ title }}
      </h5>
    </template>
    <div class="mx-1 mx-md-3">
      <div class="my-1 my-md-3">
        {{ description }}
      </div>
      <CProgress
        color="primary"
        style="height: 30px"
        :animated="true"
        :striped="true"
        :show-percentage="!isRebooting"
        :show-value="!isRebooting"
        :value="isRebooting ? 100 : progressInPercent"
      />
      <div v-if="isRebooting" class="my-1 my-md-3">
        {{ $t('fwupgrade.status.rebooting.hint') }}
      </div>
    </div>
    <template #footer>
      <small class="text-muted">
        {{ $t('fwupgrade.status.operating.hint') }}
      </small>
    </template>
  </CModal>
</template>

<script>
import { mapGetters } from 'vuex'
import { camelCase } from 'lodash'
import { State as FwStatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

export default {
  name: 'FwUpgradeOperating',
  computed: {
    ...mapGetters('fwupgrader', [
      'fwUpgraderState',
      'fwUpgraderStateAsStr',
      'progressInPercent',
      'currentVersion',
      'availableVersion'
    ]),
    isOperating() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.UPGRADE_IN_PROGRESS:
        case FwStatePb.StateKindCase.ROLLBACK_IN_PROGRESS:
        case FwStatePb.StateKindCase.FINALIZE_IN_PROGRESS:
        case FwStatePb.StateKindCase.REBOOTING:
          return true
        default:
          return false
      }
    },
    isRebooting() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.REBOOTING:
          return true
        default:
          return false
      }
    },
    tKey() {
      if (!this.isOperating) {
        return null
      }

      return camelCase(this.fwUpgraderStateAsStr)
    },
    title() {
      if (!this.isOperating) {
        return ''
      }

      return this.$t(`fwupgrade.status.${this.tKey}.title`)
    },
    description() {
      if (!this.isOperating) {
        return ''
      }

      return this.$t(`fwupgrade.status.${this.tKey}.description`, {
        energyManager: this.brandName,
        oldVersion: this.currentVersion,
        newVersion: this.availableVersion
      })
    }
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
  }
}
</script>
