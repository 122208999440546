<template>
  <div>
    <CButtonGroup :size="size">
      <CButton :color="mainBtnColor" :disabled="disableBtn" :variant="mainBtnVariant" @click="$emit('click', $event)">
        {{ label }}
      </CButton>
      <CButton
        v-if="!notToggleable"
        :color="helpBtnColor || mainBtnColor"
        :variant="helpBtnVariant || mainBtnVariant"
        @click="onClick"
      >
        <slot>
          <i class="c-icon">
            <CIcon class="align-top" name="cil-info-circle" :size="size" />
          </i>
        </slot>
      </CButton>
    </CButtonGroup>
    <small v-if="description && (showDesc || notToggleable)" class="form-text text-muted w-100">
      {{ description }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'BtnWithDescription',
  props: {
    mainBtnColor: {
      required: false,
      type: String,
      default: null
    },
    mainBtnVariant: {
      required: false,
      type: String,
      default: null
    },
    helpBtnColor: {
      required: false,
      type: String,
      default: null
    },
    helpBtnVariant: {
      required: false,
      type: String,
      default: null
    },
    label: {
      required: true,
      type: String
    },
    description: {
      required: false,
      type: String,
      default: ''
    },
    disableBtn: {
      required: false,
      type: Boolean,
      default: false
    },
    notToggleable: {
      required: false,
      type: Boolean,
      default: false
    },
    size: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      showDesc: false
    }
  },
  methods: {
    onClick() {
      this.showDesc = !this.showDesc
      this.$emit('update:show-description', this.showDesc)
    }
  }
}
</script>
