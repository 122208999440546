// source: ext/ems/eval.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.ems.BinaryEvalExpression', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.EvalExpression', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.EvalExpression.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.EvalExpressions', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.TimeseriesAggregate', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.TimeseriesAggregate.Operation', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.TimeseriesPoint', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.TimeseriesPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.TimeseriesPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.TimeseriesPoint.displayName = 'proto.de.mypowergrid.ems.TimeseriesPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.TimeseriesAggregate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.TimeseriesAggregate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.TimeseriesAggregate.displayName = 'proto.de.mypowergrid.ems.TimeseriesAggregate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.EvalExpression = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.EvalExpression, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.EvalExpression.displayName = 'proto.de.mypowergrid.ems.EvalExpression';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.BinaryEvalExpression = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.BinaryEvalExpression, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.BinaryEvalExpression.displayName = 'proto.de.mypowergrid.ems.BinaryEvalExpression';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.EvalExpressions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.EvalExpressions.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.EvalExpressions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.EvalExpressions.displayName = 'proto.de.mypowergrid.ems.EvalExpressions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.TimeseriesPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.TimeseriesPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TimeseriesPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deltaTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.TimeseriesPoint}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.TimeseriesPoint;
  return proto.de.mypowergrid.ems.TimeseriesPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.TimeseriesPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.TimeseriesPoint}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.TimeseriesPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.TimeseriesPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TimeseriesPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeltaTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesPoint} returns this
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double delta_time = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.getDeltaTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesPoint} returns this
 */
proto.de.mypowergrid.ems.TimeseriesPoint.prototype.setDeltaTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.TimeseriesAggregate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.TimeseriesAggregate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deltaStartTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    deltaEndTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    op: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.TimeseriesAggregate;
  return proto.de.mypowergrid.ems.TimeseriesAggregate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.TimeseriesAggregate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaEndTime(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.TimeseriesAggregate.Operation} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.TimeseriesAggregate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.TimeseriesAggregate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeltaStartTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDeltaEndTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.Operation = {
  INTEGRAL: 0,
  MIN: 1,
  MAX: 2
};

/**
 * optional string reference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate} returns this
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double delta_start_time = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.getDeltaStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate} returns this
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.setDeltaStartTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double delta_end_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.getDeltaEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate} returns this
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.setDeltaEndTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Operation op = 4;
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate.Operation}
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.getOp = function() {
  return /** @type {!proto.de.mypowergrid.ems.TimeseriesAggregate.Operation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.TimeseriesAggregate.Operation} value
 * @return {!proto.de.mypowergrid.ems.TimeseriesAggregate} returns this
 */
proto.de.mypowergrid.ems.TimeseriesAggregate.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.EvalExpression.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.EvalExpression.TypeCase = {
  TYPE_NOT_SET: 0,
  VALUE: 1,
  REFERENCE: 2,
  DIFF: 3,
  MAX: 4,
  MIN: 5,
  PROD: 6,
  RATIO: 7,
  SUM: 8,
  AND: 9,
  OR: 10,
  NOT: 11,
  TIMESERIES_POINT: 12,
  TIMESERIES_AGGREGATE: 13,
  LESS: 14,
  LESS_OR_EQUAL: 15,
  GREATER_OR_EQUAL: 16,
  GREATER: 17,
  ALIAS: 18
};

/**
 * @return {proto.de.mypowergrid.ems.EvalExpression.TypeCase}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.EvalExpression.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.EvalExpression.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.EvalExpression} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.EvalExpression.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    reference: jspb.Message.getFieldWithDefault(msg, 2, ""),
    diff: (f = msg.getDiff()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    max: (f = msg.getMax()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    min: (f = msg.getMin()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    prod: (f = msg.getProd()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    ratio: (f = msg.getRatio()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    sum: (f = msg.getSum()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    and: (f = msg.getAnd()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    or: (f = msg.getOr()) && proto.de.mypowergrid.ems.EvalExpressions.toObject(includeInstance, f),
    not: (f = msg.getNot()) && proto.de.mypowergrid.ems.EvalExpression.toObject(includeInstance, f),
    timeseriesPoint: (f = msg.getTimeseriesPoint()) && proto.de.mypowergrid.ems.TimeseriesPoint.toObject(includeInstance, f),
    timeseriesAggregate: (f = msg.getTimeseriesAggregate()) && proto.de.mypowergrid.ems.TimeseriesAggregate.toObject(includeInstance, f),
    less: (f = msg.getLess()) && proto.de.mypowergrid.ems.BinaryEvalExpression.toObject(includeInstance, f),
    lessOrEqual: (f = msg.getLessOrEqual()) && proto.de.mypowergrid.ems.BinaryEvalExpression.toObject(includeInstance, f),
    greaterOrEqual: (f = msg.getGreaterOrEqual()) && proto.de.mypowergrid.ems.BinaryEvalExpression.toObject(includeInstance, f),
    greater: (f = msg.getGreater()) && proto.de.mypowergrid.ems.BinaryEvalExpression.toObject(includeInstance, f),
    alias: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.EvalExpression;
  return proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.EvalExpression} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setMax(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setMin(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setProd(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setRatio(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setSum(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setAnd(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.EvalExpressions;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.EvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader);
      msg.setNot(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.ems.TimeseriesPoint;
      reader.readMessage(value,proto.de.mypowergrid.ems.TimeseriesPoint.deserializeBinaryFromReader);
      msg.setTimeseriesPoint(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.ems.TimeseriesAggregate;
      reader.readMessage(value,proto.de.mypowergrid.ems.TimeseriesAggregate.deserializeBinaryFromReader);
      msg.setTimeseriesAggregate(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.ems.BinaryEvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader);
      msg.setLess(value);
      break;
    case 15:
      var value = new proto.de.mypowergrid.ems.BinaryEvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader);
      msg.setLessOrEqual(value);
      break;
    case 16:
      var value = new proto.de.mypowergrid.ems.BinaryEvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader);
      msg.setGreaterOrEqual(value);
      break;
    case 17:
      var value = new proto.de.mypowergrid.ems.BinaryEvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader);
      msg.setGreater(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.EvalExpression} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getMax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getMin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getProd();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getRatio();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getSum();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getAnd();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter
    );
  }
  f = message.getNot();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesPoint();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.ems.TimeseriesPoint.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesAggregate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.ems.TimeseriesAggregate.serializeBinaryToWriter
    );
  }
  f = message.getLess();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getLessOrEqual();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getGreaterOrEqual();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getGreater();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.setValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reference = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.setReference = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearReference = function() {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EvalExpressions diff = 3;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getDiff = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setDiff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EvalExpressions max = 4;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getMax = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setMax = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearMax = function() {
  return this.setMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasMax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EvalExpressions min = 5;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getMin = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setMin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearMin = function() {
  return this.setMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasMin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EvalExpressions prod = 6;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getProd = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setProd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearProd = function() {
  return this.setProd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasProd = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EvalExpressions ratio = 7;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getRatio = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setRatio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearRatio = function() {
  return this.setRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasRatio = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EvalExpressions sum = 8;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getSum = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setSum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearSum = function() {
  return this.setSum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasSum = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EvalExpressions and = 9;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getAnd = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setAnd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearAnd = function() {
  return this.setAnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasAnd = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EvalExpressions or = 10;
 * @return {?proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getOr = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpressions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpressions, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpressions|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setOr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasOr = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional EvalExpression not = 11;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getNot = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpression, 11));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setNot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearNot = function() {
  return this.setNot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasNot = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TimeseriesPoint timeseries_point = 12;
 * @return {?proto.de.mypowergrid.ems.TimeseriesPoint}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getTimeseriesPoint = function() {
  return /** @type{?proto.de.mypowergrid.ems.TimeseriesPoint} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.TimeseriesPoint, 12));
};


/**
 * @param {?proto.de.mypowergrid.ems.TimeseriesPoint|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setTimeseriesPoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearTimeseriesPoint = function() {
  return this.setTimeseriesPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasTimeseriesPoint = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TimeseriesAggregate timeseries_aggregate = 13;
 * @return {?proto.de.mypowergrid.ems.TimeseriesAggregate}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getTimeseriesAggregate = function() {
  return /** @type{?proto.de.mypowergrid.ems.TimeseriesAggregate} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.TimeseriesAggregate, 13));
};


/**
 * @param {?proto.de.mypowergrid.ems.TimeseriesAggregate|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setTimeseriesAggregate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearTimeseriesAggregate = function() {
  return this.setTimeseriesAggregate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasTimeseriesAggregate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BinaryEvalExpression less = 14;
 * @return {?proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getLess = function() {
  return /** @type{?proto.de.mypowergrid.ems.BinaryEvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.BinaryEvalExpression, 14));
};


/**
 * @param {?proto.de.mypowergrid.ems.BinaryEvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setLess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearLess = function() {
  return this.setLess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasLess = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional BinaryEvalExpression less_or_equal = 15;
 * @return {?proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getLessOrEqual = function() {
  return /** @type{?proto.de.mypowergrid.ems.BinaryEvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.BinaryEvalExpression, 15));
};


/**
 * @param {?proto.de.mypowergrid.ems.BinaryEvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setLessOrEqual = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearLessOrEqual = function() {
  return this.setLessOrEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasLessOrEqual = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional BinaryEvalExpression greater_or_equal = 16;
 * @return {?proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getGreaterOrEqual = function() {
  return /** @type{?proto.de.mypowergrid.ems.BinaryEvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.BinaryEvalExpression, 16));
};


/**
 * @param {?proto.de.mypowergrid.ems.BinaryEvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setGreaterOrEqual = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearGreaterOrEqual = function() {
  return this.setGreaterOrEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasGreaterOrEqual = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BinaryEvalExpression greater = 17;
 * @return {?proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getGreater = function() {
  return /** @type{?proto.de.mypowergrid.ems.BinaryEvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.BinaryEvalExpression, 17));
};


/**
 * @param {?proto.de.mypowergrid.ems.BinaryEvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
*/
proto.de.mypowergrid.ems.EvalExpression.prototype.setGreater = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearGreater = function() {
  return this.setGreater(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasGreater = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string alias = 18;
 * @return {string}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.setAlias = function(value) {
  return jspb.Message.setOneofField(this, 18, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.EvalExpression} returns this
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.clearAlias = function() {
  return jspb.Message.setOneofField(this, 18, proto.de.mypowergrid.ems.EvalExpression.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.EvalExpression.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.BinaryEvalExpression.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.BinaryEvalExpression} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.toObject = function(includeInstance, msg) {
  var f, obj = {
    lhs: (f = msg.getLhs()) && proto.de.mypowergrid.ems.EvalExpression.toObject(includeInstance, f),
    rhs: (f = msg.getRhs()) && proto.de.mypowergrid.ems.EvalExpression.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.BinaryEvalExpression;
  return proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.BinaryEvalExpression} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.EvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader);
      msg.setLhs(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.EvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader);
      msg.setRhs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.BinaryEvalExpression} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLhs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getRhs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvalExpression lhs = 1;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.getLhs = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpression, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression} returns this
*/
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.setLhs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression} returns this
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.clearLhs = function() {
  return this.setLhs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.hasLhs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EvalExpression rhs = 2;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.getRhs = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.EvalExpression, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression} returns this
*/
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.setRhs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.BinaryEvalExpression} returns this
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.clearRhs = function() {
  return this.setRhs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.BinaryEvalExpression.prototype.hasRhs = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.EvalExpressions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.EvalExpressions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.EvalExpressions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.EvalExpressions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.EvalExpressions.toObject = function(includeInstance, msg) {
  var f, obj = {
    expressionsList: jspb.Message.toObjectList(msg.getExpressionsList(),
    proto.de.mypowergrid.ems.EvalExpression.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpressions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.EvalExpressions;
  return proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.EvalExpressions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.EvalExpressions}
 */
proto.de.mypowergrid.ems.EvalExpressions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.EvalExpression;
      reader.readMessage(value,proto.de.mypowergrid.ems.EvalExpression.deserializeBinaryFromReader);
      msg.addExpressions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.EvalExpressions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.EvalExpressions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.EvalExpressions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpressionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.EvalExpression.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvalExpression expressions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.EvalExpression>}
 */
proto.de.mypowergrid.ems.EvalExpressions.prototype.getExpressionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.EvalExpression>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.EvalExpression, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.EvalExpression>} value
 * @return {!proto.de.mypowergrid.ems.EvalExpressions} returns this
*/
proto.de.mypowergrid.ems.EvalExpressions.prototype.setExpressionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.EvalExpression=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.EvalExpressions.prototype.addExpressions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.EvalExpression, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.EvalExpressions} returns this
 */
proto.de.mypowergrid.ems.EvalExpressions.prototype.clearExpressionsList = function() {
  return this.setExpressionsList([]);
};


goog.object.extend(exports, proto.de.mypowergrid.ems);
