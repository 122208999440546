<template>
  <CRow class="p-3">
    <!-- BEGIN: Actuator Info -->
    <div :class="colCss.label">
      {{ $t(`${tBasePath}.name.label`) }}
    </div>
    <div :class="colCss.value">
      {{ actuatorGroupId }}
    </div>
    <div class="col-12">
      <!-- prettier-ignore -->
      <hr>
    </div>
    <div :class="colCss.label">
      {{ $t(`${tBasePath}.type.label`) }}
    </div>
    <div :class="colCss.value">
      {{ tActuatorGroupType }}
    </div>
    <div class="col-12">
      <!-- prettier-ignore -->
      <hr>
    </div>
    <div :class="colCss.label">
      {{ $t(`${tBasePath}.setpointSources.label`) }}
    </div>
    <div v-if="members.length === 0" :class="colCss.value"> &#8212; &#8212; &#8212; </div>
    <div v-else :class="colCss.value">
      <template v-for="(l, i) of members">
        <p :key="`group-member-${i}`">
          <span class="font-italic text-break">
            {{ l.literal }}
          </span>
          <span class="px-1">({{ l.physicalDevice || l.device }})</span>
        </p>
      </template>
    </div>
    <!-- END: Actuator Info -->
    <!-- BEGIN: Parametrization Info -->
    <div class="col-12">
      <!-- prettier-ignore -->
      <hr>
    </div>
    <div :class="colCss.label">
      {{ $t(`${tBasePath}.parametrization.label`) }}
    </div>
    <div :class="`mb-3 ${colCss.value}`">
      {{ tParametrizationType }}
    </div>
    <!-- BEGIN: Multi-Battery parametrization -->
    <template v-if="whichParametrization === 'battery'">
      <div :class="`${colCss.label}`">
        <span class="align-middle"> {{ $t(`${tBasePath}.multiBattery.deltaSoc.label`) }}</span>
      </div>
      <div :class="colCss.value">
        <span>{{ actuatorGroup.battery.deltaSoc + ' %' }}</span>
        <HelpBtn
          class="ml-1 ml-sm-2 ml-md-3"
          @toggleHelpText="description.showBatteryDeltaSoc = !description.showBatteryDeltaSoc"
        />
      </div>
      <div v-if="description.showBatteryDeltaSoc" :class="`${colCss.value} ${colCss.offset}`">
        <small class="text-muted form-text">
          {{ $t(`${tBasePath}.multiBattery.deltaSoc.description`) }}
        </small>
      </div>
    </template>
    <!-- END: Multi-Battery parametrization -->
    <!-- BEGIN: Simple -->
    <template v-if="whichParametrization === 'simplePriority'">
      <small class="text-muted form-text">
        {{ $t(`${tBasePath}.simple.description`) }}
      </small>
    </template>
    <!-- END: Simple -->
    <!-- BEGIN: Advanced priority description -->
    <template v-if="whichParametrization === 'advancedPriority'">
      <template v-for="(value, key) in advancedPriority.priority">
        <div :key="key" :class="`${colCss.value}`">
          <span>{{ key }} : {{ value }}</span>
        </div>
      </template>
      <small class="text-muted form-text">
        {{ $t(`${tBasePath}.advanced.description`) }}
      </small>
    </template>
    <!-- END: Advanced priority description -->
    <!-- BEGIN: Car LIS -->
    <template v-if="whichParametrization === 'carLis'">
      <small class="text-muted form-text">
        {{ $t(`${tBasePath}.carLis.description`, { platform: platformName }) }}
        <div>
          <template v-if="actuatorGroup.carLis.ignoreCurrentGrid">
            <span>{{
              $t(`${tBasePath}.carLis.currentGrid.description`) + ' ' + $t(`${tBasePath}.carLis.ignored`)
            }}</span>
          </template>
          <template v-else>
            <span>
              {{ `${$t(`${tBasePath}.carLis.currentGrid.description`)} ${actuatorGroup.carLis.maxCurrentGrid} A` }}
            </span>
          </template>
        </div>
        <div>
          <template v-if="actuatorGroup.carLis.ignorePowerGrid">
            <span>{{ $t(`${tBasePath}.carLis.powerGrid.description`) + ' ' + $t(`${tBasePath}.carLis.ignored`) }}</span>
          </template>
          <template v-else>
            <span>
              {{
                $t(`${tBasePath}.carLis.powerGrid.description`) + ' ' + actuatorGroup.carLis.maxPowerGrid + ' W'
              }}</span
            >
          </template>
        </div>
        <div>
          <template v-if="actuatorGroup.carLis.ignoreCurrentLis">
            <span>{{
              $t(`${tBasePath}.carLis.currentLis.description`) + ' ' + $t(`${tBasePath}.carLis.ignored`)
            }}</span>
          </template>
          <template v-else>
            <span>
              {{
                $t(`${tBasePath}.carLis.currentLis.description`) + ' ' + actuatorGroup.carLis.maxCurrentLis + ' A'
              }}</span
            >
          </template>
        </div>
        <template v-for="(value, key) in carLis.chargePoint">
          <div :key="key" :class="`${colCss.value}`">
            <span>{{ key }} : {{ $t(`${tBasePath}.carLis.maxAmpere`) }} {{ value.maxAmpere }}</span>
          </div>
        </template>
      </small>
    </template>
    <!-- END: Car LIS -->
    <!-- BEGIN: ChaiScript parametrization -->
    <template v-if="whichParametrization === 'chai'">
      <div :class="`${colCss.label}`">
        <span class="align-middle">
          {{ $t(`${tBasePath}.chai.prefix.label`) }}/{{ $t(`${tBasePath}.chai.filename.label`) }}
        </span>
      </div>
      <div :class="`${colCss.value}`">
        <span>"{{ actuatorGroup.chai.prefix }}" / "{{ actuatorGroup.chai.filename }}"</span>
      </div>
    </template>
    <!-- END: ChaiScript parametrization -->
    <!-- END: Parametrization Info -->
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'

import HelpBtn from '@/components/snippets/help-btn'
import { EMS_ACTUATOR_STRATEGIES_DESCRIPTION } from '@/grpc/protobuf/ems-energy-service-helper'
import { determineActuatorGroupType } from '@/store/modules/_ems-energy-services-config-helper'

export default {
  name: 'AsActuatorGroupShow',
  components: {
    HelpBtn
  },
  props: {
    actuatorGroupId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      description: {
        showBatteryDeltaSoc: false
      },
      advancedPriority: {
        priority: {}
      },
      carLis: {
        chargePoint: {}
      }
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getActuatorGroup', 'humanizeAGMembers']),
    actuatorGroup() {
      return this.getActuatorGroup(this.actuatorGroupId)
    },
    tActuatorGroupType() {
      const type = determineActuatorGroupType(this.actuatorGroup) || 'NONE'

      return this.$t(`${this.tBasePath}.actuatorGroupType.${type}`)
    },
    members() {
      return this.humanizeAGMembers(this.actuatorGroupId)
    },
    whichParametrization() {
      return EMS_ACTUATOR_STRATEGIES_DESCRIPTION.nested.ActuatorGroup.oneofs.type.oneof.find((o) => {
        return this.actuatorGroup[o]
      })
    },
    tParametrizationType() {
      switch (this.whichParametrization) {
        case 'battery':
          return this.$t(`${this.tBasePath}.parametrization.battery`)
        case 'chai':
          return this.$t(`${this.tBasePath}.parametrization.chaiScript`)
        case 'simplePriority':
          return this.$t(`${this.tBasePath}.parametrization.simple`)
        case 'advancedPriority':
          return this.$t(`${this.tBasePath}.parametrization.advanced`)
        case 'carLis':
          return this.$t(`${this.tBasePath}.parametrization.carLis`)
        default:
          return this.$t(`${this.tBasePath}.parametrization.none`)
      }
    }
  },
  created() {
    this.colCss = {
      label: 'col-12 col-md-2 col-lg-3 custom-form-label',
      value: 'col-12 col-md-10 col-lg-9',
      offset: 'offset-md-2 offset-lg-3'
    }
    this.tBasePath = 'ems.energyService.actuatorGroup.form'
    this.platformName = process.env.VUE_APP_PLATFORM_NAME
    this.init()
  },
  methods: {
    init() {
      if (this.whichParametrization === 'advancedPriority') {
        for (let i = 0; i < this.actuatorGroup.advancedPriority.priorityListList.length; ++i) {
          this.advancedPriority.priority[
            iecIdToLiteral(this.actuatorGroup.advancedPriority.priorityListList[i].source.iec)
          ] = this.actuatorGroup.advancedPriority.priorityListList[i].priority
        }
      }
      if (this.whichParametrization === 'carLis') {
        for (const chargePoint of this.actuatorGroup.carLis.chargePointListList) {
          this.carLis.chargePoint[iecIdToLiteral(chargePoint.source.iec)] = {
            maxAmpere: chargePoint.maxAmpere
          }
        }
      }
    }
  }
}
</script>
