/**
 * @module validations/ems-es-form-validators
 */

import moment from 'moment'
import { helpers, maxValue, minValue, decimal, required, between } from 'vuelidate/lib/validators'

/**
 * Allows to dynamically validate the EMS Energy Service Target Power form, w.r.t. the present preselection
 *
 * @function
 *
 * @param {object} context (required) is the validation context, e.g. the `this` of the Vue component instant. Has to have property `preselection`.
 *
 * @return {function}
 */
export function esTargetPowerFormValidators(context) {
  switch (context.preselection) {
    case 'SELF-CONSUMPTION':
      return {
        powerWatts: {
          required,
          zero: helpers.withParams({ type: 'zero' }, (power) => {
            return power === 0
          })
        }
      }
    case 'PEAKSHAVING':
      return {
        powerWatts: {
          required,
          positive: minValue(0)
        }
      }
    case 'PV-CURTAILMENT':
      return {
        powerWatts: {
          required,
          negative: maxValue(0)
        }
      }
    case 'EVAL':
      return {
        isValidTree: {
          required,
          valid: helpers.withParams({ type: 'valid' }, (flag) => {
            return flag
          })
        }
      }
    default:
      return {
        powerWatts: {
          required,
          decimal
        }
      }
  }
}

export const esRecordedPowerMeasurementPeakShavingFormValidators = {
  intervalMinutes: {
    required,
    positive: buildStrictPositive()
  },
  peakWatts: {
    required,
    positive: buildStrictPositive()
  },
  safetyMarginWatts: {
    required,
    positive: buildStrictPositive()
  }
}

export const addRlmTimestampValueValidators = {
  ts: {
    peakWatts: esRecordedPowerMeasurementPeakShavingFormValidators.peakWatts,
    timestamp: {
      required,
      minValue: helpers.withParams({ type: 'minValue' }, (val) => {
        return moment(val).unix() >= moment().add(14, 'minutes').unix()
      })
    }
  }
}

export const esGenerationPeakShavingFormValidators = {
  batteryCapacityKiloWattHours: {
    required,
    positive: buildStrictPositive()
  }
}

export const esDigitalOutputSwitchConditionFormValidators = {
  lowerThreshold: {
    required,
    lesserThanUpperThreshold: helpers.withParams({ type: 'lesserThanUpperThreshold' }, (val, vm) => {
      if (typeof val !== 'number') {
        return false
      }

      if (typeof vm.upperThreshold !== 'number') {
        return true
      }

      return val <= vm.upperThreshold
    })
  },
  upperThreshold: {
    required,
    greaterThanLowerThreshold: helpers.withParams({ type: 'greaterThanLowerThreshold' }, (val, vm) => {
      if (typeof val !== 'number') {
        return false
      }

      if (typeof vm.lowerThreshold !== 'number') {
        return true
      }

      return val >= vm.lowerThreshold
    })
  },
  conditionWindowSeconds: {
    required,
    minValue: minValue(0)
  }
}

export const esOffGridBatteryProtectionFormValidators = {
  socLimit: {
    required,
    decimal,
    between: between(0, 100)
  },
  probingDurationOnInSec: buildGreaterThanZero(),
  probingDurationOffInSec: buildGreaterThanZero()
}

// private
function buildStrictPositive() {
  return helpers.withParams({ type: 'positive' }, (val) => {
    return val > 0
  })
}

function buildGreaterThanZero() {
  return {
    required,
    decimal,
    positive: buildStrictPositive()
  }
}
