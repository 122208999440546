<template>
  <CCard class="shadow-none p-1 flat-card mb-0 col-12">
    <ApxCardHeader
      :title="$t('management.license.licenseEditTitle')"
      tag="h5"
      class-header="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted"
      class-title="mb-0 font-md"
    />
    <CCardBody class="mt-0">
      <CForm ref="licenseForm" @submit.prevent>
        <CTextarea
          class="custom-form-label"
          :lazy="true"
          :value="license"
          :description="$t('management.license.form.description')"
          :placeholder="$t('management.license.form.placeholder')"
          :rows="5"
          :is-valid="$v.license.$error ? false : null"
          :invalid-feedback="invalidFeedback"
          @input="onInsert"
        >
          <template #prepend-content>
            <CIcon name="cil-key-alt" />
          </template>
        </CTextarea>
        <CRow class="align-items-center">
          <CCol v-if="shouldReview" col="12">
            <p class="text-info">
              {{ $t('management.license.form.review') }}
            </p>
          </CCol>
          <!-- prettier-ignore -->
          <CCol col="7" class="text-left">
            <CButton
              class="mr-1 mb-1"
              color="primary"
              size="sm"
              :disabled="$v.$invalid"
              @click="onSubmit"
            >
              {{ $t('main.submitBtn') }}
            </CButton>
            <CButton
              class="mb-1"
              color="danger"
              size="sm"              
              @click="onCancel"
            >
              {{ $t('main.cancelBtn') }}
            </CButton>
          </CCol>
          <CCol col="5" class="text-right mb-1">
            <LicenseUploadBtn @upload:success="onUploadSuccess" @upload:error="onUploadError" />
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import ApxCardHeader from '@/components/snippets/apx-card-header'
import LicenseUploadBtn from '@/components/license/license-upload-btn'
import { newAlertNotificationMsg, newToastNotificationMsg } from '@/store/modules/notifications'
import { licenseValidators } from '@/validations/license-validators'

export default {
  name: 'LicenseUpdateForm',
  components: {
    ApxCardHeader,
    LicenseUploadBtn
  },
  data() {
    return {
      license: ''
    }
  },
  computed: {
    invalidFeedback() {
      if (!this.$v.license.required) {
        return this.$t('management.license.form.invalidFeedbackRequired')
      }

      if (!this.$v.license.validJwt) {
        return this.$t('management.license.form.invalidFeedbackJwt')
      }

      return ''
    },
    shouldReview() {
      return this.license && !this.$v.license.$anyError
    }
  },
  watch: {
    license: function () {
      if (!this.license || this.$v.license.$anyError) {
        return
      }

      this.$store.commit('auth/SET_LICENSE', this.license)
    }
  },
  methods: {
    onInsert(l) {
      this.license = l.trim()
      this.$v.license.$touch()
    },
    onUploadSuccess(l) {
      this.license = l
      this.$v.license.$touch()
    },
    onUploadError(err) {
      this.$log.error('License file upload failed.')
      this.$log.error(err)
      this.license = ''

      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'danger',
          content: this.$t('management.license.form.invalidFile'),
          show: 30
        })
      )
    },
    onCancel() {
      this.$refs.licenseForm.reset() // otherwise, selecting the same file again will fail
      this.license = ''
      this.$v.license.$reset()
      this.$emit('cancel')
    },
    onSubmit() {
      if (this.$v.license.$anyError) {
        return
      }

      this.$store.commit('apiLoadingStatus/STARTED')
      // takes current license from store
      this.$store
        .dispatch('auth/updateLicense')
        .then(() => {
          this.$log.debug('License succesfully updated.')

          // cleanup and disable "review" hint
          this.license = ''
          this.$v.license.$reset()

          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'success',
              content: this.$t('management.license.form.formSuccess'),
              show: 8
            })
          )
        })
        .catch(this.onServerError)
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err) {
      this.$log.warn(err, `code: ${err.code}`)

      let content
      if (err.code === 6) {
        content = this.$t('api.errors.licenseUpdate.unsupportedLicense')
      } else {
        content = this.$t('api.errors.licenseUpdate.generic', [err.msg, err.code])
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 10000,
          type: 'danger',
          title: this.$t('management.user.registerLicenseForm.registrationToasterTitle'),
          content
        })
      )
    }
  },
  validations: licenseValidators
}
</script>
