// package: de.mypowergrid.sherlock
// file: ext/sherlock/version.proto

var ext_sherlock_version_pb = require("../../ext/sherlock/version_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var VersionService = (function () {
  function VersionService() {}
  VersionService.serviceName = "de.mypowergrid.sherlock.VersionService";
  return VersionService;
}());

VersionService.GetAmperixPackageVersion = {
  methodName: "GetAmperixPackageVersion",
  service: VersionService,
  requestStream: false,
  responseStream: true,
  requestType: ext_sherlock_version_pb.GetAmperixPackageVersionRequest,
  responseType: ext_sherlock_version_pb.GetAmperixPackageVersionResponse
};

VersionService.GetAmperixPackageVersionUnary = {
  methodName: "GetAmperixPackageVersionUnary",
  service: VersionService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_version_pb.GetAmperixPackageVersionRequest,
  responseType: ext_sherlock_version_pb.GetAmperixPackageVersionResponse
};

VersionService.GetAmperixTarget = {
  methodName: "GetAmperixTarget",
  service: VersionService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_version_pb.GetAmperixTargetRequest,
  responseType: ext_sherlock_version_pb.GetAmperixTargetResponse
};

VersionService.GetAmperixVersion = {
  methodName: "GetAmperixVersion",
  service: VersionService,
  requestStream: false,
  responseStream: true,
  requestType: ext_sherlock_version_pb.GetAmperixVersionRequest,
  responseType: ext_sherlock_version_pb.GetAmperixVersionResponse
};

VersionService.GetAmperixVersionUnary = {
  methodName: "GetAmperixVersionUnary",
  service: VersionService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_version_pb.GetAmperixVersionRequest,
  responseType: ext_sherlock_version_pb.GetAmperixVersionResponse
};

VersionService.GetAmperixSerialNo = {
  methodName: "GetAmperixSerialNo",
  service: VersionService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_version_pb.GetAmperixSerialNoRequest,
  responseType: ext_sherlock_version_pb.GetAmperixSerialNoResponse
};

exports.VersionService = VersionService;

function VersionServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

VersionServiceClient.prototype.getAmperixPackageVersion = function getAmperixPackageVersion(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VersionService.GetAmperixPackageVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

VersionServiceClient.prototype.getAmperixPackageVersionUnary = function getAmperixPackageVersionUnary(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VersionService.GetAmperixPackageVersionUnary, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VersionServiceClient.prototype.getAmperixTarget = function getAmperixTarget(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VersionService.GetAmperixTarget, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VersionServiceClient.prototype.getAmperixVersion = function getAmperixVersion(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VersionService.GetAmperixVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

VersionServiceClient.prototype.getAmperixVersionUnary = function getAmperixVersionUnary(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VersionService.GetAmperixVersionUnary, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VersionServiceClient.prototype.getAmperixSerialNo = function getAmperixSerialNo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VersionService.GetAmperixSerialNo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.VersionServiceClient = VersionServiceClient;

