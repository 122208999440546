<template>
  <CForm @submit.prevent>
    <HelpBtn class="d-block d-sm-none float-right" @toggleHelpText="showInfoDescription = !showInfoDescription" />
    <!-- BEGIN peakWatts -->
    <CInput
      type="number"
      class="custom-form-label"
      :value.sync="$v.peakWatts.$model"
      :label="$t(`${tBasePathCurrentRlm}.peakWatts.label`) + $t(`${tBasePathCurrentRlm}.peakWatts.unit`)"
      :placeholder="$t(`${tBasePathCurrentRlm}.peakWatts.placeholder`)"
      :is-valid="$v.peakWatts.$dirty ? !$v.peakWatts.$error : null"
      :invalid-feedback="invalidFeedbackFor('peakWatts')"
      :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
    >
      <template #description>
        <div class="col-12 pl-0 text-muted small mt-1 d-none d-sm-block">
          {{ $t(`${tBasePathCurrentRlm}.peakWatts.description`) }}
        </div>
        <div v-if="showInfoDescription" class="text-muted small mt-1 d-block d-sm-none">
          {{ $t(`${tBasePathCurrentRlm}.peakWatts.description`) }}
        </div>
      </template>
    </CInput>
    <!-- END peakWatts -->
    <!-- BEGIN Toggle intervalMinutes -->
    <CRow form class="form-group">
      <CCol
        sm="5"
        :class="{
          'text-muted': !isEditInterval,
          'col-form-label': true,
          'custom-form-label': true
        }"
      >
        {{ $t(`${tGlobalRlmPath}.form.rlmCurrent.editIntervalLabel`) }}
      </CCol>
      <CCol sm="2 pt-2">
        <!-- prettier-ignore -->
        <CSwitch
          :checked.sync="isEditInterval"
          :custom="true"
          color="primary"
          shape="pill"
          size="sm"
        />
      </CCol>
    </CRow>
    <!-- END Toggle intervalMinutes -->
    <!-- BEGIN intervalMinutes -->
    <CInput
      v-if="isEditInterval"
      type="number"
      class="custom-form-label"
      :value.sync="$v.intervalMinutes.$model"
      :label="$t(`${tBasePathCurrentRlm}.intervalMinutes.label`) + $t(`${tBasePathCurrentRlm}.intervalMinutes.unit`)"
      :placeholder="$t(`${tBasePathCurrentRlm}.intervalMinutes.placeholder`)"
      :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
      :is-valid="$v.intervalMinutes.$dirty ? !$v.intervalMinutes.$error : null"
      :invalid-feedback="invalidFeedbackFor('intervalMinutes')"
    >
      <template #description>
        <div class="col-12 pl-0 text-muted small mt-1 d-none d-sm-block">
          {{ $t(`${tBasePathCurrentRlm}.intervalMinutes.description`) }}
        </div>
        <div v-if="showInfoDescription" class="text-muted small mt-1 d-block d-sm-none">
          {{ $t(`${tBasePathCurrentRlm}.intervalMinutes.description`) }}
        </div>
      </template>
    </CInput>
    <!-- END intervalMinutes -->
    <!-- prettier-ignore -->
    <div class="mt-3 form-row">
      <CButton
        size="sm"
        color="primary"
        @click="onOverrideRlmConfig"
      >
        {{ $t('main.overrideBtn') }}
      </CButton>
    </div>
  </CForm>
</template>

<script>
import HelpBtn from '@/components/snippets/help-btn'
import { newAlertNotificationMsg } from '@/store/modules/notifications'
import { esRecordedPowerMeasurementPeakShavingFormValidators } from '@/validations/ems-es-form-validators'
import { omit } from 'lodash'

export default {
  name: 'CurrentRlmForm',
  components: {
    HelpBtn
  },
  props: {
    currentSettings: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      intervalMinutes: null,
      isEditInterval: false,
      peakWatts: null,
      showInfoDescription: false
    }
  },
  computed: {
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v[field][condition]
        }
        const base = `ems.energyService.config.form.recordedPowerMeasurementPeakShaving.${field}.validation.invalidFeedback`
        const conditions = ['required', 'positive']
        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    }
  },
  created() {
    this.tBasePathCurrentRlm = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'

    if (this.currentSettings.currentPeakWatts) {
      this.peakWatts = this.currentSettings.currentPeakWatts
    }
    if (this.currentSettings.currentIntervalMinutes) {
      this.intervalMinutes = this.currentSettings.currentIntervalMinutes
    }
  },
  methods: {
    onOverrideRlmConfig() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.$store.commit(
          'notifications/NEW_ALERT',
          newAlertNotificationMsg({
            type: 'danger',
            content: this.$t(`${this.tGlobalRlmPath}.form.formInvalid`)
          })
        )
        return
      }
      this.$emit('overrideRlmConfig', { intervalMinutes: this.intervalMinutes, peakWatts: this.peakWatts })
      this.$v.$reset()
    }
  },
  validations: omit(esRecordedPowerMeasurementPeakShavingFormValidators, ['safetyMarginWatts'])
}
</script>
