// source: ext/amperix/options/options.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_gpiod_gpios_pb = require('../../../ext/gpiod/gpios_pb.js');
goog.object.extend(proto, ext_gpiod_gpios_pb);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Alias', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DeviceModel', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DriverSupport', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Function', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.Static', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.KindCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.options.Amperix.Transform', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.displayName = 'proto.de.mypowergrid.amperix.options.Amperix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DeviceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DeviceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DriverSupport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DriverSupport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Transform, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Transform.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Transform';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.de.mypowergrid.amperix.options.Amperix.Selection.repeatedFields_, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.Static, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.Static';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Alias.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Alias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Alias.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Alias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.options.Amperix.Function = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.options.Amperix.Function.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.options.Amperix.Function, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.options.Amperix.Function.displayName = 'proto.de.mypowergrid.amperix.options.Amperix.Function';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.toObject = function(includeInstance, msg) {
  var f, obj = {
    supportedModelsList: jspb.Message.toObjectList(msg.getSupportedModelsList(),
    proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.toObject, includeInstance),
    deviceConfig: (f = msg.getDeviceConfig()) && proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.toObject(includeInstance, f),
    fallbackConfigType: (f = msg.getFallbackConfigType()) && proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.de.mypowergrid.amperix.options.Amperix.Alias.toObject(includeInstance, f),
    functionsMap: (f = msg.getFunctionsMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.amperix.options.Amperix.Function.toObject) : [],
    transform: (f = msg.getTransform()) && proto.de.mypowergrid.amperix.options.Amperix.Transform.toObject(includeInstance, f),
    selection: (f = msg.getSelection()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix}
 */
proto.de.mypowergrid.amperix.options.Amperix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix;
  return proto.de.mypowergrid.amperix.options.Amperix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix}
 */
proto.de.mypowergrid.amperix.options.Amperix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DriverSupport;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.deserializeBinaryFromReader);
      msg.addSupportedModels(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.setDeviceConfig(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.deserializeBinaryFromReader);
      msg.setFallbackConfigType(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Alias;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Alias.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 5:
      var value = msg.getFunctionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.amperix.options.Amperix.Function.deserializeBinaryFromReader, "", new proto.de.mypowergrid.amperix.options.Amperix.Function());
         });
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Transform;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Transform.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.deserializeBinaryFromReader);
      msg.setSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupportedModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.serializeBinaryToWriter
    );
  }
  f = message.getDeviceConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
  f = message.getFallbackConfigType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Alias.serializeBinaryToWriter
    );
  }
  f = message.getFunctionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.amperix.options.Amperix.Function.serializeBinaryToWriter);
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Transform.serializeBinaryToWriter
    );
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    internalId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DeviceModel;
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string internal_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.getInternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.prototype.setInternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configTypesList: jspb.Message.toObjectList(msg.getConfigTypesList(),
    proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DriverSupport;
  return proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.deserializeBinaryFromReader);
      msg.addConfigTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aliasesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType;
  return proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAliases(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceModel;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string aliases = 2;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.getAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.setAliasesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.addAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.clearAliasesList = function() {
  return this.setAliasesList([]);
};


/**
 * repeated DeviceModel models = 3;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceModel, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * optional string driver_id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConfigType config_types = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.getConfigTypesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.setConfigTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.addConfigTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.ConfigType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.prototype.clearConfigTypesList = function() {
  return this.setConfigTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Transform.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Transform} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromField: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toField: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Transform}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Transform;
  return proto.de.mypowergrid.amperix.options.Amperix.Transform.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Transform} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Transform}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Transform.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Transform} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string from_field = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.getFromField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Transform} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.setFromField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_field = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.getToField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Transform} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Transform.prototype.setToField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.repeatedFields_ = [2047];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.TypeCase = {
  TYPE_NOT_SET: 0,
  STATIC: 1,
  GPIO: 2,
  SERIAL_PORT: 3,
  ENUM: 4,
  IEC_REF: 5
};

/**
 * @return {proto.de.mypowergrid.amperix.options.Amperix.Selection.TypeCase}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.options.Amperix.Selection.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_static: (f = msg.getStatic()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.toObject(includeInstance, f),
    gpio: (f = msg.getGpio()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.toObject(includeInstance, f),
    serialPort: (f = msg.getSerialPort()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.toObject(includeInstance, f),
    pb_enum: (f = msg.getEnum()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.toObject(includeInstance, f),
    iecRef: (f = msg.getIecRef()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.toObject(includeInstance, f),
    additionalValuesList: jspb.Message.toObjectList(msg.getAdditionalValuesList(),
    proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Static;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.deserializeBinaryFromReader);
      msg.setStatic(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.deserializeBinaryFromReader);
      msg.setGpio(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.deserializeBinaryFromReader);
      msg.setSerialPort(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.deserializeBinaryFromReader);
      msg.setEnum(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.deserializeBinaryFromReader);
      msg.setIecRef(value);
      break;
    case 2047:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Value;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.deserializeBinaryFromReader);
      msg.addAdditionalValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.serializeBinaryToWriter
    );
  }
  f = message.getGpio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.serializeBinaryToWriter
    );
  }
  f = message.getSerialPort();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.serializeBinaryToWriter
    );
  }
  f = message.getEnum();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.serializeBinaryToWriter
    );
  }
  f = message.getIecRef();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2047,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_ = [[9,13,14]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.KindCase = {
  KIND_NOT_SET: 0,
  STRING_VALUE: 9,
  UINT32_VALUE: 13,
  ENUM_NUMBER: 14
};

/**
 * @return {proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.KindCase}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.getKindCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.KindCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    uint32Value: jspb.Message.getFieldWithDefault(msg, 13, 0),
    enumNumber: jspb.Message.getFieldWithDefault(msg, 14, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2047, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Value;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32Value(value);
      break;
    case 14:
      var value = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum} */ (reader.readEnum());
      msg.setEnumNumber(value);
      break;
    case 2047:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2047,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum = {
  DUMMY_ENUM_ZERO: 0
};

/**
 * optional string string_value = 9;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 9, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint32 uint32_value = 13;
 * @return {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.getUint32Value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.setUint32Value = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.clearUint32Value = function() {
  return jspb.Message.setOneofField(this, 13, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.hasUint32Value = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DummyEnum enum_number = 14;
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.getEnumNumber = function() {
  return /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.DummyEnum} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.setEnumNumber = function(value) {
  return jspb.Message.setOneofField(this, 14, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.clearEnumNumber = function() {
  return jspb.Message.setOneofField(this, 14, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.hasEnumNumber = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string description = 2047;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2047, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2047, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueListList: jspb.Message.toObjectList(msg.getValueListList(),
    proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Static;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Value;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.deserializeBinaryFromReader);
      msg.addValueList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Value value_list = 1;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.getValueListList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.setValueListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.addValueList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Static.prototype.clearValueListList = function() {
  return this.setValueListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base} */ (reader.readEnum());
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base = {
  INVALID: 0,
  ALL: 1,
  ONLY_FREE: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    names: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modesList: jspb.Message.toObjectList(msg.getModesList(),
    ext_gpiod_gpios_pb.ModeSelector.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNames(value);
      break;
    case 3:
      var value = new ext_gpiod_gpios_pb.ModeSelector;
      reader.readMessage(value,ext_gpiod_gpios_pb.ModeSelector.deserializeBinaryFromReader);
      msg.addModes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      ext_gpiod_gpios_pb.ModeSelector.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type = {
  DENY: 0,
  ALLOW: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.Type} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string names = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.getNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.setNames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated de.mypowergrid.gpiod.ModeSelector modes = 3;
 * @return {!Array<!proto.de.mypowergrid.gpiod.ModeSelector>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.getModesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.gpiod.ModeSelector>} */ (
    jspb.Message.getRepeatedWrapperField(this, ext_gpiod_gpios_pb.ModeSelector, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.gpiod.ModeSelector>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.setModesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.gpiod.ModeSelector=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.gpiod.ModeSelector}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.addModes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.gpiod.ModeSelector, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter.prototype.clearModesList = function() {
  return this.setModesList([]);
};


/**
 * optional Base base = 1;
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.getBase = function() {
  return /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Base} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.setBase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    paths: jspb.Message.getFieldWithDefault(msg, 2, ""),
    aliases: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaths(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAliases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPaths();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAliases();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type = {
  DENY: 0,
  ALLOW: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.Type} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string paths = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.getPaths = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.setPaths = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string aliases = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.getAliases = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter.prototype.setAliases = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.toObject, includeInstance),
    truncate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTruncate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.serializeBinaryToWriter
    );
  }
  f = message.getTruncate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    references: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter;
  return proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getReferences();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type = {
  DENY: 0,
  ALLOW: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.Type} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string references = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.getReferences = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter.prototype.setReferences = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional uint32 truncate = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.getTruncate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef.prototype.setTruncate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Static static = 1;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection.Static}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getStatic = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection.Static} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.Static, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection.Static|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setStatic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearStatic = function() {
  return this.setStatic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.hasStatic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GPIO gpio = 2;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getGpio = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection.GPIO|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setGpio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearGpio = function() {
  return this.setGpio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.hasGpio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SerialPort serial_port = 3;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getSerialPort = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection.SerialPort|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setSerialPort = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearSerialPort = function() {
  return this.setSerialPort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.hasSerialPort = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Enum enum = 4;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getEnum = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection.Enum|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setEnum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearEnum = function() {
  return this.setEnum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.hasEnum = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IECRef iec_ref = 5;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getIecRef = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection.IECRef|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setIecRef = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.amperix.options.Amperix.Selection.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearIecRef = function() {
  return this.setIecRef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.hasIecRef = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Value additional_values = 2047;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.getAdditionalValuesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value, 2047));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.setAdditionalValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2047, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection.Value}
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.addAdditionalValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2047, opt_value, proto.de.mypowergrid.amperix.options.Amperix.Selection.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Selection} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Selection.prototype.clearAdditionalValuesList = function() {
  return this.setAdditionalValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.repeatedFields_ = [1,2,4,5,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    metersList: jspb.Message.toObjectList(msg.getMetersList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance),
    invertersList: jspb.Message.toObjectList(msg.getInvertersList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance),
    wallboxesList: jspb.Message.toObjectList(msg.getWallboxesList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance),
    relaysList: jspb.Message.toObjectList(msg.getRelaysList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance),
    containersList: jspb.Message.toObjectList(msg.getContainersList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance),
    ezasList: jspb.Message.toObjectList(msg.getEzasList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig;
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addMeters(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addInverters(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addWallboxes(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addRelays(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addContainers(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader);
      msg.addEzas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
  f = message.getInvertersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
  f = message.getWallboxesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
  f = message.getRelaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
  f = message.getContainersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
  f = message.getEzasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isReserved: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    commentsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.toObject, includeInstance),
    supportedModelsList: jspb.Message.toObjectList(msg.getSupportedModelsList(),
    proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.toObject, includeInstance),
    submessageOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message;
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReserved(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addComments(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DriverSupport;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.deserializeBinaryFromReader);
      msg.addSupportedModels(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubmessageOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIsReserved();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.serializeBinaryToWriter
    );
  }
  f = message.getSupportedModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DriverSupport.serializeBinaryToWriter
    );
  }
  f = message.getSubmessageOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isReserved: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    commentsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    transform: (f = msg.getTransform()) && proto.de.mypowergrid.amperix.options.Amperix.Transform.toObject(includeInstance, f),
    subfieldsList: jspb.Message.toObjectList(msg.getSubfieldsList(),
    proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.toObject, includeInstance),
    selection: (f = msg.getSelection()) && proto.de.mypowergrid.amperix.options.Amperix.Selection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field;
  return proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReserved(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addComments(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Transform;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Transform.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.deserializeBinaryFromReader);
      msg.addSubfields(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.amperix.options.Amperix.Selection;
      reader.readMessage(value,proto.de.mypowergrid.amperix.options.Amperix.Selection.deserializeBinaryFromReader);
      msg.setSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIsReserved();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Transform.serializeBinaryToWriter
    );
  }
  f = message.getSubfieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.serializeBinaryToWriter
    );
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.amperix.options.Amperix.Selection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 id = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_reserved = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getIsReserved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setIsReserved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated string comments = 5;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getCommentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setCommentsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.addComments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};


/**
 * optional Transform transform = 6;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Transform}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getTransform = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Transform} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Transform, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Transform|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Field subfields = 7;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getSubfieldsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field, 7));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setSubfieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.addSubfields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.clearSubfieldsList = function() {
  return this.setSubfieldsList([]);
};


/**
 * optional Selection selection = 8;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.getSelection = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection, 8));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.setSelection = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.clearSelection = function() {
  return this.setSelection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field.prototype.hasSelection = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_reserved = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getIsReserved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setIsReserved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string comments = 3;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getCommentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setCommentsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.addComments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};


/**
 * repeated Field fields = 4;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field, 4));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated DriverSupport supported_models = 5;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getSupportedModelsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport, 5));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setSupportedModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.addSupportedModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.clearSupportedModelsList = function() {
  return this.setSupportedModelsList([]);
};


/**
 * optional bool submessage_only = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.getSubmessageOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message.prototype.setSubmessageOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated Message meters = 1;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getMetersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setMetersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addMeters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearMetersList = function() {
  return this.setMetersList([]);
};


/**
 * repeated Message inverters = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getInvertersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setInvertersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addInverters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearInvertersList = function() {
  return this.setInvertersList([]);
};


/**
 * repeated Message wallboxes = 4;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getWallboxesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 4));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setWallboxesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addWallboxes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearWallboxesList = function() {
  return this.setWallboxesList([]);
};


/**
 * repeated Message relays = 5;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getRelaysList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 5));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setRelaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addRelays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearRelaysList = function() {
  return this.setRelaysList([]);
};


/**
 * repeated Message containers = 8;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getContainersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 8));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setContainersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addContainers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearContainersList = function() {
  return this.setContainersList([]);
};


/**
 * repeated Message ezas = 9;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.getEzasList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, 9));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.setEzasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message}
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.addEzas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig.prototype.clearEzasList = function() {
  return this.setEzasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType;
  return proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Alias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Alias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Alias}
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Alias;
  return proto.de.mypowergrid.amperix.options.Amperix.Alias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Alias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Alias}
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Alias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Alias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string drivers = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.getDriversList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Alias} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.setDriversList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Alias} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.addDrivers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Alias} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Alias.prototype.clearDriversList = function() {
  return this.setDriversList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.options.Amperix.Function.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Function} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Function}
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.options.Amperix.Function;
  return proto.de.mypowergrid.amperix.options.Amperix.Function.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Function} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Function}
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.options.Amperix.Function.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.Function} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string code = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.getCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Function} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.setCodeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Function} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.addCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.Function} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.Function.prototype.clearCodeList = function() {
  return this.setCodeList([]);
};


/**
 * repeated DriverSupport supported_models = 1;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getSupportedModelsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport>} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setSupportedModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DriverSupport}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.addSupportedModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DriverSupport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearSupportedModelsList = function() {
  return this.setSupportedModelsList([]);
};


/**
 * optional DeviceConfig device_config = 2;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getDeviceConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.DeviceConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setDeviceConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearDeviceConfig = function() {
  return this.setDeviceConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.hasDeviceConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FallbackConfigType fallback_config_type = 3;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getFallbackConfigType = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.FallbackConfigType|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setFallbackConfigType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearFallbackConfigType = function() {
  return this.setFallbackConfigType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.hasFallbackConfigType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Alias alias = 4;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Alias}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getAlias = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Alias} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Alias, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Alias|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setAlias = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, Function> functions = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.amperix.options.Amperix.Function>}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getFunctionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.amperix.options.Amperix.Function>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.de.mypowergrid.amperix.options.Amperix.Function));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearFunctionsMap = function() {
  this.getFunctionsMap().clear();
  return this;};


/**
 * optional Transform transform = 6;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Transform}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getTransform = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Transform} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Transform, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Transform|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Selection selection = 7;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix.Selection}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.getSelection = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix.Selection} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.options.Amperix.Selection, 7));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
*/
proto.de.mypowergrid.amperix.options.Amperix.prototype.setSelection = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.options.Amperix} returns this
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.clearSelection = function() {
  return this.setSelection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.options.Amperix.prototype.hasSelection = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.de.mypowergrid.amperix.options);
