// package: de.mypowergrid.auth
// file: ext/auth/auth_service.proto

var ext_auth_auth_service_pb = require("../../ext/auth/auth_service_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AuthService = (function () {
  function AuthService() {}
  AuthService.serviceName = "de.mypowergrid.auth.AuthService";
  return AuthService;
}());

AuthService.UpdateLicense = {
  methodName: "UpdateLicense",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.UpdateLicenseRequest,
  responseType: ext_auth_auth_service_pb.UpdateLicenseResponse
};

AuthService.DeleteLicense = {
  methodName: "DeleteLicense",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.DeleteLicenseRequest,
  responseType: ext_auth_auth_service_pb.DeleteLicenseResponse
};

AuthService.GetLicenseID = {
  methodName: "GetLicenseID",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.GetLicenseIDRequest,
  responseType: ext_auth_auth_service_pb.GetLicenseIDResponse
};

AuthService.GetLicense = {
  methodName: "GetLicense",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.GetLicenseRequest,
  responseType: ext_auth_auth_service_pb.GetLicenseResponse
};

AuthService.GetLicenseFeatures = {
  methodName: "GetLicenseFeatures",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.GetLicenseFeaturesRequest,
  responseType: ext_auth_auth_service_pb.GetLicenseFeaturesResponse
};

AuthService.GetServerNonce = {
  methodName: "GetServerNonce",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.GetServerNonceRequest,
  responseType: ext_auth_auth_service_pb.GetServerNonceResponse
};

AuthService.Login = {
  methodName: "Login",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.LoginRequest,
  responseType: ext_auth_auth_service_pb.LoginResponse
};

AuthService.Logout = {
  methodName: "Logout",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.LogoutRequest,
  responseType: ext_auth_auth_service_pb.LogoutResponse
};

AuthService.CreateUser = {
  methodName: "CreateUser",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.CreateUserRequest,
  responseType: ext_auth_auth_service_pb.CreateUserResponse
};

AuthService.CreateUserWithPUK = {
  methodName: "CreateUserWithPUK",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.CreateUserWithPUKRequest,
  responseType: ext_auth_auth_service_pb.CreateUserWithPUKResponse
};

AuthService.ListUsers = {
  methodName: "ListUsers",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.ListUsersRequest,
  responseType: ext_auth_auth_service_pb.ListUsersResponse
};

AuthService.UpdateUser = {
  methodName: "UpdateUser",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.UpdateUserRequest,
  responseType: ext_auth_auth_service_pb.UpdateUserResponse
};

AuthService.UpdateUserWithPUK = {
  methodName: "UpdateUserWithPUK",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.UpdateUserWithPUKRequest,
  responseType: ext_auth_auth_service_pb.UpdateUserWithPUKResponse
};

AuthService.DeleteUser = {
  methodName: "DeleteUser",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.DeleteUserRequest,
  responseType: ext_auth_auth_service_pb.DeleteUserResponse
};

AuthService.GetAuthData = {
  methodName: "GetAuthData",
  service: AuthService,
  requestStream: false,
  responseStream: false,
  requestType: ext_auth_auth_service_pb.GetAuthDataRequest,
  responseType: ext_auth_auth_service_pb.GetAuthDataResponse
};

exports.AuthService = AuthService;

function AuthServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AuthServiceClient.prototype.updateLicense = function updateLicense(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.UpdateLicense, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.deleteLicense = function deleteLicense(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.DeleteLicense, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getLicenseID = function getLicenseID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetLicenseID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getLicense = function getLicense(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetLicense, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getLicenseFeatures = function getLicenseFeatures(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetLicenseFeatures, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getServerNonce = function getServerNonce(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetServerNonce, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.login = function login(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.Login, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.logout = function logout(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.Logout, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.createUserWithPUK = function createUserWithPUK(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.CreateUserWithPUK, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.listUsers = function listUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.ListUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.updateUser = function updateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.UpdateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.updateUserWithPUK = function updateUserWithPUK(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.UpdateUserWithPUK, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.deleteUser = function deleteUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.DeleteUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthServiceClient.prototype.getAuthData = function getAuthData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthService.GetAuthData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AuthServiceClient = AuthServiceClient;

