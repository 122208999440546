/**
 * Actions for the [ems-energy-services-config store]{@link module:store/ems-energy-services-config}
 *
 * @module store/ems-energy-services-config-actions
 */

import { cloneDeep, isEmpty, isEqual, toUpper } from 'lodash'
import { Empty as EmptyPb } from 'google-protobuf/google/protobuf/empty_pb'
import {
  ActuatorStrategies as ActuatorStrategiesPb,
  EnergyService as EnergyServicePb,
  DecisionTree as DecisionTreePb
} from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import logger from '@/logger'
import * as apiPeakObserver from '@/api/ems/peak-observer'
import * as apiSController from '@/api/ems/scontroller'
import {
  SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES,
  buildActuatorGroupName,
  determineActuatorGroupType
} from '@/store/modules/_ems-energy-services-config-helper'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { CACHE_ROOT_KEYS, setCache } from '@/store/cache/cache'

/**
 * Action to auto-create default `ems.scontroller.ActuatorStrategies.ActuatorGroup`(s).
 *
 * Thereto the members (setpoint-sources) of the exisiting
 * actuator-groups are unioned with
 * the setpoint-sources of the EMS source-quantities.
 *
 * Then, for setpoint-source(s), which are NOT member in any actuator-group,
 * the 'trivial' actuator-group is auto-created.
 * A 'trivial' actuator-group is a `ActuatorGroup` with one member.
 * The ID (name) of the 'trivial' actuator-group is derived from the IEC-ID of the setpoint-source.
 * This is done for each actuator-group type separately.
 *
 * Finally, the Map() of new `(AG-ID, AG)` is returned.
 *
 * Note:
 * - Existing actuator-groups and setpoint-sources are received from the (global) Vuex store.
 * - Auto-created actuator-groups are added to the Vuex store.
 *
 *
 * @function
 *
 * @return {Map} newly initiated actuator-groups
 */
export function initDefaultActuatorGroups({ getters, commit, dispatch, rootState }) {
  const allSpSrcs = Object.values(rootState.emsTopologyConfig.setpointSources)

  const ags = new Map()
  Object.keys(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES).forEach((type) => {
    const spSrcs = []
    allSpSrcs.forEach((src) => {
      const isAlreadyPresent =
        getters.getSetpointSourcesByType(type).findIndex((s) => {
          return isEqual(s.iec, src.iec)
        }) > -1
      if (isAlreadyPresent) {
        return
      }

      spSrcs.push([iecIdToLiteral(src.iec), cloneDeep(src)])
    })
    spSrcs.sort((a, b) => a[0].localeCompare(b[0]))
    spSrcs.forEach((spSrc, i) => {
      const src = spSrc[1]
      const buildedAgs = buildDefaultActuatorGroupsForSrcs({ getters, srcs: [src] })
      if (buildedAgs[type]) {
        ags.set(buildActuatorGroupName({ type, id: spSrc[0] }), buildedAgs[type])
      }
    })
  })

  commit('SET_ACTUATOR_GROUPS', ags)

  return ags
}

/**
 * Mainly commits `ADD_ACTUATOR_GROUP`.
 *
 * Will only add (not replace) an actuator group.
 *
 * @function
 *
 * @param {object} arg
 * @param {string} arg.id has to be the actuator-group ID
 * @param {group} arg.group has to be the (plain) `scontroller.ActuatorStrategies.ActuatorGroup`
 *
 */
export function safeAddActuatorGroup({ getters, commit }, { id, group }) {
  const idx = getters.getActuatorGroupIndex(id)
  if (idx !== null) {
    throw Error(`Will not add new actuator-group ${id}, because a group with this ID already exists.`)
  }

  commit('ADD_ACTUATOR_GROUP', { id, group })
}

/**
 * Mainly commits `REMOVE_ACTUATOR_GROUP`
 *
 * Will remove the group also in all strategies.
 * Will trigger `initDefaultActuatorGroups` finally.
 * I.e. removing a Group with members (setpoint-sources) not in any other groups, will trigger the creation of a "default trivial" actuator-group for those members.
 *
 * @function
 *
 * @param {object} arg
 * @param {string} arg.id has to be the actuator-group ID
 *
 * @return {boolean} if `true`, only removed. if `false`, auto-default created "required" trivial actuator-group(s)
 */
export async function safeRemoveActuatorGroup({ getters, commit, dispatch }, { id }) {
  const idx = getters.getActuatorGroupIndex(id)
  if (idx === null) {
    logger.warn(`Will not remove the actuator-group ${id}, because a group with this ID does not exist.`)
    return
  }

  // ensures consistency of strategy's and strategyPreselecion's entries
  commit('REMOVE_ACTUATOR_GROUP', id)

  // ensure consistency
  const ags = await dispatch('initDefaultActuatorGroups')

  return !ags.size
}

/**
 * Action to auto complete a strategy.
 * This ensures, that each setpoint-source is "used" in a strategy.
 * In case, a setpoint-source exists, however, no actuator-group of the strategy has this setpoint-source as member the "default-trivial" actuator-group of this setpoint-source is added to the strategy.
 * This "check" is done for each actuator-group type separately.
 *
 * Actually, enforcing the consistency condition above is optionally,
 * and not done by default.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.strategyId has to be the ID of the strategy to be auto completed.
 * @param {string} params.force if set to `true`, the
 *
 * @return {array} a list of actuator-group IDs, which would/were (`force=false/true`) be auto-added to this strategy during auto completion. If empty, nothing to be auto-completed.
 */
export function autoCompleteStrategy(
  { getters, commit, rootState },
  { strategyId, force = false, skipForIecNodePrefix = null }
) {
  const allSpSrcs = Object.values(rootState.emsTopologyConfig.setpointSources)
  const autoCompletedAGs = []

  Object.keys(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES).forEach((type) => {
    const strgSpSrcs = getters.getStrategySetpointSources(strategyId, { type })
    allSpSrcs.forEach((src) => {
      if (src.iec.iecNodePrefix === skipForIecNodePrefix) {
        return
      }
      const isAlreadyPresent =
        strgSpSrcs.findIndex((s) => {
          return isEqual(s.iec, src.iec)
        }) > -1
      if (isAlreadyPresent) {
        return
      }

      const agIds = getters.getActuatorGroupIdsByActuator({ setpointSource: src, type })

      if (!agIds.length) {
        const buildedAgs = buildDefaultActuatorGroupsForSrcs({ getters, srcs: [src] })
        if (buildedAgs[type]) {
          const agId = buildActuatorGroupName({ type, id: iecIdToLiteral(src.iec) })
          agIds.push(agId)
          if (force) {
            commit('ADD_ACTUATOR_GROUP', { id: agId, group: buildedAgs[type] })
            logger.info(`Created new actuator-group "${agId}" during auto completing strategy.`)
          }
        }
      }

      agIds.forEach((agId) => autoCompletedAGs.push(agId))
      if (force) {
        agIds.forEach((agId) => {
          commit('ADD_ENERGY_SERVICE', {
            strategyId,
            actuatorGroupId: agId,
            energyService: new EnergyServicePb().toObject()
          })
          logger.info(`Auto completed strategy "${strategyId}" by actuator-group "${agId}"`)
        })
      }
    })
  })

  return autoCompletedAGs.sort()
}

/**
 * Action to auto-create the default strategy.
 * This is needed to provide the user a reasonable template for a new strategy.
 *
 * @function
 *
 */
export function createDefaultStrategy({ getters, commit, rootState }, { strategyId }) {
  const grouped = {
    spSrcs: {},
    agIds: {}
  }

  const setpointSourceMap = rootState.emsTopologyConfig.setpointSources

  for (const srcId in setpointSourceMap) {
    const src = setpointSourceMap[srcId]
    let id
    switch (src.iec.iecNodePrefix) {
      case 'bat':
        id = toUpper(src.iec.iecNodePrefix)
        break
      default:
        id = srcId
    }
    if (!grouped.spSrcs[id]) {
      grouped.spSrcs[id] = []
    }
    grouped.spSrcs[id].push(src)
  }

  for (const id in grouped.spSrcs) {
    const agIds = getters.getActuatorGroupIdsByActuators({ setpointSources: grouped.spSrcs[id] })
    if (!agIds.length) {
      const buildedAgs = buildDefaultActuatorGroupsForSrcs({ getters, srcs: grouped.spSrcs[id] })
      for (const type in buildedAgs) {
        if (!buildedAgs[type]) {
          continue
        }
        const agId = buildActuatorGroupName({ type, id })
        agIds.push(agId)
        commit('ADD_ACTUATOR_GROUP', { id: agId, group: buildedAgs[type] })
        logger.info(`Created new actuator-group "${agId}" during create default strategy.`)
      }
    }

    grouped.agIds[id] = agIds
  }

  const strategy = new Map()
  for (const id in grouped.spSrcs) {
    grouped.agIds[id].forEach((agId) => {
      strategy.set(agId, new EnergyServicePb().toObject())
    })
  }

  commit('ADD_STRATEGY', { id: strategyId, strategy })
}

export function cloneStrategy({ state, getters, commit }, { sourceId, targetId }) {
  const srcStrategy = getters.getStrategy(sourceId)
  if (!srcStrategy) {
    logger.warn(`Failed to clone source strategy ${sourceId} to ${targetId}, because the source is empty.`)
    return
  }

  const strategy = new Map()
  srcStrategy.forEach((es, idx) => {
    if (!es) {
      return
    }
    const gId = state.actuatorGroupIds[idx]
    strategy.set(gId, cloneDeep(es))
  })

  commit('ADD_STRATEGY', { id: targetId, strategy })
}

/**
 * Adds an actuator-group to an strategy.
 *
 * Will drop all other actuator-groups of same type from this strategy, sharing any member with the newly added group.
 * This ensures strategy consistency, i.e. that each actuator (setpoint-source member) is employed at most once ("no multi-user of actuator's membership").
 * Will dispatch `autoCompleteStrategy` in `force = false` mode, by default.
 * I.e. actuator-groups of "missing" actuators will not be auto-added, however, will be reported.
 *
 * @function
 *
 * @param {object} arg
 * @param {string} arg.strategyId
 * @param {string} arg.actuatorGroupId
 * @param {boolean} arg.forceAutoAdd
 *
 * @return {object} with two lists. One `droppedActuatorGroupIds` is a list of actuator-group IDs, which had to be dropped from this strategy, because of "unique actuator's membership". Another `autoAddedActuatorGroupIds` is a list of actuator-group IDS, which had to be auto added to this strategy, because of consistency.
 */
export async function addActuatorGroupToStrategy(
  { state, getters, commit, dispatch },
  { strategyId, actuatorGroupId, forceAutoAdd = false }
) {
  const ag = getters.getActuatorGroup(actuatorGroupId)

  if (!ag) {
    logger.error(
      `Failed to add actuator-group ${actuatorGroupId} to strategy ${strategyId}, because no actuator-group was found.`
    )
    return false
  }
  const droppedActuatorGroupIds = []

  if (!getters.getStrategy(strategyId)) {
    logger.error(
      `Failed to add actuator-group ${actuatorGroupId} to strategy ${strategyId}, because no strategy was found.`
    )
    return false
  }

  commit('ADD_ENERGY_SERVICE', {
    strategyId,
    actuatorGroupId,
    energyService: new EnergyServicePb().toObject()
  })

  // getters.getSetpointSourcesByType(type)

  // "drop" all actuator-groups of same type sharing any member with the newly added
  ag.setpointSourcesList.forEach((src) => {
    state.actuatorGroups.forEach((oag, i) => {
      if (actuatorGroupId === state.actuatorGroupIds[i]) {
        return
      }
      if (determineActuatorGroupType(ag) !== determineActuatorGroupType(oag)) {
        return
      }
      if (!oag.setpointSourcesList.some((s) => isEqual(src.iec, s.iec))) {
        return
      }

      const agId = state.actuatorGroupIds[i]
      commit('NULL_ENERGY_SERVICE', { strategyId, actuatorGroupId: agId })
      droppedActuatorGroupIds.push(agId)
      logger.info(`Dropped actuator-group ${agId} from strategy ${strategyId}.`)
    })
  })
  droppedActuatorGroupIds.sort()

  // ensure "consistency"
  const autoAddedActuatorGroupIds = await dispatch('autoCompleteStrategy', { strategyId, force: forceAutoAdd })

  return {
    droppedActuatorGroupIds,
    autoAddedActuatorGroupIds
  }
}

/**
 * Mainly 'nulls' energy-service for a strategy and actuator-group.
 *
 * Finally, dispatchs `autoCompleteStrategy`, to ensure consistency.
 * By default, `autoCompleteStrategy` is done in `force=false` mode,
 * i.e. the "missing" actuator-groups are NOT auto-added,
 * however, are reported.
 *
 * @function
 *
 * @param {object} arg
 * @param {string} arg.strategyId
 * @param {string} arg.acutuatorGroupId
 * @param {string} arg.forceAutoAdd
 *
 * @return {object} see `addActuatorGroupToStrategy`
 */
export async function dropActuatorGroupFromStrategy(
  { getters, commit, dispatch },
  { strategyId, actuatorGroupId, forceAutoAdd = false }
) {
  const agIdx = getters.getActuatorGroupIndex(actuatorGroupId)

  if (agIdx === null) {
    logger.error(
      `Failed to drop actuator-group ${actuatorGroupId} from strategy ${strategyId}, because no actuator-group was found.`
    )
    return false
  }
  if (!getters.getStrategy(strategyId)) {
    logger.error(
      `Failed to drop actuator-group ${actuatorGroupId} from strategy ${strategyId}, because no strategy was found.`
    )
    return false
  }

  commit('NULL_ENERGY_SERVICE', {
    strategyId,
    actuatorGroupId
  })

  const autoAddedActuatorGroupIds = await dispatch('autoCompleteStrategy', { strategyId, force: forceAutoAdd })

  return {
    droppedActuatorGroupIds: [actuatorGroupId],
    autoAddedActuatorGroupIds
  }
}

/**
 * Inits and adds (to the store) an empty decision tree node (one empty node with two empty leafs).
 *
 * @function
 *
 * @param {integer} idx has to be the index at which position the new empty node should be added (pre-order traversalf)
 *
 */
export function initNode({ commit }, idx) {
  const decisionTree = new DecisionTreePb([[], null]) // empty node
  const node = decisionTree.getNode()
  node.setIfYes(new DecisionTreePb([null, ''])) // empty leaf
  node.setIfNo(new DecisionTreePb([null, ''])) // empty leaf

  commit('ADD_DECISION', {
    idx: idx,
    decisionTree: decisionTree.toObject()
  })
}

/**
 * Inits and adds (to the store) an empty decision tree leaf.
 *
 * @function
 *
 * @param {integer} idx has to be the index at which position the new empty leaf should be added (pre-order traversalf)
 *
 */
export function initLeaf({ commit }, idx) {
  const decisionTree = new DecisionTreePb([null, '']) // empty leaf

  commit('ADD_DECISION', {
    idx: idx,
    decisionTree: decisionTree.toObject()
  })
}

/**
 * Action (API call) to get the actuator strategies
 *
 * @function
 *
 * @return {promise}
 */
export async function getActuatorStrategies({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_ACTUATOR_STRATEGIES')
    commit('SET_ACTUATOR_GROUPS', msg.getActuatorGroupFromActuatorIdMap())
    commit('SET_STRATEGIES', msg.getStrategyFromStrategyIdMap())

    return msg
  }

  return apiSController.getStrategies().then(doCommit)
}

/**
 * Action (API call) to get the strategy activation rules (strategy decisions).
 *
 * @function
 *
 * @return {promise}
 */
export async function getStrategyActivationRules({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_DECISIONS')
    commit('SET_DEFAULT_STRATEGY_ID', msg.getDefaultStrategyId())
    if (msg.hasDecisionTree()) {
      commit('SET_DECISION_TREE', msg.getDecisionTree().toObject())
    }

    return msg
  }

  return apiSController.getStrategyDecisions().then(doCommit)
}

/**
 * Action (API call) to get the peak observer params
 *
 * @function
 *
 * @param {object} context the Vuex context
 * @param {object} params
 * @param {string} params.name (optional) the peak-observer name. Defaults to the default peak observer name.
 *
 * @return {promise}
 */
export async function getPeakObserverParams(
  { commit },
  { name = apiPeakObserver.EMS_DEFAULT_PEAK_OBSERVER_NAME } = {}
) {
  const doCommit = (msg) => {
    commit('REMOVE_PEAK_OBSERVER', { name })
    commit('ADD_PEAK_OBSERVER', { name, observer: msg.toObject() })

    return msg
  }
  const doCaching = (msg) => {
    setCache([CACHE_ROOT_KEYS.peakObservers, name], msg.toObject())

    return msg
  }

  return apiPeakObserver.getPeakObserverParams(name).then(doCommit).then(doCaching)
}

/**
 * Action (API call) to set the actuator-group strategy definitions.
 * Will perform `setStrategies` call and post the data stored in the state.
 *
 * @function
 *
 * @return {promise}
 */
export async function setActuatorStrategies({ state }) {
  const params = {
    actuatorGroupIds: state.actuatorGroupIds,
    actuatorGroups: state.actuatorGroups,
    strategyIds: state.strategyIds,
    strategies: state.strategies
  }

  return apiSController.setStrategies(params)
}

/**
 * Action (API call) to set the strategy activation rules.
 * Will perform `setStrategyDecisions` call and post the data stored in the state.
 *
 * @function
 *
 * @return {promise}
 */
export async function setStrategyActivationRules({ state }) {
  const params = {}
  if (state.defaultStrategyId) {
    params.defaultStrategyId = state.defaultStrategyId
  }
  if (!isEmpty(state.decisionTree)) {
    params.decisionTree = state.decisionTree
  }

  return apiSController.setStrategyDecisions(params)
}

/**
 * ACtion (API calls) to set the peak-observer params.
 * Note, only `intervalSeconds`, and `peakWatts` is setable.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.name is the peak-observer name. Defaults to the EMS_DEFAULT_PEAK_OBSERVER_NAME
 *
 * @return {promise}
 */
export async function setPeakObserverParams(
  { getters },
  { name = apiPeakObserver.EMS_DEFAULT_PEAK_OBSERVER_NAME } = {}
) {
  const calls = []
  const { intervalSeconds, peakWatts } = getters.getPeakObserver(name) || {}
  if (intervalSeconds !== undefined) {
    calls.push(
      apiPeakObserver.setPeakObserverInterval({
        intervalSeconds,
        name
      })
    )
  } else {
    logger.warn(
      `Failed to API setPeakObserverInterval, because the peak-observer "${name}" intervalSeconds is missing.`
    )
  }

  if (peakWatts !== undefined) {
    calls.push(
      apiPeakObserver.setPeakObserverPeak({
        peakWatts,
        name
      })
    )
  } else {
    logger.warn(`Failed to API setPeakObserverPeak, because the peak-observer "${name}" peakWatts is missing.`)
  }

  return Promise.all(calls)
}

// private
function newActuatorGroup({ type = 'POWER', srcs = [] }) {
  let ag = new ActuatorStrategiesPb.ActuatorGroup()
  switch (type) {
    case 'POWER':
      if (srcs.length > 1 && srcs.every((src) => src.iec.iecNodePrefix === 'bat')) {
        ag.setBattery(new ActuatorStrategiesPb.ActuatorGroup.Battery([10]))
      } else {
        ag.setSinglePower(new EmptyPb())
      }
      break
    case 'SWITCH':
      ag.setSwitch(new EmptyPb())
      break
    default:
      ag.setSinglePower(new EmptyPb())
  }
  ag = ag.toObject()

  srcs.forEach((src) => {
    ag.setpointSourcesList.push(src)
  })

  return ag
}

function buildAllowedSourcesForActuatorGroupType({ getters, srcs }) {
  const sourceIsAllowedForActuatorGroupType = getters.sourceIsAllowedForActuatorGroupType

  return ({ actuatorGroupType }) => {
    return srcs.every((s) => {
      return sourceIsAllowedForActuatorGroupType({ iecId: s.iec, actuatorGroupType: actuatorGroupType })
    })
  }
}

function buildDefaultActuatorGroupsForSrcs({ getters, srcs }) {
  const ags = {}

  const sourceIsAllowedForActuatorGroupType = buildAllowedSourcesForActuatorGroupType({ getters, srcs })
  Object.keys(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES).forEach((type) => {
    if (!sourceIsAllowedForActuatorGroupType({ actuatorGroupType: type }) || (type === 'SWITCH' && srcs.length > 1)) {
      return
    }

    ags[type] = newActuatorGroup({
      type: type,
      srcs: srcs
    })
  })

  return ags
}

/**
 * Action (API call) to get the suitable energy Services for an actuator group
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.actuatorGroupId, the name of the actuatorGroup
 *
 * @return {promise}
 */
export async function getSuitableEnergyServices({ commit, getters }, { actuatorGroupId }) {
  const doCommit = (msg) => {
    commit('SET_SUITABLE_ENERGY_SERVICES_FOR_ACTUATOR_GROUP', {
      actuatorGroupId,
      suitableEnergyServices: msg.toObject().energyServicesList
    })

    return msg
  }
  const actuatorGroup = getters.getActuatorGroup(actuatorGroupId)
  return apiSController.getSuitableEnergyServices(actuatorGroup).then(doCommit)
}

/**
 * Action (API call) to get the suitable energy Services for an actuator group and store it on positive response
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.actuatorGroupId, the name of the actuatorGroup
 * @param {object} params.actuatorGroup, the object describing the new actuatorGroup
 *
 * @return {promise}
 */
export async function getSuitableEnergyServicesForNewActuatorGroupAndSafeAdd(
  { commit, dispatch },
  { actuatorGroupId, actuatorGroup }
) {
  const doCommit = (msg) => {
    commit('SET_SUITABLE_ENERGY_SERVICES_FOR_ACTUATOR_GROUP', {
      actuatorGroupId,
      suitableEnergyServices: msg.toObject().energyServicesList
    })

    return msg
  }

  return apiSController.getSuitableEnergyServices(actuatorGroup).then((msg) => {
    dispatch('safeAddActuatorGroup', { id: actuatorGroupId, group: actuatorGroup }).then(() => {
      doCommit(msg)
    })
  })
}

/**
 * Action (API call) to get all setpoint sources with allowed actuator group(type)s
 *
 * @function
 *
 * @return {promise}
 */
export async function getSetpointSourcePropertiesCollection({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_SETPOINT_SOURCES_TO_ACTUATOR_GROUPS')

    const setpointSources = msg.toObject().setpointSourcePropertiesList

    for (const setpointSource of setpointSources) {
      commit('SET_SETPOINT_SOURCE_TO_ACTUATOR_GROUPS', {
        setpointSourceIecId: setpointSource.source.iec,
        actuatorGroups: setpointSource.assignableActuatorGroupsList
      })
    }

    return msg
  }

  return apiSController.getSetpointSourcePropertiesCollection().then(doCommit)
}
