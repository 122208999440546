/**
 * Mutations for the [ems-energy-services-config store]{@link module:store/ems-energy-services-config}
 *
 * @module store/ems-energy-services-config-mutations
 */

import Vue from 'vue'
import logger from '@/logger'
import { DecisionTree as DecisionTreePb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'

import * as apiPeakObserver from '@/api/ems/peak-observer'
import { EMS_ENERGY_SERVICE_DECISION_TYPES } from '@/grpc/protobuf/ems-scontroller-helper'
import { isProto } from '@/grpc/protobuf/misc'
import { validateEnergyServicePlainMsg } from '@/grpc/protobuf/ems-energy-service-helper'
import {
  SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES,
  SUPPORTED_EMS_ENERGY_SERVICE_PRESELECTIONS,
  iterateDecisionTree
} from '@/store/modules/_ems-energy-services-config-helper'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
/**
 * Sets the actuator groups store-state from the `Map` of actuator groups.
 * Actuator-groups are added.
 * Actuator-groups, for which its ID already exists, are skipped (not added).
 *
 * Handles the auto-naming of field `switch` -> `pb_switch`, by renaming it back to `switch`.
 *
 * Will also add default entries for each strategy and strategy-preselection.
 * E.g. a strategy will look like `[..., null, null, ...null]`.
 *
 * @function
 *
 * @param {Map} actuatorGroups has to be a `jspb.Map()` or a plain `Map()`
 *
 */
export function SET_ACTUATOR_GROUPS(state, actuatorGroups) {
  actuatorGroups.forEach((g, id) => {
    const i = state.actuatorGroupIds.findIndex((j) => id === j)
    if (i > -1) {
      logger.warn(
        `Tried to set new actuator-group ${id}. But group with same ID is already exisiting. Skipping this actuator-group.`
      )
      return
    }

    state.actuatorGroupIds.push(id)
    if (isProto(g)) {
      g = g.toObject()
    }
    // because `switch` is a reseved keyword, `toObject` named it `pb_switch`
    // reverting this, to avoid parsing problems
    if (Object.prototype.hasOwnProperty.call(g, 'pb_switch')) {
      g.switch = g.pb_switch
      delete g.pb_switch
    }

    if (
      Object.values(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES)
        .flat()
        .every((x) => g[x] === undefined)
    ) {
      g.singlePower = {}
    }
    state.actuatorGroups.push(g)
    state.strategies.forEach((strg) => {
      strg.push(null)
    })
    state.strategyPreselections.forEach((strgSlct) => {
      strgSlct.push(null)
    })
    state.suitableEnergyServices.push(undefined)
  })
}

/**
 * Adds or updates a actuator group from input `({string} id, {ems.scontroller.ActuatorStrategies.ActuatorGroup} group)`.
 *
 * If added, a `null` entry for each strategy and strategy-preselection is added too.
 * This preserves consistency.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.id is the ID of the actuator group
 * @param {object} params.group is the `ems.scontroller.ActuatorStrategies.ActuatorGroup` (plain object)
 */
export function ADD_ACTUATOR_GROUP(state, { id, group }) {
  if (Object.prototype.hasOwnProperty.call(group, 'pb_switch')) {
    group.switch = group.pb_switch
    delete group.pb_switch
  }
  const j = state.actuatorGroupIds.findIndex((i) => i === id)
  if (j > -1) {
    state.actuatorGroups.splice(j, 1, group)
  } else {
    state.actuatorGroupIds.push(id)

    if (
      Object.values(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES)
        .flat()
        .every((x) => group[x] === undefined)
    ) {
      group.singlePower = {}
    }

    state.actuatorGroups.push(group)
    state.strategies.forEach((strg) => {
      strg.push(null)
    })
    state.strategyPreselections.forEach((strgSlct) => {
      strgSlct.push(null)
    })
    state.suitableEnergyServices.push(undefined)
  }
}

export function UPDATE_ACTUATOR_GROUP_ID(state, { oldId, newId }) {
  if (!newId) {
    logger.warn(`Will not update ID of actuator-group "${oldId}", because the new ID is missing.`)
    return
  }

  const j = state.actuatorGroupIds.findIndex((i) => i === oldId)
  if (j < 0) {
    logger.warn(`Tried to update an actuator-group "${oldId}", which was not found. Skipping.`)
    return
  }

  state.actuatorGroupIds.splice(j, 1, newId)
}

/**
 * Removes the actuator-group and actuator-group ID entry,
 * as well as cleans up strategy's and strategy-preselection's entry,
 * corresponding to the removed group.
 *
 * @function
 *
 * @param {string} id has to be the actuator-group's ID
 *
 */
export function REMOVE_ACTUATOR_GROUP(state, id) {
  const j = state.actuatorGroupIds.findIndex((i) => i === id)
  if (j < 0) {
    logger.warn(`Tried to delete actuator-group with ID ${id}, which is not existing`)
    return
  }
  state.actuatorGroupIds.splice(j, 1)
  state.actuatorGroups.splice(j, 1)
  state.strategies.forEach((strg) => {
    strg.splice(j, 1)
  })
  state.strategyPreselections.forEach((strgSlct) => {
    strgSlct.splice(j, 1)
  })
  state.suitableEnergyServices.splice(j, 1)
}

/**
 * Clears all existing strategies,
 * and strategy-preselections,
 * than sets the new strategies with energy-services and preselections from input.
 *
 * @function
 *
 * @param {Map} strategies should be a `jspb.Map()` or a plain `Map` with strategies
 *
 */
export function SET_STRATEGIES(state, strategies) {
  state.strategyIds = []
  state.strategies = []
  state.strategyPreselections = []
  strategies.forEach((strategy, id) => {
    state.strategyIds.push(id)
    state.strategies.push(buildEnergyServicesForStrategy(state, { id, strategy }))
    state.strategyPreselections.push(buildEnergyServicePreselectionsForStrategy(state, { id, strategy }))
  })
}

/**
 * adds or updates a strategy from input `({string} id, {ems.scontroller.ActuatorStrategies.Strategy} strategy)`
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.id is the strategy ID
 * @param {object} params.strategy is the {ems.scontroller.ActuatorStrategies.Strategy} strategy (JSPB or plain Object)
 */
export function ADD_STRATEGY(state, { id, strategy }) {
  if (!id || !strategy) {
    throw new TypeError('Missing input parameters. "id" and "strategy" is required.')
  }

  const energyServices = buildEnergyServicesForStrategy(state, { id, strategy })
  const preselections = buildEnergyServicePreselectionsForStrategy(state, { id, strategy })
  const j = state.strategyIds.findIndex((i) => i === id)
  if (j > -1) {
    state.strategyIds.splice(j, 1, id)
    state.strategies.splice(j, 1, energyServices)
    state.strategyPreselections.splice(j, 1, preselections)
  } else {
    state.strategyIds.push(id)
    state.strategies.push(energyServices)
    state.strategyPreselections.push(preselections)
  }
}

export function REMOVE_STRATEGY(state, id) {
  const j = state.strategyIds.findIndex((i) => i === id)
  if (j < 0) {
    logger.warn(`Tried to delete strategy with ID ${id}, which is not existing`)
    return
  }
  state.strategyIds.splice(j, 1)
  state.strategies.splice(j, 1)
  state.strategyPreselections.splice(j, 1)
}

export function ADD_ENERGY_SERVICE(
  state,
  { strategyId, actuatorGroupId, energyService, energyServicePreselection = null }
) {
  const i = state.strategyIds.findIndex((id) => id === strategyId)
  const j = state.actuatorGroupIds.findIndex((id) => id === actuatorGroupId)
  if (i < 0) {
    logger.warn(`Failed to add EMS energy-service, because no strategy was found for strategy-ID "${strategyId}"`)
    return
  }
  if (j < 0) {
    logger.warn(
      `Failed to add EMS energy-service, because no actuator-group was found for actuator-group-ID "${actuatorGroupId}"`
    )
    return
  }

  if (!validateEnergyServicePlainMsg(energyService)) {
    logger.warn('Failed to add EMS energy-service, because plain message validation failed.')
    return
  }

  state.strategies[i].splice(j, 1, energyService)
  state.strategyPreselections[i].splice(j, 1, energyServicePreselection)
}

/**
 * Set the energy-service of a strategy for a particular actuator-group to `null`.
 * Actually, this is equal to "not use" or "drop" this actuator-group from this strategy.
 *
 */
export function NULL_ENERGY_SERVICE(state, { strategyId, actuatorGroupId }) {
  const i = state.strategyIds.findIndex((id) => id === strategyId)
  const j = state.actuatorGroupIds.findIndex((id) => id === actuatorGroupId)
  if (i < 0 || j < 0) {
    logger.warn(
      `Failed to remove energy-service, because no strategy or actuator-group was found for strategy-ID ${strategyId} and actuator-group-ID ${actuatorGroupId}.`
    )
    return
  }

  // splice can deal with `j` being out of the index range
  state.strategies[i].splice(j, 1, null)
  state.strategyPreselections[i].splice(j, i, null)
}

export function CLEAR_ACTUATOR_STRATEGIES(state) {
  state.actuatorGroupIds = []
  state.actuatorGroups = []
  state.strategyIds = []
  state.strategies = []
  state.strategyPreselections = []
  state.suitableEnergyServices = []
}

export function ADD_PEAK_OBSERVER(state, { name, observer }) {
  if (isProto(observer)) {
    observer = observer.toObject()
  }
  if (!name) {
    name = apiPeakObserver.EMS_DEFAULT_PEAK_OBSERVER_NAME
  }

  if (state.peakObservers[name]) {
    state.peakObservers[name] = Object.assign({}, state.peakObservers[name], observer)
  } else {
    Vue.set(state.peakObservers, name, observer)
  }
}

export function REMOVE_PEAK_OBSERVER(state, { name }) {
  Vue.delete(state.peakObservers, name)
}

export function CLEAR_PEAK_OBSERVERS(state) {
  state.peakObservers = {}
}

export function SET_DEFAULT_STRATEGY_ID(state, strategyId) {
  state.defaultStrategyId = strategyId
}

export function SET_DECISION_TREE(state, tree) {
  if (!tree) {
    tree = new DecisionTreePb([[[], []]]).toObject()
  }

  state.decisionTree = tree
}

export function CLEAR_DECISION_TREE(state) {
  state.decisionTree = {}
}

export function CLEAR_SUITABLE_ENERGY_SERVICES(state, actuatorGroupId) {
  const j = state.actuatorGroupIds.findIndex((i) => i === actuatorGroupId)
  if (j >= 0) {
    state.suitableEnergyServices[j] = undefined
  }
}

export function ADD_DECISION(state, { idx, decisionTree }) {
  let failed = true
  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx) {
      // make sure, that decisionTree is a fully valid ems.scontroller.decisionTree, i.e. all properties are present
      // if NOT the case, assign will break vuejs reactivity, and Vue.set has to be used
      // e.g. this is the case if replaying from local storage, because `undefined` values are NOT stored
      if (
        Object.prototype.hasOwnProperty.call(tree, 'node') &&
        Object.prototype.hasOwnProperty.call(tree, 'strategyId')
      ) {
        Object.assign(tree, decisionTree) // reference (sub) tree to new (sub) decisionTree
      } else {
        Vue.set(tree, 'node', decisionTree.node)
        Vue.set(tree, 'strategyId', decisionTree.strategyId)
      }
      failed = false

      return false
    }
    return true
  })

  if (failed && idx === 0) {
    state.decisionTree = decisionTree
    failed = false
  }

  if (failed) {
    logger.warn(
      `Failed to add/update new decision (sub-tree) to the current decision tree. No node exists for index ${idx}.`
    )
  }
}

export function UPDATE_DECISION_NODE_PARAMS(state, { idx, nodeParams }) {
  let failed = true
  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx && entry.isNode) {
      EMS_ENERGY_SERVICE_DECISION_TYPES.forEach((t) => delete tree.node[t])
      // the ems.scontroller.DecisionTree.Node constructor has to be used otherwise assign below will not work properly
      const nodeParamsKeys = Object.keys(nodeParams)
      const nodeKeys = Object.keys(new DecisionTreePb.Node([]).toObject())
      if (nodeParamsKeys.length !== nodeKeys.length || !nodeKeys.every((k) => nodeParamsKeys.indexOf(k) >= 0)) {
        throw new TypeError(
          `Invalid nodeParams argument. "nodeParams" have to be constructed by the ems.scontroller.DecisionTree.Node constructor. Expected keys ${nodeKeys}. Present keys ${nodeParamsKeys}`
        )
      }

      // decisions ifYes/ifNo have to be preserved
      delete nodeParams.ifYes
      delete nodeParams.ifNo

      tree.node = Object.assign({}, tree.node, nodeParams)
      failed = false
      return false
    }
    return true
  })

  if (failed) {
    logger.warn(
      `Failed to update decision node params. Either no entry exists for index ${idx}, or this entry is a leaf.`
    )
  }
}

export function ADD_DECISION_NODE_OPTION(state, { idx, type, value }) {
  let failed = true

  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx && entry.isNode) {
      if (!tree.node.optionsList) Vue.set(tree.node, 'optionsList', [])

      const option = {}

      if (type === 'timeHysteresis') {
        option.timeHysteresis = {
          cooldownSeconds: value
        }
        tree.node.optionsList.push(option)
      }

      failed = false
      return false
    }
    return true
  })

  if (failed) {
    logger.warn(
      `Failed to update decision node params. Either no entry exists for index ${idx}, or this entry is a leaf.`
    )
  }
}

export function REMOVE_DECISION_NODE_OPTION(state, { idx, optionType }) {
  let failed = true

  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx && entry.isNode) {
      if (!tree.node.optionsList) Vue.set(tree.node, 'optionsList', [])

      let i = tree.node.optionsList.findIndex((x) => x[optionType])

      while (i >= 0) {
        Vue.delete(tree.node.optionsList, i)

        i = tree.node.optionsList.findIndex((x) => x[optionType])
      }

      failed = false
      return false
    }
    return true
  })

  if (failed) {
    logger.warn(
      `Failed to update decision node params. Either no entry exists for index ${idx}, or this entry is a leaf.`
    )
  }
}

export function UPDATE_DECISION_NODE_OPTION_FALLBACK(state, { idx, fallback }) {
  let failed = true

  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx && entry.isNode) {
      if (!tree.node.optionsList) Vue.set(tree.node, 'optionsList', [])

      // Delete all current fallback Options (only one is valid)
      let i = 0
      while (i < tree.node.optionsList.length) {
        const opt = tree.node.optionsList[i]

        if (opt.filterDefault || opt.filterLast) {
          Vue.delete(tree.node.optionsList, i)
        } else {
          i++
        }
      }

      if (fallback === 'filterLast') {
        tree.node.optionsList.unshift({ filterLast: {} })
      } else if (fallback) {
        if (fallback === 'DEFAULT') {
          tree.node.optionsList.unshift({ filterDefault: { defaultStrategy: state.defaultStrategyId } })
        } else {
          tree.node.optionsList.unshift({ filterDefault: { defaultStrategy: fallback } })
        }
      }

      failed = false
      return false
    }
    return true
  })

  if (failed) {
    logger.warn(
      `Failed to update decision node fallback option. Either no entry exists for index ${idx}, or this entry is a leaf.`
    )
  }
}

export function UPDATE_DECISION_LEAF_PARAMS(state, { idx, strategyId }) {
  let failed = true
  iterateDecisionTree(state.decisionTree, (entry, i, tree) => {
    if (i === idx && !entry.isNode) {
      tree.strategyId = strategyId
      failed = false
      return false
    }
    return true
  })

  if (failed) {
    logger.warn(
      `Failed to update decision node leaf. Either no entry exists for index ${idx}, or this entry is a node.`
    )
  }
}

export function CLEAR_DECISIONS(state) {
  state.defaultStrategyId = null
  state.decisionTree = {}
}

export function SET_CACHE(state, { key, val }) {
  if (Object.prototype.hasOwnProperty.call(state.cache, key)) {
    state.cache[key] = val
  } else {
    Vue.set(state.cache, key, val)
  }
}

export function SET_SUITABLE_ENERGY_SERVICES_FOR_ACTUATOR_GROUP(state, { actuatorGroupId, suitableEnergyServices }) {
  const j = state.actuatorGroupIds.findIndex((i) => i === actuatorGroupId)

  if (j >= 0) {
    Vue.set(state.suitableEnergyServices, j, suitableEnergyServices)
  }
}

export function CLEAR_SETPOINT_SOURCES_TO_ACTUATOR_GROUPS(state) {
  state.setpointSourcesToActuatorGroups = {}
}

export function SET_SETPOINT_SOURCE_TO_ACTUATOR_GROUPS(state, { setpointSourceIecId, actuatorGroups }) {
  Vue.set(state.setpointSourcesToActuatorGroups, iecIdToLiteral(setpointSourceIecId), actuatorGroups)
}

// private
function buildEnergyServicesForStrategy(state, { id, strategy }) {
  strategy = undressStrategy(strategy)

  const energyServices = new Array(state.actuatorGroupIds.length).fill(null)
  iterateStrategy(state, {
    id,
    strategy,
    cb: (es, j) => {
      energyServices[j] = es
    }
  })

  return energyServices
}

function buildEnergyServicePreselectionsForStrategy(state, { id, strategy }) {
  strategy = undressStrategy(strategy)

  const preselections = new Array(state.actuatorGroupIds.length).fill(null)
  const addPreselection = (es, j) => {
    let preselection = null
    let energyServiceId = null
    if (es.targetPower) {
      energyServiceId = 'targetPower'
      preselection = determineTargetPowerPreselection(es.targetPower)
    }
    validatePreselection({ preselection, energyServiceId })
    preselections[j] = preselection
  }

  iterateStrategy(state, {
    id,
    strategy,
    cb: addPreselection
  })

  return preselections

  // private
  function determineTargetPowerPreselection(tp) {
    const isGrid = !tp.positionInTopology || !tp.positionInTopology.physicalDevicesList.length
    if (tp.evalWatts) {
      return 'EVAL'
    } else if (tp.powerWatts === 0 && isGrid) {
      return 'SELF-CONSUMPTION'
    } else if (tp.powerWatts > 0 && isGrid) {
      return 'PEAKSHAVING'
    } else if (tp.powerWatts < 0 && isGrid) {
      return 'PV-CURTAILMENT'
    } else {
      return 'ADVANCED'
    }
  }

  function validatePreselection({ preselection, energyServiceId }) {
    if (!energyServiceId) {
      return
    }
    if (!SUPPORTED_EMS_ENERGY_SERVICE_PRESELECTIONS[energyServiceId].includes(preselection)) {
      throw new Error(`Unsupported preselection '${preselection}' for EMS energy-service ${energyServiceId}.`)
    }
  }
}

function undressStrategy(strategy) {
  if (isProto(strategy) && !isIterable(strategy)) {
    strategy = strategy.getEnergyServiceFromActuatorIdMap()
  } else if (!isIterable(strategy)) {
    strategy = strategy.energyServiceFromActuatorIdMap
  }
  if (!isIterable(strategy)) {
    throw new TypeError('Input parameters "strategy" does not contain an energy-service Map or is NOT iterable itself.')
  }

  return strategy
}

function iterateStrategy(state, { id, strategy, cb }) {
  strategy.forEach((es, gId) => {
    const j = state.actuatorGroupIds.findIndex((i) => i === gId)
    if (j < 0) {
      logger.warn(
        `Failed to add strategy energy-service with strategy-ID "${id}" and actuator-group-ID "${gId}". No actuator-group for "${gId}" was found.`
      )
      return
    }
    if (isProto(es)) {
      es = es.toObject()
    }
    cb(es, j)
  })
}

function isIterable(itr) {
  return itr && typeof itr.forEach === 'function'
}
