// package: de.mypowergrid.sherlock
// file: ext/sherlock/one_wire.proto

var ext_sherlock_one_wire_pb = require("../../ext/sherlock/one_wire_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OneWireService = (function () {
  function OneWireService() {}
  OneWireService.serviceName = "de.mypowergrid.sherlock.OneWireService";
  return OneWireService;
}());

OneWireService.GetOneWireInfo = {
  methodName: "GetOneWireInfo",
  service: OneWireService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_one_wire_pb.GetOneWireInfoRequest,
  responseType: ext_sherlock_one_wire_pb.GetOneWireInfoResponse
};

OneWireService.GetOneWireSensorReadings = {
  methodName: "GetOneWireSensorReadings",
  service: OneWireService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_one_wire_pb.GetOneWireSensorReadingsRequest,
  responseType: ext_sherlock_one_wire_pb.GetOneWireSensorReadingsResponse
};

exports.OneWireService = OneWireService;

function OneWireServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OneWireServiceClient.prototype.getOneWireInfo = function getOneWireInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OneWireService.GetOneWireInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OneWireServiceClient.prototype.getOneWireSensorReadings = function getOneWireSensorReadings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OneWireService.GetOneWireSensorReadings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OneWireServiceClient = OneWireServiceClient;

