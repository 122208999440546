<template>
  <CCol sm="12" :lg="{ size: 8, offset: 2 }" :xl="{ size: 6, offset: 3 }">
    <CCard class="mx-3 mx-lg-0">
      <ApxCardHeader icon="cil-key-alt" :title="pageTitle" />
      <CCardBody>
        <!-- BEGIN: License exists => show hint that registraion has been completed  -->
        <template v-if="hasLicense && !newUserRequired">
          <CCardText class="small m-0 p-0 d-flex justify-content-start">
            <p class="text-muted text-break">
              {{ $t('management.user.login.registrationCompleted.content', [brandName]) }}
            </p>
          </CCardText>
        </template>
        <!-- END: License exists => show hint that registraion has been completed -->
        <!-- BEGIN: Form to add missing license in case that License is missing, user exists -->
        <CForm v-else-if="!hasLicense && !newUserRequired" @submit.prevent>
          <CCardText class="small m-0 p-0 d-flex justify-content-start">
            <p class="text-muted text-break">
              {{ $t('management.user.registerLicenseForm.licenseMissingUserExistsMsg') }}
            </p>
          </CCardText>
          <CTextarea
            class="custom-form-label"
            :lazy="true"
            :value="licenseForm.license"
            :description="$t('management.license.form.description')"
            :placeholder="$t('management.license.form.placeholder')"
            :rows="5"
            :is-valid="$v.licenseForm.license.$error ? false : null"
            :invalid-feedback="invalidFeedbackLicense"
            @input="onLicenseInsert"
          >
            <template #prepend-content>
              <CIcon name="cil-key-alt" />
            </template>
          </CTextarea>
          <CRow>
            <CCol class="text-right offset-6 col-6">
              <LicenseUploadBtn @upload:success="onLicenseUploadSuccess" @upload:error="onLicenseUploadError" />
            </CCol>
          </CRow>
          <!-- BEGIN: Action Buttons -->
          <CRow class="d-flex align-items-center mt-2">
            <CCol class="text-left col-6">
              <CButton color="primary" :disabled="$v.licenseForm.$invalid" @click="onSubmitLicense">
                {{ $t('main.saveBtn') }}
              </CButton>
            </CCol>
            <CCol class="text-right col-6">
              <CButton
                color="link"
                :class="{
                  'pr-0': true,
                  'btn-link-darkMode': isDark
                }"
                @click="routeToLogin"
              >
                {{ $t('management.user.registerLicenseForm.loginLink') }}
              </CButton>
            </CCol>
          </CRow>
          <!-- END: Action Buttons -->
        </CForm>
        <!-- END: Form to add missing license in case that License is missing and user exists -->
        <!-- ================================ -->
        <!-- ================================ -->
        <!-- BEGIN: Initial registration form -->
        <CForm v-else @submit.prevent>
          <CCardText class="small m-0 p-0 d-flex justify-content-start">
            <p class="text-muted text-break">
              {{ $t('management.user.registerLicenseForm.description', [brandName]) }}
            </p>
          </CCardText>
          <CInput
            class="custom-form-label"
            :value.sync="$v.registrationForm.username.$model"
            :placeholder="$t('management.user.registerLicenseForm.username.placeholder')"
            :is-valid="!$v.registrationForm.username.$dirty ? null : !$v.registrationForm.username.$invalid"
            :invalid-feedback="invalidFeedbackFor('username')"
          >
            <template #prepend-content>
              <CIcon name="cil-user" />
            </template>
          </CInput>
          <CInput
            class="custom-form-label"
            :value.sync="$v.registrationForm.password.$model"
            :placeholder="$t('management.user.registerLicenseForm.password.placeholder')"
            :is-valid="!$v.registrationForm.password.$dirty ? null : !$v.registrationForm.password.$invalid"
            :invalid-feedback="invalidFeedbackFor('password')"
            :type="showPassword.password ? 'text' : 'password'"
          >
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
            <!-- BEGIN: Toggle Password Visibility -->
            <template #append-content>
              <PwdVisibilityToggle
                :show-password="showPassword.password"
                @togglePwdVisibility="showPassword.password = !showPassword.password"
              />
            </template>
            <!-- END: Toggle Password Visibility  -->
          </CInput>
          <CInput
            class="custom-form-label"
            :value.sync="$v.registrationForm.passwordConfirm.$model"
            :placeholder="$t('management.user.registerLicenseForm.passwordConfirm.placeholder')"
            :is-valid="
              !$v.registrationForm.passwordConfirm.$dirty ? null : !$v.registrationForm.passwordConfirm.$invalid
            "
            :invalid-feedback="invalidFeedbackFor('passwordConfirm')"
            :type="showPassword.passwordConfirm ? 'text' : 'password'"
          >
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
            <!-- BEGIN: Toggle Password Visibility -->
            <template #append-content>
              <PwdVisibilityToggle
                :show-password="showPassword.passwordConfirm"
                @togglePwdVisibility="showPassword.passwordConfirm = !showPassword.passwordConfirm"
              />
            </template>
            <!-- END: Toggle Password Visibility  -->
          </CInput>
          <!-- BEGIN: License -->
          <CTextarea
            class="custom-form-label"
            :lazy="true"
            :value="registrationForm.license"
            :description="$t('management.license.form.description')"
            :placeholder="$t('management.license.form.placeholder')"
            :rows="5"
            :is-valid="$v.registrationForm.license.$error ? false : null"
            :invalid-feedback="invalidFeedbackLicense"
            @input="onLicenseInsert"
          >
            <template #prepend-content>
              <CIcon name="cil-key-alt" />
            </template>
          </CTextarea>
          <CRow>
            <CCol class="text-right offset-6 col-6">
              <LicenseUploadBtn @upload:success="onLicenseUploadSuccess" @upload:error="onLicenseUploadError" />
            </CCol>
          </CRow>
          <!-- END: License -->
          <!-- BEGIN: Save/Login Button -->
          <CRow class="d-flex align-items-center mt-2">
            <CCol class="text-left col-6">
              <CButton color="primary" :disabled="$v.registrationForm.$invalid" @click="onSubmitRegistration">
                {{ $t('management.user.registerLicenseForm.completeRegistration') }}
              </CButton>
            </CCol>
            <CCol class="text-right col-6">
              <CButton
                color="link"
                :class="{
                  'pr-0': true,
                  'btn-link-darkMode': isDark
                }"
                @click="routeToLogin"
              >
                {{ $t('management.user.registerLicenseForm.loginLink') }}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
        <!-- END: Initial registration form -->
        <CRow v-if="hasLicense">
          <CCol class="text-right offset-6 col-6">
            <CButton
              color="link"
              :class="{
                'btn-link-darkMode': isDark
              }"
              @click="routeToLogin"
            >
              {{ $t('management.user.registerLicenseForm.loginLink') }}
            </CButton>
          </CCol>
        </CRow>
        <!-- END:   Save/Login Button -->
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import LicenseUploadBtn from '@/components/license/license-upload-btn'
import PwdVisibilityToggle from '@/components/snippets/pwd-visibility-toggle'

import { newToastNotificationMsg } from '@/store/modules/notifications'
import { registrationValidations } from '@/validations/user-settings-validators'
import { licenseValidators } from '@/validations/license-validators'

export default {
  name: 'Register',
  components: {
    ApxCardHeader,
    LicenseUploadBtn,
    PwdVisibilityToggle
  },
  data() {
    return {
      registrationForm: {
        license: '',
        username: '',
        password: '',
        passwordConfirm: ''
      },
      licenseForm: {
        license: ''
      },
      showPassword: {
        password: false,
        passwordConfirm: false
      }
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('auth', ['hasLicense']),
    ...mapState('auth', ['newUserRequired']),
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v.registrationForm[field][condition]
        }
        const base = `management.user.registerLicenseForm.${field}.invalidFeedback`

        let conditions = ['required', 'minLength']

        if (field === 'password') {
          conditions = ['required', 'minLength', 'strongPasswordRequired']
        }
        if (field === 'passwordConfirm') {
          conditions = ['sameAsPassword']
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    invalidFeedbackLicense() {
      if (!this.$v.registrationForm.license.required) {
        return this.$t('management.license.form.invalidFeedbackRequired')
      }

      if (!this.$v.registrationForm.license.validJwt) {
        return this.$t('management.license.form.invalidFeedbackJwt')
      }

      return ''
    },
    pageTitle() {
      if (this.hasLicense && !this.newUserRequired) {
        return this.$t('management.user.login.registrationCompleted.title')
      } else if (!this.hasLicense && !this.newUserRequired) {
        return this.$t('management.user.login.licenseMissingUserExists.title')
      } else {
        return this.$t('management.user.registerLicenseForm.title')
      }
    }
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
  },
  methods: {
    onLicenseInsert(l) {
      this.registrationForm.license = l.trim()
      this.$v.registrationForm.license.$touch()

      this.licenseForm.license = l.trim()
      this.$v.licenseForm.license.$touch()
    },
    onLicenseUploadError(err) {
      this.$log.error('License file upload failed.')
      this.$log.error(err)
      this.registrationForm.license = ''
      this.licenseForm.license = ''

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 16000,
          type: 'danger',
          title: this.$t('management.user.registerLicenseForm.registrationToasterTitle'),
          content: this.$t('management.license.form.invalidFile')
        })
      )
    },
    onLicenseUploadSuccess(l) {
      this.registrationForm.license = l
      this.$v.registrationForm.license.$touch()

      this.licenseForm.license = l
      this.$v.licenseForm.license.$touch()
    },
    onServerError(err) {
      this.$log.warn(err, `code: ${err.code}`)
      let msg = err.message

      // TODO: adapt to extended error codes, if falconer supports so
      if (err.code === 6) {
        msg = this.$t('api.errors.registration')
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 16000,
          type: 'danger',
          title: this.$t('management.user.registerLicenseForm.registrationToasterTitle'),
          content: msg
        })
      )
    },
    onSubmitLicense() {
      // Set license that was entered in the form to the store
      this.$store.commit('auth/SET_LICENSE', this.registrationForm.license)

      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('auth/updateLicense')
        .then(() => {
          this.$log.debug('Succesfully updated missing license. Login is possible with exisiting user account.')

          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: 8000,
              type: 'success',
              title: this.$t('management.user.registerLicenseForm.registrationToasterTitle'),
              content: this.$t('api.success.registrationWhileUsersExist')
            })
          )
        })
        .catch(this.onServerError)
        .finally(() => {
          this.refreshLicenseId()
          this.$store.commit('apiLoadingStatus/STOPPED')
          this.routeToLogin()
        })
    },
    onSubmitRegistration() {
      this.$v.registrationForm.$touch()
      if (this.$v.registrationForm.$anyError) {
        return
      }

      this.$store.commit('apiLoadingStatus/STARTED')

      const password = this.registrationForm.password
      const username = this.registrationForm.username
      const license = this.registrationForm.license

      this.$store
        .dispatch('auth/initialSignUp', { license, username, password })
        .then(() => {
          this.$log.debug('User succesfully signed up.')
          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              type: 'success',
              title: this.$t('management.user.registerLicenseForm.registrationToasterTitle'),
              content: this.$t('api.success.registration')
            })
          )
        })
        .catch(this.onServerError)
        .finally(() => {
          this.refreshLicenseId()
          this.$store.commit('apiLoadingStatus/STOPPED')
          this.routeToLogin()
        })
    },
    // NOTE: refreshLicenseId is needed to update the state of 'newUserRequired' after license submission
    refreshLicenseId() {
      this.$store
        .dispatch('auth/getLicenseId')
        .then(() => {
          this.$log.debug('Successfully fetched license ID.')
        })
        .catch(this.onServerError)
        .finally(() => {})
    },
    routeToLogin() {
      this.$router.push({ name: 'login' })
    }
  },
  validations: {
    registrationForm: registrationValidations(),
    licenseForm: licenseValidators
  }
}
</script>
