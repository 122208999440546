<template>
  <CCard>
    <ApxCardHeader icon="cil-input" :title="$t(`${tBasePath}.title`)" />
    <CCardBody>
      <CForm @submit.prevent>
        <CInput
          v-model="$v.listenAddress.$model"
          class="mt-4 custom-form-label"
          :label="$t(`${tBasePath}.form.listenAddress.label`)"
          :is-valid="$v.listenAddress.$dirty ? !$v.listenAddress.$invalid : null"
          :invalid-feedback="$t(`${tBasePath}.form.listenAddress.invalidFeedback`)"
          type="text"
          placeholder="0.0.0.0:1503"
          :description="$v.listenAddress.$invalid ? '' : $t(`${tBasePath}.form.listenAddress.description`)"
          :lazy="true"
          horizontal
          @input="setListenAddress"
        />
      </CForm>
    </CCardBody>

    <CCardFooter>
      <!-- prettier-ignore -->
      <CButton
        :disabled="$v.$invalid"
        type="submit"
        size="sm"
        color="primary"
        @click="save"
      >
        <CIcon size="sm" name="cil-save" />
        <span class="align-middle d-none d-sm-inline">
          {{ $t('main.saveBtn') }}
        </span>
      </CButton>
    </CCardFooter>
  </CCard>
</template>
<script>
import ApxCardHeader from '@/components/snippets/apx-card-header'
import { mapGetters } from 'vuex'
import { ipAddress, numeric, required } from 'vuelidate/lib/validators'
import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'OpenModbusInterfaceConfig',
  components: {
    ApxCardHeader
  },
  data() {
    return {
      listenAddress: null
    }
  },
  computed: {
    ...mapGetters('coreConfig', ['getField']),
    storedListenAddress() {
      return this.getField('modbusServerListenAddress')
    }
  },
  watch: {
    storedListenAddress(val) {
      this.$v.listenAddress.$model = this.storedListenAddress
    }
  },
  created() {
    this.tBasePath = 'config.openModbusInterfaceConfig'
  },
  mounted() {
    this.$store.dispatch('coreConfig/getInverterModbusServerConfig').catch((e) => {
      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 8000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content: e.message
        })
      )
    })
  },
  validations: {
    listenAddress: {
      required,
      ipAddressWithPort: (val) => {
        const ipAndPort = val.split(':')

        if (ipAndPort.length !== 2) return false

        return ipAddress(ipAndPort[0]) && numeric(ipAndPort[1]) && required(ipAndPort[1])
      }
    }
  },
  methods: {
    setListenAddress(input) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('coreConfig/SET_FIELD', {
          field: 'modbusServerListenAddress',
          value: input
        })
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store
          .dispatch('coreConfig/setInverterModbusServerConfig')
          .then(() => {
            this.$store.commit(
              'notifications/PUSH_TOAST',
              newToastNotificationMsg({
                autohide: 8000,
                type: 'success',
                title: this.$t(`${this.tBasePath}.form.formSuccessTitle`),
                content: this.$t(`${this.tBasePath}.form.formSuccess`)
              })
            )
          })
          .catch((e) => {
            this.$store.commit(
              'notifications/PUSH_TOAST',
              newToastNotificationMsg({
                autohide: 8000,
                type: 'danger',
                title: this.$t('api.errors.server'),
                content: e.message
              })
            )
          })
      }
    }
  }
}
</script>
