/**
 * Getters for the [ems-topology-config store]{@link module:store/ems-topology-config}
 *
 * @module store/ems-topology-config-getters
 */

import { isEqual, isNil, isUndefined, uniq, uniqWith } from 'lodash'
import { PhysicalDeviceType } from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import logger from '@/logger'
import {
  MTR_COUNTER_MEASURED_QUANTITIES,
  PHASE_TOPO_MEASURED_QUANTITIES,
  SRC_DEVICE_MEASURED_QUANTITIES
} from '@/grpc/protobuf/ems-topology-helper'
import {
  iecIdToLiteral,
  physicalDeviceToLiteral,
  literalToPhysicalDevice
} from '@/store/modules/_ems-topology-config-helper'

export const existingPhysicalDevices = (state) => state.existingPhysicalDevices

export const hasPhysicalDevices = (state) => {
  return !!state.existingPhysicalDevices.length
}

/**
 * Traverses the `existingPhysicalDevices`, and `topology`,
 * to provide a map/lookup from physical-device literal to a list of sources of this device. (Sources might be duplicated.)
 *
 * @function
 *
 * @return {object} with physical-device literals to source-list.
 */
export const sourcesOfPhysicalDevices = (state) => {
  const sopd = {}
  state.existingPhysicalDevices.forEach((pd) => {
    sopd[physicalDeviceToLiteral(pd)] = []
  })
  const addSrc = (pd, src) => {
    try {
      sopd[physicalDeviceToLiteral(pd)].push(src)
    } catch (_err) {
      logger.debug(
        `Inconsistent EMS topology state. E.g. tried to add a source for a physical device, where the physical device is not in the list of existingPhysicalDevices. Physical device: ${physicalDeviceToLiteral(
          pd
        )}.`
      )
    }
  }
  state.topology.phaseTopologiesList.forEach((ptopo) => {
    ptopo.physicalDeviceOnPhaseList.forEach((pdop) => {
      addSrc(pdop.physicalDevice, ptopo.source)
    })
  })
  state.topology.sourceToDeviceTopologiesList.forEach((stopo) => {
    addSrc(stopo.physicalDevice, stopo.source)
  })

  return sopd
}

/**
 * Filters list of `existingPhysicalDevices` by those supporting the specified `DoubleQuantityDescription` oneof.
 *
 * @function
 *
 * Getter has arg: `{string} quantity` is oneof `ems.topology.DoubleQuantityDescription` or `_PHASE_TOPO` or `_SRC_DEVICE_TOPO`
 *
 * @return {function}
 */
export const getPhysicalDevicesWithDoubleQuantity = (state, getters) => {
  return (quantity) => {
    const pdls = []
    for (const pdLiteral in getters.sourcesOfPhysicalDevices) {
      getters.sourcesOfPhysicalDevices[pdLiteral].forEach((src) => {
        if (
          getters.hasQuantity({
            iecId: src.iec,
            quantity,
            quantityTypes: ['doubleMq']
          })
        ) {
          pdls.push(pdLiteral)
        }
      })
    }

    return uniq(pdls).map((pdl) => {
      return literalToPhysicalDevice(pdl)
    })
  }
}

/**
 * Getter allows to get (filtered) list of `ems.topology.PhysicalDevice` (plain JS object)
 * from the list of `existingPhysicalDevices`,
 * filtered by the physical device type (`deviceType` = `uint` or `string` of `ems.topology.PhysicalDeviceType` Enum).
 * If `deviceType` is missing, all `existingPhysicalDevices` are returned.
 *
 * Returned list is sorted alpha-numerically.
 *
 * @function
 *
 * @return {function}
 */
export const getPhysicalDevices = (state) => {
  return (deviceType) => {
    if (typeof deviceType === 'string') {
      deviceType = PhysicalDeviceType[deviceType]
    }

    let pds
    if (!isNil(deviceType)) {
      pds = state.existingPhysicalDevices.filter((pd) => {
        return pd.type === deviceType
      })
    } else {
      pds = state.existingPhysicalDevices
    }

    return pds
  }
}

export const currentPhysicalDeviceNumber = (state, getters) => {
  return (deviceType) => {
    return getters.getPhysicalDevices(deviceType).length
  }
}

export const nextPhysicalDeviceNumber = (state, getters) => {
  return (deviceType) => {
    return getters.getPhysicalDevices(deviceType).length + 1
  }
}

export const logicalDeviceNames = (state) => {
  const ldns = state.sourceQuantitiesList.map((sq) => {
    return sq.source.iec.logicalDeviceName
  })

  return ldns
    .filter((ldn, i, list) => {
      return list.indexOf(ldn) === i
    })
    .sort()
}

/**
 * Getter allows to filter the `sourceQuantitiesList` by LDN
 * and returns a sorted list.
 *
 * @function
 *
 * @return {function}
 */
export const getSourceQuantities = (state) => {
  return (ldn) => {
    return state.sourceQuantitiesList
      .filter((sq) => {
        if (!ldn) {
          return true
        }
        return sq.source.iec.logicalDeviceName === ldn
      })
      .sort((sqa, sqb) => {
        const getN = (sq) => {
          let n
          if (sq.source && sq.source.iec) {
            n = iecIdToLiteral(sq.source.iec)
            if (sq.source.iec.iecNodePrefix === 'mtr') {
              n = 'zzz.' + n // let the mtr be the last
            }
          }

          return n
        }

        if (!getN(sqa)) {
          return -1
        }
        if (!getN(sqb)) {
          return 1
        }

        return getN(sqa).localeCompare(getN(sqb))
      })
  }
}

/**
 * Allows to ask, if a source or logical device has the requested `ems.topology.DoubleQuantityDescription` or `ems.topology.ThreePhaseQuantityDescription`
 *
 * Expected input parameters are:
 *
 * `{ems.topology.Source.IecId} iecId` is the IEC ID of a source. To request for a logical device, only populate the `logicalDeviceName` key
 *
 * `{string} quantity` is one of (or comma separated list of) `ems.topology.DoubleQuantityDescription` or `ems.topology.ThreePhaseQuantityDescription` or `_ANY`, `_ANY_MTR_COUNTER`, `_ALL_PHASE_TOPO`, `_NO_METER`, _ANY_PHASE_TOPO`, `_ANY_SRC_DEVICE_TOPO`
 *
 * @function
 *
 * @return {function}
 */
export const hasQuantity = (state) => {
  return ({ iecId, quantity, quantityTypes = ['doubleMq', 'threePhaseMq', 'statusMq'] }) => {
    const isAll = /^_ALL.*/.test(quantity)
    let numQ = 0
    let success = isAll

    state.sourceQuantitiesList.forEach((sqs) => {
      if (!isAll && success) {
        return
      }
      if (isAll && !success) {
        return
      }
      if (!isEqualSrc({ src: sqs.source, iecId: iecId })) {
        return
      }

      // Filter blank quanitites,
      // which might be possible, because of EMSs with "larger" proto interface.
      const qList = sqs.quantitiesList.filter((q) => {
        return quantityTypes.some((t) => {
          if (!q[t]) {
            return false
          }

          // drop 'all empty' quantites
          return !Object.values(q[t]).every((e) => !e)
        })
      })

      numQ = numQ + qList.length

      const checkQ = (q, conditionList) => {
        return conditionList.some((e) => {
          return quantityTypes.some((t) => {
            return q[t] && !isUndefined(q[t][e])
          })
        })
      }
      const checkSome = (conditionList) => {
        return qList.some((q) => {
          return checkQ(q, conditionList)
        })
      }
      const checkEvery = (conditionList) => {
        return qList.every((q) => {
          return checkQ(q, conditionList)
        })
      }

      switch (quantity) {
        case '_ANY':
          success = !!qList.length
          break
        case '_ANY_MTR_COUNTER':
          success = checkSome(MTR_COUNTER_MEASURED_QUANTITIES)
          break
        case '_ALL_PHASE_TOPO':
          success = checkEvery([...PHASE_TOPO_MEASURED_QUANTITIES, 'healthState'])
          break
        case '_ANY_PHASE_TOPO':
          success = checkSome(PHASE_TOPO_MEASURED_QUANTITIES)
          break
        case '_ANY_SRC_DEVICE_TOPO':
          success = checkSome(SRC_DEVICE_MEASURED_QUANTITIES)
          break
        default:
          success = checkSome(quantity.split(','))
          break
      }
    })

    if (numQ === 0) {
      return false
    }

    return success
  }
}

/**
 * Simply wraps `hasQuantity` for `_ANY_MTR_COUNTER`.
 */
export const hasAnyMtrCounter = (state, getters) => {
  return (iecId) => {
    return getters.hasQuantity({ iecId, quantity: '_ANY_MTR_COUNTER' })
  }
}

/**
 * Getter to count the existing number of sources for a logical device.
 *
 * Expects the `{string} ldn` logical-device name as input.
 *
 */
export const getNumSources = (state, getters) => {
  return (ldn) => {
    return getters.getSourceQuantities(ldn).length
  }
}

/**
 * Allows to check, if a source is a pure meter.
 * Pure meter means, that ALL source-quantities of this logical-device
 * are 'Phase-Topo' quantities.
 *
 * Expects the `{string} ldn` logical-device name as input.
 *
 */
export const isPureMtr = (state, getters) => {
  return (ldn) => {
    return getters.hasQuantity({
      iecId: { logicalDeviceName: ldn },
      quantity: '_ALL_PHASE_TOPO'
    })
  }
}

/**
 * Allows to check, if logical-device has a simple or expert config.
 * Checks if
 * 1. NO phase-topology exists
 * 2. all sources of the logical-device are associated to the same physical device, if a source-to-device topo is supported
 * 3. passes to be simple, if config is empty for the logical-device
 *
 * Is used to initially decide, if the expert mode should be shown or not.
 */
export const hasSimpleConfig = (state, getters) => {
  return (ldn) => {
    const srcs = getters.getSources(ldn)

    if (!srcs.length) {
      return true
    }

    let physDev
    return srcs.every((src) => {
      if (getters.getPhysicalDevicesOfPhaseTopology(src.iec).length) {
        return false
      }

      // check if src could have a src-to-dev topo
      if (!getters.hasQuantity({ iecId: src.iec, quantity: '_ANY_SRC_DEVICE_TOPO' })) {
        return true
      }

      const pd = getters.getPhysicalDeviceOfSourceToDeviceTopology(src.iec)
      if (!physDev) {
        physDev = pd
        return true
      }

      return isEqual(pd, physDev)
    })
  }
}

/**
 * Allows to check, if a source (meter) is the grid meter.
 * Simply compares the `gridMtrIecId` state with the input
 *
 * Expects `{object} iecId` (`Ems::Controller.Source.IecId`) as input filter for the source.
 * Returns `{boolean}`
 *
 */
export const isGridMtr = (state, getters) => {
  return (iecId) => {
    return isEqual(iecId, state.gridMtrIecId)
  }
}

export const getSource = (state) => {
  return (iecId) => {
    const sq = state.sourceQuantitiesList.find((sq) => {
      return isEqual(sq.source.iec, iecId)
    })

    return sq ? sq.source : null
  }
}

/**
 * Getter to get a list of all sources associated with a particular LDN. If no LDN is provided, a list of all sources
 * is returned.
 *
 * @function
 *
 * @return {function}
 */
export const getSources = (state) => {
  return (ldn, { allNodes = false } = {}) => {
    const sources = state.sourceQuantitiesList
      .filter((sq) => {
        if (!ldn) {
          return true
        }
        return sq.source.iec.logicalDeviceName === ldn && (allNodes || sq.source.iec.iecNodePrefix)
      })
      .map((sq) => {
        return sq.source
      })
    return uniqWith(sources, isEqual)
  }
}

export const getPhysicalDevicesOfPhaseTopology = (state) => {
  return (iecId) => {
    const pds = []
    state.topology.phaseTopologiesList.forEach((pt) => {
      if (!pt.source || !isEqual(pt.source.iec, iecId)) {
        return
      }

      pt.physicalDeviceOnPhaseList.forEach((pdOnPhase) => {
        pds.push(pdOnPhase.physicalDevice)
      })
    })

    return pds
  }
}

/**
 * Getter to get the `PhaseTopology.swap` value for a requested source.
 * Will return `{boolean}`.
 *
 */
export const getMtrSwapOfPhaseTopology = (state) => {
  return (iecId) => {
    if (isEqual(state.gridMtrIecId, iecId)) {
      return state.swapGridMtr
    }

    const pts = state.topology.phaseTopologiesList.filter((pt) => {
      if (!pt.source || !isEqual(pt.source.iec, iecId)) {
        return false
      }

      return true
    })

    if (pts.length !== 1) {
      if (pts.length) {
        logger.error(
          'Found more than one phase topology for requested source. I.e. the topology.phase_topologies list is inconsistent, because a source should be present at most once.'
        )
      } else {
        logger.debug('Failed to find a phase topology for requested source.')
      }
      return false
    }

    return pts[0].swap
  }
}

export const getPhysicalDeviceOfSourceToDeviceTopology = (state) => {
  return (iecId) => {
    let pd
    state.topology.sourceToDeviceTopologiesList.forEach((st) => {
      if (!st.source) {
        return
      }
      if (!isEqual(st.source.iec, iecId)) {
        return
      }

      pd = st.physicalDevice
    })

    return pd
  }
}

export const allPhysicalDevicesOfTopology = (state) => {
  const pds = []
  state.topology.phaseTopologiesList.forEach((pt) => {
    if (!pt.source) {
      return
    }
    pt.physicalDeviceOnPhaseList.forEach((pdOnPhase) => {
      if (!pdOnPhase.physicalDevice) {
        return
      }
      pds.push(pdOnPhase.physicalDevice)
    })
  })
  state.topology.sourceToDeviceTopologiesList.forEach((st) => {
    if (!st.source || !st.physicalDevice) {
      return
    }
    pds.push(st.physicalDevice)
  })

  return pds.filter((pd, i, list) => {
    // reference equality is not enough
    const j = list.findIndex((e) => isEqual(e, pd))
    return j === i
  })
}

export const isPhysicalDeviceReferenced = (state, getters) => {
  return (physDev) => {
    if (!physDev || isNil(physDev.type) || isNil(physDev.number)) {
      return false
    }

    return getters.allPhysicalDevicesOfTopology.some((pd) => {
      return isEqual(physDev, pd)
    })
  }
}

// private
/**
 * Allows to check if the provided source matches the provided IEC-ID
 *
 * @function
 *
 * @param {object} args
 * @param {Source} args.src has to be a "plain" protobuf ems.topology.Source
 * @param {object} iecId has to be the ID. To request for any src of a logical device, only populate the `logicalDeviceName` key
 *
 * @return {boolen}
 */
function isEqualSrc({ src, iecId }) {
  const ldn = iecId.iecNodePrefix ? null : iecId.logicalDeviceName
  if (!src || !src.iec) {
    return false
  }
  if (!isEqual(src.iec, iecId) && src.iec.logicalDeviceName !== ldn) {
    return false
  }

  return true
}
