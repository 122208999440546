<template>
  <!-- parent component has to wrap this component by a CCard -->
  <div>
    <ApxCardHeader icon="cil-settings" :title="$t('ems.energyService.activation.title')" />
    <CCardBody class="row">
      <CCol :sm="{ size: 6, offset: hasDecisionTree ? 3 : 0 }">
        <CSelect
          :value="defaultStrategyId"
          :options="defaultStrategyOpts"
          :label="$t('ems.energyService.config.defaultStrategy.label')"
          :description="$t('ems.energyService.config.defaultStrategy.description')"
          :invalid-feedback="$t('ems.energyService.config.defaultStrategy.invalidFeedback')"
          :is-valid="$v.defaultStrategyId.$dirty && $v.defaultStrategyId.$error ? false : null"
          :placeholder="$t('ems.energyService.config.defaultStrategy.placeholder')"
          @update:value="onSelectDefaultStrategy"
        />
      </CCol>
      <CCol :sm="{ size: hasDecisionTree ? 3 : 6 }">
        <BtnWithDescription
          v-if="!hasDecisionTree"
          main-btn-color="primary"
          main-btn-variant="outline"
          :label="$t('ems.energyService.config.decisionTree.initDecisionTree')"
          :description="$t('ems.energyService.config.decisionTree.initDecisionTreeDescription')"
          :not-toggleable="true"
          @click="onInitDecisionTree"
        />
      </CCol>
    </CCardBody>
    <CCardBody v-if="hasDecisionTree">
      <p class="text-center font-weight-bold">
        {{ $t('ems.energyService.config.decisionTree.title') }}
      </p>
      <EsDecisionTree />
    </CCardBody>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { startCase } from 'lodash'
import { eventBus } from '@/view-helper/event-bus'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import BtnWithDescription from '@/components/snippets/btn-with-description'
import EsDecisionTree from '@/components/ems/energy-services/energy-service-decision-tree/es-decision-tree'
import { defaultStrategyFormValidators } from '@/validations/ems-es-strategy-validators'

export default {
  name: 'EnergyServicesConfigActivation',
  components: {
    ApxCardHeader,
    BtnWithDescription,
    EsDecisionTree
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['hasDecisionTree', 'strategyIds']),
    ...mapState('emsEnergyServicesConfig', ['defaultStrategyId']),
    defaultStrategyOpts() {
      const opts = [
        {
          value: null,
          label: this.$t('ems.energyService.config.defaultStrategy.noDefaultStrategy')
        }
      ]
      this.strategyIds.forEach((id) => {
        opts.push({
          value: id,
          label: this.humanizeId(id)
        })
      })
      return opts
    }
  },
  validations: defaultStrategyFormValidators,
  methods: {
    humanizeId(id) {
      return startCase(id)
    },
    onSelectDefaultStrategy(strategyId) {
      eventBus.$emit('update:default-strategy', strategyId)
      this.$v.defaultStrategyId.$touch()
      this.$store.commit('emsEnergyServicesConfig/SET_DEFAULT_STRATEGY_ID', strategyId)
      this.$log.debug(`Successfully updated default strategy to "${strategyId}"`)
    },
    onInitDecisionTree() {
      this.$store.commit('emsEnergyServicesConfig/SET_DECISION_TREE')
      this.$log.debug('Commited SET_DECISION_TREE to init tree')
    }
  }
}
</script>
