<template>
  <CCard :key="device" class="col-12 col-xl-10 pb-4">
    <ApxCardHeader icon="cil-remote-control" :title="$t(`config.device.${device}.operateTitle`)" />
    <CCardBody>
      <ApxDeviceConfigNav :device="device" />
    </CCardBody>
    <CCardBody>
      <ApxDeviceStatus v-bind="getDeviceStatusByConfigId(configId, true)" />
      <!-- prettier-ignore -->
      <ApxDeviceControlValue
        v-for="(value, i) of filterDeviceProcessValues('', { onlyControllable: true })"
        :key="value.reference || i"
        :config-id="configId"
        v-bind="value"
      />
      <p v-if="filterDeviceProcessValues('', { onlyControllable: true }).length === 0">
        {{ $t('config.device.status.processValuesFilter.deviceNotControllable') }}.
      </p>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import { lowerCase } from 'lodash'
import ApxCardHeader from '@/components/snippets/apx-card-header'
import ApxDeviceConfigNav from '@/components/navs/apx-device-config-nav'
import ApxDeviceControlValue from '@/components/apx-device-control-value'
import ApxDeviceStatus from '@/components/apx-device-status'

export default {
  name: 'DeviceStatus',
  components: {
    ApxCardHeader,
    ApxDeviceConfigNav,
    ApxDeviceControlValue,
    ApxDeviceStatus
  },
  data() {
    return {
      device: ''
    }
  },
  computed: {
    ...mapGetters('deviceConfig', ['getDeviceStatusByConfigId']),
    ...mapGetters('deviceValues', ['filterDeviceProcessValues'])
  },
  created() {
    this.device = lowerCase(this.$route.params.device)
    this.configId = this.$route.params.id
  },
  mounted() {
    this.$store.commit('deviceConfig/CLEAR_STATUSES')
    this.$store
      .dispatch('deviceConfig/getDeviceStatus', this.configId)
      .then((msg) => {
        this.$log.debug('Successful getDeviceStatus.')
      })
      .catch(this.onServerError)
    this.$store.dispatch('deviceConfig/streamDeviceStatus', {
      configId: this.configId
    })

    this.$store.commit('deviceValues/CLEAR_PROCESS_VALUES')
    this.$store
      .dispatch('deviceValues/fetchEnergyManagerDeviceValues', this.configId)
      .then((msg) => {
        this.$log.debug('Successful fetchEnergyManagerDeviceValues')
      })
      .catch(this.onServerError)
    this.$store.dispatch('deviceValues/streamEnergyManagerDeviceValues', { configId: this.configId })
  },
  beforeDestroy() {
    this.$store.dispatch('deviceConfig/streamDeviceStatus', { configId: this.configId, stop: true })
    this.$store.dispatch('deviceValues/streamEnergyManagerDeviceValues', { configId: this.configId, stop: true })
  },
  methods: {
    onServerError(err) {
      this.$log.warn(err)
      // TODO: translation error content
      this.$store.commit(
        'notifications/PUSH_TOAST',
        {
          title: 'Server Error',
          type: 'toast-warning',
          content: `${err.msg}. Error-Code: ${err.code}`
        },
        { root: true }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  border: 0 !important;
  border-color: white;
  box-shadow: 0px 0px #888888;
  //background-color: #2ba6ca;
}

.no-border {
  border: 0 !important;
  box-shadow: 0px 0px !important;
}

hr.separation {
  border-top: 0.5px solid #dddddd;
}

hr.separation-strong {
  border-top: 1.5px solid #dddddd;
}
</style>
