<template>
  <CSidebar
    color-scheme="light"
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('coreui/SET', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- prettier-ignore -->
      <CIcon
        v-if="!isDark"
        class="c-sidebar-brand-full"
        name="temLogoLight"
        size="custom"
        :height="45"
      />
      <!-- prettier-ignore -->
      <CIcon
        v-else
        class="c-sidebar-brand-full"
        name="temLogoDark"
        size="custom"
        :height="45"
      />
    </CSidebarBrand>
    <CRenderFunction :content-to-render="enhancedNav" flat />
    <CSidebarMinimizer class="c-d-md-down-none" @click.native="$store.commit('coreui/TOGGLE', 'sidebarMinimize')" />
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import nav from '../_nav'

export default {
  name: 'ApxSidebar',
  computed: {
    ...mapGetters('coreui', {
      show: 'sidebarShow',
      minimize: 'sidebarMinimize',
      isDark: 'darkMode'
    }),
    ...mapGetters('auth', ['disabledFeatures', 'omiOnlyEnabled']),
    enhancedNav() {
      return nav({ featureBlacklist: this.disabledFeatures, omiOnlyEnabled: this.omiOnlyEnabled })
    }
  }
}
</script>
