// source: amperix/device_noopt.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var ext_amperix_error_pb = require('../ext/amperix/error_pb.js');
goog.object.extend(proto, ext_amperix_error_pb);
var ext_amperix_selection_pb = require('../ext/amperix/selection_pb.js');
goog.object.extend(proto, ext_amperix_selection_pb);
var ext_amperix_options_options_pb = require('../ext/amperix/options/options_pb.js');
goog.object.extend(proto, ext_amperix_options_options_pb);
goog.exportSymbol('proto.de.mypowergrid.amperix.AddDeviceConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.AddDeviceConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Container', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Container.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.DeviceCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Eza', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Eza.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.ID', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.BmsCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.BmsCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.NaboxCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Inverter.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Meter.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Relay', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Relay.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceDriver', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceDriver.ConfigType', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.DeviceType', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceConfigsRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceConfigsResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceDriversRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetDeviceDriversResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceDriversRequest.displayName = 'proto.de.mypowergrid.amperix.GetDeviceDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetDeviceDriversResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceDriversResponse.displayName = 'proto.de.mypowergrid.amperix.GetDeviceDriversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.displayName = 'proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.displayName = 'proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.displayName = 'proto.de.mypowergrid.amperix.GetDeviceConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.displayName = 'proto.de.mypowergrid.amperix.GetDeviceConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.displayName = 'proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.displayName = 'proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceConfigRequest.displayName = 'proto.de.mypowergrid.amperix.GetDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetDeviceConfigResponse.displayName = 'proto.de.mypowergrid.amperix.GetDeviceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.AddDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.AddDeviceConfigRequest.displayName = 'proto.de.mypowergrid.amperix.AddDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.AddDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.AddDeviceConfigResponse.displayName = 'proto.de.mypowergrid.amperix.AddDeviceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.displayName = 'proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.displayName = 'proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.displayName = 'proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.displayName = 'proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.DeviceDriver.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceDriver.displayName = 'proto.de.mypowergrid.amperix.DeviceDriver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceDriver.ConfigType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.displayName = 'proto.de.mypowergrid.amperix.DeviceDriver.ConfigType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.ID.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Relay.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Relay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Relay.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Relay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Container.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Container, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Container.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Container';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.DeviceConfig.Eza.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Eza, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Eza.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Eza';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.displayName = 'proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceDriversRequest;
  return proto.de.mypowergrid.amperix.GetDeviceDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional DeviceType device_type = 1;
 * @return {!proto.de.mypowergrid.amperix.DeviceType}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.prototype.getDeviceType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceType} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceDriversRequest.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    driversList: jspb.Message.toObjectList(msg.getDriversList(),
    proto.de.mypowergrid.amperix.DeviceDriver.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceDriversResponse;
  return proto.de.mypowergrid.amperix.GetDeviceDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceDriver;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceDriver.deserializeBinaryFromReader);
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceDriver.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeviceDriver drivers = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.DeviceDriver>}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.getDriversList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.DeviceDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.DeviceDriver, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.DeviceDriver>} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.setDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver}
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.addDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.DeviceDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceDriversResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceDriversResponse.prototype.clearDriversList = function() {
  return this.setDriversList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldNumbersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest;
  return proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setFieldNumbersList(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldNumbersList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated uint32 field_numbers = 1;
 * @return {!Array<number>}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.getFieldNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.setFieldNumbersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.addFieldNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.clearFieldNumbersList = function() {
  return this.setFieldNumbersList([]);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type_url = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.getTypeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigRequest.prototype.setTypeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    pb_default: (f = msg.getDefault()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse;
  return proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getDefault();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Any default = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.getDefault = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.setDefault = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.clearDefault = function() {
  return this.setDefault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDefaultDeviceConfigResponse.prototype.hasDefault = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceConfigsRequest;
  return proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional DeviceType device_type = 1;
 * @return {!proto.de.mypowergrid.amperix.DeviceType}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.prototype.getDeviceType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceType} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsRequest.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.de.mypowergrid.amperix.DeviceConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceConfigsResponse;
  return proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeviceConfig configs = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.DeviceConfig>}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.DeviceConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.DeviceConfig>} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.DeviceConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigsResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest;
  return proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    configIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse;
  return proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addConfigIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getConfigIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} returns this
*/
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string config_ids = 2;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.getConfigIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.setConfigIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.addConfigIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUnregisteredDeviceConfigsResponse.prototype.clearConfigIdsList = function() {
  return this.setConfigIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceConfigRequest;
  return proto.de.mypowergrid.amperix.GetDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    config: (f = msg.getConfig()) && proto.de.mypowergrid.amperix.DeviceConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetDeviceConfigResponse;
  return proto.de.mypowergrid.amperix.GetDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceConfig config = 2;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.getConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetDeviceConfigResponse.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.AddDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.de.mypowergrid.amperix.DeviceConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.AddDeviceConfigRequest;
  return proto.de.mypowergrid.amperix.AddDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.AddDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceConfig config = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest} returns this
*/
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.AddDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    configId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.AddDeviceConfigResponse;
  return proto.de.mypowergrid.amperix.AddDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.AddDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string config_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.AddDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.AddDeviceConfigResponse.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.de.mypowergrid.amperix.DeviceConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest;
  return proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceConfig config = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest} returns this
*/
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    configId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse;
  return proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string config_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.UpdateDeviceConfigResponse.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest;
  return proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse;
  return proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeleteDeviceConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceDriver.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    configTypesList: jspb.Message.toObjectList(msg.getConfigTypesList(),
    proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver}
 */
proto.de.mypowergrid.amperix.DeviceDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceDriver;
  return proto.de.mypowergrid.amperix.DeviceDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver}
 */
proto.de.mypowergrid.amperix.DeviceDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.DeviceDriver.ConfigType;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.deserializeBinaryFromReader);
      msg.addConfigTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getConfigTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.toObject = function(includeInstance, msg) {
  var f, obj = {
    alias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    ext_amperix_options_options_pb.Amperix.DeviceModel.toObject, includeInstance),
    config: (f = msg.getConfig()) && proto.de.mypowergrid.amperix.DeviceConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceDriver.ConfigType;
  return proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 2:
      var value = new ext_amperix_options_options_pb.Amperix.DeviceModel;
      reader.readMessage(value,ext_amperix_options_options_pb.Amperix.DeviceModel.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      ext_amperix_options_options_pb.Amperix.DeviceModel.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated options.Amperix.DeviceModel models = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, ext_amperix_options_options_pb.Amperix.DeviceModel, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel>} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} returns this
*/
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.options.Amperix.DeviceModel}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.options.Amperix.DeviceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * optional DeviceConfig config = 3;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.getConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} returns this
*/
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceDriver.ConfigType.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceType device_type = 3;
 * @return {!proto.de.mypowergrid.amperix.DeviceType}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.getDeviceType = function() {
  return /** @type {!proto.de.mypowergrid.amperix.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceType} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated ConfigType config_types = 4;
 * @return {!Array<!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType>}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.getConfigTypesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.DeviceDriver.ConfigType, 4));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType>} value
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver} returns this
*/
proto.de.mypowergrid.amperix.DeviceDriver.prototype.setConfigTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver.ConfigType}
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.addConfigTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.de.mypowergrid.amperix.DeviceDriver.ConfigType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.DeviceDriver} returns this
 */
proto.de.mypowergrid.amperix.DeviceDriver.prototype.clearConfigTypesList = function() {
  return this.setConfigTypesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_ = [[1,2,4,5,8,9]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.DeviceCase = {
  DEVICE_NOT_SET: 0,
  METER: 1,
  INVERTER: 2,
  WALLBOX: 4,
  RELAY: 5,
  CONTAINER: 8,
  EZA: 9
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.DeviceCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getDeviceCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.DeviceCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.de.mypowergrid.amperix.DeviceConfig.ID.toObject(includeInstance, f),
    meter: (f = msg.getMeter()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.toObject(includeInstance, f),
    inverter: (f = msg.getInverter()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.toObject(includeInstance, f),
    wallbox: (f = msg.getWallbox()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.toObject(includeInstance, f),
    relay: (f = msg.getRelay()) && proto.de.mypowergrid.amperix.DeviceConfig.Relay.toObject(includeInstance, f),
    container: (f = msg.getContainer()) && proto.de.mypowergrid.amperix.DeviceConfig.Container.toObject(includeInstance, f),
    eza: (f = msg.getEza()) && proto.de.mypowergrid.amperix.DeviceConfig.Eza.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig;
  return proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig}
 */
proto.de.mypowergrid.amperix.DeviceConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.ID;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.deserializeBinaryFromReader);
      msg.setMeter(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.deserializeBinaryFromReader);
      msg.setInverter(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.deserializeBinaryFromReader);
      msg.setWallbox(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Relay;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Relay.deserializeBinaryFromReader);
      msg.setRelay(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Container;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Container.deserializeBinaryFromReader);
      msg.setContainer(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Eza;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Eza.deserializeBinaryFromReader);
      msg.setEza(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.ID.serializeBinaryToWriter
    );
  }
  f = message.getMeter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.serializeBinaryToWriter
    );
  }
  f = message.getInverter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.serializeBinaryToWriter
    );
  }
  f = message.getWallbox();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.serializeBinaryToWriter
    );
  }
  f = message.getRelay();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Relay.serializeBinaryToWriter
    );
  }
  f = message.getContainer();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Container.serializeBinaryToWriter
    );
  }
  f = message.getEza();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Eza.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    configTypeId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.ID}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.ID;
  return proto.de.mypowergrid.amperix.DeviceConfig.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.ID}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfigTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.ID} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.ID} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string config_type_id = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.getConfigTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.ID} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.ID.prototype.setConfigTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG1: 1,
  CONFIG2: 2,
  CONFIG3: 3,
  CONFIG4: 4,
  CONFIG5: 5,
  CONFIG6: 6
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Meter.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Meter.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.toObject = function(includeInstance, msg) {
  var f, obj = {
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.toObject(includeInstance, f),
    config2: (f = msg.getConfig2()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.toObject(includeInstance, f),
    config3: (f = msg.getConfig3()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.toObject(includeInstance, f),
    config4: (f = msg.getConfig4()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.toObject(includeInstance, f),
    config5: (f = msg.getConfig5()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.toObject(includeInstance, f),
    config6: (f = msg.getConfig6()) && proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.deserializeBinaryFromReader);
      msg.setConfig2(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.deserializeBinaryFromReader);
      msg.setConfig3(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.deserializeBinaryFromReader);
      msg.setConfig4(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.deserializeBinaryFromReader);
      msg.setConfig5(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.deserializeBinaryFromReader);
      msg.setConfig6(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.serializeBinaryToWriter
    );
  }
  f = message.getConfig2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.serializeBinaryToWriter
    );
  }
  f = message.getConfig3();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.serializeBinaryToWriter
    );
  }
  f = message.getConfig4();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.serializeBinaryToWriter
    );
  }
  f = message.getConfig5();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.serializeBinaryToWriter
    );
  }
  f = message.getConfig6();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numChannels: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNumChannels();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 num_channels = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.getNumChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2.prototype.setNumChannels = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.toObject = function(includeInstance, msg) {
  var f, obj = {
    multicastAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    multicastInterface: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serial: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMulticastAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMulticastInterface(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMulticastAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMulticastInterface();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string multicast_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.getMulticastAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.setMulticastAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string multicast_interface = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.getMulticastInterface = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.setMulticastInterface = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serial = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.getSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3.prototype.setSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    transformerRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    optionsForcedBaudRate: (f = msg.getOptionsForcedBaudRate()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    statusCurrentRatio: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statusVoltageRatio: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTransformerRatio(value);
      break;
    case 3:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setOptionsForcedBaudRate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusCurrentRatio(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusVoltageRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getTransformerRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOptionsForcedBaudRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getStatusCurrentRatio();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatusVoltageRatio();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Selection device = 1;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.getDevice = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float transformer_ratio = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.getTransformerRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.setTransformerRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Selection options_forced_baud_rate = 3;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.getOptionsForcedBaudRate = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.setOptionsForcedBaudRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.clearOptionsForcedBaudRate = function() {
  return this.setOptionsForcedBaudRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.hasOptionsForcedBaudRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string status_current_ratio = 4;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.getStatusCurrentRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.setStatusCurrentRatio = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status_voltage_ratio = 5;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.getStatusVoltageRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5.prototype.setStatusVoltageRatio = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.toObject = function(includeInstance, msg) {
  var f, obj = {
    listenAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6;
  return proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListenAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string listen_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.getListenAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.setListenAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 unit_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Config2 config2 = 2;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig2 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config2|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig2 = function() {
  return this.setConfig2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Config3 config3 = 3;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig3 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config3|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig3 = function() {
  return this.setConfig3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig3 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Config4 config4 = 4;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig4 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config4|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig4 = function() {
  return this.setConfig4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig4 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Config5 config5 = 5;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig5 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config5|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig5 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig5 = function() {
  return this.setConfig5(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig5 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Config6 config6 = 6;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.getConfig6 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter.Config6|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.setConfig6 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.amperix.DeviceConfig.Meter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Meter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.clearConfig6 = function() {
  return this.setConfig6(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Meter.prototype.hasConfig6 = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_ = [[2,9,3,4,19,18,21,22,23,1,7,8,10,11,12,13,16,17,20]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG2: 2,
  CONFIG9: 9,
  CONFIG3: 3,
  CONFIG4: 4,
  CONFIG19: 19,
  CONFIG18: 18,
  CONFIG21: 21,
  CONFIG22: 22,
  CONFIG23: 23,
  CONFIG1: 1,
  CONFIG7: 7,
  CONFIG8: 8,
  CONFIG10: 10,
  CONFIG11: 11,
  CONFIG12: 12,
  CONFIG13: 13,
  CONFIG16: 16,
  CONFIG17: 17,
  CONFIG20: 20
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.toObject = function(includeInstance, msg) {
  var f, obj = {
    config2: (f = msg.getConfig2()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.toObject(includeInstance, f),
    config9: (f = msg.getConfig9()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.toObject(includeInstance, f),
    config3: (f = msg.getConfig3()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.toObject(includeInstance, f),
    config4: (f = msg.getConfig4()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.toObject(includeInstance, f),
    config19: (f = msg.getConfig19()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.toObject(includeInstance, f),
    config18: (f = msg.getConfig18()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.toObject(includeInstance, f),
    config21: (f = msg.getConfig21()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.toObject(includeInstance, f),
    config22: (f = msg.getConfig22()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.toObject(includeInstance, f),
    config23: (f = msg.getConfig23()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.toObject(includeInstance, f),
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.toObject(includeInstance, f),
    config7: (f = msg.getConfig7()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.toObject(includeInstance, f),
    config8: (f = msg.getConfig8()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.toObject(includeInstance, f),
    config10: (f = msg.getConfig10()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.toObject(includeInstance, f),
    config11: (f = msg.getConfig11()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.toObject(includeInstance, f),
    config12: (f = msg.getConfig12()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.toObject(includeInstance, f),
    config13: (f = msg.getConfig13()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.toObject(includeInstance, f),
    config16: (f = msg.getConfig16()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.toObject(includeInstance, f),
    config17: (f = msg.getConfig17()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.toObject(includeInstance, f),
    config20: (f = msg.getConfig20()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.deserializeBinaryFromReader);
      msg.setConfig2(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.deserializeBinaryFromReader);
      msg.setConfig9(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.deserializeBinaryFromReader);
      msg.setConfig3(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.deserializeBinaryFromReader);
      msg.setConfig4(value);
      break;
    case 19:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.deserializeBinaryFromReader);
      msg.setConfig19(value);
      break;
    case 18:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.deserializeBinaryFromReader);
      msg.setConfig18(value);
      break;
    case 21:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.deserializeBinaryFromReader);
      msg.setConfig21(value);
      break;
    case 22:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.deserializeBinaryFromReader);
      msg.setConfig22(value);
      break;
    case 23:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.deserializeBinaryFromReader);
      msg.setConfig23(value);
      break;
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.deserializeBinaryFromReader);
      msg.setConfig7(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.deserializeBinaryFromReader);
      msg.setConfig8(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.deserializeBinaryFromReader);
      msg.setConfig10(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.deserializeBinaryFromReader);
      msg.setConfig11(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.deserializeBinaryFromReader);
      msg.setConfig12(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.deserializeBinaryFromReader);
      msg.setConfig13(value);
      break;
    case 16:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.deserializeBinaryFromReader);
      msg.setConfig16(value);
      break;
    case 17:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.deserializeBinaryFromReader);
      msg.setConfig17(value);
      break;
    case 20:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.deserializeBinaryFromReader);
      msg.setConfig20(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.serializeBinaryToWriter
    );
  }
  f = message.getConfig9();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.serializeBinaryToWriter
    );
  }
  f = message.getConfig3();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.serializeBinaryToWriter
    );
  }
  f = message.getConfig4();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.serializeBinaryToWriter
    );
  }
  f = message.getConfig19();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.serializeBinaryToWriter
    );
  }
  f = message.getConfig18();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.serializeBinaryToWriter
    );
  }
  f = message.getConfig21();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.serializeBinaryToWriter
    );
  }
  f = message.getConfig22();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.serializeBinaryToWriter
    );
  }
  f = message.getConfig23();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.serializeBinaryToWriter
    );
  }
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.serializeBinaryToWriter
    );
  }
  f = message.getConfig7();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.serializeBinaryToWriter
    );
  }
  f = message.getConfig8();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.serializeBinaryToWriter
    );
  }
  f = message.getConfig10();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.serializeBinaryToWriter
    );
  }
  f = message.getConfig11();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.serializeBinaryToWriter
    );
  }
  f = message.getConfig12();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.serializeBinaryToWriter
    );
  }
  f = message.getConfig13();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.serializeBinaryToWriter
    );
  }
  f = message.getConfig16();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.serializeBinaryToWriter
    );
  }
  f = message.getConfig17();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.serializeBinaryToWriter
    );
  }
  f = message.getConfig20();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    baseFloatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    baseFloatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBaseFloatChargeLowSoc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBaseFloatChargeHighSoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBaseFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBaseFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float base_float_charge_low_soc = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.getBaseFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.setBaseFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float base_float_charge_high_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.getBaseFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.prototype.setBaseFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    readTcpPort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limitTcpPort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ratingWatt: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxFeedInWatt: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadTcpPort(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimitTcpPort(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRatingWatt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxFeedInWatt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReadTcpPort();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLimitTcpPort();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRatingWatt();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMaxFeedInWatt();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 read_tcp_port = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getReadTcpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setReadTcpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 limit_tcp_port = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getLimitTcpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setLimitTcpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float rating_watt = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getRatingWatt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setRatingWatt = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float max_feed_in_watt = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.getMaxFeedInWatt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2.prototype.setMaxFeedInWatt = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    modelVersion: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    noBatteryMeter: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    useMidLittleEndian: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasDcDcCharger: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModelVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoBatteryMeter(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseMidLittleEndian(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDcDcCharger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getModelVersion();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNoBatteryMeter();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUseMidLittleEndian();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasDcDcCharger();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float float_charge_low_soc = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float float_charge_high_soc = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double float_charge_w = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint32 model_version = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getModelVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setModelVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string dpl_name = 7;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool no_battery_meter = 8;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getNoBatteryMeter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setNoBatteryMeter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool use_mid_little_endian = 9;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getUseMidLittleEndian = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setUseMidLittleEndian = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool has_dc_dc_charger = 10;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.getHasDcDcCharger = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9.prototype.setHasDcDcCharger = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readIntervalsInvPower: jspb.Message.getFieldWithDefault(msg, 2, 0),
    readIntervalsInvPhaseVoltage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    readIntervalsInvGridFreq: jspb.Message.getFieldWithDefault(msg, 4, 0),
    readIntervalsBatVoltage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    readIntervalsBatPower: jspb.Message.getFieldWithDefault(msg, 6, 0),
    readIntervalsBatCurrent: jspb.Message.getFieldWithDefault(msg, 7, 0),
    readIntervalsBatSoc: jspb.Message.getFieldWithDefault(msg, 8, 0),
    readIntervalsPv0Voltage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    readIntervalsPv0Power: jspb.Message.getFieldWithDefault(msg, 10, 0),
    readIntervalsPv1Voltage: jspb.Message.getFieldWithDefault(msg, 11, 0),
    readIntervalsPv1Power: jspb.Message.getFieldWithDefault(msg, 12, 0),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    maxExternalSocTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsInvPower(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsInvPhaseVoltage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsInvGridFreq(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsBatVoltage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsBatPower(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsBatCurrent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsBatSoc(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsPv0Voltage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsPv0Power(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsPv1Voltage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadIntervalsPv1Power(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeW(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxExternalSocTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadIntervalsInvPower();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReadIntervalsInvPhaseVoltage();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReadIntervalsInvGridFreq();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getReadIntervalsBatVoltage();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getReadIntervalsBatPower();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getReadIntervalsBatCurrent();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getReadIntervalsBatSoc();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getReadIntervalsPv0Voltage();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getReadIntervalsPv0Power();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getReadIntervalsPv1Voltage();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getReadIntervalsPv1Power();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getMaxExternalSocTarget();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 read_intervals_inv_power = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsInvPower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsInvPower = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 read_intervals_inv_phase_voltage = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsInvPhaseVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsInvPhaseVoltage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 read_intervals_inv_grid_freq = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsInvGridFreq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsInvGridFreq = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 read_intervals_bat_voltage = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsBatVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsBatVoltage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 read_intervals_bat_power = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsBatPower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsBatPower = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 read_intervals_bat_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsBatCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsBatCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 read_intervals_bat_soc = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsBatSoc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsBatSoc = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 read_intervals_pv0_voltage = 9;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsPv0Voltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsPv0Voltage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 read_intervals_pv0_power = 10;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsPv0Power = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsPv0Power = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 read_intervals_pv1_voltage = 11;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsPv1Voltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsPv1Voltage = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 read_intervals_pv1_power = 12;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getReadIntervalsPv1Power = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setReadIntervalsPv1Power = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional float float_charge_low_soc = 13;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float float_charge_high_soc = 14;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float float_charge_w = 15;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float max_external_soc_target = 16;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.getMaxExternalSocTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3.prototype.setMaxExternalSocTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_ = [[12],[13,22,23]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.BmsCase = {
  BMS_NOT_SET: 0,
  BMS_TESVOLT_BMS: 12
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.BmsCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getBmsCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.BmsCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.NaboxCase = {
  NABOX_NOT_SET: 0,
  NA_BOX_TESVOLT_NABOX: 13,
  NA_BOX_OMI_NABOX: 22,
  NA_BOX_OMI_NO_GPIO_NABOX: 23
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.NaboxCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getNaboxCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.NaboxCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.toObject = function(includeInstance, msg) {
  var f, obj = {
    inverterModbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inverterModbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inverterFloatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    inverterPowerLimiterEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    inverterPowerLimiterDefaultLimOne: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    inverterPowerLimiterDefaultLimTwo: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    inverterChargePowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    inverterDischargePowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    bmsTesvoltBms: (f = msg.getBmsTesvoltBms()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.toObject(includeInstance, f),
    naBoxTesvoltNabox: (f = msg.getNaBoxTesvoltNabox()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.toObject(includeInstance, f),
    naBoxOmiNabox: (f = msg.getNaBoxOmiNabox()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.toObject(includeInstance, f),
    naBoxOmiNoGpioNabox: (f = msg.getNaBoxOmiNoGpioNabox()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.toObject(includeInstance, f),
    inverterInstalledIpus: jspb.Message.getFieldWithDefault(msg, 17, 0),
    inverterFundamentalFrequencyMode: (f = msg.getInverterFundamentalFrequencyMode()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inverterSymmetryMode: (f = msg.getInverterSymmetryMode()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inverterHarmonicMode: (f = msg.getInverterHarmonicMode()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inverterDisableEcoMode: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    modbusServerSlaveId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    modbusServerAddressOffset: jspb.Message.getFieldWithDefault(msg, 25, 0),
    modbusServerEndianness: (f = msg.getModbusServerEndianness()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inverterDroopsUQDroopMainLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    inverterDroopsUQDroopMainUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    inverterDroopsUQDroopT1Main: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    inverterDroopsFPDroopMainLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    inverterDroopsFPDroopMainUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    inverterDroopsFPDroopT1Main: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    inverterDroopsQUDroopMainLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    inverterDroopsQUDroopMainUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    inverterDroopsQUDeadBandLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    inverterDroopsQUDeadBandUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    inverterDroopsQLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    inverterDroopsPFDroopMainLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    inverterDroopsPFDroopMainUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 39, 0.0),
    inverterDroopsPFDeadBandLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 40, 0.0),
    inverterDroopsPFDeadBandUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 41, 0.0),
    inverterDroopsPUDroopLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 42, 0.0),
    inverterDroopsPUDroopUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 43, 0.0),
    inverterDroopsPUDeadBandLower: jspb.Message.getFloatingPointFieldWithDefault(msg, 44, 0.0),
    inverterDroopsPUDeadBandUpper: jspb.Message.getFloatingPointFieldWithDefault(msg, 45, 0.0),
    inverterDroopsPUMaxCharge: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    inverterDroopsPUMaxDischarge: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    inverterEcoModeThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInverterModbusTcpAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInverterModbusTcpSlaveId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterFloatChargeW(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInverterPowerLimiterEnabled(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterPowerLimiterDefaultLimOne(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterPowerLimiterDefaultLimTwo(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterChargePowerLimit(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDischargePowerLimit(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.deserializeBinaryFromReader);
      msg.setBmsTesvoltBms(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.deserializeBinaryFromReader);
      msg.setNaBoxTesvoltNabox(value);
      break;
    case 22:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.deserializeBinaryFromReader);
      msg.setNaBoxOmiNabox(value);
      break;
    case 23:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.deserializeBinaryFromReader);
      msg.setNaBoxOmiNoGpioNabox(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInverterInstalledIpus(value);
      break;
    case 18:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInverterFundamentalFrequencyMode(value);
      break;
    case 19:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInverterSymmetryMode(value);
      break;
    case 20:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInverterHarmonicMode(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInverterDisableEcoMode(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusServerSlaveId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModbusServerAddressOffset(value);
      break;
    case 26:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setModbusServerEndianness(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsUQDroopMainLower(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsUQDroopMainUpper(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsUQDroopT1Main(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsFPDroopMainLower(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsFPDroopMainUpper(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsFPDroopT1Main(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsQUDroopMainLower(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsQUDroopMainUpper(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsQUDeadBandLower(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsQUDeadBandUpper(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsQLimit(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPFDroopMainLower(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPFDroopMainUpper(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPFDeadBandLower(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPFDeadBandUpper(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUDroopLower(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUDroopUpper(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUDeadBandLower(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUDeadBandUpper(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUMaxCharge(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDroopsPUMaxDischarge(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterEcoModeThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInverterModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInverterModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getInverterFloatChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getInverterPowerLimiterEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getInverterPowerLimiterDefaultLimOne();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getInverterPowerLimiterDefaultLimTwo();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getInverterChargePowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getInverterDischargePowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getBmsTesvoltBms();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.serializeBinaryToWriter
    );
  }
  f = message.getNaBoxTesvoltNabox();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.serializeBinaryToWriter
    );
  }
  f = message.getNaBoxOmiNabox();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.serializeBinaryToWriter
    );
  }
  f = message.getNaBoxOmiNoGpioNabox();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.serializeBinaryToWriter
    );
  }
  f = message.getInverterInstalledIpus();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getInverterFundamentalFrequencyMode();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInverterSymmetryMode();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInverterHarmonicMode();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInverterDisableEcoMode();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getModbusServerSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getModbusServerAddressOffset();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getModbusServerEndianness();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInverterDroopsUQDroopMainLower();
  if (f !== 0.0) {
    writer.writeFloat(
      27,
      f
    );
  }
  f = message.getInverterDroopsUQDroopMainUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      28,
      f
    );
  }
  f = message.getInverterDroopsUQDroopT1Main();
  if (f !== 0.0) {
    writer.writeFloat(
      29,
      f
    );
  }
  f = message.getInverterDroopsFPDroopMainLower();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getInverterDroopsFPDroopMainUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getInverterDroopsFPDroopT1Main();
  if (f !== 0.0) {
    writer.writeFloat(
      32,
      f
    );
  }
  f = message.getInverterDroopsQUDroopMainLower();
  if (f !== 0.0) {
    writer.writeFloat(
      33,
      f
    );
  }
  f = message.getInverterDroopsQUDroopMainUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      34,
      f
    );
  }
  f = message.getInverterDroopsQUDeadBandLower();
  if (f !== 0.0) {
    writer.writeFloat(
      35,
      f
    );
  }
  f = message.getInverterDroopsQUDeadBandUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      36,
      f
    );
  }
  f = message.getInverterDroopsQLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      37,
      f
    );
  }
  f = message.getInverterDroopsPFDroopMainLower();
  if (f !== 0.0) {
    writer.writeFloat(
      38,
      f
    );
  }
  f = message.getInverterDroopsPFDroopMainUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      39,
      f
    );
  }
  f = message.getInverterDroopsPFDeadBandLower();
  if (f !== 0.0) {
    writer.writeFloat(
      40,
      f
    );
  }
  f = message.getInverterDroopsPFDeadBandUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      41,
      f
    );
  }
  f = message.getInverterDroopsPUDroopLower();
  if (f !== 0.0) {
    writer.writeFloat(
      42,
      f
    );
  }
  f = message.getInverterDroopsPUDroopUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      43,
      f
    );
  }
  f = message.getInverterDroopsPUDeadBandLower();
  if (f !== 0.0) {
    writer.writeFloat(
      44,
      f
    );
  }
  f = message.getInverterDroopsPUDeadBandUpper();
  if (f !== 0.0) {
    writer.writeFloat(
      45,
      f
    );
  }
  f = message.getInverterDroopsPUMaxCharge();
  if (f !== 0.0) {
    writer.writeFloat(
      46,
      f
    );
  }
  f = message.getInverterDroopsPUMaxDischarge();
  if (f !== 0.0) {
    writer.writeFloat(
      47,
      f
    );
  }
  f = message.getInverterEcoModeThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      48,
      f
    );
  }
};


/**
 * optional string inverter_modbus_tcp_address = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 inverter_modbus_tcp_slave_id = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float inverter_float_charge_w = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool inverter_power_limiter_enabled = 9;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterPowerLimiterEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterPowerLimiterEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional float inverter_power_limiter_default_lim_one = 10;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterPowerLimiterDefaultLimOne = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterPowerLimiterDefaultLimOne = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float inverter_power_limiter_default_lim_two = 11;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterPowerLimiterDefaultLimTwo = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterPowerLimiterDefaultLimTwo = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float inverter_charge_power_limit = 14;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterChargePowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterChargePowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float inverter_discharge_power_limit = 15;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDischargePowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDischargePowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional Config5 bms_tesvolt_bms = 12;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getBmsTesvoltBms = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5, 12));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setBmsTesvoltBms = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearBmsTesvoltBms = function() {
  return this.setBmsTesvoltBms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasBmsTesvoltBms = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Config6 na_box_tesvolt_nabox = 13;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getNaBoxTesvoltNabox = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6, 13));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setNaBoxTesvoltNabox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearNaBoxTesvoltNabox = function() {
  return this.setNaBoxTesvoltNabox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasNaBoxTesvoltNabox = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Config14 na_box_omi_nabox = 22;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getNaBoxOmiNabox = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14, 22));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setNaBoxOmiNabox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearNaBoxOmiNabox = function() {
  return this.setNaBoxOmiNabox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasNaBoxOmiNabox = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional Config15 na_box_omi_no_gpio_nabox = 23;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getNaBoxOmiNoGpioNabox = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15, 23));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setNaBoxOmiNoGpioNabox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearNaBoxOmiNoGpioNabox = function() {
  return this.setNaBoxOmiNoGpioNabox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasNaBoxOmiNoGpioNabox = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional uint32 inverter_installed_ipus = 17;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterInstalledIpus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterInstalledIpus = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional Selection inverter_fundamental_frequency_mode = 18;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterFundamentalFrequencyMode = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 18));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterFundamentalFrequencyMode = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearInverterFundamentalFrequencyMode = function() {
  return this.setInverterFundamentalFrequencyMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasInverterFundamentalFrequencyMode = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Selection inverter_symmetry_mode = 19;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterSymmetryMode = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 19));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterSymmetryMode = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearInverterSymmetryMode = function() {
  return this.setInverterSymmetryMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasInverterSymmetryMode = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Selection inverter_harmonic_mode = 20;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterHarmonicMode = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 20));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterHarmonicMode = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearInverterHarmonicMode = function() {
  return this.setInverterHarmonicMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasInverterHarmonicMode = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bool inverter_disable_eco_mode = 21;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDisableEcoMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDisableEcoMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional uint32 modbus_server_slave_id = 24;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getModbusServerSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setModbusServerSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 modbus_server_address_offset = 25;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getModbusServerAddressOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setModbusServerAddressOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional Selection modbus_server_endianness = 26;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getModbusServerEndianness = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 26));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setModbusServerEndianness = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.clearModbusServerEndianness = function() {
  return this.setModbusServerEndianness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.hasModbusServerEndianness = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional float inverter_droops_u_q_droop_main_lower = 27;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsUQDroopMainLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsUQDroopMainLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional float inverter_droops_u_q_droop_main_upper = 28;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsUQDroopMainUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsUQDroopMainUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional float inverter_droops_u_q_droop_t1_main = 29;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsUQDroopT1Main = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsUQDroopT1Main = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional float inverter_droops_f_p_droop_main_lower = 30;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsFPDroopMainLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsFPDroopMainLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional float inverter_droops_f_p_droop_main_upper = 31;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsFPDroopMainUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsFPDroopMainUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional float inverter_droops_f_p_droop_t1_main = 32;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsFPDroopT1Main = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsFPDroopT1Main = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional float inverter_droops_q_u_droop_main_lower = 33;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsQUDroopMainLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsQUDroopMainLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional float inverter_droops_q_u_droop_main_upper = 34;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsQUDroopMainUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsQUDroopMainUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional float inverter_droops_q_u_dead_band_lower = 35;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsQUDeadBandLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsQUDeadBandLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional float inverter_droops_q_u_dead_band_upper = 36;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsQUDeadBandUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsQUDeadBandUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional float inverter_droops_q_limit = 37;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsQLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsQLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional float inverter_droops_p_f_droop_main_lower = 38;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPFDroopMainLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPFDroopMainLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional float inverter_droops_p_f_droop_main_upper = 39;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPFDroopMainUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 39, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPFDroopMainUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 39, value);
};


/**
 * optional float inverter_droops_p_f_dead_band_lower = 40;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPFDeadBandLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 40, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPFDeadBandLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 40, value);
};


/**
 * optional float inverter_droops_p_f_dead_band_upper = 41;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPFDeadBandUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 41, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPFDeadBandUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 41, value);
};


/**
 * optional float inverter_droops_p_u_droop_lower = 42;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUDroopLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 42, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUDroopLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 42, value);
};


/**
 * optional float inverter_droops_p_u_droop_upper = 43;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUDroopUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 43, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUDroopUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 43, value);
};


/**
 * optional float inverter_droops_p_u_dead_band_lower = 44;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUDeadBandLower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 44, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUDeadBandLower = function(value) {
  return jspb.Message.setProto3FloatField(this, 44, value);
};


/**
 * optional float inverter_droops_p_u_dead_band_upper = 45;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUDeadBandUpper = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 45, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUDeadBandUpper = function(value) {
  return jspb.Message.setProto3FloatField(this, 45, value);
};


/**
 * optional float inverter_droops_p_u_max_charge = 46;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUMaxCharge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUMaxCharge = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional float inverter_droops_p_u_max_discharge = 47;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterDroopsPUMaxDischarge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterDroopsPUMaxDischarge = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional float inverter_eco_mode_threshold = 48;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.getInverterEcoModeThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4.prototype.setInverterEcoModeThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.BmsCase = {
  BMS_NOT_SET: 0,
  BMS_TESVOLT_BMS: 6
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.BmsCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getBmsCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.BmsCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.toObject = function(includeInstance, msg) {
  var f, obj = {
    inverterModbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inverterModbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inverterFloatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    inverterChargePowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    inverterDischargePowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    bmsTesvoltBms: (f = msg.getBmsTesvoltBms()) && proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInverterModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInverterModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterFloatChargeW(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterChargePowerLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInverterDischargePowerLimit(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.deserializeBinaryFromReader);
      msg.setBmsTesvoltBms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInverterModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInverterModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getInverterFloatChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getInverterChargePowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getInverterDischargePowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBmsTesvoltBms();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inverter_modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getInverterModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setInverterModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 inverter_modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getInverterModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setInverterModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float inverter_float_charge_w = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getInverterFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setInverterFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float inverter_charge_power_limit = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getInverterChargePowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setInverterChargePowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float inverter_discharge_power_limit = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getInverterDischargePowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setInverterDischargePowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Config5 bms_tesvolt_bms = 6;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.getBmsTesvoltBms = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config5|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.setBmsTesvoltBms = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.clearBmsTesvoltBms = function() {
  return this.setBmsTesvoltBms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18.prototype.hasBmsTesvoltBms = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limitPower: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    maxPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitPower(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxPower(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLimitPower();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMaxPower();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool limit_power = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.getLimitPower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.setLimitPower = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float max_power = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.getMaxPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21.prototype.setMaxPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fallbackTimeout: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fallbackPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    activateStandbyMode: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFallbackTimeout(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFallbackPower(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeW(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivateStandbyMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFallbackTimeout();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFallbackPower();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getActivateStandbyMode();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 fallback_timeout = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getFallbackTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setFallbackTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float fallback_power = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getFallbackPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setFallbackPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float float_charge_low_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float float_charge_high_soc = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float float_charge_w = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool activate_standby_mode = 8;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.getActivateStandbyMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22.prototype.setActivateStandbyMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    defaultBatteryCfgEqualizationChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    defaultBatteryCfgFloatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    defaultBatteryCfgFloatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultBatteryCfgFloatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultBatteryCfgEqualizationChargeW(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultBatteryCfgFloatChargeLowSoc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultBatteryCfgFloatChargeHighSoc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultBatteryCfgFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDefaultBatteryCfgEqualizationChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDefaultBatteryCfgFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDefaultBatteryCfgFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDefaultBatteryCfgFloatChargeW();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float default_battery_cfg_equalization_charge_w = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getDefaultBatteryCfgEqualizationChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setDefaultBatteryCfgEqualizationChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float default_battery_cfg_float_charge_low_soc = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getDefaultBatteryCfgFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setDefaultBatteryCfgFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float default_battery_cfg_float_charge_high_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getDefaultBatteryCfgFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setDefaultBatteryCfgFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float default_battery_cfg_float_charge_w = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.getDefaultBatteryCfgFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23.prototype.setDefaultBatteryCfgFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float float_charge_low_soc = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float float_charge_high_soc = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double float_charge_w = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasPv: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasBattery: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPv(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBattery(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHasPv();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasBattery();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_pv = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getHasPv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setHasPv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_battery = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getHasBattery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setHasBattery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float float_charge_low_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float float_charge_high_soc = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double float_charge_w = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasPv: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasBattery: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPv(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBattery(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHasPv();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasBattery();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_pv = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getHasPv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setHasPv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_battery = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getHasBattery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setHasBattery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float float_charge_low_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float float_charge_high_soc = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double float_charge_w = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasGridMeter: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    slaveIdGridMeter: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGridMeter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSlaveIdGridMeter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHasGridMeter();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSlaveIdGridMeter();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_grid_meter = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.getHasGridMeter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.setHasGridMeter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint32 slave_id_grid_meter = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.getSlaveIdGridMeter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11.prototype.setSlaveIdGridMeter = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasGridMeter: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGridMeter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHasGridMeter();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_grid_meter = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.getHasGridMeter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12.prototype.setHasGridMeter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasPv: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasBattery: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPv(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBattery(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHasPv();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasBattery();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_pv = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getHasPv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setHasPv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_battery = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getHasBattery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setHasBattery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float float_charge_low_soc = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float float_charge_high_soc = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double float_charge_w = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    wRtg: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deactivateStandby: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasGridMeter: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasPv: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasBattery: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWRtg(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeactivateStandby(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGridMeter(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPv(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBattery(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWRtg();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDeactivateStandby();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasGridMeter();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasPv();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasBattery();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float w_rtg = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getWRtg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setWRtg = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool deactivate_standby = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getDeactivateStandby = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setDeactivateStandby = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_grid_meter = 5;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getHasGridMeter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setHasGridMeter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_pv = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getHasPv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setHasPv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_battery = 7;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getHasBattery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setHasBattery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional float float_charge_low_soc = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float float_charge_high_soc = 9;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double float_charge_w = 10;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    floatChargeLowSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    floatChargeHighSoc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    floatChargeW: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    activateStandbyMode: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeLowSoc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatChargeHighSoc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatChargeW(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivateStandbyMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFloatChargeLowSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFloatChargeHighSoc();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFloatChargeW();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getActivateStandbyMode();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float float_charge_low_soc = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getFloatChargeLowSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setFloatChargeLowSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float float_charge_high_soc = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getFloatChargeHighSoc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setFloatChargeHighSoc = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double float_charge_w = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getFloatChargeW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setFloatChargeW = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool activate_standby_mode = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.getActivateStandbyMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20.prototype.setActivateStandbyMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputsContactor1: (f = msg.getInputsContactor1()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inputsContactor2: (f = msg.getInputsContactor2()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inputsVoltageHigh: (f = msg.getInputsVoltageHigh()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inputsVoltageLow: (f = msg.getInputsVoltageLow()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inputsFrequencyHigh: (f = msg.getInputsFrequencyHigh()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inputsFrequencyLow: (f = msg.getInputsFrequencyLow()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    gridMeterRef: (f = msg.getGridMeterRef()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    inverterMeterRef: (f = msg.getInverterMeterRef()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    outputsInhibit: (f = msg.getOutputsInhibit()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsContactor1(value);
      break;
    case 2:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsContactor2(value);
      break;
    case 3:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsVoltageHigh(value);
      break;
    case 4:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsVoltageLow(value);
      break;
    case 5:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsFrequencyHigh(value);
      break;
    case 6:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInputsFrequencyLow(value);
      break;
    case 7:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setGridMeterRef(value);
      break;
    case 8:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setInverterMeterRef(value);
      break;
    case 9:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setOutputsInhibit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputsContactor1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInputsContactor2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInputsVoltageHigh();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInputsVoltageLow();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInputsFrequencyHigh();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInputsFrequencyLow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getGridMeterRef();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getInverterMeterRef();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getOutputsInhibit();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
};


/**
 * optional Selection inputs_contactor1 = 1;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsContactor1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsContactor1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsContactor1 = function() {
  return this.setInputsContactor1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsContactor1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Selection inputs_contactor2 = 2;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsContactor2 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsContactor2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsContactor2 = function() {
  return this.setInputsContactor2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsContactor2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Selection inputs_voltage_high = 3;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsVoltageHigh = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsVoltageHigh = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsVoltageHigh = function() {
  return this.setInputsVoltageHigh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsVoltageHigh = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Selection inputs_voltage_low = 4;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsVoltageLow = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsVoltageLow = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsVoltageLow = function() {
  return this.setInputsVoltageLow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsVoltageLow = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Selection inputs_frequency_high = 5;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsFrequencyHigh = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsFrequencyHigh = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsFrequencyHigh = function() {
  return this.setInputsFrequencyHigh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsFrequencyHigh = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Selection inputs_frequency_low = 6;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInputsFrequencyLow = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInputsFrequencyLow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInputsFrequencyLow = function() {
  return this.setInputsFrequencyLow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInputsFrequencyLow = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Selection grid_meter_ref = 7;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getGridMeterRef = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 7));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setGridMeterRef = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearGridMeterRef = function() {
  return this.setGridMeterRef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasGridMeterRef = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Selection inverter_meter_ref = 8;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getInverterMeterRef = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 8));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setInverterMeterRef = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearInverterMeterRef = function() {
  return this.setInverterMeterRef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasInverterMeterRef = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Selection outputs_inhibit = 9;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.getOutputsInhibit = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 9));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.setOutputsInhibit = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.clearOutputsInhibit = function() {
  return this.setOutputsInhibit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config6.prototype.hasOutputsInhibit = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactor1Input: (f = msg.getContactor1Input()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    contactor2Input: (f = msg.getContactor2Input()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setContactor1Input(value);
      break;
    case 2:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setContactor2Input(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactor1Input();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getContactor2Input();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
};


/**
 * optional Selection contactor1_input = 1;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.getContactor1Input = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.setContactor1Input = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.clearContactor1Input = function() {
  return this.setContactor1Input(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.hasContactor1Input = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Selection contactor2_input = 2;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.getContactor2Input = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.setContactor2Input = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.clearContactor2Input = function() {
  return this.setContactor2Input(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config14.prototype.hasContactor2Input = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15;
  return proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config15.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional Config2 config2 = 2;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig2 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config2|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig2 = function() {
  return this.setConfig2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Config9 config9 = 9;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig9 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9, 9));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config9|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig9 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig9 = function() {
  return this.setConfig9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig9 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Config3 config3 = 3;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig3 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config3|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig3 = function() {
  return this.setConfig3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig3 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Config4 config4 = 4;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig4 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config4|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig4 = function() {
  return this.setConfig4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig4 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Config19 config19 = 19;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig19 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19, 19));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config19|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig19 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig19 = function() {
  return this.setConfig19(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig19 = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Config18 config18 = 18;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig18 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18, 18));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config18|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig18 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig18 = function() {
  return this.setConfig18(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig18 = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Config21 config21 = 21;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig21 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21, 21));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config21|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig21 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig21 = function() {
  return this.setConfig21(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig21 = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Config22 config22 = 22;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig22 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22, 22));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config22|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig22 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig22 = function() {
  return this.setConfig22(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig22 = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional Config23 config23 = 23;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig23 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23, 23));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config23|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig23 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig23 = function() {
  return this.setConfig23(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig23 = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Config7 config7 = 7;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig7 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7, 7));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config7|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig7 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig7 = function() {
  return this.setConfig7(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig7 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Config8 config8 = 8;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig8 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8, 8));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config8|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig8 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig8 = function() {
  return this.setConfig8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig8 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Config10 config10 = 10;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig10 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10, 10));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config10|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig10 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig10 = function() {
  return this.setConfig10(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig10 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Config11 config11 = 11;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig11 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11, 11));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config11|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig11 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig11 = function() {
  return this.setConfig11(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig11 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Config12 config12 = 12;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig12 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12, 12));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config12|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig12 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig12 = function() {
  return this.setConfig12(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig12 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Config13 config13 = 13;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig13 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13, 13));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config13|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig13 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig13 = function() {
  return this.setConfig13(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig13 = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Config16 config16 = 16;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig16 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16, 16));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config16|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig16 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig16 = function() {
  return this.setConfig16(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig16 = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Config17 config17 = 17;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig17 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17, 17));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config17|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig17 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig17 = function() {
  return this.setConfig17(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig17 = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Config20 config20 = 20;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.getConfig20 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20, 20));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter.Config20|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.setConfig20 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.de.mypowergrid.amperix.DeviceConfig.Inverter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Inverter} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.clearConfig20 = function() {
  return this.setConfig20(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Inverter.prototype.hasConfig20 = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_ = [[3,2,1,7,4,5,6,8,9,10,11]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG3: 3,
  CONFIG2: 2,
  CONFIG1: 1,
  CONFIG7: 7,
  CONFIG4: 4,
  CONFIG5: 5,
  CONFIG6: 6,
  CONFIG8: 8,
  CONFIG9: 9,
  CONFIG10: 10,
  CONFIG11: 11
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.toObject = function(includeInstance, msg) {
  var f, obj = {
    config3: (f = msg.getConfig3()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.toObject(includeInstance, f),
    config2: (f = msg.getConfig2()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.toObject(includeInstance, f),
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.toObject(includeInstance, f),
    config7: (f = msg.getConfig7()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.toObject(includeInstance, f),
    config4: (f = msg.getConfig4()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.toObject(includeInstance, f),
    config5: (f = msg.getConfig5()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.toObject(includeInstance, f),
    config6: (f = msg.getConfig6()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.toObject(includeInstance, f),
    config8: (f = msg.getConfig8()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.toObject(includeInstance, f),
    config9: (f = msg.getConfig9()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.toObject(includeInstance, f),
    config10: (f = msg.getConfig10()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.toObject(includeInstance, f),
    config11: (f = msg.getConfig11()) && proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.deserializeBinaryFromReader);
      msg.setConfig3(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.deserializeBinaryFromReader);
      msg.setConfig2(value);
      break;
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.deserializeBinaryFromReader);
      msg.setConfig7(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.deserializeBinaryFromReader);
      msg.setConfig4(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.deserializeBinaryFromReader);
      msg.setConfig5(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.deserializeBinaryFromReader);
      msg.setConfig6(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.deserializeBinaryFromReader);
      msg.setConfig8(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.deserializeBinaryFromReader);
      msg.setConfig9(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.deserializeBinaryFromReader);
      msg.setConfig10(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.deserializeBinaryFromReader);
      msg.setConfig11(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig3();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.serializeBinaryToWriter
    );
  }
  f = message.getConfig2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.serializeBinaryToWriter
    );
  }
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.serializeBinaryToWriter
    );
  }
  f = message.getConfig7();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.serializeBinaryToWriter
    );
  }
  f = message.getConfig4();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.serializeBinaryToWriter
    );
  }
  f = message.getConfig5();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.serializeBinaryToWriter
    );
  }
  f = message.getConfig6();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.serializeBinaryToWriter
    );
  }
  f = message.getConfig8();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.serializeBinaryToWriter
    );
  }
  f = message.getConfig9();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.serializeBinaryToWriter
    );
  }
  f = message.getConfig10();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.serializeBinaryToWriter
    );
  }
  f = message.getConfig11();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phases: (f = msg.getPhases()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    minSetPowerW: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxSetPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fallbackCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setPhases(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetPowerW(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetPower(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFallbackCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhases();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getMinSetPowerW();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMaxSetPower();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getFallbackCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Selection phases = 2;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getPhases = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setPhases = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.clearPhases = function() {
  return this.setPhases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.hasPhases = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float min_set_power_w = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getMinSetPowerW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setMinSetPowerW = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float max_set_power = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getMaxSetPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setMaxSetPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint32 min_on_time = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 min_off_time = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float fallback_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.getFallbackCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3.prototype.setFallbackCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    phases: (f = msg.getPhases()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    keyOverride: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    overrideCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 5:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setPhases(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyOverride(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverrideCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPhases();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getKeyOverride();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOverrideCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 min_on_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_off_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Selection phases = 5;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getPhases = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setPhases = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.clearPhases = function() {
  return this.setPhases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.hasPhases = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float min_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_set_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool key_override = 8;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getKeyOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setKeyOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional float override_current = 9;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.getOverrideCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2.prototype.setOverrideCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    fallbackCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFallbackCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getFallbackCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_on_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_off_time = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float min_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_set_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float fallback_current = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.getFallbackCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1.prototype.setFallbackCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    fallbackCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    numPhases: (f = msg.getNumPhases()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFallbackCurrent(value);
      break;
    case 9:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setNumPhases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getFallbackCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getNumPhases();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_on_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_off_time = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float min_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_set_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float fallback_current = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getFallbackCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setFallbackCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional Selection num_phases = 9;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.getNumPhases = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 9));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.setNumPhases = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.clearNumPhases = function() {
  return this.setNumPhases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7.prototype.hasNumPhases = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sempPort: jspb.Message.getFieldWithDefault(msg, 2, 0),
    webPort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    applicationPin: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSempPort(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWebPort(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSempPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWebPort();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getApplicationPin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 semp_port = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getSempPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setSempPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 web_port = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getWebPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setWebPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_on_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_off_time = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string application_pin = 6;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.getApplicationPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4.prototype.setApplicationPin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    dplName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float min_set_current = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float max_set_current = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string dpl_name = 5;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    applicationPin: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationPin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getApplicationPin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_on_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_off_time = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float min_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float max_set_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string application_pin = 8;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.getApplicationPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6.prototype.setApplicationPin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostIp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dplName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ctlMinSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ctlMaxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    chMinSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    chMaxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCtlMinSetCurrent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCtlMaxSetCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChMinSetCurrent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChMaxSetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCtlMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCtlMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getChMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getChMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional string host_ip = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getHostIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setHostIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dpl_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 min_on_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_off_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float ctl_min_set_current = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getCtlMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setCtlMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float ctl_max_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getCtlMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setCtlMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float ch_min_set_current = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getChMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setChMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float ch_max_set_current = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.getChMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8.prototype.setChMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSetCurrent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 min_on_time = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 min_off_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float min_set_current = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.getMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.setMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float max_set_current = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.getMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9.prototype.setMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minCtlSetPowerW: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    maxCtlSetPowerW: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinCtlSetPowerW(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxCtlSetPowerW(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinCtlSetPowerW();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMaxCtlSetPowerW();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 min_on_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_off_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float min_ctl_set_power_w = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getMinCtlSetPowerW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setMinCtlSetPowerW = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float max_ctl_set_power_w = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.getMaxCtlSetPowerW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10.prototype.setMaxCtlSetPowerW = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostIp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dplName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minOnTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOffTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    chMinSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    chMaxSetCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11;
  return proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChMinSetCurrent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChMaxSetCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinOnTime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOffTime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getChMinSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getChMaxSetCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string host_ip = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getHostIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setHostIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dpl_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 min_on_time = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getMinOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setMinOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_off_time = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getMinOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setMinOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float ch_min_set_current = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getChMinSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setChMinSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float ch_max_set_current = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.getChMaxSetCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11.prototype.setChMaxSetCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional Config3 config3 = 3;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig3 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config3|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig3 = function() {
  return this.setConfig3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig3 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Config2 config2 = 2;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig2 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config2|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig2 = function() {
  return this.setConfig2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Config7 config7 = 7;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig7 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7, 7));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config7|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig7 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig7 = function() {
  return this.setConfig7(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig7 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Config4 config4 = 4;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig4 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config4|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig4 = function() {
  return this.setConfig4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig4 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Config5 config5 = 5;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig5 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config5|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig5 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig5 = function() {
  return this.setConfig5(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig5 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Config6 config6 = 6;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig6 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config6|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig6 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig6 = function() {
  return this.setConfig6(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig6 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Config8 config8 = 8;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig8 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8, 8));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config8|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig8 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig8 = function() {
  return this.setConfig8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig8 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Config9 config9 = 9;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig9 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9, 9));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config9|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig9 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig9 = function() {
  return this.setConfig9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig9 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Config10 config10 = 10;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig10 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10, 10));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config10|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig10 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig10 = function() {
  return this.setConfig10(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig10 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Config11 config11 = 11;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.getConfig11 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11, 11));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.Config11|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.setConfig11 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.clearConfig11 = function() {
  return this.setConfig11(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Wallbox.prototype.hasConfig11 = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG1: 1
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Relay.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Relay.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Relay.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Relay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.toObject = function(includeInstance, msg) {
  var f, obj = {
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Relay;
  return proto.de.mypowergrid.amperix.DeviceConfig.Relay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Relay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    output: (f = msg.getOutput()) && ext_amperix_selection_pb.Selection.toObject(includeInstance, f),
    dplName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minOnTimeSec: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minOffTimeSec: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_selection_pb.Selection;
      reader.readMessage(value,ext_amperix_selection_pb.Selection.deserializeBinaryFromReader);
      msg.setOutput(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOnTimeSec(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinOffTimeSec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutput();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_selection_pb.Selection.serializeBinaryToWriter
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinOnTimeSec();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinOffTimeSec();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional Selection output = 1;
 * @return {?proto.de.mypowergrid.amperix.Selection}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.getOutput = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Selection} */ (
    jspb.Message.getWrapperField(this, ext_amperix_selection_pb.Selection, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Selection|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.setOutput = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.clearOutput = function() {
  return this.setOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.hasOutput = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string dpl_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 min_on_time_sec = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.getMinOnTimeSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.setMinOnTimeSec = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 min_off_time_sec = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.getMinOffTimeSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1.prototype.setMinOffTimeSec = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Relay.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Relay.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Relay} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Relay.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG1: 1
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Container.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Container.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Container.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Container.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.toObject = function(includeInstance, msg) {
  var f, obj = {
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Container;
  return proto.de.mypowergrid.amperix.DeviceConfig.Container.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Container.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Container.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Container.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Container} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Container.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.ConfigCase = {
  CONFIG_NOT_SET: 0,
  CONFIG1: 1
};

/**
 * @return {proto.de.mypowergrid.amperix.DeviceConfig.Eza.ConfigCase}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.DeviceConfig.Eza.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.DeviceConfig.Eza.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Eza.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.toObject = function(includeInstance, msg) {
  var f, obj = {
    config1: (f = msg.getConfig1()) && proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Eza;
  return proto.de.mypowergrid.amperix.DeviceConfig.Eza.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.deserializeBinaryFromReader);
      msg.setConfig1(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Eza.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusTcpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modbusTcpSlaveId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dplName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offGridEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    offGridDefaultsPv: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offGridDefaultsChp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offGridDefaultsHydrogen: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offGridDefaultsWind: jspb.Message.getFieldWithDefault(msg, 8, 0),
    offGridDefaultsBattery: jspb.Message.getFieldWithDefault(msg, 9, 0),
    offGridDefaultsChargers: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1;
  return proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModbusTcpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModbusTcpSlaveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDplName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOffGridEnabled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsPv(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsChp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsHydrogen(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsWind(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsBattery(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffGridDefaultsChargers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusTcpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModbusTcpSlaveId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDplName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOffGridEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOffGridDefaultsPv();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOffGridDefaultsChp();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOffGridDefaultsHydrogen();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOffGridDefaultsWind();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOffGridDefaultsBattery();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOffGridDefaultsChargers();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string modbus_tcp_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getModbusTcpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setModbusTcpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 modbus_tcp_slave_id = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getModbusTcpSlaveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setModbusTcpSlaveId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dpl_name = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getDplName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setDplName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool off_grid_enabled = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 off_grid_defaults_pv = 5;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsPv = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsPv = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 off_grid_defaults_chp = 6;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsChp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsChp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 off_grid_defaults_hydrogen = 7;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsHydrogen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsHydrogen = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 off_grid_defaults_wind = 8;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsWind = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsWind = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 off_grid_defaults_battery = 9;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 off_grid_defaults_chargers = 10;
 * @return {number}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.getOffGridDefaultsChargers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1.prototype.setOffGridDefaultsChargers = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Config1 config1 = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.getConfig1 = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Eza.Config1|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.setConfig1 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.Eza.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig.Eza} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.clearConfig1 = function() {
  return this.setConfig1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.Eza.prototype.hasConfig1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ID id = 15;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.ID}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getId = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.ID} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.ID, 15));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.ID|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Meter meter = 1;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Meter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getMeter = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Meter} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Meter, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Meter|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setMeter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearMeter = function() {
  return this.setMeter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasMeter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Inverter inverter = 2;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getInverter = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Inverter} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Inverter, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Inverter|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setInverter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearInverter = function() {
  return this.setInverter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasInverter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Wallbox wallbox = 4;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getWallbox = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Wallbox, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Wallbox|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setWallbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearWallbox = function() {
  return this.setWallbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasWallbox = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Relay relay = 5;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Relay}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getRelay = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Relay} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Relay, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Relay|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setRelay = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearRelay = function() {
  return this.setRelay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasRelay = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Container container = 8;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Container}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getContainer = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Container} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Container, 8));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Container|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setContainer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearContainer = function() {
  return this.setContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasContainer = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Eza eza = 9;
 * @return {?proto.de.mypowergrid.amperix.DeviceConfig.Eza}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.getEza = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DeviceConfig.Eza} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DeviceConfig.Eza, 9));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DeviceConfig.Eza|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
*/
proto.de.mypowergrid.amperix.DeviceConfig.prototype.setEza = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.amperix.DeviceConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DeviceConfig} returns this
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.clearEza = function() {
  return this.setEza(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DeviceConfig.prototype.hasEza = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.DeviceType = {
  UNSPECIFIED: 0,
  METER: 1,
  INVERTER: 2,
  HEATPUMP: 3,
  WALLBOX: 4,
  RELAY: 5,
  TEMPSENSOR: 6,
  CAR: 7,
  CONTAINER: 8,
  EZA: 9
};

goog.object.extend(exports, proto.de.mypowergrid.amperix);
