/**
 * @module view-helper/filters
 */

import moment from 'moment'
import i18n from '@/i18n'

import { TIME_UNITS_IN_SECONDS } from '@/view-helper/timeseries/timeseries-helper'

/**
 * Takes a datetime string in ISO8601 (or something moment.js can parse) and formats it to (browser) local datetime.
 *
 * [moment.js]{@link https://momentjs.com/}) is used.
 *
 * Use  in view: `{{ isoDateSring | formatToLocalDatetime }}``
 *
 * @function
 *
 * @param {string} datetime in ISO8601 format (or something moment.js can [parse]{@link https://momentjs.com/docs/#/parsing/string/})
 *
 * @return {string} formatted local time
 */
export function formatToLocalDatetime(datetime) {
  if (typeof datetime === 'string' && datetime) {
    // By default, moment parses and displays in local time.
    return moment(datetime).format('DD/MM/YYYY HH:mm:ss')
  } else {
    return ''
  }
}

/**
 * Takes a moment.MomentInput object (or something moment.js can parse) and formats it to (user) local datetime.
 *
 * [moment.js]{@link https://momentjs.com/}) is used.
 *
 * Use in view: `{{ moment.MomentInput | dateTimeFormatter(lang) }}``
 *
 * @function
 *
 * @param {object} momentObject
 *
 * @param {string} 'en' or 'de' reprensenting the locale
 *
 * @return {string} formatted (user) local datetime
 */

export function dateTimeFormatter(datetime, lang) {
  const localDateTime = moment(datetime)

  if (lang === 'en') {
    localDateTime.locale(lang)
    return localDateTime.locale(lang).format('lll')
  } else {
    localDateTime.locale(lang)
    return localDateTime.locale(lang).format('lll')
  }
}

/**
 *
 * @function
 *
 * @param {object} params
 * @param {number} params.timestamp has to be the timestamp in seconds (since unix epoch)
 * @param {number} params.periodicity (optional) is the periodicity in seconds.
 * @param {number} params.utcOffset (optional) is th offset from UTC in hours (e.g. `1.5` is supported)
 * @param {string} params.locale (optional) is the locale which should be respected during formatting
 *
 * @return {string} formatted timestamp
 */
export function formatTimestampPeriodicity({ timestamp, periodicity, utcOffset = 0, locale = 'en' }) {
  const tTouPath = 'ems.energyService.config.timeOfUseSetting.timeUnits'

  let format
  let postfix

  if (!periodicity) {
    format = 'llll ZZ'
    return moment.unix(timestamp).locale(locale).format(format)
  } else if (periodicity <= TIME_UNITS_IN_SECONDS.hour) {
    // periodicity <= hour
    format = 'mm:ss'
    postfix = i18n.t(`${tTouPath}.min`)
  } else if (periodicity <= TIME_UNITS_IN_SECONDS.day) {
    // periodicity <= day
    format = 'HH:mm:ss ZZ'
    postfix = i18n.t(`${tTouPath}.hour`)
  } else if (periodicity === TIME_UNITS_IN_SECONDS.week) {
    // periodicity <= week
    format = 'dd HH:mm:ss ZZ'
    postfix = ''
  } else {
    // periodicity = custom
    format = 'llll ZZ'
    postfix = ''
  }
  const t = moment.unix(timestamp).utc().utcOffset(utcOffset).locale(locale).format(format)
  return (t + ' ' + postfix).trim()
}

/**
 *
 * @function
 *
 * @return {number} offset in hours of the client's timezone from UTC
 */
export function getClientUtcOffset() {
  return moment().utcOffset() / 60
}
