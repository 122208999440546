<template>
  <CCard class="shadow-none p-1 flat-card mb-0 col-12">
    <ApxCardHeader
      :title="$t('management.license.licenseInfoTitle')"
      tag="h5"
      class-header="align-items-center d-flex
    justify-content-between border-bottom-0 pl-0 text-muted"
      class-title="mb-0 font-md"
    />
    <!-- BEGIN: Loading -->
    <CCardBody v-if="isLoading" class="mt-0">
      <vue-loading
        class="my-1"
        type="spiningDubbles"
        :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
      />
    </CCardBody>
    <!-- END:   Loading -->
    <!-- prettier-ignore -->
    <CCardBody
      v-else
      class="mt-0"
    >
      <div class="card-text p-0 m-0">
        <!-- BEGIN: License Expiration Warning -->
        <CAlert
          class="my-1 mx-1"
          close-button
          :show="isLicenseExpiring"
          color="warning"
        >
          <div class="alert-heading text-center">
            {{ $t('management.license.expirationWarning') }}
          </div>
        </CAlert>
        <!-- END:   License Expiration Warning -->
        <!-- BEGIN: First Card Info Row -->
        <template v-if="decodedLicense">
          <CRow>
            <CCol sm="12" md="7" class="pl-0">
              <!-- BEGIN: License sub/logo -->
              <CRow>
                <CCol col="12" class="text-center text-md-left">
                  <CIcon
                    v-if="decodedLicense.sub === 'TEM' && !isDark"
                    name="temLogoLight"
                    size="custom"
                    :height="40"
                    viewBox="0 -25 486 134"
                  />
                  <CIcon
                    v-else-if="decodedLicense.sub === 'TEM' && isDark"
                    name="temLogoDark"
                    size="custom"
                    :height="40"
                    viewBox="0 -25 486 134"
                  />
                  <!-- TODO: check issuer abbreviation for Wendeware / Amperix              -->
                  <CIcon
                    v-else-if="decodedLicense.sub === 'APX'"
                    name="amperixMinimalisticLogo"
                    size="custom"
                    :height="35"
                    viewBox="10 0 556 134"
                  />
                  <!-- Generic Logo as fallback -->
                  <CIcon
                    v-else
                    name="cil-security"
                    :height="40"
                  />
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol col="12" class="pr-0">
                  <small class="text-uppercase mb-1 text-info">
                    {{ $t('management.license.type') }}
                  </small>
                  <br>
                  <span
                    class="text-muted"
                  >
                    {{ licenseTypeLabel }}
                  </span>
                </CCol>
              </CRow>
            </CCol>
            <!-- END:   License sub/logo -->
            <!-- BEGIN: issue/issueID -->
            <CCol col="5" class="text-md-right">
              <CRow>
                <CCol col="12" class="pl-0 pr-0">
                  <small class="text-uppercase mb-1 text-info">
                    {{ $t('management.license.issuer') }}
                  </small>
                  <br>
                  <span
                    class="text-muted"
                  >
                    {{ decodedLicense.iss }}
                  </span>
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol col="12 pl-0 pr-0">
                  <small class="text-uppercase mb-1 text-info">
                    {{ $t('management.license.issuerId') }}
                  </small>
                  <br>
                  <span
                    v-if="!licenseClaims.issuerId || licenseClaims.issuerId ===''"
                    class="text-muted"
                  >
                    {{ licenseClaims.issuerIdNotAvailable }}
                  </span>
                  <span
                    v-else
                    class="text-muted"
                  >
                    {{ licenseClaims.issuerId }}
                  </span>
                </CCol>
              </CRow>
            </CCol>
            <!-- END: issue/issueID -->
          </CRow>
        </template>
        <!-- END:   First Card Info Row -->
        <hr>
        <!-- BEGIN:   Second Card Info Row -->
        <template v-if="decodedLicense">
          <!-- BEGIN: LicenseID -->
          <CRow class="mt-4">
            <CCol class="text-left pr-0">
              <small class="text-uppercase mb-1 text-info">
                {{ $t('management.license.licenseId') }}
              </small>
              <br>
              <span
                class="text-muted"
              >
                {{ decodedLicense.jti }}
              </span>
            </CCol>
          </CRow>
          <!-- END:   LicenseID -->
          <!-- BEGIN: Dates -->
          <CRow v-if="isLicenseValid" class="mt-3">
            <CCol sm="12" md="4" class="mb-1">
              <small class="text-uppercase mb-1 text-info">
                {{ $t('management.license.issuedOn') }}
              </small>
              <br>
              <span
                class="text-muted"
              >
                {{ licenseIat | dateTimeFormatter(lang) }}
              </span>
            </CCol>
            <CCol sm="12" md="4" class="text-md-center mb-1">
              <small class="text-uppercase mb-1 text-info">
                {{ $t('management.license.validFrom') }}
              </small>
              <br>
              <span
                class="text-muted"
              >
                {{ licenseNbf | dateTimeFormatter(lang) }}
              </span>
            </CCol>
            <CCol sm="12" md="4" class="text-md-right">
              <small class="text-uppercase mb-1 text-info">
                {{ $t('management.license.validTill') }}
              </small>
              <br>
              <span
                class="text-muted"
              >
                {{ licenseExp | dateTimeFormatter(lang) }}
              </span>
            </CCol>
          </CRow>
          <!-- END:   Dates -->
        </template>
        <!-- END:   Second Card Info Row -->
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import { dateTimeFormatter } from '@/view-helper/filters/datetime-formatter'

export default {
  name: 'ApxLicenseInfoCard',
  components: {
    ApxCardHeader
  },
  filters: {
    dateTimeFormatter
  },
  computed: {
    ...mapGetters('auth', [
      'decodedLicense',
      'isLicenseExpiring',
      'licenseClaims',
      'licenseExp',
      'licenseIat',
      'licenseNbf'
    ]),
    ...mapGetters('user', ['lang']),
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    isLicenseValid() {
      return this.licenseClaims.licenseType !== undefined
    },
    licenseTypeLabel() {
      const tPath = `management.license.licenseType.${this.licenseClaims.licenseType}`

      return this.$te(tPath) ? this.$t(tPath) : this.licenseClaims.licenseType
    }
  }
}
</script>
