/**
 * @module validations/ems-eval-expression-validators
 */

import { decimal, helpers, required, requiredIf } from 'vuelidate/lib/validators'
import { MULTI_ELEMENT_OPERATORS, SUPPORTED_OPERATORS } from '@/grpc/protobuf/ems-eval-expression-helper'

import { TimeseriesAggregate } from '@/../lib/proto_js/ext/ems/eval_pb'

const SUPPORTED_DRESSED_OPERATORS = [
  'reference-timeseries',
  'reference-sensor',
  'reference-sensor-all',
  'timepoint-sensor',
  'timepoint-sensor-all',
  'timeaggregate-sensor',
  'timeaggregate-sensor-all'
]

const SECONDS_PER_DAY = 24 * 60 * 60
const SENSOR_OPERATORS = [
  'timeaggregate-sensor-all',
  'timeaggregate-sensor',
  'timepoint-sensor-all',
  'timepoint-sensor'
]
const REFERENCE_OPERATORS = ['reference', 'reference-timeseries', 'reference-sensor', 'reference-sensor-all']
const TIMESERIES_OPERATORS = ['timeseriesPoint', 'timepoint-sensor', 'timepoint-sensor-all']
const TIMESERIES_AGGREGATE_OPERATORS = ['timeseriesAggregate', 'timeaggregate-sensor', 'timeaggregate-sensor-all']

// Small hacked required => requiredIf implementation to not validate init ' ' state as valid
const checkValidRef = (value, vm, operators) => {
  if (!operators.includes(vm.operator)) {
    return true
  }

  return required(value?.trim())
}

export const evalExpressionValidators = {
  alias: {
    required: (value, vm) => {
      return checkValidRef(value, vm, ['alias'])
    }
  },
  children: {
    required: (value, vm) => {
      if (!MULTI_ELEMENT_OPERATORS.includes(vm.operator)) return true

      return value > 0
    }
  },
  operator: {
    required,
    included: helpers.withParams({ type: 'included' }, (val) => {
      return SUPPORTED_OPERATORS.includes(val) || SUPPORTED_DRESSED_OPERATORS.includes(val)
    })
  },
  reference: {
    required: requiredIf((vm) => {
      return REFERENCE_OPERATORS.includes(vm.operator)
    })
  },
  value: {
    required: requiredIf((vm) => {
      return vm.operator === 'value'
    }),
    decimal
  },
  timeseriesPoint: {
    required: requiredIf((vm) => {
      return TIMESERIES_OPERATORS.includes(vm.operator)
    }),
    reference: {
      required: requiredIf(function () {
        return TIMESERIES_OPERATORS.includes(this.operator)
      })
    },
    deltaTime: {
      required,
      decimal,
      range: function (value) {
        if (this.$v && SENSOR_OPERATORS.includes(this.$v.operator.$model)) {
          return value >= -SECONDS_PER_DAY && value <= 0
        } else {
          return true
        }
      }
    }
  },
  timeseriesAggregate: {
    required: requiredIf((vm) => {
      return TIMESERIES_AGGREGATE_OPERATORS.includes(vm.operator)
    }),
    reference: {
      required: requiredIf(function () {
        return TIMESERIES_AGGREGATE_OPERATORS.includes(this.operator)
      })
    },
    deltaStartTime: {
      required,
      decimal,
      range: function (value) {
        if (this.$v && SENSOR_OPERATORS.includes(this.$v.operator.$model)) {
          return value >= -SECONDS_PER_DAY && value <= 0
        } else {
          return true
        }
      }
    },
    deltaEndTime: {
      required,
      decimal,
      order: (value, vm) => {
        return vm.deltaStartTime <= value
      },
      range: function (value) {
        if (this.$v && SENSOR_OPERATORS.includes(this.$v.operator.$model)) {
          return value >= -SECONDS_PER_DAY && value <= 0
        } else {
          return true
        }
      }
    },
    op: {
      required,
      valid: (value) => Object.values(TimeseriesAggregate.Operation).includes(value)
    }
  }
}
