<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <p style="white-space: pre-line">
      {{ $t(`${tBasePath}.description`) }}
    </p>
    <CForm @submit.prevent>
      <CRow :form="true" class="mb-2">
        <CCol>
          <CInput
            type="text"
            class="custom-form-label"
            :invalid-feedback="invalidFeedbackForAGChaiPrefix"
            :is-valid="$v.prefix.$dirty ? !$v.prefix.$error : null"
            :label="$t(`${tBasePath}.prefix.label`)"
            :placeholder="$t(`${tBasePath}.prefix.placeholder`)"
            :description="$t(`${tBasePath}.prefix.description`)"
            :value.sync="$v.prefix.$model"
          />
        </CCol>
        <CCol>
          <CInput
            type="text"
            class="custom-form-label"
            :invalid-feedback="invalidFeedbackForAGChaiFilename"
            :is-valid="$v.filepath.$dirty ? !$v.filepath.$error : null"
            :label="$t(`${tBasePath}.filepath.label`)"
            :placeholder="$t(`${tBasePath}.filepath.placeholder`)"
            :description="$t(`${tBasePath}.filepath.description`)"
            :value.sync="$v.filepath.$model"
          />
        </CCol>
      </CRow>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { formProps as props } from './form-props'
import { chaiScriptNodeFormValidators } from '@/validations/ems-es-decision-tree-validators'

import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'

export default {
  name: 'EsChaiScriptNodeForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      showFailureAlert: false,
      filepath: '',
      prefix: ''
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry']),
    invalidFeedbackForAGChaiFilename() {
      if (!this.$v.filepath.$error) {
        return ''
      }

      if (!this.$v.filepath.required) {
        return this.$t(`${this.tBasePath}.filepath.invalidFeedback.required`)
      } else if (!this.$v.filepath.filepath) {
        return this.$t(`${this.tBasePath}.filepath.invalidFeedback.filepath`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGChaiPrefix() {
      if (!this.$v.prefix.$error) {
        return ''
      }

      if (!this.$v.prefix.required) {
        return this.$t(`${this.tBasePath}.prefix.invalidFeedback.required`)
      } else if (!this.$v.prefix.alphaNum) {
        return this.$t(`${this.tBasePath}.prefix.invalidFeedback.alphaNum`)
      }

      return 'INVALID'
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.chaiScript'
    this.entry = this.getDecisionTreeEntry(this.idx)
    this.init()
  },
  validations() {
    return chaiScriptNodeFormValidators
  },
  methods: {
    init() {
      if (this.entry) {
        this.filepath = this.entry.params.decisionNodeParams.filepath
        this.prefix = this.entry.params.decisionNodeParams.prefix
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      this.$emit('confirmed', {
        idx: this.idx,
        decisionNodeType: 'chaiScript',
        params: { filepath: this.filepath, prefix: this.prefix }
      })
    }
  }
}
</script>
