<template>
  <div class="py-2">
    <p class="d-flex justify-content-between mb-1">
      <small class="text-muted text-break">
        {{ $t('ems.energyService.config.form.offGrid.noParameters') }}
      </small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'EsPreviewOffGrid'
}
</script>
