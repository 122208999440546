// source: ext/amperix/ems.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ext_amperix_error_pb = require('../../ext/amperix/error_pb.js');
goog.object.extend(proto, ext_amperix_error_pb);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerDeviceValue', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.EnergyManagerHealth', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.OperateRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.OperateResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.Value', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.Value.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.ValueUnit', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.ValueUnit.Range', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.displayName = 'proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.OperateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.OperateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.OperateRequest.displayName = 'proto.de.mypowergrid.amperix.OperateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.OperateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.OperateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.OperateResponse.displayName = 'proto.de.mypowergrid.amperix.OperateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.EnergyManagerHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.EnergyManagerHealth.displayName = 'proto.de.mypowergrid.amperix.EnergyManagerHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.displayName = 'proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.displayName = 'proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.EnergyManagerDeviceValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.displayName = 'proto.de.mypowergrid.amperix.EnergyManagerDeviceValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.displayName = 'proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.ValueUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.ValueUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.ValueUnit.displayName = 'proto.de.mypowergrid.amperix.ValueUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.ValueUnit.Range = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.ValueUnit.Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.ValueUnit.Range.displayName = 'proto.de.mypowergrid.amperix.ValueUnit.Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.Value.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.Value.displayName = 'proto.de.mypowergrid.amperix.Value';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest;
  return proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    health: (f = msg.getHealth()) && proto.de.mypowergrid.amperix.EnergyManagerHealth.toObject(includeInstance, f),
    registeredDevicesList: jspb.Message.toObjectList(msg.getRegisteredDevicesList(),
    proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse;
  return proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerHealth;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerHealth.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.deserializeBinaryFromReader);
      msg.addRegisteredDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerHealth.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnergyManagerHealth health = 2;
 * @return {?proto.de.mypowergrid.amperix.EnergyManagerHealth}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.getHealth = function() {
  return /** @type{?proto.de.mypowergrid.amperix.EnergyManagerHealth} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerHealth, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.EnergyManagerHealth|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated EnergyManagerDeviceStatus registered_devices = 3;
 * @return {!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus>}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.getRegisteredDevicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus>} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.setRegisteredDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.addRegisteredDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerStatusResponse.prototype.clearRegisteredDevicesList = function() {
  return this.setRegisteredDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string config_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusRequest.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnergyManagerDeviceStatus status = 2;
 * @return {?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.getStatus = function() {
  return /** @type{?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceStatusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  NAME: 1,
  CONFIG_ID: 2
};

/**
 * @return {proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.TypeCase}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.setName = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.clearName = function() {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string config_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.setConfigId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.clearConfigId = function() {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesRequest.prototype.hasConfigId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceValue;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EnergyManagerDeviceValue values = 2;
 * @return {!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue>}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceValue, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue>} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.amperix.EnergyManagerDeviceValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValuesResponse.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse;
  return proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceValue;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnergyManagerDeviceValue value = 2;
 * @return {?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.getValue = function() {
  return /** @type{?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceValue, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} returns this
*/
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse} returns this
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetEnergyManagerDeviceValueResponse.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.OperateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.OperateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.OperateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.de.mypowergrid.amperix.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.OperateRequest}
 */
proto.de.mypowergrid.amperix.OperateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.OperateRequest;
  return proto.de.mypowergrid.amperix.OperateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.OperateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.OperateRequest}
 */
proto.de.mypowergrid.amperix.OperateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.Value;
      reader.readMessage(value,proto.de.mypowergrid.amperix.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.OperateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.OperateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.OperateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.OperateRequest} returns this
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Value value = 2;
 * @return {?proto.de.mypowergrid.amperix.Value}
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.getValue = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Value} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.Value, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Value|undefined} value
 * @return {!proto.de.mypowergrid.amperix.OperateRequest} returns this
*/
proto.de.mypowergrid.amperix.OperateRequest.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.OperateRequest} returns this
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.OperateRequest.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.OperateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.OperateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.OperateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.OperateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.OperateResponse}
 */
proto.de.mypowergrid.amperix.OperateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.OperateResponse;
  return proto.de.mypowergrid.amperix.OperateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.OperateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.OperateResponse}
 */
proto.de.mypowergrid.amperix.OperateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.OperateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.OperateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.OperateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.OperateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.OperateResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.OperateResponse} returns this
*/
proto.de.mypowergrid.amperix.OperateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.OperateResponse} returns this
 */
proto.de.mypowergrid.amperix.OperateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.OperateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.EnergyManagerHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    alive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerHealth}
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.EnergyManagerHealth;
  return proto.de.mypowergrid.amperix.EnergyManagerHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerHealth}
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.EnergyManagerHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool alive = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.prototype.getAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerHealth} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerHealth.prototype.setAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.toObject(includeInstance, f),
    health: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastUpdate: (f = msg.getLastUpdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus;
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth} */ (reader.readEnum());
      msg.setHealth(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.serializeBinaryToWriter
    );
  }
  f = message.getHealth();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastUpdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth = {
  UNKNOWN: 0,
  OK: 1,
  WARNING: 2,
  ALARM: 3,
  UNSURE: 4,
  UNAVAILABLE: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    softwareVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusInfo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    configId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info;
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSoftwareVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusInfo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vendor = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string serial_number = 5;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string software_version = 6;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getSoftwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setSoftwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status_info = 7;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getStatusInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setStatusInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string config_id = 8;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Info info = 1;
 * @return {?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.getInfo = function() {
  return /** @type{?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.Info|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnergyManagerDeviceHealth health = 2;
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.getHealth = function() {
  return /** @type {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.EnergyManagerDeviceHealth} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.setHealth = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_update = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.getLastUpdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.setLastUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.clearLastUpdate = function() {
  return this.setLastUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceStatus.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastUpdate: (f = msg.getLastUpdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unit: (f = msg.getUnit()) && proto.de.mypowergrid.amperix.ValueUnit.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.de.mypowergrid.amperix.Value.toObject(includeInstance, f),
    control: (f = msg.getControl()) && proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.EnergyManagerDeviceValue;
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdate(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.ValueUnit;
      reader.readMessage(value,proto.de.mypowergrid.amperix.ValueUnit.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.Value;
      reader.readMessage(value,proto.de.mypowergrid.amperix.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control;
      reader.readMessage(value,proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.deserializeBinaryFromReader);
      msg.setControl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastUpdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.ValueUnit.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.Value.serializeBinaryToWriter
    );
  }
  f = message.getControl();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.toObject = function(includeInstance, msg) {
  var f, obj = {
    controllable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    value: (f = msg.getValue()) && proto.de.mypowergrid.amperix.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control;
  return proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setControllable(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.Value;
      reader.readMessage(value,proto.de.mypowergrid.amperix.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool controllable = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.getControllable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.setControllable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Value value = 2;
 * @return {?proto.de.mypowergrid.amperix.Value}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.getValue = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Value} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.Value, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Value|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_update = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getLastUpdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setLastUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.clearLastUpdate = function() {
  return this.setLastUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValueUnit unit = 4;
 * @return {?proto.de.mypowergrid.amperix.ValueUnit}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getUnit = function() {
  return /** @type{?proto.de.mypowergrid.amperix.ValueUnit} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.ValueUnit, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.ValueUnit|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Value value = 5;
 * @return {?proto.de.mypowergrid.amperix.Value}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getValue = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Value} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.Value, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Value|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Control control = 6;
 * @return {?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.getControl = function() {
  return /** @type{?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control, 6));
};


/**
 * @param {?proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.Control|undefined} value
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
*/
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.setControl = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.EnergyManagerDeviceValue} returns this
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.clearControl = function() {
  return this.setControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.EnergyManagerDeviceValue.prototype.hasControl = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.ValueUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.ValueUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ValueUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    stepSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    range: (f = msg.getRange()) && proto.de.mypowergrid.amperix.ValueUnit.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.ValueUnit}
 */
proto.de.mypowergrid.amperix.ValueUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.ValueUnit;
  return proto.de.mypowergrid.amperix.ValueUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.ValueUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.ValueUnit}
 */
proto.de.mypowergrid.amperix.ValueUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScale(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStepSize(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.amperix.ValueUnit.Range;
      reader.readMessage(value,proto.de.mypowergrid.amperix.ValueUnit.Range.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.ValueUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.ValueUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ValueUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStepSize();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.amperix.ValueUnit.Range.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.ValueUnit.Range.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.ValueUnit.Range} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.toObject = function(includeInstance, msg) {
  var f, obj = {
    minValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.ValueUnit.Range}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.ValueUnit.Range;
  return proto.de.mypowergrid.amperix.ValueUnit.Range.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.ValueUnit.Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.ValueUnit.Range}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.ValueUnit.Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.ValueUnit.Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double min_value = 1;
 * @return {number}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit.Range} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double max_value = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit.Range} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.Range.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double offset = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double scale = 3;
 * @return {number}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double step_size = 4;
 * @return {number}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.getStepSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.setStepSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Range range = 5;
 * @return {?proto.de.mypowergrid.amperix.ValueUnit.Range}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.getRange = function() {
  return /** @type{?proto.de.mypowergrid.amperix.ValueUnit.Range} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.ValueUnit.Range, 5));
};


/**
 * @param {?proto.de.mypowergrid.amperix.ValueUnit.Range|undefined} value
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
*/
proto.de.mypowergrid.amperix.ValueUnit.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.ValueUnit} returns this
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.ValueUnit.prototype.hasRange = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.Value.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.Value.TypeCase = {
  TYPE_NOT_SET: 0,
  BOOL_VALUE: 1,
  NUMERIC_VALUE: 2,
  STRING_VALUE: 3
};

/**
 * @return {proto.de.mypowergrid.amperix.Value.TypeCase}
 */
proto.de.mypowergrid.amperix.Value.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.Value.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    numericValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stringValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.Value}
 */
proto.de.mypowergrid.amperix.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.Value;
  return proto.de.mypowergrid.amperix.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.Value}
 */
proto.de.mypowergrid.amperix.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumericValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool bool_value = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.Value.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.Value.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double numeric_value = 2;
 * @return {number}
 */
proto.de.mypowergrid.amperix.Value.prototype.getNumericValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.setNumericValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.clearNumericValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.Value.prototype.hasNumericValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string string_value = 3;
 * @return {string}
 */
proto.de.mypowergrid.amperix.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.Value} returns this
 */
proto.de.mypowergrid.amperix.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.de.mypowergrid.amperix.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.de.mypowergrid.amperix);
