/** @module store/notifications */

/**
 * State for current alert and toaster messages.
 *
 * - `alertMsg` see {@link module:store/notifications.newAlertNotificationMsg}
 * - `toasterMsgs` is a lost of {@link module:store/notifications.newToastNotificationMsg}
 * - `alertPreserved` is a `boolean`. In case of routing, the alert is closed by default. If this parameter is true, it will not be closed, however, will toggled to `false`. I.e. only one route to is "preserved."
 */
export const state = {
  alertMsg: null,
  toasterMsgs: [],
  alertPreserved: false
}

let nextMessageId = 1

const getters = {
  alertMsg: (state) => {
    return state.alertMsg
  },
  lastToasterMsgId: (state) => {
    return getLastMsgId(state.toasterMsgs)
  },
  toasterMsgs: (state) => {
    return state.toasterMsgs
  }
}

const mutations = {
  NEW_ALERT(state, msg) {
    state.alertMsg = msg
  },
  ADD_TO_ALERT(state, msg) {
    if (typeof state.alertMsg === 'string') {
      state.alertMsg = [state.alertMsg, msg]
    } else if (Array.isArray(state.alertMsg)) {
      state.alertMsg.push(msg)
    } else {
      state.alertMsg = msg
    }
  },
  SHOW_ALERT(state, show) {
    if (state.alertMsg) {
      state.alertMsg.show = show
    }
  },
  DELETE_ALERT(state) {
    state.alertMsg = null
  },
  PRESERVE_ALERT(state, preserve) {
    state.alertPreserved = preserve
  },
  PUSH_TOAST(state, message) {
    state.toasterMsgs.push({
      ...message,
      id: nextMessageId++
    })
  },
  DELETE_TOAST(state, msgId) {
    const i = state.toasterMsgs.findIndex((msg) => {
      return msg.id === msgId
    })
    if (i >= 0) {
      state.toasterMsgs.splice(i, 1)
    }
  },
  DELETE_ALL_TOASTS(state) {
    state.toasterMsgs = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions: {},
  mutations
}

/**
 * Builds a new alert notification message.
 *
 * @function
 *
 * @param {object} params
 * @param {boolean|integer} params.show sets the alert to be visible or not. If an `integer`, the alert is display this time in seconds.
 * @param {string} params.type is e.g. `danger`, `warning`, 'success', `primary`, ...
 * @param {string} params.content is the alert's text/content
 *
 * @return {object}
 */
export function newAlertNotificationMsg({ show = true, type = 'danger', content = '' } = {}) {
  return {
    show,
    type,
    content
  }
}

/**
 * Builds a new toast notification message.
 *
 * @function
 *
 * @param {object} params
 * @param {integer} params.autohide is the time in ms when the toast is hidden.
 * @param {string} params.title is the toaster's title
 * @param {string} params.content is the toater's content
 * @param {string} params.type is e.g. `danger`, `warning`, 'success', `primary`, ...
 *
 * @return {object}
 */
export function newToastNotificationMsg({ autohide = 5000, title, content, type = 'warning' }) {
  return {
    autohide,
    title,
    content,
    type
  }
}

// private
function getLastMsgId(msgs) {
  const l = msgs.length
  if (l < 1 || !msgs[l - 1]) {
    return null
  } else {
    return msgs[l - 1].id
  }
}
