// source: ext/license/license.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.license.License', null, global);
goog.exportSymbol('proto.de.mypowergrid.license.LicenseType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.license.License = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.license.License, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.license.License.displayName = 'proto.de.mypowergrid.license.License';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.license.License.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.license.License.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.license.License} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.license.License.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    issuerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.license.License}
 */
proto.de.mypowergrid.license.License.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.license.License;
  return proto.de.mypowergrid.license.License.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.license.License} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.license.License}
 */
proto.de.mypowergrid.license.License.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.license.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.license.License.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.license.License.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.license.License} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.license.License.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIssuerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LicenseType license_type = 1;
 * @return {!proto.de.mypowergrid.license.LicenseType}
 */
proto.de.mypowergrid.license.License.prototype.getLicenseType = function() {
  return /** @type {!proto.de.mypowergrid.license.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.license.LicenseType} value
 * @return {!proto.de.mypowergrid.license.License} returns this
 */
proto.de.mypowergrid.license.License.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string issuer_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.license.License.prototype.getIssuerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.license.License} returns this
 */
proto.de.mypowergrid.license.License.prototype.setIssuerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.license.LicenseType = {
  NONE: 0,
  BASIC: 1,
  PRO: 2,
  PURE: 3,
  PLUS: 4,
  LEGACY: 5,
  OMI: 6
};

goog.object.extend(exports, proto.de.mypowergrid.license);
