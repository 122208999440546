// source: ext/ems/options/functional_constraints.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.ems.options.IECFunctionalConstraints', null, global);
/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.options.IECFunctionalConstraints = {
  XX: 0,
  ST: 1,
  MX: 2,
  CO: 3,
  SP: 4,
  SV: 5,
  CF: 6,
  DC: 7,
  SG: 8,
  SE: 9,
  EX: 10,
  BR: 11,
  RP: 12,
  LG: 13,
  GO: 14,
  GS: 15,
  MS: 16,
  US: 17,
  SR: 18,
  OR: 19,
  BL: 20
};

goog.object.extend(exports, proto.de.mypowergrid.ems.options);
