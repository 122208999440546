<template>
  <CCardBody>
    <!-- BEGIN: IP Address Dropdown -->
    <CSelect
      class="custom-form-label"
      :options="ipAddressOptions"
      :horizontal="styling.horizontal"
      :label="$t(`${tTouPath}.ipDropdown.label`)"
      :description="$t(`${tTouPath}.ipDropdown.description`)"
      @update:value="onSelectIpAddress"
    />
    <!-- END: IP Address Dropdown -->
    <!-- BEGIN: Show IP-Address if copy to clipboard fails -->
    <CRow v-if="showIpAddress">
      <!-- BEGIN: Label -->
      <CCol tag="label" md="5" xl="4" class="custom-form-label">
        {{ $t(`${tTouPath}.hiddenIpAddress.label`) }}
      </CCol>
      <!-- END: Label -->
      <!-- BEGIN: IP-Address -->
      <CCol md="5" xl="7" class="pl-sm-1">
        {{ copiedIpAddress }}
      </CCol>
      <!-- BEGIN: Description -->
      <CCol sm="7" class="offset-xl-4 offset-md-5 pl-sm-1">
        <small class="form-text text-muted">
          {{ $t(`${tTouPath}.hiddenIpAddress.description`) }}
        </small>
      </CCol>
    </CRow>
    <!-- END: Description -->
    <!-- END: Show IP-Address if copy to clipboard fails -->

    <!-- ============= -->
    <!-- ============= -->

    <!-- BEGIN: Hidden Input Field -->
    <!-- prettier-ignore -->
    <input id="copyIpAddress" type="hidden">
    <!-- END: Hidden Input Field -->
  </CCardBody>
</template>

<script>
import { mapGetters } from 'vuex'

import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'ApxModbusTcpIpConfigAssistant',
  data() {
    return {
      showIpAddress: false,
      copiedIpAddress: null
    }
  },
  computed: {
    ...mapGetters('apxInterfaces', ['networkKnownDevicesIpAddresses']),
    ipAddressOptions() {
      const opts = [
        {
          label: this.$t(`${this.tTouPath}.ipDropdown.placeholder`),
          value: null
        }
      ]

      this.networkKnownDevicesIpAddresses.forEach((ip) => {
        opts.push({
          value: ip
        })
      })

      return opts
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-md-5 col-xl-4',
        input: 'col-12 col-md-7 col-xl-6'
      }
    }
    this.tTouPath = 'config.device.modbusTcpAssistant'
    this.initStore()
  },
  methods: {
    copyToClipboardWithoutHttps(ipAddress) {
      document.getElementById('copyIpAddress').value = ipAddress
      const copyText = document.getElementById('copyIpAddress')

      copyText.type = 'text'
      copyText.select()

      return new Promise((resolve, reject) => {
        // NOTE: 'execCommand' is deprecated, but it is a working solution for now
        // This works in firefox 101.0.1, chrome 103.0.5060.53, edge 103.0.1264.44, fails in safari
        try {
          if (document.execCommand('copy')) {
            resolve()
          } else {
            throw new Error('Copying to clipboard failed')
          }
        } catch (err) {
          reject(err)

          this.copiedIpAddress = ipAddress
          this.showIpAddress = true
        } finally {
          copyText.type = 'hidden'
        }
      })
    },
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('apxInterfaces/fetchKnownDevices')
        .then(() => {
          this.$log.debug('Successful fetched known devices.')
        })
        .catch((err) => {
          this.$log.error('An error occurred while fetching known devices: ', err)

          this.$store.commit('notifications/PUSH_TOAST', {
            title: this.$t('interfaces.ethernet.ampNetworkConnectivity.notifications.title'),
            type: 'toast-warning',
            content: this.$t('api.errors.base_msg', ['Known devices', err.code, ''])
          })
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onCopyFailure(err) {
      this.$log.debug('An error occurred while copying the IP address to clipboard: ', err)

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          type: 'danger',
          content: this.$t(`${this.tTouPath}.notifications.contentFailure`),
          title: this.$t(`${this.tTouPath}.notifications.title`)
        })
      )
    },
    onCopySuccess(ipAddress) {
      this.$log.debug('Successfully copied the IP address to clipboard: ', ipAddress)

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 3000,
          type: 'success',
          content: this.$t(`${this.tTouPath}.notifications.contentSuccess`, {
            ipAddress: ipAddress
          }),
          title: this.$t(`${this.tTouPath}.notifications.title`)
        })
      )
    },
    onSelectIpAddress(ipAddress) {
      if (ipAddress !== null) {
        // For HTTPs and localhost, navigator's clipboard is accessable ONLY
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard
            .writeText(ipAddress)
            .then(() => {
              this.onCopySuccess()
            })
            .catch((err) => {
              this.onCopyFailure(err)
            })
        } else {
          this.copyToClipboardWithoutHttps(ipAddress)
            .then(() => {
              this.onCopySuccess(ipAddress)
            })
            .catch((err) => {
              this.onCopyFailure(err)
            })
        }
      }
    }
  }
}
</script>
