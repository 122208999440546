<template>
  <div>
    <!-- BEGIN: modal(s) -->
    <!-- prettier-ignore -->
    <CModal
      :title="modalTitle"
      color="primary"
      size="lg"
      :show="showModal"
      @update:show="onModalClose"
    >
      <AsActuatorGroupForm
        v-if="actuatorGroup.form.show"
        :type="actuatorGroup.form.type"
        :actuator-group-id="actuatorGroup.form.actuatorGroupId"
        @cancel="actuatorGroup.form.show = false"
        @create:actuator-group="onCreateActuatorGroup"
        @update:actuator-group="onUpdateActuatorGroup"
      />
      <AsActuatorGroupShow
        v-if="actuatorGroup.details.show"
        :actuator-group-id="actuatorGroup.details.actuatorGroupId"
      />
      <EmsEsEnergyServiceForm
        v-if="energyService.form.show"
        :strategy-id="strategyId"
        :actuator-group-id="energyService.form.actuatorGroupId"
        :energy-service-id="energyService.form.energyServiceId"
        @energy-service-confirmed="onEnergyServiceConfirmed"
        @energy-service-canceled="energyService.form.show = false"
      />
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
    <ConfirmationModal
      key="actuator-group-delete"
      :visible.sync="actuatorGroup.confirm.show"
      :color="actuatorGroup.confirm.type == 'delete' ? 'danger' : ''"
      size="lg"
      :title="confirmTitle"
      @update:confirmation="onConfirmActuatorGroup({ confirmed: $event })"
    >
      {{ $t('ems.energyService.actuatorGroup.confirmation.delete.description') }}
    </ConfirmationModal>
    <!-- END: modal(s) -->
    <!-- BEGIN: Header -->
    <CCardGroup>
      <CCard>
        <CCardHeader class="align-items-center d-flex justify-content-around">
          <CCardTitle class="mb-0">
            {{ $t('ems.energyService.actuatorGroups') }}
          </CCardTitle>
          <span class="card-header-actions text-muted ml-1 d-none d-xl-inline small">
            {{ strategyId | handleNewStrategy }}
          </span>
        </CCardHeader>
      </CCard>
      <CCard>
        <CCardHeader class="align-items-center d-flex justify-content-around">
          <CCardTitle class="mb-0">
            {{ $t('ems.energyService.energyServices') }}
          </CCardTitle>
          <span class="card-header-actions text-muted ml-1 d-none d-xl-inline small">
            {{ strategyId | handleNewStrategy }}
          </span>
        </CCardHeader>
      </CCard>
    </CCardGroup>
    <!-- END: Header -->
    <!-- BEGIN: Strategy -->
    <template v-for="gId of sortedActuatorGroupIds">
      <CCardGroup :key="`AG ${gId} of Strg ${strategyId}`">
        <!-- BEGIN: AG -->
        <CCard class="px-2 flat-card shadow-none">
          <CCardBody class="mt-2 mb-0 my-sm-2 my-lg-3">
            <CCardTitle tag="h6" class="d-flex flex-column flex-md-row justify-content-between text-muted">
              <span class="small">
                {{ $t('ems.energyService.actuatorGroup.form.type.label') }}:
                {{ $t(`ems.energyService.actuatorGroup.form.actuatorGroupType.${getActuatorGroupType(gId)}`) }}
              </span>
              <span class="ml-2 text-break">
                {{ gId }}
              </span>
            </CCardTitle>
            <EmsEsActuatorGroupMembers
              :actuator-group-id="gId"
              :max-number="2"
              wrapper-tag="span"
              member-tag="CCardText"
            />
            <CCardText class="mt-3 px-5 d-flex justify-content-between">
              <!-- prettier-ignore -->
              <CButton
                type="button"
                size="sm"
                shape="square"
                color="primary"
                :disabled="isNewStrategy"
                @click="onShowActuatorGroup(gId)"
              >
                {{ $t('main.detailsBtn') }}
              </CButton>
              <!-- prettier-ignore -->
              <CButton
                type="button"
                size="sm"
                shape="square"
                color="primary"
                :disabled="isNewStrategy"
                @click="onEditActuatorGroup(gId)"
              >
                {{ $t('main.editBtn') }}
              </CButton>
              <!-- prettier-ignore -->
              <CButton
                type="button"
                size="sm"
                shape="square"
                color="danger"
                :disabled="isNewStrategy"
                @click="onDeleteActuatorGroup(gId)"
              >
                {{ $t('main.deleteBtn') }}
              </CButton>
            </CCardText>
          </CCardBody>
        </CCard>
        <!-- END: AG -->
        <CCard
          :class="{
            'text-muted': isNewStrategy,
            'px-2': true,
            'flat-card': true,
            'shadow-none': true
          }"
        >
          <!-- BEGIN: used Actuator Groups -->
          <CCardBody v-if="isActuatorGroupUsed(gId)" class="mt-0 mb-2 my-sm-2 my-lg-3">
            <CCardTitle
              v-if="!hasBlankEnergyService(gId)"
              tag="h6"
              class="d-flex flex-column flex-md-row justify-content-between"
            >
              <span class="ml-2 text-muted small"> {{ $t('ems.energyService.strategy.energyService') }}: </span>
              <span class="mx-2 text-primary font-weight-bold">
                {{ getEnergyServiceName({ strategyId, actuatorGroupId: gId }) }}
              </span>
            </CCardTitle>
            <CCardText>
              <!-- :description="$t('ems.energyService.config.form.description')" -->
              <CSelect
                :key="getEnergyServiceSelectKey(gId)"
                :class="{ 'mt-md-4': hasBlankEnergyService(gId) }"
                :disabled="isNewStrategy"
                :is-valid="
                  getEnergyServiceId({ strategyId, actuatorGroupId: gId }) === '_no-energy-service' ? false : null
                "
                :value="getEnergyServiceId({ strategyId, actuatorGroupId: gId })"
                :options="
                  getEnergyServiceOpts({ strategyId, actuatorGroupId: gId, strategyIdBlacklist: blacklistedESs })
                "
                @update:value="onEnergyServiceChosen({ actuatorGroupId: gId, energyServiceId: $event })"
              >
                <template #append>
                  <!-- prettier-ignore -->
                  <CButton
                    type="button"
                    color="primary"
                    shape="square"
                    size="sm"
                    :disabled="isNewStrategy"
                    @click="onEnergyServiceChosen({ actuatorGroupId: gId, energyServiceId: getEnergyServiceId({ strategyId, actuatorGroupId: gId }) })"
                  >
                    {{ $t('main.editBtn') }}
                  </CButton>
                </template>
              </CSelect>
            </CCardText>
            <CCardText class="d-flex justify-content-end">
              <CButton size="sm" shape="square" color="primary" @click="onDropActuatorGroupFromStrategy(gId)">
                {{ $t('ems.energyService.actuatorGroup.dropFromStrategy') }}
              </CButton>
            </CCardText>
          </CCardBody>
          <!-- END: used Actuator Groups -->
          <!-- BEGIN: unused Actuator Group -->
          <CCardBody v-else class="mt-0 mb-2 my-sm-2 my-lg-3">
            <CCardText class="px-xl-5">
              {{ $t('ems.energyService.actuatorGroup.unused') }}
            </CCardText>
            <CCardText class="d-flex justify-content-end">
              <CButton
                v-if="allowAddingWallboxActuatorGroupToStrategy(gId)"
                size="sm"
                shape="square"
                color="primary"
                :disabled="isNewStrategy"
                @click="onAddActuatorGroupToStrategy(gId)"
              >
                {{ $t('ems.energyService.actuatorGroup.addToStrategy') }}
              </CButton>
            </CCardText>
          </CCardBody>
          <!-- END: unused Actuator Group -->
        </CCard>
      </CCardGroup>
    </template>
    <!-- END: Strategy -->
    <!-- BEGIN: Footer -->
    <CCardGroup>
      <CCard class="px-2 shadow-none">
        <CCardBody class="py-1 py-sm-2 py-lg-3">
          <!-- prettier-ignore -->
          <CButton
            type="button"
            size="sm"
            color="primary"
            :disabled="isNewStrategy"
            @click="onNewActuatorGroup"
          >
            {{ $t('ems.energyService.actuatorGroup.newActuatorGroup') }}
          </CButton>
        </CCardBody>
      </CCard>
      <CCard class="px-2 shadow-none">
        <CCardBody class="py-1 py-sm-2 py-lg-3">
          <BtnWithDescription
            size="sm"
            main-btn-color="primary"
            :disable-btn="isNewStrategy"
            :label="$t('ems.energyService.strategy.autoComplete.label')"
            :description="$t('ems.energyService.strategy.autoComplete.description')"
            @click="onAutoCompleteStrategy"
          />
        </CCardBody>
      </CCard>
    </CCardGroup>
    <!-- END: Footer -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AsActuatorGroupForm from '@/components/ems/energy-services/actuator-strategies-forms/as-actuator-group-form'
import AsActuatorGroupShow from '@/components/ems/energy-services/actuator-strategies-forms/as-actuator-group-show'
import BtnWithDescription from '@/components/snippets/btn-with-description'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import EmsEsActuatorGroupMembers from '@/components/ems/energy-services/ems-es-actuator-group-members'
import EmsEsEnergyServiceForm from '@/components/ems/energy-services/ems-es-energy-service-form'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { newToastNotificationMsg } from '@/store/modules/notifications'
import {
  EMS_ENERGY_SERVICE_NEW_STRATEGY_ID,
  EMS_ENERGY_SERVICE_NONE_ID
} from '@/view-helper/ems/ems-energy-service-helper'

const AUTOHIDE_INFO_MS = 30000

export default {
  name: 'EmsEsStrategyConfig',
  components: {
    AsActuatorGroupForm,
    AsActuatorGroupShow,
    BtnWithDescription,
    ConfirmationModal,
    EmsEsActuatorGroupMembers,
    EmsEsEnergyServiceForm
  },
  filters: {
    handleNewStrategy(strategyId) {
      if (strategyId === EMS_ENERGY_SERVICE_NEW_STRATEGY_ID) {
        return ''
      }
      return strategyId
    }
  },
  props: {
    strategyId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      actuatorGroup: {
        details: {
          show: false,
          actuatorGroupId: null
        },
        form: {
          type: '', // new or edit
          actuatorGroupId: null,
          show: false
        },
        confirm: {
          type: '', // delete
          actuatorGroupId: null,
          show: false
        }
      },
      energyService: {
        form: {
          actuatorGroupId: null,
          energyServiceId: EMS_ENERGY_SERVICE_NONE_ID,
          show: false
        }
      },
      sortedActuatorGroupIds: [] // `actuatorGroupids` sorted by is-used & AG-name. Has to be state, because sorting should be done only, if `strategyId` or `actuatorGroupIds` changes, not if is-used changes.
    }
  },
  computed: {
    ...mapState('emsEnergyServicesConfig', ['actuatorGroupIds', 'actuatorGroups', 'strategyIds']),
    ...mapGetters('auth', ['disabledFeatures', 'getFeatureRestrictions']),
    ...mapGetters('emsEnergyServicesConfig', [
      'actuatorGroupIncludesIecNodePrefix',
      'getActuatorGroupType',
      'getEnergyService',
      'getEnergyServiceId',
      'getEnergyServicePreselection',
      'getEnergyServiceName',
      'getEnergyServiceOpts',
      'getStrategyCountForIecNodePrefix'
    ]),
    allowAddingWallboxActuatorGroupToStrategy() {
      return (gId) => {
        if (this.chargePointUseUnrestricted) {
          return true
        }

        const maxPermittedSetpoints = this.getFeatureRestrictions({ feature: 'advanced_charge_point_use' })
          ?.electricVehicleCharger?.maxPermittedSetpoints

        const relevantIecNodePrefix = 'ch'

        if (!this.actuatorGroupIncludesIecNodePrefix({ actuatorGroupId: gId, iecNodePrefix: relevantIecNodePrefix })) {
          return true
        }

        return (
          this.getStrategyCountForIecNodePrefix({
            iecNodePrefix: relevantIecNodePrefix,
            strategyId: this.strategyId
          }) < maxPermittedSetpoints
        )
      }
    },
    chargePointUseUnrestricted() {
      const restrictions = this.getFeatureRestrictions({ feature: 'advanced_charge_point_use' })

      if (restrictions?.enabled && !restrictions?.electricVehicleCharger) {
        return true
      }

      return false
    },
    blacklistedESs() {
      const blacklist = []
      const featureToOpt = {
        backup_power: 'offGrid',
        economic_peak_shaving: 'recordedPowerMeasurementPeakShaving',
        generation_peak_shaving: 'generationPeakShaving'
      }

      for (const feature of this.disabledFeatures) {
        if (featureToOpt[feature]) {
          blacklist.push(featureToOpt[feature])
        }
      }
      return blacklist
    },
    isActuatorGroupUsed() {
      return (actuatorGroupId) => {
        return !!this.getEnergyService({ strategyId: this.strategyId, actuatorGroupId })
      }
    },
    hasBlankEnergyService() {
      return (actuatorGroupId) => {
        const esId = this.getEnergyServiceId({ strategyId: this.strategyId, actuatorGroupId })

        return esId === EMS_ENERGY_SERVICE_NONE_ID
      }
    },
    getEnergyServiceSelectKey() {
      return (gId) => {
        let key = gId
        if (this.energyService.form.actuatorGroupId === gId) {
          key += ' - ' + this.energyService.form.energyServiceId
        } else {
          key += ' - ' + this.getEnergyServiceId({ strategyId: this.strategyId, actuatorGroupId: gId })
        }

        return key
      }
    },
    isNewStrategy() {
      return this.strategyId === EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
    },
    showModal() {
      return this.actuatorGroup.details.show || this.actuatorGroup.form.show || this.energyService.form.show
    },
    confirmTitle() {
      if (this.actuatorGroup.confirm.show) {
        switch (this.actuatorGroup.confirm.type) {
          case 'delete':
            return this.$t(`ems.energyService.actuatorGroup.confirmation.${this.actuatorGroup.confirm.type}.title`, [
              this.actuatorGroup.confirm.actuatorGroupId
            ])
        }
      }
      return ''
    },
    modalTitle() {
      if (this.actuatorGroup.details.show) {
        return this.$t('ems.energyService.actuatorGroup.form.title.show')
      } else if (this.actuatorGroup.form.show) {
        return this.$t(`ems.energyService.actuatorGroup.form.title.${this.actuatorGroup.form.type}`)
      } else if (this.energyService.form.show) {
        return this.$t(`ems.energyService.config.form.${this.energyService.form.energyServiceId}.selectLabel`)
      }

      return ''
    }
  },
  watch: {
    strategyId() {
      this.sortActuatorGroupIds()
    },
    actuatorGroupIds() {
      this.sortActuatorGroupIds()
    }
  },
  created() {
    this.sortActuatorGroupIds()
  },
  methods: {
    sortActuatorGroupIds() {
      const tmp = this.actuatorGroupIds.map((id, i) => {
        return {
          id: id,
          isUsed: this.isActuatorGroupUsed(id)
        }
      })
      tmp.sort((a, b) => {
        if (!a.isUsed && b.isUsed) {
          return 1
        }
        if (a.isUsed && !b.isUsed) {
          return -1
        }
        return a.id.localeCompare(b.id, undefined, { numeric: true })
      })

      this.sortedActuatorGroupIds = tmp.map((x) => x.id)
    },
    onShowActuatorGroup(gId) {
      this.actuatorGroup.details.actuatorGroupId = gId
      this.actuatorGroup.details.show = true
    },
    onNewActuatorGroup() {
      this.actuatorGroup.form.type = 'new'
      this.actuatorGroup.form.show = true
      this.actuatorGroup.form.actuatorGroupId = null
    },
    onCreateActuatorGroup(e) {
      this.$store
        .dispatch('emsEnergyServicesConfig/getSuitableEnergyServicesForNewActuatorGroupAndSafeAdd', {
          actuatorGroupId: e.params.actuatorGroupId,
          actuatorGroup: e.params.actuatorGroup
        })
        .then(() => {
          this.$log.debug(
            `Created new actuator-group ${e.params.actuatorGroupId} and received suitable energy services`
          )

          this.actuatorGroup.form.show = false
          this.actuatorGroup.form.type = ''
          this.actuatorGroup.form.actuatorGroupId = null
        })
        .catch((err) => {
          this.$log.warn(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })
    },
    onEditActuatorGroup(gId) {
      this.actuatorGroup.form.type = 'edit'
      this.actuatorGroup.form.actuatorGroupId = gId
      this.actuatorGroup.form.show = true
    },
    onUpdateActuatorGroup(e) {
      if (e.actuatorGroupId !== e.params.actuatorGroupId) {
        this.$store.commit('emsEnergyServicesConfig/UPDATE_ACTUATOR_GROUP_ID', {
          oldId: e.actuatorGroupId,
          newId: e.params.actuatorGroupId
        })
        this.$log.debug(`Commited UPDATE_ACTUATOR_GROUP_ID from ${e.actuatorGroupId} to ${e.params.actuatorGroupId}.`)
      }

      this.$store.commit('emsEnergyServicesConfig/ADD_ACTUATOR_GROUP', {
        id: e.params.actuatorGroupId,
        group: e.params.actuatorGroup
      })

      this.actuatorGroup.form.show = false
      this.actuatorGroup.form.type = ''
      this.actuatorGroup.form.actuatorGroupId = null
    },
    onDeleteActuatorGroup(gId) {
      this.actuatorGroup.confirm.type = 'delete'
      this.actuatorGroup.confirm.actuatorGroupId = gId
      this.actuatorGroup.confirm.show = true
    },
    async onConfirmActuatorGroup({ confirmed }) {
      if (confirmed && this.actuatorGroup.confirm.type === 'delete') {
        const noAutoDefault = await this.$store.dispatch('emsEnergyServicesConfig/safeRemoveActuatorGroup', {
          id: this.actuatorGroup.confirm.actuatorGroupId
        })
        this.$log.debug(
          `Deleted (safe remove) actuator-group ${this.actuatorGroup.confirm.actuatorGroupId}. Inited ${
            noAutoDefault ? 'NO' : 'SOME'
          } auto default actuator-group(s).`
        )

        if (!noAutoDefault) {
          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: AUTOHIDE_INFO_MS,
              type: 'info',
              title: this.$t('ems.energyService.actuatorGroup.confirmation.delete.autoDefault.title'),
              content: this.$t('ems.energyService.actuatorGroup.confirmation.delete.autoDefault.description')
            })
          )
        }

        // ensure consistency of strategies
        this.strategyIds.forEach(async (id) => {
          const autoAddedActuatorGroupIds = await this.$store.dispatch('emsEnergyServicesConfig/autoCompleteStrategy', {
            strategyId: id,
            force: false,
            skipForIecNodePrefix: this.chargePointUseUnrestricted ? null : 'ch'
          })
          this.$log.debug(
            `Could auto added actuator-group(s) ${autoAddedActuatorGroupIds?.join(', ')} to strategy ${id}.`
          )
          // this.toastAutoAddedActuatorGroups(autoAddedActuatorGroupIds, { strategyId: id })
        })
      }

      this.actuatorGroup.confirm.show = false
      this.actuatorGroup.confirm.actuatorGroupId = null
      this.actuatorGroup.confirm.type = ''
    },
    async onAutoCompleteStrategy() {
      const agIds = await this.$store.dispatch('emsEnergyServicesConfig/autoCompleteStrategy', {
        strategyId: this.strategyId,
        force: true,
        skipForIecNodePrefix: this.chargePointUseUnrestricted ? null : 'ch'
      })

      this.toastAutoAddedActuatorGroups(agIds, { force: true })
    },
    async onAddActuatorGroupToStrategy(gId) {
      const rsp = await this.$store.dispatch('emsEnergyServicesConfig/addActuatorGroupToStrategy', {
        strategyId: this.strategyId,
        actuatorGroupId: gId
      })

      this.toastDroppedActuatorGroups(rsp.droppedActuatorGroupIds)
      // currently disabled to avoid "spamming"
      // this.toastAutoAddedActuatorGroups(rsp.autoAddedActuatorGroupIds)
      this.$log.debug(`The following AG might be auto-added by auto-completion: ${rsp.autoAddedActuatorGroupIds}`)
    },
    async onDropActuatorGroupFromStrategy(gId) {
      const rsp = await this.$store.dispatch('emsEnergyServicesConfig/dropActuatorGroupFromStrategy', {
        strategyId: this.strategyId,
        actuatorGroupId: gId
      })

      // currently disabled to avoid "spamming"
      // this.toastAutoAddedActuatorGroups(rsp.autoAddedActuatorGroupIds)
      this.$log.debug(`The following AG might be auto-added by auto-completion: ${rsp.autoAddedActuatorGroupIds}`)
    },
    onEnergyServiceChosen(params) {
      this.energyService.form.actuatorGroupId = params.actuatorGroupId
      this.energyService.form.energyServiceId = params.energyServiceId
      this.energyService.form.show = true
    },
    onEnergyServiceConfirmed(params) {
      if (params.defaultPeakObserver) {
        this.$store.commit('emsEnergyServicesConfig/ADD_PEAK_OBSERVER', { observer: params.defaultPeakObserver })
        delete params.defaultPeakObserver
        this.$log.debug('Successfully added/updated the default peak observer.')
      }

      this.$store.commit('emsEnergyServicesConfig/ADD_ENERGY_SERVICE', params)
      this.$log.debug(
        `Successfully added/updated an energy-service "${this.energyService.form.energyServiceId}" for strategy "${params.strategyId}", and actuator-group "${params.actuatorGroupId}"`
      )

      this.energyService.form.show = false
    },
    onModalClose(show) {
      if (show) {
        return
      }

      if (this.actuatorGroup.details.show) {
        this.actuatorGroup.details.actuatorGroupId = null
      } else if (this.actuatorGroup.form.show) {
        this.actuatorGroup.form.type = ''
        this.actuatorGroup.form.actuatorGroupId = null
      } else if (this.energyService.form.show) {
        this.energyService.form.energyServiceId = this.getEnergyServiceId({
          strategyId: this.strategyId,
          actuatorGroupId: this.energyService.form.actuatorGroupId
        })
      }

      this.actuatorGroup.details.show = false
      this.actuatorGroup.form.show = false
      this.energyService.form.show = false
    },
    toastAutoAddedActuatorGroups(actuatorGroupIds, { strategyId = this.strategyId, force = false } = {}) {
      if (!actuatorGroupIds.length) {
        return
      }

      let tkey = ''
      if (force) {
        tkey = 'autoAdded'
      } else {
        tkey = 'autoAdd'
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: AUTOHIDE_INFO_MS,
          type: 'info',
          title: this.$t(`ems.energyService.actuatorGroup.${tkey}ToStrategy.title`),
          content: this.$t(`ems.energyService.actuatorGroup.${tkey}ToStrategy.description`, {
            strategyId,
            actuatorGroupIds: actuatorGroupIds.join(', ')
          })
        })
      )
    },
    toastDroppedActuatorGroups(actuatorGroupIds) {
      if (!actuatorGroupIds.length) {
        return
      }
      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: AUTOHIDE_INFO_MS,
          type: 'info',
          title: this.$t('ems.energyService.actuatorGroup.droppedFromStrategy.title'),
          content: this.$t('ems.energyService.actuatorGroup.droppedFromStrategy.description', {
            strategyId: this.strategyId,
            actuatorGroupIds: actuatorGroupIds.join(', ')
          })
        })
      )
    }
  }
}
</script>
