/**
 * @module view-helper/timeseries
 */

import moment from 'moment'
import i18n from '@/i18n'

/**
 * Takes a Papa Parse results object and returns validation results
 *
 * [Papa Parse]{@link https://www.papaparse.com/docs}) is used.
 *
 * @function
 *
 * @param {object} results (required) from [Papa Parse containing 'data', 'errors' and 'meta' objects]{@link https://www.papaparse.com/docs#results}
 *
 * @return {object} containing meta error information. Has properties `{boolean} hasError`, `{string} type`, and depending on `type` additional info.
 */
export function timeseriesCsvValidate(results) {
  const data = results.data
  const errors = results.errors
  const metaData = results.meta

  // Check for parsing errors
  if (errors.length !== 0) {
    return {
      hasError: true,
      type: 'invalidGeneric',
      errorType: errors[0].type,
      code: errors[0].code,
      row: errors[0].row + 2
    }
  }

  // Check validity of headers
  if (metaData.fields[0] !== 'timestamp') {
    return {
      hasError: true,
      type: 'invalidHeader',
      entryValue: metaData.fields[0]
    }
  }
  if (metaData.fields[1] !== 'value') {
    return {
      hasError: true,
      type: 'invalidHeader',
      entryValue: metaData.fields[1]
    }
  }

  // Check validity of entries
  for (const [index, row] of data.entries()) {
    // Check timestamp validity
    const ts = row.timestamp

    const isTsValid = moment(ts, 'X', true).isValid()

    if (!isTsValid) {
      return {
        hasError: true,
        type: 'invalidDate',
        entryValue: ts,
        row: index + 2
      }
    }

    // Check value validity
    const value = row.value
    const isValueValid = !isNaN(value) && value !== ''

    if (!isValueValid) {
      return {
        hasError: true,
        type: 'invalidValue',
        entryValue: value,
        row: index + 2
      }
    }
  }

  return {
    hasError: false
  }
}

export const getDayOfWeekSelectOpts = () => {
  const daysOfWeek = []

  for (let i = 1; i < 8; i++) {
    const dayOfWeek = {
      label: i18n.t(`main.weekdays.${i}`),
      value: i
    }

    daysOfWeek.push(dayOfWeek)
  }

  return daysOfWeek
}

export const START_OF_WEEK_OFFSET = 345600

export const TIME_UNITS_IN_SECONDS = {
  hour: 3600,
  day: 86400,
  week: 604800
}

export const UTC_OFFSET_OPTIONS = [
  {
    label: 'GMT -12:00',
    value: -12
  },
  {
    label: 'GMT -11:00',
    value: -11
  },
  {
    label: 'GMT -10:00',
    value: -10
  },
  {
    label: 'GMT -9:30',
    value: -9.5
  },
  {
    label: 'GMT -9:00',
    value: -9
  },
  {
    label: 'GMT -8:00',
    value: -8
  },
  {
    label: 'GMT -7:00',
    value: -7
  },
  {
    label: 'GMT -6:00',
    value: -6
  },
  {
    label: 'GMT -5:00',
    value: -5
  },
  {
    label: 'GMT -4:30',
    value: -4.5
  },
  {
    label: 'GMT -4:00',
    value: -4
  },
  {
    label: 'GMT -3:30',
    value: -3.5
  },
  {
    label: 'GMT -3:00',
    value: -3
  },
  {
    label: 'GMT -2:00',
    value: -2
  },
  {
    label: 'GMT -1:00',
    value: -1
  },
  {
    label: 'GMT +0',
    value: 0
  },
  {
    label: 'GMT +1:00',
    value: 1
  },
  {
    label: 'GMT +2:00',
    value: 2
  },
  {
    label: 'GMT +3:00',
    value: 3
  },
  {
    label: 'GMT +3:30',
    value: 3.5
  },
  {
    label: 'GMT +4:00',
    value: 4
  },
  {
    label: 'GMT +4:30',
    value: 4.5
  },
  {
    label: 'GMT +5:00',
    value: 5
  },
  {
    label: 'GMT +5:30',
    value: 5.5
  },
  {
    label: 'GMT +6:00',
    value: 6
  },
  {
    label: 'GMT +6:30',
    value: 6.5
  },
  {
    label: 'GMT +7:00',
    value: 7
  },
  {
    label: 'GMT +8:00',
    value: 8
  },
  {
    label: 'GMT +9:00',
    value: 9
  },
  {
    label: 'GMT +9:30',
    value: 9.5
  },
  {
    label: 'GMT +10:00',
    value: 10
  },
  {
    label: 'GMT +10:30',
    value: 10.5
  },
  {
    label: 'GMT +11:00',
    value: 11
  },
  {
    label: 'GMT +11:30',
    value: 11.5
  },
  {
    label: 'GMT +12:00',
    value: 12
  },
  {
    label: 'GMT +13:00',
    value: 13
  },
  {
    label: 'GMT +14:00',
    value: 14
  }
]
