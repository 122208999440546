import { render, staticRenderFns } from "./one-wire.vue?vue&type=template&id=5771dffc&scoped=true"
import script from "./one-wire.vue?vue&type=script&lang=js"
export * from "./one-wire.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5771dffc",
  null
  
)

export default component.exports