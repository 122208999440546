<template>
  <div>
    <CCardBody>
      <CAlert
        color="danger"
        close-button
        :show="showFailureAlert"
        @update:show="$emit('update:show-failure-alert', $event)"
      >
        {{ $t('ems.energyService.config.form.error') }}
      </CAlert>
      <slot></slot>
    </CCardBody>
    <!-- prettier-ignore -->
    <div class="d-flex justify-content-flex-start border-top mt-3 p-2">
      <CButton
        type="submit"
        size="sm"
        color="primary"
        @click="$emit('submit')"
      >
        {{ $t('main.confirmSetBtn') }}
      </CButton>
      <CButton
        v-if="showReset"
        class="ml-2"
        type="reset"
        size="sm"
        color="danger"
        @click="$emit('reset')"
      >
        {{ $t('main.resetBtn') }}
      </CButton>
      <CButton
        type="button"
        size="sm"
        color="secondary"
        class="ml-auto"
        @click="$emit('cancel')"
      >
        {{ $t('main.cancelBtn') }}
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EsLayoutForm',
  props: {
    showReset: {
      required: false,
      type: Boolean,
      default: true
    },
    showFailureAlert: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
