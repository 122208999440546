/** @module */

import { CoreConfigService } from '@/../lib/proto_js/ext/amperix/config_pb_service'
import {
  GetInverterModbusServerConfigRequest as GetInverterModbusServerConfigRequestPb,
  SetInverterModbusServerConfigRequest as SetInverterModbusServerConfigRequestPb
} from '@/../lib/proto_js/ext/amperix/config_pb'

import { evalServiceError } from '@/api/error-handling'
import { createUnaryCall } from '@/grpc'

/**
 * @function
 *
 * @return {promise} a `amperix.GetInverterModbusServerConfigResponsePb`
 */
export function getInverterModbusServerConfig() {
  const payload = new GetInverterModbusServerConfigRequestPb()

  const call = createUnaryCall({
    service: CoreConfigService,
    method: 'GetInverterModbusServerConfig',
    payload
  })

  return call.perform().then(evalServiceError)
}

/**
 * @function
 *
 * @return {promise} a `SetInverterModbusServerConfigResponsePb`
 */
export function setInverterModbusServerConfig(modbusServerConfigPb) {
  const payload = new SetInverterModbusServerConfigRequestPb()
  payload.setModbusServerCfg(modbusServerConfigPb)

  const call = createUnaryCall({
    service: CoreConfigService,
    method: 'SetInverterModbusServerConfig',
    payload
  })

  return call.perform().then(evalServiceError)
}
