import { between, integer, decimal, minValue, numeric, required, helpers } from 'vuelidate/lib/validators'
import { getIncludes, modbusTcpAddress, httpAddress } from '@/validations/custom-validators'

export default function (configMsgName, isOMI = false) {
  const struct = {
    cfg: {}
  }

  function getSocValidator() {
    return {
      required,
      numeric,
      socRange: between(0, 100)
    }
  }

  function getDurationInSecValidator() {
    return {
      required,
      integer,
      minValue: minValue(0)
    }
  }

  function minOnOffTimeInMinValidator() {
    return {
      required,
      integer,
      minValue: minValue(0)
    }
  }

  function integerGreaterZeroValidator() {
    return {
      required,
      integer,
      minValue: minValue(0)
    }
  }

  // min and max are inclusive
  function integerBetweenValidator({ min, max }) {
    return {
      required,
      integer,
      between: between(min, max)
    }
  }

  function numericGreaterZeroValidator() {
    return {
      required,
      decimal,
      minValue: minValue(0)
    }
  }

  function numericValidator() {
    return {
      required,
      decimal
    }
  }

  function minMaxSetCurrentValidator() {
    return {
      required,
      decimal,
      minValue: minValue(0)
    }
  }

  function modbusTcpValidators() {
    return {
      modbusTcpAddress: { modbusTcpAddress },
      modbusTcpSlaveId: {
        required,
        integer,
        modbusTcpSlaveIdRange: between(0, 255)
      }
    }
  }

  function modbusServerValidators() {
    return {
      modbusServerAddressOffset: {
        required,
        integer
      },
      modbusServerEndianness: singleSelectionValidators(),
      modbusServerSlaveId: {
        required,
        integer,
        modbusServerSlaveIdRange: between(0, 255)
      }
    }
  }

  function httpAddrValidator() {
    return {
      required,
      httpAddress
    }
  }

  function floatChargeValidators() {
    return {
      floatChargeLowSoc: getSocValidator(),
      floatChargeHighSoc: getSocValidator(),
      floatChargeW: {
        required,
        numeric,
        minValue: minValue(0)
      }
    }
  }

  function readIntervalsValidators() {
    return {
      readIntervalsInvPower: integerGreaterZeroValidator(),
      readIntervalsInvPhaseVoltage: integerGreaterZeroValidator(),
      readIntervalsInvGridFreq: integerGreaterZeroValidator(),
      readIntervalsBatVoltage: integerGreaterZeroValidator(),
      readIntervalsBatPower: integerGreaterZeroValidator(),
      readIntervalsBatCurrent: integerGreaterZeroValidator(),
      readIntervalsBatSoc: integerGreaterZeroValidator(),
      readIntervalsPv0Voltage: integerGreaterZeroValidator(),
      readIntervalsPv0Power: integerGreaterZeroValidator(),
      readIntervalsPv1Voltage: integerGreaterZeroValidator(),
      readIntervalsPv1Power: integerGreaterZeroValidator()
    }
  }

  function dplNameValidators() {
    return {
      dplName: {
        required,
        alphaNum: helpers.regex('alphaNum', /^\w+$/)
      }
    }
  }

  function singleSelectionValidators() {
    return {
      singleValue: { required },
      singleInvalidValue: {
        noInvalidValue: helpers.withParams({ type: 'noInvalidValue' }, (singleInvalidValue) => {
          return !singleInvalidValue
        })
      }
    }
  }

  const mtv = modbusTcpValidators()
  const msv = modbusServerValidators()
  const inverter = {}
  // without oneof(s)

  if (isOMI) {
    inverter.config4 = {
      inverterModbusTcpAddress: mtv.modbusTcpAddress,
      inverterModbusTcpSlaveId: mtv.modbusTcpSlaveId,
      // bmsTesvoltBms: Inverter.Config5, // see below
      // naBoxTesvoltNabox: Inverter.Config6, // see below
      // naBoxOmiNabox: Inverter.Config14, // see below
      // naBoxOmiNoGpioNabox: Inverter.Config15, // see below
      inverterFundamentalFrequencyMode: singleSelectionValidators(),
      inverterSymmetryMode: singleSelectionValidators(),
      inverterHarmonicMode: singleSelectionValidators(),
      modbusServerAddressOffset: msv.modbusServerAddressOffset,
      modbusServerEndianness: msv.modbusServerEndianness,
      modbusServerSlaveId: msv.modbusServerSlaveId
    }
  } else {
    inverter.config4 = {
      inverterModbusTcpAddress: mtv.modbusTcpAddress,
      inverterModbusTcpSlaveId: mtv.modbusTcpSlaveId,
      // bmsTesvoltBms: Inverter.Config5, // see below
      // naBoxTesvoltNabox: Inverter.Config6, // see below
      // naBoxOmiNabox: Inverter.Config14, // see below
      // naBoxOmiNoGpioNabox: Inverter.Config15, // see below
      inverterFundamentalFrequencyMode: singleSelectionValidators(),
      inverterSymmetryMode: singleSelectionValidators(),
      inverterHarmonicMode: singleSelectionValidators()
    }
  }

  inverter.config5 = Object.assign(modbusTcpValidators(), {
    baseFloatChargeLowSoc: getSocValidator(),
    baseFloatChargeHighSoc: getSocValidator()
  })
  inverter.config6 = {
    inputsContactor1: singleSelectionValidators(),
    // inputsContactor2: singleSelectionValidators(), // optional
    inputsVoltageHigh: singleSelectionValidators(),
    inputsVoltageLow: singleSelectionValidators(),
    inputsFrequencyHigh: singleSelectionValidators(),
    inputsFrequencyLow: singleSelectionValidators(),
    gridMeterRef: singleSelectionValidators(),
    inverterMeterRef: singleSelectionValidators(),
    outputsInhibit: singleSelectionValidators()
  }
  inverter.config14 = {
    contactor1Input: singleSelectionValidators(),
    contactor2Input: singleSelectionValidators()
  }
  inverter.config15 = {}

  switch (configMsgName) {
    case 'Inverter.Config1':
      struct.cfg = modbusTcpValidators()
      return struct
    case 'Inverter.Config3':
      struct.cfg = {
        address: { modbusTcpAddress },
        ...floatChargeValidators(),
        maxExternalSocTarget: getSocValidator(),
        ...readIntervalsValidators()
      }
      return struct
    case 'Inverter.Config4':
      struct.cfg = inverter.config4
      return struct
    case 'Inverter.Config4.bmsTesvoltBms':
      struct.cfg = Object.assign({}, inverter.config4, { bmsTesvoltBms: inverter.config5 })
      return struct
    case 'Inverter.Config4.naBoxTesvoltNabox':
      struct.cfg = Object.assign({}, inverter.config4, { naBoxTesvoltNabox: inverter.config6 })
      return struct
    case 'Inverter.Config4.naBoxOmiNabox':
      struct.cfg = Object.assign({}, inverter.config4, { naBoxOmiNabox: inverter.config14 })
      return struct
    case 'Inverter.Config4.naBoxOmiNoGpioNabox':
      struct.cfg = Object.assign({}, inverter.config4, { naBoxOmiNoGpioNabox: inverter.config15 })
      return struct
    case 'Inverter.Config4.bmsTesvoltBms.naBoxTesvoltNabox':
      struct.cfg = Object.assign(
        {},
        inverter.config4,
        { bmsTesvoltBms: inverter.config5 },
        { naBoxTesvoltNabox: inverter.config6 }
      )
      return struct
    case 'Inverter.Config5':
      struct.cfg = inverter.config5
      return struct
    case 'Inverter.Config8':
      struct.cfg = Object.assign(modbusTcpValidators(), floatChargeValidators())
      return struct
    case 'Inverter.Config9':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators(),
        modelVersion: {
          required,
          integer,
          includes: getIncludes([5, 6], { shouldType: 'integer' })
        },
        ...dplNameValidators()
      }
      return struct
    case 'Inverter.Config10':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators()
      }
      return struct
    case 'Inverter.Config11':
      struct.cfg = {
        ...modbusTcpValidators(),
        slaveIdGridMeter: mtv.modbusTcpSlaveId
      }
      return struct
    case 'Inverter.Config12':
      struct.cfg = {
        ...modbusTcpValidators()
      }
      return struct
    case 'Inverter.Config13':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators()
      }
      return struct
    case 'Inverter.Config16':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators(),
        wRtg: numericGreaterZeroValidator()
      }
      return struct
    case 'Inverter.Config17':
      struct.cfg = {
        modbusTcpAddress: { modbusTcpAddress }
      }
      return struct
    case 'Inverter.Config19':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...dplNameValidators()
      }
      return struct
    case 'Inverter.Config20':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators()
      }
      return struct
    case 'Inverter.Config21':
      struct.cfg = {
        ...modbusTcpValidators(),
        maxPower: integerGreaterZeroValidator()
      }
      return struct
    case 'Inverter.Config22':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...floatChargeValidators(),
        fallbackPower: numericValidator(),
        fallbackTimeout: integerGreaterZeroValidator()
      }
      return struct
    case 'Inverter.Config23':
      struct.cfg = {
        ...modbusTcpValidators(),
        defaultBatteryCfgFloatChargeLowSoc: getSocValidator(),
        defaultBatteryCfgFloatChargeHighSoc: getSocValidator(),
        defaultBatteryCfgFloatChargeW: {
          required,
          numeric,
          minValue: minValue(0)
        },
        defaultBatteryCfgEqualizationChargeW: {
          required,
          numeric,
          minValue: minValue(0)
        }
      }
      return struct
    case 'Meter.Config1':
      struct.cfg = modbusTcpValidators()
      return struct
    case 'Meter.Config2':
      struct.cfg = Object.assign(modbusTcpValidators(), {
        numChannels: {
          required,
          integer,
          numChannelsRange: between(1, 20)
        }
      })
      return struct
    case 'Meter.Config4':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...dplNameValidators()
      }
      return struct
    case 'Meter.Config5':
      struct.cfg = {
        device: singleSelectionValidators(),
        optionsForcedBaudRate: singleSelectionValidators()
      }
      return struct
    case 'Meter.Config6':
      struct.cfg = {
        listenAddress: { modbusTcpAddress },
        unitId: {
          required,
          integer,
          modbusTcpSlaveIdRange: between(0, 255)
        }
      }
      return struct
    case 'Wallbox.Config1':
      struct.cfg = {
        ...mtv,
        ...dplNameValidators()
      }
      return struct
    case 'Wallbox.Config2':
      struct.cfg = {
        ...mtv,
        phases: singleSelectionValidators()
      }
      return struct
    case 'Wallbox.Config3':
      struct.cfg = {
        address: mtv.modbusTcpAddress,
        phases: singleSelectionValidators()
      }
      return struct
    case 'Wallbox.Config4':
      struct.cfg = {
        host: httpAddrValidator(),
        sempPort: integerBetweenValidator({ min: 1, max: 65535 })
      }
      return struct
    case 'Wallbox.Config5':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...dplNameValidators()
      }
      return struct
    case 'Wallbox.Config6':
      struct.cfg = {
        ...mtv,
        ...dplNameValidators()
      }
      return struct
    case 'Wallbox.Config7':
      struct.cfg = {
        ...mtv,
        ...dplNameValidators(),
        numPhases: singleSelectionValidators()
      }
      return struct
    case 'Wallbox.Config8':
      struct.cfg = {
        hostIp: httpAddrValidator(),
        ...dplNameValidators(),
        minOnTime: integerGreaterZeroValidator(),
        minOffTime: minOnOffTimeInMinValidator(),
        ctlMaxSetCurrent: minMaxSetCurrentValidator(),
        ctlMinSetCurrent: minMaxSetCurrentValidator(),
        chMaxSetCurrent: minMaxSetCurrentValidator(),
        chMinSetCurrent: minMaxSetCurrentValidator()
      }
      return struct
    case 'Wallbox.Config9':
      struct.cfg = {
        address: { modbusTcpAddress },
        minOnTime: integerGreaterZeroValidator(),
        minOffTime: integerGreaterZeroValidator(),
        maxSetCurrent: minMaxSetCurrentValidator(),
        minSetCurrent: minMaxSetCurrentValidator()
      }
      return struct
    case 'Wallbox.Config10':
      struct.cfg = {
        ...modbusTcpValidators(),
        minOnTime: integerGreaterZeroValidator(),
        minOffTime: integerGreaterZeroValidator(),
        minCtlSetPowerW: integerGreaterZeroValidator(),
        maxCtlSetPowerW: integerGreaterZeroValidator()
      }
      return struct
    case 'Relay.Config1':
      struct.cfg = {
        output: singleSelectionValidators(),
        ...dplNameValidators(),
        minOnTimeSec: getDurationInSecValidator(),
        minOffTimeSec: getDurationInSecValidator()
      }
      return struct
    case 'Container.Config1':
      struct.cfg = {
        ...modbusTcpValidators(),
        ...dplNameValidators()
      }
      return struct

    default:
      return struct
  }
}
