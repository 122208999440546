<template>
  <CCard>
    <ApxCardHeader :icon="icon" :title="title || $t('fwupgrade.title')" />
    <CCardBody>
      <slot></slot>
    </CCardBody>
    <CCardFooter v-if="$slots.actions">
      <slot name="actions"></slot>
    </CCardFooter>
    <ConfirmationModal
      :visible="doConfirm"
      color="danger"
      size="lg"
      :title="confirmTitle"
      @update:confirmation="$emit('action:confirmation', $event)"
    >
      <slot name="confirmation-body"></slot>
    </ConfirmationModal>
  </CCard>
</template>

<script>
import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'

export default {
  name: 'FwUpgradeViewLayout',
  components: {
    ApxCardHeader,
    ConfirmationModal
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'cil-update'
    },
    doConfirm: {
      type: Boolean,
      default: false
    },
    confirmTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
