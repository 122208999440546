<template>
  <!-- prettier-ignore -->
  <CRow class="small mb-3">
    <CCol sm="12" md="6">
      {{ $t(`${tBasePath}.prefix.label`) }}:
    </CCol>
    <CCol sm="12" md="6">
      {{ params.prefix }}
    </CCol>
    <CCol sm="12" md="6">
      {{ $t(`${tBasePath}.filepath.label`) }}:
    </CCol>
    <CCol sm="12" md="6">
      {{ params.filepath }}
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'EsChaiScriptNodePreview',
  props: {
    params: {
      required: true,
      type: Object
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.chaiScript'
  }
}
</script>
