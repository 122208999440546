<template>
  <EsLayoutForm
    :show-failure-alert.sync="showFailureAlert"
    @cancel="$emit('cancel')"
    @reset="init({ reseted: true })"
    @submit="onSubmit"
  >
    <p style="white-space: pre-line">
      {{ $t(`${tBasePath}.description`) }}
    </p>
    <CRow>
      <!-- prettier-ignore -->
      <CCol col="12">
        <EvalTree />
      </CCol>
      <CCol col="12">
        <CInput
          type="number"
          :invalid-feedback="invalidFeedbackTolerance"
          :is-valid="$v.tolerance.$dirty ? !$v.tolerance.$error : null"
          :label="$t(`${tBasePath}.tolerance.label`)"
          :description="$t(`${tBasePath}.tolerance.description`)"
          :placeholder="0"
          :value.sync="$v.tolerance.$model"
        />
      </CCol>
    </CRow>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { formProps as props } from './form-props'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { CACHE_IDS } from '@/store/modules/cache'
import { required, minValue } from 'vuelidate/lib/validators'

import EvalTree from '@/components/ems/evalexp/eval-tree'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'
import { recursivelyValidateEvalTreeVue } from '@/view-helper/form-helpers'

export default {
  name: 'EsValueHystresisForm',
  components: {
    EvalTree,
    EsLayoutForm
  },
  props,
  data() {
    return {
      showFailureAlert: false,
      tolerance: 0.0
    }
  },
  computed: {
    ...mapGetters('emsEvalExpression', ['isValidTree']),
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry']),
    entry() {
      return this.getDecisionTreeEntry(this.idx)
    },
    invalidFeedbackTolerance() {
      if (!this.$v.tolerance.$error) {
        return ''
      }
      if (!this.$v.tolerance.required) {
        return this.$t(`${this.tBasePath}.tolerance.invalidFeedback.required`)
      } else if (!this.$v.tolerance.minValue) {
        return this.$t(`${this.tBasePath}.tolerance.invalidFeedback.minValue`)
      }

      return 'INVALID'
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.valueHysteresis'
  },
  mounted() {
    this.init()
  },
  methods: {
    async init({ reseted = false } = {}) {
      this.tolerance = this.entry.params.decisionNodeParams.tolerance
      this.showFailureAlert = false
      this.$store.commit('apiLoadingStatus/STARTED')

      await this.$store
        .dispatch('emsSensors/sensorInit', {})
        .then(() => {
          this.$log.debug('Succesfully fetched sensor names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })

      await this.$store
        .dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: false
        })
        .then(() => {
          this.$log.debug('Succesfully fetched timeseries names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })

      let hasCache = false
      if (!reseted) {
        hasCache = await this.$store.dispatch('cache/replayStoreState', {
          path: 'emsEvalExpression',
          id: this.$route.query['ee-cache'],
          vuexStore: this.$store
        })
      }
      if (!hasCache) {
        await this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromDecisionTree', {
          decisionTreeIdx: this.idx
        })
      }
    },
    onSubmit() {
      this.showFailureAlert = false
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      if (!this.isValidTree) {
        recursivelyValidateEvalTreeVue(this)
        this.showFailureAlert = true
        return
      }

      this.$store.commit('cache/CLEAR_CACHE', { id: CACHE_IDS.emsEvalExpression })
      this.$emit('confirmed', {
        idx: this.idx,
        decisionNodeType: 'valueHysteresis',
        params: {
          tolerance: this.tolerance
        }
      })
    }
  },

  validations: {
    tolerance: {
      required,
      minValue: (value) => value > 0 && minValue(0)(value)
    }
  }
}
</script>
