/**
 * Helper for the [ems-topology-config store]{@link module:store/ems-topology-config}
 * @module store/ems-topology-config-helper
 */

import {
  PhysicalDeviceType,
  PhysicalDevice as PhysicalDevicePb,
  Source as SourcePb
} from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import { getEnumStr } from '@/grpc/parser'

export const SUPPORTED_PHYSICAL_DEVICE_TYPES = Object.values(PhysicalDeviceType).sort()

/**
 * Takes a ems.topology.Source.iecId and returns a unique ID.
 *
 * @function
 *
 * @param {object} iecId (plain object)
 *
 * @return {string}
 */
export function iecIdToLiteral(iecId) {
  return [iecId.iecNodePrefix, iecId.logicalDeviceName, iecId.iecNodePostfix].join('.')
}

/**
 * Takes a ems.topology.Source.IecId literal and returns the IecId instance as plain JS object.
 *
 * @function
 *
 * @param {string} literal
 *
 * @return {object} plain `ems.topology.Source.IecId`
 */
export function literalToIecId(literal) {
  const init = literal.split('.')

  return new SourcePb.IecId([init[1], init[0], init[2]]).toObject()
}

/**
 * Takes the topology.PhysicalDeviceType `uint` of a topology.PhysicalDevice and returns the ENUM str.
 *
 * If `uint` is not found `UNSUPPORTED` is returned.
 *
 * @function
 *
 * @param {object} physDev (required) is the topology.PhysicalDevice (plain JS object)
 *
 * @return {string}
 */
export function humanizePhysDevType(physDev) {
  let type = getEnumStr(physDev.type, PhysicalDeviceType)
  if (!type) {
    type = 'UNSUPPORTED'
  }

  return type
}

export function physicalDeviceTypeToInt(physDevType) {
  if (typeof physDevType === 'string') {
    physDevType = PhysicalDeviceType[physDevType]
  }
  if (typeof physDevType === 'number' && physDevType >= 0) {
    return physDevType
  } else {
    throw new TypeError('The topology.PhysicalDevice.type is not supported.')
  }
}

/**
 * Takes parameters of the `ems.topology.PhysicalDevice` and returns a plain JS physical device. `PhysicalDevice.type`` might be humanized to string
 *
 * @function
 *
 * @param {object} params
 *
 * @return {object}
 */
export function newPhysicalDevice({ type, number }) {
  type = physicalDeviceTypeToInt(type)

  return new PhysicalDevicePb([type, number]).toObject()
}

/**
 * Takes a ems.topology.PhysicalDevice (plain object) and computes a uniqe ID.
 *
 * @function
 *
 * @param {object} arg
 *
 * @return {string}
 */
export function physicalDeviceToLiteral(pd) {
  if (typeof pd !== 'object') {
    throw new TypeError(`Invalid physical device ${pd}. Cannot extract literal.`)
  }

  return `${pd.type}.${pd.number}`
}

/**
 * Takse a literal of a `ems.topology.PhysicalDevice` and extracts the `ems.topology.PhysicalDevice` instance (plain object).
 *
 * @function
 *
 * @param {string|null} pdl
 *
 * @return {object|null}
 */
export function literalToPhysicalDevice(pdl) {
  if (!pdl) {
    return null
  }

  const nums = pdl.split('.').map((e) => parseInt(e))
  const opts = {
    type: nums[0],
    number: nums[1]
  }
  return newPhysicalDevice(opts)
}
