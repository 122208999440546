/** @module */

import { controllerRpc } from '@/api/ems/controller'

/**
 * gRPC unary call to list all EMS sensors.
 *
 * @function
 *
 * @return {promise}
 */
export function sensorList() {
  return controllerRpc('sensor_list')
}
