// source: ext/auth/auth_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_auth_algorithm_pb = require('../../ext/auth/algorithm_pb.js');
goog.object.extend(proto, ext_auth_algorithm_pb);
var ext_auth_credentials_pb = require('../../ext/auth/credentials_pb.js');
goog.object.extend(proto, ext_auth_credentials_pb);
var ext_auth_error_pb = require('../../ext/auth/error_pb.js');
goog.object.extend(proto, ext_auth_error_pb);
var ext_auth_principal_pb = require('../../ext/auth/principal_pb.js');
goog.object.extend(proto, ext_auth_principal_pb);
var ext_auth_token_pb = require('../../ext/auth/token_pb.js');
goog.object.extend(proto, ext_auth_token_pb);
var ext_auth_user_pb = require('../../ext/auth/user_pb.js');
goog.object.extend(proto, ext_auth_user_pb);
var ext_license_license_pb = require('../../ext/license/license_pb.js');
goog.object.extend(proto, ext_license_license_pb);
goog.exportSymbol('proto.de.mypowergrid.auth.CreateUserRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.CreateUserResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.CreateUserWithPUKRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.CreateUserWithPUKResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.DeleteLicenseRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.DeleteLicenseResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.DeleteUserRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.DeleteUserResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetAuthDataRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetAuthDataResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseIDRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseIDResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetLicenseResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetServerNonceRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.GetServerNonceResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.ListUsersRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.ListUsersResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.LoginRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.LoginResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.LogoutRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.LogoutResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateLicenseRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateLicenseResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateUserRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateUserResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateUserWithPUKRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UpdateUserWithPUKResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateLicenseRequest.displayName = 'proto.de.mypowergrid.auth.UpdateLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateLicenseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateLicenseResponse.displayName = 'proto.de.mypowergrid.auth.UpdateLicenseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.DeleteLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.DeleteLicenseRequest.displayName = 'proto.de.mypowergrid.auth.DeleteLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.DeleteLicenseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.DeleteLicenseResponse.displayName = 'proto.de.mypowergrid.auth.DeleteLicenseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseIDRequest.displayName = 'proto.de.mypowergrid.auth.GetLicenseIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseIDResponse.displayName = 'proto.de.mypowergrid.auth.GetLicenseIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseRequest.displayName = 'proto.de.mypowergrid.auth.GetLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseResponse.displayName = 'proto.de.mypowergrid.auth.GetLicenseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.displayName = 'proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetServerNonceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetServerNonceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetServerNonceRequest.displayName = 'proto.de.mypowergrid.auth.GetServerNonceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetServerNonceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetServerNonceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetServerNonceResponse.displayName = 'proto.de.mypowergrid.auth.GetServerNonceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.LoginRequest.displayName = 'proto.de.mypowergrid.auth.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.LoginResponse.displayName = 'proto.de.mypowergrid.auth.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.LogoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.LogoutRequest.displayName = 'proto.de.mypowergrid.auth.LogoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.LogoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.LogoutResponse.displayName = 'proto.de.mypowergrid.auth.LogoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.CreateUserRequest.displayName = 'proto.de.mypowergrid.auth.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.CreateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.CreateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.CreateUserResponse.displayName = 'proto.de.mypowergrid.auth.CreateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.CreateUserWithPUKRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.CreateUserWithPUKRequest.displayName = 'proto.de.mypowergrid.auth.CreateUserWithPUKRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.CreateUserWithPUKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.CreateUserWithPUKResponse.displayName = 'proto.de.mypowergrid.auth.CreateUserWithPUKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.ListUsersRequest.displayName = 'proto.de.mypowergrid.auth.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.auth.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.auth.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.ListUsersResponse.displayName = 'proto.de.mypowergrid.auth.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateUserRequest.displayName = 'proto.de.mypowergrid.auth.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateUserResponse.displayName = 'proto.de.mypowergrid.auth.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateUserWithPUKRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.displayName = 'proto.de.mypowergrid.auth.UpdateUserWithPUKRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UpdateUserWithPUKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.displayName = 'proto.de.mypowergrid.auth.UpdateUserWithPUKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.DeleteUserRequest.displayName = 'proto.de.mypowergrid.auth.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.DeleteUserResponse.displayName = 'proto.de.mypowergrid.auth.DeleteUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetAuthDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetAuthDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetAuthDataRequest.displayName = 'proto.de.mypowergrid.auth.GetAuthDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.GetAuthDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.GetAuthDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.GetAuthDataResponse.displayName = 'proto.de.mypowergrid.auth.GetAuthDataResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    license: jspb.Message.getFieldWithDefault(msg, 1, ""),
    algorithm: (f = msg.getAlgorithm()) && ext_auth_user_pb.HashedCredentials.Algorithm.toObject(includeInstance, f),
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    credentials: (f = msg.getCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateLicenseRequest;
  return proto.de.mypowergrid.auth.UpdateLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    case 2:
      var value = new ext_auth_user_pb.HashedCredentials.Algorithm;
      reader.readMessage(value,ext_auth_user_pb.HashedCredentials.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 3:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    case 4:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_auth_user_pb.HashedCredentials.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional string license = 1;
 * @return {string}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional HashedCredentials.Algorithm algorithm = 2;
 * @return {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.HashedCredentials.Algorithm} */ (
    jspb.Message.getWrapperField(this, ext_auth_user_pb.HashedCredentials.Algorithm, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Principal principal = 3;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Credentials credentials = 4;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.getCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateLicenseRequest.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateLicenseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseResponse}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateLicenseResponse;
  return proto.de.mypowergrid.auth.UpdateLicenseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseResponse}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateLicenseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateLicenseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseResponse} returns this
*/
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateLicenseResponse} returns this
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateLicenseResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.DeleteLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseRequest}
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.DeleteLicenseRequest;
  return proto.de.mypowergrid.auth.DeleteLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseRequest}
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.DeleteLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.DeleteLicenseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseResponse}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.DeleteLicenseResponse;
  return proto.de.mypowergrid.auth.DeleteLicenseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseResponse}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.DeleteLicenseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.DeleteLicenseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseResponse} returns this
*/
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.DeleteLicenseResponse} returns this
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.DeleteLicenseResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDRequest}
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseIDRequest;
  return proto.de.mypowergrid.auth.GetLicenseIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDRequest}
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    licenseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newUserRequired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseIDResponse;
  return proto.de.mypowergrid.auth.GetLicenseIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewUserRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getLicenseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewUserRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse} returns this
*/
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string license_id = 2;
 * @return {string}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.getLicenseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.setLicenseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool new_user_required = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.getNewUserRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseIDResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseIDResponse.prototype.setNewUserRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseRequest}
 */
proto.de.mypowergrid.auth.GetLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseRequest;
  return proto.de.mypowergrid.auth.GetLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseRequest}
 */
proto.de.mypowergrid.auth.GetLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    license: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseResponse}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseResponse;
  return proto.de.mypowergrid.auth.GetLicenseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseResponse}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseResponse} returns this
*/
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string license = 2;
 * @return {string}
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseResponse.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesRequest}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesRequest;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesRequest}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    licenseFeaturesList: jspb.Message.toObjectList(msg.getLicenseFeaturesList(),
    proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature;
      reader.readMessage(value,proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.deserializeBinaryFromReader);
      msg.addLicenseFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getLicenseFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabledInList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    uiHints: (f = msg.getUiHints()) && proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.toObject(includeInstance, f),
    restrictions: (f = msg.getRestrictions()) && proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.toObject(includeInstance, f),
    licenseTypeRestrictionsMap: (f = msg.getLicenseTypeRestrictionsMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Array<!proto.de.mypowergrid.license.LicenseType>} */ (reader.readPackedEnum());
      msg.setEnabledInList(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints;
      reader.readMessage(value,proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.deserializeBinaryFromReader);
      msg.setUiHints(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions;
      reader.readMessage(value,proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.deserializeBinaryFromReader);
      msg.setRestrictions(value);
      break;
    case 5:
      var value = msg.getLicenseTypeRestrictionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.deserializeBinaryFromReader, "", new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabledInList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getUiHints();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.serializeBinaryToWriter
    );
  }
  f = message.getRestrictions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.serializeBinaryToWriter
    );
  }
  f = message.getLicenseTypeRestrictionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.serializeBinaryToWriter);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    strategyTree: (f = msg.getStrategyTree()) && proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.toObject(includeInstance, f),
    electricVehicleCharger: (f = msg.getElectricVehicleCharger()) && proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree;
      reader.readMessage(value,proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.deserializeBinaryFromReader);
      msg.setStrategyTree(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger;
      reader.readMessage(value,proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.deserializeBinaryFromReader);
      msg.setElectricVehicleCharger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStrategyTree();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.serializeBinaryToWriter
    );
  }
  f = message.getElectricVehicleCharger();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxPermittedNodes: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPermittedNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxPermittedNodes();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 max_permitted_nodes = 1;
 * @return {number}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.prototype.getMaxPermittedNodes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree.prototype.setMaxPermittedNodes = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxPermittedSetpoints: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger;
  return proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPermittedSetpoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxPermittedSetpoints();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 max_permitted_setpoints = 1;
 * @return {number}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.prototype.getMaxPermittedSetpoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger.prototype.setMaxPermittedSetpoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional StrategyTree strategy_tree = 2;
 * @return {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.getStrategyTree = function() {
  return /** @type{?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.StrategyTree|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.setStrategyTree = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.clearStrategyTree = function() {
  return this.setStrategyTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.hasStrategyTree = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ElectricVehicleCharger electric_vehicle_charger = 3;
 * @return {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.getElectricVehicleCharger = function() {
  return /** @type{?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.ElectricVehicleCharger|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.setElectricVehicleCharger = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.clearElectricVehicleCharger = function() {
  return this.setElectricVehicleCharger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions.prototype.hasElectricVehicleCharger = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated de.mypowergrid.license.LicenseType enabled_in = 2;
 * @return {!Array<!proto.de.mypowergrid.license.LicenseType>}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.getEnabledInList = function() {
  return /** @type {!Array<!proto.de.mypowergrid.license.LicenseType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.license.LicenseType>} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.setEnabledInList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.de.mypowergrid.license.LicenseType} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.addEnabledIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.clearEnabledInList = function() {
  return this.setEnabledInList([]);
};


/**
 * optional UIHints ui_hints = 3;
 * @return {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.getUiHints = function() {
  return /** @type{?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.UIHints|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.setUiHints = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.clearUiHints = function() {
  return this.setUiHints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.hasUiHints = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Restrictions restrictions = 4;
 * @return {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.getRestrictions = function() {
  return /** @type{?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.setRestrictions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.clearRestrictions = function() {
  return this.setRestrictions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.hasRestrictions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, Restrictions> license_type_restrictions = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions>}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.getLicenseTypeRestrictionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.Restrictions));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature.prototype.clearLicenseTypeRestrictionsMap = function() {
  this.getLicenseTypeRestrictionsMap().clear();
  return this;};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LicenseFeature license_features = 2;
 * @return {!Array<!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature>}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.getLicenseFeaturesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature>} value
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} returns this
*/
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.setLicenseFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature}
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.addLicenseFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.LicenseFeature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.auth.GetLicenseFeaturesResponse} returns this
 */
proto.de.mypowergrid.auth.GetLicenseFeaturesResponse.prototype.clearLicenseFeaturesList = function() {
  return this.setLicenseFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetServerNonceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetServerNonceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetServerNonceRequest}
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetServerNonceRequest;
  return proto.de.mypowergrid.auth.GetServerNonceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetServerNonceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetServerNonceRequest}
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetServerNonceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetServerNonceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetServerNonceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetServerNonceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetServerNonceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    nonce: msg.getNonce_asB64(),
    signature: msg.getSignature_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetServerNonceResponse;
  return proto.de.mypowergrid.auth.GetServerNonceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetServerNonceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetServerNonceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetServerNonceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse} returns this
*/
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse} returns this
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes nonce = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes nonce = 2;
 * This is a type-conversion wrapper around `getNonce()`
 * @return {string}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNonce()));
};


/**
 * optional bytes nonce = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNonce()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNonce()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse} returns this
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.setNonce = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.GetServerNonceResponse} returns this
 */
proto.de.mypowergrid.auth.GetServerNonceResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientNonce: msg.getClientNonce_asB64(),
    serverNonce: msg.getServerNonce_asB64(),
    serverNonceSignature: msg.getServerNonceSignature_asB64(),
    algorithm: (f = msg.getAlgorithm()) && ext_auth_algorithm_pb.Algorithm.toObject(includeInstance, f),
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    authdata: msg.getAuthdata_asB64(),
    newCredentials: (f = msg.getNewCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.LoginRequest}
 */
proto.de.mypowergrid.auth.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.LoginRequest;
  return proto.de.mypowergrid.auth.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.LoginRequest}
 */
proto.de.mypowergrid.auth.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientNonce(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setServerNonce(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setServerNonceSignature(value);
      break;
    case 4:
      var value = new ext_auth_algorithm_pb.Algorithm;
      reader.readMessage(value,ext_auth_algorithm_pb.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 5:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAuthdata(value);
      break;
    case 7:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setNewCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getServerNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getServerNonceSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_auth_algorithm_pb.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getAuthdata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getNewCredentials();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_nonce = 1;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getClientNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_nonce = 1;
 * This is a type-conversion wrapper around `getClientNonce()`
 * @return {string}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getClientNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientNonce()));
};


/**
 * optional bytes client_nonce = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientNonce()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getClientNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientNonce()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.setClientNonce = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes server_nonce = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes server_nonce = 2;
 * This is a type-conversion wrapper around `getServerNonce()`
 * @return {string}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getServerNonce()));
};


/**
 * optional bytes server_nonce = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getServerNonce()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getServerNonce()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.setServerNonce = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes server_nonce_signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonceSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes server_nonce_signature = 3;
 * This is a type-conversion wrapper around `getServerNonceSignature()`
 * @return {string}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonceSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getServerNonceSignature()));
};


/**
 * optional bytes server_nonce_signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getServerNonceSignature()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getServerNonceSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getServerNonceSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.setServerNonceSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional Algorithm algorithm = 4;
 * @return {?proto.de.mypowergrid.auth.Algorithm}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.Algorithm} */ (
    jspb.Message.getWrapperField(this, ext_auth_algorithm_pb.Algorithm, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
*/
proto.de.mypowergrid.auth.LoginRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Principal principal = 5;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 5));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
*/
proto.de.mypowergrid.auth.LoginRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes authdata = 6;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getAuthdata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes authdata = 6;
 * This is a type-conversion wrapper around `getAuthdata()`
 * @return {string}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getAuthdata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAuthdata()));
};


/**
 * optional bytes authdata = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAuthdata()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getAuthdata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAuthdata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.setAuthdata = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional Credentials new_credentials = 7;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.getNewCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 7));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
*/
proto.de.mypowergrid.auth.LoginRequest.prototype.setNewCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LoginRequest} returns this
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.clearNewCredentials = function() {
  return this.setNewCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LoginRequest.prototype.hasNewCredentials = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    claims: (f = msg.getClaims()) && ext_auth_token_pb.Claims.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.LoginResponse}
 */
proto.de.mypowergrid.auth.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.LoginResponse;
  return proto.de.mypowergrid.auth.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.LoginResponse}
 */
proto.de.mypowergrid.auth.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new ext_auth_token_pb.Claims;
      reader.readMessage(value,ext_auth_token_pb.Claims.deserializeBinaryFromReader);
      msg.setClaims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getClaims();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_token_pb.Claims.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.LoginResponse} returns this
*/
proto.de.mypowergrid.auth.LoginResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LoginResponse} returns this
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Claims claims = 3;
 * @return {?proto.de.mypowergrid.auth.Claims}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.getClaims = function() {
  return /** @type{?proto.de.mypowergrid.auth.Claims} */ (
    jspb.Message.getWrapperField(this, ext_auth_token_pb.Claims, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Claims|undefined} value
 * @return {!proto.de.mypowergrid.auth.LoginResponse} returns this
*/
proto.de.mypowergrid.auth.LoginResponse.prototype.setClaims = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LoginResponse} returns this
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.clearClaims = function() {
  return this.setClaims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LoginResponse.prototype.hasClaims = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.LogoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.LogoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.LogoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LogoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.LogoutRequest}
 */
proto.de.mypowergrid.auth.LogoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.LogoutRequest;
  return proto.de.mypowergrid.auth.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.LogoutRequest}
 */
proto.de.mypowergrid.auth.LogoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LogoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LogoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.LogoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.LogoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.LogoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LogoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.LogoutResponse}
 */
proto.de.mypowergrid.auth.LogoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.LogoutResponse;
  return proto.de.mypowergrid.auth.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.LogoutResponse}
 */
proto.de.mypowergrid.auth.LogoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.LogoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.LogoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.LogoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.LogoutResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.LogoutResponse} returns this
*/
proto.de.mypowergrid.auth.LogoutResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.LogoutResponse} returns this
 */
proto.de.mypowergrid.auth.LogoutResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.LogoutResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && ext_auth_user_pb.HashedCredentials.Algorithm.toObject(includeInstance, f),
    claims: (f = msg.getClaims()) && ext_auth_token_pb.Claims.toObject(includeInstance, f),
    initialCredentials: (f = msg.getInitialCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest}
 */
proto.de.mypowergrid.auth.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.CreateUserRequest;
  return proto.de.mypowergrid.auth.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest}
 */
proto.de.mypowergrid.auth.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_user_pb.HashedCredentials.Algorithm;
      reader.readMessage(value,ext_auth_user_pb.HashedCredentials.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 2:
      var value = new ext_auth_token_pb.Claims;
      reader.readMessage(value,ext_auth_token_pb.Claims.deserializeBinaryFromReader);
      msg.setClaims(value);
      break;
    case 3:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setInitialCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_user_pb.HashedCredentials.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getClaims();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_auth_token_pb.Claims.serializeBinaryToWriter
    );
  }
  f = message.getInitialCredentials();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional HashedCredentials.Algorithm algorithm = 1;
 * @return {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.HashedCredentials.Algorithm} */ (
    jspb.Message.getWrapperField(this, ext_auth_user_pb.HashedCredentials.Algorithm, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Claims claims = 2;
 * @return {?proto.de.mypowergrid.auth.Claims}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.getClaims = function() {
  return /** @type{?proto.de.mypowergrid.auth.Claims} */ (
    jspb.Message.getWrapperField(this, ext_auth_token_pb.Claims, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.Claims|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserRequest.prototype.setClaims = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.clearClaims = function() {
  return this.setClaims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.hasClaims = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Credentials initial_credentials = 3;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.getInitialCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserRequest.prototype.setInitialCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.clearInitialCredentials = function() {
  return this.setInitialCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserRequest.prototype.hasInitialCredentials = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.CreateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.CreateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.CreateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.CreateUserResponse}
 */
proto.de.mypowergrid.auth.CreateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.CreateUserResponse;
  return proto.de.mypowergrid.auth.CreateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.CreateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.CreateUserResponse}
 */
proto.de.mypowergrid.auth.CreateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.CreateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.CreateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.CreateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.CreateUserResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserResponse} returns this
*/
proto.de.mypowergrid.auth.CreateUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserResponse} returns this
 */
proto.de.mypowergrid.auth.CreateUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.CreateUserWithPUKRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    puk: msg.getPuk_asB64(),
    algorithm: (f = msg.getAlgorithm()) && ext_auth_user_pb.HashedCredentials.Algorithm.toObject(includeInstance, f),
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    credentials: (f = msg.getCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.CreateUserWithPUKRequest;
  return proto.de.mypowergrid.auth.CreateUserWithPUKRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPuk(value);
      break;
    case 2:
      var value = new ext_auth_user_pb.HashedCredentials.Algorithm;
      reader.readMessage(value,ext_auth_user_pb.HashedCredentials.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 3:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    case 4:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.CreateUserWithPUKRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPuk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_auth_user_pb.HashedCredentials.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes puk = 1;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getPuk = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes puk = 1;
 * This is a type-conversion wrapper around `getPuk()`
 * @return {string}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getPuk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPuk()));
};


/**
 * optional bytes puk = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPuk()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getPuk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPuk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.setPuk = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional HashedCredentials.Algorithm algorithm = 2;
 * @return {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.HashedCredentials.Algorithm} */ (
    jspb.Message.getWrapperField(this, ext_auth_user_pb.HashedCredentials.Algorithm, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Principal principal = 3;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Credentials credentials = 4;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.getCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
*/
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKRequest.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.CreateUserWithPUKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.CreateUserWithPUKResponse;
  return proto.de.mypowergrid.auth.CreateUserWithPUKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.CreateUserWithPUKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse} returns this
*/
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.CreateUserWithPUKResponse} returns this
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.CreateUserWithPUKResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.ListUsersRequest}
 */
proto.de.mypowergrid.auth.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.ListUsersRequest;
  return proto.de.mypowergrid.auth.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.ListUsersRequest}
 */
proto.de.mypowergrid.auth.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.auth.ListUsersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    ext_auth_token_pb.Claims.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse}
 */
proto.de.mypowergrid.auth.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.ListUsersResponse;
  return proto.de.mypowergrid.auth.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse}
 */
proto.de.mypowergrid.auth.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new ext_auth_token_pb.Claims;
      reader.readMessage(value,ext_auth_token_pb.Claims.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      ext_auth_token_pb.Claims.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse} returns this
*/
proto.de.mypowergrid.auth.ListUsersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse} returns this
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Claims users = 2;
 * @return {!Array<!proto.de.mypowergrid.auth.Claims>}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.auth.Claims>} */ (
    jspb.Message.getRepeatedWrapperField(this, ext_auth_token_pb.Claims, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.auth.Claims>} value
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse} returns this
*/
proto.de.mypowergrid.auth.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.auth.Claims=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.auth.Claims}
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.auth.Claims, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.auth.ListUsersResponse} returns this
 */
proto.de.mypowergrid.auth.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPrincipal: (f = msg.getCurrentPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    newPrincipal: (f = msg.getNewPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    currentCredentials: (f = msg.getCurrentCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f),
    newCredentials: (f = msg.getNewCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateUserRequest;
  return proto.de.mypowergrid.auth.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setCurrentPrincipal(value);
      break;
    case 2:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setNewPrincipal(value);
      break;
    case 3:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setCurrentCredentials(value);
      break;
    case 4:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setNewCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPrincipal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getNewPrincipal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getCurrentCredentials();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
  f = message.getNewCredentials();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional Principal current_principal = 1;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.getCurrentPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.setCurrentPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.clearCurrentPrincipal = function() {
  return this.setCurrentPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.hasCurrentPrincipal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Principal new_principal = 2;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.getNewPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.setNewPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.clearNewPrincipal = function() {
  return this.setNewPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.hasNewPrincipal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Credentials current_credentials = 3;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.getCurrentCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.setCurrentCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.clearCurrentCredentials = function() {
  return this.setCurrentCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.hasCurrentCredentials = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Credentials new_credentials = 4;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.getNewCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.setNewCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.clearNewCredentials = function() {
  return this.setNewCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserRequest.prototype.hasNewCredentials = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateUserResponse}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateUserResponse;
  return proto.de.mypowergrid.auth.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateUserResponse}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserResponse} returns this
*/
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserResponse} returns this
 */
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    puk: msg.getPuk_asB64(),
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    newCredentials: (f = msg.getNewCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateUserWithPUKRequest;
  return proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPuk(value);
      break;
    case 2:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    case 3:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setNewCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPuk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getNewCredentials();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes puk = 1;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.getPuk = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes puk = 1;
 * This is a type-conversion wrapper around `getPuk()`
 * @return {string}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.getPuk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPuk()));
};


/**
 * optional bytes puk = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPuk()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.getPuk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPuk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.setPuk = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Principal principal = 2;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Credentials new_credentials = 3;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.getNewCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} returns this
*/
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.setNewCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKRequest} returns this
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.clearNewCredentials = function() {
  return this.setNewCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKRequest.prototype.hasNewCredentials = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UpdateUserWithPUKResponse;
  return proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse} returns this
*/
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.UpdateUserWithPUKResponse} returns this
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.UpdateUserWithPUKResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.DeleteUserRequest}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.DeleteUserRequest;
  return proto.de.mypowergrid.auth.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.DeleteUserRequest}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
};


/**
 * optional Principal principal = 1;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.DeleteUserRequest} returns this
*/
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.DeleteUserRequest} returns this
 */
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.DeleteUserRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.DeleteUserResponse}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.DeleteUserResponse;
  return proto.de.mypowergrid.auth.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.DeleteUserResponse}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.DeleteUserResponse} returns this
*/
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.DeleteUserResponse} returns this
 */
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.DeleteUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetAuthDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetAuthDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientNonce: msg.getClientNonce_asB64(),
    serverNonce: msg.getServerNonce_asB64(),
    algorithm: (f = msg.getAlgorithm()) && ext_auth_algorithm_pb.Algorithm.toObject(includeInstance, f),
    principal: (f = msg.getPrincipal()) && ext_auth_principal_pb.Principal.toObject(includeInstance, f),
    credentials: (f = msg.getCredentials()) && ext_auth_credentials_pb.Credentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetAuthDataRequest;
  return proto.de.mypowergrid.auth.GetAuthDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetAuthDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientNonce(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setServerNonce(value);
      break;
    case 3:
      var value = new ext_auth_algorithm_pb.Algorithm;
      reader.readMessage(value,ext_auth_algorithm_pb.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 4:
      var value = new ext_auth_principal_pb.Principal;
      reader.readMessage(value,ext_auth_principal_pb.Principal.deserializeBinaryFromReader);
      msg.setPrincipal(value);
      break;
    case 5:
      var value = new ext_auth_credentials_pb.Credentials;
      reader.readMessage(value,ext_auth_credentials_pb.Credentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetAuthDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetAuthDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getServerNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_auth_algorithm_pb.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getPrincipal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_auth_principal_pb.Principal.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_auth_credentials_pb.Credentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_nonce = 1;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getClientNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_nonce = 1;
 * This is a type-conversion wrapper around `getClientNonce()`
 * @return {string}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getClientNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientNonce()));
};


/**
 * optional bytes client_nonce = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientNonce()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getClientNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientNonce()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.setClientNonce = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes server_nonce = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getServerNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes server_nonce = 2;
 * This is a type-conversion wrapper around `getServerNonce()`
 * @return {string}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getServerNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getServerNonce()));
};


/**
 * optional bytes server_nonce = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getServerNonce()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getServerNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getServerNonce()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.setServerNonce = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Algorithm algorithm = 3;
 * @return {?proto.de.mypowergrid.auth.Algorithm}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.Algorithm} */ (
    jspb.Message.getWrapperField(this, ext_auth_algorithm_pb.Algorithm, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
*/
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Principal principal = 4;
 * @return {?proto.de.mypowergrid.auth.Principal}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getPrincipal = function() {
  return /** @type{?proto.de.mypowergrid.auth.Principal} */ (
    jspb.Message.getWrapperField(this, ext_auth_principal_pb.Principal, 4));
};


/**
 * @param {?proto.de.mypowergrid.auth.Principal|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
*/
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.setPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.clearPrincipal = function() {
  return this.setPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.hasPrincipal = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Credentials credentials = 5;
 * @return {?proto.de.mypowergrid.auth.Credentials}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.getCredentials = function() {
  return /** @type{?proto.de.mypowergrid.auth.Credentials} */ (
    jspb.Message.getWrapperField(this, ext_auth_credentials_pb.Credentials, 5));
};


/**
 * @param {?proto.de.mypowergrid.auth.Credentials|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
*/
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataRequest} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetAuthDataRequest.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.GetAuthDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.GetAuthDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_auth_error_pb.Error.toObject(includeInstance, f),
    authdata: msg.getAuthdata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataResponse}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.GetAuthDataResponse;
  return proto.de.mypowergrid.auth.GetAuthDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.GetAuthDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataResponse}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_error_pb.Error;
      reader.readMessage(value,ext_auth_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAuthdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.GetAuthDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.GetAuthDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getAuthdata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.auth.Error}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.auth.Error} */ (
    jspb.Message.getWrapperField(this, ext_auth_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Error|undefined} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataResponse} returns this
*/
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.GetAuthDataResponse} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes authdata = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.getAuthdata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes authdata = 2;
 * This is a type-conversion wrapper around `getAuthdata()`
 * @return {string}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.getAuthdata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAuthdata()));
};


/**
 * optional bytes authdata = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAuthdata()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.getAuthdata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAuthdata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.GetAuthDataResponse} returns this
 */
proto.de.mypowergrid.auth.GetAuthDataResponse.prototype.setAuthdata = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


goog.object.extend(exports, proto.de.mypowergrid.auth);
