/** @module i18n */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import logger from '@/logger'

Vue.use(VueI18n)

export const DEFAULT_LOCALE = 'en'

function loadLocaleMessages() {
  const locales = require.context('../../config/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  const keysOfMain = []
  const keysOfPartial = []
  const matcherMain = /([A-Za-z0-9-_]+)\.json$/
  const matcherPartial = /_[\w,-]+\.([A-Za-z0-9-_]+).json$/
  locales.keys().forEach((key) => {
    if (key.match(matcherPartial)) {
      keysOfPartial.push(key)
    } else if (key.match(matcherMain)) {
      keysOfMain.push(key)
    }
  })

  keysOfMain.forEach((key) => {
    const matched = key.match(matcherMain)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  // sort to make sure, that 'deeper nested' partials comes last
  keysOfPartial.sort((a, b) => {
    const getPrefixedPath = (k) => {
      const path = locales(k)._partialPath.split('.')
      path.unshift(path.length)

      return path.join('.')
    }

    return getPrefixedPath(a).localeCompare(getPrefixedPath(b))
  })

  keysOfPartial.forEach((key) => {
    const matched = key.match(matcherPartial)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const translations = locales(key)
      const path = translations._partialPath.split('.')
      if (!path) {
        return
      }
      delete translations._partialPath
      let m = messages[locale]
      path.forEach((p) => {
        if (!m[p]) {
          m[p] = {}
        }
        m = m[p]
      })
      Object.assign(m, translations)
    }
  })

  return messages
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || DEFAULT_LOCALE,
  messages: loadLocaleMessages()
})

/**
 * Trailing modifieres, e.g. `-US`, are allowed.
 * I.e. `en-US`, `en-GB` are supported.
 *
 * @function
 *
 * @param {string} locale
 *
 * @return {Boolean}
 */
export function isLocaleSupported(locale) {
  const reg = new RegExp(`^(${i18n.availableLocales.join('|')})\\w*`)
  const supported = reg.test(locale)
  if (!supported) {
    logger.warn(`Lang ${locale} is not supported. Using default ${DEFAULT_LOCALE}.`)
  }

  return supported
}

/**
 * @function
 *
 * @param {vue-router-route} route
 * @param {window.navigatore} windowNavigator (of Browser)
 *
 * @return {string} the locale
 */
export function autoDetectLocale({ route, windowNavigator }) {
  let locale

  if (route) {
    locale = route.query.locale
  }
  if (locale) {
    return locale
  }

  if (windowNavigator) {
    locale = windowNavigator.language
  }

  if (locale) {
    return locale
  }

  return DEFAULT_LOCALE
}

export default i18n
