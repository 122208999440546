<template>
  <div>
    <div v-if="energyServiceId === energyServiceNoneId">
      <p>
        {{ $t('ems.energyService.config.form._no-energy-service.info') }}
      </p>
    </div>
    <EsTargetPowerForm
      v-if="energyServiceId === 'targetPower'"
      :init-values="initValues"
      :init-preselect="initPreselect"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsGenerationPeakShavingForm
      v-else-if="energyServiceId === 'generationPeakShaving'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    >
    </EsGenerationPeakShavingForm>
    <EsRecordPowerMeasurementForm
      v-else-if="energyServiceId === 'recordedPowerMeasurementPeakShaving'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsOffGridForm
      v-else-if="energyServiceId === 'offGrid'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsEvalExpressionForm
      v-else-if="energyServiceId === 'evalExpression'"
      :init-values="initValues"
      @reset="onReset"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsDigitalSwitchForm
      v-else-if="energyServiceId === 'digitalOutputSwitchCondition'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsPvCurtailmentForm
      v-else-if="energyServiceId === 'pvCurtailment'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsOffGridTargetSocGenerationCurtailmentForm
      v-else-if="energyServiceId === 'offGridTargetSocGenerationCurtailment'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
    <EsOffGridBatteryProtectionForm
      v-else-if="energyServiceId === 'offGridBatteryProtection'"
      :init-values="initValues"
      @cancel="onCancel"
      @submit="onConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EsDigitalSwitchForm from '@/components/ems/energy-services/energy-service-forms/es-digital-switch-form'
import EsEvalExpressionForm from '@/components/ems/energy-services/energy-service-forms/es-eval-expression-form'
import EsGenerationPeakShavingForm from '@/components/ems/energy-services/energy-service-forms/es-generation-peak-shaving-form'
import EsOffGridForm from '@/components/ems/energy-services/energy-service-forms/es-off-grid-form'
import EsRecordPowerMeasurementForm from '@/components/ems/energy-services/energy-service-forms/es-recorded-power-measurement-peak-shaving-form'
import EsTargetPowerForm from '@/components/ems/energy-services/energy-service-forms/es-target-power-form'
import EsPvCurtailmentForm from '@/components/ems/energy-services/energy-service-forms/es-pv-curtailment-form'
import EsOffGridTargetSocGenerationCurtailmentForm from '@/components/ems/energy-services/energy-service-forms/es-off-grid-target-soc-generation-curtailment-form'
import EsOffGridBatteryProtectionForm from '@/components/ems/energy-services/energy-service-forms/es-off-grid-battery-protection-form'
import { EMS_ENERGY_SERVICE_NONE_ID, getNewEnergyService } from '@/view-helper/ems/ems-energy-service-helper'

export default {
  name: 'EmsEsEnergyServiceForm',
  components: {
    EsEvalExpressionForm,
    EsDigitalSwitchForm,
    EsGenerationPeakShavingForm,
    EsOffGridForm,
    EsRecordPowerMeasurementForm,
    EsTargetPowerForm,
    EsPvCurtailmentForm,
    EsOffGridTargetSocGenerationCurtailmentForm,
    EsOffGridBatteryProtectionForm
  },
  props: {
    strategyId: {
      required: true,
      type: String
    },
    actuatorGroupId: {
      required: true,
      type: String
    },
    energyServiceId: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getEnergyServicePreselection', 'getEnergyServiceParams']),
    initValues() {
      return this.getEnergyServiceParams({
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId,
        energyServiceId: this.energyServiceId
      })
    },
    initPreselect() {
      return this.getEnergyServicePreselection({ strategyId: this.strategyId, actuatorGroupId: this.actuatorGroupId })
    }
  },
  created() {
    this.energyServiceNoneId = EMS_ENERGY_SERVICE_NONE_ID

    // EsEvalExpressionForm will not init via `props`, but via `$store`
    if (this.energyServiceId === 'evalExpression') {
      this.initEvalExpression()
    }
  },
  methods: {
    initEvalExpression() {
      this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromStrategy', {
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId
      })
    },
    onReset() {
      if (this.energyServiceId === 'evalExpression') {
        this.initEvalExpression()
      }
    },
    onCancel() {
      this.$emit('energy-service-canceled')
    },
    onConfirm(payload) {
      this.$log.debug('Confirmed EMS energy service config.', payload)
      const es = getNewEnergyService()
      if (this.energyServiceId === EMS_ENERGY_SERVICE_NONE_ID) {
        return
      } else {
        es[this.energyServiceId] = payload.esParams
      }
      const params = {
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId,
        energyService: es,
        energyServicePreselection: payload.esPreselection,
        defaultPeakObserver: payload.defaultPeakObserver
      }

      this.$emit('energy-service-confirmed', params)
    }
  }
}
</script>
