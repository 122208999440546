/**
 *
 * @module store/ems-timeseries
 */

import logger from '@/logger'
import Vue from 'vue'
import moment from 'moment'

import { InterpolationType as InterpolationTypePb } from '@/../lib/proto_js/ext/ems/controller/controller_pb'
import { getEnumStr } from '@/grpc/parser'
import * as apiTs from '@/api/ems/timeseries'

const SUPPORTED_TYPES = ['TIMESWITCH', 'GENERIC']

/**
 * Timeseries store for named EMS timeseries.
 *
 * Stored are timestamp-values, interpolation (`string`), periodicity (in seconds `integer`), touched (`boolean`)
 *
 * - `touched` tracks if a timeseries was updated somehow
 *
 * Each key, has to be an EMS timeseries name.
 *
 *
 */
const state = () => {
  return {
    timestampValues: {},
    interpolation: {},
    periodicity: {},
    touched: {},
    type: {}
  }
}

/**
 * Getters for the EMS timeseries store.
 *
 * - `timeseriesNames` is a asc-sorted list of Names (IDs) of all timeserieses
 * - `hasTimeseries` allows to check by name, if a timeseries already exists
 */
const getters = {
  timeseriesNames(state) {
    return Object.keys(state.timestampValues).sort((a, b) => {
      return a.localeCompare(b, undefined, { numeric: true })
    })
  },
  timeseriesTouched(state, getters) {
    return getters.timeseriesNames.map((n) => {
      return state.touched[n]
    })
  },
  hasTimeseries(state) {
    return (name) => {
      return state.timestampValues[name] !== undefined
    }
  },
  autoDetermineType(state) {
    return (name) => {
      const tsv = state.timestampValues[name]

      if (!tsv || tsv.length === 0) {
        return 'GENERIC'
      }

      for (const element of tsv) {
        if (!(element.value === 1 || element.value === 0)) {
          return 'GENERIC'
        }
      }
      return 'TIMESWITCH'
    }
  },
  getTimestampValues(state) {
    return (name) => {
      return state.timestampValues[name]
    }
  },
  getSortedTimestampValues(state) {
    // Periodicity is in seconds, t0 defines the reference time, utcOffset is in hours
    return (name, { periodicity = 0, utcOffset = 0 }) => {
      let tsvs = state.timestampValues[name]

      if (!tsvs) {
        return tsvs
      }

      if (!periodicity) {
        return tsvs
      }

      tsvs = tsvs
        .map((x) => {
          x = Object.assign({}, x)
          x.originalTimestamp = x.timestamp

          if (x.timestamp < 0) {
            x.timestamp = x.timestamp + periodicity * -Math.floor(x.timestamp / periodicity)
          }
          return x
        })
        .sort((x, y) => {
          return x.timestamp - y.timestamp
        })

      // [t0, t0+peridiocity] is the casted periodical interval
      const t0 = moment().utcOffset(utcOffset).locale('de').startOf('week').unix()
      tsvs.forEach((x, i) => {
        const n = Math.floor((x.timestamp - t0) / periodicity)
        x.timestamp = x.timestamp - n * periodicity
      })

      return tsvs.sort((x, y) => {
        return x.timestamp - y.timestamp
      })
    }
  },
  getInterpolation(state) {
    return (name) => {
      return state.interpolation[name]
    }
  },
  getPeriodicity(state) {
    return (name) => {
      return state.periodicity[name]
    }
  },
  getType(state) {
    return (name) => {
      return state.type[name]
    }
  }
}

/**
 * Mutations for the EMS timeseries store.
 *
 * - `INIT_TIMESERIES` will init a new timeseries without timestamp-values (empty list), and optional `interpolation`, `periodicity`
 * - `REMOVE_TIMESERIES` will delete the `timestampValues`, `interpolation`, `periodicity` entries
 *
 */
const mutations = {
  INIT_TIMESERIES(state, { tsName, interpolation, periodicity, touched = false, type = 'GENERIC' }) {
    if (!tsName) {
      throw new TypeError('Paramter "tsName" cannot be blank.')
    }

    if (!SUPPORTED_TYPES.includes(type)) {
      throw new TypeError('Parameter "type" is not supported.')
    }

    interpolation = interpolationToStr(interpolation)
    Vue.set(state.timestampValues, tsName, [])
    Vue.set(state.interpolation, tsName, interpolation)
    Vue.set(state.periodicity, tsName, periodicity)
    Vue.set(state.touched, tsName, touched)
    Vue.set(state.type, tsName, type)
  },
  REMOVE_TIMESERIES(state, tsName) {
    if (!tsName) {
      throw new TypeError('Argument "tsName" cannot be blank.')
    }

    Vue.delete(state.timestampValues, tsName)
    Vue.delete(state.interpolation, tsName)
    Vue.delete(state.periodicity, tsName)
    Vue.delete(state.touched, tsName)
    Vue.delete(state.type, tsName)
  },
  CLEAR_ALL(state) {
    state.timestampValues = {}
    state.interpolation = {}
    state.periodicity = {}
    state.touched = {}
    state.type = {}
  },
  SET_TIMESTAMP_VALUES(state, { tsName, timestampValueList, touched = false }) {
    const tsSorted = timestampValueList.sort((x, y) => {
      return x.timestamp - y.timestamp
    })
    Vue.set(state.timestampValues, tsName, tsSorted)
    Vue.set(state.touched, tsName, touched)
  },
  ADD_TIMESTAMP_VALUE(state, { tsName, timestampValue }) {
    const tsValues = state.timestampValues[tsName]
    if (!tsValues) {
      logger.warn('TimestampValues does not exist.')
      return
    }
    if (typeof timestampValue.timestamp === 'string') {
      timestampValue.timestamp = parseFloat(timestampValue.timestamp)
    }
    const tsValueIndex = tsValues.findIndex((tsValue) => {
      return tsValue.timestamp >= timestampValue.timestamp
    })

    if (tsValueIndex === -1) {
      tsValues.push(timestampValue)
    } else if (tsValues[tsValueIndex].timestamp === timestampValue.timestamp) {
      tsValues.splice(tsValueIndex, 1, timestampValue)
    } else {
      tsValues.splice(tsValueIndex, 0, timestampValue)
    }

    state.touched[tsName] = true
  },
  REMOVE_TIMESTAMP_VALUE(state, { tsName, timestamp }) {
    const tsValues = state.timestampValues[tsName]
    if (!tsValues) {
      logger.warn('TimestampValues does not exist.')
      return
    }
    const tsValueIndex = tsValues.findIndex((tsValue) => {
      return tsValue.timestamp >= timestamp
    })

    if (tsValueIndex === -1) {
      return
    }

    tsValues.splice(tsValueIndex, 1)

    state.touched[tsName] = true
  },
  SET_INTERPOLATION(state, { tsName, interpolation, touched = false }) {
    interpolation = interpolationToStr(interpolation)

    Vue.set(state.interpolation, tsName, interpolation)
    if (state.touched[tsName] === undefined) {
      Vue.set(state.touched, tsName, touched)
    } else {
      state.touched[tsName] = touched
    }
  },
  SET_PERIODICITY(state, { tsName, periodicity, touched = false }) {
    Vue.set(state.periodicity, tsName, periodicity)
    if (state.touched[tsName] === undefined) {
      Vue.set(state.touched, tsName, touched)
    } else {
      state.touched[tsName] = touched
    }
  },
  SET_TOUCHED(state, { tsName, touched }) {
    if (state.touched[tsName] === undefined) {
      Vue.set(state.touched, tsName, touched)
    } else {
      state.touched[tsName] = touched
    }
  },
  SET_TYPE(state, { tsName, type }) {
    if (!SUPPORTED_TYPES.includes(type)) {
      throw new TypeError('Parameter "type" is not supported.')
    }

    if (state.type[tsName] === undefined) {
      Vue.set(state.type, tsName, type)
    } else {
      state.type[tsName] = type
    }
  }
}

/**
 * Action (API call) to fetch the list of EMS timeserieses.
 * And then to fetch the timestamp values for each (optionally).
 *
 * @function
 *
 * @param params
 * @param {boolean} params.withTimestampValues allows to enable/disable to fetch timeseries's timestamp-values
 * @param {boolean} params.clear defines if before init, the store should be cleared
 *
 * @return {promise} which resolves to the returned message of `ems.controller.timeseries_list`
 */
async function timeseriesesInit({ commit, dispatch }, { withTimestampValues = true, clear = false } = {}) {
  return apiTs.timeseriesList().then((msg) => {
    if (clear) {
      commit('CLEAR_ALL')
    }
    const calls = []
    msg.getTimeSeriesesList().forEach((ts) => {
      commit('INIT_TIMESERIES', {
        tsName: ts.getName(),
        interpolation: ts.getInterpolation(),
        periodicity: ts.getRepeatPeriodSeconds()
      })
      if (withTimestampValues) {
        calls.push(dispatch('timeseriesListData', { tsName: ts.getName() }))
      }
    })

    return Promise.all(calls).then(() => {
      return msg
    })
  })
}

/**
 * Action (API call) to remove a timeseries by its name.
 *
 * @function
 *
 * @param {string} tsName has to be the timeseries name
 *
 * @return {promise} which resolves the the returned message of `ems.controller.timeseries_remove`
 */
async function timeseriesRemove({ commit }, tsName) {
  if (!tsName || typeof tsName !== 'string') {
    throw new TypeError('Argument "tsName" cannot be blank and has to be a string.')
  }

  // per default swallows NotFound Errors
  return apiTs.timeseriesRemove(tsName).then((msg) => {
    commit('REMOVE_TIMESERIES', tsName)

    return msg
  })
}

/**
 * Action (API call) to fetch a timestampValueList
 *
 * @function
 *
 * @param params
 * @param {string} params.tsName is the "reset_peak_time_series_name" which equals the peak-observer name.
 *
 * @return {promise}
 */
async function timeseriesListData({ commit }, { tsName }) {
  return apiTs.timeseriesListData({ name: tsName }).then((msg) => {
    const timestampValueList = msg.toObject().timestampValueList
    commit('SET_TIMESTAMP_VALUES', { tsName, timestampValueList })

    const meta = msg.getMeta()
    if (meta) {
      commit('SET_INTERPOLATION', { tsName, interpolation: meta.getInterpolation() })
      commit('SET_PERIODICITY', { tsName, periodicity: meta.getRepeatPeriodSeconds() })
    }

    return msg
  })
}

/**
 * Action (API call) to update (and create) a (new) timeseries.
 *
 * @function
 *
 * @param params
 * @param {string} params.tsName (required) is the name of the timeseries.
 * @param {integer} params.periodicity (optional) is the periodicity of the timeseries. If not present, will be taken from the store.
 * @param {object} params.timestampValueList (optional) is the list of timestamp-value pairs. If not present, will be taken from the store.
 *
 * @return {promise}
 */
async function timeseriesUpdate({ commit, getters }, { tsName, interpolation, periodicity, timestampValueList }) {
  if (!interpolation) {
    interpolation = getters.getInterpolation(tsName) || 'LEFT_BOUNDED'
  }
  if (!periodicity) {
    periodicity = getters.getPeriodicity(tsName) || 0
  }
  if (!timestampValueList) {
    timestampValueList = getters.getTimestampValues(tsName) || []
  }

  return apiTs
    .timeseriesUpdate({ name: tsName, interpolation, repeatPeriodSeconds: periodicity, timestampValueList })
    .then((msg) => {
      commit('SET_TOUCHED', { tsName, touched: false })
      return msg
    })
}

const actions = {
  timeseriesesInit,
  timeseriesRemove,
  timeseriesListData,
  timeseriesUpdate
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// private
function interpolationToStr(intp) {
  if (typeof intp === 'number') {
    return getEnumStr(intp, InterpolationTypePb)
  }

  return intp
}
