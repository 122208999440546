/**
 * @module store/core_config
 */
import * as apiCoreConfig from '@/api/amperix/core-config'
import { ModbusServerCfg as ModbusServerCfgPb } from '@/../lib/proto_js/ext/amperix/config_pb'

// fields referencing /proto/ext/config/Common/core.proto: CoreCfg
const state = () => {
  const defaultState = {}
  const coreConfig = new CoreConfig({})

  for (const field in coreConfig) {
    defaultState[field] = coreConfig[field] // use defaults of CoreConfig class
  }
  return defaultState
}

const getters = {
  getField: (state) => {
    return (fieldName) => {
      return state[fieldName]
    }
  }
}

const mutations = {
  SET_CORE_CONFIG(state, { coreConfig }) {
    if (!(coreConfig instanceof CoreConfig)) return

    for (const field in coreConfig) {
      state[field] = coreConfig[field]
    }
  },
  SET_FIELD(state, { field, value }) {
    if (state[field] !== undefined) {
      state[field] = value
    }
  }
}

async function getInverterModbusServerConfig({ commit }, _) {
  return apiCoreConfig.getInverterModbusServerConfig().then((getInverterModbusServerResponsePb) => {
    const modbusServerConfig = getInverterModbusServerResponsePb.getModbusServerCfg().toObject()

    commit('SET_FIELD', {
      field: 'modbusServerListenAddress',
      value: modbusServerConfig.listenAddress
    })
  })
}

async function setInverterModbusServerConfig({ state }, _) {
  const modbusServerConfigPb = new ModbusServerCfgPb()
  modbusServerConfigPb.setListenAddress(state.modbusServerListenAddress)
  return apiCoreConfig.setInverterModbusServerConfig(modbusServerConfigPb)
}

export default {
  namespaced: true,
  state,
  getters,
  actions: {
    getInverterModbusServerConfig,
    setInverterModbusServerConfig
  },
  mutations
}

export function CoreConfig({ modbusServerListenAddress = null } = {}) {
  this.modbusServerListenAddress = modbusServerListenAddress
}
