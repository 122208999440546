// JS
import Vue from 'vue'
import CoreuiVue from '@coreui/vue-pro'
import Vuelidate from 'vuelidate'
import VueLoading from 'vue-loading-template'

import logger from '@/logger'
import router from '@/router'
import store from '@/store'
import { iconsSet as icons } from '@/assets/icons/icons'
import App from './app'
import i18n, { autoDetectLocale } from '@/i18n'
import { formatToLocalDatetime } from '@/view-helper/filters/datetime-formatter'
import Multiselect from 'vue-multiselect'
import VCalendar from 'v-calendar'
import VuePapaParse from 'vue-papa-parse'

window.VERSION = process.env.VUE_APP_VERSION
Vue.filter('formatToLocalDatetime', formatToLocalDatetime)
Vue.config.productionTip = false
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(Vuelidate)
Vue.use(VueLoading) // for options see https://github.com/jkchao/vue-loading
Vue.use(VCalendar)
Vue.use(VuePapaParse)
// eslint-disable-next-line
Vue.component('multiselect', Multiselect)

new Vue({
  router,
  store,
  icons,
  i18n,
  beforeCreate: function (x) {
    let locale

    // the user store is cached in local storage
    // for a logged-in user we should preserve locale-settings during page-reload
    try {
      if (this.$store.getters['user/username']) {
        locale = this.$store.state.user.locale
      }
    } catch (err) {
      logger.error(err)
    }

    if (!locale) {
      locale = autoDetectLocale({
        route: this.$route,
        windowNavigator: window.navigator
      })
    }

    this.$store.commit('user/SET_LOCALE', locale)
  },
  render: (h) => h(App)
}).$mount('#app')
