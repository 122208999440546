/** @module */

import { createUnaryCall } from '@/grpc'
import * as VersionPb from '@/../lib/proto_js/ext/sherlock/version_pb'
import { VersionService } from '@/../lib/proto_js/ext/sherlock/version_pb_service'
import { evalServiceError } from '@/api/error-handling'

export function fetchVersion() {
  const call = createUnaryCall({
    service: VersionService,
    method: 'GetAmperixVersionUnary',
    payload: new VersionPb.GetAmperixVersionRequest()
  })

  return call.perform().then(evalServiceError)
}

export function fetchSerialNo() {
  const call = createUnaryCall({
    service: VersionService,
    method: 'GetAmperixSerialNo',
    payload: new VersionPb.GetAmperixSerialNoRequest()
  })

  return call.perform().then(evalServiceError)
}
