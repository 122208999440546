// package: de.mypowergrid.amperix
// file: ext/amperix/config.proto

var ext_amperix_config_pb = require("../../ext/amperix/config_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CoreConfigService = (function () {
  function CoreConfigService() {}
  CoreConfigService.serviceName = "de.mypowergrid.amperix.CoreConfigService";
  return CoreConfigService;
}());

CoreConfigService.GetInverterModbusServerConfig = {
  methodName: "GetInverterModbusServerConfig",
  service: CoreConfigService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_config_pb.GetInverterModbusServerConfigRequest,
  responseType: ext_amperix_config_pb.GetInverterModbusServerConfigResponse
};

CoreConfigService.SetInverterModbusServerConfig = {
  methodName: "SetInverterModbusServerConfig",
  service: CoreConfigService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_config_pb.SetInverterModbusServerConfigRequest,
  responseType: ext_amperix_config_pb.SetInverterModbusServerConfigResponse
};

exports.CoreConfigService = CoreConfigService;

function CoreConfigServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CoreConfigServiceClient.prototype.getInverterModbusServerConfig = function getInverterModbusServerConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CoreConfigService.GetInverterModbusServerConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CoreConfigServiceClient.prototype.setInverterModbusServerConfig = function setInverterModbusServerConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CoreConfigService.SetInverterModbusServerConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CoreConfigServiceClient = CoreConfigServiceClient;

