/** @module grpc/configure */

import config from '../../config/api/grpc.json'

/**
 * Supported options for building the gRPC-Web URL and mapping those to `window.location` object.
 */
export const URL_OPTS = {
  protocol: 'protocol',
  host: 'hostname',
  port: 'port',
  path: 'pathname'
}

/**
 * Builds the URL for gRPC-Web requests.
 * Will take the config from `ui/config/api/grpc.json`.
 * Passed options during function call will overwrite values specified in the config file.
 * Missing options will be replaced by the default auto-determined fallback.
 * Thereto, the `window.location` object is used.
 *
 * @function
 * @see [URL_OPTS]{@link module:grpc/configure.URL_OPTS}
 *
 * @param {object} opts allows to overwrite the configured and default options. For supported one see [URL_OPTS]{@link module:grpc/configure.URL_OPTS}.
 * @param {string} env (optional) defines the env which is used during parsing the grpc config file.
 *
 * @return {string} the URL as specified [here]{@link https://en.wikipedia.org/wiki/URL}
 */
export function getGrpcUrl(opts = {}, env) {
  const url = {}
  if (!env) {
    env = process.env.NODE_ENV
  }

  Object.assign(url, config[env])
  Object.assign(url, opts)
  determineDefaults(url)

  // prettier-ignore
  return `${url.protocol}://${url.host}${url.port ? ':' + url.port : ''}${url.path}`
}

/**
 * Reads the gRPC web options (`opts`) from the config file `ui/config/api/grpc.json`.
 *
 * Has fallbacks and is agnostic to the ENV.
 *
 * @function
 *
 * @param {object} opts (optional) are options to be merged (and overwrite) the config options.
 * @param {string} env (optional) defines the env which is used during parsing the grpc config file.
 *
 * @return {object} with options, e.g. `{boolean} withCredentials`
 */
export function getGrpcOpts(opts = {}, env) {
  const gRpcOpts = {
    withCredentials: false
  }

  if (!env) {
    env = process.env.NODE_ENV
  }

  Object.assign(gRpcOpts, config[env] ? config[env].opts : {}, opts)

  return gRpcOpts
}

// changes input
function determineDefaults(urlOpts) {
  for (const [k, m] of Object.entries(URL_OPTS)) {
    if (urlOpts[k] == null) {
      let opt = window.location[m]
      if (k === 'protocol' && /\w+:$/.test(opt)) {
        opt = opt.match(/(\w+):$/)[1]
      }
      // add missing preceding `/`
      if (k === 'path' && /^\w+.*/.test(opt)) {
        opt = '/' + opt
      }
      // rm trailing `/`
      if (k === 'path' && /.*\/$/) {
        opt = ''
      }
      urlOpts[k] = opt
    }
  }

  return urlOpts
}
