/**
 * Stores the current state and log of firmware-upgrader (fwupgrader)
 *
 * @module store/fwupgrader
 */

import Vue from 'vue'
import moment from 'moment'

import { parseFwUpgradeError } from '@/api/error-handling'
import * as apiUnary from '@/api/fwupgrader/unary'
import * as apiStream from '@/api/fwupgrader/stream'
import { isProto } from '@/grpc/protobuf/misc'
import { PollingUnaryCall } from '@/grpc/index'
import { getEnumStr } from '@/grpc/parser'
import logger from '@/logger'
import { State as StatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'
import * as NetworkPb from '@/../lib/proto_js/ext/amperix/network_pb'
import { NetworkService } from '@/../lib/proto_js/ext/amperix/network_pb_service'

const cache = {}
const retryCount = 3
const retryDelay = 5000 // in ms

function clearCache() {
  cache.countConsecutiveFailedPings = 0
  cache.stream = null
  cache.ping = null
}

/**
 * A state for the sensors.
 * Currently only a list of the names for it
 */
const state = () => {
  return {
    log: [],
    currentState: StatePb.StateKindCase.STATE_KIND_NOT_SET,
    currentVersion: '',
    availableVersion: '',
    currentErrorDetails: null,
    progressInPercent: 0,
    upgradeForbidden: false,
    forbiddenReason: ''
  }
}

function fwUpgraderStateToMessage(state) {
  const dt = moment.unix(state.timestamp.seconds + state.timestamp.nanos / (1000 * 1000 * 1000))

  let stateName = ''

  if (state.partiallyUpgraded) {
    stateName = 'partiallyUpgraded'
  } else if (state.error) {
    stateName = 'error'
  } else if (state.finalizeInProgress) {
    stateName = 'finalizeInProgress'
  } else if (state.rebooting) {
    stateName = 'rebooting'
  } else if (state.rollbackInProgress) {
    stateName = 'rollbackInProgress'
  } else if (state.upToDate) {
    stateName = 'upToDate'
  } else if (state.upgradeAvailable) {
    stateName = 'upgradeAvailable'
  } else if (state.upgradeInProgress) {
    stateName = 'upgradeInProgress'
  } else {
    stateName = 'unknown'
  }

  return { time: dt, state: stateName, message: state.message }
}

/**
 * Getters for the EMS sensor store.
 */
const getters = {
  log: (state) => {
    return state.log.map((element) => fwUpgraderStateToMessage(element))
  },
  fwUpgraderState: (state) => {
    return state.currentState
  },
  fwUpgraderStateAsStr: (state) => {
    return getEnumStr(state.currentState, StatePb.StateKindCase)
  },
  currentVersion: (state) => {
    return state.currentVersion
  },
  availableVersion: (state) => {
    return state.availableVersion
  },
  currentErrorCode: (state) => {
    if (!state.currentErrorDetails) {
      return null
    }

    return state.currentErrorDetails.code
  },
  currentErrorMsg: (state) => {
    if (!state.currentErrorDetails) {
      return null
    }

    return state.currentErrorDetails.msg
  },
  progressInPercent: (state) => {
    return state.progressInPercent
  },
  upgradeAvailable: (state) => {
    return state.currentVersion !== state.availableVersion && !state.upgradeForbidden
  },
  upgradeForbidden: (state) => {
    return state.upgradeForbidden
  },
  forbiddenReason: (state) => {
    return state.forbiddenReason
  },
  alreadyInitialized: (state) => {
    return !!(state.currentVersion && state.availableVersion)
  }
}

const mutations = {
  CLEAR_LOG(state) {
    Vue.set(state, 'log', [])
  },
  ADD_LOG(state, { entry }) {
    if (!isProto(entry) || !(entry instanceof StatePb)) {
      throw new TypeError('Not a PB de.mypowergrid.fwupgrade.State instance')
    }

    Vue.set(state.log, state.log.length, entry.toObject())
  },
  FORBID(state, { msg }) {
    state.forbiddenReason = msg
    state.upgradeForbidden = true
  },
  ALLOW(state) {
    state.forbiddenReason = ''
    state.upgradeForbidden = false
  },
  UPDATE_STATE(state, { entry }) {
    if (!isProto(entry) || !(entry instanceof StatePb)) {
      throw new TypeError('Not a PB de.mypowergrid.fwupgrade.State instance')
    }

    switch (entry.getStateKindCase()) {
      case StatePb.StateKindCase.STATE_KIND_NOT_SET:
        state.currentVersion = ''
        state.availableVersion = ''
        state.progressInPercent = 0
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.UP_TO_DATE:
        state.currentVersion = entry.getUpToDate().getInstalledVersion()
        state.availableVersion = entry.getUpToDate().getInstalledVersion()
        state.progressInPercent = 0
        break
      case StatePb.StateKindCase.UPGRADE_AVAILABLE:
        state.currentVersion = entry.getUpgradeAvailable().getInstalledVersion()
        state.availableVersion = entry.getUpgradeAvailable().getUpgradeBundleInfo().getVersion()
        state.progressInPercent = 0
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.UPGRADE_IN_PROGRESS:
        state.currentVersion = entry.getUpgradeInProgress().getOldVersion()
        state.availableVersion = entry.getUpgradeInProgress().getNewVersion()
        state.progressInPercent = entry.getUpgradeInProgress().getProgressPercentage()
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.REBOOTING:
        break
      case StatePb.StateKindCase.PARTIALLY_UPGRADED:
        state.currentVersion = entry.getPartiallyUpgraded().getOldVersion()
        state.availableVersion = entry.getPartiallyUpgraded().getNewVersion()
        state.progressInPercent = 0
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.ROLLBACK_IN_PROGRESS:
        state.currentVersion = entry.getRollbackInProgress().getOldVersion()
        state.availableVersion = entry.getRollbackInProgress().getNewVersion()
        state.progressInPercent = entry.getRollbackInProgress().getProgressPercentage()
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.FINALIZE_IN_PROGRESS:
        state.currentVersion = entry.getFinalizeInProgress().getOldVersion()
        state.availableVersion = entry.getFinalizeInProgress().getNewVersion()
        state.progressInPercent = entry.getFinalizeInProgress().getProgressPercentage()
        state.forbiddenReason = ''
        state.upgradeForbidden = false
        break
      case StatePb.StateKindCase.ERROR:
        state.progressInPercent = 0
        break
    }

    if (entry.getStateKindCase() === StatePb.StateKindCase.ERROR) {
      // `de.mypowergrid.fwupgrade.Error`
      let err = entry.getError().getError()
      if (err) {
        err = parseFwUpgradeError(err)
        state.currentErrorDetails = {
          name: err.name,
          code: err.code,
          msg: err.msg
        }
      }
    } else {
      state.currentErrorDetails = null
    }

    state.currentState = entry.getStateKindCase()
  },
  RESET_ALL(s) {
    Object.assign(s, state())
  }
}

/**
 * Action (API call) to update the log list
 *
 * @function
 */
function reloadLog({ commit }) {
  commit('CLEAR_LOG', {})
  return apiUnary.getLog().then((rsp) => {
    rsp
      .getLog()
      .getStatesList()
      .forEach((element) => {
        commit('ADD_LOG', {
          entry: element
        })
      })
  })
}

/**
 * Action (API call) to reload the fw-upgrade current state
 *
 * @function
 *
 * @param {object} arg
 *
 * @return {Promise}
 */
function reloadCurrentState({ commit }) {
  return apiUnary.getState().then((rsp) => {
    commit('UPDATE_STATE', {
      entry: rsp.getState()
    })
  })
}

/**
 * Action to initialize the store and start the fw-upgrader-state listening.
 * Before initializing, any existing store-state and cache is cleared.
 *
 *
 * @function
 *
 * @return {object} setup
 * @return {Promise} setup.initialize is loading the current fwupgrader state. Any error is already handled/swallowed.
 * @return {Promise} setup.stream is the fwupgrader state stream. As long as the stream is not closed, this promise will be pending. Any error will be handled/swallowed
 * @return {PollingUnaryCall} setup.ping is the polling ping testing for reboot
 */
function initialize({ commit, dispatch }) {
  dispatch('teardown')

  const statePromis = dispatch('reloadCurrentState')
  statePromis.catch((err) => {
    logger.warn('Failed to load current fwupgrader state.')
    logger.warn(err)
  })

  const streamPromis = apiStream.getStateStream({
    onMessage: (entry) => {
      commit('UPDATE_STATE', {
        entry: entry.getState()
      })
    },
    onReEstablishing: () => {
      dispatch('reloadCurrentState').catch(() => {
        logger.debug('Failed reload current fwupgrader state during re-establishing state-stream.')
      })
    }
  })
  streamPromis.catch((err) => {
    logger.warn('Failed to establish fwupgrader state-stream.')
    logger.warn(err)
  })
  cache.stream = streamPromis.meta.stream

  cache.ping = new PollingUnaryCall(
    {
      service: NetworkService,
      method: 'Ping',
      payload: new NetworkPb.PingRequest(),
      onMessage: () => {
        if (cache.countConsecutiveFailedPings >= retryCount) {
          // After reboot, this RPC will fail,
          // because the session is lost, and a new auth-token has to be obtained.
          // Then, this will trigger a redirect to the login-page
          dispatch('reloadCurrentState').catch((err) => {
            logger.debug('Failed reload current fwupgrader state after received first pong.')
            logger.debug(err)
          })
        }
        cache.countConsecutiveFailedPings = 0
      },
      onError: () => {
        cache.countConsecutiveFailedPings++
        if (cache.countConsecutiveFailedPings === retryCount) {
          const state = new StatePb()
          const type = new StatePb.Rebooting()
          state.setRebooting(type)

          commit('UPDATE_STATE', {
            entry: state
          })
        }
      }
    },
    { pollingInterval: retryDelay }
  )
  cache.ping.perform()

  return { initialize: statePromis, stream: streamPromis, ping: cache.ping }
}

/**
 * Action resets store & cache and stops the state stream & ping
 *
 * @function
 */
function teardown({ commit }) {
  try {
    cache.stream?.close()
  } catch (err) {
    logger.warn(err)
  }
  try {
    cache.ping?.close()
  } catch (err) {
    logger.war(err)
  }
  clearCache()
  commit('RESET_ALL')
}

function parseFwUpgraderStateKindToString(id) {
  for (const [key, value] of Object.entries(StatePb.StateKindCase)) {
    if (value === id) {
      return key
    }
  }
  return 'UNKOWN'
}

/**
 * Action (API call) start an upgrade
 *
 * @function
 */
function startUpgrade({ state }) {
  if (state.currentState === StatePb.StateKindCase.UPGRADE_AVAILABLE) {
    return apiUnary.startUpgrade()
  } else {
    throw new Error(
      'Cannot start upgrade in current state. Required state:' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.UPGRADE_AVAILABLE) +
        ' but current state is:' +
        parseFwUpgraderStateKindToString(state.currentState)
    )
  }
}

/**
 * Action (API call) finalize an upgrade
 *
 * @function
 */
function finalizeUpgrade({ state }) {
  if (state.currentState === StatePb.StateKindCase.PARTIALLY_UPGRADED) {
    return apiUnary.finalizeUpgrade()
  } else {
    throw new Error(
      'Cannot finalize upgrade in current state. Required state:' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.PARTIALLY_UPGRADED) +
        ' but current state is:' +
        parseFwUpgraderStateKindToString(state.currentState)
    )
  }
}

/**
 * Action (API call) rollback an upgrade
 *
 * @function
 */
function rollbackUpgrade({ state }) {
  if (state.currentState === StatePb.StateKindCase.PARTIALLY_UPGRADED) {
    return apiUnary.rollbackUpgrade()
  } else {
    throw new Error(
      'Cannot rollback upgrade in current state. Required state:' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.PARTIALLY_UPGRADED) +
        ' but current state is:' +
        parseFwUpgraderStateKindToString(state.currentState)
    )
  }
}

/**
 * Action (API call) rollback an upgrade
 *
 * @function
 */
function acknowledgeError({ state }) {
  if (state.currentState === StatePb.StateKindCase.ERROR) {
    return apiUnary.acknowledgeError()
  } else {
    throw new Error(
      'Cannot acknowledge error in current state. Required state:' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.ERROR) +
        ' but current state is:' +
        parseFwUpgraderStateKindToString(state.currentState)
    )
  }
}

/**
 * Action (API call) to trigger a check for latest upgraded
 *
 * @function
 */
function checkForLatestUpgrade({ state, commit }) {
  if (
    state.currentState === StatePb.StateKindCase.UPGRADE_AVAILABLE ||
    state.currentState === StatePb.StateKindCase.UP_TO_DATE
  ) {
    return apiUnary.checkForLatestUpgrade().then((rsp) => {
      if (rsp.hasBundleInfo()) {
        const fwStatePb = new StatePb()
        const upgrdAvailable = new StatePb.UpgradeAvailable([state.currentVersion])
        upgrdAvailable.setUpgradeBundleInfo(rsp.getBundleInfo())
        fwStatePb.setUpgradeAvailable(upgrdAvailable)
        commit('UPDATE_STATE', { entry: fwStatePb })
      }
      if (rsp.hasError()) {
        if (rsp.getError().hasUpdatesDisabled()) {
          commit('FORBID', {
            msg: rsp.getError().getUpdatesDisabled().getMessage()
          })
        } else {
          throw new Error('Debug: Unexspected return for fwupgrader.checkForLatestUpgrade')
        }
      } else {
        commit('ALLOW', {})
      }
    })
  } else {
    throw new Error(
      'Cannot check for upgrades current state. Required state:' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.UPGRADE_AVAILABLE) +
        ' or ' +
        parseFwUpgraderStateKindToString(StatePb.StateKindCase.UP_TO_DATE) +
        ' but current state is:' +
        parseFwUpgraderStateKindToString(state.currentState)
    )
  }
}

const actions = {
  reloadLog,
  reloadCurrentState,
  initialize,
  teardown,
  checkForLatestUpgrade,
  startUpgrade,
  finalizeUpgrade,
  rollbackUpgrade,
  acknowledgeError
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
