/**
 * @module view-helper/ems-topology-helper
 */

import { camelCase, cloneDeep, isEmpty } from 'lodash'
import i18n from '@/i18n'
import { humanizePhysDevType, physicalDeviceToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { PhysicalDevice as PhysicalDevicePb } from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import { generateTreeSelectOptionsFromMap } from '../form-helpers'

/**
 * Humanizes and translates a `ems.topology.PhysicalDevice`
 *
 * @function
 *
 * @param {object} pd is a plain `ems.topology.PhysicalDevice`
 *
 * @return {string} the humanized and translated physical device
 */
export function translatePhysicalDevice(pd) {
  if (isEmpty(pd)) {
    return ''
  }

  const typeHumanized = humanizePhysDevType(pd)
  const tk = `ems.physicalDevice.${camelCase(typeHumanized)}`
  let l = typeHumanized
  if (i18n.te(tk)) {
    l = i18n.t(tk)
  }
  if (pd.number) {
    l += ' ' + pd.number
  }

  return l
}

export function generatePhysicalDeviceSelectOpts(pds) {
  const opts = []

  pds.forEach((pd) => {
    opts.push({
      label: translatePhysicalDevice(pd),
      value: physicalDeviceToLiteral(pd)
    })
  })

  opts.sort((a, b) => a.label.localeCompare(b.label, i18n.locale, { numeric: true }))

  return opts
}

export const createDeviceLabelFromKey = (value) => {
  const deviceKey = value.split('.')[0]
  const deviceNumber = value.split('.')[1]
  const deviceName = camelCase(humanizePhysDevType({ type: parseInt(deviceKey) }))

  return `${i18n.t(`ems.physicalDevice.${deviceName}`)} ${deviceNumber}`
}

export function generatePhysicalDeviceOptsForTreeSelect(pds, { noDevice } = {}) {
  const opts = {}

  for (const pd of pds) {
    const deviceNameValue = physicalDeviceToLiteral(pd)
    const deviceType = translatePhysicalDevice(pd).split(' ')[0]
    opts[deviceNameValue] = deviceType
  }

  const treeSelectOptions = generateTreeSelectOptionsFromMap({
    valuesToCategories: opts,
    valueLabelFunction: createDeviceLabelFromKey
  })

  return noDevice
    ? [{ id: 'noDevice', label: i18n.t('ems.physicalDevice.noDevice') }, ...treeSelectOptions]
    : treeSelectOptions
}

/**
 * Takes a list of `ems.topology.PhysicalDevice`(s) and returns a list of CMulitselect selected values.
 *
 * @function
 *
 * @param {array} pds (required) has to be a list of `ems.topology.PhysicalDevice`
 *
 * @return {array} selection for CMultiselect.
 */
export function getPhysicalDevicePreselection(pds, { noDevice } = {}) {
  const opts = []
  pds.forEach((pd) => {
    opts.push(physicalDeviceToLiteral(pd))
  })

  opts.sort((a, b) => {
    if (!a) {
      return -1
    }

    if (!b) {
      return 1
    }

    return a.localeCompare(b, undefined, { numeric: true })
  })

  return opts
}

/**
 * Determines the state of the grid connection ('Grid-Toggler')
 *
 * @function
 *
 * @param {array} pit (required) is a list of topology.PhysicalDevice(s)
 *
 * @return {boolean}
 */
export function isGridEnabled(pit) {
  return !pit || !(Array.isArray(pit.physicalDevicesList) && pit.physicalDevicesList.length)
}

/**
 * Makes a list of physical devices digestable by the backend
 *
 * @function
 *
 * @param {array} pit (required) is a list of topology.PhysicalDevice(s)
 *
 * @param {boolean} isGrid (required) is the state of the grid connection
 *
 * @return {array}
 */
export function makePhysicalDevicesList(pit, isGrid) {
  const physicalDevicesList = []

  if (!isGrid) {
    pit.forEach((pd) => {
      if (!pd) {
        return
      }
      physicalDevicesList.push(new PhysicalDevicePb(pd.split('.').map((e) => parseInt(e))).toObject())
    })
  }
  return physicalDevicesList
}

/**
 * Takes a list of topology.PhysicalDevice(s) and sorts it alpha-numerically without modifing the original array.
 *
 * @function
 *
 * @param {array} pds (required) has to be a list of topology.PhysicalDevice(s), optionally extended with `typeTranslated` property
 *
 * @return {array}
 */
export function sortPhysicalDevices(pds) {
  pds = cloneDeep(pds) // `sort` is in place, but store.state has not to be modified
  return pds.sort((a, b) => {
    let i
    if (a.typeTranslated) {
      i = a.typeTranslated.localeCompare(b.typeTranslated)
    } else {
      i = a.type - b.type
    }
    if (i === 0) {
      return a.number - b.number
    }
    return i
  })
}
