// source: ext/ems/topology/topology.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var ext_ems_data$objects_pb = require('../../../ext/ems/data-objects_pb.js');
goog.object.extend(proto, ext_ems_data$objects_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ElectricityMeter', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ElectricityMeter.Id', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Error', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ErrorType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Meters', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhaseTopology', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhaseType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhysicalDevice', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.PhysicalDeviceType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Quantity', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Quantity.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Source', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Source.IecId', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Source.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SourceQuantities', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SourceQuantity', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.SourceToDeviceTopology', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.StatusQuantityDescription', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.StatusQuantityDescription.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.StringQuantityDescription', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.StringQuantityDescription.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TemperatureLocationTag', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.Topology', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatform', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatformV2', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.PhysicalDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.PhysicalDevice.displayName = 'proto.de.mypowergrid.ems.topology.PhysicalDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.displayName = 'proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.displayName = 'proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.StringQuantityDescription.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.StringQuantityDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.StringQuantityDescription.displayName = 'proto.de.mypowergrid.ems.topology.StringQuantityDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.StatusQuantityDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.StatusQuantityDescription.displayName = 'proto.de.mypowergrid.ems.topology.StatusQuantityDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Quantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Quantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Quantity.displayName = 'proto.de.mypowergrid.ems.topology.Quantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.Source.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Source.displayName = 'proto.de.mypowergrid.ems.topology.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Source.IecId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Source.IecId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Source.IecId.displayName = 'proto.de.mypowergrid.ems.topology.Source.IecId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.displayName = 'proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.displayName = 'proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.SourceQuantities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.SourceQuantities.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.SourceQuantities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.SourceQuantities.displayName = 'proto.de.mypowergrid.ems.topology.SourceQuantities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.SourceQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.SourceQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.SourceQuantity.displayName = 'proto.de.mypowergrid.ems.topology.SourceQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.PhaseTopology = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.PhaseTopology.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.PhaseTopology, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.PhaseTopology.displayName = 'proto.de.mypowergrid.ems.topology.PhaseTopology';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.displayName = 'proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.SourceToDeviceTopology, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.displayName = 'proto.de.mypowergrid.ems.topology.SourceToDeviceTopology';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Topology = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.Topology.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Topology, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Topology.displayName = 'proto.de.mypowergrid.ems.topology.Topology';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.displayName = 'proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatformV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatformV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.Error.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Error.displayName = 'proto.de.mypowergrid.ems.topology.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.Meters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.Meters.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.Meters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.Meters.displayName = 'proto.de.mypowergrid.ems.topology.Meters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.ElectricityMeter.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.ElectricityMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.ElectricityMeter.displayName = 'proto.de.mypowergrid.ems.topology.ElectricityMeter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.displayName = 'proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.ElectricityMeter.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.displayName = 'proto.de.mypowergrid.ems.topology.ElectricityMeter.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.TopologyToPlatform.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatform, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatform';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.displayName = 'proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
  return proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhysicalDeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional PhysicalDeviceType type = 1;
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDeviceType}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhysicalDeviceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDeviceType} value
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice} returns this
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 number = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice} returns this
 */
proto.de.mypowergrid.ems.topology.PhysicalDevice.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.TypeCase = {
  TYPE_NOT_SET: 0,
  POWER_AC_WATTS: 1,
  POWER_DC_WATTS: 2,
  SUPPLY_WATTHOURS: 3,
  DEMAND_WATTHOURS: 4,
  STATE_OF_CHARGE_PERCENT: 5,
  STATE_OF_HEALTH_PERCENT: 6,
  VOLTAGE_AC_VOLT: 7,
  VOLTAGE_DC_VOLT: 8,
  CURRENT_AC_AMPERE: 9,
  CURRENT_DC_AMPERE: 10,
  TEMPERATURE_DEGREE_CELCIUS: 11,
  APPARENT_POWER: 12,
  REACTIVE_POWER: 13,
  INVERTER_SETPOINT_WATTS: 14,
  ELECTRIC_VEHICLE_SUPPLY_EQUIPMENT_SETPOINT_WATTS: 15,
  ELECTRIC_VEHICLE_SUPPLY_EQUIPMENT_SETPOINT_AMPERE: 16,
  AVG_POWERFACTOR: 17,
  HARMONIC_DISTORTION_PN_CURRENT_PERCENT: 18,
  HARMONIC_DISTORTION_PN_VOLTAGE_PERCENT: 19,
  SUPPLY_DC_WATTHOURS: 20,
  DEMAND_DC_WATTHOURS: 21,
  SWITCH_SETPOINT_BOOL: 22,
  CONTRACTUAL_FEED_IN_LIMIT_WATTS: 23,
  FLOW_RATE_LITERS_PER_HOUR: 24,
  VOLUME_METER_QUBIC_METER: 25,
  FUELCELL_SETPOINT_WATTS: 26,
  HEATING_SETPOINT_WATTS: 27,
  VPP_PV_SETPOINT_WATTS: 28,
  VPP_PV_SETPOINT_TARGET_POSITION: 29,
  VPP_GRID_POWER_WATTS: 30,
  VPP_RADIO_RIPPLE_CONTROL_RECEIVER: 31,
  VPP_PV_POWER_WATTS: 32,
  VPP_USER_POWER_WATTS: 33,
  VPP_AVG_SOC_PERCENT: 34,
  VPP_BATTERY_POWER_WATTS: 35,
  VPP_PV_MAX_POWER_WATTS: 36,
  VPP_MAX_BATTERY_POWER_WATTS: 37,
  VPP_MIN_BATTERY_POWER_WATTS: 38,
  VPP_NOMINAL_CAPACITY_BATTERY_WATTHOURS: 39,
  CAPACITY_BATTERY_WATTHOURS: 40,
  VPP_GRD_BAT_SETPOINT_WATTS: 41,
  VPP_GRD_BAT_SETPOINT_TARGET_POSITION: 42,
  INTERNAL_TEMPERATURE_DEGREE_CELCIUS: 43,
  CHP_SETPOINT_WATTS: 44,
  BATTERIE_ENABLE_SETPOINT_BOOL: 45,
  EZA_SUGGESTION_WATTS: 46,
  EZA_FORWARD_SETPOINT_WATTS: 47,
  EZA_FORWARD_SETPOINT_VOLT_AMPERE_REACTIVE: 48,
  INVERTER_SETPOINT_VOLT_AMPERE_REACTIVE: 49,
  ELECTROLYZER_SETPOINT_WATTS: 50,
  HUMIDITY_PERCENT: 51,
  ACC_COOL_OUT_WATT_HOURS: 52,
  ACC_HEAT_OUT_WATT_HOURS: 53,
  HEAT_OUT_WATTS: 54,
  FAN_SPEED_PERCENT: 55,
  TMP_DEGREE_CELCIUS: 56,
  INCOMING_TMP_DEGREE_CELCIUS: 57,
  OUTGOING_TMP_DEGREE_CELCIUS: 58,
  ENVIRONMENT_TMP_DEGREE_CELCIUS: 59,
  EZA_STATE_OF_CHARGE_LISTENER_PERCENT: 60,
  EZA_MAX_DISCHARGE_LISTENER_WATTS: 61,
  MAX_DISCHARGE_WATTS: 62,
  EZA_CAPACITY_BATTERY_LISTENER_WATTHOURS: 63,
  EZA_FORWARD_SWITCH_PREVENT_OFF_GRID_BOOL: 64,
  SWITCH_PREVENT_OFF_GRID_SETPOINT_BOOL: 65,
  MAX_CHARGE_WATTS: 66,
  VPP_PV_SETPOINT_RESPONSE_WATTS: 67,
  EZA_POWER_LISTENER_WATTS: 68,
  EZA_REACTIVE_POWER_LISTENER_VOLT_AMPERE_REACTIVE: 69,
  EZA_APPARENT_POWER_LISTENER_VOLT_AMPERE: 70,
  EZA_POWER_RATING_LISTENER_WATTS: 71
};

/**
 * @return {proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.TypeCase}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    powerAcWatts: (f = msg.getPowerAcWatts()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.toObject(includeInstance, f),
    powerDcWatts: (f = msg.getPowerDcWatts()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.toObject(includeInstance, f),
    supplyWatthours: (f = msg.getSupplyWatthours()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.toObject(includeInstance, f),
    demandWatthours: (f = msg.getDemandWatthours()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.toObject(includeInstance, f),
    stateOfChargePercent: (f = msg.getStateOfChargePercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    stateOfHealthPercent: (f = msg.getStateOfHealthPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    voltageAcVolt: (f = msg.getVoltageAcVolt()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    voltageDcVolt: (f = msg.getVoltageDcVolt()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.toObject(includeInstance, f),
    currentAcAmpere: (f = msg.getCurrentAcAmpere()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    currentDcAmpere: (f = msg.getCurrentDcAmpere()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.toObject(includeInstance, f),
    temperatureDegreeCelcius: (f = msg.getTemperatureDegreeCelcius()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.toObject(includeInstance, f),
    apparentPower: (f = msg.getApparentPower()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.toObject(includeInstance, f),
    reactivePower: (f = msg.getReactivePower()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.toObject(includeInstance, f),
    inverterSetpointWatts: (f = msg.getInverterSetpointWatts()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.toObject(includeInstance, f),
    electricVehicleSupplyEquipmentSetpointWatts: (f = msg.getElectricVehicleSupplyEquipmentSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    electricVehicleSupplyEquipmentSetpointAmpere: (f = msg.getElectricVehicleSupplyEquipmentSetpointAmpere()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    avgPowerfactor: (f = msg.getAvgPowerfactor()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    harmonicDistortionPnCurrentPercent: (f = msg.getHarmonicDistortionPnCurrentPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    harmonicDistortionPnVoltagePercent: (f = msg.getHarmonicDistortionPnVoltagePercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    supplyDcWatthours: (f = msg.getSupplyDcWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    demandDcWatthours: (f = msg.getDemandDcWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    switchSetpointBool: (f = msg.getSwitchSetpointBool()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    contractualFeedInLimitWatts: (f = msg.getContractualFeedInLimitWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    flowRateLitersPerHour: (f = msg.getFlowRateLitersPerHour()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.toObject(includeInstance, f),
    volumeMeterQubicMeter: (f = msg.getVolumeMeterQubicMeter()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.toObject(includeInstance, f),
    fuelcellSetpointWatts: (f = msg.getFuelcellSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    heatingSetpointWatts: (f = msg.getHeatingSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppPvSetpointWatts: (f = msg.getVppPvSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppPvSetpointTargetPosition: (f = msg.getVppPvSetpointTargetPosition()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppGridPowerWatts: (f = msg.getVppGridPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppRadioRippleControlReceiver: (f = msg.getVppRadioRippleControlReceiver()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppPvPowerWatts: (f = msg.getVppPvPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppUserPowerWatts: (f = msg.getVppUserPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppAvgSocPercent: (f = msg.getVppAvgSocPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppBatteryPowerWatts: (f = msg.getVppBatteryPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppPvMaxPowerWatts: (f = msg.getVppPvMaxPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppMaxBatteryPowerWatts: (f = msg.getVppMaxBatteryPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppMinBatteryPowerWatts: (f = msg.getVppMinBatteryPowerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppNominalCapacityBatteryWatthours: (f = msg.getVppNominalCapacityBatteryWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    capacityBatteryWatthours: (f = msg.getCapacityBatteryWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppGrdBatSetpointWatts: (f = msg.getVppGrdBatSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppGrdBatSetpointTargetPosition: (f = msg.getVppGrdBatSetpointTargetPosition()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    internalTemperatureDegreeCelcius: (f = msg.getInternalTemperatureDegreeCelcius()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    chpSetpointWatts: (f = msg.getChpSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    batterieEnableSetpointBool: (f = msg.getBatterieEnableSetpointBool()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaSuggestionWatts: (f = msg.getEzaSuggestionWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaForwardSetpointWatts: (f = msg.getEzaForwardSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaForwardSetpointVoltAmpereReactive: (f = msg.getEzaForwardSetpointVoltAmpereReactive()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    inverterSetpointVoltAmpereReactive: (f = msg.getInverterSetpointVoltAmpereReactive()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    electrolyzerSetpointWatts: (f = msg.getElectrolyzerSetpointWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    humidityPercent: (f = msg.getHumidityPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    accCoolOutWattHours: (f = msg.getAccCoolOutWattHours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    accHeatOutWattHours: (f = msg.getAccHeatOutWattHours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    heatOutWatts: (f = msg.getHeatOutWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    fanSpeedPercent: (f = msg.getFanSpeedPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    tmpDegreeCelcius: (f = msg.getTmpDegreeCelcius()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    incomingTmpDegreeCelcius: (f = msg.getIncomingTmpDegreeCelcius()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    outgoingTmpDegreeCelcius: (f = msg.getOutgoingTmpDegreeCelcius()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    environmentTmpDegreeCelcius: (f = msg.getEnvironmentTmpDegreeCelcius()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaStateOfChargeListenerPercent: (f = msg.getEzaStateOfChargeListenerPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaMaxDischargeListenerWatts: (f = msg.getEzaMaxDischargeListenerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    maxDischargeWatts: (f = msg.getMaxDischargeWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaCapacityBatteryListenerWatthours: (f = msg.getEzaCapacityBatteryListenerWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaForwardSwitchPreventOffGridBool: (f = msg.getEzaForwardSwitchPreventOffGridBool()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    switchPreventOffGridSetpointBool: (f = msg.getSwitchPreventOffGridSetpointBool()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    maxChargeWatts: (f = msg.getMaxChargeWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    vppPvSetpointResponseWatts: (f = msg.getVppPvSetpointResponseWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaPowerListenerWatts: (f = msg.getEzaPowerListenerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaReactivePowerListenerVoltAmpereReactive: (f = msg.getEzaReactivePowerListenerVoltAmpereReactive()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaApparentPowerListenerVoltAmpere: (f = msg.getEzaApparentPowerListenerVoltAmpere()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaPowerRatingListenerWatts: (f = msg.getEzaPowerRatingListenerWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinaryFromReader);
      msg.setPowerAcWatts(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinaryFromReader);
      msg.setPowerDcWatts(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.deserializeBinaryFromReader);
      msg.setSupplyWatthours(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.deserializeBinaryFromReader);
      msg.setDemandWatthours(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setStateOfChargePercent(value);
      break;
    case 6:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setStateOfHealthPercent(value);
      break;
    case 7:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVoltageAcVolt(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinaryFromReader);
      msg.setVoltageDcVolt(value);
      break;
    case 9:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setCurrentAcAmpere(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinaryFromReader);
      msg.setCurrentDcAmpere(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.deserializeBinaryFromReader);
      msg.setTemperatureDegreeCelcius(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinaryFromReader);
      msg.setApparentPower(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinaryFromReader);
      msg.setReactivePower(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.deserializeBinaryFromReader);
      msg.setInverterSetpointWatts(value);
      break;
    case 15:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setElectricVehicleSupplyEquipmentSetpointWatts(value);
      break;
    case 16:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setElectricVehicleSupplyEquipmentSetpointAmpere(value);
      break;
    case 17:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setAvgPowerfactor(value);
      break;
    case 18:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHarmonicDistortionPnCurrentPercent(value);
      break;
    case 19:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHarmonicDistortionPnVoltagePercent(value);
      break;
    case 20:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSupplyDcWatthours(value);
      break;
    case 21:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setDemandDcWatthours(value);
      break;
    case 22:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSwitchSetpointBool(value);
      break;
    case 23:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setContractualFeedInLimitWatts(value);
      break;
    case 24:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.deserializeBinaryFromReader);
      msg.setFlowRateLitersPerHour(value);
      break;
    case 25:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.deserializeBinaryFromReader);
      msg.setVolumeMeterQubicMeter(value);
      break;
    case 26:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFuelcellSetpointWatts(value);
      break;
    case 27:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHeatingSetpointWatts(value);
      break;
    case 28:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppPvSetpointWatts(value);
      break;
    case 29:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppPvSetpointTargetPosition(value);
      break;
    case 30:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppGridPowerWatts(value);
      break;
    case 31:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppRadioRippleControlReceiver(value);
      break;
    case 32:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppPvPowerWatts(value);
      break;
    case 33:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppUserPowerWatts(value);
      break;
    case 34:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppAvgSocPercent(value);
      break;
    case 35:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppBatteryPowerWatts(value);
      break;
    case 36:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppPvMaxPowerWatts(value);
      break;
    case 37:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppMaxBatteryPowerWatts(value);
      break;
    case 38:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppMinBatteryPowerWatts(value);
      break;
    case 39:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppNominalCapacityBatteryWatthours(value);
      break;
    case 40:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setCapacityBatteryWatthours(value);
      break;
    case 41:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppGrdBatSetpointWatts(value);
      break;
    case 42:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppGrdBatSetpointTargetPosition(value);
      break;
    case 43:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setInternalTemperatureDegreeCelcius(value);
      break;
    case 44:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setChpSetpointWatts(value);
      break;
    case 45:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setBatterieEnableSetpointBool(value);
      break;
    case 46:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaSuggestionWatts(value);
      break;
    case 47:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaForwardSetpointWatts(value);
      break;
    case 48:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaForwardSetpointVoltAmpereReactive(value);
      break;
    case 49:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setInverterSetpointVoltAmpereReactive(value);
      break;
    case 50:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setElectrolyzerSetpointWatts(value);
      break;
    case 51:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHumidityPercent(value);
      break;
    case 52:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setAccCoolOutWattHours(value);
      break;
    case 53:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setAccHeatOutWattHours(value);
      break;
    case 54:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHeatOutWatts(value);
      break;
    case 55:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFanSpeedPercent(value);
      break;
    case 56:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTmpDegreeCelcius(value);
      break;
    case 57:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setIncomingTmpDegreeCelcius(value);
      break;
    case 58:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setOutgoingTmpDegreeCelcius(value);
      break;
    case 59:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEnvironmentTmpDegreeCelcius(value);
      break;
    case 60:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaStateOfChargeListenerPercent(value);
      break;
    case 61:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaMaxDischargeListenerWatts(value);
      break;
    case 62:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setMaxDischargeWatts(value);
      break;
    case 63:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaCapacityBatteryListenerWatthours(value);
      break;
    case 64:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaForwardSwitchPreventOffGridBool(value);
      break;
    case 65:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSwitchPreventOffGridSetpointBool(value);
      break;
    case 66:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setMaxChargeWatts(value);
      break;
    case 67:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVppPvSetpointResponseWatts(value);
      break;
    case 68:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaPowerListenerWatts(value);
      break;
    case 69:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaReactivePowerListenerVoltAmpereReactive(value);
      break;
    case 70:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaApparentPowerListenerVoltAmpere(value);
      break;
    case 71:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaPowerRatingListenerWatts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPowerAcWatts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.serializeBinaryToWriter
    );
  }
  f = message.getPowerDcWatts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.serializeBinaryToWriter
    );
  }
  f = message.getSupplyWatthours();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.serializeBinaryToWriter
    );
  }
  f = message.getDemandWatthours();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.serializeBinaryToWriter
    );
  }
  f = message.getStateOfChargePercent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getStateOfHealthPercent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVoltageAcVolt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVoltageDcVolt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.serializeBinaryToWriter
    );
  }
  f = message.getCurrentAcAmpere();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDcAmpere();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.serializeBinaryToWriter
    );
  }
  f = message.getApparentPower();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.serializeBinaryToWriter
    );
  }
  f = message.getReactivePower();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.serializeBinaryToWriter
    );
  }
  f = message.getInverterSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.serializeBinaryToWriter
    );
  }
  f = message.getElectricVehicleSupplyEquipmentSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getElectricVehicleSupplyEquipmentSetpointAmpere();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getAvgPowerfactor();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHarmonicDistortionPnCurrentPercent();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHarmonicDistortionPnVoltagePercent();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSupplyDcWatthours();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getDemandDcWatthours();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSwitchSetpointBool();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getContractualFeedInLimitWatts();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getFlowRateLitersPerHour();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.serializeBinaryToWriter
    );
  }
  f = message.getVolumeMeterQubicMeter();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.serializeBinaryToWriter
    );
  }
  f = message.getFuelcellSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHeatingSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppPvSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppPvSetpointTargetPosition();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppGridPowerWatts();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppRadioRippleControlReceiver();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppPvPowerWatts();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppUserPowerWatts();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppAvgSocPercent();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppBatteryPowerWatts();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppPvMaxPowerWatts();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppMaxBatteryPowerWatts();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppMinBatteryPowerWatts();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppNominalCapacityBatteryWatthours();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getCapacityBatteryWatthours();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppGrdBatSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppGrdBatSetpointTargetPosition();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getInternalTemperatureDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getChpSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getBatterieEnableSetpointBool();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaSuggestionWatts();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaForwardSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaForwardSetpointVoltAmpereReactive();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getInverterSetpointVoltAmpereReactive();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getElectrolyzerSetpointWatts();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHumidityPercent();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getAccCoolOutWattHours();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getAccHeatOutWattHours();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHeatOutWatts();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getFanSpeedPercent();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTmpDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getIncomingTmpDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getOutgoingTmpDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEnvironmentTmpDegreeCelcius();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaStateOfChargeListenerPercent();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaMaxDischargeListenerWatts();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getMaxDischargeWatts();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaCapacityBatteryListenerWatthours();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaForwardSwitchPreventOffGridBool();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSwitchPreventOffGridSetpointBool();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getMaxChargeWatts();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVppPvSetpointResponseWatts();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaPowerListenerWatts();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaReactivePowerListenerVoltAmpereReactive();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaApparentPowerListenerVoltAmpere();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaPowerRatingListenerWatts();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (reader.readEnum());
      msg.setPhaseType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional PhaseType phase_type = 1;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseType}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.prototype.getPhaseType = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseType} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo.prototype.setPhaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (reader.readEnum());
      msg.setPhaseType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPhaseBalance();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional PhaseType phase_type = 1;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseType}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.getPhaseType = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseType} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.setPhaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool phase_balance = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.getPhaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty.prototype.setPhaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationTag: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.TemperatureLocationTag} */ (reader.readEnum());
      msg.setLocationTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationTag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional TemperatureLocationTag location_tag = 1;
 * @return {!proto.de.mypowergrid.ems.topology.TemperatureLocationTag}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.prototype.getLocationTag = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.TemperatureLocationTag} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TemperatureLocationTag} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature.prototype.setLocationTag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    minStateOfChargePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxStateOfChargePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinStateOfChargePercent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxStateOfChargePercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinStateOfChargePercent();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMaxStateOfChargePercent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double min_state_of_charge_percent = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.getMinStateOfChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.setMinStateOfChargePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double max_state_of_charge_percent = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.getMaxStateOfChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties.prototype.setMaxStateOfChargePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    mmdc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMmdc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMmdc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool mmdc = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.prototype.getMmdc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties.prototype.setMmdc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialKind: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties;
  return proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.MaterialKind} */ (reader.readEnum());
      msg.setMaterialKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.MaterialKind material_kind = 1;
 * @return {!proto.de.mypowergrid.ems.MaterialKind}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.prototype.getMaterialKind = function() {
  return /** @type {!proto.de.mypowergrid.ems.MaterialKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.MaterialKind} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties.prototype.setMaterialKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PhaseInfo power_ac_watts = 1;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getPowerAcWatts = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setPowerAcWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearPowerAcWatts = function() {
  return this.setPowerAcWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasPowerAcWatts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DcProperties power_dc_watts = 2;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getPowerDcWatts = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setPowerDcWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearPowerDcWatts = function() {
  return this.setPowerDcWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasPowerDcWatts = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EnergyProperty supply_watthours = 3;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getSupplyWatthours = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setSupplyWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearSupplyWatthours = function() {
  return this.setSupplyWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasSupplyWatthours = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EnergyProperty demand_watthours = 4;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getDemandWatthours = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.EnergyProperty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setDemandWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearDemandWatthours = function() {
  return this.setDemandWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasDemandWatthours = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty state_of_charge_percent = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getStateOfChargePercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setStateOfChargePercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearStateOfChargePercent = function() {
  return this.setStateOfChargePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasStateOfChargePercent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Empty state_of_health_percent = 6;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getStateOfHealthPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 6));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setStateOfHealthPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearStateOfHealthPercent = function() {
  return this.setStateOfHealthPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasStateOfHealthPercent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Empty voltage_ac_volt = 7;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVoltageAcVolt = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 7));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVoltageAcVolt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVoltageAcVolt = function() {
  return this.setVoltageAcVolt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVoltageAcVolt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DcProperties voltage_dc_volt = 8;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVoltageDcVolt = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVoltageDcVolt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVoltageDcVolt = function() {
  return this.setVoltageDcVolt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVoltageDcVolt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Empty current_ac_ampere = 9;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getCurrentAcAmpere = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 9));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setCurrentAcAmpere = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearCurrentAcAmpere = function() {
  return this.setCurrentAcAmpere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasCurrentAcAmpere = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DcProperties current_dc_ampere = 10;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getCurrentDcAmpere = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.DcProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setCurrentDcAmpere = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearCurrentDcAmpere = function() {
  return this.setCurrentDcAmpere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasCurrentDcAmpere = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Temperature temperature_degree_celcius = 11;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getTemperatureDegreeCelcius = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature, 11));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.Temperature|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setTemperatureDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearTemperatureDegreeCelcius = function() {
  return this.setTemperatureDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasTemperatureDegreeCelcius = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PhaseInfo apparent_power = 12;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getApparentPower = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo, 12));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setApparentPower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearApparentPower = function() {
  return this.setApparentPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasApparentPower = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PhaseInfo reactive_power = 13;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getReactivePower = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo, 13));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.PhaseInfo|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setReactivePower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearReactivePower = function() {
  return this.setReactivePower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasReactivePower = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional InverterProperties inverter_setpoint_watts = 14;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getInverterSetpointWatts = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties, 14));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.InverterProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setInverterSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearInverterSetpointWatts = function() {
  return this.setInverterSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasInverterSetpointWatts = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Empty electric_vehicle_supply_equipment_setpoint_watts = 15;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getElectricVehicleSupplyEquipmentSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 15));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setElectricVehicleSupplyEquipmentSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearElectricVehicleSupplyEquipmentSetpointWatts = function() {
  return this.setElectricVehicleSupplyEquipmentSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasElectricVehicleSupplyEquipmentSetpointWatts = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Empty electric_vehicle_supply_equipment_setpoint_ampere = 16;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getElectricVehicleSupplyEquipmentSetpointAmpere = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 16));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setElectricVehicleSupplyEquipmentSetpointAmpere = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearElectricVehicleSupplyEquipmentSetpointAmpere = function() {
  return this.setElectricVehicleSupplyEquipmentSetpointAmpere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasElectricVehicleSupplyEquipmentSetpointAmpere = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Empty avg_powerfactor = 17;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getAvgPowerfactor = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 17));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setAvgPowerfactor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearAvgPowerfactor = function() {
  return this.setAvgPowerfactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasAvgPowerfactor = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Empty harmonic_distortion_pn_current_percent = 18;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getHarmonicDistortionPnCurrentPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 18));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setHarmonicDistortionPnCurrentPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearHarmonicDistortionPnCurrentPercent = function() {
  return this.setHarmonicDistortionPnCurrentPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasHarmonicDistortionPnCurrentPercent = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Empty harmonic_distortion_pn_voltage_percent = 19;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getHarmonicDistortionPnVoltagePercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 19));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setHarmonicDistortionPnVoltagePercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearHarmonicDistortionPnVoltagePercent = function() {
  return this.setHarmonicDistortionPnVoltagePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasHarmonicDistortionPnVoltagePercent = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Empty supply_dc_watthours = 20;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getSupplyDcWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 20));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setSupplyDcWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearSupplyDcWatthours = function() {
  return this.setSupplyDcWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasSupplyDcWatthours = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Empty demand_dc_watthours = 21;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getDemandDcWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 21));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setDemandDcWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearDemandDcWatthours = function() {
  return this.setDemandDcWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasDemandDcWatthours = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.Empty switch_setpoint_bool = 22;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getSwitchSetpointBool = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 22));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setSwitchSetpointBool = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearSwitchSetpointBool = function() {
  return this.setSwitchSetpointBool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasSwitchSetpointBool = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Empty contractual_feed_in_limit_watts = 23;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getContractualFeedInLimitWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 23));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setContractualFeedInLimitWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearContractualFeedInLimitWatts = function() {
  return this.setContractualFeedInLimitWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasContractualFeedInLimitWatts = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional MaterialFlowProperties flow_rate_liters_per_hour = 24;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getFlowRateLitersPerHour = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties, 24));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setFlowRateLitersPerHour = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearFlowRateLitersPerHour = function() {
  return this.setFlowRateLitersPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasFlowRateLitersPerHour = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional MaterialFlowProperties volume_meter_qubic_meter = 25;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVolumeMeterQubicMeter = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties, 25));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.MaterialFlowProperties|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVolumeMeterQubicMeter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVolumeMeterQubicMeter = function() {
  return this.setVolumeMeterQubicMeter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVolumeMeterQubicMeter = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.Empty fuelcell_setpoint_watts = 26;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getFuelcellSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 26));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setFuelcellSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearFuelcellSetpointWatts = function() {
  return this.setFuelcellSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasFuelcellSetpointWatts = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.Empty heating_setpoint_watts = 27;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getHeatingSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 27));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setHeatingSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearHeatingSetpointWatts = function() {
  return this.setHeatingSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasHeatingSetpointWatts = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.Empty vpp_pv_setpoint_watts = 28;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppPvSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 28));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppPvSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppPvSetpointWatts = function() {
  return this.setVppPvSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppPvSetpointWatts = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.Empty vpp_pv_setpoint_target_position = 29;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppPvSetpointTargetPosition = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 29));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppPvSetpointTargetPosition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppPvSetpointTargetPosition = function() {
  return this.setVppPvSetpointTargetPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppPvSetpointTargetPosition = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Empty vpp_grid_power_watts = 30;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppGridPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 30));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppGridPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppGridPowerWatts = function() {
  return this.setVppGridPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppGridPowerWatts = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.Empty vpp_radio_ripple_control_receiver = 31;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppRadioRippleControlReceiver = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 31));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppRadioRippleControlReceiver = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppRadioRippleControlReceiver = function() {
  return this.setVppRadioRippleControlReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppRadioRippleControlReceiver = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Empty vpp_pv_power_watts = 32;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppPvPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 32));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppPvPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppPvPowerWatts = function() {
  return this.setVppPvPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppPvPowerWatts = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.protobuf.Empty vpp_user_power_watts = 33;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppUserPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 33));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppUserPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppUserPowerWatts = function() {
  return this.setVppUserPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppUserPowerWatts = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.protobuf.Empty vpp_avg_soc_percent = 34;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppAvgSocPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 34));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppAvgSocPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppAvgSocPercent = function() {
  return this.setVppAvgSocPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppAvgSocPercent = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Empty vpp_battery_power_watts = 35;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppBatteryPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 35));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppBatteryPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppBatteryPowerWatts = function() {
  return this.setVppBatteryPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppBatteryPowerWatts = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.protobuf.Empty vpp_pv_max_power_watts = 36;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppPvMaxPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 36));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppPvMaxPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppPvMaxPowerWatts = function() {
  return this.setVppPvMaxPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppPvMaxPowerWatts = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.protobuf.Empty vpp_max_battery_power_watts = 37;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppMaxBatteryPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 37));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppMaxBatteryPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppMaxBatteryPowerWatts = function() {
  return this.setVppMaxBatteryPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppMaxBatteryPowerWatts = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.protobuf.Empty vpp_min_battery_power_watts = 38;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppMinBatteryPowerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 38));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppMinBatteryPowerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppMinBatteryPowerWatts = function() {
  return this.setVppMinBatteryPowerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppMinBatteryPowerWatts = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional google.protobuf.Empty vpp_nominal_capacity_battery_watthours = 39;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppNominalCapacityBatteryWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 39));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppNominalCapacityBatteryWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppNominalCapacityBatteryWatthours = function() {
  return this.setVppNominalCapacityBatteryWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppNominalCapacityBatteryWatthours = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.protobuf.Empty capacity_battery_watthours = 40;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getCapacityBatteryWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 40));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setCapacityBatteryWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearCapacityBatteryWatthours = function() {
  return this.setCapacityBatteryWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasCapacityBatteryWatthours = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Empty vpp_grd_bat_setpoint_watts = 41;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppGrdBatSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 41));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppGrdBatSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppGrdBatSetpointWatts = function() {
  return this.setVppGrdBatSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppGrdBatSetpointWatts = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.Empty vpp_grd_bat_setpoint_target_position = 42;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppGrdBatSetpointTargetPosition = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 42));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppGrdBatSetpointTargetPosition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppGrdBatSetpointTargetPosition = function() {
  return this.setVppGrdBatSetpointTargetPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppGrdBatSetpointTargetPosition = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.protobuf.Empty internal_temperature_degree_celcius = 43;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getInternalTemperatureDegreeCelcius = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 43));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setInternalTemperatureDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearInternalTemperatureDegreeCelcius = function() {
  return this.setInternalTemperatureDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasInternalTemperatureDegreeCelcius = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.protobuf.Empty chp_setpoint_watts = 44;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getChpSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 44));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setChpSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearChpSetpointWatts = function() {
  return this.setChpSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasChpSetpointWatts = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional google.protobuf.Empty batterie_enable_setpoint_bool = 45;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getBatterieEnableSetpointBool = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 45));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setBatterieEnableSetpointBool = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearBatterieEnableSetpointBool = function() {
  return this.setBatterieEnableSetpointBool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasBatterieEnableSetpointBool = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.protobuf.Empty eza_suggestion_watts = 46;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaSuggestionWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 46));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaSuggestionWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaSuggestionWatts = function() {
  return this.setEzaSuggestionWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaSuggestionWatts = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.protobuf.Empty eza_forward_setpoint_watts = 47;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaForwardSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 47));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaForwardSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaForwardSetpointWatts = function() {
  return this.setEzaForwardSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaForwardSetpointWatts = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional google.protobuf.Empty eza_forward_setpoint_volt_ampere_reactive = 48;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaForwardSetpointVoltAmpereReactive = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 48));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaForwardSetpointVoltAmpereReactive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaForwardSetpointVoltAmpereReactive = function() {
  return this.setEzaForwardSetpointVoltAmpereReactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaForwardSetpointVoltAmpereReactive = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.protobuf.Empty inverter_setpoint_volt_ampere_reactive = 49;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getInverterSetpointVoltAmpereReactive = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 49));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setInverterSetpointVoltAmpereReactive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearInverterSetpointVoltAmpereReactive = function() {
  return this.setInverterSetpointVoltAmpereReactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasInverterSetpointVoltAmpereReactive = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional google.protobuf.Empty electrolyzer_setpoint_watts = 50;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getElectrolyzerSetpointWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 50));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setElectrolyzerSetpointWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearElectrolyzerSetpointWatts = function() {
  return this.setElectrolyzerSetpointWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasElectrolyzerSetpointWatts = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional google.protobuf.Empty humidity_percent = 51;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getHumidityPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 51));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setHumidityPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearHumidityPercent = function() {
  return this.setHumidityPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasHumidityPercent = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional google.protobuf.Empty acc_cool_out_watt_hours = 52;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getAccCoolOutWattHours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 52));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setAccCoolOutWattHours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearAccCoolOutWattHours = function() {
  return this.setAccCoolOutWattHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasAccCoolOutWattHours = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional google.protobuf.Empty acc_heat_out_watt_hours = 53;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getAccHeatOutWattHours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 53));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setAccHeatOutWattHours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearAccHeatOutWattHours = function() {
  return this.setAccHeatOutWattHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasAccHeatOutWattHours = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional google.protobuf.Empty heat_out_watts = 54;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getHeatOutWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 54));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setHeatOutWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearHeatOutWatts = function() {
  return this.setHeatOutWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasHeatOutWatts = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional google.protobuf.Empty fan_speed_percent = 55;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getFanSpeedPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 55));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setFanSpeedPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearFanSpeedPercent = function() {
  return this.setFanSpeedPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasFanSpeedPercent = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional google.protobuf.Empty tmp_degree_celcius = 56;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getTmpDegreeCelcius = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 56));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setTmpDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearTmpDegreeCelcius = function() {
  return this.setTmpDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasTmpDegreeCelcius = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional google.protobuf.Empty incoming_tmp_degree_celcius = 57;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getIncomingTmpDegreeCelcius = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 57));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setIncomingTmpDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearIncomingTmpDegreeCelcius = function() {
  return this.setIncomingTmpDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasIncomingTmpDegreeCelcius = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional google.protobuf.Empty outgoing_tmp_degree_celcius = 58;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getOutgoingTmpDegreeCelcius = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 58));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setOutgoingTmpDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearOutgoingTmpDegreeCelcius = function() {
  return this.setOutgoingTmpDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasOutgoingTmpDegreeCelcius = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional google.protobuf.Empty environment_tmp_degree_celcius = 59;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEnvironmentTmpDegreeCelcius = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 59));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEnvironmentTmpDegreeCelcius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 59, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEnvironmentTmpDegreeCelcius = function() {
  return this.setEnvironmentTmpDegreeCelcius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEnvironmentTmpDegreeCelcius = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional google.protobuf.Empty eza_state_of_charge_listener_percent = 60;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaStateOfChargeListenerPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 60));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaStateOfChargeListenerPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 60, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaStateOfChargeListenerPercent = function() {
  return this.setEzaStateOfChargeListenerPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaStateOfChargeListenerPercent = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional google.protobuf.Empty eza_max_discharge_listener_watts = 61;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaMaxDischargeListenerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 61));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaMaxDischargeListenerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 61, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaMaxDischargeListenerWatts = function() {
  return this.setEzaMaxDischargeListenerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaMaxDischargeListenerWatts = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional google.protobuf.Empty max_discharge_watts = 62;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getMaxDischargeWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 62));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setMaxDischargeWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 62, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearMaxDischargeWatts = function() {
  return this.setMaxDischargeWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasMaxDischargeWatts = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional google.protobuf.Empty eza_capacity_battery_listener_watthours = 63;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaCapacityBatteryListenerWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 63));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaCapacityBatteryListenerWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaCapacityBatteryListenerWatthours = function() {
  return this.setEzaCapacityBatteryListenerWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaCapacityBatteryListenerWatthours = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional google.protobuf.Empty eza_forward_switch_prevent_off_grid_bool = 64;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaForwardSwitchPreventOffGridBool = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 64));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaForwardSwitchPreventOffGridBool = function(value) {
  return jspb.Message.setOneofWrapperField(this, 64, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaForwardSwitchPreventOffGridBool = function() {
  return this.setEzaForwardSwitchPreventOffGridBool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaForwardSwitchPreventOffGridBool = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional google.protobuf.Empty switch_prevent_off_grid_setpoint_bool = 65;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getSwitchPreventOffGridSetpointBool = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 65));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setSwitchPreventOffGridSetpointBool = function(value) {
  return jspb.Message.setOneofWrapperField(this, 65, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearSwitchPreventOffGridSetpointBool = function() {
  return this.setSwitchPreventOffGridSetpointBool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasSwitchPreventOffGridSetpointBool = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional google.protobuf.Empty max_charge_watts = 66;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getMaxChargeWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 66));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setMaxChargeWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 66, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearMaxChargeWatts = function() {
  return this.setMaxChargeWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasMaxChargeWatts = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional google.protobuf.Empty vpp_pv_setpoint_response_watts = 67;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getVppPvSetpointResponseWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 67));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setVppPvSetpointResponseWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 67, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearVppPvSetpointResponseWatts = function() {
  return this.setVppPvSetpointResponseWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasVppPvSetpointResponseWatts = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional google.protobuf.Empty eza_power_listener_watts = 68;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaPowerListenerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 68));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaPowerListenerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 68, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaPowerListenerWatts = function() {
  return this.setEzaPowerListenerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaPowerListenerWatts = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional google.protobuf.Empty eza_reactive_power_listener_volt_ampere_reactive = 69;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaReactivePowerListenerVoltAmpereReactive = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 69));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaReactivePowerListenerVoltAmpereReactive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 69, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaReactivePowerListenerVoltAmpereReactive = function() {
  return this.setEzaReactivePowerListenerVoltAmpereReactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaReactivePowerListenerVoltAmpereReactive = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional google.protobuf.Empty eza_apparent_power_listener_volt_ampere = 70;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaApparentPowerListenerVoltAmpere = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 70));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaApparentPowerListenerVoltAmpere = function(value) {
  return jspb.Message.setOneofWrapperField(this, 70, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaApparentPowerListenerVoltAmpere = function() {
  return this.setEzaApparentPowerListenerVoltAmpere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaApparentPowerListenerVoltAmpere = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional google.protobuf.Empty eza_power_rating_listener_watts = 71;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.getEzaPowerRatingListenerWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 71));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.setEzaPowerRatingListenerWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 71, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.clearEzaPowerRatingListenerWatts = function() {
  return this.setEzaPowerRatingListenerWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.prototype.hasEzaPowerRatingListenerWatts = function() {
  return jspb.Message.getField(this, 71) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.TypeCase = {
  TYPE_NOT_SET: 0,
  POWER_AC_WATTS: 1,
  SUPPLY_WATTHOURS: 2,
  DEMAND_WATTHOURS: 3,
  VOLTAGE_AC_VOLT: 4,
  CURRENT_AC_AMPERE: 5,
  APPARENT_POWER: 6,
  REACTIVE_POWER: 7,
  POWERFACTOR: 8,
  HARMONIC_DISTORTION_PN_CURRENT_PERCENT: 9,
  HARMONIC_DISTORTION_PN_VOLTAGE_PERCENT: 10
};

/**
 * @return {proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.TypeCase}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    powerAcWatts: (f = msg.getPowerAcWatts()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    supplyWatthours: (f = msg.getSupplyWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    demandWatthours: (f = msg.getDemandWatthours()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    voltageAcVolt: (f = msg.getVoltageAcVolt()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    currentAcAmpere: (f = msg.getCurrentAcAmpere()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    apparentPower: (f = msg.getApparentPower()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    reactivePower: (f = msg.getReactivePower()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    powerfactor: (f = msg.getPowerfactor()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    harmonicDistortionPnCurrentPercent: (f = msg.getHarmonicDistortionPnCurrentPercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    harmonicDistortionPnVoltagePercent: (f = msg.getHarmonicDistortionPnVoltagePercent()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription;
  return proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPowerAcWatts(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSupplyWatthours(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setDemandWatthours(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setVoltageAcVolt(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setCurrentAcAmpere(value);
      break;
    case 6:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setApparentPower(value);
      break;
    case 7:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setReactivePower(value);
      break;
    case 8:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPowerfactor(value);
      break;
    case 9:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHarmonicDistortionPnCurrentPercent(value);
      break;
    case 10:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHarmonicDistortionPnVoltagePercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPowerAcWatts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSupplyWatthours();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getDemandWatthours();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getVoltageAcVolt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getCurrentAcAmpere();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getApparentPower();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getReactivePower();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPowerfactor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHarmonicDistortionPnCurrentPercent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHarmonicDistortionPnVoltagePercent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Empty power_ac_watts = 1;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getPowerAcWatts = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setPowerAcWatts = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearPowerAcWatts = function() {
  return this.setPowerAcWatts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasPowerAcWatts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty supply_watthours = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getSupplyWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setSupplyWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearSupplyWatthours = function() {
  return this.setSupplyWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasSupplyWatthours = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty demand_watthours = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getDemandWatthours = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setDemandWatthours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearDemandWatthours = function() {
  return this.setDemandWatthours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasDemandWatthours = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty voltage_ac_volt = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getVoltageAcVolt = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setVoltageAcVolt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearVoltageAcVolt = function() {
  return this.setVoltageAcVolt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasVoltageAcVolt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty current_ac_ampere = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getCurrentAcAmpere = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setCurrentAcAmpere = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearCurrentAcAmpere = function() {
  return this.setCurrentAcAmpere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasCurrentAcAmpere = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Empty apparent_power = 6;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getApparentPower = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 6));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setApparentPower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearApparentPower = function() {
  return this.setApparentPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasApparentPower = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Empty reactive_power = 7;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getReactivePower = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 7));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setReactivePower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearReactivePower = function() {
  return this.setReactivePower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasReactivePower = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Empty powerfactor = 8;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getPowerfactor = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 8));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setPowerfactor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearPowerfactor = function() {
  return this.setPowerfactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasPowerfactor = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Empty harmonic_distortion_pn_current_percent = 9;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getHarmonicDistortionPnCurrentPercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 9));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setHarmonicDistortionPnCurrentPercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearHarmonicDistortionPnCurrentPercent = function() {
  return this.setHarmonicDistortionPnCurrentPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasHarmonicDistortionPnCurrentPercent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Empty harmonic_distortion_pn_voltage_percent = 10;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.getHarmonicDistortionPnVoltagePercent = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 10));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.setHarmonicDistortionPnVoltagePercent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.clearHarmonicDistortionPnVoltagePercent = function() {
  return this.setHarmonicDistortionPnVoltagePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.prototype.hasHarmonicDistortionPnVoltagePercent = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.TypeCase = {
  TYPE_NOT_SET: 0,
  LPHD_ADD_INFO: 1
};

/**
 * @return {proto.de.mypowergrid.ems.topology.StringQuantityDescription.TypeCase}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.StringQuantityDescription.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.StringQuantityDescription.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.StringQuantityDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.StringQuantityDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    lphdAddInfo: (f = msg.getLphdAddInfo()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.StringQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.StringQuantityDescription;
  return proto.de.mypowergrid.ems.topology.StringQuantityDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.StringQuantityDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.StringQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setLphdAddInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.StringQuantityDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.StringQuantityDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLphdAddInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Empty lphd_add_info = 1;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.getLphdAddInfo = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StringQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.setLphdAddInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.StringQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StringQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.clearLphdAddInfo = function() {
  return this.setLphdAddInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StringQuantityDescription.prototype.hasLphdAddInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.TypeCase = {
  TYPE_NOT_SET: 0,
  PCS_CCU_STATE: 1,
  PCS_IPU_STATE: 2,
  PCS_MCU_STATE: 3,
  WALLBOX_STATE: 4,
  GRID_STATE: 5,
  AIR_CONDITIONER_STATE: 6,
  FIRE_ALARM_STATE: 7,
  CONTAINER_DOOR_STATE: 8,
  CONTAINER_SYNC_BOX_STATE: 9,
  CONTAINER_STORAGE_BOX_STATE: 10,
  HEALTH_STATE: 11,
  EZA_GRID_STATE_LISTENER: 12,
  EZA_HEALTH_STATE_LISTENER: 13,
  KACO_INVERTER_STATE: 14,
  KACO_PRECHARGE_UNIT_STATE: 15,
  KACO_PRECHARGE_UNIT_ERROR: 16
};

/**
 * @return {proto.de.mypowergrid.ems.topology.StatusQuantityDescription.TypeCase}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.StatusQuantityDescription.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.StatusQuantityDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    pcsCcuState: (f = msg.getPcsCcuState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    pcsIpuState: (f = msg.getPcsIpuState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    pcsMcuState: (f = msg.getPcsMcuState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    wallboxState: (f = msg.getWallboxState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    gridState: (f = msg.getGridState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    airConditionerState: (f = msg.getAirConditionerState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    fireAlarmState: (f = msg.getFireAlarmState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    containerDoorState: (f = msg.getContainerDoorState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    containerSyncBoxState: (f = msg.getContainerSyncBoxState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    containerStorageBoxState: (f = msg.getContainerStorageBoxState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    healthState: (f = msg.getHealthState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaGridStateListener: (f = msg.getEzaGridStateListener()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ezaHealthStateListener: (f = msg.getEzaHealthStateListener()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    kacoInverterState: (f = msg.getKacoInverterState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    kacoPrechargeUnitState: (f = msg.getKacoPrechargeUnitState()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    kacoPrechargeUnitError: (f = msg.getKacoPrechargeUnitError()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.StatusQuantityDescription;
  return proto.de.mypowergrid.ems.topology.StatusQuantityDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPcsCcuState(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPcsIpuState(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPcsMcuState(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setWallboxState(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGridState(value);
      break;
    case 6:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setAirConditionerState(value);
      break;
    case 7:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFireAlarmState(value);
      break;
    case 8:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setContainerDoorState(value);
      break;
    case 9:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setContainerSyncBoxState(value);
      break;
    case 10:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setContainerStorageBoxState(value);
      break;
    case 11:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setHealthState(value);
      break;
    case 12:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaGridStateListener(value);
      break;
    case 13:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEzaHealthStateListener(value);
      break;
    case 14:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setKacoInverterState(value);
      break;
    case 15:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setKacoPrechargeUnitState(value);
      break;
    case 16:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setKacoPrechargeUnitError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.StatusQuantityDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPcsCcuState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPcsIpuState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPcsMcuState();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getWallboxState();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGridState();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getAirConditionerState();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getFireAlarmState();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getContainerDoorState();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getContainerSyncBoxState();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getContainerStorageBoxState();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getHealthState();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaGridStateListener();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getEzaHealthStateListener();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getKacoInverterState();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getKacoPrechargeUnitState();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getKacoPrechargeUnitError();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Empty pcs_ccu_state = 1;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getPcsCcuState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setPcsCcuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearPcsCcuState = function() {
  return this.setPcsCcuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasPcsCcuState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty pcs_ipu_state = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getPcsIpuState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setPcsIpuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearPcsIpuState = function() {
  return this.setPcsIpuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasPcsIpuState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty pcs_mcu_state = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getPcsMcuState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setPcsMcuState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearPcsMcuState = function() {
  return this.setPcsMcuState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasPcsMcuState = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty wallbox_state = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getWallboxState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setWallboxState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearWallboxState = function() {
  return this.setWallboxState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasWallboxState = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty grid_state = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getGridState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setGridState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearGridState = function() {
  return this.setGridState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasGridState = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Empty air_conditioner_state = 6;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getAirConditionerState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 6));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setAirConditionerState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearAirConditionerState = function() {
  return this.setAirConditionerState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasAirConditionerState = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Empty fire_alarm_state = 7;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getFireAlarmState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 7));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setFireAlarmState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearFireAlarmState = function() {
  return this.setFireAlarmState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasFireAlarmState = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Empty container_door_state = 8;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getContainerDoorState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 8));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setContainerDoorState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearContainerDoorState = function() {
  return this.setContainerDoorState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasContainerDoorState = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Empty container_sync_box_state = 9;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getContainerSyncBoxState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 9));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setContainerSyncBoxState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearContainerSyncBoxState = function() {
  return this.setContainerSyncBoxState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasContainerSyncBoxState = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Empty container_storage_box_state = 10;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getContainerStorageBoxState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 10));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setContainerStorageBoxState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearContainerStorageBoxState = function() {
  return this.setContainerStorageBoxState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasContainerStorageBoxState = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Empty health_state = 11;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getHealthState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 11));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setHealthState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearHealthState = function() {
  return this.setHealthState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasHealthState = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Empty eza_grid_state_listener = 12;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getEzaGridStateListener = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 12));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setEzaGridStateListener = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearEzaGridStateListener = function() {
  return this.setEzaGridStateListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasEzaGridStateListener = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Empty eza_health_state_listener = 13;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getEzaHealthStateListener = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 13));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setEzaHealthStateListener = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearEzaHealthStateListener = function() {
  return this.setEzaHealthStateListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasEzaHealthStateListener = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Empty kaco_inverter_state = 14;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getKacoInverterState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 14));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setKacoInverterState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearKacoInverterState = function() {
  return this.setKacoInverterState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasKacoInverterState = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Empty kaco_precharge_unit_state = 15;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getKacoPrechargeUnitState = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 15));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setKacoPrechargeUnitState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearKacoPrechargeUnitState = function() {
  return this.setKacoPrechargeUnitState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasKacoPrechargeUnitState = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Empty kaco_precharge_unit_error = 16;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.getKacoPrechargeUnitError = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 16));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
*/
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.setKacoPrechargeUnitError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.topology.StatusQuantityDescription.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.StatusQuantityDescription} returns this
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.clearKacoPrechargeUnitError = function() {
  return this.setKacoPrechargeUnitError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.StatusQuantityDescription.prototype.hasKacoPrechargeUnitError = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_ = [[1,2,3,6]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.Quantity.TypeCase = {
  TYPE_NOT_SET: 0,
  DOUBLE_MQ: 1,
  THREE_PHASE_MQ: 2,
  STRING_MQ: 3,
  STATUS_MQ: 6
};

/**
 * @return {proto.de.mypowergrid.ems.topology.Quantity.TypeCase}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.Quantity.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Quantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Quantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Quantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    doubleMq: (f = msg.getDoubleMq()) && proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.toObject(includeInstance, f),
    threePhaseMq: (f = msg.getThreePhaseMq()) && proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.toObject(includeInstance, f),
    stringMq: (f = msg.getStringMq()) && proto.de.mypowergrid.ems.topology.StringQuantityDescription.toObject(includeInstance, f),
    statusMq: (f = msg.getStatusMq()) && proto.de.mypowergrid.ems.topology.StatusQuantityDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity}
 */
proto.de.mypowergrid.ems.topology.Quantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Quantity;
  return proto.de.mypowergrid.ems.topology.Quantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Quantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity}
 */
proto.de.mypowergrid.ems.topology.Quantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.DoubleQuantityDescription;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.deserializeBinaryFromReader);
      msg.setDoubleMq(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.deserializeBinaryFromReader);
      msg.setThreePhaseMq(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.StringQuantityDescription;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.StringQuantityDescription.deserializeBinaryFromReader);
      msg.setStringMq(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.topology.StatusQuantityDescription;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.StatusQuantityDescription.deserializeBinaryFromReader);
      msg.setStatusMq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Quantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Quantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Quantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoubleMq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.DoubleQuantityDescription.serializeBinaryToWriter
    );
  }
  f = message.getThreePhaseMq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription.serializeBinaryToWriter
    );
  }
  f = message.getStringMq();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.StringQuantityDescription.serializeBinaryToWriter
    );
  }
  f = message.getStatusMq();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.topology.StatusQuantityDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional DoubleQuantityDescription double_mq = 1;
 * @return {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.getDoubleMq = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.DoubleQuantityDescription, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.DoubleQuantityDescription|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
*/
proto.de.mypowergrid.ems.topology.Quantity.prototype.setDoubleMq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.clearDoubleMq = function() {
  return this.setDoubleMq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.hasDoubleMq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ThreePhaseQuantityDescription three_phase_mq = 2;
 * @return {?proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.getThreePhaseMq = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.ThreePhaseQuantityDescription|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
*/
proto.de.mypowergrid.ems.topology.Quantity.prototype.setThreePhaseMq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.clearThreePhaseMq = function() {
  return this.setThreePhaseMq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.hasThreePhaseMq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StringQuantityDescription string_mq = 3;
 * @return {?proto.de.mypowergrid.ems.topology.StringQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.getStringMq = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.StringQuantityDescription} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.StringQuantityDescription, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.StringQuantityDescription|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
*/
proto.de.mypowergrid.ems.topology.Quantity.prototype.setStringMq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.clearStringMq = function() {
  return this.setStringMq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.hasStringMq = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StatusQuantityDescription status_mq = 6;
 * @return {?proto.de.mypowergrid.ems.topology.StatusQuantityDescription}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.getStatusMq = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.StatusQuantityDescription} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.StatusQuantityDescription, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.StatusQuantityDescription|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
*/
proto.de.mypowergrid.ems.topology.Quantity.prototype.setStatusMq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.topology.Quantity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Quantity} returns this
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.clearStatusMq = function() {
  return this.setStatusMq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Quantity.prototype.hasStatusMq = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Source.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.Source.TypeCase = {
  TYPE_NOT_SET: 0,
  IEC: 2,
  SAMPLING_SYNCHRONIZER: 3
};

/**
 * @return {proto.de.mypowergrid.ems.topology.Source.TypeCase}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.Source.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.Source.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    emsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iec: (f = msg.getIec()) && proto.de.mypowergrid.ems.topology.Source.IecId.toObject(includeInstance, f),
    samplingSynchronizer: (f = msg.getSamplingSynchronizer()) && proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Source;
  return proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmsId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.Source.IecId;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.IecId.deserializeBinaryFromReader);
      msg.setIec(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.deserializeBinaryFromReader);
      msg.setSamplingSynchronizer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.Source.IecId.serializeBinaryToWriter
    );
  }
  f = message.getSamplingSynchronizer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Source.IecId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Source.IecId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.IecId.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalDeviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iecNodePrefix: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iecNodePostfix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Source.IecId}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Source.IecId;
  return proto.de.mypowergrid.ems.topology.Source.IecId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Source.IecId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Source.IecId}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalDeviceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIecNodePrefix(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIecNodePostfix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Source.IecId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Source.IecId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.IecId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIecNodePrefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIecNodePostfix();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string logical_device_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.getLogicalDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.Source.IecId} returns this
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.setLogicalDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iec_node_prefix = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.getIecNodePrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.Source.IecId} returns this
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.setIecNodePrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 iec_node_postfix = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.getIecNodePostfix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.Source.IecId} returns this
 */
proto.de.mypowergrid.ems.topology.Source.IecId.prototype.setIecNodePostfix = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.TypeCase = {
  TYPE_NOT_SET: 0,
  PHYSICAL_DEVICE: 1,
  GRID: 2
};

/**
 * @return {proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.TypeCase}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.toObject = function(includeInstance, msg) {
  var f, obj = {
    physicalDevice: (f = msg.getPhysicalDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f),
    grid: (f = msg.getGrid()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer;
  return proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setPhysicalDevice(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGrid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhysicalDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getGrid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhysicalDevice physical_device = 1;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.getPhysicalDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} returns this
*/
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.setPhysicalDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.clearPhysicalDevice = function() {
  return this.setPhysicalDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.hasPhysicalDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty grid = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.getGrid = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} returns this
*/
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.setGrid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.clearGrid = function() {
  return this.setGrid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer.prototype.hasGrid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ems_id = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.getEmsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.Source} returns this
 */
proto.de.mypowergrid.ems.topology.Source.prototype.setEmsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IecId iec = 2;
 * @return {?proto.de.mypowergrid.ems.topology.Source.IecId}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.getIec = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source.IecId} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source.IecId, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source.IecId|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Source} returns this
*/
proto.de.mypowergrid.ems.topology.Source.prototype.setIec = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.topology.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Source} returns this
 */
proto.de.mypowergrid.ems.topology.Source.prototype.clearIec = function() {
  return this.setIec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.hasIec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SamplingSynchronizer sampling_synchronizer = 3;
 * @return {?proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.getSamplingSynchronizer = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source.SamplingSynchronizer|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.Source} returns this
*/
proto.de.mypowergrid.ems.topology.Source.prototype.setSamplingSynchronizer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.topology.Source.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.Source} returns this
 */
proto.de.mypowergrid.ems.topology.Source.prototype.clearSamplingSynchronizer = function() {
  return this.setSamplingSynchronizer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.Source.prototype.hasSamplingSynchronizer = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceQuantitiesCollectionList: jspb.Message.toObjectList(msg.getSourceQuantitiesCollectionList(),
    proto.de.mypowergrid.ems.topology.SourceQuantities.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection;
  return proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.SourceQuantities;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.SourceQuantities.deserializeBinaryFromReader);
      msg.addSourceQuantitiesCollection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceQuantitiesCollectionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.SourceQuantities.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SourceQuantities source_quantities_collection = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.SourceQuantities>}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.getSourceQuantitiesCollectionList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.SourceQuantities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.SourceQuantities, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.SourceQuantities>} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} returns this
*/
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.setSourceQuantitiesCollectionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities}
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.addSourceQuantitiesCollection = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.topology.SourceQuantities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} returns this
 */
proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.prototype.clearSourceQuantitiesCollectionList = function() {
  return this.setSourceQuantitiesCollectionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.SourceQuantities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.de.mypowergrid.ems.topology.Source.toObject(includeInstance, f),
    quantitiesList: jspb.Message.toObjectList(msg.getQuantitiesList(),
    proto.de.mypowergrid.ems.topology.Quantity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.SourceQuantities;
  return proto.de.mypowergrid.ems.topology.SourceQuantities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Source;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.Quantity;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Quantity.deserializeBinaryFromReader);
      msg.addQuantities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.SourceQuantities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter
    );
  }
  f = message.getQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.Quantity.serializeBinaryToWriter
    );
  }
};


/**
 * optional Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities} returns this
*/
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities} returns this
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Quantity quantities = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.Quantity>}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.getQuantitiesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.Quantity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.Quantity, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.Quantity>} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities} returns this
*/
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.setQuantitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.Quantity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.Quantity}
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.addQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.Quantity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantities} returns this
 */
proto.de.mypowergrid.ems.topology.SourceQuantities.prototype.clearQuantitiesList = function() {
  return this.setQuantitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.SourceQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.de.mypowergrid.ems.topology.Source.toObject(includeInstance, f),
    quantity: (f = msg.getQuantity()) && proto.de.mypowergrid.ems.topology.Quantity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.SourceQuantity;
  return proto.de.mypowergrid.ems.topology.SourceQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Source;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.Quantity;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Quantity.deserializeBinaryFromReader);
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.SourceQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.SourceQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.Quantity.serializeBinaryToWriter
    );
  }
};


/**
 * optional Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity} returns this
*/
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Quantity quantity = 2;
 * @return {?proto.de.mypowergrid.ems.topology.Quantity}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.getQuantity = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Quantity} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Quantity, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Quantity|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity} returns this
*/
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.setQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.clearQuantity = function() {
  return this.setQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SourceQuantity.prototype.hasQuantity = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.PhaseTopology.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.de.mypowergrid.ems.topology.Source.toObject(includeInstance, f),
    sourcePhase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    physicalDeviceOnPhaseList: jspb.Message.toObjectList(msg.getPhysicalDeviceOnPhaseList(),
    proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.toObject, includeInstance),
    swap: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.PhaseTopology;
  return proto.de.mypowergrid.ems.topology.PhaseTopology.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Source;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (reader.readEnum());
      msg.setSourcePhase(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.deserializeBinaryFromReader);
      msg.addPhysicalDeviceOnPhase(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.PhaseTopology.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter
    );
  }
  f = message.getSourcePhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPhysicalDeviceOnPhaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.serializeBinaryToWriter
    );
  }
  f = message.getSwap();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase = {
  ACCUMULATED: 0,
  PHASE_A: 1,
  PHASE_B: 2,
  PHASE_C: 3,
  DONTKNOW: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    physicalDevice: (f = msg.getPhysicalDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f),
    phase: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase;
  return proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setPhysicalDevice(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhysicalDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional PhysicalDevice physical_device = 1;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.getPhysicalDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} returns this
*/
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.setPhysicalDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.clearPhysicalDevice = function() {
  return this.setPhysicalDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.hasPhysicalDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnPhase phase = 2;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.getPhase = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
*/
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnPhase source_phase = 2;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.getSourcePhase = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.setSourcePhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated PhysicalDeviceOnPhase physical_device_on_phase = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase>}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.getPhysicalDeviceOnPhaseList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase>} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
*/
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.setPhysicalDeviceOnPhaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.addPhysicalDeviceOnPhase = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.topology.PhaseTopology.PhysicalDeviceOnPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.clearPhysicalDeviceOnPhaseList = function() {
  return this.setPhysicalDeviceOnPhaseList([]);
};


/**
 * optional bool swap = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.getSwap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology} returns this
 */
proto.de.mypowergrid.ems.topology.PhaseTopology.prototype.setSwap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.de.mypowergrid.ems.topology.Source.toObject(includeInstance, f),
    physicalDevice: (f = msg.getPhysicalDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.SourceToDeviceTopology;
  return proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Source;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setPhysicalDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter
    );
  }
  f = message.getPhysicalDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Source source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.getSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} returns this
*/
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} returns this
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PhysicalDevice physical_device = 2;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.getPhysicalDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} returns this
*/
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.setPhysicalDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology} returns this
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.clearPhysicalDevice = function() {
  return this.setPhysicalDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.prototype.hasPhysicalDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Topology.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Topology.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Topology} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Topology.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseTopologiesList: jspb.Message.toObjectList(msg.getPhaseTopologiesList(),
    proto.de.mypowergrid.ems.topology.PhaseTopology.toObject, includeInstance),
    sourceToDeviceTopologiesList: jspb.Message.toObjectList(msg.getSourceToDeviceTopologiesList(),
    proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.topology.Topology.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Topology;
  return proto.de.mypowergrid.ems.topology.Topology.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Topology} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.topology.Topology.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConfigId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.PhaseTopology;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhaseTopology.deserializeBinaryFromReader);
      msg.addPhaseTopologies(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.SourceToDeviceTopology;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.deserializeBinaryFromReader);
      msg.addSourceToDeviceTopologies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Topology.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Topology} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Topology.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPhaseTopologiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.PhaseTopology.serializeBinaryToWriter
    );
  }
  f = message.getSourceToDeviceTopologiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.SourceToDeviceTopology.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 config_id = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.getConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.Topology} returns this
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PhaseTopology phase_topologies = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology>}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.getPhaseTopologiesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.PhaseTopology, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.PhaseTopology>} value
 * @return {!proto.de.mypowergrid.ems.topology.Topology} returns this
*/
proto.de.mypowergrid.ems.topology.Topology.prototype.setPhaseTopologiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.addPhaseTopologies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.PhaseTopology, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.Topology} returns this
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.clearPhaseTopologiesList = function() {
  return this.setPhaseTopologiesList([]);
};


/**
 * repeated SourceToDeviceTopology source_to_device_topologies = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology>}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.getSourceToDeviceTopologiesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.SourceToDeviceTopology, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology>} value
 * @return {!proto.de.mypowergrid.ems.topology.Topology} returns this
*/
proto.de.mypowergrid.ems.topology.Topology.prototype.setSourceToDeviceTopologiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.SourceToDeviceTopology}
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.addSourceToDeviceTopologies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.topology.SourceToDeviceTopology, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.Topology} returns this
 */
proto.de.mypowergrid.ems.topology.Topology.prototype.clearSourceToDeviceTopologiesList = function() {
  return this.setSourceToDeviceTopologiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceQuantity: (f = msg.getSourceQuantity()) && proto.de.mypowergrid.ems.topology.SourceQuantity.toObject(includeInstance, f),
    timeresolution: jspb.Message.getFieldWithDefault(msg, 3, 0),
    onPhase: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity;
  return proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorName(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.SourceQuantity;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.SourceQuantity.deserializeBinaryFromReader);
      msg.setSourceQuantity(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution} */ (reader.readEnum());
      msg.setTimeresolution(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (reader.readEnum());
      msg.setOnPhase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceQuantity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.SourceQuantity.serializeBinaryToWriter
    );
  }
  f = message.getTimeresolution();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOnPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution = {
  INSTANT: 0,
  ONE_MINUTE: 1,
  ON_CHANGE: 2
};

/**
 * optional string sensor_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.getSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.setSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SourceQuantity source_quantity = 2;
 * @return {?proto.de.mypowergrid.ems.topology.SourceQuantity}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.getSourceQuantity = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.SourceQuantity} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.SourceQuantity, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.SourceQuantity|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} returns this
*/
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.setSourceQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.clearSourceQuantity = function() {
  return this.setSourceQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.hasSourceQuantity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timeresolution timeresolution = 3;
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.getTimeresolution = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.Timeresolution} value
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.setTimeresolution = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PhaseTopology.OnPhase on_phase = 4;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase}
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.getOnPhase = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} value
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity} returns this
 */
proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.prototype.setOnPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.repeatedFields_ = [2,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceQuantitiesCollection: (f = msg.getSourceQuantitiesCollection()) && proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.toObject(includeInstance, f),
    sensorNameOfSourceQuantityCollectionList: jspb.Message.toObjectList(msg.getSensorNameOfSourceQuantityCollectionList(),
    proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.toObject, includeInstance),
    topology: (f = msg.getTopology()) && proto.de.mypowergrid.ems.topology.Topology.toObject(includeInstance, f),
    energyOfPhysicalDeviceList: jspb.Message.toObjectList(msg.getEnergyOfPhysicalDeviceList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.toObject, includeInstance),
    energiesAddUpToGridList: jspb.Message.toObjectList(msg.getEnergiesAddUpToGridList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.deserializeBinaryFromReader);
      msg.setSourceQuantitiesCollection(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.deserializeBinaryFromReader);
      msg.addSensorNameOfSourceQuantityCollection(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.Topology;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Topology.deserializeBinaryFromReader);
      msg.setTopology(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.deserializeBinaryFromReader);
      msg.addEnergyOfPhysicalDevice(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinaryFromReader);
      msg.addEnergiesAddUpToGrid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceQuantitiesCollection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection.serializeBinaryToWriter
    );
  }
  f = message.getSensorNameOfSourceQuantityCollectionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity.serializeBinaryToWriter
    );
  }
  f = message.getTopology();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.Topology.serializeBinaryToWriter
    );
  }
  f = message.getEnergyOfPhysicalDeviceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getEnergiesAddUpToGridList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    energySource: (f = msg.getEnergySource()) && proto.de.mypowergrid.ems.topology.Source.toObject(includeInstance, f),
    onPhase: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Source;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Source.deserializeBinaryFromReader);
      msg.setEnergySource(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (reader.readEnum());
      msg.setOnPhase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergySource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Source.serializeBinaryToWriter
    );
  }
  f = message.getOnPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Source energy_source = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.getEnergySource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.setEnergySource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.clearEnergySource = function() {
  return this.setEnergySource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.hasEnergySource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PhaseTopology.OnPhase on_phase = 2;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.getOnPhase = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseTopology.OnPhase} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.prototype.setOnPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    physicalDevice: (f = msg.getPhysicalDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f),
    addList: jspb.Message.toObjectList(msg.getAddList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.toObject, includeInstance),
    subList: jspb.Message.toObjectList(msg.getSubList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setPhysicalDevice(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinaryFromReader);
      msg.addAdd(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.deserializeBinaryFromReader);
      msg.addSub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhysicalDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getAddList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.serializeBinaryToWriter
    );
  }
  f = message.getSubList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhysicalDevice physical_device = 1;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.getPhysicalDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.setPhysicalDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.clearPhysicalDevice = function() {
  return this.setPhysicalDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.hasPhysicalDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EnergyQuantityOnPhase add = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.getAddList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.setAddList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.addAdd = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.clearAddList = function() {
  return this.setAddList([]);
};


/**
 * repeated EnergyQuantityOnPhase sub = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.getSubList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.setSubList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.addSub = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice.prototype.clearSubList = function() {
  return this.setSubList([]);
};


/**
 * optional SourceQuantitiesCollection source_quantities_collection = 1;
 * @return {?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.getSourceQuantitiesCollection = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.setSourceQuantitiesCollection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.clearSourceQuantitiesCollection = function() {
  return this.setSourceQuantitiesCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.hasSourceQuantitiesCollection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SensorNameOfSourceQuantity sensor_name_of_source_quantity_collection = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.getSensorNameOfSourceQuantityCollectionList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.setSensorNameOfSourceQuantityCollectionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.addSensorNameOfSourceQuantityCollection = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.SensorNameOfSourceQuantity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.clearSensorNameOfSourceQuantityCollectionList = function() {
  return this.setSensorNameOfSourceQuantityCollectionList([]);
};


/**
 * optional Topology topology = 3;
 * @return {?proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.getTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Topology} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Topology, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Topology|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.setTopology = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.clearTopology = function() {
  return this.setTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.hasTopology = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated EnergyOfPhysicalDevice energy_of_physical_device = 4;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.getEnergyOfPhysicalDeviceList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice, 4));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.setEnergyOfPhysicalDeviceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.addEnergyOfPhysicalDevice = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyOfPhysicalDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.clearEnergyOfPhysicalDeviceList = function() {
  return this.setEnergyOfPhysicalDeviceList([]);
};


/**
 * repeated EnergyQuantityOnPhase energies_add_up_to_grid = 5;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.getEnergiesAddUpToGridList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, 5));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.setEnergiesAddUpToGridList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.addEnergiesAddUpToGrid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.EnergyQuantityOnPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatformV2.prototype.clearEnergiesAddUpToGridList = function() {
  return this.setEnergiesAddUpToGridList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Error.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    physicalDevicesList: jspb.Message.toObjectList(msg.getPhysicalDevicesList(),
    proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Error}
 */
proto.de.mypowergrid.ems.topology.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Error;
  return proto.de.mypowergrid.ems.topology.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Error}
 */
proto.de.mypowergrid.ems.topology.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.ErrorType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.addPhysicalDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPhysicalDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorType type = 1;
 * @return {!proto.de.mypowergrid.ems.topology.ErrorType}
 */
proto.de.mypowergrid.ems.topology.Error.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.ErrorType} value
 * @return {!proto.de.mypowergrid.ems.topology.Error} returns this
 */
proto.de.mypowergrid.ems.topology.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated PhysicalDevice physical_devices = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>}
 */
proto.de.mypowergrid.ems.topology.Error.prototype.getPhysicalDevicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.PhysicalDevice>} value
 * @return {!proto.de.mypowergrid.ems.topology.Error} returns this
*/
proto.de.mypowergrid.ems.topology.Error.prototype.setPhysicalDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhysicalDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.Error.prototype.addPhysicalDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.PhysicalDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.Error} returns this
 */
proto.de.mypowergrid.ems.topology.Error.prototype.clearPhysicalDevicesList = function() {
  return this.setPhysicalDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.Meters.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.Meters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.Meters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Meters.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    electricityMetersList: jspb.Message.toObjectList(msg.getElectricityMetersList(),
    proto.de.mypowergrid.ems.topology.ElectricityMeter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.Meters}
 */
proto.de.mypowergrid.ems.topology.Meters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.Meters;
  return proto.de.mypowergrid.ems.topology.Meters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.Meters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.Meters}
 */
proto.de.mypowergrid.ems.topology.Meters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConfigId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.ElectricityMeter;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.ElectricityMeter.deserializeBinaryFromReader);
      msg.addElectricityMeters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.Meters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.Meters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.Meters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getElectricityMetersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.ElectricityMeter.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 config_id = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.getConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.Meters} returns this
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ElectricityMeter electricity_meters = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter>}
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.getElectricityMetersList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.ElectricityMeter, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter>} value
 * @return {!proto.de.mypowergrid.ems.topology.Meters} returns this
*/
proto.de.mypowergrid.ems.topology.Meters.prototype.setElectricityMetersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter}
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.addElectricityMeters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.ElectricityMeter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.Meters} returns this
 */
proto.de.mypowergrid.ems.topology.Meters.prototype.clearElectricityMetersList = function() {
  return this.setElectricityMetersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.toObject = function(includeInstance, msg) {
  var f, obj = {
    measuredDevicesAtPhaseList: jspb.Message.toObjectList(msg.getMeasuredDevicesAtPhaseList(),
    proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.toObject, includeInstance),
    measuresPower: jspb.Message.getFieldWithDefault(msg, 2, 0),
    measuresSupply: jspb.Message.getFieldWithDefault(msg, 3, 0),
    measuresDemand: jspb.Message.getFieldWithDefault(msg, 4, 0),
    meterId: (f = msg.getMeterId()) && proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.toObject(includeInstance, f),
    phaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.ElectricityMeter;
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.deserializeBinaryFromReader);
      msg.addMeasuredDevicesAtPhase(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (reader.readEnum());
      msg.setMeasuresPower(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (reader.readEnum());
      msg.setMeasuresSupply(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (reader.readEnum());
      msg.setMeasuresDemand(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.topology.ElectricityMeter.Id;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.deserializeBinaryFromReader);
      msg.setMeterId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.ElectricityMeter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasuredDevicesAtPhaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.serializeBinaryToWriter
    );
  }
  f = message.getMeasuresPower();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMeasuresSupply();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMeasuresDemand();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMeterId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.serializeBinaryToWriter
    );
  }
  f = message.getPhaseBalance();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f),
    phaseA: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    phaseB: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    phaseC: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase;
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseA(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseB(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseC(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getPhaseA();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPhaseB();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPhaseC();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional PhysicalDevice device = 1;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.getDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} returns this
*/
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool phase_a = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.getPhaseA = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.setPhaseA = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool phase_b = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.getPhaseB = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.setPhaseB = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool phase_c = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.getPhaseC = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase.prototype.setPhaseC = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalDeviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iecNodePrefix: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iecNodePostfix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.ElectricityMeter.Id;
  return proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalDeviceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIecNodePrefix(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIecNodePostfix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIecNodePrefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIecNodePostfix();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string logical_device_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.getLogicalDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.setLogicalDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iec_node_prefix = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.getIecNodePrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.setIecNodePrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 iec_node_postfix = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.getIecNodePostfix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.prototype.setIecNodePostfix = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MeasuredDeviceAtPhase measured_devices_at_phase = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase>}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getMeasuredDevicesAtPhaseList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase>} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
*/
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setMeasuredDevicesAtPhaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.addMeasuredDevicesAtPhase = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.topology.ElectricityMeter.MeasuredDeviceAtPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.clearMeasuredDevicesAtPhaseList = function() {
  return this.setMeasuredDevicesAtPhaseList([]);
};


/**
 * optional PhaseType measures_power = 2;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseType}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getMeasuresPower = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseType} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setMeasuresPower = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PhaseType measures_supply = 3;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseType}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getMeasuresSupply = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseType} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setMeasuresSupply = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PhaseType measures_demand = 4;
 * @return {!proto.de.mypowergrid.ems.topology.PhaseType}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getMeasuresDemand = function() {
  return /** @type {!proto.de.mypowergrid.ems.topology.PhaseType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.PhaseType} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setMeasuresDemand = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Id meter_id = 5;
 * @return {?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getMeterId = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.ElectricityMeter.Id, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
*/
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setMeterId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.clearMeterId = function() {
  return this.setMeterId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.hasMeterId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool phase_balance = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.getPhaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.ElectricityMeter} returns this
 */
proto.de.mypowergrid.ems.topology.ElectricityMeter.prototype.setPhaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.toObject = function(includeInstance, msg) {
  var f, obj = {
    meters: (f = msg.getMeters()) && proto.de.mypowergrid.ems.topology.Meters.toObject(includeInstance, f),
    deviceFromMeterList: jspb.Message.toObjectList(msg.getDeviceFromMeterList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.toObject, includeInstance),
    metersAddUpToGridList: jspb.Message.toObjectList(msg.getMetersAddUpToGridList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatform;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.Meters;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.Meters.deserializeBinaryFromReader);
      msg.setMeters(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.deserializeBinaryFromReader);
      msg.addDeviceFromMeter(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinaryFromReader);
      msg.addMetersAddUpToGrid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.Meters.serializeBinaryToWriter
    );
  }
  f = message.getDeviceFromMeterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.serializeBinaryToWriter
    );
  }
  f = message.getMetersAddUpToGridList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorNameSupply: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sensorNameDemand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    meterId: (f = msg.getMeterId()) && proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.toObject(includeInstance, f),
    phaseA: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    phaseB: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    phaseC: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorNameSupply(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorNameDemand(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.ElectricityMeter.Id;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.deserializeBinaryFromReader);
      msg.setMeterId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseA(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseB(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseC(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorNameSupply();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSensorNameDemand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMeterId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.ElectricityMeter.Id.serializeBinaryToWriter
    );
  }
  f = message.getPhaseA();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPhaseB();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPhaseC();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string sensor_name_supply = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getSensorNameSupply = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setSensorNameSupply = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sensor_name_demand = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getSensorNameDemand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setSensorNameDemand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ElectricityMeter.Id meter_id = 3;
 * @return {?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getMeterId = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.ElectricityMeter.Id, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.ElectricityMeter.Id|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setMeterId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.clearMeterId = function() {
  return this.setMeterId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.hasMeterId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool phase_a = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getPhaseA = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setPhaseA = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool phase_b = 5;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getPhaseB = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setPhaseB = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool phase_c = 6;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.getPhaseC = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.prototype.setPhaseC = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.toObject = function(includeInstance, msg) {
  var f, obj = {
    physicalDevice: (f = msg.getPhysicalDevice()) && proto.de.mypowergrid.ems.topology.PhysicalDevice.toObject(includeInstance, f),
    addList: jspb.Message.toObjectList(msg.getAddList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.toObject, includeInstance),
    subList: jspb.Message.toObjectList(msg.getSubList(),
    proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter;
  return proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.topology.PhysicalDevice;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.PhysicalDevice.deserializeBinaryFromReader);
      msg.setPhysicalDevice(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinaryFromReader);
      msg.addAdd(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.deserializeBinaryFromReader);
      msg.addSub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhysicalDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.topology.PhysicalDevice.serializeBinaryToWriter
    );
  }
  f = message.getAddList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.serializeBinaryToWriter
    );
  }
  f = message.getSubList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhysicalDevice physical_device = 1;
 * @return {?proto.de.mypowergrid.ems.topology.PhysicalDevice}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.getPhysicalDevice = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.PhysicalDevice} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.PhysicalDevice, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.PhysicalDevice|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.setPhysicalDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.clearPhysicalDevice = function() {
  return this.setPhysicalDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.hasPhysicalDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MeterSensor add = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.getAddList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.setAddList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.addAdd = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.clearAddList = function() {
  return this.setAddList([]);
};


/**
 * repeated MeterSensor sub = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.getSubList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.setSubList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.addSub = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter.prototype.clearSubList = function() {
  return this.setSubList([]);
};


/**
 * optional Meters meters = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Meters}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.getMeters = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Meters} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.topology.Meters, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Meters|undefined} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.setMeters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.clearMeters = function() {
  return this.setMeters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.hasMeters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeviceFromMeter device_from_meter = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.getDeviceFromMeterList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.setDeviceFromMeterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.addDeviceFromMeter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatform.DeviceFromMeter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.clearDeviceFromMeterList = function() {
  return this.setDeviceFromMeterList([]);
};


/**
 * repeated MeterSensor meters_add_up_to_grid = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.getMetersAddUpToGridList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor>} value
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
*/
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.setMetersAddUpToGridList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor}
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.addMetersAddUpToGrid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.topology.TopologyToPlatform.MeterSensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.topology.TopologyToPlatform} returns this
 */
proto.de.mypowergrid.ems.topology.TopologyToPlatform.prototype.clearMetersAddUpToGridList = function() {
  return this.setMetersAddUpToGridList([]);
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.PhysicalDeviceType = {
  BATTERY: 0,
  CHP: 1,
  WALLBOX: 2,
  FUELCELL: 3,
  HEATPUMP: 4,
  PV: 5,
  USER: 6,
  HEATING_ELEMENT: 7,
  CONTAINER: 8
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.PhaseType = {
  MISSING: 0,
  SINGLE_PHASE: 1,
  THREE_PHASE_ACCUMULATED: 2,
  THREE_PHASE_DIFFERENTIATED: 3
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.TemperatureLocationTag = {
  INTERNAL: 0,
  ENCLOSURE: 1,
  HEATSINK: 2,
  AVERAGE: 3,
  MIN: 4,
  MAX: 5,
  AMBIENT_INSIDE: 6,
  AMBIENT_OUTSIDE: 7
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.topology.ErrorType = {
  NO_ERROR: 0,
  MORE_DEVICES_THAN_METERS: 1,
  DEVICES_ALWAYS_MEASURED_TOGETHER: 2,
  CONFIG_ID_DOES_NOT_MATCH: 3,
  DUPLICATED_SOURCE: 4,
  DUPLICATED_SETPOINT_ON_ONE_PHYSICAL_DEVICE: 5,
  EZA_FORWARD_SETPOINT_NOT_MATCHED: 6
};

goog.object.extend(exports, proto.de.mypowergrid.ems.topology);
