/** @module store/api-loading-status */

import Vue from 'vue'

export const API_LOADING_IDS = {
  ems: {
    defaultPeakObserver: 'ems-default-peak-observer',
    esActuatorStrategies: 'ems-es-actuator-strategies',
    esStrategyActivationRules: 'ems-es-strategy-activation-rules',
    rlmTimeseries: 'rlm-timeseries',
    setpointSourceProperties: 'ems-setpoint-source-properties',
    suitableEnergyServices: 'ems-suitable-ess',
    topology: 'ems-topology',
    topologyQuantities: 'ems-topology-quantities'
  },
  network: {
    knownDevices: 'network-known-devices'
  }
}

const state = {
  loading: false,
  isLoading: {},
  loaded: {},
  count: {}
}

const getters = {
  loading(state) {
    return state.loading
  },
  isLoading: (state) => {
    return (id) => {
      return state.isLoading[id]
    }
  },
  loaded: (state) => {
    return (id) => {
      return state.loaded[id]
    }
  },
  numCalls: (state) => {
    return (id) => {
      return state.count[id] || 0
    }
  }
}

const mutations = {
  SET_LOADING(state, { id, status }) {
    if (state.isLoading[id] === undefined) {
      Vue.set(state.isLoading, id, status)
    } else {
      state.isLoading[id] = status
    }

    if (status) {
      if (state.count[id] === undefined) {
        Vue.set(state.count, id, 1)
      } else {
        state.count[id]++
      }
    }
  },
  SET_LOADED(state, { id, status }) {
    if (state.loaded[id] === undefined) {
      Vue.set(state.loaded, id, status)
    } else {
      state.loaded[id] = status
    }
  },
  STARTED(state) {
    state.loading = true
  },
  STOPPED(state) {
    state.loading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
