<template>
  <div>
    <CCardText>
      <h6 class="text-muted text-break text-left">
        {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.profileSettingsTitle`) }}
      </h6>
    </CCardText>
    <CCardText class="small m-0 p-0 d-flex justify-content-between">
      <p class="mr-1 d-block d-sm-none">
        <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
      </p>
      <p class="ml-auto text-muted text-break">
        {{ $t(`${tTouPath}.timeProfileConfiguration.selectedTitle`) }}:
        <span class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ tsName }}
        </span>
      </p>
    </CCardText>
    <CForm @submit.prevent>
      <!-- BEGIN: Config for TOU Form  -->
      <!-- BEGIN: TS Type Selection  -->
      <div class="mt-0 mb-1 col-12 p-2 pr-3">
        <CRow form class="form-group">
          <!-- BEGIN: LABEL   -->
          <CCol sm="5" class="custom-form-label">
            {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.label`) }}
          </CCol>
          <!-- END: LABEL   -->
          <!-- prettier-ignore -->
          <CCol class="pt-1 pt-sm-0" sm="2">
            <CSwitch
              color="primary"
              size="sm"
              :checked="isTypeTimeSwitch"
              @update:checked="onTypeUpdate"
            />
          </CCol>
          <!-- BEGIN: Show Type  -->
          <CCol sm="3" class="text-monospace font-weight-bold text-info small text-truncate align-self-center">
            <span v-if="isTypeTimeSwitch">
              {{ $t(`${tTouPath}.timeProfileTable.metaInfo.type.timeSwitch`) }}
            </span>
            <span v-else>
              {{ $t(`${tTouPath}.timeProfileTable.metaInfo.type.generic`) }}
            </span>
          </CCol>
          <!-- END: Show Type  -->
          <!-- BEGIN: Description  -->
          <CCol sm="7" class="offset-sm-5">
            <small class="form-text text-muted d-none d-sm-block">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted w-75 d-block d-sm-none">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.description`) }}
            </small>
          </CCol>
          <!-- END: Description   -->
        </CRow>
      </div>
      <!-- END:   TS Type Selection -->
      <!-- prettier-ignore -->
      <hr class="my-3" >
      <!-- BEGIN: Periodicity Options  -->
      <div class="mt-0 mb-1 col-12 p-2 pr-3">
        <CRow form class="form-group">
          <CCol sm="5" class="custom-form-label col-form-label">
            {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.label`) }}
          </CCol>
          <CInputRadioGroup
            class="col-sm-7 text-break text-muted"
            :options="periodicityOptions"
            :checked.sync="periodicity"
            :custom="true"
            :inline="false"
            @change.native="onPeriodicityChange"
          />
          <CCol sm="7" class="offset-sm-5">
            <small class="form-text text-muted d-none d-sm-block">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.description`) }}
            </small>
          </CCol>
        </CRow>
        <!-- END: Periodicity Options   -->
        <!-- BEGIN: CustomPeriodicity  -->
        <CInput
          v-if="periodicity === 'custom'"
          type="number"
          class="custom-form-label"
          :value.sync="tsConfigForm.periodicityValue"
          :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.label`)"
          :placeholder="$t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.placeholder`)"
          :is-valid="$v.tsConfigForm.periodicityValue.$dirty ? !$v.tsConfigForm.periodicityValue.$error : null"
          :invalid-feedback="
            $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.validation.invalidFeedback.required`)
          "
          :horizontal="styling.horizontal"
          @update:value="onCustomPeriodicityUpdate"
        >
          <template #description>
            <small class="form-text text-muted d-none d-sm-block">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.description`) }}
            </small>
          </template>
        </CInput>
        <!-- END:   CustomPeriodicity  -->
      </div>
      <!-- END:   Periodicity Options  -->
      <!-- prettier-ignore -->
      <hr class="my-3" >
      <!-- BEGIN: Interpolation Options LG viewports -->
      <div class="mt-0 mb-1 col-12 p-2 pr-3 d-none d-sm-block">
        <CRow form class="form-group">
          <CCol sm="5" class="custom-form-label col-form-label">
            {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.interpolation.label`) }}
          </CCol>
          <CInputRadioGroup
            class="col-sm-7 text-break text-muted"
            :options="interpolationOptions"
            :checked.sync="tsConfigForm.interpolation"
            :custom="true"
            :inline="false"
            @change.native="onInterpolationChange"
          />
          <CCol sm="7" class="offset-sm-5">
            <small class="form-text text-muted">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.interpolation.description`) }}
            </small>
          </CCol>
        </CRow>
      </div>
      <!-- END:   Interpolation Options LG viewports -->
      <!-- BEGIN: Interpolation Options SM viewports -->
      <div v-if="showInfoDescription" class="mt-0 mb-1 col-12 p-2 pr-3 d-block d-sm-none">
        <!-- prettier-ignore -->
        <hr class="my-3" >
        <CRow form class="form-group">
          <CCol sm="5" class="custom-form-label col-form-label">
            {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.interpolation.label`) }}
          </CCol>
          <CCol sm="7">
            <small class="form-text text-muted">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.interpolation.description`) }}
            </small>
          </CCol>
        </CRow>
      </div>
      <!-- END:   Interpolation Options SM viewports  -->
      <!-- END:   Config for TOU Form  -->
      <!-- prettier-ignore -->
      <hr class="my-3" >
      <!-- BEGIN: Main TOU Form  -->
      <CCardText>
        <h6 class="text-muted text-break text-left text-primary">
          {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timePhaseTitle`) }}
        </h6>
      </CCardText>
      <div class="mt-0 col-12 p-2 pr-3">
        <!-- BEGIN: Type for timeSwitch  -->
        <CRow v-if="isTypeTimeSwitch" form class="form-group">
          <CCol sm="5" class="custom-form-label col-form-label">
            {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.timeSwitch.label`) }}
          </CCol>
          <CInputRadioGroup
            class="col-sm-7 text-break text-muted"
            :options="timeSwitchOptions"
            :checked.sync="tsConfigForm.tsValue"
            :custom="true"
            :inline="true"
          />
          <CCol sm="7" class="offset-sm-5">
            <small class="form-text text-muted d-none d-sm-block font-weight-bold">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.timeSwitch.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none font-weight-bold">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.timeSwitch.description`) }}
            </small>
          </CCol>
        </CRow>
        <!-- END:   Type for On/Off  -->
        <!-- BEGIN: Type for Generic  -->
        <CInput
          v-if="!isTypeTimeSwitch"
          type="number"
          class="custom-form-label"
          :value.sync="tsConfigForm.tsValue"
          :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.generic.label`)"
          :placeholder="$t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.generic.placeholder`)"
          :is-valid="$v.tsConfigForm.tsValue.$dirty ? !$v.tsConfigForm.tsValue.$error : null"
          :invalid-feedback="
            $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.validation.invalidFeedback.required`)
          "
          :horizontal="styling.horizontal"
          step="0.1"
        >
          <template #description>
            <small class="form-text text-muted d-none d-sm-block font-weight-bold">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.generic.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none font-weight-bold">
              {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.tsType.options.generic.description`) }}
            </small>
          </template>
        </CInput>
        <!-- END:   Type for Generic Value  -->
        <!-- BEGIN: TS Datetime Chooser -- No Periodicity -->
        <v-date-picker
          v-if="getPeriodicity(tsName) === 0"
          v-model="tsConfigForm.timestamp"
          :locale="currentLocal"
          mode="dateTime"
          :is-dark="isDark"
          is24hr
          :first-day-of-week="2"
          :min-date="new Date()"
          :popover="{ placement: 'top-end' }"
        >
          <template v-slot="{ inputValue, inputEvents, togglePopover }">
            <CInput
              class="custom-form-label"
              :value="inputValue"
              :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.label`)"
              :horizontal="styling.horizontal"
              readonly
              v-on="inputEvents"
            >
              <template #append>
                <CButton size="sm" color="primary" @click="togglePopover()">
                  <CIcon size="lg" name="cilCalendar" />
                </CButton>
              </template>
              <template #description>
                <CRow>
                  <small class="col-12 form-text text-muted d-none d-sm-block">
                    {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="col-12 form-text text-muted d-block d-sm-none">
                    {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.description`) }}
                  </small>
                </CRow>
              </template>
            </CInput>
          </template>
        </v-date-picker>
        <!-- END:   TS Datetime Chooser -- No Periodicity  -->
        <!-- BEGIN: TS Slider -- Periodicity for hour, day and week -->
        <TouPeriodicitySlider
          v-else
          :key="tsConfigForm.periodicityValue"
          :locale="currentLocal"
          :periodicity="tsConfigForm.periodicityValue || 0"
          :periodicity-mode="periodicity"
          :show-info-description="showInfoDescription"
          @onTimestampPeriodicityUpdate="onTimestampPeriodicityUpdate"
          @onUtcOffsetUpdate="onUtcOffsetUpdate"
        />
        <!-- END: TS Slider --  Periodicity for hour, day and week -->
        <!-- END:   Main TOU Form  -->
        <!-- BEGIN: Action Buttons  -->
        <!-- prettier-ignore -->
        <div class="mt-3 form-row p-2">
          <CButton
            class="mr-2"
            size="sm"
            color="primary"
            @click="onAddTsValues"
          >
            <CIcon size="sm" name="cil-file-add" />
            <span class="align-middle d-none d-sm-inline">
              {{ $t('main.addBtn') }}
            </span>
          </CButton>

          <CButton
            class="ml-auto"
            size="sm"
            color="danger"
            @click="onRemove"
          >
            <CIcon size="sm" name="cil-trash" />
            <span class="align-middle d-none d-sm-inline">
              {{ $t('main.removeAllBtn') }}
            </span>
          </CButton>
        </div>
        <!-- END:   Action Buttons  -->
      </div>
    </CForm>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import TouPeriodicitySlider from '@/components/ems/timeseries/tou-periodicity-slider'
import { newAlertNotificationMsg } from '@/store/modules/notifications'
import { required } from 'vuelidate/lib/validators'

import HelpBtn from '@/components/snippets/help-btn'
import { TIME_UNITS_IN_SECONDS } from '@/view-helper/timeseries/timeseries-helper'

export default {
  name: 'TouTimeseriesConfigForm',
  components: {
    HelpBtn,
    TouPeriodicitySlider
  },
  props: {
    tsName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      periodicity: 'off',
      showInfoDescription: false,
      tsConfigForm: {
        interpolation: 'LINEAR',
        periodicityValue: null,
        tsValue: '1',
        timestamp: this.initialTimestamp(), // timestamp for non-periodic timeseries
        timestampPeriodicity: 0 // timestamp for periodic timeseries
      }
    }
  },
  computed: {
    ...mapGetters('emsTimeseries', ['getInterpolation', 'getPeriodicity', 'getType', 'getTimestampValues']),
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('user', {
      currentLocal: 'lang'
    }),
    interpolationOptions() {
      return [
        {
          value: 'LEFT_BOUNDED',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.left`)
        },
        {
          value: 'LINEAR',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.linear`)
        },
        {
          value: 'RIGHT_BOUNDED',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.right`)
        }
      ]
    },
    isTypeTimeSwitch() {
      return this.getType(this.tsName) === 'TIMESWITCH'
    },
    periodicityOptions() {
      return [
        {
          value: 'off',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.off`)
        },
        {
          value: 'hour',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.hour`)
        },
        {
          value: 'day',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.day`)
        },
        {
          value: 'week',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.week`)
        },
        {
          value: 'custom',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.label`)
        }
      ]
    },
    timeSwitchOptions() {
      return [
        {
          value: '1',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.tsType.options.timeSwitch.options.on`)
        },
        {
          value: '0',
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.tsType.options.timeSwitch.options.off`)
        }
      ]
    }
  },
  watch: {
    tsName() {
      this.initStore()
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-xl-5',
        input: 'col-12 col-xl-7'
      }
    }
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
    this.timeUnitsInSeconds = TIME_UNITS_IN_SECONDS

    this.initStore()
  },
  methods: {
    initStore() {
      if (this.getTimestampValues(this.tsName).length !== 0) {
        this.tsConfigForm.interpolation = this.getInterpolation(this.tsName)
      } else {
        this.tsConfigForm.interpolation = 'LINEAR'
      }

      this.tsConfigForm.periodicityValue = this.getPeriodicity(this.tsName)

      switch (this.tsConfigForm.periodicityValue) {
        case 0:
          this.periodicity = 'off'
          break
        case this.timeUnitsInSeconds.hour:
          this.periodicity = 'hour'
          break
        case this.timeUnitsInSeconds.day:
          this.periodicity = 'day'
          break
        case this.timeUnitsInSeconds.week:
          this.periodicity = 'week'
          break
        default:
          this.periodicity = 'custom'
      }
    },
    initialTimestamp() {
      return moment().format()
    },
    onAddTsValues() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.$store.commit(
          'notifications/NEW_ALERT',
          newAlertNotificationMsg({
            type: 'danger',
            content: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.formInvalid`)
          })
        )

        return
      }

      let timestampValue
      const value = parseFloat(this.tsConfigForm.tsValue)

      // non-periodic timestamp => use specific date
      if (this.getPeriodicity(this.tsName) === 0) {
        timestampValue = {
          timestamp: moment(this.tsConfigForm.timestamp).startOf('minute').unix(),
          value
        }
        // for periodic hour, day, week, custom => use seconds
      } else {
        timestampValue = {
          timestamp: this.tsConfigForm.timestampPeriodicity,
          value
        }
      }

      this.$store.commit('emsTimeseries/ADD_TIMESTAMP_VALUE', {
        tsName: this.tsName,
        timestampValue
      })

      this.tsConfigForm.tsValue = '1'
      this.tsConfigForm.timestamp = this.initialTimestamp()
      this.$v.$reset()
    },
    onCustomPeriodicityUpdate() {
      this.$v.$touch()
      this.tsConfigForm.periodicityValue = parseInt(this.tsConfigForm.periodicityValue)

      this.$store.commit('emsTimeseries/SET_PERIODICITY', {
        tsName: this.tsName,
        periodicity: this.tsConfigForm.periodicityValue,
        touched: true
      })

      this.$log.debug('Succesfully set custom periodicity for timeseries: ', this.tsName)
    },
    onInterpolationChange() {
      this.$store.commit('emsTimeseries/SET_INTERPOLATION', {
        tsName: this.tsName,
        interpolation: this.tsConfigForm.interpolation,
        touched: true
      })

      this.$log.debug('Succesfully set interpolation for timeseries: ', this.tsName)
    },
    onPeriodicityChange() {
      switch (this.periodicity) {
        case 'custom':
          break
        case 'hour':
          this.tsConfigForm.periodicityValue = this.timeUnitsInSeconds.hour
          break
        case 'day':
          this.tsConfigForm.periodicityValue = this.timeUnitsInSeconds.day
          break
        case 'week':
          this.tsConfigForm.periodicityValue = this.timeUnitsInSeconds.week
          break
        default:
          this.tsConfigForm.periodicityValue = 0
      }

      this.$store.commit('emsTimeseries/SET_PERIODICITY', {
        tsName: this.tsName,
        periodicity: this.tsConfigForm.periodicityValue,
        touched: true
      })

      this.$log.debug('Succesfully set periodicity for timeseries: ', this.tsName)
    },
    onRemove() {
      this.$store.commit('emsTimeseries/SET_TIMESTAMP_VALUES', {
        tsName: this.tsName,
        timestampValueList: [],
        touched: true
      })
    },
    onTimestampPeriodicityUpdate(timestamp) {
      this.tsConfigForm.timestampPeriodicity = timestamp
    },
    onTypeUpdate(value) {
      this.$store.commit('emsTimeseries/SET_TYPE', {
        tsName: this.tsName,
        type: value ? 'TIMESWITCH' : 'GENERIC'
      })
    },
    onUtcOffsetUpdate(utcOffset) {
      this.$emit('onUtcOffsetUpdate', utcOffset)
    }
  },
  validations: {
    tsConfigForm: {
      tsValue: {
        required
      },
      periodicityValue: {
        required
      }
    }
  }
}
</script>
