<template>
  <CCard>
    <ApxCardHeader icon="cil-vector" :title="$t('ems.topology.title')" />
    <!-- BEGIN: Topology Body -->
    <CCardBody>
      <EmsEditExistingPhysDevs :key="`phys-devs-${resetCounter}`" />
    </CCardBody>
    <CCardBody>
      <EmsEditTopoConfig :key="`topo-config-${resetCounter}`" />
    </CCardBody>
    <!-- END: BEGIN: Topology Body -->
    <!-- BEGIN CFooter/ Action Buttons -->
    <!-- prettier-ignore -->
    <div class="border-top mt-3 p-2">
      <CButton
        type="submit"
        size="sm"
        color="primary"
        :disabled="loading"
        @click="onSaveTopology"
      >
        <CIcon size="sm" name="cil-save" />
        <span class="align-middle d-none d-sm-inline">
          {{ $t('ems.topology.submitBtn') }}
        </span>
      </CButton>
      <CButton
        class="ml-2 ml-md-5"
        type="reset"
        size="sm"
        color="danger"
        :disabled="loading"
        @click="onResetTopology"
      >
        <CIcon size="sm" name="cil-x-circle" />
        <span class="align-middle d-none d-sm-inline">
          {{ $t('ems.topology.resetBtn') }}
        </span>
      </CButton>
    </div>
    <!-- END CFooter/ Action Buttons -->
    <ConfirmationModal
      :visible.sync="showConfirmSaveTopology"
      color="danger"
      size=""
      :title="$t('ems.topology.confirmation.title')"
      @update:confirmation="onConfirmSaveTopology({ confirmed: $event })"
    >
      <span
        v-for="(txt, i) of textConfirmSaveTopology"
        :key="`${i}-${txt.length}`"
        class="d-inline-block mb-1"
        style="white-space: pre-line"
      >
        {{ txt }}
      </span>
    </ConfirmationModal>
  </CCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { finalizeTopologyChange as emsFinalizeTopologyChange } from '@/api/ems/controller'
import { newAlertNotificationMsg, newToastNotificationMsg } from '@/store/modules/notifications'
import { validateTopology } from '@/validations/ems-topology-validators'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import EmsEditExistingPhysDevs from '@/components/ems/topology/ems-edit-existing-phys-devs'
import EmsEditTopoConfig from '@/components/ems/topology/ems-edit-topo-config'

export default {
  name: 'Topology',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    EmsEditExistingPhysDevs,
    EmsEditTopoConfig
  },
  data() {
    return {
      showConfirmSaveTopology: false,
      textConfirmSaveTopology: [],
      resetCounter: 0
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapState('emsTopologyConfig', ['existingPhysicalDevices', 'gridMtrIecId', 'topology'])
  },
  created() {
    this.init()
  },
  methods: {
    init({ isReset = false } = {}) {
      const calls = []
      this.$store.commit('apiLoadingStatus/STARTED')
      calls.push(
        this.$store.dispatch('emsTopologyConfig/getSourceQuantities').then((msg) => {
          this.$log.debug('Successfully received EMS source quantities')
        })
      )
      calls.push(
        this.$store.dispatch('emsTopologyConfig/initTopology').then((msg) => {
          this.$log.debug('Successfully received EMS topology')
        })
      )

      return Promise.all(calls)
        .then(() => {
          if (isReset) {
            this.showAlert('reset-success')
          }
        })
        .catch((err) => {
          if (isReset) {
            this.showAlert('reset-failed')
          }
          this.onServerError(err)
        })
        .finally(() => {
          if (isReset) {
            this.resetCounter++
          }
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    async onConfirmSaveTopology({ confirmed }) {
      if (!confirmed) {
        return
      }

      this.$store.commit('apiLoadingStatus/STARTED')
      try {
        this.$log.debug('Submitting EMS topology...')
        try {
          await this.$store.dispatch('emsTopologyConfig/setTopology')
        } catch (err) {
          this.$log.error('Failed to save the EMS topology.', err)
          this.showAlert('save-failed', err)
          this.onServerError(err, { type: 'danger' })
          return
        }
        this.$log.debug('Successfully set EMS topology.')
        this.showAlert('save-success')

        this.$log.debug('Restarting the EMS ...')
        this.showAlert('restart-processing')
        try {
          // need to to tell the EMS that the topo update was finished
          await emsFinalizeTopologyChange()
        } catch (err) {
          this.$log.error('Failed EMS restart.', err)
          this.showAlert('restart-failed')
          this.onServerError(err, { type: 'danger' })
          return
        }
        this.$log.debug('Successfully restarted EMS.')
        this.showAlert('restart-success')

        await this.init() // reload to get new topo config ID
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    onResetTopology() {
      this.showAlert('close')
      this.init({ isReset: true })
    },
    onSaveTopology() {
      this.showAlert('close')

      this.textConfirmSaveTopology = []
      const validator = validateTopology(this)
      if (!validator.isValid()) {
        this.$log.info('Topology validation failed')

        if (!validator.isValid('physical-devices')) {
          const pdErr = validator.getError('physical-devices')
          switch (pdErr.type) {
            case 'notEmpty':
              this.textConfirmSaveTopology.push(this.$t('ems.topology.confirmation.empty'))
              break
            case 'hasSupportedPhysicalDevices':
              // should not happen
              this.$log.error(pdErr, this.existingPhysicalDevices)
              break
            case 'hasUser':
              this.textConfirmSaveTopology.push(this.$t('ems.topology.confirmation.noUser'))
              break
          }
        }

        if (!validator.isValid('assignment')) {
          const asgErr = validator.getError('assignment')
          switch (asgErr.type) {
            case 'notEmpty':
              this.textConfirmSaveTopology.push(this.$t('ems.topology.confirmation.noAssignment'))
              break
            case 'allPhysDevsAssigned':
              this.textConfirmSaveTopology.push(this.$t('ems.topology.confirmation.notAllPhysDevsAssigned'))
              break
            case 'hasGrid':
              this.textConfirmSaveTopology.push(this.$t('ems.topology.confirmation.noGrid'))
              break
          }
        }

        this.showConfirmSaveTopology = true

        return
      }

      this.onConfirmSaveTopology({ confirmed: true })
    },
    onServerError(err, opts = {}) {
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err, opts))

      this.$log.warn(err)
      if (err.type) {
        this.$log.warn('EMS rejected topology config due to:', err.type)
      }
    },
    showAlert(type, err) {
      let message = ''
      switch (type) {
        case 'reset-success':
          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: 3000,
              type: 'success',
              title: this.$t('api.success.ok'),
              content: this.$t('ems.topology.alerts.resetSuccess')
            })
          )
          break
        case 'reset-failed':
          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: false,
              type: 'danger',
              title: this.$t('api.failure'),
              content: this.$t('ems.topology.alerts.resetFailed')
            })
          )
          break
        case 'save-success':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'info',
              content: this.$t('ems.topology.alerts.submitSuccess')
            })
          )
          break
        case 'save-failed':
          message = this.$t('ems.topology.alerts.failure')
          if (err && err.type) {
            let devices = []

            if (
              err.type &&
              [
                'DUPLICATED_SETPOINT_ON_ONE_PHYSICAL_DEVICE',
                'DEVICES_ALWAYS_MEASURED_TOGETHER',
                'EZA_FORWARD_SETPOINT_NOT_MATCHED'
              ].includes(err.type)
            ) {
              devices = err.physicalDevicesList
            }
            message += ' '
            if (devices.length) {
              message += this.$t(`ems.topology.alerts.errorTypes.${err.type}`, { devices: devices.join(', ') })
            } else {
              message += this.$t(`ems.topology.alerts.errorTypes.${err.type}`)
            }
          }
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'danger',
              content: message
            })
          )
          break
        case 'restart-processing':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'info',
              content: this.$t('ems.topology.alerts.processing')
            })
          )
          break
        case 'restart-failed':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'danger',
              content: this.$t('ems.topology.alerts.failedProcessing')
            })
          )
          break
        case 'restart-success':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'success',
              content: this.$t('ems.topology.alerts.success')
            })
          )
          break
        default:
          this.$store.commit('notifications/DELETE_ALERT')
      }
    }
  }
}
</script>
