// source: ext/sherlock/one_wire.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.sherlock.GetOneWireInfoRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetOneWireInfoResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireSensor.Type', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireSensorReading', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireSensorReading.ReadingCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireServiceError', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireServiceError.Code', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.OneWireTemperature', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetOneWireInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.displayName = 'proto.de.mypowergrid.sherlock.GetOneWireInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetOneWireInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.displayName = 'proto.de.mypowergrid.sherlock.GetOneWireInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.displayName = 'proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.displayName = 'proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.sherlock.OneWireSensorReading.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.sherlock.OneWireSensorReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.OneWireSensorReading.displayName = 'proto.de.mypowergrid.sherlock.OneWireSensorReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.OneWireSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.OneWireSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.OneWireSensor.displayName = 'proto.de.mypowergrid.sherlock.OneWireSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.OneWireTemperature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.OneWireTemperature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.OneWireTemperature.displayName = 'proto.de.mypowergrid.sherlock.OneWireTemperature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.OneWireServiceError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.OneWireServiceError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.OneWireServiceError.displayName = 'proto.de.mypowergrid.sherlock.OneWireServiceError';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoRequest}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetOneWireInfoRequest;
  return proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoRequest}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.sherlock.OneWireServiceError.toObject(includeInstance, f),
    rawInfo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetOneWireInfoResponse;
  return proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.OneWireServiceError;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.OneWireServiceError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.OneWireServiceError.serializeBinaryToWriter
    );
  }
  f = message.getRawInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional OneWireServiceError error = 1;
 * @return {?proto.de.mypowergrid.sherlock.OneWireServiceError}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.OneWireServiceError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.OneWireServiceError, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.OneWireServiceError|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string raw_info = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.getRawInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireInfoResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetOneWireInfoResponse.prototype.setRawInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest;
  return proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.sherlock.OneWireServiceError.toObject(includeInstance, f),
    sensorReadingsList: jspb.Message.toObjectList(msg.getSensorReadingsList(),
    proto.de.mypowergrid.sherlock.OneWireSensorReading.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse;
  return proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.OneWireServiceError;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.OneWireServiceError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.sherlock.OneWireSensorReading;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.OneWireSensorReading.deserializeBinaryFromReader);
      msg.addSensorReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.OneWireServiceError.serializeBinaryToWriter
    );
  }
  f = message.getSensorReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.sherlock.OneWireSensorReading.serializeBinaryToWriter
    );
  }
};


/**
 * optional OneWireServiceError error = 1;
 * @return {?proto.de.mypowergrid.sherlock.OneWireServiceError}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.OneWireServiceError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.OneWireServiceError, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.OneWireServiceError|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OneWireSensorReading sensor_readings = 2;
 * @return {!Array<!proto.de.mypowergrid.sherlock.OneWireSensorReading>}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.getSensorReadingsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.sherlock.OneWireSensorReading>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.sherlock.OneWireSensorReading, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.sherlock.OneWireSensorReading>} value
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.setSensorReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensorReading=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading}
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.addSensorReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.sherlock.OneWireSensorReading, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetOneWireSensorReadingsResponse.prototype.clearSensorReadingsList = function() {
  return this.setSensorReadingsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.ReadingCase = {
  READING_NOT_SET: 0,
  TEMPERATURE: 2
};

/**
 * @return {proto.de.mypowergrid.sherlock.OneWireSensorReading.ReadingCase}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.getReadingCase = function() {
  return /** @type {proto.de.mypowergrid.sherlock.OneWireSensorReading.ReadingCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.sherlock.OneWireSensorReading.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.OneWireSensorReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensorReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensor: (f = msg.getSensor()) && proto.de.mypowergrid.sherlock.OneWireSensor.toObject(includeInstance, f),
    temperature: (f = msg.getTemperature()) && proto.de.mypowergrid.sherlock.OneWireTemperature.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.OneWireSensorReading;
  return proto.de.mypowergrid.sherlock.OneWireSensorReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensorReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.OneWireSensor;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.OneWireSensor.deserializeBinaryFromReader);
      msg.setSensor(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.sherlock.OneWireTemperature;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.OneWireTemperature.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.OneWireSensorReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensorReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.OneWireSensor.serializeBinaryToWriter
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.sherlock.OneWireTemperature.serializeBinaryToWriter
    );
  }
};


/**
 * optional OneWireSensor sensor = 1;
 * @return {?proto.de.mypowergrid.sherlock.OneWireSensor}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.getSensor = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.OneWireSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.OneWireSensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.OneWireSensor|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading} returns this
*/
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.setSensor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading} returns this
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.clearSensor = function() {
  return this.setSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.hasSensor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OneWireTemperature temperature = 2;
 * @return {?proto.de.mypowergrid.sherlock.OneWireTemperature}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.getTemperature = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.OneWireTemperature} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.OneWireTemperature, 2));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.OneWireTemperature|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading} returns this
*/
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.setTemperature = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.sherlock.OneWireSensorReading.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensorReading} returns this
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.OneWireSensorReading.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.OneWireSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: msg.getAddress_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensor}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.OneWireSensor;
  return proto.de.mypowergrid.sherlock.OneWireSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensor}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.sherlock.OneWireSensor.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.OneWireSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_TEMPERATURE: 1
};

/**
 * optional Type type = 1;
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensor.Type}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.sherlock.OneWireSensor.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.sherlock.OneWireSensor.Type} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensor} returns this
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes address = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes address = 2;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireSensor} returns this
 */
proto.de.mypowergrid.sherlock.OneWireSensor.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.OneWireTemperature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.OneWireTemperature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    resolution: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.OneWireTemperature}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.OneWireTemperature;
  return proto.de.mypowergrid.sherlock.OneWireTemperature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.OneWireTemperature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.OneWireTemperature}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResolution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.OneWireTemperature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.OneWireTemperature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getResolution();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireTemperature} returns this
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional uint32 resolution = 2;
 * @return {number}
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireTemperature} returns this
 */
proto.de.mypowergrid.sherlock.OneWireTemperature.prototype.setResolution = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.OneWireServiceError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.OneWireServiceError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.OneWireServiceError}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.OneWireServiceError;
  return proto.de.mypowergrid.sherlock.OneWireServiceError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.OneWireServiceError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.OneWireServiceError}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.sherlock.OneWireServiceError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.OneWireServiceError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.OneWireServiceError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.Code = {
  NONE: 0,
  GENERIC: 1,
  UNSUPPORTED_TYPE: 2
};

/**
 * optional Code code = 1;
 * @return {!proto.de.mypowergrid.sherlock.OneWireServiceError.Code}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.getCode = function() {
  return /** @type {!proto.de.mypowergrid.sherlock.OneWireServiceError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.sherlock.OneWireServiceError.Code} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireServiceError} returns this
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.OneWireServiceError} returns this
 */
proto.de.mypowergrid.sherlock.OneWireServiceError.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.de.mypowergrid.sherlock);
