/**
 * @module store/auth
 */

import { isEmpty } from 'lodash'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import Vue from 'vue'
import * as licensePbPkg from '@/../lib/proto_js/ext/license/license_pb'
import { getEnumStr } from '@/grpc/parser'
import * as apiAuth from '@/api/auth'
import logger from '@/logger'

/**
 * Defines the number of days before a user is warned,
 * that her license will expire.
 * Of course, the user is also warned, if the license is expired.
 *
 * @type {number}
 */
const EXPIRING_THRESHOLD_IN_DAYS = 30

const state = () => {
  return {
    features: null,
    licenseId: '',
    license: '',
    newUserRequired: null
  }
}

/**
 * Getters for the auth store.
 *
 * The `hasLicense` relies on the `licenseId` (NOT the `license` itself),
 * because the `licenseId` can be queried for a non-authorized user,
 * however, the `license` not.
 *
 * `licenseType` is the type of the license specified in the JWT itself. Note, it should not be confused with the proto `license.LicenseType` enum!
 *
 * `licenseClaims` returns a plain JS `license.License` proto message or `license.?` proto message, whatever is specified as `licenseType` in the JWT.
 *
 */
const getters = {
  hasLicense: (state) => {
    return !isEmpty(state.licenseId)
  },
  decodedLicense: (state) => {
    if (state.license) {
      try {
        return jwtDecode(state.license)
      } catch (err) {
        logger.error('Decoding the JWT license failed.')
        logger.error(err.message)
      }
    }

    return null
  },
  licenseExp: (state, getters) => {
    if (!state.license || !getters.decodedLicense) {
      return null
    }

    const exp = getters.decodedLicense.exp

    return moment.unix(exp)
  },
  isLicenseExpiring: (state, getters) => {
    const exp = getters.licenseExp
    if (!exp) {
      return null
    }

    const diff = exp.diff(moment(), 'days')

    return diff <= EXPIRING_THRESHOLD_IN_DAYS
  },
  licenseNbf: (state, getters) => {
    if (!state.license || !getters.decodedLicense) {
      return null
    }

    return moment.unix(getters.decodedLicense.nbf)
  },
  licenseIat: (state, getters) => {
    if (!state.license || !getters.decodedLicense) {
      return null
    }

    return moment.unix(getters.decodedLicense.iat)
  },
  licenseType: (state, getters) => {
    if (!state.license || !getters.decodedLicense) {
      return null
    }

    return getters.decodedLicense.licenseType
  },
  licenseClaims: (state, getters) => {
    try {
      const dl = getters.decodedLicense
      const lc = dl ? dl.license : null
      const lt = dl ? dl.licenseType : null
      if (!state.license || !lc || !lt) {
        logger.warn('License or license type and/or license claims are missing.')
        return null
      }
      const LicensePb = licensePbPkg[lt]
      if (!LicensePb) {
        throw new Error(`The license type ${lt} is not supported`)
      }
      const claims = LicensePb.deserializeBinary(lc).toObject()
      claims.licenseType = getEnumStr(claims.licenseType, licensePbPkg.LicenseType)

      return claims
    } catch (err) {
      logger.error(err.message)
      return null
    }
  },
  isLicenseSupported: (state, getters) => {
    const lc = getters.licenseClaims
    if (!state.license || !lc) {
      return null
    }

    // check if proto `license.LicenseType` ENUM is known
    // `lc.licenseType` was parsed to String in `getters.licenseClaims`
    return !!lc.licenseType
  },
  getFeatureRestrictions: (state, getters) => {
    return ({ feature }) => {
      const type = getters.licenseClaims?.licenseType

      return state.features?.[feature]?.[type]
    }
  },
  disabledFeatures: (state, getters) => {
    const type = getters.licenseClaims?.licenseType

    const disabledFeatures = []

    for (const feature in state.features) {
      if (!state.features?.[feature]?.[type]?.enabled) {
        disabledFeatures.push(feature)
      }
    }

    return disabledFeatures
  },
  enabledFeatures: (state, getters) => {
    const type = getters.licenseClaims?.licenseType

    const enabledFeatures = []

    for (const feature in state.features) {
      if (state.features?.[feature]?.[type]?.enabled) {
        enabledFeatures.push(feature)
      }
    }

    return enabledFeatures
  },
  omiOnlyEnabled: (state, getters) => {
    const type = getters.licenseClaims?.licenseType

    return state.features?.omi_only?.[type]?.enabled
  }
}

const mutations = {
  SET_FEATURES(state, features) {
    if (!state.features) {
      Vue.set(state, 'features', {})
    }

    for (const feature in features) {
      if (!state.features[feature]) {
        Vue.set(state.features, feature, {})
      }

      for (const licenseType in features[feature]) {
        state.features[feature][licenseType] = features[feature][licenseType]
      }
    }
  },
  SET_LICENSE_ID(state, licenseId) {
    state.licenseId = licenseId
  },
  SET_LICENSE(state, license) {
    state.license = license || ''
  },
  SET_NEW_USER_REQUIRED(state, newUserRequired) {
    state.newUserRequired = newUserRequired
  }
}

/**
 * API call to get the license ID and set its value into the store.
 *
 * @function
 *
 * @return {promise}
 */
function getLicenseId({ commit }) {
  return apiAuth.getLicenseId().then((msg) => {
    commit('SET_LICENSE_ID', msg.getLicenseId())
    commit('SET_NEW_USER_REQUIRED', msg.getNewUserRequired())

    return msg
  })
}

/**
 * API call to get the license and set its bare value into the store.
 *
 * @function
 *
 * @return {promise}
 */
function getLicense({ commit }) {
  return apiAuth.getLicense().then((msg) => {
    commit('SET_LICENSE', msg.getLicense())

    return msg
  })
}

/**
 * API call to update the license (taken from the store),
 * and then get the license ID from the BE (if update successful).
 *
 * @function
 *
 */
async function updateLicense({ state, commit }) {
  const err = await _updateLicense({ commit }, { license: state.license })

  if (err) {
    logger.error('Failed update of license.')
    throw err
  } else {
    logger.info('Successfully updated license.')
  }
}

/**
 * API call to set a license,
 * and to create a new user with password.
 * The user is not auhtenticated yet!
 *
 * @function
 *
 * @param {object} params (all properties are required)
 * @param {string} params.license is the license (JWT)
 * @param {string} params.username
 * @param {string} params.password
 *
 */
async function initialSignUp({ commit }, { license, username, password }) {
  commit('SET_LICENSE', license)
  const err = await _updateLicense({ commit }, { license: license, username, password, init: true })

  if (err) {
    logger.error('Failed initial authentication.')
    throw err
  } else {
    logger.info('Successful initial authentication.')
  }
}

async function getLicenseFeatures({ commit }) {
  const doCommit = (msg) => {
    commit('SET_FEATURES', parseLicenseFeatures(msg.toObject().licenseFeaturesList))
  }
  return apiAuth.getLicenseFeatures().then(doCommit)
}

export default {
  namespaced: true,
  state,
  getters,
  actions: {
    getLicenseFeatures,
    getLicenseId,
    getLicense,
    updateLicense,
    initialSignUp
  },
  mutations
}

// ***
// private
// ***

// @return {Error|null}
//
async function _updateLicense({ commit }, { license, username, password, init = false }) {
  const userParams = {}
  if (init) {
    Object.assign(userParams, { username, password })
  }

  let error = null
  try {
    await apiAuth.updateLicense(license, userParams)
  } catch (err) {
    error = err
  }

  try {
    // a successful init of the license will result into a not empty license ID
    // otherwise the old ID is returned
    const rsp = await apiAuth.getLicenseId()
    commit('SET_LICENSE_ID', rsp.getLicenseId())
  } catch (err) {
    logger.error('Failed to get and set the license ID during license update.')
    logger.error(err.message)
  }

  return error
}

function parseLicenseFeatures(features) {
  const parsedFeatures = {}

  for (const feature of features) {
    const parsedFeature = {}

    for (const [licenseType, restrictions] of feature.licenseTypeRestrictionsMap) {
      // clean fields which equal undefined
      parsedFeature[licenseType] = JSON.parse(JSON.stringify(restrictions))
    }

    parsedFeatures[feature.name] = parsedFeature
  }

  return parsedFeatures
}
