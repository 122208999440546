// source: ext/ems/config/config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_ems_eval_pb = require('../../../ext/ems/eval_pb.js');
goog.object.extend(proto, ext_ems_eval_pb);
var ext_ems_topology_topology_pb = require('../../../ext/ems/topology/topology_pb.js');
goog.object.extend(proto, ext_ems_topology_topology_pb);
var ext_ems_scontroller_scontroller_pb = require('../../../ext/ems/scontroller/scontroller_pb.js');
goog.object.extend(proto, ext_ems_scontroller_scontroller_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.config.BandwidthMode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.BaseSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.BoolVariable', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Controller', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Controller.HostAndPort', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.DescriptionEntry', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.DoubleVariable', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.EmsConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.EnumOptions', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.EnumVariable', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.EvalSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.IecSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.IntegerVariable', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.MeterSensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Notify', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.NotifyPipelineConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.PersistentState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.SamplingSynchronizer', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.SamplingSynchronizers', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Scontroller', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Sensor', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Sensor.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.SensorInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.SensorInfo.Pipeline', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Sensors', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.Summand', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.System', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.System.ExtraNode', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.System.ExtraNode.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.VariableCollection', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.VariableOption', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.VariableOption.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.config.VariableStore', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.EmsConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.EmsConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.EmsConfig.displayName = 'proto.de.mypowergrid.ems.config.EmsConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Scontroller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Scontroller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Scontroller.displayName = 'proto.de.mypowergrid.ems.config.Scontroller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.SamplingSynchronizers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.SamplingSynchronizers.displayName = 'proto.de.mypowergrid.ems.config.SamplingSynchronizers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.SamplingSynchronizer.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.SamplingSynchronizer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.SamplingSynchronizer.displayName = 'proto.de.mypowergrid.ems.config.SamplingSynchronizer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Summand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Summand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Summand.displayName = 'proto.de.mypowergrid.ems.config.Summand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Sensors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.Sensors.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Sensors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Sensors.displayName = 'proto.de.mypowergrid.ems.config.Sensors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Sensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.config.Sensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Sensor.displayName = 'proto.de.mypowergrid.ems.config.Sensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.SensorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.SensorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.SensorInfo.displayName = 'proto.de.mypowergrid.ems.config.SensorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.BaseSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.BaseSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.BaseSensor.displayName = 'proto.de.mypowergrid.ems.config.BaseSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.IecSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.IecSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.IecSensor.displayName = 'proto.de.mypowergrid.ems.config.IecSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.EvalSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.EvalSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.EvalSensor.displayName = 'proto.de.mypowergrid.ems.config.EvalSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.MeterSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.MeterSensor.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.MeterSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.MeterSensor.displayName = 'proto.de.mypowergrid.ems.config.MeterSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.System = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.System.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.System, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.System.displayName = 'proto.de.mypowergrid.ems.config.System';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.System.ExtraNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.config.System.ExtraNode.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.config.System.ExtraNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.System.ExtraNode.displayName = 'proto.de.mypowergrid.ems.config.System.ExtraNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.displayName = 'proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.displayName = 'proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.PersistentState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.PersistentState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.PersistentState.displayName = 'proto.de.mypowergrid.ems.config.PersistentState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.NotifyPipelineConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.NotifyPipelineConfig.displayName = 'proto.de.mypowergrid.ems.config.NotifyPipelineConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Notify = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Notify, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Notify.displayName = 'proto.de.mypowergrid.ems.config.Notify';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Controller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.Controller.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Controller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Controller.displayName = 'proto.de.mypowergrid.ems.config.Controller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.Controller.HostAndPort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.Controller.HostAndPort.displayName = 'proto.de.mypowergrid.ems.config.Controller.HostAndPort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.DescriptionEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.DescriptionEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.DescriptionEntry.displayName = 'proto.de.mypowergrid.ems.config.DescriptionEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.VariableCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.VariableCollection.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.VariableCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.VariableCollection.displayName = 'proto.de.mypowergrid.ems.config.VariableCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.DoubleVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.DoubleVariable.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.DoubleVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.DoubleVariable.displayName = 'proto.de.mypowergrid.ems.config.DoubleVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.IntegerVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.IntegerVariable.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.IntegerVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.IntegerVariable.displayName = 'proto.de.mypowergrid.ems.config.IntegerVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.BoolVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.BoolVariable.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.BoolVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.BoolVariable.displayName = 'proto.de.mypowergrid.ems.config.BoolVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.EnumOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.EnumOptions.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.EnumOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.EnumOptions.displayName = 'proto.de.mypowergrid.ems.config.EnumOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.EnumVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.EnumVariable.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.EnumVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.EnumVariable.displayName = 'proto.de.mypowergrid.ems.config.EnumVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.VariableOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.config.VariableOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.VariableOption.displayName = 'proto.de.mypowergrid.ems.config.VariableOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.config.VariableStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.config.VariableStore.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.config.VariableStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.config.VariableStore.displayName = 'proto.de.mypowergrid.ems.config.VariableStore';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.EmsConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.EmsConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EmsConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensors: (f = msg.getSensors()) && proto.de.mypowergrid.ems.config.Sensors.toObject(includeInstance, f),
    sampmplingSynchronizers: (f = msg.getSampmplingSynchronizers()) && proto.de.mypowergrid.ems.config.SamplingSynchronizers.toObject(includeInstance, f),
    topology: (f = msg.getTopology()) && ext_ems_topology_topology_pb.Topology.toObject(includeInstance, f),
    system: (f = msg.getSystem()) && proto.de.mypowergrid.ems.config.System.toObject(includeInstance, f),
    persistentState: (f = msg.getPersistentState()) && proto.de.mypowergrid.ems.config.PersistentState.toObject(includeInstance, f),
    notify: (f = msg.getNotify()) && proto.de.mypowergrid.ems.config.Notify.toObject(includeInstance, f),
    controller: (f = msg.getController()) && proto.de.mypowergrid.ems.config.Controller.toObject(includeInstance, f),
    scontroller: (f = msg.getScontroller()) && proto.de.mypowergrid.ems.config.Scontroller.toObject(includeInstance, f),
    userVariables: (f = msg.getUserVariables()) && proto.de.mypowergrid.ems.config.VariableStore.toObject(includeInstance, f),
    systemVariables: (f = msg.getSystemVariables()) && proto.de.mypowergrid.ems.config.VariableStore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig}
 */
proto.de.mypowergrid.ems.config.EmsConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.EmsConfig;
  return proto.de.mypowergrid.ems.config.EmsConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.EmsConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig}
 */
proto.de.mypowergrid.ems.config.EmsConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.Sensors;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Sensors.deserializeBinaryFromReader);
      msg.setSensors(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.SamplingSynchronizers;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.SamplingSynchronizers.deserializeBinaryFromReader);
      msg.setSampmplingSynchronizers(value);
      break;
    case 4:
      var value = new ext_ems_topology_topology_pb.Topology;
      reader.readMessage(value,ext_ems_topology_topology_pb.Topology.deserializeBinaryFromReader);
      msg.setTopology(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.config.System;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.System.deserializeBinaryFromReader);
      msg.setSystem(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.config.PersistentState;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.PersistentState.deserializeBinaryFromReader);
      msg.setPersistentState(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.config.Notify;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Notify.deserializeBinaryFromReader);
      msg.setNotify(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.config.Controller;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Controller.deserializeBinaryFromReader);
      msg.setController(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.config.Scontroller;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Scontroller.deserializeBinaryFromReader);
      msg.setScontroller(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.config.VariableStore;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.VariableStore.deserializeBinaryFromReader);
      msg.setUserVariables(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.config.VariableStore;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.VariableStore.deserializeBinaryFromReader);
      msg.setSystemVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.EmsConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.EmsConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EmsConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensors();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.Sensors.serializeBinaryToWriter
    );
  }
  f = message.getSampmplingSynchronizers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.SamplingSynchronizers.serializeBinaryToWriter
    );
  }
  f = message.getTopology();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_ems_topology_topology_pb.Topology.serializeBinaryToWriter
    );
  }
  f = message.getSystem();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.config.System.serializeBinaryToWriter
    );
  }
  f = message.getPersistentState();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.config.PersistentState.serializeBinaryToWriter
    );
  }
  f = message.getNotify();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.config.Notify.serializeBinaryToWriter
    );
  }
  f = message.getController();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.config.Controller.serializeBinaryToWriter
    );
  }
  f = message.getScontroller();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.config.Scontroller.serializeBinaryToWriter
    );
  }
  f = message.getUserVariables();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.config.VariableStore.serializeBinaryToWriter
    );
  }
  f = message.getSystemVariables();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.ems.config.VariableStore.serializeBinaryToWriter
    );
  }
};


/**
 * optional Sensors sensors = 1;
 * @return {?proto.de.mypowergrid.ems.config.Sensors}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getSensors = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.Sensors} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.Sensors, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.Sensors|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setSensors = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearSensors = function() {
  return this.setSensors(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasSensors = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SamplingSynchronizers sampmpling_synchronizers = 3;
 * @return {?proto.de.mypowergrid.ems.config.SamplingSynchronizers}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getSampmplingSynchronizers = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.SamplingSynchronizers} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.SamplingSynchronizers, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.SamplingSynchronizers|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setSampmplingSynchronizers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearSampmplingSynchronizers = function() {
  return this.setSampmplingSynchronizers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasSampmplingSynchronizers = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Topology topology = 4;
 * @return {?proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Topology} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Topology, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Topology|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setTopology = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearTopology = function() {
  return this.setTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasTopology = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional System system = 5;
 * @return {?proto.de.mypowergrid.ems.config.System}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getSystem = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.System} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.System, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.System|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setSystem = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearSystem = function() {
  return this.setSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasSystem = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PersistentState persistent_state = 6;
 * @return {?proto.de.mypowergrid.ems.config.PersistentState}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getPersistentState = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.PersistentState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.PersistentState, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.PersistentState|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setPersistentState = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearPersistentState = function() {
  return this.setPersistentState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasPersistentState = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Notify notify = 7;
 * @return {?proto.de.mypowergrid.ems.config.Notify}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getNotify = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.Notify} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.Notify, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.Notify|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setNotify = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearNotify = function() {
  return this.setNotify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasNotify = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Controller controller = 8;
 * @return {?proto.de.mypowergrid.ems.config.Controller}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getController = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.Controller} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.Controller, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.Controller|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setController = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearController = function() {
  return this.setController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasController = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Scontroller scontroller = 9;
 * @return {?proto.de.mypowergrid.ems.config.Scontroller}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getScontroller = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.Scontroller} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.Scontroller, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.Scontroller|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setScontroller = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearScontroller = function() {
  return this.setScontroller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasScontroller = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional VariableStore user_variables = 10;
 * @return {?proto.de.mypowergrid.ems.config.VariableStore}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getUserVariables = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.VariableStore} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.VariableStore, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.VariableStore|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setUserVariables = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearUserVariables = function() {
  return this.setUserVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasUserVariables = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional VariableStore system_variables = 11;
 * @return {?proto.de.mypowergrid.ems.config.VariableStore}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.getSystemVariables = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.VariableStore} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.VariableStore, 11));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.VariableStore|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
*/
proto.de.mypowergrid.ems.config.EmsConfig.prototype.setSystemVariables = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EmsConfig} returns this
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.clearSystemVariables = function() {
  return this.setSystemVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EmsConfig.prototype.hasSystemVariables = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Scontroller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Scontroller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Scontroller.toObject = function(includeInstance, msg) {
  var f, obj = {
    actuatorStrategies: (f = msg.getActuatorStrategies()) && ext_ems_scontroller_scontroller_pb.ActuatorStrategies.toObject(includeInstance, f),
    strategyDecision: (f = msg.getStrategyDecision()) && ext_ems_scontroller_scontroller_pb.StrategyDecision.toObject(includeInstance, f),
    interval: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Scontroller}
 */
proto.de.mypowergrid.ems.config.Scontroller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Scontroller;
  return proto.de.mypowergrid.ems.config.Scontroller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Scontroller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Scontroller}
 */
proto.de.mypowergrid.ems.config.Scontroller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_scontroller_scontroller_pb.ActuatorStrategies;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.ActuatorStrategies.deserializeBinaryFromReader);
      msg.setActuatorStrategies(value);
      break;
    case 2:
      var value = new ext_ems_scontroller_scontroller_pb.StrategyDecision;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.StrategyDecision.deserializeBinaryFromReader);
      msg.setStrategyDecision(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Scontroller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Scontroller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Scontroller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActuatorStrategies();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_scontroller_scontroller_pb.ActuatorStrategies.serializeBinaryToWriter
    );
  }
  f = message.getStrategyDecision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_ems_scontroller_scontroller_pb.StrategyDecision.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.scontroller.ActuatorStrategies actuator_strategies = 1;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.getActuatorStrategies = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.ActuatorStrategies, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Scontroller} returns this
*/
proto.de.mypowergrid.ems.config.Scontroller.prototype.setActuatorStrategies = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Scontroller} returns this
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.clearActuatorStrategies = function() {
  return this.setActuatorStrategies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.hasActuatorStrategies = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.StrategyDecision strategy_decision = 2;
 * @return {?proto.de.mypowergrid.ems.scontroller.StrategyDecision}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.getStrategyDecision = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.StrategyDecision} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.StrategyDecision, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.StrategyDecision|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Scontroller} returns this
*/
proto.de.mypowergrid.ems.config.Scontroller.prototype.setStrategyDecision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Scontroller} returns this
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.clearStrategyDecision = function() {
  return this.setStrategyDecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.hasStrategyDecision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double interval = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.Scontroller} returns this
 */
proto.de.mypowergrid.ems.config.Scontroller.prototype.setInterval = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.SamplingSynchronizers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.toObject = function(includeInstance, msg) {
  var f, obj = {
    samplingSynchronizerFromMissingSummandNameMap: (f = msg.getSamplingSynchronizerFromMissingSummandNameMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.ems.config.SamplingSynchronizer.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizers}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.SamplingSynchronizers;
  return proto.de.mypowergrid.ems.config.SamplingSynchronizers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizers}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSamplingSynchronizerFromMissingSummandNameMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.ems.config.SamplingSynchronizer.deserializeBinaryFromReader, "", new proto.de.mypowergrid.ems.config.SamplingSynchronizer());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.SamplingSynchronizers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSamplingSynchronizerFromMissingSummandNameMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.ems.config.SamplingSynchronizer.serializeBinaryToWriter);
  }
};


/**
 * map<string, SamplingSynchronizer> sampling_synchronizer_from_missing_summand_name = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.ems.config.SamplingSynchronizer>}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.prototype.getSamplingSynchronizerFromMissingSummandNameMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.ems.config.SamplingSynchronizer>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.de.mypowergrid.ems.config.SamplingSynchronizer));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizers} returns this
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizers.prototype.clearSamplingSynchronizerFromMissingSummandNameMap = function() {
  this.getSamplingSynchronizerFromMissingSummandNameMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.SamplingSynchronizer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.toObject = function(includeInstance, msg) {
  var f, obj = {
    sumPowerSensor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inaccuracyFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    concurrentTimeError: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    summandsList: jspb.Message.toObjectList(msg.getSummandsList(),
    proto.de.mypowergrid.ems.config.Summand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.SamplingSynchronizer;
  return proto.de.mypowergrid.ems.config.SamplingSynchronizer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSumPowerSensor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInaccuracyFactor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConcurrentTimeError(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.config.Summand;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Summand.deserializeBinaryFromReader);
      msg.addSummands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.SamplingSynchronizer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSumPowerSensor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInaccuracyFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getConcurrentTimeError();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSummandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.de.mypowergrid.ems.config.Summand.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sum_power_sensor = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.getSumPowerSensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.setSumPowerSensor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double inaccuracy_factor = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.getInaccuracyFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.setInaccuracyFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double concurrent_time_error = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.getConcurrentTimeError = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.setConcurrentTimeError = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Summand summands = 4;
 * @return {!Array<!proto.de.mypowergrid.ems.config.Summand>}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.getSummandsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.Summand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.Summand, 4));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.Summand>} value
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} returns this
*/
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.setSummandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.Summand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.Summand}
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.addSummands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.de.mypowergrid.ems.config.Summand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.SamplingSynchronizer} returns this
 */
proto.de.mypowergrid.ems.config.SamplingSynchronizer.prototype.clearSummandsList = function() {
  return this.setSummandsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Summand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Summand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Summand.toObject = function(includeInstance, msg) {
  var f, obj = {
    outPowerSensorSynch: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outEnergyDerivativeSensor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    powerSensor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    demandSensor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplySensor: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Summand}
 */
proto.de.mypowergrid.ems.config.Summand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Summand;
  return proto.de.mypowergrid.ems.config.Summand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Summand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Summand}
 */
proto.de.mypowergrid.ems.config.Summand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutPowerSensorSynch(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutEnergyDerivativeSensor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerSensor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDemandSensor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplySensor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Summand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Summand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Summand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutPowerSensorSynch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutEnergyDerivativeSensor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPowerSensor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDemandSensor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplySensor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string out_power_sensor_synch = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.getOutPowerSensorSynch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Summand} returns this
 */
proto.de.mypowergrid.ems.config.Summand.prototype.setOutPowerSensorSynch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string out_energy_derivative_sensor = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.getOutEnergyDerivativeSensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Summand} returns this
 */
proto.de.mypowergrid.ems.config.Summand.prototype.setOutEnergyDerivativeSensor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string power_sensor = 3;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.getPowerSensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Summand} returns this
 */
proto.de.mypowergrid.ems.config.Summand.prototype.setPowerSensor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string demand_sensor = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.getDemandSensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Summand} returns this
 */
proto.de.mypowergrid.ems.config.Summand.prototype.setDemandSensor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supply_sensor = 5;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Summand.prototype.getSupplySensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Summand} returns this
 */
proto.de.mypowergrid.ems.config.Summand.prototype.setSupplySensor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.Sensors.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Sensors.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Sensors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Sensors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Sensors.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    proto.de.mypowergrid.ems.config.Sensor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Sensors}
 */
proto.de.mypowergrid.ems.config.Sensors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Sensors;
  return proto.de.mypowergrid.ems.config.Sensors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Sensors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Sensors}
 */
proto.de.mypowergrid.ems.config.Sensors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.Sensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Sensor.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Sensors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Sensors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Sensors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Sensors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.Sensor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sensor sensors = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.Sensor>}
 */
proto.de.mypowergrid.ems.config.Sensors.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.Sensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.Sensor, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.Sensor>} value
 * @return {!proto.de.mypowergrid.ems.config.Sensors} returns this
*/
proto.de.mypowergrid.ems.config.Sensors.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.Sensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.Sensor}
 */
proto.de.mypowergrid.ems.config.Sensors.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.Sensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.Sensors} returns this
 */
proto.de.mypowergrid.ems.config.Sensors.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.config.Sensor.oneofGroups_ = [[1,2,3,4,7]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.Sensor.TypeCase = {
  TYPE_NOT_SET: 0,
  BASE_SENSOR: 1,
  IEC_SENSOR: 2,
  EVAL_SENSOR: 3,
  METER_SENSOR: 4,
  STATE_SENSOR: 7
};

/**
 * @return {proto.de.mypowergrid.ems.config.Sensor.TypeCase}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.config.Sensor.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Sensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Sensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Sensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseSensor: (f = msg.getBaseSensor()) && proto.de.mypowergrid.ems.config.BaseSensor.toObject(includeInstance, f),
    iecSensor: (f = msg.getIecSensor()) && proto.de.mypowergrid.ems.config.IecSensor.toObject(includeInstance, f),
    evalSensor: (f = msg.getEvalSensor()) && proto.de.mypowergrid.ems.config.EvalSensor.toObject(includeInstance, f),
    meterSensor: (f = msg.getMeterSensor()) && proto.de.mypowergrid.ems.config.MeterSensor.toObject(includeInstance, f),
    stateSensor: (f = msg.getStateSensor()) && proto.de.mypowergrid.ems.config.IecSensor.toObject(includeInstance, f),
    sensorName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emsId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Sensor}
 */
proto.de.mypowergrid.ems.config.Sensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Sensor;
  return proto.de.mypowergrid.ems.config.Sensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Sensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Sensor}
 */
proto.de.mypowergrid.ems.config.Sensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.BaseSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader);
      msg.setBaseSensor(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.config.IecSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.IecSensor.deserializeBinaryFromReader);
      msg.setIecSensor(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.EvalSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.EvalSensor.deserializeBinaryFromReader);
      msg.setEvalSensor(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.config.MeterSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.MeterSensor.deserializeBinaryFromReader);
      msg.setMeterSensor(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.config.IecSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.IecSensor.deserializeBinaryFromReader);
      msg.setStateSensor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Sensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Sensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Sensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseSensor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter
    );
  }
  f = message.getIecSensor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.config.IecSensor.serializeBinaryToWriter
    );
  }
  f = message.getEvalSensor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.EvalSensor.serializeBinaryToWriter
    );
  }
  f = message.getMeterSensor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.config.MeterSensor.serializeBinaryToWriter
    );
  }
  f = message.getStateSensor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.config.IecSensor.serializeBinaryToWriter
    );
  }
  f = message.getSensorName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmsId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional BaseSensor base_sensor = 1;
 * @return {?proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getBaseSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.BaseSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.BaseSensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.BaseSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
*/
proto.de.mypowergrid.ems.config.Sensor.prototype.setBaseSensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.clearBaseSensor = function() {
  return this.setBaseSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.hasBaseSensor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IecSensor iec_sensor = 2;
 * @return {?proto.de.mypowergrid.ems.config.IecSensor}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getIecSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.IecSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.IecSensor, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.IecSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
*/
proto.de.mypowergrid.ems.config.Sensor.prototype.setIecSensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.clearIecSensor = function() {
  return this.setIecSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.hasIecSensor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EvalSensor eval_sensor = 3;
 * @return {?proto.de.mypowergrid.ems.config.EvalSensor}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getEvalSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.EvalSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.EvalSensor, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.EvalSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
*/
proto.de.mypowergrid.ems.config.Sensor.prototype.setEvalSensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.clearEvalSensor = function() {
  return this.setEvalSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.hasEvalSensor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MeterSensor meter_sensor = 4;
 * @return {?proto.de.mypowergrid.ems.config.MeterSensor}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getMeterSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.MeterSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.MeterSensor, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.MeterSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
*/
proto.de.mypowergrid.ems.config.Sensor.prototype.setMeterSensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.clearMeterSensor = function() {
  return this.setMeterSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.hasMeterSensor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IecSensor state_sensor = 7;
 * @return {?proto.de.mypowergrid.ems.config.IecSensor}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getStateSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.IecSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.IecSensor, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.IecSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
*/
proto.de.mypowergrid.ems.config.Sensor.prototype.setStateSensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.config.Sensor.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.clearStateSensor = function() {
  return this.setStateSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.hasStateSensor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string sensor_name = 5;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.setSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ems_id = 6;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.getEmsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Sensor} returns this
 */
proto.de.mypowergrid.ems.config.Sensor.prototype.setEmsId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.SensorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.SensorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SensorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.de.mypowergrid.ems.config.Sensor.toObject(includeInstance, f),
    maxAge: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    sendPipeline: jspb.Message.getFieldWithDefault(msg, 3, 0),
    forceNotifyState: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo}
 */
proto.de.mypowergrid.ems.config.SensorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.SensorInfo;
  return proto.de.mypowergrid.ems.config.SensorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.SensorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo}
 */
proto.de.mypowergrid.ems.config.SensorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.Sensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Sensor.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxAge(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.ems.config.SensorInfo.Pipeline} */ (reader.readEnum());
      msg.setSendPipeline(value);
      break;
    case 4:
      var value = /** @type {!proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState} */ (reader.readEnum());
      msg.setForceNotifyState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.SensorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.SensorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.SensorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.Sensor.serializeBinaryToWriter
    );
  }
  f = message.getMaxAge();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSendPipeline();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getForceNotifyState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.SensorInfo.Pipeline = {
  NONE: 0,
  INSTANT: 1,
  ON_DEMAND: 2,
  ONE_MINUTE: 3,
  UNKNOWN: 4,
  STATE: 5
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState = {
  UNSET: 0,
  NOTIFY: 1,
  NOT_NOTIFY: 2
};

/**
 * optional Sensor config = 1;
 * @return {?proto.de.mypowergrid.ems.config.Sensor}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.getConfig = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.Sensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.Sensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.Sensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo} returns this
*/
proto.de.mypowergrid.ems.config.SensorInfo.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo} returns this
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double max_age = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.getMaxAge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo} returns this
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.setMaxAge = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Pipeline send_pipeline = 3;
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo.Pipeline}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.getSendPipeline = function() {
  return /** @type {!proto.de.mypowergrid.ems.config.SensorInfo.Pipeline} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.config.SensorInfo.Pipeline} value
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo} returns this
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.setSendPipeline = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ForceNotifyState force_notify_state = 4;
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState}
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.getForceNotifyState = function() {
  return /** @type {!proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.config.SensorInfo.ForceNotifyState} value
 * @return {!proto.de.mypowergrid.ems.config.SensorInfo} returns this
 */
proto.de.mypowergrid.ems.config.SensorInfo.prototype.setForceNotifyState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.BaseSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.BaseSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.BaseSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bias: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    notify: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    timeoutSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    exchange: jspb.Message.getFieldWithDefault(msg, 6, ""),
    routingKey: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.BaseSensor;
  return proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.BaseSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScale(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBias(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotify(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimeoutSeconds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchange(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutingKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.BaseSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBias();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNotify();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTimeoutSeconds();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getExchange();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRoutingKey();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional double scale = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double bias = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getBias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setBias = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool notify = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getNotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setNotify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional double timeout_seconds = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getTimeoutSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setTimeoutSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string exchange = 6;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getExchange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setExchange = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string routing_key = 7;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.getRoutingKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.BaseSensor} returns this
 */
proto.de.mypowergrid.ems.config.BaseSensor.prototype.setRoutingKey = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.IecSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.IecSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.IecSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseSensor: (f = msg.getBaseSensor()) && proto.de.mypowergrid.ems.config.BaseSensor.toObject(includeInstance, f),
    data: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.IecSensor}
 */
proto.de.mypowergrid.ems.config.IecSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.IecSensor;
  return proto.de.mypowergrid.ems.config.IecSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.IecSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.IecSensor}
 */
proto.de.mypowergrid.ems.config.IecSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.BaseSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader);
      msg.setBaseSensor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.IecSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.IecSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.IecSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseSensor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional BaseSensor base_sensor = 1;
 * @return {?proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.getBaseSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.BaseSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.BaseSensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.BaseSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.IecSensor} returns this
*/
proto.de.mypowergrid.ems.config.IecSensor.prototype.setBaseSensor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.IecSensor} returns this
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.clearBaseSensor = function() {
  return this.setBaseSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.hasBaseSensor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.IecSensor} returns this
 */
proto.de.mypowergrid.ems.config.IecSensor.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.EvalSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.EvalSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EvalSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseSensor: (f = msg.getBaseSensor()) && proto.de.mypowergrid.ems.config.BaseSensor.toObject(includeInstance, f),
    eval: (f = msg.getEval()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    align: jspb.Message.getFieldWithDefault(msg, 3, 0),
    average: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor}
 */
proto.de.mypowergrid.ems.config.EvalSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.EvalSensor;
  return proto.de.mypowergrid.ems.config.EvalSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.EvalSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor}
 */
proto.de.mypowergrid.ems.config.EvalSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.BaseSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader);
      msg.setBaseSensor(value);
      break;
    case 2:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setEval(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAlign(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAverage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.EvalSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.EvalSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EvalSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseSensor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter
    );
  }
  f = message.getEval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getAlign();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAverage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional BaseSensor base_sensor = 1;
 * @return {?proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.getBaseSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.BaseSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.BaseSensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.BaseSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
*/
proto.de.mypowergrid.ems.config.EvalSensor.prototype.setBaseSensor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.clearBaseSensor = function() {
  return this.setBaseSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.hasBaseSensor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression eval = 2;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.getEval = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
*/
proto.de.mypowergrid.ems.config.EvalSensor.prototype.setEval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.clearEval = function() {
  return this.setEval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.hasEval = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 align = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.getAlign = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.setAlign = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool average = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.getAverage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.config.EvalSensor} returns this
 */
proto.de.mypowergrid.ems.config.EvalSensor.prototype.setAverage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.MeterSensor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.MeterSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.MeterSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.MeterSensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseSensor: (f = msg.getBaseSensor()) && proto.de.mypowergrid.ems.config.BaseSensor.toObject(includeInstance, f),
    bcrDataObject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    powerSensorsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor}
 */
proto.de.mypowergrid.ems.config.MeterSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.MeterSensor;
  return proto.de.mypowergrid.ems.config.MeterSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.MeterSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor}
 */
proto.de.mypowergrid.ems.config.MeterSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.BaseSensor;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.BaseSensor.deserializeBinaryFromReader);
      msg.setBaseSensor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBcrDataObject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPowerSensors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.MeterSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.MeterSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.MeterSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseSensor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.BaseSensor.serializeBinaryToWriter
    );
  }
  f = message.getBcrDataObject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPowerSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional BaseSensor base_sensor = 1;
 * @return {?proto.de.mypowergrid.ems.config.BaseSensor}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.getBaseSensor = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.BaseSensor} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.BaseSensor, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.BaseSensor|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
*/
proto.de.mypowergrid.ems.config.MeterSensor.prototype.setBaseSensor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.clearBaseSensor = function() {
  return this.setBaseSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.hasBaseSensor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bcr_data_object = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.getBcrDataObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.setBcrDataObject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string power_sensors = 3;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.getPowerSensorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.setPowerSensorsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.addPowerSensors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.MeterSensor} returns this
 */
proto.de.mypowergrid.ems.config.MeterSensor.prototype.clearPowerSensorsList = function() {
  return this.setPowerSensorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.System.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.System.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.System.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.System} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalDeviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iecDriverServiceServerAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    extraNodesList: jspb.Message.toObjectList(msg.getExtraNodesList(),
    proto.de.mypowergrid.ems.config.System.ExtraNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.System}
 */
proto.de.mypowergrid.ems.config.System.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.System;
  return proto.de.mypowergrid.ems.config.System.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.System} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.System}
 */
proto.de.mypowergrid.ems.config.System.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalDeviceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIecDriverServiceServerAddress(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.System.ExtraNode;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.System.ExtraNode.deserializeBinaryFromReader);
      msg.addExtraNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.System.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.System.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.System} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIecDriverServiceServerAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExtraNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.System.ExtraNode.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.TypeCase = {
  TYPE_NOT_SET: 0,
  TIMESERIES: 3,
  PEAK_OBSERVER: 4
};

/**
 * @return {proto.de.mypowergrid.ems.config.System.ExtraNode.TypeCase}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.config.System.ExtraNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.config.System.ExtraNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.System.ExtraNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalNodeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataObjectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeseries: (f = msg.getTimeseries()) && proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.toObject(includeInstance, f),
    peakObserver: (f = msg.getPeakObserver()) && proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.System.ExtraNode;
  return proto.de.mypowergrid.ems.config.System.ExtraNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalNodeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataObjectName(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.deserializeBinaryFromReader);
      msg.setTimeseries(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.deserializeBinaryFromReader);
      msg.setPeakObserver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.System.ExtraNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalNodeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeseries();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserver();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeseriesName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateInterval: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries;
  return proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeseriesName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpdateInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeseriesName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateInterval();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string timeseries_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.getTimeseriesName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.setTimeseriesName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double update_interval = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.getUpdateInterval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries.prototype.setUpdateInterval = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyMeterSensorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resetPeakTimeSeriesName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver;
  return proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyMeterSensorName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetPeakTimeSeriesName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyMeterSensorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResetPeakTimeSeriesName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeInterval();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string energy_meter_sensor_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.getEnergyMeterSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.setEnergyMeterSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reset_peak_time_series_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.getResetPeakTimeSeriesName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.setResetPeakTimeSeriesName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 time_interval = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.getTimeInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string logical_node_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.getLogicalNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.setLogicalNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_object_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.getDataObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.setDataObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Timeseries timeseries = 3;
 * @return {?proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.getTimeseries = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.System.ExtraNode.Timeseries|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
*/
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.setTimeseries = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.config.System.ExtraNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.clearTimeseries = function() {
  return this.setTimeseries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.hasTimeseries = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PeakObserver peak_observer = 4;
 * @return {?proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.getPeakObserver = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.System.ExtraNode.PeakObserver|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
*/
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.setPeakObserver = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.config.System.ExtraNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode} returns this
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.clearPeakObserver = function() {
  return this.setPeakObserver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.System.ExtraNode.prototype.hasPeakObserver = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string logical_device_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.prototype.getLogicalDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System} returns this
 */
proto.de.mypowergrid.ems.config.System.prototype.setLogicalDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iec_driver_service_server_address = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.System.prototype.getIecDriverServiceServerAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.System} returns this
 */
proto.de.mypowergrid.ems.config.System.prototype.setIecDriverServiceServerAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ExtraNode extra_nodes = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.config.System.ExtraNode>}
 */
proto.de.mypowergrid.ems.config.System.prototype.getExtraNodesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.System.ExtraNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.System.ExtraNode, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.System.ExtraNode>} value
 * @return {!proto.de.mypowergrid.ems.config.System} returns this
*/
proto.de.mypowergrid.ems.config.System.prototype.setExtraNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.System.ExtraNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.System.ExtraNode}
 */
proto.de.mypowergrid.ems.config.System.prototype.addExtraNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.config.System.ExtraNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.System} returns this
 */
proto.de.mypowergrid.ems.config.System.prototype.clearExtraNodesList = function() {
  return this.setExtraNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.PersistentState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.PersistentState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.PersistentState.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointInterval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 2, ""),
    backlogDefault: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.PersistentState}
 */
proto.de.mypowergrid.ems.config.PersistentState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.PersistentState;
  return proto.de.mypowergrid.ems.config.PersistentState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.PersistentState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.PersistentState}
 */
proto.de.mypowergrid.ems.config.PersistentState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCheckpointInterval(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDir(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBacklogDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.PersistentState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.PersistentState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.PersistentState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointInterval();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDir();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBacklogDefault();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 checkpoint_interval = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.getCheckpointInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.PersistentState} returns this
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.setCheckpointInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string dir = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.getDir = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.PersistentState} returns this
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.setDir = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 backlog_default = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.getBacklogDefault = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.PersistentState} returns this
 */
proto.de.mypowergrid.ems.config.PersistentState.prototype.setBacklogDefault = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.NotifyPipelineConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    delay: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxSamples: jspb.Message.getFieldWithDefault(msg, 2, 0),
    jsonSensors: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    combinationFactor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minimumFreeDiskSpace: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dir: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.NotifyPipelineConfig;
  return proto.de.mypowergrid.ems.config.NotifyPipelineConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDelay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxSamples(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJsonSensors(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCombinationFactor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinimumFreeDiskSpace(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDir(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.NotifyPipelineConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelay();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMaxSamples();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getJsonSensors();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCombinationFactor();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinimumFreeDiskSpace();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDir();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional double delay = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setDelay = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 max_samples = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getMaxSamples = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setMaxSamples = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool json_sensors = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getJsonSensors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setJsonSensors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint32 combination_factor = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getCombinationFactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setCombinationFactor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 minimum_free_disk_space = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getMinimumFreeDiskSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setMinimumFreeDiskSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string dir = 6;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.getDir = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.NotifyPipelineConfig} returns this
 */
proto.de.mypowergrid.ems.config.NotifyPipelineConfig.prototype.setDir = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Notify.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Notify} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Notify.toObject = function(includeInstance, msg) {
  var f, obj = {
    instantValueSendMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oneMinutePipeline: (f = msg.getOneMinutePipeline()) && proto.de.mypowergrid.ems.config.NotifyPipelineConfig.toObject(includeInstance, f),
    instantPipeline: (f = msg.getInstantPipeline()) && proto.de.mypowergrid.ems.config.NotifyPipelineConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Notify}
 */
proto.de.mypowergrid.ems.config.Notify.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Notify;
  return proto.de.mypowergrid.ems.config.Notify.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Notify} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Notify}
 */
proto.de.mypowergrid.ems.config.Notify.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.config.BandwidthMode} */ (reader.readEnum());
      msg.setInstantValueSendMode(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.config.NotifyPipelineConfig;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.NotifyPipelineConfig.deserializeBinaryFromReader);
      msg.setOneMinutePipeline(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.NotifyPipelineConfig;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.NotifyPipelineConfig.deserializeBinaryFromReader);
      msg.setInstantPipeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Notify.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Notify} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Notify.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstantValueSendMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOneMinutePipeline();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.config.NotifyPipelineConfig.serializeBinaryToWriter
    );
  }
  f = message.getInstantPipeline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.NotifyPipelineConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional BandwidthMode instant_value_send_mode = 1;
 * @return {!proto.de.mypowergrid.ems.config.BandwidthMode}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.getInstantValueSendMode = function() {
  return /** @type {!proto.de.mypowergrid.ems.config.BandwidthMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.config.BandwidthMode} value
 * @return {!proto.de.mypowergrid.ems.config.Notify} returns this
 */
proto.de.mypowergrid.ems.config.Notify.prototype.setInstantValueSendMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NotifyPipelineConfig one_minute_pipeline = 2;
 * @return {?proto.de.mypowergrid.ems.config.NotifyPipelineConfig}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.getOneMinutePipeline = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.NotifyPipelineConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.NotifyPipelineConfig, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.NotifyPipelineConfig|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Notify} returns this
*/
proto.de.mypowergrid.ems.config.Notify.prototype.setOneMinutePipeline = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Notify} returns this
 */
proto.de.mypowergrid.ems.config.Notify.prototype.clearOneMinutePipeline = function() {
  return this.setOneMinutePipeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.hasOneMinutePipeline = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NotifyPipelineConfig instant_pipeline = 3;
 * @return {?proto.de.mypowergrid.ems.config.NotifyPipelineConfig}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.getInstantPipeline = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.NotifyPipelineConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.NotifyPipelineConfig, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.NotifyPipelineConfig|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.Notify} returns this
*/
proto.de.mypowergrid.ems.config.Notify.prototype.setInstantPipeline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.Notify} returns this
 */
proto.de.mypowergrid.ems.config.Notify.prototype.clearInstantPipeline = function() {
  return this.setInstantPipeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.Notify.prototype.hasInstantPipeline = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.Controller.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Controller.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Controller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Controller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Controller.toObject = function(includeInstance, msg) {
  var f, obj = {
    mqhandlerAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serverListenList: jspb.Message.toObjectList(msg.getServerListenList(),
    proto.de.mypowergrid.ems.config.Controller.HostAndPort.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Controller}
 */
proto.de.mypowergrid.ems.config.Controller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Controller;
  return proto.de.mypowergrid.ems.config.Controller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Controller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Controller}
 */
proto.de.mypowergrid.ems.config.Controller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqhandlerAddress(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.config.Controller.HostAndPort;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.Controller.HostAndPort.deserializeBinaryFromReader);
      msg.addServerListen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Controller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Controller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Controller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Controller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMqhandlerAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServerListenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.config.Controller.HostAndPort.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.Controller.HostAndPort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.Controller.HostAndPort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.Controller.HostAndPort}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.Controller.HostAndPort;
  return proto.de.mypowergrid.ems.config.Controller.HostAndPort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.Controller.HostAndPort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.Controller.HostAndPort}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.Controller.HostAndPort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.Controller.HostAndPort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Controller.HostAndPort} returns this
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 port = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.Controller.HostAndPort} returns this
 */
proto.de.mypowergrid.ems.config.Controller.HostAndPort.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string mqhandler_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.Controller.prototype.getMqhandlerAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.Controller} returns this
 */
proto.de.mypowergrid.ems.config.Controller.prototype.setMqhandlerAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated HostAndPort server_listen = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.config.Controller.HostAndPort>}
 */
proto.de.mypowergrid.ems.config.Controller.prototype.getServerListenList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.Controller.HostAndPort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.Controller.HostAndPort, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.Controller.HostAndPort>} value
 * @return {!proto.de.mypowergrid.ems.config.Controller} returns this
*/
proto.de.mypowergrid.ems.config.Controller.prototype.setServerListenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.Controller.HostAndPort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.Controller.HostAndPort}
 */
proto.de.mypowergrid.ems.config.Controller.prototype.addServerListen = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.config.Controller.HostAndPort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.Controller} returns this
 */
proto.de.mypowergrid.ems.config.Controller.prototype.clearServerListenList = function() {
  return this.setServerListenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.DescriptionEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.DescriptionEntry;
  return proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry} returns this
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry} returns this
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry} returns this
 */
proto.de.mypowergrid.ems.config.DescriptionEntry.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.VariableCollection.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.VariableCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.VariableCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.de.mypowergrid.ems.config.VariableOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection}
 */
proto.de.mypowergrid.ems.config.VariableCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.VariableCollection;
  return proto.de.mypowergrid.ems.config.VariableCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.VariableCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection}
 */
proto.de.mypowergrid.ems.config.VariableCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.VariableOption;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.VariableOption.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.VariableCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.VariableCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.VariableOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection} returns this
*/
proto.de.mypowergrid.ems.config.VariableCollection.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection} returns this
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection} returns this
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated VariableOption elements = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.config.VariableOption>}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.VariableOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.VariableOption, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.VariableOption>} value
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection} returns this
*/
proto.de.mypowergrid.ems.config.VariableCollection.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.VariableOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.VariableOption}
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.config.VariableOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.VariableCollection} returns this
 */
proto.de.mypowergrid.ems.config.VariableCollection.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.DoubleVariable.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.DoubleVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.DoubleVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.DoubleVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    def: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.DoubleVariable;
  return proto.de.mypowergrid.ems.config.DoubleVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.DoubleVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMax(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.DoubleVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.DoubleVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.DoubleVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDef();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
*/
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double min = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double max = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double def = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.getDef = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.DoubleVariable} returns this
 */
proto.de.mypowergrid.ems.config.DoubleVariable.prototype.setDef = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.IntegerVariable.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.IntegerVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.IntegerVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.IntegerVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    min: jspb.Message.getFieldWithDefault(msg, 3, 0),
    max: jspb.Message.getFieldWithDefault(msg, 4, 0),
    def: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.IntegerVariable;
  return proto.de.mypowergrid.ems.config.IntegerVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.IntegerVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMax(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.IntegerVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.IntegerVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.IntegerVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDef();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
*/
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 min = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 def = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.getDef = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.IntegerVariable} returns this
 */
proto.de.mypowergrid.ems.config.IntegerVariable.prototype.setDef = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.BoolVariable.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.BoolVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.BoolVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.BoolVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    def: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable}
 */
proto.de.mypowergrid.ems.config.BoolVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.BoolVariable;
  return proto.de.mypowergrid.ems.config.BoolVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.BoolVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable}
 */
proto.de.mypowergrid.ems.config.BoolVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.BoolVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.BoolVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.BoolVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDef();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable} returns this
*/
proto.de.mypowergrid.ems.config.BoolVariable.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable} returns this
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable} returns this
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool def = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.getDef = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.config.BoolVariable} returns this
 */
proto.de.mypowergrid.ems.config.BoolVariable.prototype.setDef = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.EnumOptions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.EnumOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.EnumOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EnumOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions}
 */
proto.de.mypowergrid.ems.config.EnumOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.EnumOptions;
  return proto.de.mypowergrid.ems.config.EnumOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.EnumOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions}
 */
proto.de.mypowergrid.ems.config.EnumOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.EnumOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.EnumOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EnumOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions} returns this
*/
proto.de.mypowergrid.ems.config.EnumOptions.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions} returns this
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions} returns this
 */
proto.de.mypowergrid.ems.config.EnumOptions.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.EnumVariable.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.EnumVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.EnumVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EnumVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    descriptionsList: jspb.Message.toObjectList(msg.getDescriptionsList(),
    proto.de.mypowergrid.ems.config.DescriptionEntry.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.de.mypowergrid.ems.config.EnumOptions.toObject, includeInstance),
    def: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable}
 */
proto.de.mypowergrid.ems.config.EnumVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.EnumVariable;
  return proto.de.mypowergrid.ems.config.EnumVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.EnumVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable}
 */
proto.de.mypowergrid.ems.config.EnumVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DescriptionEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DescriptionEntry.deserializeBinaryFromReader);
      msg.addDescriptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.EnumOptions;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.EnumOptions.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.EnumVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.EnumVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.EnumVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DescriptionEntry.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.EnumOptions.serializeBinaryToWriter
    );
  }
  f = message.getDef();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated DescriptionEntry descriptions = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.getDescriptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.DescriptionEntry, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.DescriptionEntry>} value
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
*/
proto.de.mypowergrid.ems.config.EnumVariable.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.DescriptionEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.DescriptionEntry}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.addDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.DescriptionEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EnumOptions options = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.config.EnumOptions>}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.EnumOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.EnumOptions, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.EnumOptions>} value
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
*/
proto.de.mypowergrid.ems.config.EnumVariable.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.EnumOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.EnumOptions}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.config.EnumOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional int64 def = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.getDef = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.config.EnumVariable} returns this
 */
proto.de.mypowergrid.ems.config.EnumVariable.prototype.setDef = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.VariableOption.TypeCase = {
  TYPE_NOT_SET: 0,
  DOUBLE_VARIABLE: 1,
  COLLECTION: 2,
  INTEGER_VARIABLE: 3,
  ENUM_VARIABLE: 4,
  BOOL_VARIABLE: 5
};

/**
 * @return {proto.de.mypowergrid.ems.config.VariableOption.TypeCase}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.config.VariableOption.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.VariableOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.VariableOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    doubleVariable: (f = msg.getDoubleVariable()) && proto.de.mypowergrid.ems.config.DoubleVariable.toObject(includeInstance, f),
    collection: (f = msg.getCollection()) && proto.de.mypowergrid.ems.config.VariableCollection.toObject(includeInstance, f),
    integerVariable: (f = msg.getIntegerVariable()) && proto.de.mypowergrid.ems.config.IntegerVariable.toObject(includeInstance, f),
    enumVariable: (f = msg.getEnumVariable()) && proto.de.mypowergrid.ems.config.EnumVariable.toObject(includeInstance, f),
    boolVariable: (f = msg.getBoolVariable()) && proto.de.mypowergrid.ems.config.BoolVariable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption}
 */
proto.de.mypowergrid.ems.config.VariableOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.VariableOption;
  return proto.de.mypowergrid.ems.config.VariableOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.VariableOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption}
 */
proto.de.mypowergrid.ems.config.VariableOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.DoubleVariable;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.DoubleVariable.deserializeBinaryFromReader);
      msg.setDoubleVariable(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.config.VariableCollection;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.VariableCollection.deserializeBinaryFromReader);
      msg.setCollection(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.config.IntegerVariable;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.IntegerVariable.deserializeBinaryFromReader);
      msg.setIntegerVariable(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.config.EnumVariable;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.EnumVariable.deserializeBinaryFromReader);
      msg.setEnumVariable(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.config.BoolVariable;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.BoolVariable.deserializeBinaryFromReader);
      msg.setBoolVariable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.VariableOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.VariableOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoubleVariable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.DoubleVariable.serializeBinaryToWriter
    );
  }
  f = message.getCollection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.config.VariableCollection.serializeBinaryToWriter
    );
  }
  f = message.getIntegerVariable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.config.IntegerVariable.serializeBinaryToWriter
    );
  }
  f = message.getEnumVariable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.config.EnumVariable.serializeBinaryToWriter
    );
  }
  f = message.getBoolVariable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.config.BoolVariable.serializeBinaryToWriter
    );
  }
};


/**
 * optional DoubleVariable double_variable = 1;
 * @return {?proto.de.mypowergrid.ems.config.DoubleVariable}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getDoubleVariable = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.DoubleVariable} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.DoubleVariable, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.DoubleVariable|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
*/
proto.de.mypowergrid.ems.config.VariableOption.prototype.setDoubleVariable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.clearDoubleVariable = function() {
  return this.setDoubleVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.hasDoubleVariable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VariableCollection collection = 2;
 * @return {?proto.de.mypowergrid.ems.config.VariableCollection}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getCollection = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.VariableCollection} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.VariableCollection, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.VariableCollection|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
*/
proto.de.mypowergrid.ems.config.VariableOption.prototype.setCollection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.clearCollection = function() {
  return this.setCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.hasCollection = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntegerVariable integer_variable = 3;
 * @return {?proto.de.mypowergrid.ems.config.IntegerVariable}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getIntegerVariable = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.IntegerVariable} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.IntegerVariable, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.IntegerVariable|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
*/
proto.de.mypowergrid.ems.config.VariableOption.prototype.setIntegerVariable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.clearIntegerVariable = function() {
  return this.setIntegerVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.hasIntegerVariable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EnumVariable enum_variable = 4;
 * @return {?proto.de.mypowergrid.ems.config.EnumVariable}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getEnumVariable = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.EnumVariable} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.EnumVariable, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.EnumVariable|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
*/
proto.de.mypowergrid.ems.config.VariableOption.prototype.setEnumVariable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.clearEnumVariable = function() {
  return this.setEnumVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.hasEnumVariable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BoolVariable bool_variable = 5;
 * @return {?proto.de.mypowergrid.ems.config.BoolVariable}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.getBoolVariable = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.BoolVariable} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.config.BoolVariable, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.BoolVariable|undefined} value
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
*/
proto.de.mypowergrid.ems.config.VariableOption.prototype.setBoolVariable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.config.VariableOption.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.config.VariableOption} returns this
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.clearBoolVariable = function() {
  return this.setBoolVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.config.VariableOption.prototype.hasBoolVariable = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.config.VariableStore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.config.VariableStore.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.config.VariableStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.config.VariableStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.de.mypowergrid.ems.config.VariableOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.config.VariableStore}
 */
proto.de.mypowergrid.ems.config.VariableStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.config.VariableStore;
  return proto.de.mypowergrid.ems.config.VariableStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.config.VariableStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.config.VariableStore}
 */
proto.de.mypowergrid.ems.config.VariableStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.config.VariableOption;
      reader.readMessage(value,proto.de.mypowergrid.ems.config.VariableOption.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.config.VariableStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.config.VariableStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.config.VariableStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.config.VariableStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.config.VariableOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VariableOption elements = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.config.VariableOption>}
 */
proto.de.mypowergrid.ems.config.VariableStore.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.config.VariableOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.config.VariableOption, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.config.VariableOption>} value
 * @return {!proto.de.mypowergrid.ems.config.VariableStore} returns this
*/
proto.de.mypowergrid.ems.config.VariableStore.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.config.VariableOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.config.VariableOption}
 */
proto.de.mypowergrid.ems.config.VariableStore.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.config.VariableOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.config.VariableStore} returns this
 */
proto.de.mypowergrid.ems.config.VariableStore.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.config.BandwidthMode = {
  ALWAYS_SEND: 0,
  SEND_ON_DEMAND: 1,
  NEVER_SEND: 2
};

goog.object.extend(exports, proto.de.mypowergrid.ems.config);
