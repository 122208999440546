<template>
  <FwUpgradeViewLayout :do-confirm="doConfirm" :confirm-title="confirmTitle" @action:confirmation="onConfirm">
    <CRow>
      <CCol sm="12" md="5">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.partiallyUpgraded.title', { energyManager: brandName }) }}
        </p>
        <p>
          {{
            $t('fwupgrade.status.partiallyUpgraded.description', {
              energyManager: brandName,
              newVersion: availableVersion,
              oldVersion: currentVersion
            })
          }}
        </p>
        <p class="text-danger font-weight-bold">
          {{ $t('fwupgrade.status.partiallyUpgraded.warning', { energyManager: brandName }) }}
        </p>
      </CCol>
      <CCol sm="12" md="7">
        <p class="font-weight-bold text-primary">
          {{ $t('fwupgrade.status.partiallyUpgraded.guide.title') }}
        </p>
        <ol>
          <li>
            {{ $t('fwupgrade.status.partiallyUpgraded.guide.1', { energyManager: brandName }) }}
          </li>
          <li>
            {{ $t('fwupgrade.status.partiallyUpgraded.guide.2', { energyManager: brandName }) }}
          </li>
          <li>
            {{ $t('fwupgrade.status.partiallyUpgraded.guide.3', { energyManager: brandName }) }}
          </li>
        </ol>
      </CCol>
    </CRow>
    <!-- prettier-ignore -->
    <template #actions>
      <CButton
        class="mx-1 mx-md-3 mx-lg-4"
        size="sm"
        color="danger"
        :disabled="loading"
        @click="onRollbackingUpgrade"
      >
        <CIcon size="sm" name="cil-level-down" />
        <span> {{ $t('fwupgrade.action.rollbackUpgrade.btn') }} </span>
      </CButton>
      <CButton
        class="mx-1 mx-md-3 mx-lg-4"
        size="sm"
        color="danger"
        :disabled="loading"
        @click="onFinalizingUpgrade"
      >
        <CIcon size="sm" name="cil-check-double" />
        <span> {{ $t('fwupgrade.action.finalizeUpgrade.btn') }} </span>
      </CButton>
    </template>
    <template #confirmation-body>
      <p>
        {{ confirmDescription }}
      </p>
      <div class="text-danger mt-1 mt-md-3 mt-lg-4">
        <p class="font-weight-bold">
          <CIcon size="sm" name="cil-warning" class="mb-1 mx-1" />
          <span class="mt-1"> {{ $t('fwupgrade.warning') }} </span>
        </p>
        <p v-if="confirmWarningChangesLost" class="mb-1">
          {{ confirmWarningChangesLost }}
        </p>
        <p>
          {{ confirmWarningOutOfOrder }}
        </p>
      </div>
    </template>
  </FwUpgradeViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
import { State as StatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import FwUpgradeViewLayout from '@/components/fw-upgrade/fw-upgrade-view-layout'

import { buildI18nErrorMsg } from '@/view-helper/fw-upgrade/fw-upgrade-error'
import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'FwUpgradePartiallyUpgraded',
  components: {
    FwUpgradeViewLayout
  },
  data() {
    return {
      actionTriggered: null,
      doConfirm: false
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('fwupgrader', ['fwUpgraderState', 'currentVersion', 'availableVersion']),
    confirmTitle() {
      if (!this.actionTriggered) {
        return ''
      }

      return this.$t(`fwupgrade.action.${this.actionTriggered}.confirm`)
    },
    confirmDescription() {
      if (!this.actionTriggered) {
        return ''
      }

      return this.$t(`fwupgrade.action.${this.actionTriggered}.description`, {
        energyManager: this.brandName,
        newVersion: this.availableVersion,
        oldVersion: this.currentVersion
      })
    },
    confirmWarningChangesLost() {
      switch (this.actionTriggered) {
        case 'rollbackUpgrade':
          return this.$t(`fwupgrade.action.${this.actionTriggered}.warningChangesLost`, {
            energyManager: this.brandName
          })
        default:
          return ''
      }
    },
    confirmWarningOutOfOrder() {
      if (!this.actionTriggered) {
        return ''
      }

      return this.$t(`fwupgrade.action.${this.actionTriggered}.warningOutOfOrder`, { energyManager: this.brandName })
    }
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
    this.actionFinalizeEnum = StatePb.StateKindCase.FINALIZE_IN_PROGRESS
    this.actionRollbackEnum = StatePb.StateKindCase.ROLLBACK_IN_PROGRESS
  },
  methods: {
    onFinalizingUpgrade() {
      this.actionTriggered = 'finalizeUpgrade'
      this.doConfirm = true
    },
    onRollbackingUpgrade() {
      this.actionTriggered = 'rollbackUpgrade'
      this.doConfirm = true
    },
    async onConfirm(confirmed) {
      this.doConfirm = false
      if (!confirmed) {
        this.actionTriggered = null
        return
      }

      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch(`fwupgrader/${this.actionTriggered}`)
        this.$log.info(`Succesfully started fw-${kebabCase(this.actionTriggered)}.`)
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t(`fwupgrade.error.${this.actionTriggered}.title`),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
        this.actionTriggered = null
      }
    }
  }
}
</script>
