<template>
  <CRow>
    <CCol sm="12">
      <CListGroupItem>
        <div class="mb-0 mt-0">
          <div
            :class="{
              'card-header-bottom-border': showConfig,
              'px-4': true,
              'pb-3': showConfig,
              'ml-n3': true,
              'mr-n3': true
            }"
          >
            <div :style="{ cursor: 'pointer' }" class="collapsable-card-header pl-0" @click="showConfig = !showConfig">
              <i class="card-header-icon">
                <CIcon
                  :class="{
                    'pb-1': true,
                    'pl-0': true,
                    'no-device-assignment-icon': emptyTopo,
                    'device-assignment-icon': !emptyTopo
                  }"
                  :name="ldnConfigStatusIconName"
                  :height="20"
                />
              </i>
              <span class="text-info d-inline-block d-sm-none text-truncate" style="max-width: 100px">
                {{ ldn }}
              </span>
              <span class="text-info d-none d-sm-inline-block d-md-none text-truncate" style="max-width: 270px">
                {{ ldn }}
              </span>
              <span class="text-info d-none d-md-inline-block">
                {{ ldn }}
              </span>
              <div class="card-header-actions mb-2">
                <span
                  :class="{
                    'topo-card-header-configure-action-no-device-assignment': emptyTopo,
                    'topo-card-header-configure-action': !emptyTopo,
                    'font-sm': true,
                    'btn-minimize': true,
                    'd-none': true,
                    'd-md-block': true
                  }"
                >
                  <strong
                    :class="{
                      'font-sm': true
                    }"
                  >
                    {{ $t('ems.topology.configure') }}
                  </strong>
                  <i
                    :class="{
                      'topo-config-header-icon': true
                    }"
                  >
                    <CIcon class="pb-1" :name="`cil-chevron-${showConfig ? 'bottom' : 'top'}`" :height="20" />
                  </i>
                </span>
                <span
                  :class="{
                    'topo-card-header-configure-action-no-device-assignment': emptyTopo,
                    'topo-card-header-configure-action': !emptyTopo,
                    'font-sm': true,
                    'btn-minimize': true,
                    'd-md-none': true
                  }"
                >
                  <i
                    :class="{
                      'topo-config-header-icon': true
                    }"
                  >
                    <CIcon class="pb-1" :name="`cil-chevron-${showConfig ? 'bottom' : 'top'}`" :height="20" />
                  </i>
                </span>
              </div>
            </div>
          </div>
          <CCollapse :show="showConfig">
            <CCardBody v-if="isPureMtr(ldn)">
              <template v-for="(src, i) of sources">
                <!-- prettier-ignore -->
                <hr v-if="i !== 0" :key="i" class="mt-3" style="width: 60%;">
                <EmsEditTopoConfigPureMtr :key="`pure-mtr-${i}`" :iec-id="src.iec" />
              </template>
            </CCardBody>
            <CCardBody v-else>
              <EmsEditTopoConfigGenericSrc :ldn="ldn" />
            </CCardBody>
          </CCollapse>
        </div>
      </CListGroupItem>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import EmsEditTopoConfigGenericSrc from '@/components/ems/topology/ems-edit-topo-config-generic-src'
import EmsEditTopoConfigPureMtr from '@/components/ems/topology/ems-edit-topo-config-pure-mtr'

export default {
  name: 'EmsEditTopoConfigLd',
  components: {
    EmsEditTopoConfigGenericSrc,
    EmsEditTopoConfigPureMtr
  },
  props: {
    ldn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showConfig: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', [
      'getPhysicalDeviceOfSourceToDeviceTopology',
      'getPhysicalDevicesOfPhaseTopology',
      'getSources',
      'isGridMtr',
      'isPureMtr',
      'hasAnyMtrCounter'
    ]),
    sources() {
      return this.getSources(this.ldn)
    },
    emptyTopo() {
      return this.sources.every((src) => {
        return (
          !this.getPhysicalDeviceOfSourceToDeviceTopology(src.iec) &&
          !this.getPhysicalDevicesOfPhaseTopology(src.iec).length &&
          !this.isGridMtr(src.iec)
        )
      })
    },
    ldnConfigStatusIconName() {
      // TODO: define a mapping of device to icon at some central position
      if (this.emptyTopo) {
        return 'cil-warning'
      } else {
        const anyMtrCounter = this.hasAnyMtrCounter({ logicalDeviceName: this.ldn })
        const anyPhaseTopo = this.sources.some((src) => {
          return this.isGridMtr(src.iec) || !!this.getPhysicalDevicesOfPhaseTopology(src.iec).length
        })
        const anySrcToDevTopo = this.sources.some((src) => {
          return !!this.getPhysicalDeviceOfSourceToDeviceTopology(src.iec)
        })

        if (anyMtrCounter && anyPhaseTopo) {
          return 'cil-av-timer'
        }

        if (anySrcToDevTopo) {
          return 'cil-remote-settings'
        }
      }

      return 'cil-warning'
    }
  }
}
</script>
<style>
header.card-header.collapsable-card-header {
  border: 0;
  padding: 0;
}

li.list-group-item {
  /*border-left: 1px solid;*/
  /*border-right: 1px solid;*/
  /*border-top: 1px solid;*/
  padding: 0.75rem 1rem;
}

.card-header-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 21, 0.125);
}
</style>
