// package: de.mypowergrid.amperix
// file: amperix/device_noopt.proto

var amperix_device_noopt_pb = require("../amperix/device_noopt_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeviceService = (function () {
  function DeviceService() {}
  DeviceService.serviceName = "de.mypowergrid.amperix.DeviceService";
  return DeviceService;
}());

DeviceService.GetDeviceDrivers = {
  methodName: "GetDeviceDrivers",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.GetDeviceDriversRequest,
  responseType: amperix_device_noopt_pb.GetDeviceDriversResponse
};

DeviceService.GetDefaultDeviceConfig = {
  methodName: "GetDefaultDeviceConfig",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.GetDefaultDeviceConfigRequest,
  responseType: amperix_device_noopt_pb.GetDefaultDeviceConfigResponse
};

DeviceService.GetDeviceConfigs = {
  methodName: "GetDeviceConfigs",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.GetDeviceConfigsRequest,
  responseType: amperix_device_noopt_pb.GetDeviceConfigsResponse
};

DeviceService.GetUnregisteredDeviceConfigs = {
  methodName: "GetUnregisteredDeviceConfigs",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.GetUnregisteredDeviceConfigsRequest,
  responseType: amperix_device_noopt_pb.GetUnregisteredDeviceConfigsResponse
};

DeviceService.GetDeviceConfig = {
  methodName: "GetDeviceConfig",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.GetDeviceConfigRequest,
  responseType: amperix_device_noopt_pb.GetDeviceConfigResponse
};

DeviceService.AddDeviceConfig = {
  methodName: "AddDeviceConfig",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.AddDeviceConfigRequest,
  responseType: amperix_device_noopt_pb.AddDeviceConfigResponse
};

DeviceService.UpdateDeviceConfig = {
  methodName: "UpdateDeviceConfig",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.UpdateDeviceConfigRequest,
  responseType: amperix_device_noopt_pb.UpdateDeviceConfigResponse
};

DeviceService.DeleteDeviceConfig = {
  methodName: "DeleteDeviceConfig",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: amperix_device_noopt_pb.DeleteDeviceConfigRequest,
  responseType: amperix_device_noopt_pb.DeleteDeviceConfigResponse
};

exports.DeviceService = DeviceService;

function DeviceServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeviceServiceClient.prototype.getDeviceDrivers = function getDeviceDrivers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetDeviceDrivers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.getDefaultDeviceConfig = function getDefaultDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetDefaultDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.getDeviceConfigs = function getDeviceConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetDeviceConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.getUnregisteredDeviceConfigs = function getUnregisteredDeviceConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetUnregisteredDeviceConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.getDeviceConfig = function getDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.addDeviceConfig = function addDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.AddDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.updateDeviceConfig = function updateDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.UpdateDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.deleteDeviceConfig = function deleteDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.DeleteDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeviceServiceClient = DeviceServiceClient;

