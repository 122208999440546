// source: ext/options/field.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
var ext_amperix_options_options_pb = require('../../ext/amperix/options/options_pb.js');
goog.object.extend(proto, ext_amperix_options_options_pb);
var ext_ems_options_functional_constraints_pb = require('../../ext/ems/options/functional_constraints_pb.js');
goog.object.extend(proto, ext_ems_options_functional_constraints_pb);
var ext_ems_options_ems_options_pb = require('../../ext/ems/options/ems_options_pb.js');
goog.object.extend(proto, ext_ems_options_ems_options_pb);
var ext_gpiod_options_pb = require('../../ext/gpiod/options_pb.js');
goog.object.extend(proto, ext_gpiod_options_pb);
goog.exportSymbol('proto.de.mypowergrid.options.FieldOptions', null, global);
goog.exportSymbol('proto.de.mypowergrid.options.field', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.options.FieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.options.FieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.options.FieldOptions.displayName = 'proto.de.mypowergrid.options.FieldOptions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.options.FieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.options.FieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.options.FieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    functionalConstraints: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enumName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amperix: (f = msg.getAmperix()) && ext_amperix_options_options_pb.Amperix.toObject(includeInstance, f),
    gpio: (f = msg.getGpio()) && ext_gpiod_options_pb.GpioFieldOption.toObject(includeInstance, f),
    ems: (f = msg.getEms()) && ext_ems_options_ems_options_pb.EmsFieldOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.options.FieldOptions}
 */
proto.de.mypowergrid.options.FieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.options.FieldOptions;
  return proto.de.mypowergrid.options.FieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.options.FieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.options.FieldOptions}
 */
proto.de.mypowergrid.options.FieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.options.IECFunctionalConstraints} */ (reader.readEnum());
      msg.setFunctionalConstraints(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnumName(value);
      break;
    case 3:
      var value = new ext_amperix_options_options_pb.Amperix;
      reader.readMessage(value,ext_amperix_options_options_pb.Amperix.deserializeBinaryFromReader);
      msg.setAmperix(value);
      break;
    case 4:
      var value = new ext_gpiod_options_pb.GpioFieldOption;
      reader.readMessage(value,ext_gpiod_options_pb.GpioFieldOption.deserializeBinaryFromReader);
      msg.setGpio(value);
      break;
    case 5:
      var value = new ext_ems_options_ems_options_pb.EmsFieldOptions;
      reader.readMessage(value,ext_ems_options_ems_options_pb.EmsFieldOptions.deserializeBinaryFromReader);
      msg.setEms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.options.FieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.options.FieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.options.FieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFunctionalConstraints();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEnumName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmperix();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_amperix_options_options_pb.Amperix.serializeBinaryToWriter
    );
  }
  f = message.getGpio();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ext_gpiod_options_pb.GpioFieldOption.serializeBinaryToWriter
    );
  }
  f = message.getEms();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ext_ems_options_ems_options_pb.EmsFieldOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional de.mypowergrid.ems.options.IECFunctionalConstraints functional_constraints = 1;
 * @return {!proto.de.mypowergrid.ems.options.IECFunctionalConstraints}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.getFunctionalConstraints = function() {
  return /** @type {!proto.de.mypowergrid.ems.options.IECFunctionalConstraints} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.options.IECFunctionalConstraints} value
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
 */
proto.de.mypowergrid.options.FieldOptions.prototype.setFunctionalConstraints = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string enum_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.getEnumName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
 */
proto.de.mypowergrid.options.FieldOptions.prototype.setEnumName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional de.mypowergrid.amperix.options.Amperix amperix = 3;
 * @return {?proto.de.mypowergrid.amperix.options.Amperix}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.getAmperix = function() {
  return /** @type{?proto.de.mypowergrid.amperix.options.Amperix} */ (
    jspb.Message.getWrapperField(this, ext_amperix_options_options_pb.Amperix, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.options.Amperix|undefined} value
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
*/
proto.de.mypowergrid.options.FieldOptions.prototype.setAmperix = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
 */
proto.de.mypowergrid.options.FieldOptions.prototype.clearAmperix = function() {
  return this.setAmperix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.hasAmperix = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional de.mypowergrid.gpiod.GpioFieldOption gpio = 4;
 * @return {?proto.de.mypowergrid.gpiod.GpioFieldOption}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.getGpio = function() {
  return /** @type{?proto.de.mypowergrid.gpiod.GpioFieldOption} */ (
    jspb.Message.getWrapperField(this, ext_gpiod_options_pb.GpioFieldOption, 4));
};


/**
 * @param {?proto.de.mypowergrid.gpiod.GpioFieldOption|undefined} value
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
*/
proto.de.mypowergrid.options.FieldOptions.prototype.setGpio = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
 */
proto.de.mypowergrid.options.FieldOptions.prototype.clearGpio = function() {
  return this.setGpio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.hasGpio = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional de.mypowergrid.ems.options.EmsFieldOptions ems = 5;
 * @return {?proto.de.mypowergrid.ems.options.EmsFieldOptions}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.getEms = function() {
  return /** @type{?proto.de.mypowergrid.ems.options.EmsFieldOptions} */ (
    jspb.Message.getWrapperField(this, ext_ems_options_ems_options_pb.EmsFieldOptions, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.options.EmsFieldOptions|undefined} value
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
*/
proto.de.mypowergrid.options.FieldOptions.prototype.setEms = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.options.FieldOptions} returns this
 */
proto.de.mypowergrid.options.FieldOptions.prototype.clearEms = function() {
  return this.setEms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.options.FieldOptions.prototype.hasEms = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * A tuple of {field number, class constructor} for the extension
 * field named `field`.
 * @type {!jspb.ExtensionFieldInfo<!proto.de.mypowergrid.options.FieldOptions>}
 */
proto.de.mypowergrid.options.field = new jspb.ExtensionFieldInfo(
    91576,
    {field: 0},
    proto.de.mypowergrid.options.FieldOptions,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.de.mypowergrid.options.FieldOptions.toObject),
    0);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[91576] = new jspb.ExtensionFieldBinaryInfo(
    proto.de.mypowergrid.options.field,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.de.mypowergrid.options.FieldOptions.serializeBinaryToWriter,
    proto.de.mypowergrid.options.FieldOptions.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[91576] = proto.de.mypowergrid.options.field;

goog.object.extend(exports, proto.de.mypowergrid.options);
