/** @module grpc/protobuf/ems-helper */

import topologyDescription from '@/../lib/proto_json/ext/ems/topology/topology'
import TopologyPb from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import { buildDescriptor } from '@/grpc/parser'

Object.defineProperty(TopologyPb.Topology, 'name', {
  value: 'Topology',
  writable: false
})

export const EMS_TOPOLOGY_PKG_DESCRIPTION = topologyDescription.nested.de.nested.mypowergrid.nested.ems.nested.topology

export const EMS_TOPOLOGY_DESCRIPTOR = buildDescriptor(EMS_TOPOLOGY_PKG_DESCRIPTION, TopologyPb, {
  rootConstructorName: 'Topology'
})

export const EMS_SOURCE_DESCRIPTOR = buildDescriptor(EMS_TOPOLOGY_PKG_DESCRIPTION, TopologyPb, {
  rootConstructorName: 'Source'
})

/**
 * A "METER" is defined as a Sensor (source),
 * which measures increasing energy dmd/sup values (counters)
 *
 */
export const MTR_COUNTER_MEASURED_QUANTITIES = ['supplyWatthours', 'demandWatthours']

export const PHASE_TOPO_MEASURED_QUANTITIES = [
  'powerAcWatts', // double_mq: 1; three_phase_mq: 1
  'supplyWatthours', // double_mq: 3; three_phase_mq: 2
  'demandWatthours', // double_mq: 4; three_phase_mq: 3
  'voltageAcVolt', // double_mq: 7; three_phase_mq: 4
  'currentAcAmpere', // double_mq: 9; three_phase_mq: 5
  'apparentPower', // double_mq: 12; three_phase_mq: 6
  'reactivePower', // double_mq: 13; three_phase_mq: 7
  'avgPowerfactor', // double_mq: 17
  'powerfactor', // three_phase_mq: 8
  'harmonicDistortionPnCurrentPercent', // double_mq: 18; three_phase_mq: 9
  'harmonicDistortionPnVoltagePercent' // double_mq: 19; three_phase_mq: 10
]

/**
 * NOTE: sources with `vpp...` quantities are "purely virtual",
 * and should not be assignable to any physical device.
 *
 * `vpp...` quantities have field numbers: `28-39` and `41-42`.
 */
const srcDeviceDoubleMqs = [
  'powerDcWatts', // 2
  'stateOfChargePercent', // 5
  'stateOfHealthPercent', // 6
  'voltageDcVolt', // 8
  'currentDcAmpere', // 10
  'temperatureDegreeCelcius', // 11
  'inverterSetpointWatts', // 14
  'electricVehicleSupplyEquipmentSetpointWatts', // 15
  'electricVehicleSupplyEquipmentSetpointAmpere', // 16
  'supplyDcWatthours', // 20
  'demandDcWatthours', // 21
  'switchSetpointBool', // 22
  'contractualFeedInLimitWatts', // 23
  'flowRateLitersPerHour', // 24
  'volumeMeterQubicMeter', // 25
  'fuelcellSetpointWatts', // 26
  'heatingSetpointWatts', // 27
  'capacityBatteryWatthours', // 40
  'internalTemperatureDegreeCelcius', // 43
  'chpSetpointWatts', // 44
  'batterieEnableSetpointBool', // 45
  'ezaSuggestionWatts', // 46
  'ezaForwardSetpointWatts', // 47
  'ezaForwardSetpointVoltAmpereReactive', // 48
  'inverterSetpointVoltAmpereReactive', // 49
  'electrolyzerSetpointWatts', // 50
  'humidityPercent', // double_mq: 51
  'accCoolOutWattHours', // double_mq: 52
  'accHeatOutWattHours', // double_mq: 53
  'heatOutWatts', // double_mq: 54
  'fanSpeedPercent', // double_mq: 55
  'tmpDegreeCelcius', // double_mq: 56
  'incomingTmpDegreeCelcius', // double_mq: 57
  'outgoingTmpDegreeCelcius', // double_mq: 58
  'environmentTmpDegreeCelcius', // double_mq: 59
  'ezaStateOfChargeListenerPercent', // double_mq: 60
  'ezaMaxDischargeListenerWatts', // double_mq: 61
  'maxDischargeWatts', // double_mq: 62
  'ezaCapacityBatteryListenerWatthours', // double_mq: 63
  'ezaForwardSwitchPreventOffGridBool', // double_mq: 64
  'switchPreventOffGridSetpointBool', // double_mq: 65
  'maxChargeWatts', // double_mq: 66
  'ezaPowerListenerWatts', // 68 ZEZA.TotW ASG_SP
  'ezaReactivePowerListenerVoltAmpereReactive', // 69  ZEZA.TotVAr ASG_SP
  'ezaApparentPowerListenerVoltAmpere', // 70 ZEZA.TotVA ASG_SP
  'ezaPowerRatingListenerWatts' // 71 ZEZA.WRtg ASG_SP
]

const srcDeviceStatusMqs = [
  'pcsCcuState', // 1
  'pcsIpuState', // 2
  'pcsMcuState', // 3
  'wallboxState', // 4
  'gridState', // 5
  'airConditionerState', // 6
  'fireAlarmState', // 7
  'containerDoorState', // 8
  'containerSyncBoxState', // 9
  'containerStorageBoxState', // 10
  'healthState', // 11
  'ezaGridStateListener', // 12
  'ezaHealthStateListener', // 13
  'kacoInverterState', // 14
  'kacoPrechargeUnitState', // 15
  'kacoPrechargeUnitError' // 16
]

export const SRC_DEVICE_MEASURED_QUANTITIES = [...srcDeviceStatusMqs, ...srcDeviceDoubleMqs]

export const POWER_SETPOINT_SRC_QUANTITIES = [
  'inverterSetpointWatts',
  'electricVehicleSupplyEquipmentSetpointWatts',
  // 'electricVehicleSupplyEquipmentSetpointAmpere',
  'fuelcellSetpointWatts',
  'heatingSetpointWatts',
  'chpSetpointWatts',
  'ezaForwardSetpointWatts',
  'ezaForwardSetpointVoltAmpereReactive',
  'electrolyzerSetpointWatts'
]
