<template>
  <div>
    <EmsEditTopoConfigDevSelectLabel :label="label" />

    <div class="input-group">
      <div class="form-control p-0">
        <TreeSelect
          :value="value"
          disable-branch-nodes
          :clearable="false"
          :placeholder="$t('ems.topology.form.other.placeholder')"
          :options="physicalDeviceOpts"
          @input="onUpdateValue"
        />
      </div>
      <div class="input-group-append">
        <span class="input-group-text">
          <CLink class="input-info-action" @click="showDescription = !showDescription">
            <CIcon class="text-primary" name="cil-info-circle" :height="18" />
          </CLink>
        </span>
      </div>
    </div>
    <small v-show="showDescription" class="form-text text-muted w-100">
      {{ $t('ems.topology.descriptions.sourceToDevice') }}
    </small>
  </div>
</template>

<script>
import EmsEditTopoConfigDevSelectLabel from '@/components/ems/topology/ems-edit-topo-config-dev-select-label'

import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import { mapGetters } from 'vuex'
import { generatePhysicalDeviceOptsForTreeSelect } from '@/view-helper/ems/ems-topology-helper'

export default {
  name: 'EmsEditTopoConfigDevSelect',
  components: {
    EmsEditTopoConfigDevSelectLabel,
    TreeSelect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showDescription: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['existingPhysicalDevices']),
    physicalDeviceOpts() {
      return generatePhysicalDeviceOptsForTreeSelect(this.existingPhysicalDevices, { noDevice: true })
    }
  },
  methods: {
    onUpdateValue(value) {
      if (value === 'noDevice') {
        this.$emit('update:value', null)
      } else {
        this.$emit('update:value', value)
      }
    }
  }
}
</script>
