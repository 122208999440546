<template>
  <!-- prettier-ignore -->
  <CAlert
    v-if="alertMsg"
    close-button
    :color="alertMsg.type"
    :show="alertMsg.show"
    @update:show="onUpdateShow"
  >
    <template v-if="Array.isArray(alertMsg.content)">
      <template v-for="(entry, i) of alertMsg.content">
        <span :key="i" class="inline-block mb-1">
          {{ entry }}
        </span>
      </template>
    </template>
    <span v-if="typeof alertMsg.content === 'string'">
      {{ alertMsg.content }}
    </span>
  </CAlert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ApxMainAlert',
  computed: {
    ...mapState('notifications', ['alertMsg', 'alertPreserved']),
    isVisible() {
      // comparison of different types: for boolean values, true becomes 1 and false becomes 0
      return this.alertMsg && this.alertMsg.show > 0
    }
  },
  watch: {
    '$route.path': function (p) {
      if (this.alertPreserved) {
        this.$store.commit('notifications/PRESERVE_ALERT', false)
      } else {
        // hide if route changes
        this.$store.commit('notifications/SHOW_ALERT', false)
      }
    },
    isVisible: function (e) {
      // scroll alert into view
      if (e) {
        setTimeout(() => {
          if (this.$el.scrollIntoView) {
            this.$el.scrollIntoView(false)
          } else {
            this.$log.info('Failed to scroll main alert into view.')
          }
        }, 300)
      }
    }
  },
  methods: {
    onUpdateShow(e) {
      this.$store.commit('notifications/SHOW_ALERT', e)
    }
  }
}
</script>
