// source: ext/ems/acsi/acsi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Classes', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Error', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Request', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Request.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Response', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.Response.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.acsi.ServiceErrors', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.Error.displayName = 'proto.de.mypowergrid.ems.acsi.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.Request.displayName = 'proto.de.mypowergrid.ems.acsi.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.Response.displayName = 'proto.de.mypowergrid.ems.acsi.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.displayName = 'proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.displayName = 'proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.displayName = 'proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.displayName = 'proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.displayName = 'proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.displayName = 'proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.displayName = 'proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.displayName = 'proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceError: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.Error}
 */
proto.de.mypowergrid.ems.acsi.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.Error;
  return proto.de.mypowergrid.ems.acsi.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.Error}
 */
proto.de.mypowergrid.ems.acsi.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.acsi.ServiceErrors} */ (reader.readEnum());
      msg.setServiceError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceError();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ServiceErrors service_error = 1;
 * @return {!proto.de.mypowergrid.ems.acsi.ServiceErrors}
 */
proto.de.mypowergrid.ems.acsi.Error.prototype.getServiceError = function() {
  return /** @type {!proto.de.mypowergrid.ems.acsi.ServiceErrors} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.acsi.ServiceErrors} value
 * @return {!proto.de.mypowergrid.ems.acsi.Error} returns this
 */
proto.de.mypowergrid.ems.acsi.Error.prototype.setServiceError = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.Request.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.acsi.Request.TypeCase = {
  TYPE_NOT_SET: 0,
  GETSERVERDIRECTORY: 2,
  GETLOGICALDEVICEDIRECTORY: 3,
  GETLOGICALNODEDIRECTORY: 4,
  GETALLDATAVALUES: 5
};

/**
 * @return {proto.de.mypowergrid.ems.acsi.Request.TypeCase}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.acsi.Request.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    getserverdirectory: (f = msg.getGetserverdirectory()) && proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.toObject(includeInstance, f),
    getlogicaldevicedirectory: (f = msg.getGetlogicaldevicedirectory()) && proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.toObject(includeInstance, f),
    getlogicalnodedirectory: (f = msg.getGetlogicalnodedirectory()) && proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.toObject(includeInstance, f),
    getalldatavalues: (f = msg.getGetalldatavalues()) && proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.Request}
 */
proto.de.mypowergrid.ems.acsi.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.Request;
  return proto.de.mypowergrid.ems.acsi.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.Request}
 */
proto.de.mypowergrid.ems.acsi.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.deserializeBinaryFromReader);
      msg.setGetserverdirectory(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.deserializeBinaryFromReader);
      msg.setGetlogicaldevicedirectory(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.deserializeBinaryFromReader);
      msg.setGetlogicalnodedirectory(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.deserializeBinaryFromReader);
      msg.setGetalldatavalues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetserverdirectory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetlogicaldevicedirectory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetlogicalnodedirectory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetalldatavalues();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetServerDirectoryRequest GetServerDirectory = 2;
 * @return {?proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.getGetserverdirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
*/
proto.de.mypowergrid.ems.acsi.Request.prototype.setGetserverdirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.clearGetserverdirectory = function() {
  return this.setGetserverdirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.hasGetserverdirectory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GetLogicalDeviceDirectoryRequest GetLogicalDeviceDirectory = 3;
 * @return {?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.getGetlogicaldevicedirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
*/
proto.de.mypowergrid.ems.acsi.Request.prototype.setGetlogicaldevicedirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.clearGetlogicaldevicedirectory = function() {
  return this.setGetlogicaldevicedirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.hasGetlogicaldevicedirectory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetLogicalNodeDirectoryRequest GetLogicalNodeDirectory = 4;
 * @return {?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.getGetlogicalnodedirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
*/
proto.de.mypowergrid.ems.acsi.Request.prototype.setGetlogicalnodedirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.clearGetlogicalnodedirectory = function() {
  return this.setGetlogicalnodedirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.hasGetlogicalnodedirectory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GetAllDataValuesRequest GetAllDataValues = 5;
 * @return {?proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.getGetalldatavalues = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
*/
proto.de.mypowergrid.ems.acsi.Request.prototype.setGetalldatavalues = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.acsi.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Request} returns this
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.clearGetalldatavalues = function() {
  return this.setGetalldatavalues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Request.prototype.hasGetalldatavalues = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.Response.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.acsi.Response.TypeCase = {
  TYPE_NOT_SET: 0,
  ERROR: 1,
  GETSERVERDIRECTORY: 2,
  GETLOGICALDEVICEDIRECTORY: 3,
  GETLOGICALNODEDIRECTORY: 4,
  GETALLDATAVALUES: 5
};

/**
 * @return {proto.de.mypowergrid.ems.acsi.Response.TypeCase}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.acsi.Response.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.ems.acsi.Error.toObject(includeInstance, f),
    getserverdirectory: (f = msg.getGetserverdirectory()) && proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.toObject(includeInstance, f),
    getlogicaldevicedirectory: (f = msg.getGetlogicaldevicedirectory()) && proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.toObject(includeInstance, f),
    getlogicalnodedirectory: (f = msg.getGetlogicalnodedirectory()) && proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.toObject(includeInstance, f),
    getalldatavalues: (f = msg.getGetalldatavalues()) && proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.Response}
 */
proto.de.mypowergrid.ems.acsi.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.Response;
  return proto.de.mypowergrid.ems.acsi.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.Response}
 */
proto.de.mypowergrid.ems.acsi.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.acsi.Error;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.deserializeBinaryFromReader);
      msg.setGetserverdirectory(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.deserializeBinaryFromReader);
      msg.setGetlogicaldevicedirectory(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.deserializeBinaryFromReader);
      msg.setGetlogicalnodedirectory(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse;
      reader.readMessage(value,proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.deserializeBinaryFromReader);
      msg.setGetalldatavalues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.acsi.Error.serializeBinaryToWriter
    );
  }
  f = message.getGetserverdirectory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetlogicaldevicedirectory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetlogicalnodedirectory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetalldatavalues();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.ems.acsi.Error}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.Error|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
*/
proto.de.mypowergrid.ems.acsi.Response.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetServerDirectoryResponse GetServerDirectory = 2;
 * @return {?proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getGetserverdirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
*/
proto.de.mypowergrid.ems.acsi.Response.prototype.setGetserverdirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.clearGetserverdirectory = function() {
  return this.setGetserverdirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.hasGetserverdirectory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GetLogicalDeviceDirectoryResponse GetLogicalDeviceDirectory = 3;
 * @return {?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getGetlogicaldevicedirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
*/
proto.de.mypowergrid.ems.acsi.Response.prototype.setGetlogicaldevicedirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.clearGetlogicaldevicedirectory = function() {
  return this.setGetlogicaldevicedirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.hasGetlogicaldevicedirectory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetLogicalNodeDirectoryResponse GetLogicalNodeDirectory = 4;
 * @return {?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getGetlogicalnodedirectory = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
*/
proto.de.mypowergrid.ems.acsi.Response.prototype.setGetlogicalnodedirectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.clearGetlogicalnodedirectory = function() {
  return this.setGetlogicalnodedirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.hasGetlogicalnodedirectory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GetAllDataValuesResponse GetAllDataValues = 5;
 * @return {?proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.getGetalldatavalues = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
*/
proto.de.mypowergrid.ems.acsi.Response.prototype.setGetalldatavalues = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.acsi.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.Response} returns this
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.clearGetalldatavalues = function() {
  return this.setGetalldatavalues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.Response.prototype.hasGetalldatavalues = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lnreference: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest;
  return proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLnreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLnreference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string LNReference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.prototype.getLnreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesRequest.prototype.setLnreference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lnreference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataattributereferenceList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dataattributevalue: (f = msg.getDataattributevalue()) && google_protobuf_struct_pb.ListValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse;
  return proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLnreference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDataattributereference(value);
      break;
    case 3:
      var value = new google_protobuf_struct_pb.ListValue;
      reader.readMessage(value,google_protobuf_struct_pb.ListValue.deserializeBinaryFromReader);
      msg.setDataattributevalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLnreference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataattributereferenceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDataattributevalue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_struct_pb.ListValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string LNReference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.getLnreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.setLnreference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string DataAttributeReference = 2;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.getDataattributereferenceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.setDataattributereferenceList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.addDataattributereference = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.clearDataattributereferenceList = function() {
  return this.setDataattributereferenceList([]);
};


/**
 * optional google.protobuf.ListValue DataAttributeValue = 3;
 * @return {?proto.google.protobuf.ListValue}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.getDataattributevalue = function() {
  return /** @type{?proto.google.protobuf.ListValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.ListValue, 3));
};


/**
 * @param {?proto.google.protobuf.ListValue|undefined} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
*/
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.setDataattributevalue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.clearDataattributevalue = function() {
  return this.setDataattributevalue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.acsi.GetAllDataValuesResponse.prototype.hasDataattributevalue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lnreference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    acsiclass: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest;
  return proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLnreference(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.acsi.Classes} */ (reader.readEnum());
      msg.setAcsiclass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLnreference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAcsiclass();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string LNReference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.getLnreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.setLnreference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Classes ACSIClass = 2;
 * @return {!proto.de.mypowergrid.ems.acsi.Classes}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.getAcsiclass = function() {
  return /** @type {!proto.de.mypowergrid.ems.acsi.Classes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.acsi.Classes} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryRequest.prototype.setAcsiclass = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancenameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse;
  return proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addInstancename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancenameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string InstanceName = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.getInstancenameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.setInstancenameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.addInstancename = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalNodeDirectoryResponse.prototype.clearInstancenameList = function() {
  return this.setInstancenameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ldreference: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest;
  return proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLdreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLdreference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string LDReference = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.prototype.getLdreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryRequest.prototype.setLdreference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lnreferenceList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse;
  return proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLnreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLnreferenceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string LNReference = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.getLnreferenceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.setLnreferenceList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.addLnreference = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetLogicalDeviceDirectoryResponse.prototype.clearLnreferenceList = function() {
  return this.setLnreferenceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest;
  return proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse;
  return proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string Reference = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.getReferenceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.setReferenceList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.addReference = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse} returns this
 */
proto.de.mypowergrid.ems.acsi.GetServerDirectoryResponse.prototype.clearReferenceList = function() {
  return this.setReferenceList([]);
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.acsi.ServiceErrors = {
  INSTANCE_NOT_AVAILABLE: 0,
  INSTANCE_IN_USE: 1,
  ACCESS_VIOLATION: 2,
  ACCESS_NOT_ALLOWED_IN_CURRENT_STATE: 3,
  PARAMETER_VALUE_INAPPROPRIATE: 4,
  PARAMETER_VALUE_INCONSISTENT: 5,
  CLASS_NOT_SUPPORTED: 6,
  INSTANCE_LOCKED_BY_OTHER_CLIENT: 7,
  CONTROL_MUST_BE_SELECTED: 8,
  TYPE_CONFLICT: 9,
  FAILED_DUE_TO_COMMUNICATIONS_CONSTRAINT: 10,
  FAILED_DUE_TO_SERVER_CONSTRAINT: 11
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.acsi.Classes = {
  DATA: 0,
  DATA_SET: 1,
  BRCB: 2,
  URCB: 3,
  LCB: 4,
  LOG: 5,
  SGCB: 6,
  GOCB: 7,
  GSCB: 8,
  MSVCB: 9,
  USVCB: 10
};

goog.object.extend(exports, proto.de.mypowergrid.ems.acsi);
