<template>
  <!-- prettier-ignore -->
  <nav class="navbar navbar-expand-sm bg-light">
    <CButton
      class="mr-auto"
      type="button"
      size="sm"
      color="secondary"
      @click="routeToDevices"
    >
      <CIcon size="sm" name="cilDescription" />
      <span class="align-middle d-none d-sm-inline">
        {{ $t('config.device.list.showAllConfigs') }}
      </span>
    </CButton>
    <CButton
      class="mx-1 text-white"
      type="button"
      size="sm"
      color="primary"
      @click="routeToDeviceEdit"
    >
      {{ $t('config.device.nav.settingsBtn') }}
    </CButton>
    <CButton
      v-if="$route.name !== 'config-device-status'"
      class="mx-1 text-white"
      type="button"
      size="sm"
      color="primary"
      @click="routeToDeviceStatus"
    >
      {{ $t('config.device.nav.statusBtn') }}
    </CButton>
    <CButton
      v-if="$route.name !== 'config-device-operate' && $route.params.device !== 'meter'"
      class="mx-1 text-white"
      type="button"
      size="sm"
      color="primary"
      @click="routeToDeviceOperate"
    >
      {{ $t('config.device.nav.operateBtn') }}
    </CButton>
  </nav>
</template>

<script>
export default {
  name: 'ApxDeviceConfigNav',
  props: {
    device: {
      type: String,
      required: true
    }
  },
  methods: {
    routeToDevices() {
      this.$router.push({
        name: 'config-devices',
        params: { device: this.device + 's' }
      })
    },
    routeToDeviceEdit() {
      this.$router.push({
        name: 'config-device-edit',
        params: { id: this.$route.params.id }
      })
    },
    routeToDeviceStatus() {
      this.$router.push({
        name: 'config-device-status',
        params: { id: this.$route.params.id }
      })
    },
    routeToDeviceOperate() {
      this.$router.push({
        name: 'config-device-operate',
        params: { id: this.$route.params.id }
      })
    }
  }
}
</script>
