<template>
  <CFooter class="pl-2 pt-2 pb-2" :fixed="!isMobileSize">
    <!-- prettier-ignore -->
    <CIcon
      class="c-sidebar-brand-full"
      name="temFooterLogoLight"
      size="custom"
      :height="45"
    />
    <div class="d-flex flex-fill justify-content-end align-items-end h-100">
      <span class="text-light">{{ version }}</span>
    </div>
  </CFooter>
</template>

<script>
import { registerViewportMobileSizeListener } from '@/view-helper/viewport'
import { mapGetters } from 'vuex'

export default {
  name: 'ApxFooter',
  data() {
    return {
      isMobileSize: true
    }
  },
  computed: {
    ...mapGetters('apxInformations', ['version'])
  },
  mounted() {
    registerViewportMobileSizeListener.call(this)
    this.$store.dispatch('apxInformations/fetchVersion')
  }
}
</script>
