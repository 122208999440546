/** @module */

import { controllerRpc } from '@/api/ems/controller'
import { EvalExpression as EvalExpressionPb } from '@/../lib/proto_js/ext/ems/eval_pb'
import { ChangeAlias as ChangeAliasPb, Name as NamePb } from '@/../lib/proto_js/ext/ems/controller/controller_pb'
import { aliasError } from '@/api/error-handling'

/**
 * gRPC unary call to list all eval aliases.
 *
 * @function
 *
 * @return {promise}
 */
export async function listAliases() {
  return controllerRpc('list_aliases')
}

export async function createAlias(name) {
  const defaultValue = new EvalExpressionPb()
  defaultValue.setValue(0)

  const command = new ChangeAliasPb()
  command.setName(name)
  command.setExpression(defaultValue)

  return controllerRpc('create_alias', command).then(aliasError)
}

export async function removeAlias(name) {
  const command = new NamePb()
  command.setName(name)

  return controllerRpc('remove_alias', command)
}

export async function getAlias(name) {
  const command = new NamePb()
  command.setName(name)

  return controllerRpc('get_alias', command)
}

export async function setAlias(name, expressionPb) {
  if (expressionPb === undefined) {
    throw new Error('DEBUG:setAlias called incorrectly') // should user never see -> no translation
  }
  const command = new ChangeAliasPb()
  command.setName(name)
  command.setExpression(expressionPb)

  return controllerRpc('change_alias', command).then(aliasError)
}
