<template>
  <CDropdown add-menu-classes="pt-0" class="c-header-nav-items" in-nav placement="bottom-end">
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-language" :height="24" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader class="text-center" color="light" tag="div">
      <strong>{{ $t('header.languageDropdownTitle') }}</strong>
    </CDropdownHeader>
    <CDropdownItem class="border-top" @click="changeLanguage('de')"> DE </CDropdownItem>
    <CDropdownItem class="border-top" @click="changeLanguage('en')"> EN </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['lang'])
  },
  methods: {
    changeLanguage: function (language) {
      this.$log.debug(language)
      if (language === 'de') {
        this.$store.commit('user/SET_LOCALE', 'de')
      } else if (language === 'en') {
        this.$store.commit('user/SET_LOCALE', 'en')
      }
    }
  }
}
</script>

<style scoped>
.c-header-nav .dropdown-item {
  min-width: 80px;
}
</style>
