const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: false
}

const mutations = {
  TOGGLE_SIDEBAR_DESKTOP(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  TOGGLE_SIDEBAR_MOBILE(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  SET(state, [variable, value]) {
    state[variable] = value
  },
  TOGGLE(state, variable) {
    state[variable] = !state[variable]
  }
}

const actions = {}

const getters = {
  sidebarShow: (state) => {
    return state.sidebarShow
  },
  sidebarMinimize: (state) => {
    return state.sidebarMinimize
  },
  darkMode: (state) => {
    return state.darkMode
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
