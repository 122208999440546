/*
 * helper function to trigger all childviews validations and return the valid state
 * @param [VueComponent] component
 * @return [Boolean] true if all children valid, else false
 */

import i18n from '@/i18n'

export function recursivelyValidateEvalTreeVue(component) {
  let allValid = true
  for (const child of component.$children) {
    allValid &&= recursivelyValidateEvalTreeVue(child)
  }

  if (component.$v) {
    component.$v.$touch()
    allValid &&= !component.$v.$invalid
  }

  return allValid
}

export const buildEvalTreeLabelTranslation = (tPath) => {
  const tBasePath = 'ems.energyService.config.evalTree.form'

  return (label) => {
    const hasT = tPath ? i18n.te(`${tBasePath}.${tPath}.${label}`) : null

    return hasT ? i18n.t(`${tBasePath}.${tPath}.${label}`) : label
  }
}

export function generateTreeSelectOptionsFromMap({
  valuesToCategories = {},
  categoryLabelFunction = (c) => c,
  valueLabelFunction = (v) => v
}) {
  const treeOptions = []

  const sortAlphaNum = (a, b) => a.label.localeCompare(b.label, i18n.locale, { numeric: true })

  for (const category of [...new Set(Object.values(valuesToCategories))]) {
    treeOptions.push({
      id: category,
      label: categoryLabelFunction(category),
      children: []
    })
  }

  for (const value of Object.keys(valuesToCategories)) {
    treeOptions
      .find((node) => node.id === valuesToCategories[value])
      .children.push({
        id: value,
        label: valueLabelFunction(value)
      })
  }

  const finalTreeOptions = treeOptions.filter((treeOption) => treeOption.children?.length > 1)

  finalTreeOptions.map((treeOption) => treeOption.children.sort(sortAlphaNum))

  treeOptions
    .filter((treeOption) => treeOption.children?.length === 1)
    .map((treeOption) =>
      finalTreeOptions.push({
        id: treeOption.children[0].id,
        label: treeOption.children[0].label
      })
    )

  finalTreeOptions.sort(sortAlphaNum)

  return finalTreeOptions
}

export function operatorOptionsForTreeSelect({ blacklistedOperators = [] }) {
  const operatorsToTypes = {
    value: 'value',
    alias: 'alias',
    diff: 'arithmetic_operation',
    prod: 'arithmetic_operation',
    sum: 'arithmetic_operation',
    ratio: 'arithmetic_operation',
    max: 'aggregation_operation',
    min: 'aggregation_operation',
    less: 'compare_operation',
    lessOrEqual: 'compare_operation',
    greaterOrEqual: 'compare_operation',
    greater: 'compare_operation',
    and: 'logical_operation',
    or: 'logical_operation',
    not: 'logical_operation',
    reference: 'reference_operation',
    'reference-timeseries': 'reference_operation',
    'reference-sensor': 'reference_operation',
    'reference-sensor-all': 'reference_operation',
    timeseriesPoint: 'timePoint_operation',
    'timepoint-sensor': 'timePoint_operation',
    'timepoint-sensor-all': 'timePoint_operation',
    timeseriesAggregate: 'timeAggregate_operation',
    'timeaggregate-sensor': 'timeAggregate_operation',
    'timeaggregate-sensor-all': 'timeAggregate_operation'
  }

  for (const operator in operatorsToTypes) {
    if (blacklistedOperators.includes(operator)) {
      delete operatorsToTypes[operator]
    }
  }

  return generateTreeSelectOptionsFromMap({
    valuesToCategories: operatorsToTypes,
    categoryLabelFunction: buildEvalTreeLabelTranslation('operators.operatorTypes'),
    valueLabelFunction: buildEvalTreeLabelTranslation('operators.operatorValues')
  })
}
