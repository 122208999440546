/**
 * @module validations/ems-topology-validators
 */

import { isEmpty, isEqual, isNil } from 'lodash'
import logger from '@/logger'
import { humanizePhysDevType } from '@/store/modules/_ems-topology-config-helper'

/**
 * Allows to validate the data of the `ems.topology.Topology`.
 * However, expected data structure to be validated are the `emsTopologyConfig` store data.
 *
 * Should not be used with vuelidate.
 *
 * @constructor
 *
 */
export function TopologyValidator() {
  function ValidationError(params) {
    this.type = params.type
  }

  this.assignmentErrors = []
  this.physicalDevicesErrors = []

  this.isValid = (kind = '') => {
    if (!kind) {
      return this.physicalDevicesErrors.length + this.assignmentErrors.length === 0
    }

    switch (kind) {
      case 'assignment':
        return this.assignmentErrors.length === 0
      case 'physical-devices':
        return this.physicalDevicesErrors.length === 0
      default:
        return false
    }
  }

  this.getError = (kind = '') => {
    if (!kind) {
      return this.assignmentErrors.concat(this.physicalDevicesErrors)
    }

    switch (kind) {
      case 'assignment':
        return this.assignmentErrors[0]
      case 'physical-devices':
        return this.physicalDevicesErrors[0]
      default:
        return null
    }
  }

  /**
   * Validates the list of existing physical devices (`ems.topology.PhysicalDevice` as plain Object).
   *
   * @function
   *
   * @param {object} params
   * @param {array} params.existingPhysicalDevices has to be a list of `ems.topology.PhysicalDevice` plain JS objects
   *
   * @return {boolean}
   */
  this.validatePhysicalDevices = ({ existingPhysicalDevices }) => {
    const pds = existingPhysicalDevices
    const validators = [
      {
        type: 'notEmpty',
        validate: () => {
          return Array.isArray(pds) && pds.length > 0
        }
      },
      {
        type: 'hasSupportedPhysicalDevices',
        validate: () => {
          return pds.every((pd) => {
            if (!pd) {
              return false
            }

            return humanizePhysDevType(pd) !== 'UNSUPPORTED'
          })
        }
      },
      {
        type: 'hasUser',
        validate: () => {
          return pds.some((pd) => {
            if (!pd) {
              return false
            }

            return humanizePhysDevType(pd) === 'USER'
          })
        }
      }
    ]

    const { errors } = runValidation({ validators })
    this.physicalDevicesErrors = this.physicalDevicesErrors.concat(errors)

    return errors.length === 0
  }

  this.validateAssignments = (state) => {
    const validators = [
      {
        type: 'notEmpty',
        validate: () => {
          if (state.gridMtrIecId) {
            return true
          }

          if (!state.topology) {
            return false
          }

          return !isEmpty(state.topology.phaseTopologiesList) || !isEmpty(state.topology.sourceToDeviceTopologiesList)
        }
      },
      {
        type: 'allPhysDevsAssigned',
        validate: () => {
          try {
            if (state.gridMtrIecId) {
              return true
            }

            return state.existingPhysicalDevices.every((physDev) => {
              let included = state.topology.phaseTopologiesList.some((pt) => {
                return pt.physicalDeviceOnPhaseList.some((pdop) => {
                  const pd = pdop.physicalDevice

                  return !isNil(pdop.physicalDevice) && isEqual(pd, physDev)
                })
              })

              if (included) {
                return true
              }

              included = state.topology.sourceToDeviceTopologiesList.some((st) => {
                return !isNil(st.physicalDevice) && isEqual(st.physicalDevice, physDev)
              })

              return included
            })
          } catch (err) {
            logger.error(err)
          }

          return false
        }
      },
      {
        type: 'hasGrid',
        validate: () => {
          return !!state.gridMtrIecId
        }
      }
    ]

    const { errors } = runValidation({ validators })
    this.assignmentErrors = this.assignmentErrors.concat(errors)

    return errors.length === 0
  }

  // private
  function runValidation({ validators }) {
    const errors = []
    let isValid = true

    for (const v of validators) {
      if (!isValid) {
        break
      }
      isValid = isValid && v.validate()

      if (isValid) {
        continue
      }

      errors.push(new ValidationError({ type: v.type }))
    }

    return { errors }
  }
}

/**
 * Runs validation for the EMS topology.
 *
 * @function
 *
 * @param {object} ctxt has to be the View context, i.e. the vue instance, which provides the getters (data) for the `emsTopologyConfig` store-module
 *
 * @return {TopologyValidator} the validator
 */
export function validateTopology(ctxt) {
  const validator = new TopologyValidator()
  if (!ctxt.existingPhysicalDevices) {
    throw new TypeError('ctxt missed "existingPhysicalDevices"')
  }

  if (!ctxt.topology) {
    throw new TypeError('ctxt missed "topology"')
  }

  validator.validatePhysicalDevices({
    existingPhysicalDevices: ctxt.existingPhysicalDevices
  })

  validator.validateAssignments(ctxt)

  return validator
}
