/**
 * @module view-helper/device-config
 */

import { isEqual } from 'lodash'

export const deviceConfigHooks = {
  inverter: {
    config4: {
      inverterDroopsFPDroopMainLower: 'showWarningDialog',
      inverterDroopsFPDroopMainUpper: 'showWarningDialog',
      inverterDroopsFPDroopT1Main: 'showWarningDialog',
      inverterDroopsPFDeadBandLower: 'showWarningDialog',
      inverterDroopsPFDeadBandUpper: 'showWarningDialog',
      inverterDroopsPFDroopMainLower: 'showWarningDialog',
      inverterDroopsPFDroopMainUpper: 'showWarningDialog',
      inverterDroopsPUDeadBandLower: 'showWarningDialog',
      inverterDroopsPUDeadBandUpper: 'showWarningDialog',
      inverterDroopsPUDroopLower: 'showWarningDialog',
      inverterDroopsPUDroopUpper: 'showWarningDialog',
      inverterDroopsPUMaxCharge: 'showWarningDialog',
      inverterDroopsPUMaxDischarge: 'showWarningDialog',
      inverterDroopsQLimit: 'showWarningDialog',
      inverterDroopsQUDeadBandLower: 'showWarningDialog',
      inverterDroopsQUDeadBandUpper: 'showWarningDialog',
      inverterDroopsQUDroopMainLower: 'showWarningDialog',
      inverterDroopsQUDroopMainUpper: 'showWarningDialog',
      inverterDroopsUQDroopMainLower: 'showWarningDialog',
      inverterDroopsUQDroopMainUpper: 'showWarningDialog',
      inverterDroopsUQDroopT1Main: 'showWarningDialog'
    }
  }
}

export function createCompareResults(a, b, compareToResultSturcture) {
  return createCompareResultsIntern(a, b, compareToResultSturcture, [])
}

function collectContainedLeafs(object, hooks, path) {
  let list = []
  for (const [key, value] of Object.entries(hooks)) {
    if (object[key] !== undefined) {
      if (typeof value === 'string') {
        list.push({ result: value, path: path.concat(key) })
      } else {
        list = list.concat(collectContainedLeafs(object[key], hooks[key], path.concat(key)))
      }
    }
  }
  return list
}

function createCompareResultsIntern(a, b, compareToResultSturcture, path) {
  let list = []
  for (const [key, value] of Object.entries(compareToResultSturcture)) {
    if (a[key] === undefined && b[key] === undefined) {
      continue
    }

    if (a[key] !== undefined && b[key] === undefined) {
      list = list.concat(collectContainedLeafs(a[key], compareToResultSturcture[key], path.concat(key)))
      continue
    }

    if (a[key] === undefined && b[key] !== undefined) {
      list = list.concat(collectContainedLeafs(b[key], compareToResultSturcture[key], path.concat(key)))
      continue
    }

    if (typeof value === 'string') {
      if (!isEqual(a[key], b[key])) {
        list.push({ result: value, path: path.concat(key) })
      }
    } else {
      list = list.concat(createCompareResultsIntern(a[key], b[key], compareToResultSturcture[key], path.concat(key)))
    }
  }

  return list
}
