export function secToMin(val) {
  if (!val) {
    return val
  }

  return val / 60
}

export function minToSec(val) {
  if (!val) {
    return val
  }

  return val * 60
}

export function strToFloat(val) {
  if (typeof val === 'string') {
    return parseFloat(val)
  }

  return val
}

/**
 * Computed property for EsOffGridBatteryProtection Form and Preview
 *
 * @function
 *
 * @return {null|numeric}
 */
export function probingDurationOnInMin() {
  return secToMin(this.probingDurationOnInSec)
}

/**
 * Computed property for EsOffGridBatteryProtection Form and Preview
 *
 * @function
 *
 * @return {null|numeric}
 */
export function probingDurationOffInMin() {
  return secToMin(this.probingDurationOffInSec)
}
