/**
 * Mutations for the [ems-topology-config store]{@link module:store/ems-topology-config}
 *
 * @module store/ems-topology-config-mutations
 */

import { isEmpty, isEqual } from 'lodash'
import logger from '@/logger'
import Vue from 'vue'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'

export function ADD_PHYSICAL_DEVICE(state, physDev) {
  if (!physDev || typeof physDev.type !== 'number' || typeof physDev.number !== 'number') {
    throw new TypeError('Invalid physical device. Cannot be added.')
  }

  const isExisting = state.existingPhysicalDevices.some((pd) => isEqual(pd, physDev))
  if (isExisting) {
    logger.warn('Tried to add already existing physical device. Ignoring:', physDev)
    return
  }

  state.existingPhysicalDevices.push(physDev)
}

export function REMOVE_PHYSICAL_DEVICE(state, physDev) {
  const i = state.existingPhysicalDevices.findIndex((pd) => isEqual(pd, physDev))

  if (i < 0) {
    logger.warn('Tried to remove a non-existing physical device. Ignoring')
    return
  }

  state.existingPhysicalDevices.splice(i, 1)
}

export function CLEAR_PHYSICAL_DEVICES(state) {
  state.existingPhysicalDevices = []
}

export function SET_SOURCE_QUANTITIES(state, srcQuantities) {
  state.sourceQuantitiesList = srcQuantities.filter((sqs) => {
    const isIec = sqs.source && sqs.source.iec && sqs.source.iec.logicalDeviceName
    if (!isIec) {
      logger.info('Received an EMS topology.SourceQuantities Source, which is NOT an IEC. Ignoring.')
    }

    return isIec
  })
}

export function SET_GRID_MTR_IECID(state, iecId) {
  if (iecId.iecNodePrefix !== 'mtr') {
    logger.warn('Tries to add grid mtr IecId which has not the mtr prefix. Are we sure?')
  }
  state.gridMtrIecId = iecId
}

export function SET_SWAP_GRID_MTR(state, swap) {
  state.swapGridMtr = swap
}

export function CLEAR_GRID_MTR_IECID(state) {
  state.gridMtrIecId = null
  state.swapGridMtr = false
}

export function SET_TOPOLOGY(state, topology) {
  state.topology = topology
}

/**
 * Mutation to add (actually add/update/remove) a `ems.topology.PhaseTopology` plain JS object to the store.
 *
 * Adding a `PhaseTopology` with empty `physical_device_on_phase`,
 * will remove this topo entry.
 *
 * Adding an existing `PhaseTopology` will update (replace) the topo entry.
 * However, will preserve the existing `swap` flag.
 *
 * Adding a non-existing `PhaseTopology` will add a new topo entry.
 *
 * @function
 *
 * @param {object} phaseTopo has to be a plain JS object of `ems.topology.PhaseTopology`
 *
 */
export function ADD_PHASE_TOPOLOGY(state, phaseTopo) {
  const phaseTopos = state.topology.phaseTopologiesList
  const i = phaseTopos.findIndex((pt) => {
    return isEqual(pt.source, phaseTopo.source)
  })
  const shouldRm = isEmpty(phaseTopo.physicalDeviceOnPhaseList)

  if (i < 0 && !shouldRm) {
    phaseTopos.push(phaseTopo) // add
  } else if (i >= 0 && !shouldRm) {
    phaseTopo.swap = phaseTopos[i].swap
    phaseTopos.splice(i, 1, phaseTopo) // replace
  } else if (i >= 0 && shouldRm) {
    phaseTopos.splice(i, 1) // rm
  }
}

/**
 * Mutation to add (actually add/update/remove) a `ems.topology.SourceToDeviceTopology` plain JS object to the store.
 *
 * Adding a `SourceToDeviceTopology` with empty physical device,
 * will remove this topo entry.
 * Adding an existing `SourceToDeviceTopology` will update (replace) the topo entry.
 *
 * @function
 *
 * @param {object} srcTopo has to be a plain JS object of `ems.topology.SourceToDeviceTopology`
 *
 */
export function ADD_SOURCE_TO_DEVICE_TOPOLOGY(state, srcTopo) {
  const srcTopos = state.topology.sourceToDeviceTopologiesList
  const i = srcTopos.findIndex((st) => {
    return isEqual(st.source, srcTopo.source)
  })
  const shouldRm = isEmpty(srcTopo.physicalDevice)

  if (i < 0 && !shouldRm) {
    srcTopos.push(srcTopo) // add
  } else if (i >= 0 && !shouldRm) {
    srcTopos.splice(i, 1, srcTopo) // replace
  } else if (i >= 0 && shouldRm) {
    srcTopos.splice(i, 1) // rm
  }
}

/**
 * Allows to SET the `PhaseTopology.swap` value for a source.
 * Will NOT set, if this source does NOT have any phase-topo.
 *
 * @function
 *
 * @param {object} arg
 *
 */
export function SET_SWAP_MTR(state, { iecId, swap }) {
  if (isEqual(state.gridMtrIecId, iecId)) {
    state.swapGridMtr = swap
    return
  }

  const pt = state.topology.phaseTopologiesList.find((pt) => {
    return pt.source && isEqual(pt.source.iec, iecId)
  })

  if (!pt) {
    logger.warn('Failed to set the phase-topo swap flag, because the source does not have any phase-topo.')
    return
  }

  pt.swap = swap
}

export function CLEAR_SETPOINT_SOURCES(state) {
  state.setpointSources = {}
}

export function SET_SETPOINT_SOURCE(state, { setpointSource }) {
  Vue.set(state.setpointSources, iecIdToLiteral(setpointSource.iec), setpointSource)
}
