// package: de.mypowergrid.amperix
// file: ext/amperix/network.proto

var ext_amperix_network_pb = require("../../ext/amperix/network_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NetworkService = (function () {
  function NetworkService() {}
  NetworkService.serviceName = "de.mypowergrid.amperix.NetworkService";
  return NetworkService;
}());

NetworkService.Ping = {
  methodName: "Ping",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_network_pb.PingRequest,
  responseType: ext_amperix_network_pb.PingResponse
};

NetworkService.SetUplinkNetworkConfig = {
  methodName: "SetUplinkNetworkConfig",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_network_pb.SetUplinkNetworkConfigRequest,
  responseType: ext_amperix_network_pb.SetUplinkNetworkConfigResponse
};

NetworkService.GetUplinkNetworkConfig = {
  methodName: "GetUplinkNetworkConfig",
  service: NetworkService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_network_pb.GetUplinkNetworkConfigRequest,
  responseType: ext_amperix_network_pb.GetUplinkNetworkConfigResponse
};

exports.NetworkService = NetworkService;

function NetworkServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NetworkServiceClient.prototype.ping = function ping(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.Ping, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NetworkServiceClient.prototype.setUplinkNetworkConfig = function setUplinkNetworkConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.SetUplinkNetworkConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NetworkServiceClient.prototype.getUplinkNetworkConfig = function getUplinkNetworkConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NetworkService.GetUplinkNetworkConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NetworkServiceClient = NetworkServiceClient;

