/**
 * @module view-helper/device-config
 */

import { lowerFirst, upperFirst, startCase } from 'lodash'
import { Amperix as OptAmperixPb } from '@/../lib/proto_js/ext/amperix/options/options_pb'
import i18n from '@/i18n'
import logger from '@/logger'
import { DEVICE_CONFIG_DESCRIPTION, DEVICE_CONFIG_DESCRIPTOR } from '@/grpc/protobuf/device-config-helper'
import { isProto } from '@/grpc/protobuf/misc'
import {
  protoTypeToJsType,
  findDescription,
  iterateScalarFieldsOfDescription,
  objectToProtoMsg,
  safeGetMsgChain
} from '@/grpc/parser'
import { DeviceConfigFormValidator } from '@/view-helper/device-config/device-config-form-validator'

/**
 * Provides state and logic to dynamically work on, update and analyze a protobuf DeviceConfig.
 *
 * @constructor
 *
 * @param {object} options
 * @param {object} options.rootDescription (optional) is the protobuf message description (JSON) for the `DeviceConfig`.
 * @param {object} options.rootDescriptor (optional) is the protobuf message descriptor for the `DeviceConfig`
 * @param {DeviceConfigFormValidator} options.validator (optional) will be applied to validate config fields after scaler field is set.
 *
 */
export function DeviceConfigFormInteractor({ rootDescription, rootDescriptor, vue, validator }) {
  rootDescription = rootDescription || DEVICE_CONFIG_DESCRIPTION
  rootDescriptor = rootDescriptor || DEVICE_CONFIG_DESCRIPTOR
  if (!vue || typeof vue !== 'object') {
    throw new TypeError('The "vue" instance is missing as input argument. It is required.')
  }

  if (typeof vue.cfg !== 'object' || typeof vue.desc !== 'object') {
    throw new TypeError('The "vue" instance has to have data properties "cfg" and "desc".')
  }

  if (!validator) {
    // init 'dummy' one without validation functionality
    // avoids a lot of checking if validator is present
    validator = new DeviceConfigFormValidator({ vue })
  }

  // have to be set during initialization
  const configIds = {
    id: '',
    driverId: '',
    configTypeId: ''
  }
  let configMsgName
  let configFieldNums = []
  let configFieldNames = []
  let configDescriptor = {}
  let configCache = {}

  /**
   * A list of scalar form field descripions.
   * Each description is a `ScalarFormFieldDesc|SelectionFormFieldDesc` or a `ForkDesc`.
   *
   * @member {array}
   */
  this.desc = []

  /**
   * The actual proto config for this Device and Config-Type
   * as proto JS function.
   * Depending on user interaction it can be changed,
   * e.g. by setting scalar fields or setting or resetting a oneof field.
   *
   * @member {object}
   */
  this.cfg = {}

  const that = this

  function toLiteral(someIds) {
    if (!Array.isArray(someIds)) {
      throw new TypeError('Invalid argument. "someIds" has to be an array.')
    }

    return someIds.join('.')
  }

  this.toLiteral = toLiteral

  function fromLiteral(someLiteral) {
    if (typeof someLiteral !== 'string') {
      throw new TypeError('Invalid argument. "someLiteral" has to be a string.')
    }

    return someLiteral.split('.').map((i) => parseInt(i))
  }

  this.fromLiteral = fromLiteral

  /**
   * Initializes the state for a NEW device config.
   *
   * @function
   *
   * @param {object} cfgInfo (required) is a [DeviceConfigInfo]{@link module:store/device.DeviceConfigInfo}. The `cfgInfo` is validated to fit with the root description and -descriptor.
   *
   */
  this.initNew = async function (cfgInfo) {
    validateCfgInfo(cfgInfo)

    const config = await that._getDefault({
      fieldNumbers: cfgInfo.configMsgFieldNumPath,
      driverId: cfgInfo.driverId
    })
    validateConfig(config)

    that._init(cfgInfo, config)
  }

  /**
   * Initializes the state for an existing (EDIT) device config.
   *
   * @function
   *
   * @param {object} cfgInfo (required) is a [DeviceConfigInfo]{@link module:store/device.DeviceConfigInfo}. The `cfgInfo` is validated to fit with the root description and -descriptor.
   * @param {object} deviceConfig (required) is the `DevicePb.DeviceConfig` JS proto (or plain object) message as returned by the BE.
   */
  this.initEdit = async function (cfgInfo, deviceConfig) {
    // duck typing
    if (!deviceConfig || !(deviceConfig.hasId || Object.prototype.hasOwnProperty.call(deviceConfig, 'id'))) {
      throw new TypeError('Argument "deviceConfig" has to be a JS-protobuf DeviceConfig message.')
    }
    validateCfgInfo(cfgInfo)

    const { fieldNames } = findDescription(cfgInfo.configMsgFieldNumPath, rootDescription)
    let config
    if (deviceConfig.hasId) {
      config = safeGetMsgChain(deviceConfig, fieldNames)
    } else {
      /* prettier-ignore */
      config = safeGetMsgChain(
        objectToProtoMsg({
          descriptor: rootDescriptor,
          payload: deviceConfig
        }),
        fieldNames
      )
    }
    validateConfig(config)

    that._init(cfgInfo, config)
  }

  /**
   * E.g. required to 'refresh' form-lables, if lang changes.
   *
   * @function
   *
   */
  this.rebuildDesc = function () {
    if (!that.cfg) {
      logger.warn('Called rebuldDesc before config was provided. This is not possible. Skipping.')
      return
    }

    that._buildDesc()
    that._sync()
  }

  /**
   * Wraps the current proto-JS `that.cfg` into a proto `DevicePb.DeviceConfig`.
   * Uses current var `configFieldNums` to access/set `DevicePb.DeviceConfig.<device>.<config>`.
   * Uses current var `configIds` as `ID` for `DevicePb.DeviceConfig`.
   *
   * @function
   *
   * @return a full proto JS `DevicePb.DeviceConfig`
   */
  this.getDeviceConfig = function () {
    const deviceConfig = newConfig(configFieldNums, {
      ids: configIds,
      bare: false
    })

    return deviceConfig
  }

  /**
   * Allows to get and set a scalar/selection field value(s) of the proto config.
   * If `value` is not provided, it will be returned,
   * otherwise it will be set.
   * After setting a value, `_sync`s the Vue component state.
   *
   * Hint: use this method to get and set (update) state (data) for the Vue component.
   *
   * @function
   *
   * @param {object} fieldNumbers (required) are a list of proto field numbers, which point to the proto message field
   * @param {string|number|boolean} value (optional) is the value to be set
   *
   * @return {string|number|boolean|null|array} the getted or setted value
   */
  this.fieldValue = function (fieldNumbers, value) {
    let { fieldNames, msgName, desc } = findDescription(fieldNumbers, rootDescription, { allowMissingDesc: true })
    const formFieldName = toLiteral(fieldNames)
    fieldNames = stripOfConfigNamespace(fieldNames)
    if (!fieldNames?.length) {
      logger.warn(`Failed to get/set config field for field-numbers ${fieldNumbers} and field-names ${fieldNames}.`)
      return
    }

    if (value === undefined) {
      const value = safeGetMsgChain(that.cfg, fieldNames)

      // primitive
      if (typeof value !== 'object') {
        return value
      }

      if (/[\w.]*Selection$/.test(msgName)) {
        if (!value) {
          logger.warn(`Found missing value for Selection for field-numbers ${fieldNumbers}.`)
          return null
        } else if (value.getMultiple()) {
          // multiple_invalid_values can be ignored, since default of a repeated is `[]`, which is fine
          return value.getMultipleValuesList()
        } else {
          if (value.hasSingleInvalidValue() && value.getSingleInvalidValue().getKindCase() > 0) {
            return null
          }
          return value.getSingleValue()
        }
      }

      logger.warn(`Failed to get value for field-numbers ${fieldNumbers} (field-names ${fieldNames}).`)

      return undefined
    }

    if (/[\w.]*Selection$/.test(msgName)) {
      const slct = safeGetMsgChain(that.cfg, fieldNames)
      if (!slct) {
        logger.error(
          `Failed to find Selection message for field-numbers ${fieldNumbers}. I.e. current config is somehow invalid. Ignoring.`
        )

        return value
      }
      const idxThreshold = slct.getValuesList().length

      if (slct.getMultiple()) {
        slct.clearMultipleValuesList()
        slct.clearMultipleInvalidValuesList()

        if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val >= 0 && val < idxThreshold) {
              slct.addMultipleValues(val)
            } else {
              logger.warn(
                `Tried to set a Selection at field-numbers ${fieldNumbers} of multiple-value index ${val}, not included in the allowed values range. Skipping.`
              )
            }
          })
        } else if (value == null) {
          // unset: all is cleared already :)
        } else {
          logger.warn(
            `Tried to set a Selection at field-numbers ${fieldNumbers} of multiple-values ${value}, being invalid. Skipping`
          )
        }
      } else {
        if (value !== null && value >= 0 && value < idxThreshold) {
          slct.setSingleValue(value)
          slct.clearSingleInvalidValue()
        } else if (value == null) {
          // unset: setting the default + invalid value, because we cannot do else
          slct.setSingleValue(0)
          if (!slct.hasSingleInvalidValue()) {
            slct.setSingleInvalidValue(new OptAmperixPb.Selection.Value())
          }
          slct.getSingleInvalidValue().setStringValue('')
          slct.getSingleInvalidValue().setDescription('Unselected by UI.')
        } else {
          logger.warn(
            `Tried to set a Selection at field-numbers ${fieldNumbers} of single-value index ${value}, not included in the allowed values range. Skipping.`
          )
        }
      }
    } else {
      const setter = `set${upperFirst(fieldNames[fieldNames.length - 1])}`
      // Note:
      // cfg was returned by reference (no deep clone or something else);
      // i.e. in case of a sub-config, the reference points (also) to the configCache;
      // i.e. any update here, updates the configCache too :);
      const cfg = safeGetMsgChain(that.cfg, fieldNames.slice(0, fieldNames.length - 1))

      // Parse to expected type
      // Note:
      // Serialization will do the job,
      // however, setting the value here,
      // will NOT transform the type,
      // hence, e.g., a `get` of `unit32` would return a string.
      //
      if (typeof value === 'string') {
        switch (protoTypeToJsType(desc.type)) {
          case 'number':
            // Note:
            // `value = parseInt(value)` might be expecter for integers here.
            // However, we cannot force an input to accept integers only,
            // parsing here will NOT update the UI,
            // but, circumvent the validation.
            value = parseFloat(value)
            break
        }
      }
      cfg[setter](value)
    }

    that._sync(fieldNumbers)
    that.updateValidation({ fieldNames, formFieldName }) // need 'bare' fieldNames

    return value
  }

  /**
   * Allows to get the value (state) of a fork (proto oneof).
   *
   * @function
   *
   * @param {object} params
   * @param {array} params.fieldNumbers (required) are a list of proto field numbers, which point to the proto message containing the oneofs
   * @param {string} params.oneofName (required) is the name of the oneof (fork)
   *
   * @return {string} literal of field-numbers of setted fork. If prefixed with `_CLEAR.` the oneof is not selected.
   */
  this.forkValue = function ({ fieldNumbers, oneofName }) {
    const f = getForkValueFieldNumber({ fieldNumbers, oneofName })

    if (f === 0) {
      return '_CLEAR.' + toLiteral(fieldNumbers.concat([oneofName]))
    }
    return toLiteral(fieldNumbers.concat([f]))
  }

  /**
   * Allows to update (rebuild and sync) a oneof-choice (fork) of a device config or device sub-config.
   *
   * @function
   *
   * @param {object} options
   * @param {array} options.fieldNumbers (required) is a list of protobuf field numbers (IDs) pointing the the protobuf oneof field, w.r.t. the 'root' DeviceConfig.
   * @param {string} options.driverId (optional) is the driverId of the device config. Required by the BE, to receive default configs. Will fallback to the config `driverId` set during init.
   *
   * @return {promise}
   */
  this.setFork = async function ({ fieldNumbers, driverId }) {
    const subConfig = await that._getDefault({ fieldNumbers, driverId })
    that.fieldValue(fieldNumbers, subConfig)

    that._buildDesc()
    that._sync() // full sync of cfg is important, otherwise oneof fields might be set not exclusive
  }

  /**
   * Allows to reset (rebuild and sync) a oneof-choice (fork) of a device config or device sub-config.
   *
   * @function
   *
   * @param {object} options
   * @param {array} options.fieldNumbers (required) is a list of protobuf field numbers (IDs) pointing the the protobuf message containing the oneof field, w.r.t. the 'root' DeviceConfig.
   * @param {string} options.oneofName (required) is the protobuf name for the oneof field.
   *
   */
  this.unsetFork = function ({ fieldNumbers, oneofName }) {
    const f = getForkValueFieldNumber({ fieldNumbers, oneofName })
    let { fieldNames } = findDescription(fieldNumbers.concat([f]), rootDescription)
    fieldNames = stripOfConfigNamespace(fieldNames)
    const clearer = `clear${upperFirst(fieldNames.pop())}`
    const cfg = safeGetMsgChain(that.cfg, fieldNames)
    cfg[clearer]()

    that._buildDesc()
    that._sync()
  }

  this.findDesc = function (descName) {
    return that.desc.find((d) => d.name === descName)
  }

  this.findDescIndex = function (descName) {
    return that.desc.findIndex((d) => d.name === descName)
  }

  /**
   * @param {array} fieldNames are a list of proto message field names, w.r.t. the 'bare' device config (NOT the full device config). E.g. `modbusTcpSlaveId` of the `Meter.Config1` 'bare' config. If blank, validation for all fields is applied.
   * @param {string} formFieldName is the `name` of the HTTP form-field element. Needed to update the `isValid` prop.
   *
   */
  this.updateValidation = function ({ fieldNames, formFieldName } = {}) {
    const isValid = validator.validate(fieldNames)
    if (formFieldName) {
      updateDesc(fieldNames, formFieldName)
    }
    if (!fieldNames && !isValid) {
      const { desc } = findDescription(configFieldNums.slice(0, 1), rootDescription)
      iterateScalarFieldsOfDescription({
        description: desc,
        msgName: configMsgName.split('.')[1],
        cb: ({ fieldNames }) => {
          updateDesc(fieldNames, toLiteral(configFieldNames.concat(fieldNames)))
        }
      })
    }

    function updateDesc(fNames, ffName) {
      const i = that.findDescIndex(ffName)
      if (i > -1) {
        const d = vue.desc[i]
        d.isValid = validator.isValid(fNames) // update
        vue.desc.splice(i, 1, d) // sync
      }
    }
  }

  /**
   * Filters the own properties of a `FormFieldDesc`.
   * Passes only own properties being needed as HTML (CoreUI) attributes.
   *
   * @function
   *
   * @param {FormFieldDesc} formFieldDesc
   * @param {object} options
   * @param {array} options.whitelist is an optional list of html attrs (in camelCase) to be allowed. Allows to overwrite the default one.
   *
   * @return {object}
   */
  this.htmlAttrsOf = function (formFieldDesc, { whitelist } = {}) {
    whitelist = whitelist ?? [
      'id',
      'name',
      'lazy',
      'type',
      'label',
      'description',
      'invalidFeedback',
      'placeholder',
      'isValid'
    ]

    const attrs = {}
    whitelist.forEach((e) => {
      attrs[e] = formFieldDesc[e]
    })

    return attrs
  }

  this._init = function (cfgInfo, config) {
    clearConfigCache()
    setConfigMetaData(cfgInfo)
    that.cfg = config
    that._buildDesc()
    that._syncConfigCache(cfgInfo.configMsgFieldNumPath)
    that._sync()
  }

  // syncs `cfg` and `desc` with the Vue-Component state
  this._sync = function (fieldNums = configFieldNums) {
    // full sync of description
    vue.desc.splice(0, vue.desc.length, ...that.desc) // looks weird because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays

    // sync 'bar' config
    let { fieldNames } = findDescription(fieldNums, rootDescription, { allowMissingDesc: true })
    fieldNames = stripOfConfigNamespace(fieldNames)

    if (fieldNames.length === 0) {
      // init config
      vue.cfg = that.cfg.toObject()
      return
    }

    let cfg = vue.cfg
    // sync scalar or message (sub-) field
    fieldNames.forEach((n, i) => {
      if (i < fieldNames.length - 1) {
        cfg = cfg[n]
      } else {
        let val = safeGetMsgChain(that.cfg, fieldNames)
        if (typeof val === 'object' && val.toObject) {
          val = val.toObject()
        }
        cfg[n] = val
      }
    })
  }

  // should be used during init to sync the config cache
  this._syncConfigCache = function (fieldNums) {
    let { fieldNames, desc } = findDescription(fieldNums, rootDescription)
    // iterate 'forks' (oneofs)
    if (!desc.oneofs) {
      return
    }
    const cfg = safeGetMsgChain(that.cfg, stripOfConfigNamespace(fieldNames))
    for (const s in desc.oneofs) {
      const fieldNum = cfg[`get${upperFirst(s)}Case`]()
      if (fieldNum > 0) {
        const newFieldNums = fieldNums.concat([fieldNum])
        fieldNames = findDescription(newFieldNums, rootDescription).fieldNames
        const getter = `get${upperFirst(fieldNames.pop())}`
        configCache[toLiteral(newFieldNums)] = cfg[getter]()
        that._syncConfigCache(newFieldNums)
      }
    }
  }

  // gets the default config from BE or cache
  this._getDefault = async function ({ fieldNumbers, driverId }) {
    driverId = driverId || configIds.driverId
    let config
    if (configCache[toLiteral(fieldNumbers)]) {
      return configCache[toLiteral(fieldNumbers)]
    }

    try {
      config = await vue.$store.dispatch('deviceConfig/getDefaultDeviceConfig', { fieldNumbers, driverId })
      configCache[toLiteral(fieldNumbers)] = config
    } catch (err) {
      logger.warn(
        `Failed to receive default device config from BE for field numbers ${fieldNumbers} and driver ID ${driverId}.`
      )
      logger.warn(`${err.name}: ${err.message}`)
      logger.warn(
        `Will create new empty config from root-descriptor (DeviceConfig descripto) at field numbers ${fieldNumbers}.`
      )

      config = newConfig(fieldNumbers)
    }

    return config
  }

  // takes the current device config `that.cfg`,
  // and builds the 'form description' `that.desc`
  this._buildDesc = function () {
    that.desc = []
    function extract(fieldNums) {
      const { fieldNames, desc } = findDescription(fieldNums, rootDescription)
      const forksDesc = new ForksDesc(fieldNums)

      if (!desc.fields && desc.values) {
        // expected to be an ENUM
        that.desc.push(new SelectionFormFieldDesc(fieldNums))
        return
      } else if (!desc.fields) {
        logger.error(`Found empty description for field-numbers ${fieldNums}.`)
        return
      }

      // transform to array to ensure correct order of fields by field-ID
      const fields = Object.entries(desc.fields)
      fields.sort((a, b) => {
        return a[1].id - b[1].id
      })
      fields.forEach((field) => {
        const fork = forksDesc.getFork(field[0])
        if (fork) {
          // child config(s) inside parent config
          const i = that.desc.findIndex((d) => d.id === fork.id)
          if (i < 0) {
            that.desc.push(fork)
          }
          const el = safeGetMsgChain(that.cfg, stripOfConfigNamespace(fieldNames))
          // check if current cfg has oneof field set
          if (el[`has${upperFirst(field[0])}`]()) {
            extract(fieldNums.concat([field[1].id]))
          }
        } else if (/^Selection$/.test(field[1].type)) {
          that.desc.push(new SelectionFormFieldDesc(fieldNums.concat([field[1].id])))
        } else if (/^[A-Z]\w*/.test(field[1].type)) {
          // duck-type: any "other" Msg/ENUM
          extract(fieldNums.concat([field[1].id]))
        } else {
          // any primitive value
          that.desc.push(new ScalarFormFieldDesc(fieldNums.concat([field[1].id])))
        }
      })
    }

    extract(configFieldNums)
  }

  function clearConfigCache() {
    configCache = {}
  }

  function getForkValueFieldNumber({ fieldNumbers, oneofName }) {
    let { fieldNames } = findDescription(fieldNumbers, rootDescription)
    fieldNames = stripOfConfigNamespace(fieldNames)
    const cfg = safeGetMsgChain(that.cfg, fieldNames)
    const getterCase = `get${upperFirst(oneofName)}Case`
    if (typeof cfg[getterCase] !== 'function') {
      throw new Error(
        `Unset fork failed, since sub-message of config at field ${fieldNames} does not have a oneof of with name ${oneofName}.`
      )
    }

    return cfg[getterCase]()
  }

  /**
   * Creates a new `DevicePb.DeviceConfig` for `DevicePb.DeviceConfig.<device>.<config>` proto JS message
   *
   * @function
   *
   * @param {array} fieldNums are a list of field numbers pointing to a Config of the 'root' `DevicePb.DeviceConfig` message.
   * @param {object} options
   * @param {object} options.ids (optional) are the DeviceConfig.Id identifiers. Will be merged into the new (device) config
   * @param {boolean} options.bare (defaults to true) is a flag whether to return the 'bare' newly inited config or the full DeviceConfig with setted current `that.cfg`.
   *
   * @return {object} a proto JS `DevicePb.DeviceConfig` or `DevicePb.DeviceConfig.<device>.<config>` message
   */
  function newConfig(fieldNums, { ids, bare } = { bare: true }) {
    const { fieldNames } = findDescription(fieldNums, rootDescription)
    const payload = {}
    let p = payload
    fieldNames.forEach((n) => {
      p[n] = {}
      p = p[n]
    })

    if (ids) {
      payload.id = ids
    }

    const deviceConfig = objectToProtoMsg({ descriptor: rootDescriptor, payload })
    if (bare) {
      return safeGetMsgChain(deviceConfig, fieldNames)
    }

    const setter = `set${upperFirst(fieldNames.pop())}`
    safeGetMsgChain(deviceConfig, fieldNames)[setter](that.cfg)
    return deviceConfig
  }

  function setConfigMetaData(cfgInfo) {
    configIds.id = cfgInfo.configId
    configIds.driverId = cfgInfo.driverId
    configIds.configTypeId = cfgInfo.configTypeId
    configMsgName = cfgInfo.configMsgName
    configFieldNums = cfgInfo.configMsgFieldNumPath
    configFieldNames = findDescription(configFieldNums, rootDescription).fieldNames
    configDescriptor = rootDescriptor
    configFieldNames.forEach((n) => {
      configDescriptor = configDescriptor[n]
    })
  }

  function stripOfConfigNamespace(someIds) {
    return someIds.slice(configFieldNums.length)
  }

  function validateCfgInfo(cfgInfo) {
    const { fieldNames, desc, msgName } = findDescription(cfgInfo.configMsgFieldNumPath, rootDescription)
    if (!fieldNames || !desc || msgName !== cfgInfo.configMsgName) {
      throw new TypeError(
        `The provided argument "cfgInfo" for proto config msg "${cfgInfo.configMsgName}" does not fit to the proto message root-description.`
      )
    }

    return true
  }

  function validateConfig(config) {
    if (!config) {
      throw new Error(
        'Failed to extract the bare config during DeviceConfigFormInteractor init call. Potentially the root-description and -descriptor do not fit to the device config-info and config.'
      )
    }

    return true
  }

  /**
   * Main structure for a form field description.
   * Has to be a "plain" custructor to be trackable by vue (v2).
   * Here "plain" means, that all attributes are primitives or plain objects.
   *
   * The set of owd properties will be passed to the HTML form element as attributes.
   *
   *
   * Mainly does translation of label, placeholder, ...
   * Inits default own properties.
   *
   * @constructor
   *
   * @param {array} fieldNums are a list of field numbers pointing to a (deeply nested) Config-field of the 'root' `DevicePb.DeviceConfig` message.
   * @param {array} fieldNames are the field names corresponding to the field numbers.
   */
  function FormFieldDesc(fieldNums, fieldNames) {
    this.id = toLiteral(fieldNums)
    this.key = toLiteral(fieldNames)
    this.name = toLiteral(fieldNames)

    /**
     * A list of protobuf field numbers pointing to the protobuf field w.r.t. the root-description.
     *
     * @member {array}
     */
    this.fieldNums = fieldNums
    this.level = fieldNums.length - configFieldNums.length

    // ***
    // non-HTML props
    //
    this.isScalar = false
    this.isSelection = false
    this.isCheckbox = false
    this.isInput = false
    this.isMultiple = false
    this.options = []

    /**
     * Used/set by vualidate to indicate validation error.
     *
     * @member {boolean|null}
     */
    this.isValid = null

    // ***
    // HTML props
    //
    this.lazy = true
    this.type = ''

    // translations are for each `DevicePb.DeviceConfig.<device>.<config>`
    // however, fields can be deeply nested due to submessages and forks
    // -> project current fieldNums onto 'bare' Config.
    let tPath
    if (fieldNums.length <= configFieldNums.length + 1) {
      tPath = `config.device.form.${fieldNames.join('.')}`
    } else {
      // go one level up to msg description
      /* prettier-ignore */
      let { msgName } = findDescription(
        fieldNums.slice(0, fieldNums.length - 1),
        rootDescription
      )
      msgName = msgName.split('.')
      msgName = msgName[msgName.length - 1]

      // example: 'config.device.form.meter.config1.modbusTcpAddress.<label|description|...>';
      let keys = []
      keys = keys.concat(configFieldNames.slice(0, 1)) // device field name
      keys = keys.concat([lowerFirst(msgName)]) // config field name
      keys = keys.concat([fieldNames[fieldNames.length - 1]]) // scalar field name
      tPath = `config.device.form.${keys.join('.')}`
    }
    const labels = ['label', 'description', 'invalidFeedback', 'placeholder']
    labels.forEach((l) => {
      let label
      if (i18n.te(`${tPath}.${l}`)) {
        label = i18n.t(`${tPath}.${l}`)
      } else if (l === 'label') {
        label = startCase(fieldNames[fieldNames.length - 1])
      } else {
        label = null
      }

      this[l] = label
    })
  }

  /**
   * Data structure for a scalar form field description.
   *
   * @constructor
   *
   * @param {array} fieldNums are a list of field numbers pointing to a (deeply nested) Config-field (with primitive value!) of the 'root' `DevicePb.DeviceConfig` message.
   */
  function ScalarFormFieldDesc(fieldNums) {
    const { fieldNames, desc } = findDescription(fieldNums, rootDescription)

    FormFieldDesc.call(this, fieldNums, fieldNames)
    this.isScalar = true
    this.isCheckbox = false
    this.isInput = true

    if (desc.type === 'string') {
      this.type = 'text'
    } else if (/^(uint|fixed).*/.test(desc.type)) {
      this.type = 'number'
      this.min = 0
      this.step = 1
    } else if (/^(int|sint|sfixed).*/.test(desc.type)) {
      this.type = 'number'
      this.step = 1
    } else if (/^(float|double)/.test(desc.type)) {
      this.type = 'number'
      this.step = 'any'
    } else if (desc.type === 'bool') {
      this.type = 'checkbox'
      this.isInput = false
      this.isCheckbox = true
      this.placeholder = null
      this.horizontal = null
    }
  }

  /**
   * Data structure for a scalar form field description.
   *
   * @constructor
   *
   * @param {array} fieldNums are a list of field numbers pointing to a (deeply nested) Config-field (with `amperix.Selection` OR Enum msg!) of the 'root' `DevicePb.DeviceConfig` message.
   */
  function SelectionFormFieldDesc(fieldNums) {
    const { fieldNames, desc } = findDescription(fieldNums, rootDescription, { allowMissingDesc: true })

    FormFieldDesc.call(this, fieldNums, fieldNames)
    this.isSelection = true

    const slct = safeGetMsgChain(that.cfg, stripOfConfigNamespace(fieldNames))
    if (isProto(slct)) {
      this.isMultiple = slct.getMultiple()
      slct.getValuesList().forEach((val, i) => {
        let label = ''
        if (val.hasStringValue()) {
          label = val.getDescription() || val.getStringValue()
        } else if (val.hasUint32Value()) {
          label = val.getDescription() || val.getUint32Value().toString()
        }
        // fieldNames and val specific translations might be added here
        // note: numbers are converted so string: '0' != falsy :)
        label = label || i18n.t('config.device.form.misc.noSelectionValue')

        this.options.push({
          value: i.toString(),
          [this.isMultiple ? 'text' : 'label']: label
        })
      })
    } else if (desc && desc.values) {
      // ENUM
      this.isMultiple = false
      for (const e in desc.values) {
        this.options.push({
          value: desc.values[e].toString(),
          label: e // translation might be added in the future
        })
      }
    }

    // nothing to select -> replace description
    if (!this.options.length) {
      this.description = i18n.t('config.device.form.misc.noSelectionOptions')
    }

    // invalid value present. Extend description:
    if (isProto(slct) && !slct.getMultiple() && slct.hasSingleInvalidValue()) {
      const invalidVal = slct.getSingleInvalidValue()
      let invalidValTxt
      if (invalidVal.getDescription()) {
        invalidValTxt = invalidVal.getDescription()
      } else if (invalidVal.hasStringValue()) {
        invalidValTxt = invalidVal.getStringValue()
      } else if (invalidVal.hasUint32Value()) {
        invalidValTxt = invalidVal.getUint32Value().toString()
      } else if (invalidVal.hasEnumNumber()) {
        invalidValTxt = `ENUM ${invalidVal.getEnumNumber()}`
      }

      if (invalidValTxt) {
        this.description =
          (this.description ?? '') +
          ' ' +
          i18n.t('config.device.form.misc.invalidSelectionValue', { value: invalidValTxt })
      }
    }
    if (isProto(slct) && slct.getMultiple() && slct.getMultipleInvalidValuesList()) {
      const invalidValTxts = []
      slct.getMultipleInvalidValuesList().forEach((invalidVal) => {
        if (invalidVal.hasStringValue() && invalidVal.getStringValue()) {
          invalidValTxts.push(invalidVal.getStringValue())
        } else if (invalidVal.hasUint32Value()) {
          invalidValTxts.push(invalidVal.getUint32Value().toString())
        }
      })

      if (invalidValTxts.length) {
        this.description =
          (this.description ?? '') +
          ' ' +
          i18n.t('config.device.form.misc.invalidSelectionValues', { values: invalidValTxts.join(', ') })
      }
    }
  }

  /**
   * Data structure for fork(s) inside a `DevicePb.DeviceConfig.<device>.<config>` config (or sub-config).
   *
   * A fork is defined as:
   * A set of child-config(s) inside of the parent config.
   * One of the child-config(s) is optionally selecable by the user.
   * Each child-config is a fully valid `DevicePb.DeviceConfig.<device>.<config>` itself.
   * In protobuf a fork is represented by a `oneof` collection.
   *
   * @constructor
   *
   * @param {array} fieldNums are a list of field numbers pointing to a (deeply nested) Config with oneof(s) of the 'root' `DevicePb.DeviceConfig` message.
   */
  function ForksDesc(fieldNums) {
    const { fieldNames, desc } = findDescription(fieldNums, rootDescription)
    this.forks = []

    // iterate 'forks' (= possible steps)
    for (const s in desc.oneofs) {
      // example: 'config.device.form.inverter.config4.bms.<lable|...>';
      // todo: translation does not work for 'forks inside a fork';
      const tPath = `config.device.form.${fieldNames.join('.')}.${s}.label`
      let label
      if (i18n.te(tPath)) {
        label = i18n.t(tPath)
      } else {
        label = `Select ${startCase(s)}`
      }
      const options = [
        {
          value: '_CLEAR.' + toLiteral(fieldNums.concat([s])),
          label
        }
      ]

      // iterate 'fork-fields' (= possible options for each step)
      desc.oneofs[s].oneof.forEach((o) => {
        const fieldNum = desc.fields[o].id
        const value = toLiteral(fieldNums.concat([fieldNum]))
        let label
        // example: 'config.device.form.inverter.config4.bmsTesvoltBms.<lable|...>';
        // todo: translation does not work for 'forks inside a fork';
        const tPath = `config.device.form.${fieldNames.join('.')}.${o}.label`
        if (i18n.te(tPath)) {
          label = i18n.t(tPath)
        } else {
          label = startCase(o)
        }

        options.push({
          value,
          label
        })
      })

      this.forks.push(
        new ForkDesc({
          step: s,
          fieldNums,
          fieldNames,
          oneofNames: desc.oneofs[s].oneof,
          options
        })
      )
    }

    this.getFork = (name) => {
      let fork
      this.forks.forEach((f) => {
        // oneofNames (field names) have to be unique inside each proto message
        if (f.oneofNames.includes(name)) {
          fork = f
        }
      })

      return fork
    }

    function ForkDesc({ step, fieldNums, fieldNames, oneofNames, options }) {
      this.isFork = true
      this.id = toLiteral(fieldNames.concat([step]))
      this.key = this.id
      this.name = this.id
      this.fieldNumbers = fieldNums
      this.fieldNames = fieldNames
      this.oneofName = step
      this.oneofNames = oneofNames
      // to match the level of the 'neighbor' scalar fields we add +1
      this.level = fieldNames.length - configFieldNames.length + 1
      this.options = options
    }
  }
}
