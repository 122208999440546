<template>
  <EsLayoutForm @cancel="$emit('cancel')" @submit="onSubmit">
    <p style="white-space: pre-line">
      {{ $t(`${tBasePath}.description`) }}
    </p>
  </EsLayoutForm>
</template>

<script>
import { formProps as props } from './form-props'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'

export default {
  name: 'EsInvalidCheckNodeForm',
  components: {
    EsLayoutForm
  },
  props,
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.invalidCheck'
  },
  methods: {
    onSubmit() {
      this.$emit('confirmed', { idx: this.idx, decisionNodeType: 'invalidCheck', params: {} })
    }
  }
}
</script>
