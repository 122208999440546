/** @module store/apx-interfaces */

import * as apiSherlock from '@/api/sherlock/apx-informations'

// STATE
const state = () => {
  return {
    serialno: null,
    version: null
  }
}

// GETTERS
const getters = {
  serialno: (state) => state.serialno,
  version: (state) => state.version
}

// MUTATIONS
const mutations = {
  SET_SERIALNO(state, { serialno }) {
    state.serialno = serialno
  },
  SET_VERSION(state, { version }) {
    state.version = version
  }
}

// ACTIONS

/*
 * @param {object} store
 * @param {object} params
 * @param {string} params.newVersion

 */
function fetchSerialNo({ commit }) {
  const doCommit = (msg) => {
    commit('SET_SERIALNO', { serialno: msg.getSerialNo() })
  }

  return apiSherlock.fetchSerialNo().then(doCommit)
}

function fetchVersion({ commit }) {
  const doCommit = (msg) => {
    commit('SET_VERSION', { version: msg.getVersion() })
  }

  return apiSherlock.fetchVersion().then(doCommit)
}

const actions = {
  fetchSerialNo,
  fetchVersion
}
//
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
