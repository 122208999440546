/**
 * Stores the state of device process values provided by the EnergyManager.
 *
 * @module store/device-values
 */

import * as apiEms from '@/api/amperix/ems'
import logger from '@/logger'
import { startStopStream } from '@/api/misc'
import { DeviceProcessValue } from '@/store/modules/_device-structs'

// private
const streamState = {
  processValues: {}
}

/**
 * The device-values store allows to store:
 *
 * - `configId` for which the process valus are stored.
 * - `processValues` which are a list of [DeviceProcessValue]{@link module:store/device.DeviceProcessValue} instances.
 *
 * @function
 *
 * @return {object}
 */
const state = () => {
  return {
    configId: null,
    processValues: []
  }
}

/**
 * Getters for device-values.
 * Allow to compute properties base on the current state.
 *
 *
 * @property {function} filterDeviceProcessValues allows to filter the list of [DeviceProcessValue(s)]{@link module:store/device.DeviceProcessValue} by reference. The logical device name is ignored. Looks for any match of the filter-string (1st argument). Available options are `{ onlyControllable: true }` (2nd argument). Than, the filter applies to controllable process values only.
 *
 */
const getters = {
  configId: (state) => state.configId,
  deviceProcessValues: (state) => state.processValues,
  getDeviceProcessValue: (state) => {
    return (reference) => {
      return state.processValues.find((v) => {
        return v.reference === reference
      })
    }
  },
  filterDeviceProcessValues: (state) => {
    return (filterStr, { onlyControllable } = {}) => {
      let values
      if (onlyControllable) {
        values = state.processValues.filter((v) => {
          // Filters out specific ids for control
          const id = v.reference.replace(/.*\//, '')
          if (['invZINV0.eStop', 'invZINV0.strStop', 'invZINV0.grdContactor'].includes(id)) return false

          return v.isControllable
        })
      } else {
        values = state.processValues
      }
      if (!filterStr) {
        return values
      }

      const filter = new RegExp(filterStr, 'i')
      return values.filter((v) => {
        let id = v.reference
        if (typeof id !== 'string') {
          return false
        }

        id = id.replace(/.*\//, '') // cut LD-name off

        return filter.test(id)
      })
    }
  }
}

const mutations = {
  SET_CONFIG_ID(state, configId) {
    state.configId = configId
  },
  ADD_PROCESS_VALUE(state, processValue) {
    if (!processValue) {
      throw new TypeError('Cannot update a device process value which is blank.')
    }

    const i = state.processValues.findIndex((v) => {
      return v.reference === processValue.reference
    })

    if (i > -1) {
      // see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
      state.processValues.splice(i, 1, processValue)
    } else {
      state.processValues.push(processValue)
    }
  },
  CLEAR_PROCESS_VALUES(state) {
    state.processValues = []
  }
}

/**
 * gRPC to fetch EnergyManagerDeviceValues
 *
 * @function
 *
 * @param {string} configId is the config ID for which the process values are fetched.
 *
 * @return {promise}
 */
async function fetchEnergyManagerDeviceValues({ commit }, configId) {
  const doCommit = (msg) => {
    commit('SET_CONFIG_ID', configId)
    msg.getValuesList().forEach((v) => {
      // add also updates
      commit('ADD_PROCESS_VALUE', new DeviceProcessValue(v))
    })

    return msg
  }

  return apiEms.getEnergyManagerDeviceValues({ configId }).then(doCommit)
}

/**
 * Start/stop stream for List of EnergyManagerDeviceValue.
 * Received list of EnergyManagerDeviceValue is created/updated in the store.
 *
 * @function
 *
 * @param {object} params
 * @param {string} configId is the config ID for which the process values are streamed.
 * @param {function} onMsg is a callback on message receive after commit(s)
 * @param {boolean} stop is a flag whether to stop the stream or not (defaults to false).
 *
 */
async function streamEnergyManagerDeviceValues({ commit }, { configId, onMsg, stop } = { stop: false }) {
  const onMessage = (msg) => {
    msg.getValuesList().forEach((v) => {
      // add also updates
      commit('ADD_PROCESS_VALUE', new DeviceProcessValue(v))
    })
    if (onMsg) {
      onMsg(msg)
    }
  }

  let call = streamState.processValues[configId]
  call = startStopStream({
    call,
    stop,
    starter: () => apiEms.streamEnergyManagerDeviceValues({ configId }, { onMessage }),
    name: 'streamEnergyManagerDeviceValues'
  })
  streamState.processValues[configId] = call

  return call
}

/**
 * gRPC to get an EnergyManagerDeviceValue
 *
 * @function
 *
 * @param {string} reference is the IEC reference ID for which the process value is fetched.
 *
 * @return {promise}
 */
async function getEnergyManagerDeviceValue({ commit }, reference) {
  const doCommit = (msg) => {
    // Note: do NOT CLEAR_PROCESS_VALUES
    // Action can be used to update particular values
    if (!msg.hasValue()) {
      logger.warn('Received GetEnergyManagerDeviceValueResponse without value.')

      return msg
    }
    // add also updates
    commit('ADD_PROCESS_VALUE', new DeviceProcessValue(msg.getValue()))

    return msg
  }

  return apiEms.getEnergyManagerDeviceValue({ reference }).then(doCommit)
}

/**
 * Action to perform a EnergyManagerService operate call.
 * Has no side-effect.
 *
 * @function
 *
 * @param {object} params see [API operate]{@link module:src/api/amperix/ems.operate}
 *
 * @return {promise}
 */
async function operate({ commit }, { reference, value }) {
  return apiEms.operate({ reference, value })
}

const actions = {
  fetchEnergyManagerDeviceValues,
  streamEnergyManagerDeviceValues,
  getEnergyManagerDeviceValue,
  operate
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
