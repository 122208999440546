<template>
  <div class="py-2">
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.socLimit.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ socLimit }} </em>
        <em>%</em>
      </small>
    </p>
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.probingDurationOnInMin.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ probingDurationOnInMin }} </em>
        <em>min</em>
      </small>
    </p>
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t(`${tBasePath}.probingDurationOffInMin.label`) }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ probingDurationOffInMin }} </em>
        <em>min</em>
      </small>
    </p>
  </div>
</template>

<script>
import { probingDurationOnInMin, probingDurationOffInMin } from '../energy-service-forms/form-methods'

export default {
  name: 'EsPreviewOffGridBatteryProtection',
  props: {
    socLimit: {
      required: true,
      type: Number
    },
    probingDurationOnInSec: {
      required: true,
      type: Number
    },
    probingDurationOffInSec: {
      required: true,
      type: Number
    }
  },
  computed: {
    probingDurationOnInMin,
    probingDurationOffInMin
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.offGridBatteryProtection'
  }
}
</script>
