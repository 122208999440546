<template>
  <EsLayoutForm
    :show-failure-alert.sync="showFailureAlert"
    @cancel="$emit('cancel')"
    @reset="onReset"
    @submit="onSubmit"
  >
    <CRow>
      <!-- prettier-ignore -->
      <EvalTreeModeToggler
        :expert-mode.sync="expertMode"
        :show-extended-operator-mode-toggler="false"
        @update:expert-mode="onSwitchExpertMode"
      />
      <CCol v-if="expertMode">
        <EvalTree />
      </CCol>
      <CCol v-else col="12" :md="{ size: 8, offset: 2 }">
        <div class="col-12 mt-3 d-flex justify-content-center">
          <!-- prettier-ignore -->
          <CSwitch
            color="primary"
            size="sm"
            :checked="isOn"
            v-bind="switchLabelIcon"
            @update:checked="onSwitchOn"
          />
          <span v-if="isOn" class="ml-2">
            {{ $t('main.on') }}
          </span>
          <span v-else class="ml-2">
            {{ $t('main.off') }}
          </span>
        </div>
      </CCol>
    </CRow>
  </EsLayoutForm>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { EvalExpression as EvalExpressionPb } from '@/../lib/proto_js/ext/ems/eval_pb'

import EsLayoutForm from './es-layout-form'
import EvalTreeModeToggler from '@/components/ems/evalexp/eval-tree-mode-toggler'
import EvalTree from '@/components/ems/evalexp/eval-tree'

import { formProps as props } from './form-props'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { recursivelyValidateEvalTreeVue } from '@/view-helper/form-helpers'
// import { CACHE_IDS } from '@/store/modules/cache'

export default {
  name: 'EsEvalExpressionForm',
  components: {
    EsLayoutForm,
    EvalTreeModeToggler,
    EvalTree
  },
  props,
  data() {
    // data of eval-expression are stored in the store
    return {
      expertMode: false,
      isOn: true,
      showFailureAlert: false,
      initialExpertMode: false
    }
  },
  computed: {
    ...mapState('emsEvalExpression', ['evalExpression']),
    ...mapGetters('emsEvalExpression', ['parsedEvalTree', 'isValidTree', 'sizeEvalTree'])
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.evalExpression'
    this.switchLabelIcon = {
      labelOn: '\u2713',
      labelOff: '\u2715'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.showFailureAlert = false
      this.$store.commit('apiLoadingStatus/STARTED')

      await this.$store
        .dispatch('emsSensors/sensorInit', {})
        .then(() => {
          this.$log.debug('Succesfully fetched sensor names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })

      await this.$store
        .dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: false
        })
        .then(() => {
          this.$log.debug('Succesfully fetched timeseries names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })

      this.initSimpleMode()
      this.initialExpertMode = this.expertMode
    },
    initSimpleMode() {
      // missing tree
      if (this.sizeEvalTree === 0) {
        this.$store.commit('emsEvalExpression/SET', new EvalExpressionPb([1]).toObject())
        return
      }
      if (this.sizeEvalTree > 1) {
        this.expertMode = true
        return
      }
      // "empty" tree: init to simple mode with switch 'ON'
      if (this.parsedEvalTree.isNode === null) {
        this.$store.commit('emsEvalExpression/UPDATE_VALUE', { nodeIdx: 0, value: 1 })
        return
      }
      if (this.parsedEvalTree.operator === 'value' && [0, 1].includes(this.parsedEvalTree.value)) {
        this.expertMode = false
        this.isOn = !!this.parsedEvalTree.value
        return
      }

      this.expertMode = true
    },
    onSwitchOn(isOn) {
      // clear non-trivial eval-tree
      if (this.sizeEvalTree > 1) {
        this.$store.commit('emsEvalExpression/CLEAR')
      }

      this.$store.commit('emsEvalExpression/UPDATE_VALUE', { nodeIdx: 0, value: isOn ? 1 : 0 })
      this.isOn = isOn
    },
    onSwitchExpertMode(isExpertMode) {
      if (!isExpertMode) {
        this.onSwitchOn(this.isOn)
      }
      this.expertMode = isExpertMode
    },
    onSubmit() {
      this.showFailureAlert = false

      if (!this.isValidTree) {
        recursivelyValidateEvalTreeVue(this)
        this.showFailureAlert = true
        return
      }

      this.$emit('submit', {
        esParams: cloneDeep(this.evalExpression)
      })
    },
    onReset() {
      this.expertMode = this.initialExpertMode
      this.$emit('reset')
      this.initSimpleMode()
    }
  }
}
</script>
