<template>
  <div>
    <CCardText class="small m-0 p-0">
      <p class="text-muted text-break text-right">
        {{ $t(`${tGlobalRlmPath}.timestampInfo`) }}:
        <span class="pl-1 pl-lg-2 text-monospace text-info">
          {{ currentSettings.currentPeakTimestamp }}
        </span>
      </p>
    </CCardText>
    <CCardText class="d-flex justify-content-between small">
      <span>
        <p class="mb-0 mr-1 text-muted text-break">
          {{ $t(`${tBasePathCurrentRlm}.peakWatts.label`) + $t(`${tBasePathCurrentRlm}.peakWatts.unit`) }}
        </p>
        <p class="text-monospace">{{ currentSettings.currentPeakWatts }}</p>
      </span>
      <span>
        <p class="m-0 p0 text-muted text-break">
          {{ $t(`${tBasePathCurrentRlm}.intervalMinutes.label`) + $t(`${tBasePathCurrentRlm}.intervalMinutes.unit`) }}
        </p>
        <p class="text-monospace">{{ currentSettings.currentIntervalMinutes }}</p>
      </span>
    </CCardText>
  </div>
</template>

<script>
export default {
  name: 'CurrentRlmDisplay',
  props: {
    currentSettings: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  created() {
    this.tBasePathCurrentRlm = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'
  }
}
</script>
