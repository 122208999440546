<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm @submit.prevent>
      <CRow class="mb-3">
        <CCol>
          <CInputCheckbox
            class="float-right"
            inline
            :label="$t(`${tBasePath}.socThreshold.eval`)"
            :checked.sync="useEval"
          />
        </CCol>
      </CRow>
      <CRow v-if="useEval" class="mt-2">
        <CCol class="col-sm-3 font-weight-bold text-primary">
          {{ $t(`${tBasePath}.socThreshold.label`) }}
        </CCol>
        <CCol class="col-sm-9">
          <EvalTree />
        </CCol>
      </CRow>
      <CInput
        v-else
        id="soc-threshold"
        type="range"
        min="0"
        max="100"
        :lazy="false"
        add-input-classes="soc-slider-range"
        add-label-classes="custom-form-label"
        custom
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.socThreshold.label`)"
        :description="$t(`${tBasePath}.socThreshold.description`)"
        :value.sync="socThresholdPercent"
      >
        <template #append>
          <output class="soc-slider-value" for="soc-threshold"> {{ socThresholdPercent }} % </output>
        </template>
      </CInput>
      <CInput
        id="soc-deadzone"
        type="range"
        min="0"
        :max="socThresholdPercent"
        :lazy="false"
        add-input-classes="soc-slider-range"
        add-label-classes="custom-form-label"
        custom
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.socDeadzoneBelow.label`)"
        :description="$t(`${tBasePath}.socDeadzoneBelow.description`)"
        :value.sync="socDeadzoneBelow"
      >
        <template #append>
          <output class="soc-slider-value" for="soc-deadzone"> {{ socDeadzoneBelow }} % </output>
        </template>
      </CInput>
      <!-- prettier-ignore -->
      <CSwitch
        v-if="allowLegacy"
        color="primary"
        size="sm"
        :checked="useLegacy"
        @update:checked="switchType"
      />
      <span v-if="allowLegacy" class="ml-3 align-top d-inline-block">
        {{ useLegacy ? $t(`${tBasePath}.legacy.legacy`) : $t(`${tBasePath}.legacy.new`) }}
      </span>
      <template v-if="useLegacy">
        <CSelect
          add-label-classes="custom-form-label"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
          :label="$t(`${tBasePath}.physicalDevice.label`)"
          :description="$t(`${tBasePath}.physicalDevice.description`)"
          :is-valid="$v.physicalDeviceId.$dirty && $v.physicalDeviceId.$error ? false : null"
          :invalid-feedback="$t(`${tBasePath}.physicalDevice.invalidFeedback`)"
          :options="physicalDeviceOpts"
          :value.sync="$v.physicalDeviceId.$model"
        />
      </template>
      <template v-else>
        <CSelect
          add-label-classes="custom-form-label"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
          :label="$t(`${tBasePath}.actuatorGroup.label`)"
          :description="$t(`${tBasePath}.actuatorGroup.description`)"
          :is-valid="!$v.actuatorGroup.$invalid"
          :invalid-feedback="$t(`${tBasePath}.actuatorGroup.invalid`)"
          :options="agOptions"
          :value.sync="$v.actuatorGroup.$model"
        />
        <CButton
          :color="entry.name ? 'primary' : 'danger'"
          size="sm"
          variant="outline"
          @click="expertEditMode = !expertEditMode"
        >
          <i>
            {{ expertEditMode ? $t(`${tBasePath}.expert.hide`) : $t(`${tBasePath}.expert.show`) }}
          </i>
        </CButton>
        <CRow v-if="expertEditMode">
          <CCol class="pt-1 col-12 text-muted" style="font-size: smaller">
            {{ $t(`${tBasePath}.expert.description`) }}
          </CCol>
          <CCol class="pt-1 col-12">
            <CSelect
              add-label-classes="custom-form-label"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
              :label="$t(`${tBasePath}.deadZone.label`)"
              :description="$t(`${tBasePath}.deadZone.description`)"
              :options="deadzoneOptions"
              :value.sync="deadzoneBehaviour"
            />
          </CCol>
          <CCol class="col-sm-3 font-weight-bold text-primary">
            {{ $t(`${tBasePath}.fixate.label`) }}
          </CCol>

          <CInputRadioGroup
            class="col-sm-9"
            :options="[
              {
                value: 'adapt',
                label: $t(`${tBasePath}.fixate.adapt`)
              },
              {
                value: 'keep',
                label: $t(`${tBasePath}.fixate.keep`)
              }
            ]"
            :checked="keepSetpoint ? 'keep' : 'adapt'"
            @update:checked="(opt) => (keepSetpoint = opt === 'keep')"
          />
        </CRow>
      </template>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'
import { formProps as props } from './form-props'
import { literalToPhysicalDevice, physicalDeviceToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { socNodeFormValidators } from '@/validations/ems-es-decision-tree-validators'
import { generatePhysicalDeviceSelectOpts } from '@/view-helper/ems/ems-topology-helper'
import { DecisionTree as DecisionTreePb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import EvalTree from '@/components/ems/evalexp/eval-tree'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'

const defaultParams = {
  socThresholdPercent: 50,
  socDeadzoneBelow: 3,
  legacy: {}
}

export default {
  name: 'EsSocNodeForm',
  components: {
    EsLayoutForm,
    EvalTree
  },
  props,
  data() {
    return {
      socThresholdPercent: defaultParams.socThresholdPercent,
      socDeadzoneBelow: defaultParams.socDeadzoneBelow,
      legacy: defaultParams.legacy,
      physicalDeviceId: this.physicalDeviceToDeviceId(defaultParams.legacy),
      showFailureAlert: false,
      useLegacy: true,
      actuatorGroup: '',
      keepSetpoint: false,
      deadzoneBehaviour: DecisionTreePb.Node.LimitCondition.DeadzoneBehaviour.NHY,
      actuatorGroupsWithBattery: [],
      useEval: false,
      expertEditMode: false,
      allowLegacy: false
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry', 'getActuatorGroup']),
    ...mapGetters('emsTopologyConfig', ['getPhysicalDevicesWithDoubleQuantity']),
    ...mapState('emsEnergyServicesConfig', ['actuatorGroupIds']),
    ...mapGetters('emsEvalExpression', ['isValidTree']),
    physicalDeviceOpts() {
      return generatePhysicalDeviceSelectOpts(this.getPhysicalDevicesWithDoubleQuantity('stateOfChargePercent'), {
        noDevice: false
      })
    },
    deadzoneOptions() {
      const opts = []

      Object.entries(DecisionTreePb.Node.LimitCondition.DeadzoneBehaviour).forEach(([key, value]) => {
        opts.push({
          value: value,
          label: key
        })
      })
      return opts
    },
    agOptions() {
      const opts = [
        {
          disabled: !!this.actuatorGroup,
          value: '',
          label: this.$t(`${this.tBasePath}.actuatorGroup.placeholder`)
        }
      ]
      this.actuatorGroupsWithBattery.forEach((e) => {
        opts.push({
          value: e,
          label: e
        })
      })

      return opts
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.socGreaterThan'
    this.entry = this.getDecisionTreeEntry(this.idx)
  },
  mounted() {
    this.init()
  },
  validations() {
    return socNodeFormValidators
  },
  methods: {
    async init({ reset = false } = {}) {
      const updateTS = async () => {
        this.$store.commit('apiLoadingStatus/STARTED')

        this.$store
          .dispatch('emsSensors/sensorInit', {})
          .then(() => {
            this.$log.debug('Succesfully fetched sensor names.')
          })
          .catch((err) => {
            this.$log.error(err)
            this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
          })

        this.$store
          .dispatch('emsTimeseries/timeseriesesInit', {
            withTimestampValues: false
          })
          .then(() => {
            this.$log.debug('Succesfully fetched timeseries names.')
          })
          .catch((err) => {
            this.$log.error(err)
            this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
          })
          .finally(() => {
            this.$store.commit('apiLoadingStatus/STOPPED')
          })
      }

      const loadStore = async () => {
        let hasCache = false
        if (!reset) {
          hasCache = await this.$store.dispatch('cache/replayStoreState', {
            path: 'emsEvalExpression',
            id: this.$route.query['ee-cache'],
            vuexStore: this.$store
          })
        }
        if (!hasCache) {
          await this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromDecisionTree', {
            decisionTreeIdx: this.idx
          })
        }
      }

      await Promise.all([updateTS(), loadStore()])

      this.actuatorGroupIds.forEach((id) => {
        const group = this.getActuatorGroup(id)
        if (group) {
          for (const setpoint of group.setpointSourcesList) {
            const iec = setpoint.iec
            if (iec && iec.iecNodePrefix === 'bat') {
              this.actuatorGroupsWithBattery.push(id)
              break
            }
          }
        }
      })

      this.showFailureAlert = false
      if (this.entry) {
        this.useEval = !!this.entry.params.decisionNodeParams.socLimitExpression
        this.socThresholdPercent =
          this.entry.params.decisionNodeParams.socThresholdPercent || defaultParams.socThresholdPercent
        this.socDeadzoneBelow = this.entry.params.decisionNodeParams.socDeadzoneBelow || defaultParams.socDeadzoneBelow
        if (this.entry.params.decisionNodeParams.legacy) {
          this.legacy = Object.assign({}, this.entry.params.decisionNodeParams.legacy)
          this.useLegacy = true
          this.allowLegacy = true
        } else if (!this.entry.params.decisionNodeParams.socDeadzoneBehaviour) {
          this.actuatorGroup = ''
          this.keepSetpoint = false
          this.deadzoneBehaviour = DecisionTreePb.Node.LimitCondition.DeadzoneBehaviour.NHY
          this.useLegacy = false
        } else {
          this.actuatorGroup = this.entry.params.decisionNodeParams.socDeadzoneBehaviour.actuatorGroupName
          this.keepSetpoint = !!this.entry.params.decisionNodeParams.socDeadzoneBehaviour.keepSetpoint
          this.deadzoneBehaviour = this.entry.params.decisionNodeParams.socDeadzoneBehaviour.deadzoneBehaviour
          this.useLegacy = false
        }
      } else {
        this.socThresholdPercent = defaultParams.socThresholdPercent
        this.socDeadzoneBelow = defaultParams.socDeadzoneBelow
        this.actuatorGroup = ''
        this.keepSetpoint = false
        this.deadzoneBehaviour = DecisionTreePb.Node.LimitCondition.DeadzoneBehaviour.NHY
        this.useLegacy = false
        this.useEval = false
      }
      this.physicalDeviceId = this.physicalDeviceToDeviceId(this.legacy)
    },
    physicalDeviceToDeviceId(pd) {
      if (isEmpty(pd)) {
        return null
      }
      return physicalDeviceToLiteral(pd)
    },
    switchType(isLegacy) {
      this.useLegacy = isLegacy
      if (isLegacy) {
        this.legacy = defaultParams.legacy
        this.$v.physicalDeviceId.$model = this.physicalDeviceOpts[0].value
      } else {
        this.actuatorGroup = ''
        this.keepSetpoint = false
        this.deadzoneBehaviour = DecisionTreePb.Node.LimitCondition.DeadzoneBehaviour.NHY
      }
      this.$v.$touch()
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const command = {
        idx: this.idx,
        decisionNodeType: 'socGreaterThan',
        params: {
          socThresholdPercent: parseFloat(this.socThresholdPercent),
          socDeadzoneBelow: parseFloat(this.socDeadzoneBelow)
        }
      }
      if (this.useLegacy) {
        command.params.legacy = literalToPhysicalDevice(this.physicalDeviceId)
      } else {
        const socDeadzoneBehaviour = {
          actuatorGroupName: this.actuatorGroup,
          zeroPoint: 0,
          deadzoneBehaviour: this.deadzoneBehaviour
        }

        if (this.keepSetpoint) {
          socDeadzoneBehaviour.keepSetpoint = {}
        } else {
          socDeadzoneBehaviour.fixateSetpoint = 0
        }

        command.params.socDeadzoneBehaviour = socDeadzoneBehaviour
      }

      if (this.useEval) {
        if (!this.isValidTree) {
          this.showFailureAlert = true
          return
        }
        command.params.socThresholdPercent = undefined
        command.params.socLimitExpression = true
      } else {
        command.params.socLimitExpression = false
      }
      this.$emit('confirmed', command)
    }
  }
}
</script>
