<template>
  <CCardText>
    <h5 class="mb-4 mt-2"> In order to configure your energy services following steps are required: </h5>
    <div>
      <p class="font-weight-bold"> Define energy service strategies </p>
      <ol>
        <li>
          Definition and configuration of actuator groups. Each actuator group is responsible to to perform one energy
          service. For example, a battery (group with one actuator), which should optimize self-consumption, or a
          PV-plant, for which curtailment has to be applied.
        </li>
        <li>
          Configuration of the energy service of each actuator group, e.g. self-consumption performed by the battery.
        </li>
        <li>
          The collection of pairs of actuator group and energy service is called energy service strategy. You can define
          multiple strategies.
        </li>
      </ol>
    </div>
    <div>
      <p class="font-weight-bold"> Define activation rules for each strategy </p>
      <ol>
        <li> Define the default strategy. </li>
        <li> Configuration of case-dependent strategy activation (multi-use). </li>
      </ol>
    </div>
    <div>
      <p class="font-weight-bold"> Check and submit your energy service configurations </p>
      <ol>
        <li> All done configurations can be checked on a final overview page. </li>
        <li> Submission of your configuration to the energy manager. </li>
      </ol>
    </div>
  </CCardText>
</template>

<script>
export default {
  name: 'EnergyServiceConfigGuideEn'
}
</script>
