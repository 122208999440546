/** @module */

import { camelCase, isNil, upperFirst } from 'lodash'
import { Empty as EmptyPb } from 'google-protobuf/google/protobuf/empty_pb'

import { RpcService } from '@/../lib/proto_js/ext/ems/controller/controller_pb_service'
import { Request } from '@/../lib/proto_js/ext/ems/controller/controller_pb'
import logger from '@/logger'
import { createUnaryCall } from '@/grpc'
import { isProto } from '@/grpc/protobuf/misc'
import { evalEmsControllerError } from '@/api/error-handling'

export function protoRpcMethodTo(type, method) {
  switch (type) {
    case 'setter':
      return `set${upperFirst(camelCase(method))}`
    case 'getter':
      return `get${upperFirst(camelCase(method))}`
    default:
      throw new TypeError('Invalid argument for type. Allowed are: "setter" or "getter".')
  }
}

/**
 * Performs a EMS controller proto-RPC gRPC call.
 *
 * @function
 *
 * @param {string} method (required) is the proto-RPC method to be requested. `method` is set as one-of field in controller.Request.
 * @param {object|undefined|null} payload (optional) is the proto-RPC payload needed for the RPC `method`. In case it is NOT a google.protobuf.Empty message, it has to be a proto-message, otherwise it can be missing.
 *
 * @return {promise}
 */
export function controllerRpc(method, payload) {
  const req = new Request()
  const methodSet = protoRpcMethodTo('setter', method)
  const methodGet = protoRpcMethodTo('getter', method)

  if (isNil(payload)) {
    payload = new EmptyPb()
  } else if (!isProto(payload)) {
    throw new TypeError('Payload of controllerRpc has to be nil or a protobuf message.')
  }
  req[methodSet](payload)

  const call = createUnaryCall({
    service: RpcService,
    method: 'ControllerRpc',
    payload: req
  })

  const evalResponse = (msg) => {
    return msg[methodGet]()
  }

  return call.perform().then(evalEmsControllerError).then(evalResponse)
}

/**
 * API RPC which triggers any gRPC call
 * by default `ems.controller.Request.ping`,
 * until success ('pong') or timeout.
 *
 * @function
 *
 * @param {object} params
 * @param {number} params.timeout (optional) is the timeout in ms to wait for a 'pong'.
 * @param {number} params.interval (optional) is the polling interval in ms in case of failing 'ping'(s).
 * @param {number} params.initialDelay (optional) defines a delay in ms before performing the first ping
 *
 * @param {function} params.rpc (optionally) defines the RPC to be called. Has to return a Promise. By default `controllerRpc('ping')` is called.
 *
 * @return {promise}
 */
export function pingWaitPong({ timeout = 5000, interval = 500, initialDelay = 0, rpc } = {}) {
  const num = Math.ceil(timeout / interval)
  if (!rpc) {
    rpc = () => controllerRpc('ping')
  }

  return new Promise((resolve, reject) => {
    let i = 0
    let intervalId = null

    run()

    function onSuccess(msg) {
      logger.info('Successful RPC.')
      if (intervalId) {
        clearInterval(intervalId)
      }
      resolve(msg)
    }

    function onFailure(err) {
      logger.info('Failed RPC. Wait and retry.', err ? err.message : '')

      if (i === 0) {
        intervalId = setInterval(run, interval)
      }
      i++

      if (i > num) {
        if (intervalId) {
          clearInterval(intervalId)
        }
        reject(err)
      }
    }

    function run() {
      setTimeout(() => {
        rpc().then(onSuccess).catch(onFailure)
      }, initialDelay)
    }
  })
}

/**
 * API RPC which triggers an `ems.controller.Request.shutdown`, and waits until the EMS is up again.
 *
 * Because the EMS is down in-between, it cannot indicate it it is up again.
 * We test if the EMS is up again, by a polling 'ping'.
 * The `initialDelay` is 100 ms.
 *
 * @function
 *
 * @param {object} opts (optional) see [pingWaitPong]{@link module:src/api/controller.pingWaitPong}
 *
 * @return {promise}
 */
export function shutdownWaitUp(opts = {}) {
  return controllerRpc('shutdown').finally(() => {
    return pingWaitPong(Object.assign({ initialDelay: 100 }, opts))
  })
}

/**
 * API RPC which triggers an `ems.controller.Request.finalize_topology_change`.
 *
 * @function
 *
 * @return {promise}
 */
export function finalizeTopologyChange() {
  return controllerRpc('finalize_topology_change')
}
