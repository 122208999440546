<template>
  <!-- prettier-ignore -->
  <div :class="cssClass">
    <CSwitch
      color="primary"
      size="sm"
      :checked="expertMode"
      @update:checked="$emit('update:expert-mode', $event)"
    />
    <span class="ml-2">
      {{ $t('ems.energyService.config.evalTree.expertMode') }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'EvalTreeModeToggler',
  props: {
    expertMode: {
      type: Boolean,
      required: true
    },
    cssClass: {
      type: String,
      required: false,
      default: 'col-12 d-flex justify-content-end'
    }
  }
}
</script>
