<template>
  <div>
    <!-- BEGIN: Timeseries Meta Info  -->
    <CCardText class="small m-0 p-0">
      <p class="text-muted text-break text-right mb-1">
        {{ $t(`${tTouPath}.timeProfileTable.metaInfo.profileNameLabel`) }}:
        <span class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ tsName }}
        </span>
      </p>
      <p class="text-muted text-break text-right mb-1">
        {{ $t(`${tTouPath}.timeProfileTable.metaInfo.periodicityLabel`) }}:
        <span v-if="periodicity.custom" class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ periodicity.label }}
          <!-- prettier-ignore -->
          <br>
          ({{ periodicity.value }} {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.secondsLabel`) }})
        </span>
        <span v-else class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ periodicity.label }}
        </span>
      </p>
      <p class="text-muted text-break text-right mb-1">
        {{ $t(`${tTouPath}.timeProfileTable.metaInfo.interpolationLabel`) }}:
        <span class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ interpolation }}
        </span>
      </p>
      <p class="text-muted text-break text-right mb-1">
        {{ $t(`${tTouPath}.timeProfileTable.metaInfo.typeLabel`) }}:
        <span class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
          {{ typeValue }}
        </span>
      </p>
    </CCardText>
    <!-- END:   Timeseries Meta Info  -->
    <!-- BEGIN: Table  -->
    <!-- prettier-ignore -->
    <CDataTable
      class="table-sm p-2"
      :items="tsValues"
      :fields="fields"
      hover
      border
      sorter
    >
      <template #datetime="{item}">
        <td class="align-middle">
          {{ item.datetime }}
        </td>
      </template>
      <template #value="{item}">
        <td class="align-middle">
          {{ item.value }}
        </td>
      </template>
      <template #remove_tsValue="{item}">
        <td class="pr-0 text-center">
          <CButton
            color="danger"
            square
            size="sm"
            @click="onRemove(item)"
          >
            <CIcon name="cil-trash"/>
          </CButton>
        </td>
      </template>
      <template #no-items-view>
        <tbody class="d-flex align-content-center">
          <!-- prettier-ignore -->
          <svg
            id="svg-inner"
            x="0"
            y="0"
            width="50%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <CIcon name="cilBan" :height="100"/>
            <text
              x="50%"
              y="80%"
              :style="{'font-size': '12px', fill: isDark ? 'var(--dark-danger)' : 'var(--danger)'}"
              dominant-baseline="hanging"
              text-anchor="middle"
            >
              <tspan>
                {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.noData`) }}
              </tspan>
            </text>
          </svg>
        </tbody>
      </template>
    </CDataTable>
    <!-- END:   Table  -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { omit } from 'lodash'

import { formatTimestampPeriodicity } from '@/view-helper/filters/datetime-formatter'
import { TIME_UNITS_IN_SECONDS } from '@/view-helper/timeseries/timeseries-helper'

export default {
  name: 'TouTimeseriesTable',
  props: {
    tsName: {
      type: String,
      required: true
    },
    utcOffset: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('coreui', { isDark: 'darkMode' }),
    ...mapGetters('emsTimeseries', ['getSortedTimestampValues', 'getPeriodicity', 'getInterpolation', 'getType']),
    ...mapGetters('user', {
      currentLocal: 'lang'
    }),
    isTypeTimeSwitch() {
      return this.getType(this.tsName) === 'TIMESWITCH'
    },
    fields() {
      let valueLabel = this.$t(`${this.tTouPath}.timeProfileTable.timeSwitchHeader`)

      if (!this.isTypeTimeSwitch) {
        valueLabel = this.$t(`${this.tTouPath}.timeProfileTable.genericHeader`)
      }

      return [
        {
          key: 'datetime',
          label: this.$t(`${this.tTouPath}.timeProfileTable.timestampHeader`),
          _style: 'width:10%'
        },
        {
          key: 'value',
          label: valueLabel,
          _style: 'width:10%;',
          sorter: false
        },
        {
          key: 'remove_tsValue',
          label: '',
          _style: 'width:1%',
          sorter: false
        }
      ]
    },
    interpolation() {
      const interpolation = this.getInterpolation(this.tsName)

      switch (interpolation) {
        case 'RIGHT_BOUNDED':
          return this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.right`)
        case 'LINEAR':
          return this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.linear`)
        default:
          return this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.interpolation.options.left`)
      }
    },
    periodicity() {
      const periodicity = this.getPeriodicity(this.tsName)

      if (periodicity === 0) {
        return { value: periodicity, label: this.$t(`${this.tTouPath}.timeProfileTable.metaInfo.periodicityValueNone`) }
      } else if (periodicity === this.timeUnitsInSeconds.hour) {
        return {
          value: periodicity,
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.hour`)
        }
      } else if (periodicity === this.timeUnitsInSeconds.day) {
        return {
          value: periodicity,
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.day`)
        }
      } else if (periodicity === this.timeUnitsInSeconds.week) {
        return {
          value: periodicity,
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.week`)
        }
      } else {
        return {
          value: periodicity,
          label: this.$t(`${this.tTouPath}.timeProfileConfiguration.configForm.periodicity.options.custom.label`),
          custom: true
        }
      }
    },
    tsValues() {
      const tvs = this.getSortedTimestampValues(this.tsName, {
        periodicity: this.getPeriodicity(this.tsName),
        utcOffset: this.utcOffset
      })

      if (tvs) {
        return tvs.map((item) => {
          let value = item.value

          if (this.isTypeTimeSwitch) {
            value = this.$t('main.on')

            if (item.value === 0) {
              value = this.$t('main.off')
            }
          }

          const datetime = formatTimestampPeriodicity({
            timestamp: item.timestamp,
            periodicity: this.getPeriodicity(this.tsName),
            utcOffset: this.utcOffset,
            locale: this.currentLocal
          })

          return {
            timestamp: item.timestamp,
            value,
            datetime,
            ...omit(item, ['timestamp', 'value'])
          }
        })
      }
      return []
    },
    typeValue() {
      if (this.isTypeTimeSwitch) {
        return this.$t(`${this.tTouPath}.timeProfileTable.metaInfo.type.timeSwitch`)
      } else {
        return this.$t(`${this.tTouPath}.timeProfileTable.metaInfo.type.generic`)
      }
    }
  },
  created() {
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'
    this.timeUnitsInSeconds = TIME_UNITS_IN_SECONDS
  },
  methods: {
    onRemove(item) {
      this.$store.commit('emsTimeseries/REMOVE_TIMESTAMP_VALUE', {
        tsName: this.tsName,
        timestamp: item.originalTimestamp || item.timestamp
      })
    }
  }
}
</script>

<style scoped>
#svg-outer {
  display: flex;
  align-items: center;
  background-color: lightgray;
  height: 100%;
}

#svg-inner {
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 0;
  display: block;
}
</style>
