<template>
  <!-- wrapped by CCard -->
  <!-- prettier-ignore -->
  <div>
    <EmsEsStrategyChooser
      :active-strategy="strategy.activeOne"
      @abort:strategy-cloning="onAbortStrategyCloning"
      @create:strategy="onCreateStrategy"
      @switched:strategy="onSwitchedStrategy"
    />
    <EmsEsStrategyConfig
      :strategy-id="strategy.activeOne"
    />
    <CCardBody>
      <div class="d-flex justify-content-end">
        <BtnWithDescription
          ref="cloneBtn"
          class="mx-1 d-inline-block align-top"
          main-btn-color="primary"
          main-btn-variant="outline"
          :label="$t('ems.energyService.strategy.clone')"
          :disable-btn="isNewStrategy"
          size="sm"
          @click="onCloneStrategy"
          @update:show-description="onShowDescription({ type: 'CLONE', show: $event })"
        />
        <BtnWithDescription
          ref="deleteBtn"
          main-btn-color="danger"
          main-btn-variant="outline"
          class="mx-1 d-inline-block align-top"
          color="danger"
          :label="$t('ems.energyService.strategy.delete')"
          :disable-btn="isNewStrategy || isReferencedStrategy"
          size="sm"
          @click="onDeleteStrategy"
          @update:show-description="onShowDescription({ type: 'DELETE', show: $event })"
        />
      </div>
      <div v-if="show.descriptionClone" class="mt-1 ml-auto text-muted small" style="max-width: 250px">
        {{ cloneBtnDescription }}
      </div>
      <div v-if="show.descriptionDelete" class="mt-1 ml-auto text-muted small" style="max-width: 250px">
        {{ deleteBtnDescription }}
      </div>
    </CCardBody>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BtnWithDescription from '@/components/snippets/btn-with-description'
import EmsEsStrategyChooser from '@/components/ems/energy-services/ems-es-strategy-chooser'
import EmsEsStrategyConfig from '@/components/ems/energy-services/ems-es-strategy-config'
import { API_LOADING_IDS } from '@/store/modules/api-loading-status'
import { EMS_ENERGY_SERVICE_NEW_STRATEGY_ID } from '@/view-helper/ems/ems-energy-service-helper'

export default {
  name: 'EnergyServicesConfigStrategies',
  components: {
    BtnWithDescription,
    EmsEsStrategyChooser,
    EmsEsStrategyConfig
  },
  data() {
    return {
      show: {
        descriptionClone: false,
        descriptionDelete: false
      },
      strategy: {
        activeOne: EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
      }
    }
  },
  computed: {
    ...mapState('emsEnergyServicesConfig', ['actuatorGroupIds', 'strategyIds']),
    ...mapGetters('apiLoadingStatus', ['loaded']),
    ...mapGetters('emsEnergyServicesConfig', [
      'isDefaultStrategy',
      'isDecisionTreeLeaf',
      'getCache',
      'isDecisionTreeNodeFallbackOption'
    ]),
    isNewStrategy() {
      return this.strategy.activeOne === EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
    },
    isReferencedStrategy() {
      return (
        this.isDefaultStrategy(this.strategy.activeOne) ||
        this.isDecisionTreeLeaf(this.strategy.activeOne) ||
        this.isDecisionTreeNodeFallbackOption(this.strategy.activeOne)
      )
    },
    loadedActuatorStrategies() {
      return this.loaded(API_LOADING_IDS.ems.esActuatorStrategies)
    },
    cloneBtnDescription() {
      if (this.isNewStrategy) {
        return this.$t('ems.energyService.strategy.notCloneNewStrategy')
      }

      return this.$t('ems.energyService.strategy.cloneDescription')
    },
    deleteBtnDescription() {
      if (this.isNewStrategy) {
        return this.$t('ems.energyService.strategy.notDeleteNewStrategy')
      }
      if (this.isReferencedStrategy) {
        return this.$t('ems.energyService.strategy.notDeleteReferencedStrategy')
      }

      return this.$t('ems.energyService.strategy.deleteDescription')
    }
  },
  watch: {
    loadedActuatorStrategies(yes) {
      if (yes) {
        this.init()
      }
    },
    '$route.query'(q) {
      if (!q || !(this.strategyIds.includes(q.sid) || EMS_ENERGY_SERVICE_NEW_STRATEGY_ID === q.sid)) {
        this.$log.info(`Strategy with ID ${q.sid} not found.`)
        return
      }
      this.strategy.activeOne = q.sid
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const sid = this.$route.query.sid
      if (this.strategyIds.includes(sid) || EMS_ENERGY_SERVICE_NEW_STRATEGY_ID === sid) {
        this.strategy.activeOne = sid
      } else if (this.strategyIds.length > 0) {
        this.strategy.activeOne = this.strategyIds[0]
      } else {
        this.strategy.activeOne = EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
      }
    },
    showStrategy(sid) {
      // alternative use: this.strategy.activeOne = sid
      // however, routing is the consistent way
      this.$router.push({
        name: 'ems-energy-services-config-strategies',
        query: { sid }
      })
    },
    onSwitchedStrategy(id) {
      this.showStrategy(id)
    },
    onCreateStrategy(e) {
      const strategyId = e.name
      if (!strategyId) {
        this.$log.error('Failed to create new EMS energy service strategy. Strategy Name (ID) is missing.')
        return
      }

      const strategyIdToBeCloned = this.getCache('strategyIdToBeCloned')
      if (strategyIdToBeCloned) {
        this.$store.dispatch('emsEnergyServicesConfig/cloneStrategy', {
          sourceId: strategyIdToBeCloned,
          targetId: strategyId
        })
        this.$store.commit('emsEnergyServicesConfig/SET_CACHE', { key: 'strategyIdToBeCloned' }) // clear cache
        this.$log.debug(`Cloned strategy "${strategyIdToBeCloned}" to "${strategyId}".`)
      } else {
        this.$store.dispatch('emsEnergyServicesConfig/createDefaultStrategy', { strategyId })
        this.$log.debug(`Created a new strategy "${strategyId}".`)
      }

      this.showStrategy(strategyId)
    },
    onAbortStrategyCloning() {
      this.$store.commit('emsEnergyServicesConfig/SET_CACHE', { key: 'strategyIdToBeCloned' }) // clear cache
    },
    onCloneStrategy() {
      if (this.isNewStrategy) {
        this.$log.warn(`Do not allow to clone a ${EMS_ENERGY_SERVICE_NEW_STRATEGY_ID}`)
        return
      }

      this.$store.commit('emsEnergyServicesConfig/SET_CACHE', {
        key: 'strategyIdToBeCloned',
        val: this.strategy.activeOne
      })
      this.showStrategy(EMS_ENERGY_SERVICE_NEW_STRATEGY_ID)
    },
    onDeleteStrategy() {
      if (this.isNewStrategy) {
        this.$log.warn(`Do not allow to delete a ${EMS_ENERGY_SERVICE_NEW_STRATEGY_ID}`)
        return
      }
      if (this.isReferencedStrategy) {
        this.$log.warn(`Do not allow to delete a referenced Strategy ${this.strategy.activeOne}`)
        return
      }

      this.$store.commit('emsEnergyServicesConfig/REMOVE_STRATEGY', this.strategy.activeOne)
      this.$log.debug('Commited REMOVE_STRATEGY', this.strategy.activeOne)

      this.init()
      // ensures consistency, e.g. routing, cache, ...
      this.showStrategy(this.strategyIds[0] || EMS_ENERGY_SERVICE_NEW_STRATEGY_ID)
    },
    onShowDescription({ type, show }) {
      switch (type) {
        case 'CLONE':
          this.show.descriptionClone = show
          if (show) {
            this.show.descriptionDelete = !show
            this.$refs.deleteBtn.showDesc = !show
          }
          break
        case 'DELETE':
          this.show.descriptionDelete = show
          if (show) {
            this.show.descriptionClone = !show
            this.$refs.cloneBtn.showDesc = !show
          }
          break
      }
    }
  }
}
</script>
