<template>
  <div class="py-2">
    <p class="d-flex justify-content-between mb-1">
      <small class="ml-0 mr-3 font-weight-bold text-primary">
        {{ $t('ems.energyService.config.form.generationPeakShaving.batteryCapacity.label') }}
      </small>
      <small class="ml-1 mr-0 pr-0 text-break text-primary">
        <em>
          {{ batteryCapacityWattHours / 1000 }}
        </em>
        <em>kWh</em>
      </small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'EsPreviewGenerationPeakShaving',
  props: {
    batteryCapacityWattHours: {
      required: true,
      type: Number
    }
  }
}
</script>
