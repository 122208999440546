<template>
  <div>
    <!-- BEGIN: Offset Dropdown -->
    <CSelect
      v-if="periodicity !== timeUnitsInSeconds.day"
      :value="utcOffset"
      :options="utcOptions"
      :horizontal="styling.horizontalRegular"
      :description="$t(`${tTouPath}.timeProfileConfiguration.configForm.utcOffset.description`)"
      :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.utcOffset.label`)"
      @update:value="onOffsetChange"
    />
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <v-date-picker v-model="t0" :first-day-of-week="2" :locale="currentLocal" mode="dateTime" :is-dark="isDark" is24hr>
      <template v-slot="{ inputValue }">
        <CInput
          class="custom-form-label"
          :value="formatTime(inputValue)"
          :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.starttime.label`)"
          :horizontal="styling.horizontal"
          readonly
        >
          <template #description>
            <CRow>
              <small class="col-12 form-text text-muted d-block">
                {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.starttime.description`) }}
              </small>
            </CRow>
          </template>
        </CInput>
      </template>
    </v-date-picker>
    <!-- END: Offset Dropdown -->
    <!-- BEGIN: Periodiciy -->
    <!-- BEGIN: LABEL   -->
    <div class="custom-form-label mb-3">
      {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.label`) }}
    </div>
    <!-- END: LABEL -->
    <CRow>
      <!-- BEGIN: Weekday-->
      <CCol v-if="periodicity === timeUnitsInSeconds.week && periodicityMode !== 'custom'" col="12">
        <CSelect
          class="custom-form-label"
          :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.day`)"
          :value="timeFromTs.weekday"
          :options="dayOfWeekSelectOpts"
          @change="updateTime($event, 'weekday')"
        />
      </CCol>
      <CCol v-for="type in pickerTypes" :key="type">
        <CInput
          type="number"
          min="0"
          :max="maxNumber(type)"
          class="custom-form-label"
          :label="$t(`${tTouPath}.timeProfileConfiguration.configForm.periodicity.options.${type}`)"
          :value="timeFromTs[type]"
          @change="updateTime($event, type)"
        />
      </CCol>
    </CRow>
    <!-- END: Periodiciy -->
    <!-- BEGIN: Periodicity Slider -->
    <CInput
      id="per"
      :key="timestampPeriodicity"
      type="range"
      :min="minRange"
      :max="maxRange"
      :step="stepSize"
      :lazy="false"
      add-input-classes="tou-slider-range"
      add-label-classes="custom-form-label"
      custom
      :horizontal="styling.horizontalSlider"
      :value="timestampPeriodicity"
      @change="onTimestampChange"
    />
    <!-- END: Periodicity Slider -->
    <!-- BEGIN: Periodicity Slider - Description   -->
    <small class="row col-12 form-text text-muted d-none d-sm-block">
      {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.description`) }}
    </small>
    <small v-if="showInfoDescription" class="row col-12 form-text text-muted d-block d-sm-none">
      {{ $t(`${tTouPath}.timeProfileConfiguration.configForm.timestamp.description`) }}
    </small>
    <!-- END: Periodicity Slider - Description -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import { getClientUtcOffset, formatTimestampPeriodicity } from '@/view-helper/filters/datetime-formatter'
import {
  UTC_OFFSET_OPTIONS,
  START_OF_WEEK_OFFSET,
  TIME_UNITS_IN_SECONDS,
  getDayOfWeekSelectOpts
} from '@/view-helper/timeseries/timeseries-helper'

export default {
  name: 'TouPeriodicitySlider',
  props: {
    locale: {
      type: String,
      required: true
    },
    periodicity: {
      type: Number,
      required: true
    },
    periodicityMode: {
      type: String,
      required: true
    },
    showInfoDescription: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      days: 0,
      t0: null,
      timestampPeriodicity: null,
      utcOffset: 0
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('user', {
      currentLocal: 'lang'
    }),
    dayOfWeekSelectOpts() {
      return getDayOfWeekSelectOpts()
    },
    maxRange() {
      return this.minRange + this.periodicity - 1
    },
    minRange() {
      return START_OF_WEEK_OFFSET - this.utcOffsetInSec
    },
    stepSize() {
      return 1
    },
    utcOffsetInSec() {
      return this.utcOffset * this.timeUnitsInSeconds.hour
    },
    pickerTypes() {
      const types = ['minute', 'second']

      if (this.periodicity > this.timeUnitsInSeconds.hour) {
        types.unshift('hour')
      }

      if (this.periodicity > this.timeUnitsInSeconds.day && this.periodicityMode === 'custom') {
        types.unshift('day')
      }

      return types
    },
    timeFromTs() {
      return {
        second: moment.unix(this.timestampPeriodicity).second(),
        minute: moment.unix(this.timestampPeriodicity).minute(),
        hour: moment.unix(this.timestampPeriodicity).utc().utcOffset(this.utcOffset).hour(),
        day: Math.floor((this.timestampPeriodicity - this.minRange) / 86400),
        weekday: moment.unix(this.timestampPeriodicity).utc().utcOffset(this.utcOffset).isoWeekday()
      }
    }
  },
  watch: {
    periodicity() {
      this.initTimestampPeriodicity()
    },
    utcOffset() {
      this.initTimestampPeriodicity()
      this.t0 = moment().locale('de').startOf('week').utcOffset(this.utcOffset).format()
    }
  },
  created() {
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
    this.styling = {
      horizontal: {
        label: 'col-12 col-xl-5',
        input: 'col-12 col-xl-7'
      },
      horizontalRegular: {
        label: 'col-12 col-xl-5 custom-form-label',
        input: 'col-12 col-xl-7'
      },
      horizontalSlider: {
        label: 'col-12',
        input: 'col-12'
      }
    }
    this.utcOptions = UTC_OFFSET_OPTIONS
    this.timeUnitsInSeconds = TIME_UNITS_IN_SECONDS

    this.initUtcOffset()
    this.initTimestampPeriodicity()

    this.t0 = moment().locale('de').startOf('week').utcOffset(this.utcOffset).format('DD.MM.YYYY HH:mm:ss')
  },
  methods: {
    formatTime(t) {
      if (this.currentLocal === 'de') {
        t = moment(t, 'DD.MM.YYYY HH:mm:ss')
      } else {
        t = moment(t, 'MM/DD/YYYY HH:mm:ss')
      }

      return formatTimestampPeriodicity({
        timestamp: t.unix(),
        periodicity: this.periodicity,
        utcOffset: this.utcOffset
      })
    },
    initTimestampPeriodicity() {
      this.timestampPeriodicity = this.minRange
      this.$emit('onTimestampPeriodicityUpdate', this.timestampPeriodicity)
    },
    initUtcOffset() {
      if (this.periodicity >= this.timeUnitsInSeconds.day) {
        this.utcOffset = getClientUtcOffset()
        this.$emit('onUtcOffsetUpdate', this.utcOffset)
      }
    },
    maxNumber(type) {
      switch (type) {
        case 'second':
        case 'minute':
          return 60
        case 'hour':
          return 23
        case 'day':
          return Math.floor((this.maxRange - this.minRange) / 86400)
      }
    },
    onOffsetChange(utcOffset) {
      this.utcOffset = utcOffset
      this.$emit('onUtcOffsetUpdate', this.utcOffset)
    },
    onTimestampChange(t) {
      this.timestampPeriodicity = t
      this.$emit('onTimestampPeriodicityUpdate', this.timestampPeriodicity)
    },
    updateTime(p, type) {
      if (type === 'day') {
        const range = Math.floor((this.maxRange - this.minRange) / 86400)
        let d

        if (p) {
          d = parseInt(p)
        } else {
          return
        }

        // NOTE: This does not consistently update the input value
        if (d < 0) {
          d = 0
        } else if (range) {
          d = range
        }

        const tRest = (this.timestampPeriodicity - this.minRange) % 86400
        this.timestampPeriodicity = this.minRange + d * 86400 + tRest
      } else {
        let t = moment.unix(this.timestampPeriodicity)
        if (['hour', 'weekday'].includes(type)) {
          t = moment.unix(this.timestampPeriodicity).utc().utcOffset(this.utcOffset)
        }

        t[type](type === 'weekday' ? p.target.value : parseInt(p))

        this.timestampPeriodicity = t.unix()
      }
      this.$emit('onTimestampPeriodicityUpdate', this.timestampPeriodicity)
    }
  }
}
</script>
