<template>
  <div>
    <CCardText>
      <h6 class="text-muted text-break text-left text-primary">
        {{ $t(`${tTouPath}.timeProfiles.addProfileForm.addProfileTitle`) }}
      </h6>
    </CCardText>
    <div class="mt-0 mb-1 col-12 p-2 pr-3">
      <!-- BEGIN:  Form  -->
      <CForm @submit.prevent>
        <CInput
          type="text"
          class="custom-form-label"
          :value.sync="$v.addProfileForm.newTsName.$model"
          :label="$t(`${tTouPath}.timeProfiles.addProfileForm.newTsName.label`)"
          :placeholder="$t(`${tTouPath}.timeProfiles.addProfileForm.newTsName.placeholder`)"
          :is-valid="$v.addProfileForm.newTsName.$dirty ? !$v.addProfileForm.newTsName.$error : null"
          :invalid-feedback="invalidFeedback"
          :horizontal="styling.horizontal"
        >
          <template #description>
            <small class="form-text text-muted d-none d-sm-block">
              {{ $t(`${tTouPath}.timeProfiles.addProfileForm.newTsName.description`) }}
            </small>
            <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
              {{ $t(`${tTouPath}.timeProfiles.addProfileForm.newTsName.description`) }}
            </small>
          </template>
        </CInput>
        <!-- prettier-ignore -->
        <!-- BEGIN: CSV import format information -->
        <div class="d-none d-sm-block">
          <span class="text-break text-left text-primary font-weight-bold">
            {{ $t(`${tTouPath}.timeProfiles.addProfileForm.importFormat.title`) }}
          </span>
          <small class="form-text text-muted">
            {{ $t(`${tTouPath}.timeProfiles.addProfileForm.importFormat.description`) }}
          </small>
        </div>
        <div v-if="showInfoDescription" class="d-block d-sm-none">
          <span class="text-break text-left text-primary font-weight-bold">
            {{ $t(`${tTouPath}.timeProfiles.addProfileForm.importFormat.title`) }}
          </span>
          <small class="form-text text-muted">
            {{ $t(`${tTouPath}.timeProfiles.addProfileForm.importFormat.description`) }}
          </small>
        </div>
        <!-- END:   CSV import format information -->
        <div class="mt-3 d-flex justify-content-begin">
          <CButton class="mr-2" size="sm" color="primary" :disabled="$v.$invalid">
            <!-- prettier-ignore -->
            <label :class="{ 'p-0': true, 'm-0': true, 'import-label-enabled': !$v.$invalid, 'import-label-disabled': $v.$invalid }">
              <input
                ref="csv"
                accept=".csv"
                type="file"
                :disabled="$v.$invalid"
                @change="onCsvUpload"
                @click="hideAlertOnCsvImport"
              >
              <CIcon size="sm" name="cil-cloud-upload"/>
              <span class="align-middle d-none d-sm-inline">
                {{ $t('main.importBtn') }}
              </span>
            </label>
          </CButton>
          <!-- prettier-ignore -->
          <CButton
            size="sm"
            color="success"
            :disabled="$v.addProfileForm.$invalid"
            @click="onAddNewProfile"
          >
            <CIcon size="sm" name="cil-file-add" />
            <span class="align-middle d-none d-sm-inline">
              {{ $t(`${tTouPath}.createNewProfileBtn`) }}
            </span>
          </CButton>
        </div>
      </CForm>
      <!-- END:  Form  -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { newAlertNotificationMsg } from '@/store/modules/notifications'
import { timeseriesNameValidator } from '@/validations/ems-tou-form-validators'

import { timeseriesCsvValidate } from '@/view-helper/timeseries/timeseries-helper'

export default {
  name: 'TouTimeseriesNewForm',
  props: {
    showInfoDescription: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      addProfileForm: {
        newTsName: ''
      },
      csvData: ''
    }
  },
  computed: {
    ...mapGetters('emsTimeseries', ['timeseriesNames']),
    invalidFeedback() {
      if (!this.$v.addProfileForm.newTsName.required) {
        return this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.validation.invalidFeedback.required`)
      }

      if (!this.$v.addProfileForm.newTsName.alphaNum) {
        return this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.validation.invalidFeedback.alphaNum`)
      }

      if (!this.$v.addProfileForm.newTsName.unique) {
        return this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.validation.invalidFeedback.unique`)
      }

      return ''
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-xl-5',
        input: 'col-12 col-xl-7'
      }
    }
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
  },
  methods: {
    hideAlertOnCsvImport() {
      this.$store.commit('notifications/SHOW_ALERT', false)
    },
    onAddNewProfile() {
      const newTsName = this.addProfileForm.newTsName

      this.$store.commit('emsTimeseries/INIT_TIMESERIES', {
        tsName: newTsName,
        interpolation: 'LEFT_BOUNDED',
        periodicity: 0,
        touched: true
      })
      this.resetAddProfileForm(newTsName)
    },
    onCsvUpload(e) {
      const csvFile = e.target.files[0]

      this.$log.debug('A csv file has been succesfully uploaded: ', csvFile)
      this.$papa.parse(csvFile, {
        header: true,
        delimiter: ',',
        skipEmptyLines: true,
        complete: this.onCsvUploadComplete
      })
    },
    onCsvUploadComplete(results) {
      this.csvData = results.data
      const newTsName = this.addProfileForm.newTsName
      let resetNewTsName

      const validationResult = timeseriesCsvValidate(results)

      if (validationResult.hasError) {
        resetNewTsName = false
        let errMsg = ''

        this.$log.debug('The uploaded CSV file does not match the expected format.', validationResult)
        if (validationResult.type === 'invalidHeader') {
          errMsg = this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.csvHeaderError`, [
            validationResult.entryValue,
            validationResult.type
          ])
        } else if (validationResult.type === 'invalidDate' || validationResult.type === 'invalidValue') {
          errMsg = this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.csvEntryError`, [
            validationResult.type,
            validationResult.row,
            validationResult.entryValue
          ])
        } else if (validationResult.type === 'invalidGeneric') {
          errMsg = this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.csvGenericError`, [
            validationResult.errorType,
            validationResult.row
          ])
        }

        this.$store.commit(
          'notifications/NEW_ALERT',
          newAlertNotificationMsg({
            type: 'danger',
            content: errMsg,
            show: true
          })
        )
      } else {
        const timestampValueList = this.csvData.map((item) => {
          return {
            timestamp: parseFloat(item.timestamp),
            value: parseFloat(item.value)
          }
        })

        this.$store.commit('emsTimeseries/INIT_TIMESERIES', {
          tsName: newTsName,
          interpolation: 'LEFT_BOUNDED',
          periodicity: 0,
          touched: true
        })

        this.$store.commit('emsTimeseries/SET_TIMESTAMP_VALUES', {
          tsName: newTsName,
          timestampValueList,
          touched: true
        })
      }

      this.resetAddProfileForm(newTsName, resetNewTsName)
    },
    resetAddProfileForm(name, resetNewTsName = true) {
      if (resetNewTsName) {
        this.addProfileForm.newTsName = ''
        this.$emit('afterTimeseriesCreate', { name: name })
      }
      this.$refs.csv.value = null
      this.csvData = ''
      this.hasValildHeaders = true
      this.invalidHeaders = []
      this.$v.addProfileForm.$reset()
    }
  },
  validations() {
    return {
      addProfileForm: {
        newTsName: timeseriesNameValidator(this.timeseriesNames || [])
      }
    }
  }
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}

.import-label-enabled {
  cursor: pointer;
}

.import-label-disabled {
  cursor: not-allowed;
}
</style>
