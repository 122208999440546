var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app",class:{ 'c-dark-theme': _vm.isDark },style:({ cursor: _vm.cursorLoadingStyle })},[(_vm.isLoggedIn)?_c('ApxSidebar'):_vm._e(),_c('CWrapper',[(_vm.isLoggedIn)?_c('ApxHeader'):_vm._e(),_c('ApxToaster'),(_vm.isLoggedIn)?_c('FwUpgradeGuard'):_vm._e(),_c('div',{staticClass:"c-body"},[_c('main',{class:{
          'c-main': true,
          'pt-0': true,
          'px-0': _vm.isLoggedIn,
          'px-lg-2': _vm.isLoggedIn,
          'px-xl-5': _vm.isLoggedIn
        }},[_c('CContainer',{staticClass:"p-0 m-0",attrs:{"fluid":""}},[(_vm.isLoggedIn)?_c('CRow',{staticClass:"my-1 my-md-2 my-xl-3"},[_c('CCol',{attrs:{"col":"12","md":{ size: 10, offset: 1 },"xl":{ size: 8, offset: 2 }}},[_c('ApxMainAlert')],1)],1):_vm._e(),(_vm.isLoggedIn)?[_c('transition',[_c('router-view')],1)]:_c('ApxAuthContainer',[_c('transition',[_c('router-view')],1)],1)],2)],1),(_vm.isLoggedIn)?_c('ApxFooter'):_c('CFooter',{staticClass:"position-absolute"},[_c('ApxInformationsSnippet')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }