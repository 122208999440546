// source: ext/ems/controller/controller.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_ems_data$objects_pb = require('../../../ext/ems/data-objects_pb.js');
goog.object.extend(proto, ext_ems_data$objects_pb);
var ext_ems_acsi_acsi_pb = require('../../../ext/ems/acsi/acsi_pb.js');
goog.object.extend(proto, ext_ems_acsi_acsi_pb);
var ext_ems_topology_topology_pb = require('../../../ext/ems/topology/topology_pb.js');
goog.object.extend(proto, ext_ems_topology_topology_pb);
var ext_ems_config_config_pb = require('../../../ext/ems/config/config_pb.js');
goog.object.extend(proto, ext_ems_config_config_pb);
var ext_ems_scontroller_scontroller_pb = require('../../../ext/ems/scontroller/scontroller_pb.js');
goog.object.extend(proto, ext_ems_scontroller_scontroller_pb);
var ext_ems_eval_pb = require('../../../ext/ems/eval_pb.js');
goog.object.extend(proto, ext_ems_eval_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.AbortRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ActivationFlag', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.AliasResult', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.AliasResult.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.AvailableVariableStores', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.BackupResult', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ChangeAlias', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.DataForVariableStore', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Error', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Heartbeat', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.InterpolationType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LogMessage', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LogNotification', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LogNotification.Notification', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LoggingInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LoggingLevel', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.LoggingSetting', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Loop', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Name', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.NameValue', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.NameValues', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Names', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Notification', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.OperateRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.PeakObserverName', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.PeakObserverNameList', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.PeakObserverState', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.RemoveSourceReply', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ReplaceSourceRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Request', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Request.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Response', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Response.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.RpcRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.RpcRequest.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.RpcResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.RpcResponse.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleEntry.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleId', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.ScheduleIdList', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SchemaForVariableStore', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SensorListDataRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SensorNameAndSampleList', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SensorNotification', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SensorSample', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.SensorSampleList', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Timeseries', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimeseriesName', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Timeserieses', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.TimestampValues', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.VariableStore', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.VariableStore.StoreType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.VariableStoreRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.VariableStoreSetRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Version', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.Version.Number', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.controller.VersionByGit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.LoggingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.LoggingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.LoggingInfo.displayName = 'proto.de.mypowergrid.ems.controller.LoggingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.LoggingSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.LoggingSetting.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.LoggingSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.LoggingSetting.displayName = 'proto.de.mypowergrid.ems.controller.LoggingSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.LogMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.LogMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.LogMessage.displayName = 'proto.de.mypowergrid.ems.controller.LogMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Version.displayName = 'proto.de.mypowergrid.ems.controller.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Version.Number = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Version.Number, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Version.Number.displayName = 'proto.de.mypowergrid.ems.controller.Version.Number';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ChangeAlias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ChangeAlias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ChangeAlias.displayName = 'proto.de.mypowergrid.ems.controller.ChangeAlias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Loop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.Loop.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Loop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Loop.displayName = 'proto.de.mypowergrid.ems.controller.Loop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.AliasResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.AliasResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.AliasResult.displayName = 'proto.de.mypowergrid.ems.controller.AliasResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.VersionByGit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.VersionByGit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.VersionByGit.displayName = 'proto.de.mypowergrid.ems.controller.VersionByGit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Heartbeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Heartbeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Heartbeat.displayName = 'proto.de.mypowergrid.ems.controller.Heartbeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.displayName = 'proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.LogNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.LogNotification.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.LogNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.LogNotification.displayName = 'proto.de.mypowergrid.ems.controller.LogNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.LogNotification.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.LogNotification.Notification.displayName = 'proto.de.mypowergrid.ems.controller.LogNotification.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SensorNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.SensorNotification.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SensorNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SensorNotification.displayName = 'proto.de.mypowergrid.ems.controller.SensorNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.RpcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.RpcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.RpcRequest.displayName = 'proto.de.mypowergrid.ems.controller.RpcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.RpcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.RpcResponse.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.RpcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.RpcResponse.displayName = 'proto.de.mypowergrid.ems.controller.RpcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Notification.displayName = 'proto.de.mypowergrid.ems.controller.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ActivationFlag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ActivationFlag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ActivationFlag.displayName = 'proto.de.mypowergrid.ems.controller.ActivationFlag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.VariableStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.VariableStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.VariableStore.displayName = 'proto.de.mypowergrid.ems.controller.VariableStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.AvailableVariableStores.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.AvailableVariableStores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.AvailableVariableStores.displayName = 'proto.de.mypowergrid.ems.controller.AvailableVariableStores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.DataForVariableStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.DataForVariableStore.displayName = 'proto.de.mypowergrid.ems.controller.DataForVariableStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SchemaForVariableStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SchemaForVariableStore.displayName = 'proto.de.mypowergrid.ems.controller.SchemaForVariableStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.VariableStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.VariableStoreRequest.displayName = 'proto.de.mypowergrid.ems.controller.VariableStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.VariableStoreSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.displayName = 'proto.de.mypowergrid.ems.controller.VariableStoreSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.Request.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Request.displayName = 'proto.de.mypowergrid.ems.controller.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.Response.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Response.displayName = 'proto.de.mypowergrid.ems.controller.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Error.displayName = 'proto.de.mypowergrid.ems.controller.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.AbortRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.AbortRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.AbortRequest.displayName = 'proto.de.mypowergrid.ems.controller.AbortRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Name = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Name, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Name.displayName = 'proto.de.mypowergrid.ems.controller.Name';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Names = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.Names.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Names, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Names.displayName = 'proto.de.mypowergrid.ems.controller.Names';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.NameValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.NameValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.NameValue.displayName = 'proto.de.mypowergrid.ems.controller.NameValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.NameValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.NameValues.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.NameValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.NameValues.displayName = 'proto.de.mypowergrid.ems.controller.NameValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimeseriesName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimeseriesName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimeseriesName.displayName = 'proto.de.mypowergrid.ems.controller.TimeseriesName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Timeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Timeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Timeseries.displayName = 'proto.de.mypowergrid.ems.controller.Timeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.Timeserieses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.Timeserieses.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.Timeserieses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.Timeserieses.displayName = 'proto.de.mypowergrid.ems.controller.Timeserieses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.displayName = 'proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.displayName = 'proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.displayName = 'proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.displayName = 'proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.TimestampValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.TimestampValues.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.TimestampValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.TimestampValues.displayName = 'proto.de.mypowergrid.ems.controller.TimestampValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SensorListDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SensorListDataRequest.displayName = 'proto.de.mypowergrid.ems.controller.SensorListDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SensorSample = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SensorSample, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SensorSample.displayName = 'proto.de.mypowergrid.ems.controller.SensorSample';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SensorSampleList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.SensorSampleList.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SensorSampleList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SensorSampleList.displayName = 'proto.de.mypowergrid.ems.controller.SensorSampleList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.SensorNameAndSampleList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.displayName = 'proto.de.mypowergrid.ems.controller.SensorNameAndSampleList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.ScheduleIdList.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleIdList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleIdList.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleIdList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleId.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.displayName = 'proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.OperateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.OperateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.OperateRequest.displayName = 'proto.de.mypowergrid.ems.controller.OperateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.PeakObserverNameList.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.PeakObserverNameList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.PeakObserverNameList.displayName = 'proto.de.mypowergrid.ems.controller.PeakObserverNameList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.PeakObserverName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.PeakObserverName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.PeakObserverName.displayName = 'proto.de.mypowergrid.ems.controller.PeakObserverName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.PeakObserverState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.PeakObserverState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.PeakObserverState.displayName = 'proto.de.mypowergrid.ems.controller.PeakObserverState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.displayName = 'proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.displayName = 'proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.ReplaceSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.displayName = 'proto.de.mypowergrid.ems.controller.ReplaceSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.ems.controller.RemoveSourceReply.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.ems.controller.RemoveSourceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.controller.RemoveSourceReply.displayName = 'proto.de.mypowergrid.ems.controller.RemoveSourceReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.LoggingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.LoggingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.LoggingInfo}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.LoggingInfo;
  return proto.de.mypowergrid.ems.controller.LoggingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.LoggingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.LoggingInfo}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.LoggingLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.LoggingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.LoggingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LoggingInfo} returns this
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LoggingLevel level = 2;
 * @return {!proto.de.mypowergrid.ems.controller.LoggingLevel}
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.getLevel = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.LoggingLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.LoggingLevel} value
 * @return {!proto.de.mypowergrid.ems.controller.LoggingInfo} returns this
 */
proto.de.mypowergrid.ems.controller.LoggingInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.LoggingSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.LoggingSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryList: jspb.Message.toObjectList(msg.getEntryList(),
    proto.de.mypowergrid.ems.controller.LoggingInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.LoggingSetting}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.LoggingSetting;
  return proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.LoggingSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.LoggingSetting}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.LoggingInfo;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LoggingInfo.deserializeBinaryFromReader);
      msg.addEntry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.LoggingSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.LoggingSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.LoggingInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoggingInfo entry = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.LoggingInfo>}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.getEntryList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.LoggingInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.LoggingInfo, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.LoggingInfo>} value
 * @return {!proto.de.mypowergrid.ems.controller.LoggingSetting} returns this
*/
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.setEntryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.LoggingInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.LoggingInfo}
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.addEntry = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.LoggingInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.LoggingSetting} returns this
 */
proto.de.mypowergrid.ems.controller.LoggingSetting.prototype.clearEntryList = function() {
  return this.setEntryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.LogMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.LogMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.LogMessage}
 */
proto.de.mypowergrid.ems.controller.LogMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.LogMessage;
  return proto.de.mypowergrid.ems.controller.LogMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.LogMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.LogMessage}
 */
proto.de.mypowergrid.ems.controller.LogMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.LogMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.LogMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LogMessage} returns this
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LogMessage} returns this
 */
proto.de.mypowergrid.ems.controller.LogMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    params: (f = msg.getParams()) && proto.de.mypowergrid.ems.controller.Version.Number.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Version}
 */
proto.de.mypowergrid.ems.controller.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Version;
  return proto.de.mypowergrid.ems.controller.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Version}
 */
proto.de.mypowergrid.ems.controller.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Value;
      reader.readMessage(value,google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.controller.Version.Number;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Version.Number.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Value.serializeBinaryToWriter
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.controller.Version.Number.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Version.Number.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Version.Number} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Version.Number.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorV: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minorV: jspb.Message.getFieldWithDefault(msg, 2, 0),
    patchV: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Version.Number}
 */
proto.de.mypowergrid.ems.controller.Version.Number.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Version.Number;
  return proto.de.mypowergrid.ems.controller.Version.Number.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Version.Number} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Version.Number}
 */
proto.de.mypowergrid.ems.controller.Version.Number.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajorV(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinorV(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPatchV(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Version.Number.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Version.Number} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Version.Number.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorV();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinorV();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPatchV();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 major_v = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.getMajorV = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Version.Number} returns this
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.setMajorV = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minor_v = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.getMinorV = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Version.Number} returns this
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.setMinorV = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 patch_v = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.getPatchV = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Version.Number} returns this
 */
proto.de.mypowergrid.ems.controller.Version.Number.prototype.setPatchV = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Value id = 1;
 * @return {?proto.google.protobuf.Value}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 1));
};


/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Version} returns this
*/
proto.de.mypowergrid.ems.controller.Version.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Version} returns this
 */
proto.de.mypowergrid.ems.controller.Version.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Version} returns this
 */
proto.de.mypowergrid.ems.controller.Version.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Number params = 3;
 * @return {?proto.de.mypowergrid.ems.controller.Version.Number}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.getParams = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Version.Number} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Version.Number, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Version.Number|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Version} returns this
*/
proto.de.mypowergrid.ems.controller.Version.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Version} returns this
 */
proto.de.mypowergrid.ems.controller.Version.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Version.prototype.hasParams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ChangeAlias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ChangeAlias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expression: (f = msg.getExpression()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ChangeAlias}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ChangeAlias;
  return proto.de.mypowergrid.ems.controller.ChangeAlias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ChangeAlias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ChangeAlias}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setExpression(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ChangeAlias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ChangeAlias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpression();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.ChangeAlias} returns this
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional de.mypowergrid.ems.EvalExpression expression = 2;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.getExpression = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ChangeAlias} returns this
*/
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.setExpression = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ChangeAlias} returns this
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.clearExpression = function() {
  return this.setExpression(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ChangeAlias.prototype.hasExpression = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Loop.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Loop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Loop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Loop.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliasesInLoopList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Loop}
 */
proto.de.mypowergrid.ems.controller.Loop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Loop;
  return proto.de.mypowergrid.ems.controller.Loop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Loop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Loop}
 */
proto.de.mypowergrid.ems.controller.Loop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAliasesInLoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Loop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Loop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Loop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAliasesInLoopList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string aliases_in_loop = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.getAliasesInLoopList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.controller.Loop} returns this
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.setAliasesInLoopList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.Loop} returns this
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.addAliasesInLoop = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.Loop} returns this
 */
proto.de.mypowergrid.ems.controller.Loop.prototype.clearAliasesInLoopList = function() {
  return this.setAliasesInLoopList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.AliasResult.TypeCase = {
  TYPE_NOT_SET: 0,
  OK: 1,
  DUPLICATE: 2,
  NOTFOUND: 3,
  LOOP: 4
};

/**
 * @return {proto.de.mypowergrid.ems.controller.AliasResult.TypeCase}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.AliasResult.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.AliasResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.AliasResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AliasResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    duplicate: (f = msg.getDuplicate()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    notfound: (f = msg.getNotfound()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    loop: (f = msg.getLoop()) && proto.de.mypowergrid.ems.controller.Loop.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult}
 */
proto.de.mypowergrid.ems.controller.AliasResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.AliasResult;
  return proto.de.mypowergrid.ems.controller.AliasResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.AliasResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult}
 */
proto.de.mypowergrid.ems.controller.AliasResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setDuplicate(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setNotfound(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.controller.Loop;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Loop.deserializeBinaryFromReader);
      msg.setLoop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.AliasResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.AliasResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AliasResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getDuplicate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getNotfound();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getLoop();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.controller.Loop.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Empty ok = 1;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.getOk = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
*/
proto.de.mypowergrid.ems.controller.AliasResult.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty duplicate = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.getDuplicate = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
*/
proto.de.mypowergrid.ems.controller.AliasResult.prototype.setDuplicate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.clearDuplicate = function() {
  return this.setDuplicate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.hasDuplicate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty notFound = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.getNotfound = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
*/
proto.de.mypowergrid.ems.controller.AliasResult.prototype.setNotfound = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.clearNotfound = function() {
  return this.setNotfound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.hasNotfound = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Loop loop = 4;
 * @return {?proto.de.mypowergrid.ems.controller.Loop}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.getLoop = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Loop} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Loop, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Loop|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
*/
proto.de.mypowergrid.ems.controller.AliasResult.prototype.setLoop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.controller.AliasResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.AliasResult} returns this
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.clearLoop = function() {
  return this.setLoop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.AliasResult.prototype.hasLoop = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.VersionByGit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.VersionByGit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VersionByGit.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, ""),
    commitMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.VersionByGit;
  return proto.de.mypowergrid.ems.controller.VersionByGit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.VersionByGit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommitMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.VersionByGit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.VersionByGit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VersionByGit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommitMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit} returns this
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit} returns this
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string commit_message = 3;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.getCommitMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit} returns this
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.setCommitMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string branch = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VersionByGit} returns this
 */
proto.de.mypowergrid.ems.controller.VersionByGit.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Heartbeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Heartbeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    params: (f = msg.getParams()) && proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Heartbeat;
  return proto.de.mypowergrid.ems.controller.Heartbeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Value;
      reader.readMessage(value,google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Heartbeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Heartbeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Value.serializeBinaryToWriter
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus = {
  ALIVE: 0,
  ERROR: 1,
  DEAD: 2,
  UNKNOWN: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    emsstatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pingtimestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    disktotalbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    diskusedbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    diskinodestotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    diskinodesused: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    uptimeseconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    loadaverageperminute: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    memorytotalbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    memorycommittedbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    memoryfreebytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    swaptotalbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    swapusedbytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    thermaldegreescList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    maintenanceip: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus;
  return proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus} */ (reader.readEnum());
      msg.setEmsstatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPingtimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisktotalbytes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiskusedbytes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiskinodestotal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiskinodesused(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUptimeseconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoadaverageperminute(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemorytotalbytes(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemorycommittedbytes(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemoryfreebytes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwaptotalbytes(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwapusedbytes(value);
      break;
    case 14:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setThermaldegreescList(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaintenanceip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmsstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPingtimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDisktotalbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDiskusedbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDiskinodestotal();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDiskinodesused();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUptimeseconds();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLoadaverageperminute();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMemorytotalbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getMemorycommittedbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getMemoryfreebytes();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSwaptotalbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getSwapusedbytes();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getThermaldegreescList();
  if (f.length > 0) {
    writer.writePackedDouble(
      14,
      f
    );
  }
  f = message.getMaintenanceip();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional EmsStatus EMSStatus = 1;
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getEmsstatus = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.Heartbeat.EmsStatus} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setEmsstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double PingTimestamp = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getPingtimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setPingtimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double DiskTotalBytes = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getDisktotalbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setDisktotalbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double DiskUsedBytes = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getDiskusedbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setDiskusedbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double DiskInodesTotal = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getDiskinodestotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setDiskinodestotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double DiskInodesUsed = 6;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getDiskinodesused = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setDiskinodesused = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double UptimeSeconds = 7;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getUptimeseconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setUptimeseconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double LoadAveragePerMinute = 8;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getLoadaverageperminute = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setLoadaverageperminute = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double MemoryTotalBytes = 9;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getMemorytotalbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setMemorytotalbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double MemoryCommittedBytes = 10;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getMemorycommittedbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setMemorycommittedbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double MemoryFreeBytes = 11;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getMemoryfreebytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setMemoryfreebytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double SwapTotalBytes = 12;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getSwaptotalbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setSwaptotalbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double SwapUsedBytes = 13;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getSwapusedbytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setSwapusedbytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated double ThermalDegreesC = 14;
 * @return {!Array<number>}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getThermaldegreescList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setThermaldegreescList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.addThermaldegreesc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.clearThermaldegreescList = function() {
  return this.setThermaldegreescList([]);
};


/**
 * optional string MaintenanceIp = 15;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.getMaintenanceip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus.prototype.setMaintenanceip = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Value id = 1;
 * @return {?proto.google.protobuf.Value}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 1));
};


/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat} returns this
*/
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SystemStatus params = 3;
 * @return {?proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.getParams = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Heartbeat.SystemStatus|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat} returns this
*/
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Heartbeat} returns this
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Heartbeat.prototype.hasParams = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.LogNotification.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.LogNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.de.mypowergrid.ems.controller.LogNotification.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification}
 */
proto.de.mypowergrid.ems.controller.LogNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.LogNotification;
  return proto.de.mypowergrid.ems.controller.LogNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification}
 */
proto.de.mypowergrid.ems.controller.LogNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Value;
      reader.readMessage(value,google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.controller.LogNotification.Notification;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LogNotification.Notification.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.LogNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Value.serializeBinaryToWriter
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.controller.LogNotification.Notification.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.LogNotification.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    priority: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.LogNotification.Notification;
  return proto.de.mypowergrid.ems.controller.LogNotification.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.LogNotification.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double timestamp = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 priority = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.Notification.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Value id = 1;
 * @return {?proto.google.protobuf.Value}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 1));
};


/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification} returns this
*/
proto.de.mypowergrid.ems.controller.LogNotification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Notification params = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.LogNotification.Notification>}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.LogNotification.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.LogNotification.Notification, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.LogNotification.Notification>} value
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification} returns this
*/
proto.de.mypowergrid.ems.controller.LogNotification.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.LogNotification.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification.Notification}
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.controller.LogNotification.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.LogNotification} returns this
 */
proto.de.mypowergrid.ems.controller.LogNotification.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.SensorNotification.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SensorNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SensorNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorNameAndSamplesList: jspb.Message.toObjectList(msg.getSensorNameAndSamplesList(),
    proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNotification}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SensorNotification;
  return proto.de.mypowergrid.ems.controller.SensorNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SensorNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNotification}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.SensorNameAndSampleList;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.deserializeBinaryFromReader);
      msg.addSensorNameAndSamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SensorNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SensorNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorNameAndSamplesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SensorNameAndSampleList sensor_name_and_samples = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList>}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.getSensorNameAndSamplesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.SensorNameAndSampleList, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList>} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorNotification} returns this
*/
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.setSensorNameAndSamplesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList}
 */
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.addSensorNameAndSamples = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.SensorNameAndSampleList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNotification} returns this
 */
proto.de.mypowergrid.ems.controller.SensorNotification.prototype.clearSensorNameAndSamplesList = function() {
  return this.setSensorNameAndSamplesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  CONTROLLER: 2,
  ACSI: 3,
  NOTIFICATION: 4
};

/**
 * @return {proto.de.mypowergrid.ems.controller.RpcRequest.TypeCase}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.RpcRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.RpcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.RpcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RpcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controller: (f = msg.getController()) && proto.de.mypowergrid.ems.controller.Request.toObject(includeInstance, f),
    acsi: (f = msg.getAcsi()) && ext_ems_acsi_acsi_pb.Request.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.de.mypowergrid.ems.controller.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.RpcRequest;
  return proto.de.mypowergrid.ems.controller.RpcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.RpcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.controller.Request;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Request.deserializeBinaryFromReader);
      msg.setController(value);
      break;
    case 3:
      var value = new ext_ems_acsi_acsi_pb.Request;
      reader.readMessage(value,ext_ems_acsi_acsi_pb.Request.deserializeBinaryFromReader);
      msg.setAcsi(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.controller.Notification;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.RpcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.RpcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RpcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getController();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.controller.Request.serializeBinaryToWriter
    );
  }
  f = message.getAcsi();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_ems_acsi_acsi_pb.Request.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.controller.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Request controller = 2;
 * @return {?proto.de.mypowergrid.ems.controller.Request}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.getController = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Request} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Request, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Request|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
*/
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.setController = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.clearController = function() {
  return this.setController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.hasController = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional de.mypowergrid.ems.acsi.Request acsi = 3;
 * @return {?proto.de.mypowergrid.ems.acsi.Request}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.getAcsi = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.Request} */ (
    jspb.Message.getWrapperField(this, ext_ems_acsi_acsi_pb.Request, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.Request|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
*/
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.setAcsi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.clearAcsi = function() {
  return this.setAcsi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.hasAcsi = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Notification notification = 4;
 * @return {?proto.de.mypowergrid.ems.controller.Notification}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.getNotification = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Notification} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Notification, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Notification|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
*/
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.setNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.controller.RpcRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.RpcRequest} returns this
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.RpcRequest.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.RpcResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  CONTROLLER: 2,
  ACSI: 3
};

/**
 * @return {proto.de.mypowergrid.ems.controller.RpcResponse.TypeCase}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.RpcResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.RpcResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.RpcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.RpcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RpcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controller: (f = msg.getController()) && proto.de.mypowergrid.ems.controller.Response.toObject(includeInstance, f),
    acsi: (f = msg.getAcsi()) && ext_ems_acsi_acsi_pb.Response.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.RpcResponse;
  return proto.de.mypowergrid.ems.controller.RpcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.RpcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.controller.Response;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Response.deserializeBinaryFromReader);
      msg.setController(value);
      break;
    case 3:
      var value = new ext_ems_acsi_acsi_pb.Response;
      reader.readMessage(value,ext_ems_acsi_acsi_pb.Response.deserializeBinaryFromReader);
      msg.setAcsi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.RpcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.RpcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RpcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getController();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.controller.Response.serializeBinaryToWriter
    );
  }
  f = message.getAcsi();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ext_ems_acsi_acsi_pb.Response.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse} returns this
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Response controller = 2;
 * @return {?proto.de.mypowergrid.ems.controller.Response}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.getController = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Response} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Response, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Response|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse} returns this
*/
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.setController = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.controller.RpcResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse} returns this
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.clearController = function() {
  return this.setController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.hasController = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional de.mypowergrid.ems.acsi.Response acsi = 3;
 * @return {?proto.de.mypowergrid.ems.acsi.Response}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.getAcsi = function() {
  return /** @type{?proto.de.mypowergrid.ems.acsi.Response} */ (
    jspb.Message.getWrapperField(this, ext_ems_acsi_acsi_pb.Response, 3));
};


/**
 * @param {?proto.de.mypowergrid.ems.acsi.Response|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse} returns this
*/
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.setAcsi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.controller.RpcResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.RpcResponse} returns this
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.clearAcsi = function() {
  return this.setAcsi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.RpcResponse.prototype.hasAcsi = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Notification}
 */
proto.de.mypowergrid.ems.controller.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Notification;
  return proto.de.mypowergrid.ems.controller.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Notification}
 */
proto.de.mypowergrid.ems.controller.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ActivationFlag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ActivationFlag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ActivationFlag}
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ActivationFlag;
  return proto.de.mypowergrid.ems.controller.ActivationFlag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ActivationFlag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ActivationFlag}
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ActivationFlag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ActivationFlag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.controller.ActivationFlag} returns this
 */
proto.de.mypowergrid.ems.controller.ActivationFlag.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.VariableStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.VariableStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore}
 */
proto.de.mypowergrid.ems.controller.VariableStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.VariableStore;
  return proto.de.mypowergrid.ems.controller.VariableStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore}
 */
proto.de.mypowergrid.ems.controller.VariableStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.VariableStore.StoreType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.VariableStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.VariableStore.StoreType = {
  UNKNOWN: 0,
  USER: 1,
  TECHNICIAN: 2,
  LIS: 3
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StoreType type = 2;
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore.StoreType}
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.VariableStore.StoreType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.VariableStore.StoreType} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStore.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.AvailableVariableStores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.AvailableVariableStores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.toObject = function(includeInstance, msg) {
  var f, obj = {
    storesList: jspb.Message.toObjectList(msg.getStoresList(),
    proto.de.mypowergrid.ems.controller.VariableStore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.AvailableVariableStores}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.AvailableVariableStores;
  return proto.de.mypowergrid.ems.controller.AvailableVariableStores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.AvailableVariableStores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.AvailableVariableStores}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.VariableStore;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.VariableStore.deserializeBinaryFromReader);
      msg.addStores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.AvailableVariableStores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.AvailableVariableStores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.VariableStore.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VariableStore stores = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.VariableStore>}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.getStoresList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.VariableStore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.VariableStore, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.VariableStore>} value
 * @return {!proto.de.mypowergrid.ems.controller.AvailableVariableStores} returns this
*/
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.setStoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.VariableStore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.VariableStore}
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.addStores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.VariableStore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.AvailableVariableStores} returns this
 */
proto.de.mypowergrid.ems.controller.AvailableVariableStores.prototype.clearStoresList = function() {
  return this.setStoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.DataForVariableStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.DataForVariableStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataJson: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.DataForVariableStore}
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.DataForVariableStore;
  return proto.de.mypowergrid.ems.controller.DataForVariableStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.DataForVariableStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.DataForVariableStore}
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.DataForVariableStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.DataForVariableStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data_json = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.prototype.getDataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.DataForVariableStore} returns this
 */
proto.de.mypowergrid.ems.controller.DataForVariableStore.prototype.setDataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SchemaForVariableStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    schemaJson: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uiSchemaJson: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SchemaForVariableStore;
  return proto.de.mypowergrid.ems.controller.SchemaForVariableStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchemaJson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUiSchemaJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SchemaForVariableStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchemaJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUiSchemaJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string schema_json = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.getSchemaJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore} returns this
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.setSchemaJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ui_schema_json = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.getUiSchemaJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.SchemaForVariableStore} returns this
 */
proto.de.mypowergrid.ems.controller.SchemaForVariableStore.prototype.setUiSchemaJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.VariableStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    variableStoreName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreRequest}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.VariableStoreRequest;
  return proto.de.mypowergrid.ems.controller.VariableStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreRequest}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariableStoreName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.VariableStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariableStoreName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string variable_store_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.getVariableStoreName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreRequest} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.setVariableStoreName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreRequest} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStoreRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    variableStoreName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataJson: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.VariableStoreSetRequest;
  return proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariableStoreName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariableStoreName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string variable_store_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.getVariableStoreName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.setVariableStoreName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_json = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.getDataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} returns this
 */
proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.prototype.setDataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Request.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,60,61,62,63]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.Request.TypeCase = {
  TYPE_NOT_SET: 0,
  ABORT: 1,
  SHUTDOWN: 2,
  PING: 3,
  TIMESERIES_REMOVE: 4,
  TIMESERIES_UPDATE: 5,
  SENSOR_LIST: 6,
  SENSOR_LIST_DATA: 7,
  SCHEDULE_LIST: 8,
  SCHEDULE_LIST_DATA: 9,
  SCHEDULE_ADD: 10,
  SCHEDULE_REMOVE: 11,
  OPERATE: 12,
  PEAK_OBSERVER_LIST: 13,
  PEAK_OBSERVER_LIST_DATA: 14,
  TIMESERIES_ADD: 15,
  TIMESERIES_LIST: 16,
  TIMESERIES_ADD_VALUES: 17,
  TIMESERIES_LIST_DATA: 18,
  PEAK_OBSERVER_SET_PEAK: 19,
  LIST_DEVICES: 20,
  LIST_NODES: 21,
  LIST_NODE_DATA: 22,
  CONFIGURE_TOPOLOGY: 23,
  GET_TOPOLOGY: 24,
  GET_QUANTITIES: 25,
  GET_TOPOLOGY_TO_PLATFORM: 26,
  SENSOR_ADD_DATA: 27,
  GET_TOPOLOGY_TO_PLATFORM_V2: 28,
  NOTIFY_ON: 29,
  NOTIFY_OFF: 30,
  SENSOR_LIST_CONFIG: 31,
  GET_STRATEGIES: 32,
  SET_STRATEGIES: 33,
  GET_STRATEGY_DECISIONS: 34,
  SET_STRATEGY_DECISIONS: 35,
  PEAK_OBSERVER_SET_INTERVAL: 36,
  REMOVE_SOURCE: 37,
  REPLACE_SOURCE: 38,
  SET_SENSOR_NOTIFY_ON_DEMAND: 39,
  EXPLAIN_DECISION: 40,
  GIT_VERSION_INFO: 41,
  NOTIFY_CLEAR: 42,
  LIST_LOG_CHANGED: 43,
  LIST_LOG: 44,
  CHANGE_LOG: 45,
  CHANGE_ALIAS: 46,
  REMOVE_ALIAS: 47,
  GET_ALIAS: 48,
  LIST_ALIASES: 49,
  CREATE_ALIAS: 50,
  GET_SETPOINT_SOURCE_PROPERTIES_COLLECTION: 51,
  GET_SUITABLE_ENERGY_SERVICES: 52,
  LIST_VARIABLE_STORES: 53,
  GET_SCHEMA_VARIABLE_STORE: 54,
  GET_DATA_VARIABLE_STORE: 55,
  SET_DATA_VARIABLE_STORE: 56,
  FINALIZE_TOPOLOGY_CHANGE: 57,
  SEND_LOG_MESSAGE: 58,
  BACKUP_STORE: 60,
  BACKUP_RESTORE: 61,
  BACKUP_REMOVE: 62,
  BACKUP_LIST: 63
};

/**
 * @return {proto.de.mypowergrid.ems.controller.Request.TypeCase}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.Request.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    abort: (f = msg.getAbort()) && proto.de.mypowergrid.ems.controller.AbortRequest.toObject(includeInstance, f),
    shutdown: (f = msg.getShutdown()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesRemove: (f = msg.getTimeseriesRemove()) && proto.de.mypowergrid.ems.controller.TimeseriesName.toObject(includeInstance, f),
    timeseriesUpdate: (f = msg.getTimeseriesUpdate()) && proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.toObject(includeInstance, f),
    sensorList: (f = msg.getSensorList()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sensorListData: (f = msg.getSensorListData()) && proto.de.mypowergrid.ems.controller.SensorListDataRequest.toObject(includeInstance, f),
    scheduleList: (f = msg.getScheduleList()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    scheduleListData: (f = msg.getScheduleListData()) && proto.de.mypowergrid.ems.controller.ScheduleId.toObject(includeInstance, f),
    scheduleAdd: (f = msg.getScheduleAdd()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.toObject(includeInstance, f),
    scheduleRemove: (f = msg.getScheduleRemove()) && proto.de.mypowergrid.ems.controller.ScheduleId.toObject(includeInstance, f),
    operate: (f = msg.getOperate()) && proto.de.mypowergrid.ems.controller.OperateRequest.toObject(includeInstance, f),
    peakObserverList: (f = msg.getPeakObserverList()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    peakObserverListData: (f = msg.getPeakObserverListData()) && proto.de.mypowergrid.ems.controller.PeakObserverName.toObject(includeInstance, f),
    timeseriesAdd: (f = msg.getTimeseriesAdd()) && proto.de.mypowergrid.ems.controller.Timeseries.toObject(includeInstance, f),
    timeseriesList: (f = msg.getTimeseriesList()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesAddValues: (f = msg.getTimeseriesAddValues()) && proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.toObject(includeInstance, f),
    timeseriesListData: (f = msg.getTimeseriesListData()) && proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.toObject(includeInstance, f),
    peakObserverSetPeak: (f = msg.getPeakObserverSetPeak()) && proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.toObject(includeInstance, f),
    listDevices: (f = msg.getListDevices()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    listNodes: (f = msg.getListNodes()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    listNodeData: (f = msg.getListNodeData()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    configureTopology: (f = msg.getConfigureTopology()) && ext_ems_topology_topology_pb.Topology.toObject(includeInstance, f),
    getTopology: (f = msg.getGetTopology()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getQuantities: (f = msg.getGetQuantities()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getTopologyToPlatform: (f = msg.getGetTopologyToPlatform()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sensorAddData: (f = msg.getSensorAddData()) && proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.toObject(includeInstance, f),
    getTopologyToPlatformV2: (f = msg.getGetTopologyToPlatformV2()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    notifyOn: (f = msg.getNotifyOn()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    notifyOff: (f = msg.getNotifyOff()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    sensorListConfig: (f = msg.getSensorListConfig()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    getStrategies: (f = msg.getGetStrategies()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    setStrategies: (f = msg.getSetStrategies()) && ext_ems_scontroller_scontroller_pb.ActuatorStrategies.toObject(includeInstance, f),
    getStrategyDecisions: (f = msg.getGetStrategyDecisions()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    setStrategyDecisions: (f = msg.getSetStrategyDecisions()) && ext_ems_scontroller_scontroller_pb.StrategyDecision.toObject(includeInstance, f),
    peakObserverSetInterval: (f = msg.getPeakObserverSetInterval()) && proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.toObject(includeInstance, f),
    removeSource: (f = msg.getRemoveSource()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f),
    replaceSource: (f = msg.getReplaceSource()) && proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.toObject(includeInstance, f),
    setSensorNotifyOnDemand: (f = msg.getSetSensorNotifyOnDemand()) && proto.de.mypowergrid.ems.controller.ActivationFlag.toObject(includeInstance, f),
    explainDecision: (f = msg.getExplainDecision()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    gitVersionInfo: (f = msg.getGitVersionInfo()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    notifyClear: (f = msg.getNotifyClear()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    listLogChanged: (f = msg.getListLogChanged()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    listLog: (f = msg.getListLog()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    changeLog: (f = msg.getChangeLog()) && proto.de.mypowergrid.ems.controller.LoggingSetting.toObject(includeInstance, f),
    changeAlias: (f = msg.getChangeAlias()) && proto.de.mypowergrid.ems.controller.ChangeAlias.toObject(includeInstance, f),
    removeAlias: (f = msg.getRemoveAlias()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    getAlias: (f = msg.getGetAlias()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    listAliases: (f = msg.getListAliases()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    createAlias: (f = msg.getCreateAlias()) && proto.de.mypowergrid.ems.controller.ChangeAlias.toObject(includeInstance, f),
    getSetpointSourcePropertiesCollection: (f = msg.getGetSetpointSourcePropertiesCollection()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getSuitableEnergyServices: (f = msg.getGetSuitableEnergyServices()) && ext_ems_scontroller_scontroller_pb.ActuatorStrategies.ActuatorGroup.toObject(includeInstance, f),
    listVariableStores: (f = msg.getListVariableStores()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getSchemaVariableStore: (f = msg.getGetSchemaVariableStore()) && proto.de.mypowergrid.ems.controller.VariableStoreRequest.toObject(includeInstance, f),
    getDataVariableStore: (f = msg.getGetDataVariableStore()) && proto.de.mypowergrid.ems.controller.VariableStoreRequest.toObject(includeInstance, f),
    setDataVariableStore: (f = msg.getSetDataVariableStore()) && proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.toObject(includeInstance, f),
    finalizeTopologyChange: (f = msg.getFinalizeTopologyChange()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sendLogMessage: (f = msg.getSendLogMessage()) && proto.de.mypowergrid.ems.controller.LogMessage.toObject(includeInstance, f),
    backupStore: (f = msg.getBackupStore()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    backupRestore: (f = msg.getBackupRestore()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    backupRemove: (f = msg.getBackupRemove()) && proto.de.mypowergrid.ems.controller.Name.toObject(includeInstance, f),
    backupList: (f = msg.getBackupList()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Request}
 */
proto.de.mypowergrid.ems.controller.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Request;
  return proto.de.mypowergrid.ems.controller.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Request}
 */
proto.de.mypowergrid.ems.controller.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.AbortRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.AbortRequest.deserializeBinaryFromReader);
      msg.setAbort(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setShutdown(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesName;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesName.deserializeBinaryFromReader);
      msg.setTimeseriesRemove(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.deserializeBinaryFromReader);
      msg.setTimeseriesUpdate(value);
      break;
    case 6:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSensorList(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.controller.SensorListDataRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorListDataRequest.deserializeBinaryFromReader);
      msg.setSensorListData(value);
      break;
    case 8:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setScheduleList(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleId;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinaryFromReader);
      msg.setScheduleListData(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.deserializeBinaryFromReader);
      msg.setScheduleAdd(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleId;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinaryFromReader);
      msg.setScheduleRemove(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.ems.controller.OperateRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.OperateRequest.deserializeBinaryFromReader);
      msg.setOperate(value);
      break;
    case 13:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPeakObserverList(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.ems.controller.PeakObserverName;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.PeakObserverName.deserializeBinaryFromReader);
      msg.setPeakObserverListData(value);
      break;
    case 15:
      var value = new proto.de.mypowergrid.ems.controller.Timeseries;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinaryFromReader);
      msg.setTimeseriesAdd(value);
      break;
    case 16:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTimeseriesList(value);
      break;
    case 17:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.deserializeBinaryFromReader);
      msg.setTimeseriesAddValues(value);
      break;
    case 18:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.deserializeBinaryFromReader);
      msg.setTimeseriesListData(value);
      break;
    case 19:
      var value = new proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.deserializeBinaryFromReader);
      msg.setPeakObserverSetPeak(value);
      break;
    case 20:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setListDevices(value);
      break;
    case 21:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setListNodes(value);
      break;
    case 22:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setListNodeData(value);
      break;
    case 23:
      var value = new ext_ems_topology_topology_pb.Topology;
      reader.readMessage(value,ext_ems_topology_topology_pb.Topology.deserializeBinaryFromReader);
      msg.setConfigureTopology(value);
      break;
    case 24:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetTopology(value);
      break;
    case 25:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetQuantities(value);
      break;
    case 26:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetTopologyToPlatform(value);
      break;
    case 27:
      var value = new proto.de.mypowergrid.ems.controller.SensorNameAndSampleList;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.deserializeBinaryFromReader);
      msg.setSensorAddData(value);
      break;
    case 28:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetTopologyToPlatformV2(value);
      break;
    case 29:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setNotifyOn(value);
      break;
    case 30:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setNotifyOff(value);
      break;
    case 31:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setSensorListConfig(value);
      break;
    case 32:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetStrategies(value);
      break;
    case 33:
      var value = new ext_ems_scontroller_scontroller_pb.ActuatorStrategies;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.ActuatorStrategies.deserializeBinaryFromReader);
      msg.setSetStrategies(value);
      break;
    case 34:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetStrategyDecisions(value);
      break;
    case 35:
      var value = new ext_ems_scontroller_scontroller_pb.StrategyDecision;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.StrategyDecision.deserializeBinaryFromReader);
      msg.setSetStrategyDecisions(value);
      break;
    case 36:
      var value = new proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.deserializeBinaryFromReader);
      msg.setPeakObserverSetInterval(value);
      break;
    case 37:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setRemoveSource(value);
      break;
    case 38:
      var value = new proto.de.mypowergrid.ems.controller.ReplaceSourceRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.deserializeBinaryFromReader);
      msg.setReplaceSource(value);
      break;
    case 39:
      var value = new proto.de.mypowergrid.ems.controller.ActivationFlag;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ActivationFlag.deserializeBinaryFromReader);
      msg.setSetSensorNotifyOnDemand(value);
      break;
    case 40:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setExplainDecision(value);
      break;
    case 41:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGitVersionInfo(value);
      break;
    case 42:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setNotifyClear(value);
      break;
    case 43:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setListLogChanged(value);
      break;
    case 44:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setListLog(value);
      break;
    case 45:
      var value = new proto.de.mypowergrid.ems.controller.LoggingSetting;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinaryFromReader);
      msg.setChangeLog(value);
      break;
    case 46:
      var value = new proto.de.mypowergrid.ems.controller.ChangeAlias;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ChangeAlias.deserializeBinaryFromReader);
      msg.setChangeAlias(value);
      break;
    case 47:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setRemoveAlias(value);
      break;
    case 48:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setGetAlias(value);
      break;
    case 49:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setListAliases(value);
      break;
    case 50:
      var value = new proto.de.mypowergrid.ems.controller.ChangeAlias;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ChangeAlias.deserializeBinaryFromReader);
      msg.setCreateAlias(value);
      break;
    case 51:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setGetSetpointSourcePropertiesCollection(value);
      break;
    case 52:
      var value = new ext_ems_scontroller_scontroller_pb.ActuatorStrategies.ActuatorGroup;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.ActuatorStrategies.ActuatorGroup.deserializeBinaryFromReader);
      msg.setGetSuitableEnergyServices(value);
      break;
    case 53:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setListVariableStores(value);
      break;
    case 54:
      var value = new proto.de.mypowergrid.ems.controller.VariableStoreRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.VariableStoreRequest.deserializeBinaryFromReader);
      msg.setGetSchemaVariableStore(value);
      break;
    case 55:
      var value = new proto.de.mypowergrid.ems.controller.VariableStoreRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.VariableStoreRequest.deserializeBinaryFromReader);
      msg.setGetDataVariableStore(value);
      break;
    case 56:
      var value = new proto.de.mypowergrid.ems.controller.VariableStoreSetRequest;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.deserializeBinaryFromReader);
      msg.setSetDataVariableStore(value);
      break;
    case 57:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFinalizeTopologyChange(value);
      break;
    case 58:
      var value = new proto.de.mypowergrid.ems.controller.LogMessage;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LogMessage.deserializeBinaryFromReader);
      msg.setSendLogMessage(value);
      break;
    case 60:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setBackupStore(value);
      break;
    case 61:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setBackupRestore(value);
      break;
    case 62:
      var value = new proto.de.mypowergrid.ems.controller.Name;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader);
      msg.setBackupRemove(value);
      break;
    case 63:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setBackupList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbort();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.AbortRequest.serializeBinaryToWriter
    );
  }
  f = message.getShutdown();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesRemove();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesName.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesUpdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.serializeBinaryToWriter
    );
  }
  f = message.getSensorList();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSensorListData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.controller.SensorListDataRequest.serializeBinaryToWriter
    );
  }
  f = message.getScheduleList();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getScheduleListData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleId.serializeBinaryToWriter
    );
  }
  f = message.getScheduleAdd();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.serializeBinaryToWriter
    );
  }
  f = message.getScheduleRemove();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleId.serializeBinaryToWriter
    );
  }
  f = message.getOperate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.ems.controller.OperateRequest.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverList();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverListData();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.ems.controller.PeakObserverName.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesAdd();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.de.mypowergrid.ems.controller.Timeseries.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesList();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesAddValues();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesListData();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverSetPeak();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.serializeBinaryToWriter
    );
  }
  f = message.getListDevices();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getListNodes();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getListNodeData();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getConfigureTopology();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      ext_ems_topology_topology_pb.Topology.serializeBinaryToWriter
    );
  }
  f = message.getGetTopology();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetQuantities();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetTopologyToPlatform();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSensorAddData();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.serializeBinaryToWriter
    );
  }
  f = message.getGetTopologyToPlatformV2();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getNotifyOn();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getNotifyOff();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getSensorListConfig();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getGetStrategies();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSetStrategies();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      ext_ems_scontroller_scontroller_pb.ActuatorStrategies.serializeBinaryToWriter
    );
  }
  f = message.getGetStrategyDecisions();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSetStrategyDecisions();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      ext_ems_scontroller_scontroller_pb.StrategyDecision.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverSetInterval();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.serializeBinaryToWriter
    );
  }
  f = message.getRemoveSource();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getReplaceSource();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetSensorNotifyOnDemand();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.de.mypowergrid.ems.controller.ActivationFlag.serializeBinaryToWriter
    );
  }
  f = message.getExplainDecision();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGitVersionInfo();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getNotifyClear();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getListLogChanged();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getListLog();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getChangeLog();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.de.mypowergrid.ems.controller.LoggingSetting.serializeBinaryToWriter
    );
  }
  f = message.getChangeAlias();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.de.mypowergrid.ems.controller.ChangeAlias.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAlias();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getGetAlias();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getListAliases();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getCreateAlias();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.de.mypowergrid.ems.controller.ChangeAlias.serializeBinaryToWriter
    );
  }
  f = message.getGetSetpointSourcePropertiesCollection();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetSuitableEnergyServices();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      ext_ems_scontroller_scontroller_pb.ActuatorStrategies.ActuatorGroup.serializeBinaryToWriter
    );
  }
  f = message.getListVariableStores();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetSchemaVariableStore();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.de.mypowergrid.ems.controller.VariableStoreRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetDataVariableStore();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.de.mypowergrid.ems.controller.VariableStoreRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetDataVariableStore();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.de.mypowergrid.ems.controller.VariableStoreSetRequest.serializeBinaryToWriter
    );
  }
  f = message.getFinalizeTopologyChange();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSendLogMessage();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.de.mypowergrid.ems.controller.LogMessage.serializeBinaryToWriter
    );
  }
  f = message.getBackupStore();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getBackupRestore();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getBackupRemove();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter
    );
  }
  f = message.getBackupList();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional AbortRequest abort = 1;
 * @return {?proto.de.mypowergrid.ems.controller.AbortRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getAbort = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.AbortRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.AbortRequest, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.AbortRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setAbort = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearAbort = function() {
  return this.setAbort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasAbort = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty shutdown = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getShutdown = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setShutdown = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearShutdown = function() {
  return this.setShutdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasShutdown = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty ping = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getPing = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setPing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearPing = function() {
  return this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasPing = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeseriesName timeseries_remove = 4;
 * @return {?proto.de.mypowergrid.ems.controller.TimeseriesName}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesRemove = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.TimeseriesName} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesName, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.TimeseriesName|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesRemove = function() {
  return this.setTimeseriesRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesRemove = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeseriesUpdateRequest timeseries_update = 5;
 * @return {?proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesUpdate = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesUpdate = function() {
  return this.setTimeseriesUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesUpdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Empty sensor_list = 6;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSensorList = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 6));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSensorList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSensorList = function() {
  return this.setSensorList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSensorList = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SensorListDataRequest sensor_list_data = 7;
 * @return {?proto.de.mypowergrid.ems.controller.SensorListDataRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSensorListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.SensorListDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.SensorListDataRequest, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.SensorListDataRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSensorListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSensorListData = function() {
  return this.setSensorListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSensorListData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Empty schedule_list = 8;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getScheduleList = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 8));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setScheduleList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearScheduleList = function() {
  return this.setScheduleList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasScheduleList = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ScheduleId schedule_list_data = 9;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleId}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getScheduleListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleId} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleId, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleId|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setScheduleListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearScheduleListData = function() {
  return this.setScheduleListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasScheduleListData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ScheduleEntry schedule_add = 10;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getScheduleAdd = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setScheduleAdd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearScheduleAdd = function() {
  return this.setScheduleAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasScheduleAdd = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ScheduleId schedule_remove = 11;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleId}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getScheduleRemove = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleId} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleId, 11));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleId|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setScheduleRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearScheduleRemove = function() {
  return this.setScheduleRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasScheduleRemove = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OperateRequest operate = 12;
 * @return {?proto.de.mypowergrid.ems.controller.OperateRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getOperate = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.OperateRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.OperateRequest, 12));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.OperateRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setOperate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearOperate = function() {
  return this.setOperate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasOperate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Empty peak_observer_list = 13;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getPeakObserverList = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 13));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setPeakObserverList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearPeakObserverList = function() {
  return this.setPeakObserverList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasPeakObserverList = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PeakObserverName peak_observer_list_data = 14;
 * @return {?proto.de.mypowergrid.ems.controller.PeakObserverName}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getPeakObserverListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.PeakObserverName} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.PeakObserverName, 14));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.PeakObserverName|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setPeakObserverListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearPeakObserverListData = function() {
  return this.setPeakObserverListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasPeakObserverListData = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Timeseries timeseries_add = 15;
 * @return {?proto.de.mypowergrid.ems.controller.Timeseries}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesAdd = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Timeseries} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Timeseries, 15));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Timeseries|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesAdd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesAdd = function() {
  return this.setTimeseriesAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesAdd = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Empty timeseries_list = 16;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesList = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 16));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesList = function() {
  return this.setTimeseriesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesList = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional TimeseriesAddValuesRequest timeseries_add_values = 17;
 * @return {?proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesAddValues = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest, 17));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesAddValues = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesAddValues = function() {
  return this.setTimeseriesAddValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesAddValues = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional TimeseriesListDataRequest timeseries_list_data = 18;
 * @return {?proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getTimeseriesListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest, 18));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setTimeseriesListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearTimeseriesListData = function() {
  return this.setTimeseriesListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasTimeseriesListData = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PeakObserverSetPeakRequest peak_observer_set_peak = 19;
 * @return {?proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getPeakObserverSetPeak = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest, 19));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setPeakObserverSetPeak = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearPeakObserverSetPeak = function() {
  return this.setPeakObserverSetPeak(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasPeakObserverSetPeak = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Empty list_devices = 20;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListDevices = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 20));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListDevices = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListDevices = function() {
  return this.setListDevices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListDevices = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Name list_nodes = 21;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListNodes = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 21));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListNodes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListNodes = function() {
  return this.setListNodes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListNodes = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Name list_node_data = 22;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListNodeData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 22));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListNodeData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListNodeData = function() {
  return this.setListNodeData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListNodeData = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Topology configure_topology = 23;
 * @return {?proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getConfigureTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Topology} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Topology, 23));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Topology|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setConfigureTopology = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearConfigureTopology = function() {
  return this.setConfigureTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasConfigureTopology = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Empty get_topology = 24;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetTopology = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 24));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetTopology = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetTopology = function() {
  return this.setGetTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetTopology = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Empty get_quantities = 25;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetQuantities = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 25));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetQuantities = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetQuantities = function() {
  return this.setGetQuantities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetQuantities = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.Empty get_topology_to_platform = 26;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetTopologyToPlatform = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 26));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetTopologyToPlatform = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetTopologyToPlatform = function() {
  return this.setGetTopologyToPlatform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetTopologyToPlatform = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional SensorNameAndSampleList sensor_add_data = 27;
 * @return {?proto.de.mypowergrid.ems.controller.SensorNameAndSampleList}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSensorAddData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.SensorNameAndSampleList, 27));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.SensorNameAndSampleList|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSensorAddData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSensorAddData = function() {
  return this.setSensorAddData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSensorAddData = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.Empty get_topology_to_platform_v2 = 28;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetTopologyToPlatformV2 = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 28));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetTopologyToPlatformV2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetTopologyToPlatformV2 = function() {
  return this.setGetTopologyToPlatformV2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetTopologyToPlatformV2 = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional Names notify_on = 29;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getNotifyOn = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 29));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setNotifyOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearNotifyOn = function() {
  return this.setNotifyOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasNotifyOn = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional Names notify_off = 30;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getNotifyOff = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 30));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setNotifyOff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearNotifyOff = function() {
  return this.setNotifyOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasNotifyOff = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional Name sensor_list_config = 31;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSensorListConfig = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 31));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSensorListConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSensorListConfig = function() {
  return this.setSensorListConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSensorListConfig = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Empty get_strategies = 32;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetStrategies = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 32));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetStrategies = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetStrategies = function() {
  return this.setGetStrategies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetStrategies = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.ActuatorStrategies set_strategies = 33;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSetStrategies = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.ActuatorStrategies, 33));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSetStrategies = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSetStrategies = function() {
  return this.setSetStrategies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSetStrategies = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.protobuf.Empty get_strategy_decisions = 34;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetStrategyDecisions = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 34));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetStrategyDecisions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetStrategyDecisions = function() {
  return this.setGetStrategyDecisions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetStrategyDecisions = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.StrategyDecision set_strategy_decisions = 35;
 * @return {?proto.de.mypowergrid.ems.scontroller.StrategyDecision}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSetStrategyDecisions = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.StrategyDecision} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.StrategyDecision, 35));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.StrategyDecision|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSetStrategyDecisions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSetStrategyDecisions = function() {
  return this.setSetStrategyDecisions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSetStrategyDecisions = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional PeakObserverSetIntervalRequest peak_observer_set_interval = 36;
 * @return {?proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getPeakObserverSetInterval = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest, 36));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setPeakObserverSetInterval = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearPeakObserverSetInterval = function() {
  return this.setPeakObserverSetInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasPeakObserverSetInterval = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Source remove_source = 37;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getRemoveSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 37));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setRemoveSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearRemoveSource = function() {
  return this.setRemoveSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasRemoveSource = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional ReplaceSourceRequest replace_source = 38;
 * @return {?proto.de.mypowergrid.ems.controller.ReplaceSourceRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getReplaceSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ReplaceSourceRequest, 38));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ReplaceSourceRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setReplaceSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearReplaceSource = function() {
  return this.setReplaceSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasReplaceSource = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional ActivationFlag set_sensor_notify_on_demand = 39;
 * @return {?proto.de.mypowergrid.ems.controller.ActivationFlag}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSetSensorNotifyOnDemand = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ActivationFlag} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ActivationFlag, 39));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ActivationFlag|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSetSensorNotifyOnDemand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSetSensorNotifyOnDemand = function() {
  return this.setSetSensorNotifyOnDemand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSetSensorNotifyOnDemand = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional google.protobuf.Empty explain_decision = 40;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getExplainDecision = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 40));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setExplainDecision = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearExplainDecision = function() {
  return this.setExplainDecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasExplainDecision = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Empty git_version_info = 41;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGitVersionInfo = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 41));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGitVersionInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGitVersionInfo = function() {
  return this.setGitVersionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGitVersionInfo = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional Names notify_clear = 42;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getNotifyClear = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 42));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setNotifyClear = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearNotifyClear = function() {
  return this.setNotifyClear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasNotifyClear = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.protobuf.Empty list_log_changed = 43;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListLogChanged = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 43));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListLogChanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListLogChanged = function() {
  return this.setListLogChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListLogChanged = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.protobuf.Empty list_log = 44;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListLog = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 44));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListLog = function() {
  return this.setListLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListLog = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional LoggingSetting change_log = 45;
 * @return {?proto.de.mypowergrid.ems.controller.LoggingSetting}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getChangeLog = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.LoggingSetting} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.LoggingSetting, 45));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.LoggingSetting|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setChangeLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearChangeLog = function() {
  return this.setChangeLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasChangeLog = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional ChangeAlias change_alias = 46;
 * @return {?proto.de.mypowergrid.ems.controller.ChangeAlias}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getChangeAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ChangeAlias} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ChangeAlias, 46));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ChangeAlias|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setChangeAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearChangeAlias = function() {
  return this.setChangeAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasChangeAlias = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional Name remove_alias = 47;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getRemoveAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 47));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setRemoveAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearRemoveAlias = function() {
  return this.setRemoveAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasRemoveAlias = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional Name get_alias = 48;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 48));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetAlias = function() {
  return this.setGetAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetAlias = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.protobuf.Empty list_aliases = 49;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListAliases = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 49));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListAliases = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListAliases = function() {
  return this.setListAliases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListAliases = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional ChangeAlias create_alias = 50;
 * @return {?proto.de.mypowergrid.ems.controller.ChangeAlias}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getCreateAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ChangeAlias} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ChangeAlias, 50));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ChangeAlias|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setCreateAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearCreateAlias = function() {
  return this.setCreateAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasCreateAlias = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional google.protobuf.Empty get_setpoint_source_properties_collection = 51;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetSetpointSourcePropertiesCollection = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 51));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetSetpointSourcePropertiesCollection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetSetpointSourcePropertiesCollection = function() {
  return this.setGetSetpointSourcePropertiesCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetSetpointSourcePropertiesCollection = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup get_suitable_energy_services = 52;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetSuitableEnergyServices = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.ActuatorStrategies.ActuatorGroup, 52));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies.ActuatorGroup|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetSuitableEnergyServices = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetSuitableEnergyServices = function() {
  return this.setGetSuitableEnergyServices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetSuitableEnergyServices = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional google.protobuf.Empty list_variable_stores = 53;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getListVariableStores = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 53));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setListVariableStores = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearListVariableStores = function() {
  return this.setListVariableStores(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasListVariableStores = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional VariableStoreRequest get_schema_variable_store = 54;
 * @return {?proto.de.mypowergrid.ems.controller.VariableStoreRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetSchemaVariableStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.VariableStoreRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.VariableStoreRequest, 54));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.VariableStoreRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetSchemaVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetSchemaVariableStore = function() {
  return this.setGetSchemaVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetSchemaVariableStore = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional VariableStoreRequest get_data_variable_store = 55;
 * @return {?proto.de.mypowergrid.ems.controller.VariableStoreRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getGetDataVariableStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.VariableStoreRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.VariableStoreRequest, 55));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.VariableStoreRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setGetDataVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearGetDataVariableStore = function() {
  return this.setGetDataVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasGetDataVariableStore = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional VariableStoreSetRequest set_data_variable_store = 56;
 * @return {?proto.de.mypowergrid.ems.controller.VariableStoreSetRequest}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSetDataVariableStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.VariableStoreSetRequest} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.VariableStoreSetRequest, 56));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.VariableStoreSetRequest|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSetDataVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSetDataVariableStore = function() {
  return this.setSetDataVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSetDataVariableStore = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional google.protobuf.Empty finalize_topology_change = 57;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getFinalizeTopologyChange = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 57));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setFinalizeTopologyChange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearFinalizeTopologyChange = function() {
  return this.setFinalizeTopologyChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasFinalizeTopologyChange = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional LogMessage send_log_message = 58;
 * @return {?proto.de.mypowergrid.ems.controller.LogMessage}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getSendLogMessage = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.LogMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.LogMessage, 58));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.LogMessage|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setSendLogMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearSendLogMessage = function() {
  return this.setSendLogMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasSendLogMessage = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional Name backup_store = 60;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getBackupStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 60));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setBackupStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 60, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearBackupStore = function() {
  return this.setBackupStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasBackupStore = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional Name backup_restore = 61;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getBackupRestore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 61));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setBackupRestore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 61, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearBackupRestore = function() {
  return this.setBackupRestore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasBackupRestore = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional Name backup_remove = 62;
 * @return {?proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getBackupRemove = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Name} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Name, 62));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Name|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setBackupRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 62, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearBackupRemove = function() {
  return this.setBackupRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasBackupRemove = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional google.protobuf.Empty backup_list = 63;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.getBackupList = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 63));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
*/
proto.de.mypowergrid.ems.controller.Request.prototype.setBackupList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.de.mypowergrid.ems.controller.Request.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Request} returns this
 */
proto.de.mypowergrid.ems.controller.Request.prototype.clearBackupList = function() {
  return this.setBackupList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Request.prototype.hasBackupList = function() {
  return jspb.Message.getField(this, 63) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Response.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,60,61,62,63]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.Response.TypeCase = {
  TYPE_NOT_SET: 0,
  ERROR: 1,
  SHUTDOWN: 2,
  PING: 3,
  TIMESERIES_REMOVE: 4,
  TIMESERIES_UPDATE: 5,
  SENSOR_LIST: 6,
  SENSOR_LIST_DATA: 7,
  SCHEDULE_LIST: 8,
  SCHEDULE_LIST_DATA: 9,
  SCHEDULE_ADD: 10,
  SCHEDULE_REMOVE: 11,
  OPERATE: 12,
  PEAK_OBSERVER_LIST: 13,
  PEAK_OBSERVER_LIST_DATA: 14,
  TIMESERIES_ADD: 15,
  TIMESERIES_LIST: 16,
  TIMESERIES_ADD_VALUES: 17,
  TIMESERIES_LIST_DATA: 18,
  PEAK_OBSERVER_SET_PEAK: 19,
  LIST_DEVICES: 20,
  LIST_NODES: 21,
  LIST_NODE_DATA: 22,
  CONFIGURE_TOPOLOGY: 23,
  GET_TOPOLOGY: 24,
  GET_QUANTITIES: 25,
  GET_TOPOLOGY_TO_PLATFORM: 26,
  SENSOR_ADD_DATA: 27,
  GET_TOPOLOGY_TO_PLATFORM_V2: 28,
  NOTIFY_ON: 29,
  NOTIFY_OFF: 30,
  SENSOR_LIST_CONFIG: 31,
  GET_STRATEGIES: 32,
  SET_STRATEGIES: 33,
  GET_STRATEGY_DECISIONS: 34,
  SET_STRATEGY_DECISIONS: 35,
  PEAK_OBSERVER_SET_INTERVAL: 36,
  REMOVE_SOURCE: 37,
  REPLACE_SOURCE: 38,
  SET_SENSOR_NOTIFY_ON_DEMAND: 39,
  EXPLAIN_DECISION: 40,
  GIT_VERSION_INFO: 41,
  NOTIFY_CLEAR: 42,
  LIST_LOG_CHANGED: 43,
  LIST_LOG: 44,
  CHANGE_LOG: 45,
  CHANGE_ALIAS: 46,
  REMOVE_ALIAS: 47,
  GET_ALIAS: 48,
  LIST_ALIASES: 49,
  CREATE_ALIAS: 50,
  GET_SETPOINT_SOURCE_PROPERTIES_COLLECTION: 51,
  GET_SUITABLE_ENERGY_SERVICES: 52,
  LIST_VARIABLE_STORES: 53,
  GET_SCHEMA_VARIABLE_STORE: 54,
  GET_DATA_VARIABLE_STORE: 55,
  SET_DATA_VARIABLE_STORE: 56,
  FINALIZE_TOPOLOGY_CHANGE: 57,
  SEND_LOG_MESSAGE: 58,
  BACKUP_STORE: 60,
  BACKUP_RESTORE: 61,
  BACKUP_REMOVE: 62,
  BACKUP_LIST: 63
};

/**
 * @return {proto.de.mypowergrid.ems.controller.Response.TypeCase}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.Response.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.ems.controller.Error.toObject(includeInstance, f),
    shutdown: (f = msg.getShutdown()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesRemove: (f = msg.getTimeseriesRemove()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesUpdate: (f = msg.getTimeseriesUpdate()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sensorList: (f = msg.getSensorList()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    sensorListData: (f = msg.getSensorListData()) && proto.de.mypowergrid.ems.controller.SensorSampleList.toObject(includeInstance, f),
    scheduleList: (f = msg.getScheduleList()) && proto.de.mypowergrid.ems.controller.ScheduleIdList.toObject(includeInstance, f),
    scheduleListData: (f = msg.getScheduleListData()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.toObject(includeInstance, f),
    scheduleAdd: (f = msg.getScheduleAdd()) && proto.de.mypowergrid.ems.controller.ScheduleId.toObject(includeInstance, f),
    scheduleRemove: (f = msg.getScheduleRemove()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    operate: (f = msg.getOperate()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    peakObserverList: (f = msg.getPeakObserverList()) && proto.de.mypowergrid.ems.controller.PeakObserverNameList.toObject(includeInstance, f),
    peakObserverListData: (f = msg.getPeakObserverListData()) && proto.de.mypowergrid.ems.controller.PeakObserverState.toObject(includeInstance, f),
    timeseriesAdd: (f = msg.getTimeseriesAdd()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesList: (f = msg.getTimeseriesList()) && proto.de.mypowergrid.ems.controller.Timeserieses.toObject(includeInstance, f),
    timeseriesAddValues: (f = msg.getTimeseriesAddValues()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    timeseriesListData: (f = msg.getTimeseriesListData()) && proto.de.mypowergrid.ems.controller.TimestampValues.toObject(includeInstance, f),
    peakObserverSetPeak: (f = msg.getPeakObserverSetPeak()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    listDevices: (f = msg.getListDevices()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    listNodes: (f = msg.getListNodes()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    listNodeData: (f = msg.getListNodeData()) && proto.de.mypowergrid.ems.controller.NameValues.toObject(includeInstance, f),
    configureTopology: (f = msg.getConfigureTopology()) && ext_ems_topology_topology_pb.Error.toObject(includeInstance, f),
    getTopology: (f = msg.getGetTopology()) && ext_ems_topology_topology_pb.Topology.toObject(includeInstance, f),
    getQuantities: (f = msg.getGetQuantities()) && ext_ems_topology_topology_pb.SourceQuantitiesCollection.toObject(includeInstance, f),
    getTopologyToPlatform: (f = msg.getGetTopologyToPlatform()) && ext_ems_topology_topology_pb.TopologyToPlatform.toObject(includeInstance, f),
    sensorAddData: (f = msg.getSensorAddData()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getTopologyToPlatformV2: (f = msg.getGetTopologyToPlatformV2()) && ext_ems_topology_topology_pb.TopologyToPlatformV2.toObject(includeInstance, f),
    notifyOn: (f = msg.getNotifyOn()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    notifyOff: (f = msg.getNotifyOff()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sensorListConfig: (f = msg.getSensorListConfig()) && ext_ems_config_config_pb.SensorInfo.toObject(includeInstance, f),
    getStrategies: (f = msg.getGetStrategies()) && ext_ems_scontroller_scontroller_pb.ActuatorStrategies.toObject(includeInstance, f),
    setStrategies: (f = msg.getSetStrategies()) && ext_ems_scontroller_scontroller_pb.SetActuatorStrategiesResult.toObject(includeInstance, f),
    getStrategyDecisions: (f = msg.getGetStrategyDecisions()) && ext_ems_scontroller_scontroller_pb.StrategyDecision.toObject(includeInstance, f),
    setStrategyDecisions: (f = msg.getSetStrategyDecisions()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    peakObserverSetInterval: (f = msg.getPeakObserverSetInterval()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    removeSource: (f = msg.getRemoveSource()) && proto.de.mypowergrid.ems.controller.RemoveSourceReply.toObject(includeInstance, f),
    replaceSource: (f = msg.getReplaceSource()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    setSensorNotifyOnDemand: (f = msg.getSetSensorNotifyOnDemand()) && proto.de.mypowergrid.ems.controller.ActivationFlag.toObject(includeInstance, f),
    explainDecision: (f = msg.getExplainDecision()) && ext_ems_scontroller_scontroller_pb.DecisionExplaination.toObject(includeInstance, f),
    gitVersionInfo: (f = msg.getGitVersionInfo()) && proto.de.mypowergrid.ems.controller.VersionByGit.toObject(includeInstance, f),
    notifyClear: (f = msg.getNotifyClear()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    listLogChanged: (f = msg.getListLogChanged()) && proto.de.mypowergrid.ems.controller.LoggingSetting.toObject(includeInstance, f),
    listLog: (f = msg.getListLog()) && proto.de.mypowergrid.ems.controller.LoggingSetting.toObject(includeInstance, f),
    changeLog: (f = msg.getChangeLog()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    changeAlias: (f = msg.getChangeAlias()) && proto.de.mypowergrid.ems.controller.AliasResult.toObject(includeInstance, f),
    removeAlias: (f = msg.getRemoveAlias()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    getAlias: (f = msg.getGetAlias()) && ext_ems_eval_pb.EvalExpression.toObject(includeInstance, f),
    listAliases: (f = msg.getListAliases()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f),
    createAlias: (f = msg.getCreateAlias()) && proto.de.mypowergrid.ems.controller.AliasResult.toObject(includeInstance, f),
    getSetpointSourcePropertiesCollection: (f = msg.getGetSetpointSourcePropertiesCollection()) && ext_ems_scontroller_scontroller_pb.SetpointSourcePropertiesCollection.toObject(includeInstance, f),
    getSuitableEnergyServices: (f = msg.getGetSuitableEnergyServices()) && ext_ems_scontroller_scontroller_pb.EnergyServices.toObject(includeInstance, f),
    listVariableStores: (f = msg.getListVariableStores()) && proto.de.mypowergrid.ems.controller.AvailableVariableStores.toObject(includeInstance, f),
    getSchemaVariableStore: (f = msg.getGetSchemaVariableStore()) && proto.de.mypowergrid.ems.controller.SchemaForVariableStore.toObject(includeInstance, f),
    getDataVariableStore: (f = msg.getGetDataVariableStore()) && proto.de.mypowergrid.ems.controller.DataForVariableStore.toObject(includeInstance, f),
    setDataVariableStore: (f = msg.getSetDataVariableStore()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    finalizeTopologyChange: (f = msg.getFinalizeTopologyChange()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    sendLogMessage: (f = msg.getSendLogMessage()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    backupStore: jspb.Message.getFieldWithDefault(msg, 60, 0),
    backupRestore: jspb.Message.getFieldWithDefault(msg, 61, 0),
    backupRemove: jspb.Message.getFieldWithDefault(msg, 62, 0),
    backupList: (f = msg.getBackupList()) && proto.de.mypowergrid.ems.controller.Names.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Response}
 */
proto.de.mypowergrid.ems.controller.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Response;
  return proto.de.mypowergrid.ems.controller.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Response}
 */
proto.de.mypowergrid.ems.controller.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.Error;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setShutdown(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    case 4:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTimeseriesRemove(value);
      break;
    case 5:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTimeseriesUpdate(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setSensorList(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.controller.SensorSampleList;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorSampleList.deserializeBinaryFromReader);
      msg.setSensorListData(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleIdList;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleIdList.deserializeBinaryFromReader);
      msg.setScheduleList(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.deserializeBinaryFromReader);
      msg.setScheduleListData(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleId;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinaryFromReader);
      msg.setScheduleAdd(value);
      break;
    case 11:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setScheduleRemove(value);
      break;
    case 12:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setOperate(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.ems.controller.PeakObserverNameList;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.PeakObserverNameList.deserializeBinaryFromReader);
      msg.setPeakObserverList(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.ems.controller.PeakObserverState;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.PeakObserverState.deserializeBinaryFromReader);
      msg.setPeakObserverListData(value);
      break;
    case 15:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTimeseriesAdd(value);
      break;
    case 16:
      var value = new proto.de.mypowergrid.ems.controller.Timeserieses;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Timeserieses.deserializeBinaryFromReader);
      msg.setTimeseriesList(value);
      break;
    case 17:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setTimeseriesAddValues(value);
      break;
    case 18:
      var value = new proto.de.mypowergrid.ems.controller.TimestampValues;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimestampValues.deserializeBinaryFromReader);
      msg.setTimeseriesListData(value);
      break;
    case 19:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPeakObserverSetPeak(value);
      break;
    case 20:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setListDevices(value);
      break;
    case 21:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setListNodes(value);
      break;
    case 22:
      var value = new proto.de.mypowergrid.ems.controller.NameValues;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.NameValues.deserializeBinaryFromReader);
      msg.setListNodeData(value);
      break;
    case 23:
      var value = new ext_ems_topology_topology_pb.Error;
      reader.readMessage(value,ext_ems_topology_topology_pb.Error.deserializeBinaryFromReader);
      msg.setConfigureTopology(value);
      break;
    case 24:
      var value = new ext_ems_topology_topology_pb.Topology;
      reader.readMessage(value,ext_ems_topology_topology_pb.Topology.deserializeBinaryFromReader);
      msg.setGetTopology(value);
      break;
    case 25:
      var value = new ext_ems_topology_topology_pb.SourceQuantitiesCollection;
      reader.readMessage(value,ext_ems_topology_topology_pb.SourceQuantitiesCollection.deserializeBinaryFromReader);
      msg.setGetQuantities(value);
      break;
    case 26:
      var value = new ext_ems_topology_topology_pb.TopologyToPlatform;
      reader.readMessage(value,ext_ems_topology_topology_pb.TopologyToPlatform.deserializeBinaryFromReader);
      msg.setGetTopologyToPlatform(value);
      break;
    case 27:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSensorAddData(value);
      break;
    case 28:
      var value = new ext_ems_topology_topology_pb.TopologyToPlatformV2;
      reader.readMessage(value,ext_ems_topology_topology_pb.TopologyToPlatformV2.deserializeBinaryFromReader);
      msg.setGetTopologyToPlatformV2(value);
      break;
    case 29:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setNotifyOn(value);
      break;
    case 30:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setNotifyOff(value);
      break;
    case 31:
      var value = new ext_ems_config_config_pb.SensorInfo;
      reader.readMessage(value,ext_ems_config_config_pb.SensorInfo.deserializeBinaryFromReader);
      msg.setSensorListConfig(value);
      break;
    case 32:
      var value = new ext_ems_scontroller_scontroller_pb.ActuatorStrategies;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.ActuatorStrategies.deserializeBinaryFromReader);
      msg.setGetStrategies(value);
      break;
    case 33:
      var value = new ext_ems_scontroller_scontroller_pb.SetActuatorStrategiesResult;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.SetActuatorStrategiesResult.deserializeBinaryFromReader);
      msg.setSetStrategies(value);
      break;
    case 34:
      var value = new ext_ems_scontroller_scontroller_pb.StrategyDecision;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.StrategyDecision.deserializeBinaryFromReader);
      msg.setGetStrategyDecisions(value);
      break;
    case 35:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSetStrategyDecisions(value);
      break;
    case 36:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPeakObserverSetInterval(value);
      break;
    case 37:
      var value = new proto.de.mypowergrid.ems.controller.RemoveSourceReply;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.RemoveSourceReply.deserializeBinaryFromReader);
      msg.setRemoveSource(value);
      break;
    case 38:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setReplaceSource(value);
      break;
    case 39:
      var value = new proto.de.mypowergrid.ems.controller.ActivationFlag;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ActivationFlag.deserializeBinaryFromReader);
      msg.setSetSensorNotifyOnDemand(value);
      break;
    case 40:
      var value = new ext_ems_scontroller_scontroller_pb.DecisionExplaination;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.DecisionExplaination.deserializeBinaryFromReader);
      msg.setExplainDecision(value);
      break;
    case 41:
      var value = new proto.de.mypowergrid.ems.controller.VersionByGit;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.VersionByGit.deserializeBinaryFromReader);
      msg.setGitVersionInfo(value);
      break;
    case 42:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setNotifyClear(value);
      break;
    case 43:
      var value = new proto.de.mypowergrid.ems.controller.LoggingSetting;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinaryFromReader);
      msg.setListLogChanged(value);
      break;
    case 44:
      var value = new proto.de.mypowergrid.ems.controller.LoggingSetting;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.LoggingSetting.deserializeBinaryFromReader);
      msg.setListLog(value);
      break;
    case 45:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setChangeLog(value);
      break;
    case 46:
      var value = new proto.de.mypowergrid.ems.controller.AliasResult;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.AliasResult.deserializeBinaryFromReader);
      msg.setChangeAlias(value);
      break;
    case 47:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setRemoveAlias(value);
      break;
    case 48:
      var value = new ext_ems_eval_pb.EvalExpression;
      reader.readMessage(value,ext_ems_eval_pb.EvalExpression.deserializeBinaryFromReader);
      msg.setGetAlias(value);
      break;
    case 49:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setListAliases(value);
      break;
    case 50:
      var value = new proto.de.mypowergrid.ems.controller.AliasResult;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.AliasResult.deserializeBinaryFromReader);
      msg.setCreateAlias(value);
      break;
    case 51:
      var value = new ext_ems_scontroller_scontroller_pb.SetpointSourcePropertiesCollection;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.SetpointSourcePropertiesCollection.deserializeBinaryFromReader);
      msg.setGetSetpointSourcePropertiesCollection(value);
      break;
    case 52:
      var value = new ext_ems_scontroller_scontroller_pb.EnergyServices;
      reader.readMessage(value,ext_ems_scontroller_scontroller_pb.EnergyServices.deserializeBinaryFromReader);
      msg.setGetSuitableEnergyServices(value);
      break;
    case 53:
      var value = new proto.de.mypowergrid.ems.controller.AvailableVariableStores;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.AvailableVariableStores.deserializeBinaryFromReader);
      msg.setListVariableStores(value);
      break;
    case 54:
      var value = new proto.de.mypowergrid.ems.controller.SchemaForVariableStore;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SchemaForVariableStore.deserializeBinaryFromReader);
      msg.setGetSchemaVariableStore(value);
      break;
    case 55:
      var value = new proto.de.mypowergrid.ems.controller.DataForVariableStore;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.DataForVariableStore.deserializeBinaryFromReader);
      msg.setGetDataVariableStore(value);
      break;
    case 56:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSetDataVariableStore(value);
      break;
    case 57:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setFinalizeTopologyChange(value);
      break;
    case 58:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setSendLogMessage(value);
      break;
    case 60:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (reader.readEnum());
      msg.setBackupStore(value);
      break;
    case 61:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (reader.readEnum());
      msg.setBackupRestore(value);
      break;
    case 62:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (reader.readEnum());
      msg.setBackupRemove(value);
      break;
    case 63:
      var value = new proto.de.mypowergrid.ems.controller.Names;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader);
      msg.setBackupList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.Error.serializeBinaryToWriter
    );
  }
  f = message.getShutdown();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesRemove();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesUpdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSensorList();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getSensorListData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.controller.SensorSampleList.serializeBinaryToWriter
    );
  }
  f = message.getScheduleList();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleIdList.serializeBinaryToWriter
    );
  }
  f = message.getScheduleListData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.serializeBinaryToWriter
    );
  }
  f = message.getScheduleAdd();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleId.serializeBinaryToWriter
    );
  }
  f = message.getScheduleRemove();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getOperate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverList();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.ems.controller.PeakObserverNameList.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverListData();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.ems.controller.PeakObserverState.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesAdd();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesList();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.de.mypowergrid.ems.controller.Timeserieses.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesAddValues();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getTimeseriesListData();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.de.mypowergrid.ems.controller.TimestampValues.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverSetPeak();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getListDevices();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getListNodes();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getListNodeData();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.de.mypowergrid.ems.controller.NameValues.serializeBinaryToWriter
    );
  }
  f = message.getConfigureTopology();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      ext_ems_topology_topology_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getGetTopology();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      ext_ems_topology_topology_pb.Topology.serializeBinaryToWriter
    );
  }
  f = message.getGetQuantities();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      ext_ems_topology_topology_pb.SourceQuantitiesCollection.serializeBinaryToWriter
    );
  }
  f = message.getGetTopologyToPlatform();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      ext_ems_topology_topology_pb.TopologyToPlatform.serializeBinaryToWriter
    );
  }
  f = message.getSensorAddData();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetTopologyToPlatformV2();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      ext_ems_topology_topology_pb.TopologyToPlatformV2.serializeBinaryToWriter
    );
  }
  f = message.getNotifyOn();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getNotifyOff();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSensorListConfig();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      ext_ems_config_config_pb.SensorInfo.serializeBinaryToWriter
    );
  }
  f = message.getGetStrategies();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      ext_ems_scontroller_scontroller_pb.ActuatorStrategies.serializeBinaryToWriter
    );
  }
  f = message.getSetStrategies();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      ext_ems_scontroller_scontroller_pb.SetActuatorStrategiesResult.serializeBinaryToWriter
    );
  }
  f = message.getGetStrategyDecisions();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      ext_ems_scontroller_scontroller_pb.StrategyDecision.serializeBinaryToWriter
    );
  }
  f = message.getSetStrategyDecisions();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getPeakObserverSetInterval();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getRemoveSource();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.de.mypowergrid.ems.controller.RemoveSourceReply.serializeBinaryToWriter
    );
  }
  f = message.getReplaceSource();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSetSensorNotifyOnDemand();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.de.mypowergrid.ems.controller.ActivationFlag.serializeBinaryToWriter
    );
  }
  f = message.getExplainDecision();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      ext_ems_scontroller_scontroller_pb.DecisionExplaination.serializeBinaryToWriter
    );
  }
  f = message.getGitVersionInfo();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.de.mypowergrid.ems.controller.VersionByGit.serializeBinaryToWriter
    );
  }
  f = message.getNotifyClear();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getListLogChanged();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.de.mypowergrid.ems.controller.LoggingSetting.serializeBinaryToWriter
    );
  }
  f = message.getListLog();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.de.mypowergrid.ems.controller.LoggingSetting.serializeBinaryToWriter
    );
  }
  f = message.getChangeLog();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getChangeAlias();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.de.mypowergrid.ems.controller.AliasResult.serializeBinaryToWriter
    );
  }
  f = message.getRemoveAlias();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getGetAlias();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      ext_ems_eval_pb.EvalExpression.serializeBinaryToWriter
    );
  }
  f = message.getListAliases();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
  f = message.getCreateAlias();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.de.mypowergrid.ems.controller.AliasResult.serializeBinaryToWriter
    );
  }
  f = message.getGetSetpointSourcePropertiesCollection();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      ext_ems_scontroller_scontroller_pb.SetpointSourcePropertiesCollection.serializeBinaryToWriter
    );
  }
  f = message.getGetSuitableEnergyServices();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      ext_ems_scontroller_scontroller_pb.EnergyServices.serializeBinaryToWriter
    );
  }
  f = message.getListVariableStores();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.de.mypowergrid.ems.controller.AvailableVariableStores.serializeBinaryToWriter
    );
  }
  f = message.getGetSchemaVariableStore();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.de.mypowergrid.ems.controller.SchemaForVariableStore.serializeBinaryToWriter
    );
  }
  f = message.getGetDataVariableStore();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.de.mypowergrid.ems.controller.DataForVariableStore.serializeBinaryToWriter
    );
  }
  f = message.getSetDataVariableStore();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getFinalizeTopologyChange();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getSendLogMessage();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getField(message, 60));
  if (f != null) {
    writer.writeEnum(
      60,
      f
    );
  }
  f = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getField(message, 61));
  if (f != null) {
    writer.writeEnum(
      61,
      f
    );
  }
  f = /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getField(message, 62));
  if (f != null) {
    writer.writeEnum(
      62,
      f
    );
  }
  f = message.getBackupList();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.ems.controller.Error}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Error|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty shutdown = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getShutdown = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setShutdown = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearShutdown = function() {
  return this.setShutdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasShutdown = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty ping = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getPing = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setPing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearPing = function() {
  return this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasPing = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Empty timeseries_remove = 4;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesRemove = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 4));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesRemove = function() {
  return this.setTimeseriesRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesRemove = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Empty timeseries_update = 5;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesUpdate = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 5));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesUpdate = function() {
  return this.setTimeseriesUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesUpdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Names sensor_list = 6;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSensorList = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSensorList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSensorList = function() {
  return this.setSensorList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSensorList = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SensorSampleList sensor_list_data = 7;
 * @return {?proto.de.mypowergrid.ems.controller.SensorSampleList}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSensorListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.SensorSampleList} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.SensorSampleList, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.SensorSampleList|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSensorListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSensorListData = function() {
  return this.setSensorListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSensorListData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ScheduleIdList schedule_list = 8;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleIdList}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getScheduleList = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleIdList} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleIdList, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleIdList|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setScheduleList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearScheduleList = function() {
  return this.setScheduleList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasScheduleList = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ScheduleEntry schedule_list_data = 9;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getScheduleListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setScheduleListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearScheduleListData = function() {
  return this.setScheduleListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasScheduleListData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ScheduleId schedule_add = 10;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleId}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getScheduleAdd = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleId} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleId, 10));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleId|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setScheduleAdd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearScheduleAdd = function() {
  return this.setScheduleAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasScheduleAdd = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Empty schedule_remove = 11;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getScheduleRemove = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 11));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setScheduleRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearScheduleRemove = function() {
  return this.setScheduleRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasScheduleRemove = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Empty operate = 12;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getOperate = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 12));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setOperate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearOperate = function() {
  return this.setOperate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasOperate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PeakObserverNameList peak_observer_list = 13;
 * @return {?proto.de.mypowergrid.ems.controller.PeakObserverNameList}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getPeakObserverList = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.PeakObserverNameList} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.PeakObserverNameList, 13));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.PeakObserverNameList|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setPeakObserverList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearPeakObserverList = function() {
  return this.setPeakObserverList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasPeakObserverList = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PeakObserverState peak_observer_list_data = 14;
 * @return {?proto.de.mypowergrid.ems.controller.PeakObserverState}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getPeakObserverListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.PeakObserverState} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.PeakObserverState, 14));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.PeakObserverState|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setPeakObserverListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearPeakObserverListData = function() {
  return this.setPeakObserverListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasPeakObserverListData = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Empty timeseries_add = 15;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesAdd = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 15));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesAdd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesAdd = function() {
  return this.setTimeseriesAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesAdd = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Timeserieses timeseries_list = 16;
 * @return {?proto.de.mypowergrid.ems.controller.Timeserieses}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesList = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Timeserieses} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Timeserieses, 16));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Timeserieses|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesList = function() {
  return this.setTimeseriesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesList = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Empty timeseries_add_values = 17;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesAddValues = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 17));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesAddValues = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesAddValues = function() {
  return this.setTimeseriesAddValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesAddValues = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional TimestampValues timeseries_list_data = 18;
 * @return {?proto.de.mypowergrid.ems.controller.TimestampValues}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getTimeseriesListData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.TimestampValues} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.TimestampValues, 18));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.TimestampValues|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setTimeseriesListData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearTimeseriesListData = function() {
  return this.setTimeseriesListData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasTimeseriesListData = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Empty peak_observer_set_peak = 19;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getPeakObserverSetPeak = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 19));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setPeakObserverSetPeak = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearPeakObserverSetPeak = function() {
  return this.setPeakObserverSetPeak(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasPeakObserverSetPeak = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Names list_devices = 20;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListDevices = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 20));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListDevices = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListDevices = function() {
  return this.setListDevices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListDevices = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Names list_nodes = 21;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListNodes = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 21));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListNodes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListNodes = function() {
  return this.setListNodes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListNodes = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional NameValues list_node_data = 22;
 * @return {?proto.de.mypowergrid.ems.controller.NameValues}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListNodeData = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.NameValues} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.NameValues, 22));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.NameValues|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListNodeData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListNodeData = function() {
  return this.setListNodeData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListNodeData = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Error configure_topology = 23;
 * @return {?proto.de.mypowergrid.ems.topology.Error}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getConfigureTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Error} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Error, 23));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Error|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setConfigureTopology = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearConfigureTopology = function() {
  return this.setConfigureTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasConfigureTopology = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Topology get_topology = 24;
 * @return {?proto.de.mypowergrid.ems.topology.Topology}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetTopology = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Topology} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Topology, 24));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Topology|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetTopology = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetTopology = function() {
  return this.setGetTopology(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetTopology = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional de.mypowergrid.ems.topology.SourceQuantitiesCollection get_quantities = 25;
 * @return {?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetQuantities = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.SourceQuantitiesCollection, 25));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.SourceQuantitiesCollection|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetQuantities = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetQuantities = function() {
  return this.setGetQuantities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetQuantities = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional de.mypowergrid.ems.topology.TopologyToPlatform get_topology_to_platform = 26;
 * @return {?proto.de.mypowergrid.ems.topology.TopologyToPlatform}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetTopologyToPlatform = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.TopologyToPlatform} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.TopologyToPlatform, 26));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.TopologyToPlatform|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetTopologyToPlatform = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetTopologyToPlatform = function() {
  return this.setGetTopologyToPlatform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetTopologyToPlatform = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.Empty sensor_add_data = 27;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSensorAddData = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 27));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSensorAddData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSensorAddData = function() {
  return this.setSensorAddData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSensorAddData = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional de.mypowergrid.ems.topology.TopologyToPlatformV2 get_topology_to_platform_v2 = 28;
 * @return {?proto.de.mypowergrid.ems.topology.TopologyToPlatformV2}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetTopologyToPlatformV2 = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.TopologyToPlatformV2} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.TopologyToPlatformV2, 28));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.TopologyToPlatformV2|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetTopologyToPlatformV2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetTopologyToPlatformV2 = function() {
  return this.setGetTopologyToPlatformV2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetTopologyToPlatformV2 = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.Empty notify_on = 29;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getNotifyOn = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 29));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setNotifyOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearNotifyOn = function() {
  return this.setNotifyOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasNotifyOn = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Empty notify_off = 30;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getNotifyOff = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 30));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setNotifyOff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearNotifyOff = function() {
  return this.setNotifyOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasNotifyOff = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional de.mypowergrid.ems.config.SensorInfo sensor_list_config = 31;
 * @return {?proto.de.mypowergrid.ems.config.SensorInfo}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSensorListConfig = function() {
  return /** @type{?proto.de.mypowergrid.ems.config.SensorInfo} */ (
    jspb.Message.getWrapperField(this, ext_ems_config_config_pb.SensorInfo, 31));
};


/**
 * @param {?proto.de.mypowergrid.ems.config.SensorInfo|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSensorListConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSensorListConfig = function() {
  return this.setSensorListConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSensorListConfig = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.ActuatorStrategies get_strategies = 32;
 * @return {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetStrategies = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.ActuatorStrategies, 32));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.ActuatorStrategies|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetStrategies = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetStrategies = function() {
  return this.setGetStrategies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetStrategies = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult set_strategies = 33;
 * @return {?proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSetStrategies = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.SetActuatorStrategiesResult, 33));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.SetActuatorStrategiesResult|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSetStrategies = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSetStrategies = function() {
  return this.setSetStrategies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSetStrategies = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.StrategyDecision get_strategy_decisions = 34;
 * @return {?proto.de.mypowergrid.ems.scontroller.StrategyDecision}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetStrategyDecisions = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.StrategyDecision} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.StrategyDecision, 34));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.StrategyDecision|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetStrategyDecisions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetStrategyDecisions = function() {
  return this.setGetStrategyDecisions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetStrategyDecisions = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Empty set_strategy_decisions = 35;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSetStrategyDecisions = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 35));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSetStrategyDecisions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSetStrategyDecisions = function() {
  return this.setSetStrategyDecisions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSetStrategyDecisions = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.protobuf.Empty peak_observer_set_interval = 36;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getPeakObserverSetInterval = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 36));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setPeakObserverSetInterval = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearPeakObserverSetInterval = function() {
  return this.setPeakObserverSetInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasPeakObserverSetInterval = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional RemoveSourceReply remove_source = 37;
 * @return {?proto.de.mypowergrid.ems.controller.RemoveSourceReply}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getRemoveSource = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.RemoveSourceReply} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.RemoveSourceReply, 37));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.RemoveSourceReply|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setRemoveSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearRemoveSource = function() {
  return this.setRemoveSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasRemoveSource = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.protobuf.Empty replace_source = 38;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getReplaceSource = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 38));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setReplaceSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearReplaceSource = function() {
  return this.setReplaceSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasReplaceSource = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional ActivationFlag set_sensor_notify_on_demand = 39;
 * @return {?proto.de.mypowergrid.ems.controller.ActivationFlag}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSetSensorNotifyOnDemand = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ActivationFlag} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ActivationFlag, 39));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ActivationFlag|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSetSensorNotifyOnDemand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSetSensorNotifyOnDemand = function() {
  return this.setSetSensorNotifyOnDemand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSetSensorNotifyOnDemand = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.DecisionExplaination explain_decision = 40;
 * @return {?proto.de.mypowergrid.ems.scontroller.DecisionExplaination}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getExplainDecision = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.DecisionExplaination} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.DecisionExplaination, 40));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.DecisionExplaination|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setExplainDecision = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearExplainDecision = function() {
  return this.setExplainDecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasExplainDecision = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional VersionByGit git_version_info = 41;
 * @return {?proto.de.mypowergrid.ems.controller.VersionByGit}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGitVersionInfo = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.VersionByGit} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.VersionByGit, 41));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.VersionByGit|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGitVersionInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGitVersionInfo = function() {
  return this.setGitVersionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGitVersionInfo = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.Empty notify_clear = 42;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getNotifyClear = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 42));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setNotifyClear = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearNotifyClear = function() {
  return this.setNotifyClear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasNotifyClear = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional LoggingSetting list_log_changed = 43;
 * @return {?proto.de.mypowergrid.ems.controller.LoggingSetting}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListLogChanged = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.LoggingSetting} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.LoggingSetting, 43));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.LoggingSetting|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListLogChanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListLogChanged = function() {
  return this.setListLogChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListLogChanged = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional LoggingSetting list_log = 44;
 * @return {?proto.de.mypowergrid.ems.controller.LoggingSetting}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListLog = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.LoggingSetting} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.LoggingSetting, 44));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.LoggingSetting|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListLog = function() {
  return this.setListLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListLog = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional google.protobuf.Empty change_log = 45;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getChangeLog = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 45));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setChangeLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearChangeLog = function() {
  return this.setChangeLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasChangeLog = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional AliasResult change_alias = 46;
 * @return {?proto.de.mypowergrid.ems.controller.AliasResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getChangeAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.AliasResult} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.AliasResult, 46));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.AliasResult|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setChangeAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearChangeAlias = function() {
  return this.setChangeAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasChangeAlias = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.protobuf.Empty remove_alias = 47;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getRemoveAlias = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 47));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setRemoveAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearRemoveAlias = function() {
  return this.setRemoveAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasRemoveAlias = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional de.mypowergrid.ems.EvalExpression get_alias = 48;
 * @return {?proto.de.mypowergrid.ems.EvalExpression}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.EvalExpression} */ (
    jspb.Message.getWrapperField(this, ext_ems_eval_pb.EvalExpression, 48));
};


/**
 * @param {?proto.de.mypowergrid.ems.EvalExpression|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetAlias = function() {
  return this.setGetAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetAlias = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional Names list_aliases = 49;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListAliases = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 49));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListAliases = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListAliases = function() {
  return this.setListAliases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListAliases = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional AliasResult create_alias = 50;
 * @return {?proto.de.mypowergrid.ems.controller.AliasResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getCreateAlias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.AliasResult} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.AliasResult, 50));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.AliasResult|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setCreateAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearCreateAlias = function() {
  return this.setCreateAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasCreateAlias = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection get_setpoint_source_properties_collection = 51;
 * @return {?proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetSetpointSourcePropertiesCollection = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.SetpointSourcePropertiesCollection, 51));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.SetpointSourcePropertiesCollection|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetSetpointSourcePropertiesCollection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetSetpointSourcePropertiesCollection = function() {
  return this.setGetSetpointSourcePropertiesCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetSetpointSourcePropertiesCollection = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional de.mypowergrid.ems.scontroller.EnergyServices get_suitable_energy_services = 52;
 * @return {?proto.de.mypowergrid.ems.scontroller.EnergyServices}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetSuitableEnergyServices = function() {
  return /** @type{?proto.de.mypowergrid.ems.scontroller.EnergyServices} */ (
    jspb.Message.getWrapperField(this, ext_ems_scontroller_scontroller_pb.EnergyServices, 52));
};


/**
 * @param {?proto.de.mypowergrid.ems.scontroller.EnergyServices|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetSuitableEnergyServices = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetSuitableEnergyServices = function() {
  return this.setGetSuitableEnergyServices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetSuitableEnergyServices = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional AvailableVariableStores list_variable_stores = 53;
 * @return {?proto.de.mypowergrid.ems.controller.AvailableVariableStores}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getListVariableStores = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.AvailableVariableStores} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.AvailableVariableStores, 53));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.AvailableVariableStores|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setListVariableStores = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearListVariableStores = function() {
  return this.setListVariableStores(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasListVariableStores = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional SchemaForVariableStore get_schema_variable_store = 54;
 * @return {?proto.de.mypowergrid.ems.controller.SchemaForVariableStore}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetSchemaVariableStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.SchemaForVariableStore} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.SchemaForVariableStore, 54));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.SchemaForVariableStore|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetSchemaVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetSchemaVariableStore = function() {
  return this.setGetSchemaVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetSchemaVariableStore = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional DataForVariableStore get_data_variable_store = 55;
 * @return {?proto.de.mypowergrid.ems.controller.DataForVariableStore}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getGetDataVariableStore = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.DataForVariableStore} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.DataForVariableStore, 55));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.DataForVariableStore|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setGetDataVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearGetDataVariableStore = function() {
  return this.setGetDataVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasGetDataVariableStore = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional google.protobuf.Empty set_data_variable_store = 56;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSetDataVariableStore = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 56));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSetDataVariableStore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSetDataVariableStore = function() {
  return this.setSetDataVariableStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSetDataVariableStore = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional google.protobuf.Empty finalize_topology_change = 57;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getFinalizeTopologyChange = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 57));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setFinalizeTopologyChange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearFinalizeTopologyChange = function() {
  return this.setFinalizeTopologyChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasFinalizeTopologyChange = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional google.protobuf.Empty send_log_message = 58;
 * @return {?proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getSendLogMessage = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 58));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setSendLogMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearSendLogMessage = function() {
  return this.setSendLogMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasSendLogMessage = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional BackupResult backup_store = 60;
 * @return {!proto.de.mypowergrid.ems.controller.BackupResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getBackupStore = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.BackupResult} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.setBackupStore = function(value) {
  return jspb.Message.setOneofField(this, 60, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearBackupStore = function() {
  return jspb.Message.setOneofField(this, 60, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasBackupStore = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional BackupResult backup_restore = 61;
 * @return {!proto.de.mypowergrid.ems.controller.BackupResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getBackupRestore = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.BackupResult} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.setBackupRestore = function(value) {
  return jspb.Message.setOneofField(this, 61, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearBackupRestore = function() {
  return jspb.Message.setOneofField(this, 61, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasBackupRestore = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional BackupResult backup_remove = 62;
 * @return {!proto.de.mypowergrid.ems.controller.BackupResult}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getBackupRemove = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.BackupResult} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.BackupResult} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.setBackupRemove = function(value) {
  return jspb.Message.setOneofField(this, 62, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearBackupRemove = function() {
  return jspb.Message.setOneofField(this, 62, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasBackupRemove = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional Names backup_list = 63;
 * @return {?proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.getBackupList = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Names} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Names, 63));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Names|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
*/
proto.de.mypowergrid.ems.controller.Response.prototype.setBackupList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.de.mypowergrid.ems.controller.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.Response} returns this
 */
proto.de.mypowergrid.ems.controller.Response.prototype.clearBackupList = function() {
  return this.setBackupList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.Response.prototype.hasBackupList = function() {
  return jspb.Message.getField(this, 63) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Error}
 */
proto.de.mypowergrid.ems.controller.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Error;
  return proto.de.mypowergrid.ems.controller.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Error}
 */
proto.de.mypowergrid.ems.controller.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Error.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Error} returns this
 */
proto.de.mypowergrid.ems.controller.Error.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.AbortRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.AbortRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.AbortRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AbortRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    emergency: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.AbortRequest}
 */
proto.de.mypowergrid.ems.controller.AbortRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.AbortRequest;
  return proto.de.mypowergrid.ems.controller.AbortRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.AbortRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.AbortRequest}
 */
proto.de.mypowergrid.ems.controller.AbortRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmergency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.AbortRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.AbortRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.AbortRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.AbortRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmergency();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool emergency = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.AbortRequest.prototype.getEmergency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.controller.AbortRequest} returns this
 */
proto.de.mypowergrid.ems.controller.AbortRequest.prototype.setEmergency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Name.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Name.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Name} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Name.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Name.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Name;
  return proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Name} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Name}
 */
proto.de.mypowergrid.ems.controller.Name.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Name.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Name} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Name.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Name.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Name} returns this
 */
proto.de.mypowergrid.ems.controller.Name.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Names.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Names.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Names.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Names} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Names.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Names.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Names;
  return proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Names} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Names}
 */
proto.de.mypowergrid.ems.controller.Names.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Names.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Names} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Names.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.controller.Names.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.controller.Names} returns this
 */
proto.de.mypowergrid.ems.controller.Names.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.Names} returns this
 */
proto.de.mypowergrid.ems.controller.Names.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.Names} returns this
 */
proto.de.mypowergrid.ems.controller.Names.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.NameValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.NameValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.NameValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.NameValue}
 */
proto.de.mypowergrid.ems.controller.NameValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.NameValue;
  return proto.de.mypowergrid.ems.controller.NameValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.NameValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.NameValue}
 */
proto.de.mypowergrid.ems.controller.NameValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_struct_pb.Value;
      reader.readMessage(value,google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.NameValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.NameValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.NameValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_struct_pb.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.NameValue} returns this
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Value value = 2;
 * @return {?proto.google.protobuf.Value}
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 2));
};


/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.NameValue} returns this
*/
proto.de.mypowergrid.ems.controller.NameValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.NameValue} returns this
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.NameValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.NameValues.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.NameValues.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.NameValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.NameValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.NameValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameValuesList: jspb.Message.toObjectList(msg.getNameValuesList(),
    proto.de.mypowergrid.ems.controller.NameValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.NameValues}
 */
proto.de.mypowergrid.ems.controller.NameValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.NameValues;
  return proto.de.mypowergrid.ems.controller.NameValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.NameValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.NameValues}
 */
proto.de.mypowergrid.ems.controller.NameValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.NameValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.NameValue.deserializeBinaryFromReader);
      msg.addNameValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.NameValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.NameValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.NameValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.NameValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.NameValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NameValue name_values = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.NameValue>}
 */
proto.de.mypowergrid.ems.controller.NameValues.prototype.getNameValuesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.NameValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.NameValue, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.NameValue>} value
 * @return {!proto.de.mypowergrid.ems.controller.NameValues} returns this
*/
proto.de.mypowergrid.ems.controller.NameValues.prototype.setNameValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.NameValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.NameValue}
 */
proto.de.mypowergrid.ems.controller.NameValues.prototype.addNameValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.NameValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.NameValues} returns this
 */
proto.de.mypowergrid.ems.controller.NameValues.prototype.clearNameValuesList = function() {
  return this.setNameValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimeseriesName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesName}
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimeseriesName;
  return proto.de.mypowergrid.ems.controller.TimeseriesName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesName}
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimeseriesName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesName} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Timeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Timeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Timeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    interpolation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    repeatPeriodSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries}
 */
proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Timeseries;
  return proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Timeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries}
 */
proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.InterpolationType} */ (reader.readEnum());
      msg.setInterpolation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRepeatPeriodSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Timeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Timeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Timeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInterpolation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRepeatPeriodSeconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries} returns this
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional InterpolationType interpolation = 2;
 * @return {!proto.de.mypowergrid.ems.controller.InterpolationType}
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.getInterpolation = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.InterpolationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.InterpolationType} value
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries} returns this
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.setInterpolation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 repeat_period_seconds = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.getRepeatPeriodSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries} returns this
 */
proto.de.mypowergrid.ems.controller.Timeseries.prototype.setRepeatPeriodSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.Timeserieses.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.Timeserieses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.Timeserieses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Timeserieses.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeSeriesesList: jspb.Message.toObjectList(msg.getTimeSeriesesList(),
    proto.de.mypowergrid.ems.controller.Timeseries.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.Timeserieses}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.Timeserieses;
  return proto.de.mypowergrid.ems.controller.Timeserieses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.Timeserieses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.Timeserieses}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.Timeseries;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinaryFromReader);
      msg.addTimeSerieses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.Timeserieses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.Timeserieses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.Timeserieses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeSeriesesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.Timeseries.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Timeseries time_serieses = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.Timeseries>}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.getTimeSeriesesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.Timeseries>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.Timeseries, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.Timeseries>} value
 * @return {!proto.de.mypowergrid.ems.controller.Timeserieses} returns this
*/
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.setTimeSeriesesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.Timeseries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.Timeseries}
 */
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.addTimeSerieses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.Timeseries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.Timeserieses} returns this
 */
proto.de.mypowergrid.ems.controller.Timeserieses.prototype.clearTimeSeriesesList = function() {
  return this.setTimeSeriesesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    interpolation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestampValueList: jspb.Message.toObjectList(msg.getTimestampValueList(),
    proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.toObject, includeInstance),
    repeatPeriodSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest;
  return proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.de.mypowergrid.ems.controller.InterpolationType} */ (reader.readEnum());
      msg.setInterpolation(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinaryFromReader);
      msg.addTimestampValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRepeatPeriodSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInterpolation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimestampValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getRepeatPeriodSeconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue;
  return proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double timestamp = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional InterpolationType interpolation = 2;
 * @return {!proto.de.mypowergrid.ems.controller.InterpolationType}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.getInterpolation = function() {
  return /** @type {!proto.de.mypowergrid.ems.controller.InterpolationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.InterpolationType} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.setInterpolation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated TimestampValue timestamp_value = 3;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.getTimestampValueList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, 3));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} returns this
*/
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.setTimestampValueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.addTimestampValue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.clearTimestampValueList = function() {
  return this.setTimestampValueList([]);
};


/**
 * optional uint64 repeat_period_seconds = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.getRepeatPeriodSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.prototype.setRepeatPeriodSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestampValuesList: jspb.Message.toObjectList(msg.getTimestampValuesList(),
    proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest;
  return proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinaryFromReader);
      msg.addTimestampValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestampValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TimeseriesUpdateRequest.TimestampValue timestamp_values = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.getTimestampValuesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} returns this
*/
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.setTimestampValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue}
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.addTimestampValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesAddValuesRequest.prototype.clearTimestampValuesList = function() {
  return this.setTimestampValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    begin: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    end: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest;
  return proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBegin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBegin();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double begin = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.getBegin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.setBegin = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double end = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.TimeseriesListDataRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.TimestampValues.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.TimestampValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.TimestampValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimestampValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampValueList: jspb.Message.toObjectList(msg.getTimestampValueList(),
    proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.toObject, includeInstance),
    meta: (f = msg.getMeta()) && proto.de.mypowergrid.ems.controller.Timeseries.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.TimestampValues;
  return proto.de.mypowergrid.ems.controller.TimestampValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.TimestampValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.deserializeBinaryFromReader);
      msg.addTimestampValue(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.controller.Timeseries;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.Timeseries.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.TimestampValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.TimestampValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.TimestampValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.controller.Timeseries.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeseriesUpdateRequest.TimestampValue timestamp_value = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.getTimestampValueList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue>} value
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues} returns this
*/
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.setTimestampValueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.addTimestampValue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.TimeseriesUpdateRequest.TimestampValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues} returns this
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.clearTimestampValueList = function() {
  return this.setTimestampValueList([]);
};


/**
 * optional Timeseries meta = 2;
 * @return {?proto.de.mypowergrid.ems.controller.Timeseries}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.getMeta = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.Timeseries} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.Timeseries, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.Timeseries|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues} returns this
*/
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.TimestampValues} returns this
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.TimestampValues.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SensorListDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    begin: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    end: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SensorListDataRequest}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SensorListDataRequest;
  return proto.de.mypowergrid.ems.controller.SensorListDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SensorListDataRequest}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBegin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SensorListDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBegin();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string sensor_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.getSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.setSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double begin = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.getBegin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.setBegin = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double end = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorListDataRequest} returns this
 */
proto.de.mypowergrid.ems.controller.SensorListDataRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SensorSample.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SensorSample} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorSample.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    validity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    errorAbove: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    errorBelow: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample}
 */
proto.de.mypowergrid.ems.controller.SensorSample.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SensorSample;
  return proto.de.mypowergrid.ems.controller.SensorSample.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SensorSample} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample}
 */
proto.de.mypowergrid.ems.controller.SensorSample.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.ems.Quality.ValidType} */ (reader.readEnum());
      msg.setValidity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setErrorAbove(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setErrorBelow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SensorSample.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SensorSample} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorSample.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getValidity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getErrorAbove();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getErrorBelow();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double timestamp = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional de.mypowergrid.ems.Quality.ValidType validity = 3;
 * @return {!proto.de.mypowergrid.ems.Quality.ValidType}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.getValidity = function() {
  return /** @type {!proto.de.mypowergrid.ems.Quality.ValidType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.Quality.ValidType} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.setValidity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double error_above = 4;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.getErrorAbove = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.setErrorAbove = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double error_below = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.getErrorBelow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSample.prototype.setErrorBelow = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SensorSampleList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SensorSampleList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorSamplesList: jspb.Message.toObjectList(msg.getSensorSamplesList(),
    proto.de.mypowergrid.ems.controller.SensorSample.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SensorSampleList}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SensorSampleList;
  return proto.de.mypowergrid.ems.controller.SensorSampleList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SensorSampleList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SensorSampleList}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.controller.SensorSample;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorSample.deserializeBinaryFromReader);
      msg.addSensorSamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SensorSampleList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SensorSampleList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorSamplesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.ems.controller.SensorSample.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SensorSample sensor_samples = 1;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.SensorSample>}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.getSensorSamplesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.SensorSample>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.SensorSample, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.SensorSample>} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorSampleList} returns this
*/
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.setSensorSamplesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.SensorSample=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample}
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.addSensorSamples = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.ems.controller.SensorSample, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.SensorSampleList} returns this
 */
proto.de.mypowergrid.ems.controller.SensorSampleList.prototype.clearSensorSamplesList = function() {
  return this.setSensorSamplesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sensorSamplesList: jspb.Message.toObjectList(msg.getSensorSamplesList(),
    proto.de.mypowergrid.ems.controller.SensorSample.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.SensorNameAndSampleList;
  return proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorName(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.controller.SensorSample;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.SensorSample.deserializeBinaryFromReader);
      msg.addSensorSamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSensorSamplesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.ems.controller.SensorSample.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sensor_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.getSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} returns this
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.setSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SensorSample sensor_samples = 2;
 * @return {!Array<!proto.de.mypowergrid.ems.controller.SensorSample>}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.getSensorSamplesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.ems.controller.SensorSample>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.ems.controller.SensorSample, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.ems.controller.SensorSample>} value
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} returns this
*/
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.setSensorSamplesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.ems.controller.SensorSample=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.SensorSample}
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.addSensorSamples = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.ems.controller.SensorSample, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.SensorNameAndSampleList} returns this
 */
proto.de.mypowergrid.ems.controller.SensorNameAndSampleList.prototype.clearSensorSamplesList = function() {
  return this.setSensorSamplesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleIdList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleIdList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleIdList}
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleIdList;
  return proto.de.mypowergrid.ems.controller.ScheduleIdList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleIdList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleIdList}
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScheduleIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleIdList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleIdList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string schedule_ids = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.getScheduleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleIdList} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.setScheduleIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleIdList} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.addScheduleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleIdList} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleIdList.prototype.clearScheduleIdsList = function() {
  return this.setScheduleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleId.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleId.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleId}
 */
proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleId;
  return proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleId}
 */
proto.de.mypowergrid.ems.controller.ScheduleId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string schedule_id = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.ScheduleId.prototype.getScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleId} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleId.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_ = [[4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.TypeCase = {
  TYPE_NOT_SET: 0,
  SELF_CONSUMPTION: 4,
  POWER_BIAS: 5,
  GENERATION_PEAK_SHAVING: 6,
  LOAD_PEAK_SHAVING: 7,
  BALANCE_DIFFERENCE_OPTIMIZATION: 8,
  HEATPUMP_MODE_OVERRIDE: 9
};

/**
 * @return {proto.de.mypowergrid.ems.controller.ScheduleEntry.TypeCase}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.ems.controller.ScheduleEntry.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    priority: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    end: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    selfConsumption: (f = msg.getSelfConsumption()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.toObject(includeInstance, f),
    powerBias: (f = msg.getPowerBias()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.toObject(includeInstance, f),
    generationPeakShaving: (f = msg.getGenerationPeakShaving()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.toObject(includeInstance, f),
    loadPeakShaving: (f = msg.getLoadPeakShaving()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.toObject(includeInstance, f),
    balanceDifferenceOptimization: (f = msg.getBalanceDifferenceOptimization()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.toObject(includeInstance, f),
    heatpumpModeOverride: (f = msg.getHeatpumpModeOverride()) && proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnd(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.deserializeBinaryFromReader);
      msg.setSelfConsumption(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.deserializeBinaryFromReader);
      msg.setPowerBias(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.deserializeBinaryFromReader);
      msg.setGenerationPeakShaving(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.deserializeBinaryFromReader);
      msg.setLoadPeakShaving(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.deserializeBinaryFromReader);
      msg.setBalanceDifferenceOptimization(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride;
      reader.readMessage(value,proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.deserializeBinaryFromReader);
      msg.setHeatpumpModeOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSelfConsumption();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.serializeBinaryToWriter
    );
  }
  f = message.getPowerBias();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.serializeBinaryToWriter
    );
  }
  f = message.getGenerationPeakShaving();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.serializeBinaryToWriter
    );
  }
  f = message.getLoadPeakShaving();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.serializeBinaryToWriter
    );
  }
  f = message.getBalanceDifferenceOptimization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.serializeBinaryToWriter
    );
  }
  f = message.getHeatpumpModeOverride();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.toObject = function(includeInstance, msg) {
  var f, obj = {
    watts: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWatts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWatts();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double watts = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.prototype.getWatts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias.prototype.setWatts = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.toObject = function(includeInstance, msg) {
  var f, obj = {
    refinement: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    timeshift: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefinement(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimeshift(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefinement();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTimeshift();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional bool refinement = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.getRefinement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.setRefinement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double timeshift = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.getTimeshift = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving.prototype.setTimeshift = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.toObject = function(includeInstance, msg) {
  var f, obj = {
    dispatchOverwrite: jspb.Message.getFieldWithDefault(msg, 1, ""),
    safetyMarginOverwrite: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDispatchOverwrite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSafetyMarginOverwrite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDispatchOverwrite();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSafetyMarginOverwrite();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dispatch_overwrite = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.getDispatchOverwrite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.setDispatchOverwrite = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string safety_margin_overwrite = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.getSafetyMarginOverwrite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving.prototype.setSafetyMarginOverwrite = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string config = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.prototype.getConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization.prototype.setConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride;
  return proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional de.mypowergrid.ems.ENG_SP_ModeCtrl.Value mode = 1;
 * @return {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.prototype.getMode = function() {
  return /** @type {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.ENG_SP_ModeCtrl.Value} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 priority = 1;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double start = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double end = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional SelfConsumption self_consumption = 4;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getSelfConsumption = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption, 4));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.SelfConsumption|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setSelfConsumption = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearSelfConsumption = function() {
  return this.setSelfConsumption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasSelfConsumption = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PowerBias power_bias = 5;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getPowerBias = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias, 5));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.PowerBias|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setPowerBias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearPowerBias = function() {
  return this.setPowerBias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasPowerBias = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GenerationPeakShaving generation_peak_shaving = 6;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getGenerationPeakShaving = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving, 6));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.GenerationPeakShaving|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setGenerationPeakShaving = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearGenerationPeakShaving = function() {
  return this.setGenerationPeakShaving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasGenerationPeakShaving = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LoadPeakShaving load_peak_shaving = 7;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getLoadPeakShaving = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving, 7));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.LoadPeakShaving|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setLoadPeakShaving = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearLoadPeakShaving = function() {
  return this.setLoadPeakShaving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasLoadPeakShaving = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BalanceDifferenceOptimization balance_difference_optimization = 8;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getBalanceDifferenceOptimization = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization, 8));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.BalanceDifferenceOptimization|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setBalanceDifferenceOptimization = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearBalanceDifferenceOptimization = function() {
  return this.setBalanceDifferenceOptimization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasBalanceDifferenceOptimization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional HeatpumpModeOverride heatpump_mode_override = 9;
 * @return {?proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.getHeatpumpModeOverride = function() {
  return /** @type{?proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride, 9));
};


/**
 * @param {?proto.de.mypowergrid.ems.controller.ScheduleEntry.HeatpumpModeOverride|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
*/
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.setHeatpumpModeOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.ems.controller.ScheduleEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ScheduleEntry} returns this
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.clearHeatpumpModeOverride = function() {
  return this.setHeatpumpModeOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ScheduleEntry.prototype.hasHeatpumpModeOverride = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.OperateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.OperateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.OperateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataObject: (f = msg.getDataObject()) && ext_ems_data$objects_pb.DataObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.OperateRequest}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.OperateRequest;
  return proto.de.mypowergrid.ems.controller.OperateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.OperateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.OperateRequest}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 2:
      var value = new ext_ems_data$objects_pb.DataObject;
      reader.readMessage(value,ext_ems_data$objects_pb.DataObject.deserializeBinaryFromReader);
      msg.setDataObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.OperateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.OperateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.OperateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataObject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_ems_data$objects_pb.DataObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.OperateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional de.mypowergrid.ems.DataObject data_object = 2;
 * @return {?proto.de.mypowergrid.ems.DataObject}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.getDataObject = function() {
  return /** @type{?proto.de.mypowergrid.ems.DataObject} */ (
    jspb.Message.getWrapperField(this, ext_ems_data$objects_pb.DataObject, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.DataObject|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.OperateRequest} returns this
*/
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.setDataObject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.OperateRequest} returns this
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.clearDataObject = function() {
  return this.setDataObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.OperateRequest.prototype.hasDataObject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.PeakObserverNameList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.toObject = function(includeInstance, msg) {
  var f, obj = {
    peakObserverNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverNameList}
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.PeakObserverNameList;
  return proto.de.mypowergrid.ems.controller.PeakObserverNameList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverNameList}
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPeakObserverNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.PeakObserverNameList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeakObserverNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string peak_observer_names = 1;
 * @return {!Array<string>}
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.getPeakObserverNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.setPeakObserverNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.addPeakObserverNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverNameList} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverNameList.prototype.clearPeakObserverNamesList = function() {
  return this.setPeakObserverNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.PeakObserverName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.toObject = function(includeInstance, msg) {
  var f, obj = {
    peakObserverName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverName}
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.PeakObserverName;
  return proto.de.mypowergrid.ems.controller.PeakObserverName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverName}
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeakObserverName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.PeakObserverName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeakObserverName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string peak_observer_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.prototype.getPeakObserverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverName} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverName.prototype.setPeakObserverName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.PeakObserverState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyMeterSensorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dcctNodeReference: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intervalSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    resetPeakTimeSeriesName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    peakWatts: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    peakTimestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.PeakObserverState;
  return proto.de.mypowergrid.ems.controller.PeakObserverState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyMeterSensorName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDcctNodeReference(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntervalSeconds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetPeakTimeSeriesName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPeakWatts(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPeakTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.PeakObserverState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyMeterSensorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDcctNodeReference();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntervalSeconds();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getResetPeakTimeSeriesName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeakWatts();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPeakTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string energy_meter_sensor_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getEnergyMeterSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setEnergyMeterSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dcct_node_reference = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getDcctNodeReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setDcctNodeReference = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 interval_seconds = 3;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setIntervalSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string reset_peak_time_series_name = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getResetPeakTimeSeriesName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setResetPeakTimeSeriesName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double peak_watts = 5;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getPeakWatts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setPeakWatts = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double peak_timestamp = 6;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.getPeakTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverState} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverState.prototype.setPeakTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    peakObserverName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    peakWatts: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest;
  return proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeakObserverName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPeakWatts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeakObserverName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPeakWatts();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string peak_observer_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.getPeakObserverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.setPeakObserverName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double peak_watts = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.getPeakWatts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetPeakRequest.prototype.setPeakWatts = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    peakObserverName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    intervalSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest;
  return proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeakObserverName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntervalSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeakObserverName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntervalSeconds();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string peak_observer_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.getPeakObserverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.setPeakObserverName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 interval_seconds = 2;
 * @return {number}
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.getIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest} returns this
 */
proto.de.mypowergrid.ems.controller.PeakObserverSetIntervalRequest.prototype.setIntervalSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    remove: (f = msg.getRemove()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f),
    replacement: (f = msg.getReplacement()) && ext_ems_topology_topology_pb.Source.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.ReplaceSourceRequest;
  return proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setRemove(value);
      break;
    case 2:
      var value = new ext_ems_topology_topology_pb.Source;
      reader.readMessage(value,ext_ems_topology_topology_pb.Source.deserializeBinaryFromReader);
      msg.setReplacement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemove();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getReplacement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ext_ems_topology_topology_pb.Source.serializeBinaryToWriter
    );
  }
};


/**
 * optional de.mypowergrid.ems.topology.Source remove = 1;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.getRemove = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} returns this
*/
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.setRemove = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} returns this
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.clearRemove = function() {
  return this.setRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.hasRemove = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional de.mypowergrid.ems.topology.Source replacement = 2;
 * @return {?proto.de.mypowergrid.ems.topology.Source}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.getReplacement = function() {
  return /** @type{?proto.de.mypowergrid.ems.topology.Source} */ (
    jspb.Message.getWrapperField(this, ext_ems_topology_topology_pb.Source, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.topology.Source|undefined} value
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} returns this
*/
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.setReplacement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.controller.ReplaceSourceRequest} returns this
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.clearReplacement = function() {
  return this.setReplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.controller.ReplaceSourceRequest.prototype.hasReplacement = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.controller.RemoveSourceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    topologyChangesList: jspb.Message.toObjectList(msg.getTopologyChangesList(),
    google_protobuf_empty_pb.Empty.toObject, includeInstance),
    strategyChangesList: jspb.Message.toObjectList(msg.getStrategyChangesList(),
    google_protobuf_empty_pb.Empty.toObject, includeInstance),
    decisionTreeChangesList: jspb.Message.toObjectList(msg.getDecisionTreeChangesList(),
    google_protobuf_empty_pb.Empty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.controller.RemoveSourceReply;
  return proto.de.mypowergrid.ems.controller.RemoveSourceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.addTopologyChanges(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.addStrategyChanges(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.addDecisionTreeChanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.controller.RemoveSourceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopologyChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getStrategyChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getDecisionTreeChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.protobuf.Empty topology_changes = 1;
 * @return {!Array<!proto.google.protobuf.Empty>}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.getTopologyChangesList = function() {
  return /** @type{!Array<!proto.google.protobuf.Empty>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_empty_pb.Empty, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.Empty>} value
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
*/
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.setTopologyChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.Empty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.addTopologyChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.Empty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.clearTopologyChangesList = function() {
  return this.setTopologyChangesList([]);
};


/**
 * repeated google.protobuf.Empty strategy_changes = 2;
 * @return {!Array<!proto.google.protobuf.Empty>}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.getStrategyChangesList = function() {
  return /** @type{!Array<!proto.google.protobuf.Empty>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Empty>} value
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
*/
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.setStrategyChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Empty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.addStrategyChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Empty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.clearStrategyChangesList = function() {
  return this.setStrategyChangesList([]);
};


/**
 * repeated google.protobuf.Empty decision_tree_changes = 3;
 * @return {!Array<!proto.google.protobuf.Empty>}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.getDecisionTreeChangesList = function() {
  return /** @type{!Array<!proto.google.protobuf.Empty>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {!Array<!proto.google.protobuf.Empty>} value
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
*/
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.setDecisionTreeChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.protobuf.Empty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Empty}
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.addDecisionTreeChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.protobuf.Empty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.ems.controller.RemoveSourceReply} returns this
 */
proto.de.mypowergrid.ems.controller.RemoveSourceReply.prototype.clearDecisionTreeChangesList = function() {
  return this.setDecisionTreeChangesList([]);
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.LoggingLevel = {
  DISABLE: 0,
  FATAL: 1,
  ERROR: 2,
  WARNING: 3,
  INFO: 4,
  DEBUG: 5,
  TRACE: 6,
  DEFAULT: 7
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.BackupResult = {
  OK: 0,
  PARSING_ERROR: 1,
  BACKUP_NOT_FOUND: 2,
  BACKUP_ALREADY_EXISTS: 3,
  IO_ERROR: 4,
  STORED_TOPOLOGY_NOT_COMPATIBLE: 5,
  STORED_STRATEGIES_NOT_COMPATIBLE: 6,
  STORED_ALIASES_HAVE_LOOPS: 7
};

/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.controller.InterpolationType = {
  LINEAR: 0,
  LEFT_BOUNDED: 1,
  RIGHT_BOUNDED: 2
};

goog.object.extend(exports, proto.de.mypowergrid.ems.controller);
