<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <p>
      {{ $t('ems.energyService.config.form.offGrid.description') }}
    </p>
  </EsLayoutForm>
</template>

<script>
import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'

export default {
  name: 'EsOffGridForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      showFailureAlert: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    onSubmit() {
      const esParams = {}
      this.$emit('submit', { esParams })
    }
  }
}
</script>
