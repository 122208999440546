// package: de.mypowergrid.fwupgrade
// file: ext/fwupgrade/upgrader.proto

var ext_fwupgrade_upgrader_pb = require("../../ext/fwupgrade/upgrader_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Upgrader = (function () {
  function Upgrader() {}
  Upgrader.serviceName = "de.mypowergrid.fwupgrade.Upgrader";
  return Upgrader;
}());

Upgrader.GetLog = {
  methodName: "GetLog",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.GetLogRequest,
  responseType: ext_fwupgrade_upgrader_pb.GetLogResponse
};

Upgrader.GetState = {
  methodName: "GetState",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.GetStateRequest,
  responseType: ext_fwupgrade_upgrader_pb.GetStateResponse
};

Upgrader.GetStateStream = {
  methodName: "GetStateStream",
  service: Upgrader,
  requestStream: false,
  responseStream: true,
  requestType: ext_fwupgrade_upgrader_pb.GetStateStreamRequest,
  responseType: ext_fwupgrade_upgrader_pb.GetStateStreamResponse
};

Upgrader.AcknowledgeError = {
  methodName: "AcknowledgeError",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.AcknowledgeErrorRequest,
  responseType: ext_fwupgrade_upgrader_pb.AcknowledgeErrorResponse
};

Upgrader.CheckForLatestUpgrade = {
  methodName: "CheckForLatestUpgrade",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.CheckForLatestUpgradeRequest,
  responseType: ext_fwupgrade_upgrader_pb.CheckForLatestUpgradeResponse
};

Upgrader.StartUpgrade = {
  methodName: "StartUpgrade",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.StartUpgradeRequest,
  responseType: ext_fwupgrade_upgrader_pb.StartUpgradeResponse
};

Upgrader.FinalizeUpgrade = {
  methodName: "FinalizeUpgrade",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.FinalizeUpgradeRequest,
  responseType: ext_fwupgrade_upgrader_pb.FinalizeUpgradeResponse
};

Upgrader.RollbackUpgrade = {
  methodName: "RollbackUpgrade",
  service: Upgrader,
  requestStream: false,
  responseStream: false,
  requestType: ext_fwupgrade_upgrader_pb.RollbackUpgradeRequest,
  responseType: ext_fwupgrade_upgrader_pb.RollbackUpgradeResponse
};

exports.Upgrader = Upgrader;

function UpgraderClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UpgraderClient.prototype.getLog = function getLog(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.GetLog, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.getState = function getState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.GetState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.getStateStream = function getStateStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Upgrader.GetStateStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.acknowledgeError = function acknowledgeError(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.AcknowledgeError, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.checkForLatestUpgrade = function checkForLatestUpgrade(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.CheckForLatestUpgrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.startUpgrade = function startUpgrade(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.StartUpgrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.finalizeUpgrade = function finalizeUpgrade(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.FinalizeUpgrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UpgraderClient.prototype.rollbackUpgrade = function rollbackUpgrade(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Upgrader.RollbackUpgrade, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UpgraderClient = UpgraderClient;

