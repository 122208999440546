<template>
  <!-- prettier-ignore -->
  <CBreadcrumb
    class="breadcrumb border-0 mb-0"
    :items="currentBc(route)"
  />
</template>

<script>
import { currentBc } from './_breadcrumb'

export default {
  name: 'ApxBreadcrumb',
  data() {
    return {
      route: this.$route
    }
  },
  watch: {
    $route() {
      this.route = this.$route
    }
  },
  methods: {
    upperCaseFirst(str) {
      return str.replace(/^\w/, (c) => c.toUpperCase())
    },
    currentBc: currentBc
  }
}
</script>
