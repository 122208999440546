<template>
  <div>
    <!-- BEGIN: Grid => On -->
    <p
      v-if="
        !positionInTopology || !positionInTopology.physicalDevicesList || !positionInTopology.physicalDevicesList.length
      "
      class="d-flex justify-content-between mb-1"
    >
      <small class="ml-0 mr-3 font-weight-bold text-primary">
        {{ $t(`${tBasePath}.shortLabel`) }}
      </small>
      <small class="ml-1 mr-0 pr-0 text-break text-primary">
        <em>
          {{ $t(`${tBasePath}.gridEnabledLabel`) }}
        </em>
      </small>
    </p>
    <!-- END: Grid => On -->
    <!-- BEGIN: Grid => Off -->
    <p v-else class="d-flex justify-content-between mb-1">
      <small class="ml-0 mr-3 font-weight-bold text-primary">
        {{ $t(`${tBasePath}.shortLabel`) }}
      </small>
      <small class="ml-1 mr-0 pr-0 text-break text-primary">
        <span v-for="(pd, i) of positionInTopology.physicalDevicesList" :key="i">
          <em>{{ translatePhysicalDevice(pd) }}</em>
          <span v-if="i !== positionInTopology.physicalDevicesList.length - 1">, </span>
        </span>
      </small>
    </p>
    <!-- END: Grid => Off -->
  </div>
</template>

<script>
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'

export default {
  name: 'EsPreviewPositionInTopology',
  props: {
    positionInTopology: {
      required: true,
      type: Object
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.global.positionInTopology'
  },
  methods: {
    translatePhysicalDevice
  }
}
</script>
