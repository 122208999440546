/**
 * @module validations/user-settings-validators
 */

import { helpers, minLength, required, requiredUnless, sameAs } from 'vuelidate/lib/validators'
import { strongPasswordRequired } from '@/validations/custom-validators'
import { licenseValidators } from '@/validations/license-validators'

const config = {
  username: {
    minLength: 4
  },
  password: {
    minLength: 10
  }
}

const userPasswordValidators = {
  username: {
    required,
    minLength: minLength(config.username.minLength)
  },
  password: {
    required,
    minLength: minLength(config.password.minLength),
    strongPasswordRequired: helpers.withParams({ type: 'strongPasswordRequired' }, (val) => {
      if (val) {
        return strongPasswordRequired(val)
      } else {
        return false
      }
    })
  },
  passwordConfirm: {
    required,
    sameAsPassword: sameAs('password')
  }
}

export function registrationValidations() {
  return {
    ...licenseValidators,
    ...userPasswordValidators
  }
}

export function changeCredentialsValidators({ currentUsername }) {
  if (!currentUsername) {
    throw new TypeError('Invalid argument currentUsername. Should not be blank.')
  }

  return {
    currentPassword: { required },
    newUsername: {
      required: requiredUnless('newPassword'),
      minLength: minLength(config.username.minLength),
      notSameAs: helpers.withParams({ type: 'notSameAs', currentUsername }, (val) => val !== currentUsername)
    },
    newPassword: {
      required: requiredUnless('newUsername'),
      minLength: minLength(config.password.minLength),
      strongPasswordRequired: helpers.withParams({ type: 'strongPasswordRequired' }, (val, vm) => {
        if (!helpers.req(val) && helpers.req(vm.newUsername)) {
          // pwd optional if new usr
          return true
        } else if (!helpers.req(val) && !helpers.req(vm.newUsername)) {
          // pwd required if no usr
          return false
        }

        return strongPasswordRequired(val)
      })
    },
    newPasswordConfirm: {
      required: requiredUnless('newUsername'),
      sameAsPassword: sameAs('newPassword')
    }
  }
}

export function createUserValidations() {
  return {
    // TODO: Validate that username does not already exist in list of all users
    ...userPasswordValidators
  }
}

export function createUserResetPukValidations() {
  return {
    ...userPasswordValidators,
    puk: {
      required
    }
  }
}
