<template>
  <FwUpgradeViewLayout>
    <CRow>
      <CCol>
        <p class="font-weight-bold text-danger">
          {{ $t('fwupgrade.status.error.title') }}
        </p>
        <p>
          {{ $t('fwupgrade.status.error.description', { energyManager: brandName }) }}
        </p>
      </CCol>
      <CCol v-if="isFwErrorState">
        <p class="font-weight-bold text-danger">
          {{ tFwErrorCode }}
        </p>
        <p class="font-italic">
          {{ currentErrorMsg }}
        </p>
      </CCol>
    </CRow>

    <template v-if="isFwErrorState" #actions>
      <!-- prettier-ignore -->
      <CButton
        class="mx-1 mx-md-3 mx-lg-4"
        size="sm"
        color="primary"
        :disabled="loading"
        @click="onAcknowledgeError"
      >
        {{ $t('fwupgrade.action.acknowledgeError.btn') }}
      </CButton>
    </template>
  </FwUpgradeViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Error as FWErrorPb, State as FwStatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import FwUpgradeViewLayout from '@/components/fw-upgrade/fw-upgrade-view-layout'

import { getEnumStr } from '@/grpc/parser'
import { buildI18nErrorMsg } from '@/view-helper/fw-upgrade/fw-upgrade-error'
import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'FwUpgradeError',
  components: {
    FwUpgradeViewLayout
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('fwupgrader', ['fwUpgraderState', 'currentErrorCode', 'currentErrorMsg']),
    isFwErrorState() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.ERROR:
          return true
        default:
          return false
      }
    },
    tFwErrorCode() {
      const tKey = `fwupgrade.error.errorKind.${this.currentErrorCode}`
      if (this.$te(tKey)) {
        return this.$t(tKey)
      }

      return getEnumStr(this.currentErrorCode, FWErrorPb.ErrorKindCase) || 'UNKNOWN'
    }
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
  },
  methods: {
    async onAcknowledgeError() {
      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch('fwupgrader/acknowledgeError')
        this.$log.info('Succesfully ack fw-upgrade error.')

        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 5000,
            type: 'success',
            title: this.$t('fwupgrade.action.acknowledgeError.success'),
            content: this.$t('fwupgrade.action.acknowledgeError.hint')
          })
        )
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t('fwupgrade.error.acknowledgeError.title'),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    }
  }
}
</script>
