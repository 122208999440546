/** @module grpc/protobuf/device-helper */

import { upperFirst } from 'lodash'
import devicePbMsgDesc from '@/../lib/proto_json/amperix/device_noopt'
import * as DevicePb from '@/../lib/proto_js/amperix/device_noopt_pb'
import { buildDescriptor } from '@/grpc/parser'

Object.defineProperty(DevicePb.DeviceConfig, 'name', {
  value: 'DeviceConfig',
  writable: false
})

// warpping off the namespace :)
export const DEVICE_SERVICE_FULL_DESCRIPTION = devicePbMsgDesc.nested.de.nested.mypowergrid.nested.amperix.nested

export const DEVICE_CONFIG_DESCRIPTION = DEVICE_SERVICE_FULL_DESCRIPTION.DeviceConfig

/**
 * @example
 * {
 *  {
 *    _ProtoMsgConstructor: DevicePb.DeviceConfig,
 *    id: {},
 *    ...
 *    meter: {
 *      _ProtoMsgConstructor: DevicePb.DeviceConfig.Meter,
 *      config1: { ... },
 *      ...
 *    },
 *    inverter: { ... },
 *    ...
 * }
 *
 * @constant
 *
 * @type {object}
 *
 */
export const DEVICE_CONFIG_DESCRIPTOR = buildDescriptor(DEVICE_CONFIG_DESCRIPTION, DevicePb.DeviceConfig)

/**
 * Allows to process received `DevicePb.DeviceConfig` messages.
 *
 * @function
 *
 * @param {object} msg has to be an instance of `DevicePb.DeviceConfig` proto message constructor or the instance parsed `toObject()`.
 *
 * @return {object} result
 * @return {string} result.configMsgName specifies the 'bare' config message constructor, e.g. `Meter.Config1`
 * @return {object} result.configMsg is the 'bare' proto config message
 * @return {array} result.configMsgFieldNamePath is the path (list) of proto field IDs, which points to the 'bare' proto config message
 * @return {array} result.configMsgFieldNumPath is the path (list) of proto field Names, which points to the 'bare' proto config message
 */
export function processDeviceConfigMsg(msg) {
  let configId = null
  let driverId = null
  let configTypeId = null
  let configMsgName = null
  let configMsg = null
  const configMsgFieldNamePath = []
  const configMsgFieldNumPath = []

  // duck typing :)
  const isProto = typeof msg.hasId === 'function'

  if (isProto && msg.hasId()) {
    configId = msg.getId().getId()
    driverId = msg.getId().getDriverId()
    configTypeId = msg.getId().getConfigTypeId()
  } else if (!isProto && msg.id) {
    configId = msg.id.id
    configTypeId = msg.id.configTypeId
    driverId = msg.id.driverId
  }

  DEVICE_CONFIG_DESCRIPTION.oneofs.device.oneof.forEach((dev) => {
    if (isProto && !msg[`has${upperFirst(dev)}`]()) {
      return
    } else if (!isProto && !msg[dev]) {
      return
    }

    const devMsg = isProto ? msg[`get${upperFirst(dev)}`]() : msg[dev]
    const devMsgName = DEVICE_CONFIG_DESCRIPTION.fields[dev].type
    const devMsgDesc = DEVICE_CONFIG_DESCRIPTION.nested[devMsgName]
    configMsgFieldNamePath.push(dev)
    configMsgFieldNumPath.push(DEVICE_CONFIG_DESCRIPTION.fields[dev].id)

    devMsgDesc.oneofs.config.oneof.forEach((c) => {
      if (isProto && !devMsg[`has${upperFirst(c)}`]()) {
        return
      } else if (!isProto && !devMsg[c]) {
        return
      }

      configMsgName = `${devMsgName}.${devMsgDesc.fields[c].type}`
      configMsg = isProto ? devMsg[`get${upperFirst(c)}`]() : devMsg[c]
      configMsgFieldNamePath.push(c)
      configMsgFieldNumPath.push(devMsgDesc.fields[c].id)
    })
  })

  return {
    configId,
    driverId,
    configTypeId,
    configMsgName,
    configMsg,
    configMsgFieldNamePath,
    configMsgFieldNumPath
  }
}
