<template>
  <component :is="wrapperTag">
    <component
      :is="memberTag"
      v-for="(l, i) of members"
      :key="`group-member-${i}`"
      class="d-flex flex-column flex-md-row justify-content-between text-primary mb-1"
    >
      <span v-if="withDevice" class="mr-2">{{ l.physicalDevice || l.device }}</span>
      <small :class="{ 'ml-2': withDevice, 'text-break': true, 'font-italic': true }">
        {{ l.ldn }}
      </small>
    </component>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

// maxNumber = `0` will NOT limit the maximal number of shown members
export default {
  name: 'EmsEsActuatorGroupMembers',
  props: {
    actuatorGroupId: {
      required: true,
      type: String
    },
    withDevice: {
      required: false,
      type: Boolean,
      default: true
    },
    maxNumber: {
      required: false,
      type: Number,
      default: 0
    },
    wrapperTag: {
      required: false,
      type: String,
      default: 'div'
    },
    memberTag: {
      required: false,
      type: String,
      default: 'p'
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['humanizeAGMembers']),
    members() {
      const mbrs = []
      this.humanizeAGMembers(this.actuatorGroupId).forEach((l, i) => {
        if (this.maxNumber && i > this.maxNumber) {
          return
        }

        if (!this.maxNumber || i < this.maxNumber) {
          mbrs.push(l)
        } else {
          mbrs.push({
            device: '...',
            ldn: '...'
          })
        }
      })

      return mbrs
    }
  }
}
</script>
