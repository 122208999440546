<template>
  <!-- prettier-ignore -->
  <CCard class="col-12 mt-2 pb-0" border-color="primary">
    <CCardBody class="mb-0 mt-0 pt-2 pb-1 pl-0 pr-0">
      <!-- TODO: handle controllable process values -->
      <p class="small card-text">
        {{ $t('config.device.status.processValuesFilter.lastUpdate') }}:
        {{ lastUpdate | formatToLocalDatetime }}
      </p>
      <div class="h5 text-muted text-right mb-2">
        {{ reference | stripOfLogicalDeviceName }}
      </div>
      <div class="h4 mb-0">
        <small>
          {{ $t('config.device.status.processValuesFilter.currentValue') }}:
        </small>
        {{ value }}
        <small
          v-if="unitSymbol"
          class="h6 text-muted text-uppercase font-weight-bold pl-3"
        >
          {{ unitSymbol }}
        </small>
      </div>
    </CCardBody>
    <CCardBody call="mt-1">
      <div>
        <CRow>
          <CCol v-if="ctlValIsBool" sm="12" md="8">
            <CInputCheckbox
              :key="reference"
              :checked.sync="ctlVal"
              v-bind="getInputFieldProps()"
            />
          </CCol>
          <CCol v-else sm="12" md="10">
            <CInput
              :key="reference"
              :value.sync="ctlVal"
              v-bind="getInputFieldProps()"
            />
          </CCol>
          <CCol sm="12" :md="ctlValIsBool ? 4 : 2">
            <CButton
              type="submit"
              size="sm"
              color="primary"
              @click.prevent="onSet"
            >
              {{ $t('main.setBtn') }}
            </CButton>
          </CCol>
        </CRow>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { times } from 'lodash'
import { filterDataObjectName, stripOfLogicalDeviceName } from '@/view-helper/filters/iec-str-formatter'

export default {
  name: 'ApxDeviceControlValue',
  filters: {
    stripOfLogicalDeviceName
  },
  props: {
    configId: {
      type: String,
      required: false,
      default: ''
    },
    lastUpdate: {
      type: String,
      required: false,
      default: ''
    },
    reference: {
      type: String,
      required: true
    },
    isControllable: {
      type: Boolean,
      required: true
    },
    value: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    unitSymbol: {
      type: String,
      required: false,
      default: null
    },
    controlValue: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    controlValueType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ctlValIsBool: this.controlValueType === 'boolean',
      ctlVal: this.controlValue
    }
  },
  methods: {
    getInputFieldProps() {
      const props = {
        type: 'text',
        label: `Set ${filterDataObjectName(this.reference)} to:`,
        lazy: true,
        custom: false,
        horizontal: { label: 'col-sm-6', input: 'col-sm-6' }
      }

      switch (this.controlValueType) {
        case 'number':
          props.type = 'number'
          props.step = 'any'
          break
        case 'boolean':
          props.type = 'checkbox'
          props.horizontal = { label: 'col-sm-8', input: 'col-sm-4' }
          props.label = `activate ${filterDataObjectName(this.reference)}`
          break
      }
      return props
    },
    onSet(e) {
      let ctlVal = this.ctlVal
      if (this.controlValueType === 'number') {
        ctlVal = parseFloat(ctlVal)
      }
      // Note: status and process value streaming should be started/stopped by the parent component
      // prettier-ignore
      this.$store
        .dispatch('deviceValues/operate', { reference: this.reference, value: ctlVal })
        .then(() => {
          this.$log.debug(`Successful operate for ${this.reference}`)
          // Currently streaming is polling, hence status and process value are 'refreshed' manually in a higher frequence here.
          // This can be removed, if it is a proper stream.
          times(10, (i) => {
            window.setTimeout(
              () => {
                this.$store
                  .dispatch('deviceValues/getEnergyManagerDeviceValue', this.reference)
                  .then(() => {
                    this.$store.dispatch('deviceConfig/getDeviceStatus', this.configId)
                  })
              },
              2000 * i
            )
          })
        })
        .catch(this.onServerError)
    },
    onServerError(err) {
      this.$log.warn(err)
      // TODO: translation error content
      this.$store.commit('notifications/PUSH_TOAST', {
        title: 'Server Error',
        type: 'toast-warning',
        content: `Setting control value failed. ${err.msg}. Error-Code: ${err.code}`
      })
    }
  }
}
</script>
