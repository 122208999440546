<template>
  <div class="py-2">
    <EsPreviewPositionInTopology :position-in-topology="radioRippleControlReceiver" />
  </div>
</template>

<script>
import EsPreviewPositionInTopology from './es-preview-position-in-topology'

export default {
  name: 'EsPreviewPvCurtailment',
  components: {
    EsPreviewPositionInTopology
  },
  props: {
    radioRippleControlReceiver: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
