<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :color="color"
    :fade="false"
    :size="size"
    :title="title"
    @update:show="onUpdateShow"
  >
    <slot></slot>
    <template #footer>
      <div class="d-flex justify-content-end">
        <CButton class="mx-1" type="button" color="secondary" @click="onCancel">
          {{ btnCancelLabel || $t('main.cancelBtn') }}
        </CButton>
        <!-- prettier-ignore -->
        <CButton
          class="mx-1"
          type="button"
          :color="color"
          :disabled="disabled"
          @click="onConfirm"
        >
          {{ btnConfirmLabel || $t('main.confirmSetBtn') }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    color: {
      required: false,
      type: String,
      default: null
    },
    size: {
      required: false,
      type: String,
      default: null
    },
    title: {
      required: true,
      type: String
    },
    visible: {
      required: true,
      type: Boolean
    },
    btnConfirmLabel: {
      required: false,
      type: String,
      default: ''
    },
    btnCancelLabel: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: this.visible,
      disabled: !this.visible
    }
  },
  watch: {
    visible(v) {
      this.show = v
      this.disabled = !v
    }
  },
  methods: {
    onUpdateShow(show) {
      this.disabled = !show
      this.$emit('update:visible', show)
    },
    onConfirm() {
      this.disabled = true
      this.$emit('update:visible', false)
      this.$emit('update:confirmation', true)
    },
    onCancel() {
      this.$emit('update:visible', false)
      this.$emit('update:confirmation', false)
    }
  }
}
</script>
