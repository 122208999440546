import i18n from '@/i18n'
import { deviceTypeToIcon } from '@/view-helper/device-config/device-config-models-helper'

const deviceNav = ({ featureBlacklist = [], omiOnlyEnabled = false }) => {
  let deviceItems = [
    {
      name: i18n.t('layout.configure.meters.nav_title'),
      to: {
        name: 'config-devices',
        params: {
          device: 'meters'
        }
      },
      icon: deviceTypeToIcon('meter')
    },
    {
      name: i18n.t('layout.configure.inverters.nav_title'),
      to: {
        name: 'config-devices',
        params: {
          device: 'inverters'
        }
      },
      icon: deviceTypeToIcon('inverter')
    },
    {
      name: i18n.t('layout.configure.wallboxs.nav_title'),
      to: {
        name: 'config-devices',
        params: {
          device: 'wallboxs'
        }
      },
      icon: deviceTypeToIcon('wallbox')
    },
    {
      name: i18n.t('layout.configure.relays.nav_title'),
      to: {
        name: 'config-devices',
        params: {
          device: 'relays'
        }
      },
      icon: deviceTypeToIcon('relay')
    },
    ...(!featureBlacklist.includes('container')
      ? [
          {
            name: i18n.t('layout.configure.containers.nav_title'),
            to: {
              name: 'config-devices',
              params: {
                device: 'containers'
              }
            },
            icon: deviceTypeToIcon('container')
          }
        ]
      : []),
    ...(!featureBlacklist.includes('eza')
      ? /* [
          {
            name: i18n.t('layout.configure.ezas.nav_title'),
            to: {
              name: 'config-devices',
              params: {
                device: 'ezas'
              }
            },
            icon: deviceTypeToIcon('eza')
          }
        ] */
        []
      : [])
  ]

  if (omiOnlyEnabled) {
    deviceItems = deviceItems.filter((item) => item.to.params.device === 'inverters')
  }

  return [
    {
      _name: 'CSidebarNavDropdown',
      name: i18n.t('layout.configure.device-settings.nav_title'),
      to: {
        name: 'config'
      },
      icon: 'cil-settings-alt',
      items: deviceItems
    }
  ]
}

const emsNav = ({ featureBlacklist = [], omiOnlyEnabled = false }) => {
  if (omiOnlyEnabled) return []

  return [
    {
      _name: 'CSidebarNavDropdown',
      name: i18n.t('layout.ems.nav_title'),
      to: {
        name: 'ems'
      },
      icon: 'cil-energy-circle',
      items: [
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('layout.ems.topology.nav_title'),
          to: {
            name: 'ems-topology'
          },
          icon: 'cil-vector'
        },
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('layout.ems.deviceReplacement.nav_title'),
          to: {
            name: 'ems-device-replacement'
          },
          icon: 'cil-swap-horizontal'
        },
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('layout.ems.energyService.nav_title'),
          to: {
            name: 'ems-energy-services-config'
          },
          icon: 'cil-description'
        },
        ...(!featureBlacklist.includes('economic_peak_shaving')
          ? [
              {
                _name: 'CSidebarNavItem',
                name: i18n.t('layout.ems.loadPeak.nav_title'),
                to: {
                  name: 'ems-rlm-config'
                },
                icon: 'cil-call-missed-outgoing'
              }
            ]
          : []),
        ...(!featureBlacklist.includes('time_of_use')
          ? [
              {
                _name: 'CSidebarNavItem',
                name: i18n.t('layout.ems.timeOfUse.nav_title'),
                to: {
                  name: 'ems-tou-config'
                },
                icon: 'cil-timer'
              }
            ]
          : []),
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('layout.ems.evalAlias.nav_title'),
          to: {
            name: 'ems-eval-aliases'
          },
          icon: 'cil-at'
        }
      ]
    }
  ]
}

export default function ({ featureBlacklist = [], omiOnlyEnabled = false }) {
  return [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavTitle',
          _children: [i18n.t('layout.predict.nav_sidebar_title')]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('layout.interfaces.nav_title'),
          to: {
            name: 'interface-info'
          },
          icon: 'cil-ethernet-alt',
          items: [
            {
              name: i18n.t('layout.network.nav_title'),
              to: {
                name: 'interface-info-ethernet'
              },
              icon: 'cil-sitemap'
            },
            {
              name: i18n.t('layout.io.nav_title'),
              to: {
                name: 'interface-info-io'
              },
              icon: 'cil-loop'
            },
            {
              name: i18n.t('layout.onewire.nav_title'),
              to: {
                name: 'interface-info-onewire'
              },
              icon: 'cil-input'
            }
          ]
        },
        {
          _name: 'CSidebarNavTitle',
          _children: [i18n.t('layout.configure.nav_sidebar_title')]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('layout.configure.system-settings.nav_title'),
          to: {
            name: 'settings'
          },
          icon: 'cil-settings',
          items: [
            {
              name: i18n.t('layout.license.nav_title'),
              to: {
                name: 'system-settings-license'
              },
              icon: 'cil-key-alt'
            },
            {
              name: i18n.t('layout.network.nav_title'),
              to: {
                name: 'system-settings-network'
              },
              icon: 'cil-sitemap'
            },
            // ENABLE IF: fw-upgrade should be available for all users
            // {
            //   name: i18n.t('layout.fwupgrade.nav_title'),
            //   to: {
            //     name: 'system-settings-fwupgrade'
            //   },
            //   icon: 'cil-update'
            // },
            ...(omiOnlyEnabled
              ? [
                  {
                    name: i18n.t('layout.omi.nav_title'),
                    to: {
                      name: 'system-settings-open-modbus-interface'
                    },
                    icon: 'cil-input'
                  }
                ]
              : [])
          ]
        },
        ...deviceNav({ featureBlacklist, omiOnlyEnabled }),
        ...emsNav({ featureBlacklist, omiOnlyEnabled }),
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('layout.users.nav_title'),
          to: '/users/management',
          icon: 'cil-group'
        }
      ]
    }
  ]
}
