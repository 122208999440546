<template>
  <!-- prettier-ignore -->
  <CCarousel
    :key="`strategy-chooser-with-active: ${activeStrategy}`"
    class="custom-nav-carousel-wrapper"
    arrows
    indicators
    animate
    :interval="0"
  >
    <template v-for="(id) of strategyIds">
      <CCarouselItem
        :key="id"
        :active="activeStrategy === id"
        :caption-header="id"
        :image="{ placeholderColor: 'var(--light)' }"
      >
        <div class="carousel-caption">
          <h5>
            <span class="d-block d-md-inline-block mr-2 mr-md-3 mb-2 align-middle text-primary">
              <i class="c-icon c-icon-custom-size">
                <CIcon name="cil-settings" size="lg" />
              </i>
            </span>
            <span class="d-block d-md-inline-block align-middle">
              <span class="d-none d-md-block text-muted text-left small mb-1" style="font-size: 0.6em;">
                {{ $t('ems.energyService.strategy.title') }}
              </span>
              <span class="d-block text-primary">
                {{ id }}
              </span>
            </span>
          </h5>
        </div>
      </CCarouselItem>
    </template>
    <CCarouselItem :active="activeStrategy === newStrategyId" :caption-header="newStrategyId" :image="{ placeholderColor: 'var(--light)' }" @slideToItem="onSwitch">
      <div class="carousel-caption">
        <h5 class="text-primary" >
          <span v-if="strategyIdToBeCloned">
            <span class="d-inline">
              {{ $t('ems.energyService.strategy.cloning') }}
            </span>
            <span class="d-none d-md-inline">
              {{ `"${strategyIdToBeCloned}"` }}
            </span>
            
            <CButton
              color="primary"
              size="sm" 
              variant="ghost"
              @click="onAbortCloning"
            >
              <CIcon size="lg" name="cilXCircle" />
            </CButton>
          </span>
          <span v-else>
            {{ $t('ems.energyService.strategy.new') }}
          </span>
        </h5>
        <CInput
          class="mb-1"
          type="text"
          :invalid-feedback="invalidFeedbackForStrategyName"
          :is-valid="$v.newStrategy.name.$dirty ? !$v.newStrategy.name.$error : null"
          :label="null"
          :placeholder="newStrategyFormPlaceholder"
          :tooltip-feedback="false"
          :value.sync="$v.newStrategy.name.$model"
        >
          <template #append>
            <CButton type="submit" size="sm" color="primary" @click.prevent="onCreateStrategy">
              <CIcon size="lg" name="cilPlusCircle" />
            </CButton>
          </template>
          <template #description>
            <small
              v-if="newStrategyFormDescription"
              class="d-none d-md-block form-text text-muted w-100"
              v-text="newStrategyFormDescription"
            ></small>
          </template>
        </CInput>
      </div>
    </CCarouselItem>
  </CCarousel>
</template>

<script>
import { mapGetters } from 'vuex'
import { newStrategyFormValidators } from '@/validations/ems-es-strategy-validators'
import { EMS_ENERGY_SERVICE_NEW_STRATEGY_ID } from '@/view-helper/ems/ems-energy-service-helper'

export default {
  name: 'EmsEsStrategyChooser',
  props: {
    activeStrategy: {
      required: true,
      type: String
    },
    newStrategyId: {
      required: false,
      type: String,
      default: EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
    }
  },
  data() {
    return {
      newStrategy: {
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['strategyIds', 'getCache']),
    invalidFeedbackForStrategyName() {
      if (!this.$v.newStrategy.name.$error) {
        return ''
      }
      if (!this.$v.newStrategy.name.required) {
        return this.$t('ems.energyService.strategy.form.name.invalidFeedback.required')
      } else if (!this.$v.newStrategy.name.words) {
        return this.$t('ems.energyService.strategy.form.name.invalidFeedback.words')
      } else if (!this.$v.newStrategy.name.unique) {
        return this.$t('ems.energyService.strategy.form.name.invalidFeedback.uniqueness')
      } else if (!this.$v.newStrategy.name.reserved) {
        return this.$t('ems.energyService.strategy.form.name.invalidFeedback.reserved')
      } else {
        return ''
      }
    },
    newStrategyFormDescription() {
      if (this.$v.newStrategy.name.$error) {
        return null
      }

      if (this.strategyIdToBeCloned) {
        return this.$t('ems.energyService.strategy.form.name.descriptionClone')
      }

      return this.$t('ems.energyService.strategy.form.name.description')
    },
    newStrategyFormPlaceholder() {
      if (this.strategyIdToBeCloned) {
        return this.$t('ems.energyService.strategy.form.name.placeholderClone')
      }

      return this.$t('ems.energyService.strategy.form.name.placeholder')
    },
    strategyIdToBeCloned() {
      return this.getCache('strategyIdToBeCloned')
    }
  },
  validations() {
    return {
      newStrategy: newStrategyFormValidators({ existingNames: this.strategyIds })
    }
  },
  methods: {
    onAbortCloning() {
      this.$emit('abort:strategy-cloning')
    },
    onCreateStrategy() {
      this.$v.newStrategy.$touch()
      if (this.$v.newStrategy.$anyError) {
        return
      }
      this.$emit('create:strategy', Object.assign({}, this.newStrategy))
      this.newStrategy.name = ''
      this.$v.newStrategy.$reset()
    },
    onSwitch(e) {
      // actually this event is emitted for each CCarouselItem, hence it is sufficiently to register for the first item only
      const id = e.captionHeader
      if (!id) {
        this.$log.error('Detected an EMS Energy Service Strategy without ID (caption-header). This is not supported.')
        return
      }
      this.$emit('switched:strategy', id)
    }
  }
}
</script>
