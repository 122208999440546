<template>
  <CCard class="col-sm-12 col-xl-10 card-columns px-0">
    <ApxCardHeader icon="cil-group" :title="$t(`${tBasePath}.pageTitle`)" />
    <!-- BEGIN: Show Loading Indicator -->
    <vue-loading
      v-if="isLoading"
      class="my-5"
      type="spiningDubbles"
      :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
    />
    <!-- END: Show Loading Indicator -->
    <!-- BEGIN: Show main user management view -->
    <CCardGroup v-else class="mt-4 px-3">
      <!-- BEGIN: Overview of all registered users  -->
      <CCard class="shadow-none p-1 flat-card mb-0">
        <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
          <CCardTitle class="mb-0 font-md">
            <h5>
              {{ $t(`${tBasePath}.usersOverview.title`) }}
            </h5>
          </CCardTitle>
        </CCardHeader>
        <CCardBody class="mt-0 pb-3 col-12">
          <!-- BEGIN: Table  -->
          <!-- prettier-ignore -->
          <div class="mt-0 mb-1 col-12">
            <CDataTable
              class="table-sm p-2"
              :items="users"
              :fields="fields"
              hover
              border
              sorter
              :sorter-value="{ column: 'username', asc: true }"
            >
              <template #remove_user="{item}">
                <td class="align-middle text-center">
                  <CButton
                    color="danger"
                    square
                    size="sm"
                    :disabled="isUserDeleteDsabled"
                    @click="onRemoveUserAccount(item)"
                  >
                    <CIcon name="cil-trash"/>
                  </CButton>
                </td>
              </template>
              <template #no-items-view>
                <tbody class="d-flex align-content-center">
                  <!-- prettier-ignore -->
                  <svg
                    id="svg-inner"
                    x="0"
                    y="0"
                    width="50%"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <CIcon name="cilBan" :height="100"/>
                    <text
                      x="50%"
                      y="80%"
                      style="font-size: 12px; fill: #ff0000;"
                      dominant-baseline="hanging"
                      text-anchor="middle"
                    >
                      <tspan>
                        {{ $t(`${tBasePath}.usersOverview.noUsers`) }}
                      </tspan>
                    </text>
                  </svg>
                </tbody>
              </template>
            </CDataTable>
            <!-- BEGIN: Help Text to inform that last user cannot be deleted -->
            <CCardText
              v-if="isUserDeleteDsabled"
              class="small m-0 p-0 pl-2 d-flex justify-content-start"
            >
              <p class="d-block d-sm-none mt-1">
                <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
              </p>
              <p class="text-muted text-break d-none d-sm-block">
                {{ $t(`${tBasePath}.usersOverview.description`) }}
              </p>
              <p v-if="showInfoDescription" class="ml-3 text-muted text-break d-block d-sm-none">
                {{ $t(`${tBasePath}.usersOverview.description`) }}
              </p>
            </CCardText>
            <!-- END: Help Text to inform that last user cannot be deleted   -->
          </div>
          <!-- END: Table  -->
        </CCardBody>
      </CCard>
      <!-- END: Overview of all registered users   -->
      <!-- ================================ -->
      <!-- ================================ -->
      <!-- BEGIN: Create new user -->
      <CCard class="shadow-none p-1 flat-card mb-0">
        <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
          <CCardTitle class="mb-0 font-md">
            <h5>
              {{ $t(`${tBasePath}.createUserForm.title`) }}
            </h5>
          </CCardTitle>
        </CCardHeader>
        <CCardBody class="mt-0 pb-3 col-12">
          <!-- BEGIN: Toggle Help Text -->
          <CCardText class="small m-0 p-0 d-flex justify-content-start">
            <p class="d-block d-sm-none mt-1">
              <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
            </p>
            <p class="text-muted text-break d-none d-sm-block">
              {{ $t(`${tBasePath}.createUserForm.description`) }}
            </p>
            <p v-if="showInfoDescription" class="ml-3 text-muted text-break d-block d-sm-none">
              {{ $t(`${tBasePath}.createUserForm.description`) }}
            </p>
          </CCardText>
          <!-- END:   Toggle Help Text -->
          <!-- BEGIN: Form  -->
          <div class="mt-0 mb-1 col-12">
            <CForm autocomplete="off" @submit.prevent>
              <!-- BEGIN: Username Input -->
              <CInput
                class="custom-form-label"
                :value.sync="$v.createUserForm.username.$model"
                :label="$t(`${tBasePath}.createUserForm.username.label`)"
                :placeholder="$t(`${tBasePath}.createUserForm.username.placeholder`)"
                :is-valid="
                  createUserForm.username && $v.createUserForm.username.$dirty
                    ? !$v.createUserForm.username.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('username')"
                :horizontal="styling.horizontal"
                type="text"
              >
                <template #description>
                  <small class="form-text text-muted d-none d-sm-block">
                    {{ $t(`${tBasePath}.createUserForm.username.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
                    {{ $t(`${tBasePath}.createUserForm.username.description`) }}
                  </small>
                </template>
              </CInput>
              <!-- END: Username Input -->
              <!-- BEGIN: Password Input -->
              <CInput
                autocomplete="new-password"
                class="custom-form-label"
                :value.sync="$v.createUserForm.password.$model"
                :label="$t(`${tBasePath}.createUserForm.password.label`)"
                :placeholder="$t(`${tBasePath}.createUserForm.password.placeholder`)"
                :is-valid="
                  createUserForm.password && $v.createUserForm.password.$dirty
                    ? !$v.createUserForm.password.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('password')"
                :horizontal="styling.horizontal"
                :type="showPassword.password ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.password"
                    @togglePwdVisibility="showPassword.password = !showPassword.password"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
                <!-- BEGIN: Description for password -->
                <template #description>
                  <!-- TODO: check if this can be achieved in a different manner. Do this for the password confirm field as well -->
                  <small class="form-text text-muted d-none d-sm-block col-12 px-0">
                    {{ $t(`${tBasePath}.createUserForm.password.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none p-0 m-0">
                    {{ $t(`${tBasePath}.createUserForm.password.description`) }}
                  </small>
                </template>
                <!-- END: Description for password -->
              </CInput>
              <!-- END: Password Input -->
              <!-- BEGIN: PasswordConfirm Input-->
              <CInput
                class="custom-form-label"
                :value.sync="$v.createUserForm.passwordConfirm.$model"
                :label="$t(`${tBasePath}.createUserForm.passwordConfirm.label`)"
                :placeholder="$t(`${tBasePath}.createUserForm.passwordConfirm.placeholder`)"
                :is-valid="
                  createUserForm.passwordConfirm && $v.createUserForm.passwordConfirm.$dirty
                    ? !$v.createUserForm.passwordConfirm.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('passwordConfirm')"
                :horizontal="styling.horizontal"
                :type="showPassword.passwordConfirm ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.passwordConfirm"
                    @togglePwdVisibility="showPassword.passwordConfirm = !showPassword.passwordConfirm"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
                <!-- BEGIN: Description for Password Confirm -->
                <template #description>
                  <small class="form-text text-muted col-12 px-0">
                    <!-- Note: This html white space character helps to avoid weird line break behavior of the password visibility toggle icon  -->
                    &zwnj;
                  </small>
                </template>
                <!-- END: Description for Password Confirm -->
              </CInput>
              <!-- END:   PasswordConfirmInput-->
              <!-- BEGIN:   Action Buttons  -->
              <!-- prettier-ignore -->
              <div class="mt-3 form-row p-2">
                <CButton
                  class="mr-2"
                  size="sm"
                  color="primary"
                  :disabled="$v.createUserForm.$invalid"
                  @click="createUser"
                >
                  {{ $t('main.addBtn') }}
                </CButton>

                <CButton
                  class="ml-auto"
                  size="sm"
                  color="danger"
                  @click="cancel"
                >
                  {{ $t('main.cancelBtn') }}
                </CButton>
              </div>
              <!-- END:   Action Buttons  -->
            </CForm>
          </div>
          <!-- END: Form  -->
        </CCardBody>
      </CCard>
      <!-- END: Create new user -->
    </CCardGroup>
    <!-- END: Show main user management view -->
    <!-- BEGIN: DeleteConfirmationModal -->
    <ConfirmationModal
      :visible.sync="showConfirmDeleteModal"
      color="danger"
      size="sm"
      :title="$t('management.user.accountSettings.confirmationModal.deleteAccount.title')"
      @update:confirmation="deleteUserAccount({ confirmed: $event })"
    >
      {{ $t('management.user.accountSettings.confirmationModal.deleteAccount.content') }}
    </ConfirmationModal>
    <!-- END:  DeleteConfirmationModal -->
  </CCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import HelpBtn from '@/components/snippets/help-btn'
import PwdVisibilityToggle from '@/components/snippets/pwd-visibility-toggle'

import { newAlertNotificationMsg, newToastNotificationMsg } from '@/store/modules/notifications'
import { createUserValidations } from '@/validations/user-settings-validators'

export default {
  name: 'UserManagement',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    HelpBtn,
    PwdVisibilityToggle
  },
  data() {
    return {
      createUserForm: {
        username: null,
        password: null,
        passwordConfirm: null
      },
      showConfirmDeleteModal: false,
      showInfoDescription: false,
      showPassword: {
        password: false,
        passwordConfirm: false
      },
      userToDelete: null
    }
  },
  computed: {
    ...mapState('users', ['users']),
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    fields() {
      return [
        {
          key: 'username',
          label: this.$t(`${this.tBasePath}.usersOverview.userLabel`),
          _style: 'width:90%'
        },
        {
          key: 'remove_user',
          label: '',
          _style: 'width:10%;',
          sorter: false
        }
      ]
    },
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v.createUserForm[field][condition]
        }
        const base = `management.users.userManagement.createUserForm.${field}.invalidFeedback`
        let conditions = []
        if (field === 'username') {
          conditions = ['required', 'minLength']
        }
        if (field === 'password') {
          conditions = ['required', 'minLength', 'strongPasswordRequired']
        }
        if (field === 'passwordConfirm') {
          conditions = ['required', 'sameAsPassword']
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    isUserDeleteDsabled() {
      return this.users.length === 1
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-xl-5',
        input: 'col-12 col-xl-7'
      }
    }
    this.tBasePath = 'management.users.userManagement'
    this.initStore()
  },
  methods: {
    cancel() {
      this.clearForm()
      this.$v.createUserForm.$reset()
    },
    clearForm() {
      this.createUserForm.username = null
      this.createUserForm.password = null
      this.createUserForm.passwordConfirm = null
    },
    createUser() {
      this.$v.createUserForm.$touch()
      if (this.$v.createUserForm.$anyError) {
        return
      }

      this.$store.commit('apiLoadingStatus/STARTED')

      const username = this.createUserForm.username
      const password = this.createUserForm.password

      this.$store
        .dispatch('users/createUser', { username, password })
        .then(() => {
          this.onSuccess('createSuccess')
          this.$log.debug('Succesfully created a new user')

          this.clearForm()
        })
        .catch((err) => {
          console.log('create error: ', err)
          this.$log.warn(err)
          this.onServerError(err, 'createError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    deleteUserAccount({ confirmed }) {
      if (!confirmed) {
        return
      }
      this.showConfirmDeleteModal = false

      const username = this.userToDelete
      this.$store
        .dispatch('users/deleteUser', { username })
        .then(() => {
          this.onSuccess('deleteSuccess')
          this.$log.debug('Succesfully removed user')
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err, 'deleteError')
        })
        .finally(() => {
          this.userToDelete = null

          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('users/getUsers', {})
        .then(() => {
          this.$log.debug('Succesfully fetched list of all users.')
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err, 'initError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onRemoveUserAccount(item) {
      this.userToDelete = item.username
      this.showConfirmDeleteModal = true
    },
    onServerError(err, type) {
      const tErrorPath = 'api.errors.users'
      let content

      if (type === 'initError') {
        content = this.$t(`${tErrorPath}.init`, { errorCode: err.code })
      } else if (type === 'createError') {
        content = this.$t(`${tErrorPath}.create`, { errorCode: err.code })
      } else if (type === 'deleteError') {
        content = this.$t(`${tErrorPath}.delete`, { errorCode: err.code })
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 8000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content: content
        })
      )
    },
    onSuccess(type) {
      let content

      if (type === 'createSuccess') {
        content = this.$t(`${this.tBasePath}.notifications.create`)
      } else if (type === 'deleteSuccess') {
        content = this.$t(`${this.tBasePath}.notifications.delete`)
      }

      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'success',
          content: content,
          show: 8
        })
      )
    }
  },
  validations: {
    createUserForm: createUserValidations()
  }
}
</script>

<style scoped>
#svg-outer {
  display: flex;
  align-items: center;
  background-color: lightgray;
  height: 100%;
}

#svg-inner {
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 0;
  display: block;
}
</style>
