<template>
  <div class="py-2">
    <!-- BEGIN: Power Watts -->
    <p class="d-flex justify-content-between mb-1">
      <small class="font-weight-bold text-primary">
        {{ $t('ems.energyService.config.form.targetPower.powerWatts.label') }}
      </small>
      <small class="ml-1 mr-0 pl-0 pr-0 text-break text-primary">
        <em> {{ powerWatts }} </em>
        <em>W</em>
      </small>
    </p>
    <!-- END: Power Watts -->
    <!-- BEGIN: Power at Position in Topology -->
    <EsPreviewPositionInTopology :position-in-topology="positionInTopology" />
    <!-- END: Power at Position in Topology -->
  </div>
</template>

<script>
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'
import EsPreviewPositionInTopology from './es-preview-position-in-topology'

export default {
  name: 'EsPreviewTargetPower',
  components: {
    EsPreviewPositionInTopology
  },
  props: {
    powerWatts: {
      required: true,
      type: Number
    },
    positionInTopology: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    translatePhysicalDevice
  }
}
</script>
