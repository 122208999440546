<template>
  <CLink class="input-password-visibility-action_light" :disabled="disabled" @click="$emit('togglePwdVisibility')">
    <CIcon :name="showPassword ? hideIcon : showIcon" size="sm" :class="{ 'text-muted': disabled }" />
  </CLink>
</template>

<script>
export default {
  name: 'PwdVisibilityToggle',
  props: {
    showPassword: {
      type: Boolean
    },
    showIcon: {
      type: String,
      default: 'cis-eye'
    },
    hideIcon: {
      type: String,
      default: 'cis-eye-slash'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
