/** @module */

import logger from '@/logger'

/**
 * Starts or creates or stops a stream
 *
 * @function
 *
 */
export function startStopStream({ call, stop, starter, name }) {
  name = name || ''

  if (!stop && call && call.stream.running) {
    logger.info(`Stream ${name} is already runnging.`)
  } else if (!stop && call && !call.stream.running) {
    call.perform()
    logger.info(`Started stream ${name}.`)
  } else if (!stop) {
    call = starter()
    logger.info(`Created stream ${name}.`)
  } else if (call) {
    call.close()
    logger.info(`Closed stream ${name}`)
  } else {
    logger.warn(`Failed to stop stream ${name}. No stream found.`)
  }

  return call
}
