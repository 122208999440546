<template>
  <FwUpgradeViewLayout icon="cil-notes" :title="$t('fwupgrade.log.title')">
    <div class="d-flex justify-content-end justify-content-sm-start">
      <!-- prettier-ignore -->
      <CButton
        class="mx-2"
        color="primary"
        size="sm"
        :disabled="loading"
        @click="reload"
      >
        {{ $t(`fwupgrade.log.reload`) }}
      </CButton>
    </div>
    <CDataTable
      :items="sortedAndfilteredLog"
      :fields="fields"
      column-filter
      items-per-page-select
      :items-per-page="20"
      hover
      :sorter="{ external: true, resetable: false }"
      :sorter-value="{ column: 'time', asc: sorting.time === 'asc' ? true : false }"
      pagination
      @update:sorter-value="onSorting"
    >
      <template #time="{ item }">
        <td class="align-middle">
          {{ toDateString(item.time) }}
        </td>
      </template>
      <template #state="{ item }">
        <td class="align-middle">
          {{ toStateString(item.state) }}
        </td>
      </template>
      <template #state-filter="{}">
        <!-- prettier-ignore -->
        <CSelect
          :value.sync="filter.state"
          :options="stateFilterOptions"
          class="mb-0"
          size="sm"
          :placeholder="''"
        />
      </template>
      <template #no-items-view="{}">
        {{ '' }}
      </template>
    </CDataTable>
  </FwUpgradeViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import FwUpgradeViewLayout from '@/components/fw-upgrade/fw-upgrade-view-layout'

import { buildI18nErrorMsg } from '@/view-helper/fw-upgrade/fw-upgrade-error'
import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'FwUpgradeLog',
  components: {
    FwUpgradeViewLayout
  },
  data() {
    return {
      filter: {
        state: ''
      },
      sorting: {
        time: 'desc'
      }
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loading']),
    ...mapGetters('fwupgrader', ['log']),
    ...mapGetters('user', ['lang']),
    fields() {
      return [
        {
          key: 'time',
          label: this.$t('fwupgrade.log.label.time'),
          filter: false,
          sorter: true,
          _style: 'min-width:180px'
        },
        {
          key: 'state',
          label: this.$t('fwupgrade.log.label.state'),
          filter: true,
          sorter: false,
          _style: 'min-width:160px'
        },
        {
          key: 'message',
          label: this.$t('fwupgrade.log.label.message'),
          filter: false,
          sorter: false,
          _style: 'min-width:400px;'
        }
      ]
    },
    sortedAndfilteredLog() {
      let log = this.log
      if (this.filter.state !== '') {
        log = log.filter((e) => e.state === this.filter.state)
      }

      let order
      switch (this.sorting.time) {
        case 'asc':
          order = 1
          break
        case 'desc':
          order = -1
          break
        default:
          order = null
      }

      if (order) {
        log = log.sort((a, b) => {
          // moment does support `>,<,...`
          if (a.time > b.time) {
            return order
          } else if (a.time < b.time) {
            return -order
          } else {
            return 0
          }
        })
      }

      return log
    },
    stateFilterOptions() {
      const opts = [{ value: '', label: this.$t('fwupgrade.log.noFilter') }]
      const states = [
        'error',
        'upToDate',
        'upgradeAvailable',
        'partiallyUpgraded',
        'upgradeInProgress',
        'rollbackInProgress',
        'finalizeInProgress',
        'rebooting'
      ]
      states.forEach((s) => {
        opts.push({ value: s, label: this.$t('fwupgrade.log.state.' + s) })
      })
      return opts
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.reload()
    },
    toDateString(time) {
      time.locale(this.lang)
      return time.format('ll LTS')
    },
    toStateString(state) {
      return this.$t('fwupgrade.log.state.' + state)
    },
    onSorting(e) {
      switch (e.column) {
        case 'time':
          this.sorting.time = e.asc ? 'asc' : 'desc'
          break
        default:
          this.sorting.time = null
      }
    },
    async reload() {
      try {
        this.$store.commit('apiLoadingStatus/STARTED')
        await this.$store.dispatch('fwupgrader/reloadLog')
        this.$log.debug('Succesfully loaded fw-upgrader log.')
      } catch (err) {
        this.$log.error(err)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: false,
            type: 'danger',
            title: this.$t('fwupgrade.error.log.title'),
            content: buildI18nErrorMsg(err)
          })
        )
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    }
  }
}
</script>
