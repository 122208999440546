<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <p style="white-space: pre-line">
      {{ $t(`${tBasePath}.description`) }}
    </p>
    <CForm @submit.prevent>
      <CSelect
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.target.label`)"
        :description="$t(`${tBasePath}.target.description`)"
        :is-valid="$v.target.$dirty && $v.target.$error ? false : null"
        :invalid-feedback="$t(`${tBasePath}.target.invalidFeedback`)"
        :options="targetOpts"
        :value="$v.target.$model"
        @update:value="onUpdateTarget"
      />
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { formProps as props } from './form-props'
import { vppGridBatNodeFormValidators } from '@/validations/ems-es-decision-tree-validators'
import { EMS_VPP_TARGET } from '@/view-helper/ems/ems-energy-service-helper'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'

export default {
  name: 'EsVppGridBatNodeForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      target: null,
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry']),
    targetOpts() {
      const opts = [{ value: null, label: this.$t(`${this.tBasePath}.target.placeholder`) }]

      EMS_VPP_TARGET.forEach((gs) => {
        opts.push({
          value: gs,
          label: this.$t(`${this.tBasePath}.target.${gs}`)
        })
      })

      return opts
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.vppGridBatTargetIs'
    this.entry = this.getDecisionTreeEntry(this.idx)
    this.init()
  },
  validations() {
    return vppGridBatNodeFormValidators
  },
  methods: {
    init() {
      if (this.entry) {
        this.target = this.entry.params.decisionNodeParams.target
      }
    },
    onUpdateTarget(target) {
      this.$v.target.$model = target
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.showFailureAlert = false
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      this.$emit('confirmed', {
        idx: this.idx,
        decisionNodeType: 'vppGridBatTargetIs',
        params: { target: this.target }
      })
    }
  }
}
</script>
