/** @module logger */

import Vue from 'vue'
import VueLogger from 'vuejs-logger'
const isProduction = process.env.NODE_ENV === 'production'

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  separator: '--',
  showConsoleColors: true
}

Vue.use(VueLogger, options)

// TODO: Somehow, vuejs-logger is cracy slow during testing. Replace the `vuejs-logger` or understand this issue.
const logger = console
logger.fatal = console.error

/**
 * Logs to Browser console.
 * Inside any Vue-component available by default via `this.$log`.
 * Allowed log-levels are `debug, info, warn, error, fatal`.
 *
 * See [vuejs-logger]{@link https://github.com/justinkames/vuejs-logger} for details.
 *
 */
export default logger
