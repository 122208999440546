<template>
  <ApxDeviceConfig class="col-12 col-xl-10 pb-4" @show-alert="showAlert" />
</template>

<script>
import ApxDeviceConfig from '@/components/apx-config-forms/apx-device-config'
import { newAlertNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'DeviceNewEdit',
  components: { ApxDeviceConfig },
  methods: {
    showAlert(alert) {
      switch (alert) {
        case 'failed':
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'danger',
              content: this.$t('config.device.form.formError'),
              show: true
            })
          )
          break
        case 'success':
          this.$store.commit('notifications/PRESERVE_ALERT', true)
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'success',
              content: this.$t('config.device.form.formSuccess'),
              show: 8
            })
          )
          break
      }
    }
  }
}
</script>
