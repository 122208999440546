<template>
  <!-- prettier-ignore -->
  <CCard class="col-12 mt-2 pb-0" border-color="primary">
    <CCardBody class="mb-0 mt-0 pt-2 pb-1 pl-0 pr-0">
      <!-- TODO: handle controllable process values -->
      <p class="small card-text">
        {{ $t('config.device.status.processValuesFilter.lastUpdate') }}:
        {{ lastUpdate | formatToLocalDatetime }}
      </p>
      <div class="h5 text-muted text-right mb-2">
        {{ reference | stripOfLogicalDeviceName }}
      </div>
      <div class="h4 mb-0">
        {{ value }}
        <small
          v-if="unitSymbol"
          class="h6 text-muted text-uppercase font-weight-bold pl-3"
        >
          {{ unitSymbol }}
        </small>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { stripOfLogicalDeviceName } from '@/view-helper/filters/iec-str-formatter'

export default {
  name: 'ApxDeviceProcessValue',
  filters: {
    stripOfLogicalDeviceName
  },
  props: {
    lastUpdate: {
      type: String,
      required: false,
      default: ''
    },
    reference: {
      type: String,
      required: true
    },
    value: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    unitSymbol: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
