<template>
  <CCardHeader :class="classHeader">
    <CCardTitle :class="classTitle" :tag="tag">
      <template v-if="icon">
        <CIcon class="card-header-icon" :name="icon" size="xl" />
      </template>
      <span>{{ title }}</span>
      <slot></slot>
    </CCardTitle>
  </CCardHeader>
</template>

<script>
export default {
  name: 'ApxCardHeader',
  props: {
    icon: {
      required: false,
      type: String,
      default: ''
    },
    title: {
      type: [String],
      default: ''
    },
    tag: {
      required: false,
      type: String,
      default: 'h4'
    },
    classHeader: {
      required: false,
      type: [String, Object],
      default: undefined
    },
    classTitle: {
      required: false,
      type: [String, Object],
      default: 'mb-0'
    }
  }
}
</script>
