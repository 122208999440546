import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import logger from '@/logger'
import { CACHE_IDS } from '@/store/modules/cache'
import { eventBus } from '@/view-helper/event-bus'
import ApxMainContainer from '@/layout/main-container'
import DeviceReplacement from '@/views/ems/device-replacement'
import DeviceNewEdit from '@/views/configurations/device-new-edit'
import DeviceOperate from '@/views/configurations/device-operate'
import Devices from '@/views/configurations/devices'
import DeviceStatus from '@/views/configurations/device-status'
import EnergyServicesConfig from '@/views/ems/energy-services-config'
import EnergyServicesConfigActivation from '@/views/ems/energy-services-config-activation'
import EnergyServicesConfigStrategies from '@/views/ems/energy-services-config-strategies'
import EnergyServicesConfigSubmission from '@/views/ems/energy-services-config-submission'
import Ethernet from '@/views/apx-interfaces/ethernet'
import EvalAliases from '@/views/ems/eval-aliases'
import FwUpgrade from '@/views/fw-upgrade.vue'
import IOs from '@/views/apx-interfaces/io'
import License from '@/views/license'
import Login from '@/views/login'
import NetworkConfig from '@/views/configurations/network-config'
import OpenModbusInterfaceConfig from '@/views/configurations/open-modbus-interface-config'
import OneWire from '@/views/apx-interfaces/one-wire'
import Register from '@/views/register'
import RlmConfig from '@/views/ems/rlm-config'
import Toplogy from '@/views/ems/topology'
import TouConfig from '@/views/ems/tou-config'
import UserMangement from '@/views/user-management'
import UserPuk from '@/views/user-puk'
import UserSettings from '@/views/user-settings'

Vue.use(VueRouter)

const ROUTES_NOT_ACCESSIBLE_IF_LOGGED_IN = ['login', 'puk', 'register']

const router = new VueRouter({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: {
        name: 'interface-info'
      },
      component: ApxMainContainer,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'interface-info',
          name: 'interface-info',
          redirect: {
            name: 'interface-info-ethernet'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'ethernet',
              name: 'interface-info-ethernet',
              component: Ethernet
            },
            {
              path: 'io',
              name: 'interface-info-io',
              component: IOs
            },
            {
              path: 'one-wire',
              name: 'interface-info-onewire',
              component: OneWire
            }
          ]
        },
        {
          path: 'system-settings',
          name: 'system-settings',
          redirect: {
            name: 'system-settings-network'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'ethernet',
              name: 'system-settings-network',
              component: NetworkConfig
            },
            {
              path: 'license',
              name: 'system-settings-license',
              component: License
            },
            {
              path: 'omi',
              name: 'system-settings-open-modbus-interface',
              component: OpenModbusInterfaceConfig
            },
            {
              path: 'fwupgrade',
              name: 'system-settings-fwupgrade',
              component: FwUpgrade
            }
          ]
        },
        {
          path: 'device-settings',
          name: 'config',
          redirect: {
            name: 'config-devices',
            params: {
              device: 'inverters'
            }
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':device',
              name: 'config-devices',
              component: Devices,
              props: true
            },
            {
              path: ':device/new',
              name: 'config-device-new',
              component: DeviceNewEdit,
              props: true
            },
            {
              path: ':device/edit/:id',
              name: 'config-device-edit',
              component: DeviceNewEdit,
              props: true
            },
            {
              path: ':device/status/:id',
              name: 'config-device-status',
              component: DeviceStatus,
              props: true
            },
            {
              path: ':device/operate/:id',
              name: 'config-device-operate',
              component: DeviceOperate,
              props: true
            }
          ]
        },
        {
          path: 'ems',
          name: 'ems',
          redirect: {
            name: 'ems-energy-services'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'topology',
              name: 'ems-topology',
              component: Toplogy
            },
            {
              path: 'device-replacement',
              name: 'ems-device-replacement',
              component: DeviceReplacement
            },
            {
              path: 'energy-services',
              name: 'ems-energy-services',
              redirect: {
                name: 'ems-energy-services-config'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: 'config',
                  name: 'ems-energy-services-config',
                  component: EnergyServicesConfig,
                  children: [
                    {
                      path: 'strategies',
                      name: 'ems-energy-services-config-strategies',
                      component: EnergyServicesConfigStrategies,
                      meta: { cache: 'emsEnergyServicesConfig' }
                    },
                    {
                      path: 'activation',
                      name: 'ems-energy-services-config-activation',
                      component: EnergyServicesConfigActivation,
                      meta: { cache: 'emsEnergyServicesConfig' }
                    },
                    {
                      path: 'submission',
                      name: 'ems-energy-services-config-submission',
                      component: EnergyServicesConfigSubmission
                    }
                  ]
                }
              ]
            },
            {
              path: 'rlm-config',
              name: 'ems-rlm-config',
              component: RlmConfig
            },
            {
              path: 'tou-config',
              name: 'ems-tou-config',
              component: TouConfig
            },
            {
              path: 'eval-aliases',
              name: 'ems-eval-aliases',
              component: EvalAliases
            }
          ]
        }
      ]
    },
    {
      path: '/users',
      name: 'users',
      component: ApxMainContainer,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: { requiresAuth: false }
        },
        {
          path: 'register',
          name: 'register',
          component: Register,
          meta: { requiresAuth: false }
        },
        {
          path: 'puk',
          name: 'puk',
          component: UserPuk,
          meta: { requiresAuth: false }
        },
        {
          path: 'settings',
          name: 'user-settings',
          component: UserSettings,
          meta: { requiresAuth: true }
        },
        {
          path: 'management',
          name: 'user-management',
          component: UserMangement,
          meta: { requiresAuth: true }
        }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['user/isLoggedIn']

  if (!isLoggedIn && to.matched.some((record) => record.meta.requiresAuth)) {
    next('/users/login')
  } else if (isLoggedIn && ROUTES_NOT_ACCESSIBLE_IF_LOGGED_IN.includes(to.name)) {
    next('/')
  } else {
    // cache data, before navigate away
    let cache = from.meta.cache
    if (cache) {
      if (typeof cache === 'string') {
        cache = [cache]
      }
      logger.info(`Will cache store-module(s)/path(s) "${cache}"`)
      cache.forEach((c) => {
        const id = CACHE_IDS[c]
        if (!id) {
          logger.warn(`Failed to cache module/path ${c}, because no cache ID is defined.`)
        }
        store.dispatch('cache/cacheStoreState', {
          path: c,
          id: id
        })
      })
    }

    next()
  }
})

// TODO: in the future unauthorized should be handled separatelly from unauthenticated
eventBus.$on('grpc:unary-permission-denied', handleUnauthenticated)
eventBus.$on('grpc:unary-unauthenticated', handleUnauthenticated)

function handleUnauthenticated(e) {
  // some kind of hack:
  // let the gRPC caller some time to process the error, then redirect
  // this avoids some runtime errors
  setTimeout(() => {
    store.commit('notifications/DELETE_ALL_TOASTS')
    store.commit('user/LOGOUT')
    router.push({ name: 'login' })
    // TODO: display some notifications
  }, 500)
}

export default router
