<template>
  <CCard class="information-card text-muted">
    <CCardBody>
      <CRow v-for="(infoRow, index) in convertInformationRows" :key="index">
        <CCol class="text-left">
          <p class="p-0">
            {{ infoRow.key }}
          </p>
        </CCol>
        <CCol class="text-right">
          <p class="p-0">
            {{ infoRow.value }}
          </p>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'ApxInterfaceInformationCard',
  props: {
    cardInfos: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    convertInformationRows() {
      const convertedInfos = []
      this.cardInfos.forEach((e) => {
        const key = e.split(' ')[0]
        convertedInfos.push({ key: key, value: e.slice(key.length, e.length) })
      })
      return convertedInfos
    }
  }
}
</script>
