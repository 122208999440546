<template>
  <!-- prettier-ignore -->
  <dl class="row">
    <dt class="col-sm-4">
      {{ title }}:
    </dt>
    <dd class="col-sm-6 text-muted font-weight-bold">
      {{ info || 'N/A' }}
    </dd>
    <dd class="col-sm-2"></dd>
  </dl>
</template>

<script>
export default {
  name: 'ApxDeviceStatusInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      default: 'N/A'
    }
  }
}
</script>
