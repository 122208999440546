<template>
  <div>
    <CAlert :show="showError" color="danger" close-button>
      {{ errorMsg }}
    </CAlert>
    <CCard>
      <ApxCardHeader icon="cil-at" :title="$t(`${tBasePath}.title`)" />
      <CCardBody class="border-bottom">
        <CCardTitle tag="h5">
          {{ $t(`${tBasePath}.titleCreate`) }}
        </CCardTitle>
        <CRow>
          <CCol md="8">
            <CInput
              v-model.trim="$v.createName.$model"
              :invalid-feedback="newNameError"
              :is-valid="createName !== '' ? !$v.createName.$invalid : null"
              :placeholder="$t(`${tBasePath}.namePlaceholder`)"
              size="sm"
              :lazy="true"
            />
          </CCol>
          <CCol md="4">
            <!--prettier-ignore-->
            <CButton
              class="float-right"
              color="success"
              size="sm"
              :disabled="$v.createName.$invalid"
              @click="createAlias"
            >
              {{ $t(`${tBasePath}.buttons.create`) }}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardBody>
        <CCardTitle tag="h5">
          {{ $t(`${tBasePath}.titleEdit`) }}
        </CCardTitle>
        <div v-if="loading" class="d-flex justify-content-around align-items-center">
          <CSpinner color="primary" />
        </div>
        <CRow v-else>
          <CCol md="8">
            <CSelect
              :options="aliasOpts"
              size="sm"
              :placeholder="$t(`${tBasePath}.select`)"
              :value.sync="$v.selected.$model"
              @change="loadSelectedAlias"
            />
          </CCol>
          <CCol md="4" class="d-flex align-items-center justify-content-end">
            <CButton
              class="ml-2"
              color="success"
              size="sm"
              :disabled="!(!$v.selected.$invalid && treeHasChanged)"
              @click="updateAlias"
            >
              {{ $t(`${tBasePath}.buttons.save`) }}
            </CButton>
            <!-- prettier-ignore -->
            <CButton
              class="ml-2"
              color="danger"
              size="sm"
              :disabled="!selected"
              @click="removeAlias"
            >
              {{ $t(`${tBasePath}.buttons.remove`) }}
            </CButton>
            <!-- prettier-ignore -->
            <CButton
              class="ml-2"
              color="danger"
              size="sm"
              :disabled="!treeHasChanged || !selected"
              @click="loadSelectedAlias"
            >
              {{ $t(`${tBasePath}.buttons.reset`) }}
            </CButton>
          </CCol>
        </CRow>
        <EvalTree v-if="selected" ref="evaltree" :append-menu-to-body="true" style="overflow: auto" />
      </CCardBody>
    </CCard>
    <ConfirmationModal
      :visible.sync="showDeleteConfirmationModal"
      color="danger"
      size="lg"
      :title="$t(`${tBasePath}.deleteConfirmationModal.title`)"
      @update:confirmation="onConfirmDeleteAlias({ confirmed: $event })"
    >
      {{ $t(`${tBasePath}.deleteConfirmationModal.content`) }}
    </ConfirmationModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import EvalTree from '@/components/ems/evalexp/eval-tree'
import { recursivelyValidateEvalTreeVue } from '@/view-helper/form-helpers'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'EvalAliases',
  components: {
    ApxCardHeader,
    EvalTree,
    ConfirmationModal
  },
  data() {
    return {
      createName: '',
      errorMsg: '',
      loading: true,
      selected: '',
      showError: false,
      showDeleteConfirmationModal: false
    }
  },
  computed: {
    ...mapGetters('emsEvalExpression', ['isValidTree', 'treeHasChanged']),
    ...mapGetters('emsEvalAliases', ['aliasNames']),
    aliasOpts() {
      return this.aliasNames.map((alias) => {
        return { value: alias, label: alias }
      })
    },
    newNameError() {
      if (!this.$v.createName.$invalid) {
        return ''
      } else if (!this.$v.createName.newValue) {
        return this.$t(`${this.tBasePath}.errors.duplicate`)
      } else if (!this.$v.createName.required) {
        return this.$t(`${this.tBasePath}.errors.nameRequired`)
      } else {
        // should not happen
        return 'DEBUG: Unkown error case'
      }
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.evalAlias'
  },
  mounted() {
    const aliasRsp = this.$store
      .dispatch('emsEvalAliases/getAliases')
      .then(() => {
        this.$log.debug('Succesfully fetched alias names.')
      })
      .catch((err) => {
        this.errorMsg = err.message
        this.showError = true
      })

    const timeseriesRsp = this.$store
      .dispatch('emsTimeseries/timeseriesesInit', {
        withTimestampValues: false
      })
      .then(() => {
        this.$log.debug('Succesfully fetched timeseries names.')
      })
      .catch((err) => {
        this.$log.error(err)
      })

    const sensorRsp = this.$store
      .dispatch('emsSensors/sensorInit', {})
      .then(() => {
        this.$log.debug('Succesfully fetched sensor names.')
      })
      .catch((err) => {
        this.$log.error(err)
      })

    Promise.all([aliasRsp, timeseriesRsp, sensorRsp]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    isValid() {
      if (this.$refs.evaltree) {
        return recursivelyValidateEvalTreeVue(this.$refs.evaltree)
      } else {
        return false
      }
    },
    createAlias() {
      this.showError = false

      if (!this.$v.createName.$invalid) {
        this.$store
          .dispatch('emsEvalAliases/createAlias', { name: this.$v.createName.$model })
          .then(() => {
            this.selected = this.createName
            this.loadSelectedAlias()
            this.createName = ''
          })
          .catch((err) => {
            this.errorMsg = err.message
            this.showError = true
          })
      }
    },
    removeAlias() {
      this.showError = false
      this.showDeleteConfirmationModal = true
    },
    onConfirmDeleteAlias({ confirmed }) {
      if (!confirmed) {
        return
      }
      if (!this.$v.selected.$invalid) {
        this.$store
          .dispatch('emsEvalAliases/removeAlias', { name: this.selected })
          .then(() => {
            this.selected = ''
          })
          .catch((err) => {
            this.errorMsg = err.message
            this.showError = true
          })
      }
    },
    loadSelectedAlias() {
      if (!this.$v.selected.$invalid) {
        this.$store
          .dispatch('emsEvalExpression/copyEvalExpressionFromAlias', { aliasName: this.selected })
          .catch((err) => {
            this.errorMsg = err.message
            this.showError = true
          })
      }
    },
    updateAlias() {
      this.showError = false

      if (!this.$v.selected.$invalid && this.isValid()) {
        this.$store
          .dispatch('emsEvalExpression/copyEvalExpressionToAlias', {
            aliasName: this.selected
          })
          .catch((err) => {
            this.errorMsg = err.message
            this.showError = true
          })
      } else if (!this.isValid()) {
        this.errorMsg = this.$t(`${this.tBasePath}.errors.invalidTree`)
        this.showError = true
      }
    }
  },
  validations: {
    selected: {
      required
    },
    createName: {
      required,
      newValue: function (value) {
        return !this.aliasNames.includes(value)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .eval-expression-tree-entry-container {
  min-width: 250px;
}
::v-deep .eval-expression-tree-container {
  display: flex;
}
</style>
