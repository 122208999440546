<template>
  <CCard class="col-sm-12 col-xl-10 card-columns">
    <CForm @submit.prevent>
      <ApxCardHeader icon="cis-settings" :title="$t(`${tBasePath}.pageTitle`)" />
      <!-- BEGIN: Show Loading Indicator -->
      <vue-loading
        v-if="isLoading"
        class="my-5"
        type="spiningDubbles"
        :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
      />
      <!-- END: Show Loading Indicator -->
      <CCardGroup v-else class="mt-4">
        <!-- BEGIN: Enter Current Password/Delete Account -->
        <CCard class="shadow-none p-1 flat-card mb-0">
          <CCardBody class="mt-0 pb-3 col-12">
            <!-- BEGIN: Current User Info -->
            <CCardText class="small m-0 p-0">
              <p class="text-muted text-break text-right">
                {{ $t('management.user.global.username.placeholder') }}:
                <span class="pl-0 pl-lg-2 text-monospace font-weight-bold text-info">
                  {{ username }}
                </span>
              </p>
            </CCardText>
            <!-- END: Current User Info -->
            <!-- BEGIN: Toggle Help Text -->
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="d-block d-sm-none mt-1">
                <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
              </p>
              <p class="text-muted text-break d-none d-sm-block">
                {{ $t(`${tBasePath}.description`) }}
              </p>
              <p v-if="showInfoDescription" class="ml-3 text-muted text-break d-block d-sm-none">
                {{ $t(`${tBasePath}.description`) }}
              </p>
            </CCardText>
            <!-- END:   Toggle Help Text -->
            <div class="mt-0 mb-1 col-12 border border-primary p-2 pr-3 rounded">
              <!-- BEGIN: currentPassword -->
              <CInput
                class="custom-form-label"
                :value.sync="$v.changeCredentialsForm.currentPassword.$model"
                :label="$t(`${tBasePath}.currentPassword.label`)"
                :placeholder="$t(`${tBasePath}.currentPassword.placeholder`)"
                :is-valid="
                  $v.changeCredentialsForm.currentPassword.$dirty
                    ? !$v.changeCredentialsForm.currentPassword.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('currentPassword')"
                :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
                :type="showPassword.currentPassword ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.currentPassword"
                    @togglePwdVisibility="showPassword.currentPassword = !showPassword.currentPassword"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
              </CInput>
              <!-- END: currentPassword -->
            </div>
            <!-- BEGIN: Delete Account -->
            <div class="mt-0 mb-1 col-12 border border-primary p-2 pr-3 rounded">
              <div class="pl-0 text-muted small pb-2 d-none d-sm-block">
                {{ $t(`${tBasePath}.deleteDescription`) }}
              </div>
              <div v-if="showInfoDescription" class="pl-0 text-muted small pb-2 d-block d-sm-none">
                {{ $t(`${tBasePath}.deleteDescription`) }}
              </div>
              <!-- prettier-ignore -->
              <CButton
                type="submit"
                size="sm"
                color="danger"
                block
                :disabled="allDisabled || isUserDeleteDsabled"
                @click="showConfirmDeleteModal = true"
              >
                {{ $t(`${tBasePath}.deleteAccountBtn`) }}
              </CButton>
              <div class="d-flex justify-content-start mt-2 mb-0"></div>
            </div>
            <!-- END: Delete Account  -->
          </CCardBody>
        </CCard>
        <!-- END: Enter Current Password/Delete Account -->
        <!-- ================================ -->
        <!-- ================================ -->
        <!-- BEGIN: Enter New Credentials -->
        <CCard class="shadow-none p-1 flat-card mb-0">
          <CCardBody class="mt-0 pb-3 col-12">
            <!-- BEGIN: Toggle Help Text -->
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="d-block d-sm-none mt-1">
                <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
              </p>
            </CCardText>
            <!-- END:   Toggle Help Text -->
            <div class="mt-0 mb-1 col-12 border border-primary p-2 pr-3 rounded">
              <!-- BEGIN: newUsername -->
              <CInput
                autocomplete="off"
                :class="allDisabled ? 'custom-form-label-disabled' : 'custom-form-label'"
                :value.sync="$v.changeCredentialsForm.newUsername.$model"
                :disabled="allDisabled"
                :description="$t(`${tBasePath}.newUsername.description`)"
                :label="$t(`${tBasePath}.newUsername.label`)"
                :placeholder="$t(`${tBasePath}.newUsername.placeholder`)"
                :is-valid="
                  changeCredentialsForm.newUsername && $v.changeCredentialsForm.newUsername.$dirty
                    ? !$v.changeCredentialsForm.newUsername.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('newUsername')"
                :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
                type="text"
              >
                <!-- BEGIN: Description for new Username -->
                <template #description>
                  <small class="form-text text-muted d-none d-sm-block col-12 px-0">
                    {{ $t(`${tBasePath}.newUsername.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none p-0 m-0">
                    {{ $t(`${tBasePath}.newUsername.description`) }}
                  </small>
                </template>
                <!-- END: Description for new Username -->
              </CInput>
              <!-- END: newUsername   -->
              <!-- BEGIN: newPassword -->
              <CInput
                autocomplete="new-password"
                :class="allDisabled ? 'custom-form-label-disabled' : 'custom-form-label'"
                :value.sync="$v.changeCredentialsForm.newPassword.$model"
                :disabled="allDisabled"
                :description="$t(`${tBasePath}.newPassword.description`)"
                :label="$t(`${tBasePath}.newPassword.label`)"
                :placeholder="$t(`${tBasePath}.newPassword.placeholder`)"
                :is-valid="
                  changeCredentialsForm.newPassword && $v.changeCredentialsForm.newPassword.$dirty
                    ? !$v.changeCredentialsForm.newPassword.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('newPassword')"
                :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
                :type="showPassword.password ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.password"
                    @togglePwdVisibility="showPassword.password = !showPassword.password"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
                <!-- BEGIN: Description for New Password -->
                <template #description>
                  <!-- TODO: check if this can be achieved in a different manner. Do this for the password confirm field as well -->
                  <small class="form-text text-muted d-none d-sm-block col-12 px-0">
                    {{ $t(`${tBasePath}.newPassword.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none p-0 m-0">
                    {{ $t(`${tBasePath}.newPassword.description`) }}
                  </small>
                </template>
                <!-- END: Description for New Password -->
              </CInput>
              <!-- END: newPassword   -->
              <!-- BEGIN: newPasswordConfirm -->
              <CInput
                autocomplete="new-password"
                :class="allDisabled ? 'custom-form-label-disabled' : 'custom-form-label'"
                :value.sync="$v.changeCredentialsForm.newPasswordConfirm.$model"
                :disabled="allDisabled"
                :label="$t(`${tBasePath}.newPasswordConfirm.label`)"
                :placeholder="$t(`${tBasePath}.newPasswordConfirm.placeholder`)"
                :is-valid="
                  changeCredentialsForm.newPassword && $v.changeCredentialsForm.newPasswordConfirm.$dirty
                    ? !$v.changeCredentialsForm.newPasswordConfirm.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('newPasswordConfirm')"
                :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
                :type="showPassword.passwordConfirm ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.passwordConfirm"
                    @togglePwdVisibility="showPassword.passwordConfirm = !showPassword.passwordConfirm"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
                <!-- BEGIN: Description for Password Confirm -->
                <template #description>
                  <small class="form-text text-muted col-12 px-0">
                    <!-- Note: This html white space character helps to avoid weird line break behavior of the password visibility toggle icon  -->
                    &zwnj;
                  </small>
                </template>
                <!-- END: Description for Password Confirm -->
              </CInput>
              <!-- END: newPasswordConfirm -->
              <!-- BEGIN: Action Buttons  -->
              <!-- prettier-ignore -->
              <div class="mt-3 form-row p-2">
                <CButton
                  class="mr-2"
                  size="sm"
                  color="primary"
                  :disabled="submitDisabled"
                  @click.prevent="showConfirmChangeModal = true"
                >
                  {{ $t('main.saveBtn') }}
                </CButton>

                <CButton
                  class="ml-auto"
                  size="sm"
                  color="danger"
                  @click="cancel"
                >
                  {{ $t('main.cancelBtn') }}
                </CButton>
              </div>
              <!-- END:   Action Buttons  -->
            </div>
          </CCardBody>
        </CCard>
        <!-- END: Enter New Credentials  -->
      </CCardGroup>
      <!-- BEGIN: ChangeConfirmationModal -->
      <ConfirmationModal
        :visible.sync="showConfirmChangeModal"
        color="danger"
        size="sm"
        :title="$t(`${tBasePath}.confirmationModal.changeUsernamePassword.title`)"
        @update:confirmation="changeUserCredentials({ confirmed: $event })"
      >
        {{ $t(`${tBasePath}.confirmationModal.changeUsernamePassword.content`) }}
      </ConfirmationModal>
      <!-- END: ChangeConfirmationModal -->
      <!-- BEGIN: DeleteConfirmationModal -->
      <ConfirmationModal
        :visible.sync="showConfirmDeleteModal"
        color="danger"
        size="sm"
        :title="$t(`${tBasePath}.confirmationModal.deleteAccount.title`)"
        @update:confirmation="deleteAccount({ confirmed: $event })"
      >
        {{ $t(`${tBasePath}.confirmationModal.deleteAccount.content`) }}
      </ConfirmationModal>
      <!-- END:  DeleteConfirmationModal -->
    </CForm>
  </CCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import HelpBtn from '@/components/snippets/help-btn'
import PwdVisibilityToggle from '@/components/snippets/pwd-visibility-toggle'

import { newToastNotificationMsg } from '../store/modules/notifications'
import { changeCredentialsValidators } from '@/validations/user-settings-validators'

export default {
  name: 'UserSettings',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    HelpBtn,
    PwdVisibilityToggle
  },
  data() {
    return {
      changeCredentialsForm: {
        newUsername: null,
        currentPassword: null,
        newPassword: null,
        newPasswordConfirm: null
      },
      showConfirmChangeModal: false,
      showConfirmDeleteModal: false,
      showInfoDescription: false,
      showPassword: {
        currentPassword: false,
        password: false,
        passwordConfirm: false
      }
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    ...mapGetters('user', ['username']),
    ...mapState('users', ['users']),
    allDisabled() {
      return !this.changeCredentialsForm.currentPassword
    },
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v.changeCredentialsForm[field][condition]
        }
        const base = `management.user.accountSettings.${field}.invalidFeedback`
        let conditions = []

        if (field === 'currentPassword') {
          conditions = ['required']
        }
        if (field === 'newUsername') {
          conditions = ['required', 'minLength', 'notSameAs']
        }
        if (field === 'newPassword') {
          conditions = ['required', 'minLength', 'strongPasswordRequired']
        }
        if (field === 'newPasswordConfirm') {
          conditions = ['required', 'sameAsPassword']
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    isUserDeleteDsabled() {
      return this.users.length === 1
    },
    submitDisabled() {
      return this.allDisabled || this.$v.changeCredentialsForm.$invalid
    }
  },
  created() {
    this.initStore()
    this.tBasePath = 'management.user.accountSettings'
  },
  methods: {
    cancel() {
      this.clearForm()
      this.$v.changeCredentialsForm.$reset()
    },
    changeUserCredentials({ confirmed }) {
      if (!confirmed) {
        return
      }

      this.showConfirmChangeModal = false
      this.$v.changeCredentialsForm.$touch()
      if (this.$v.changeCredentialsForm.$invalid) {
        return
      }

      const newUsername = this.changeCredentialsForm.newUsername
      const currentPassword = this.changeCredentialsForm.currentPassword
      const newPassword = this.changeCredentialsForm.newPassword

      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('user/updateUser', { currentUsername: this.username, currentPassword, newUsername, newPassword })
        .then(() => {
          this.$log.debug('Succesfully updated user credentials')

          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: 3000,
              type: 'success',
              title: this.$t('management.user.accountSettings.changeCredentialsTitle'),
              content: this.$t('api.success.changeCredentials')
            })
          )
          this.clearForm()
          this.$router.push({ name: 'login' })
        })
        .catch((err) => {
          this.onServerError(err, 'changeCredentialsError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    clearForm() {
      this.changeCredentialsForm.currentPassword = null
      this.changeCredentialsForm.newUsername = null
      this.changeCredentialsForm.newPassword = null
      this.changeCredentialsForm.newPasswordConfirm = null
    },
    deleteAccount({ confirmed }) {
      if (!confirmed) {
        return
      }

      this.$store.commit('apiLoadingStatus/STARTED')
      this.showConfirmDeleteModal = false

      this.$store
        .dispatch('user/deleteUser', { currentUsername: this.username })
        .then(() => {
          this.$log.debug('Succesfully deleted user')

          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: 3000,
              type: 'success',
              title: this.$t('management.user.accountSettings.changeCredentialsTitle'),
              content: this.$t('api.success.changeCredentials')
            })
          )
          this.clearForm()
          this.$router.push({ name: 'login' })
        })
        .catch((err) => {
          this.onServerError(err, 'deleteError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('users/getUsers', {})
        .then(() => {
          this.$log.debug('Succesfully fetched list of all users.')
        })
        .catch((err) => {
          this.onServerError(err, 'initError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err, type) {
      this.$log.warn(err)

      const tErrorPath = 'api.errors.users'
      let content

      if (type === 'initError') {
        content = this.$t(`${tErrorPath}.init`, { errorCode: err.code })
      } else if (type === 'changeCredentialsError') {
        content = this.$t(`${tErrorPath}.changeCredentials`, { errorCode: err.code })
      } else if (type === 'deleteError') {
        content = this.$t(`${tErrorPath}.delete`, { errorCode: err.code })
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 8000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content: content
        })
      )
    }
  },
  validations() {
    if (this.username) {
      return {
        changeCredentialsForm: changeCredentialsValidators({ currentUsername: this.username })
      }
    } else {
      return {}
    }
  }
}
</script>
