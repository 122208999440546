// source: ext/sherlock/network.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.sherlock.CheckConnectivityRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.CheckConnectivityResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetKnownDevicesRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetKnownDevicesResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetNetworkInfoRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.GetNetworkInfoResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.Network', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.Network.Type', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.NetworkDevice', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.NetworkServiceError', null, global);
goog.exportSymbol('proto.de.mypowergrid.sherlock.NetworkServiceError.Code', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetNetworkInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.displayName = 'proto.de.mypowergrid.sherlock.GetNetworkInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetNetworkInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.displayName = 'proto.de.mypowergrid.sherlock.GetNetworkInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.CheckConnectivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.CheckConnectivityRequest.displayName = 'proto.de.mypowergrid.sherlock.CheckConnectivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.CheckConnectivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.CheckConnectivityResponse.displayName = 'proto.de.mypowergrid.sherlock.CheckConnectivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetKnownDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.displayName = 'proto.de.mypowergrid.sherlock.GetKnownDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.GetKnownDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.displayName = 'proto.de.mypowergrid.sherlock.GetKnownDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.Network.displayName = 'proto.de.mypowergrid.sherlock.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.NetworkServiceError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.NetworkServiceError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.NetworkServiceError.displayName = 'proto.de.mypowergrid.sherlock.NetworkServiceError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.sherlock.NetworkDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.sherlock.NetworkDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.sherlock.NetworkDevice.displayName = 'proto.de.mypowergrid.sherlock.NetworkDevice';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    network: (f = msg.getNetwork()) && proto.de.mypowergrid.sherlock.Network.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetNetworkInfoRequest;
  return proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.Network;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.Network.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.Network.serializeBinaryToWriter
    );
  }
};


/**
 * optional Network network = 1;
 * @return {?proto.de.mypowergrid.sherlock.Network}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.getNetwork = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.Network} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.Network, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.Network|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest} returns this
*/
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.setNetwork = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoRequest} returns this
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoRequest.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.sherlock.NetworkServiceError.toObject(includeInstance, f),
    rawInfo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetNetworkInfoResponse;
  return proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.NetworkServiceError;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.NetworkServiceError.serializeBinaryToWriter
    );
  }
  f = message.getRawInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NetworkServiceError error = 1;
 * @return {?proto.de.mypowergrid.sherlock.NetworkServiceError}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.NetworkServiceError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.NetworkServiceError, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.NetworkServiceError|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string raw_info = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.getRawInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.GetNetworkInfoResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetNetworkInfoResponse.prototype.setRawInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.CheckConnectivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    network: (f = msg.getNetwork()) && proto.de.mypowergrid.sherlock.Network.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.CheckConnectivityRequest;
  return proto.de.mypowergrid.sherlock.CheckConnectivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.Network;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.Network.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.CheckConnectivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.Network.serializeBinaryToWriter
    );
  }
};


/**
 * optional Network network = 1;
 * @return {?proto.de.mypowergrid.sherlock.Network}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.getNetwork = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.Network} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.Network, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.Network|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest} returns this
*/
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.setNetwork = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityRequest} returns this
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityRequest.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.CheckConnectivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.sherlock.NetworkServiceError.toObject(includeInstance, f),
    rawInfo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.CheckConnectivityResponse;
  return proto.de.mypowergrid.sherlock.CheckConnectivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.NetworkServiceError;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.CheckConnectivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.NetworkServiceError.serializeBinaryToWriter
    );
  }
  f = message.getRawInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NetworkServiceError error = 1;
 * @return {?proto.de.mypowergrid.sherlock.NetworkServiceError}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.NetworkServiceError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.NetworkServiceError, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.NetworkServiceError|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} returns this
*/
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} returns this
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string raw_info = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.getRawInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.CheckConnectivityResponse} returns this
 */
proto.de.mypowergrid.sherlock.CheckConnectivityResponse.prototype.setRawInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesRequest}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetKnownDevicesRequest;
  return proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesRequest}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.sherlock.NetworkServiceError.toObject(includeInstance, f),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.de.mypowergrid.sherlock.NetworkDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.GetKnownDevicesResponse;
  return proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.sherlock.NetworkServiceError;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.sherlock.NetworkDevice;
      reader.readMessage(value,proto.de.mypowergrid.sherlock.NetworkDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.sherlock.NetworkServiceError.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.sherlock.NetworkDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional NetworkServiceError error = 1;
 * @return {?proto.de.mypowergrid.sherlock.NetworkServiceError}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.sherlock.NetworkServiceError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.sherlock.NetworkServiceError, 1));
};


/**
 * @param {?proto.de.mypowergrid.sherlock.NetworkServiceError|undefined} value
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated NetworkDevice devices = 2;
 * @return {!Array<!proto.de.mypowergrid.sherlock.NetworkDevice>}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.sherlock.NetworkDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.sherlock.NetworkDevice, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.sherlock.NetworkDevice>} value
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} returns this
*/
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.sherlock.NetworkDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice}
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.sherlock.NetworkDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.sherlock.GetKnownDevicesResponse} returns this
 */
proto.de.mypowergrid.sherlock.GetKnownDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.Network}
 */
proto.de.mypowergrid.sherlock.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.Network;
  return proto.de.mypowergrid.sherlock.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.Network}
 */
proto.de.mypowergrid.sherlock.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.sherlock.Network.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.sherlock.Network.Type = {
  UNSPECIFIED: 0,
  PROPERTY: 1,
  MAINTENANCE: 2,
  PUBLIC: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.de.mypowergrid.sherlock.Network.Type}
 */
proto.de.mypowergrid.sherlock.Network.prototype.getType = function() {
  return /** @type {!proto.de.mypowergrid.sherlock.Network.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.sherlock.Network.Type} value
 * @return {!proto.de.mypowergrid.sherlock.Network} returns this
 */
proto.de.mypowergrid.sherlock.Network.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.NetworkServiceError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.NetworkServiceError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.NetworkServiceError}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.NetworkServiceError;
  return proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.NetworkServiceError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.NetworkServiceError}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.de.mypowergrid.sherlock.NetworkServiceError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.NetworkServiceError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.NetworkServiceError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.Code = {
  NONE: 0,
  GENERIC: 1,
  NOT_SUPPORTED: 2
};

/**
 * optional Code code = 1;
 * @return {!proto.de.mypowergrid.sherlock.NetworkServiceError.Code}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.getCode = function() {
  return /** @type {!proto.de.mypowergrid.sherlock.NetworkServiceError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.de.mypowergrid.sherlock.NetworkServiceError.Code} value
 * @return {!proto.de.mypowergrid.sherlock.NetworkServiceError} returns this
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.NetworkServiceError} returns this
 */
proto.de.mypowergrid.sherlock.NetworkServiceError.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.sherlock.NetworkDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.sherlock.NetworkDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.NetworkDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    macAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hostname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.sherlock.NetworkDevice;
  return proto.de.mypowergrid.sherlock.NetworkDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.sherlock.NetworkDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.sherlock.NetworkDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.sherlock.NetworkDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.sherlock.NetworkDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string mac_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice} returns this
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice} returns this
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.sherlock.NetworkDevice} returns this
 */
proto.de.mypowergrid.sherlock.NetworkDevice.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.de.mypowergrid.sherlock);
