<template>
  <CCard>
    <ApxCardHeader icon="cil-swap-horizontal" :title="$t('ems.deviceReplacement.pageTitle')" />
    <!-- BEGIN: If API calls are in progess, show Loading Indicator -->
    <vue-loading
      v-if="isLoading"
      class="my-5"
      type="spiningDubbles"
      :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
    />
    <!-- END:  If API calls are in progess, show Loading Indicator -->
    <CCardBody v-else>
      <!-- prettier-ignore -->
      <nav class="navbar navbar-expand-sm bg-light d-flex justify-content-end">
        <CButton 
          type="button"
          size="sm"
          color="primary"
          @click="initStore"
        >
          <CIcon size="sm" name="cilReload" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('main.reloadBtn') }}
          </span>
        </CButton>
      </nav>
      <p class="mt-2 mb-3 text-break text-muted form-text">
        {{ $t('ems.deviceReplacement.pageDescription') }}
      </p>
      <!-- BEGIN: Online Devices -->
      <h4 class="ml-1">
        {{ $t('ems.deviceReplacement.onlineDevices') }}
      </h4>
      <div>
        <CListGroup v-for="(ld, index) of ldnsWithStatus" :key="index" class="mx-1">
          <EmsReplaceDevice
            v-if="ld.isOnline"
            :ld="ld"
            @onRemoveDevice="onRemoveDevice"
            @onReplaceDevice="onReplaceDevice"
          />
        </CListGroup>
      </div>
      <!-- END: Online Devices   -->
      <!-- BEGIN: Offline Devices -->
      <h4 class="ml-1 mt-5">
        {{ $t('ems.deviceReplacement.offlineDevices') }}
      </h4>
      <div>
        <CListGroup v-for="(ld, index) of ldnsWithStatus" :key="index" class="mx-1">
          <EmsReplaceDevice
            v-if="!ld.isOnline"
            :ld="ld"
            @onRemoveDevice="onRemoveDevice"
            @onReplaceDevice="onReplaceDevice"
          />
        </CListGroup>
      </div>
      <!-- END:   Offline Devices   -->
    </CCardBody>
  </CCard>
</template>

<script>
import ApxCardHeader from '@/components/snippets/apx-card-header'
import EmsReplaceDevice from '@/components/ems/device-replacement/ems-replace-device'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'

import { mapGetters } from 'vuex'

export default {
  name: 'DeviceReplacement',
  components: {
    ApxCardHeader,
    EmsReplaceDevice
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['logicalDeviceNames']),
    ...mapGetters('deviceConfig', ['getDeviceStatus']),
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    ldnsWithStatus() {
      return this.logicalDeviceNames
        .map((ldn) => {
          const isOnline = !(
            this.getDeviceStatus(ldn).health === 'FATAL' || this.getDeviceStatus(ldn).health === 'ALARM'
          )

          let statusIndicator
          switch (this.getDeviceStatus(ldn).health) {
            case 'OK':
              statusIndicator = 'a_green'
              break
            case 'WARNING':
            case 'UNSURE':
              statusIndicator = 'b_yellow'
              break
            default:
              statusIndicator = 'c_red'
          }
          this.$log.debug(`Device: ${ldn}, Is Online: ${isOnline}`)

          return {
            name: ldn,
            isOnline,
            statusIndicator
          }
        })
        .sort((a, b) => {
          if (!a?.name) {
            return -1
          }

          if (!b?.name) {
            return 1
          }

          return a.name.localeCompare(b.name)
        })
    }
  },
  created() {
    this.initStore()
  },
  methods: {
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')
      Promise.all([
        this.$store.dispatch('emsTopologyConfig/getSourceQuantities'),
        this.$store.dispatch('deviceConfig/fetchDeviceStatuses')
      ])
        .then(() => {
          this.$log.debug('Successfully received source-quantities and device-statuses.')
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err)
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onRemoveDevice({ ldn }) {
      this.$store
        .dispatch('emsTopologyConfig/removeLdn', ldn)
        .then((res) => {
          this.$log.debug('Succesfully removed source(s).')
          this.$log.debug(res)
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err)
        })
        .finally(() => {
          this.initStore()
        })
    },
    onReplaceDevice({ oldLdn, newLdn }) {
      this.$store
        .dispatch('emsTopologyConfig/replaceLdn', { oldLdn: oldLdn, newLdn: newLdn })
        .then((res) => {
          this.$log.debug('Succesfully replaced source(s).')
          this.$log.debug(res)
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err)
        })
        .finally(() => {
          this.initStore()
        })
    },
    onServerError(err, opts = {}) {
      this.$log.error(err)
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err, opts))
    }
  }
}
</script>

<style scoped></style>
