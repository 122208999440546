/**
 * Helper for the [ems-energy-services-config store]{@link module:store/ems-energy-services-config}
 * @module grpc/protobuf/ems-eval-expression-helper
 */

import { isEmpty, upperFirst } from 'lodash'
import evalDescription from '@/../lib/proto_json/ext/ems/eval'
import {
  EvalExpression as EvalExpressionPb,
  EvalExpressions as EvalExpressionsPb,
  BinaryEvalExpression as BinaryEvalExpressionPb,
  TimeseriesPoint as TimeseriesPointPb,
  TimeseriesAggregate as TimeseriesAggregatePb
} from '@/../lib/proto_js/ext/ems/eval_pb'
import { isProto } from '@/grpc/protobuf/misc'

// Enables only these operators to be shown to the user
export const DEFAULT_ENABLED_OPERATORS = ['and', 'or', 'not', 'reference-timeseries']

export const SUPPORTED_OPERATORS =
  evalDescription.nested.de.nested.mypowergrid.nested.ems.nested.EvalExpression.oneofs.type.oneof

export const PRIMITIVE_OPERATORS = []
export const SINGLE_ELEMENT_OPERATORS = []
export const BINARY_ELEMENT_OPERATORS = []
export const MULTI_ELEMENT_OPERATORS = []

const evalExpFields = evalDescription.nested.de.nested.mypowergrid.nested.ems.nested.EvalExpression.fields
SUPPORTED_OPERATORS.forEach((o) => {
  switch (evalExpFields[o].type) {
    case 'EvalExpressions':
      MULTI_ELEMENT_OPERATORS.push(o)
      break
    case 'EvalExpression':
      SINGLE_ELEMENT_OPERATORS.push(o)
      break
    case 'BinaryEvalExpression':
      BINARY_ELEMENT_OPERATORS.push(o)
      break
    default:
      PRIMITIVE_OPERATORS.push(o)
  }
})

/**
 * Allows to travers in pre-order the ems.EvalExpression tree
 *
 * @generator
 * @function
 *
 * @param {object} evalExp has to be a `ems.EvalExpression` plain JS-proto Object (will be converted with `toObject`, if not the case)
 * @param {EvalTreeEntry} parsedEvalExp (optional) can be a `new EvalTreeEntry` which will be populated during traversal
 *
 * @yields {object} `{ idx, tree, parsedTree, parentIdx, childIdx }`. `idx` is the 'global' tree index of the node itself. `tree` and `parsedTree` is the node + all its children at this point. `parentIdx` is the 'global' tree index of the parent. `childIdx` is the 'local' index of the `expressionsList` (child-array).
 *
 * @return {generator}
 */
export function traverseEvalTree(evalExp, parsedEvalExp) {
  evalExp = evalExpressionPbToPlain(evalExp)

  let idx = -1

  function* iterates(tree, parsedTree, { parentIdx, childIdx } = {}) {
    idx++
    parsedTree.init(tree, { idx })
    const value = { idx, tree, parsedTree, parentIdx, childIdx }

    yield value

    for (const o of SUPPORTED_OPERATORS) {
      if (PRIMITIVE_OPERATORS.includes(o) || tree[o] === undefined) {
        continue
      } else if (BINARY_ELEMENT_OPERATORS.includes(o)) {
        const parentIdx = idx
        if (tree[o].lhs) {
          const expr = tree[o].lhs
          parsedTree.lhs = new EvalTreeEntry()
          yield* iterates(expr, parsedTree.lhs, { parentIdx, childIdx: 0 })
        }
        if (tree[o].rhs) {
          const expr = tree[o].rhs
          parsedTree.rhs = new EvalTreeEntry()
          yield* iterates(expr, parsedTree.rhs, { parentIdx, childIdx: 1 })
        }
      } else {
        const parentIdx = idx
        if (tree[o].expressionsList) {
          const exprs = tree[o].expressionsList
          for (const [i, e] of exprs.entries()) {
            parsedTree.children.push(new EvalTreeEntry())
            yield* iterates(e, parsedTree.children[i], { parentIdx, childIdx: i })
          }
        } else {
          parsedTree.children.push(new EvalTreeEntry())
          yield* iterates(tree[o], parsedTree.children[0], { parentIdx, childIdx: 0 })
        }
      }
    }
  }

  if (!parsedEvalExp) {
    parsedEvalExp = new EvalTreeEntry()
  }

  return iterates(evalExp, parsedEvalExp, { parentIdx: null, childIdx: 0 })
}

/**
 * Iterates in pre-order the ems.EvalExpression tree.
 * Allows to intercept and to abort.
 *
 * @function
 *
 * @param {object} tree has to be a `ems.EvalExpression` plain JS-proto Object
 * @param {function} cb (optional) is a callback funtion called for each iteration step. It will receive the yielded value of [traverseEvalTree]{@link module:grpc/protobuf/ems-eval-expression-helper.traverseEvalTree}. It should return truthy to continue and falsy to abort.
 *
 * @return {EvalTreeEntry} is the parsed eval expression tree
 */
export function iterateEvalTree(tree, cb) {
  const parsedTree = new EvalTreeEntry()

  if (isEmpty(tree)) {
    return parsedTree
  }

  const itr = traverseEvalTree(tree, parsedTree)
  let step = itr.next()

  while (!step.done) {
    if (cb) {
      if (!cb(step.value)) {
        break
      }
    }

    step = itr.next()
  }

  return parsedTree
}

/**
 * Instantiates a new `ems.EvalExpression` as plain JS-object
 *
 * @function
 *
 * @param {object} params (optional) all properties are exclusive one ofs
 * @param {number} params.value is the eval expression's value
 * @param {string} params.reference is the eval expression's reference
 * @param {string} params.operator is one of the eval expression's operators
 *
 * @return {object}
 */
export function newEvalExpression({ value, reference, operator } = {}) {
  const evalExpPb = new EvalExpressionPb()

  if (operator) {
    if (!SUPPORTED_OPERATORS.includes(operator)) {
      throw new TypeError(`The operator ${operator} is not supported.`)
    }

    const setter = `set${upperFirst(operator)}`
    if (SINGLE_ELEMENT_OPERATORS.includes(operator)) {
      evalExpPb[setter](new EvalExpressionPb())
    } else {
      evalExpPb[setter](new EvalExpressionsPb())
    }
  } else if (reference) {
    evalExpPb.setReference(reference)
  } else if (typeof value === 'number') {
    evalExpPb.setValue(value)
  }

  return evalExpressionPbToPlain(evalExpPb)
}

export function newBinaryEvalExpression() {
  const evalExpPb = new BinaryEvalExpressionPb()
  const lhs = new EvalExpressionPb()
  const rhs = new EvalExpressionPb()
  evalExpPb.setRhs(rhs)
  evalExpPb.setLhs(lhs)

  const ret = evalExpPb.toObject()
  ret.lhs.value = undefined
  ret.rhs.value = undefined

  return ret
}

/**
 * Instantiates a new `ems.EvalExpressions` as plain JS-object
 *
 * @function
 *
 * @return {object}
 */
export function newEvalExpressions() {
  return new EvalExpressionsPb().toObject()
}

export function evalExpressionPbToPlain(evalExpPb) {
  if (!isProto(evalExpPb)) {
    return evalExpPb
  }

  const hasValue = evalExpPb.hasValue()
  const hasReference = evalExpPb.hasReference()
  const hasAlias = evalExpPb.hasAlias()
  const evalExpPlain = evalExpPb.toObject()
  // undefining for the root node is sufficient
  // this allows to distinguish a fully empty EvalExpression from one with value `0` or reference ''
  if (!hasValue) {
    evalExpPlain.value = undefined
  }

  if (!hasReference) {
    evalExpPlain.reference = undefined
  }

  if (!hasAlias) {
    evalExpPlain.alias = undefined
  }

  return evalExpPlain
}

export function evalExpressionPlainToPb(evalExpPlain) {
  if (isProto(evalExpPlain)) {
    return evalExpPlain
  }

  const evalExpressionPb = new EvalExpressionPb()
  convert(evalExpPlain, evalExpressionPb)

  return evalExpressionPb

  // private
  function convert(tree, msg) {
    let setter = ''
    let getter = ''
    for (const o of SUPPORTED_OPERATORS) {
      if (PRIMITIVE_OPERATORS.includes(o) || tree[o] === undefined) {
        continue
      } else if (BINARY_ELEMENT_OPERATORS.includes(o)) {
        setter = `set${upperFirst(o)}`
        getter = `get${upperFirst(o)}`
        msg[setter](new BinaryEvalExpressionPb())
        msg[getter]().setLhs(new EvalExpressionPb())
        if (tree[o].lhs) {
          convert(tree[o].lhs, msg[getter]().getLhs())
        }

        msg[getter]().setRhs(new EvalExpressionPb())
        if (tree[o].rhs) {
          convert(tree[o].rhs, msg[getter]().getRhs())
        }
      } else {
        setter = `set${upperFirst(o)}`
        getter = `get${upperFirst(o)}`

        if (tree[o].expressionsList) {
          msg[setter](new EvalExpressionsPb())
          tree[o].expressionsList.forEach((e, i) => {
            msg[getter]().addExpressions(new EvalExpressionPb())
            convert(e, msg[getter]().getExpressionsList()[i])
          })
        } else {
          msg[setter](new EvalExpressionPb())
          convert(tree[o], msg[getter]())
        }

        break
      }
    }

    if (tree.reference) {
      msg.setReference(tree.reference)
    } else if (tree.alias) {
      msg.setAlias(tree.alias)
    } else if (tree.value !== undefined && !anyExceptValue(tree)) {
      msg.setValue(tree.value)
    } else if (tree.timeseriesPoint) {
      const point = new TimeseriesPointPb()
      point.setReference(tree.timeseriesPoint.reference)
      point.setDeltaTime(tree.timeseriesPoint.deltaTime)
      msg.setTimeseriesPoint(point)
    } else if (tree.timeseriesAggregate) {
      const point = new TimeseriesAggregatePb()
      point.setReference(tree.timeseriesAggregate.reference)
      point.setDeltaStartTime(tree.timeseriesAggregate.deltaStartTime)
      point.setDeltaEndTime(tree.timeseriesAggregate.deltaEndTime)
      point.setOp(tree.timeseriesAggregate.op)
      msg.setTimeseriesAggregate(point)
    }
  }

  function anyExceptValue(tree) {
    let hasAny = false
    for (const o of SUPPORTED_OPERATORS) {
      if (o === 'value') {
        continue
      }

      if (tree[o]) {
        hasAny = true
        break
      }
    }

    return hasAny
  }
}

/**
 * Wraps a ems.EvalExpression.
 * Does not iterate, i.e. takes ONLY the node-data itself.
 *
 * @constructor
 *
 * @param {object} entry is a ems.EvalExpression as plain or native JS-proto Object. NOTE: for plain ensure, that `value` of the root-node is set manually to undefined, if native one had no value before (`toObject`) will NOT do the job.
 *
 */
export function EvalTreeEntry(entry) {
  this.idx = null
  this.isNode = null
  this.operator = ''
  this.operatorKind = ''
  this.value = null
  this.reference = ''
  this.alias = ''
  this.children = []
  this.lhs = null
  this.rhs = null
  this.timeseriesPoint = { reference: '', deltaTime: 0 }
  this.timeseriesAggregate = {
    reference: '',
    deltaStartTime: 0,
    deltaEndTime: 0,
    op: TimeseriesAggregatePb.Operation.INTEGRAL
  }

  this.init = (e, { idx } = {}) => {
    this.idx = idx

    for (const o of SUPPORTED_OPERATORS) {
      if (o === 'timeseriesPoint' && e[o] !== null && e[o] !== undefined) {
        this.operator = o
        this.isNode = false
        this.timeseriesPoint.reference = e[o].reference
        this.timeseriesPoint.deltaTime = e[o].deltaTime
      }
      if (o === 'timeseriesAggregate' && e[o] !== null && e[o] !== undefined) {
        this.operator = o
        this.isNode = false
        this.timeseriesAggregate.reference = e[o].reference
        this.timeseriesAggregate.deltaStartTime = e[o].deltaStartTime
        this.timeseriesAggregate.deltaEndTime = e[o].deltaEndTime
        this.timeseriesAggregate.op = e[o].op
      }
      if (PRIMITIVE_OPERATORS.includes(o)) {
        continue
      }
      if (e[o] !== undefined) {
        this.isNode = true
        this.operator = o
        if (SINGLE_ELEMENT_OPERATORS.includes(o)) {
          this.operatorKind = 'SINGLE'
        } else if (BINARY_ELEMENT_OPERATORS.includes(o)) {
          this.operatorKind = 'BINARY'
        } else {
          this.operatorKind = 'MULTI'
        }
        break
      }
    }

    if (!this.operator && e.reference) {
      // an empty reference equivalent to be not set
      this.isNode = false
      this.operator = 'reference'
      this.operatorKind = 'PRIMITIVE'
      this.reference = e.reference
    } else if (!this.operator && e.alias) {
      // an empty alias equivalent to be not set
      this.isNode = false
      this.operator = 'alias'
      this.operatorKind = 'PRIMITIVE'
      this.alias = e.alias
    } else if (!this.operator && e.value !== undefined && e.value !== null) {
      // `0` value is possible
      this.isNode = false
      this.operator = 'value'
      this.operatorKind = 'PRIMITIVE'
      this.value = e.value
    }
  }

  if (entry) {
    this.init(entry)
  }
}
