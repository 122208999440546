<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm class="mt-4" @submit.prevent>
      <CInput
        type="number"
        class="custom-form-label"
        :horizontal="{ label: 'col-12 col-md-5', input: 'col-12 col-md-7' }"
        :value.sync="$v.batteryCapacityKiloWattHours.$model"
        :label="
          $t('ems.energyService.config.form.generationPeakShaving.batteryCapacity.label') +
          $t('ems.energyService.config.form.generationPeakShaving.batteryCapacity.unit')
        "
        :description="$t('ems.energyService.config.form.generationPeakShaving.batteryCapacity.description')"
        :placeholder="$t('ems.energyService.config.form.generationPeakShaving.batteryCapacity.placeholder')"
        :is-valid="$v.batteryCapacityKiloWattHours.$dirty ? !$v.batteryCapacityKiloWattHours.$error : null"
        :invalid-feedback="invalidFeedbackForBatteryCapacityKiloWattHours"
      />
    </CForm>
  </EsLayoutForm>
</template>

<script>
import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import { esGenerationPeakShavingFormValidators } from '@/validations/ems-es-form-validators'

export default {
  name: 'EsPeakShavingForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      batteryCapacityKiloWattHours: null,
      showFailureAlert: false
    }
  },
  computed: {
    invalidFeedbackForBatteryCapacityKiloWattHours() {
      const isInvalid = (condition) => {
        return !this.$v.batteryCapacityKiloWattHours[condition]
      }
      const base = 'ems.energyService.config.form.generationPeakShaving.batteryCapacity.validation.invalidFeedback'
      const conditions = ['required', 'positive']
      let feedback = ''
      for (const c of conditions) {
        if (isInvalid(c)) {
          feedback = this.$t(`${base}.${c}`)
          break
        }
      }

      return feedback
    }
  },
  created() {
    this.init()
  },
  validations: esGenerationPeakShavingFormValidators,
  methods: {
    init() {
      this.batteryCapacityKiloWattHours = this.initValues.batteryCapacityWattHours / 1000
      if (this.batteryCapacityKiloWattHours === 0) {
        this.batteryCapacityKiloWattHours = null
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const esParams = {
        batteryCapacityWattHours: 1000 * parseFloat(this.batteryCapacityKiloWattHours)
      }
      this.$emit('submit', { esParams })
    }
  }
}
</script>
