/** @module */
import { camelCase, lowerCase, pickBy, upperFirst } from 'lodash'
import { Error as ErrorPb } from '@/../lib/proto_js/ext/amperix/error_pb'
import { Error as FWErrorPb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'
import { ErrorType as TopologyErrorType } from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import i18n from '@/i18n'
import logger from '@/logger'
import { getEnumStr } from '@/grpc/parser'
import { humanizePhysDevType } from '@/store/modules/_ems-topology-config-helper'
import { newToastNotificationMsg } from '@/store/modules/notifications'

/**
 * Class representing any gRPC service error.
 *
 * I.e. a gRPC response (with status OK) and a reponse message with Error field.
 *
 * @extends Error
 */
export class GrpcServiceError extends Error {
  constructor({ code, msg }) {
    super(msg)
    this.name = 'GrpcServiceError'
    this.code = code || 0
    this.msg = msg
  }
}

/**
 * Class representing an authentication error.
 *
 * @extends Error
 */
export class AuthenticationError extends Error {
  /**
   * Create an authentication error
   *
   * If the type is a:
   *
   * `SERVER_ERROR` the `code` property is a gRPC [status code]{@link https://developers.google.com/maps-booking/reference/grpc-api/status_codes}
   *
   * `SERVICE_ERROR` the `code` property is a `auth.Error.Code` enum.
   *
   *
   * @param {object} params
   * @param {string} params.msg is the error message
   * @param {integer} params.code is the error code (gRPC Error code or `auth.Error.Code`)
   * @param {string} type (optional) has to be oneof `'UNKNOWN', 'PROCESSING_ERROR', 'SERVER_ERROR', 'SERVICE_ERROR'`
   */
  constructor({ msg, code, type }) {
    super(msg)
    const supportedTypes = ['UNKNOWN', 'PROCESSING_ERROR', 'SERVER_ERROR', 'SERVICE_ERROR']

    this.name = 'AuthenticationError'
    this.code = code || 0
    this.msg = msg
    this.type = type || 'UNKNOWN'

    if (!supportedTypes.includes(this.type)) {
      throw new TypeError(`The type "${type}" is not supported.`)
    }
  }
}

/**
 * Takes a Proto response message, checks for service errors,
 * returns msg if no error, otherwise throws.
 *
 * @function
 *
 * @param {object} msg a protobuf message with error field. The error message of the error field should have error `code` and `msg`
 *
 * @return {object} received msg if no error
 */
export function evalServiceError(msg) {
  if (msg.hasError && msg.hasError()) {
    const err = msg.getError()
    if (err && err.getCode() >= 0) {
      throw new GrpcServiceError({
        msg: err.getMsg ? err.getMsg() : '',
        code: err.getCode()
      })
    }
  }

  return msg
}

/**
 * Checks a EMS controller response.
 *
 * @function
 *
 * @param {object} msg (required) is the EMS constroller.Response protobuf JS msg
 *
 * @return {object} controller.Response protobuf JS msg
 */
export function evalEmsControllerError(msg) {
  if (msg.hasError()) {
    const err = new Error(msg.getError().getDescription())
    err.name = 'GrpcEmsControllerError'
    err.code = ErrorPb.Code.ENERGY_MANAGER
    err.msg = err.message // expected property for any API Error

    throw err
  }

  return msg
}

/**
 * Allows to convert a `de.mypowergrid.fwupgrade.Error` to a JS-error object
 *
 * @function
 *
 * @param {object} has to be a PB `de.mypowergrid.fwupgrade.Error` instance
 * @param {object} opts
 * @param {string} opts.errorName allows to switch between 'GrpcFwupgraderError' and 'GrpcFwupgraderImmediateError'
 *
 * @return {Error}
 */
export function parseFwUpgradeError(error, { errorName } = {}) {
  let msg = ''
  let errorKindStr = ''
  try {
    // Log the error, to have at least "full information" on the console.
    logger.warn('FW-Upgrader failed:')
    logger.warn(pickBy(error.toObject(), (x) => x))

    const msgs = []
    msgs.push(error.getMessage())

    errorKindStr = getEnumStr(error.getErrorKindCase(), FWErrorPb.ErrorKindCase) || 'UNKNOWN'
    if (errorKindStr !== 'UNKNOWN' && errorKindStr !== 'ERROR_KIND_NOT_SET') {
      const e = error[`get${upperFirst(camelCase(errorKindStr))}`]()
      if (e.getMessage) {
        msgs.push(e.getMessage())
      }
    }
    msg = msgs.filter(Boolean).join(' ')
  } catch (err) {
    logger.error(err)
  }

  errorName ||= 'GrpcFwupgraderError'
  const err = new Error(`${errorKindStr}: ${msg}` || error.getErrorKindCase())
  err.name = errorName
  err.code = error.getErrorKindCase()
  err.msg = msg // not include the one-of error code

  return err
}

/**
 * Allows to check a fwupgrader GetLogResponse, GetState, AcknowledgeError, CheckForLatestUpgrade  response for errors.
 *
 * @function
 *
 * @throws GrpcFwupgraderError
 *
 * @param {object} msg (required) fwupgrader response with regular error
 * @param {object} opts (optional)
 * @param {function} opts.allowHook if present will be called with the `de.mypowergrid.fwupgrade.Error` instance, if an error is present. If returns true, the msg with error will be passed, instead of thrown.
 *
 * @return {object} fwupgrader response
 */
export function evalFwupgraderError(msg, { allowHook } = {}) {
  if (msg.hasError && msg.hasError()) {
    if (allowHook && allowHook(msg.getError())) {
      return msg
    }
    throw parseFwUpgradeError(msg.getError(), { errorName: 'GrpcFwupgraderError' })
  }

  return msg
}

/**
 * Checks a EMS fwupgrader response.
 *
 * @function
 *
 * @throws GrpcFwupgraderImmediateError
 *
 * @param {object} msg (required) fwupgrader response with immediate error
 *
 * @return {object} fwupgrader response
 */
export function evalFwupgraderImmediateError(msg) {
  if (msg.hasImmediateError && msg.hasImmediateError()) {
    throw parseFwUpgradeError(msg.getImmediateError(), { errorName: 'GrpcFwupgraderImmediateError' })
  }

  return msg
}

/**
 * Checks the EMS controller configure_topology response.
 *
 * @function
 *
 * @param {object} msg (required) is the EMS topology.Error protobuf JS msg
 *
 * @return {object} topology.Error protobuf JS msg
 */
export function evalSetTopologyError(msg) {
  if (msg.getType() === 0) {
    return msg
  }

  const type = getEnumStr(msg.getType(), TopologyErrorType)
  const err = new Error(`Configuration of a new EMS topology failed: ${type}`)
  err.name = 'TopologyError'
  err.code = ErrorPb.Code.ENERGY_MANAGER
  err.type = type
  err.physicalDevicesList = msg.getPhysicalDevicesList().map((pd) => {
    pd = pd.toObject()
    pd.type = humanizePhysDevType(pd)

    return `${upperFirst(lowerCase(pd.type))} ${pd.number}`
  })

  throw err
}

/**
 * Checks the EMS controller alias response.
 *
 * @function
 *
 * @param {object} msg (required) is the EMS alias.Error protobuf JS msg
 *
 * @return {object} topology.Error protobuf JS msg
 */
export function aliasError(msg) {
  if (msg.hasOk()) {
    return msg
  }

  const errorParams = {
    name: 'AliasError',
    code: ErrorPb.Code.ENERGY_MANAGER,
    type: null
  }

  if (msg.hasNotfound()) {
    errorParams.type = 'notFound'
  } else if (msg.hasDuplicate()) {
    errorParams.type = 'duplicate'
  } else if (msg.hasLoop()) {
    errorParams.type = 'loop'
    errorParams.loopAliases = msg.getLoop().getAliasesInLoopList()
  }

  const err = new Error(i18n.t(`ems.energyService.config.evalAlias.errors.${errorParams.type}`))

  if (errorParams.loopAliases) {
    for (const [i, alias] of errorParams.loopAliases.entries()) {
      err.message += ` ${alias}${i < errorParams.loopAliases.length - 1 ? ',' : ''}`
    }
  }

  for (const key in errorParams) {
    err[key] = errorParams[key]
  }

  throw err
}

/**
 * Takes a Proto response message,
 * checks for service errors,
 * checks for NETWORK_NOT_FOUND,
 * removes NETWORK_NOT_FOUND from message if present,
 * and return finally the input message.
 *
 * Why to use?:
 * In case of a `GetUplinkNetworkConfigResponse` with no uplink config present,
 * the NETWORK_NOT_FOUND error is present.
 * But, configuration should not be aborted.
 * But, still a `NetworkConfig` is attached with `id` only.
 *
 * @function
 *
 * @param {object} msg a protobuf response message
 *
 * @return {object} the protobug response message
 */
export function handleErrorNetworkNotFound(msg) {
  return swallowError(msg, 2)
}

export function handleErrorDeviceNotFount(msg) {
  return swallowError(msg, 9)
}

/**
 * Creates a generic server notification-message.
 *
 * @function
 *
 * @param {amperix.Error} err is the JSPB `amperix.Error` (JSPB or plain object). Should have `err.code` and `err.msg`.
 * @param {object} opts are (optional) options which will be merged (shallow) into the returned notification message.
 *
 * @return {object} a notification message, which can be directly `commit('notifications/PUSH_TOAST', msg)`
 *
 */
export function genericServerErrorNotificationMsg(err, opts = {}) {
  if (typeof err.toObject === 'function') {
    err = err.toObject()
  }

  const msg = newToastNotificationMsg({
    title: i18n.t('api.errors.server'),
    content: i18n.t('api.errors.generic', [getEnumStr(err.code, ErrorPb.Code), err.code, err.msg])
  })

  return Object.assign(msg, opts)
}

// private
function swallowError(msg, code) {
  if (msg.getError) {
    const err = msg.getError()
    if (err && err.getCode() === code) {
      let errMsg
      if (err.getMsg) {
        errMsg = err.getMsg()
      }
      const codeStr = Object.keys(ErrorPb.Code).find((c) => ErrorPb.Code[c] === code)
      logger.warn(
        `Received an ${codeStr} error during API request with message: '${errMsg}'. However, will NOT bubble this Error. Instead, it will be gracefully swallowed. This is a feature :).`
      )

      msg.setError(null)
    }
  }

  return msg
}
