<template>
  <div>
    <CRow>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkPublic"
          :description="networkPublic.rawInfo"
          :is-loading="isLoading.networkPublic"
          :has-error="hasError.networkPublic"
          :type="$t('interfaces.ethernet.uplink')"
        />
      </CCol>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkPublicConnectivity"
          :description="networkPublicConnectivity.rawInfo"
          :is-loading="isLoading.networkPublicConnectivity"
          :has-error="hasError.networkPublicConnectivity"
          :type="$t('interfaces.ethernet.uplinkConnectivity')"
          allow-reload
          @reload="fetchConnectivity('networkPublic')"
        />
      </CCol>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkProperty"
          :description="networkProperty.rawInfo"
          :is-loading="isLoading.networkProperty"
          :has-error="hasError.networkProperty"
          :type="brandName + ' ' + $t('interfaces.ethernet.ampNetwork')"
        />
      </CCol>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkKnownDevices"
          :description="networkKnownDevicesRawInfo"
          :is-loading="isLoading.networkKnownDevices"
          :has-error="hasError.networkKnownDevices"
          :type="brandName + ' ' + $t('interfaces.ethernet.ampNetworkConnectivity.sectionTitle')"
          allow-reload
          @reload="fetchKnownDevices()"
        />
      </CCol>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkMaintenance"
          :description="networkMaintenance.rawInfo"
          :is-loading="isLoading.networkMaintenance"
          :has-error="hasError.networkMaintenance"
          :type="$t('interfaces.ethernet.maintenance')"
        />
      </CCol>
      <CCol xs="2" lg="4">
        <apx-interface-card
          key="networkMaintenanceConnectivity"
          :description="networkMaintenanceConnectivity.rawInfo"
          :is-loading="isLoading.networkMaintenanceConnectivity"
          :has-error="hasError.networkMaintenanceConnectivity"
          :type="$t('interfaces.ethernet.maintenanceConnectivity')"
          allow-reload
          @reload="fetchConnectivity('networkMaintenance')"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ApxInterfaceCard from '@/components/apx-interface-card'
import { mapGetters } from 'vuex'

import { API_LOADING_IDS } from '@/store/modules/api-loading-status'

const networks = ['networkProperty', 'networkMaintenance', 'networkPublic']
// 'networkPropertyConnectivity' is currently not supported
const networkConnectivities = ['networkMaintenanceConnectivity', 'networkPublicConnectivity']
export default {
  name: 'EthernetList',
  components: {
    ApxInterfaceCard
  },
  data() {
    const isLoading = {}
    const hasError = {}
    networks.forEach((n) => {
      isLoading[n] = false
      hasError[n] = false
    })
    networkConnectivities.forEach((c) => {
      isLoading[c] = false
      hasError[c] = false
    })
    isLoading.networkKnownDevices = false
    hasError.networkKnownDevices = false
    return {
      isLoading,
      hasError
    }
  },
  computed: {
    ...mapGetters('apxInterfaces', networks.concat(networkConnectivities).concat(['networkKnownDevicesRawInfo'])),
    ...mapGetters('apiLoadingStatus', ['loaded'])
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
  },
  mounted() {
    networks.forEach(this.fetchNetwork)
    this.fetchConnectivity('networkMaintenance')
    this.fetchConnectivity('networkPublic')
    this.fetchKnownDevices()
  },
  methods: {
    fetchNetwork(interfaceType) {
      const onSuccess = (res) => {
        this.isLoading[interfaceType] = false
      }
      let msg = ''
      const onError = (err) => {
        this.$log.warn(err)
        this.isLoading[interfaceType] = false
        this.hasError[interfaceType] = true

        if (err.code === 1) {
          msg = this.$t('api.errors.gRPCcode1')
        } else {
          msg = this.$t('api.errors.gRPCcode2')
        }
        const content = this.$t('api.errors.base_msg', [interfaceType, err.code, msg])
        const errMessage = {
          title: interfaceType,
          type: 'toast-warning',
          content: content
        }
        this.$store.commit('notifications/PUSH_TOAST', errMessage)
      }

      this.isLoading[interfaceType] = true
      this.$store
        .dispatch('apxInterfaces/fetchInterfaceInfo', {
          interfaceId: 'networks',
          interfaceType: interfaceType
        })
        .then(onSuccess)
        .catch(onError)
    },
    fetchConnectivity(interfaceType) {
      const id = interfaceType + 'Connectivity'
      const onSuccess = (res) => {
        this.isLoading[id] = false
      }
      let msg = ''
      const onError = (err) => {
        this.$log.warn(err)
        this.isLoading[id] = false
        this.hasError[id] = true

        if (err.code === 1) {
          msg = this.$t('api.errors.gRPCcode1')
        } else {
          msg = this.$t('api.errors.gRPCcode2')
        }
        const content = this.$t('api.errors.base_msg', [interfaceType, err.code, msg])
        const errMessage = {
          title: interfaceType,
          type: 'toast-warning',
          content: content
        }
        this.$store.commit('notifications/PUSH_TOAST', errMessage)
      }

      this.isLoading[id] = true
      this.$store
        .dispatch('apxInterfaces/fetchNetworkConnectivity', {
          interfaceType: interfaceType
        })
        .then(onSuccess)
        .catch(onError)
    },
    fetchKnownDevices() {
      const id = 'networkKnownDevices'
      const onSuccess = (res) => {
        this.isLoading[id] = false

        this.$store.commit('apiLoadingStatus/SET_LOADED', {
          id: API_LOADING_IDS.network.knownDevices,
          status: true
        })
      }
      const onError = (err) => {
        this.$log.warn(err)
        this.isLoading[id] = false
        this.hasError[id] = true

        const content = this.$t('api.errors.base_msg', ['Known devices', err.code, ''])
        const errMessage = {
          title: this.$t('interfaces.ethernet.ampNetworkConnectivity.notifications.title'),
          type: 'toast-warning',
          content: content
        }
        this.$store.commit('notifications/PUSH_TOAST', errMessage)
      }

      this.isLoading[id] = true
      this.$store.dispatch('apxInterfaces/fetchKnownDevices').then(onSuccess).catch(onError)
    }
  }
}
</script>

<style scoped></style>
