<template>
  <div>
    <slot name="heading">
      <div class="text-center">
        <span>
          {{ $t('ems.energyService.config.decisionTree.node.currentParameters') }}
        </span>
      </div>
    </slot>
    <EsTimeSwitchPreview
      v-if="entry.isNode && entry.params.decisionNodeType === 'greaterThanZero'"
      :key="entry.idx"
      :class="{ border: border }"
      :decision-tree-idx="entry.idx"
    />
    <EsSocNodePreview
      v-if="entry.isNode && entry.params.decisionNodeType === 'socGreaterThan'"
      :key="entry.idx"
      :class="{ border: border }"
      :params="entry.params.decisionNodeParams"
      :decision-tree-idx="entry.idx"
    />
    <EsChaiScriptNodePreview
      v-if="entry.isNode && entry.params.decisionNodeType === 'chaiScript'"
      :key="entry.idx"
      :class="{ border: border }"
      :params="entry.params.decisionNodeParams"
    />
  </div>
</template>

<script>
import EsSocNodePreview from './es-soc-node-preview'
import EsChaiScriptNodePreview from './es-chai-script-node-preview'
import EsTimeSwitchPreview from './es-time-switch-preview'

export default {
  name: 'EsNodePreview',
  components: {
    EsTimeSwitchPreview,
    EsSocNodePreview,
    EsChaiScriptNodePreview
  },
  props: {
    border: {
      required: false,
      type: Boolean,
      default: false
    },
    entry: {
      required: true,
      type: Object || null
    }
  }
}
</script>
