<template>
  <div>
    <!-- prettier-ignore -->
    <CForm v-if="desc.length > 0">
      <ApxDeviceConfigPreSubmitHook
      :initial-data="initialData"
      :submit-data="cfgFormInteractor.getDeviceConfig().toObject()"
      :evaluate="evaluate"
      translation-path="config.device.form"
      @response="doSubmit"/>
      <template v-for="(f, i) of parsedDesc">
        <hr v-if="displayForkDelimitor(i)" :key="'hr-' + f.key">
        <template v-if="f.isScalar || f.isSelection">
          <CInputCheckbox
            v-if="f.isCheckbox"
            :key="f.key"
            class="my-2 mb-4 custom-form-label"
            v-bind="htmlAttrs[i]"
            :checked="cfgFormInteractor.fieldValue(f.fieldNums)"
            @update:checked="onUpdateField(f.name, $event)"
          />
          <CInput
            v-if="f.isInput"
            :key="f.key"
            :disabled="isEditForbidden(f)"
            class="my-2 custom-form-label"
            :horizontal="styling.horizontalScalar"
            v-bind="htmlAttrs[i]"
            :value="slctInteractorToForm(cfgFormInteractor.fieldValue(f.fieldNums))"
            @update:value="onUpdateField(f.name, $event)"
          />
          <CSelect
            v-if="f.isSelection && !f.isMultiple"
            :key="f.key"
            class="my-2 custom-form-label"
            :horizontal="styling.horizontalScalar"
            v-bind="htmlAttrs[i]"
            :is-valid="htmlAttrs[i].isValid === true ? null : htmlAttrs[i].isValid"
            :disabled="!f.options.length"
            :options="f.options"
            :value="slctInteractorToForm(cfgFormInteractor.fieldValue(f.fieldNums))"
            @update:value="onUpdateSelect(f.name, $event)"
          />
          <div v-if="f.isSelection && f.isMultiple" :key="f.key" class="form-group form-row my-2">
            <label :class="`custom-form-label ${styling.horizontalScalar.label}`">
              {{ f.label }}
            </label>
            <CMultiSelect
              :class="`${styling.horizontalScalar.input} ${f.isValid ? '' : 'is-invalid'}`"
              :multiple="true"
              :disabled="!f.options.length"
              :options="f.options"
              :search="true"
              :search-placeholder="f.placeholder"
              selection-type="tags"
              :selected="slctInteractorToForm(cfgFormInteractor.fieldValue(f.fieldNums))"
              @update="onUpdateMultiSelect(f.name, $event)"
            >
            </CMultiSelect>
            <!-- CSS toggles visibility -->
            <div v-if="f.invalidFeedback" :class="`invalid-feedback ${styling.horizontalScalar.input} ${cssColToOffset(styling.horizontalScalar.label)}`">
              {{ f.invalidFeedback }}
            </div>
            <small v-if="f.description" :class="`form-text text-muted ${styling.horizontalScalar.input} ${cssColToOffset(styling.horizontalScalar.label)}`">
              {{ f.description }}
            </small>
          </div>
        </template>
        <CSelect
          v-if="f.isFork && forkOptions(f).length"
          :key="f.key"
          :class="`ml-${2 * f.level}`"
          :horizontal="styling.horizontalFork || true"
          :name="f.name"
          :options="forkOptions(f)"
          :value="cfgFormInteractor.forkValue({ fieldNumbers: f.fieldNumbers, oneofName: f.oneofName })"
          @update:value="onForkUpdate"
        />
      </template>
      <div class="border-top mt-3 pt-2 px-2 ">
        <CButton
          type="submit"
          :disabled="isLoading"
          size="sm"
          color="primary"
          @click.prevent="onSubmit"
        >
          {{ $t('main.saveBtn') }}
        </CButton>
        <CButton
          class="ml-5"
          type="reset"
          :disabled="isLoading"
          size="sm"
          color="danger"
          @click.prevent="onReset"
        >
          {{ $t('main.resetBtn') }}
        </CButton>
      </div>
    </CForm>
    <div v-else-if="!isLoading">
      {{ $t('config.device.form.unsupported') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import ApxDeviceConfigPreSubmitHook from '@/components/apx-config-forms/apx-device-config-pre-submit-hook'
import { DeviceConfigFormInteractor } from '@/view-helper/device-config/device-config-form-interactor'
import { DeviceConfigFormValidator } from '@/view-helper/device-config/device-config-form-validator'
import { CACHE_ROOT_KEYS, getCache } from '@/store/cache/cache'

const forbiddenProtoFieldsForEdit = ['dplName']

export default {
  name: 'ApxDeviceConfigForm',
  components: {
    ApxDeviceConfigPreSubmitHook
  },
  props: {
    configMsgName: {
      type: String,
      default: ''
    },
    cfgInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    devCfg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    styling: {
      type: Object,
      required: false,
      default: () => {
        return {
          horizontalScalar: {
            label: 'col-sm-3',
            input: 'col-sm-9'
          },
          horizontalFork: {
            label: 'd-none',
            input: 'col-12'
          }
        }
      }
    }
  },
  data() {
    return {
      isLoading: true,
      cfg: {},
      desc: [],
      hasValidator: false,
      initialData: undefined,
      evaluate: false
    }
  },
  computed: {
    ...mapGetters('auth', ['disabledFeatures', 'omiOnlyEnabled']),
    htmlAttrs() {
      return this.parsedDesc.map((ffd) => {
        return this.cfgFormInteractor.htmlAttrsOf(ffd)
      })
    },
    displayForkDelimitor() {
      return (i) => {
        const b = this.parsedDesc[i - 1]
        const f = this.parsedDesc[i]

        if (!b) {
          return f.isFork
        }

        if (f.isFork) {
          return true
        }

        const bN = b.isFork ? b.level + 1 : b.level
        const fN = f.isFork ? f.level + 1 : f.level

        return fN - bN !== 0
      }
    },
    blacklistedFieldNames() {
      const featureToDescNames = {
        backup_power: ['.nabox'],
        power_quality: ['.inverterFundamentalFrequencyMode', '.inverterSymmetryMode', '.inverterHarmonicMode']
      }

      if (this.omiOnlyEnabled) {
        if (this.disabledFeatures.includes('power_quality')) {
          return featureToDescNames.power_quality
        } else {
          return []
        }
      }

      let blacklist = ['modbusServerAddressOffset', 'modbusServerEndianness', 'modbusServerSlaveId']

      for (const feature of this.disabledFeatures) {
        if (featureToDescNames[feature]) {
          blacklist = blacklist.concat(featureToDescNames[feature])
        }
      }
      return blacklist
    },
    isEditView() {
      return this.$route.name === 'config-device-edit'
    },
    parsedDesc() {
      const desc = this.desc.filter(
        (descItem) => !this.blacklistedFieldNames.some((blacklistItem) => descItem.name.includes(blacklistItem))
      )

      for (const descItem of desc) {
        if (this.isEditForbidden(descItem)) {
          descItem.description = this.$t(`config.device.form.global.${descItem.key.split('.').pop()}.editDescription`)
        }
      }

      return desc
    }
  },
  watch: {
    '$i18n.locale': function () {
      // due to dynamic $t-key computate we have to trigger lang-updates manually
      this.cfgFormInteractor.rebuildDesc()
    },
    isLoading: function (l) {
      // some loader etc. would be better, however, this was simple
      if (l) {
        document.body.style.cursor = 'wait'
      } else {
        document.body.style.cursor = 'default'
      }
    }
  },
  created() {
    // Note: cfgFormInteractor is statefull (i.e. has `data` of this vue)
    // `data` update should happen ONLY <via cfgFormInteractor
    this.cfgFormValidator = new DeviceConfigFormValidator({ vue: this, configMsgName: this.configMsgName })
    this.cfgFormInteractor = new DeviceConfigFormInteractor({ vue: this, validator: this.cfgFormValidator })
    this.init()
  },
  validations() {
    return this.cfgFormValidator.buildCfgValidations()
  },
  methods: {
    async init() {
      this.$log.debug('Initializing ApxDeviceConfigForm.')
      try {
        if (isEmpty(this.devCfg)) {
          await this.cfgFormInteractor.initNew(this.cfgInfo)
        } else {
          // load proto-JS config:
          // this avoids problems because of proto-JS <-> plain-JS parsing
          const id = this.devCfg.id?.id
          const devCfg = getCache([CACHE_ROOT_KEYS.deviceConfigs, id])
          if (!devCfg) {
            this.$log.warn(`Failed to find cached device config ${id}`)
          } else {
            this.$log.debug(`Successfully extracted proto-JS device config ${id} from cache.`)
          }

          await this.cfgFormInteractor.initEdit(this.cfgInfo, devCfg || this.devCfg)
        }
      } catch (err) {
        this.$log.warn(
          'Failed to initialize DeviceConfigFormInteractor. Potentially the config or corresponding config-meta-data are "ill-defind".'
        )
        this.$log.warn(err.message)
        this.$log.warn('config meta-data:', this.cfgInfo, 'config:', this.devCfg)
      }
      this.isLoading = false
      this.initialData = this.cfgFormInteractor.getDeviceConfig().toObject()
    },
    cssColToOffset(colClasses) {
      try {
        return colClasses.replace(/col-([a-z]+)/g, 'offset-$1').replace(/col-12 /g, '')
      } catch (err) {
        this.$log.error(err)

        return ''
      }
    },
    isEditForbidden(fieldDesc) {
      return this.isEditView && forbiddenProtoFieldsForEdit.some((f) => fieldDesc.key.split('.').pop() === f)
    },
    disableField(f) {
      return this.isEditForbidden(f)
    },
    forkOptions(f) {
      if (f.oneofName !== 'nabox') return f.options

      let validNaBoxes = ['_CLEAR.2.4.nabox']

      if (this.omiOnlyEnabled) {
        validNaBoxes = validNaBoxes.concat(['2.4.22', '2.4.23'])
      } else if (!this.disabledFeatures.includes('backup_power')) {
        validNaBoxes = validNaBoxes.concat(['2.4.13'])
      }
      return f.options.filter((opt) => validNaBoxes.includes(opt.value))
    },
    slctInteractorToForm(slct) {
      if (typeof slct === 'number') {
        return slct.toString()
      }

      if (Array.isArray(slct)) {
        return slct.map((e) => {
          if (typeof e === 'number') {
            return e.toString()
          }

          return e
        })
      }

      return slct
    },
    slctFormToInteractor(slct) {
      if (typeof slct === 'string') {
        return parseInt(slct)
      }

      if (Array.isArray(slct)) {
        return slct.map((e) => {
          if (typeof e === 'string') {
            return parseInt(e)
          }

          return e
        })
      }

      return slct
    },
    onUpdateField(name, val) {
      try {
        const f = this.cfgFormInteractor.findDesc(name)
        // setting field val as string to proto-cfg will perform type conversion
        this.cfgFormInteractor.fieldValue(f.fieldNums, val)
      } catch (err) {
        this.$log.error('Failed to set device config scalar field value')
        this.$log.error(err)
      }
    },
    onUpdateSelect(name, val) {
      try {
        const f = this.cfgFormInteractor.findDesc(name)
        this.cfgFormInteractor.fieldValue(f.fieldNums, this.slctFormToInteractor(val))
      } catch (err) {
        this.$log.error('Failed to set device config single select field value')
        this.$log.error(err)
      }
    },
    onUpdateMultiSelect(name, vals) {
      try {
        const f = this.cfgFormInteractor.findDesc(name)
        this.cfgFormInteractor.fieldValue(f.fieldNums, this.slctFormToInteractor(vals))
      } catch (err) {
        this.$log.error('Failed to set device config multiple select field values')
        this.$log.error(err)
      }
    },
    async onForkUpdate(val) {
      this.isLoading = true
      try {
        if (/^_CLEAR\..*/.test(val)) {
          val = val.split('.')
          val.shift()
          const oneofName = val.pop()
          val = val.map((i) => parseInt(i))
          this.cfgFormInteractor.unsetFork({ fieldNumbers: val, oneofName })
        } else {
          val = this.cfgFormInteractor.fromLiteral(val)
          await this.cfgFormInteractor.setFork({ fieldNumbers: val })
        }
      } catch (err) {
        this.$log.error('Failed to set/unset device config fork (oneof message).')
        this.$log.error(err)
      }
      this.isLoading = false
    },
    onReset() {
      this.$log.debug('Resetting ApxDeviceConfigForm.')
      this.isLoading = true
      this.$emit('reset') // parent has to re-load data and trigger re-render
    },
    onSubmit() {
      this.cfgFormValidator.validate()
      const isValid = this.cfgFormValidator.isValid()

      if (isValid === null || isValid) {
        // submit the proto-JS config, NOT the plain-JS one from the store
        this.evaluate = true
      } else {
        this.cfgFormInteractor.updateValidation()
        this.$emit('validation-error', this.$v.cfg)
      }
    },
    doSubmit(response) {
      if (response) {
        this.$emit('submit', this.cfgFormInteractor.getDeviceConfig())
      }
      this.evaluate = false
    }
  }
}
</script>
