/** @module grpc/protobuf/ems-helper */

import sControllerDescription from '@/../lib/proto_json/ext/ems/scontroller/scontroller'

// prettier-ignore
export const EMS_ENERGY_SERVICE_IDS = sControllerDescription
  .nested
  .de
  .nested
  .mypowergrid
  .nested
  .ems
  .nested
  .scontroller
  .nested
  .EnergyService
  .oneofs
  .type
  .oneof

// prettier-ignore
export const EMS_ENERGY_SERVICE_DECISION_TYPES = sControllerDescription
  .nested
  .de
  .nested
  .mypowergrid
  .nested
  .ems
  .nested
  .scontroller
  .nested
  .DecisionTree
  .nested
  .Node
  .oneofs
  .type
  .oneof
