/**
 * Stores the state of one EMS eval expression tree. (proto: `ems.EvalExpression`)
 *
 * Is somehow a temporary state, with the following work-flow:
 *
 * - Should be populated during building the eval expression tree.
 * - Should be copied from a target-state (optional).
 * - Should be copied to the desired target-state or submitted (API).
 * - Should be cleaned finally.
 *
 * @module store/ems-eval-expression
 */

import Vue from 'vue'
import { cloneDeep } from 'lodash'
import logger from '@/logger'
import { DecisionTree as DecisionTreePb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import {
  SUPPORTED_OPERATORS,
  SINGLE_ELEMENT_OPERATORS,
  PRIMITIVE_OPERATORS,
  BINARY_ELEMENT_OPERATORS,
  newEvalExpression,
  newEvalExpressions,
  newBinaryEvalExpression,
  iterateEvalTree,
  evalExpressionPbToPlain,
  evalExpressionPlainToPb
} from '@/grpc/protobuf/ems-eval-expression-helper'
import { iterateDecisionTree } from '@/store/modules/_ems-energy-services-config-helper'
import { evalExpressionValidators } from '@/validations/ems-eval-expression-validators'
import { setAlias, getAlias } from '@/api/ems/eval-aliases'

import { TimeseriesAggregate } from '@/../lib/proto_js/ext/ems/eval_pb'

const state = () => {
  return {
    currentDecisionTreeIdx: null,
    currentEnergyServiceId: {
      strategyId: null,
      actuatorGroupId: null
    },
    evalExpression: newEvalExpression(),
    hasChanged: false
  }
}

const getters = {
  treeHasChanged: (state) => {
    // conservativ approach if we are not sure it stayed the same we assume it has changed
    return state.hasChanged
  },
  parsedEvalTree: (state) => {
    return iterateEvalTree(state.evalExpression)
  },
  getEvalTreeEntry: (state) => {
    return (nodeIdx) => {
      let entry = null
      iterateEvalTree(state.evalExpression, ({ idx, parsedTree }) => {
        if (idx !== nodeIdx) {
          return true
        }

        entry = parsedTree
        return false
      })

      return entry
    }
  },
  sizeEvalTree: (state) => {
    let n = 0
    iterateEvalTree(state.evalExpression, () => {
      n++

      return true
    })

    return n
  },
  isValidTree: (state) => {
    const fields = ['operator', 'reference', 'alias']
    const timepointFields = ['reference', 'deltaTime']
    const aggregateFields = ['reference', 'deltaStartTime', 'deltaEndTime', 'op']
    let validators
    let isValid = true
    iterateEvalTree(state.evalExpression, ({ parsedTree }) => {
      for (const f of fields) {
        validators = evalExpressionValidators[f]
        if (!validators) {
          throw new Error(`Validator is missing for field/property ${f}`)
        }

        for (const v in validators) {
          isValid = validators[v](parsedTree[f], parsedTree)
        }

        if (!isValid) {
          break
        }
      }

      if (isValid && parsedTree.operator === 'timeseriesPoint') {
        for (const f of timepointFields) {
          validators = evalExpressionValidators.timeseriesPoint[f]
          if (!validators) {
            throw new Error(`Validator is missing for field/property ${f}`)
          }

          for (const v in validators) {
            isValid = validators[v](parsedTree.timeseriesPoint[f], parsedTree.timeseriesPoint)
          }

          if (!isValid) {
            break
          }
        }
      }

      if (isValid && parsedTree.operator === 'timeseriesAggregate') {
        for (const f of aggregateFields) {
          validators = evalExpressionValidators.timeseriesAggregate[f]
          if (!validators) {
            throw new Error(`Validator is missing for field/property ${f}`)
          }

          for (const v in validators) {
            isValid = validators[v](parsedTree.timeseriesAggregate[f], parsedTree.timeseriesAggregate)
          }

          if (!isValid) {
            break
          }
        }
      }

      // abort iterateEvalTree iff not valid
      return isValid
    })
    return isValid
  }
}

const mutations = {
  SET_CURRENT_DECISION_TREE_IDX(state, idx) {
    state.currentDecisionTreeIdx = idx
  },
  SET_CURRENT_ENERGY_SERVICE_ID(state, { strategyId, actuatorGroupId }) {
    state.currentEnergyServiceId.strategyId = strategyId
    state.currentEnergyServiceId.actuatorGroupId = actuatorGroupId
  },
  SET(state, evalExpression) {
    state.hasChanged = false
    state.evalExpression = evalExpression
  },
  CLEAR(state) {
    state.hasChanged = false
    state.currentDecisionTreeIdx = null
    state.evalExpression = newEvalExpression()
  },
  ADD_ENTRY(state, { parentIdx, evalExpression }) {
    state.hasChanged = true
    if (!evalExpression) {
      evalExpression = newEvalExpression()
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== parentIdx) {
        return true
      }

      if (!parsedTree.isNode) {
        return false
      }

      switch (parsedTree.operatorKind) {
        case 'SINGLE':
          addReactivePropertyToTree(tree, parsedTree.operator, evalExpression)
          break
        case 'MULTI':
          tree[parsedTree.operator].expressionsList.push(evalExpression)
          break
        case 'BINARY':
          logger.warn(
            `Failed to add new entry to eval expression at IDX ${parentIdx}, Binary operator can only have exactly two childs.`
          )
          return false
        default:
          logger.warn(
            `Failed to add new entry to eval expression at IDX ${parentIdx}, because this point has an unsupported operator kind.`
          )
      }
      failed = false

      return false
    })

    if (failed) {
      logger.warn(`Failed to add new entry to eval expression at IDX ${parentIdx}.`)
    }
  },
  REMOVE_ENTRY(state, { nodeIdx }) {
    state.hasChanged = true
    if (nodeIdx === 0) {
      state.evalExpression = newEvalExpression()

      return
    }

    let failed = true
    let nodeParentIdx
    let nodeChildIdx
    iterateEvalTree(state.evalExpression, ({ idx, parentIdx, childIdx }) => {
      if (idx !== nodeIdx) {
        return true
      }

      nodeParentIdx = parentIdx
      nodeChildIdx = childIdx
    })

    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeParentIdx) {
        return true
      }

      if (!parsedTree.isNode || nodeChildIdx === undefined) {
        return false
      }

      failed = false
      switch (parsedTree.operatorKind) {
        case 'SINGLE':
          tree[parsedTree.operator] = newEvalExpression()
          break
        case 'MULTI':
          tree[parsedTree.operator].expressionsList.splice(nodeChildIdx, 1)
          break
        case 'BINARY':
          if (nodeChildIdx === 0) {
            tree[parsedTree.operator].lhs = newEvalExpression()
          } else if (nodeChildIdx === 1) {
            tree[parsedTree.operator].rhs = newEvalExpression()
          } else {
            failed = true
          }
          break
        default:
          failed = true
      }

      return false
    })

    if (failed) {
      logger.warn(`Failed to remove entry of eval expression at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_OPERATOR(state, { nodeIdx, operator }) {
    state.hasChanged = true
    if (!SUPPORTED_OPERATORS.includes(operator)) {
      throw new TypeError(`The operator ${operator} is not supported.`)
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      failed = false
      let operatorKind
      if (PRIMITIVE_OPERATORS.includes(operator)) {
        operatorKind = 'PRIMITIVE'
      } else if (SINGLE_ELEMENT_OPERATORS.includes(operator)) {
        operatorKind = 'SINGLE'
      } else if (BINARY_ELEMENT_OPERATORS.includes(operator)) {
        operatorKind = 'BINARY'
      } else {
        operatorKind = 'MULTI'
      }

      if (
        (operatorKind === 'SINGLE' && parsedTree.operatorKind === 'SINGLE') ||
        (operatorKind === 'BINARY' && parsedTree.operatorKind === 'BINARY') ||
        (operatorKind === 'MULTI' && parsedTree.operatorKind === 'MULTI')
      ) {
        addReactivePropertyToTree(tree, operator, tree[parsedTree.operator])
      } else if (operatorKind === 'PRIMITIVE') {
        switch (operator) {
          case 'value':
            addReactivePropertyToTree(tree, operator, 0)
            break
          case 'reference':
            // setting a whitespace is a way to distingish between reference not set yet and no one-of reference-field
            addReactivePropertyToTree(tree, operator, ' ')
            break
          case 'alias':
            // setting a whitespace is a way to distingish between alias not set yet and no one-of alias-field
            addReactivePropertyToTree(tree, operator, ' ')
            break
          case 'timeseriesPoint':
            addReactivePropertyToTree(tree, operator, { reference: '', deltaTime: 0 })
            break
          case 'timeseriesAggregate':
            addReactivePropertyToTree(tree, operator, {
              reference: '',
              deltaStartTime: 0,
              deltaEndTime: 0,
              op: TimeseriesAggregate.Operation.INTEGRAL
            })
            break
          default:
            addReactivePropertyToTree(tree, operator, null)
        }
      } else if (operatorKind === 'SINGLE') {
        addReactivePropertyToTree(tree, operator, newEvalExpression())
      } else if (operatorKind === 'BINARY') {
        addReactivePropertyToTree(tree, operator, newBinaryEvalExpression())
      } else if (operatorKind === 'MULTI') {
        addReactivePropertyToTree(tree, operator, newEvalExpressions())
      } else {
        failed = true
      }
      // clear 'old' operator entry
      if (parsedTree.operator && operator !== parsedTree.operator) {
        tree[parsedTree.operator] = undefined
      }

      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression operator at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_VALUE(state, { nodeIdx, value }) {
    state.hasChanged = true
    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      if (parsedTree.isNode) {
        return false
      }

      failed = false
      tree.value = typeof value === 'string' ? parseFloat(value) : value
      tree.reference = null
      tree.alias = null
      tree.timeseriesPoint = null
      tree.timeseriesAggregate = null

      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression value at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_REFERENCE(state, { nodeIdx, reference }) {
    state.hasChanged = true
    if (!reference) {
      throw new TypeError('The reference cannot be blank.')
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      if (parsedTree.isNode) {
        return false
      }

      failed = false
      tree.reference = reference
      tree.alias = null
      tree.value = null
      tree.timeseriesPoint = null
      tree.timeseriesAggregate = null

      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression value at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_ALIAS(state, { nodeIdx, alias }) {
    state.hasChanged = true
    if (!alias) {
      throw new TypeError('The alias cannot be blank.')
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      if (parsedTree.isNode) {
        return false
      }

      failed = false
      tree.reference = null
      tree.alias = alias
      tree.value = null
      tree.timeseriesPoint = null
      tree.timeseriesAggregate = null
      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression value at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_TIMEPOINT(state, { nodeIdx, reference, deltaTime }) {
    state.hasChanged = true
    if (!reference) {
      throw new TypeError('The reference cannot be blank.')
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      if (parsedTree.isNode) {
        return false
      }

      failed = false
      tree.timeseriesPoint = {
        reference: reference,
        deltaTime: deltaTime
      }
      tree.timeseriesAggregate = null

      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression value at IDX ${nodeIdx}.`)
    }
  },
  UPDATE_AGGREGATE(state, { nodeIdx, reference, deltaStartTime, deltaEndTime, op }) {
    state.hasChanged = true
    if (!reference) {
      throw new TypeError('The reference cannot be blank.')
    }

    let failed = true
    iterateEvalTree(state.evalExpression, ({ idx, tree, parsedTree }) => {
      if (idx !== nodeIdx) {
        return true
      }

      if (parsedTree.isNode) {
        return false
      }

      failed = false
      tree.timeseriesAggregate = {
        reference: reference,
        deltaStartTime: deltaStartTime,
        deltaEndTime: deltaEndTime,
        op: op
      }
      tree.timeseriesPoint = null

      return false
    })

    if (failed) {
      logger.warn(`Failed to update the eval expression value at IDX ${nodeIdx}.`)
    }
  },
  SET_UNCHANGED(state) {
    state.hasChanged = false
  }
}

function copyEvalExpressionFromDecisionTree({ rootState, commit }, { decisionTreeIdx }) {
  let success = false
  iterateDecisionTree(rootState.emsEnergyServicesConfig.decisionTree, (_entry, idx, tree) => {
    if (idx !== decisionTreeIdx) {
      return true
    }

    const node = tree.node
    if (!node) {
      commit('CLEAR')
      logger.warn(`Failed to copy eval expression from EMS decision tree IDX ${idx}, because the node is missing.`)

      return false
    }
    let evalExp
    if (node.greaterThanZero) {
      evalExp = node.greaterThanZero
    } else if (node.valueHysteresis && node.valueHysteresis.greaterThanZero) {
      evalExp = node.valueHysteresis.greaterThanZero
    } else if (node.socGreaterThan && node.socGreaterThan.socLimitExpression) {
      evalExp = node.socGreaterThan.socLimitExpression
    }
    if (!evalExp) {
      commit('CLEAR')
      logger.info(`Failed to copy eval expression from EMS decision tree IDX ${idx}`)

      return false
    }

    evalExp = cloneDeep(evalExp)
    commit('SET', evalExp)
    success = true
    logger.info(`Successfully copied eval expression from EMS decision tree IDX ${idx}.`)

    return false
  })

  if (success) {
    commit('SET_CURRENT_DECISION_TREE_IDX', decisionTreeIdx)
  }

  return success
}

function copyEvalExpressionToDecisionTree({ state, commit, rootGetters }, { decisionTreeIdx }) {
  const hasNode = rootGetters['emsEnergyServicesConfig/decisionTreeNodeIdxs'].includes(decisionTreeIdx)

  if (!hasNode) {
    logger.warn(
      `Failed to copy eval expression to EMS decision tree IDX ${decisionTreeIdx}, because the tree does not have a node at IDX.`
    )
    return false
  }

  const nodeParams = new DecisionTreePb.Node().toObject()
  nodeParams.greaterThanZero = cloneDeep(state.evalExpression)
  commit(
    'emsEnergyServicesConfig/UPDATE_DECISION_NODE_PARAMS',
    {
      idx: decisionTreeIdx,
      nodeParams
    },
    {
      root: true
    }
  )

  logger.info(`Successfully copied eval expression to EMS decision tree IDX ${decisionTreeIdx}.`)

  return true
}

function copyEvalExpressionToDecisionTreeVH({ state, commit, rootGetters }, { decisionTreeIdx, tolerance }) {
  const hasNode = rootGetters['emsEnergyServicesConfig/decisionTreeNodeIdxs'].includes(decisionTreeIdx)

  if (!hasNode) {
    logger.warn(
      `Failed to copy eval expression to EMS decision tree IDX ${decisionTreeIdx}, because the tree does not have a node at IDX.`
    )
    return false
  }

  const nodeParams = new DecisionTreePb.Node().toObject()
  const hysteresis = new DecisionTreePb.Node.ValueHysteresis().toObject()
  hysteresis.greaterThanZero = cloneDeep(state.evalExpression)
  hysteresis.tolerance = tolerance
  nodeParams.valueHysteresis = hysteresis
  commit(
    'emsEnergyServicesConfig/UPDATE_DECISION_NODE_PARAMS',
    {
      idx: decisionTreeIdx,
      nodeParams
    },
    {
      root: true
    }
  )

  logger.info(`Successfully copied eval expression to EMS decision tree IDX ${decisionTreeIdx}.`)

  return true
}

function copyEvalExpressionToDecisionTreeSoC({ state, commit, rootGetters }, { decisionTreeIdx, params }) {
  const hasNode = rootGetters['emsEnergyServicesConfig/decisionTreeNodeIdxs'].includes(decisionTreeIdx)

  if (!hasNode) {
    logger.warn(
      `Failed to copy eval expression to EMS decision tree IDX ${decisionTreeIdx}, because the tree does not have a node at IDX.`
    )
    return false
  }

  params.socLimitExpression = params.socLimitExpression === true ? cloneDeep(state.evalExpression) : undefined
  const nodeParams = new DecisionTreePb.Node().toObject()
  nodeParams.socGreaterThan = params
  commit(
    'emsEnergyServicesConfig/UPDATE_DECISION_NODE_PARAMS',
    {
      idx: decisionTreeIdx,
      nodeParams
    },
    {
      root: true
    }
  )

  logger.info(`Successfully copied eval expression to EMS decision tree IDX ${decisionTreeIdx}.`)

  return true
}

function copyEvalExpressionFromStrategy({ state, commit, rootGetters }, { strategyId, actuatorGroupId }) {
  let success = false
  const es = rootGetters['emsEnergyServicesConfig/getEnergyService']({ strategyId, actuatorGroupId })

  if (!es) {
    commit('CLEAR')
    logger.warn(
      `Failed to copy eval expression from EMS energy-service of strategy ${strategyId} and actuator-group ${actuatorGroupId}, because the energy-service is missing.`
    )

    return false
  }
  const evalExp = es.evalExpression
  if (!evalExp) {
    commit('CLEAR')
  } else {
    commit('SET', cloneDeep(evalExp))
  }
  success = true
  commit('SET_CURRENT_ENERGY_SERVICE_ID', { strategyId, actuatorGroupId })
  logger.info(
    `Successfully copied eval expression from EMS energy-service of strategy ${strategyId} and actuator-group ${actuatorGroupId}.`
  )

  return success
}

async function copyEvalExpressionFromAlias({ state, commit }, { aliasName }) {
  return getAlias(aliasName).then((evalPb) => {
    if (!evalPb) {
      return false
    }
    const evalExp = evalExpressionPbToPlain(evalPb)

    if (!evalExp) {
      commit('CLEAR')
    } else {
      commit('SET', cloneDeep(evalExp))
    }

    return true
  })
}

async function copyEvalExpressionToAlias({ state, commit }, { aliasName }) {
  const expr = state.evalExpression
  if (expr) {
    return setAlias(aliasName, evalExpressionPlainToPb(expr)).then(() => {
      commit('SET_UNCHANGED')
    })
  } else {
    throw new TypeError('The Eval Expression cannot be blank.')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    copyEvalExpressionFromDecisionTree,
    copyEvalExpressionToDecisionTree,
    copyEvalExpressionFromStrategy,
    copyEvalExpressionToDecisionTreeVH,
    copyEvalExpressionToDecisionTreeSoC,
    copyEvalExpressionFromAlias,
    copyEvalExpressionToAlias
  }
}

// private
function addReactivePropertyToTree(tree, operator, value) {
  if (Object.prototype.hasOwnProperty.call(tree, operator)) {
    tree[operator] = value
  } else {
    Vue.set(tree, operator, value)
  }
}
