/**
 * @module view-helper/viewport
 */

/**
 * Adds an Eventlistener and detects whether the screen size matches mobile viewport sizes.
 * In order to use this function, call it like this: registerViewportMobileSizeListener.call(this) and register
 * a local variable 'isMobileSize' in the component
 * @function
 *
 */

export function registerViewportMobileSizeListener() {
  // TODO: import SCSS media breakpoints into JS via webpack
  const mql = window.matchMedia('screen and (max-width: 1441px)')
  mql.addListener((e) => {
    this.isMobileSize = e.matches
  })
  this.isMobileSize = mql.matches
}
