export const itwmFooterLogoLight = [
  '600 163.6',
  `
    <style>
      .a {
        fill: #009b7c;
      }

      .b {
        fill: #fff;
      }

      .c {
        fill: #231815;
      }
    </style>
  </defs>
  <g>
    <path class="a" d="M0,97.8C23.1,85.9,57,66.6,97.3,38.5V29C61.9,52.2,25.9,70.8,0,83.1Z"/>
    <path class="a" d="M0,69.6C25.8,59.1,61.8,42.9,97.3,21.8V17.1C63.9,33.7,29.3,44,0,50.5Z"/>
    <path class="a" d="M97.3,97.9V50.4C75.2,69.1,53.2,85.2,34.8,97.9Z"/>
    <path class="a" d="M22.3,97.9A678.23,678.23,0,0,0,82.2,58.1c4.9-3.7,10-7.5,15.1-11.4V40.8C66,64.3,34.1,83.8,9.1,97.9Z"/>
  </g>
  <g>
    <path class="a" d="M0,43.6c28.5-5,63.3-14,97.3-30.2V8.1C63,23.5,28.7,30,0,32.3Z"/>
    <path class="a" d="M0,.1V27.3c2.1-.1,4.3-.3,6.5-.5,23.7-1.8,55.9-6,90.8-21.1V0H0Z"/>
  </g>
  <g>
    <path class="b" d="M0,27.3v5C28.7,30,63,23.5,97.3,8.1V5.8C62.5,20.9,30.2,25,6.5,26.9c-2.2.1-4.4.3-6.5.4"/>
    <path class="b" d="M0,50.5C29.2,44,63.9,33.7,97.3,17.1V13.4C63.3,29.6,28.5,38.6,0,43.6Z"/>
    <path class="b" d="M0,83.1C25.8,70.8,61.9,52.2,97.3,29V21.7C61.8,42.8,25.8,59,0,69.5Z"/>
    <path class="b" d="M22.3,97.9H34.7C53.1,85.2,75.1,69,97.2,50.4V46.6C92.1,50.5,87.1,54.3,82.1,58A652.35,652.35,0,0,1,22.3,97.9"/>
    <path class="b" d="M0,97.9H9.1C34.1,83.8,66,64.3,97.3,40.8V38.5A886.42,886.42,0,0,1,0,97.9Z"/>
    <path class="c" d="M571.7,97.8h12.5V80.1c0-6.9,0-19.6,10.2-19.6a13.68,13.68,0,0,1,5.6,1V48.9a12.63,12.63,0,0,0-3.8-.4c-7.5,0-12.6,7.6-13.2,12h-.2V49.6H571.7ZM559.1,84.9c-4.7,2.6-9.9,4.9-16,4.9-7.7,0-13.2-4.4-13.7-12.1h33.2c0-16.8-5-29-22.9-29-14.9,0-22.3,11.5-22.3,25.4,0,15.8,9.2,25,25.1,25,6.7,0,11.9-1.3,16.5-3.8V84.9ZM529.4,69c.6-6.4,4.1-11.3,11.1-11.3s9.8,5.2,10.2,11.3ZM489.5,97.8H502v-39h10.9V49.6H502v-6c0-3.7,1.3-7.4,5.5-7.4a9.25,9.25,0,0,1,5.4,1.7l1-10.4a41.78,41.78,0,0,0-9.2-1c-9.9,0-15.3,6.7-15.3,16.3v6.7h-9.2v9.2h9.2V97.8ZM439.7,72.6c0-7.2,3.9-14.3,12.1-14.3s12.1,6.9,12.1,14.3c0,8-2.5,16.6-12.1,16.6s-12.1-8.7-12.1-16.6m-13.1,1.5c0,13.8,9.2,24.8,25.1,24.8s25.2-11,25.2-24.8c0-15.9-10.9-25.5-25.2-25.5-14.1,0-25.1,9.6-25.1,25.5M374.5,97.8H387V74.3c0-6.2,2-16,11-16,8,0,8.1,7.9,8.1,13.8V97.8h12.5V67.4c0-10.8-4.9-18.9-16.3-18.9a17.76,17.76,0,0,0-15.1,7.7H387V27.7H374.5Zm-57,0H330V74.3c0-6.2,2-16,11-16,8,0,8.1,7.9,8.1,13.8V97.8h12.5V67.4c0-10.8-4.9-18.9-16.3-18.9-6.5,0-11.9,2.1-15.8,7.7h-.2V49.7H317.5V97.8ZM304.6,49.7H292.1V73.2c0,6.2-2,16-11,16-8,0-8.1-7.9-8.1-13.8V49.7H260.5V80.1c0,10.8,4.9,18.9,16.3,18.9a20.05,20.05,0,0,0,15.8-7.7h.2v6.5h11.8ZM219.2,83.9c0-7.4,8.3-8.3,13.9-8.3h5.4c0,3.7-.6,7.2-2.6,9.9a10.66,10.66,0,0,1-8.8,4.3c-4.4,0-7.9-1.8-7.9-5.9M212.9,63a24.88,24.88,0,0,1,14.8-5.2c7.4,0,10.8,2.6,10.8,10.4h-6.9c-5.2,0-11.4.5-16.1,2.8s-8.2,6.5-8.2,13.7c0,9.2,8.3,14.3,16.8,14.3,5.7,0,12-3,14.8-8.3h.2a54.57,54.57,0,0,0,.6,7.2h11c-.3-3.7-.5-7.1-.6-10.7s-.2-7.2-.2-12.1V68.9c0-14.2-6.1-20.3-20.7-20.3a43.55,43.55,0,0,0-16.7,3.6ZM175.2,97.8h12.5V80.1c0-6.9,0-19.6,10.2-19.6a13.68,13.68,0,0,1,5.6,1V48.9a12.63,12.63,0,0,0-3.8-.4c-7.5,0-12.6,7.6-13.2,12h-.2V49.6H175.2Zm-45.2,0h13.1V69.2h22V58.9h-22V42.8h23.2V32.6H130.1V97.8Z"/>
    <rect class="c" x="476.8" y="131" width="6.3" height="32.6"/>
    <polygon class="c" points="497 136.2 488 136.2 488 131 512.4 131 512.4 136.2 503.3 136.2 503.3 163.6 497 163.6 497 136.2"/>
    <polygon class="c" points="515.7 131 522.3 131 528.2 157.1 528.3 157.1 533.6 131 542.2 131 547.8 157.1 547.9 157.1 553.8 131 559.9 131 551.7 163.6 543.6 163.6 537.9 137.6 537.8 137.6 532.2 163.6 524 163.6 515.7 131"/>
    <polygon class="c" points="564.7 131 574.8 131 582.3 155.7 582.4 155.7 589.9 131 600 131 600 163.6 593.9 163.6 593.9 136.7 593.9 136.7 585.4 163.6 579.3 163.6 570.8 136.7 570.7 136.8 570.7 163.6 564.7 163.6 564.7 131"/>
  </g>
`
]

export const itwmFooterLogoDark = [
  '600 163.6',
  `
    <style>
      .a {
        fill: #009b7c;
      }

      .b {
        fill: #fff;
      }

      .c {
        fill: #e1e1e1;
      }
    </style>
  </defs>
  <g>
    <path class="a" d="M0,97.8C23.1,85.9,57,66.6,97.3,38.5V29C61.9,52.2,25.9,70.8,0,83.1Z"/>
    <path class="a" d="M0,69.6C25.8,59.1,61.8,42.9,97.3,21.8V17.1C63.9,33.7,29.3,44,0,50.5Z"/>
    <path class="a" d="M97.3,97.9V50.4C75.2,69.1,53.2,85.2,34.8,97.9Z"/>
    <path class="a" d="M22.3,97.9A678.23,678.23,0,0,0,82.2,58.1c4.9-3.7,10-7.5,15.1-11.4V40.8C66,64.3,34.1,83.8,9.1,97.9Z"/>
  </g>
  <g>
    <path class="a" d="M0,43.6c28.5-5,63.3-14,97.3-30.2V8.1C63,23.5,28.7,30,0,32.3Z"/>
    <path class="a" d="M0,.1V27.3c2.1-.1,4.3-.3,6.5-.5,23.7-1.8,55.9-6,90.8-21.1V0H0Z"/>
  </g>
  <g>
    <path class="b" d="M0,27.3v5C28.7,30,63,23.5,97.3,8.1V5.8C62.5,20.9,30.2,25,6.5,26.9c-2.2.1-4.4.3-6.5.4"/>
    <path class="b" d="M0,50.5C29.2,44,63.9,33.7,97.3,17.1V13.4C63.3,29.6,28.5,38.6,0,43.6Z"/>
    <path class="b" d="M0,83.1C25.8,70.8,61.9,52.2,97.3,29V21.7C61.8,42.8,25.8,59,0,69.5Z"/>
    <path class="b" d="M22.3,97.9H34.7C53.1,85.2,75.1,69,97.2,50.4V46.6C92.1,50.5,87.1,54.3,82.1,58A652.35,652.35,0,0,1,22.3,97.9"/>
    <path class="b" d="M0,97.9H9.1C34.1,83.8,66,64.3,97.3,40.8V38.5A886.42,886.42,0,0,1,0,97.9Z"/>
    <path class="c" d="M571.7,97.8h12.5V80.1c0-6.9,0-19.6,10.2-19.6a13.68,13.68,0,0,1,5.6,1V48.9a12.63,12.63,0,0,0-3.8-.4c-7.5,0-12.6,7.6-13.2,12h-.2V49.6H571.7ZM559.1,84.9c-4.7,2.6-9.9,4.9-16,4.9-7.7,0-13.2-4.4-13.7-12.1h33.2c0-16.8-5-29-22.9-29-14.9,0-22.3,11.5-22.3,25.4,0,15.8,9.2,25,25.1,25,6.7,0,11.9-1.3,16.5-3.8V84.9ZM529.4,69c.6-6.4,4.1-11.3,11.1-11.3s9.8,5.2,10.2,11.3ZM489.5,97.8H502v-39h10.9V49.6H502v-6c0-3.7,1.3-7.4,5.5-7.4a9.25,9.25,0,0,1,5.4,1.7l1-10.4a41.78,41.78,0,0,0-9.2-1c-9.9,0-15.3,6.7-15.3,16.3v6.7h-9.2v9.2h9.2V97.8ZM439.7,72.6c0-7.2,3.9-14.3,12.1-14.3s12.1,6.9,12.1,14.3c0,8-2.5,16.6-12.1,16.6s-12.1-8.7-12.1-16.6m-13.1,1.5c0,13.8,9.2,24.8,25.1,24.8s25.2-11,25.2-24.8c0-15.9-10.9-25.5-25.2-25.5-14.1,0-25.1,9.6-25.1,25.5M374.5,97.8H387V74.3c0-6.2,2-16,11-16,8,0,8.1,7.9,8.1,13.8V97.8h12.5V67.4c0-10.8-4.9-18.9-16.3-18.9a17.76,17.76,0,0,0-15.1,7.7H387V27.7H374.5Zm-57,0H330V74.3c0-6.2,2-16,11-16,8,0,8.1,7.9,8.1,13.8V97.8h12.5V67.4c0-10.8-4.9-18.9-16.3-18.9-6.5,0-11.9,2.1-15.8,7.7h-.2V49.7H317.5V97.8ZM304.6,49.7H292.1V73.2c0,6.2-2,16-11,16-8,0-8.1-7.9-8.1-13.8V49.7H260.5V80.1c0,10.8,4.9,18.9,16.3,18.9a20.05,20.05,0,0,0,15.8-7.7h.2v6.5h11.8ZM219.2,83.9c0-7.4,8.3-8.3,13.9-8.3h5.4c0,3.7-.6,7.2-2.6,9.9a10.66,10.66,0,0,1-8.8,4.3c-4.4,0-7.9-1.8-7.9-5.9M212.9,63a24.88,24.88,0,0,1,14.8-5.2c7.4,0,10.8,2.6,10.8,10.4h-6.9c-5.2,0-11.4.5-16.1,2.8s-8.2,6.5-8.2,13.7c0,9.2,8.3,14.3,16.8,14.3,5.7,0,12-3,14.8-8.3h.2a54.57,54.57,0,0,0,.6,7.2h11c-.3-3.7-.5-7.1-.6-10.7s-.2-7.2-.2-12.1V68.9c0-14.2-6.1-20.3-20.7-20.3a43.55,43.55,0,0,0-16.7,3.6ZM175.2,97.8h12.5V80.1c0-6.9,0-19.6,10.2-19.6a13.68,13.68,0,0,1,5.6,1V48.9a12.63,12.63,0,0,0-3.8-.4c-7.5,0-12.6,7.6-13.2,12h-.2V49.6H175.2Zm-45.2,0h13.1V69.2h22V58.9h-22V42.8h23.2V32.6H130.1V97.8Z"/>
    <rect class="c" x="476.8" y="131" width="6.3" height="32.6"/>
    <polygon class="c" points="497 136.2 488 136.2 488 131 512.4 131 512.4 136.2 503.3 136.2 503.3 163.6 497 163.6 497 136.2"/>
    <polygon class="c" points="515.7 131 522.3 131 528.2 157.1 528.3 157.1 533.6 131 542.2 131 547.8 157.1 547.9 157.1 553.8 131 559.9 131 551.7 163.6 543.6 163.6 537.9 137.6 537.8 137.6 532.2 163.6 524 163.6 515.7 131"/>
    <polygon class="c" points="564.7 131 574.8 131 582.3 155.7 582.4 155.7 589.9 131 600 131 600 163.6 593.9 163.6 593.9 136.7 593.9 136.7 585.4 163.6 579.3 163.6 570.8 136.7 570.7 136.8 570.7 163.6 564.7 163.6 564.7 131"/>
  </g>
`
]
