<template>
  <CCol sm="12" :lg="{ size: 8, offset: 2 }" :xl="{ size: 6, offset: 3 }">
    <!-- BEGIN: Heading/Title -->
    <h2 class="welcome-heading text-uppercase text-bold text-center text-white pb-3 mb-3">
      <p>{{ brandName }}</p>
    </h2>
    <!-- END: Heading/Title -->
    <CCardGroup class="px-2 mb-5">
      <!-- BEGIN: Login Box  -->
      <CCard>
        <CCardBody>
          <h2 class="pb-2">
            {{ $t(`${tBasePath}.title`) }}
          </h2>
          <CForm v-if="!newCredentialsRequired" @submit.prevent @keyup.enter="login">
            <!-- BEGIN: Prompt for user to use new password  -->
            <CCardText v-if="loggingInWithNewCredentials" class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-muted text-break">
                {{ $t(`${tNewUserLoginPath}.prompt.useNewPassword`) }}
              </p>
            </CCardText>
            <!-- END: Prompt for user to use new password   -->
            <CInput
              ref="usernameInput"
              class="custom-form-label"
              :value.sync="$v.loginForm.username.$model"
              :disabled="!hasLicense"
              :placeholder="$t(`${tExistingUserLoginPath}.username.placeholder`)"
              :is-valid="!$v.loginForm.username.$dirty ? null : !$v.loginForm.username.$invalid"
              :invalid-feedback="$t(`${tExistingUserLoginPath}.username.invalidFeedback`)"
            >
              <template #prepend-content>
                <CIcon name="cil-user" />
              </template>
            </CInput>
            <CInput
              class="custom-form-label"
              :value.sync="$v.loginForm.password.$model"
              :disabled="!hasLicense"
              :placeholder="$t(`${tExistingUserLoginPath}.password.placeholder`)"
              :is-valid="!$v.loginForm.password.$dirty ? null : !$v.loginForm.password.$invalid"
              :invalid-feedback="$t(`${tExistingUserLoginPath}.password.invalidFeedback`)"
              :type="showPassword.login.password ? 'text' : 'password'"
            >
              <template #prepend-content>
                <CIcon name="cil-lock-locked" />
              </template>
              <!-- BEGIN: Toggle Password Visibility -->
              <template #append-content>
                <PwdVisibilityToggle
                  tabindex="-1"
                  :disabled="!hasLicense"
                  :show-password="showPassword.login.password"
                  @togglePwdVisibility="showPassword.login.password = !showPassword.login.password"
                />
              </template>
              <!-- END: Toggle Password Visibility  -->
            </CInput>
            <!-- BEGIN: Login Action Buttons -->
            <CRow>
              <CCol class="text-left col-4">
                <CButton color="primary" class="px-2" :disabled="!hasLicense" @click="login">
                  {{ $t(`${tBasePath}.submit`) }}
                </CButton>
              </CCol>
              <CCol class="text-right col-8">
                <CButton
                  v-if="!hasLicense"
                  color="link"
                  :class="{
                    'd-lg-none': true,
                    'btn-link-darkMode': isDark
                  }"
                  :disabled="hasLicense"
                  @click="routeToRegistration"
                >
                  {{ $t(`${tBasePath}.registrationInitial.link`) }}
                </CButton>
                <!-- prettier-ignore -->
                <HelpBtn
                  class="d-lg-none"
                  @toggleHelpText="showIsRegisteredInfo = !showIsRegisteredInfo"
                />
              </CCol>
            </CRow>
            <!-- NOTE: Row without col is used for better alignment with submit button -->
            <CRow v-if="hasLicense">
              <CButton
                color="link"
                class="ml-1 mt-2"
                :class="{
                  'btn-link-darkMode': isDark
                }"
                @click="routToSetupUserWithPuk"
              >
                {{ $t(`${tBasePath}.forgotCredentials`) }}
              </CButton>
            </CRow>
            <!-- END: Login Action Buttons -->
          </CForm>
          <!-- END: User Login Form  -->
          <!-- ================================ -->
          <!-- BEGIN: Change Initial Credentials Form  -->
          <CForm v-if="newCredentialsRequired" @submit.prevent>
            <!-- BEGIN: Prompt for user to create new password  -->
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-muted text-break">
                {{ $t(`${tNewUserLoginPath}.prompt.changePassword`) }}
              </p>
            </CCardText>
            <!-- END: Prompt for user to create new password  -->
            <CInput
              class="custom-form-label"
              :value.sync="$v.changeInitialCredentialsForm.newPassword.$model"
              :label="$t(`${tNewUserLoginPath}.newPassword.label`)"
              :placeholder="$t(`${tNewUserLoginPath}.newPassword.placeholder`)"
              :is-valid="
                $v.changeInitialCredentialsForm.newPassword.$dirty
                  ? !$v.changeInitialCredentialsForm.newPassword.$invalid
                  : null
              "
              :invalid-feedback="invalidFeedbackFor('newPassword')"
              :type="showPassword.newCredentialsRequired.password ? 'text' : 'password'"
            >
              <!-- BEGIN: Toggle Password Visibility -->
              <template #append-content>
                <PwdVisibilityToggle
                  :show-password="showPassword.newCredentialsRequired.password"
                  @togglePwdVisibility="
                    showPassword.newCredentialsRequired.password = !showPassword.newCredentialsRequired.password
                  "
                />
              </template>
              <!-- END: Toggle Password Visibility  -->
            </CInput>
            <!-- END: newPassword   -->
            <!-- BEGIN: newPasswordConfirm -->
            <CInput
              class="custom-form-label"
              :value.sync="$v.changeInitialCredentialsForm.newPasswordConfirm.$model"
              :label="$t(`${tNewUserLoginPath}.newPasswordConfirm.label`)"
              :placeholder="$t(`${tNewUserLoginPath}.newPasswordConfirm.placeholder`)"
              :is-valid="
                $v.changeInitialCredentialsForm.newPasswordConfirm.$dirty
                  ? !$v.changeInitialCredentialsForm.newPasswordConfirm.$invalid
                  : null
              "
              :invalid-feedback="invalidFeedbackFor('newPasswordConfirm')"
              :type="showPassword.newCredentialsRequired.passwordConfirm ? 'text' : 'password'"
            >
              <!-- BEGIN: Toggle Password Visibility -->
              <template #append-content>
                <PwdVisibilityToggle
                  :show-password="showPassword.newCredentialsRequired.passwordConfirm"
                  @togglePwdVisibility="
                    showPassword.newCredentialsRequired.passwordConfirm =
                      !showPassword.newCredentialsRequired.passwordConfirm
                  "
                />
              </template>
              <!-- END: Toggle Password Visibility  -->
            </CInput>
            <!-- END: newPasswordConfirm -->
            <div class="mt-2 form-row px-2">
              <CButton class="mr-2" size="sm" color="primary" @click="loginWithNewCredentials">
                {{ $t('main.saveBtn') }}
              </CButton>
            </div>
          </CForm>
          <!-- END: Change Initial Credentials Form  -->
          <!-- BEGIN: Toggleable help text for small devices -->
          <template v-if="showIsRegisteredInfo">
            <!-- BEGIN: Both license and user exist => regular login -->
            <div v-if="hasLicense && !newUserRequired" class="text-muted small mt-2 d-lg-none">
              <p>
                {{ $t(`${tBasePath}.registrationCompleted.content`, [brandName]) }}
              </p>
            </div>
            <!-- END: Both license and user exist => regular login -->
            <!-- BEGIN: License is missing, user exists => need to update the license -->
            <div v-else-if="!hasLicense && !newUserRequired" class="text-muted small mt-2 d-lg-none">
              <p>
                {{ $t(`${tBasePath}.licenseMissingUserExists.content`, [brandName]) }}
              </p>
            </div>
            <!-- END: License is missing, user exists => need to update the license -->
            <!-- BEGIN: License exists, user is missing => need to create user via PUK -->
            <div v-else-if="hasLicense && newUserRequired" class="text-muted small mt-2 d-lg-none">
              <p>
                {{ $t(`${tBasePath}.licenseExistsUserMissing.content`, [brandName]) }}
              </p>
            </div>
            <!-- END: License exists, user is missing => need to create user via PUK -->
            <!-- BEGIN: Neither License nor user exist => initial setup -->
            <div v-else class="text-muted small mt-2 d-lg-none">
              <p>
                {{ $t(`${tBasePath}.registrationInitial.content`, [brandName]) }}
              </p>
            </div>
            <!-- END: Neither License nor user exist => initial setup -->
          </template>
          <!-- END:   Toggleable help text for small devices -->
        </CCardBody>
      </CCard>
      <!-- END: Login Box  -->
      <!-- BEGIN: Registration Box  -->
      <!-- prettier-ignore -->
      <CCard
        :color="isDark ? 'dark-1' : 'primary'"
        text-color="white"
        class="d-md-down-none"
      >
        <CCardBody>
          <!-- BEGIN: License existis, user is missing => need to create user via PUK -->
          <template v-if="hasLicense && newUserRequired">
            <h2 class="text-left pb-2">
              {{ $t(`${tBasePath}.licenseExistsUserMissing.title`) }}
            </h2>
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-break">
                {{ $t(`${tBasePath}.licenseExistsUserMissing.content`, [brandName]) }}
              </p>
            </CCardText>
          </template>
          <!-- END: License existis, user is missing => need to create user via PUK   -->
          <!-- BEGIN: License is missing, user exists => need to update the license -->
          <template v-else-if="!hasLicense && !newUserRequired">
            <h2 class="text-left pb-1">
              {{ $t(`${tBasePath}.licenseMissingUserExists.title`) }}
            </h2>
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-break">
                {{ $t(`${tBasePath}.licenseMissingUserExists.content`, [brandName]) }}
              </p>
            </CCardText>
          </template>
          <!-- END: License is missing, user exists => need to update the license -->
          <!-- BEGIN: Both license and user exist => regular login -->
          <template v-else-if="hasLicense && !newUserRequired">
            <h2 class="text-left pb-2">
              {{ $t(`${tBasePath}.registrationCompleted.title`) }}
            </h2>
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-break">
                {{ $t(`${tBasePath}.registrationCompleted.content`, [brandName]) }}
              </p>
            </CCardText>
          </template>
          <!-- END: Both license and user exist => login -->
          <!-- BEGIN: Neither License nor user exist => initial setup -->
          <template v-else>
            <h2 class="text-left pb-1">
              {{ $t(`${tBasePath}.registrationInitial.title`) }}
            </h2>
            <CCardText class="small m-0 p-0 d-flex justify-content-start">
              <p class="text-break">
                {{ $t(`${tBasePath}.registrationInitial.content`, [brandName]) }}
              </p>
            </CCardText>
          </template>
          <!-- END: Neither License nor user exist => initial setup -->
          <!-- BEGIN: Registration Action Button -->
          <CRow>
            <CCol class="d-flex justify-content-end mt-1">
              <!-- prettier-ignore -->
              <CButton
                :disabled="hasLicense"
                color="light"
                variant="outline"
                @click="routeToRegistration"
              >
                {{ $t(`${tBasePath}.registrationInitial.link`) }}
              </CButton>
            </CCol>
          </CRow>
          <!-- END: Registration Action Button -->
        </CCardBody>
      </CCard>
      <!-- END: Registration Box -->
    </CCardGroup>
  </CCol>
</template>

<script>
import VueRouter from 'vue-router'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

import HelpBtn from '@/components/snippets/help-btn'
import PwdVisibilityToggle from '@/components/snippets/pwd-visibility-toggle'

import { newToastNotificationMsg } from '@/store/modules/notifications'
import { registrationValidations } from '@/validations/user-settings-validators'

export default {
  name: 'Login',
  components: {
    HelpBtn,
    PwdVisibilityToggle
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      changeInitialCredentialsForm: {
        newPassword: null,
        newPasswordConfirm: null
      },
      newCredentialsRequired: false,
      loggingInWithNewCredentials: false,
      showIsRegisteredInfo: false,
      showPassword: {
        login: {
          password: false
        },
        newCredentialsRequired: {
          password: false,
          passwordConfirm: false
        }
      }
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('auth', ['hasLicense']),
    ...mapGetters('user', [
      'lang',
      'isLoggedIn',
      'claimsNotBefore',
      'claimsNotAfter',
      'satisfiesClaimsNotBeforeTimestamp',
      'satisfiesClaimsNotAfterTimestamp'
    ]),
    ...mapState('auth', ['newUserRequired']),
    invalidFeedbackFor() {
      return (field) => {
        // NOTE: Feedback for regular login is handled directly in the template
        const isInvalid = (condition) => {
          return !this.$v.changeInitialCredentialsForm[field][condition]
        }
        const base = `management.user.login.newUserLoginForm.${field}.invalidFeedback`
        let conditions = []

        switch (field) {
          case 'newPassword':
            conditions = ['required', 'minLength', 'strongPasswordRequired']
            break
          case 'newPasswordConfirm':
            conditions = ['required', 'sameAsPassword']
            break
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    }
  },
  created() {
    this.brandName = process.env.VUE_APP_BRAND_NAME
    this.tBasePath = 'management.user.login'
    this.tExistingUserLoginPath = 'management.user.login.existingUserLoginForm'
    this.tNewUserLoginPath = 'management.user.login.newUserLoginForm'
  },
  mounted() {
    setTimeout(() => {
      this.$refs?.usernameInput?.$el.querySelector('input').focus()
    }, 500)
  },
  methods: {
    async login() {
      this.$store.commit('apiLoadingStatus/STARTED')

      const username = this.loginForm.username
      const password = this.loginForm.password

      try {
        const claims = await this.$store.dispatch('user/login', { username, password })

        if (claims.loginWithNewCredentials !== undefined) {
          this.$log.debug('NEW_CREDENTIALS_REQUIRED for user login.')
          this.newCredentialsRequired = true
          this.loginWithNewCredentialsHandler = claims.loginWithNewCredentials

          return
        }

        // handle failed token validation,
        // e.g. if Apx-time or browser-time are false
        if (!this.isLoggedIn) {
          this.$log.warn(
            'Invalid token claims. Expected to be logged-in, however, the received token claims seem to be invalid.'
          )
          const errMessage = {
            title: this.$t(`${this.tBasePath}.claims.invalid.title`),
            content: []
          }
          const now = moment().locale(this.lang)
          if (!this.satisfiesClaimsNotBeforeTimestamp && this.claimsNotBefore) {
            errMessage.content.push(
              this.$t(`${this.tBasePath}.claims.invalid.notBefore`, [
                this.claimsNotBefore.format('lll'),
                now.format('lll')
              ])
            )
          }
          if (!this.satisfiesClaimsNotAfterTimestamp && this.claimsNotAfter) {
            errMessage.content.push(
              this.$t(`${this.tBasePath}.claims.invalid.notAfter`, [
                this.claimsNotAfter.format('lll'),
                now.format('lll')
              ])
            )
          }
          if (!this.claimsNotBefore && !this.claimsNotAfter) {
            errMessage.content.push(this.$t(`${this.tBasePath}.claims.invalid.required`))
          } else {
            errMessage.content.push(this.$t(`${this.tBasePath}.claims.invalid.help`))
          }

          this.$store.commit(
            'notifications/PUSH_TOAST',
            newToastNotificationMsg({
              autohide: false,
              type: 'danger',
              title: errMessage.title,
              content: errMessage.content.join(' ')
            })
          )

          // remain on the login page
          return
        }

        this.$log.debug('User succesfully logged in.', claims)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 3000,
            type: 'success',
            title: this.$t(`${this.tBasePath}.loginAuthToasterTitle`),
            content: this.$t('api.success.login')
          })
        )

        // navigate back, iff this page was NOT the first page ever visited
        // e.g. this is usefull, in case a user was (auto-) logged out before
        if (VueRouter.START_LOCATION.path === this.$route.path) {
          this.$router.push({ name: 'home' })
        } else {
          this.$router.back()
        }
      } catch (err) {
        this.onServerError(err)
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    async loginWithNewCredentials() {
      this.$store.commit('apiLoadingStatus/STARTED')

      const newPassword = this.changeInitialCredentialsForm.newPassword

      try {
        const claims = await this.loginWithNewCredentialsHandler({ newPassword })

        this.$log.debug('Successful user login with new credentials.', claims)
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 3000,
            type: 'success',
            title: this.$t(`${this.tBasePath}.loginAuthToasterTitle`),
            content: this.$t('api.success.changeInitialPassword')
          })
        )

        this.newCredentialsRequired = false
        this.loggingInWithNewCredentials = true
        this.loginForm.password = ''
      } catch (err) {
        this.onServerError(err)
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    },
    onServerError(err) {
      this.$log.warn(err)

      // TODO: Need to distinguish Authentication from Server Errors
      if (err.name === 'ServerError') {
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 10000,
            type: 'danger',
            title: this.$t('api.errors.server'),
            content: this.$t('api.errors.login')
          })
        )
      }
      if (err.name === 'AuthenticationError') {
        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 10000,
            type: 'danger',
            title: this.$t(`${this.tBasePath}.loginAuthToasterTitle`),
            content: this.$t('api.errors.login')
          })
        )
      }
    },
    routToSetupUserWithPuk() {
      this.$router.push({ name: 'puk' })
    },
    routeToRegistration() {
      this.$router.push({ name: 'register' })
    }
  },
  validations: {
    loginForm: {
      username: {
        required
      },
      password: {
        required
      }
    },
    changeInitialCredentialsForm: {
      newPassword: registrationValidations().password,
      newPasswordConfirm: {
        required,
        samsAsNewPassword: sameAs('newPassword')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.welcome-heading {
  font-size: 4rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 3rem;
  text-shadow: 0 3px rgba(0, 0, 0, 0.7);
}
</style>
