<template>
  <div class="mt-4">
    <CRow>
      <CCol md="10" class="p-0">
        <CInput
          type="number"
          step="1"
          :horizontal="formHorizontal"
          :invalid-feedback="invalidFeedbackForAGLisMaxCurrentGrid"
          :is-valid="$v.maxCurrentGrid.$dirty ? !$v.maxCurrentGrid.$error : null"
          :label="$t(`${tBasePath}.carLis.currentGrid.description`)"
          :disabled="carLis.ignoreCurrentGrid"
          :placeholder="0"
          :description="$t(`${tBasePath}.carLis.currentGrid.description`)"
          :value.sync="$v.maxCurrentGrid.$model"
          @change="(val) => $emit('update', { maxCurrentGrid: val })"
        />
      </CCol>
      <CCol md="2">
        <CInputCheckbox
          :label="$t(`${tBasePath}.carLis.ignore`)"
          class="align-self-center"
          size="sm"
          color="primary"
          :checked.sync="carLis.ignoreCurrentGrid"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="10" class="p-0">
        <CInput
          type="number"
          step="1"
          :horizontal="formHorizontal"
          :invalid-feedback="invalidFeedbackForAGLisMaxPowerGrid"
          :is-valid="$v.maxPowerGrid.$dirty ? !$v.maxPowerGrid.$error : null"
          :label="$t(`${tBasePath}.carLis.powerGrid.description`)"
          :disabled="carLis.ignorePowerGrid"
          :placeholder="0"
          :description="$t(`${tBasePath}.carLis.powerGrid.description`)"
          :value.sync="$v.maxPowerGrid.$model"
          @change="(val) => $emit('update', { maxPowerGrid: val })"
        />
      </CCol>
      <CCol md="2">
        <CInputCheckbox
          :label="$t(`${tBasePath}.carLis.ignore`)"
          class="align-self-center"
          size="sm"
          color="primary"
          :checked.sync="carLis.ignorePowerGrid"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="10" class="p-0">
        <CInput
          type="number"
          step="1"
          :horizontal="formHorizontal"
          :invalid-feedback="invalidFeedbackForAGLisMaxCurrentLis"
          :is-valid="$v.maxCurrentLis.$dirty ? !$v.maxCurrentLis.$error : null"
          :label="$t(`${tBasePath}.carLis.currentLis.description`)"
          :disabled="carLis.ignoreCurrentLis"
          :placeholder="0"
          :description="$t(`${tBasePath}.carLis.currentLis.description`)"
          :value.sync="$v.maxCurrentLis.$model"
          @change="(val) => $emit('update', { maxCurrentLis: val })"
        />
      </CCol>
      <CCol md="2">
        <CInputCheckbox
          :label="$t(`${tBasePath}.carLis.ignore`)"
          class="align-self-center"
          size="sm"
          color="primary"
          :checked.sync="carLis.ignoreCurrentLis"
        />
      </CCol>
    </CRow>
    <div v-for="source in $v.chargePoint.$each.$iter" :key="source.name.$model" class="mt-2">
      <p class="font-weight-bold mb-0">
        {{ source.name.$model }}
      </p>

      <CInput
        type="number"
        class="ml-4"
        step="1"
        :horizontal="formHorizontal"
        :invalid-feedback="invalidFeedbackLis"
        :is-valid="source.maxAmpere.$dirty ? !source.maxAmpere.$error : null"
        :label="$t(`${tBasePath}.carLis.maxAmpere`)"
        :placeholder="16"
        :value.sync="source.maxAmpere.$model"
      />
    </div>
    <small class="text-muted form-text mb-2">
      {{ $t(`${tBasePath}.carLis.description`, { platform: platformName }) }}
    </small>
  </div>
</template>
<script>
import { carLisValidators } from '@/validations/ems-es-actuator-group-validators'

export default {
  name: 'AsActuatorGroupForm',
  props: {
    carLis: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      maxCurrentGrid: null,
      ignoreCurrentGrid: null,
      maxPowerGrid: null,
      ignorePowerGrid: null,
      maxCurrentLis: null,
      ignoreCurrentLis: null,
      chargePoint: []
    }
  },
  computed: {
    invalidFeedbackForAGLisMaxCurrentGrid() {
      if (!this.$v.maxCurrentGrid.$error) {
        return ''
      }

      if (!this.$v.maxCurrentGrid.required) {
        return this.$t(`${this.tBasePath}.carLis.currentGrid.required`)
      } else if (!this.$v.maxCurrentGrid.minValue) {
        return this.$t(`${this.tBasePath}.carLis.currentGrid.positiv`)
      } else if (!this.$v.maxCurrentGrid.decimal) {
        return this.$t(`${this.tBasePath}.carLis.currentGrid.decimal`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGLisMaxPowerGrid() {
      if (!this.$v.maxPowerGrid.$error) {
        return ''
      }

      if (!this.$v.maxPowerGrid.required) {
        return this.$t(`${this.tBasePath}.carLis.powerGrid.required`)
      } else if (!this.$v.maxPowerGrid.minValue) {
        return this.$t(`${this.tBasePath}.carLis.powerGrid.positiv`)
      } else if (!this.$v.maxPowerGrid.decimal) {
        return this.$t(`${this.tBasePath}.carLis.powerGrid.decimal`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGLisMaxCurrentLis() {
      if (!this.$v.maxCurrentLis.$error) {
        return ''
      }

      if (!this.$v.maxCurrentLis.required) {
        return this.$t(`${this.tBasePath}.carLis.currentLis.required`)
      } else if (!this.$v.maxCurrentLis.minValue) {
        return this.$t(`${this.tBasePath}.carLis.currentLis.positiv`)
      } else if (!this.$v.maxCurrentLis.decimal) {
        return this.$t(`${this.tBasePath}.carLis.currentLis.decimal`)
      }

      return 'INVALID'
    },
    invalidFeedbackLis() {
      return this.$t(`${this.tBasePath}.carLis.invalidFeedback`)
    }
  },
  created() {
    this.formHorizontal = {
      label: 'col-12 col-md-2 col-lg-4 custom-form-label',
      input: 'col-12 col-md-8 col-lg-8'
    }
    this.platformName = process.env.VUE_APP_PLATFORM_NAME
    this.tBasePath = 'ems.energyService.actuatorGroup.form'

    for (const key in this.carLis) {
      this[key] = this.carLis[key]
    }
  },
  validations() {
    return carLisValidators(this)
  }
}
</script>
