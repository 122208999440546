// source: ext/amperix/config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ext_amperix_error_pb = require('../../ext/amperix/error_pb.js');
goog.object.extend(proto, ext_amperix_error_pb);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.ModbusServerCfg', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.ModbusServerCfg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.ModbusServerCfg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.ModbusServerCfg.displayName = 'proto.de.mypowergrid.amperix.ModbusServerCfg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.displayName = 'proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.displayName = 'proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.displayName = 'proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.displayName = 'proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.ModbusServerCfg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.ModbusServerCfg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.toObject = function(includeInstance, msg) {
  var f, obj = {
    listenAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.ModbusServerCfg}
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.ModbusServerCfg;
  return proto.de.mypowergrid.amperix.ModbusServerCfg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.ModbusServerCfg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.ModbusServerCfg}
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.ModbusServerCfg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.ModbusServerCfg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListenAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string listen_address = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.prototype.getListenAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.ModbusServerCfg} returns this
 */
proto.de.mypowergrid.amperix.ModbusServerCfg.prototype.setListenAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest;
  return proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    modbusServerCfg: (f = msg.getModbusServerCfg()) && proto.de.mypowergrid.amperix.ModbusServerCfg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse;
  return proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.ModbusServerCfg;
      reader.readMessage(value,proto.de.mypowergrid.amperix.ModbusServerCfg.deserializeBinaryFromReader);
      msg.setModbusServerCfg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getModbusServerCfg();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.ModbusServerCfg.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ModbusServerCfg modbus_server_cfg = 2;
 * @return {?proto.de.mypowergrid.amperix.ModbusServerCfg}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.getModbusServerCfg = function() {
  return /** @type{?proto.de.mypowergrid.amperix.ModbusServerCfg} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.ModbusServerCfg, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.ModbusServerCfg|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.setModbusServerCfg = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.clearModbusServerCfg = function() {
  return this.setModbusServerCfg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetInverterModbusServerConfigResponse.prototype.hasModbusServerCfg = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modbusServerCfg: (f = msg.getModbusServerCfg()) && proto.de.mypowergrid.amperix.ModbusServerCfg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest;
  return proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.ModbusServerCfg;
      reader.readMessage(value,proto.de.mypowergrid.amperix.ModbusServerCfg.deserializeBinaryFromReader);
      msg.setModbusServerCfg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModbusServerCfg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.ModbusServerCfg.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModbusServerCfg modbus_server_cfg = 1;
 * @return {?proto.de.mypowergrid.amperix.ModbusServerCfg}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.getModbusServerCfg = function() {
  return /** @type{?proto.de.mypowergrid.amperix.ModbusServerCfg} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.ModbusServerCfg, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.ModbusServerCfg|undefined} value
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest} returns this
*/
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.setModbusServerCfg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.clearModbusServerCfg = function() {
  return this.setModbusServerCfg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigRequest.prototype.hasModbusServerCfg = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse;
  return proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.SetInverterModbusServerConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.de.mypowergrid.amperix);
