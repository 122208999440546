<template>
  <!-- prettier-ignore -->
  <CRow class="small mb-3">
    <CCol sm="12" md="6">
      {{ $t(`${tBasePath}.socThreshold.label`) }}:
    </CCol>
    <CCol v-if="params.socThresholdPercent" sm="12" md="6">
      {{ params.socThresholdPercent }} %
    </CCol>
    <EvalTree v-else-if="decisionTreeIdx === currentDecisionTreeIdx" :preview="true" />
    <CCol sm="12" md="6">
      {{ $t(`${tBasePath}.socDeadzoneBelow.label`) }}:
    </CCol>
    <CCol sm="12" md="6">
      {{ params.socDeadzoneBelow }} %
    </CCol>
    <template v-if="params.legacy || !params.socDeadzoneBehaviour">
      <CCol sm="12" md="6">
        {{ $t(`${tBasePath}.physicalDevice.label`) }}:
      </CCol>
      <CCol  sm="12" md="6">
        {{ translatePhysicalDevice(params.legacy) }}
      </CCol>
    </template>
    <template v-else>
      <CCol sm="12" md="6">
        {{  $t(`${tBasePath}.actuatorGroup.label`) }}:
      </CCol>
      <CCol  sm="12" md="6">
        {{ params.socDeadzoneBehaviour.actuatorGroupName }}
      </CCol>
    </template>
  </CRow>
</template>

<script>
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'
import { mapState } from 'vuex'
import EvalTree from '@/components/ems/evalexp/eval-tree'

export default {
  name: 'EsSocNodePreview',
  components: {
    EvalTree
  },
  props: {
    params: {
      required: true,
      type: Object
    },
    decisionTreeIdx: {
      required: true,
      type: Number
    }
  },
  computed: {
    ...mapState('emsEvalExpression', ['currentDecisionTreeIdx'])
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.socGreaterThan'
    this.init()
  },
  methods: {
    async init() {
      Promise.all([
        this.$store.dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: false
        }),
        this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromDecisionTree', {
          decisionTreeIdx: this.decisionTreeIdx
        })
      ])
    },
    translatePhysicalDevice
  }
}
</script>
