/** @module */

import { Empty as EmptyPb } from 'google-protobuf/google/protobuf/empty_pb'
import {
  InterpolationType as InterpolationTypePb,
  TimeseriesName as TimeseriesNamePb,
  Timeseries as TimeseriesPb,
  TimeseriesListDataRequest as TimeseriesListDataRequestPb,
  TimeseriesUpdateRequest as TimeseriesUpdateRequestPb
} from '@/../lib/proto_js/ext/ems/controller/controller_pb'
import { controllerRpc } from '@/api/ems/controller'

/**
 * gRPC unary call to add (initialize) a new EMS timeseries by name.
 * Successful response will provide a `google.protobuf.Empty`.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.name (required) is the name of the timeseries, to be removed.
 * @param {string|number} params.interpolation (optionally)
 * @param {integer} params.repeatPeriodSeconds (optionally) could be a periodicity of the timeseries w.r.t. the first entry. If `0`, no periodicity is assumed.
 *
 * @return {promise}
 */
export function timeseriesAdd({ name, interpolation = 'LEFT_BOUNDED', repeatPeriodSeconds = 0 }) {
  if (!name) {
    throw new TypeError('Timeseries name is missing.')
  }

  if (typeof interpolation === 'string') {
    interpolation = InterpolationTypePb[interpolation]
  }

  const req = new TimeseriesPb([name, interpolation, repeatPeriodSeconds])
  return controllerRpc('timeseries_add', req)
}

/**
 * gRPC unary call to remove a EMS timeseries by name.
 * Successful response will provide a `google.protobuf.Empty`.
 *
 * A `ems.controller.Error` is raised, if e.g. timeseries name ist not found.
 *
 * @function
 *
 * @param {string} name (required) is the name of the timeseries, to be removed.
 * @param {object} opts
 * @param {boolean} opts.swallowNotFoundError
 *
 * @return {promise}
 */
export function timeseriesRemove(name, { swallowNotFoundError = true } = {}) {
  if (!name) {
    throw new TypeError('Timeseries name is missing.')
  }

  const req = new TimeseriesNamePb([name])

  return controllerRpc('timeseries_remove', req).catch((err) => {
    if (!swallowNotFoundError || err.name !== 'GrpcEmsControllerError') {
      throw err
    }

    if (/.*not.*found.*/.test(err.message)) {
      return new EmptyPb()
    }
    throw err
  })
}

/**
 * gRPC unary call to list all EMS timeseries.
 *
 * @function
 *
 * @return {promise}
 */
export function timeseriesList() {
  return controllerRpc('timeseries_list')
}

/**
 * gRPC unary call to create/update a EMS timeseries by name.
 * Successful response will provide a `google.protobuf.Empty`.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.name (required) is the name of the timeseries, to be removed.
 * @param {string|number} params.interpolation (optionally)
 * @param {integer} params.repeatPeriodSeconds (optionally) could be a periodicity of the timeseries w.r.t. the first entry. If `0`, no periodicity is assumed.
 * @param {array} params.timestampValueList has to be a list of plain js `ems.controller.TimeseriesUpdateRequest.TimestampValue` objects
 *
 * @return {promise}
 */
export function timeseriesUpdate({
  name,
  interpolation = 'LEFT_BOUNDED',
  repeatPeriodSeconds = 0,
  timestampValueList = []
}) {
  if (!name) {
    throw new TypeError('Timeseries name is missing.')
  }

  if (typeof interpolation === 'string') {
    interpolation = InterpolationTypePb[interpolation]
  }

  const req = new TimeseriesUpdateRequestPb([name, interpolation, null, repeatPeriodSeconds])
  timestampValueList.forEach((tv) => {
    req.addTimestampValue(new TimeseriesUpdateRequestPb.TimestampValue([tv.timestamp, tv.value]))
  })

  return controllerRpc('timeseries_update', req)
}

/**
 * gRPC unary call to list data of a specific timeseries.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.name has to be the timeseries name
 * @param {number} params.tBegin defines the condition `tBegin < t` (defaults to '1970-01-01T00:00:00Z')
 * @param {number} params.tEnd defines the condition `tEnd > t` (defaults to '3000-01-01T00:00:00Z')
 *
 * @return {promise}
 */
export function timeseriesListData({ name, tBegin = 0, tEnd = 32503680000 }) {
  if (!name) {
    throw new TypeError('Timeseries name is missing.')
  }

  if (tBegin > tEnd) {
    throw new TypeError('Invalid time-range. Expected "tBegin <= tEnd".')
  }

  const req = new TimeseriesListDataRequestPb([name, tBegin, tEnd])

  return controllerRpc('timeseries_list_data', req)
}
