<template>
  <CRow>
    <CCol class="mt-1 mb-3" sm="12">
      <h4 class="text-center">
        {{ $t('ems.topology.deviceTypePicker') }}
      </h4>
    </CCol>
    <CCol class="mb-2" sm="6">
      <h5>
        {{ $t('ems.topology.supportedDevices') }}
      </h5>
      <CListGroupItem
        v-for="pdt of supportedPhysicalDeviceTypesWithLabel"
        :key="pdt.type"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <span>
          {{ pdt.label }}
        </span>
        <span>
          <CButton class="mx-1" size="sm" color="primary" @click="addPhysicalDevice(pdt.type)">
            <CIcon size="sm" name="cilPlusCircle" />
          </CButton>
          <CButton
            class="mx-1"
            size="sm"
            color="danger"
            :disabled="currentPhysicalDeviceNumber(pdt.type) === 0"
            @click="removePhysicalDevice(pdt.type)"
          >
            <CIcon size="sm" name="cilMinusCircle" />
          </CButton>
        </span>
      </CListGroupItem>
    </CCol>
    <CCol class="mb-2" sm="6">
      <h5>
        {{ $t('ems.topology.yourPhysicalDevices') }}
      </h5>
      <CListGroupItem
        v-for="pd of existingPhysicalDevicesWithLabel"
        :key="'device-' + pd.type + '-' + pd.number"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        {{ pd.label }}
      </CListGroupItem>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'

import { SUPPORTED_PHYSICAL_DEVICE_TYPES, newPhysicalDevice } from '@/store/modules/_ems-topology-config-helper'
import { newToastNotificationMsg } from '@/store/modules/notifications'
import { sortPhysicalDevices, translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'

export default {
  name: 'EmsEditExistingPhysDevs',
  computed: {
    ...mapGetters('auth', ['enabledFeatures']),
    ...mapGetters('emsTopologyConfig', [
      'currentPhysicalDeviceNumber',
      'existingPhysicalDevices',
      'isPhysicalDeviceReferenced',
      'nextPhysicalDeviceNumber'
    ]),
    supportedPhysicalDeviceTypesWithLabel() {
      const pdts = []
      SUPPORTED_PHYSICAL_DEVICE_TYPES.forEach((pdt) => {
        if (pdt === 8 && !this.enabledFeatures.includes('container')) return

        const l = translatePhysicalDevice({ type: pdt })
        pdts.push({
          type: pdt,
          typeTranslated: l,
          label: l
        })
      })
      return sortPhysicalDevices(pdts)
    },
    existingPhysicalDevicesWithLabel() {
      const pds = []
      this.existingPhysicalDevices.forEach((pd) => {
        pds.push({
          type: pd.type,
          number: pd.number,
          typeTranslated: translatePhysicalDevice({ type: pd.type }),
          label: translatePhysicalDevice(pd)
        })
      })

      return sortPhysicalDevices(pds)
    }
  },
  methods: {
    addPhysicalDevice(physDevType) {
      this.$store.commit(
        'emsTopologyConfig/ADD_PHYSICAL_DEVICE',
        newPhysicalDevice({
          type: physDevType,
          number: this.nextPhysicalDeviceNumber(physDevType)
        })
      )
    },
    removePhysicalDevice(physDevType) {
      const pd = newPhysicalDevice({
        type: physDevType,
        number: this.currentPhysicalDeviceNumber(physDevType)
      })

      if (this.isPhysicalDeviceReferenced(pd)) {
        this.$log.debug('Cannot remove physical device, because it is referenced in the topology.', pd)

        this.$store.commit(
          'notifications/PUSH_TOAST',
          newToastNotificationMsg({
            autohide: 10000,
            type: 'danger',
            title: this.$t('ems.topology.alerts.notRmPhysDev'),
            content: this.$t('ems.topology.alerts.notRmPhysDevDescription')
          })
        )

        return
      }

      this.$store.commit('emsTopologyConfig/REMOVE_PHYSICAL_DEVICE', pd)
    }
  }
}
</script>
