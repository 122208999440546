// source: ext/fwupgrade/upgrader.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.BundleInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.ErrorKindCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.ErrorMessage', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.UnableToInstall', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetLogRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetLogResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetStateRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetStateResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetStateStreamRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.GetStateStreamResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.ImmediateError', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.ImmediateError.ErrorKindCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.Log', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.StartUpgradeRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.StartUpgradeResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.Error', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.OperationInProgress', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.Rebooting', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.StateKindCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.UpToDate', null, global);
goog.exportSymbol('proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetLogRequest.displayName = 'proto.de.mypowergrid.fwupgrade.GetLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetLogResponse.displayName = 'proto.de.mypowergrid.fwupgrade.GetLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.fwupgrade.Log.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Log.displayName = 'proto.de.mypowergrid.fwupgrade.Log';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.fwupgrade.State.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.displayName = 'proto.de.mypowergrid.fwupgrade.State';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.UpToDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.UpToDate.displayName = 'proto.de.mypowergrid.fwupgrade.State.UpToDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.displayName = 'proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.OperationInProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.OperationInProgress.displayName = 'proto.de.mypowergrid.fwupgrade.State.OperationInProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.Rebooting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.Rebooting.displayName = 'proto.de.mypowergrid.fwupgrade.State.Rebooting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.displayName = 'proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.State.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.State.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.State.Error.displayName = 'proto.de.mypowergrid.fwupgrade.State.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.BundleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.BundleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.BundleInfo.displayName = 'proto.de.mypowergrid.fwupgrade.BundleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetStateRequest.displayName = 'proto.de.mypowergrid.fwupgrade.GetStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetStateResponse.displayName = 'proto.de.mypowergrid.fwupgrade.GetStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetStateStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.displayName = 'proto.de.mypowergrid.fwupgrade.GetStateStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.GetStateStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.displayName = 'proto.de.mypowergrid.fwupgrade.GetStateStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.displayName = 'proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.displayName = 'proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.displayName = 'proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.displayName = 'proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.StartUpgradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.displayName = 'proto.de.mypowergrid.fwupgrade.StartUpgradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.StartUpgradeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.displayName = 'proto.de.mypowergrid.fwupgrade.StartUpgradeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.displayName = 'proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.displayName = 'proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.displayName = 'proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.displayName = 'proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.displayName = 'proto.de.mypowergrid.fwupgrade.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.displayName = 'proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.displayName = 'proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.displayName = 'proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.displayName = 'proto.de.mypowergrid.fwupgrade.Error.ErrorMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.displayName = 'proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.UnableToInstall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.displayName = 'proto.de.mypowergrid.fwupgrade.Error.UnableToInstall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.displayName = 'proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.fwupgrade.ImmediateError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.fwupgrade.ImmediateError.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.fwupgrade.ImmediateError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.fwupgrade.ImmediateError.displayName = 'proto.de.mypowergrid.fwupgrade.ImmediateError';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    since: (f = msg.getSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogRequest}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetLogRequest;
  return proto.de.mypowergrid.fwupgrade.GetLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogRequest}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp since = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.getSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogRequest} returns this
*/
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.setSince = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogRequest} returns this
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.clearSince = function() {
  return this.setSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetLogRequest.prototype.hasSince = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f),
    log: (f = msg.getLog()) && proto.de.mypowergrid.fwupgrade.Log.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetLogResponse;
  return proto.de.mypowergrid.fwupgrade.GetLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.Log;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Log.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.Log.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Log log = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.Log}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.getLog = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Log} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Log, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Log|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.setLog = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetLogResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.clearLog = function() {
  return this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetLogResponse.prototype.hasLog = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.fwupgrade.Log.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.de.mypowergrid.fwupgrade.State.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Log}
 */
proto.de.mypowergrid.fwupgrade.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Log;
  return proto.de.mypowergrid.fwupgrade.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Log}
 */
proto.de.mypowergrid.fwupgrade.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.State;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter
    );
  }
};


/**
 * repeated State states = 1;
 * @return {!Array<!proto.de.mypowergrid.fwupgrade.State>}
 */
proto.de.mypowergrid.fwupgrade.Log.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.fwupgrade.State>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.fwupgrade.State, 1));
};


/**
 * @param {!Array<!proto.de.mypowergrid.fwupgrade.State>} value
 * @return {!proto.de.mypowergrid.fwupgrade.Log} returns this
*/
proto.de.mypowergrid.fwupgrade.Log.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.de.mypowergrid.fwupgrade.State=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.Log.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.de.mypowergrid.fwupgrade.State, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.fwupgrade.Log} returns this
 */
proto.de.mypowergrid.fwupgrade.Log.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.fwupgrade.State.oneofGroups_ = [[3,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.fwupgrade.State.StateKindCase = {
  STATE_KIND_NOT_SET: 0,
  UP_TO_DATE: 3,
  UPGRADE_AVAILABLE: 4,
  UPGRADE_IN_PROGRESS: 5,
  REBOOTING: 6,
  PARTIALLY_UPGRADED: 7,
  ROLLBACK_IN_PROGRESS: 8,
  FINALIZE_IN_PROGRESS: 9,
  ERROR: 10
};

/**
 * @return {proto.de.mypowergrid.fwupgrade.State.StateKindCase}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getStateKindCase = function() {
  return /** @type {proto.de.mypowergrid.fwupgrade.State.StateKindCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upToDate: (f = msg.getUpToDate()) && proto.de.mypowergrid.fwupgrade.State.UpToDate.toObject(includeInstance, f),
    upgradeAvailable: (f = msg.getUpgradeAvailable()) && proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.toObject(includeInstance, f),
    upgradeInProgress: (f = msg.getUpgradeInProgress()) && proto.de.mypowergrid.fwupgrade.State.OperationInProgress.toObject(includeInstance, f),
    rebooting: (f = msg.getRebooting()) && proto.de.mypowergrid.fwupgrade.State.Rebooting.toObject(includeInstance, f),
    partiallyUpgraded: (f = msg.getPartiallyUpgraded()) && proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.toObject(includeInstance, f),
    rollbackInProgress: (f = msg.getRollbackInProgress()) && proto.de.mypowergrid.fwupgrade.State.OperationInProgress.toObject(includeInstance, f),
    finalizeInProgress: (f = msg.getFinalizeInProgress()) && proto.de.mypowergrid.fwupgrade.State.OperationInProgress.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.State.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State;
  return proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.fwupgrade.State.UpToDate;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.UpToDate.deserializeBinaryFromReader);
      msg.setUpToDate(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.deserializeBinaryFromReader);
      msg.setUpgradeAvailable(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.fwupgrade.State.OperationInProgress;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinaryFromReader);
      msg.setUpgradeInProgress(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.fwupgrade.State.Rebooting;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.Rebooting.deserializeBinaryFromReader);
      msg.setRebooting(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.deserializeBinaryFromReader);
      msg.setPartiallyUpgraded(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.fwupgrade.State.OperationInProgress;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinaryFromReader);
      msg.setRollbackInProgress(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.fwupgrade.State.OperationInProgress;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinaryFromReader);
      msg.setFinalizeInProgress(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.fwupgrade.State.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpToDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.fwupgrade.State.UpToDate.serializeBinaryToWriter
    );
  }
  f = message.getUpgradeAvailable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.serializeBinaryToWriter
    );
  }
  f = message.getUpgradeInProgress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.fwupgrade.State.OperationInProgress.serializeBinaryToWriter
    );
  }
  f = message.getRebooting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.fwupgrade.State.Rebooting.serializeBinaryToWriter
    );
  }
  f = message.getPartiallyUpgraded();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.serializeBinaryToWriter
    );
  }
  f = message.getRollbackInProgress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.fwupgrade.State.OperationInProgress.serializeBinaryToWriter
    );
  }
  f = message.getFinalizeInProgress();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.fwupgrade.State.OperationInProgress.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.fwupgrade.State.Error.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.UpToDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpToDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    installedVersion: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpToDate}
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.UpToDate;
  return proto.de.mypowergrid.fwupgrade.State.UpToDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpToDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpToDate}
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstalledVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.UpToDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpToDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstalledVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string installed_version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.prototype.getInstalledVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpToDate} returns this
 */
proto.de.mypowergrid.fwupgrade.State.UpToDate.prototype.setInstalledVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    installedVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    upgradeBundleInfo: (f = msg.getUpgradeBundleInfo()) && proto.de.mypowergrid.fwupgrade.BundleInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable;
  return proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstalledVersion(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.BundleInfo;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.BundleInfo.deserializeBinaryFromReader);
      msg.setUpgradeBundleInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstalledVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpgradeBundleInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.BundleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string installed_version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.getInstalledVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} returns this
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.setInstalledVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BundleInfo upgrade_bundle_info = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.BundleInfo}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.getUpgradeBundleInfo = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.BundleInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.BundleInfo, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.BundleInfo|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} returns this
*/
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.setUpgradeBundleInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} returns this
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.clearUpgradeBundleInfo = function() {
  return this.setUpgradeBundleInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable.prototype.hasUpgradeBundleInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.OperationInProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    progressPercentage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.OperationInProgress;
  return proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgressPercentage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.OperationInProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgressPercentage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string old_version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.getOldVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} returns this
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.setOldVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_version = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.getNewVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} returns this
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.setNewVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 progress_percentage = 3;
 * @return {number}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.getProgressPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} returns this
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.setProgressPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.OperationInProgress} returns this
 */
proto.de.mypowergrid.fwupgrade.State.OperationInProgress.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.Rebooting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.Rebooting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.Rebooting}
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.Rebooting;
  return proto.de.mypowergrid.fwupgrade.State.Rebooting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.Rebooting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.Rebooting}
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.Rebooting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.Rebooting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.Rebooting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newVersion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded;
  return proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.getOldVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} returns this
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.setOldVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_version = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.getNewVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} returns this
 */
proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded.prototype.setNewVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.State.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.State.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.State.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.State.Error}
 */
proto.de.mypowergrid.fwupgrade.State.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.State.Error;
  return proto.de.mypowergrid.fwupgrade.State.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.State.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.State.Error}
 */
proto.de.mypowergrid.fwupgrade.State.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.State.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.State.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.State.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.State.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.State.Error.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.State.Error.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.State.Error.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.Error.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpToDate up_to_date = 3;
 * @return {?proto.de.mypowergrid.fwupgrade.State.UpToDate}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getUpToDate = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.UpToDate} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.UpToDate, 3));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.UpToDate|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setUpToDate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearUpToDate = function() {
  return this.setUpToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasUpToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UpgradeAvailable upgrade_available = 4;
 * @return {?proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getUpgradeAvailable = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable, 4));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.UpgradeAvailable|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setUpgradeAvailable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearUpgradeAvailable = function() {
  return this.setUpgradeAvailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasUpgradeAvailable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OperationInProgress upgrade_in_progress = 5;
 * @return {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getUpgradeInProgress = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.OperationInProgress} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.OperationInProgress, 5));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setUpgradeInProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearUpgradeInProgress = function() {
  return this.setUpgradeInProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasUpgradeInProgress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Rebooting rebooting = 6;
 * @return {?proto.de.mypowergrid.fwupgrade.State.Rebooting}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getRebooting = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.Rebooting} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.Rebooting, 6));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.Rebooting|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setRebooting = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearRebooting = function() {
  return this.setRebooting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasRebooting = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PartiallyUpgraded partially_upgraded = 7;
 * @return {?proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getPartiallyUpgraded = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded, 7));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.PartiallyUpgraded|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setPartiallyUpgraded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearPartiallyUpgraded = function() {
  return this.setPartiallyUpgraded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasPartiallyUpgraded = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OperationInProgress rollback_in_progress = 8;
 * @return {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getRollbackInProgress = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.OperationInProgress} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.OperationInProgress, 8));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setRollbackInProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearRollbackInProgress = function() {
  return this.setRollbackInProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasRollbackInProgress = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OperationInProgress finalize_in_progress = 9;
 * @return {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getFinalizeInProgress = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.OperationInProgress} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.OperationInProgress, 9));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.OperationInProgress|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setFinalizeInProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearFinalizeInProgress = function() {
  return this.setFinalizeInProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasFinalizeInProgress = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Error error = 10;
 * @return {?proto.de.mypowergrid.fwupgrade.State.Error}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State.Error, 10));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
*/
proto.de.mypowergrid.fwupgrade.State.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.fwupgrade.State.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.State} returns this
 */
proto.de.mypowergrid.fwupgrade.State.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.State.prototype.hasError = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.BundleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.BundleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardware: jspb.Message.getFieldWithDefault(msg, 1, ""),
    branding: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.BundleInfo;
  return proto.de.mypowergrid.fwupgrade.BundleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.BundleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardware(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranding(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.BundleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.BundleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardware();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBranding();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string hardware = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.getHardware = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo} returns this
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.setHardware = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string branding = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.getBranding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo} returns this
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.setBranding = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo} returns this
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.BundleInfo} returns this
 */
proto.de.mypowergrid.fwupgrade.BundleInfo.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateRequest}
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetStateRequest;
  return proto.de.mypowergrid.fwupgrade.GetStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateRequest}
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f),
    state: (f = msg.getState()) && proto.de.mypowergrid.fwupgrade.State.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetStateResponse;
  return proto.de.mypowergrid.fwupgrade.GetStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.State;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional State state = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.getState = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetStateResponse.prototype.hasState = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamRequest}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetStateStreamRequest;
  return proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamRequest}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f),
    state: (f = msg.getState()) && proto.de.mypowergrid.fwupgrade.State.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.GetStateStreamResponse;
  return proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.State;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional State state = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.getState = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.GetStateStreamResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.GetStateStreamResponse.prototype.hasState = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorState: (f = msg.getErrorState()) && proto.de.mypowergrid.fwupgrade.State.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest;
  return proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.State;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.State.deserializeBinaryFromReader);
      msg.setErrorState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.State.serializeBinaryToWriter
    );
  }
};


/**
 * optional State error_state = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.State}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.getErrorState = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.State} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.State, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.State|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest} returns this
*/
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.setErrorState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest} returns this
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.clearErrorState = function() {
  return this.setErrorState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorRequest.prototype.hasErrorState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse;
  return proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.AcknowledgeErrorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest;
  return proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.de.mypowergrid.fwupgrade.Error.toObject(includeInstance, f),
    bundleInfo: (f = msg.getBundleInfo()) && proto.de.mypowergrid.fwupgrade.BundleInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse;
  return proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.Error;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.BundleInfo;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.BundleInfo.deserializeBinaryFromReader);
      msg.setBundleInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter
    );
  }
  f = message.getBundleInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.BundleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BundleInfo bundle_info = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.BundleInfo}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.getBundleInfo = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.BundleInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.BundleInfo, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.BundleInfo|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.setBundleInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.clearBundleInfo = function() {
  return this.setBundleInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.CheckForLatestUpgradeResponse.prototype.hasBundleInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.StartUpgradeRequest;
  return proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    immediateError: (f = msg.getImmediateError()) && proto.de.mypowergrid.fwupgrade.ImmediateError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.StartUpgradeResponse;
  return proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.ImmediateError;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinaryFromReader);
      msg.setImmediateError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImmediateError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.ImmediateError.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImmediateError immediate_error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.ImmediateError}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.getImmediateError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.ImmediateError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.ImmediateError, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.ImmediateError|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.setImmediateError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.StartUpgradeResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.clearImmediateError = function() {
  return this.setImmediateError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.StartUpgradeResponse.prototype.hasImmediateError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest;
  return proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    immediateError: (f = msg.getImmediateError()) && proto.de.mypowergrid.fwupgrade.ImmediateError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse;
  return proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.ImmediateError;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinaryFromReader);
      msg.setImmediateError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImmediateError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.ImmediateError.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImmediateError immediate_error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.ImmediateError}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.getImmediateError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.ImmediateError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.ImmediateError, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.ImmediateError|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.setImmediateError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.clearImmediateError = function() {
  return this.setImmediateError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.FinalizeUpgradeResponse.prototype.hasImmediateError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest;
  return proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    immediateError: (f = msg.getImmediateError()) && proto.de.mypowergrid.fwupgrade.ImmediateError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse;
  return proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.fwupgrade.ImmediateError;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinaryFromReader);
      msg.setImmediateError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImmediateError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.fwupgrade.ImmediateError.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImmediateError immediate_error = 1;
 * @return {?proto.de.mypowergrid.fwupgrade.ImmediateError}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.getImmediateError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.ImmediateError} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.ImmediateError, 1));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.ImmediateError|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse} returns this
*/
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.setImmediateError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse} returns this
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.clearImmediateError = function() {
  return this.setImmediateError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.RollbackUpgradeResponse.prototype.hasImmediateError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.fwupgrade.Error.oneofGroups_ = [[2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorKindCase = {
  ERROR_KIND_NOT_SET: 0,
  OPERATION_ALREADY_IN_PROGRESS: 2,
  UNABLE_TO_CONTACT_SERVER: 3,
  UPDATES_DISABLED: 4,
  UNABLE_TO_READ_METADATA: 5,
  UNABLE_TO_READ_MANIFEST: 6,
  UNABLE_TO_PARSE_INSTALLED_VERSION: 7,
  UNABLE_TO_PARSE_BUNDLE_VERSION: 8,
  INVALID_RAUC_STATUS: 9,
  SLOT_GROUPS_NOT_IDENTICAL: 10,
  INSUFFICIENT_OVERLAY_SPACE: 11,
  UNABLE_TO_UPGRADE: 12,
  UNABLE_TO_RESTORE_SLOTGROUP: 13,
  OVERLAY_BACKUP_MISSING: 14,
  UNABLE_TO_FINALIZE: 15,
  INTERNAL_ERROR: 16
};

/**
 * @return {proto.de.mypowergrid.fwupgrade.Error.ErrorKindCase}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getErrorKindCase = function() {
  return /** @type {proto.de.mypowergrid.fwupgrade.Error.ErrorKindCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationAlreadyInProgress: (f = msg.getOperationAlreadyInProgress()) && proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.toObject(includeInstance, f),
    unableToContactServer: (f = msg.getUnableToContactServer()) && proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.toObject(includeInstance, f),
    updatesDisabled: (f = msg.getUpdatesDisabled()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    unableToReadMetadata: (f = msg.getUnableToReadMetadata()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    unableToReadManifest: (f = msg.getUnableToReadManifest()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    unableToParseInstalledVersion: (f = msg.getUnableToParseInstalledVersion()) && proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.toObject(includeInstance, f),
    unableToParseBundleVersion: (f = msg.getUnableToParseBundleVersion()) && proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.toObject(includeInstance, f),
    invalidRaucStatus: (f = msg.getInvalidRaucStatus()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    slotGroupsNotIdentical: (f = msg.getSlotGroupsNotIdentical()) && proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.toObject(includeInstance, f),
    insufficientOverlaySpace: (f = msg.getInsufficientOverlaySpace()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    unableToUpgrade: (f = msg.getUnableToUpgrade()) && proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.toObject(includeInstance, f),
    unableToRestoreSlotgroup: (f = msg.getUnableToRestoreSlotgroup()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    overlayBackupMissing: (f = msg.getOverlayBackupMissing()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f),
    unableToFinalize: (f = msg.getUnableToFinalize()) && proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.toObject(includeInstance, f),
    internalError: (f = msg.getInternalError()) && proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error;
  return proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error}
 */
proto.de.mypowergrid.fwupgrade.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.deserializeBinaryFromReader);
      msg.setOperationAlreadyInProgress(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.deserializeBinaryFromReader);
      msg.setUnableToContactServer(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setUpdatesDisabled(value);
      break;
    case 5:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setUnableToReadMetadata(value);
      break;
    case 6:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setUnableToReadManifest(value);
      break;
    case 7:
      var value = new proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.deserializeBinaryFromReader);
      msg.setUnableToParseInstalledVersion(value);
      break;
    case 8:
      var value = new proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.deserializeBinaryFromReader);
      msg.setUnableToParseBundleVersion(value);
      break;
    case 9:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setInvalidRaucStatus(value);
      break;
    case 10:
      var value = new proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.deserializeBinaryFromReader);
      msg.setSlotGroupsNotIdentical(value);
      break;
    case 11:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setInsufficientOverlaySpace(value);
      break;
    case 12:
      var value = new proto.de.mypowergrid.fwupgrade.Error.UnableToInstall;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.deserializeBinaryFromReader);
      msg.setUnableToUpgrade(value);
      break;
    case 13:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setUnableToRestoreSlotgroup(value);
      break;
    case 14:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setOverlayBackupMissing(value);
      break;
    case 15:
      var value = new proto.de.mypowergrid.fwupgrade.Error.UnableToInstall;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.deserializeBinaryFromReader);
      msg.setUnableToFinalize(value);
      break;
    case 16:
      var value = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader);
      msg.setInternalError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationAlreadyInProgress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.serializeBinaryToWriter
    );
  }
  f = message.getUnableToContactServer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.serializeBinaryToWriter
    );
  }
  f = message.getUpdatesDisabled();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getUnableToReadMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getUnableToReadManifest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getUnableToParseInstalledVersion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.serializeBinaryToWriter
    );
  }
  f = message.getUnableToParseBundleVersion();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.serializeBinaryToWriter
    );
  }
  f = message.getInvalidRaucStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getSlotGroupsNotIdentical();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.serializeBinaryToWriter
    );
  }
  f = message.getInsufficientOverlaySpace();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getUnableToUpgrade();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.serializeBinaryToWriter
    );
  }
  f = message.getUnableToRestoreSlotgroup();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getOverlayBackupMissing();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
  f = message.getUnableToFinalize();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.serializeBinaryToWriter
    );
  }
  f = message.getInternalError();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress}
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress;
  return proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress}
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer;
  return proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion;
  return proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.AlreadyOnLatestVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.ErrorMessage;
  return proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.ErrorMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion;
  return proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.toObject = function(includeInstance, msg) {
  var f, obj = {
    newVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.UnableToInstall;
  return proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string new_version = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.getNewVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.setNewVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.UnableToInstall.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionSlotGroupA: jspb.Message.getFieldWithDefault(msg, 1, ""),
    versionSlotGroupB: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical;
  return proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionSlotGroupA(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionSlotGroupB(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionSlotGroupA();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersionSlotGroupB();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string version_slot_group_a = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.getVersionSlotGroupA = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.setVersionSlotGroupA = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version_slot_group_b = 2;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.getVersionSlotGroupB = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical.prototype.setVersionSlotGroupB = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OperationAlreadyInProgress operation_already_in_progress = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getOperationAlreadyInProgress = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setOperationAlreadyInProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearOperationAlreadyInProgress = function() {
  return this.setOperationAlreadyInProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasOperationAlreadyInProgress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UnableToContactServer unable_to_contact_server = 3;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToContactServer = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer, 3));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.UnableToContactServer|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToContactServer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToContactServer = function() {
  return this.setUnableToContactServer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToContactServer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorMessage updates_disabled = 4;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUpdatesDisabled = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 4));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUpdatesDisabled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUpdatesDisabled = function() {
  return this.setUpdatesDisabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUpdatesDisabled = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorMessage unable_to_read_metadata = 5;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToReadMetadata = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 5));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToReadMetadata = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToReadMetadata = function() {
  return this.setUnableToReadMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToReadMetadata = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorMessage unable_to_read_manifest = 6;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToReadManifest = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 6));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToReadManifest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToReadManifest = function() {
  return this.setUnableToReadManifest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToReadManifest = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UnableToParseVersion unable_to_parse_installed_version = 7;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToParseInstalledVersion = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion, 7));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToParseInstalledVersion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToParseInstalledVersion = function() {
  return this.setUnableToParseInstalledVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToParseInstalledVersion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UnableToParseVersion unable_to_parse_bundle_version = 8;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToParseBundleVersion = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion, 8));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.UnableToParseVersion|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToParseBundleVersion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToParseBundleVersion = function() {
  return this.setUnableToParseBundleVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToParseBundleVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ErrorMessage invalid_rauc_status = 9;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getInvalidRaucStatus = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 9));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setInvalidRaucStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearInvalidRaucStatus = function() {
  return this.setInvalidRaucStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasInvalidRaucStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SlotGroupsNotIdentical slot_groups_not_identical = 10;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getSlotGroupsNotIdentical = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical, 10));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.SlotGroupsNotIdentical|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setSlotGroupsNotIdentical = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearSlotGroupsNotIdentical = function() {
  return this.setSlotGroupsNotIdentical(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasSlotGroupsNotIdentical = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ErrorMessage insufficient_overlay_space = 11;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getInsufficientOverlaySpace = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 11));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setInsufficientOverlaySpace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearInsufficientOverlaySpace = function() {
  return this.setInsufficientOverlaySpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasInsufficientOverlaySpace = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UnableToInstall unable_to_upgrade = 12;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToUpgrade = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.UnableToInstall, 12));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToUpgrade = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToUpgrade = function() {
  return this.setUnableToUpgrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToUpgrade = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ErrorMessage unable_to_restore_slotgroup = 13;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToRestoreSlotgroup = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 13));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToRestoreSlotgroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToRestoreSlotgroup = function() {
  return this.setUnableToRestoreSlotgroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToRestoreSlotgroup = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ErrorMessage overlay_backup_missing = 14;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getOverlayBackupMissing = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 14));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setOverlayBackupMissing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearOverlayBackupMissing = function() {
  return this.setOverlayBackupMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasOverlayBackupMissing = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional UnableToInstall unable_to_finalize = 15;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getUnableToFinalize = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.UnableToInstall, 15));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.UnableToInstall|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setUnableToFinalize = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearUnableToFinalize = function() {
  return this.setUnableToFinalize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasUnableToFinalize = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ErrorMessage internal_error = 16;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.getInternalError = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.ErrorMessage, 16));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.ErrorMessage|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
*/
proto.de.mypowergrid.fwupgrade.Error.prototype.setInternalError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.de.mypowergrid.fwupgrade.Error.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.Error} returns this
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.clearInternalError = function() {
  return this.setInternalError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.Error.prototype.hasInternalError = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.ErrorKindCase = {
  ERROR_KIND_NOT_SET: 0,
  OPERATION_ALREADY_IN_PROGRESS: 2
};

/**
 * @return {proto.de.mypowergrid.fwupgrade.ImmediateError.ErrorKindCase}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.getErrorKindCase = function() {
  return /** @type {proto.de.mypowergrid.fwupgrade.ImmediateError.ErrorKindCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.fwupgrade.ImmediateError.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.fwupgrade.ImmediateError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.fwupgrade.ImmediateError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationAlreadyInProgress: (f = msg.getOperationAlreadyInProgress()) && proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.fwupgrade.ImmediateError}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.fwupgrade.ImmediateError;
  return proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.fwupgrade.ImmediateError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.fwupgrade.ImmediateError}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress;
      reader.readMessage(value,proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.deserializeBinaryFromReader);
      msg.setOperationAlreadyInProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.fwupgrade.ImmediateError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.fwupgrade.ImmediateError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationAlreadyInProgress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.fwupgrade.ImmediateError} returns this
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Error.OperationAlreadyInProgress operation_already_in_progress = 2;
 * @return {?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.getOperationAlreadyInProgress = function() {
  return /** @type{?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress, 2));
};


/**
 * @param {?proto.de.mypowergrid.fwupgrade.Error.OperationAlreadyInProgress|undefined} value
 * @return {!proto.de.mypowergrid.fwupgrade.ImmediateError} returns this
*/
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.setOperationAlreadyInProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.fwupgrade.ImmediateError.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.fwupgrade.ImmediateError} returns this
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.clearOperationAlreadyInProgress = function() {
  return this.setOperationAlreadyInProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.fwupgrade.ImmediateError.prototype.hasOperationAlreadyInProgress = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.de.mypowergrid.fwupgrade);
