/** @module grpc/misc */

import { Selection as SelectionPb } from '@/../lib/proto_js/ext/amperix/selection_pb'

const SELECTION_PB_KEYS = Object.keys(new SelectionPb().toObject())

/**
 * Check via duck typing if instance is a JSPB object.
 *
 * @function
 *
 * @param {object} msg
 *
 * @return {boolean}
 */
export function isProto(msg) {
  return msg && typeof msg.toObject === 'function'
}

/**
 * Allows to identify/check if a plain JS proto object is a `amperix.Selection` msg.
 *
 * @function
 *
 * @param {object} plainSlct
 *
 * @return {boolean}
 */
export function ducktypeSelection(plainSlct = {}) {
  return SELECTION_PB_KEYS.every((k) => {
    return k in plainSlct
  })
}
