/** @module store/apx-interfaces */

import _ from 'lodash'

// API
import * as OneWirePb from '@/../lib/proto_js/ext/sherlock/one_wire_pb'
import * as NetworkPb from '@/../lib/proto_js/ext/sherlock/network_pb'
import * as GpioPb from '@/../lib/proto_js/ext/sherlock/gpio_pb'
import * as apiSherlock from '@/api/sherlock/apx-interfaces'

/** currently supported interface by sherlockd */
export const SUPPORTED_INTERFACES = ['ios', 'networks', 'onewires']

export const INTERFACE_TYPE_TO_INFO_STATE_KEY = {
  ioDigitalInput: 'digitalInput',
  ioDigitalOutput: 'digitalOutput',
  ioDryContactInput: 'dryContactInput',
  networkProperty: 'networkProperty',
  networkMaintenance: 'networkMaintenance',
  networkPublic: 'networkPublic',
  oneWireAll: 'all'
}

export const INTERFACE_TYPE_TO_CONNECTIVITY_STATE_KEY = {
  networkProperty: 'networkPropertyConnectivity',
  networkMaintenance: 'networkMaintenanceConnectivity',
  networkPublic: 'networkPublicConnectivity'
}

const NETWORK_INTERFACE_TYPE_TO_ENUM = {
  networkProperty: NetworkPb.Network.Type.PROPERTY,
  networkMaintenance: NetworkPb.Network.Type.MAINTENANCE,
  networkPublic: NetworkPb.Network.Type.PUBLIC
}

// STATE
function initIos(s) {
  s.ios = {
    digitalInput: new GpioPb.GetGPIOInfoResponse().toObject(),
    digitalOutput: new GpioPb.GetGPIOInfoResponse().toObject(),
    dryContactInput: new GpioPb.GetGPIOInfoResponse().toObject()
  }
}

function initNetworks(s) {
  // takes the proto ENUM Network.Type, parse it to camelCase + upperFirst, and prefix with `network`,
  // results e.g. in `networkProperty`
  s.networks = {}
  for (const n of Object.keys(NetworkPb.Network.Type)) {
    if (n === 'UNSPECIFIED') {
      continue
    }
    s.networks['network' + _.upperFirst(_.camelCase(n))] = new NetworkPb.GetNetworkInfoResponse().toObject()
    s.networks['network' + _.upperFirst(_.camelCase(n)) + 'Connectivity'] =
      new NetworkPb.CheckConnectivityResponse().toObject()
  }

  s.networks.knownDevices = []
}

function initOneWires(s) {
  s.onewires = {
    all: new OneWirePb.GetOneWireInfoResponse().toObject()
  }
}
const state = () => {
  const s = {}
  initIos(s)
  initNetworks(s)
  initOneWires(s)

  return s
}

// GETTERS
const getters = {
  ioDigitalInput: (state) => state.ios.digitalInput,
  ioDigitalOutput: (state) => state.ios.digitalOutput,
  ioDryContactInput: (state) => state.ios.dryContactInput,
  networkProperty: (state) => state.networks.networkProperty,
  networkMaintenance: (state) => state.networks.networkMaintenance,
  networkPublic: (state) => state.networks.networkPublic,
  networkPropertyConnectivity: (state) => state.networks.networkPropertyConnectivity,
  networkMaintenanceConnectivity: (state) => state.networks.networkMaintenanceConnectivity,
  networkPublicConnectivity: (state) => state.networks.networkPublicConnectivity,
  networkKnownDevicesRawInfo: (state) => {
    // for simplicity we parse the structured data to raw_info
    let rawInfo = ''
    state.networks.knownDevices.forEach((d) => {
      if (d.macAddress) {
        rawInfo = rawInfo.concat(`MAC: ${d.macAddress}`)
      }
      if (d.ipAddress) {
        rawInfo = rawInfo.concat(` IP: ${d.ipAddress}`)
      }
      if (d.hostname) {
        rawInfo = rawInfo.concat(` Hostname: ${d.hostname}`)
      }
      rawInfo = rawInfo.concat('\n')
    })

    return rawInfo
  },
  networkKnownDevicesIpAddresses: (state) => {
    const deviceIps = []
    state.networks.knownDevices.forEach((d) => {
      if (d.ipAddress) {
        deviceIps.push(d.ipAddress)
      }
    })

    return deviceIps
  },
  oneWireAll: (state) => state.oneWireAll.all,
  getOneWireAllAsArray: (state) => {
    const oneWireAllRawInfo = state.onewires.all.rawInfo
    return oneWireAllRawInfo.split(/\n\n/).map((block) => block.split(/\n/))
  }
}

const mutations = {
  UPDATE_INTERFACE_INFO(state, { interfaceId, interfaceType, payload }) {
    state[interfaceId][INTERFACE_TYPE_TO_INFO_STATE_KEY[interfaceType]] = payload
  },
  UPDATE_NETWORK_CONNECTIVITY(state, { interfaceType, payload }) {
    state.networks[INTERFACE_TYPE_TO_CONNECTIVITY_STATE_KEY[interfaceType]] = payload
  },
  ADD_NETWORK_KNOWN_DEVICE(state, device) {
    state.networks.knownDevices.push(device)
  },
  CLEAR_NETWORK_KNOWN_DEVICES(state) {
    state.networks.knownDevices = []
  },
  CLEAR_INTERFACE(state, { interfaceId, interfaceType }) {
    let initFct
    switch (interfaceId) {
      case 'ios':
        initFct = initIos
        break
      case 'networks':
        initFct = initNetworks
        break
      case 'onewires':
        initFct = initOneWires
        break
      default:
        throw new TypeError(`Tried to CLEAR_INTERFACE '${interfaceId}', which is not supported.`)
    }
    if (interfaceType) {
      const s = {}
      let t = INTERFACE_TYPE_TO_INFO_STATE_KEY[interfaceType]

      if (!t) {
        throw new TypeError(
          `The interfaceType '${interfaceType}' is not supported. Please specifiy an interfaceType which equals a getters-name.`
        )
      }
      initFct(s)
      state[interfaceId][t] = s[interfaceId][t]

      t = INTERFACE_TYPE_TO_CONNECTIVITY_STATE_KEY[interfaceType]
      if (!t) {
        return
      }

      state[interfaceId][t] = s[interfaceId][t]
    } else {
      initFct(state)
    }
  }
}

// ACTIONS
/**
 * @example
 * Can be e.g. dispatched in a component by
 * `this.$store.dispatch('apxIntefaces/fetchInterfaceInfo', { interfaceId: 'networks', interfaceType: 'networkPublic' })`
 *
 * @param {object} store is the Vuex store
 * @param {object} params
 * @param {string} params.interfaceId can be one of SUPPORTED_INTERFACES
 * @param {string} params.interfaceType specifies the particular type of the interface group. Has to match a key of `INTERFACE_TYPE_TO_INFO_STATE_KEY` constant.
 *
 * @return {Promise}
 */
function fetchInterfaceInfo({ commit }, { interfaceId, interfaceType }) {
  // only to make sure, that the action is used correctly
  if (!SUPPORTED_INTERFACES.includes(interfaceId)) {
    throw new TypeError(`Interface '${interfaceId}' is NOT supported.`)
  }

  commit('CLEAR_INTERFACE', { interfaceId, interfaceType })

  const doCommit = (msg) => {
    commit('UPDATE_INTERFACE_INFO', {
      interfaceId,
      interfaceType,
      payload: msg.toObject()
    })

    // ensure, that this return does NOT reference to the Vuex store-state (payload above)
    return msg.toObject()
  }

  switch (interfaceType) {
    case 'ioDigitalInput':
      return apiSherlock.fetchGpioDigitalInputInfo().then(doCommit)
    case 'ioDigitalOutput':
      return apiSherlock.fetchGpioDigitalOutputInfo().then(doCommit)
    case 'ioDryContactInput':
      return apiSherlock.fetchGpioDryContactInputInfo().then(doCommit)
    case 'networkProperty':
      return apiSherlock.fetchNetworkInfo(NETWORK_INTERFACE_TYPE_TO_ENUM[interfaceType]).then(doCommit)
    case 'networkMaintenance':
      return apiSherlock.fetchNetworkInfo(NETWORK_INTERFACE_TYPE_TO_ENUM[interfaceType]).then(doCommit)
    case 'networkPublic':
      return apiSherlock.fetchNetworkInfo(NETWORK_INTERFACE_TYPE_TO_ENUM[interfaceType]).then(doCommit)
    case 'oneWireAll':
      return apiSherlock.fetchOneWireInfo().then(doCommit)
    default:
      throw new TypeError(
        `InterfaceType '${interfaceType}' is not supported. Please specifiy an interfaceType which equals a getters-name.`
      )
  }
}

/**
 * @param {object} store is the Vuex store
 * @param {object} params
 * @param {string} params.interfaceType specifies the particular type of the 'networks' interface group. Has to match a key of `INTERFACE_TYPE_TO_CONNECTIVITY_STATE_KEY` constant.
 *
 * @return {Promise}
 */
function fetchNetworkConnectivity({ commit }, { interfaceType }) {
  const doCommit = (msg) => {
    commit('UPDATE_NETWORK_CONNECTIVITY', {
      interfaceType,
      payload: msg.toObject()
    })

    // ensure, that this return does NOT reference to the Vuex store-state (payload above)
    return msg.toObject()
  }
  const t = NETWORK_INTERFACE_TYPE_TO_ENUM[interfaceType]

  if (!t) {
    throw new TypeError(
      `InterfaceType '${interfaceType}' is not supported. Please specifiy an interfaceType which equals a getters-name for Network Connectivity.`
    )
  }

  return apiSherlock.fetchNetworkConnectivity(t).then(doCommit)
}

function fetchKnownDevices({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_NETWORK_KNOWN_DEVICES')
    msg.getDevicesList().forEach((d) => {
      commit('ADD_NETWORK_KNOWN_DEVICE', d.toObject())
    })

    return msg
  }

  return apiSherlock.fetchKnownDevices().then(doCommit)
}

const actions = {
  fetchInterfaceInfo,
  fetchNetworkConnectivity,
  fetchKnownDevices
}

/**
 * Currently the Interface state is simple and not structured.
 * This may change in the future.
 *
 * Supported apx-interfaces are: Network/Ethernet, IOs, OneWire.
 *
 * State description:
 * For each interface a list of interface-informations exists.
 * Currently, an interface-information contains `type` and `desc`.
 *
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
