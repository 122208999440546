/**
 * @module validations/ems-es-actuator-group-form-validators
 */

import { helpers, required, requiredIf, alphaNum, between, integer, minValue, decimal } from 'vuelidate/lib/validators'
import { SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES } from '@/store/modules/_ems-energy-services-config-helper'

/**
 * Uses dynamic validation schema of vualidate (for vue v2)
 *
 * Expects "plain" data:
 * `name, selectedType, selectedParameterType, selectedSetpointSources`
 * Expects "nested" data:
 * `battery: { deltaSoc }` & `chai: { filename, prefix }`
 *
 * @param {object} ctxt has to be the Vue context, i.e. the vue instance, which provides the data & getters
 * @param {object} params
 * @param {array} params.existingNames (optional) is a list of other EMS actuator-group names (IDs)
 * @param {string} params.origName (required if `existingNames`) is the name which should be updated
 * @param {function} params.getPhysicalDevice optionally allows to get the physical device of a setpoint source
 *
 * @return {object} with the validator function
 */
export function actuatorGroupValidatores(ctxt, params) {
  const isChai = ctxt.selectedParameterType === 'chai'

  const validators = {
    name: {
      required,
      words: helpers.regex('words', /^[\w\s-.]+$/),
      unique: helpers.withParams({ type: 'uniqueness' }, (n) => {
        const names = params.existingNames || []

        return !names.includes(n) || n === params.origName
      })
    },
    selectedType: {
      required,
      included: helpers.withParams({ type: 'included' }, (type) => {
        if (isChai) {
          return true
        }

        return Object.keys(SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES).some((t) => t === type)
      })
    },
    selectedParameterType: {
      required
    },
    selectedSetpointSources: {
      required: requiredIf(function () {
        return ctxt.selectedParameterType !== 'chai'
      }),
      members: helpers.withParams({ type: 'members' }, (srcs) => {
        if (ctxt.selectedParameterType === 'none') {
          return srcs.length === 1
        }
        if (ctxt.selectedParameterType === 'battery') {
          if (srcs.length < 2) {
            return false
          }

          return srcs.every((src) => {
            let pd
            if (params.getPhysicalDevice) {
              pd = params.getPhysicalDevice(src.iec)
            }

            // ems.topology.PhysicalDeviceType.BATTERY = 0
            // fallback "simple" heuristic check
            return pd ? pd.type === 0 : src.iec.iecNodePrefix === 'bat'
          })
        }
        if (ctxt.selectedParameterType === 'carLis') {
          if (srcs.length < 1) {
            return false
          }

          return srcs.every((src) => {
            let pd
            if (params.getPhysicalDevice) {
              pd = params.getPhysicalDevice(src.iec)
            }

            // ems.topology.PhysicalDeviceType.WALLBOX = 2
            // fallback "simple" heuristic check
            return pd ? pd.type === 2 : src.iec.iecNodePrefix === 'ch'
          })
        }
        if (ctxt.selectedParameterType === 'simplePriority') {
          if (srcs.length < 2) {
            return false
          }
        }
        if (ctxt.selectedParameterType === 'advancedPriority') {
          if (srcs.length < 2) {
            return false
          }
        }
        if (ctxt.selectedType === 'SWITCH') {
          if (srcs.length !== 1) {
            return false
          }
        }

        return true
      }),
      membersType: helpers.withParams({ type: 'membersType' }, (srcs = []) => {
        if (!srcs.length) {
          return true
        }

        if (isChai) {
          return true
        }

        return srcs.every((s) => {
          return ctxt.sourceIsAllowedForActuatorGroupType({ iecId: s.iec, actuatorGroupType: ctxt.selectedType })
        })
      })
    }
  }

  if (ctxt.selectedParameterType === 'battery') {
    validators.battery = {
      deltaSoc: {
        required,
        between: between(0, 100)
      }
    }
  }

  if (ctxt.selectedParameterType === 'advancedPriority') {
    validators.advancedPriority = {
      priority: {
        $each: {
          $trackBy: 'name',
          name: {},
          priority: {
            required,
            integer
          }
        }
      }
    }
  }

  if (ctxt.selectedParameterType === 'carLis') {
    validators.carLis = carLisValidators(ctxt)
  }

  if (isChai) {
    validators.chai = {
      prefix: {
        required,
        alphaNum
      },
      filename: {
        required,
        filepath: helpers.regex('filepath', /^[A-z\d-_/.]*\.[A-z]+$/)
      }
    }
  }

  return validators
}

export function carLisValidators(ctxt) {
  return {
    maxCurrentGrid: {
      required: requiredIf(function () {
        return !ctxt.carLis.ignoreCurrentGrid
      }),
      minValue: minValue(0),
      decimal: decimal
    },
    ignoreCurrentGrid: {
      isBoolean: (val) => typeof val === 'boolean'
    },
    maxPowerGrid: {
      required: requiredIf(function () {
        return !ctxt.carLis.ignorePowerGrid
      }),
      minValue: minValue(0),
      decimal: decimal
    },
    ignorePowerGrid: {
      isBoolean: (val) => (val) => typeof val === 'boolean'
    },
    maxCurrentLis: {
      required: requiredIf(function () {
        return !ctxt.carLis.ignoreCurrentLis
      }),
      minValue: minValue(0),
      decimal: decimal
    },
    ignoreCurrentLis: {
      isBoolean: (val) => (val) => typeof val === 'boolean'
    },
    chargePoint: {
      $each: {
        $trackBy: 'name',
        name: {},
        maxAmpere: {
          required,
          decimal: decimal,
          minValue: minValue(6)
        }
      }
    }
  }
}
