import { Upgrader } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb_service'
import {
  GetLogRequest as GetLogRequestPb,
  GetStateRequest as GetStateRequestPb,
  AcknowledgeErrorRequest as AcknowledgeErrorRequestPb,
  CheckForLatestUpgradeRequest as CheckForLatestUpgradeRequestPb,
  StartUpgradeRequest as StartUpgradeRequestPb,
  FinalizeUpgradeRequest as FinalizeUpgradeRequestPb,
  RollbackUpgradeRequest as RollbackUpgradeRequestPb
} from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import { evalFwupgraderError, evalFwupgraderImmediateError } from '@/api/error-handling'
import { createUnaryCall } from '@/grpc'
import { Timestamp as TimestampPb } from 'google-protobuf/google/protobuf/timestamp_pb'

/**
 * API call to get the log of the fwupgraderd
 *
 * @function
 *
 * @param {integer} timestamp seconds since unix epoch
 *
 * @return {promise}
 */
export function getLog(timestamp = 0) {
  const req = new GetLogRequestPb()
  const ts = new TimestampPb()
  ts.setSeconds(timestamp)
  req.setSince(ts)

  const call = createUnaryCall({
    service: Upgrader,
    method: 'GetLog',
    payload: req
  })

  return call.perform().then(evalFwupgraderError)
}

/**
 * API call to get the state of the fwupgraderd
 *
 * @function
 *
 * @return {promise}
 */
export function getState() {
  const req = new GetStateRequestPb()

  const call = createUnaryCall({
    service: Upgrader,
    method: 'GetState',
    payload: req
  })

  return call.perform().then(evalFwupgraderError)
}

/**
 * API call to Acknowledge an error
 *
 * @function
 *
 * @return {promise}
 */
export function acknowledgeError() {
  // ack any error state (and not a specific one only)
  // Currently, the falconer implementation does NOT eval `error_state` anyway.
  const req = new AcknowledgeErrorRequestPb()

  const call = createUnaryCall({
    service: Upgrader,
    method: 'AcknowledgeError',
    payload: req
  })

  return call.perform().then(evalFwupgraderError)
}

/**
 * API call to check whether there are new updates
 *
 * @function
 *
 * @return {promise}
 */
export function checkForLatestUpgrade() {
  const req = new CheckForLatestUpgradeRequestPb()

  const call = createUnaryCall({
    service: Upgrader,
    method: 'CheckForLatestUpgrade',
    payload: req
  })

  return call.perform().then((msg) => {
    return evalFwupgraderError(msg, {
      allowHook: (err) => {
        return err.hasUpdatesDisabled && err.hasUpdatesDisabled()
      }
    })
  })
}

/**
 * API call to start an upgrade
 *
 * @function
 *
 * @return {promise}
 */
export function startUpgrade() {
  const req = new StartUpgradeRequestPb()
  const call = createUnaryCall({
    service: Upgrader,
    method: 'StartUpgrade',
    payload: req
  })

  return call.perform().then(evalFwupgraderImmediateError)
}

/**
 * API call to finalize an upgrade
 *
 * @function
 *
 * @return {promise}
 */
export function finalizeUpgrade() {
  const req = new FinalizeUpgradeRequestPb()
  const call = createUnaryCall({
    service: Upgrader,
    method: 'FinalizeUpgrade',
    payload: req
  })

  return call.perform().then(evalFwupgraderImmediateError)
}

/**
 * API call to rollback an upgrade
 *
 * @function
 *
 * @return {promise}
 */
export function rollbackUpgrade() {
  const req = new RollbackUpgradeRequestPb()
  const call = createUnaryCall({
    service: Upgrader,
    method: 'RollbackUpgrade',
    payload: req
  })

  return call.perform().then(evalFwupgraderImmediateError)
}
