/**
 * @module store/device
 */

import { SUPPORTED_DEVICE_STATUSES } from './devices'
import { parseProtobufTimestampToIso8601 } from '@/grpc/parser'

/**
 * Represents a device model (a device of particular model/type/firmware/...)
 *
 * Inherits own properties (members) of [DeviceConfigInfo]{@link module:store/device.DeviceConfigInfo}
 *
 * @constructor
 *
 * @param {object} params define the properties of the device model
 *
 */
export function DeviceModel(params) {
  /**
   * An internal ID, used e.g. for select-dropdowns etc.
   *
   * @member {integer}
   */
  this.id = params.id

  /**
   * A human readable Name for this device model, Siemens PAC 2200.
   * Will be prepended by `driverAlias`
   *
   * @member {string}
   */
  this.name = params.name

  const dcf = new DeviceConfigInfo(params)

  Object.assign(this, dcf)

  const prefixName = (p) => {
    this.name = `${p} ${this.name}`
  }

  if (this.configTypeAlias) {
    prefixName(this.configTypeAlias)
  } else if (this.driverAlias) {
    prefixName(this.driverAlias)
  } else if (this.driverId) {
    prefixName(this.driverId)
  }
}

/**
 * Represents the set of meta-data of a device-config.
 *
 * @constructor
 *
 * @param {object} params with properties of the device config.
 *
 */
export function DeviceConfigInfo({
  driverId,
  driverAlias,
  configTypeId,
  configTypeAlias,
  configId,
  configMsgName,
  configMsgFieldNumPath,
  modelIds,
  modelNames
}) {
  /**
   * Is the DeviceDriver.id of the BE.
   *
   * @member {string}
   */
  this.driverId = driverId

  /**
   * Is a human readable alias for the driverId
   *
   * @member {string}
   */
  this.driverAlias = driverAlias

  /**
   * Is the DeviceConfig.ID.config_type_id of the BE.
   *
   * @member {string}
   */
  this.configTypeId = configTypeId

  /**
   * Is a human readable alias for the configTypeId
   *
   * @member {string}
   */
  this.configTypeAlias = configTypeAlias

  /**
   * Identifies the constructor of the `config` and allows to use the proto message descriptor.
   *
   * @member {string}
   */
  this.configMsgName = configMsgName

  /**
   * The config-ID of the device config (used by the BE).
   *
   * @member {object}
   */
  this.configId = configId

  /**
   * The proto message `DevicePb.DeviceConfig` wraps a number of 'bare' configs.
   * Each 'bare' config can be identified by a sequence (path) of field ID (integers).
   * E.g. `[4, 4, 11]` will point to `DeviceConfig -> Inverter -> Config4 -> Config5` of the proto message tree.
   */
  this.configMsgFieldNumPath = configMsgFieldNumPath || []

  this.modelIds = modelIds || []
  this.modelNames = modelNames || []
}

/* prettier-ignore */
/**
 * Is the device status.
 * A device status can be mapped to its DeviceConfigInfo or DeviceModel by its `configId`.
 *
 * The device status is mainly provided by the EMS.
 * Device status info are defined in `amperix.EnergyManagerDeviceStatus` proto message.
 *
 * @constructor
 *
 * @param {object} params with the device status. Param `info` allows to include any further status info.
 *
 */
export function DeviceStatus({
  configId,
  serialNumber,
  health,
  info
}) {
  /**
   * Is the ID used by the config-d.
   * Needed to uniquely identify the config for this device.
   */
  this.configId = configId

  /**
   * The serial number (physical identifier) of the 'real world' device.
   * Will be used by the user, to identify this device.
   */
  this.serialNumber = serialNumber

  if (health && !SUPPORTED_DEVICE_STATUSES.includes(health)) {
    throw new Error(`Invalid health status '${health}'.`)
  }

  /**
   * Describes the Health status of the (logical) device.
   * Is taken from the LPHD node of the IEC tree.
   *
   * All states of the proto ENUM `EnergyManagerDeviceStatus.EnergyManagerDeviceHealth` are allowed.
   * Additionally `FATAL` is present.
   * See [SUPPORTED_DEVICE_STATUSES]{@link module:store/devices.SUPPORTED_DEVICE_STATUSES}
   *
   */
  this.health = health

  Object.assign(this, info)
}

/**
 * Wrapper for `EnergyManagerDeviceValue`.
 *
 * Simplifies some proto-msg structs.
 * Projects onto data only.
 * This allows to store an instance in the Vuex store.
 *
 * Note: `toObject()` for EnergyManageDeviceValue makes it hard to get the right oneof `value` type.
 *
 * @constructor
 *
 * @param {object} val has to be a `EnergyManagerDeviceValue` instance.
 */
export function DeviceProcessValue(val) {
  /**
   * a reference string to uniquely identify the value
   */
  this.reference = val.getReference()

  /**
   * a human-readable alias for the value
   */
  this.alias = val.getAlias()

  /**
   * last time the value was updated (ISO8601 string)
   */
  this.lastUpdate = parseProtobufTimestampToIso8601(val.getLastUpdate())

  /**
   * unit symbol, including prefixes
   */
  this.unitSymbol = val.hasUnit() ? val.getUnit().getSymbol() : null

  /**
   * the value
   */
  this.value = null
  /**
   * the type of the value: 'boolean', 'number', 'string'
   */
  this.valueType = null
  if (val.hasValue()) {
    this.value = parseValue(val.getValue())
    this.valueType = getValueType(val.getValue())
  }

  /**
   * means the value is generally controllable
   */
  this.isControllable = null
  /**
   * in case the value is controllable,
   * the current controlValue
   */
  this.controlValue = null
  /**
   * in case the value is controllable,
   * the type of the settable value
   */
  this.controlValueType = null
  if (val.hasControl()) {
    const c = val.getControl()
    this.isControllable = c.getControllable()
    if (c.hasValue()) {
      this.controlValue = parseValue(c.getValue())
      this.controlValueType = getValueType(c.getValue())
    }
  }

  function parseValue(v) {
    if (v.hasBoolValue()) {
      return v.getBoolValue()
    } else if (v.hasNumericValue()) {
      return v.getNumericValue()
    } else if (v.hasStringValue()) {
      return v.getStringValue()
    } else {
      return null
    }
  }

  function getValueType(v) {
    if (v.hasBoolValue()) {
      return 'boolean'
    } else if (v.hasNumericValue()) {
      return 'number'
    } else if (v.hasStringValue()) {
      return 'string'
    }
  }
}
