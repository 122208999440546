<template>
  <ApxDevicesConfigNav>
    <ApxCardHeader :icon="deviceIcon" :title="$t(`config.device.${device}.listTitle`)" />
    <CCardBody>
      <nav class="navbar navbar-expand-sm bg-light">
        <!-- prettier-ignore -->
        <CButton
          class="btn mr-auto"
          type="button"
          size="sm"
          color="success"
          @click="routeToDeviceNew"
        >
          <CIcon size="sm" name="cilDescription" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t(`config.device.${device}.newTitle`) }}
          </span>
        </CButton>
      </nav>
    </CCardBody>
    <CCardBody>
      <h4>
        {{ $t('config.device.status.serviceable') }}
      </h4>
      <div class="table-responsive">
        <table class="table table-sm table-striped mt-0">
          <thead>
            <tr>
              <th class="d-none d-xxl-table-cell">
                {{ $t('config.device.list.driverName') }}
              </th>
              <th>
                {{ $t('config.device.list.deviceName') }}
              </th>
              <th> S/N </th>
              <th class="text-center">
                {{ $t('config.device.list.status') }}
              </th>
              <th class="d-none d-lg-table-cell">
                {{ $t('config.device.list.model') }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cfg in sortDevCfgInfos(serviceableDevCfgInfos, 'driverAlias')" :key="cfg.configId">
              <td class="d-none d-xxl-table-cell align-middle align-self-center text-left">
                {{ cfg.driverAlias }}
              </td>
              <td class="align-middle text-break">
                {{ getDeviceStatusByConfigId(cfg.configId).name }}
              </td>
              <td class="align-middle">
                {{ getDeviceStatusByConfigId(cfg.configId).serialNumber }}
              </td>
              <td class="align-middle align-self-center text-center">
                <!-- prettier-ignore -->
                <CIcon
                  :class="getDeviceStatusIconClass(cfg.configId)"
                  :name="(getDeviceStatusByConfigId(cfg.configId).health === 'OK') ? 'cisCheckCircle' : 'cisWarning'"
                  :height="28"
                />
              </td>
              <td v-if="getDeviceStatusByConfigId(cfg.configId).model" class="d-none d-lg-table-cell">
                {{ getDeviceStatusByConfigId(cfg.configId).model }}
              </td>
              <td v-else class="align-self-center text-center d-none d-lg-table-cell"> N/A </td>
              <td class="align-middle text-center">
                <CButton color="primary" size="sm" class="m-1" @click="routeToDeviceStatus(cfg.configId)">
                  {{ $t('main.detailsBtn') }}
                </CButton>
                <CButton
                  v-if="device === 'inverter'"
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="routeToDeviceOperate(cfg.configId)"
                >
                  {{ $t('config.device.nav.operateBtn') }}
                </CButton>
                <CButton color="primary" size="sm" class="m-1 px-3" @click="routeToDeviceEdit(cfg.configId)">
                  {{ $t('main.editBtn') }}
                </CButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CCardBody>
    <CCardBody v-if="unserviceableDevCfgInfos[0]">
      <h4>
        {{ $t('config.device.status.unserviceable') }}
      </h4>
      <div class="table-responsive">
        <table class="table table-sm table-striped mt-0">
          <thead>
            <tr>
              <th>
                {{ $t('config.device.list.driverName') }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cfg in sortDevCfgInfos(unserviceableDevCfgInfos, 'driverAlias')" :key="cfg.configId">
              <td class="align-middle">
                {{ cfg.driverAlias }}
              </td>
              <td class="align-middle text-right">
                <CButton
                  class="my-1 mr-2 mr-md-4 px-3"
                  color="primary"
                  size="sm"
                  @click="routeToDeviceEdit(cfg.configId)"
                >
                  {{ $t('main.editBtn') }}
                </CButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CCardBody>
  </ApxDevicesConfigNav>
</template>

<script>
import { mapGetters } from 'vuex'
import { toLower, toUpper } from 'lodash'
import ApxCardHeader from '@/components/snippets/apx-card-header'
import ApxDevicesConfigNav from '@/components/navs/apx-devices-config-nav'
import { deviceTypeToIcon } from '@/view-helper/device-config/device-config-models-helper'
import { sortDevCfgInfos } from '@/view-helper/sorting'

export default {
  name: 'Devices',
  components: { ApxCardHeader, ApxDevicesConfigNav },
  data() {
    return {
      device: ''
    }
  },
  computed: {
    ...mapGetters('deviceConfig', ['serviceableDevCfgInfos', 'unserviceableDevCfgInfos', 'getDeviceStatusByConfigId']),
    deviceIcon() {
      return deviceTypeToIcon(this.device)
    }
  },
  watch: {
    '$route.params.device': function (device) {
      this.initStore(device)
    }
  },
  created() {
    this.setDeviceName(this.$route.params.device)
  },
  mounted() {
    this.initStore(this.$route.params.device)
  },
  beforeDestroy() {
    this.$store.dispatch('deviceConfig/streamDeviceStatuses', { stop: true })
  },
  methods: {
    setDeviceName(device) {
      device = device.replace(/^([a-z]+)s$/i, '$1')
      device = toLower(device)
      this.device = device
    },
    initStore(device) {
      this.setDeviceName(device)
      this.$store.commit('deviceConfig/SET_DEVICE_TYPE', toUpper(this.device))

      // prettier-ignore
      this.$store
        .dispatch('deviceConfig/fetchDeviceDrivers', toUpper(this.device))
        .catch(this.onServerError)

      // CLEAR here to avoid temporarily inconsistent state due to async
      this.$store.commit('deviceConfig/CLEAR_CONFIGS')
      this.$store.commit('deviceConfig/CLEAR_STATUSES')
      this.$store
        .dispatch('deviceConfig/fetchDeviceConfigsByDeviceTypeWithStatus', toUpper(this.device))
        .catch(this.onServerError)
      this.$store.dispatch('deviceConfig/streamDeviceStatuses')
    },
    getDeviceStatusIconClass(configId) {
      const health = this.getDeviceStatusByConfigId(configId).health
      if (health === 'OK') {
        return 'status-ok'
      } else if (['WARNING', 'UNSURE'].includes(health)) {
        return 'status-warning'
      } else if (['ALARM', 'UNKNOWN', 'UNAVAILABLE'].includes(health)) {
        return 'status-alarm'
      } else {
        return null
      }
    },
    sortDevCfgInfos: sortDevCfgInfos,
    routeToDeviceNew() {
      this.$router.push({
        name: 'config-device-new',
        params: { device: this.device }
      })
    },
    routeToDeviceStatus(configId) {
      this.$router.push({
        name: 'config-device-status',
        params: { device: this.device, id: configId }
      })
    },
    routeToDeviceOperate(configId) {
      this.$router.push({
        name: 'config-device-operate',
        params: { device: this.device, id: configId }
      })
    },
    routeToDeviceEdit(configId) {
      this.$router.push({
        name: 'config-device-edit',
        params: { device: this.device, id: configId }
      })
    },
    onServerError(err) {
      this.$log.warn(err)
      const errMessage = {
        title: 'Server Error',
        type: 'toast-warning',
        content: this.$t('api.errors.fetchDeviceConfigs', { errorCode: err.code })
      }
      this.$store.commit('notifications/PUSH_TOAST', errMessage, {
        root: true
      })
    }
  }
}
</script>
