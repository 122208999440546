<template>
  <!-- prettier-ignore -->
  <Header>
    <template v-if="isDark" #brand-icon>
      <CIcon
        class="py-2 d-md-none"
        name="temLogoDarkMini"
        size="custom"
        :height="40"
      />
      <CIcon
        class="py-1 d-none d-md-inline d-lg-none"
        name="temLogoDark"
        size="custom"
        :height="40"
      />
    </template >
    <template v-else #brand-icon>
      <CIcon
        class="py-2 d-md-none"
        name="temLogoLightMini"
        size="custom"
        :height="40"
      />
      <CIcon
        class="py-1 d-none d-md-inline d-lg-none"
        name="temLogoLight"
        size="custom"
        :height="40"
      />
    </template>
  </Header>
</template>

<script>
import Header from './header'
import { mapGetters } from 'vuex'

export default {
  name: 'TemHeader',
  components: {
    Header
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    })
  }
}
</script>
