<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm @submit.prevent>
      <CRow form>
        <CCol sm="12">
          <!-- prettier-ignore -->
          <CSelect
            :value="preselection"
            :options="preselectionOpts"
            :horizontal="{ label: 'col-12 col-md-5 custom-form-label', input: 'col-12 col-md-7' }"
            :label="$t(`${tBasePath}.shortcuts.label`)"
            :description="$t(`${tBasePath}.shortcuts.description`)"
            @update:value="onSelectPreselect"
          />
        </CCol>
        <CCol v-if="preselection !== 'EVAL'" sm="12">
          <CInput
            type="number"
            custom="true"
            step="100"
            :max="maxPowerWatts"
            :min="minPowerWatts"
            :disabled="isDisabled"
            :description="powerWattsDescription"
            :is-valid="!isDisabled && $v.powerWatts.$dirty ? !$v.powerWatts.$error : null"
            :invalid-feedback="invalidFeedbackForPowerWatts"
            :horizontal="{ label: 'col-12 col-md-5 custom-form-label', input: 'col-12 col-md-7' }"
            :label="$t(`${tBasePath}.powerWatts.label`) + $t(`${tBasePath}.powerWatts.unit`)"
            :placeholder="powerWattsPlaceholder"
            :value.sync="$v.powerWatts.$model"
          />
        </CCol>
        <EvalTree v-if="preselection === 'EVAL'" />
        <!-- BEGIN: Position in Topology -->
        <CCol class="custom-form-separator" sm="12"></CCol>
        <!-- BEGIN: Position in Topology: Grid - Hidden on Small Devices -->
        <CCol
          xs="7"
          :class="{
            'offset-xs-0': true,
            'offset-sm-5': true,
            'pl-1': true,
            'd-none d-sm-block': true
          }"
        >
          <!-- prettier-ignore -->
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :disabled="isDisabled"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol
          tag="label"
          sm="5"
          :class="{
            'align-items-center': true,
            'text-muted': true,
            'd-none d-sm-block': true
          }"
          :for="safeId"
        >
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <!-- END: Position in Topology: Grid - Hidden on Small Devices -->
        <!-- BEGIN: Position in Topology: Device Selection -->
        <CCol
          tag="label"
          sm="5"
          :class="{
            'custom-form-label': true,
            'd-flex': true,
            'align-items-start': true
          }"
          :for="safeId"
        >
          {{ $t(`${tBasePath}.positionInTopology.label`) }}
        </CCol>
        <!-- BEGIN: Position in Topology: Grid - Visible on Small Devices -->
        <CCol
          xs="7"
          :class="{
            'pl-1': true,
            'd-block d-sm-none': true
          }"
        >
          <!-- prettier-ignore -->
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :disabled="isDisabled"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol
          tag="label"
          xs="5"
          :class="{
            'align-items-center': true,
            'text-muted': true,
            'd-block d-sm-none': true
          }"
          :for="safeId"
        >
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <!-- END: Position in Topology: Grid - Visible on Small Devices -->
        <CCol v-if="!isGrid" sm="7">
          <TreeSelect
            :id="safeId"
            :value="positionInTopologySelected"
            disable-branch-nodes
            multiple
            :placeholder="$t(`${tBasePath}.positionInTopology.placeholder`)"
            :options="positionInTopologyOpts"
            @input="onUpdatePositionInTopology"
          />
        </CCol>
        <CCol
          v-if="!isGrid"
          tag="label"
          xs="7"
          :class="{
            'offset-xs-0': true,
            'offset-sm-5': true,
            'pl-1': true
          }"
        >
          <!-- prettier-ignore -->
          <small
            :class="{
              'text-muted': true,
              'form-text': true,
              'w-100': true
            }"
          >
            {{ posInTopoDescription }}
          </small>
        </CCol>
        <!-- END: Position in Topology -->
      </CRow>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { makeUid } from '@coreui/utils/src'
// import { cloneDeep } from 'lodash'

import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import { esTargetPowerFormValidators } from '@/validations/ems-es-form-validators'
import { getEnergyServiceTargetPowerPreselectionOpts } from '@/view-helper/ems/ems-energy-service-helper'
import {
  generatePhysicalDeviceOptsForTreeSelect,
  getPhysicalDevicePreselection,
  isGridEnabled,
  makePhysicalDevicesList
} from '@/view-helper/ems/ems-topology-helper'
import EvalTree from '@/components/ems/evalexp/eval-tree'
import { recursivelyValidateEvalTreeVue } from '@/view-helper/form-helpers'
// import { EvalExpression as EvalExpressionPb } from '@/../lib/proto_js/ext/ems/eval_pb'
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'EsTargetPowerForm',
  components: {
    EsLayoutForm,
    EvalTree,
    TreeSelect
  },
  props,
  data() {
    return {
      powerWatts: 0,
      positionInTopologySelected: [],
      isGrid: null,
      preselection: null,
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['existingPhysicalDevices']),
    ...mapState('emsEvalExpression', ['evalExpression']),
    ...mapGetters('emsEvalExpression', ['parsedEvalTree', 'isValidTree', 'sizeEvalTree']),
    positionInTopologyOpts() {
      return generatePhysicalDeviceOptsForTreeSelect(this.existingPhysicalDevices)
    },

    preselectionOpts() {
      return getEnergyServiceTargetPowerPreselectionOpts()
    },
    safeId() {
      return makeUid()
    },
    isDisabled() {
      return this.preselection === 'SELF-CONSUMPTION'
    },
    maxPowerWatts() {
      if (this.preselection === 'PV-CURTAILMENT') {
        return 0
      } else {
        return null
      }
    },
    minPowerWatts() {
      if (this.preselection === 'PEAKSHAVING') {
        return 0
      } else {
        return null
      }
    },
    powerWattsDescription() {
      switch (this.preselection) {
        case 'PEAKSHAVING':
          return this.$t(`${this.tBasePath}.powerWatts.peakshavingDescription`)
        case 'PV-CURTAILMENT':
          if (this.isGrid) {
            return this.$t(`${this.tBasePath}.powerWatts.curtailmentDescription`)
          } else {
            return this.$t(`${this.tBasePath}.powerWatts.description`)
          }
        case 'SELF-CONSUMPTION':
          return this.$t(`${this.tBasePath}.powerWatts.consumptionDescription`)
        default:
          return this.$t(`${this.tBasePath}.powerWatts.description`)
      }
    },
    posInTopoDescription() {
      switch (this.preselection) {
        case 'PEAKSHAVING':
          return this.$t(`${this.tBasePath}.positionInTopology.peakshavingDescription`)
        case 'PV-CURTAILMENT':
          return this.$t(`${this.tBasePath}.positionInTopology.curtailmentDescription`)
        case 'SELF-CONSUMPTION':
          return this.$t(`${this.tBasePath}.positionInTopology.consumptionDescription`)
        default:
          return this.$t(`${this.tBasePath}.positionInTopology.description`)
      }
    },
    powerWattsPlaceholder() {
      if (this.preselection === 'PV-CURTAILMENT') {
        return '-7000'
      } else if (this.preselection === 'PEAKSHAVING') {
        return '16000'
      }
      return '0'
    },
    invalidFeedbackForPowerWatts() {
      if (!this.$v.powerWatts.$error) {
        return ''
      }

      const isInvalid = (condition) => {
        return Object.prototype.hasOwnProperty.call(this.$v.powerWatts, condition) && !this.$v.powerWatts[condition]
      }
      const base = 'ems.energyService.config.form.targetPower.powerWatts.validation.invalidFeedback'
      const conditions = ['required', 'decimal', 'zero', 'positive', 'negative']
      let feedback = ''
      for (const c of conditions) {
        if (isInvalid(c)) {
          feedback = this.$t(`${base}.${c}`)
          break
        }
      }

      return feedback
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.targetPower'
    this.switchLabelIcon = {
      labelOn: '\u2713',
      labelOff: '\u2715'
    }
  },
  mounted() {
    this.init()
  },
  validations() {
    return esTargetPowerFormValidators(this)
  },
  methods: {
    async init() {
      this.preselection = this.initPreselect
      if (this.preselection !== 'EVAL') {
        this.powerWatts = this.initValues.powerWatts
      } else {
        this.$store.commit('emsEvalExpression/SET', this.initValues.evalWatts)
      }
      const pit = this.initValues.positionInTopology
      this.isGrid = isGridEnabled(pit)

      if (pit && pit.physicalDevicesList) {
        this.positionInTopologySelected = getPhysicalDevicePreselection(pit.physicalDevicesList)
      }

      this.$store.commit('apiLoadingStatus/STARTED')

      await this.$store
        .dispatch('emsSensors/sensorInit', {})
        .then(() => {
          this.$log.debug('Succesfully fetched sensor names.')
        })
        .catch((err) => {
          this.$log.error(err)
        })

      await this.$store
        .dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: false
        })
        .then(() => {
          this.$log.debug('Succesfully fetched timeseries names.')
        })
        .catch((err) => {
          this.$log.error(err)
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onSelectPreselect(val) {
      // DO not call this.init() here, otherwise you always reset to the init state :)
      this.preselection = val
      this.powerWatts = val === 'SELF-CONSUMPTION' ? 0 : null
      this.isGrid = true
      this.positionInTopologySelected = []
      this.showFailureAlert = false
      this.$v.$reset()
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const physicalDevicesList = makePhysicalDevicesList(this.positionInTopologySelected, this.isGrid)
      const esParams = { positionInTopology: { physicalDevicesList } }
      if (this.preselection !== 'EVAL') {
        esParams.powerWatts = parseFloat(this.powerWatts)
      } else {
        if (!this.isValidTree) {
          recursivelyValidateEvalTreeVue(this)
          this.showFailureAlert = true
          return
        }

        esParams.evalWatts = this.evalExpression
      }

      this.$emit('submit', {
        esParams,
        esPreselection: this.preselection
      })
    },
    onUpdatePositionInTopology(selection) {
      this.positionInTopologySelected = selection
    }
  }
}
</script>
