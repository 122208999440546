import {
  cibClockify,
  cibCreativeCommonsSampling,
  cibElasticStack,
  cibServerFault,
  cisCheckCircle,
  cisEye,
  cisEyeSlash,
  cisFilter,
  cisGraph,
  cisMinusCircle,
  cisRemoteControl,
  cisRemoteSettings,
  cisSettings,
  cisWarning,
  linearSet
} from '@coreui/icons-pro'

import { amperixMinimalisticLogo } from './amperix-logo'
import { temLogoLight, temLogoLightMini, temLogoDarkMini, temLogoDark, temFooterLogoLight } from './tem-logo'
import { wendewareFooterLogoLight, wendewareFooterLogoDark } from './wendeware-logo'
import { itwmFooterLogoLight, itwmFooterLogoDark } from './fhg-itwm-logo'

export const iconsSet = Object.assign(
  {},
  { amperixMinimalisticLogo },
  { temLogoLightMini, temLogoLight, temLogoDarkMini, temLogoDark, temFooterLogoLight },
  { wendewareFooterLogoLight, wendewareFooterLogoDark },
  { itwmFooterLogoLight, itwmFooterLogoDark },
  {
    cibClockify,
    cibCreativeCommonsSampling,
    cibElasticStack,
    cibServerFault,
    cisCheckCircle,
    cisEye,
    cisEyeSlash,
    cisFilter,
    cisGraph,
    cisMinusCircle,
    cisRemoteControl,
    cisRemoteSettings,
    cisSettings,
    cisWarning
  },
  linearSet
)
