<template>
  <CCol sm="12" :lg="{ size: 8, offset: 2 }" :xl="{ size: 6, offset: 3 }">
    <CCard class="mx-3 mx-lg-0">
      <ApxCardHeader icon="cil-shield-x" :title="$t(`${tBasePath}.pageTitle`)" />
      <!-- BEGIN: Reset password/Create user with PUK Form  -->
      <CCardBody>
        <CForm autocomplete="off" @submit.prevent>
          <CRow class="form-group">
            <CCol lg="12">
              <CButton
                color="btn btn-outline-primary"
                size="sm"
                class="py-1 mb-2"
                :class="{
                  'btn-link-darkMode': isDark
                }"
                @click="routeToLogin"
              >
                {{ $t('management.user.registerLicenseForm.loginLink') }}
              </CButton>

              <CCardText class="small m-0 p-0 d-flex justify-content-start">
                <p class="d-block d-sm-none mt-1">
                  <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
                </p>
                <p class="text-muted text-break d-none d-sm-block">
                  {{ $t(`${tBasePath}.description`) }}
                </p>
                <p v-if="showInfoDescription" class="ml-3 text-muted text-break d-block d-sm-none">
                  {{ $t(`${tBasePath}.description`) }}
                </p>
              </CCardText>
              <!-- BEGIN: username -->
              <CInput
                class="custom-form-label"
                :value.sync="$v.resetPasswortCreateUserPukForm.username.$model"
                :label="$t(`${tBasePath}.resetPasswortCreateUserPukForm.username.label`)"
                :placeholder="$t(`${tBasePath}.resetPasswortCreateUserPukForm.username.placeholder`)"
                :is-valid="
                  $v.resetPasswortCreateUserPukForm.username.$dirty
                    ? !$v.resetPasswortCreateUserPukForm.username.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('username')"
                :horizontal="styling.horizontal"
                type="text"
              >
                <template #description>
                  <small class="form-text text-muted d-none d-sm-block">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.username.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.username.description`) }}
                  </small>
                </template>
              </CInput>
              <!-- END: username -->
              <!-- BEGIN: password -->
              <CInput
                autocomplete="new-password"
                class="custom-form-label"
                :value.sync="$v.resetPasswortCreateUserPukForm.password.$model"
                :label="$t(`${tBasePath}.resetPasswortCreateUserPukForm.password.label`)"
                :placeholder="$t(`${tBasePath}.resetPasswortCreateUserPukForm.password.placeholder`)"
                :is-valid="
                  $v.resetPasswortCreateUserPukForm.password.$dirty
                    ? !$v.resetPasswortCreateUserPukForm.password.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('password')"
                :horizontal="styling.horizontal"
                :type="showPassword.password ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.password"
                    @togglePwdVisibility="showPassword.password = !showPassword.password"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
                <template #description>
                  <small class="form-text text-muted d-none d-sm-block col-12 px-0">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.password.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.password.description`) }}
                  </small>
                </template>
              </CInput>
              <!-- END: password -->
              <!-- BEGIN: passwordConfirm -->
              <CInput
                autocomplete="new-password"
                class="custom-form-label"
                :value.sync="$v.resetPasswortCreateUserPukForm.passwordConfirm.$model"
                :label="$t(`${tBasePath}.resetPasswortCreateUserPukForm.passwordConfirm.label`)"
                :placeholder="$t(`${tBasePath}.resetPasswortCreateUserPukForm.passwordConfirm.placeholder`)"
                :is-valid="
                  $v.resetPasswortCreateUserPukForm.passwordConfirm.$dirty
                    ? !$v.resetPasswortCreateUserPukForm.passwordConfirm.$invalid
                    : null
                "
                :invalid-feedback="invalidFeedbackFor('passwordConfirm')"
                :horizontal="styling.horizontal"
                :type="showPassword.passwordConfirm ? 'text' : 'password'"
              >
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.passwordConfirm"
                    @togglePwdVisibility="showPassword.passwordConfirm = !showPassword.passwordConfirm"
                  />
                </template>
                <!-- BEGIN: Description for Password Confirm -->
                <template #description>
                  <small class="form-text text-muted col-12 px-0">
                    <!-- Note: This html white space character helps to avoid weird line break behavior of the password visibility toggle icon  -->
                    &zwnj;
                  </small>
                </template>
                <!-- END: Description for Password Confirm -->
                <!-- END: Toggle Password Visibility  -->
              </CInput>
              <!-- END: passwordConfirm -->
              <!-- BEGIN: PUK  -->
              <CInput
                class="custom-form-label"
                :value.sync="$v.resetPasswortCreateUserPukForm.puk.$model"
                :label="$t(`${tBasePath}.resetPasswortCreateUserPukForm.puk.label`)"
                :placeholder="$t(`${tBasePath}.resetPasswortCreateUserPukForm.puk.placeholder`)"
                :is-valid="
                  $v.resetPasswortCreateUserPukForm.puk.$dirty ? !$v.resetPasswortCreateUserPukForm.puk.$invalid : null
                "
                :invalid-feedback="invalidFeedbackFor('puk')"
                :horizontal="styling.horizontal"
                :type="showPassword.puk ? 'text' : 'password'"
              >
                <template #description>
                  <small class="form-text text-muted d-none d-sm-block col-12 px-0">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.puk.description`) }}
                  </small>
                  <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
                    {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.puk.description`) }}
                  </small>
                </template>
                <!-- BEGIN: Toggle Password Visibility -->
                <template #append-content>
                  <PwdVisibilityToggle
                    :show-password="showPassword.puk"
                    @togglePwdVisibility="showPassword.puk = !showPassword.puk"
                  />
                </template>
                <!-- END: Toggle Password Visibility  -->
              </CInput>
              <!-- END:   PUK -->
            </CCol>
          </CRow>
          <!-- BEGIN:  Action Buttons -->
          <!-- prettier-ignore -->
          <div class="mt-n3 form-row px-2 d-flex justify-content-between">
            <CButton
              class="mt-2"
              size="sm"
              color="success"
              :disabled="submitDisabled"
              @click="createUser"
            >
              {{ $t(`${tBasePath}.actions.create`) }}
            </CButton>
            <CButton
              class="mt-2"
              size="sm"
              color="primary"
              :disabled="submitDisabled"
              @click="showPasswordResetModal = true"
            >
              {{ $t(`${tBasePath}.actions.reset`) }}
            </CButton>
          </div>
          <!-- END:   Action Buttons  -->
        </CForm>
      </CCardBody>
      <!-- END: Reset password/Create user with PUK Form  -->
      <!-- BEGIN: Reset Password Modal -->
      <ConfirmationModal
        :visible.sync="showPasswordResetModal"
        color="danger"
        size="sm"
        :title="$t(`${tBasePath}.resetPasswortCreateUserPukForm.confirmationModal.resetPassword.title`)"
        :disabled="submitDisabled"
        @update:confirmation="resetPassword({ confirmed: $event })"
      >
        {{ $t(`${tBasePath}.resetPasswortCreateUserPukForm.confirmationModal.resetPassword.content`) }}
      </ConfirmationModal>
      <!-- END: Reset Password Modal -->
    </CCard>
  </CCol>
</template>

<script>
import { mapGetters } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import HelpBtn from '@/components/snippets/help-btn'
import PwdVisibilityToggle from '@/components/snippets/pwd-visibility-toggle'

import { newToastNotificationMsg } from '../store/modules/notifications'
import { createUserResetPukValidations } from '@/validations/user-settings-validators'

export default {
  name: 'UserPuk',
  components: {
    ApxCardHeader,
    ConfirmationModal,
    HelpBtn,
    PwdVisibilityToggle
  },
  data() {
    return {
      resetPasswortCreateUserPukForm: {
        username: null,
        password: null,
        passwordConfirm: null,
        puk: null
      },
      showInfoDescription: false,
      showPassword: {
        password: false,
        passwordConfirm: false,
        puk: false
      },
      showPasswordResetModal: false
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v.resetPasswortCreateUserPukForm[field][condition]
        }
        const base = `management.users.resetUserPuk.resetPasswortCreateUserPukForm.${field}.invalidFeedback`
        let conditions = []

        if (field === 'username') {
          conditions = ['required', 'minLength']
        }
        if (field === 'password') {
          conditions = ['required', 'minLength', 'strongPasswordRequired']
        }
        if (field === 'passwordConfirm') {
          conditions = ['required', 'sameAsPassword']
        }
        if (field === 'puk') {
          conditions = ['required']
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    submitDisabled() {
      return this.$v.resetPasswortCreateUserPukForm.$invalid
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-xl-5',
        input: 'col-12 col-xl-7',
        description: 'col-12 col-sm-10'
      }
    }
    this.tBasePath = 'management.users.resetUserPuk'
  },
  methods: {
    createUser() {
      this.$v.resetPasswortCreateUserPukForm.$touch()
      if (this.$v.resetPasswortCreateUserPukForm.$invalid) {
        return
      }

      const username = this.resetPasswortCreateUserPukForm.username
      const password = this.resetPasswortCreateUserPukForm.password
      const puk = this.resetPasswortCreateUserPukForm.puk

      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('users/createUserWithPuk', {
          username,
          password,
          puk
        })
        .then(() => {
          this.$log.debug('Succesfully created a new user with a PUK')

          this.onSuccess('createSuccess')
          this.routeToLogin()
        })
        .catch((err) => {
          this.onServerError(err, 'createError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
          this.isLoading = false
        })
    },
    resetPassword({ confirmed }) {
      if (!confirmed) {
        return
      }
      this.showPasswordResetModal = false

      this.$v.resetPasswortCreateUserPukForm.$touch()
      if (this.$v.resetPasswortCreateUserPukForm.$invalid) {
        return
      }

      const username = this.resetPasswortCreateUserPukForm.username
      const newPassword = this.resetPasswortCreateUserPukForm.password
      const puk = this.resetPasswortCreateUserPukForm.puk

      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('users/updateUserWithPuk', {
          username,
          newPassword,
          puk
        })
        .then(() => {
          this.$log.debug('Succesfully reset the user password with a PUK')

          this.onSuccess('resetSuccess')
          this.routeToLogin()
        })
        .catch((err) => {
          this.onServerError(err, 'resetError')
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
          this.isLoading = false
        })
    },
    onServerError(err, type) {
      const tErrorPath = 'api.errors.users'
      let content

      switch (type) {
        case 'resetError':
          content = this.$t(`${tErrorPath}.reset`, { errorCode: err.code })
          break
        case 'createError':
          content = this.$t(`${tErrorPath}.create`, { errorCode: err.code })
          break
      }
      content += ' ' + err.message
      content = content.trim()

      this.$log.warn(err)
      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 10000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content
        })
      )
    },
    onSuccess(type) {
      let content

      switch (type) {
        case 'resetSuccess':
          content = this.$t(`${this.tBasePath}.notifications.reset`)
          break
        case 'createSuccess':
          content = this.$t(`${this.tBasePath}.notifications.create`)
          break
      }

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 8000,
          type: 'success',
          title: this.$t('api.success.ok'),
          content
        })
      )
    },
    routeToLogin() {
      this.$router.push({ name: 'login' })
    }
  },
  validations: {
    resetPasswortCreateUserPukForm: createUserResetPukValidations()
  }
}
</script>
