<template>
  <div>
    <HelpBtn class="d-block d-sm-none float-right" @toggleHelpText="showInfoDescription = !showInfoDescription" />
    <CForm @submit.prevent>
      <CInput
        type="number"
        class="custom-form-label"
        :value.sync="$v.ts.peakWatts.$model"
        :label="$t(`${tBasePathCurrentRlm}.peakWatts.label`) + $t(`${tBasePathCurrentRlm}.peakWatts.unit`)"
        :placeholder="$t(`${tBasePathCurrentRlm}.peakWatts.placeholder`)"
        :is-valid="$v.ts.peakWatts.$dirty ? !$v.ts.peakWatts.$error : null"
        :invalid-feedback="invalidFeedbackFor('peakWatts')"
        :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
      >
        <template #description>
          <div class="col-12 pl-0 text-muted small mt-1 d-none d-sm-block">
            {{ $t(`${tBasePathCurrentRlm}.peakWatts.description`) }}
          </div>
          <div v-if="showInfoDescription" class="text-muted small mt-1 d-block d-sm-none">
            {{ $t(`${tBasePathCurrentRlm}.peakWatts.description`) }}
          </div>
        </template>
      </CInput>
      <v-date-picker
        v-model="ts.timestamp"
        :locale="currentLocal"
        mode="dateTime"
        :is-dark="isDark"
        is24hr
        :min-date="new Date()"
        :popover="{ placement: $screens({ default: 'top', md: 'top-start' }) }"
      >
        <template v-slot="{ inputValue, inputEvents, togglePopover }">
          <CInput
            class="custom-form-label pl-1"
            :value="inputValue"
            :label="$t(`${tGlobalRlmPath}.form.rlmTimeseries.timestamp.tableHeader`)"
            :is-valid="$v.ts.timestamp.$error ? false : null"
            :invalid-feedback="invalidFeedbackFor('timestamp')"
            :horizontal="{ label: 'col-12 col-xl-5', input: 'col-12 col-xl-7' }"
            readonly
            v-on="inputEvents"
          >
            <template #append>
              <CButton size="sm" color="primary" @click="togglePopover()">
                <CIcon size="lg" name="cilCalendar" />
              </CButton>
            </template>
            <template #description>
              <div class="col-12 pl-0 text-muted small mt-1 d-none d-sm-block">
                {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.timestamp.description`) }}
              </div>
              <div v-if="showInfoDescription" class="text-muted small mt-1 d-block d-sm-none">
                {{ $t(`${tGlobalRlmPath}.form.rlmTimeseries.timestamp.description`) }}
              </div>
            </template>
          </CInput>
        </template>
      </v-date-picker>
    </CForm>
    <!-- prettier-ignore -->
    <div class="mt-3 form-row">
      <CButton
        class="mr-2"
        size="sm"
        color="primary"
        @click="onAdd"
      >
        {{ $t('main.addBtn') }}
      </CButton>

      <CButton
        class="ml-auto"
        size="sm"
        color="danger"
        @click="onRemove"
      >
        {{ $t('main.removeAllBtn') }}
      </CButton>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import HelpBtn from '@/components/snippets/help-btn'
import { addRlmTimestampValueValidators } from '@/validations/ems-es-form-validators'

export default {
  name: 'TimeseriesConfigForm',
  components: {
    HelpBtn
  },
  props: {
    tsName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showInfoDescription: false,
      ts: {
        peakWatts: null,
        timestamp: this.initialTimestamp()
      }
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v.ts[field][condition]
        }
        const base = `ems.energyService.config.globalRlmSetting.form.rlmTimeseries.${field}.validation.invalidFeedback`

        let conditions = ['required', 'positive']
        if (field === 'timestamp') {
          conditions = ['minValue']
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    ...mapGetters('user', {
      currentLocal: 'lang'
    })
  },
  created() {
    this.tBasePathCurrentRlm = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.tGlobalRlmPath = 'ems.energyService.config.globalRlmSetting'
  },
  methods: {
    initialTimestamp() {
      return moment().add(15, 'minutes').format()
    },
    onAdd() {
      this.$v.ts.$touch()
      if (this.$v.ts.$invalid) {
        return
      }

      const timestampValue = {
        timestamp: moment(this.ts.timestamp).startOf('minute').unix(),
        value: this.ts.peakWatts
      }
      this.$store.commit('emsTimeseries/ADD_TIMESTAMP_VALUE', {
        tsName: this.tsName,
        timestampValue
      })

      this.$v.ts.$reset()
      this.ts.peakWatts = null
      this.ts.timestamp = this.initialTimestamp()
    },
    onRemove() {
      this.$store.commit('emsTimeseries/SET_TIMESTAMP_VALUES', {
        tsName: this.tsName,
        timestampValueList: []
      })
    }
  },
  validations: addRlmTimestampValueValidators
}
</script>
