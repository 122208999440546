/**
 * @module view-helper/ems-energy-service-helper
 */

import i18n from '@/i18n'
import * as DataObjectPb from '@/../lib/proto_js/ext/ems/data-objects_pb'
import { EnergyService as EnergyServicePb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import { getEnumStr } from '@/grpc/parser'
import { EMS_ENERGY_SERVICE_DESCRIPTION } from '@/grpc/protobuf/ems-energy-service-helper'

export const EMS_ENERGY_SERVICE_NEW_STRATEGY_ID = '_new-strategy'

export const EMS_ENERGY_SERVICE_NONE_ID = '_no-energy-service'

export const EMS_GRID_STATES = Object.keys(DataObjectPb.GridState)
  .filter((gs) => {
    return !['UNKNOWN', 'OFF', 'OTHER', 'ISLAND_WITHOUT_LOAD', 'ISLAND_WITH_LOAD', 'ISLAND_TO_ISLAND'].includes(gs)
  })
  .sort((a, b) => {
    return DataObjectPb.GridState[a] - DataObjectPb.GridState[b]
  })

export const EMS_VPP_TARGET = Object.keys(DataObjectPb.ENS_ExternalSetpointTarget.Value)
  .filter((gs) => {
    return !['PV'].includes(gs)
  })
  .sort((a, b) => {
    return DataObjectPb.ENS_ExternalSetpointTarget.Value[a] - DataObjectPb.ENS_ExternalSetpointTarget.Value[b]
  })

export const getNewEnergyService = ({ asPlain, energyServiceType } = { asPlain: true }) => {
  const init = new Array(3)
  if (energyServiceType) {
    const fieldId = EMS_ENERGY_SERVICE_DESCRIPTION.fields[energyServiceType].id
    init[fieldId - 1] = []
  }
  const es = new EnergyServicePb(init)
  if (asPlain) {
    return es.toObject()
  }

  return es
}

export const getEnergyServiceTargetPowerPreselectionOpts = () => {
  return [
    {
      label: i18n.t('ems.energyService.config.form.targetPower.shortcuts.options.advanced'),
      value: 'ADVANCED'
    },
    {
      label: i18n.t('ems.energyService.config.form.targetPower.shortcuts.options.consumption'),
      value: 'SELF-CONSUMPTION'
    },
    {
      label: i18n.t('ems.energyService.config.form.targetPower.shortcuts.options.peakshaving'),
      value: 'PEAKSHAVING'
    },
    {
      label: i18n.t('ems.energyService.config.form.targetPower.shortcuts.options.curtailment'),
      value: 'PV-CURTAILMENT'
    },
    {
      label: i18n.t('ems.energyService.config.form.targetPower.shortcuts.options.eval'),
      value: 'EVAL'
    }
  ]
}

export const getHumanizedDecisionNodeLabel = (id, entry) => {
  if (!id) {
    return ''
  }

  const translateEnumVal = ({ enumVal, enumDef, tPath } = {}) => {
    if (typeof enumVal === 'number') {
      enumVal = getEnumStr(enumVal, enumDef)
    }
    if (enumVal && i18n.te(`${tPath}.${enumVal}`)) {
      return i18n.t(`${tPath}.${enumVal}`)
    } else if (!enumVal) {
      return '...'
    }

    return enumVal
  }

  const tBasePath = 'ems.energyService.config.decisionTree'
  const tPath = `${tBasePath}.node.${id}`
  if (i18n.te(tPath)) {
    let label
    const params = entry.params.decisionNodeParams
    let val
    switch (id) {
      case 'gridStateIs':
        label = i18n.t(tPath, {
          state: translateEnumVal({
            enumVal: params?.gridState,
            enumDef: DataObjectPb.GridState,
            tPath: `${tBasePath}.form.gridStateIs.gridState`
          })
        })
        break
      case 'vppGridBatTargetIs':
        label = i18n.t(tPath, {
          target: translateEnumVal({
            enumVal: params?.target,
            enumDef: DataObjectPb.ENS_ExternalSetpointTarget.Value,
            tPath: `${tBasePath}.form.vppGridBatTargetIs.target`
          })
        })
        break
      case 'socGreaterThan':
        val = params ? params.socThresholdPercent : null
        if (typeof val !== 'number') {
          val = '...'
        }
        label = i18n.t(tPath, { soc: val })
        break
      default:
        label = i18n.t(tPath)
    }

    return label
  } else {
    return entry.name
  }
}
