/**
 * Actions for the [device-config store]{@link module:store/device-config}
 *
 * @module store/device-config-actions
 */

import * as DevicePb from '@/../lib/proto_js/amperix/device_noopt_pb'
import { EnergyManagerDeviceStatus } from '@/../lib/proto_js/ext/amperix/ems_pb'
import logger from '@/logger'
import { startStopStream } from '@/api/misc'
import * as apiDevice from '@/api/amperix/device'
import * as apiEms from '@/api/amperix/ems'
import { DEVICE_CONFIG_DESCRIPTOR } from '@/grpc/protobuf/device-config-helper'
import { getEnumStr, objectToProtoMsg } from '@/grpc/parser'
import { DeviceStatus } from '@/store/modules/_device-structs'
import { CACHE_ROOT_KEYS, setCache, clearCache } from '@/store/cache/cache'

// private
const streamState = {
  status: {}
}

// --- List and CRUD config(s) actions ---

/**
 * Action to fetch all available device-drives form the BE for a paritcular device-type.
 *
 * Received drivers are added to `drivers` of this store state.
 *
 * **Clients of this action should use the store state to access the result, not the return itself. Errors should be handled by evaluating the promise rejection.**
 *
 * @function
 *
 * @param {string} deviceType (optional) has to be one of the [SUPPORTED_DEVICES]{@link module:store/devices.SUPPORTED_DEVICES}
 *
 * @return {Promise} the Device.GetDeviceDriversResponse
 */
export async function fetchDeviceDrivers({ commit }, deviceType) {
  commit('SET_DEVICE_TYPE', deviceType)
  commit('CLEAR_DRIVERS')

  const doCommit = (msg) => {
    msg.getDriversList().forEach((driver) => {
      commit('ADD_DRIVER', driver.toObject())
    })

    return msg
  }

  return apiDevice.fetchDeviceDrivers(deviceType).then(doCommit)
}

/**
 * Action to fetch all configured devices form the BE for a particular device-type.
 *
 * Side effects:
 * - initially `CLEAR_CONFIGS` is committed
 * - `SET_DEVICE_TYPE` is committed
 * - Received configs are added to `configs` of this store state.
 *
 * **Clients of this action should use the store state to access the result, not the return itself. Errors should be handled by evaluating the promise rejection.**
 *
 * @function
 *
 * @param {string} deviceType (optional) has to be one of the [SUPPORTED_DEVICES]{@link module:store/devices.SUPPORTED_DEVICES}
 *
 * @return {Promise} which resolves to a list of Device.GetDeviceConfigsResponse proto messages.
 */
export async function fetchDeviceConfigsByDeviceType({ commit }, deviceType) {
  const doCommit = (msg) => {
    commit('SET_DEVICE_TYPE', deviceType)
    commit('CLEAR_CONFIGS')
    msg.getConfigsList().forEach((c) => {
      commit('ADD_CONFIG', c.toObject())
    })

    return msg
  }

  return apiDevice.fetchDeviceConfigs(deviceType).then(doCommit)
}

/**
 * Get the default values for a DeviceConfig.
 * Has NO side-effect.
 * Only calls API.
 *
 * @function
 *
 * @param {object} params see [API getDefaultDeviceConfig]{@link module:src/api/amperix/device.getDefaultDeviceConfig}
 *
 * @return {Promise} which resolves to a `DevicePb.DeviceConfig.<device>.<config>` proto message
 */
export async function getDefaultDeviceConfig({ commit }, { fieldNumbers, driverId }) {
  return apiDevice.getDefaultDeviceConfig({ fieldNumbers, driverId })
}

/**
 * GET a device config
 *
 * SIDE EFFECT:
 * Will write the received proto-JS config to the global in memory cache.
 * This cache can be used to avoid proto-JS <-> plain-JS parsing and its problems.
 *
 * @function
 *
 * @param {string} configId
 *
 * @return {promise}
 */
export async function getDeviceConfig({ getters, commit, dispatch }, configId) {
  await dispatch('fetchDeviceDrivers') // need to receive all drives for meta-data; clears drivers

  const doCommit = (msg) => {
    commit('CLEAR_CONFIGS')

    const cfg = msg.getConfig()
    commit('ADD_CONFIG', cfg.toObject()) // persist proto config as plain JS-object

    // cache proto-JS object
    const configId = cfg.getId()?.getId() || '__unknown__'
    clearCache(CACHE_ROOT_KEYS.deviceConfigs)
    setCache([CACHE_ROOT_KEYS.deviceConfigs, configId], cfg)

    let devCfgInfo = {}
    try {
      devCfgInfo = getters.getDevCfgInfo(msg.getConfig().getId().getId())
    } catch (err) {
      logger.warn('Received a DeviceConfig message without config-d ID. ID is required.')
      logger.warn(err)
    }

    commit('FILTER_DRIVERS', devCfgInfo.driverId ? [devCfgInfo.driverId] : [])

    const driver = getters.drivers.find((d) => d.id === devCfgInfo.driverId)
    let deviceType = 'UNSPECIFIED'
    if (driver) {
      deviceType = getEnumStr(driver.deviceType, DevicePb.DeviceType)
    }
    commit('SET_DEVICE_TYPE', deviceType)

    return msg
  }

  return apiDevice.getDeviceConfig(configId).then(doCommit)
}

/**
 * Adds (creates) a new device config
 * Will include required meta-data from the device config info into the device config proto message.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.deviceType is a [Device Type]{@link module:store/devices.SUPPORTED_DEVICES}
 * @param {DeviceConfigInfo} params.devCfgInfo has to be a [DeviceConfigInfo]{@link module:store/device.DeviceConfigInfo} containing meta-data, e.g. the driver and config-type ID.
 * @param {DeviceModel} params.devCfgMsg has to be a plain or proto object of `DevicePb.DeviceConfig`
 *
 * @return {Promise}
 */
export async function addDeviceConfig({ commit }, { deviceType, devCfgInfo, devCfgMsg }) {
  commit('SET_DEVICE_TYPE', deviceType)

  if (typeof devCfgMsg.toObject !== 'function') {
    devCfgMsg = objectToProtoMsg({
      descriptor: DEVICE_CONFIG_DESCRIPTOR,
      payload: devCfgMsg
    })
  }

  if (!devCfgMsg.hasId()) {
    devCfgMsg.setId(new DevicePb.DeviceConfig.ID())
  }
  const id = devCfgMsg.getId()
  if (!id.getDriverId()) {
    id.setDriverId(devCfgInfo.driverId)
  }
  if (!id.getConfigTypeId()) {
    id.setConfigTypeId(devCfgInfo.configTypeId)
  }

  // config-d ID added by API method
  return apiDevice.addDeviceConfig(devCfgMsg)
}

/**
 * Updates an existing device confgi
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.deviceType is a [Device Type]{@link module:store/devices.SUPPORTED_DEVICES}
 * @param {DeviceModel} params.devCfgMsg has to be a plain or proto object of `DevicePb.DeviceConfig`
 *
 * @return {Promise}
 */
export async function updateDeviceConfig({ commit }, { deviceType, devCfgMsg }) {
  commit('SET_DEVICE_TYPE', deviceType)

  return apiDevice.updateDeviceConfig(devCfgMsg)
}

/**
 * Deletes a device config
 *
 * @function
 *
 * @param {string} configId
 *
 * @return {Promise}
 */
export async function deleteDeviceConfig({ commit }, configId) {
  return apiDevice.deleteDeviceConfig(configId)
}

// ---
// config status actions
// ---

// private
const parseEnergyManagerDeviceStatus = (emsDeviceStatus) => {
  let info
  let configId
  let serialNumber
  if (emsDeviceStatus.hasInfo()) {
    info = emsDeviceStatus.getInfo().toObject()

    configId = info.configId
    delete info.configId

    serialNumber = info.serialNumber
    delete info.serialNumber
  } else {
    info = {}
  }
  if (!configId) {
    logger.warn('Received an EnergyManagerDeviceStatus with missing configId,', emsDeviceStatus.toObject())
  }
  const health = getEnumStr(emsDeviceStatus.getHealth(), EnergyManagerDeviceStatus.EnergyManagerDeviceHealth)
  // TODO: parse Timestamp: https://developers.google.com/protocol-buffers/docs/reference/google.protobuf#timestamp
  // info.lastUpdate = emsDeviceStatus.getLastUpdate()

  return new DeviceStatus({
    configId,
    serialNumber,
    health,
    info
  })
}

/**
 * Fetches ALL device (config) statuses.
 *
 * @function
 *
 * @return {Promise}
 */
export async function fetchDeviceStatuses({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_STATUSES')
    if (msg.health && !msg.health.alive) {
      logger.warn('The EMS is NOT reported as alive. Device status information cannot be obtained.')
    }

    msg.getRegisteredDevicesList().forEach((status) => {
      commit('ADD_STATUS', parseEnergyManagerDeviceStatus(status))
    })

    return msg
  }

  return apiEms.getEnergyManagerStatus().then(doCommit)
}

/**
 * Start/stop stream for List of EnergyManagerDeviceStatus.
 * Received list of EnergyManagerDeviceStatus is created/updated in the store.
 *
 * @function
 *
 * @param {object} params
 * @param {function} onMsg is a callback on message receive after commit(s)
 * @param {boolean} stop is a flag whether to stop the stream or not (defaults to false).
 *
 */
export async function streamDeviceStatuses({ commit }, { onMsg, stop } = { stop: false }) {
  const onMessage = (msg) => {
    msg.getRegisteredDevicesList().forEach((status) => {
      // add also updates
      commit('ADD_STATUS', parseEnergyManagerDeviceStatus(status))
    })
    if (onMsg) {
      onMsg(msg)
    }
  }

  let call = streamState.status.all
  call = startStopStream({
    call,
    stop,
    starter: () => apiEms.streamEnergyManagerStatus({ onMessage }),
    name: 'streamDeviceStatuses'
  })
  streamState.status.all = call

  return call
}

/**
 * GET a EnergyMangerDeviceStatus
 *
 * @function
 *
 * @param {string} configId for which the status is fetched.
 *
 * @return {Promise}
 */
export async function getDeviceStatus({ commit }, configId) {
  const doCommit = (msg) => {
    commit('CLEAR_STATUSES')
    if (msg.getStatus()) {
      commit('ADD_STATUS', parseEnergyManagerDeviceStatus(msg.getStatus()))
    }

    return msg
  }

  return apiEms.getEnergyManagerDeviceStatus({ configId }).then(doCommit)
}

/**
 * Start/stop stream for EnergyManagerDeviceStatus.
 * Received EnergyManagerDeviceStatus is created/updated in the store.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.configId is the config ID for which the status is streamed (required).
 * @param {function} onMsg is a callback on message receive after commit(s)
 * @param {boolean} stop is a flag whether to stop the stream or not (defaults to false).
 *
 */
export async function streamDeviceStatus({ commit }, { configId, onMsg, stop } = { stop: false }) {
  const onMessage = (msg) => {
    if (msg.getStatus()) {
      // add also updates
      commit('ADD_STATUS', parseEnergyManagerDeviceStatus(msg.getStatus()))
      if (onMsg) {
        onMsg(msg)
      }
    }
  }

  let call = streamState.status[configId]
  call = startStopStream({
    call,
    stop,
    starter: () => apiEms.streamEnergyManagerDeviceStatus({ configId }, { onMessage }),
    name: 'streamDeviceStatus'
  })
  streamState.status[configId] = call

  return call
}

// ---
// wrapper actions
// ---

/**
 * Performs
 * [fetchDeviceConfigsByDeviceType]{@link module:store/device-config-actions.fetchDeviceConfigsByDeviceType} and
 * [fetchDeviceStatuses]{@link module:store/device-config-actions.fetchDeviceStatuses}
 * asynchroneously.
 *
 * @function
 *
 * @param {string} deviceType is the device type for which the configs are fetched.
 *
 * @return {Promise}
 */
export async function fetchDeviceConfigsByDeviceTypeWithStatus({ dispatch }, deviceType) {
  // currently fetchDeviceStatuses fetches ALL statuses (unfiltered by deviceType)
  /* prettier-ignore */
  return Promise.all([
    dispatch('fetchDeviceConfigsByDeviceType', deviceType),
    dispatch('fetchDeviceStatuses')
  ])
}

/**
 * Performs
 * [getDeviceConfig]{@link module:store/device-config-actions.getDeviceConfig} and
 * [getDeviceStatus]{@link module:store/device-config-actions.getDeviceStatus}
 * asynchroneously.
 *
 * @function
 *
 * @param {string} configId for which the config and status are requested.
 *
 * @return {Promise}
 */
export async function getDeviceConfigWithStatus({ dispatch, getters }, configId) {
  /* prettier-ignore */
  return Promise.all([
    dispatch('getDeviceConfig', configId),
    dispatch('getDeviceStatus', configId)
  ])
}
