/**
 * @module view-helper/device-config
 */

import { generateTreeSelectOptionsFromMap } from '../form-helpers'

export const getModelLabelFromId = (deviceModels) => {
  return (modalId) => {
    return deviceModels.find((modal) => modal.id === parseInt(modalId)).name
  }
}
export function generateDeviceModelsOptsForTreeSelect(deviceModels) {
  const opts = []
  for (const deviceModel of deviceModels) {
    opts[deviceModel.id] = deviceModel.name.split(' ')[0]
  }
  const deviceModalOptions = generateTreeSelectOptionsFromMap({
    valuesToCategories: opts,
    valueLabelFunction: getModelLabelFromId(deviceModels)
  })

  return deviceModalOptions
}
/**
 * Maps a device-type, e.g. `inverter`, to an icon for this device.
 *
 * @function
 *
 * @param {string} deviceType
 *
 * @return {string} the CoreUI icon identifier
 */
export function deviceTypeToIcon(deviceType) {
  switch (deviceType) {
    case 'meter':
      return 'cil-av-timer'
    case 'inverter':
      return 'cil-remote-settings'
    case 'wallbox':
      return 'cil-input-power'
    case 'relay':
      return 'cil-swap-horizontal-circle'
    case 'container':
      return 'cil-box-alt'
    case 'eza':
      return 'cil-input-component'
    default:
      return 'cil-settings-alt'
  }
}
