<template>
  <CCard class="break-lines card-accent-primary">
    <CCardHeader> {{ type }} </CCardHeader>
    <CCardBody v-if="isLoading">
      <vue-loading type="bars" :style="loadingIndicatorStyle" />
    </CCardBody>
    <CCardBody v-else-if="hasError">
      <div id="svg-outer">
        <!-- prettier-ignore -->
        <svg
          id="svg-inner"
          x="0"
          y="0"
          width="800"
          height="400"
          xmlns="http://www.w3.org/2000/svg"
        >
          <CIcon name="cilBan" :height="300" />
          <text
            x="50%"
            y="80%"
            style="font-size: 12px; fill: #ff0000;"
            dominant-baseline="hanging"
            text-anchor="middle"
          >
            <tspan>
              {{ $t('api.errors.fetch') }}
            </tspan>
          </text>
        </svg>
      </div>
    </CCardBody>
    <CCardBody v-else>
      <CRow v-if="descriptionAsArray.length">
        <CCol v-for="(block, index) in descriptionAsArray" :key="index" md="6" xl="4">
          <ApxInterfaceInformationCard :card-infos="block" />
        </CCol>
      </CRow>
      <pre v-else class="pb-3">{{ description }}</pre>
    </CCardBody>
    <!-- prettier-ignore -->
    <CCardBody v-if="allowReload" align="right">
      <CButton color="primary" variant="outline" @click="$emit('reload')">
        {{ $t('main.reloadBtn') }}
      </CButton>
    </CCardBody>
  </CCard>
</template>

<script>
import ApxInterfaceInformationCard from './apx-interface-information-card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ApxInterfaceCard',
  components: {
    ApxInterfaceInformationCard
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    allowReload: {
      type: Boolean,
      default: false
    },
    descriptionAsArray: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('coreui', { isDark: 'darkMode' }),
    loadingIndicatorStyle() {
      return {
        fill: 'var(--primary)',
        width: '150px',
        height: '150px'
      }
    }
  }
}
</script>

<style scoped>
.break-lines {
  white-space: pre-line;
}

.break-lines-keep-tabs {
  white-space: pre-wrap;
}

#svg-outer {
  display: flex;
  align-items: center;
  background-color: lightgray;
  height: 100%;
}

#svg-inner {
  margin: 0 auto;
  display: block;
}
</style>
