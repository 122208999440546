<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm class="mt-4" @submit.prevent>
      <!-- BEGIN: Upper Threshold  -->
      <CInput
        type="number"
        custom="true"
        step="100"
        :is-valid="$v.upperThreshold.$dirty ? !$v.upperThreshold.$error : null"
        :invalid-feedback="invalidFeedbackFor('upperThreshold')"
        :horizontal="styling.horizontal"
        :label="$t(`${tBasePath}.upperThreshold.label`) + $t(`${tBasePath}.upperThreshold.unit`)"
        :placeholder="$t(`${tBasePath}.upperThreshold.placeholder`)"
        :description="upperThresholdDescription"
        :value="upperThreshold"
        @change="onChangeUpperThreshold"
      />
      <!-- END: Upper Threshold   -->
      <!-- BEGIN: Lower Threshold  -->
      <CInput
        type="number"
        custom="true"
        step="100"
        :is-valid="$v.lowerThreshold.$dirty ? !$v.lowerThreshold.$error : null"
        :invalid-feedback="invalidFeedbackFor('lowerThreshold')"
        :horizontal="styling.horizontal"
        :label="$t(`${tBasePath}.lowerThreshold.label`) + $t(`${tBasePath}.lowerThreshold.unit`)"
        :placeholder="$t(`${tBasePath}.lowerThreshold.placeholder`)"
        :description="lowerThresholdDescription"
        :value="lowerThreshold"
        @change="onChangeLowerThreshold"
      />
      <!-- END: Lower Threshold -->
      <!-- BEGIN: Negation-Switch: inverse of `this.negate`  -->
      <CRow role="group" class="form-group form-row">
        <label :class="'col-form-label ' + styling.horizontal.label">
          {{ $t(`${tBasePath}.negateSwitch.label`) }}
        </label>
        <div :class="styling.horizontal.input">
          <CSwitch
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :checked="!negate"
            @update:checked="negate = !$event"
          />
          <span class="ml-3 align-top d-inline-block">
            {{ negateSwitchLabel }}
          </span>
          <small class="ml-1 mt-1 align-top text-muted d-none d-sm-inline-block">
            {{ negateSwitchLabelDescription }}
          </small>
          <small class="text-muted form-text w-100">
            {{ $t(`${tBasePath}.negateSwitch.description`) }}
          </small>
        </div>
      </CRow>
      <!-- END: Negation-Switch  -->
      <!-- BEGIN: Tolerance Window -->
      <CInput
        type="number"
        custom="true"
        step="10"
        :min="0"
        :description="$t(`${tBasePath}.conditionWindowSeconds.description`)"
        :is-valid="$v.conditionWindowSeconds.$dirty ? !$v.conditionWindowSeconds.$error : null"
        :invalid-feedback="invalidFeedbackFor('conditionWindowSeconds')"
        :horizontal="styling.horizontal"
        :label="$t(`${tBasePath}.conditionWindowSeconds.label`) + $t(`${tBasePath}.conditionWindowSeconds.unit`)"
        :placeholder="$t(`${tBasePath}.conditionWindowSeconds.placeholder`)"
        :value.sync="$v.conditionWindowSeconds.$model"
      />
      <!-- END: Tolerance Window   -->
      <!-- BEGIN: Position in Topology -->
      <CRow form>
        <CCol class="custom-form-separator" sm="12"></CCol>
        <!-- BEGIN: Position in Topology: Grid - Hidden on Small Devices -->
        <CCol xs="7" class="offset-xs-0 offset-sm-5 pl-1 d-none d-sm-block">
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol tag="label" sm="5" class="align-items-center text-muted d-none d-sm-block">
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <!-- END: Position in Topology: Grid - Hidden on Small Devices -->
        <CCol tag="label" sm="5" class="custom-form-label d-flex align-items-start" :for="safeId">
          {{ $t(`${tBasePath}.positionInTopology.label`) }}
        </CCol>
        <!-- BEGIN: Position in Topology: Grid - Visible on Small Devices -->
        <CCol xs="7" class="pl-1 d-block d-sm-none">
          <CSwitch
            class="align-self-center"
            color="primary"
            size="sm"
            v-bind="switchLabelIcon"
            :checked.sync="isGrid"
          />
        </CCol>
        <CCol tag="label" xs="5" class="align-items-center text-muted d-block d-sm-none" :for="safeId">
          {{ $t(`${tBasePath}.positionInTopology.gridEnabledLabel`) }}
        </CCol>
        <!-- END: Position in Topology: Grid - Visible on Small Devices -->
        <!-- BEGIN: Position in Topology: Device Selection -->
        <CCol v-if="!isGrid" sm="7">
          <TreeSelect
            :id="safeId"
            :value="positionInTopologySelected"
            disable-branch-nodes
            multiple
            :placeholder="$t(`${tBasePath}.positionInTopology.placeholder`)"
            :options="positionInTopologyOpts"
            @input="onUpdatePositionInTopology"
          />
        </CCol>
        <CCol v-if="!isGrid" tag="label" xs="7" class="offset-xs-0 offset-sm-5 pl-1">
          <small class="text-muted form-text w-100">
            {{ $t(`${tBasePath}.positionInTopology.description`) }}
          </small>
        </CCol>
        <!-- END: Position in Topology: Device Selection -->
        <!-- END: Position in Topology -->
      </CRow>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeUid } from '@coreui/utils/src'

import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import { esDigitalOutputSwitchConditionFormValidators } from '@/validations/ems-es-form-validators'
import {
  generatePhysicalDeviceOptsForTreeSelect,
  getPhysicalDevicePreselection,
  isGridEnabled,
  makePhysicalDevicesList
} from '@/view-helper/ems/ems-topology-helper'
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'EsDigitalSwitchForm',
  components: {
    EsLayoutForm,
    TreeSelect
  },
  props,
  data() {
    return {
      conditionWindowSeconds: null,
      isGrid: null,
      negate: null,
      upperThreshold: null,
      lowerThreshold: null,
      positionInTopologySelected: [],
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['existingPhysicalDevices']),
    positionInTopologyOpts() {
      return generatePhysicalDeviceOptsForTreeSelect(this.existingPhysicalDevices)
    },
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v[field][condition]
        }
        const base = `${this.tBasePath}.${field}.validation.invalidFeedback`
        const conditions = ['required']

        if (field === 'upperThreshold') {
          conditions.push('greaterThanLowerThreshold')
        } else if (field === 'lowerThreshold') {
          conditions.push('lesserThanUpperThreshold')
        } else {
          conditions.push('nonNegative')
        }

        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    },
    safeId() {
      return makeUid()
    },
    upperThresholdDescription() {
      let label
      if (this.negate) {
        label = this.$t(`${this.tBasePath}.upperThreshold.descriptionNegateTrue`)
      } else {
        label = this.$t(`${this.tBasePath}.upperThreshold.descriptionNegateFalse`)
      }

      return `${label} ${this.$t(`${this.tBasePath}.upperThreshold.description`)}`
    },
    lowerThresholdDescription() {
      let label
      if (this.negate) {
        label = this.$t(`${this.tBasePath}.lowerThreshold.descriptionNegateTrue`)
      } else {
        label = this.$t(`${this.tBasePath}.lowerThreshold.descriptionNegateFalse`)
      }

      return `${label} ${this.$t(`${this.tBasePath}.lowerThreshold.description`)}`
    },
    negateSwitchLabel() {
      if (this.negate) {
        return this.$t('main.off')
      } else {
        return this.$t('main.on')
      }
    },
    negateSwitchLabelDescription() {
      let desc
      if (this.negate) {
        desc = this.$t(`${this.tBasePath}.negateSwitch.labelNegateTrue`)
      } else {
        desc = this.$t(`${this.tBasePath}.negateSwitch.labelNegateFalse`)
      }

      return `(${desc})`
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-md-5 custom-form-label',
        input: 'col-12 col-md-7'
      }
    }
    this.switchLabelIcon = {
      labelOn: '\u2713',
      labelOff: '\u2715'
    }
    this.tBasePath = 'ems.energyService.config.form.digitalOutputSwitchCondition'
    this.init()
  },
  methods: {
    init() {
      this.negate = this.initValues.negate
      this.conditionWindowSeconds = this.initValues.conditionWindowSeconds
      this.upperThreshold = this.initValues.onThreshold
      this.lowerThreshold = this.initValues.onThreshold - this.initValues.holdDelta
      const pit = this.initValues.powerAtPositionInTopology
      this.isGrid = isGridEnabled(pit)

      if (pit && pit.physicalDevicesList) {
        this.positionInTopologySelected = getPhysicalDevicePreselection(pit.physicalDevicesList)
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const physicalDevicesList = makePhysicalDevicesList(this.positionInTopologySelected, this.isGrid)
      const esParams = {
        onThreshold: parseFloat(this.upperThreshold),
        holdDelta: parseFloat(this.upperThreshold - this.lowerThreshold),
        negate: this.negate,
        conditionWindowSeconds: parseFloat(this.conditionWindowSeconds),
        powerAtPositionInTopology: { physicalDevicesList }
      }

      this.$emit('submit', { esParams })
    },
    onUpdatePositionInTopology(selection) {
      this.positionInTopologySelected = selection
    },
    onChangeLowerThreshold(v) {
      this.$v.lowerThreshold.$model = parseFloat(v)
    },
    onChangeUpperThreshold(v) {
      this.$v.upperThreshold.$model = parseFloat(v)
    }
  },
  validations: esDigitalOutputSwitchConditionFormValidators
}
</script>
