var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EsLayoutForm',{attrs:{"show-failure-alert":_vm.showFailureAlert},on:{"update:showFailureAlert":function($event){_vm.showFailureAlert=$event},"update:show-failure-alert":function($event){_vm.showFailureAlert=$event},"cancel":function($event){return _vm.$emit('cancel')},"reset":_vm.init,"submit":_vm.onSubmit}},[_c('CForm',{on:{"submit":function($event){$event.preventDefault();}}},[_c('CRow',{attrs:{"form":""}},[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"value":_vm.preselection,"options":_vm.preselectionOpts,"horizontal":{ label: 'col-12 col-md-5 custom-form-label', input: 'col-12 col-md-7' },"label":_vm.$t(`${_vm.tBasePath}.shortcuts.label`),"description":_vm.$t(`${_vm.tBasePath}.shortcuts.description`)},on:{"update:value":_vm.onSelectPreselect}})],1),(_vm.preselection !== 'EVAL')?_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"type":"number","custom":"true","step":"100","max":_vm.maxPowerWatts,"min":_vm.minPowerWatts,"disabled":_vm.isDisabled,"description":_vm.powerWattsDescription,"is-valid":!_vm.isDisabled && _vm.$v.powerWatts.$dirty ? !_vm.$v.powerWatts.$error : null,"invalid-feedback":_vm.invalidFeedbackForPowerWatts,"horizontal":{ label: 'col-12 col-md-5 custom-form-label', input: 'col-12 col-md-7' },"label":_vm.$t(`${_vm.tBasePath}.powerWatts.label`) + _vm.$t(`${_vm.tBasePath}.powerWatts.unit`),"placeholder":_vm.powerWattsPlaceholder,"value":_vm.$v.powerWatts.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.powerWatts, "$model", $event)}}})],1):_vm._e(),(_vm.preselection === 'EVAL')?_c('EvalTree'):_vm._e(),_c('CCol',{staticClass:"custom-form-separator",attrs:{"sm":"12"}}),_c('CCol',{class:{
          'offset-xs-0': true,
          'offset-sm-5': true,
          'pl-1': true,
          'd-none d-sm-block': true
        },attrs:{"xs":"7"}},[_c('CSwitch',_vm._b({staticClass:"align-self-center",attrs:{"color":"primary","size":"sm","disabled":_vm.isDisabled,"checked":_vm.isGrid},on:{"update:checked":function($event){_vm.isGrid=$event}}},'CSwitch',_vm.switchLabelIcon,false))],1),_c('CCol',{class:{
          'align-items-center': true,
          'text-muted': true,
          'd-none d-sm-block': true
        },attrs:{"tag":"label","sm":"5","for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.tBasePath}.positionInTopology.gridEnabledLabel`))+" ")]),_c('CCol',{class:{
          'custom-form-label': true,
          'd-flex': true,
          'align-items-start': true
        },attrs:{"tag":"label","sm":"5","for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.tBasePath}.positionInTopology.label`))+" ")]),_c('CCol',{class:{
          'pl-1': true,
          'd-block d-sm-none': true
        },attrs:{"xs":"7"}},[_c('CSwitch',_vm._b({staticClass:"align-self-center",attrs:{"color":"primary","size":"sm","disabled":_vm.isDisabled,"checked":_vm.isGrid},on:{"update:checked":function($event){_vm.isGrid=$event}}},'CSwitch',_vm.switchLabelIcon,false))],1),_c('CCol',{class:{
          'align-items-center': true,
          'text-muted': true,
          'd-block d-sm-none': true
        },attrs:{"tag":"label","xs":"5","for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.tBasePath}.positionInTopology.gridEnabledLabel`))+" ")]),(!_vm.isGrid)?_c('CCol',{attrs:{"sm":"7"}},[_c('TreeSelect',{attrs:{"id":_vm.safeId,"value":_vm.positionInTopologySelected,"disable-branch-nodes":"","multiple":"","placeholder":_vm.$t(`${_vm.tBasePath}.positionInTopology.placeholder`),"options":_vm.positionInTopologyOpts},on:{"input":_vm.onUpdatePositionInTopology}})],1):_vm._e(),(!_vm.isGrid)?_c('CCol',{class:{
          'offset-xs-0': true,
          'offset-sm-5': true,
          'pl-1': true
        },attrs:{"tag":"label","xs":"7"}},[_c('small',{class:{
            'text-muted': true,
            'form-text': true,
            'w-100': true
          }},[_vm._v(" "+_vm._s(_vm.posInTopoDescription)+" ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }