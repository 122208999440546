/**
 *
 * @module store/ems-sensors
 */

import Vue from 'vue'

import * as apiSensor from '@/api/ems/sensor'
import { generateTreeSelectOptionsFromMap, buildEvalTreeLabelTranslation } from '@/view-helper/form-helpers'

/**
 * A state for the sensors.
 * Currently only a lookup from `name` -> `{}`
 */
const state = () => {
  return {}
}

// stringes from ems/src/topology/sensor-names.cc string currently must be kept synchron by hand

const BASIC_DEVICES = ['Battery', 'Chp', 'ECar', 'FuelCell', 'Heatpump', 'HeatingElement', 'Pv', 'User', 'Grid']
const IEC_NODE_PREFIXES = ['bat', 'ch', 'chp', 'ctl', 'elz', 'fc', 'he', 'hp', 'inv', 'io', 'mtr', 'pv', 'sen', 'vpp']

const orPatternBasicDevices = `(${BASIC_DEVICES.join('|')})`
const orPatternIecNodes = `(${IEC_NODE_PREFIXES.join('|')})`
const SENSORNAMES_BLACKLIST = /^.*_1min$/

// prettier-ignore
export const BASIC_SENSORNAMES_WHITELIST = new RegExp(
  `^(${[
    `(${orPatternBasicDevices}(Power|Temperature|SoC)_[0-9]+)|`,
    '(.*_Power)',
    '(.*_SoC)',
    '(.*_Temperature)',
    '(.*_vpp_grd_bat_setpoint_watts)'
    ].join('|')
  })$`
)

export const BASIC_SENSORNAMES = new RegExp(`^(${orPatternBasicDevices}(Power|Temperature|SoC)_[0-9]+)`)

// prettier-ignore
export const ALL_SENSOR_POSTFIX = new RegExp(
  [
    `_${orPatternIecNodes}_[0-9]+.*`,
    '_health',
    '(Power|Temperature|SoC)_[0-9]+'
  ].join('|')
)

export const IEC_ID_SENSORNAMES = new RegExp(
  `((_[0-9]*)?_${orPatternIecNodes}_[0-9]+).*_(vpp_grd_bat_setpoint_watts|Power|Temperature|SoC)$`
)

/**
 * Getters for the EMS sensor store.
 */
const getters = {
  getSensorNames: (state) => {
    return (showAll) => {
      const sensors = Object.keys(state).sort()
      if (showAll) {
        return sensors
      }
      return sensors.filter((item) => !SENSORNAMES_BLACKLIST.test(item) && BASIC_SENSORNAMES_WHITELIST.test(item))
    }
  },
  basicSensorNamesToDeviceNames: (state) => {
    const sensors = Object.keys(state).sort()
    const sensorNamesToDevices = {}

    const basicSensorNames = sensors.filter(
      (sensor) => !SENSORNAMES_BLACKLIST.test(sensor) && BASIC_SENSORNAMES.test(sensor)
    )

    for (const sensorName of basicSensorNames) {
      const countId = sensorName.match(/_[0-9]+/g)[0]
      const deviceName = sensorName.substring(0, sensorName.length - countId.length)
      sensorNamesToDevices[sensorName] = deviceName
    }

    return sensorNamesToDevices
  },
  sensorNamesToDeviceNames: (state) => {
    const sensors = Object.keys(state).sort()
    const sensorNamesToDevices = {}

    const sensorNames = sensors.filter((item) => !SENSORNAMES_BLACKLIST.test(item))

    for (const sensorName of sensorNames) {
      if (sensorName.match(ALL_SENSOR_POSTFIX)) {
        const serialNumber = sensorName.match(ALL_SENSOR_POSTFIX)[0]
        const deviceName = sensorName.substring(0, sensorName.length - serialNumber.length)
        sensorNamesToDevices[sensorName] = deviceName
      } else {
        sensorNamesToDevices[sensorName] = sensorName
      }
    }

    return sensorNamesToDevices
  },
  iecIdSensorNamesToDeviceNames: (state) => {
    const sensors = Object.keys(state).sort()

    const sensorNamesToDevices = {}

    const iecIdSensorNames = sensors.filter(
      (sensor) => !SENSORNAMES_BLACKLIST.test(sensor) && IEC_ID_SENSORNAMES.test(sensor)
    )

    for (const sensorName of iecIdSensorNames) {
      const serialNumber = sensorName.match(IEC_ID_SENSORNAMES)[0]
      const deviceName = sensorName.substring(0, sensorName.length - serialNumber.length)
      sensorNamesToDevices[sensorName] = deviceName
    }

    return sensorNamesToDevices
  },
  basicSensorNameOptionsForTreeSelect: (_state, getter) => {
    const basicSensorNames = generateTreeSelectOptionsFromMap({
      valuesToCategories: getter.basicSensorNamesToDeviceNames,
      categoryLabelFunction: buildEvalTreeLabelTranslation('sensors')
    })
    const sensorNamesWithIecIds = generateTreeSelectOptionsFromMap({
      valuesToCategories: getter.iecIdSensorNamesToDeviceNames
    })
    const treeOptions = basicSensorNames.concat(sensorNamesWithIecIds)

    return treeOptions
  },
  allSensorNameOptionsForTreeSelect: (_state, getter) => {
    const allSensorNames = generateTreeSelectOptionsFromMap({
      valuesToCategories: getter.sensorNamesToDeviceNames,
      categoryLabelFunction: buildEvalTreeLabelTranslation('sensors')
    })

    return allSensorNames
  }
}

const mutations = {
  INIT_SENSOR_BY_NAME(state, { name }) {
    if (!name) {
      throw new TypeError('Paramter "name" cannot be blank.')
    }

    Vue.set(state, name, {})
  },
  CLEAR_ALL(state) {
    for (const key in state) {
      Vue.delete(state, key)
    }
  }
}

/**
 * Action (API call) to fetch the list of EMS timeserieses.
 * And then to fetch the timestamp values for each (optionally).
 *
 * @function
 *
 * @param params
 * @param {boolean} params.clear defines if before init, the store should be cleared
 *
 * @return {promise} which resolves to the returned message of `ems.controller.timeseries_list`
 */
async function sensorInit({ commit, dispatch }, { clear = false } = {}) {
  return apiSensor.sensorList().then((msg) => {
    if (clear) {
      commit('CLEAR_ALL')
    }
    msg.getNamesList().forEach((name) => {
      commit('INIT_SENSOR_BY_NAME', {
        name: name
      })
    })

    return msg
  })
}

const actions = {
  sensorInit
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
