/** @module */

import { createUnaryCall, createPollingUnaryCall } from '@/grpc'
import * as EmsPb from '@/../lib/proto_js/ext/amperix/ems_pb'
import { EnergyManagerService } from '@/../lib/proto_js/ext/amperix/ems_pb_service'
import { evalServiceError, handleErrorDeviceNotFount } from '@/api/error-handling'

/**
 * gRPC EnergyManagerService call for GetEnergyManagerStatus
 *
 * @function
 *
 * @return {promise}
 */
export function getEnergyManagerStatus() {
  /* prettier-ignore */
  const call = createUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerStatus',
    payload: new EmsPb.GetEnergyManagerStatusRequest()
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC EnergyManagerService stream (currently polling) to receive the EnergyManagerDeviceStatus(es)
 *
 * @function
 *
 * @param {object} callbacks
 * @param {function} callbacks.onMessage
 * @param {function} callbacks.onError
 *
 * @return {PollingUnaryCall}
 */
export function streamEnergyManagerStatus({ onMessage, onError }) {
  /* prettier-ignore */
  const call = createPollingUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerStatus',
    payload: new EmsPb.GetEnergyManagerStatusRequest(),
    onMessage,
    onError
  })

  call.perform()

  return call
}

/**
 * gRPC EnergyManagerService call for GetEnergyManagerDeviceStatus
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.configId is the config-ID for which the device status is requested.
 *
 * @return {promise}
 */
export function getEnergyManagerDeviceStatus({ configId }) {
  /* prettier-ignore */
  const call = createUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceStatus',
    payload: new EmsPb.GetEnergyManagerDeviceStatusRequest([
      null,
      configId
    ])
  })

  return call.perform().then(handleErrorDeviceNotFount).then(evalServiceError)
}

/**
 * gRPC EnergyManagerService stream (currently polling) to receive the EnergyManagerDeviceStatus
 *
 * @function
 *
 * @param {object} params see [getEnergyManagerDeviceStatus]{@link module:src/api/amperix/ems.getEnergyManagerDeviceStatus}
 * @param {object} callbacks for success and error (optional)
 * @param {function} callbacks.onMessage
 * @param {function} callbacks.onError
 *
 * @return {PollingUnaryCall}
 */
export function streamEnergyManagerDeviceStatus({ configId }, { onMessage, onError }) {
  /* prettier-ignore */
  const call = createPollingUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceStatus',
    payload: new EmsPb.GetEnergyManagerDeviceStatusRequest([
      null,
      configId
    ]),
    onMessage,
    onError
  })

  call.perform()

  return call
}

// private
function buildGetEnergyManagerDeviceValuesReq({ configId, deviceName }) {
  const req = new EmsPb.GetEnergyManagerDeviceValuesRequest()
  if (configId) {
    req.setConfigId(configId)
  } else if (deviceName) {
    req.setName(deviceName)
  } else {
    throw new Error('Missing arguments, configId or deviceName has to be specified')
  }

  return req
}

/**
 * gRPC EnergyManagerService call for GetEnergyManagerDeviceValues.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.configId is the config-ID for which the device values are requested (required if deviceName is empty).
 * @param {string} params.deviceName is the `EnergyManagerDeviceStatus.Info.name` for which the device values are requested (required if configId is empty).
 *
 * @return {promise}
 */
export function getEnergyManagerDeviceValues({ configId, deviceName }) {
  /* prettier-ignore */
  const call = createUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceValues',
    payload: buildGetEnergyManagerDeviceValuesReq({ configId, deviceName })
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC EnergyManagerService stream (currently polling) to receive the EnergyManagerDeviceValues.
 *
 * @function
 *
 * @param {object} params see [getEnergyManagerDeviceValues]{@link module:src/api/amperix/ems.getEnergyManagerDeviceValues}
 * @param {object} callbacks for success and error (optional)
 * @param {function} callbacks.onMessage
 * @param {function} callbacks.onError
 *
 * @return {PollingUnaryCall}
 */
export function streamEnergyManagerDeviceValues({ configId, deviceName }, { onMessage, onError }) {
  /* prettier-ignore */
  const call = createPollingUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceValues',
    payload: buildGetEnergyManagerDeviceValuesReq({ configId, deviceName }),
    onMessage,
    onError
  })

  call.perform()

  return call
}

/**
 * gRPC EnergyManagerService call for GetEnergyMangerDeviceValue
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.reference is the reference string used to identify a data-attribute/object in the IEC tree.
 *
 * @return {promise}
 */
export function getEnergyManagerDeviceValue({ reference }) {
  if (!reference) {
    throw new Error('Missing argument, reference has to be specified')
  }

  /* prettier-ignore */
  const call = createUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceValue',
    payload: new EmsPb.GetEnergyManagerDeviceValueRequest([reference])
  })

  return call.perform().then(evalServiceError)
}

/**
 * gRPC EnergyManagerService stream (currently polling) to receive an EnergyMangerDeviceValue.
 *
 * @function
 *
 * @param {object} params see [getEnergyManagerDeviceValue]{@link module:src/api/amperix/ems.getEnergyManagerDeviceValue}
 * @param {function} callbacks.onMessage
 * @param {function} callbacks.onError
 *
 * @return {PollingUnaryCall}
 */
export function streamEnergyManagerDeviceValue({ reference }, { onMessage, onError }) {
  if (!reference) {
    throw new Error('Missing argument, reference has to be specified')
  }

  /* prettier-ignore */
  const call = createPollingUnaryCall({
    service: EnergyManagerService,
    method: 'GetEnergyManagerDeviceValue',
    payload: new EmsPb.GetEnergyManagerDeviceValueRequest([reference]),
    onMessage,
    onError
  })

  call.perform()

  return call
}

/**
 * gRPC EnergyManagerService call for Operate
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.reference is the reference string used to identify the data-attribute/object in the IEC tree.
 * @param {boolean|number|string} params.value is the value to be set by the operate call.
 *
 * @return {promise}
 */
export function operate({ reference, value }) {
  if (!reference) {
    throw new Error('Missing argument, reference has to be specified')
  }

  if (typeof value === 'undefined') {
    throw new Error('Missing argument, value has to be specified')
  }

  const val = new Array(3)
  let i
  switch (typeof value) {
    case 'boolean':
      i = 0
      break
    case 'number':
      i = 1
      break
    case 'string':
      i = 2
      break
    default:
      throw new Error('Operate RPC on provided value is not supported.')
  }
  val[i] = value

  /* prettier-ignore */
  const call = createUnaryCall({
    service: EnergyManagerService,
    method: 'Operate',
    payload: new EmsPb.OperateRequest([reference, val])
  })

  return call.perform().then(evalServiceError)
}
