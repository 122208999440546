/** @module */

import {
  PeakObserverName as PeakObserverNamePb,
  PeakObserverSetPeakRequest as PeakObserverSetPeakRequestPb,
  PeakObserverSetIntervalRequest as PeakObserverSetIntervalRequestPb
} from '@/../lib/proto_js/ext/ems/controller/controller_pb'
import { controllerRpc } from '@/api/ems/controller'

/**
 * The default peak observer name.
 * Hard coded in the EMS.
 * Auto created by the EMS.
 */
export const EMS_DEFAULT_PEAK_OBSERVER_NAME = 'GenericSystem/DCCT.peak'

/**
 * gRPC unary call to fetch the peak observer params.
 * Successful response will provide the `ems.controller.PeakObserverState`
 *
 * @function
 *
 * @param {string} (unused) EMS ignores this value anyway
 *
 * @return {promise}
 */
export function getPeakObserverParams() {
  const req = new PeakObserverNamePb([''])

  return controllerRpc('peak_observer_list_data', req)
}

/**
 * gRPC unary call to set the peak observer interval
 *
 * @function
 *
 * @param {object} params
 * @param {number} params.intervalSeconds (required) is the new RLM peakshaving interval.
 * @param {string} params.name (unused) EMS ignores this value anyway
 *
 * @return {promise}
 */
export function setPeakObserverInterval({ intervalSeconds }) {
  const req = new PeakObserverSetIntervalRequestPb(['', intervalSeconds])

  return controllerRpc('peak_observer_set_interval', req)
}

/**
 * gRPC unary call to set the peak observer peak
 *
 * @function
 *
 * @param {object} params
 * @param {number} params.peakWatts (required) is the new power peak in Watts
 * @param {string} params.name (unused) EMS ignores this value anyway
 *
 * @return {promise}
 */
export function setPeakObserverPeak({ peakWatts }) {
  const req = new PeakObserverSetPeakRequestPb(['', peakWatts])

  return controllerRpc('peak_observer_set_peak', req)
}
