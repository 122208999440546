<template>
  <CRow>
    <CCol md="6" xl="4">
      <apx-interface-card
        key="ioDigitalInput"
        :description="ioDigitalInput.rawInfo"
        :is-loading="isLoading.ioDigitalInput"
        :has-error="hasError.ioDigitalInput"
        :type="$t('interfaces.io.digitalInputs')"
      />
    </CCol>
    <CCol md="6" xl="4">
      <apx-interface-card
        key="ioDigitalOutput"
        :description="ioDigitalOutput.rawInfo"
        :is-loading="isLoading.ioDigitalOutput"
        :has-error="hasError.ioDigitalOutput"
        :type="$t('interfaces.io.digitalOutputs')"
      />
    </CCol>
    <CCol md="6" xl="4">
      <apx-interface-card
        key="ioDryContactInput"
        :description="ioDryContactInput.rawInfo"
        :is-loading="isLoading.ioDryContactInput"
        :has-error="hasError.ioDryContactInput"
        :type="$t('interfaces.io.digitalDryContact')"
      />
    </CCol>
  </CRow>
</template>

<script>
import ApxInterfaceCard from '@/components/apx-interface-card'
import { mapGetters } from 'vuex'

const ios = ['ioDigitalInput', 'ioDigitalOutput', 'ioDryContactInput']

export default {
  name: 'IOs',
  components: { ApxInterfaceCard },
  data() {
    const isLoading = {}
    const hasError = {}
    ios.forEach((io) => {
      isLoading[io] = false
      hasError[io] = false
    })
    return { isLoading, hasError }
  },
  computed: {
    ...mapGetters('apxInterfaces', ios)
  },
  mounted() {
    const fetchIos = (interfaceType) => {
      const onSuccess = (res) => {
        this.isLoading[interfaceType] = false
      }
      const onError = (err) => {
        this.$log.warn(err)
        this.isLoading[interfaceType] = false
        this.hasError[interfaceType] = true

        let msg = ''
        if (err.code === 1) {
          msg = this.$t('api.errors.gRPCcode1')
        } else {
          msg = this.$t('api.errors.gRPCcode2')
        }
        const content = this.$t('api.errors.base_msg', [interfaceType, err.code, msg])
        const errMessage = {
          title: interfaceType,
          type: 'toast-warning',
          content: content
        }
        this.$store.commit('notifications/PUSH_TOAST', errMessage)
      }

      this.isLoading[interfaceType] = true
      this.$store
        .dispatch('apxInterfaces/fetchInterfaceInfo', {
          interfaceId: 'ios',
          interfaceType: interfaceType
        })
        .then(onSuccess)
        .catch(onError)
    }

    ios.forEach(fetchIos)
  }
}
</script>

<style scoped></style>
