var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CListGroupItem',[_c('div',{staticClass:"collapsable-card-header pl-0 px-4 ml-n3 mr-n3",class:{
          'card-header-bottom-border': _vm.showConfig,
          'pb-3': _vm.showConfig
        },style:({ cursor: 'pointer' }),on:{"click":function($event){_vm.showConfig = !_vm.showConfig}}},[_c('i',{staticClass:"card-header-icon"},[_c('CIcon',{staticClass:"pb-1 pl-0",class:{
              'status-ok': _vm.ld.statusIndicator === 'a_green',
              'status-warning': _vm.ld.statusIndicator === 'b_yellow',
              'status-alarm': !_vm.ld.isOnline || _vm.ld.statusIndicator === 'c_red'
            },attrs:{"name":!_vm.ld.isOnline ? 'cisMinusCircle' : _vm.ld.statusIndicator === 'a_green' ? 'cisCheckCircle' : 'cisWarning',"height":26}})],1),_c('span',{staticClass:"text-info d-inline-block d-sm-none text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(_vm.ld.name)+" ")]),_c('span',{staticClass:"text-info d-none d-sm-inline-block d-md-none text-truncate",staticStyle:{"max-width":"270px"}},[_vm._v(" "+_vm._s(_vm.ld.name)+" ")]),_c('span',{staticClass:"text-info d-none d-md-inline-block"},[_vm._v(" "+_vm._s(_vm.ld.name)+" ")]),_c('div',{staticClass:"card-header-actions mb-2"},[_c('span',{staticClass:"topo-card-header-configure-action btn-minimize font-sm d-none d-md-block text-primary"},[_c('strong',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.$t('main.configActions.edit'))+" ")]),_c('i',{staticClass:"topo-config-header-icon"},[_c('CIcon',{staticClass:"pb-1",attrs:{"name":`cil-chevron-${_vm.showConfig ? 'bottom' : 'top'}`,"height":20}})],1)]),_c('span',{staticClass:"topo-card-header-configure-action btn-minimize font-sm d-md-none"},[_c('i',{staticClass:"topo-config-header-icon"},[_c('CIcon',{staticClass:"pb-1",attrs:{"name":`cil-chevron-${_vm.showConfig ? 'bottom' : 'top'}`,"height":20}})],1)])])]),_c('CCollapse',{attrs:{"show":_vm.showConfig}},[_c('CCardBody',{staticClass:"pb-1"},[_c('EmsReplaceDeviceSelection',{attrs:{"ld":_vm.ld},on:{"onRemoveDevice":_vm.onRemoveDevice,"onReplaceDevice":_vm.onReplaceDevice}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }