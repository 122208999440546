/**
 * @module view-helper/sorting
 */

/**
 * Sorts a list of DeviceConfigs in place by specified sorting key.
 * DeviceConfigInfo[sortingKey] has to be a string.
 *
 * @function
 *
 * @param {array<DeviceConfigInfo>} configs (required)
 * @param {string} sortingKey (required) by which the list should be sorted
 *
 * @return {array}
 */
export function sortDevCfgInfos(configs, sortingKey) {
  configs.sort((a, b) => {
    if (!a[sortingKey]) {
      return -1
    }
    return a[sortingKey].localeCompare(b[sortingKey])
  })

  return configs
}
