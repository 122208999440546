/** @module */

import { createUnaryCall } from '@/grpc'
import * as NetworkPb from '@/../lib/proto_js/ext/amperix/network_pb'
import { NetworkService } from '@/../lib/proto_js/ext/amperix/network_pb_service'
import { evalServiceError, handleErrorNetworkNotFound } from '@/api/error-handling'
import { UPLINK_NETWORK_CONFIG_FIELDS } from '@/store/modules/network-config'

/**
 * API ping call. (Pings the falconer.)
 *
 * @function
 *
 * @return {promise} a `amperix.PingResponse`
 */
export function ping() {
  return createUnaryCall({
    service: NetworkService,
    method: 'Ping',
    payload: new NetworkPb.PingRequest()
  })
    .perform()
    .then(evalServiceError)
}

export function fetchUplinkNetworkConfig() {
  const getUplinkNetworkConfig = createUnaryCall({
    service: NetworkService,
    method: 'GetUplinkNetworkConfig',
    payload: new NetworkPb.GetUplinkNetworkConfigRequest()
  })

  return getUplinkNetworkConfig.perform().then(handleErrorNetworkNotFound).then(evalServiceError)
}

/**
 * Sends a NetworkConfig to the BE.
 * The config pairs of UPLINK_NETWORK_CONFIG_FIELDS (automaticAddress, staticAddress), (implicitGateway, exclusiveGateway), (implicitDns, explicitDns) are one-of fields.
 * If the first of the pair is `true`, the second is ignored.
 *
 * @see module:store/network-config.uplinkNetworkConfigTemplate
 *
 * @function
 *
 * @param {object} config is the uplink network config. The structure is defined in [store/modules/network-config]{@link module:store/network-config.uplinkNetworkConfigTemplate}.
 *
 * @return {Promise}
 */
export function upsertUplinkNetworkConfig(config) {
  // cannot use `Object.keys(new NetworkPb.NetworkConfig().toObject())`,
  // since order is not necessary the one defined in the proto-msg.
  for (const f of Object.keys(config)) {
    if (UPLINK_NETWORK_CONFIG_FIELDS.indexOf(f) < 0) {
      throw new TypeError(`The NetworkConfig field ${f} is not supported.`)
    }
  }

  const networkConfig = new NetworkPb.NetworkConfig()
  networkConfig.setId(config.id)
  if (config.static) {
    networkConfig.setStatic(
      new NetworkPb.NetworkConfig.StaticConfig([
        [config.ip],
        config.netmask,
        [config.gateway],
        [[[config.primaryDns]], [[config.secondaryDns]]]
      ])
    )
  } else if (config.auto) {
    networkConfig.setFullAuto(new NetworkPb.NetworkConfig.FullAutoConfig([]))
  } else if (config.autoAddr) {
    networkConfig.setAutoAddr(
      new NetworkPb.NetworkConfig.AutoAddrConfig([[[[config.primaryDns]], [[config.secondaryDns]]]])
    )
  }

  const payload = new NetworkPb.SetUplinkNetworkConfigRequest()
  payload.setUplinkConfig(networkConfig)

  const gpio = createUnaryCall({
    service: NetworkService,
    method: 'SetUplinkNetworkConfig',
    payload: payload
  })

  return gpio.perform().then(evalServiceError)
}
