import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import apiLoadingStatus from './modules/api-loading-status'
import apxInterfaces from './modules/apx-interfaces'
import apxInformations from './modules/apx-informations'
import auth from './modules/auth'
import cache from './modules/cache'
import coreConfig from './modules/core-config'
import coreui from './modules/coreui'
import deviceConfig from './modules/device-config'
import deviceValues from './modules/device-values'
import emsEnergyServicesConfig from './modules/ems-energy-services-config'
import emsEvalAliases from './modules/ems-eval-aliases'
import emsEvalExpression from './modules/ems-eval-expression'
import emsTopologyConfig from './modules/ems-topology-config'
import fwupgrader from './modules/fwupgrader'
import networkConfig from './modules/network-config'
import notifications from './modules/notifications'
import user from './modules/user'
import users from './modules/users'

import emsTimeseries from './modules/ems-timeseries'
import emsSensors from './modules/ems-sensors'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const persistedState = createPersistedState({
  key: 'store',
  paths: ['cache', 'user']
})

export default new Vuex.Store({
  modules: {
    apiLoadingStatus,
    apxInterfaces,
    apxInformations,
    auth,
    cache,
    coreConfig,
    coreui,
    deviceConfig,
    deviceValues,
    emsEnergyServicesConfig,
    emsEvalAliases,
    emsEvalExpression,
    emsSensors,
    emsTimeseries,
    emsTopologyConfig,
    fwupgrader,
    networkConfig,
    notifications,
    user,
    users
  },
  plugins: [persistedState],
  strict: debug
})
