<template>
  <div v-if="dataLoaded" class="mt-2">
    <div class="d-flex">
      <small class="ml-auto text-uppercase text-break text-info">
        {{ esName }}
      </small>
    </div>
    <EsPreviewEvalExpression
      v-if="esId === 'evalExpression'"
      :strategy-id="strategyId"
      :actuator-group-id="actuatorGroupId"
      :eval-expression="esParams"
    />
    <EsPreviewDigitalSwitch v-if="esId === 'digitalOutputSwitchCondition'" v-bind="esParams" />
    <EsPreviewGenerationPeakShaving v-if="esId === 'generationPeakShaving'" v-bind="esParams" />
    <EsPreviewOffGrid v-if="esId === 'offGrid'" />
    <EsPreviewRecordedPowerPeakShaving v-if="esId === 'recordedPowerMeasurementPeakShaving'" v-bind="esParams" />
    <EsPreviewTargetPower v-if="esId === 'targetPower' && !esParams.evalWatts" v-bind="esParams" />
    <EsPreviewTargetPowerEval v-if="esId === 'targetPower' && esParams.evalWatts" v-bind="esParams" />
    <EsPreviewPvCurtailment v-if="esId === 'pvCurtailment'" v-bind="esParams" />
    <EsPreviewOffGridBatteryProtection v-if="esId === 'offGridBatteryProtection'" v-bind="esParams" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { API_LOADING_IDS } from '@/store/modules/api-loading-status'
import EsPreviewDigitalSwitch from '@/components/ems/energy-services/energy-service-preview/es-preview-digital-switch'
import EsPreviewEvalExpression from '@/components/ems/energy-services/energy-service-preview/es-preview-eval-expression'
import EsPreviewGenerationPeakShaving from '@/components/ems/energy-services/energy-service-preview/es-preview-generation-peak-shaving'
import EsPreviewOffGrid from '@/components/ems/energy-services/energy-service-preview/es-preview-off-grid'
import EsPreviewRecordedPowerPeakShaving from '@/components/ems/energy-services/energy-service-preview/es-preview-recorded-power-peak-shaving'
import EsPreviewTargetPower from '@/components/ems/energy-services/energy-service-preview/es-preview-target-power'
import EsPreviewTargetPowerEval from '@/components/ems/energy-services/energy-service-preview/es-preview-target-power-eval'
import EsPreviewPvCurtailment from '@/components/ems/energy-services/energy-service-preview/es-preview-pv-curtailment'
import EsPreviewOffGridBatteryProtection from '@/components/ems/energy-services/energy-service-preview/es-preview-off-grid-battery-protection'

export default {
  name: 'EsPreviewLayout',
  components: {
    EsPreviewDigitalSwitch,
    EsPreviewEvalExpression,
    EsPreviewGenerationPeakShaving,
    EsPreviewOffGrid,
    EsPreviewRecordedPowerPeakShaving,
    EsPreviewTargetPower,
    EsPreviewTargetPowerEval,
    EsPreviewPvCurtailment,
    EsPreviewOffGridBatteryProtection
  },
  props: {
    strategyId: {
      required: true,
      type: String
    },
    actuatorGroupId: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loaded', 'numCalls']),
    ...mapGetters('emsEnergyServicesConfig', [
      'getStrategy',
      'getEnergyServiceId',
      'getEnergyServiceName',
      'getEnergyServiceParams'
    ]),
    dataLoaded() {
      return (
        this.loaded(API_LOADING_IDS.ems.esActuatorStrategies) &&
        (this.loaded(API_LOADING_IDS.ems.defaultPeakObserver) || this.numCalls(API_LOADING_IDS.ems.defaultPeakObserver))
      )
    },
    esId() {
      return this.getEnergyServiceId({ strategyId: this.strategyId, actuatorGroupId: this.actuatorGroupId })
    },
    esName() {
      return this.getEnergyServiceName({
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId
      })
    },
    esParams() {
      if (!this.esId || this.esId === '_no-energy-service') {
        return {}
      }

      return this.getEnergyServiceParams({
        strategyId: this.strategyId,
        actuatorGroupId: this.actuatorGroupId,
        energyServiceId: this.esId
      })
    }
  }
}
</script>
