// package: de.mypowergrid.amperix
// file: ext/amperix/ems.proto

var ext_amperix_ems_pb = require("../../ext/amperix/ems_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var EnergyManagerService = (function () {
  function EnergyManagerService() {}
  EnergyManagerService.serviceName = "de.mypowergrid.amperix.EnergyManagerService";
  return EnergyManagerService;
}());

EnergyManagerService.GetEnergyManagerStatus = {
  methodName: "GetEnergyManagerStatus",
  service: EnergyManagerService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_ems_pb.GetEnergyManagerStatusRequest,
  responseType: ext_amperix_ems_pb.GetEnergyManagerStatusResponse
};

EnergyManagerService.GetEnergyManagerDeviceStatus = {
  methodName: "GetEnergyManagerDeviceStatus",
  service: EnergyManagerService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_ems_pb.GetEnergyManagerDeviceStatusRequest,
  responseType: ext_amperix_ems_pb.GetEnergyManagerDeviceStatusResponse
};

EnergyManagerService.GetEnergyManagerDeviceValues = {
  methodName: "GetEnergyManagerDeviceValues",
  service: EnergyManagerService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_ems_pb.GetEnergyManagerDeviceValuesRequest,
  responseType: ext_amperix_ems_pb.GetEnergyManagerDeviceValuesResponse
};

EnergyManagerService.GetEnergyManagerDeviceValue = {
  methodName: "GetEnergyManagerDeviceValue",
  service: EnergyManagerService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_ems_pb.GetEnergyManagerDeviceValueRequest,
  responseType: ext_amperix_ems_pb.GetEnergyManagerDeviceValueResponse
};

EnergyManagerService.Operate = {
  methodName: "Operate",
  service: EnergyManagerService,
  requestStream: false,
  responseStream: false,
  requestType: ext_amperix_ems_pb.OperateRequest,
  responseType: ext_amperix_ems_pb.OperateResponse
};

exports.EnergyManagerService = EnergyManagerService;

function EnergyManagerServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EnergyManagerServiceClient.prototype.getEnergyManagerStatus = function getEnergyManagerStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EnergyManagerService.GetEnergyManagerStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EnergyManagerServiceClient.prototype.getEnergyManagerDeviceStatus = function getEnergyManagerDeviceStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EnergyManagerService.GetEnergyManagerDeviceStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EnergyManagerServiceClient.prototype.getEnergyManagerDeviceValues = function getEnergyManagerDeviceValues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EnergyManagerService.GetEnergyManagerDeviceValues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EnergyManagerServiceClient.prototype.getEnergyManagerDeviceValue = function getEnergyManagerDeviceValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EnergyManagerService.GetEnergyManagerDeviceValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EnergyManagerServiceClient.prototype.operate = function operate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EnergyManagerService.Operate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EnergyManagerServiceClient = EnergyManagerServiceClient;

