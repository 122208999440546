<template>
  <!-- prettier-ignore -->
  <CButton
    :color="color"
    :size="size"
    :variant="variant"
    @click="$emit('toggleHelpText')"
  >
    <CIcon :name="icon" :size="size"/>
  </CButton>
</template>

<script>
export default {
  name: 'HelpBtn',
  props: {
    color: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
      // Alternative CoreUI info icons, for example: cid-info-circle, cis-info-circle
      default: 'cil-info-circle'
    },
    size: {
      type: String,
      default: 'sm'
    },
    variant: {
      type: String,
      default: 'outline'
    }
  }
}
</script>
