<template>
  <!-- Only one preview can be showed at a time, because only one emsEvalExpression state exists -->
  <EvalTree v-if="decisionTreeIdx === currentDecisionTreeIdx" :preview="true" />
</template>

<script>
import { mapState } from 'vuex'
import EvalTree from '@/components/ems/evalexp/eval-tree'

export default {
  name: 'EsTimeSwitchPreview',
  components: {
    EvalTree
  },
  props: {
    decisionTreeIdx: {
      required: true,
      type: Number
    }
  },
  computed: {
    ...mapState('emsEvalExpression', ['currentDecisionTreeIdx'])
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('emsTimeseries/timeseriesesInit', {
        withTimestampValues: false
      })
      await this.$store
        .dispatch('emsSensors/sensorInit', {})
        .then(() => {
          this.$log.debug('Succesfully fetched sensor names.')
        })
        .catch((err) => {
          this.$log.error(err)
        })
      await this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromDecisionTree', {
        decisionTreeIdx: this.decisionTreeIdx
      })
    }
  }
}
</script>
