var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('p',{staticClass:"d-flex justify-content-between mb-1"},[_c('small',{class:_vm.labelClass},[_vm._v(" "+_vm._s(_vm.$t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.intervalMinutes.label'))+" ")]),_c('small',{class:Object.assign({}, _vm.valueClass, {
          'text-primary': _vm.intervalSeconds,
          'text-danger': !_vm.intervalSeconds
        })},[_c('em',[_vm._v(" "+_vm._s(_vm.intervalSeconds / 60 || '?')+" ")]),_c('em',[_vm._v("min")])])]),_c('p',{staticClass:"d-flex justify-content-between mb-1"},[_c('small',{class:_vm.labelClass},[_vm._v(" "+_vm._s(_vm.$t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.peakWatts.label'))+" ")]),_c('small',{class:Object.assign({}, _vm.valueClass, {
          'text-primary': _vm.peakWatts,
          'text-danger': !_vm.peakWatts
        })},[_c('em',[_vm._v(" "+_vm._s(_vm.peakWatts || '?')+" ")]),_c('em',[_vm._v("W")])])]),_c('p',{staticClass:"d-flex justify-content-between mb-2"},[_c('small',{class:_vm.labelClass},[_vm._v(" "+_vm._s(_vm.$t('ems.energyService.config.form.recordedPowerMeasurementPeakShaving.safetyMarginWatts.label'))+" ")]),_c('small',{class:_vm.valueClass},[_c('em',[_vm._v(" "+_vm._s(_vm.safetyMarginWatts)+" ")]),_c('em',[_vm._v("W")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }