export const temLogoLightMini = [
  '306.14 90.71',
  `
<style id="style8" type="text/css">
  .st0 {
    fill: #222222;
  }
  .st1 {
    fill: #9DBF26;
  }
  .st2 {
    clip-path: url(#SVGID_2_);
    fill: #222222;
  }
</style>
<polygon
  id="polygon10"
  points="14.47,14.56 14.47,22.77 31.87,22.77 31.87,76.11 40.5,76.11 40.5,22.77 57.9,22.77 57.9,14.56 "
  class="st0"
/>
<polygon
  id="polygon12"
  points="66.11,14.56 66.11,76.11 74.74,76.11 105.64,76.11 105.64,67.89 74.74,67.89 74.74,49.65 100.99,49.65   100.99,41.44 74.74,41.44 74.74,22.77 105.64,22.77 105.64,14.56 "
  class="st1"
/>
<polygon
  id="polygon14"
  points="158.06,14.56 139.98,55.2 121.91,14.56 113.82,14.56 113.82,76.11 122.25,76.11 122.25,34.52   136.98,67.55 142.99,67.55 157.72,33.69 157.72,76.11 166.14,76.11 166.14,14.56 "
  class="st0"
/>
<g id="g26">
  <defs id="defs17">
    <rect height="31.96" width="105.11" y="44.28" x="186.43" id="SVGID_1_" />
  </defs>
  <clipPath id="SVGID_2_">
    <use id="use19" style="overflow:visible;" xlink:href="#SVGID_1_" />
  </clipPath>
  <path
    id="path22"
    clip-path="url(#SVGID_2_)"
    d="M288.13,64.99c0.98,0,1.46,0.46,1.46,1.39v1.77c0,0.93-0.49,1.39-1.46,1.39h-1v-4.55H288.13z M289.77,70.69   c0.94-0.43,1.41-1.27,1.41-2.54v-1.77c0-0.93-0.26-1.64-0.79-2.13c-0.52-0.49-1.28-0.73-2.27-0.73h-2.59v12.72h1.59v-5.23h1h0.12   l1.57,5.23h1.71L289.77,70.69z M283.33,63.52h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z    M273.66,70.35h1.23v4.43h-1.11c-0.98,0-1.46-0.46-1.46-1.39v-7c0-0.93,0.49-1.39,1.46-1.39h2.37v-1.46h-2.37   c-0.99,0-1.74,0.24-2.27,0.73c-0.52,0.49-0.79,1.2-0.79,2.13v7c0,0.93,0.26,1.64,0.79,2.13c0.52,0.49,1.28,0.73,2.27,0.73h2.61   v-7.25h-2.73V70.35z M265.88,66.22l1,5.93h-2L265.88,66.22z M269.22,76.24l-2.38-12.72h-1.96l-2.37,12.72h1.68l0.45-2.63h2.48   l0.45,2.63H269.22z M259.13,63.52v9.09l-2.73-9.09h-1.7v12.72h1.61v-9.09l2.73,9.09h1.7V63.52H259.13z M249.57,66.22l1,5.93h-2   L249.57,66.22z M252.91,76.24l-2.38-12.72h-1.96l-2.38,12.72h1.68l0.45-2.63h2.48l0.45,2.63H252.91z M240.5,70.88l-2.32-7.36h-1.61   v12.72h1.59v-9.16l1.59,5.77h1.48l1.59-5.77v9.16h1.59V63.52h-1.61L240.5,70.88z M228.16,69.49l-1.48-5.96h-1.7l2.39,8.25v4.47   h1.59v-4.47l2.36-8.25h-1.7L228.16,69.49z M220.68,70.35h1.23v4.43h-1.11c-0.98,0-1.47-0.46-1.47-1.39v-7   c0-0.93,0.49-1.39,1.47-1.39h2.37v-1.46h-2.37c-0.99,0-1.75,0.24-2.27,0.73c-0.52,0.49-0.79,1.2-0.79,2.13v7   c0,0.93,0.26,1.64,0.79,2.13c0.52,0.49,1.28,0.73,2.27,0.73h2.61v-7.25h-2.73V70.35z M212.34,64.99c0.98,0,1.46,0.46,1.46,1.39   v1.77c0,0.93-0.49,1.39-1.46,1.39h-1v-4.55H212.34z M213.98,70.69c0.94-0.43,1.41-1.27,1.41-2.54v-1.77c0-0.93-0.26-1.64-0.79-2.13   c-0.52-0.49-1.28-0.73-2.27-0.73h-2.59v12.72h1.59v-5.23h1h0.13l1.57,5.23h1.72L213.98,70.69z M207.54,63.52h-4.66v12.72h4.66   v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z M198.66,63.52v9.09l-2.73-9.09h-1.7v12.72h1.61v-9.09l2.73,9.09h1.7V63.52   H198.66z M192.04,63.52h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z"
    class="st2"
  />
  <path
    id="path24"
    clip-path="url(#SVGID_2_)"
    d="M229.2,44.39v1.46h2.16v11.25h1.61V45.85h2.14v-1.46H229.2z M225.98,44.39h-1.59v12.72h4.64v-1.47h-3.05V44.39   z M220.18,47.23v7.07c0,0.99-0.46,1.48-1.38,1.48c-0.93,0-1.39-0.49-1.39-1.48v-7.07c0-0.99,0.46-1.48,1.39-1.48   C219.72,45.75,220.18,46.24,220.18,47.23 M215.82,47.23v7.07c0,0.94,0.26,1.67,0.78,2.18c0.52,0.51,1.25,0.77,2.21,0.77   s1.69-0.26,2.21-0.77c0.52-0.51,0.78-1.24,0.78-2.18v-7.07c0-0.94-0.26-1.67-0.78-2.18c-0.52-0.51-1.25-0.77-2.21-0.77   s-1.69,0.26-2.21,0.77C216.08,45.56,215.82,46.29,215.82,47.23 M210.86,54.44l-1.59-10.05h-1.71l2.36,12.72h1.87l2.36-12.72h-1.71   L210.86,54.44z M205.76,52.27c-0.26-0.52-0.57-0.96-0.94-1.34c-0.37-0.38-0.74-0.74-1.11-1.09c-0.37-0.35-0.68-0.73-0.94-1.13   s-0.38-0.81-0.38-1.21v-0.18c0-0.98,0.49-1.46,1.47-1.46h1.98v-1.46h-1.98c-1.01,0-1.78,0.25-2.31,0.75   c-0.53,0.5-0.79,1.23-0.79,2.18v0.18c0,0.56,0.13,1.09,0.38,1.6c0.26,0.51,0.57,0.95,0.94,1.33c0.37,0.38,0.74,0.75,1.11,1.1   c0.37,0.35,0.68,0.73,0.94,1.14c0.26,0.41,0.38,0.83,0.38,1.26v0.25c0,0.98-0.49,1.46-1.46,1.46h-1.98v1.47h1.98   c2.07,0,3.11-0.98,3.11-2.93v-0.25C206.14,53.34,206.02,52.79,205.76,52.27 M198.84,44.39h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61   v-1.36h-2.61v-4.16h3.05V44.39z M186.43,44.39v1.46h2.16v11.25h1.61V45.85h2.14v-1.46H186.43z"
    class="st2"
  />
</g>
`
]

export const temLogoLight = [
  '306.14 106.71',
  `
<style>
.cls-1 {
    fill: #9dbf26;
}

.cls-2 {
    fill: #222;
}

.cls-3 {
    fill: none;
    stroke: #9dbf26;
    stroke-width: 12.47px;
}
</style>
<g id="Logo_Klasisch" data-name="Logo Klasisch">
    <g>
        <path class="cls-1" d="M200.11,0a1.24,1.24,0,0,0-1.27,1.27V23.54a1.24,1.24,0,0,0,1.27,1.27h3.77a1.24,1.24,0,0,0,1.27-1.27V1.27A1.24,1.24,0,0,0,203.88,0Z" />
        <polygon class="cls-2" points="0 9.04 0 14.68 16.13 14.68 16.13 61.95 22.68 61.95 22.68 14.68 38.81 14.68 38.81 9.04 0 9.04" />
        <polygon class="cls-2" points="77.26 61.95 47.63 61.95 47.63 9.04 76.03 9.04 76.03 14.68 54.17 14.68 54.17 32.27 74.4 32.27 74.4 37.81 54.17 37.81 54.17 56.31 77.26 56.31 77.26 61.95" />
        <path class="cls-2" d="M85.44,59.81V52.49a14.21,14.21,0,0,0,3.18,2A27.28,27.28,0,0,0,92.53,56a32.69,32.69,0,0,0,4.14,1,24.76,24.76,0,0,0,3.82.31c4,0,7-.72,9-2.13a7,7,0,0,0,3-6.09,6.65,6.65,0,0,0-1-3.73,11.82,11.82,0,0,0-2.73-2.91,27.66,27.66,0,0,0-4.13-2.5c-1.59-.81-3.32-1.63-5.18-2.54S95.71,35.45,94,34.5a23.53,23.53,0,0,1-4.41-3.19,12.9,12.9,0,0,1-3-3.9,11.41,11.41,0,0,1-1.09-5.14A11.31,11.31,0,0,1,87.26,16a14.17,14.17,0,0,1,4.41-4.41A21.8,21.8,0,0,1,97.9,9,28.56,28.56,0,0,1,105,8.14c5.49,0,9.54.63,12,1.86v7c-3.32-2.18-7.54-3.22-12.73-3.22a22.47,22.47,0,0,0-4.31.41,12.09,12.09,0,0,0-3.82,1.36,7.56,7.56,0,0,0-2.73,2.5,6.05,6.05,0,0,0-1,3.68,7.18,7.18,0,0,0,.81,3.5A8.72,8.72,0,0,0,95.58,28a23.6,23.6,0,0,0,3.82,2.36c1.5.78,3.22,1.59,5.18,2.5s3.91,1.91,5.68,3A26.74,26.74,0,0,1,115,39.18a15.45,15.45,0,0,1,3.23,4.18,10.88,10.88,0,0,1,1.18,5.22,12.11,12.11,0,0,1-6,11.05,19.61,19.61,0,0,1-6.32,2.45,37,37,0,0,1-7.59.78,31.29,31.29,0,0,1-3.27-.19,40.3,40.3,0,0,1-4-.59c-1.36-.27-2.64-.59-3.82-.95a15.47,15.47,0,0,1-3-1.32" />
        <path class="cls-2" d="M172.07,9,151.34,61.9h-7.22L123.85,9h7.31L146.66,51a24.12,24.12,0,0,1,1.14,4.68h.13a24.73,24.73,0,0,1,1.28-4.77L165,9h7Z" />
        <polygon class="cls-2" points="268.87 61.95 239.88 61.95 239.88 9.04 246.42 9.04 246.42 56.31 268.87 56.31 268.87 61.95" />
        <polygon class="cls-2" points="306.14 14.68 290.01 14.68 290.01 61.95 283.46 61.95 283.46 14.68 267.33 14.68 267.33 9.04 306.14 9.04 306.14 14.68" />
        <path class="cls-1" d="M228.56,35.36A27.36,27.36,0,0,0,209.88,9.14V16A21.1,21.1,0,0,1,222.2,35.36c0,11.63-9,21.09-20.18,21.09S181.84,47,181.84,35.36A21.1,21.1,0,0,1,194.16,16V9.14a27.36,27.36,0,0,0-18.68,26.22c0,15.18,11.86,27.45,26.54,27.45s26.54-12.32,26.54-27.45" />
    </g>
</g>
<g id="Energy_Manager" data-name="Energy Manager">
    <line class="cls-3" x1="306.14" y1="95.9" x2="267.33" y2="95.9" />
    <line class="cls-3" x1="38.81" y1="95.9" y2="95.9" />
    <g>
        <path class="cls-2" d="M72.58,87.22H67.17v7.4h4.64V97H67.17v7.59h5.41v2.59H64.31V84.62h8.27Z" />
        <path class="cls-2" d="M86.31,84.62v22.59h-3L78.44,91v16.18H75.58V84.62h3l4.86,16.18V84.62Z" />
        <path class="cls-2" d="M98,87.22H92.62v7.4h4.64V97H92.62v7.59H98v2.59H89.76V84.62H98Z" />
        <path class="cls-2" d="M111.71,107.21h-3l-2.78-9.31h-2v9.31h-2.81V84.62h4.59a5.77,5.77,0,0,1,4,1.32,5,5,0,0,1,1.41,3.78v3.13c0,2.23-.82,3.73-2.5,4.5Zm-7.82-20v8.09h1.78c1.72,0,2.59-.82,2.59-2.46V89.72c0-1.64-.87-2.46-2.59-2.46h-1.78Z" />
        <path class="cls-2" d="M119.35,94.31h4.86v12.9h-4.64a5.73,5.73,0,0,1-4-1.32,5,5,0,0,1-1.41-3.77V89.67a4.84,4.84,0,0,1,1.41-3.77,5.66,5.66,0,0,1,4-1.32h4.23v2.59h-4.23c-1.72,0-2.59.82-2.59,2.45v12.46c0,1.63.87,2.45,2.59,2.45h2V96.67h-2.18Z" />
        <path class="cls-2" d="M134.62,84.62h3L133.44,99.3v8h-2.82v-8l-4.27-14.68h3L132,95.21Z" />
        <path class="cls-2" d="M159.61,84.62h2.87v22.59h-2.82V90.94l-2.82,10.27h-2.63l-2.82-10.27v16.27h-2.82V84.62h2.86l4.14,13.09Z" />
        <path class="cls-2" d="M174,107.21l-.77-4.68h-4.41l-.77,4.68h-3l4.23-22.59h3.49L177,107.21Zm-4.77-7.27h3.54L171,89.4Z" />
        <path class="cls-2" d="M190.2,84.62v22.59h-3L182.34,91v16.18h-2.86V84.62h3l4.86,16.18V84.62Z" />
        <path class="cls-2" d="M201.7,107.21l-.77-4.68h-4.41l-.77,4.68h-3L197,84.62h3.5l4.23,22.59ZM197,99.94h3.55L198.75,89.4Z" />
        <path class="cls-2" d="M211.79,94.31h4.86v12.9H212a5.75,5.75,0,0,1-4.05-1.32,4.84,4.84,0,0,1-1.41-3.77V89.67A4.84,4.84,0,0,1,208,85.9,5.67,5.67,0,0,1,212,84.58h4.22v2.59H212c-1.73,0-2.59.82-2.59,2.45v12.46c0,1.63.86,2.45,2.59,2.45H214V96.67h-2.18Z" />
        <path class="cls-2" d="M228.2,87.22h-5.41v7.4h4.63V97h-4.63v7.59h5.41v2.59h-8.28V84.62h8.28Z" />
        <path class="cls-2" d="M241.88,107.21h-3l-2.77-9.31h-2v9.31h-2.82V84.62h4.59a5.82,5.82,0,0,1,4,1.32,5,5,0,0,1,1.41,3.78v3.13c0,2.23-.82,3.73-2.5,4.5Zm-7.87-20v8.09h1.78c1.72,0,2.59-.82,2.59-2.46V89.72c0-1.64-.87-2.46-2.59-2.46H234Z" />
    </g>
</g>
`
]

export const temLogoDarkMini = [
  '306.14 90.71',
  `
<style id="style8" type="text/css">
  .st1 {
    fill: #9DBF26;
  }
</style>
<polygon
  id="polygon10"
  points="14.47,14.56 14.47,22.77 31.87,22.77 31.87,76.11 40.5,76.11 40.5,22.77 57.9,22.77 57.9,14.56 "
  class="st0"
/>
<polygon
  id="polygon12"
  points="66.11,14.56 66.11,76.11 74.74,76.11 105.64,76.11 105.64,67.89 74.74,67.89 74.74,49.65 100.99,49.65   100.99,41.44 74.74,41.44 74.74,22.77 105.64,22.77 105.64,14.56 "
  class="st1"
/>
<polygon
  id="polygon14"
  points="158.06,14.56 139.98,55.2 121.91,14.56 113.82,14.56 113.82,76.11 122.25,76.11 122.25,34.52   136.98,67.55 142.99,67.55 157.72,33.69 157.72,76.11 166.14,76.11 166.14,14.56 "
  class="st0"
/>
<g id="g26">
  <defs id="defs17">
    <rect height="31.96" width="105.11" y="44.28" x="186.43" id="SVGID_1_" />
  </defs>
  <clipPath id="SVGID_2_">
    <use id="use19" style="overflow:visible;" xlink:href="#SVGID_1_" />
  </clipPath>
  <path
    id="path22"
    clip-path="url(#SVGID_2_)"
    d="M288.13,64.99c0.98,0,1.46,0.46,1.46,1.39v1.77c0,0.93-0.49,1.39-1.46,1.39h-1v-4.55H288.13z M289.77,70.69   c0.94-0.43,1.41-1.27,1.41-2.54v-1.77c0-0.93-0.26-1.64-0.79-2.13c-0.52-0.49-1.28-0.73-2.27-0.73h-2.59v12.72h1.59v-5.23h1h0.12   l1.57,5.23h1.71L289.77,70.69z M283.33,63.52h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z    M273.66,70.35h1.23v4.43h-1.11c-0.98,0-1.46-0.46-1.46-1.39v-7c0-0.93,0.49-1.39,1.46-1.39h2.37v-1.46h-2.37   c-0.99,0-1.74,0.24-2.27,0.73c-0.52,0.49-0.79,1.2-0.79,2.13v7c0,0.93,0.26,1.64,0.79,2.13c0.52,0.49,1.28,0.73,2.27,0.73h2.61   v-7.25h-2.73V70.35z M265.88,66.22l1,5.93h-2L265.88,66.22z M269.22,76.24l-2.38-12.72h-1.96l-2.37,12.72h1.68l0.45-2.63h2.48   l0.45,2.63H269.22z M259.13,63.52v9.09l-2.73-9.09h-1.7v12.72h1.61v-9.09l2.73,9.09h1.7V63.52H259.13z M249.57,66.22l1,5.93h-2   L249.57,66.22z M252.91,76.24l-2.38-12.72h-1.96l-2.38,12.72h1.68l0.45-2.63h2.48l0.45,2.63H252.91z M240.5,70.88l-2.32-7.36h-1.61   v12.72h1.59v-9.16l1.59,5.77h1.48l1.59-5.77v9.16h1.59V63.52h-1.61L240.5,70.88z M228.16,69.49l-1.48-5.96h-1.7l2.39,8.25v4.47   h1.59v-4.47l2.36-8.25h-1.7L228.16,69.49z M220.68,70.35h1.23v4.43h-1.11c-0.98,0-1.47-0.46-1.47-1.39v-7   c0-0.93,0.49-1.39,1.47-1.39h2.37v-1.46h-2.37c-0.99,0-1.75,0.24-2.27,0.73c-0.52,0.49-0.79,1.2-0.79,2.13v7   c0,0.93,0.26,1.64,0.79,2.13c0.52,0.49,1.28,0.73,2.27,0.73h2.61v-7.25h-2.73V70.35z M212.34,64.99c0.98,0,1.46,0.46,1.46,1.39   v1.77c0,0.93-0.49,1.39-1.46,1.39h-1v-4.55H212.34z M213.98,70.69c0.94-0.43,1.41-1.27,1.41-2.54v-1.77c0-0.93-0.26-1.64-0.79-2.13   c-0.52-0.49-1.28-0.73-2.27-0.73h-2.59v12.72h1.59v-5.23h1h0.13l1.57,5.23h1.72L213.98,70.69z M207.54,63.52h-4.66v12.72h4.66   v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z M198.66,63.52v9.09l-2.73-9.09h-1.7v12.72h1.61v-9.09l2.73,9.09h1.7V63.52   H198.66z M192.04,63.52h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61v-1.36h-2.61v-4.16h3.05V63.52z"
    class="st2"
  />
  <path
    id="path24"
    clip-path="url(#SVGID_2_)"
    d="M229.2,44.39v1.46h2.16v11.25h1.61V45.85h2.14v-1.46H229.2z M225.98,44.39h-1.59v12.72h4.64v-1.47h-3.05V44.39   z M220.18,47.23v7.07c0,0.99-0.46,1.48-1.38,1.48c-0.93,0-1.39-0.49-1.39-1.48v-7.07c0-0.99,0.46-1.48,1.39-1.48   C219.72,45.75,220.18,46.24,220.18,47.23 M215.82,47.23v7.07c0,0.94,0.26,1.67,0.78,2.18c0.52,0.51,1.25,0.77,2.21,0.77   s1.69-0.26,2.21-0.77c0.52-0.51,0.78-1.24,0.78-2.18v-7.07c0-0.94-0.26-1.67-0.78-2.18c-0.52-0.51-1.25-0.77-2.21-0.77   s-1.69,0.26-2.21,0.77C216.08,45.56,215.82,46.29,215.82,47.23 M210.86,54.44l-1.59-10.05h-1.71l2.36,12.72h1.87l2.36-12.72h-1.71   L210.86,54.44z M205.76,52.27c-0.26-0.52-0.57-0.96-0.94-1.34c-0.37-0.38-0.74-0.74-1.11-1.09c-0.37-0.35-0.68-0.73-0.94-1.13   s-0.38-0.81-0.38-1.21v-0.18c0-0.98,0.49-1.46,1.47-1.46h1.98v-1.46h-1.98c-1.01,0-1.78,0.25-2.31,0.75   c-0.53,0.5-0.79,1.23-0.79,2.18v0.18c0,0.56,0.13,1.09,0.38,1.6c0.26,0.51,0.57,0.95,0.94,1.33c0.37,0.38,0.74,0.75,1.11,1.1   c0.37,0.35,0.68,0.73,0.94,1.14c0.26,0.41,0.38,0.83,0.38,1.26v0.25c0,0.98-0.49,1.46-1.46,1.46h-1.98v1.47h1.98   c2.07,0,3.11-0.98,3.11-2.93v-0.25C206.14,53.34,206.02,52.79,205.76,52.27 M198.84,44.39h-4.66v12.72h4.66v-1.47h-3.05v-4.27h2.61   v-1.36h-2.61v-4.16h3.05V44.39z M186.43,44.39v1.46h2.16v11.25h1.61V45.85h2.14v-1.46H186.43z"
    class="st2"
  />
</g>
`
]

export const temLogoDark = [
  '306.14 106.71',
  `
<style>
.cls-1 {
    fill: #9dbf26;
}

.cls-2 {
    fill: #fff;
}

.cls-3 {
    fill: none;
    stroke: #9dbf26;
    stroke-width: 12.47px;
}
</style>
<g id="Logo_Klasisch" data-name="Logo Klasisch">
    <g>
        <path class="cls-1" d="M200.11,0a1.24,1.24,0,0,0-1.27,1.27V23.54a1.24,1.24,0,0,0,1.27,1.27h3.77a1.24,1.24,0,0,0,1.27-1.27V1.27A1.24,1.24,0,0,0,203.88,0Z" />
        <polygon class="cls-2" points="0 9.04 0 14.68 16.13 14.68 16.13 61.95 22.68 61.95 22.68 14.68 38.81 14.68 38.81 9.04 0 9.04" />
        <polygon class="cls-2" points="77.26 61.95 47.63 61.95 47.63 9.04 76.03 9.04 76.03 14.68 54.17 14.68 54.17 32.27 74.4 32.27 74.4 37.81 54.17 37.81 54.17 56.31 77.26 56.31 77.26 61.95" />
        <path class="cls-2" d="M85.44,59.81V52.49a14.21,14.21,0,0,0,3.18,2A27.28,27.28,0,0,0,92.53,56a32.69,32.69,0,0,0,4.14,1,24.76,24.76,0,0,0,3.82.31c4,0,7-.72,9-2.13a7,7,0,0,0,3-6.09,6.65,6.65,0,0,0-1-3.73,11.82,11.82,0,0,0-2.73-2.91,27.66,27.66,0,0,0-4.13-2.5c-1.59-.81-3.32-1.63-5.18-2.54S95.71,35.45,94,34.5a23.53,23.53,0,0,1-4.41-3.19,12.9,12.9,0,0,1-3-3.9,11.41,11.41,0,0,1-1.09-5.14A11.31,11.31,0,0,1,87.26,16a14.17,14.17,0,0,1,4.41-4.41A21.8,21.8,0,0,1,97.9,9,28.56,28.56,0,0,1,105,8.14c5.49,0,9.54.63,12,1.86v7c-3.32-2.18-7.54-3.22-12.73-3.22a22.47,22.47,0,0,0-4.31.41,12.09,12.09,0,0,0-3.82,1.36,7.56,7.56,0,0,0-2.73,2.5,6.05,6.05,0,0,0-1,3.68,7.18,7.18,0,0,0,.81,3.5A8.72,8.72,0,0,0,95.58,28a23.6,23.6,0,0,0,3.82,2.36c1.5.78,3.22,1.59,5.18,2.5s3.91,1.91,5.68,3A26.74,26.74,0,0,1,115,39.18a15.45,15.45,0,0,1,3.23,4.18,10.88,10.88,0,0,1,1.18,5.22,12.11,12.11,0,0,1-6,11.05,19.61,19.61,0,0,1-6.32,2.45,37,37,0,0,1-7.59.78,31.29,31.29,0,0,1-3.27-.19,40.3,40.3,0,0,1-4-.59c-1.36-.27-2.64-.59-3.82-.95a15.47,15.47,0,0,1-3-1.32" />
        <path class="cls-2" d="M172.07,9,151.34,61.9h-7.22L123.85,9h7.31L146.66,51a24.12,24.12,0,0,1,1.14,4.68h.13a24.73,24.73,0,0,1,1.28-4.77L165,9h7Z" />
        <polygon class="cls-2" points="268.87 61.95 239.88 61.95 239.88 9.04 246.42 9.04 246.42 56.31 268.87 56.31 268.87 61.95" />
        <polygon class="cls-2" points="306.14 14.68 290.01 14.68 290.01 61.95 283.46 61.95 283.46 14.68 267.33 14.68 267.33 9.04 306.14 9.04 306.14 14.68" />
        <path class="cls-1" d="M228.56,35.36A27.36,27.36,0,0,0,209.88,9.14V16A21.1,21.1,0,0,1,222.2,35.36c0,11.63-9,21.09-20.18,21.09S181.84,47,181.84,35.36A21.1,21.1,0,0,1,194.16,16V9.14a27.36,27.36,0,0,0-18.68,26.22c0,15.18,11.86,27.45,26.54,27.45s26.54-12.32,26.54-27.45" />
    </g>
</g>
<g id="Energy_Manager" data-name="Energy Manager">
    <line class="cls-3" x1="306.14" y1="95.9" x2="267.33" y2="95.9" />
    <line class="cls-3" x1="38.81" y1="95.9" y2="95.9" />
    <g>
        <path class="cls-2" d="M72.58,87.22H67.17v7.4h4.64V97H67.17v7.59h5.41v2.59H64.31V84.62h8.27Z" />
        <path class="cls-2" d="M86.31,84.62v22.59h-3L78.44,91v16.18H75.58V84.62h3l4.86,16.18V84.62Z" />
        <path class="cls-2" d="M98,87.22H92.62v7.4h4.64V97H92.62v7.59H98v2.59H89.76V84.62H98Z" />
        <path class="cls-2" d="M111.71,107.21h-3l-2.78-9.31h-2v9.31h-2.81V84.62h4.59a5.77,5.77,0,0,1,4,1.32,5,5,0,0,1,1.41,3.78v3.13c0,2.23-.82,3.73-2.5,4.5Zm-7.82-20v8.09h1.78c1.72,0,2.59-.82,2.59-2.46V89.72c0-1.64-.87-2.46-2.59-2.46h-1.78Z" />
        <path class="cls-2" d="M119.35,94.31h4.86v12.9h-4.64a5.73,5.73,0,0,1-4-1.32,5,5,0,0,1-1.41-3.77V89.67a4.84,4.84,0,0,1,1.41-3.77,5.66,5.66,0,0,1,4-1.32h4.23v2.59h-4.23c-1.72,0-2.59.82-2.59,2.45v12.46c0,1.63.87,2.45,2.59,2.45h2V96.67h-2.18Z" />
        <path class="cls-2" d="M134.62,84.62h3L133.44,99.3v8h-2.82v-8l-4.27-14.68h3L132,95.21Z" />
        <path class="cls-2" d="M159.61,84.62h2.87v22.59h-2.82V90.94l-2.82,10.27h-2.63l-2.82-10.27v16.27h-2.82V84.62h2.86l4.14,13.09Z" />
        <path class="cls-2" d="M174,107.21l-.77-4.68h-4.41l-.77,4.68h-3l4.23-22.59h3.49L177,107.21Zm-4.77-7.27h3.54L171,89.4Z" />
        <path class="cls-2" d="M190.2,84.62v22.59h-3L182.34,91v16.18h-2.86V84.62h3l4.86,16.18V84.62Z" />
        <path class="cls-2" d="M201.7,107.21l-.77-4.68h-4.41l-.77,4.68h-3L197,84.62h3.5l4.23,22.59ZM197,99.94h3.55L198.75,89.4Z" />
        <path class="cls-2" d="M211.79,94.31h4.86v12.9H212a5.75,5.75,0,0,1-4.05-1.32,4.84,4.84,0,0,1-1.41-3.77V89.67A4.84,4.84,0,0,1,208,85.9,5.67,5.67,0,0,1,212,84.58h4.22v2.59H212c-1.73,0-2.59.82-2.59,2.45v12.46c0,1.63.86,2.45,2.59,2.45H214V96.67h-2.18Z" />
        <path class="cls-2" d="M228.2,87.22h-5.41v7.4h4.63V97h-4.63v7.59h5.41v2.59h-8.28V84.62h8.28Z" />
        <path class="cls-2" d="M241.88,107.21h-3l-2.77-9.31h-2v9.31h-2.82V84.62h4.59a5.82,5.82,0,0,1,4,1.32,5,5,0,0,1,1.41,3.78v3.13c0,2.23-.82,3.73-2.5,4.5Zm-7.87-20v8.09h1.78c1.72,0,2.59-.82,2.59-2.46V89.72c0-1.64-.87-2.46-2.59-2.46H234Z" />
    </g>
</g>
`
]

export const temFooterLogoLight = [
  '899.57 315.21',
  `
  <g transform="matrix(1.3333 0 0 -1.3333 0 315.21)">
    <g transform="scale(.1)" fill="#7e7f7f">
      <path d="m4403.3 2364.1s-28.36 0-28.36-28.35v-490.39s0-28.35 28.36-28.35h83.3s28.34 0 28.34 28.35v490.39s0 28.35-28.34 28.35h-83.3"/>
      <path d="m0.42969 2165.2v-123.23h354.53v-1040.1h144.16v1040.1h355.34v123.23h-854.04"/>
      <path d="m1700 1001.9h-652.15v1163.2h624.95v-123.17h-480.79v-387.13h444.74v-122.33h-444.74v-407.37h507.99v-123.24"/>
      <path d="m1879.6 1049v160.56c19.28-16.3 42.46-30.65 69.79-43.73 27.19-12.98 55.88-24.11 85.71-32.96 30.2-8.81 60.39-15.81 90.58-20.75 30.52-4.72 58.63-7.22 84.11-7.22 88.73 0 154.97 15.58 198.91 46.81 43.63 31.01 65.66 75.65 65.66 134.05 0 31.59-7.36 58.66-22.03 81.95-14.58 23.29-34.78 44.54-60.25 63.7-25.83 19.39-56.25 37.64-91.48 55.12-35.48 17.66-73.25 36.24-113.98 55.66-42.72 20.4-82.86 41.55-120.05 62.53-37.32 21.02-69.47 44.31-96.98 69.79-27.57 25.47-49.04 54.3-64.75 86.41-15.85 32.15-23.75 69.79-23.75 112.96 0 53.13 12.26 99.17 36.83 138.4 24.69 39.37 57.16 71.51 96.98 96.99 40.18 25.47 85.86 44.31 137.18 56.7 50.93 12.53 103.38 18.62 156.41 18.62 121.33 0 209.83-13.57 265.49-41.22v-153.47c-72.66 47.63-166.19 71.41-279.82 71.41-31.66 0-63-2.99-94.58-9.43-31.44-6.08-59.55-16.3-84.12-30.16-24.69-14.12-44.53-32.37-60.02-54.53-15.62-22.16-23.2-49-23.2-81.14 0-29.61 5.87-55.34 17.57-76.91 11.71-21.7 29.02-41.32 51.87-59.35 22.83-17.66 50.97-35.1 83.8-51.76 32.79-16.85 70.93-35.1 113.61-55.31 44.31-20.56 85.86-42.13 125.46-64.84 39.6-22.74 74.14-47.85 103.76-75.31 29.83-27.66 53.24-58.18 70.82-91.69 17.54-33.74 26.15-71.96 26.15-115.14 0-57.35-11.81-106.03-35.46-145.62-23.75-39.95-56.02-72.33-96.17-96.99-40.41-24.92-86.99-42.94-139.47-53.941-52.68-11.16-108.34-16.66-166.65-16.66-19.39 0-43.36 1.371-72.07 4.492-28.46 2.961-57.74 7.098-87.57 12.949-29.61 5.53-57.76 12.75-84.5 21.02-26.61 8.36-47.96 17.79-63.79 28.01"/>
      <path d="m3786 2165.2-455.87-1163.2h-158.87l-446.11 1163.2h160.55l340.68-922.32c10.9-29.62 19.03-64.07 24.88-103.07h3.22c4.72 32.36 14.12 67.25 28.47 104.65l347.75 920.74h155.3"/>
      <path d="m5916.3 1001.9h-638.36v1163.2h144.16v-1040h494.2v-123.24"/>
      <path d="m6736.1 2042h-355.29v-1040.1h-144.16v1040.1h-354.56v123.23h854.01v-123.23"/>
      <path d="m0 502.87v-57.281h84.508v-440.01h62.859v440.01h83.813v57.281h-231.18"/>
      <path d="m447.7 502.87v-218.61h-95.687v218.61h-62.852v-497.29h62.852v221.4h95.687v-221.4h62.16v497.29h-62.16"/>
      <path d="m605.54 502.87v-497.29h182.29v57.27h-119.43v166.93h101.98v53.078h-101.98v162.73h119.43v57.281h-182.29"/>
      <path d="m979.2 502.87v-497.29h182.29v57.27h-119.43v166.93h101.97v53.078h-101.97v162.73h119.43v57.281h-182.29"/>
      <path d="m1413.6 502.87v-355.51l-106.86 355.51h-66.35v-497.29h62.86v355.5l106.86-355.5h66.35v497.29h-62.86"/>
      <path d="m1571.5 502.87v-497.29h182.29v57.27h-119.43v166.93h101.98v53.078h-101.98v162.73h119.43v57.281h-182.29"/>
      <path d="m2068 5.5898-69.15 217.2c36.32 16.77 55.18 50.301 55.18 99.18v69.14c0 72.653-41.9 111.77-119.43 111.77h-101.27v-497.29h62.16v204.64h44l61.46-204.64zm-172.51 440.01h39.11c37.72 0 57.27-18.161 57.27-54.493v-69.14c0-36.309-19.55-54.469-57.27-54.469h-39.11v178.1"/>
      <path d="m2246.8 289.15v-53.078h48.19v-173.21h-43.31c-37.72 0-57.26 18.16-57.26 54.473v273.79c0 36.32 19.54 54.481 57.26 54.481h92.9v57.269h-92.9c-77.52 0-119.42-39.109-119.42-111.75v-273.79c0-72.633 41.9-111.74 119.42-111.74h101.98v283.56h-106.86"/>
      <path d="m2582.8 502.87-57.27-233.28-57.97 233.28h-66.35l93.59-322.68v-174.61h62.16v174.61l92.19 322.68h-66.35"/>
      <path d="m3001.2 129.9c0 122.92-146.67 163.44-146.67 251.43v7c0 37.707 20.25 57.27 57.27 57.27h77.52v57.277h-77.52c-78.92 0-121.52-39.82-121.52-114.55v-7c0-119.41 146.67-160.63 146.67-251.43v-9.777c0-37.711-20.25-57.262-57.27-57.262h-77.52v-57.27h77.52c83.12 0 121.52 39.809 121.52 114.53v9.777"/>
      <path d="m3038.9 502.87v-57.281h84.51v-440.01h62.87v440.01h83.8v57.281h-231.18"/>
      <path d="m3427.9 507.06c-74.72 0-116.64-41.911-116.64-115.24v-276.58c0-73.344 41.92-115.24 116.64-115.24 74.74 0 116.64 41.898 116.64 115.24v276.58c0 73.328-41.9 115.24-116.64 115.24m0-57.27c34.92 0 53.78-20.258 53.78-57.969v-276.58c0-37.722-18.86-57.972-53.78-57.972-35.61 0-54.48 20.25-54.48 57.972v276.58c0 37.711 18.87 57.969 54.48 57.969"/>
      <path d="m3867.2 5.5898-69.14 217.2c36.31 16.77 55.17 50.301 55.17 99.18v69.14c0 72.653-41.9 111.77-119.43 111.77h-101.27v-497.29h62.16v204.64h44l61.46-204.64zm-172.51 440.01h39.11c37.71 0 57.27-18.161 57.27-54.493v-69.14c0-36.309-19.56-54.469-57.27-54.469h-39.11v178.1"/>
      <path d="m3996.4 502.87-92.89-497.29h65.65l17.46 102.68h97.08l17.46-102.68h64.95l-92.89 497.29zm39.11-105.46 39.11-231.89h-78.22z"/>
      <path d="m4326.1 289.15v-53.078h48.19v-173.21h-43.3c-37.73 0-57.28 18.16-57.28 54.473v273.79c0 36.32 19.55 54.481 57.28 54.481h92.89v57.269h-92.89c-77.53 0-119.44-39.109-119.44-111.75v-273.79c0-72.633 41.91-111.74 119.44-111.74h101.97v283.56h-106.86"/>
      <path d="m4521.6 502.87v-497.29h182.3v57.27h-119.44v166.93h101.98v53.078h-101.98v162.73h119.44v57.281h-182.3"/>
      <path d="m4895.3 502.87v-497.29h182.3v57.27h-119.43v166.93h101.97v53.078h-101.97v162.73h119.43v57.281h-182.3"/>
      <path d="m5301.8 502.87-50.99-189.27-50.98 189.27h-65.66l74.04-245.85-81.02-250.73h65.65l57.97 198.36 57.97-198.36h65.66l-80.33 250.73 73.34 245.85h-65.65"/>
      <path d="m5440.8 502.87v-497.29h62.16v178.8h39.11c77.53 0 119.44 39.121 119.44 111.75v94.992c0 72.641-41.91 111.75-119.44 111.75zm158.54-206.74c0-36.32-19.55-54.481-57.27-54.481h-39.11v203.95h39.11c37.72 0 57.27-18.161 57.27-54.481v-94.992"/>
      <path d="m5741.8 502.87v-497.29h182.28v57.27h-119.43v166.93h101.97v53.078h-101.97v162.73h119.43v57.281h-182.28"/>
      <path d="m6238.4 5.5898-69.14 217.2c36.31 16.77 55.18 50.301 55.18 99.18v69.14c0 72.653-41.91 111.77-119.44 111.77h-101.27v-497.29h62.16v204.64h44l61.46-204.64zm-172.51 440.01h39.11c37.73 0 57.27-18.161 57.27-54.493v-69.14c0-36.309-19.54-54.469-57.27-54.469h-39.11v178.1"/>
      <path d="m6264.9 502.87v-57.281h84.51v-440.01h62.86v440.01h83.81v57.281h-231.18"/>
      <path d="m6746.8 129.9c0 122.92-146.67 163.44-146.67 251.43v7c0 37.707 20.25 57.27 57.27 57.27h77.53v57.277h-77.53c-78.92 0-121.53-39.82-121.53-114.55v-7c0-119.41 146.67-160.63 146.67-251.43v-9.777c0-37.711-20.24-57.262-57.27-57.262h-77.52v-57.27h77.52c83.12 0 121.53 39.809 121.53 114.53v9.777"/>
      <path d="m5029 1586.9c0 271.47-173.01 501.12-411.16 577.39v-150.42c159.06-70.44 270.63-235.08 270.63-426.97 0-256-198.58-463.52-443.54-463.52s-443.54 207.52-443.54 463.52c0 191.89 111.58 356.53 270.63 426.97v150.42c-238.15-76.27-411.15-305.92-411.15-577.39 0-333.77 261.49-604.34 584.06-604.34s584.07 270.57 584.07 604.34"/>
    </g>
  </g>
`
]
