<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm class="mt-4" @submit.prevent>
      <CInput
        type="number"
        class="custom-form-label"
        :horizontal="{ label: 'col-12 col-md-5', input: 'col-12 col-md-7' }"
        :value.sync="$v.intervalMinutes.$model"
        :label="$t(`${tBasePath}.intervalMinutes.label`) + $t(`${tBasePath}.intervalMinutes.unit`)"
        :description="$t(`${tBasePath}.intervalMinutes.description`)"
        :placeholder="$t(`${tBasePath}.intervalMinutes.placeholder`)"
        :is-valid="$v.intervalMinutes.$dirty ? !$v.intervalMinutes.$error : null"
        :invalid-feedback="invalidFeedbackFor('intervalMinutes')"
      />
      <CInput
        type="number"
        class="custom-form-label"
        :horizontal="{ label: 'col-12 col-md-5', input: 'col-12 col-md-7' }"
        :value.sync="$v.peakWatts.$model"
        :label="$t(`${tBasePath}.peakWatts.label`) + $t(`${tBasePath}.peakWatts.unit`)"
        :description="$t(`${tBasePath}.peakWatts.description`)"
        :placeholder="$t(`${tBasePath}.peakWatts.placeholder`)"
        :is-valid="$v.peakWatts.$dirty ? !$v.peakWatts.$error : null"
        :invalid-feedback="invalidFeedbackFor('peakWatts')"
      />
      <CInput
        type="number"
        class="custom-form-label"
        :horizontal="{ label: 'col-12 col-md-5', input: 'col-12 col-md-7' }"
        :value.sync="$v.safetyMarginWatts.$model"
        :label="$t(`${tBasePath}.safetyMarginWatts.label`) + $t(`${tBasePath}.safetyMarginWatts.unit`)"
        :description="$t(`${tBasePath}.safetyMarginWatts.description`)"
        :placeholder="$t(`${tBasePath}.safetyMarginWatts.placeholder`)"
        :is-valid="$v.safetyMarginWatts.$dirty ? !$v.safetyMarginWatts.$error : null"
        :invalid-feedback="invalidFeedbackFor('safetyMarginWatts')"
      />
    </CForm>
  </EsLayoutForm>
</template>

<script>
import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import { esRecordedPowerMeasurementPeakShavingFormValidators } from '@/validations/ems-es-form-validators'

export default {
  name: 'EsRecordPowerMeasurementForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      intervalMinutes: null,
      peakWatts: null,
      safetyMarginWatts: null,
      showFailureAlert: false
    }
  },
  computed: {
    invalidFeedbackFor() {
      return (field) => {
        const isInvalid = (condition) => {
          return !this.$v[field][condition]
        }
        const base = `ems.energyService.config.form.recordedPowerMeasurementPeakShaving.${field}.validation.invalidFeedback`
        const conditions = ['required', 'positive']
        let feedback = ''
        for (const c of conditions) {
          if (isInvalid(c)) {
            feedback = this.$t(`${base}.${c}`)
            break
          }
        }

        return feedback
      }
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.form.recordedPowerMeasurementPeakShaving'
    this.init()
  },
  validations: esRecordedPowerMeasurementPeakShavingFormValidators,
  methods: {
    init() {
      // should NOT set 0 for any init value
      if (this.initValues.peakWatts) {
        this.peakWatts = this.initValues.peakWatts
      }
      if (!this.initValues.intervalSeconds) {
        this.intervalMinutes = 15 // a good default
      } else if (this.initValues.intervalSeconds > 0) {
        this.intervalMinutes = this.initValues.intervalSeconds / 60
      }
      if (!this.initValues.safetyMarginWatts && this.initValues.peakWatts) {
        this.safetyMarginWatts = 0.02 * this.initValues.peakWatts // a good default
      } else if (this.initValues.safetyMarginWatts) {
        this.safetyMarginWatts = this.initValues.safetyMarginWatts
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const esParams = {
        safetyMarginWatts: parseFloat(this.safetyMarginWatts)
      }
      const defaultPeakObserver = {
        intervalSeconds: 60 * parseFloat(this.intervalMinutes),
        peakWatts: parseFloat(this.peakWatts)
      }
      this.$emit('submit', { esParams, defaultPeakObserver })
    }
  }
}
</script>
