// source: ext/ems/options/ems_options.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.de.mypowergrid.ems.options.DataObjectType', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.options.EmsFieldOptions', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv', null, global);
goog.exportSymbol('proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.options.EmsFieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.options.EmsFieldOptions.displayName = 'proto.de.mypowergrid.ems.options.EmsFieldOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.displayName = 'proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.displayName = 'proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyServiceApplicationEnv: (f = msg.getEnergyServiceApplicationEnv()) && proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.toObject(includeInstance, f),
    quantityOption: (f = msg.getQuantityOption()) && proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.options.EmsFieldOptions;
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv;
      reader.readMessage(value,proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.deserializeBinaryFromReader);
      msg.setEnergyServiceApplicationEnv(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions;
      reader.readMessage(value,proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.deserializeBinaryFromReader);
      msg.setQuantityOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.options.EmsFieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyServiceApplicationEnv();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.serializeBinaryToWriter
    );
  }
  f = message.getQuantityOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.toObject = function(includeInstance, msg) {
  var f, obj = {
    suitableForSwitch: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    suitableForPower: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    requiresSourceInDeviceTopology: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    requiresPowerTraderRemoteControl: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv;
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuitableForSwitch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuitableForPower(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresSourceInDeviceTopology(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresPowerTraderRemoteControl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuitableForSwitch();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSuitableForPower();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRequiresSourceInDeviceTopology();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRequiresPowerTraderRemoteControl();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool suitable_for_switch = 1;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.getSuitableForSwitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.setSuitableForSwitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool suitable_for_power = 2;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.getSuitableForPower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.setSuitableForPower = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool requires_source_in_device_topology = 3;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.getRequiresSourceInDeviceTopology = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.setRequiresSourceInDeviceTopology = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool requires_power_trader_remote_control = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.getRequiresPowerTraderRemoteControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv.prototype.setRequiresPowerTraderRemoteControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeTypeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataObjectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataObjectType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sensorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allowMultipleInstancesPerSource: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions;
  return proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeTypeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataObjectName(value);
      break;
    case 3:
      var value = /** @type {!proto.de.mypowergrid.ems.options.DataObjectType} */ (reader.readEnum());
      msg.setDataObjectType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensorName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstancesPerSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeTypeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSensorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllowMultipleInstancesPerSource();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string node_type_name = 1;
 * @return {string}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.getNodeTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.setNodeTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_object_name = 2;
 * @return {string}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.getDataObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.setDataObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DataObjectType data_object_type = 3;
 * @return {!proto.de.mypowergrid.ems.options.DataObjectType}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.getDataObjectType = function() {
  return /** @type {!proto.de.mypowergrid.ems.options.DataObjectType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.de.mypowergrid.ems.options.DataObjectType} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.setDataObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string sensor_name = 4;
 * @return {string}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.getSensorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.setSensorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool allow_multiple_instances_per_source = 5;
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.getAllowMultipleInstancesPerSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions.prototype.setAllowMultipleInstancesPerSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional EnergyServiceApplicationEnv energy_service_application_env = 1;
 * @return {?proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.getEnergyServiceApplicationEnv = function() {
  return /** @type{?proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv, 1));
};


/**
 * @param {?proto.de.mypowergrid.ems.options.EmsFieldOptions.EnergyServiceApplicationEnv|undefined} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions} returns this
*/
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.setEnergyServiceApplicationEnv = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.clearEnergyServiceApplicationEnv = function() {
  return this.setEnergyServiceApplicationEnv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.hasEnergyServiceApplicationEnv = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QuantityOptions quantity_option = 2;
 * @return {?proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.getQuantityOption = function() {
  return /** @type{?proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions, 2));
};


/**
 * @param {?proto.de.mypowergrid.ems.options.EmsFieldOptions.QuantityOptions|undefined} value
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions} returns this
*/
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.setQuantityOption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.ems.options.EmsFieldOptions} returns this
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.clearQuantityOption = function() {
  return this.setQuantityOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.ems.options.EmsFieldOptions.prototype.hasQuantityOption = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.de.mypowergrid.ems.options.DataObjectType = {
  INVALID: 0,
  DPL: 3,
  MV: 4,
  BCR: 5,
  WYE: 6,
  ASG_SP: 7,
  ASG_SG: 8,
  ENS_DS: 9,
  ENS_GRIDSTATE: 10,
  ENG_SP_MODECTRL: 11,
  TSG_SG: 12,
  SPS: 13,
  SAV: 14,
  DPC: 15,
  SPC: 16,
  DEL: 17,
  INS: 18,
  ENS_INVERTERSTATE: 19,
  ENS_BATTERYSTATE: 20,
  ENS_CONNTYP: 21,
  ENS_DERTYP: 22,
  ENS_WALLBOXSTATE: 23,
  ENS_HEATPUMPOPMODE: 24,
  ENG_HEATPUMPOPMODE: 25,
  VSS: 26,
  ENC_GRIDSTATE: 27,
  ENS_MATTYP: 28,
  ENC_EMS_ERROR: 29,
  ENS_EXTERNAL_SETPOINT_TARGET: 30,
  ENS_PCS_CCU_STATE: 31,
  ENS_PCS_IPU_STATE: 32,
  ENS_PCS_MCU_STATE: 33,
  ENS_GATEWAY_STATE: 34,
  GATEWAY_DISCOVERY: 35,
  ENS_EZA_STATE: 36,
  ENC_DS: 37,
  ENS_FIREALARMSTATE: 38,
  ENS_AIR_CONDITIONER_STATE: 39,
  ENS_BEHAVIOUR_MODE: 40,
  DPS: 41,
  ENS_KACO_INV_STATE: 42,
  ENS_KACO_PCU_STATE: 43,
  ENS_KACO_PCU_ERR_STATE: 44
};

goog.object.extend(exports, proto.de.mypowergrid.ems.options);
