/** @module */

import { createUnaryCall } from '@/grpc'
import * as GpioPb from '@/../lib/proto_js/ext/sherlock/gpio_pb'
import { GPIOService } from '@/../lib/proto_js/ext/sherlock/gpio_pb_service'
import * as NetworkPb from '@/../lib/proto_js/ext/sherlock/network_pb'
import { NetworkService } from '@/../lib/proto_js/ext/sherlock/network_pb_service'
import * as OneWirePb from '@/../lib/proto_js/ext/sherlock/one_wire_pb'
import { OneWireService } from '@/../lib/proto_js/ext/sherlock/one_wire_pb_service'
import { evalServiceError } from '@/api/error-handling'

// GPIOs
export function fetchGpio({ method, payload }) {
  const gpio = createUnaryCall({
    service: GPIOService,
    method: method,
    payload: payload
  })

  return gpio.perform().then(evalServiceError)
}

// due to historical reasons this wrapper exists
// might be refactored
export function fetchGpioDigitalInputInfo() {
  return fetchGpio({
    method: 'GetGPIOInfo',
    payload: new GpioPb.GetGPIOInfoRequest([GpioPb.GPIOSubsystem.DIGITAL_INPUTS])
  })
}

// due to historical reasons this wrapper exists
// might be refactored
export function fetchGpioDigitalOutputInfo() {
  return fetchGpio({
    method: 'GetGPIOInfo',
    payload: new GpioPb.GetGPIOInfoRequest([GpioPb.GPIOSubsystem.DIGITAL_OUTPUTS])
  })
}

// due to historical reasons this wrapper exists
// might be refactored
export function fetchGpioDryContactInputInfo() {
  return fetchGpio({
    method: 'GetGPIOInfo',
    payload: new GpioPb.GetGPIOInfoRequest([GpioPb.GPIOSubsystem.DRY_CONTACTS])
  })
}

// Networks
export function fetchNetwork({ method, payload }) {
  const network = createUnaryCall({
    service: NetworkService,
    method: method,
    payload: payload
  })

  return network.perform().then(evalServiceError)
}

function buildNetwork(networkType) {
  const network = new NetworkPb.Network()
  network.setType(networkType)

  return network
}

function buildGetNetworkInfoRequest(networkType) {
  const req = new NetworkPb.GetNetworkInfoRequest()
  req.setNetwork(buildNetwork(networkType))

  return req
}

function buildCheckConnectivityRequest(networkType) {
  const req = new NetworkPb.CheckConnectivityRequest()
  req.setNetwork(buildNetwork(networkType))

  return req
}

/**
 * @function
 *
 * @param {object} a Protobuf Msg Enum `Network.Type`, e.g. `PROPERTY`
 *
 * @return {Promise}
 */
export function fetchNetworkInfo(networkType) {
  return fetchNetwork({
    method: 'GetNetworkInfo',
    payload: buildGetNetworkInfoRequest(networkType)
  })
}

/**
 * @function
 *
 * @param {object} a Protobuf Msg Enum `Network.Type`, e.g. `PROPERTY`
 *
 * @return {Promise}
 */
export function fetchNetworkConnectivity(networkType) {
  return fetchNetwork({
    method: 'CheckConnectivity',
    payload: buildCheckConnectivityRequest(networkType)
  })
}

/**
 * gRPC unary call to GetKnownDevices
 *
 * @function
 *
 * @return {promise}
 */
export function fetchKnownDevices() {
  return fetchNetwork({
    method: 'GetKnownDevices',
    payload: new NetworkPb.GetKnownDevicesRequest()
  })
}

// OneWires
export function fetchOneWire({ method, payload }) {
  const oneWire = createUnaryCall({
    service: OneWireService,
    method: method,
    payload: payload
  })

  return oneWire.perform().then(evalServiceError)
}

export function fetchOneWireInfo() {
  return fetchOneWire({
    method: 'GetOneWireInfo',
    payload: new OneWirePb.GetOneWireInfoRequest()
  })
}
