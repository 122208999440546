<template>
  <div>
    <apx-interface-card
      key="OneWires"
      :description-as-array="getOneWireAllAsArray"
      :is-loading="isLoading"
      :has-error="hasError"
      :type="$t('interfaces.oneWire.owSensors')"
      allow-reload
      @reload="fetchOneWire()"
    />
  </div>
</template>

<script>
import ApxInterfaceCard from '@/components/apx-interface-card'
import { mapGetters } from 'vuex'

export default {
  name: 'OneWire',
  components: { ApxInterfaceCard },
  data() {
    return {
      isLoading: false,
      hasError: false
    }
  },
  computed: {
    ...mapGetters('apxInterfaces', ['getOneWireAllAsArray'])
  },
  mounted() {
    this.fetchOneWire()
  },
  methods: {
    fetchOneWire() {
      this.isLoading = true
      const onSuccess = (res) => {
        this.isLoading = false
      }
      const onError = (err) => {
        this.$log.warn(err)
        this.isLoading = false
        this.hasError = true

        let msg = ''
        if (err.code === 1) {
          msg = this.$t('api.errors.gRPCcode1')
        } else {
          msg = this.$t('api.errors.gRPCcode2')
        }
        const content = this.$t('api.errors.base_msg', ['OneWire', err.code, msg])
        const errMessage = {
          title: 'OneWire',
          type: 'toast-warning',
          content: content
        }
        this.$store.commit('notifications/PUSH_TOAST', errMessage)
      }
      this.$store
        .dispatch('apxInterfaces/fetchInterfaceInfo', {
          interfaceId: 'onewires',
          interfaceType: 'oneWireAll'
        })
        .then(onSuccess)
        .catch(onError)
    }
  }
}
</script>

<style scoped></style>
