<template>
  <div class="eval-expression-tree-container">
    <EvalTreeEntry
      :key="`eval-exp-entry-${parsedEvalTree.idx}`"
      :append-menu-to-body="appendMenuToBody"
      :entry="parsedEvalTree"
      :preview="preview"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EvalTreeEntry from './eval-tree-entry'

export default {
  name: 'EvalTree',
  components: {
    EvalTreeEntry
  },
  props: {
    preview: {
      type: Boolean,
      required: false,
      default: false
    },
    appendMenuToBody: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('emsEvalExpression', ['parsedEvalTree'])
  },
  mounted() {
    this.$store.dispatch('emsEvalAliases/getAliases').catch((err) => {
      this.$log.error(err)
    })
  }
}
</script>
