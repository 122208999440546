/**
 * Getters for the [ems-energy-services-config store]{@link module:store/ems-energy-services-config}
 *
 * @module store/ems-energy-services-config-getters
 */

import { camelCase, isEmpty, isEqual, startCase } from 'lodash'
import i18n from '@/i18n'

import { EMS_DEFAULT_PEAK_OBSERVER_NAME } from '@/api/ems/peak-observer'
import { EMS_ENERGY_SERVICE_IDS } from '@/grpc/protobuf/ems-scontroller-helper'
import {
  determineActuatorGroupParametrizationType,
  determineActuatorGroupType,
  iterateDecisionTree,
  SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES,
  SUPPORTED_EMS_ENERGY_SERVICES,
  uniqueEnergyServiceNamesFromEnergyServices
} from '@/store/modules/_ems-energy-services-config-helper'
import { EMS_ENERGY_SERVICE_NONE_ID, getNewEnergyService } from '@/view-helper/ems/ems-energy-service-helper'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { translatePhysicalDevice } from '@/view-helper/ems/ems-topology-helper'

export const actuatorGroupIds = (state) => {
  return state.actuatorGroupIds
}

export const getActuatorGroup = (state) => {
  return (id) => {
    const j = state.actuatorGroupIds.findIndex((i) => i === id)
    if (j > -1) {
      return state.actuatorGroups[j]
    } else {
      return null
    }
  }
}

export const getActuatorGroupType = (state, getter) => {
  return (id) => {
    return determineActuatorGroupType(getter.getActuatorGroup(id) || {})
  }
}

/**
 * Finds the "trivial" actuator-group IDs by its actuator (setpoint-source).
 * If not found, empty list is returned.
 *
 * Optionally, a filter with the actuator group's type can be employed.
 */
export const getActuatorGroupIdsByActuator = (state) => {
  return ({ setpointSource, type }) => {
    const spSrcLiteral = iecIdToLiteral(setpointSource.iec)
    const idxs = []
    state.actuatorGroups.forEach((ag, i) => {
      if (
        ag.setpointSourcesList.length !== 1 ||
        !isEqual(spSrcLiteral, iecIdToLiteral(ag.setpointSourcesList[0].iec)) ||
        (type && determineActuatorGroupType(ag) !== type)
      ) {
        return
      }

      idxs.push(i)
    })

    const ids = []
    idxs.forEach((i) => {
      ids.push(state.actuatorGroupIds[i])
    })

    return ids
  }
}

/**
 * Finds the actuator-group IDs by its actuators.
 * The order of actuators does not matter.
 * Actuators are identified by their ID.
 * If not found, empty list is returned.
 *
 * Optionally, a filter with the actuator group's type can be employed.
 *
 */
export const getActuatorGroupIdsByActuators = (state) => {
  return ({ setpointSources, type }) => {
    const spSrcLiterals = setpointSources.map((s) => iecIdToLiteral(s.iec)).sort()
    const idxs = []
    state.actuatorGroups.forEach((ag, i) => {
      const agSpSrcLiterals = ag.setpointSourcesList.map((s) => iecIdToLiteral(s.iec)).sort()

      if (!isEqual(spSrcLiterals, agSpSrcLiterals) || (type && determineActuatorGroupType(ag) !== type)) {
        return
      }

      idxs.push(i)
    })

    const ids = []
    idxs.forEach((i) => {
      ids.push(state.actuatorGroupIds[i])
    })

    return ids
  }
}

export const getActuatorGroupIndex = (state) => {
  return (id) => {
    const j = state.actuatorGroupIds.findIndex((i) => i === id)
    if (j > -1) {
      return j
    } else {
      return null
    }
  }
}

/**
 * Getter to get a list of
 * "humanized" `ems.scontroller.ActuatorStrategies.ActuatorGroups` members.
 *
 * Takes the ActuatorGroup-Id and returns a
 * array of `{device: "...", physicalDevice: "...", ldn: "...", literal: "..."}` objects.
 *
 * @function
 *
 *
 * @return {function}
 */
export const humanizeAGMembers = (state, getters, _rootState, rootGetters) => {
  return (gId) => {
    const ag = getters.getActuatorGroup(gId)
    if (!ag) {
      return ''
    }

    const labels = []
    ag.setpointSourcesList.forEach((src) => {
      let l = ''
      let ldn = ''
      if (src.iec) {
        ldn = src.iec.logicalDeviceName
        l = src.iec.iecNodePrefix
        const k = `ems.iec.${l}`
        if (i18n.te(k)) {
          l = i18n.t(k)
        } else {
          l = startCase(l)
        }
        // l += ' ' + src.iec.iecNodePostfix
      }
      const pd = rootGetters['emsTopologyConfig/getPhysicalDeviceOfSourceToDeviceTopology'](src.iec)
      labels.push({
        device: l,
        physicalDevice: pd ? translatePhysicalDevice(pd) : null,
        ldn,
        literal: iecIdToLiteral(src.iec)
      })
    })

    return labels
  }
}

/**
 * Getter to get a list of all (setpoint) sources of the actuator-groups.
 *
 * The list is a set, i.e. each element is uniquely.
 *
 * @function
 *
 * @return {array}
 */
export const setpointSources = (state) => {
  const srcs = []
  state.actuatorGroups.forEach((ag) => {
    ag.setpointSourcesList.forEach((src) => {
      if (srcs.findIndex((s) => isEqual(s.iec, src.iec)) < 0) {
        srcs.push(src)
      }
    })
  })

  return srcs
}

/**
 * Getter to get a list of all setpoint sources of the actuator-groups filtered by it's setpoint type, e.g. `POWER` or `SWITCH`.
 *
 * @function
 *
 */
export const getSetpointSourcesByType = (_state, getter) => {
  return (type) => {
    return getter.setpointSources.filter((src) => {
      return getter.sourceIsAllowedForActuatorGroupType({ iecId: src.iec, actuatorGroupType: type })
    })
  }
}

export const strategyIds = (state) => {
  return state.strategyIds
}

export const getStrategy = (state) => {
  return (id) => {
    const j = state.strategyIds.findIndex((i) => i === id)
    if (j > -1) {
      return state.strategies[j]
    } else {
      return null
    }
  }
}

export const getStrategyIndex = (state) => {
  return (id) => {
    const j = state.strategyIds.findIndex((i) => i === id)

    if (j > -1) {
      return j
    } else {
      return null
    }
  }
}

export const getStrategyActuatorGroupIds = (state, getter) => {
  return (id) => {
    const strg = getter.getStrategy(id)
    const agIds = []

    if (!strg) {
      return agIds
    }

    strg.forEach((es, i) => {
      if (es) {
        agIds.push(state.actuatorGroupIds[i])
      }
    })

    return agIds
  }
}

/**
 * Getter which takes a strategy ID,
 * and returns the list of setpoint-sources (array of `topology.Source`) of this strategy.
 * The setpoint-sources are extracted from the strategy's actuator-groups.
 * A setpoint-source is included only once.
 * Optionally, a filter with the actuator-group's type, e.g. `POWER` or `SWITCH`, can be applied
 *
 */
export const getStrategySetpointSources = (state, getter) => {
  return (id, { type } = {}) => {
    const strg = getter.getStrategy(id) // list of energy-services

    if (strg == null) {
      return []
    }

    const srcs = {}
    strg.forEach((es, i) => {
      if (!es) {
        return
      }

      const ag = state.actuatorGroups[i]
      ag.setpointSourcesList.forEach((src) => {
        const l = iecIdToLiteral(src.iec)
        if (type) {
          if (determineActuatorGroupType(ag) === type) {
            srcs[l] = src
          }
        } else {
          srcs[l] = src
        }
      })
    })

    return Object.values(srcs)
  }
}

export const getEnergyService = (state, getter) => {
  return ({ strategyId, actuatorGroupId }) => {
    const i = getter.getStrategyIndex(strategyId)
    const j = getter.getActuatorGroupIndex(actuatorGroupId)
    if (i === null || j === null) {
      return null
    }

    return state.strategies[i][j]
  }
}

export const getEnergyServiceId = (state, getter) => {
  return ({ strategyId, actuatorGroupId }) => {
    const es = getter.getEnergyService({ strategyId, actuatorGroupId })
    if (!es) {
      return null
    }
    const esId = EMS_ENERGY_SERVICE_IDS.find((i) => !!es[i])
    if (!esId) {
      return EMS_ENERGY_SERVICE_NONE_ID
    }

    return esId
  }
}

export const getEnergyServicePreselection = (state, getter) => {
  return ({ strategyId, actuatorGroupId }) => {
    const i = getter.getStrategyIndex(strategyId)
    const j = getter.getActuatorGroupIndex(actuatorGroupId)
    if (i === null || j === null) {
      return null
    }

    return state.strategyPreselections[i][j]
  }
}

/**
 * Getter to extract the parameters for an `ems.scontroller.EnergyService` oneof field.
 *
 * Expects `{ strategyId, actuatorGroupId, energyServiceId }` as input.
 *
 * Note:
 * - `energyServiceId` = the oneof field-name
 * - some params (View forms) are extendend by additional data
 *
 * @function
 *
 * @return {function}
 */
export const getEnergyServiceParams = (state, getter) => {
  return ({ strategyId, actuatorGroupId, energyServiceId }) => {
    let es = getter.getEnergyService({ strategyId, actuatorGroupId })

    if (!es || !es[energyServiceId]) {
      es = getNewEnergyService({ energyServiceType: energyServiceId, asPlain: true })
    }

    if (es.recordedPowerMeasurementPeakShaving) {
      return Object.assign({}, es.recordedPowerMeasurementPeakShaving, getter.defaultPeakObserver || {})
    }

    return es[energyServiceId]
  }
}

/**
 * Getter to extract the translated Energy Service Name for a
 * `ems.scontroller.EnergyService` oneof field.
 *
 * Takes into account current pre-seclection.
 *
 * Expects `{ strategyId, actuatorGroupId }` as input.
 *
 * @function
 *
 * @return {function}
 */
export const getEnergyServiceName = (state, getter) => {
  return ({ strategyId, actuatorGroupId }) => {
    const esId = getter.getEnergyServiceId({ strategyId, actuatorGroupId })
    const esPreselection = getter.getEnergyServicePreselection({ strategyId, actuatorGroupId })

    if (!esId || esId === '_no-energy-service') {
      return ''
    }

    if (!esPreselection || esPreselection === 'ADVANCED') {
      return i18n.t(`ems.energyService.config.form.${esId}.selectLabel`)
    }
    return i18n.t(`ems.energyService.config.form.${esId}.${camelCase(esPreselection)}`)
  }
}

export const getEnergyServiceOpts = (state, getter) => {
  const tBasePath = 'ems.energyService.config.form'
  return ({ strategyId, actuatorGroupId, strategyIdBlacklist = [] }) => {
    const opts = [
      {
        value: EMS_ENERGY_SERVICE_NONE_ID,
        label: i18n.t(`${tBasePath}._no-energy-service.selectLabel`)
      }
    ]
    const allowedEnergyServiceNames = getter.getSuitableEnergyServiceNamesForActuatorGroupId({ actuatorGroupId })

    EMS_ENERGY_SERVICE_IDS.filter((id) => {
      if (!allowedEnergyServiceNames.includes(id)) {
        return false
      }

      if (strategyIdBlacklist.includes(id)) {
        return false
      }

      const agType = getter.getActuatorGroupType(actuatorGroupId)
      const allowedIds = SUPPORTED_EMS_ENERGY_SERVICES[agType]
      if (!allowedIds?.length) {
        return false
      }

      return allowedIds.includes(id)
    }).forEach((id) => {
      opts.push({
        value: id,
        label: i18n.t(`${tBasePath}.${id}.selectLabel`)
      })
    })

    const esId = getter.getEnergyServiceId({ strategyId, actuatorGroupId })
    const esPreselection = getter.getEnergyServicePreselection({ strategyId, actuatorGroupId })
    if (!esId || !esPreselection) {
      return opts
    }

    if (esPreselection === 'ADVANCED') {
      return opts
    }

    const opt = opts.find((o) => o.value === esId)
    if (!opt) {
      return
    }

    opt.label =
      i18n.t(`${tBasePath}.${esId}.selectLabel`) +
      ' (' +
      i18n.t(`${tBasePath}.${esId}.${camelCase(esPreselection)}`) +
      ')'

    return opts
  }
}

export const getPeakObserver = (state) => {
  return (name) => {
    return state.peakObservers[name]
  }
}

export const defaultPeakObserver = (state) => {
  return state.peakObservers[EMS_DEFAULT_PEAK_OBSERVER_NAME]
}

export const isDefaultStrategy = (state) => {
  return (strategyId) => {
    return state.defaultStrategyId === strategyId
  }
}

export const hasDecisionTree = (state) => {
  return !isEmpty(state.decisionTree)
}

export const isDecisionTreeLeaf = (state) => {
  return (strategyId) => {
    let isLeaf = false
    iterateDecisionTree(state.decisionTree, (entry, idx) => {
      if (!entry.isNode && entry.params.strategyId === strategyId) {
        isLeaf = true
        return false
      }
      return true
    })

    return isLeaf
  }
}

export const isDecisionTreeNodeFallbackOption = (state) => {
  return (strategyId) => {
    let isOption = false
    iterateDecisionTree(state.decisionTree, (entry, idx) => {
      const optionsList = entry?.node?.optionsList

      if (!optionsList) return true
      for (const opt of optionsList) {
        if (opt.filterDefault && opt.filterDefault.defaultStrategy === strategyId) {
          isOption = true
          return false
        }
      }
      return true
    })

    return isOption
  }
}

export const decisionTreeNodeIdxs = (state) => {
  const idxs = []
  iterateDecisionTree(state.decisionTree, (entry, idx) => {
    if (entry.isNode) {
      idxs.push(idx)
    }
    return true
  })

  return idxs
}

export const decisionTreeLeafIdxs = (state) => {
  const idxs = []
  iterateDecisionTree(state.decisionTree, (entry, idx) => {
    if (!entry.isNode && typeof entry.params.strategyId === 'string') {
      idxs.push(idx)
    }
    return true
  })

  return idxs
}

export const parsedDecisionTree = (state) => {
  return iterateDecisionTree(state.decisionTree)
}

export const getDecisionTreeEntry = (state) => {
  return (idx) => {
    let entry = null
    iterateDecisionTree(state.decisionTree, (e, i) => {
      if (i === idx) {
        entry = e
        return false
      }
      return true
    })

    return entry
  }
}

export const getCache = (state) => {
  return (key) => {
    return state.cache[key]
  }
}

// For a given DecisionTreeEntry ID
// Search through the optionsList for a set fallback strategy
// return [null || 'filterLast' || strategyId]
export const getDecisionTreeEntryFallbackStrategy = (state) => {
  return (idx) => {
    const entry = getDecisionTreeEntry(state)(idx)

    const optionsList = entry?.node?.optionsList

    for (const opt of optionsList) {
      if (Object.keys(opt).includes('filterDefault') && opt.filterDefault?.defaultStrategy !== undefined) {
        return opt.filterDefault.defaultStrategy
      } else if (Object.keys(opt).includes('filterLast') && opt.filterLast !== undefined) {
        return 'filterLast'
      }
    }

    return null
  }
}

export const nodeCount = (state, getter) => {
  return getter.decisionTreeNodeIdxs.length
}

export const getStrategyCountForIecNodePrefix = (state, getter) => {
  return ({ strategyId, iecNodePrefix }) => {
    const matchingActuatorIndexes = []

    for (const [i, actuatorGroup] of state.actuatorGroups.entries()) {
      if (!actuatorGroup.setpointSourcesList) continue

      for (const source of actuatorGroup.setpointSourcesList) {
        if (source?.iec?.iecNodePrefix === iecNodePrefix) {
          matchingActuatorIndexes.push(i)
        }
      }
    }
    const strategy = getter.getStrategy(strategyId)

    let matchingStrategies = 0
    for (const matchingActuatorIndex of matchingActuatorIndexes) {
      if (strategy?.[matchingActuatorIndex]) {
        matchingStrategies += 1
      }
    }

    return matchingStrategies
  }
}

export const actuatorGroupIncludesIecNodePrefix = (state, getter) => {
  return ({ actuatorGroupId, iecNodePrefix }) => {
    const actuatorGroup = getter.getActuatorGroup(actuatorGroupId)

    if (!actuatorGroup?.setpointSourcesList) return false

    for (const source of actuatorGroup.setpointSourcesList) {
      if (source?.iec?.iecNodePrefix === iecNodePrefix) {
        return true
      }
    }

    return false
  }
}

export const getSuitableEnergyServicesForActuatorGroupId = (state, getter) => {
  return ({ actuatorGroupId }) => {
    return state.suitableEnergyServices[getter.getActuatorGroupIndex(actuatorGroupId)]
  }
}

export const getSuitableEnergyServiceNamesForActuatorGroupId = (state, getter) => {
  return ({ actuatorGroupId }) => {
    const energyServices = getter.getSuitableEnergyServicesForActuatorGroupId({ actuatorGroupId })

    return uniqueEnergyServiceNamesFromEnergyServices(energyServices)
  }
}

/**
 * Getter has arg: `{object} params`
 * {string} params.actuatorGroupParametrizationType is any oneof of ActuatorGroup, including 'none'
 * Getter returns: {string}[] sourceLiterals
 *
 * @function
 *
 * @param
 *
 * @return {function}
 */
export const getSetpointSourceLiteralsForActuatorGroupParametrizationType = (state, getter) => {
  return ({ actuatorGroupParametrizationType }) => {
    const sourceIds = []

    for (const setpointSourceLiteral in state.setpointSourcesToActuatorGroups) {
      const actuatorGroupParametrizationTypes = getter.getActuatorGroupParametrizationTypesForSetpointSourceLiteral({
        setpointSourceLiteral
      })

      if (actuatorGroupParametrizationTypes.includes(actuatorGroupParametrizationType)) {
        sourceIds.push(setpointSourceLiteral)
      }
    }

    return sourceIds.sort()
  }
}

/**
 * Getter has arg: `{object} params`
 * {string} params.setpointSourceLiteral
 * Getter returns: {string}[] actuatorGroupParametrizationTypes
 *
 * @function
 *
 * @param
 *
 * @return {function}
 */
export const getActuatorGroupParametrizationTypesForSetpointSourceLiteral = (state) => {
  return ({ setpointSourceLiteral }) => {
    if (!state.setpointSourcesToActuatorGroups[setpointSourceLiteral]) {
      return []
    }

    const actuatorGroupParametrizationTypes = []
    for (const actuatorGroup of state.setpointSourcesToActuatorGroups[setpointSourceLiteral]) {
      actuatorGroupParametrizationTypes.push(determineActuatorGroupParametrizationType(actuatorGroup))
    }

    return actuatorGroupParametrizationTypes
  }
}

/**
 * Allows to ask, if a source or logical device has the requested setpoint quantity.
 *
 * `{ems.topology.Source.IecId} iecId` is the IEC ID of a source.
 * `{string} actuatorGroupType` is one of: `POWER`, `SWITCH`
 *
 * @function
 *
 * @return {function}
 */
export const sourceIsAllowedForActuatorGroupType = (state, getter) => {
  return ({ iecId, actuatorGroupType }) => {
    const supportedActuatorGroupParametrizationTypes = SUPPORTED_ACTUATOR_GROUP_PARAMETRIZATION_TYPES[actuatorGroupType]

    if (!supportedActuatorGroupParametrizationTypes) return false

    if (actuatorGroupType === 'POWER') {
      // empty ActuatorGroup has no oneOf set, yet describes a valid POWER ActuatorGroup
      supportedActuatorGroupParametrizationTypes.push('none')
    }

    const setpointSourceLiteral = iecIdToLiteral(iecId)

    const actuatorGroupParametrizationTypes = getter.getActuatorGroupParametrizationTypesForSetpointSourceLiteral({
      setpointSourceLiteral
    })

    return actuatorGroupParametrizationTypes.some((parametrizationType) =>
      supportedActuatorGroupParametrizationTypes.includes(parametrizationType)
    )
  }
}
