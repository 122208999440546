/**
 * @module validations/ems-es-strategy-validators
 */

import { helpers, required } from 'vuelidate/lib/validators'
import { startCase } from 'lodash'
import i18n from '@/i18n'
import { EMS_ENERGY_SERVICE_IDS } from '@/grpc/protobuf/ems-scontroller-helper'
import { determineActuatorGroupType } from '@/store/modules/_ems-energy-services-config-helper'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { EMS_ENERGY_SERVICE_NEW_STRATEGY_ID } from '@/view-helper/ems/ems-energy-service-helper'

/**
 * Factory to create a new EMS Energy Service Strategy form validator
 *
 * @function
 *
 * @param {object} params
 * @param {array} params.existingNames (optional) is a list of other EMS Energy Service Strategy names (IDs)
 *
 * @return {object} with own properties matching the form field names
 */
export function newStrategyFormValidators(params) {
  return {
    name: {
      required,
      words: helpers.regex('words', /^(\w|\s|-)+$/),
      unique: helpers.withParams({ type: 'uniqueness' }, (n) => {
        const names = params.existingNames || []
        return !names.includes(n)
      }),
      reserved: helpers.withParams({ type: 'reserved' }, (n) => {
        return n !== EMS_ENERGY_SERVICE_NEW_STRATEGY_ID
      })
    }
  }
}

export const defaultStrategyFormValidators = {
  defaultStrategyId: {
    required
  }
}

/**
 * Allows to validate the correctness of the `ems.scontroller.ActuatorStrategies`.
 * The actually validated data structure is the `emsEnergyServiceConfig` store data.
 *
 * Should not be used with vuelidate.
 *
 * @constructor
 *
 */
export function ActuatorStrategiesValidator() {
  function ValidationError(params) {
    this.targetId = params.targetId
    this.type = params.type
  }

  this.strategyErrors = []
  this.actuatorGroupErrors = []

  this.isValid = () => {
    return this.strategyErrors.length + this.actuatorGroupErrors.length === 0
  }

  /**
   * Validates the data of the `ems.ActuatorStrategies.Strategy` map.
   * Either a valid `ems.topology.scontroller.EnergyService` (having a oneof),
   * or `null` is allowed.
   *
   * @function
   *
   * @param {object} params
   * @param {array} params.actuatorGroupIds
   * @param {array} params.strategyIds
   * @param {array} params.actuatorGroups
   * @param {function} params.getStrategy has to return the strategy (list of energy-service)
   *
   * @return {boolean}
   */
  this.validateStrategies = ({ actuatorGroupIds, strategyIds, actuatorGroups, getStrategy }) => {
    const validators = [
      {
        type: 'hasName',
        validate: (sId) => !!sId
      },
      {
        type: 'required',
        validate: (sId) => !!getStrategy(sId)
      },
      {
        type: 'hasAgEsPairs',
        validate: (sId) => {
          const strg = getStrategy(sId)

          // Note: does not check, that strategy actually has an energy-service. Entry with `null` energy-service passes.
          return strg.length === actuatorGroupIds.length
        }
      },
      {
        type: 'hasNonEmptyEnergyServices',
        validate: (sId) => {
          let valid = true
          const strg = getStrategy(sId)

          // will pass for `null`
          for (const es of strg) {
            valid =
              valid &&
              (!es ||
                EMS_ENERGY_SERVICE_IDS.some((id) => {
                  return es[id]
                }))

            if (!valid) {
              break
            }
          }

          return valid
        }
      },
      {
        type: 'multiUseActuator',
        validate: (sId) => {
          const strg = getStrategy(sId)
          const usedSetpointSources = {
            POWER: {},
            SWITCH: {},
            NONE: {}
          }
          actuatorGroups.forEach((ag, i) => {
            // skip un-used AG (having `null` energy-service)
            if (!strg[i]) {
              return
            }

            ag.setpointSourcesList.forEach((src) => {
              const type = determineActuatorGroupType(ag)
              usedSetpointSources[type][iecIdToLiteral(src.iec)] =
                usedSetpointSources[type][iecIdToLiteral(src.iec)] ?? 0
              usedSetpointSources[type][iecIdToLiteral(src.iec)]++
            })
          })

          return Object.values(usedSetpointSources).every((srcTypes) => {
            return Object.values(srcTypes).every((num) => num <= 1)
          })
        }
      }
    ]

    const { errors } = runValidation({ ids: strategyIds, validators })

    this.strategyErrors = this.strategyErrors.concat(errors)

    return errors.length === 0
  }

  this.buildNotification = () => {
    const errors = []
    const evalFailures = (errs, type) => {
      const tBasePath = `ems.energyService.${type}.validation`
      errs.forEach((e) => {
        let msg = i18n.t(`${tBasePath}.invalid`, { sId: e.targetId })
        const tErrPath = `${tBasePath}.${e.type}`
        if (i18n.te(tErrPath)) {
          msg = msg + ': ' + i18n.t(tErrPath)
        } else {
          msg = msg + ': not ' + startCase(e.type)
        }

        errors.push(msg)
      })
    }

    evalFailures(this.strategyErrors, 'strategy')
    evalFailures(this.actuatorGroupErrors, 'actuatorGroup')

    return {
      autohide: false,
      title: i18n.t('ems.energyService.validationFailureStrategiesTitle'),
      type: 'danger',
      content: errors.length === 1 ? errors[0] : errors
    }
  }

  // private
  function runValidation({ ids, validators }) {
    const errors = []

    ids.forEach((id) => {
      let isValid = true
      for (const v of validators) {
        if (!isValid) {
          break
        }

        isValid = isValid && v.validate(id)
        if (!isValid) {
          errors.push(new ValidationError({ targetId: id, type: v.type }))
        }
      }
    })

    return { errors }
  }
}

/**
 * Runs validation for the actuator strategies.
 *
 * @function
 *
 * @param {object} ctxt has to be the View context, i.e. the vue instance, which provides the getters (data) for `ActuatorStrategiesValidator`
 *
 * @return {ActuatorStrategiesValidator} the validator
 */
export function validateActuatorStrategies(ctxt) {
  const validator = new ActuatorStrategiesValidator()
  if (!Array.isArray(ctxt.actuatorGroupIds)) {
    throw new TypeError('ctxt missed "actuatorGroupIds"')
  }
  if (!Array.isArray(ctxt.strategyIds)) {
    throw new TypeError('ctxt missed "strategyIds"')
  }
  if (!Array.isArray(ctxt.actuatorGroups)) {
    throw new TypeError('ctxt missed "actuatorGroups"')
  }
  if (typeof ctxt.getStrategy !== 'function') {
    throw new TypeError('ctxt missed "getStrategy"')
  }

  validator.validateStrategies({
    actuatorGroupIds: ctxt.actuatorGroupIds,
    strategyIds: ctxt.strategyIds,
    actuatorGroups: ctxt.actuatorGroups,
    getStrategy: ctxt.getStrategy
  })

  return validator
}
