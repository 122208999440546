<template>
  <div>
    <!-- BEGIN: Grid Meter Checkbox -->
    <CInputCheckbox
      class="py-1"
      :label="$t('ems.topology.gridMtr')"
      :disabled="missingMtrCounter"
      :description="$t('ems.topology.descriptions.gridMtr')"
      :checked="isGridMtr(iecId)"
      @update:checked="onUpdateGridMtr"
    />
    <!-- END: Grid Meter Checkbox -->
    <!-- BEGIN: Device Selection -->
    <div class="form-group mt-3" role="group">
      <EmsEditTopoConfigDevSelectLabel :label="iecIdToLiteral(iecId)" :is-devs="true" />
      <div class="input-group">
        <div class="form-control p-0">
          <TreeSelect
            :id="iecIdToLiteral(iecId)"
            :key="`pure-mtr-${physicalDeviceTreeOpts.length}`"
            :value.sync="selectedPhysicalDevices"
            disable-branch-nodes
            multiple
            :placeholder="$t('ems.topology.form.meter.placeholder')"
            :options="physicalDeviceTreeOpts"
            @input="onSelectDevice"
          />
        </div>
        <div class="input-group-append">
          <span class="input-group-text">
            <!-- prettier-ignore -->
            <CLink
              class="input-info-action"
              @click="showInfoDescription = !showInfoDescription"
            >
              <CIcon class="text-primary" name="cil-info-circle" :height="18" />
            </CLink>
          </span>
        </div>
      </div>
      <small v-show="showInfoDescription" class="form-text text-muted w-100">
        {{ $t('ems.topology.descriptions.sourceToDevices') }}
      </small>
    </div>
    <!-- END: Device Selection -->
    <!-- BEGIN: Swap Meter Checkbox -->
    <CInputCheckbox
      class="ml-1 ml-md-2 ml-lg-3 ml-xl-5 py-1 mt-5 mt-sm-0 mt-md-0 mt-lg-0"
      :label="$t('ems.topology.swapMtr')"
      :disabled="disableSwap"
      :description="$t('ems.topology.descriptions.swapMtr')"
      :checked="getMtrSwapOfPhaseTopology(iecId)"
      @update:checked="onUpdateSwapMtr"
    />
    <!-- END: Swap Meter Checkbox -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmsEditTopoConfigDevSelectLabel from '@/components/ems/topology/ems-edit-topo-config-dev-select-label'
import { iecIdToLiteral, physicalDeviceToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { generatePhysicalDeviceOptsForTreeSelect } from '@/view-helper/ems/ems-topology-helper'
import { validateIecId } from '@/view-helper/props-validation/iec-id-props-validation'

import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'EmsEditTopoConfigPureMtr',
  components: {
    EmsEditTopoConfigDevSelectLabel,
    TreeSelect
  },
  props: {
    iecId: {
      type: Object,
      required: true,
      validator: validateIecId
    },
    initPhysDevLiterals: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      touched: false,
      showInfoDescription: false
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', [
      'existingPhysicalDevices',
      'hasPhysicalDevices',
      'getPhysicalDevicesOfPhaseTopology',
      'getMtrSwapOfPhaseTopology',
      'isGridMtr',
      'hasAnyMtrCounter'
    ]),
    missingMtrCounter() {
      return !this.hasAnyMtrCounter(this.iecId)
    },
    physicalDeviceTreeOpts() {
      return generatePhysicalDeviceOptsForTreeSelect(this.existingPhysicalDevices)
    },

    selectedPhysicalDevices() {
      if (this.existingPhysicalDevices.length === 0) {
        return []
      }

      return this.getPhysicalDevicesOfPhaseTopology(this.iecId).map((pd) => {
        return physicalDeviceToLiteral(pd)
      })
    },

    disableSelect() {
      return !!this.isGridMtr(this.iecId)
    },
    disableSwap() {
      return this.missingMtrCounter || (!this.selectedPhysicalDevices.length && !this.isGridMtr(this.iecId))
    }
  },
  watch: {
    hasPhysicalDevices: function (e) {
      if (this.touched || !e) {
        return
      }

      // API topo called returned with non-trivial topo
      this.touched = true
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    iecIdToLiteral,

    init() {
      if (this.initPhysDevLiterals.length && !this.selectedPhysicalDevices.length) {
        this.onSelectDevice(this.initPhysDevLiterals)
      }
    },
    onSelectDevice(opts) {
      this.$store.dispatch(
        'emsTopologyConfig/updatePhaseTopo',
        Object.assign(
          {},
          {
            iecId: this.iecId,
            physDevLiterals: opts
          }
        )
      )
    },
    onUpdateGridMtr(isChecked) {
      if (isChecked) {
        this.$store.dispatch('emsTopologyConfig/setGridMtr', this.iecId)
      } else {
        this.$store.commit('emsTopologyConfig/CLEAR_GRID_MTR_IECID')
      }
    },
    onUpdateSwapMtr(isChecked) {
      this.$store.commit('emsTopologyConfig/SET_SWAP_MTR', {
        iecId: this.iecId,
        swap: isChecked
      })
    }
  }
}
</script>
