<template>
  <div class="w-100 d-flex flex-column align-items-end">
    <p class="m-0">{{ serialno }}</p>
    <p class="m-0">{{ version }}</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApxInformationsSnippet',
  computed: {
    ...mapGetters('apxInformations', ['serialno', 'version'])
  },
  mounted() {
    this.$store.dispatch('apxInformations/fetchSerialNo').catch((err) => {
      this.$log.error('Failed fetchSerialNo')
      this.$log.error(err)
    })
    this.$store.dispatch('apxInformations/fetchVersion').catch((err) => {
      this.$log.error('Failed fetchVersion')
      this.$log.error(err)
    })
  }
}
</script>
