<template>
  <CCard class="col-12 card-columns px-0">
    <ApxCardHeader icon="cil-key-alt" :title="$t('management.license.pageTitle')" />
    <CCardGroup class="mt-4 px-3">
      <CCol lg="6">
        <ApxLicenseInfoCard />
      </CCol>
      <CCol lg="6"> <ApxLicenseUpdateForm @cancel="init" /> </CCol>
    </CCardGroup>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ApxLicenseInfoCard from '@/components/license/license-info-card'
import ApxLicenseUpdateForm from '@/components/license/license-update-form'

import { newToastNotificationMsg } from '@/store/modules/notifications'

export default {
  name: 'License',
  components: {
    ApxCardHeader,
    ApxLicenseInfoCard,
    ApxLicenseUpdateForm
  },
  computed: {
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    })
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store.dispatch('auth/getLicenseId').catch(this.onServerError) // for consistency get also Licens ID, however, license does matter
      this.$store
        .dispatch('auth/getLicense')
        .then(() => {
          this.$log.debug('Succesfully fetched license information.')
        })
        .catch(this.onServerError)
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err) {
      this.$log.warn(err)

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 10000,
          type: 'danger',
          title: this.$t('api.errors.server'),
          content: this.$t('api.errors.fetch')
        })
      )
    }
  }
}
</script>

<style scoped></style>
