<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm class="mt-4" @submit.prevent>
      <CInput
        type="text"
        :horizontal="formHorizontal"
        :invalid-feedback="invalidFeedbackForAGName"
        :is-valid="$v.name.$dirty ? !$v.name.$error : null"
        :label="$t(`${tBasePath}.name.label`)"
        :placeholder="$t(`${tBasePath}.name.placeholder`)"
        :description="$t(`${tBasePath}.name.description`)"
        :value.sync="$v.name.$model"
      />
      <CSelect
        :horizontal="formHorizontal"
        :value.sync="selectedType"
        :options="actuatorGroupTypeOpts"
        :disabled="type === 'edit'"
        :label="$t(`${tBasePath}.type.label`)"
        :description="type === 'edit' ? $t(`${tBasePath}.type.notEditable`) : $t(`${tBasePath}.type.description`)"
        :is-valid="$v.selectedType.$dirty ? !$v.selectedType.$error : null"
        :invalid-feedback="invalidFeedbackForAGSelectedType"
      />
      <!-- BEGIN: Parametrization  -->
      <template v-if="actuatorGroupParametrizationOpts.length > 1">
        <CSelect
          :horizontal="formHorizontal"
          :value.sync="selectedParameterType"
          :options="actuatorGroupParametrizationOpts"
          :disabled="type === 'edit'"
          :label="$t(`${tBasePath}.parametrization.label`)"
          :description="type === 'edit' ? $t(`${tBasePath}.parametrization.notEditable`) : null"
        />
        <!-- BEGIN: Multi-Battery parametrization -->
        <template v-if="selectedParameterType === 'battery'">
          <CInput
            type="number"
            step="5"
            :horizontal="formHorizontal"
            :invalid-feedback="invalidFeedbackForAGBatteryDeltaSoc"
            :is-valid="$v.battery.deltaSoc.$dirty ? !$v.battery.deltaSoc.$error : null"
            :label="$t(`${tBasePath}.multiBattery.deltaSoc.labelWithUnit`)"
            :placeholder="$t(`${tBasePath}.multiBattery.deltaSoc.placeholder`)"
            :description="$t(`${tBasePath}.multiBattery.deltaSoc.description`)"
            :value.sync="$v.battery.deltaSoc.$model"
          />
        </template>
        <!-- END: Multi-Battery parametrization -->
        <!-- BEGIN: Simple description -->
        <template v-if="selectedParameterType === 'simplePriority'">
          <small class="text-muted form-text mb-2">
            {{ $t(`${tBasePath}.simple.description`) }}
          </small>
        </template>
        <!-- END: Simple description -->
        <!-- BEGIN: Advanced priority parametrization -->
        <template v-if="selectedParameterType === 'advancedPriority'">
          <template v-for="source in $v.advancedPriority.priority.$each.$iter">
            <CInput
              :key="source.name.$model"
              type="number"
              step="1"
              :horizontal="formHorizontal"
              :invalid-feedback="invalidFeedbackAdvanced"
              :is-valid="source.priority.$dirty ? !source.priority.$error : null"
              :label="source.name.$model"
              :placeholder="0"
              :value.sync="source.priority.$model"
            />
          </template>
          <small class="text-muted form-text mb-2">
            {{ $t(`${tBasePath}.advanced.description`) }}
          </small>
        </template>
        <!-- END: Advanced priority parametrization -->
        <!-- BEGIN: Car LIS -->
        <template v-if="selectedParameterType === 'carLis'">
          <AsActuatorGroupFormCarLis :car-lis="carLis" :validations="$v.carLis" @update="carLisUpdate" />
        </template>
        <!-- END: Car LIS -->
        <!-- BEGIN: ChaiScript parametrization -->
        <template v-if="selectedParameterType === 'chai'">
          <CRow :form="true" class="mb-2">
            <CCol>
              <CInput
                type="text"
                class="custom-form-label"
                :invalid-feedback="invalidFeedbackForAGChaiPrefix"
                :is-valid="$v.chai.prefix.$dirty ? !$v.chai.prefix.$error : null"
                :label="$t(`${tBasePath}.chai.prefix.label`)"
                :placeholder="$t(`${tBasePath}.chai.prefix.placeholder`)"
                :description="$t(`${tBasePath}.chai.prefix.description`)"
                :value.sync="$v.chai.prefix.$model"
              />
            </CCol>
            <CCol>
              <CInput
                type="text"
                class="custom-form-label"
                :invalid-feedback="invalidFeedbackForAGChaiFilename"
                :is-valid="$v.chai.filename.$dirty ? !$v.chai.filename.$error : null"
                :label="$t(`${tBasePath}.chai.filename.label`)"
                :placeholder="$t(`${tBasePath}.chai.filename.placeholder`)"
                :description="$t(`${tBasePath}.chai.filename.description`)"
                :value.sync="$v.chai.filename.$model"
              />
            </CCol>
          </CRow>
        </template>
        <!-- END: ChaiScript parametrization -->
      </template>
      <!-- END: Parametrization -->
      <div class="form-group form-row">
        <label :class="`col-form-label ${formHorizontal.label}`">
          {{ $t(`${tBasePath}.setpointSources.label`) }}
        </label>
        <CMultiSelect
          v-if="type === 'new'"
          :key="initCount + optsSetpointSrcLiterals.map((e) => e.text).join()"
          :class="`${formHorizontal.input} ${$v.selectedSetpointSources.$error ? 'is-invalid' : ''}`"
          :multiple="true"
          :options="optsSetpointSrcLiterals"
          :search="true"
          :search-placeholder="$t(`${tBasePath}.setpointSources.placeholder`)"
          selection-type="tags"
          :selected="slctSetpointSrcLiterals"
          @update="onUpdateSetpointSources"
        >
        </CMultiSelect>
        <!-- CSS toggles visibility -->
        <div v-if="type === 'new'" :class="`invalid-feedback ${formHorizontal.input} offset-md-2  offset-lg-3`">
          {{ invalidFeedbackForAGSetpointSources }}
        </div>
        <small v-if="type === 'new'" :class="`form-text text-muted ${formHorizontal.input} offset-md-2  offset-lg-3`">
          {{ $t(`${tBasePath}.setpointSources.description`) }}
        </small>
        <div v-if="type === 'edit'" :class="`form-text ${formHorizontal.input} pt-1`">
          <p v-if="slctSetpointSrcLiterals.length === 0"> &#8212; &#8212; &#8212; </p>
          <p v-else>
            {{ slctSetpointSrcLiterals.map((item) => item).join(', ') }}
          </p>
          <small class="form-text text-muted pt-1">
            {{ $t(`${tBasePath}.setpointSources.notEditable`) }}
          </small>
        </div>
      </div>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { ActuatorStrategies as ActuatorStrategiesPb } from '@/../lib/proto_js/ext/ems/scontroller/scontroller_pb'
import { Empty as EmptyPb } from 'google-protobuf/google/protobuf/empty_pb'

import AsActuatorGroupFormCarLis from '@/components/ems/energy-services/actuator-strategies-forms/as-actuator-group-form-car-lis'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'
import { EMS_ACTUATOR_STRATEGIES_DESCRIPTION } from '@/grpc/protobuf/ems-energy-service-helper'
// import { getActuatorGroupParmetrizaionOpts } from '@/view-helper/ems/ems-energy-service-helper'
import { actuatorGroupValidatores } from '@/validations/ems-es-actuator-group-validators'
import { determineActuatorGroupType } from '@/store/modules/_ems-energy-services-config-helper'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
// TODO: remove this hacky way and use the falconer's license-features?
import Feature from '@/../config/feature/feature.json'

const defaultValues = {
  deltaSoc: 10,
  carLis: {
    // use same defaults as in the old version
    maxCurrentGrid: 0,
    ignoreCurrentGrid: true,
    maxPowerGrid: 40000,
    ignorePowerGrid: false,
    maxCurrentLis: 32,
    ignoreCurrentLis: false,
    maxAmpere: 16
  }
}

export default {
  name: 'AsActuatorGroupForm',
  components: {
    AsActuatorGroupFormCarLis,
    EsLayoutForm
  },
  props: {
    type: {
      required: true,
      type: String,
      validator(type) {
        return ['new', 'edit'].includes(type)
      }
    },
    actuatorGroupId: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      name: this.actuatorGroupId,
      selectedType: 'POWER',
      selectedParameterType: 'singlePower',
      selectedSetpointSources: [],
      battery: {
        deltaSoc: defaultValues.deltaSoc
      },
      chai: {
        filename: '',
        prefix: ''
      },
      simplePriority: {},
      advancedPriority: {
        priority: []
      },
      carLis: {
        maxCurrentGrid: defaultValues.carLis.maxCurrentGrid,
        ignoreCurrentGrid: defaultValues.carLis.ignoreCurrentGrid,
        maxPowerGrid: defaultValues.carLis.maxPowerGrid,
        ignorePowerGrid: defaultValues.carLis.ignorePowerGrid,
        maxCurrentLis: defaultValues.carLis.maxCurrentLis,
        ignoreCurrentLis: defaultValues.carLis.ignoreCurrentLis,
        chargePoint: []
      },
      showFailureAlert: false,
      initCount: 0
    }
  },
  // note: `sourceIsAllowedForActuatorGroupType` is required by the `actuatorGroupValidatores`
  computed: {
    ...mapState('emsEnergyServicesConfig', ['actuatorGroupIds']),
    ...mapState('emsTopologyConfig', ['setpointSources']),
    ...mapGetters('emsEnergyServicesConfig', [
      'getActuatorGroup',
      'getSetpointSourceLiteralsForActuatorGroupParametrizationType',
      'sourceIsAllowedForActuatorGroupType'
    ]),
    ...mapGetters('emsTopologyConfig', ['getPhysicalDeviceOfSourceToDeviceTopology']),
    actuatorGroup() {
      return this.getActuatorGroup(this.actuatorGroupId)
    },
    slctSetpointSrcLiterals() {
      return this.selectedSetpointSources.map((src) => {
        return iecIdToLiteral(src.iec)
      })
    },
    optsSetpointSrcLiterals() {
      const validSetpointSourceLiterals = this.getSetpointSourceLiteralsForActuatorGroupParametrizationType({
        actuatorGroupParametrizationType: this.selectedType === 'POWER' ? this.selectedParameterType : 'switch'
      })

      return validSetpointSourceLiterals.map((l) => {
        return {
          text: l,
          value: l
        }
      })
    },
    actuatorGroupParametrizationOpts() {
      const opts = []

      if (Feature.chai === 'true') {
        opts.push({
          value: 'chai',
          label: this.$t(`${this.tBasePath}.parametrization.chaiScript`)
        })
      }

      if (this.selectedType === 'POWER') {
        opts.push({
          value: 'singlePower',
          label: this.$t(`${this.tBasePath}.parametrization.singlePower`)
        })
        opts.push({
          value: 'battery',
          label: this.$t(`${this.tBasePath}.parametrization.battery`)
        })
        opts.push({
          value: 'simplePriority',
          label: this.$t(`${this.tBasePath}.parametrization.simple`)
        })

        if (Feature.chai === 'true') {
          opts.push({
            value: 'chai',
            label: this.$t(`${this.tBasePath}.parametrization.chaiScript`)
          })
        }
        if (Feature.advanced === 'true') {
          opts.push({
            value: 'advancedPriority',
            label: this.$t('ems.energyService.actuatorGroup.form.parametrization.advanced')
          })
        }

        opts.push({
          value: 'carLis',
          label: this.$t(`${this.tBasePath}.parametrization.carLis`)
        })
      }
      // currently "SWITCH" actuator-group(s) have no parametrization

      return opts
    },
    invalidFeedbackAdvanced() {
      return this.$t(`${this.tBasePath}.advanced.invalidFeedback`)
    },
    invalidFeedbackForAGName() {
      if (!this.$v.name.$error) {
        return ''
      }
      if (!this.$v.name.required) {
        return this.$t(`${this.tBasePath}.name.invalidFeedback.required`)
      } else if (!this.$v.name.words) {
        return this.$t(`${this.tBasePath}.name.invalidFeedback.words`)
      } else if (!this.$v.name.unique) {
        return this.$t(`${this.tBasePath}.name.invalidFeedback.uniqueness`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGSelectedType() {
      if (!this.$v.selectedType.required) {
        return this.$t(`${this.tBasePath}.type.invalidFeedback.required`)
      } else if (!this.$v.selectedType.included) {
        return this.$t(`${this.tBasePath}.type.invalidFeedback.included`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGSetpointSources() {
      if (!this.$v.selectedSetpointSources.$error) {
        return ''
      }

      if (!this.$v.selectedSetpointSources.required) {
        return this.$t(`${this.tBasePath}.setpointSources.invalidFeedback.required`)
      } else if (!this.$v.selectedSetpointSources.members) {
        switch (this.selectedType) {
          case 'POWER':
            return this.$t(
              `${this.tBasePath}.setpointSources.invalidFeedback.members.${this.selectedParameterType || 'none'}`
            )
          case 'SWITCH':
            return this.$t(`${this.tBasePath}.setpointSources.invalidFeedback.members.switch`)
          default:
            return ''
        }
      } else if (!this.$v.selectedSetpointSources.membersType) {
        if (this.selectedType === 'NONE') {
          return ''
        }
        return this.$t(`${this.tBasePath}.setpointSources.invalidFeedback.membersType.${this.selectedType}`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGBatteryDeltaSoc() {
      if (!this.$v.battery.deltaSoc.$error) {
        return ''
      }

      if (!this.$v.battery.deltaSoc.required) {
        return this.$t(`${this.tBasePath}.multiBattery.deltaSoc.invalidFeedback.required`)
      } else if (!this.$v.battery.deltaSoc.between) {
        return this.$t(`${this.tBasePath}.multiBattery.deltaSoc.invalidFeedback.between`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGChaiFilename() {
      if (!this.$v.chai.filename.$error) {
        return ''
      }

      if (!this.$v.chai.filename.required) {
        return this.$t(`${this.tBasePath}.chai.filename.invalidFeedback.required`)
      } else if (!this.$v.chai.filename.filepath) {
        return this.$t(`${this.tBasePath}.chai.filename.invalidFeedback.filepath`)
      }

      return 'INVALID'
    },
    invalidFeedbackForAGChaiPrefix() {
      if (!this.$v.chai.prefix.$error) {
        return ''
      }

      if (!this.$v.chai.prefix.required) {
        return this.$t(`${this.tBasePath}.chai.prefix.invalidFeedback.required`)
      } else if (!this.$v.chai.prefix.alphaNum) {
        return this.$t(`${this.tBasePath}.chai.prefix.invalidFeedback.alphaNum`)
      }

      return 'INVALID'
    },
    parametrizationType() {
      // Determine parametrization type only if form is edited
      if (this.type === 'edit') {
        return EMS_ACTUATOR_STRATEGIES_DESCRIPTION.nested.ActuatorGroup.oneofs.type.oneof.find((o) => {
          return this.actuatorGroup[o]
        })
      }
      return ''
    }
  },
  watch: {
    actuatorGroupId(gId) {
      this.name = gId
    }
  },
  created() {
    this.formHorizontal = {
      label: 'col-12 col-md-2 col-lg-3 custom-form-label',
      input: 'col-12 col-md-10 col-lg-9'
    }
    this.tBasePath = 'ems.energyService.actuatorGroup.form'

    this.actuatorGroupTypeOpts = [
      {
        value: 'POWER',
        label: this.$t(`${this.tBasePath}.actuatorGroupType.POWER`)
      },
      {
        value: 'SWITCH',
        label: this.$t(`${this.tBasePath}.actuatorGroupType.SWITCH`)
      }
    ]

    this.init()
  },
  validations() {
    return actuatorGroupValidatores(this, {
      existingNames: this.actuatorGroupIds,
      origName: this.actuatorGroupId,
      getPhysicalDevice: this.getPhysicalDeviceOfSourceToDeviceTopology
    })
  },
  methods: {
    carLisUpdate(changes) {
      for (const [key, value] of Object.entries(changes)) {
        this.$v.carLis[key].$model = value
      }
    },
    init() {
      this.initCount++
      this.name = this.actuatorGroupId
      this.selectedSetpointSources = []
      if (this.type === 'new') {
        return
      }

      this.actuatorGroup.setpointSourcesList.forEach((src) => {
        this.selectedSetpointSources.push(this.setpointSources[iecIdToLiteral(src.iec)])
      })

      this.selectedType = determineActuatorGroupType(this.actuatorGroup)

      // Determine preselection based on parametrization type
      this.selectedParameterType = this.parametrizationType || 'none'

      // Get initial deltaSoc values
      switch (this.selectedParameterType) {
        case 'battery':
          this.battery.deltaSoc = this.actuatorGroup.battery?.deltaSoc ?? defaultValues.deltaSoc
          break
        case 'chai':
          this.chai.prefix = this.actuatorGroup.chai?.prefix ?? ''
          this.chai.filename = this.actuatorGroup.chai?.filename ?? ''
          break
        case 'advancedPriority':
          this.advancedPriority.priority = []
          for (let i = 0; i < this.actuatorGroup.advancedPriority.priorityListList.length; ++i) {
            this.advancedPriority.priority.push({
              name: iecIdToLiteral(this.actuatorGroup.advancedPriority.priorityListList[i].source.iec),
              priority: this.actuatorGroup.advancedPriority.priorityListList[i].priority
            })
          }
          break
        case 'carLis':
          if (this.actuatorGroup.carLis !== undefined) {
            if (this.actuatorGroup.carLis.ignoreCurrentGrid === undefined) {
              this.carLis.ignoreCurrentGrid = false
              this.carLis.maxCurrentGrid = this.actuatorGroup.carLis.maxCurrentGrid ?? 0
            } else {
              this.carLis.ignoreCurrentGrid = true
              this.carLis.maxCurrentGrid = 0
            }

            if (this.actuatorGroup.carLis.ignorePowerGrid === undefined) {
              this.carLis.ignorePowerGrid = false
              this.carLis.maxPowerGrid = this.actuatorGroup.carLis.maxPowerGrid ?? 0
            } else {
              this.carLis.ignorePowerGrid = true
              this.carLis.maxPowerGrid = 0
            }

            if (this.actuatorGroup.carLis.ignoreCurrentLis === undefined) {
              this.carLis.ignoreCurrentLis = false
              this.carLis.maxCurrentLis = this.actuatorGroup.carLis.maxCurrentLis ?? 0
            } else {
              this.carLis.ignoreCurrentLis = true
              this.carLis.maxCurrentLis = 0
            }
            this.carLis.chargePoint = []
            for (let i = 0; i < this.actuatorGroup.carLis.chargePointListList.length; ++i) {
              this.carLis.chargePoint.push({
                name: iecIdToLiteral(this.actuatorGroup.carLis.chargePointListList[i].source.iec),
                maxAmpere: this.actuatorGroup.carLis.chargePointListList[i].maxAmpere
              })
            }
          }
          break
      }
    },
    onUpdateSetpointSources(selectedLiterals) {
      this.selectedSetpointSources = []
      selectedLiterals.forEach((l) => {
        this.selectedSetpointSources.push(this.setpointSources[l])
      })
      this.$v.selectedSetpointSources.$touch()

      // remove the setpoint which are missing from the list for advanced priority
      for (let i = this.advancedPriority.priority.length - 1; i >= 0; i--) {
        let found = 0
        for (let j = 0; j < this.selectedSetpointSources.length; j++) {
          if (iecIdToLiteral(this.selectedSetpointSources[j].iec) === this.advancedPriority.priority[i].name) {
            found = 1
          }
        }
        if (found === 0) {
          this.advancedPriority.priority.splice(i, 1)
        }
      }

      // add the setpointsources that are missing to the list for advanced priority
      for (let j = 0; j < this.selectedSetpointSources.length; j++) {
        let found = 0
        for (let i = this.advancedPriority.priority.length - 1; i >= 0; i--) {
          if (iecIdToLiteral(this.selectedSetpointSources[j].iec) === this.advancedPriority.priority[i].name) {
            found = 1
          }
        }
        if (found === 0) {
          this.advancedPriority.priority.push({
            name: iecIdToLiteral(this.selectedSetpointSources[j].iec),
            priority: 0
          })
        }
      }

      // remove the setpoint which are missing from the list for charge infrastructure
      for (let i = this.carLis.chargePoint.length - 1; i >= 0; i--) {
        let found = 0
        for (let j = 0; j < this.selectedSetpointSources.length; j++) {
          if (iecIdToLiteral(this.selectedSetpointSources[j].iec) === this.carLis.chargePoint[i].name) {
            found = 1
          }
        }
        if (found === 0) {
          this.carLis.chargePoint.splice(i, 1)
        }
      }

      // add the setpointsources that are missing to the list for charge infrastructure
      for (let j = 0; j < this.selectedSetpointSources.length; j++) {
        let found = 0
        for (let i = this.carLis.chargePoint.length - 1; i >= 0; i--) {
          if (iecIdToLiteral(this.selectedSetpointSources[j].iec) === this.carLis.chargePoint[i].name) {
            found = 1
          }
        }
        if (found === 0) {
          this.carLis.chargePoint.push({
            name: iecIdToLiteral(this.selectedSetpointSources[j].iec),
            maxAmpere: defaultValues.carLis.maxAmpere
          })
        }
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const params = {
        actuatorGroupId: this.name.trim(),
        actuatorGroup: null
      }
      const group = new ActuatorStrategiesPb.ActuatorGroup().toObject()
      delete group.pb_switch // because `switch` is a reserved key-word, the field was named as `pb_switch`, however, the plain object to proto parser needs `switch` as field name

      this.selectedSetpointSources.forEach((spSrc) => {
        group.setpointSourcesList.push(cloneDeep(spSrc))
      })

      if (this.selectedType === 'SWITCH') {
        group.switch = new EmptyPb().toObject()
      }

      if (this.selectedParameterType === 'battery') {
        const battery = new ActuatorStrategiesPb.ActuatorGroup.Battery()
        battery.setDeltaSoc(this.battery.deltaSoc)
        group.battery = battery.toObject()

        if (this.type === 'edit') {
          // preserve NOT edit-able attributes
          group.battery = Object.assign({}, this.actuatorGroup.battery, group.battery)
        }
      }

      if (this.selectedParameterType === 'simplePriority') {
        const simple = new EmptyPb()
        group.simplePriority = simple.toObject()

        if (this.type === 'edit') {
          // preserve NOT edit-able attributes
          group.simplePriority = Object.assign({}, this.actuatorGroup.simplePriority, group.simplePriority)
        }
      }

      if (this.selectedParameterType === 'advancedPriority') {
        const advanced = new ActuatorStrategiesPb.ActuatorGroup.AdvancedPriority()
        group.advancedPriority = advanced.toObject()

        for (const advPriority of this.advancedPriority.priority) {
          const entry = new ActuatorStrategiesPb.ActuatorGroup.AdvancedPriority.SourcePriority().toObject()
          entry.priority = advPriority.priority ? advPriority.priority : 0
          entry.source = cloneDeep(this.setpointSources[advPriority.name])

          group.advancedPriority.priorityListList.push(cloneDeep(entry))
        }

        if (this.type === 'edit') {
          // preserve NOT edit-able attributes
          group.advancedPriority = Object.assign({}, this.actuatorGroup.advancedPriority, group.advancedPriority)
        }
      }

      if (this.selectedParameterType === 'carLis') {
        const carLis = new ActuatorStrategiesPb.ActuatorGroup.LiS()
        if (this.carLis.ignoreCurrentGrid) {
          carLis.setIgnoreCurrentGrid(new EmptyPb())
        } else {
          carLis.setMaxCurrentGrid(this.carLis.maxCurrentGrid)
        }
        if (this.carLis.ignorePowerGrid) {
          carLis.setIgnorePowerGrid(new EmptyPb())
        } else {
          carLis.setMaxPowerGrid(this.carLis.maxPowerGrid)
        }
        if (this.carLis.ignoreCurrentLis) {
          carLis.setIgnoreCurrentLis(new EmptyPb())
        } else {
          carLis.setMaxCurrentLis(this.carLis.maxCurrentLis)
        }
        group.carLis = carLis.toObject()

        for (const chargePoint of this.carLis.chargePoint) {
          const entry = new ActuatorStrategiesPb.ActuatorGroup.LiS.ChargingPointConfig().toObject()
          entry.maxAmpere = chargePoint.maxAmpere ? chargePoint.maxAmpere : 0
          entry.source = cloneDeep(this.setpointSources[chargePoint.name])

          group.carLis.chargePointListList.push(cloneDeep(entry))
        }

        if (this.type === 'edit') {
          // preserve NOT edit-able attributes
          group.carLis = Object.assign({}, this.actuatorGroup.carLis, group.carLis)
        }
      }

      if (this.selectedParameterType === 'chai') {
        const chaiScript = new ActuatorStrategiesPb.ActuatorGroup.ChaiScript()
        chaiScript.setPrefix(this.chai.prefix)
        chaiScript.setFilename(this.chai.filename)
        group.chai = chaiScript.toObject()

        if (this.type === 'edit') {
          group.chai = Object.assign({}, this.actuatorGroup.chai, group.chai)
        }
      }

      params.actuatorGroup = group

      switch (this.type) {
        case 'new':
          this.$emit('create:actuator-group', { actuatorGroupId: this.actuatorGroupId, params })
          break
        case 'edit':
          this.$emit('update:actuator-group', { actuatorGroupId: this.actuatorGroupId, params })
          break
      }
    }
  }
}
</script>
