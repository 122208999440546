<template>
  <CCard :key="device" class="col-12 col-xl-10 pb-4">
    <ApxCardHeader icon="cil-remote-control" :title="$t(`config.device.${device}.statusTitle`)" />
    <CCardBody>
      <ApxDeviceConfigNav :device="device" />
    </CCardBody>
    <CCardBody>
      <ApxDeviceStatus v-bind="getDeviceStatusByConfigId(configId, true)" />
      <!-- prettier-ignore -->
      <CWidgetIcon
        class="no-border mb-0 mt-5"
        :header="$t('config.device.status.processValuesFilter.header')"
        :text="$t('config.device.status.processValuesFilter.text')"
        color="info"
        :icon-padding="false"
      >
        <CIcon
          name="cisGraph"
          :width="36"
        />
      </CWidgetIcon>
      <!-- prettier-ignore -->
      <CCardBody class="mt-4 mb-0 ml-0 pl-0 pb-0 pt-2">
        <CInput
          class="col-sm-6 ml-0 pl-0 text-muted font-weight-bold"
          :value.sync="deviceValuesFilter"
          :label="$t('config.device.status.processValuesFilter.label')"
          horizontal
          type="text"
        />
        <hr class="separation-strong mt-0 p-0">
      </CCardBody>
      <!-- ---- BEGIN Process Value Information ---- -->
      <!-- prettier-ignore -->
      <ApxDeviceProcessValue
        v-for="(value, i) of filterDeviceProcessValues(deviceValuesFilter)"
        :key="value.reference || i"
        v-bind="value"
      />
      <!-- ---- END Process Value Information ---- -->
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import { toLower } from 'lodash'
import ApxCardHeader from '@/components/snippets/apx-card-header'
import ApxDeviceConfigNav from '@/components/navs/apx-device-config-nav'
import ApxDeviceProcessValue from '@/components/apx-device-process-value'
import ApxDeviceStatus from '@/components/apx-device-status'

export default {
  name: 'DeviceStatus',
  components: {
    ApxCardHeader,
    ApxDeviceConfigNav,
    ApxDeviceProcessValue,
    ApxDeviceStatus
  },
  data() {
    return {
      device: '',
      deviceValuesFilter: ''
    }
  },
  computed: {
    ...mapGetters('deviceConfig', ['getDeviceStatusByConfigId']),
    ...mapGetters('deviceValues', ['filterDeviceProcessValues'])
  },
  created() {
    this.device = toLower(this.$route.params.device)
    this.configId = this.$route.params.id
  },
  mounted() {
    this.$store.commit('deviceConfig/CLEAR_STATUSES')
    this.$store
      .dispatch('deviceConfig/getDeviceStatus', this.configId)
      .then((msg) => {
        this.$log.debug('Successful getDeviceStatus.')
      })
      .catch(this.onServerError)
    this.$store.dispatch('deviceConfig/streamDeviceStatus', {
      configId: this.configId
    })

    this.$store.commit('deviceValues/CLEAR_PROCESS_VALUES')
    this.$store
      .dispatch('deviceValues/fetchEnergyManagerDeviceValues', this.configId)
      .then((msg) => {
        this.$log.debug('Successful fetchEnergyManagerDeviceValues')
      })
      .catch(this.onServerError)
    this.$store.dispatch('deviceValues/streamEnergyManagerDeviceValues', { configId: this.configId })
  },
  beforeDestroy() {
    this.$store.dispatch('deviceConfig/streamDeviceStatus', { configId: this.configId, stop: true })
    this.$store.dispatch('deviceValues/streamEnergyManagerDeviceValues', { configId: this.configId, stop: true })
  },
  methods: {
    onServerError(err) {
      this.$log.warn(err)
      // TODO: translation error content
      this.$store.commit(
        'notifications/PUSH_TOAST',
        {
          title: 'Server Error',
          type: 'toast-warning',
          content: `${err.msg}. Error-Code: ${err.code}`
        },
        { root: true }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  border: 0 !important;
  border-color: white;
  box-shadow: 0px 0px #888888;
  //background-color: #2ba6ca;
}

.no-border {
  border: 0 !important;
  box-shadow: 0px 0px !important;
}

hr.separation {
  border-top: 0.5px solid #dddddd;
}

hr.separation-strong {
  border-top: 1.5px solid #dddddd;
}
</style>
