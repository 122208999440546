/** @module grpc/parser */
import { camelCase, upperFirst, isEqual, isNil } from 'lodash'
import { Empty as EmptyPb } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp as TimestampPb } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Value as ValuePb } from 'google-protobuf/google/protobuf/struct_pb'
import * as SelectionPbPkg from '@/../lib/proto_js/ext/amperix/selection_pb'
import selectionPbMsgDesc from '@/../lib/proto_json/ext/amperix/selection'
import { evalExpressionPlainToPb } from '@/grpc/protobuf/ems-eval-expression-helper'

const TMP = {}

/**
 * Takes a proto ENUM integer and the ENUM-msg in a JS representation: ` { 'ENUM_STR': 0, ... }`, and returns the enum string.
 *
 * @function
 *
 * @param {integer} i is the protobuf uint representation of the Enum value
 * @param {object} enumObj is the protobuf enum definition with `{ enumStr: j, ... }`
 *
 * @return {string|undefined}
 */
export function getEnumStr(i, enumObj) {
  return Object.keys(enumObj).find((e) => enumObj[e] === i)
}

/**
 * Takes a proto scalar type and returns a JS type.
 *
 * @see {@link https://developers.google.com/protocol-buffers/docs/proto3#scalar}
 *
 * @function
 *
 * @param {string} protoType has to be the proto scalar type
 *
 * @return {string|null}
 */
export function protoTypeToJsType(protoType = '') {
  if (protoType === 'string') {
    return 'string'
  } else if (protoType === 'bool') {
    return 'boolean'
  } else if (/^(double|float|int|uint|sint|fixed|sfixed).*/.test(protoType)) {
    return 'number'
  } else if (protoType === 'bytes') {
    return 'string'
  } else if (/^[A-Z]\w*/.test(protoType)) {
    return 'object'
  }

  return null
}

/**
 * Converts a `google.protobuf.Timestamp` to a ISO8601 datetime string.
 *
 * @function
 *
 * @param {object} timestamp is either a `google.protobuf.Timestamp` instance or the JS object obtained by `toObject()`.
 *
 * @return {string|null} a datetime in ISO8601 form
 */
export function parseProtobufTimestampToIso8601(timestamp) {
  if (!timestamp) {
    return null
  }

  // duck-type if google.protobuf.Timestamp object
  if (timestamp.getSeconds) {
    return timestamp.toDate().toISOString()
  }

  const seconds = timestamp.seconds
  const nanos = timestamp.nanos
  if (typeof seconds !== 'number' || typeof nanos !== 'number') {
    return null
  }

  return new Date(1000 * seconds + nanos / 1000000).toISOString()
}

/**
 * Extends the protobuf `Any.unpack` functionallity.
 * Will auto extract the correct desericalize binary function from provided proto package.
 *
 * @function
 *
 * @param {object} any (required) is the instance of a protobuf `Any` message
 * @param {object} protoPackage is the JS module, which contains the constructor of the `any` instance. The `type URL` of the any message with striped off package name `de.mypowergrid.amperix` (if present) has to match a constructor in the provided proto package.
 * @param {object} options (optional)
 * @param {string} options.packageNameSpace (optional) allows to define the namespace of the provided proto package. Defaults to empty string.
 *
 * @return {object} the 'typed' JS proto message
 */
export function parseAnyToTypedMsg(any, protoPackage, { packageNameSpace } = { packageNameSpace: '' }) {
  const typeName = any.getTypeName()
  const msgKlassPath = typeName.split('.')

  // strip off package name from msgKlassPath
  const packageNameSpacePath = packageNameSpace.split('.')
  const l = packageNameSpacePath.length
  if (isEqual(msgKlassPath.slice(0, l), packageNameSpacePath)) {
    msgKlassPath.splice(0, l)
  }

  let ProtoMsgConstructor = protoPackage
  msgKlassPath.forEach((k, i) => {
    ProtoMsgConstructor = ProtoMsgConstructor[k]
    if (!ProtoMsgConstructor) {
      /* prettier-ignore */
      throw new Error(`Failed to find proto message constructor ${k} at position ${msgKlassPath.slice(0, i)} in provided proto package.`)
    }
  })

  return any.unpack(ProtoMsgConstructor.deserializeBinary, any.getTypeName())
}

/**
 * Takes a list of proto message field numbers (ids),
 * finds the proto message type if this field,
 * and then looks up the (JSON) description of this proto-message.
 *
 * @function
 *
 * @param {array} fieldNums (required) is the list of proto message field numbers
 * @param {object} description (required) is the (JSON) description for the proto message (sub) packages
 * @param {object} opts
 * @param {string} opts.rootMessageName (optional) in case the provided description has only message definitions at its root. Then, `rootMessageName` will select the message for which `fieldNums` should be applied.
 * @param {boolean} opts.allowMissingDesc (optional) allows to not raise if description for last field-numbers is not found, e.g. for a `amperix.Select`.
 *
 * @return {object} result
 * @return {array} result.fieldNums are the initially provided fieldNums
 * @return {array} result.fieldNames are the field-names which belong to the fieldNums
 * @return {string} result.msgName is the namespace proto-msg name
 * @return {object} result.desc is the description of the proto message of the desired field
 */
export function findDescription(fieldNums, description, { rootMessageName, allowMissingDesc } = {}) {
  if (!Array.isArray(fieldNums)) {
    throw new Error(
      'Argument "fieldNums" is missing or invalid. `fieldNums` have to be an array of protobuf field numbers (IDs).'
    )
  }
  if (description === undefined) {
    throw new Error('Argument desc is missing. `desc` is required.')
  }

  if (fieldNums.length === 0) {
    return {
      fieldNums,
      fieldNames: [],
      msgName: rootMessageName || '',
      desc: rootMessageName ? description.nested[rootMessageName] : description
    }
  }

  const msgTypeMatcher = /^[A-Z]\w*/
  const nesteds = []
  const fieldNames = []
  let firstItr = true
  let nestedsOffset = 0

  function extract(desc, i) {
    const n = fieldNums[i]
    const fields = desc.fields || {}
    let type = null
    // handle to find description for `fieldNums` with `rootMessageName`
    if (firstItr) {
      if (rootMessageName) {
        type = rootMessageName
        nestedsOffset++
        i--
      }
      firstItr = false
    }
    if (!type) {
      for (const f in fields) {
        if (fields[f].id === n) {
          type = fields[f].type
          fieldNames[i] = f
        }
      }
    }
    if (!type) {
      throw new Error(
        `Failed to find a description for field numbers [${fieldNums}] at position "${i}". This field seems not to exist.`
      )
    }

    if (msgTypeMatcher.test(type)) {
      // proto message field
      nesteds.push(desc.nested || {})
      let success = false
      for (let j = i + nestedsOffset; j >= 0; j--) {
        if (nesteds[j][type]) {
          desc = nesteds[j][type]
          success = true
          break
        }
      }
      if (!success && (!allowMissingDesc || fieldNums[i + 1])) {
        throw new Error(`Failed to find a description for message type ${type}.`)
      } else if (!success) {
        desc = {}
      }
    } else {
      // scalar field
      desc = desc.fields[fieldNames[i]]
    }
    i++

    if (fieldNums[i]) {
      return extract(desc, i)
    } else {
      const msgName = [type]
      if (msgTypeMatcher.test(type)) {
        // backward type-search in the (nested) namespace
        for (let j = i + nestedsOffset; j >= 0; j--) {
          for (const t in nesteds[j]) {
            let child = null
            if (nesteds[j][t].nested) {
              child = nesteds[j][t].nested[msgName[0]]
            }
            // check if child and if child is actually the true child
            // the latter is required, since children can have the same name
            if (child && child === nesteds[j + 1][msgName[0]]) {
              msgName.unshift(t)
            }
          }
        }
      }

      return { fieldNums, fieldNames, msgName: msgName.join('.'), desc }
    }
  }

  return extract(description, 0)
}

/**
 * Takes a protobuf message (JSON) description and iterates over all scalar fields. Each steps calls the provided callback.
 * Note: a `Selection` is treated as scaler field.
 *
 * @function
 *
 * @param {object} params
 * @param {object} params.description (required) is the protobug message (JSON) description.
 * @param {function} params.cb (required) is the callback function, called for each iteration step. It is called with `{fieldNames: [...], fieldNumbers: [...]}`
 * @param {string} params.msgName (optional) msgName to be picked initially from the description
 * @param {number} params.maxNumItr (optional) to avoid infinity loops for recursive message definitions
 *
 */
export function iterateScalarFieldsOfDescription({ description, msgName, cb, maxNumItr = 100000 }) {
  let numItr = 0

  function itr(fieldNums = []) {
    if (maxNumItr && numItr > maxNumItr) {
      return
    }
    numItr++

    const { fieldNames, desc } = findDescription(fieldNums, description, {
      rootMessageName: msgName,
      allowMissingDesc: true
    })

    const fields = desc?.fields || {}
    for (const f in fields) {
      if (/.*Selection$/.test(fields[f].type)) {
        const params = {
          fieldNames: fieldNames.concat([f]),
          fieldNumbers: fieldNums.concat([fields[f].id])
        }
        cb(params)
      } else if (/^[A-Z]\w*/.test(fields[f].type)) {
        itr(fieldNums.concat([fields[f].id]))
      } else {
        const params = {
          fieldNames: fieldNames.concat([f]),
          fieldNumbers: fieldNums.concat([fields[f].id])
        }
        cb(params)
      }
    }
  }

  itr()
}

/**
 * JSPB (the used [protobuf lib]{@link https://developers.google.com/protocol-buffers/docs/reference/javascript-generated}) does not support field descriptors.
 * We emulate parts of the field descriptor features,
 * by parsing the proto message description (here compiled to JSON),
 * and building a 'self-made' protobuf descriptor.
 *
 * Takes a (JSON) description of a proto message and the proto message constructor.
 * Then, a 'self-made' descriptor is created.
 *
 * @function
 *
 * @param {object} description has to be the description of the proto message. This description has to conform to the format of the JSON descriptor generated by [protobuf.js]{@link https://github.com/protobufjs/protobuf.js}
 * @param {function|object} protobufConstructorPkg has to be the constructor function of the proto message or the protobuf package, which contains the constructor function.
 *
 * @param {object} opts (optional)
 * @param {string} opts.rootConstructorName (optionally required) is the name of the RootConstructor to be used from the protobufConstructorPkg. Required iff the protobufConstructorPkg is a protobuf package, i.e. a plain object and NOT a constructor function
 * @param {object} opts.otherPkgDescriptions (optional) is a hash with protobuf message (package) descriptions from other namespaces imported (and used) by the `description` (see above)
 * @param {object} opts.otherPkgConstructors (optional) is a has with protobuf message (package) constructors from other namespaces required by the `protobufConstructorPkg`
 *
 *
 * @return {object} a 'self-made' descriptor is returned. It contains all fields of the proto message as owen object properties. Additionally, for each field of type `message` the constructor function is provided.
 */
export function buildDescriptor(description, protobufConstructorPkg, opts = {}) {
  let RootConstructor
  if (opts.rootConstructorName) {
    RootConstructor = protobufConstructorPkg[opts.rootConstructorName]
  } else {
    RootConstructor = protobufConstructorPkg
  }
  if (typeof RootConstructor !== 'function') {
    throw new TypeError('Failed to build proto message descriptor. No RootConstructor could be found.')
  }

  const desc = opts.rootConstructorName ? description.nested[opts.rootConstructorName] : description
  const descriptor = {
    _ProtoMsgConstructor: RootConstructor,
    _fieldType: RootConstructor.name || 'RootMessageTypeMissing'
  }
  const namespace = opts.rootConstructorName ? [opts.rootConstructorName] : []

  function extract(desc, descriptor, namespacePath = []) {
    const fields = desc.fields || {}

    let t
    for (const f in fields) {
      t = desc.fields[f].type
      descriptor[f] = {
        _fieldType: t
      }
      if (/^[A-Z]\w*/.test(t)) {
        let k = 0
        let success = false
        while (k <= namespacePath.length) {
          if (success) {
            break
          }
          let i = namespacePath.length
          while (i >= k) {
            let d = description
            let c = protobufConstructorPkg
            for (let j = k; j < i; j++) {
              if (!d || !d.nested) {
                break
              }
              c = c[namespacePath[j]]
              d = d.nested[namespacePath[j]]
            }
            if (d && d.nested && d.nested[t]) {
              success = true
              descriptor[f]._ProtoMsgConstructor = c[t]
              extract(d.nested[t], descriptor[f], namespacePath.concat([t]))
              break
            }
            i--
          }
          k++
        }

        // Handle the situation, that some contstructor(s) shall NOT be included in the provided protobuf-constructor-pkg
        if (!descriptor[f]._ProtoMsgConstructor) {
          switch (t) {
            case 'EvalExpression':
              Object.assign(descriptor[f], { _fieldType: 'EvalExpression', _ProtoMsgConstructor: null })
              break
            case 'Selection':
              buildApxSelectionDescriptor()
              Object.assign(descriptor[f], TMP.apxSelectionDescriptor)
              break
          }
        }
      } else if (/^google\.protobuf\.\w+/.test(t)) {
        switch (t.match(/^google\.protobuf\.(\w+)/)[1]) {
          case 'Empty':
            descriptor[f]._ProtoMsgConstructor = EmptyPb
            break
          case 'Timestamp':
            descriptor[f]._ProtoMsgConstructor = TimestampPb
            break
          case 'Value':
            descriptor[f]._ProtoMsgConstructor = ValuePb
            break
        }
      } else if (/^([a-z]+\.)+[A-Z]\w*/.test(t)) {
        let subPkgNamespace = t.split('.')
        const subPkgType = subPkgNamespace.pop()
        subPkgNamespace = subPkgNamespace.join('.')
        if (!opts.otherPkgConstructors || !opts.otherPkgDescriptions) {
          throw new TypeError(
            `Detected a message type "${t}" from another proto package, however, missed to find "otherPkgConstructors" and/or "otherPkgDescriptions" options.`
          )
        }

        const subPkgDescription = opts.otherPkgDescriptions[subPkgNamespace]
        const subPkgConstructor = opts.otherPkgConstructors[subPkgNamespace]

        if (!subPkgDescription) {
          throw new TypeError(`Failed to find a package description for package ${subPkgNamespace}.`)
        }

        if (!subPkgConstructor) {
          throw new TypeError(`Failed to find a proto package constuctor for package ${subPkgNamespace}.`)
        }
        if (subPkgDescription.nested[subPkgType].fields) {
          descriptor[f] = Object.assign(
            buildDescriptor(subPkgDescription, subPkgConstructor, { rootConstructorName: subPkgType }),
            descriptor[f]
          )
        } else if (subPkgDescription.nested[subPkgType].values) {
          // enum
          descriptor[f]._ProtoMsgConstructor = subPkgConstructor[subPkgType]
          if (!descriptor[f]._ProtoMsgConstructor) {
            throw new Error(`Failed to build proto message descriptor. Enum ${subPkgType} was missing in sub-package.`)
          }
        }
      }
    }
  }
  extract(desc, descriptor, namespace)

  return descriptor
}

// Helper:
// build has to be done here, to avoid circular dependencies
function buildApxSelectionDescriptor() {
  const apxDescription = selectionPbMsgDesc.nested.de.nested.mypowergrid.nested.amperix

  TMP.apxSelectionDescriptor =
    TMP.apxSelectionDescriptor ||
    buildDescriptor(apxDescription.nested.Selection, SelectionPbPkg.Selection, {
      otherPkgDescriptions: {
        'options.Amperix.Selection': apxDescription.nested.options.nested.Amperix.nested.Selection
      },
      otherPkgConstructors: {
        'options.Amperix.Selection': SelectionPbPkg.default.options.Amperix.Selection
      }
    })
  delete TMP.apxSelectionDescriptor._fieldType

  return TMP.apxSelectionDescriptor
}

/**
 * Takes a proto message descriptor and a payload,
 * and returns a proto-message instance.
 *
 * @function
 *
 * @example
 * objectToProtoMsg({
 *   descriptor: {
 *     _ProtoMsgConstructor: ProtoMsgRoot,
 *     field1: { _ProtoMsgConstructor: ProtoMsgField1, field2: null, ... },
 *     ...
 *   },
 *   payload: {
 *     field1: { field2: 'foo bar doo', ...},
 *     ...
 *   }
 * })
 *
 * @param {object} params
 *
 * @param {object} params.descriptor (required) to be used to parse the payload. The descriptor structure has to represent the proto message structure. Descriptor properties (keys) have to be named as the proto message fields. Additionally, each field, which is not of scalar type, has to define its proto message constructor.
 *
 * @param {object} params.payload (required) to be parsed to a proto-message. The payload structure has to represent the proto message structure. Payload properties (keys) have to be named as the proto message fields.
 *
 * @return {object} the proto-message
 */
export function objectToProtoMsg({ descriptor, payload }) {
  if (!descriptor || !payload) {
    throw new Error('Missing descriptor or payload')
  }

  // Handle some "hand crafted" exceptions
  switch (descriptor._fieldType) {
    case 'EvalExpression':
      return evalExpressionPlainToPb(payload)
  }

  if (!descriptor._ProtoMsgConstructor) {
    throw new Error('Missing descriptor or descriptor._ProtoMsgConstructor.')
  }
  if (payload.constructor.name !== 'Object') {
    throw new Error('Missing payload or payload.constructor.name !== Object')
  }

  const msg = new descriptor._ProtoMsgConstructor()

  function trimArrayField(fieldName) {
    return fieldName.replace(/List$/, '')
  }

  function setField(fieldName, value, { tag } = {}) {
    let setter
    try {
      switch (tag) {
        case 'ARRAY':
          setter = `add${upperFirst(trimArrayField(fieldName))}`
          break
        case 'ARRAY_ENUM':
          setter = `add${upperFirst(trimArrayField(fieldName))}`
          value = enumValueChecker(value)
          break
        case 'ENUM':
          setter = `set${upperFirst(fieldName)}`
          value = enumValueChecker(value)
          break
        default:
          setter = `set${upperFirst(fieldName)}`
      }

      // Avoid to set a NOT present `oneof kind` for `amperix.Selection.Value`.
      // Duck-typing the msg, check "triviality" and optionally return to avoid "overwrite".
      // Will NOT set if `value === falsy`.
      // Will prefere `unit32Value = 0` over `stringValue = ''`, if `value === falsy` for both.
      if (
        msg.getKindCase &&
        msg.getStringValue &&
        msg.getUint32Value &&
        msg.getEnumNumber &&
        ['stringValue', 'uint32Value', 'enumNumber'].includes(fieldName) &&
        [9, 13, 14].includes(msg.getKindCase()) &&
        !value &&
        (fieldName === 'uint32Value' ? msg.getStringValue() : true)
      ) {
        return
      }

      msg[setter](value)
    } catch (err) {
      if (err.name !== 'InvalidEnum') {
        err.message = `The setter '${setter}' is undefined for message '${
          descriptor._fieldType
        }' with props '${JSON.stringify(msg.toObject())}' and payload '${JSON.stringify(payload)}'.`
      }

      throw err
    }

    // private
    function enumValueChecker(val) {
      const EnumObj = descriptor[fieldName]._ProtoMsgConstructor
      const enumType = descriptor[fieldName]._fieldType
      let enumInt
      if (!Number.isInteger(val)) {
        enumInt = EnumObj[val]
      } else if (getEnumStr(val, EnumObj)) {
        enumInt = val
      }
      if (enumInt >= 0) {
        return enumInt
      }

      const err = new Error(`Invalid ENUM value ${val} for ENUM '${enumType}' ${JSON.stringify(EnumObj)}.`)
      err.name = 'InvalidEnum'

      throw err
    }
  }

  function isScalar(d) {
    if (!d || !d._fieldType) {
      return true
    }
    return /^[a-z]\w*/.test(d._fieldType.split('.').pop())
  }

  for (const field in payload) {
    if (isNil(payload[field])) {
      continue
    }

    // actually, payload value is NOT a plain object, however a protobuf JS message
    if (typeof payload[field].toObject === 'function') {
      setField(field, payload[field])
      return msg
    }

    // catch "special" messages here,
    // e.g. those which cannot be parsed by this function (e.g. recursive messages),
    // or/and which do have an "external" constructor,
    // or whatever.
    switch (descriptor[field]?._fieldType) {
      case 'EvalExpression':
        setField(field, evalExpressionPlainToPb(payload[field]))
        continue
    }

    let tag
    switch (payload[field].constructor.name) {
      case 'Object':
        if (!descriptor[field]) {
          throw new Error(`Field "${field}" is NOT supported. No descriptor found.`)
        }
        setField(field, objectToProtoMsg({ descriptor: descriptor[field], payload: payload[field] }))
        break
      case 'Array':
        tag = 'ARRAY'
        for (const a of payload[field]) {
          switch (a.constructor.name) {
            case 'Object':
              if (!descriptor[trimArrayField(field)]) {
                throw new Error(`Entry of repeated field "${field}" is NOT supported. No descriptor found.`)
              }
              setField(field, objectToProtoMsg({ descriptor: descriptor[trimArrayField(field)], payload: a }), { tag })
              break
            case 'Array':
              throw new Error('Parsing nested arrays is not supported.')
            default:
              if (!isScalar(descriptor[trimArrayField(field)])) {
                tag = 'ARRAY_ENUM'
              }
              setField(field, a, { tag })
          }
        }
        break
      default:
        if (isScalar(descriptor[field])) {
          tag = 'SCALAR'
        } else {
          tag = 'ENUM'
        }
        setField(field, payload[field], { tag })
    }
  }

  return msg
}

/**
 * Allows to safely get Protobuf one-of message field from a protobuf message one-of field-chain.
 *
 * Handles the case, that one-of submessages are empty or not defined,
 * and then aborts the chain.
 *
 * @function
 *
 * @param {object} message has to be the JS representation of the protobuf message
 * @param {array} fields have to be a list of protobuf message fields (as defined in the .proto file) to be chained.
 *
 * @return {(object|boolean|string|any|null)} the value of the 'last' protobuf message field
 */
export function safeGetMsgChain(message, fields) {
  if (!fields || fields.length === 0) {
    return message
  }

  let i = 0
  function iterate(msg) {
    let nextMsg = null
    let field = fields[i]

    // check for camelCase needed, because camelCase function on an already camelCased String with Single Letter parts leads to wrong results e.g 'aaa_b_d_cccc' => 'aaaBDCccc' => 'aaaBdCccc'
    const fieldString = isCamelCase(field) ? field : camelCase(field)
    const has = 'has' + upperFirst(fieldString)
    const get = 'get' + upperFirst(fieldString)

    if (typeof msg[has] === 'function' && msg[has]()) {
      // next msg field reached
      i++
      nextMsg = msg[get]()
      field = fields[i]
    } else if (typeof msg[has] === 'undefined' && typeof msg[get] === 'function') {
      // primitive field reached
      nextMsg = msg[get]()
      field = null
    }

    if (nextMsg && field) {
      return iterate(nextMsg)
    } else {
      return nextMsg
    }
  }

  return iterate(message)
}

export function extendJsProtobufMessagePrototype(jspb) {
  jspb.Message.prototype.safeGetMsgChain = function (fields) {
    return safeGetMsgChain(this, fields)
  }
}

const isCamelCase = (str) => /^([a-z]+)([0-9]*|([A-Z]([a-z]*)))*$/.test(str)
