<template>
  <CCard class="shadow-none p-2 mb-5">
    <ApxCardHeader icon="cil-settings" :title="$t('ems.energyService.submission.title')" />
    <CCardSubtitle class="mt-2">
      <p class="text-center text-muted px-2 mt-2 mb-0">
        {{ $t('ems.energyService.submission.description') }}
      </p>
    </CCardSubtitle>
    <CCardBody class="row">
      <div
        v-for="sId of strategyIds"
        :key="sId"
        :class="{
          'col-12': true,
          'col-xl-6': strategyIds.length > 1
        }"
      >
        <!-- BEGIN: Configured Strategies -->
        <CCard class="shadow-none" border-color="primary">
          <CCardHeader
            :class="{
              'text-center': true,
              'font-weight-bold': true
            }"
            text-color="primary"
          >
            {{ sId }}
            <div class="card-header-actions">
              <span v-if="isDefaultStrategy(sId)">
                <small class="default-strategy">
                  {{ $t('ems.energyService.config.defaultStrategy.title') }}
                </small>
              </span>
              <span v-else-if="getActivationRules(sId).length === 0">
                <small class="text-danger">
                  {{ $t('ems.energyService.config.unused') }}
                </small>
              </span>
            </div>
          </CCardHeader>
          <!-- BEGIN: Strategy -->
          <CCardBody v-if="getStrategy(sId).every((e) => !e)">
            <CCardText class="text-warning">
              {{ $t('ems.energyService.submission.emptyStrategyWarn') }}
            </CCardText>
          </CCardBody>
          <CCardGroup
            v-for="gId of getStrategyActuatorGroupIds(sId)"
            :key="gId"
            class="border submission-card-group mt-2 mb-1 mx-1"
          >
            <CCard key="`members-for: ${gId}`" class="shadow-none p-2 flat-card mb-0">
              <small class="text-break float-left text-muted text-uppercase">
                {{ $t('ems.energyService.actuatorGroup.form.title.show') }}
              </small>
              <CCardBody :key="`key-for: ${gId}`" class="p-2">
                <div class="my-2">
                  <CCardText class="d-flex justify-content-between">
                    <small class="text-break text-muted">
                      {{ gId }}
                    </small>
                    <small class="text-uppercase text-break text-info">
                      {{ $t('ems.energyService.actuatorGroup.members') }}
                    </small>
                  </CCardText>
                </div>
                <EmsEsActuatorGroupMembers
                  :actuator-group-id="gId"
                  :with-device="false"
                  :max-number="2"
                  wrapper-tag="div"
                  member-tag="CCardText"
                />
              </CCardBody>
            </CCard>
            <!-- BEGIN: Energy Service for AG -->
            <CCard :key="`energy-service-for: ${gId}`" class="shadow-none p-2 flat-card mb-0">
              <small class="text-sm-right text-muted text-uppercase">
                {{ $t('ems.energyService.energyService') }}
              </small>
              <CCardBody class="p-2">
                <EsPreviewLayout :strategy-id="sId" :actuator-group-id="gId" />
              </CCardBody>
            </CCard>
            <!-- END: Energy Service for AG -->
          </CCardGroup>
          <!-- END: Strategy -->
          <!-- BEGIN: Activation Rules for Strategy -->
          <div class="card-header-actions">
            <p
              class="mt-2 mb-1 font-sm font-weight-bold text-grey text-center d-flex justify-content-center"
              style="cursor: pointer"
              @click="onShowActicationRules(sId)"
            >
              {{ $t('ems.energyService.submission.activationRules') }}
              <span v-if="getActivationRules(sId).length > 0" class="ml-3 float-right card-header-action">
                <i>
                  <CIcon
                    class="pb-1"
                    :name="`cil-chevron-${showActivationRules.includes(sId) ? 'bottom' : 'top'}`"
                    :height="20"
                  />
                </i>
              </span>
            </p>
          </div>
          <div class="mx-2 mb-2">
            <small
              v-if="getActivationRules(sId).length === 0"
              :class="{
                'mb-1': true,
                'text-center': true,
                'text-muted': isDefaultStrategy(sId),
                'text-danger': !isDefaultStrategy(sId)
              }"
            >
              {{
                isDefaultStrategy(sId)
                  ? $t('ems.energyService.config.noActivationRulesDefault')
                  : $t('ems.energyService.config.noActivationRules')
              }}
            </small>
          </div>
          <template v-if="showActivationRules.includes(sId)">
            <div class="mx-2 mb-2">
              <small class="mb-1 text-center text-muted">
                {{ $t('ems.energyService.submission.rulesDescription') }}
              </small>
            </div>
            <CListGroup v-for="(rule, j) of getActivationRules(sId)" :key="j" class="border mx-1 mb-1">
              <ul class="path">
                <li v-for="(decision, k) of rule" :key="k">
                  <span class="font-sm inline-block" :data-decison-answer="$t(`main.${decision.answer}`)">
                    <EsNodeOptionPreview :entry="decision.entry" />
                    <span>
                      {{ decision.name }}
                    </span>
                    <i
                      v-if="esNodePreviewWhitelist.includes(decision.entry.name)"
                      :key="decision.entry.idx"
                      :ref="`showDecisionTreeEntry-${decision.entry.idx}`"
                      class="c-icon text-info float-right"
                      @click="
                        onToggleDecisionTreeEntryInfos(
                          entryInfoId({
                            strategyId: sId,
                            ruleIdx: j,
                            decisionIdx: k,
                            decisionTreeIdx: decision.entry.idx
                          })
                        )
                      "
                    >
                      <CIcon class="align-text-top" name="cil-info-circle" />
                    </i>
                    <div
                      v-if="
                        shouldShowDecisionTreeEntryInfo({
                          strategyId: sId,
                          ruleIdx: j,
                          decisionIdx: k,
                          decisionTreeIdx: decision.entry.idx
                        })
                      "
                      class="mt-2 text-muted"
                    >
                      <EsNodePreview :entry="decision.entry">
                        <template #heading>
                          <span></span>
                        </template>
                      </EsNodePreview>
                    </div>
                  </span>
                </li>
                <li>
                  <span class="font-sm">
                    {{ sId }}
                  </span>
                </li>
              </ul>
            </CListGroup>
          </template>
          <!-- END: Activation Rules for Strategy -->
        </CCard>
        <!-- END: Configured Strategies -->
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getHumanizedDecisionNodeLabel } from '@/view-helper/ems/ems-energy-service-helper'
import ApxCardHeader from '@/components/snippets/apx-card-header'
import EmsEsActuatorGroupMembers from '@/components/ems/energy-services/ems-es-actuator-group-members'
import EsPreviewLayout from '@/components/ems/energy-services/energy-service-preview/es-preview-layout'
import EsNodePreview from '@/components/ems/energy-services/energy-service-decision-tree/es-node-preview'
import EsNodeOptionPreview from '@/components/ems/energy-services/energy-service-decision-tree/es-node-option-preview'

export default {
  name: 'EnergyServicesConfigSubmission',
  components: {
    ApxCardHeader,
    EmsEsActuatorGroupMembers,
    EsPreviewLayout,
    EsNodePreview,
    EsNodeOptionPreview
  },
  data() {
    return {
      showActivationRules: [],
      showDecisionTreeEntryInfos: []
    }
  },
  computed: {
    ...mapState('emsEnergyServicesConfig', ['strategyIds']),
    ...mapGetters('emsEnergyServicesConfig', [
      'getStrategy',
      'getStrategyActuatorGroupIds',
      'decisionTreeLeafIdxs',
      'getDecisionTreeEntry',
      'isDefaultStrategy'
    ]),
    ...mapGetters('user', ['lang']),
    getActivationRules() {
      return (sId) => {
        const rules = []
        this.decisionTreeLeafIdxs.forEach((i) => {
          const leaf = this.getDecisionTreeEntry(i)
          if (sId === leaf.name) {
            const rule = []
            leaf.idxPath.forEach((j, k) => {
              if (i === j) {
                return
              }
              const node = this.getDecisionTreeEntry(j)
              rule.push({
                name: getHumanizedDecisionNodeLabel(node.name, node),
                answer: this.getDecisionTreeEntry(leaf.idxPath[k + 1]).isYes ? 'yes' : 'no',
                entry: node
              })
            })
            rules.push(rule)
          }
        })

        return rules
      }
    }
  },
  created() {
    this.esNodePreviewWhitelist = ['socGreaterThan', 'greaterThanZero']
  },
  methods: {
    entryInfoId({ strategyId, ruleIdx, decisionIdx, decisionTreeIdx }) {
      return `${strategyId}_._${ruleIdx}_._${decisionIdx}_._${decisionTreeIdx}`
    },
    shouldShowDecisionTreeEntryInfo({ strategyId, ruleIdx, decisionIdx, decisionTreeIdx }) {
      return this.showDecisionTreeEntryInfos.includes(
        this.entryInfoId({ strategyId, ruleIdx, decisionIdx, decisionTreeIdx })
      )
    },
    onShowActicationRules(sId) {
      if (this.getActivationRules(sId).length === 0) {
        return
      }

      const j = this.showActivationRules.findIndex((id) => sId === id)

      if (j < 0) {
        this.showActivationRules.push(sId)
      } else {
        this.showActivationRules.splice(j, 1)
      }
    },
    onToggleDecisionTreeEntryInfos(eId) {
      const j = this.showDecisionTreeEntryInfos.findIndex((id) => eId === id)

      if (j < 0) {
        this.showDecisionTreeEntryInfos.push(eId)
      } else {
        this.showDecisionTreeEntryInfos.splice(j, 1)
      }

      // close all other 'greaterThanZero' previews, because only one can be showed at a time
      this.showDecisionTreeEntryInfos.forEach((id, i) => {
        if (eId === id) {
          return
        }

        try {
          let idx = id.split('_._') // decisionTreeIdx on last position
          idx = idx[idx.length - 1]
          const entry = this.getDecisionTreeEntry(parseInt(idx))
          if (entry.name === 'greaterThanZero') {
            this.showDecisionTreeEntryInfos.splice(i, 1)
          }
        } catch (err) {
          this.$log.error('Failed to close all other "greaterThanZero" previews.')
          this.$log.error(err)
        }
      })
    }
  }
}
</script>
<style>
ul.no-bullets {
  list-style-type: none;
}

.path {
  color: var(--primary);
  list-style-type: none;
  margin: 10px;
  padding: 0;
  overflow: hidden;
}

.path li > span {
  display: block;
  /*display: inline-block;*/
  text-align: center;
  /*color: var(--primary);*/
  /*color: #666;*/
  padding: 15px 20px 15px 20px;
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid var(--primary);
  margin-bottom: 35px;
  font-size: 11pt;
}

/* Vertical lines connecting list items*/
.path li > span:after {
  content: '';
  background: #ccc;
  position: absolute;
  bottom: -37px;
  left: 50%;
  height: 36px;
  width: 1px;
}

/* Hover effect */
/*.path li > span:hover {
  background: var(--primary);
  color: #fff;
  border: 1px solid #94a0b4;
}*/

/* Overlay text content on path */
.path li > span:before {
  content: attr(data-decison-answer);
  color: var(--primary);
  background: none;
  position: absolute;
  bottom: -30px;
  padding: 0;
  right: 55%;
}

/* Hide vertical line after last child list item*/
.path li:last-child > span:after {
  display: none;
}

/* Remove bottom margin from last visible child */
.path li:last-child > span {
  margin-bottom: 0;
}

.path i:hover {
  cursor: pointer;
}
</style>
