/**
 * @module view-helper/props-validation
 */

/**
 * Takes a topology.Source.IecId as plain JS objects and validates entries.
 *
 * @function
 *
 * @param {object} iecId (required) has to be a plain JS object of protobuf ems.topology.Source.IecId
 *
 * @return {boolean}
 */
export function validateIecId(iecId) {
  let isValid = true
  isValid = isValid && typeof iecId.logicalDeviceName === 'string' && iecId.logicalDeviceName.length > 0
  isValid = isValid && typeof iecId.iecNodePrefix === 'string' && iecId.iecNodePrefix.length > 0
  isValid = isValid && typeof iecId.iecNodePostfix === 'number' && iecId.iecNodePostfix >= 0

  return isValid
}
