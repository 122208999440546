<template>
  <ConfirmationModal
    :visible="evaluate && hookEvaluation.result === 'showWarningDialog'"
    color="danger"
    size="lg"
    :title="dialogTitle"
    @update:confirmation="$emit('response', $event)"
  >
    <p v-if="dialogContentBold" class="font-weight-bold"> &#9888;&#65039; {{ dialogContentBold }} </p>
    {{ dialogContent }}
  </ConfirmationModal>
</template>

<script>
import { deviceConfigHooks, createCompareResults } from '@/view-helper/device-config/device-config-hooks'
import ConfirmationModal from '@/components/snippets/confirmation-modal'

export default {
  name: 'ApxDeviceConfigPreSubmitHook',
  components: { ConfirmationModal },
  props: {
    initialData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    submitData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    evaluate: {
      type: Boolean,
      required: true
    },
    translationPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    hookEvaluation() {
      const list = createCompareResults(this.submitData, this.initialData, deviceConfigHooks)
      if (list.length) {
        return list[0]
      } else {
        return { result: '', path: [] }
      }
    },
    dialogTitle() {
      if (this.hookEvaluation.path.length === 0) {
        return ''
      }
      return this.$t(`${this.translationPath}.${this.hookEvaluation.path.join('.')}.modalTitle`)
    },
    dialogContentBold() {
      if (this.hookEvaluation.path.length === 0) {
        return ''
      }
      const path = `${this.translationPath}.${this.hookEvaluation.path.join('.')}.modalContentBold`
      if (this.$te(path)) {
        return this.$t(path)
      } else {
        return ''
      }
    },
    dialogContent() {
      if (this.hookEvaluation.path.length === 0) {
        return ''
      }
      return this.$t(`${this.translationPath}.${this.hookEvaluation.path.join('.')}.modalContent`)
    }
  },
  watch: {
    evaluate: function () {
      if (this.evaluate) {
        switch (this.hookEvaluation.result) {
          case 'printConsole':
            console.log('found', this.hookEvaluation.path)
            this.$emit('response', true)
            break
          case 'showWarningDialog':
            break
          default:
            this.$emit('response', true)
        }
      }
    }
  }
}
</script>
