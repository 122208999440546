<template>
  <div class="my-1 mx-1 pt-2 pt-lg-3 es-decision-tree-entry">
    <div v-if="entry.idx > 0" class="text-center text-muted">
      <span v-if="entry.isYes" class="d-inline-block small">
        {{ $t(`${tBasePath}.node.ifYes`) }}
      </span>
      <span v-if="entry.isNo" class="d-inline-block small">
        {{ $t(`${tBasePath}.node.ifNo`) }}
      </span>
    </div>
    <div
      :class="{
        'es-decision-tree-entry-container': true,
        'es-decision-tree-entry-separator': entry.idx > 0
      }"
    >
      <EsNodeOptions v-if="optionEditMode && entry.isNode" :entry="entry" />
      <h6
        :class="{
          'mt-2': entry.idx > 0,
          'mt-lg-3': entry.idx > 0,
          'text-center': true,
          'text-primary': entry.name,
          'text-danger': !entry.name,
          'font-weight-bold': true
        }"
      >
        <span v-if="entry.isNode" class="mr-1 d-inline-block align-bottom mt-1">
          <CButton
            class="small"
            :color="entry.name ? 'primary' : 'danger'"
            size="sm"
            variant="outline"
            @click="optionEditMode = !optionEditMode"
          >
            <i>
              <CIcon name="cil-options" size="sm" />
              <CBadge v-if="entry.node.optionsList && entry.node.optionsList.length > 0" color="primary">
                {{ entry.node.optionsList.length }}
              </CBadge>
            </i>
          </CButton>
        </span>
        <span class="mr-1 d-inline-block align-bottom">
          <span class="d-block text-muted text-left small mb-1">{{ formatedType }}</span>
          <span class="d-block text-primary">{{ formatedName }}</span>
        </span>
        <span class="ml-1 d-inline-block align-bottom mt-1">
          <CButton
            class="small"
            :color="entry.name ? 'primary' : 'danger'"
            size="sm"
            variant="outline"
            @click="editMode = !editMode"
          >
            <i>
              <CIcon name="cil-settings" size="sm" />
            </i>
          </CButton>
        </span>
      </h6>
      <div v-if="editMode && entry.isNode" class="mt-3">
        <EsNodePreview v-if="esNodePreviewWhitelist.includes(entry.name)" :border="true" :entry="entry" />
        <!-- :description="$t(`${tBasePath}.selectNodeType`)" -->
        <CSelect :value="decisionNodeType" :options="decisionNodeTypeOpts" @update:value="onSelectDecisionTreeNode">
          <template #append>
            <CButton type="button" size="sm" color="primary" @click="onSelectDecisionTreeNode(decisionNodeType)">
              {{ $t('main.editBtn') }}
            </CButton>
          </template>
        </CSelect>
      </div>
      <div v-if="editMode && !entry.isNode" class="mt-3">
        <!-- :description="$t(`${tBasePath}.selectLeafType`)" -->
        <CSelect :value="strategyId" :options="strategyIdOpts" @update:value="onSelectDecisionTreeLeaf" />
      </div>
      <BtnWithDescription
        v-if="editMode && allowNodeConversion"
        :main-btn-color="entry.isNode ? 'danger' : 'primary'"
        main-btn-variant="outline"
        :label="toggleEntryLabel"
        :description="toggleEntryDescription"
        size="sm"
        @click="onToggleEntry"
      />
    </div>
    <div v-if="entry.isNode" class="d-flex justify-content-around">
      <EsDecisionTreeEntry
        v-for="child in entry.children"
        :key="`entry-${child.idx}-is-node-${child.isNode}`"
        :entry="child"
        :decision-node-types="decisionNodeTypes"
      />
    </div>
    <!-- form for decision node params -->
    <CModal
      :title="nodeParamsFormTitle"
      size="lg"
      color="primary"
      :show="showDecisionNodeForm"
      @update:show="onShowDecisionForm"
    >
      <div v-if="showDecisionNodeForm && !decisionNodeType">
        <p>
          {{ $t(`${tBasePath}.form.noDecisionQuestion.info`) }}
        </p>
      </div>
      <EsSocNodeForm
        v-if="showDecisionNodeForm && decisionNodeType === 'socGreaterThan'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
      />
      <EsOffgridNodeForm
        v-if="showDecisionNodeForm && decisionNodeType === 'gridStateIs'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
      />
      <EsVppGridBatNodeForm
        v-if="showDecisionNodeForm && decisionNodeType === 'vppGridBatTargetIs'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
      />
      <EsInvalidCheckNodeForm
        v-if="showDecisionNodeForm && decisionNodeType === 'invalidCheck'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
      />
      <EsTimeSwitchForm
        v-if="showDecisionNodeForm && decisionNodeType === 'greaterThanZero'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
        @route-to:tou="routeToTou"
      />
      <EsChaiScriptNodeForm
        v-if="showDecisionNodeForm && decisionNodeType === 'chaiScript'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
      />
      <EsValueHystresisForm
        v-if="showDecisionNodeForm && decisionNodeType === 'valueHysteresis'"
        :idx="entry.idx"
        @cancel="onShowDecisionForm(false)"
        @confirmed="onUpdateDecisionNodeParams"
        @route-to:tou="routeToTou"
      />
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import BtnWithDescription from '@/components/snippets/btn-with-description'
import EsNodePreview from './es-node-preview'
import EsOffgridNodeForm from './es-offgrid-node-form'
import EsSocNodeForm from './es-soc-node-form'
import EsTimeSwitchForm from './es-time-switch-form'
import EsInvalidCheckNodeForm from './es-invalid-check-node-form'
import EsChaiScriptNodeForm from './es-chai-script-node-form'
import EsValueHystresisForm from './es-value-hysteresis-form'
import EsNodeOptions from '@/components/ems/energy-services/energy-service-decision-tree/es-node-options'
import EsVppGridBatNodeForm from '@/components/ems/energy-services/energy-service-decision-tree/es-vpp-grid-bat-node-form.vue'
// import { cloneDeep } from 'lodash'

import { CACHE_IDS } from '@/store/modules/cache'
import { API_LOADING_IDS } from '@/store/modules/api-loading-status'
import { newToastNotificationMsg } from '@/store/modules/notifications'
import { getHumanizedDecisionNodeLabel } from '@/view-helper/ems/ems-energy-service-helper'
import Feature from '@/../config/feature/feature.json'
import { eventBus } from '@/view-helper/event-bus'

const CACHE_PATH = 'es-decision-tree-entry'
const tBasePath = 'ems.energyService.config.decisionTree'

export default {
  name: 'EsDecisionTreeEntry',
  components: {
    BtnWithDescription,
    EsNodePreview,
    EsOffgridNodeForm,
    EsSocNodeForm,
    EsTimeSwitchForm,
    EsInvalidCheckNodeForm,
    EsChaiScriptNodeForm,
    EsValueHystresisForm,
    EsNodeOptions,
    EsVppGridBatNodeForm
  },
  props: {
    entry: {
      type: Object,
      required: true
    },
    decisionNodeTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editMode: false,
      decisionNodeType: null,
      previousDecisionNodeType: null,
      strategyId: null,
      showDecisionNodeForm: false,
      optionType: 'filterLast',
      optionEditMode: false
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loaded']),
    ...mapGetters('emsEnergyServicesConfig', ['strategyIds', 'nodeCount']),
    ...mapState('emsEnergyServicesConfig', ['defaultStrategyId']),
    ...mapGetters('auth', ['disabledFeatures', 'getFeatureRestrictions']),
    loadedStrategyActivationRules() {
      return this.loaded(API_LOADING_IDS.ems.esStrategyActivationRules)
    },
    formatedName() {
      if (!this.entry.name && this.entry.isNode) {
        return this.$t(`${tBasePath}.node.emptyName`)
      }

      if (!this.entry.name && !this.entry.isNode) {
        return this.$t(`${tBasePath}.leaf.emptyName`)
      }

      if (this.entry.isNode) {
        return getHumanizedDecisionNodeLabel(this.entry.name, this.entry)
      }

      return this.entry.name
    },
    formatedType() {
      if (this.entry.isNode) {
        return this.$t(`${tBasePath}.node.name`)
      } else {
        return this.$t(`${tBasePath}.leaf.name`)
      }
    },
    nodeParamsFormTitle() {
      switch (this.decisionNodeType) {
        case 'socGreaterThan':
          return this.$t(`${tBasePath}.form.socGreaterThan.title`)
        case 'gridStateIs':
          return this.$t(`${tBasePath}.form.gridStateIs.title`)
        case 'greaterThanZero':
          return this.$t(`${tBasePath}.form.greaterThanZero.title`)
        case 'invalidCheck':
          return this.$t(`${tBasePath}.form.invalidCheck.title`)
        case 'chaiScript':
          return this.$t(`${tBasePath}.form.chaiScript.title`)
        case 'valueHysteresis':
          return this.$t(`${tBasePath}.form.valueHysteresis.title`)
        case 'vppGridBatTargetIs':
          return this.$t(`${tBasePath}.form.vppGridBatTargetIs.title`)
        default:
          return this.$t(`${tBasePath}.node.emptyName`)
      }
    },
    toggleEntryLabel() {
      if (this.entry.idx === 0) {
        return this.$t(`${tBasePath}.removeDecisionTree`)
      } else if (this.entry.isNode) {
        return this.$t(`${tBasePath}.convertToLeaf`)
      } else {
        return this.$t(`${tBasePath}.convertToNode`)
      }
    },
    toggleEntryDescription() {
      if (this.entry.idx === 0) {
        return this.$t(`${tBasePath}.removeDecisionTreeDescription`)
      } else if (this.entry.isNode) {
        return this.$t(`${tBasePath}.convertToLeafDescription`)
      } else {
        return this.$t(`${tBasePath}.convertToNodeDescription`)
      }
    },
    blacklistedDecisionNodeTypes() {
      const blacklist = []
      if (this.disabledFeatures.includes('time_of_use')) {
        blacklist.push('greaterThanZero')
      }

      return blacklist
    },
    decisionNodeTypeOpts() {
      const opts = [
        {
          value: null,
          label: this.$t(`${tBasePath}.node.emptyName`)
        }
      ]
      this.decisionNodeTypes.forEach((id) => {
        if (this.blacklistedDecisionNodeTypes.includes(id)) {
          return
        }
        if (id === 'chaiScript' && Feature.chai !== 'true') {
          return
        }
        opts.push({
          value: id,
          label: getHumanizedDecisionNodeLabel(id, this.entry)
        })
      })

      return opts
    },
    strategyIdOpts() {
      const opts = [
        {
          value: null,
          label: this.$t(`${tBasePath}.leaf.emptyName`)
        },
        {
          value: 'DEFAULT',
          label: this.$t(`${tBasePath}.leaf.DEFAULT`)
        }
      ]
      this.strategyIds.forEach((id) => {
        opts.push({
          value: id,
          label: id
        })
      })

      return opts
    },
    allowNodeConversion() {
      if (this.entry.isNode) return true

      const restrictions = this.getFeatureRestrictions({
        feature: 'multi_use'
      })

      if (!restrictions || !restrictions?.enabled) return false

      if (restrictions.enabled && !restrictions?.strategyTree?.maxPermittedNodes) return true

      return this.nodeCount < restrictions?.strategyTree?.maxPermittedNodes
    }
  },
  watch: {
    loadedStrategyActivationRules(yes) {
      if (yes) {
        this.init()
      }
    }
  },
  created() {
    this.tBasePath = tBasePath
    this.esNodePreviewWhitelist = ['socGreaterThan', 'chaiScript']
    this.init()
  },
  methods: {
    async init() {
      try {
        const hasCache = await this.$store.dispatch('cache/replayLocalState', {
          id: this.$route.query['esc-cache'],
          vm: this,
          path: CACHE_PATH,
          condition: (c) => c.idx === this.entry.idx,
          blacklist: ['idx']
        })
        if (!hasCache) {
          this.editMode = !this.entry.name
          this.decisionNodeType = this.entry.params.decisionNodeType
          this.strategyId = this.entry.params.strategyId
        }
      } catch (err) {
        this.$log.error(err)
      }
    },
    routeToTou(payload) {
      this.$store.dispatch('cache/cacheStoreState', { id: CACHE_IDS.emsEvalExpression, path: 'emsEvalExpression' })
      this.$store.commit('cache/SET_CACHE', {
        id: CACHE_IDS.emsEnergyServicesConfig,
        type: 'local',
        payload: Object.assign(
          {
            [CACHE_PATH]: {
              idx: this.entry.idx,
              editMode: this.editMode,
              decisionNodeType: this.decisionNodeType,
              previousDecisionNodeType: this.previousDecisionNodeType,
              strategyId: this.strategyId,
              showDecisionNodeForm: this.showDecisionNodeForm
            }
          },
          payload
        )
      })
      this.$log.debug(
        `[Cache] Succesfully cached local state for emsEnergyServicesConfig with ID ${CACHE_IDS.emsEnergyServicesConfig} `
      )

      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          autohide: 8000,
          type: 'info',
          title: this.$t('main.cache.workStatus'),
          content: this.$t('main.cache.workStatusDescription')
        })
      )

      this.$router.push({
        name: 'ems-tou-config',
        query: {
          'esc-cache': CACHE_IDS.emsEnergyServicesConfig,
          'ee-cache': CACHE_IDS.emsEvalExpression
        }
      })
    },
    onSelectDecisionTreeNode(val) {
      this.previousDecisionNodeType = this.decisionNodeType
      this.decisionNodeType = val
      this.showDecisionNodeForm = true
    },
    onSelectDecisionTreeLeaf(val) {
      this.strategyId = val
      if (val === 'DEFAULT') {
        this.strategyId = this.defaultStrategyId
      }
      this.editMode = !this.strategyId
      eventBus.$emit('update:decision-tree-leaf-params', { idx: this.entry.idx, strategyId: this.strategyId || '' })
    },
    onShowDecisionForm(show) {
      this.showDecisionNodeForm = show
      if (show) {
        return
      }
      // canceled
      this.decisionNodeType = this.previousDecisionNodeType
      this.$store.commit('cache/CLEAR_CACHE', { id: CACHE_IDS.emsEvalExpression })
    },
    onUpdateDecisionNodeParams(e) {
      this.editMode = false
      this.showDecisionNodeForm = false
      eventBus.$emit('update:decision-tree-node-params', e)
    },
    onToggleEntry() {
      if (this.entry.idx === 0) {
        eventBus.$emit('remove:decision-tree', { displayConfirm: true })
      } else {
        eventBus.$emit('toggle:decision-tree-entry', { idx: this.entry.idx, toggleToNode: !this.entry.isNode })
      }
    }
  }
}
</script>
