// package: de.mypowergrid.sherlock
// file: ext/sherlock/gpio.proto

var ext_sherlock_gpio_pb = require("../../ext/sherlock/gpio_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var GPIOService = (function () {
  function GPIOService() {}
  GPIOService.serviceName = "de.mypowergrid.sherlock.GPIOService";
  return GPIOService;
}());

GPIOService.GetGPIOInfo = {
  methodName: "GetGPIOInfo",
  service: GPIOService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_gpio_pb.GetGPIOInfoRequest,
  responseType: ext_sherlock_gpio_pb.GetGPIOInfoResponse
};

GPIOService.GetGPIOStatus = {
  methodName: "GetGPIOStatus",
  service: GPIOService,
  requestStream: false,
  responseStream: false,
  requestType: ext_sherlock_gpio_pb.GetGPIOStatusRequest,
  responseType: ext_sherlock_gpio_pb.GetGPIOStatusResponse
};

exports.GPIOService = GPIOService;

function GPIOServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

GPIOServiceClient.prototype.getGPIOInfo = function getGPIOInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GPIOService.GetGPIOInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GPIOServiceClient.prototype.getGPIOStatus = function getGPIOStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GPIOService.GetGPIOStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.GPIOServiceClient = GPIOServiceClient;

