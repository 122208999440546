import i18n from '@/i18n'

function deviceSettingsBcHelper($route) {
  const items = [
    {
      text: i18n.t('layout.configure.devices.nav_sidebar_title'),
      to: {
        name: 'config'
      }
    }
  ]

  const device = $route.params.device
  if (device) {
    const isListView = new RegExp(`.*${device}$`).test($route.path)
    const deviceRouteName = isListView ? device : `${device}s`

    items.push({
      text: i18n.t(`layout.configure.${deviceRouteName}.nav_title`),
      to: {
        name: 'config-devices',
        params: {
          device: deviceRouteName
        }
      }
    })

    if (!isListView) {
      const action = $route.path.match(new RegExp(`.*${device}/(?<action>\\w+).*`)).groups.action

      // user $route.path, since this is the last breadcrumb
      // will be ignored anyway :)
      items.push({
        text: i18n.t(`main.configActions.${action}`),
        to: $route.path
      })
    }
  }
  return items
}

function systemSettingsBcHelper($route) {
  const items = [
    {
      text: i18n.t('layout.configure.system-settings.nav_title'),
      to: {
        name: 'system-settings'
      }
    }
  ]

  let text

  if ($route.name === 'system-settings-license') {
    text = i18n.t('layout.license.nav_title')
  } else if ($route.name === 'system-settings-network') {
    text = i18n.t('layout.network.nav_title')
  } else if ($route.name === 'system-settings-fwupgrade') {
    text = i18n.t('layout.fwupgrade.nav_title')
  } else if ($route.name === 'system-settings-open-modbus-interface') {
    text = i18n.t('layout.omi.nav_title')
  }

  if (text) {
    items.push({
      text,
      to: {
        name: $route.name
      }
    })
  }

  return items
}

function interfacesBcHelper($route) {
  const items = [
    {
      text: i18n.t('layout.interfaces.nav_title'),
      to: {
        name: 'interface-info'
      }
    }
  ]

  if (/io.*/.test($route.path)) {
    items.push({
      text: i18n.t('layout.io.nav_title'),
      to: {
        name: 'interface-info-io'
      }
    })
  } else if (/ethernet.*/.test($route.path)) {
    items.push({
      text: i18n.t('layout.network.nav_title'),
      to: {
        name: 'interface-info-ethernet'
      }
    })
  } else if (/one-wire.*/.test($route.path)) {
    items.push({
      text: i18n.t('layout.onewire.nav_title'),
      to: {
        name: 'interface-info-onewire'
      }
    })
  }

  return items
}

function emsBcHelper($route) {
  const items = []
  $route.matched.forEach((m) => {
    const item = {
      to: {
        name: m.name
      }
    }
    items.push(item)

    // choose config over convention :)
    switch (m.name) {
      case 'ems':
        item.text = i18n.t('layout.ems.nav_title')
        break
      case 'ems-topology':
        item.text = i18n.t('layout.ems.topology.nav_title')
        break
      case 'ems-energy-services':
        item.text = i18n.t('layout.ems.energyService.nav_title')
        break
      case 'ems-energy-services-config':
        item.text = i18n.t('layout.ems.energyService.config.nav_title')
        break
      case 'ems-energy-services-config-strategies':
        item.text = i18n.t('layout.ems.energyService.config.strategies.nav_title')
        break
      case 'ems-energy-services-config-activation':
        item.text = i18n.t('layout.ems.energyService.config.activation.nav_title')
        break
      case 'ems-energy-services-config-submission':
        item.text = i18n.t('layout.ems.energyService.config.submission.nav_title')
        break
      default:
        // no match, just remove the item
        items.pop()
    }
  })

  return items
}

export function currentBc($route) {
  if (/^\/device-settings.*/.test($route.path)) {
    return deviceSettingsBcHelper($route)
  } else if (/^\/system-settings.*/.test($route.path)) {
    return systemSettingsBcHelper($route)
  } else if (/^\/interface-info.*/.test($route.path)) {
    return interfacesBcHelper($route)
  } else if (/^\/ems.*/.test($route.path)) {
    return emsBcHelper($route)
  }

  return []
}
