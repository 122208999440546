/** @module store/user */

import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import { AuthenticationError } from '@/api/error-handling'
import * as apiAuth from '@/api/auth'
import i18n, { DEFAULT_LOCALE, isLocaleSupported } from '@/i18n'
import logger from '@/logger'

/**
 * Store state for `user`.
 *
 * The `id` is currently NOT supported.
 * The token `jwt` (json web token) is stored in a httpOnly cookie.
 *
 * The `{object} claims` is a `auth.Claims` plain JS proto object.
 *
 * @function
 *
 * @return {object}
 */
const state = () => ({
  id: null,
  username: '',
  claims: {},
  locale: DEFAULT_LOCALE
})

/**
 * Allows to check de.mypowergrid.auth claims validity
 *
 * @function
 * @private
 *
 * @param {object} plain JS object of `de.mypowergrid.auth.Validity` proto-msg
 *
 * @return {boolean}
 */
function checkValidity(validity, { timestampField }) {
  let valid = false
  if (!validity) {
    logger.warn('Authentication token invalid. Missing validity in claims.')

    return valid
  }

  const now = moment()
  const timestamp = moment.unix(validity[timestampField].seconds)

  switch (timestampField) {
    case 'notBefore':
      valid = !now.isBefore(timestamp)
      break
    case 'notAfter':
      valid = !now.isAfter(timestamp)
      break
  }

  if (!valid) {
    switch (timestampField) {
      case 'notBefore':
        logger.warn(
          `Authentication token invalid. Cannot be used before ${timestamp.format()}. Used reference time ${now.format()}`
        )
        break
      case 'notAfter':
        logger.warn(
          `Authentication token invalid. Cannot be used after ${timestamp.format()}. Used reference time ${now.format()}`
        )
        break
    }
  }

  return valid
}

/**
 * `isLoggedIn` returns `true` if the user has valid claims, `false` otherwise. NOTE: Due to caching timestamp validity is checked only the first time. This is not desired, however, not caching the result is also not desired. We decided to cache the result. The BE is still validating each request.
 *
 */
const getters = {
  lang: (state) => {
    return state.locale.match(/^[a-z]{2}/i)[0].toLowerCase()
  },
  username: (state) => {
    return state.username
  },
  claimsNotBefore: (state) => {
    if (!state.claims.validity) {
      return null
    }

    return moment.unix(state.claims.validity.notBefore.seconds).locale(state.locale || 'en')
  },
  claimsNotAfter: (state) => {
    if (!state.claims.validity) {
      return null
    }

    return moment.unix(state.claims.validity.notAfter.seconds).locale(state.locale || 'en')
  },
  satisfiesClaimsNotBeforeTimestamp: (state) => {
    return checkValidity(state.claims.validity, { timestampField: 'notBefore' })
  },
  satisfiesClaimsNotAfterTimestamp: (state) => {
    return checkValidity(state.claims.validity, { timestampField: 'notAfter' })
  },
  isLoggedIn: (state, getters) => {
    if (!state.username || isEmpty(state.claims)) {
      return false
    }

    return getters.satisfiesClaimsNotBeforeTimestamp && getters.satisfiesClaimsNotAfterTimestamp
  }
}

const mutations = {
  SET_LOCALE(state, locale) {
    if (isLocaleSupported(locale)) {
      state.locale = locale
    } else {
      state.locale = DEFAULT_LOCALE
    }

    // tmp hack to avoid `[vue-i18n] Fall back to translate the keypath`
    // for e.g. en-US -> en fallback
    // for fallbacks see https://kazupon.github.io/vue-i18n/guide/fallback.html#implicit-fallback-using-locales
    const lang = getters.lang(state)
    if (lang !== i18n.locale) {
      i18n.locale = lang
    }
  },
  SET_USERNAME(state, username) {
    state.username = username
  },
  LOGIN(state, { claims }) {
    if (isNil(claims) || isEmpty(claims)) {
      throw new TypeError('Claims cannot be blank.')
    }

    state.username = claims.principal.username
    state.claims = claims
  },
  LOGOUT(state) {
    state.username = ''
    state.claims = {}
  }
}

/**
 * API call to login
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username
 * @param {string} params.password is the password
 *
 * @throws {AuthenticationError}
 *
 * @return {promise}
 *  Resolves to a non-empty `auth.Claims` plain proto msg object, if logged in.
 *  Resolves to an async `function` to be called with `{ newPassword: '...' }`, if login with new credentials is required. If this call is successful, it will require a new login with the new password.
 *  Rejects to a `AuthenticationError`` with `code`, `msg` (or `message`), and `type`.
 */
async function login({ commit, dispatch }, { username, password }) {
  try {
    // if fails, throws AuthenticationError
    const msg = await apiAuth.login({ username, password })
    if (
      !msg.safeGetMsgChain(['claims', 'principal', 'username']) ||
      !msg.safeGetMsgChain(['claims', 'validity', 'not_after'])
    ) {
      throw new AuthenticationError({
        msg: 'Server response is invalid.',
        type: 'PROCESSING_ERROR'
      })
    }
    const claims = msg.getClaims().toObject()

    commit('LOGIN', { claims })
    dispatch('cache/clear', undefined, { root: true })

    return claims
  } catch (err) {
    // NEW_CREDENTIALS_REQUIRED
    if (err.name === 'AuthenticationError' && err.type === 'SERVICE_ERROR' && err.code === 9) {
      return {
        loginWithNewCredentials: async function ({ newPassword }) {
          const msg = await apiAuth.login({ username, password, newPassword })

          return msg.getClaims() ? msg.getClaims().toObject() : undefined
        }
      }
    }

    throw err
  }
}

/**
 * API call to logout.
 *
 * @function
 *
 * @return {promise}
 */
function logout({ commit, dispatch }) {
  const doCommit = (msg) => {
    commit('LOGOUT')
    dispatch('cache/clear', undefined, { root: true })
    return msg
  }

  return apiAuth.logout().then(doCommit)
}

/**
 * API call to update user's username and/or password.
 * In case of success, it will commit a logout (because the BE logs the user out, too).
 *
 * @function
 *
 * @param {object} params see [updateUser]{@link @link module:src/api/auth/index.updateUser}
 * @param {string} params.currentUsername is optional. If blank, it will be taken from the user's store state.
 *
 * @return {promise}
 */
function updateUser({ commit, state }, { currentUsername, currentPassword, newUsername, newPassword }) {
  const doCommit = (msg) => {
    commit('LOGOUT')
    commit('SET_USERNAME', newUsername || currentUsername)

    if (newUsername) {
      commit('users/REMOVE_USER', currentUsername, { root: true })
    }

    return msg
  }

  if (!currentUsername) {
    currentUsername = state.username
  }

  return apiAuth.updateUser({ currentUsername, currentPassword, newUsername, newPassword }).then(doCommit)
}

/**
 * API call to delete a user.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username (optional) is the username of the user to be deleted. If blank, it will be taken from the user's store state.
 *
 * @return {promise}
 */
function deleteUser({ commit, state }, { username } = {}) {
  const doCommit = (msg) => {
    commit('LOGOUT')

    return msg
  }

  if (!username) {
    username = state.username
  }

  return apiAuth.deleteUser({ username }).then(doCommit)
}

export default {
  namespaced: true,
  state,
  getters,
  actions: {
    login,
    logout,
    updateUser,
    deleteUser
  },
  mutations
}
