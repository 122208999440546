/**
 * @module view-helper/device-config
 */

import { isEmpty } from 'lodash'
import cfgValidationsFactory from '@/validations/device-config-form-validators'

/**
 * Allows to validate and check validation state of a device config.
 *
 * @constructor
 *
 * @param {object} params
 * @param {object} pramas.vue (required) has to be the Vue.js component instance. `vue` has to have data `cfg`, which are under validation.
 * @param {string} params.configMsgName has to be the full proto message name of the 'bare' config (dot separated), e.g. `Meter.Config1`. (Can be blank, than validation is skipped.)
 */
export function DeviceConfigFormValidator({ vue, configMsgName }) {
  if (!vue || typeof vue !== 'object') {
    throw new TypeError('The "vue" instance is missing as input argument. It is required.')
  }

  if (typeof vue.cfg !== 'object') {
    throw new TypeError('The "vue" instance has to have data property "cfg", which is under validation.')
  }

  /**
   * Allows to return an object with config field validators, as used by vualidate. Should be plugged into the vue compoment `validations` method.
   *
   * @function
   *
   * @return {object}
   */
  this.buildCfgValidations = buildCfgValidations

  /**
   * Applies the field validation(s) to the field(s).
   *
   * @function
   *
   * @param {array} fieldNames are a list of proto message field names, w.r.t. the 'bare' device config (NOT the full device config). E.g. `modbusTcpSlaveId` of the `Meter.Config1` 'bare' config. If blank, an overall validation is applied.
   *
   */
  this.validate = function (fieldNames) {
    if (!hasValidations()) {
      return
    }

    if (!fieldNames) {
      vue.$v.cfg.$touch()
      return
    }

    const c = getFieldValidator(fieldNames)

    if (!c || typeof c.$touch !== 'function') {
      return
    }
    c.$touch()
  }

  /**
   * Checks whether field(s) are valid.
   *
   * @function
   *
   * @param {object} fieldNames are a list of proto message field names, see [validate]{@link module:view-helper/device-config.DeviceConfigFormValidator#validate}. If blank, `$anyError` is checked.
   *
   * @return {boolean|null}
   */
  this.isValid = function (fieldNames) {
    if (!hasValidations()) {
      return null
    }

    if (!fieldNames) {
      return !vue.$v.cfg.$anyError
    }

    const c = getFieldValidator(fieldNames)
    // not using $error, otherwise do not showing any validation is not possible
    return c && c.$dirty ? !c.$invalid : null
  }

  this.reset = function (fieldNames) {
    if (!hasValidations()) {
      return
    }

    if (!fieldNames) {
      vue.$v.cfg.$reset()
      return
    }

    const c = getFieldValidator(fieldNames)
    c.$reset()
  }

  // private
  function buildCfgValidations() {
    // Having an explicit evaluation of possible combination will fail for a larger amount (> 2) of different oneofs
    // Currently: `bms, nabox` oneofs
    if (configMsgName === 'Inverter.Config4') {
      if (vue.cfg.bmsTesvoltBms && vue.cfg.naBoxTesvoltNabox) {
        return cfgValidationsFactory(
          'Inverter.Config4.bmsTesvoltBms.naBoxTesvoltNabox',
          vue.$store.getters['auth/omiOnlyEnabled']
        )
      }
      if (vue.cfg.bmsTesvoltBms) {
        return cfgValidationsFactory('Inverter.Config4.bmsTesvoltBms', vue.$store.getters['auth/omiOnlyEnabled'])
      }
      if (vue.cfg.naBoxTesvoltNabox) {
        return cfgValidationsFactory('Inverter.Config4.naBoxTesvoltNabox', vue.$store.getters['auth/omiOnlyEnabled'])
      }
    }

    return cfgValidationsFactory(configMsgName)
  }

  function hasValidations() {
    return !isEmpty(buildCfgValidations().cfg)
  }

  function getFieldValidator(fieldNames) {
    let c = vue.$v ? vue.$v.cfg : null
    fieldNames.forEach((f) => {
      if (c) {
        c = c[f]
      }
    })

    return c
  }
}
