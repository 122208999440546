import { helpers, ipAddress } from 'vuelidate/lib/validators'

export const modbusTcpAddress = (value) => {
  if (!value) {
    return false
  }
  let matches = value.match(/^(?<ip>.+):(?<port>.+)$/i)

  if (!matches || !matches.groups) {
    return false
  } else {
    matches = matches.groups
  }

  const ip = matches.ip
  const port = matches.port

  if (!ip || !port) {
    return false
  }

  let valid = true
  if (/^\d.+/.test(ip)) {
    valid = valid && ipAddress(ip)
  } else if (/^\[.+]$/.test(ip)) {
    // todo test ipv6 in square brackets
  } else {
    // todo test hostname string
  }

  if (/^\d.+/.test(port)) {
    valid = valid && /^\d+$/.test(port)
  } else {
    // todo test service name
  }

  return valid
}

/**
 * Validates if ipv4 or ipv6 or valid domain name.
 *
 * TODO: ipv6 validation
 *
 * @function
 *
 * @param {string} addr
 *
 * @return {boolean}
 */
export const httpAddress = (addr) => {
  if (!addr) {
    return false
  }

  let valid = true
  if (/^\d.+/.test(addr)) {
    // ipv4
    valid = valid && ipAddress(addr)
  } else if (/^\[.+]$/.test(addr)) {
    // todo test ipv6 in square brackets
  } else if (addr === 'localhost') {
    // pass
  } else {
    // domain name
    valid = valid && /^([A-Za-z0-9-]+\.|)[A-Za-z0-9-]+\.[A-Za-z]+$/.test(addr)
  }

  return valid
}

export const strongPasswordRequired = (password) => {
  return (
    password.length >= 20 ||
    (password.length >= 10 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[#<>?~!@$%^&*-]/.test(password))
  )
}

export const getIncludes = (valuesToBeIncluded = [], { shouldType = undefined } = {}) => {
  return helpers.withParams({ type: 'includes' }, (val) => {
    switch (shouldType) {
      case 'numeric':
        val = parseFloat(val)
        break
      case 'integer':
        val = parseInt(val)
        break
    }

    return valuesToBeIncluded.includes(val)
  })
}
