<template>
  <CHeader fixed with-subheader light>
    <template v-if="isLoggedIn">
      <CToggler in-header class="ml-2 mr-md-5 d-lg-none" @click="$store.commit('coreui/TOGGLE_SIDEBAR_MOBILE')" />
      <CToggler in-header class="ml-2 d-md-down-none" @click="$store.commit('coreui/TOGGLE_SIDEBAR_DESKTOP')" />
      <CHeaderBrand class="mx-auto text-primary" to="/">
        <slot name="brand-icon"></slot>
      </CHeaderBrand>
    </template>
    <CHeaderNav :class="isLoggedIn ? 'none' : 'ml-auto'">
      <CHeaderNavItem class="px-0">
        <button class="c-header-nav-btn" @click="() => $store.commit('coreui/TOGGLE', 'darkMode')">
          <CIcon v-if="isDark" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <CHeaderNavItem>
        <ApxLangToggle />
      </CHeaderNavItem>
      <CHeaderNavItem v-if="isLoggedIn">
        <CDropdown add-menu-classes="pt-0" class="c-header-nav-items" in-nav placement="bottom-end">
          <template #toggler>
            <CHeaderNavLink>
              <CIcon name="cil-user" :height="24" />
            </CHeaderNavLink>
          </template>
          <CDropdownHeader class="text-center pb-0" color="light" tag="div">
            <strong>
              {{ $t('management.user.global.username.placeholder') }}
            </strong>
          </CDropdownHeader>
          <CDropdownHeader class="text-center pt-0" color="light" tag="div">
            <strong class="text-monospace text-info">
              {{ username }}
            </strong>
          </CDropdownHeader>
          <CDropdownItem @click="showClearCache = true">
            <CIcon name="cil-info" />
            {{ $t('header.cache.cache') }}
          </CDropdownItem>
          <CDropdownItem @click="routeToUserSettings">
            <CIcon name="cis-settings" />
            {{ $t('header.userAccount.settings') }}
          </CDropdownItem>
          <CDropdownItem @click="logout">
            <CIcon name="cil-lock-locked" />
            {{ $t('header.userAccount.logout') }}
          </CDropdownItem>
        </CDropdown>
      </CHeaderNavItem>
      <CModal :title="$t('header.cache.cache')" color="warning" size="sm" :show.sync="showClearCache">
        {{ $t('header.cache.description') }}
        <template #footer>
          <CButton class="mx-1" type="button" color="warning" @click="onClearCache">
            {{ $t('header.cache.clear') }}
          </CButton>
          <CButton class="mx-1" type="button" color="warning" @click="onClearReloadCache">
            {{ $t('header.cache.clear_reload') }}
          </CButton>
        </template>
      </CModal>
    </CHeaderNav>
    <CSubheader v-if="isLoggedIn" class="justify-content-between px-3">
      <ApxBreadCrumb />
    </CSubheader>
  </CHeader>
</template>

<script>
import ApxLangToggle from '@/components/apx-lang-toggle'
import ApxBreadCrumb from '@/layout/breadcrumb'
import { newToastNotificationMsg } from '@/store/modules/notifications'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {
    ApxBreadCrumb,
    ApxLangToggle
  },
  data() {
    return {
      showClearCache: false
    }
  },
  computed: {
    ...mapGetters('coreui', {
      isDark: 'darkMode'
    }),
    ...mapGetters('user', ['isLoggedIn', 'username'])
  },
  created() {
    this.tBasePath = 'management.user.login'
  },
  methods: {
    onClearCache() {
      this.$store.commit('cache/PURGE_CACHE')
      this.showClearCache = false
    },
    onClearReloadCache() {
      this.onClearCache()
      window.location.reload()
    },
    logout() {
      this.$store.commit('user/LOGOUT') // ensures, that the router.push works as expected
      this.$store.dispatch('user/logout').catch((err) => {
        this.$logger.error('API call logout failed!')
        this.$logger.error(err.message)
      })

      this.$router.push({ name: 'login' })
      this.$store.commit(
        'notifications/PUSH_TOAST',
        newToastNotificationMsg({
          type: 'success',
          title: this.$t(`${this.tBasePath}.loginAuthToasterTitle`),
          content: this.$t('api.success.logout'),
          autohide: 10000
        })
      )
    },
    routeToUserSettings() {
      this.$router.push({ name: 'user-settings' })
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.5rem;
}

.c-header-nav .dropdown-item {
  min-width: 80px;
}
</style>
