/** @module */

import { objectToProtoMsg } from '@/grpc/parser'
import { EMS_TOPOLOGY_DESCRIPTOR, EMS_SOURCE_DESCRIPTOR } from '@/grpc/protobuf/ems-topology-helper'
import { isProto } from '@/grpc/protobuf/misc'
import { evalSetTopologyError } from '@/api/error-handling'
import { controllerRpc } from '@/api/ems/controller'
import { ReplaceSourceRequest as ReplaceSourceRequestPb } from '@/../lib/proto_js/ext/ems/controller/controller_pb'

export function getQuantities() {
  return controllerRpc('get_quantities')
}

export function getTopology() {
  return controllerRpc('get_topology')
}

/**
 * API call to set `ems.topology.Topology`.
 *
 * @function
 *
 * @param {object} topo has to be a plain or protobuf `ems.topology.Topology` message
 *
 * @return {promise}
 */
export function setTopology(topo) {
  if (!isProto(topo)) {
    topo = objectToProtoMsg({
      descriptor: EMS_TOPOLOGY_DESCRIPTOR,
      payload: topo
    })
  }

  return controllerRpc('configure_topology', topo).then(evalSetTopologyError)
}

/**
 * API call to replace a source.
 *
 * @function
 *
 * @param remove {object} is the old source to be replaced; has to be a plain or protobuf `ems.topology.Source` message
 * @param replacement {object} is the new source to replace the old source; has to be a plain or protobuf `ems.topology.Source` message
 *
 * @return {promise}
 */
export function replaceSource({ remove, replacement }) {
  if (!isProto(remove)) {
    remove = objectToProtoMsg({
      descriptor: EMS_SOURCE_DESCRIPTOR,
      payload: remove
    })
  }

  if (!isProto(replacement)) {
    replacement = objectToProtoMsg({
      descriptor: EMS_SOURCE_DESCRIPTOR,
      payload: replacement
    })
  }

  const req = new ReplaceSourceRequestPb()
  req.setRemove(remove)
  req.setReplacement(replacement)

  return controllerRpc('replace_source', req)
}

/**
 * API call to remove a source.
 *
 * @function
 *
 * @param source {object} is the source to be removed; has to be a plain or protobuf `ems.topology.Source` message
 *
 * @return {promise}
 */
export function removeSource(source) {
  if (!isProto(source)) {
    source = objectToProtoMsg({
      descriptor: EMS_SOURCE_DESCRIPTOR,
      payload: source
    })
  }

  return controllerRpc('remove_source', source)
}
