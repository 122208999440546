// source: ext/amperix/network.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ext_amperix_error_pb = require('../../ext/amperix/error_pb.js');
goog.object.extend(proto, ext_amperix_error_pb);
goog.exportSymbol('proto.de.mypowergrid.amperix.DNS', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.IPAddr', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig.ConfigCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.PingRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.PingResponse', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest', null, global);
goog.exportSymbol('proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.PingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.PingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.PingRequest.displayName = 'proto.de.mypowergrid.amperix.PingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.PingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.PingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.PingResponse.displayName = 'proto.de.mypowergrid.amperix.PingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.displayName = 'proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.NetworkConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.amperix.NetworkConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.NetworkConfig.displayName = 'proto.de.mypowergrid.amperix.NetworkConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.displayName = 'proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.displayName = 'proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.displayName = 'proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.displayName = 'proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.IPAddr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.IPAddr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.IPAddr.displayName = 'proto.de.mypowergrid.amperix.IPAddr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.DNS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.DNS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.DNS.displayName = 'proto.de.mypowergrid.amperix.DNS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.displayName = 'proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.displayName = 'proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.displayName = 'proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.PingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.PingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.PingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.PingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientTime: (f = msg.getClientTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.PingRequest}
 */
proto.de.mypowergrid.amperix.PingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.PingRequest;
  return proto.de.mypowergrid.amperix.PingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.PingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.PingRequest}
 */
proto.de.mypowergrid.amperix.PingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setClientTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.PingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.PingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.PingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.PingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp client_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.amperix.PingRequest.prototype.getClientTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.amperix.PingRequest} returns this
*/
proto.de.mypowergrid.amperix.PingRequest.prototype.setClientTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.PingRequest} returns this
 */
proto.de.mypowergrid.amperix.PingRequest.prototype.clearClientTime = function() {
  return this.setClientTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.PingRequest.prototype.hasClientTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.PingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.PingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.PingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    clientTime: (f = msg.getClientTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    serverTime: (f = msg.getServerTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.PingResponse}
 */
proto.de.mypowergrid.amperix.PingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.PingResponse;
  return proto.de.mypowergrid.amperix.PingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.PingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.PingResponse}
 */
proto.de.mypowergrid.amperix.PingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setClientTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setServerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.PingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.PingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.PingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getClientTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getServerTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
*/
proto.de.mypowergrid.amperix.PingResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp client_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.getClientTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
*/
proto.de.mypowergrid.amperix.PingResponse.prototype.setClientTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.clearClientTime = function() {
  return this.setClientTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.hasClientTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp server_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.getServerTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
*/
proto.de.mypowergrid.amperix.PingResponse.prototype.setServerTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.PingResponse} returns this
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.clearServerTime = function() {
  return this.setServerTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.PingResponse.prototype.hasServerTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uplinkConfig: (f = msg.getUplinkConfig()) && proto.de.mypowergrid.amperix.NetworkConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest;
  return proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.deserializeBinaryFromReader);
      msg.setUplinkConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUplinkConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional NetworkConfig uplink_config = 1;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.getUplinkConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest} returns this
*/
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.setUplinkConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest} returns this
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.clearUplinkConfig = function() {
  return this.setUplinkConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigRequest.prototype.hasUplinkConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.amperix.NetworkConfig.ConfigCase = {
  CONFIG_NOT_SET: 0,
  STATIC: 2,
  FULL_AUTO: 3,
  AUTO_ADDR: 4
};

/**
 * @return {proto.de.mypowergrid.amperix.NetworkConfig.ConfigCase}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.getConfigCase = function() {
  return /** @type {proto.de.mypowergrid.amperix.NetworkConfig.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.NetworkConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_static: (f = msg.getStatic()) && proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.toObject(includeInstance, f),
    fullAuto: (f = msg.getFullAuto()) && proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.toObject(includeInstance, f),
    autoAddr: (f = msg.getAutoAddr()) && proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.NetworkConfig;
  return proto.de.mypowergrid.amperix.NetworkConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.deserializeBinaryFromReader);
      msg.setStatic(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.deserializeBinaryFromReader);
      msg.setFullAuto(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.deserializeBinaryFromReader);
      msg.setAutoAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.NetworkConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatic();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.serializeBinaryToWriter
    );
  }
  f = message.getFullAuto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.serializeBinaryToWriter
    );
  }
  f = message.getAutoAddr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    primaryDns: (f = msg.getPrimaryDns()) && proto.de.mypowergrid.amperix.DNS.toObject(includeInstance, f),
    secondaryDns: (f = msg.getSecondaryDns()) && proto.de.mypowergrid.amperix.DNS.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig;
  return proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.DNS;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DNS.deserializeBinaryFromReader);
      msg.setPrimaryDns(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.DNS;
      reader.readMessage(value,proto.de.mypowergrid.amperix.DNS.deserializeBinaryFromReader);
      msg.setSecondaryDns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimaryDns();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.DNS.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryDns();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.DNS.serializeBinaryToWriter
    );
  }
};


/**
 * optional DNS primary_dns = 1;
 * @return {?proto.de.mypowergrid.amperix.DNS}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.getPrimaryDns = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DNS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DNS, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DNS|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.setPrimaryDns = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.clearPrimaryDns = function() {
  return this.setPrimaryDns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.hasPrimaryDns = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DNS secondary_dns = 2;
 * @return {?proto.de.mypowergrid.amperix.DNS}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.getSecondaryDns = function() {
  return /** @type{?proto.de.mypowergrid.amperix.DNS} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.DNS, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.DNS|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.setSecondaryDns = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.clearSecondaryDns = function() {
  return this.setSecondaryDns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.prototype.hasSecondaryDns = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: (f = msg.getIp()) && proto.de.mypowergrid.amperix.IPAddr.toObject(includeInstance, f),
    netmask: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gateway: (f = msg.getGateway()) && proto.de.mypowergrid.amperix.IPAddr.toObject(includeInstance, f),
    dnsConfig: (f = msg.getDnsConfig()) && proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig;
  return proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.IPAddr;
      reader.readMessage(value,proto.de.mypowergrid.amperix.IPAddr.deserializeBinaryFromReader);
      msg.setIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetmask(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.amperix.IPAddr;
      reader.readMessage(value,proto.de.mypowergrid.amperix.IPAddr.deserializeBinaryFromReader);
      msg.setGateway(value);
      break;
    case 4:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.deserializeBinaryFromReader);
      msg.setDnsConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.IPAddr.serializeBinaryToWriter
    );
  }
  f = message.getNetmask();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGateway();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.amperix.IPAddr.serializeBinaryToWriter
    );
  }
  f = message.getDnsConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional IPAddr ip = 1;
 * @return {?proto.de.mypowergrid.amperix.IPAddr}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.getIp = function() {
  return /** @type{?proto.de.mypowergrid.amperix.IPAddr} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.IPAddr, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.IPAddr|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.setIp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.clearIp = function() {
  return this.setIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.hasIp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string netmask = 2;
 * @return {string}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.getNetmask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.setNetmask = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IPAddr gateway = 3;
 * @return {?proto.de.mypowergrid.amperix.IPAddr}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.getGateway = function() {
  return /** @type{?proto.de.mypowergrid.amperix.IPAddr} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.IPAddr, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.IPAddr|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.setGateway = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.clearGateway = function() {
  return this.setGateway(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.hasGateway = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DNSConfig dns_config = 4;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.getDnsConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.setDnsConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.clearDnsConfig = function() {
  return this.setDnsConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig.prototype.hasDnsConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig;
  return proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    dnsConfig: (f = msg.getDnsConfig()) && proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig;
  return proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.deserializeBinaryFromReader);
      msg.setDnsConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDnsConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional DNSConfig dns_config = 1;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.getDnsConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig.DNSConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.setDnsConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.clearDnsConfig = function() {
  return this.setDnsConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig.prototype.hasDnsConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StaticConfig static = 2;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.getStatic = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig.StaticConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.prototype.setStatic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.clearStatic = function() {
  return this.setStatic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.hasStatic = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FullAutoConfig full_auto = 3;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.getFullAuto = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig, 3));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig.FullAutoConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.prototype.setFullAuto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.clearFullAuto = function() {
  return this.setFullAuto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.hasFullAuto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AutoAddrConfig auto_addr = 4;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.getAutoAddr = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig, 4));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig.AutoAddrConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
*/
proto.de.mypowergrid.amperix.NetworkConfig.prototype.setAutoAddr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.de.mypowergrid.amperix.NetworkConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.NetworkConfig} returns this
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.clearAutoAddr = function() {
  return this.setAutoAddr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.NetworkConfig.prototype.hasAutoAddr = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.IPAddr.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.IPAddr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.IPAddr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.IPAddr.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.IPAddr}
 */
proto.de.mypowergrid.amperix.IPAddr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.IPAddr;
  return proto.de.mypowergrid.amperix.IPAddr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.IPAddr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.IPAddr}
 */
proto.de.mypowergrid.amperix.IPAddr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.IPAddr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.IPAddr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.IPAddr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.IPAddr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.de.mypowergrid.amperix.IPAddr.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.de.mypowergrid.amperix.IPAddr} returns this
 */
proto.de.mypowergrid.amperix.IPAddr.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.DNS.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.DNS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.DNS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DNS.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: (f = msg.getIp()) && proto.de.mypowergrid.amperix.IPAddr.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.DNS}
 */
proto.de.mypowergrid.amperix.DNS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.DNS;
  return proto.de.mypowergrid.amperix.DNS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.DNS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.DNS}
 */
proto.de.mypowergrid.amperix.DNS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.amperix.IPAddr;
      reader.readMessage(value,proto.de.mypowergrid.amperix.IPAddr.deserializeBinaryFromReader);
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.DNS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.DNS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.DNS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.DNS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.amperix.IPAddr.serializeBinaryToWriter
    );
  }
};


/**
 * optional IPAddr ip = 1;
 * @return {?proto.de.mypowergrid.amperix.IPAddr}
 */
proto.de.mypowergrid.amperix.DNS.prototype.getIp = function() {
  return /** @type{?proto.de.mypowergrid.amperix.IPAddr} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.IPAddr, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.IPAddr|undefined} value
 * @return {!proto.de.mypowergrid.amperix.DNS} returns this
*/
proto.de.mypowergrid.amperix.DNS.prototype.setIp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.DNS} returns this
 */
proto.de.mypowergrid.amperix.DNS.prototype.clearIp = function() {
  return this.setIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.DNS.prototype.hasIp = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse;
  return proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.SetUplinkNetworkConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest;
  return proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && ext_amperix_error_pb.Error.toObject(includeInstance, f),
    uplinkConfig: (f = msg.getUplinkConfig()) && proto.de.mypowergrid.amperix.NetworkConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse;
  return proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_amperix_error_pb.Error;
      reader.readMessage(value,ext_amperix_error_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.amperix.NetworkConfig;
      reader.readMessage(value,proto.de.mypowergrid.amperix.NetworkConfig.deserializeBinaryFromReader);
      msg.setUplinkConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_amperix_error_pb.Error.serializeBinaryToWriter
    );
  }
  f = message.getUplinkConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.amperix.NetworkConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.de.mypowergrid.amperix.Error}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.getError = function() {
  return /** @type{?proto.de.mypowergrid.amperix.Error} */ (
    jspb.Message.getWrapperField(this, ext_amperix_error_pb.Error, 1));
};


/**
 * @param {?proto.de.mypowergrid.amperix.Error|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NetworkConfig uplink_config = 2;
 * @return {?proto.de.mypowergrid.amperix.NetworkConfig}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.getUplinkConfig = function() {
  return /** @type{?proto.de.mypowergrid.amperix.NetworkConfig} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.amperix.NetworkConfig, 2));
};


/**
 * @param {?proto.de.mypowergrid.amperix.NetworkConfig|undefined} value
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} returns this
*/
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.setUplinkConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse} returns this
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.clearUplinkConfig = function() {
  return this.setUplinkConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.amperix.GetUplinkNetworkConfigResponse.prototype.hasUplinkConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.de.mypowergrid.amperix);
