<template>
  <CCard class="col-12 card-columns px-0">
    <ApxCardHeader icon="cil-timer" :title="$t(`${tTouPath}.pageTitle`)"> </ApxCardHeader>
    <!-- BEGIN: If API calls are in progess, show Loading Indicator -->
    <vue-loading
      v-if="isLoading"
      class="my-5"
      type="spiningDubbles"
      :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }"
    />
    <!-- END:   If API calls are in progess, show Loading Indicator -->
    <!-- BEGIN: If data is fetched from server, show main Time of Use view  -->
    <div v-else>
      <!-- BEGIN: If no timeseries profiles exist: Show Initial Upload Form -->
      <CCard v-if="timeseriesesEmpty" class="shadow-none p-1 px-3 flat-card mt-2 col-xl-6">
        <CCardBody class="mt-0 col-12">
          <TouTimeseriesNewForm :show-info-description="showInfoDescription" @afterTimeseriesCreate="onTsNameSelect" />
        </CCardBody>
      </CCard>
      <!-- END: If no timeseries profiles exist: Show Initial Upload Form-->
      <!-- BEGIN: If profiles exist: Show All Time of Use Settings-->
      <div v-else>
        <CCardGroup class="mt-4 px-3">
          <!-- BEGIN: Time Profiles Overview -->
          <CCard class="shadow-none p-1 flat-card mb-0">
            <CCardHeader class="border-bottom-0 pl-0 text-muted">
              <CCardTitle tag="h5" class="mb-0 font-md">
                <span>
                  {{ $t(`${tTouPath}.timeProfiles.title`) }}
                </span>
                <span class="float-right mr-1 d-inline d-sm-none">
                  <HelpBtn @toggleHelpText="showInfoDescription = !showInfoDescription" />
                </span>
              </CCardTitle>
            </CCardHeader>
            <!-- ---- BEGIN: Timeseries Profile Selection  ---- -->
            <CCardBody class="mt-0 col-12">
              <TouTimeseriesProfiles
                :show-info-description="showInfoDescription"
                :active-ts-name="activeTsName"
                @onSelect="onTsNameSelect"
                @onRemove="onTsAfterRemove"
              />
              <!-- ---- END: Timeseries Profile Selection  ---- -->
              <!-- prettier-ignore -->
              <hr class="my-3">
              <!-- BEGIN: Upload Form -->
              <TouTimeseriesNewForm
                :show-info-description="showInfoDescription"
                @afterTimeseriesCreate="onTsNameSelect"
              />
              <!-- prettier-ignore -->
              <hr class="my-3">
              <!-- BEGIN: Action Buttons / Save Dialog -->
              <CCardText>
                <h6 class="text-muted text-break text-left text-primary">
                  {{ $t(`${tTouPath}.saveProfileTitle`) }}
                </h6>
              </CCardText>
              <div class="mt-0 mb-1 col-12 p-2 pr-3">
                <div class="pl-0 text-muted small pb-2 d-none d-sm-block">
                  {{ $t(`${tTouPath}.saveProfileDescription`) }}
                </div>
                <div v-if="showInfoDescription" class="pl-0 text-muted small pb-2 d-block d-sm-none">
                  {{ $t(`${tTouPath}.saveProfileDescription`) }}
                </div>
                <div class="d-flex justify-content-between mt-2 mb-0">
                  <CButton size="sm" color="primary" @click="updateTimeseries">
                    <CIcon size="sm" name="cil-save" />
                    <span class="align-middle d-none d-sm-inline">
                      {{ $t('main.saveBtn') }}
                    </span>
                  </CButton>
                  <CButton size="sm" color="danger" @click="initStore">
                    <CIcon size="sm" name="cil-x-Circle" />
                    <span class="align-middle d-none d-sm-inline">
                      {{ $t('main.resetBtn') }}
                    </span>
                  </CButton>
                </div>
                <!-- prettier-ignore -->
                <hr class="my-3">
                <div class="d-flex justify-content-start mt-2 mb-0">
                  <CButton size="sm" block color="primary" @click="updateAllTimeserieses">
                    {{ $t('main.saveAllBtn') }}
                  </CButton>
                </div>
                <div v-if="linkToEmsEsca" class="d-flex justify-content-start mt-2 mb-0">
                  <CButton size="sm" block color="primary" :to="linkToEmsEsca">
                    {{ $t(`${tTouPath}.linkToEnergyServiceActivation`) }}
                  </CButton>
                </div>
              </div>
              <!-- END:   Action Buttons / Save Dialog -->
              <!-- END: Upload Form -->
            </CCardBody>
          </CCard>
          <!-- END:  Time Profiles Overview  -->
          <!-- BEGIN: Edit Timeseries Config  -->
          <CCard class="shadow-none p-1 flat-card mb-0">
            <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
              <CCardTitle tag="h5" class="mb-0 font-md">
                <span>
                  {{ $t(`${tTouPath}.timeProfileConfiguration.title`) }}
                </span>
              </CCardTitle>
            </CCardHeader>
            <CCardBody class="mt-0 col-12">
              <TouTimeseriesConfigForm
                v-if="activeTsName"
                class="p-0"
                :ts-name="activeTsName"
                @onUtcOffsetUpdate="onUtcOffsetUpdate"
              />
            </CCardBody>
          </CCard>
        </CCardGroup>
        <!-- END: Edit Timeseries Config -->
        <!-- BEGIN: View Timeseries Profile Details  -->
        <CCard class="shadow-none p-1 px-3 flat-card mb-0">
          <CCardHeader class="align-items-center d-flex justify-content-between border-bottom-0 pl-0 text-muted">
            <CCardTitle class="mb-0 font-md">
              <h5>
                {{ $t(`${tTouPath}.timeProfileTable.titleOverview`) }}
              </h5>
            </CCardTitle>
          </CCardHeader>
          <CCardBody class="mt-0 pb-0 col-12">
            <TouTimeseriesTable v-if="activeTsName" :ts-name="activeTsName" :utc-offset="utcOffset" />
          </CCardBody>
        </CCard>
        <!-- END: View Timeseries Profile Details -->
      </div>
      <!-- END: If profiles exist: Show All Time of Use Settings-->
    </div>
    <!-- END:   If data is fetched from server, show main Time of Use view -->
  </CCard>
</template>

<script>
import ApxCardHeader from '@/components/snippets/apx-card-header'
import HelpBtn from '@/components/snippets/help-btn'
import TouTimeseriesConfigForm from '@/components/ems/timeseries/tou-timeseries-config-form'
import TouTimeseriesNewForm from '@/components/ems/timeseries/tou-timeseries-new-form'
import TouTimeseriesProfiles from '@/components/ems/timeseries/tou-timeseries-profiles'
import TouTimeseriesTable from '@/components/ems/timeseries/tou-timeseries-table'

import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { newAlertNotificationMsg } from '@/store/modules/notifications'

import { mapGetters } from 'vuex'

export default {
  name: 'TouConfig',
  components: {
    ApxCardHeader,
    HelpBtn,
    TouTimeseriesNewForm,
    TouTimeseriesConfigForm,
    TouTimeseriesTable,
    TouTimeseriesProfiles
  },
  data() {
    return {
      activeTsName: '',
      showInfoDescription: false,
      utcOffset: 0
    }
  },
  computed: {
    invalidFeedback() {
      if (!this.$v.addProfileForm.newTsName.required) {
        return this.$t(`${this.tTouPath}.timeProfiles.addProfileForm.newTsName.validation.invalidFeedback.required`)
      }
      return ''
    },
    ...mapGetters('emsTimeseries', ['timeseriesNames', 'timeseriesTouched', 'autoDetermineType']),
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    }),
    timeseriesesEmpty() {
      return this.timeseriesNames.length === 0
    }
  },
  created() {
    this.initStore()
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
    this.linkToEmsEsca = null
    if (this.$route.query['esc-cache'] || this.$route.query['ee-cache']) {
      this.linkToEmsEsca = {
        name: 'ems-energy-services-config-activation',
        query: {
          'esc-cache': this.$route.query['esc-cache'],
          'ee-cache': this.$route.query['ee-cache']
        }
      }
    }
  },
  methods: {
    initStore() {
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: true,
          clear: true
        })
        .then(() => {
          // by default, select first timeseries
          this.activeTsName = this.timeseriesNames[0] || ''
          this.$log.debug('Succesfully fetched timeseries names.')
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onServerError(err)
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onFailure() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'danger',
          content: this.$t(`${this.tTouPath}.failure`),
          show: true
        })
      )
    },
    onServerError(err, opts = {}) {
      this.$log.error(err)
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err, opts))
    },
    onSuccess() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'success',
          content: this.$t(`${this.tTouPath}.success`),
          show: 8
        })
      )
    },
    onTsAfterRemove() {
      this.activeTsName = this.timeseriesNames[0] || ''
    },
    onTsNameSelect({ name }) {
      this.activeTsName = name || ''
      const type = this.autoDetermineType(this.activeTsName)

      this.$store.commit('emsTimeseries/SET_TYPE', {
        tsName: this.activeTsName,
        type
      })
    },
    onUtcOffsetUpdate(utcOffset) {
      this.utcOffset = utcOffset
    },
    updateAllTimeserieses() {
      this.$store.commit('apiLoadingStatus/STARTED')
      const calls = []
      this.timeseriesNames.forEach((n, i) => {
        if (!this.timeseriesTouched[i]) {
          return
        }

        calls.push(this.$store.dispatch('emsTimeseries/timeseriesUpdate', { tsName: n }))
      })

      Promise.all(calls)
        .then(() => {
          this.$log.debug('Successfully updated all timeseries.')
          this.onSuccess()
        })
        .catch((err) => {
          this.$log.error(err)
          this.onFailure()
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
          this.initStore()
        })
    },
    async updateTimeseries() {
      try {
        this.$store.commit('apiLoadingStatus/STARTED')

        await this.$store.dispatch('emsTimeseries/timeseriesUpdate', {
          tsName: this.activeTsName
        })
        this.$log.debug(`Succesfully updated timeseries '${this.activeTsName}'.`)
        this.onSuccess()
      } catch (err) {
        this.$log.error(err)
        this.onFailure()
      } finally {
        this.$store.commit('apiLoadingStatus/STOPPED')
      }
    }
  }
}
</script>
