<template>
  <div>
    <div v-if="entry.isNode && entry.node.optionsList">
      <div v-for="(item, key) in entry.node.optionsList.slice().reverse()" :key="key">
        <div v-if="item.filterLast">{{ $t(`${tBasePath}.option`) }}: {{ $t(`${tBasePath}.filterLast`) }}</div>
        <div v-if="item.timeHysteresis">
          {{ $t(`${tBasePath}.option`) }}: {{ $t(`${tBasePath}.timeHysteresis`) }}
          {{ item.timeHysteresis.cooldownSeconds }}
        </div>
        <div v-if="item.filterDefault">
          {{ $t(`${tBasePath}.option`) }}: {{ $t(`${tBasePath}.filterDefault`) }}
          {{ item.filterDefault.defaultStrategy }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EsNodeOptionPreview',
  components: {},
  props: {
    entry: {
      required: true,
      type: Object || null
    }
  },
  data() {
    return {
      tBasePath: 'ems.energyService.config.decisionTree.form.options'
    }
  }
}
</script>
