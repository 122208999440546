<template>
  <div class="mt-1 mb-3">
    <h4 class="text-center">
      {{ $t('ems.topology.topologyAssignment') }}
    </h4>
    <p class="mt-1 mb-3 text-break text-center">
      {{ $t('ems.topology.topologyAssignmentDescription') }}
    </p>
    <CListGroup v-for="(ldn, index) of logicalDeviceNames" :key="index">
      <EmsEditTopoConfigLd :ldn="ldn" />
    </CListGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmsEditTopoConfigLd from '@/components/ems/topology/ems-edit-topo-config-ld'

export default {
  name: 'EmsEditTopoConfig',
  components: {
    EmsEditTopoConfigLd
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['logicalDeviceNames'])
  }
}
</script>
