<template>
  <CRow align-vertical="start">
    <CCol xs="12" lg="10" xl="9">
      <CCard>
        <ApxCardHeader icon="cil-ethernet" :title="$t('config.networkConfig.title')" />
        <CCardBody>
          <CForm @submit.prevent>
            <!-- BEGIN CRow - Automatic/Static -->
            <CRow form class="form-group">
              <CCol sm="3" class="custom-form-label">
                {{ $t('config.networkConfig.form.autoConfig.label') }}
              </CCol>
              <CInputRadioGroup
                class="col-sm-9"
                :options="[
                  {
                    value: 'dynamic',
                    label: $t('config.networkConfig.form.autoConfig.radioAuto.label')
                  },
                  {
                    value: 'static',
                    label: $t('config.networkConfig.form.autoConfig.radioManually.label')
                  }
                ]"
                :checked="isStatic ? 'static' : 'dynamic'"
                :custom="true"
                :inline="false"
                :invalid-feedback="$t('config.networkConfig.form.autoConfig.validation.invalidFeedback')"
                :label="$t('config.networkConfig.form.autoConfig.label')"
                @update:checked="onStaticAddrChange"
              />
              <CCol sm="3"></CCol>
              <CCol class="col-sm-9">
                <small class="form-text text-muted w-75">
                  {{ $t('config.networkConfig.form.autoConfig.description') }}
                </small>
              </CCol>
            </CRow>
            <!-- END CRow - Automatic/Static -->
            <!-- BEGIN CInput - IP -->
            <CInput
              :value.sync="$v.config.ip.$model"
              class="mt-4 custom-form-label"
              :disabled="!isStatic"
              :lazy="true"
              :is-valid="showValidation.ip ? !$v.config.ip.$invalid : null"
              :invalid-feedback="$t('config.networkConfig.form.ip.validation.invalidFeedback')"
              type="text"
              :description="$v.config.ip.$invalid ? $t('config.networkConfig.form.ip.description') : ''"
              :label="$t('config.networkConfig.form.ip.label')"
              horizontal
              placeholder="192.168.178.2"
              @change="setShowValidation('ip')"
            />
            <!-- END CInput - IP -->
            <!-- BEGIN CInput - netmask -->
            <CInput
              :value.sync="$v.config.netmask.$model"
              class="custom-form-label"
              :disabled="!isStatic"
              :lazy="true"
              :is-valid="showValidation.netmask ? !$v.config.netmask.$invalid : null"
              :invalid-feedback="$t('config.networkConfig.form.subnet.validation.invalidFeedback')"
              type="text"
              :description="$v.config.netmask.$invalid ? $t('config.networkConfig.form.subnet.description') : ''"
              :label="$t('config.networkConfig.form.subnet.label')"
              horizontal
              placeholder="255.255.255.0"
              @change="setShowValidation('netmask')"
            />
            <!-- END CInput - netmask -->
            <!-- BEGIN CInput - gateway -->
            <CInput
              :value.sync="$v.config.gateway.$model"
              class="custom-form-label"
              :disabled="!isStatic"
              :lazy="true"
              :is-valid="showValidation.gateway ? !$v.config.gateway.$invalid : null"
              :invalid-feedback="$t('config.networkConfig.form.gateway.validation.invalidFeedback')"
              type="text"
              :description="$v.config.gateway.$invalid ? $t('config.networkConfig.form.gateway.description') : ''"
              :label="$t('config.networkConfig.form.gateway.label')"
              horizontal
              placeholder="192.168.178.1"
              @change="setShowValidation('gateway')"
            />
            <!-- END CInput - gateway -->
            <!-- BEGIN CRow - dns settings -->
            <CRow form class="form-group">
              <CCol sm="3" class="col-form-label custom-form-label">
                {{ $t('config.networkConfig.form.dns.title') }}
              </CCol>
              <CCol sm="9 pt-2">
                <CInputCheckbox
                  class="col-sm-9"
                  :label="$t('config.networkConfig.form.dns.field.dnsFromDncpCheckBox.label')"
                  :invalid-feedback="
                    $t('config.networkConfig.form.dns.field.dnsFromDncpCheckBox.validation.invalidFeedback')
                  "
                  :checked="isDnsDhcp"
                  :disabled="isStatic"
                  :custom="true"
                  :inline="false"
                  @update:checked="onDhcpDnsChange"
                />
              </CCol>
              <CCol sm="3"></CCol>
              <CCol class="col-sm-9">
                <small class="form-text text-muted w-50 mt-0">
                  {{ $t('config.networkConfig.form.dns.field.dnsFromDncpCheckBox.description') }}
                </small>
              </CCol>
            </CRow>
            <!-- END CRow - dns settings -->
            <!-- BEGIN CInput - primary dns -->
            <CInput
              :value.sync="$v.config.primaryDns.$model"
              class="custom-form-label"
              :disabled="isDnsDhcp"
              type="text"
              :lazy="true"
              :is-valid="showValidation.primaryDns ? !$v.config.primaryDns.$invalid : null"
              :invalid-feedback="$t('config.networkConfig.form.dns.field.primaryDns.validation.invalidFeedback')"
              :description="
                $v.config.primaryDns.$invalid ? $t('config.networkConfig.form.dns.field.primaryDns.description') : ''
              "
              :label="$t('config.networkConfig.form.dns.field.primaryDns.label')"
              horizontal
              placeholder="8.8.8.8"
              @change="setShowValidation('primaryDns')"
            />
            <!-- END CInput - primary dns -->
            <!-- BEGIN CInput - secondary dns -->
            <CInput
              :value.sync="$v.config.secondaryDns.$model"
              class="mb-5 custom-form-label"
              :disabled="isDnsDhcp"
              :lazy="true"
              :is-valid="showValidation.secondaryDns ? !$v.config.secondaryDns.$invalid : null"
              :invalid-feedback="$t('config.networkConfig.form.dns.field.secondaryDns.validation.invalidFeedback')"
              type="text"
              :description="
                $v.config.secondaryDns.$invalid
                  ? $t('config.networkConfig.form.dns.field.secondaryDns.description')
                  : ''
              "
              :label="$t('config.networkConfig.form.dns.field.secondaryDns.label')"
              horizontal
              placeholder="8.8.4.4"
              @change="setShowValidation('secondaryDns')"
            />
            <!-- END CInput - secondary dns -->
          </CForm>
        </CCardBody>
        <!-- BEGIN CFooter -->
        <!-- prettier-ignore -->
        <div class="border-top mt-3 p-3">
          <CButton
            type="submit"
            size="sm"
            color="primary"
            @mousedown="beforeActionStart"
            @click="submit(false)"
          >
            <CIcon size="sm" name="cil-save" />
            <span class="align-middle d-none d-sm-inline">
              {{ $t('main.saveBtn') }}
            </span>
          </CButton>
          <CButton
            class="ml-5"
            type="reset"
            size="sm"
            color="danger"
            @mousedown="beforeActionStart"
            @click="resetForm"
          >
            <CIcon size="sm" name="cil-x-circle" />
            <span class="align-middle d-none d-sm-inline">
              {{ $t('main.resetBtn') }}
            </span>
          </CButton>
        </div>
        <!-- END CFooter -->
      </CCard>
    </CCol>
    <!-- prettier-ignore -->
    <CModal
      :backdrop="true"
      :centered="true"
      :close-on-backdrop="false"
      color="info"
      size="lg"
      :show.sync="showModal"
    >
      <template #header>
        <span v-if="config.static" class="modal-title">
          {{ $t('config.networkConfig.form.infoIpChangeModalTitle') }}
        </span>
        <span v-else class="modal-title">
          {{ $t('config.networkConfig.form.infoDhcpChangeModalTitle') }}
        </span>
        <CButtonClose class="text-white" @click="showModal = false" />
      </template>
      <div v-if="isReloading">
        <vue-loading type="spiningDubbles" :style="{ fill: 'var(--primary)', width: '100px', height: '100px' }" />
      </div>
      <div v-else>
        {{ $t('config.networkConfig.form.infoModalContent') }}
      </div>
      <template #footer>
        <CButton color="secondary" :disabled="isReloading" @click="showModal = false">
          {{ $t('main.cancelBtn') }}
        </CButton>
        <CButton color="danger" :disabled="isReloading" @click="submit(true)">
          {{ $t('main.saveBtn') }}
        </CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { uplinkNetworkConfigTemplate } from '@/store/modules/network-config'
import uplinkNetworkConfigValidations from '@/validations/uplink-network-config'
import { newAlertNotificationMsg } from '@/store/modules/notifications'

import ApxCardHeader from '@/components/snippets/apx-card-header'

export default {
  name: 'NetworkConfig',
  components: {
    ApxCardHeader
  },
  data() {
    return {
      oldIp: '',
      showModal: false,
      isReloading: false,
      config: uplinkNetworkConfigTemplate(),
      showValidation: {
        ip: false,
        netmask: false,
        gateway: false,
        primaryDns: false,
        secondaryDns: false,
        startingAction: false // action = submitting or resetting
      }
    }
  },
  computed: {
    isStatic: function () {
      return this.config.static
    },
    isDnsDhcp: function () {
      if (this.config.static) {
        return false
      }
      return !this.config.autoAddr
    }
  },
  validations: uplinkNetworkConfigValidations,
  beforeCreate() {
    // define some static props
    this.remoteOrigConfig = {}
    this.horizontal = { label: 'col-3', input: 'col-9' }
  },
  created() {
    this.hostName = process.env.VUE_APP_HOSTNAME
  },
  mounted() {
    this.fetchNetworkConfigForm()
  },
  methods: {
    setConfig(msg) {
      for (const f in msg) {
        this.config[f] = msg[f]
        this.remoteOrigConfig[f] = msg[f]
      }

      this.isLastDnsDhcp = this.isDnsDhcp
      this.config.auto = !this.config.static && !this.config.autoAddr // make sure, that initial view is consistent with config-state (requires that one oneof is set)
    },
    setShowValidation(field, enable = true) {
      if (this.showValidation.startingAction && field !== 'action') {
        // blocking DOM changes during submitting/resetting
        // otherwise the button will be moved and mouse-down/-up will not happen on the same html-tag
        return
      }

      switch (field) {
        case 'action':
          this.showValidation.startingAction = enable
          break
        case 'all':
          this.showValidation.ip = enable
          this.showValidation.netmask = enable
          this.showValidation.gateway = enable
          this.showValidation.primaryDns = enable
          this.showValidation.secondaryDns = enable
          break
        case 'addr':
          this.showValidation.ip = enable
          this.showValidation.netmask = enable
          this.showValidation.gateway = enable
          break
        case 'dns':
          this.showValidation.primaryDns = enable
          this.showValidation.secondaryDns = enable
          break
        default:
          this.showValidation[field] = enable
      }
    },
    onServerError(err) {
      this.$log.warn(err)
      const errMessage = {
        title: 'Server Error',
        type: 'toast-warning',
        content: this.$t('api.errors.networkConfigUpdate', [err.code])
      }
      this.$store.commit('notifications/PUSH_TOAST', errMessage, {
        root: true
      })
    },
    onStaticAddrChange(val) {
      if (val === 'static') {
        this.isLastDnsDhcp = this.isDnsDhcp
        this.config.static = true
        this.config.auto = false
        this.config.autoAddr = false
      } else if (val === 'dynamic') {
        this.config.static = false
        this.config.auto = this.isLastDnsDhcp
        this.config.autoAddr = !this.isLastDnsDhcp
      }
      // disable validation to give the user a chance to type first
      this.setShowValidation('addr', false)
      if (this.config.auto) {
        this.setShowValidation('dns', false)
      }
    },
    onDhcpDnsChange(dhcp) {
      this.isLastDnsDhcp = this.isDnsDhcp
      if (dhcp) {
        this.config.static = false
        this.config.auto = true
        this.config.autoAddr = false
      } else {
        this.config.static = this.isStatic
        this.config.auto = false
        this.config.autoAddr = !this.isStatic
      }

      // disable validation to give the user a chance to type first
      this.setShowValidation('dns', false)
    },
    beforeActionStart(e) {
      // make sure to setShowValidation('action', false) later
      this.setShowValidation('action', true)
    },
    fetchNetworkConfigForm() {
      this.$store
        .dispatch('networkConfig/fetchUplinkNetworkConfig')
        .then((msg) => {
          this.oldIp = msg.ip
          this.setConfig(msg)
        })
        .catch(this.onServerError)
    },
    resetForm(fetchFromServer = true) {
      this.$store.commit('notifications/DELETE_ALERT')
      if (fetchFromServer === false) {
        this.$log.debug('Soft form reset to state of initital server request')
        this.config = this.remoteOrigConfig
      } else {
        this.$log.debug('Hard form reset, triggering a new server request')
        this.fetchNetworkConfigForm()
      }
      this.setShowValidation('action', false)
      this.setShowValidation('all', false)
    },
    submit(confirmed = false) {
      // validation
      this.setShowValidation('action', false)
      if (this.config.static) {
        this.setShowValidation('all', true)
      } else if (this.config.autoAddr) {
        this.setShowValidation('dns', true)
      }
      this.$v.config.$touch()
      if (this.$v.config.$anyError) {
        this.$store.commit(
          'notifications/NEW_ALERT',
          newAlertNotificationMsg({
            type: 'danger',
            content: this.$t('config.networkConfig.form.formError')
          })
        )
        return
      }

      // update of static IP or update from static -> DHCP
      const needUrlUpdate =
        (this.config.static && this.config.ip !== this.remoteOrigConfig.ip) ||
        ((this.config.auto || this.config.autoAddr) && this.remoteOrigConfig.static)
      confirmed = confirmed || !needUrlUpdate
      if (!confirmed) {
        this.showModal = true
        return
      }

      this.$store
        .dispatch('networkConfig/upsertUplinkNetworkConfig', this.config)
        .then((msg) => {
          this.$log.debug('Successfully upserted the uplink network config.')
          this.setConfig(msg)
          this.setShowValidation('all', false)
          this.$store.commit('notifications/DELETE_ALERT')
          this.$store.commit(
            'notifications/NEW_ALERT',
            newAlertNotificationMsg({
              type: 'success',
              content: this.$t('config.networkConfig.form.formSuccess'),
              show: 8
            })
          )
          if (needUrlUpdate) {
            this.isReloading = true
            // TODO: probe new location.origin by XMLHttpRequest
            setTimeout(() => {
              if (this.config.auto || this.config.autoAddr) {
                window.location.hostname = this.hostName
              } else if (this.config.static) {
                window.location.hostname = this.config.ip
              }
              // actually, the reload will remove it anyway
              // this.isReloading = false
            }, 12000)
          }
        })
        .catch(this.onServerError)
    }
  }
}
</script>
