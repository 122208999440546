<template>
  <div>
    <CCardText>
      <h6 class="text-muted text-break text-left">
        {{ $t(`${tTouPath}.timeProfiles.selectionTitle`) }}
      </h6>
    </CCardText>
    <div class="mt-0 mb-1 col-12 p-2 pr-3">
      <!-- BEGIN:  Timeseries List with Buttons -->
      <CListGroup class="mb-2">
        <CListGroupItem
          v-for="(name, i) in timeseriesNamesSanitized"
          :key="i"
          class="d-flex justify-content-between align-items-center"
          tag="button"
          :active="activeTsName === name"
          @click="onSelectTs(name)"
        >
          {{ name }}
          <CBadge class="d-none d-md-block p-1" :color="timeseriesTouched[i] ? 'warning' : 'success'" shape="pill">
            {{
              timeseriesTouched[i]
                ? $t(`${tTouPath}.timeProfiles.state.unsaved`)
                : $t(`${tTouPath}.timeProfiles.state.saved`)
            }}
          </CBadge>
          <i class="d-block d-md-none">
            <CIcon v-if="timeseriesTouched[i]" class="text-warning" name="cil-warning" :height="20" />
            <CIcon v-else class="text-success" name="cil-check" :height="20" />
          </i>
        </CListGroupItem>
      </CListGroup>
      <small class="form-text text-muted d-none d-sm-block">
        {{ $t(`${tTouPath}.timeProfileListDescription`) }}
      </small>
      <small v-if="showInfoDescription" class="form-text text-muted d-block d-sm-none">
        {{ $t(`${tTouPath}.timeProfileListDescription`) }}
      </small>
      <div class="mt-2 d-flex justify-content-between">
        <CButton class="mr-2" size="sm" color="primary" @click="onCsvExport">
          <CIcon size="sm" name="cil-cloud-download" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('main.exportBtn') }}
          </span>
        </CButton>
        <CButton class="ml-auto" size="sm" color="danger" @click="showConfirmDeleteModal = true">
          <CIcon size="sm" name="cil-trash" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('main.removeBtn') }}
          </span>
        </CButton>
        <!-- END:  Timeseries List with Buttons-->
      </div>
      <!-- BEGIN: ConfirmationModal -->
      <ConfirmationModal
        :visible.sync="showConfirmDeleteModal"
        color="danger"
        size="sm"
        :title="$t(`${tTouPath}.timeProfiles.confirmationModal.deleteTsProfile.title`)"
        @update:confirmation="removeSelectedTimeseries({ confirmed: $event })"
      >
        {{ $t(`${tTouPath}.timeProfiles.confirmationModal.deleteTsProfile.content`) }}
      </ConfirmationModal>
      <!-- END: ConfirmationModal -->
    </div>
  </div>
</template>

<script>
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import { newAlertNotificationMsg } from '@/store/modules/notifications'
import { mapGetters } from 'vuex'

export default {
  name: 'TouTimeseriesProfiles',
  components: {
    ConfirmationModal
  },
  props: {
    showInfoDescription: {
      type: Boolean,
      required: true
    },
    activeTsName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showConfirmDeleteModal: false
    }
  },
  computed: {
    ...mapGetters('emsTimeseries', ['getTimestampValues', 'timeseriesNames', 'timeseriesTouched']),
    timeseriesNamesSanitized() {
      return this.timeseriesNames.filter((name) => {
        return name !== 'reset_grid_peak'
      })
    }
  },
  created() {
    this.tTouPath = 'ems.energyService.config.timeOfUseSetting'
  },
  methods: {
    onCsvExport() {
      const data = this.getTimestampValues(this.activeTsName)

      const csvFile = this.$papa.unparse(data, {
        delimiter: ','
      })

      this.$papa.download(csvFile, this.activeTsName)
      this.$log.debug('A csv file has been succesfully downloaded: ', csvFile)
    },
    onFailure() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'danger',
          content: this.$t(`${this.tTouPath}.failure`),
          show: true
        })
      )
    },
    onSelectTs(name) {
      this.$emit('onSelect', { name })
    },
    onSuccess() {
      this.$store.commit(
        'notifications/NEW_ALERT',
        newAlertNotificationMsg({
          type: 'success',
          content: this.$t(`${this.tTouPath}.success`),
          show: 4
        })
      )
    },
    removeSelectedTimeseries({ confirmed }) {
      if (!confirmed) {
        return
      }
      this.showConfirmDeleteModal = false
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('emsTimeseries/timeseriesRemove', this.activeTsName)
        .then(() => {
          this.$log.debug('Succesfully removed timeseries.')
          this.$emit('onRemove')
          this.onSuccess()
        })
        .catch((err) => {
          this.$log.warn(err)
          this.onFailure()
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    }
  }
}
</script>

<style scoped>
.list-group {
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
