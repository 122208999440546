<template>
  <EsLayoutForm :show-failure-alert.sync="showFailureAlert" @cancel="$emit('cancel')" @reset="init" @submit="onSubmit">
    <CForm @submit.prevent>
      <div class="row">
        <div class="text-muted col mb-4">{{ descriptionText }}</div>
        <CSwitch color="primary" size="sm" :checked.sync="expertMode" @update:checked="onExpertModeChanged" />
        <span class="ml-2">
          {{ $t('ems.energyService.config.evalTree.expertMode') }}
        </span>
      </div>
      <CSelect
        add-label-classes="custom-form-label"
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.batteryLabel`)"
        :description="$t(`${tBasePath}.batteryDescription`)"
        :invalid-feedback="$t(`${tBasePath}.batteryInvalidFeedback`)"
        :options="batteryDeviceOpts"
        :value.sync="$v.physicalDeviceId.$model"
      />
      <CInput
        v-if="expertMode"
        id="soc-max"
        type="range"
        min="0"
        max="100"
        :lazy="false"
        add-input-classes="soc-slider-range"
        add-label-classes="custom-form-label"
        custom
        :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
        :label="$t(`${tBasePath}.socMaxLabel`)"
        :description="$t(`${tBasePath}.socMaxDescription`)"
        :invalid-feedback="$t(`${tBasePath}.socMaxOutOfRangeFeedback`)"
        :value.sync="$v.socMax.$model"
      >
        <template #append>
          <output class="soc-slider-value" for="soc-max"> {{ socMax }} % </output>
        </template>
      </CInput>
    </CForm>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { offGridTargetSocGenerationCurtailmentValidators } from '@/validations/ems-es-decision-tree-validators'
import EsLayoutForm from './es-layout-form'
import { formProps as props } from './form-props'
import { generatePhysicalDeviceSelectOpts } from '@/view-helper/ems/ems-topology-helper'
import { literalToPhysicalDevice, physicalDeviceToLiteral } from '@/store/modules/_ems-topology-config-helper'

const defaultParams = {
  socDeadzoneBelow: 5,
  socMax: 95
}

export default {
  name: 'EsOffGridTargetSocGenerationCurtailmentForm',
  components: {
    EsLayoutForm
  },
  props,
  data() {
    return {
      expertMode: false,
      physicalDeviceId: null,
      showFailureAlert: false,
      socMax: defaultParams.socMax
    }
  },
  computed: {
    ...mapGetters('emsTopologyConfig', ['getPhysicalDevices']),
    batteryDeviceOpts() {
      return generatePhysicalDeviceSelectOpts(this.getPhysicalDevices('BATTERY'))
    },
    descriptionText() {
      return this.expertMode
        ? this.$t(`${this.tBasePath}.serviceExpertDescription`)
        : this.$t(`${this.tBasePath}.serviceDescription`)
    }
  },
  created() {
    this.init()
    this.tBasePath = 'ems.energyService.config.form.offGridTargetSocGenerationCurtailment'
  },
  validations: offGridTargetSocGenerationCurtailmentValidators,
  methods: {
    init() {
      this.$v.physicalDeviceId.$model = this.initValues.battery
        ? physicalDeviceToLiteral(this.initValues.battery)
        : this.batteryDeviceOpts[0].value

      this.$v.socMax.$model = this.initValues.socMax || defaultParams.socMax
      this.expertMode = this.socMax !== defaultParams.socMax
    },
    onExpertModeChanged() {
      if (!this.expertMode) {
        this.$v.socMax.$model = defaultParams.socMax
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.showFailureAlert = true
        return
      }

      const esParams = {
        socMax: this.socMax,
        socDeadzoneBelow: defaultParams.socDeadzoneBelow,
        battery: literalToPhysicalDevice(this.physicalDeviceId)
      }

      this.$emit('submit', {
        esParams
      })
    }
  }
}
</script>
