// package: de.mypowergrid.ems.controller
// file: ext/ems/controller/controller.proto

var ext_ems_controller_controller_pb = require("../../../ext/ems/controller/controller_pb");
var ext_ems_acsi_acsi_pb = require("../../../ext/ems/acsi/acsi_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var RpcService = (function () {
  function RpcService() {}
  RpcService.serviceName = "de.mypowergrid.ems.controller.RpcService";
  return RpcService;
}());

RpcService.ControllerRpc = {
  methodName: "ControllerRpc",
  service: RpcService,
  requestStream: false,
  responseStream: false,
  requestType: ext_ems_controller_controller_pb.Request,
  responseType: ext_ems_controller_controller_pb.Response
};

RpcService.AcsiRpc = {
  methodName: "AcsiRpc",
  service: RpcService,
  requestStream: false,
  responseStream: false,
  requestType: ext_ems_acsi_acsi_pb.Request,
  responseType: ext_ems_acsi_acsi_pb.Response
};

exports.RpcService = RpcService;

function RpcServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

RpcServiceClient.prototype.controllerRpc = function controllerRpc(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RpcService.ControllerRpc, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

RpcServiceClient.prototype.acsiRpc = function acsiRpc(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(RpcService.AcsiRpc, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.RpcServiceClient = RpcServiceClient;

