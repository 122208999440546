/**
 * Actions for the [ems-topology-config store]{@link module:store/ems-topology-config}
 *
 * @module store/ems-topology-config-actions
 */

import { isEmpty, isEqual } from 'lodash'
import logger from '@/logger'
import {
  PhysicalDevice as PhysicalDevicePb,
  PhaseTopology as PhaseTopologyPb,
  SourceToDeviceTopology as SourceToDeviceTopologyPb
} from '@/../lib/proto_js/ext/ems/topology/topology_pb'
import * as apiTopology from '@/api/ems/topology'
import * as apiSController from '@/api/ems/scontroller'
import { iecIdToLiteral } from '@/store/modules/_ems-topology-config-helper'
import { finalizeTopologyChange as emsFinalizeTopologyChange } from '@/api/ems/controller'

/**
 * Sets the `state.gridMtrIecId` to the `iecId` and cleans up the list of physical devices in the phase-topology for this source.
 *
 * @function
 *
 * @param {object} iecId has to be the `ems.topology.Source.IecId` of a source. (plain object)
 *
 */
export function setGridMtr({ commit, getters, state }, iecId) {
  if (!getters.hasAnyMtrCounter(iecId)) {
    logger.warn('Will not set the grid mtr, because the source does not support _ANY_MTR_COUNTER quantity.')
    return
  }

  commit('SET_GRID_MTR_IECID', iecId)
  if (!isEqual(iecId, state.gridMtrIecId)) {
    logger.warn(
      'After SET_GRID_MTR_IECID: Setting failed. Will not cleanup physical device list of source with IEC ID: ',
      iecId
    )
    return
  }

  // cleanup list of physical devices
  // with source and empty iec
  const phaseTopo = new PhaseTopologyPb([[null, [], null]]).toObject()
  Object.assign(phaseTopo.source.iec, iecId)
  commit('ADD_PHASE_TOPOLOGY', phaseTopo)
}

/**
 * Action to add the grid mtr to the phase-topology from the `state.gridMtrIecId`. This is needed, since the EMS evaluate the phase-topology only, and does NOT know any grid-mtr IEC ID state.
 *
 * @function
 *
 */
export function addGridMtrToPhaseTopology({ commit, getters, state }) {
  const iecId = state.gridMtrIecId
  if (!iecId) {
    logger.warn('Cannot add grid mtr to phase topology, because no grid mtr IEC-ID was found in the state.')
    return
  }
  const src = getters.getSource(iecId)
  if (!src) {
    logger.warn('Cannot add grid mtr to phase topology, because no source for the IEC-ID ', iecId, 'was found.')
    return
  }
  const pds = state.existingPhysicalDevices // we might need to filter by 'electric' devices in the future

  const phaseTopo = new PhaseTopologyPb().toObject()
  phaseTopo.source = src
  pds.forEach((pd) => {
    phaseTopo.physicalDeviceOnPhaseList.push({
      physicalDevice: pd
    })
  })
  phaseTopo.swap = state.swapGridMtr

  commit('ADD_PHASE_TOPOLOGY', phaseTopo)
}

/**
 * Action which parses the EMS phase topology and extracts the grid mtr.
 * Then, sets the `state.gridMtrIecId`.
 *
 * @function
 *
 */
export function setGridMtrFromPhaseTopology({ commit, getters, state }) {
  commit('CLEAR_GRID_MTR_IECID') // SET_GRID_MTR_IECID might not be called

  const numOfAllPds = getters.allPhysicalDevicesOfTopology.length
  const numOfAllMtrs = state.topology.phaseTopologiesList.filter((pt) => {
    return pt && pt.source && pt.source.iec && getters.hasAnyMtrCounter(pt.source.iec)
  }).length

  // abort if no or only one mtr is present
  if (numOfAllMtrs < 2) {
    return
  }

  for (const pt of state.topology.phaseTopologiesList) {
    if (!pt.source) {
      logger.warn(
        'Found topology.PhaseTopology in the topology phase-topologies-list, which has no source. This is invalid. Skipping.'
      )
      return
    }
    const iecId = pt.source.iec
    // skip sources without _ANY_MTR_COUNTER
    if (!iecId || !getters.hasAnyMtrCounter(iecId)) {
      continue
    }

    // skip if no or only one phys device
    // in case only 1-to-1 relations exists, this is a non-grid topo
    const pds = getters.getPhysicalDevicesOfPhaseTopology(iecId)
    if (pds.length > 1 && pds.length === numOfAllPds) {
      // entries of pds and allPds have to be unique
      // pds is a sub-set of allPds
      // multiple grid mtrs are not supported, however, in case they are present, the first will win
      commit('SET_GRID_MTR_IECID', iecId)
      commit('SET_SWAP_MTR', { iecId, swap: pt.swap })

      // cleanup to avoid displaying a (redundent) list of physical devices
      const phaseTopo = new PhaseTopologyPb().toObject()
      phaseTopo.source = pt.source
      commit('ADD_PHASE_TOPOLOGY', phaseTopo)

      // in case multiple grid Meters exist
      break
    }
  }
}

/**
 * Actions to set the existing physical devices from the EMS topology.
 *
 * @function
 *
 */
export function setExistingPhysicalDevicesFromTopology({ commit, getters }) {
  commit('CLEAR_PHYSICAL_DEVICES')

  getters.allPhysicalDevicesOfTopology.forEach((pd) => {
    commit('ADD_PHYSICAL_DEVICE', Object.assign({}, pd)) // (shallow) clone, to make sure that getter return is NOT modified ever
  })
}

/**
 * Action to update one entry of the `ems.topology.Topology.source_to_device_topologies`.
 *
 * Iff a physical device is provided as input,
 * will create a `ems.topology.SourceToDeviceTopology` and add/update the `source_to_device_topologies` list. (Checks the sources quantities to ensure if should be added.)
 *
 * Iff no physical device is provided as input,
 * will remove the `ems.topology.SourceToDeviceTopology` from the `source_to_device_topologies` list.
 *
 * @function
 *
 * @param {object} params
 * @param {object} params.iecId has to be the `ems.topology.Source.IecId` which uniquely identifies a `ems.topology.Source`
 * @param {string} params.physDevLiteral has to be the literal of a `ems.topology.PhysicalDevice` (of form `type.number`) or empty

 *
 */
export function updateSrcToDevTopo({ getters, commit }, { iecId, physDevLiteral }) {
  if (physDevLiteral && !getters.hasQuantity({ iecId, quantity: '_ANY_SRC_DEVICE_TOPO' })) {
    const iecLiteral = iecId ? iecIdToLiteral(iecId) : iecId
    logger.info(`Will not add source ${iecLiteral} to SourceToDeviceTopology, because it has no appropriate quantity.`)
    return
  }

  const topo = new SourceToDeviceTopologyPb().toObject()
  topo.source = getters.getSource(iecId)
  if (physDevLiteral) {
    topo.physicalDevice = new PhysicalDevicePb(physDevLiteral.split('.').map((e) => parseInt(e))).toObject()
  }

  commit('ADD_SOURCE_TO_DEVICE_TOPOLOGY', topo)
}

/**
 * Action to update one entry of the `ems.topology.Topology.phase_topologies`.
 *
 * See [updateSrcToDevTopo]{@link module:store/ems-topology-config-actions.updateSrcToDevTopo}
 *
 * @function
 *
 * @param {object} params
 * @param {object} params.iecId has to be the `ems.topology.Source.IecId` which uniquely identifies a `ems.topology.Source`
 * @param {array<string>} params.physDevLiterals has to be a list of literals of `ems.topology.PhysicalDevice` (of form `['type.number', ...]`) or empty
 *
 */
export function updatePhaseTopo({ getters, commit }, { iecId, physDevLiterals }) {
  if (!isEmpty(physDevLiterals) && !getters.hasQuantity({ iecId, quantity: '_ANY_PHASE_TOPO' })) {
    const iecLiteral = iecId ? iecIdToLiteral(iecId) : iecId
    logger.info(`Will not add source ${iecLiteral} to PhaseTopology, because it has no appropriate quantity.`)
    return
  }

  const topo = new PhaseTopologyPb().toObject()
  topo.source = getters.getSource(iecId)
  physDevLiterals.forEach((pdl) => {
    if (!pdl) {
      return
    }
    const pd = new PhysicalDevicePb(pdl.split('.').map((e) => parseInt(e))).toObject()
    topo.physicalDeviceOnPhaseList.push({
      physicalDevice: pd
    })
  })

  commit('ADD_PHASE_TOPOLOGY', topo)
}

/**
 * Action to cleanup (delete) the source to device and phase topology of a logical device.
 *
 * @function
 *
 * @param {string} ldn has to be the logical device name
 *
 */
export function cleanupTopo({ getters, commit, dispatch }, ldn) {
  getters.getSourceQuantities(ldn).forEach((sqs) => {
    const iecId = sqs.source.iec

    if (getters.isGridMtr(iecId)) {
      commit('CLEAR_GRID_MTR_IECID')
    }
    dispatch('updateSrcToDevTopo', { iecId })
    dispatch('updatePhaseTopo', { iecId, physDevLiterals: [] })
  })
}

/**
 * Action (API call) to get the EMS sourec quantities list.
 *
 * @function
 *
 * @return {promise}
 */
export async function getSourceQuantities({ commit }) {
  const doCommit = (msg) => {
    commit(
      'SET_SOURCE_QUANTITIES',
      msg.getSourceQuantitiesCollectionList().map((sqs) => sqs.toObject())
    )

    return msg
  }

  return apiTopology.getQuantities().then(doCommit)
}

/**
 * Action (API call) to get the EMS topo.
 * Then, to set the EMS topo.
 * Then, to set existing physical devices and the grid mtr.
 *
 * @function
 *
 * @return {promise}
 */
export async function initTopology({ commit, dispatch }) {
  const doCommit = (msg) => {
    commit('SET_TOPOLOGY', msg.toObject())

    return msg
  }
  const doCompute = (msg) => {
    dispatch('setExistingPhysicalDevicesFromTopology')
    dispatch('setGridMtrFromPhaseTopology')

    return msg
  }

  return apiTopology.getTopology().then(doCommit).then(doCompute)
}

/**
 * Action (API post) to set the EMS topo.
 *
 * @function
 *
 * @return {promise}
 */
export async function setTopology({ dispatch, state }) {
  if (state.gridMtrIecId) {
    dispatch('addGridMtrToPhaseTopology')
  }

  return apiTopology.setTopology(state.topology)
}

/**
 * Action (API call) to remove sources by their logical device name.
 *
 * @function
 *
 * @param getters {object}
 * @param ldn {string} is the logical device name of the device to be removed
 *
 * @return {promise}
 */
export function removeLdn({ getters }, ldn) {
  const promises = []

  getters.getSources(ldn, { allNodes: true }).forEach((source) => {
    promises.push(apiTopology.removeSource(source))
  })

  return Promise.all(promises).then(() => {
    return emsFinalizeTopologyChange()
  })
}

/**
 * Action (API call) to replace sources logical device name.
 *
 * @function
 *
 * @param getters {object}
 * @param oldLdn {string} is the logical device name of the (old) device to be replaced
 * @param newLdn {string} is the logical device name of the (new) device to replace the old device
 *
 * @return {promise}
 */
export function replaceLdn({ getters }, { oldLdn, newLdn }) {
  const forReplacment = []
  const forRemoval = []

  getters.getSources(oldLdn, { allNodes: true }).forEach((oldSrc) => {
    let removeOldSource = true

    getters.getSources(newLdn, { allNodes: true }).forEach((newSrc) => {
      if (
        oldSrc.iec.iecNodePrefix === newSrc.iec.iecNodePrefix &&
        oldSrc.iec.iecNodePostfix === newSrc.iec.iecNodePostfix
      ) {
        forReplacment.push({ oldSrc: oldSrc, newSrc: newSrc })
        removeOldSource = false
      }
    })

    if (removeOldSource) {
      forRemoval.push(oldSrc)
    }
  })
  const promises = []

  forReplacment.forEach((item) => {
    promises.push(apiTopology.replaceSource({ remove: item.oldSrc, replacement: item.newSrc }))
  })

  forRemoval.forEach((oldSrc) => {
    promises.push(apiTopology.removeSource(oldSrc))
  })

  return Promise.all(promises).then(() => {
    return emsFinalizeTopologyChange()
  })
}

/**
 * Action (API call) to get all setpoint sources
 *
 * @function
 *
 * @return {promise}
 */
export async function getSetpointSources({ commit }) {
  const doCommit = (msg) => {
    commit('CLEAR_SETPOINT_SOURCES')

    const setpointSources = msg.toObject().setpointSourcePropertiesList

    for (const setpointSource of setpointSources) {
      commit('SET_SETPOINT_SOURCE', {
        setpointSource: setpointSource.source
      })
    }

    return msg
  }

  return apiSController.getSetpointSourcePropertiesCollection().then(doCommit)
}
