<template>
  <component :is="tag" class="text-break">
    <span class="d-block">
      <strong class="pb-1">
        {{ isDevs ? $t('ems.topology.assignDevicesTo') : $t('ems.topology.assignDeviceTo') }}:
      </strong>
    </span>
    <span class="d-block">
      {{ label }}
    </span>
  </component>
</template>
<script>
export default {
  name: 'EmsEditTopoConfigDevSelectLabel',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'label'
    },
    label: {
      type: String,
      required: true
    },
    isDevs: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
