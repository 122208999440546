/**
 * @module validations/ems-tou-validators
 */

import { helpers, required } from 'vuelidate/lib/validators'

/**
 * Factory to create EMS timeseries name validator.
 *
 * @function
 *
 * @param {array} existingNames has to be a list of existing names
 *
 * @return {object}
 */
export function timeseriesNameValidator(existingNames) {
  if (!Array.isArray(existingNames)) {
    throw new TypeError('Argument "existingNames" has to be an array.')
  }

  return {
    required,
    alphaNum: helpers.regex('alphaNum', /^[\w,-]+$/),
    unique: helpers.withParams({ type: 'unique', existingNames }, (name) => {
      return existingNames.every((n) => {
        return n !== name
      })
    })
  }
}
