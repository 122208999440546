<template>
  <FwUpgradeOperating v-if="showFwUpgradeOperating" />
</template>

<script>
import { mapGetters } from 'vuex'
import { State as FwStatePb } from '@/../lib/proto_js/ext/fwupgrade/upgrader_pb'

import FwUpgradeOperating from '@/components/fw-upgrade/fw-upgrade-operating'

function reloadUI() {
  console.warn('FW-upgrade operation seems to be finished. Reload UI.')
  window.location.reload()
}

export default {
  name: 'FwUpgradeGuard',
  components: {
    FwUpgradeOperating
  },
  computed: {
    ...mapGetters('fwupgrader', ['fwUpgraderState']),
    showFwUpgradeOperating() {
      switch (this.fwUpgraderState) {
        case FwStatePb.StateKindCase.UPGRADE_IN_PROGRESS:
        case FwStatePb.StateKindCase.ROLLBACK_IN_PROGRESS:
        case FwStatePb.StateKindCase.FINALIZE_IN_PROGRESS:
        case FwStatePb.StateKindCase.REBOOTING:
          return true
        default:
          return false
      }
    }
  },
  watch: {
    showFwUpgradeOperating(current, previous) {
      if (!current && previous) {
        // handle the case of a finshed reboot,
        // without lost-session.
        reloadUI()
      }
    }
  },
  created() {
    this.$log.debug('Initialize fwupgrade store.')
    this.$store
      .dispatch('fwupgrader/initialize')
      .then(async ({ initialize, stream }) => {
        await initialize
        this.$log.debug('Succesfully initialized fwupgrade status-state.')
        await stream
        this.$log.debug('Succesfully closed fwupgrade status-stream')
      })
      .catch((err) => {
        this.$log.error(err)
      })
  },
  destroyed() {
    // Currently, as long as `showFwUpgradeOperating === true`,
    // the user cannot do anything,
    // in particular, she cannot leave this vue.
    // Hence, if this vue gets destroyed and `showFwUpgradeOperating === true`,
    // an auto-redirect to the login-page was done.
    // This is the trigger, that the reboot has finished,
    // and the UI has to be reloaded.
    if (this.showFwUpgradeOperating) {
      setTimeout(() => {
        reloadUI()
      }, 250)
    }
    this.$log.debug('Teardown fwupgrade store.')
    this.$store.dispatch('fwupgrader/teardown')
  }
}
</script>
