<template>
  <div>
    <h6 class="font-weight-bold">
      {{ $t(`${tBasePath}.form.options.optionsTitle`) }}
    </h6>
    <CRow v-if="!!optionOpts.length" form>
      <CCol class="col-7">
        <CSelect :value.sync="optionType" :options="optionOpts" />
      </CCol>
      <CCol class="col-3">
        <CInput v-if="optionType == 'timeHysteresis'" type="number" placeholder="1" :value.sync="cooldown" />
      </CCol>
      <CCol class="col-2">
        <!-- prettier-ignore -->
        <CButton
          class="small"
          color="success"
          size="sm"
          variant="outline"
          @click="addOption()"
        >
          <CIcon name="cil-plus" size="lg" />
        </CButton>
      </CCol>
    </CRow>

    <CListGroup class="mb-2">
      <CListGroupItem v-for="opt in optionsListPreview" :key="opt.type" class="p-2">
        <CRow>
          <CCol class="col-8 pt-1">
            {{ $t(`${tBasePath}.form.options.${opt.type}`) }}
          </CCol>
          <CCol v-for="value in opt.values" :key="value" class="col-2 pt-1">
            {{ value }}
          </CCol>
          <CCol class="col-2">
            <CButton
              class="small float-right"
              color="danger"
              size="sm"
              variant="outline"
              @click="removeOption(opt.type)"
            >
              <CIcon name="cil-minus" size="sm" />
            </CButton>
          </CCol>
        </CRow>
      </CListGroupItem>
    </CListGroup>

    <h6 class="font-weight-bold">
      {{ $t(`${tBasePath}.form.options.fallbackTitle`) }}
    </h6>

    <CRow>
      <CCol>
        <CSelect :value.sync="currentFallback" :options="fallbackOpts" @update:value="updateFallbackStrategy" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { eventBus } from '@/view-helper/event-bus'
const tBasePath = 'ems.energyService.config.decisionTree'

export default {
  name: 'EsNodeOptions',
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      optionType: 'timeHysteresis',
      cooldown: 1.0,
      currentFallback: ''
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', ['loaded']),
    ...mapGetters('emsEnergyServicesConfig', ['strategyIds', 'getDecisionTreeEntryFallbackStrategy']),
    ...mapState('emsEnergyServicesConfig', ['defaultStrategyId']),
    optionsList() {
      return this.entry?.node?.optionsList || []
    },
    optionsListPreview() {
      const previews = []
      for (const option of this.optionsList) {
        if (option.timeHysteresis !== undefined) {
          const preview = {
            type: 'timeHysteresis',
            values: option.timeHysteresis
          }
          previews.push(preview)
        }
      }

      return previews
    },
    fallbackOpts() {
      const opts = [
        {
          value: '',
          label: this.$t(`${tBasePath}.form.options.noFallback`)
        },
        {
          value: 'filterLast',
          label: this.$t(`${tBasePath}.form.options.filterLast`)
        },
        {
          value: 'DEFAULT',
          label: this.$t(`${tBasePath}.leaf.DEFAULT`)
        }
      ]

      for (const strategyId of this.strategyIds) {
        opts.push({
          value: strategyId,
          label: strategyId
        })
      }
      return opts
    },
    optionOpts() {
      const opts = []

      for (const value of ['timeHysteresis']) {
        if (this.entry?.node?.optionsList.map((x) => x[value]).some((x) => x !== undefined)) {
          continue
        }

        opts.push({
          value: value,
          label: this.$t(`${tBasePath}.form.options.${value}`)
        })
      }

      return opts
    }
  },
  created() {
    this.tBasePath = tBasePath
  },
  mounted() {
    const current = this.getDecisionTreeEntryFallbackStrategy(this.entry.idx)

    this.currentFallback = current !== null ? current : ''
  },
  methods: {
    updateFallbackStrategy() {
      eventBus.$emit('update:decision-tree-node-option-fallback', {
        idx: this.entry.idx,
        fallback: this.currentFallback
      })
    },
    addOption() {
      if (this.optionType === 'timeHysteresis') {
        const value = parseFloat(this.cooldown)
        if (value) {
          eventBus.$emit('add:decision-tree-node-option', {
            idx: this.entry.idx,
            type: this.optionType,
            value: value
          })
        } else {
          this.cooldown = 1.0
        }
      } else {
        console.log('Invalid option selected')
      }
    },
    removeOption(optionType) {
      eventBus.$emit('remove:decision-tree-node-option', { idx: this.entry.idx, optionType: optionType })
    }
  }
}
</script>
