// source: ext/auth/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ext_auth_algorithm_pb = require('../../ext/auth/algorithm_pb.js');
goog.object.extend(proto, ext_auth_algorithm_pb);
var ext_auth_token_pb = require('../../ext/auth/token_pb.js');
goog.object.extend(proto, ext_auth_token_pb);
goog.exportSymbol('proto.de.mypowergrid.auth.HashedCredentials', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.HashedCredentials.Algorithm', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.HashedCredentials.Algorithm.TypeCase', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.User', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.User.AuthInfo', null, global);
goog.exportSymbol('proto.de.mypowergrid.auth.UserDB', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.UserDB = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.UserDB, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.UserDB.displayName = 'proto.de.mypowergrid.auth.UserDB';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.de.mypowergrid.auth.User.repeatedFields_, null);
};
goog.inherits(proto.de.mypowergrid.auth.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.User.displayName = 'proto.de.mypowergrid.auth.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.User.AuthInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.User.AuthInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.User.AuthInfo.displayName = 'proto.de.mypowergrid.auth.User.AuthInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.HashedCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.HashedCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.HashedCredentials.displayName = 'proto.de.mypowergrid.auth.HashedCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.de.mypowergrid.auth.HashedCredentials.Algorithm.oneofGroups_);
};
goog.inherits(proto.de.mypowergrid.auth.HashedCredentials.Algorithm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.HashedCredentials.Algorithm.displayName = 'proto.de.mypowergrid.auth.HashedCredentials.Algorithm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.displayName = 'proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.UserDB.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.UserDB.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.UserDB} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UserDB.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersMap: (f = msg.getUsersMap()) ? f.toObject(includeInstance, proto.de.mypowergrid.auth.User.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.UserDB}
 */
proto.de.mypowergrid.auth.UserDB.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.UserDB;
  return proto.de.mypowergrid.auth.UserDB.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.UserDB} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.UserDB}
 */
proto.de.mypowergrid.auth.UserDB.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getUsersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.de.mypowergrid.auth.User.deserializeBinaryFromReader, "", new proto.de.mypowergrid.auth.User());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.UserDB.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.UserDB.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.UserDB} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.UserDB.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.de.mypowergrid.auth.User.serializeBinaryToWriter);
  }
};


/**
 * map<string, User> users = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.de.mypowergrid.auth.User>}
 */
proto.de.mypowergrid.auth.UserDB.prototype.getUsersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.de.mypowergrid.auth.User>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.de.mypowergrid.auth.User));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.de.mypowergrid.auth.UserDB} returns this
 */
proto.de.mypowergrid.auth.UserDB.prototype.clearUsersMap = function() {
  this.getUsersMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.de.mypowergrid.auth.User.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.User.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    claims: (f = msg.getClaims()) && ext_auth_token_pb.Claims.toObject(includeInstance, f),
    hashedCredentialsList: jspb.Message.toObjectList(msg.getHashedCredentialsList(),
    proto.de.mypowergrid.auth.HashedCredentials.toObject, includeInstance),
    authInfo: (f = msg.getAuthInfo()) && proto.de.mypowergrid.auth.User.AuthInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.User}
 */
proto.de.mypowergrid.auth.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.User;
  return proto.de.mypowergrid.auth.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.User}
 */
proto.de.mypowergrid.auth.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_token_pb.Claims;
      reader.readMessage(value,ext_auth_token_pb.Claims.deserializeBinaryFromReader);
      msg.setClaims(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.auth.HashedCredentials;
      reader.readMessage(value,proto.de.mypowergrid.auth.HashedCredentials.deserializeBinaryFromReader);
      msg.addHashedCredentials(value);
      break;
    case 3:
      var value = new proto.de.mypowergrid.auth.User.AuthInfo;
      reader.readMessage(value,proto.de.mypowergrid.auth.User.AuthInfo.deserializeBinaryFromReader);
      msg.setAuthInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClaims();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_token_pb.Claims.serializeBinaryToWriter
    );
  }
  f = message.getHashedCredentialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.de.mypowergrid.auth.HashedCredentials.serializeBinaryToWriter
    );
  }
  f = message.getAuthInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.de.mypowergrid.auth.User.AuthInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.User.AuthInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.User.AuthInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.User.AuthInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastAttempt: (f = msg.getLastAttempt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSuccess: (f = msg.getLastSuccess()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    numFailures: jspb.Message.getFieldWithDefault(msg, 3, 0),
    needsNewCredentials: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo}
 */
proto.de.mypowergrid.auth.User.AuthInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.User.AuthInfo;
  return proto.de.mypowergrid.auth.User.AuthInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.User.AuthInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo}
 */
proto.de.mypowergrid.auth.User.AuthInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastAttempt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSuccess(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumFailures(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsNewCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.User.AuthInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.User.AuthInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.User.AuthInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastAttempt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSuccess();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNumFailures();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNeedsNewCredentials();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_attempt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.getLastAttempt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
*/
proto.de.mypowergrid.auth.User.AuthInfo.prototype.setLastAttempt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.clearLastAttempt = function() {
  return this.setLastAttempt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.hasLastAttempt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp last_success = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.getLastSuccess = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
*/
proto.de.mypowergrid.auth.User.AuthInfo.prototype.setLastSuccess = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.clearLastSuccess = function() {
  return this.setLastSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.hasLastSuccess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 num_failures = 3;
 * @return {number}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.getNumFailures = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.setNumFailures = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool needs_new_credentials = 4;
 * @return {boolean}
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.getNeedsNewCredentials = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.de.mypowergrid.auth.User.AuthInfo} returns this
 */
proto.de.mypowergrid.auth.User.AuthInfo.prototype.setNeedsNewCredentials = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional Claims claims = 1;
 * @return {?proto.de.mypowergrid.auth.Claims}
 */
proto.de.mypowergrid.auth.User.prototype.getClaims = function() {
  return /** @type{?proto.de.mypowergrid.auth.Claims} */ (
    jspb.Message.getWrapperField(this, ext_auth_token_pb.Claims, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Claims|undefined} value
 * @return {!proto.de.mypowergrid.auth.User} returns this
*/
proto.de.mypowergrid.auth.User.prototype.setClaims = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.User} returns this
 */
proto.de.mypowergrid.auth.User.prototype.clearClaims = function() {
  return this.setClaims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.User.prototype.hasClaims = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated HashedCredentials hashed_credentials = 2;
 * @return {!Array<!proto.de.mypowergrid.auth.HashedCredentials>}
 */
proto.de.mypowergrid.auth.User.prototype.getHashedCredentialsList = function() {
  return /** @type{!Array<!proto.de.mypowergrid.auth.HashedCredentials>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.de.mypowergrid.auth.HashedCredentials, 2));
};


/**
 * @param {!Array<!proto.de.mypowergrid.auth.HashedCredentials>} value
 * @return {!proto.de.mypowergrid.auth.User} returns this
*/
proto.de.mypowergrid.auth.User.prototype.setHashedCredentialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.de.mypowergrid.auth.HashedCredentials=} opt_value
 * @param {number=} opt_index
 * @return {!proto.de.mypowergrid.auth.HashedCredentials}
 */
proto.de.mypowergrid.auth.User.prototype.addHashedCredentials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.de.mypowergrid.auth.HashedCredentials, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.de.mypowergrid.auth.User} returns this
 */
proto.de.mypowergrid.auth.User.prototype.clearHashedCredentialsList = function() {
  return this.setHashedCredentialsList([]);
};


/**
 * optional AuthInfo auth_info = 3;
 * @return {?proto.de.mypowergrid.auth.User.AuthInfo}
 */
proto.de.mypowergrid.auth.User.prototype.getAuthInfo = function() {
  return /** @type{?proto.de.mypowergrid.auth.User.AuthInfo} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.User.AuthInfo, 3));
};


/**
 * @param {?proto.de.mypowergrid.auth.User.AuthInfo|undefined} value
 * @return {!proto.de.mypowergrid.auth.User} returns this
*/
proto.de.mypowergrid.auth.User.prototype.setAuthInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.User} returns this
 */
proto.de.mypowergrid.auth.User.prototype.clearAuthInfo = function() {
  return this.setAuthInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.User.prototype.hasAuthInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.HashedCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.HashedCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && proto.de.mypowergrid.auth.HashedCredentials.Algorithm.toObject(includeInstance, f),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials}
 */
proto.de.mypowergrid.auth.HashedCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.HashedCredentials;
  return proto.de.mypowergrid.auth.HashedCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials}
 */
proto.de.mypowergrid.auth.HashedCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.de.mypowergrid.auth.HashedCredentials.Algorithm;
      reader.readMessage(value,proto.de.mypowergrid.auth.HashedCredentials.Algorithm.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.HashedCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.de.mypowergrid.auth.HashedCredentials.Algorithm.serializeBinaryToWriter
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.TypeCase = {
  TYPE_NOT_SET: 0,
  DIGEST: 1,
  BCRYPT: 2
};

/**
 * @return {proto.de.mypowergrid.auth.HashedCredentials.Algorithm.TypeCase}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.getTypeCase = function() {
  return /** @type {proto.de.mypowergrid.auth.HashedCredentials.Algorithm.TypeCase} */(jspb.Message.computeOneofCase(this, proto.de.mypowergrid.auth.HashedCredentials.Algorithm.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.HashedCredentials.Algorithm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.toObject = function(includeInstance, msg) {
  var f, obj = {
    digest: (f = msg.getDigest()) && ext_auth_algorithm_pb.Algorithm.Digest.toObject(includeInstance, f),
    bcrypt: (f = msg.getBcrypt()) && proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.HashedCredentials.Algorithm;
  return proto.de.mypowergrid.auth.HashedCredentials.Algorithm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ext_auth_algorithm_pb.Algorithm.Digest;
      reader.readMessage(value,ext_auth_algorithm_pb.Algorithm.Digest.deserializeBinaryFromReader);
      msg.setDigest(value);
      break;
    case 2:
      var value = new proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt;
      reader.readMessage(value,proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.deserializeBinaryFromReader);
      msg.setBcrypt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.HashedCredentials.Algorithm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDigest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ext_auth_algorithm_pb.Algorithm.Digest.serializeBinaryToWriter
    );
  }
  f = message.getBcrypt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.prototype.toObject = function(opt_includeInstance) {
  return proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt;
  return proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional Algorithm.Digest digest = 1;
 * @return {?proto.de.mypowergrid.auth.Algorithm.Digest}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.getDigest = function() {
  return /** @type{?proto.de.mypowergrid.auth.Algorithm.Digest} */ (
    jspb.Message.getWrapperField(this, ext_auth_algorithm_pb.Algorithm.Digest, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.Algorithm.Digest|undefined} value
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} returns this
*/
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.setDigest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.de.mypowergrid.auth.HashedCredentials.Algorithm.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} returns this
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.clearDigest = function() {
  return this.setDigest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.hasDigest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BCrypt bcrypt = 2;
 * @return {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.getBcrypt = function() {
  return /** @type{?proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt, 2));
};


/**
 * @param {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm.BCrypt|undefined} value
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} returns this
*/
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.setBcrypt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.de.mypowergrid.auth.HashedCredentials.Algorithm.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials.Algorithm} returns this
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.clearBcrypt = function() {
  return this.setBcrypt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.HashedCredentials.Algorithm.prototype.hasBcrypt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Algorithm algorithm = 1;
 * @return {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.getAlgorithm = function() {
  return /** @type{?proto.de.mypowergrid.auth.HashedCredentials.Algorithm} */ (
    jspb.Message.getWrapperField(this, proto.de.mypowergrid.auth.HashedCredentials.Algorithm, 1));
};


/**
 * @param {?proto.de.mypowergrid.auth.HashedCredentials.Algorithm|undefined} value
 * @return {!proto.de.mypowergrid.auth.HashedCredentials} returns this
*/
proto.de.mypowergrid.auth.HashedCredentials.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.de.mypowergrid.auth.HashedCredentials} returns this
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.de.mypowergrid.auth.HashedCredentials} returns this
 */
proto.de.mypowergrid.auth.HashedCredentials.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


goog.object.extend(exports, proto.de.mypowergrid.auth);
