import { ipAddress, required, helpers } from 'vuelidate/lib/validators'

function requiredIfStatic(field, vm) {
  return !vm.static || helpers.req(field)
}

function requiredIfNotDnsDhcp(field, vm) {
  if (vm.static || vm.autoAddr) {
    return helpers.req(field)
  } else {
    return true
  }
}

function ipAddr(ip, vm) {
  return !vm.static || ipAddress(ip)
}

function ipDns(ip, vm) {
  if (vm.static || vm.autoAddr) {
    return ipAddress(ip)
  } else {
    return true
  }
}

export default {
  config: {
    static: {
      required
    },
    auto: {
      required
    },
    autoAddr: {
      required
    },
    ip: {
      required: requiredIfStatic,
      ipAddress: ipAddr
    },
    netmask: {
      required: requiredIfStatic,
      ipAddress: ipAddr
    },
    gateway: {
      required: requiredIfStatic,
      ipAddress: ipAddr
    },
    primaryDns: {
      required: requiredIfNotDnsDhcp,
      ipAddress: ipDns
    },
    secondaryDns: {
      required: requiredIfNotDnsDhcp,
      ipAddress: ipDns
    }
  }
}
