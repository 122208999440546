/** @module */

import { AuthService } from '@/../lib/proto_js/ext/auth/auth_service_pb_service'
import {
  UpdateLicenseRequest as UpdateLicenseRequestPb,
  GetLicenseIDRequest as GetLicenseIDRequestPb,
  GetLicenseRequest as GetLicenseRequestPb,
  GetLicenseFeaturesRequest as GetLicenseFeaturesRequestPb,
  GetAuthDataRequest as GetAuthDataRequestPb,
  LoginRequest as LoginRequestPb,
  LogoutRequest as LogoutRequestPb,
  ListUsersRequest as ListUsersRequestPb,
  CreateUserRequest as CreateUserRequestPb,
  CreateUserWithPUKRequest as CreateUserWithPUKRequestPb,
  UpdateUserRequest as UpdateUserRequestPb,
  UpdateUserWithPUKRequest as UpdateUserWithPUKRequestPb,
  DeleteUserRequest as DeleteUserRequestPb
} from '@/../lib/proto_js/ext/auth/auth_service_pb'
import { Algorithm as AlgorithmPb } from '@/../lib/proto_js/ext/auth/algorithm_pb'
import { Credentials as CredentialsPb } from '@/../lib/proto_js/ext/auth/credentials_pb'
import { Principal as PrincipalPb } from '@/../lib/proto_js/ext/auth/principal_pb'
import { Role as RolePb } from '@/../lib/proto_js/ext/auth/role_pb'
import { HashedCredentials as HashedCredentialsPb } from '@/../lib/proto_js/ext/auth/user_pb'

import { AuthenticationError, evalServiceError } from '@/api/error-handling'
import { createUnaryCall } from '@/grpc'

/**
 * API call to create/update a license and to init the first user.
 *
 * @function
 *
 * @param {string} license (required) is the license to be created/updated.
 * @param {object} params (optional) only required if license created
 * @param {string} params.username is the username.
 * @param {string} params.password is the password.
 *
 * @return {promise}
 */
export function updateLicense(license, { username, password } = {}) {
  const req = new UpdateLicenseRequestPb([license])

  if (username && password) {
    const alg = new HashedCredentialsPb.Algorithm()
    alg.setBcrypt(new HashedCredentialsPb.Algorithm.BCrypt())
    req.setAlgorithm(alg)
    req.setPrincipal(new PrincipalPb([username]))
    req.setCredentials(new CredentialsPb([password]))
  }

  const call = createUnaryCall({
    service: AuthService,
    method: 'UpdateLicense',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to get the licnse ID (uuid).
 *
 * Does not require any authentication.
 *
 * @function
 *
 *
 * @return {promise}
 */
export function getLicenseId() {
  const call = createUnaryCall({
    service: AuthService,
    method: 'GetLicenseID',
    payload: new GetLicenseIDRequestPb()
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to get the full license.
 *
 * @function
 *
 *
 * @return {promise}
 */
export function getLicense() {
  const call = createUnaryCall({
    service: AuthService,
    method: 'GetLicense',
    payload: new GetLicenseRequestPb()
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to get auth data needed for login.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username.
 * @param {string} params.password is the password.
 *
 * @return {promise}
 */
export function getAuthData({ username, password }) {
  const req = new GetAuthDataRequestPb()
  const alg = new AlgorithmPb()

  alg.setBasic(new AlgorithmPb.Basic())
  req.setAlgorithm(alg)
  req.setPrincipal(new PrincipalPb([username]))
  req.setCredentials(new CredentialsPb([password]))

  const call = createUnaryCall({
    service: AuthService,
    method: 'GetAuthData',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to login using basic authentication.
 *
 * Credentials are sent in the clear.
 * The password is base64 encoded.
 *
 * In case of success, the auth token will be attached as cookie.
 *
 * The cookie claims are sent as resonse.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username
 * @param {string} params.password is the password
 * @param {string} params.newPassword (optional) is the new password. Only required, iff a login with new credentials.
 * @param {boolean} debug will get the `auth.LoginRequest.authdata` by calling `GetAuthData`. Should NOT be used in production mode.
 *
 * @throws {AuthenticationError}
 *
 * @return {promise}
 *  Resolves to a `auth.LoginResponse` (with `auth.Claims`) proto msg object, if logged in or successfull login with new credentials.
 *  Rejects to a `AuthenticationError`` with `code`, `msg` (or `message`), and `type`.
 */
export async function login({ username, password, newPassword }, debug = false) {
  try {
    let authData
    if (debug) {
      authData = await getAuthData({ username, password })
      evalServiceError(authData)
      authData = authData.getAuthdata()
    } else {
      authData = new CredentialsPb([password]).serializeBinary()
    }

    const req = new LoginRequestPb()
    const alg = new AlgorithmPb()

    alg.setBasic(new AlgorithmPb.Basic())
    req.setAlgorithm(alg)
    req.setPrincipal(new PrincipalPb([username]))
    req.setAuthdata(authData)
    if (newPassword) {
      req.setNewCredentials(new CredentialsPb([newPassword]))
    }

    const call = createUnaryCall({
      service: AuthService,
      method: 'Login',
      payload: req
    })

    const rsp = await call.perform()
    return evalServiceError(rsp)
  } catch (err) {
    switch (err.name) {
      case 'GrpcError':
        throw new AuthenticationError({
          code: err.code,
          msg: err.msg || 'Server Error',
          type: 'SERVER_ERROR'
        })
      case 'GrpcServiceError':
        throw new AuthenticationError({
          code: err.code,
          msg: err.msg || 'Authentication failed.',
          type: 'SERVICE_ERROR'
        })
      default:
        throw new AuthenticationError({
          code: err.code || null,
          msg: err.message || 'Unknown Error',
          type: 'UNKNOWN'
        })
    }
  }
}

/**
 * API call to logout.
 *
 * @function
 *
 * @return {promise}
 */
export function logout() {
  const call = createUnaryCall({
    service: AuthService,
    method: 'Logout',
    payload: new LogoutRequestPb()
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to list all users
 *
 * @function
 *
 * @param {object} arg
 *
 * @return {promise} resolves to a `auth.ListUsersResponse` with a list of `auth.Claims` proto objects
 */
export function listUsers() {
  const call = createUnaryCall({
    service: AuthService,
    method: 'ListUsers',
    payload: new ListUsersRequestPb()
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to create a new user.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username (required).
 * @param {string} params.password is the password (required).
 * @param {array} params.roles is an optional list of user's role names (`string`).
 *
 * @return {promise} resolves to an empty `auth.CreateUserResponse`
 */
export function createUser({ username, password, roles = [] }) {
  if (!username || !password) {
    throw new Error('The "username" and "password" is required.')
  }

  const req = new CreateUserRequestPb([[], [], []])
  req.getAlgorithm().setBcrypt(new HashedCredentialsPb.Algorithm.BCrypt())
  req.getClaims().setPrincipal(new PrincipalPb([username]))
  roles.forEach((role) => {
    req.getClaims().addRoles(new RolePb([role]))
  })
  req.getInitialCredentials().setPassword(password)

  const call = createUnaryCall({
    service: AuthService,
    method: 'CreateUser',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to create a new admin user with PUK.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username (required).
 * @param {string} params.password is the password (required).
 * @param {string} params.puk is the PUK (required). Will be base-64 encoded and set as protobuf bytes.
 *
 * @return {promise} resolves to an empty `auth.CreateUserWithPUKResponse`
 */
export function createUserWithPuk({ username, password, puk }) {
  if (!username || !password || !puk) {
    throw new Error('The "username" and "password" and "puk" is required.')
  }

  const req = new CreateUserWithPUKRequestPb([[], [], [], []])
  req.setPuk(btoa(puk))
  req.getAlgorithm().setBcrypt(new HashedCredentialsPb.Algorithm.BCrypt())
  req.getPrincipal().setUsername(username)
  req.getCredentials().setPassword(password)

  const call = createUnaryCall({
    service: AuthService,
    method: 'CreateUserWithPUK',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to update the username and/or password.
 * If successfully updated, the user will be logged out.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.currentUsername (required) is the current username
 * @param {string} params.newUsername (optional) is the new username, in case the username should be updated.
 * @param {string} params.currentPassword (required) is the current password
 * @param {string} params.newPassword (optional) is the new password, in case the password should be updated.
 *
 * @return {promise}
 */
export function updateUser({ currentUsername, newUsername, currentPassword, newPassword }) {
  if (!currentUsername) {
    throw new Error('currentUsername is required')
  }

  // Currently the BE does NOT require a pwd, however, this should change in the future
  if (!currentPassword) {
    throw new Error('currentPassword is required')
  }

  if (!newUsername && !newPassword) {
    throw new Error('Either newUsername or newPassword is required.')
  }

  const req = new UpdateUserRequestPb()
  req.setCurrentPrincipal(new PrincipalPb([currentUsername]))
  req.setCurrentCredentials(new CredentialsPb([currentPassword]))
  if (newUsername) {
    req.setNewPrincipal(new PrincipalPb([newUsername]))
  }
  if (newPassword) {
    req.setNewCredentials(new CredentialsPb([newPassword]))
  }

  const call = createUnaryCall({
    service: AuthService,
    method: 'UpdateUser',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to update a user's password providing a PUK.
 *
 * Note: The user has to provide a PUK to get authorized, hence authentication-token + old-password is not required.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username is the username (required).
 * @param {string} params.newPassword is the new password (required).
 * @param {string} params.puk is the PUK (required).
 *
 * @return {promise} resolves to an empty `auth.UpdateUserWithPUKResponse`
 */
export function updateUserWithPuk({ username, newPassword, puk }) {
  if (!username || !newPassword || !puk) {
    throw new Error('The "username" and "newPassword" and "puk" is required.')
  }

  const req = new UpdateUserWithPUKRequestPb([[], [], []])
  req.setPuk(btoa(puk))
  req.getPrincipal().setUsername(username)
  req.getNewCredentials().setPassword(newPassword)

  const call = createUnaryCall({
    service: AuthService,
    method: 'UpdateUserWithPUK',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

/**
 * API call to delete a user.
 *
 * @function
 *
 * @param {object} params
 * @param {string} params.username (required) is the username of the user to be deleted.
 *
 * @return {promise}
 */
export function deleteUser({ username }) {
  if (!username) {
    throw new Error('username is required')
  }

  const req = new DeleteUserRequestPb()
  req.setPrincipal(new PrincipalPb([username]))

  const call = createUnaryCall({
    service: AuthService,
    method: 'DeleteUser',
    payload: req
  })

  return call.perform().then(evalServiceError)
}

export function getLicenseFeatures() {
  const call = createUnaryCall({
    service: AuthService,
    method: 'GetLicenseFeatures',
    payload: new GetLicenseFeaturesRequestPb()
  })

  return call.perform().then(evalServiceError)
}
