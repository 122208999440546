<template>
  <div class="full-width-bg" :style="landingBg">
    <CWrapper class="transparency">
      <ApxHeader />
      <div class="content-wrapper">
        <CRow v-if="isLoading">
          <vue-loading type="spiningDubbles" :style="{ fill: 'var(--primary)', width: '200px', height: '200px' }" />
        </CRow>
        <!-- DO NOT use v-if/else, otherwise router-view is re-rendered during API loading, which is NOT desireable -->
        <CRow :class="{ 'd-none': isLoading }">
          <router-view></router-view>
        </CRow>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import ApxHeader from 'custom/header'

export default {
  name: 'ApxAuthContainer',
  components: {
    ApxHeader
  },
  data() {
    let backgroundImage = ''
    if (process.env.VUE_APP_WHITELABEL === 'APX') {
      backgroundImage = `url(${require('@/assets/img/landing-apx.svg')})`
    } else {
      backgroundImage = `url(${require('@/assets/img/landing-tem.svg')})`
    }
    return {
      landingBg: {
        backgroundImage
      }
    }
  },
  computed: {
    ...mapGetters('apiLoadingStatus', {
      isLoading: 'loading'
    })
  },
  created() {
    this.fetchLicenseId()
  },
  methods: {
    fetchLicenseId() {
      this.$store.commit('apiLoadingStatus/STARTED')
      this.$store
        .dispatch('auth/getLicenseId')
        .then(() => {
          this.$log.debug('Successfully fetched license ID.')
        })
        .catch(this.onServerError)
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })
    },
    onServerError(err) {
      this.$log.warn(err)
      this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
    }
  }
}
</script>
<style lang="scss" scoped>
.full-width-bg {
  width: 100%;
  padding: 0;
  margin: 0;
  background-size: 200px;
}

.transparency {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-wrapper {
  margin-top: 15vh;
}
</style>
