<template>
  <EsLayoutForm
    :show-failure-alert.sync="showFailureAlert"
    @cancel="$emit('cancel')"
    @reset="init({ reseted: true })"
    @submit="onSubmit"
  >
    <CRow>
      <!-- prettier-ignore -->
      <EvalTreeModeToggler
        :expert-mode.sync="expertMode"
        :show-extended-operator-mode-toggler="true"
      />
      <CCol v-if="expertMode" col="12">
        <EvalTree />
      </CCol>
      <CCol v-else col="12" :md="{ size: 8, offset: 2 }">
        <CSelect
          :label="$t(`${tBasePath}.tsSelection.label`)"
          :placeholder="$t(`${tBasePath}.tsSelection.placeholder`)"
          :description="$t(`${tBasePath}.tsSelection.description`)"
          :is-valid="$v.selectedTimeseries.$invalid ? false : null"
          :invalid-feedback="$t(`${tBasePath}.tsSelection.invalidFeedback`)"
          :options="timeseriesOptions"
          :value="selectedTimeseries"
          @update:value="onUpdateTimeseries"
        />
      </CCol>
      <div class="col-12 d-flex justify-content-end">
        <CLink class="text-primary" @click.prevent="routeToTou">
          {{ $t(`${tBasePath}.tsSelection.linkTouSetting`) }}
        </CLink>
      </div>
    </CRow>
  </EsLayoutForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import { formProps as props } from './form-props'
import { genericServerErrorNotificationMsg } from '@/api/error-handling'
import { CACHE_IDS } from '@/store/modules/cache'

import EvalTreeModeToggler from '@/components/ems/evalexp/eval-tree-mode-toggler'
import EvalTree from '@/components/ems/evalexp/eval-tree'
import EsLayoutForm from '@/components/ems/energy-services/energy-service-forms/es-layout-form'
import { recursivelyValidateEvalTreeVue } from '@/view-helper/form-helpers'

export default {
  name: 'EsTimeSwitchForm',
  components: {
    EvalTreeModeToggler,
    EvalTree,
    EsLayoutForm
  },
  props,
  data() {
    return {
      selectedTimeseries: null,
      expertMode: false,
      showFailureAlert: false
    }
  },
  computed: {
    ...mapGetters('emsEvalExpression', ['parsedEvalTree', 'isValidTree', 'sizeEvalTree']),
    ...mapGetters('emsEnergyServicesConfig', ['getDecisionTreeEntry']),
    ...mapGetters('emsTimeseries', ['timeseriesNames']),
    entry() {
      return this.getDecisionTreeEntry(this.idx)
    },
    timeseriesOptions() {
      return this.timeseriesNames.map((n) => {
        return {
          value: n,
          label: n
        }
      })
    }
  },
  watch: {
    parsedEvalTree(tree) {
      this.initSelectedTimeseries(tree)
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.decisionTree.form.greaterThanZero'
  },
  mounted() {
    this.init()
  },
  methods: {
    async init({ reseted = false } = {}) {
      this.showFailureAlert = false
      this.$store.commit('apiLoadingStatus/STARTED')

      this.$store
        .dispatch('emsSensors/sensorInit', {})
        .then(() => {
          this.$log.debug('Succesfully fetched sensor names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })

      this.$store
        .dispatch('emsTimeseries/timeseriesesInit', {
          withTimestampValues: false
        })
        .then(() => {
          this.$log.debug('Succesfully fetched timeseries names.')
        })
        .catch((err) => {
          this.$log.error(err)
          this.$store.commit('notifications/PUSH_TOAST', genericServerErrorNotificationMsg(err))
        })
        .finally(() => {
          this.$store.commit('apiLoadingStatus/STOPPED')
        })

      let hasCache = false
      if (!reseted) {
        hasCache = await this.$store.dispatch('cache/replayStoreState', {
          path: 'emsEvalExpression',
          id: this.$route.query['ee-cache'],
          vuexStore: this.$store
        })
      }
      if (!hasCache) {
        await this.$store.dispatch('emsEvalExpression/copyEvalExpressionFromDecisionTree', {
          decisionTreeIdx: this.idx
        })
      }

      this.initSelectedTimeseries(this.parsedEvalTree)
      this.expertMode = this.parsedEvalTree.isNode === null ? false : !this.selectedTimeseries
    },
    initSelectedTimeseries(tree) {
      if (this.sizeEvalTree > 1) {
        this.selectedTimeseries = null
        return
      }

      if (this.timeseriesNames.includes(tree.reference)) {
        this.selectedTimeseries = tree.reference
        return
      }

      this.selectedTimeseries = null
    },
    routeToTou() {
      this.$emit('route-to:tou')
      // parent will do the navigation and store-caching
    },
    onUpdateTimeseries(name) {
      this.selectedTimeseries = name
      this.$v.selectedTimeseries.$touch()
      if (this.$v.selectedTimeseries.$invalid) {
        return
      }

      this.$store.commit('emsEvalExpression/CLEAR')
      this.$store.commit('emsEvalExpression/UPDATE_REFERENCE', { nodeIdx: 0, reference: name })
    },
    onSubmit() {
      this.showFailureAlert = false

      if (!this.isValidTree) {
        recursivelyValidateEvalTreeVue(this)
        this.showFailureAlert = true
        return
      }

      this.$store.commit('cache/CLEAR_CACHE', { id: CACHE_IDS.emsEvalExpression })
      // no params are required, all data is in `emsEvalExpression` store
      this.$emit('confirmed', {
        idx: this.idx,
        decisionNodeType: 'greaterThanZero',
        params: {}
      })
    }
  },
  validations: {
    selectedTimeseries: {
      required: requiredIf(function () {
        return !this.expertMode
      })
    }
  }
}
</script>
