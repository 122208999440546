/**
 * Stores the state of EMS topology configuration provided by the EnergyManagementSystem.
 *
 * @module store/ems-topology-config
 */

import { Topology as TopologyPb } from '@/../lib/proto_js/ext/ems/topology/topology_pb'

import * as actions from '@/store/modules/_ems-topology-config-actions'
import * as getters from '@/store/modules/_ems-topology-config-getters'
import * as mutations from '@/store/modules/_ems-topology-config-mutations'

/**
 * The ems-topoloyg-config store allows to store:
 *
 * - `existingPhysicalDevices` is a list of topology.PhysicalDevice(s), which were added by the user.
 * - `sourceQuantitiesList` is a list of topology.SourceQuantities, which were requested from the EMS (`getQuantities`).
 * - `gridMtrIecId` is an optional reference to the topology.Source.IecId of the meter, which measures the grid-power.
 * - `topology` is a topology.Topology which was requested from the EMS (`getTopology``), and should be updated finally (`setTopology`).
 *
 * @function
 *
 * @return {object}
 */
const state = () => {
  return {
    existingPhysicalDevices: [],
    sourceQuantitiesList: [],
    gridMtrIecId: null,
    setpointSources: {},
    swapGridMtr: false,
    topology: new TopologyPb().toObject()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
