<template>
  <div>
    <div
      :class="{
        'eval-expression-tree-entry-container': true,
        'eval-expression-tree-entry-separator': entry.idx > 0,
        'text-center': true
      }"
    >
      <div v-if="!preview">
        <TreeSelect
          v-model="$v.operator.$model"
          size="sm"
          :append-to-body="appendMenuToBody"
          disable-branch-nodes
          :class="$v.operator.$invalid ? 'is-invalid' : null"
          :clearable="false"
          :placeholder="$t(`${tBasePath}.operation.placeholder`)"
          :options="operatorOptions"
          :disabled="disableOnPreviewPage"
          @select="onUpdateOperator"
        />
        <div class="invalid-feedback"> {{ $t(`${tBasePath}.operation.invalidFeedback`) }} </div>
      </div>
      <div v-else class="small">
        {{ dressedOperator }}
      </div>
      <template v-if="dressedOperator === 'value'">
        <CInput
          v-if="!preview"
          v-model.number="$v.value.$model"
          type="number"
          :is-valid="$v.value.$invalid ? false : null"
          :invalid-feedback="$t(`${tBasePath}.value.invalidFeedback`)"
          :placeholder="$t(`${tBasePath}.value.placeholder`)"
          size="sm"
          :lazy="true"
          @update:value="onUpdateValue"
        />
        <div v-else class="small">
          {{ $v.value.$model }}
        </div>
      </template>
      <template v-else-if="['timeseriesPoint', 'timepoint-sensor', 'timepoint-sensor-all'].includes(dressedOperator)">
        <div v-if="!preview">
          <CSelect
            v-if="dressedOperator === 'timeseriesPoint'"
            size="sm"
            :description="$t(`${tBasePath}.timepoint.reference.description`)"
            :is-valid="!$v.timeseriesPoint.reference.$invalid"
            :invalid-feedback="$t(`${tBasePath}.reference.invalidFeedback`)"
            :placeholder="$t(`${tBasePath}.reference.placeholder`)"
            :options="referenceOptions"
            :value.sync="$v.timeseriesPoint.reference.$model"
            @update:value="onUpdateTimepoint"
          />
          <div v-if="['timepoint-sensor', 'timepoint-sensor-all'].includes(dressedOperator)">
            <TreeSelect
              v-model="$v.timeseriesPoint.reference.$model"
              size="sm"
              :append-to-body="appendMenuToBody"
              disable-branch-nodes
              :class="$v.timeseriesPoint.reference.$invalid ? 'is-invalid' : null"
              :clearable="false"
              :show-count="true"
              :description="$t(`${tBasePath}.timepoint.reference.description`)"
              :placeholder="$t(`${tBasePath}.referenceSensor.placeholder`)"
              :options="referenceOptions"
              @input="onUpdateTimepoint"
            />
            <div class="invalid-feedback"> {{ $t(`${tBasePath}.referenceSensor.invalidFeedback`) }} </div>
          </div>
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <hr />
          <CInput
            v-model.number="$v.timeseriesPoint.deltaTime.$model"
            type="number"
            :description="$t(`${tBasePath}.timepoint.deltaLabel`)"
            :is-valid="!$v.timeseriesPoint.deltaTime.$invalid"
            :invalid-feedback="timepointFeedback"
            :placeholder="$t(`${tBasePath}.value.placeholder`)"
            size="sm"
            :lazy="true"
            @update:value="onUpdateTimepoint"
          />
        </div>
        <div v-else class="small">
          <div> {{ $t(`${tBasePath}.timepoint.reference.label`) }}: {{ timeseriesPoint.reference }} </div>
          <div>
            {{ $t(`${tBasePath}.timepoint.deltaPreview`, { value: timeseriesPoint.deltaTime }) }}
          </div>
        </div>
      </template>
      <template
        v-else-if="
          ['timeseriesAggregate', 'timeaggregate-sensor', 'timeaggregate-sensor-all'].includes(dressedOperator)
        "
      >
        <div v-if="!preview">
          <CSelect
            v-if="dressedOperator === 'timeseriesAggregate'"
            :value.sync="$v.timeseriesAggregate.reference.$model"
            size="sm"
            :description="$t(`${tBasePath}.aggregate.reference.description`)"
            :is-valid="!$v.timeseriesAggregate.reference.$invalid"
            :invalid-feedback="$t(`${tBasePath}.reference.invalidFeedback`)"
            :placeholder="$t(`${tBasePath}.reference.placeholder`)"
            :options="referenceOptions"
            @update:value="onUpdateAggregate"
          />
          <TreeSelect
            v-if="['timeaggregate-sensor', 'timeaggregate-sensor-all'].includes(dressedOperator)"
            v-model="$v.timeseriesAggregate.reference.$model"
            size="sm"
            :append-to-body="appendMenuToBody"
            disable-branch-nodes
            :class="$v.timeseriesAggregate.reference.$invalid ? 'is-invalid' : null"
            :clearable="false"
            :show-count="true"
            :description="$t(`${tBasePath}.aggregate.reference.description`)"
            :placeholder="$t(`${tBasePath}.referenceSensor.placeholder`)"
            :options="referenceOptions"
            @input="onUpdateAggregate"
          />
          <div class="invalid-feedback"> {{ $t(`${tBasePath}.referenceSensor.invalidFeedback`) }} </div>
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <hr />
          <CInput
            v-model.number="$v.timeseriesAggregate.deltaStartTime.$model"
            class="mb-3"
            type="number"
            :description="$t(`${tBasePath}.aggregate.startLabel`)"
            :is-valid="!$v.timeseriesAggregate.deltaStartTime.$invalid"
            :invalid-feedback="aggregateFeedbackStart"
            :placeholder="$t(`${tBasePath}.value.placeholder`)"
            size="sm"
            :lazy="true"
            @update:value="onUpdateAggregate"
          />
          <CInput
            v-model.number="$v.timeseriesAggregate.deltaEndTime.$model"
            class="mb-3"
            type="number"
            :description="$t(`${tBasePath}.aggregate.endLabel`)"
            :is-valid="!$v.timeseriesAggregate.deltaEndTime.$invalid"
            :invalid-feedback="aggregateFeedbackEnd"
            :placeholder="$t(`${tBasePath}.value.placeholder`)"
            size="sm"
            :lazy="true"
            @update:value="onUpdateAggregate"
          />
          <CSelect
            size="sm"
            :description="$t(`${tBasePath}.aggregate.type.label`)"
            :is-valid="$v.timeseriesAggregate.op.$invalid ? false : null"
            :invalid-feedback="$t(`${tBasePath}.operation.invalidFeedback`)"
            :placeholder="$t(`${tBasePath}.operation.placeholder`)"
            :options="aggregateOptions"
            :value.sync="$v.timeseriesAggregate.op.$model"
            @update:value="onUpdateAggregate"
          />
        </div>
        <div v-else class="small">
          <div> {{ $t(`${tBasePath}.aggregate.reference.label`) }}:{{ timeseriesAggregate.reference }} </div>
          <div>
            {{ $t(`${tBasePath}.aggregate.startPreview`, { value: timeseriesAggregate.deltaStartTime }) }}
          </div>
          <div>
            {{ $t(`${tBasePath}.aggregate.endPreview`, { value: timeseriesAggregate.deltaEndTime }) }}
          </div>
          <div> {{ $t(`${tBasePath}.aggregate.type.label`) }}: {{ undressType(timeseriesAggregate.op) }} </div>
        </div>
      </template>
      <template
        v-else-if="
          ['reference', 'reference-timeseries', 'reference-sensor', 'reference-sensor-all'].includes(dressedOperator)
        "
      >
        <div v-if="!preview">
          <CInput
            v-if="dressedOperator === 'reference'"
            type="string"
            size="sm"
            :is-valid="!$v.reference.$invalid"
            :invalid-feedback="$t(`${tBasePath}.reference.invalidFeedback`)"
            :placeholder="$t(`${tBasePath}.reference.placeholder`)"
            :lazy="400"
            :value.sync="$v.reference.$model"
            @update:value="onUpdateReference"
          />
          <CSelect
            v-if="dressedOperator === 'reference-timeseries'"
            size="sm"
            :is-valid="$v.reference.$invalid ? false : null"
            :invalid-feedback="$t(`${tBasePath}.referenceTimeseries.invalidFeedback`)"
            :placeholder="$t(`${tBasePath}.referenceTimeseries.placeholder`)"
            :options="referenceOptions"
            :value.sync="$v.reference.$model"
            @update:value="onUpdateReferenceProfile"
          />
          <div v-if="['reference-sensor', 'reference-sensor-all'].includes(dressedOperator)">
            <TreeSelect
              v-model="$v.reference.$model"
              size="sm"
              :append-to-body="appendMenuToBody"
              disable-branch-nodes
              :class="$v.reference.$invalid ? 'is-invalid' : null"
              :clearable="false"
              :show-count="true"
              :placeholder="$t(`${tBasePath}.referenceSensor.placeholder`)"
              :options="referenceOptions"
              @input="onUpdateReferenceProfile"
            />
            <div class="invalid-feedback"> {{ $t(`${tBasePath}.referenceSensor.invalidFeedback`) }} </div>
          </div>
        </div>
        <div v-else class="small">
          {{ $v.reference.$model }}
        </div>
      </template>
      <template v-else-if="dressedOperator === 'alias'">
        <CSelect
          v-if="!preview"
          size="sm"
          :is-valid="$v.alias.$invalid ? false : null"
          :invalid-feedback="$t(`${tBasePath}.alias.invalidFeedback`)"
          :placeholder="$t(`${tBasePath}.alias.placeholder`)"
          :options="aliasOpts"
          :value.sync="$v.alias.$model"
          @update:value="onUpdateAlias"
        />
        <div v-else class="small">
          {{ $v.alias.$model }}
        </div>
      </template>
      <div v-if="$v.children.$dirty && $v.children.$invalid" class="invalid-feedback d-flex justify-content-center">
        {{ $t(`${tBasePath}.children.invalidFeedback`) }}
      </div>
      <CButtonGroup v-if="!preview" size="sm">
        <!-- might add tooltip -->
        <!-- v-c-tooltip="'Adds a new Operand'" -->
        <CButton
          v-if="entry.isNode && !(entry.lhs && entry.rhs) && operator !== 'not'"
          variant="ghost"
          color="primary"
          @click.prevent="onAddOperand"
        >
          <CIcon size="sm" name="cilPlusCircle" />
          <span v-if="showExtendedDisplay" class="ml-1">
            {{ $t('main.addBtn') }}
          </span>
        </CButton>
        <!-- might add tooltip -->
        <!-- v-c-tooltip="`Removes the entire ${entry.isNode ? 'node' : 'leaf'}`" -->
        <CButton v-if="entry.idx !== 0" variant="ghost" color="danger" @click="onRmOperand">
          <CIcon size="sm" name="cilXCircle" />
          <span v-if="showExtendedDisplay" class="ml-1">
            {{ $t('main.removeBtn') }}
          </span>
        </CButton>
      </CButtonGroup>
    </div>
    <div
      v-if="entry.isNode && entry.children.length"
      class="d-flex justify-content-around"
      :class="{ 'mr-2': addSpaceToNeighbours, 'ml-2': addSpaceToNeighbours }"
    >
      <EvalTreeEntry
        v-for="child in entry.children"
        :key="`eval-exp-entry-${child.idx}-${child.isNode ? 'n' : 'l'}`"
        :entry="child"
        :preview="preview"
        :add-space-to-neighbours="entry.children.length !== 1"
        :append-menu-to-body="appendMenuToBody"
      />
    </div>
    <div
      v-if="entry.isNode && entry.lhs && entry.rhs"
      class="d-flex"
      :class="{ 'mr-2': addSpaceToNeighbours, 'ml-2': addSpaceToNeighbours }"
    >
      <EvalTreeEntry
        :key="`eval-exp-entry-${entry.lhs.idx}-${entry.lhs.isNode ? 'n' : 'l'}`"
        :entry="entry.lhs"
        :preview="preview"
        :append-menu-to-body="appendMenuToBody"
      />
      <EvalTreeEntry
        :key="`eval-exp-entry-${entry.rhs.idx}-${entry.rhs.isNode ? 'n' : 'l'}`"
        :entry="entry.rhs"
        :preview="preview"
        :append-menu-to-body="appendMenuToBody"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { evalExpressionValidators } from '@/validations/ems-eval-expression-validators'
import { TimeseriesAggregate } from '@/../lib/proto_js/ext/ems/eval_pb'
import { operatorOptionsForTreeSelect } from '@/view-helper/form-helpers'
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'EvalTreeEntry',
  components: {
    TreeSelect
  },
  props: {
    disableOnPreviewPage: {
      type: Boolean,
      required: false,
      default: false
    },
    entry: {
      type: Object,
      required: true
    },
    preview: {
      type: Boolean,
      required: false,
      default: false
    },
    addSpaceToNeighbours: {
      type: Boolean,
      required: false,
      default: true
    },
    appendMenuToBody: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      operator: this.entry.operator ? this.entry.operator : null,
      alias: this.entry.alias?.trim(),
      reference: this.entry.reference?.trim(),
      timeseriesPoint: this.entry.timeseriesPoint
        ? {
            reference: this.entry.timeseriesPoint.reference,
            deltaTime: this.entry.timeseriesPoint.deltaTime
          }
        : { reference: null, deltaTime: 0 },
      timeseriesAggregate: this.entry.timeseriesAggregate
        ? {
            reference: this.entry.timeseriesAggregate.reference,
            deltaStartTime: this.entry.timeseriesAggregate.deltaStartTime,
            deltaEndTime: this.entry.timeseriesAggregate.deltaEndTime,
            op: this.entry.timeseriesAggregate.op
          }
        : { reference: null, deltaStartTime: 0, deltaEndTime: 0, op: TimeseriesAggregate.Operation.INTEGRAL },
      value: this.entry.value,
      children: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['disabledFeatures']),
    ...mapGetters('emsEvalAliases', ['aliasNames']),
    ...mapGetters('emsEvalExpression', ['sizeEvalTree']),
    ...mapGetters('emsSensors', [
      'getSensorNames',
      'basicSensorNameOptionsForTreeSelect',
      'allSensorNameOptionsForTreeSelect'
    ]),
    ...mapGetters('emsTimeseries', ['timeseriesNames']),
    showExtendedDisplay() {
      return this.sizeEvalTree < 5
    },
    dressedOperator() {
      switch (this.operator) {
        case 'reference':
          if (this.timeseriesNames && this.timeseriesNames.includes(this.reference)) {
            return 'reference-timeseries'
          }
          if (this.getSensorNames(false).includes(this.reference)) {
            return 'reference-sensor'
          }
          if (this.getSensorNames(true).includes(this.reference)) {
            return 'reference-sensor-all'
          }
          return 'reference'
        case 'timeseriesPoint':
          if (this.getSensorNames(false).includes(this.timeseriesPoint.reference)) {
            return 'timepoint-sensor'
          }
          if (this.getSensorNames(true).includes(this.timeseriesPoint.reference)) {
            return 'timepoint-sensor-all'
          }
          return 'timeseriesPoint'
        case 'timeseriesAggregate':
          if (this.getSensorNames(false).includes(this.timeseriesAggregate.reference)) {
            return 'timeaggregate-sensor'
          }
          if (this.getSensorNames(true).includes(this.timeseriesAggregate.reference)) {
            return 'timeaggregate-sensor-all'
          }
          return 'timeseriesAggregate'
        default:
          return this.operator
      }
    },
    aliasOpts() {
      return this.aliasNames.map((alias) => {
        return { value: alias, label: alias }
      })
    },
    operatorOptions() {
      let blacklistedOperators = []

      if (this.disabledFeatures.includes('time_of_use')) {
        blacklistedOperators = blacklistedOperators.concat([
          'reference-timeseries',
          'timeseriesPoint',
          'timeseriesAggregate'
        ])
      }

      return operatorOptionsForTreeSelect({ blacklistedOperators })
    },
    timeseriesOptions() {
      return this.timeseriesNames.map((n) => {
        return {
          value: n,
          label: n
        }
      })
    },
    referenceOptions() {
      const basicSensorOperators = ['timepoint-sensor', 'timeaggregate-sensor', 'reference-sensor']
      const allSensorOperators = ['timepoint-sensor-all', 'timeaggregate-sensor-all', 'reference-sensor-all']
      if (basicSensorOperators.includes(this.dressedOperator)) {
        return this.basicSensorNameOptionsForTreeSelect
      } else if (allSensorOperators.includes(this.dressedOperator)) {
        return this.allSensorNameOptionsForTreeSelect
      } else {
        return this.timeseriesOptions
      }
    },
    aggregateOptions() {
      return [
        {
          value: TimeseriesAggregate.Operation.INTEGRAL,
          label: this.$t(`${this.tBasePath}.aggregate.type.integral`)
        },
        { value: TimeseriesAggregate.Operation.MIN, label: this.$t(`${this.tBasePath}.aggregate.type.min`) },
        { value: TimeseriesAggregate.Operation.MAX, label: this.$t(`${this.tBasePath}.aggregate.type.max`) }
      ]
    },
    timepointFeedback() {
      if (!this.$v.timeseriesPoint.deltaTime.range) {
        return this.$t(`${this.tBasePath}.sensorRangeError`)
      } else {
        return this.$t(`${this.tBasePath}.value.invalidFeedback`)
      }
    },
    aggregateFeedbackStart() {
      if (!this.$v.timeseriesAggregate.deltaStartTime.range) {
        return this.$t(`${this.tBasePath}.sensorRangeError`)
      } else {
        return this.$t(`${this.tBasePath}.value.invalidFeedback`)
      }
    },
    aggregateFeedbackEnd() {
      if (!this.$v.timeseriesAggregate.deltaEndTime.range) {
        return this.$t(`${this.tBasePath}.sensorRangeError`)
      } else if (!this.$v.timeseriesAggregate.deltaEndTime.order) {
        return this.$t(`${this.tBasePath}.aggregate.orderError`)
      } else {
        return this.$t(`${this.tBasePath}.value.invalidFeedback`)
      }
    },
    myCustomStyles() {
      return {
        tree: {
          height: 'auto',
          maxHeight: '300px',
          overflowY: 'visible',
          display: 'inline-block',
          textAlign: 'left'
        },
        row: {
          style: {
            width: '500px',
            cursor: 'pointer'
          },
          child: {
            class: '',
            style: {
              height: '35px'
            }
          }
        }
      }
    }
  },
  watch: {
    entry(e) {
      this.sync(e)
    }
  },
  created() {
    this.tBasePath = 'ems.energyService.config.evalTree.form'
    this.referenceOperators = ['reference-timeseries', 'reference-sensor', 'reference-sensor-all']
    this.timepointOperators = ['timepoint-sensor', 'timepoint-sensor-all']
    this.timeaggregateOperators = ['timeaggregate-sensor', 'timeaggregate-sensor-all']
    this.sync(this.entry)
  },
  mounted() {
    this.operator = this.dressedOperator
  },
  // Hack to capture the error triggered by the VueTreeSelectMenu
  // this error has no functional interference
  errorCaptured(_, vm, info) {
    if (vm._name === '<VueTreeselectMenu>' && info === 'v-on handler') {
      return false
    }
  },
  methods: {
    sync(e) {
      const switchedWithinSameType =
        (this.referenceOperators.includes(this.operator) && e.operator === 'reference') ||
        (this.timepointOperators.includes(this.operator) && e.operator === 'timeseriesPoint') ||
        (this.timeaggregateOperators.includes(this.operator) && e.operator === 'timeseriesAggregate')

      this.timeseriesPoint.reference = this.toTreeSelectValue(e.timeseriesPoint.reference)
      this.timeseriesPoint.deltaTime = e.timeseriesPoint.deltaTime
      this.timeseriesAggregate.reference = this.toTreeSelectValue(e.timeseriesAggregate.reference)
      this.timeseriesAggregate.op = e.timeseriesAggregate.op
      this.timeseriesAggregate.deltaStartTime = e.timeseriesAggregate.deltaStartTime
      this.timeseriesAggregate.deltaEndTime = e.timeseriesAggregate.deltaEndTime
      this.reference = this.toTreeSelectValue(e.reference)
      this.value = e.value
      this.alias = this.toTreeSelectValue(e.alias)

      this.children = e.children.length

      if (!switchedWithinSameType) {
        if (e.operator === null || e.operator === '') {
          this.operator = null
        } else {
          this.operator = e.operator
          this.operator = this.dressedOperator
        }
      }
      this.$v.$touch()
    },
    onUpdateOperator(val) {
      this.$store.commit('emsEvalExpression/UPDATE_OPERATOR', {
        nodeIdx: this.entry.idx,
        operator: this.undressOperator(val.id)
      })

      this.$v.reference.$model = null
      this.value = 0 // default
      this.$v.alias.$model = null

      this.$v.timeseriesPoint.reference.$model = null
      this.$v.timeseriesPoint.deltaTime.$model = 0

      this.$v.timeseriesAggregate.reference.$model = null
      this.$v.timeseriesAggregate.deltaStartTime.$model = 0
      this.$v.timeseriesAggregate.deltaEndTime.$model = 0
      this.$v.timeseriesAggregate.op.$model = TimeseriesAggregate.Operation.INTEGRAL
      this.$v.$touch()
    },
    undressOperator(op) {
      switch (op) {
        case 'reference-timeseries':
        case 'reference-sensor':
        case 'reference-sensor-all':
          return 'reference'
        case 'timepoint-sensor':
        case 'timepoint-sensor-all':
          return 'timeseriesPoint'
        case 'timeaggregate-sensor':
        case 'timeaggregate-sensor-all':
          return 'timeseriesAggregate'
        default:
          return op
      }
    },
    undressType(id) {
      let ret = ''
      this.aggregateOptions.forEach((element) => {
        if (element.value === id) {
          ret = element.label
        }
      })
      return ret
    },
    onUpdateValue() {
      if (this.$v.value.$invalid) {
        return
      }
      this.$store.commit('emsEvalExpression/UPDATE_VALUE', {
        nodeIdx: this.entry.idx,
        value: this.$v.value.$model
      })
    },
    // onUpdateReference und onUpdateReferenceProfile
    onUpdateReference() {
      if (this.$v.reference.$invalid) {
        return
      }

      this.$store.commit('emsEvalExpression/UPDATE_REFERENCE', {
        nodeIdx: this.entry.idx,
        reference: this.$v.reference.$model
      })
    },
    onUpdateAlias(alias) {
      alias = alias.trim()
      this.$v.alias.$model = alias

      if (this.$v.alias.$invalid) {
        return
      }

      this.$store.commit('emsEvalExpression/UPDATE_ALIAS', {
        nodeIdx: this.entry.idx,
        alias
      })
    },

    onUpdateTimepoint() {
      this.$v.timeseriesPoint.$touch()
      if (this.$v.timeseriesPoint.$invalid) {
        return
      }

      this.$store.commit('emsEvalExpression/UPDATE_TIMEPOINT', {
        nodeIdx: this.entry.idx,
        reference: this.$v.timeseriesPoint.reference.$model,
        deltaTime: parseFloat(this.$v.timeseriesPoint.deltaTime.$model)
      })
    },

    onUpdateAggregate() {
      this.$v.timeseriesAggregate.$touch()
      if (this.$v.timeseriesAggregate.$invalid) {
        return
      }
      this.$store.commit('emsEvalExpression/UPDATE_AGGREGATE', {
        nodeIdx: this.entry.idx,
        reference: this.$v.timeseriesAggregate.reference.$model,
        deltaStartTime: parseFloat(this.$v.timeseriesAggregate.deltaStartTime.$model),
        deltaEndTime: parseFloat(this.$v.timeseriesAggregate.deltaEndTime.$model),
        op: parseFloat(this.$v.timeseriesAggregate.op.$model)
      })
    },

    onUpdateReferenceProfile() {
      this.$v.reference.$touch()
      if (this.$v.reference.$invalid) {
        return
      }

      this.$store.commit('emsEvalExpression/UPDATE_REFERENCE', {
        nodeIdx: this.entry.idx,
        reference: this.$v.reference.$model
      })
    },
    onAddOperand() {
      this.$store.commit('emsEvalExpression/ADD_ENTRY', {
        parentIdx: this.entry.idx
      })
    },
    onRmOperand() {
      this.$store.commit('emsEvalExpression/REMOVE_ENTRY', {
        nodeIdx: this.entry.idx
      })
    },
    toTreeSelectValue(value) {
      value = value?.trim()
      if (value === '') {
        return null
      } else {
        return value
      }
    }
  },
  validations: evalExpressionValidators
}
</script>
