/**
 * Simple global in memory cache.
 *
 * Non-volatile and not-dynamic (in the sense of VueJS).
 *
 * @module store/cache
 */

let cache = {}

export const CACHE_ROOT_KEYS = {
  peakObservers: 'peakObservers',
  deviceConfigs: 'deviceConfigs'
}

/**
 * Access an entry of the global in memory cache.
 *
 * Is NOT dynamic in the sense of Vue.
 *
 * @function
 *
 * @param {array<string>} path (optional) is a list of keys successively chained to access a cache value.
 *
 * @return {any} the cache value
 */
export function getCache(path = []) {
  let c = cache
  path.forEach((e) => {
    if (e === undefined) {
      throw new Error('Invalid cache path. Found undefined. Expected a string.')
    }

    if (c === undefined) {
      return
    }
    c = c[e]
  })

  return c
}

/**
 * Allows to set a value to the global in memory cache.
 *
 * Set will overwrite.
 *
 * @function
 *
 * @param {array<string>} path is a list of keys, at which position the value should be set
 * @param {any} value the value to be set
 */
export function setCache(path, value) {
  let c = cache
  path.forEach((e, i) => {
    if (i < path.length - 1) {
      if (typeof c[e] !== 'object') {
        c[e] = {}
      }
      c = c[e]
    } else {
      c[e] = value
    }
  })
}

export function clearCache(key) {
  if (key) {
    cache[key] = undefined
  } else {
    cache = {}
  }
}
