<template>
  <CCard :key="device" class="px-0">
    <ApxCardHeader
      :icon="deviceIcon"
      :title="
        routeName === 'config-device-new'
          ? $t(`config.device.${device}.newTitle`)
          : $t(`config.device.${device}.settingsTitle`)
      "
    />
    <CCardBody>
      <nav class="navbar navbar-expand-sm bg-light">
        <!-- prettier-ignore -->
        <CButton
          type="button"
          size="sm"
          color="secondary"
          class="mr-auto "
          @click="routeToDeviceList"
        >
          <CIcon size="sm" name="cilDescription" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('config.device.list.showAllConfigs') }}
          </span>
        </CButton>
        <CButton
          v-if="routeName === 'config-device-edit'"
          type="button"
          size="sm"
          color="danger"
          @click="showConfirmDelete = true"
        >
          {{ $t('config.device.list.deleteConfig') }}
        </CButton>
        <!-- prettier-ignore -->
        <CButton
          class="ml-2"
          type="button"
          size="sm"
          color="primary"
          @click="showAssistant = !showAssistant"
        >
          <CIcon size="sm" name="cilBrush" />
          <span class="align-middle d-none d-sm-inline">
            {{ $t('config.device.modbusTcpAssistant.assistantBtn') }}
          </span>
        </CButton>
      </nav>
    </CCardBody>
    <CCardBody>
      <CForm class="mt-4" @submit.prevent>
        <div v-if="routeName === 'config-device-new'" class="form-group form-row">
          <label class="custom-form-label col-12 col-md-5 col-xl-4">{{
            $t('config.device.form.selectModel.label')
          }}</label>
          <div class="col-12 col-md-7 col-xl-6">
            <TreeSelect
              :value.sync="selectedModelId"
              disable-branch-nodes
              :clearable="false"
              :placeholder="$t('config.device.form.selectModel.placeholder')"
              :options="modelSelectOpts"
              @input="onSelectModel"
            />
            <small class="form-text text-muted w-100">{{ $t('config.device.form.selectModel.description') }}</small>
          </div>
        </div>

        <CInput
          v-if="routeName === 'config-device-edit'"
          class="custom-form-label"
          :label="$t('config.device.form.selectModel.preselectedLable')"
          :description="$t('config.device.form.selectModel.preselectedDescription')"
          :value="deviceConfigDriverName"
          :horizontal="styling.horizontal"
          disabled
        />
      </CForm>
      <!-- prettier-ignore -->
      <hr v-if="selectedModelId || deviceConfigDriverName" >
      <!-- Hint: make sure to update the `key` if the config prop changes -->
      <ApxDeviceConfigForm
        v-if="deviceModel.configId || deviceModel.configMsgName"
        :key="(deviceModel.configId || deviceModel.configMsgName) + '_' + resetCount"
        :config-msg-name="deviceModel.configMsgName"
        :cfg-info="deviceModel"
        :dev-cfg="deviceConfig"
        :styling="{
          horizontalScalar: styling.horizontal,
          horizontalFork: { label: 'd-none', input: 'col-12 col-md-8 col-xl-7' }
        }"
        @validation-error="onValidationError"
        @reset="onReset"
        @submit="onSubmit"
      />
    </CCardBody>
    <ConfirmationModal
      :visible.sync="showConfirmDelete"
      color="danger"
      size="sm"
      :title="$t('config.device.form.deleteConfirmTitle')"
      @update:confirmation="onDeleteDeviceConfig({ confirmed: $event, configId })"
    >
      {{ $t('config.device.form.deleteConfirmContent') }}
    </ConfirmationModal>
    <!-- BEGIN: Modbus IP Config Assistant -->
    <CModal
      :show.sync="showAssistant"
      color="info"
      size="lg"
      :title="$t('config.device.modbusTcpAssistant.assistantBtn')"
    >
      <ApxModbusTcpIpConfigAssistant />
      <template #footer>
        <div class="d-flex justify-content-end">
          <CButton class="mx-1" type="button" color="secondary" @click="showAssistant = !showAssistant">
            {{ $t('main.closeBtn') }}
          </CButton>
        </div>
      </template>
    </CModal>
    <!-- END:   Modbus IP Config Assistant -->
  </CCard>
</template>

<script>
import { toLower, toUpper } from 'lodash'
import { mapGetters } from 'vuex'

import {
  generateDeviceModelsOptsForTreeSelect,
  deviceTypeToIcon
} from '@/view-helper/device-config/device-config-models-helper'

import ApxCardHeader from '@/components/snippets/apx-card-header'
import ApxDeviceConfigForm from '@/components/apx-config-forms/apx-device-config-form'
import ApxModbusTcpIpConfigAssistant from '@/components/apx-modbus-tcp-ip-config-assistant'
import ConfirmationModal from '@/components/snippets/confirmation-modal'
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'ApxDeviceConfig',
  components: {
    ApxCardHeader,
    ApxDeviceConfigForm,
    ApxModbusTcpIpConfigAssistant,
    ConfirmationModal,
    TreeSelect
  },
  data() {
    return {
      device: '',
      selectedModelId: null,
      deviceModel: {}, // an instance of DeviceModel
      deviceConfig: {}, // the device config as plain object
      deviceConfigDriverName: '',
      resetCount: 0,
      showAssistant: false,
      showConfirmDelete: false
    }
  },
  computed: {
    ...mapGetters('auth', ['omiOnlyEnabled']),
    ...mapGetters('deviceConfig', [
      'deviceModels',
      'getNewDeviceModel',
      'getDevCfgInfo',
      'getDeviceConfig',
      'omiInverters'
    ]),
    deviceIcon() {
      return deviceTypeToIcon(this.device)
    },
    modelSelectOpts() {
      if (this.omiOnlyEnabled && this.device === 'inverter') {
        return generateDeviceModelsOptsForTreeSelect(this.omiInverters)
      }

      return generateDeviceModelsOptsForTreeSelect(this.deviceModels)
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.initStore(id)
    }
  },
  created() {
    this.styling = {
      horizontal: {
        label: 'col-12 col-md-5 col-xl-4',
        input: 'col-12 col-md-7 col-xl-6'
      }
    }
    this.routeName = this.$route.name
    this.device = toLower(this.$route.params.device)
    this.initStore(this.$route.params.id)
  },
  mounted() {},
  methods: {
    initStore(configId) {
      const calls = []
      this.configId = configId
      this.$store.commit('deviceConfig/CLEAR_CONFIGS')
      calls.push(
        this.$store
          .dispatch('deviceConfig/fetchDeviceDrivers', toUpper(this.device))
          .then((msg) => {
            this.$log.debug('Successful fetchDeviceDrivers for deviceModels.')
          })
          .catch(this.onServerError)
          .finally(() => {
            this.$log.debug(this.deviceModels)
          })
      )

      if (this.routeName === 'config-device-edit') {
        // writes config to in-memory cache, too
        calls.push(
          this.$store
            .dispatch('deviceConfig/getDeviceConfig', this.configId)
            .then((msg) => {
              const c = this.getDevCfgInfo(this.configId)
              this.deviceConfig = this.getDeviceConfig(this.configId)
              this.deviceModel = c // note: DeviceConfigInfo is a subset of DeviceModel
              this.deviceConfigDriverName = c.driverAlias

              this.$log.debug('Successful getDeviceConfig with meta-data:')
              this.$log.debug(c)
            })
            .catch(this.onServerError)
        )
      }

      return Promise.all(calls)
    },
    onSelectModel(mtrId) {
      this.deviceModel = this.getNewDeviceModel(parseInt(mtrId))
    },
    onServerError(err) {
      this.$log.warn(err)
      this.$store.commit('notifications/PUSH_TOAST', {
        title: err.name,
        content: err.message,
        type: 'toast-warning',
        autohide: 3000
      })
    },
    onCUDError(err) {
      this.$log.warn(err)
      const errMessage = {
        title: 'Server Error',
        type: 'toast-warning',
        content: [],
        autohide: false
      }
      if (this.routeName === 'config-device-new') {
        errMessage.content.push(this.$t('api.errors.deviceConfigAdd', { errorCode: err.code }))
      } else if (this.routeName === 'config-device-edit') {
        errMessage.content.push(this.$t('api.errors.deviceConfigUpdate', { errorCode: err.code }))
      }
      errMessage.content.push(err.message)

      this.$store.commit('notifications/PUSH_TOAST', errMessage)
    },
    async onReset() {
      await this.initStore(this.$route.params.id)
      this.resetCount++ // forces re-render of ApxDeviceConfigForm
    },
    onSubmit(devCfgMsg) {
      // devCfgMsg is a full proto JS object
      switch (this.routeName) {
        case 'config-device-new':
          this.$store
            .dispatch('deviceConfig/addDeviceConfig', {
              deviceType: toUpper(this.device),
              devCfgInfo: this.deviceModel,
              devCfgMsg
            })
            .then((msg) => {
              this.$log.debug('Successfully added a new device configuration')
              this.$emit('show-alert', 'success')
              this.routeToDeviceList()
            })
            .catch(this.onCUDError)
          break
        case 'config-device-edit':
          this.$store
            .dispatch('deviceConfig/updateDeviceConfig', {
              deviceType: toUpper(this.device),
              devCfgMsg
            })
            .then((msg) => {
              this.$log.debug('Successfully updated the device configuration')
              // TODO: will never be displayed to the user, because of redirect
              this.$emit('show-alert', 'success')

              const configId = msg.getConfigId() // BE updates the config-ID
              if (configId && configId !== this.deviceModel.configId) {
                this.routeToDeviceEdit(configId)
              } else {
                this.routeToDeviceList()
              }
            })
            .catch(this.onCUDError)
          break
      }
    },
    onValidationError(validation) {
      this.$emit('show-alert', 'failed')
    },
    onDeleteDeviceConfig({ confirmed, configId }) {
      if (!confirmed) {
        return
      }

      this.deleteDeviceConfig(configId)
    },
    deleteDeviceConfig(configId) {
      this.$store
        .dispatch('deviceConfig/deleteDeviceConfig', configId)
        .then((msg) => {
          this.$log.debug('Successful deleteDeviceConfig')
          this.$emit('show-alert', 'success')
          this.routeToDeviceList()
        })
        .catch(this.onCUDError)
    },
    routeToDeviceEdit(configId) {
      this.$router.push({
        name: 'config-device-edit',
        params: { device: this.device, id: configId }
      })
    },
    routeToDeviceList() {
      this.$router.push({
        name: 'config-devices',
        params: { device: `${this.device}s` }
      })
    }
  }
}
</script>
