/**
 * @module validations/ems-es-decision-tree-validators
 */

import { isEmpty } from 'lodash'
import { helpers, required, requiredIf, alphaNum, between } from 'vuelidate/lib/validators'

import { EMS_ENERGY_SERVICE_DECISION_TYPES } from '@/grpc/protobuf/ems-scontroller-helper'
import i18n from '@/i18n'

/**
 * Validators used for vualidate to validate the EsSocNodeForm
 *
 * @namepace
 * @property {object} physicalDeviceId contains the validators for the physical device ID (literal)
 *
 */
export const socNodeFormValidators = {
  physicalDeviceId: {
    required: requiredIf((vm) => {
      return vm.useLegacy === true
    })
  },
  actuatorGroup: {
    required: requiredIf((vm) => {
      return vm.useLegacy === false
    })
  },
  useLegacy: {
    dontCare: (value) => value || true // make the unit test for validator happy
  }
}

export const offgridNodeFormValidators = {
  gridState: {
    required
  }
}

export const vppGridBatNodeFormValidators = {
  target: {
    required
  }
}

export const chaiScriptNodeFormValidators = {
  prefix: {
    required,
    alphaNum
  },
  filepath: {
    required,
    filepath: helpers.regex('filepath', /^[A-z\d-_/.]*\.[A-z]+$/)
  }
}

/**
 * Validators used for vualidate to validate the es-off-grid-target-soc-generation-curtailment-form
 *
 * @namepace
 * @property {object} physicalDeviceId contains the validators for the physical device ID (literal)
 *
 */
export const offGridTargetSocGenerationCurtailmentValidators = {
  physicalDeviceId: {
    required
  },
  socMax: {
    required,
    betweenValue: between(0, 100)
  }
}

/**
 * Allows to validate the `ems.scontroller.DecisionTree`.
 * Should not be used with vuelidate.
 *
 * @constructor
 */
export function TreeValidator() {
  this.invalidIdxs = []
  this.invalidTypes = []

  this.isValid = () => {
    return this.invalidIdxs.length === 0
  }

  /**
   * Validates the leafs of the decision tree
   *
   * @function
   *
   * @param {array} entries are a list of instances of `DecisionTreeEntry` (should be leafs)
   *
   * @return {boolean}
   */
  this.leafs = (entries) => {
    const validators = [
      {
        type: 'isLeaf',
        validate: (entry) => !entry.isNode
      },
      {
        type: 'hasName',
        validate: (entry) => !!entry.name
      },
      {
        type: 'validLeafParams',
        validate: (entry) => !!entry.params.strategyId
      }
    ]

    const { invalidIdxs, invalidTypes } = runValidation({ entries, validators })

    this.invalidIdxs = this.invalidIdxs.concat(invalidIdxs)
    this.invalidTypes = this.invalidTypes.concat(invalidTypes)

    return invalidIdxs.length === 0
  }

  /**
   * Validates the nodes of the decision tree
   *
   * @function
   *
   * @param {array} entries are a list of instances of `DecisionTreeEntry` (should be nodes)
   *
   * @return {boolean}
   */
  this.nodes = (entries) => {
    const validators = [
      {
        type: 'isNode',
        validate: (entry) => !!entry.isNode
      },
      {
        type: 'hasName',
        validate: (entry) => !!entry.name
      },
      {
        type: 'validNodeParams',
        validate: (entry) => {
          return (
            !isEmpty(entry.params) &&
            entry.params.decisionNodeType &&
            EMS_ENERGY_SERVICE_DECISION_TYPES.includes(entry.params.decisionNodeType) &&
            entry.params.decisionNodeParams
          )
        }
      }
    ]

    const { invalidIdxs, invalidTypes } = runValidation({ entries, validators })

    this.invalidIdxs = this.invalidIdxs.concat(invalidIdxs)
    this.invalidTypes = this.invalidTypes.concat(invalidTypes)

    return invalidIdxs.length === 0
  }

  // private
  function runValidation({ entries, validators }) {
    const invalidIdxs = []
    const invalidTypes = []

    entries.forEach((entry) => {
      let isValid = true
      for (const v of validators) {
        if (!isValid) {
          break
        }

        isValid = isValid && v.validate(entry)
        if (!isValid) {
          invalidIdxs.push(entry.idx)
          invalidTypes.push(v.type)
        }
      }
    })

    return { invalidIdxs, invalidTypes }
  }
}

/**
 * Runs validation of the decision tree
 *
 * @function
 *
 * @param {object} ctxt has to be the View context, i.e. the vue instance which provides the getters `decisionTreeNodeIdxs`, `decisionTreeLeafIdxs` and `getDecisionEntry()`
 *
 * @return {TreeValidator} the validator
 */
export function validateDecisionTree(ctxt) {
  const nodes = []
  const leafs = []
  const validator = new TreeValidator()
  ctxt.decisionTreeNodeIdxs.forEach((idx) => {
    nodes.push(ctxt.getDecisionTreeEntry(idx))
  })
  ctxt.decisionTreeLeafIdxs.forEach((idx) => {
    leafs.push(ctxt.getDecisionTreeEntry(idx))
  })

  validator.nodes(nodes)
  validator.leafs(leafs)

  return validator
}

export function buildDecisionTreeValidationNotification({ ctxt, validator }) {
  const tBasePath = 'ems.energyService.config.decisionTree.validation'
  const errors = []
  const evalFailures = (idx, isNode) => {
    const i = validator.invalidIdxs.findIndex((j) => j === idx)
    if (i >= 0) {
      let msg
      if (isNode) {
        msg = i18n.t(`${tBasePath}.invalidNode`, { idx })
      } else {
        msg = i18n.t(`${tBasePath}.invalidLeaf`, { idx })
      }

      const tErrPath = `${tBasePath}.${validator.invalidTypes[i]}`
      if (i18n.te(tErrPath)) {
        msg = msg + ': ' + i18n.t(tErrPath)
      }

      errors.push(msg)
    }
  }
  ctxt.decisionTreeNodeIdxs.forEach((idx) => {
    evalFailures(idx, true)
  })
  ctxt.decisionTreeLeafIdxs.forEach((idx) => {
    evalFailures(idx, false)
  })

  return {
    autohide: false,
    title: i18n.t('ems.energyService.activation.validationFailureTitle'),
    type: 'danger',
    content: errors.length === 1 ? errors[0] : errors
  }
}
